import { LIST_LOADING, LIST_LOADED, REMOVE_ITEM, ADD_ITEM, CLEAR_ITEMS, CHANGE_ITEM_QUANTITY, CHANGE_ITEM_PRICE, CHANGE_TAX_RATE, EXPORT_LIST_BEGIN, EXPORT_LIST_SUCCESS, EXPORT_LIST_FAIL, TOGGLE_EDIT, } from './action-creators';
export var ExportingStatus;
(function (ExportingStatus) {
    ExportingStatus[ExportingStatus["None"] = 0] = "None";
    ExportingStatus[ExportingStatus["Loading"] = 1] = "Loading";
    ExportingStatus[ExportingStatus["Begin"] = 2] = "Begin";
    ExportingStatus[ExportingStatus["Fail"] = 3] = "Fail";
    ExportingStatus[ExportingStatus["Success"] = 4] = "Success";
})(ExportingStatus || (ExportingStatus = {}));
export default function (state, action) {
    if (!state) {
        state = {
            items: [],
            exportingStatus: ExportingStatus.None,
            taxRate: 12,
        };
    }
    switch (action.type) {
        case LIST_LOADING:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Loading });
        case LIST_LOADED:
            return Object.assign({}, state, {
                items: action.items,
                exportingStatus: ExportingStatus.None,
            });
        case CLEAR_ITEMS:
            return Object.assign({}, state, { items: [], exportingStatus: ExportingStatus.None });
        case REMOVE_ITEM:
            return Object.assign({}, state, { items: state.items.filter(function (i) { return i.id !== action.id; }) });
        case ADD_ITEM:
            return Object.assign({}, state, {
                items: state.items.findIndex(function (i) { return i.id === action.item.id; }) === -1
                    ? Object.assign([], state.items, state.items.concat([action.item]))
                    : state.items.map(function (i) {
                        return i.id === action.item.id ? Object.assign({}, i, { quantity: i.quantity + 1 }) : i;
                    }),
            });
        case CHANGE_ITEM_QUANTITY:
            return Object.assign({}, state, {
                items: state.items.map(function (i) {
                    return i.id === action.id
                        ? Object.assign({}, i, { quantity: action.value })
                        : i;
                }),
            });
        case CHANGE_ITEM_PRICE:
            return Object.assign({}, state, {
                items: state.items.map(function (i) {
                    return i.id === action.id
                        ? Object.assign({}, i, { price: action.value })
                        : i;
                }),
            });
        case CHANGE_TAX_RATE:
            return Object.assign({}, state, { taxRate: action.value });
        case TOGGLE_EDIT:
            return Object.assign({}, state, {
                items: state.items.map(function (i) {
                    return i.id === action.id
                        ? Object.assign({}, i, { isEditing: action.editing })
                        : i;
                }),
            });
        case EXPORT_LIST_BEGIN:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Begin });
        case EXPORT_LIST_SUCCESS:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Success });
        case EXPORT_LIST_FAIL:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Fail });
        default:
            return state;
    }
}
