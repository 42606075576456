var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import { pdfSendUrl } from '../../../InvoiceOverview/action-creators';
import classnames from 'classnames';
import { invoiceOverviewPageUrl } from 'Shared/known-urls';
import MyMenigoIcon from '../../Shared/MyMenigoIcon/MyMenigoIcon';
var UnpaidInvoiceBlock = /** @class */ (function (_super) {
    __extends(UnpaidInvoiceBlock, _super);
    function UnpaidInvoiceBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UnpaidInvoiceBlock.prototype.getInvoiceStatus = function (status) {
        if (status === 'paid')
            return translate('/Invoice/Paid');
        else if (status === 'notPaid')
            return translate('/Invoice/UnPaid');
        else
            return '-';
    };
    UnpaidInvoiceBlock.prototype.dateInPast = function (date) {
        var today = new Date();
        var issueDate = new Date(date);
        if (issueDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    };
    ;
    UnpaidInvoiceBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("h2", { className: styles.invoice__Header }, "Fakturor"),
            React.createElement("div", { className: styles.invoice__Container },
                this.props.invoiceList.length === 0 && (translate('/Invoice/NoUnPaidInvoices')),
                this.props.invoiceList.length > 0 && (this.props.invoiceList.slice(0, 2).map(function (data, index) { return (React.createElement("div", { className: styles.invoice__WrapItems, key: index },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles.cell },
                            React.createElement("span", { className: styles.cell__Header }, translate('/Invoice/Status')),
                            React.createElement("span", { className: styles.cell__Data }, _this.getInvoiceStatus(data.status))),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("span", { className: styles.cell__Header }, translate('/Invoice/InvoiceNumber')),
                            React.createElement("span", { className: styles.cell__Data }, data.id)),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("span", { className: styles.cell__Header }, translate('/Invoice/InvoiceDate')),
                            React.createElement("span", { className: styles.cell__Data }, data.issueDate)),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("span", { className: styles.cell__Header }, translate('/Invoice/DueDate')),
                            React.createElement("span", { className: classnames(styles.cell__Data, _this.dateInPast(data.dueDate) && styles.datePassed) }, data.dueDate)),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("span", { className: styles.cell__Header }, translate('/Invoice/Amount')),
                            React.createElement("span", { className: styles.cell__Data },
                                data.amount,
                                " ",
                                translate('/Price/CurrencySymbol'))),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("a", { className: styles.download, onClick: function () { return _this.props.pdfSendUrl(data.url, data.id); } },
                                translate('/Invoice/PdfText'),
                                React.createElement(MyMenigoIcon, { name: "download" })))))); })),
                React.createElement("div", { className: styles.invoice__Footer },
                    React.createElement("a", { href: invoiceOverviewPageUrl(), className: styles.invoice__Footer__Link },
                        React.createElement("span", null, translate('/Invoice/AllInvoice')),
                        React.createElement(MyMenigoIcon, { name: "arrow" }))))));
    };
    return UnpaidInvoiceBlock;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    pdfSendUrl: function (url, invoiceNumber) {
        dispatch(pdfSendUrl(url, invoiceNumber));
    }
}); })(UnpaidInvoiceBlock);
