var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import MenuItem from './MenuItem';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import connect from 'Shared/connect';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
var MyPagesMenu = /** @class */ (function (_super) {
    __extends(MyPagesMenu, _super);
    function MyPagesMenu(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { isOpen: false };
        return _this;
    }
    MyPagesMenu.prototype.toggleMenu = function (status) {
        this.setState({ isOpen: !this.state.isOpen });
    };
    MyPagesMenu.prototype.render = function () {
        var _this = this;
        var isDesktop = this.props.currentBreakpoint > SMALL;
        var menuItemNode = !!this.props.menuItems &&
            this.props.menuItems.map(function (menuItem, i) {
                return (React.createElement(MenuItem, { key: menuItem.url, isCurrent: menuItem.isCurrent, toggle: function () { return _this.toggleMenu(_this.state.isOpen); }, isLoggedIn: _this.props.isLoggedIn, isDisabledForGuestUsers: menuItem.isDisabledForGuestUsers, url: menuItem.url, name: menuItem.name }));
            });
        var mobileButtonNode = (React.createElement(Button, { appearance: "tertiary", onClick: function () { return _this.toggleMenu(_this.state.isOpen); } },
            React.createElement(Icon, { name: "nav" })));
        var activeMenuItem = this.props.menuItems.filter(function (menuItem) { return menuItem.isCurrent; });
        return (React.createElement("div", { className: styles.base }, !isDesktop ? (React.createElement("div", null,
            React.createElement("div", { className: styles.mobileMenuHeader },
                React.createElement("div", { className: styles.activeMenuItemWrap }, activeMenuItem.length > 0 && activeMenuItem[0].name),
                mobileButtonNode),
            this.state.isOpen ? React.createElement("div", { className: styles.mobileMenuItems }, menuItemNode) : null)) : (React.createElement("div", { className: styles.myMenigoMenuItems }, menuItemNode))));
    };
    return MyPagesMenu;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
}); })(MyPagesMenu);
