import React, { useEffect, useRef } from 'react';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductCampaignDate from 'Shared/ProductComponents/ProductCampaignDate';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import ProductMarks from 'Shared/ProductCampaignLabel';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import * as styles from './mobile.scss';
import { translate } from 'Shared/translate';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
var ProductSearchMobile = function (_a) {
    var product = _a.product, selectedVariant = _a.selectedVariant, url = _a.url, discontinuedOrBackordered = _a.discontinuedOrBackordered, onClick = _a.onClick, onFocus = _a.onFocus, rowIndex = _a.rowIndex, addToListDisabled = _a.addToListDisabled, rowHasFocus = _a.rowHasFocus, addByIndex = _a.addByIndex;
    var rowElement = useRef(null);
    var disabledFadeClassName = discontinuedOrBackordered || product.isTender ? styles.disabledFade : '';
    var handleOnFocus = function () {
        if (onFocus) {
            return onFocus(rowIndex);
        }
    };
    var handleClick = function (e) {
        e.preventDefault();
        rowElement.current.focus();
        if (onClick) {
            onClick(rowIndex);
        }
        addByIndex(rowIndex);
    };
    useEffect(function () {
        if (rowHasFocus) {
            rowElement.current.focus();
        }
    }, [rowHasFocus, rowElement]);
    return (React.createElement("div", { ref: rowElement, tabIndex: 0, className: styles.card, itemType: "http://schema.org/Product", itemScope: true },
        React.createElement("div", { className: styles.cardInner },
            React.createElement("div", { className: styles.innerFlexDiv },
                React.createElement("div", { className: styles.imageWrap },
                    React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, code: selectedVariant && selectedVariant.code },
                        React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.content.displayName, size: "tiny" }))),
                React.createElement("div", { className: styles.outerFlexWrap },
                    React.createElement("div", { className: styles.topRow },
                        React.createElement("div", { className: styles.titleWrap },
                            React.createElement(ProductTitle, { linkUrl: url, ticket: product.ticket, displayName: product.content.displayName, className: styles.title })),
                        !discontinuedOrBackordered && !product.isTender && (React.createElement("div", { className: styles.availabilityWrap },
                            React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability })))),
                    React.createElement("div", { className: styles.contentRow },
                        React.createElement("div", { className: styles.detailsWrap },
                            React.createElement("div", { className: disabledFadeClassName },
                                React.createElement(ProductCampaignDate, { isUtf: product.isUtf, bestBeforeDate: product.bestBeforeDate, isInCampaign: product.isInCampaign, closestCampaignEndDate: product.closestCampaignEndDate, isProductIncludedInNewsCampaign: product.isProductIncludedInNewsCampaign, productCampaignDescriptions: product.campaignDescriptions })),
                            React.createElement(ProductDetails, { code: product.content.code, linkUrl: url, ticket: product.ticket, trademark: product.trademark, className: disabledFadeClassName })),
                        !discontinuedOrBackordered && !product.isTender && (React.createElement("div", { className: styles.typeAndUnitWrap },
                            React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: selectedVariant, productArea: product.area, discontinuedOrBackordered: discontinuedOrBackordered, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign })))),
                    React.createElement("div", { className: styles.contentRow },
                        !discontinuedOrBackordered && !product.isTender && (React.createElement("div", { className: styles.priceWrap },
                            React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: false }))),
                        React.createElement("div", { className: styles.marksAndDisabledInfoWrap },
                            !discontinuedOrBackordered && !product.isTender && (React.createElement("div", { className: styles.marksWrap },
                                React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice }))),
                            (discontinuedOrBackordered || product.isTender) && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.disabledIconWrap },
                                    React.createElement(Icon, { className: styles.disabledIcon, name: "exclamation-mark" })),
                                React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender }))))))),
            React.createElement("div", { className: styles.bottomRow },
                React.createElement("div", { className: styles.listBtnsWrap }),
                React.createElement("div", { className: styles.addToListBtnWrap },
                    React.createElement(Button, { onClick: function (e) { return handleClick(e); }, onFocus: function () { return handleOnFocus(); }, className: styles.btn, disabled: addToListDisabled }, addToListDisabled ? translate('/Product/AlreadyInList') : translate('/Product/AddToList')))))));
};
export default ProductSearchMobile;
