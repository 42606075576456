import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal, { ModalType } from 'Shared/Modal';
import { removeProductsFromList } from 'Shared/ListItem/action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import * as styles from '../base.scss';
export var deleteDialogId = function (listId) { return listId + "_Delete"; };
function DeleteDialog(props) {
    return (React.createElement(Modal, { title: translate('/Account/ProductList/ConfirmRemoveProducts'), type: ModalType.Warning, isOpen: props.isOpen, onClose: props.closeModal, reserveSpaceForCloseButton: true, fadeDesign: true },
        props.showOnlyUnlockedWillBeDeleted && (React.createElement("p", { className: styles.deleteDialog }, translate('/Account/ProductList/ConfirmRemoveUnlockedItems'))),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return props.removeProductsFromList(); }, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal, customAcceptText: props.showOnlyUnlockedWillBeDeleted && translate('/Shared/RemoveUnlockedItems') })));
}
export default connect(null, function (dispatch, _a) {
    var listId = _a.listId, codes = _a.codes;
    return ({
        removeProductsFromList: function () { return dispatch(removeProductsFromList(listId, codes)); },
    });
})(DeleteDialog);
