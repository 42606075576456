import React from 'react';
import * as styles from './base.scss';
var EsalesFacetCategory = function (_a) {
    var subCategories = _a.subCategories, excludeCnt = _a.excludeCnt;
    var list = subCategories.map(function (category) { return (React.createElement("li", { key: category.code },
        React.createElement("a", { href: category.url, "data-ticket": category.ticket, className: styles.facetValue },
            React.createElement("div", { className: styles.facetEllipsisWrap },
                React.createElement("label", null, category.displayName),
                !excludeCnt && React.createElement("span", { className: styles.count },
                    "(",
                    category.productCount,
                    ") "))))); });
    return React.createElement(React.Fragment, null, list);
};
export default EsalesFacetCategory;
