var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import classnames from 'classnames';
import React, { useState } from 'react';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import { translate } from 'Shared/translate';
import * as styles from '../base.scss';
var MobileFiltersAndFacets = function (props) {
    var _a, _b;
    var _c = __read(useState(false), 2), isOpen = _c[0], setOpen = _c[1];
    var toggleFacets = function () {
        setOpen(!isOpen);
    };
    var closeFacets = function () {
        setOpen(false);
    };
    var buttonText = function (count) {
        if (count == 0)
            return translate('/Shared/NoHits');
        if (props.hasActiveCustomFilters)
            return translate('/ProductListing/FadeShowsMobile', "");
        return count > 1 ? translate('/ProductListing/FadeShowsMobile', props.totalCount) : translate('/ProductListing/FadeShowsOneMobile', props.totalCount);
    };
    var countActiveFilters = function (initial) {
        props.facets.filter(function (x) { return x.facetValues.filter(function (y) { return y.selected; }); }).forEach(function (x) {
            initial += x.facetValues.filter(function (y) { return y.selected; }).length;
        });
        return initial;
    };
    var totalSelectedFiltersCount = !!props.facets ? countActiveFilters(props.activeCustomFilterCount) : props.activeCustomFilterCount;
    return (React.createElement("div", { className: classnames(styles.mobileFilterWrapper, (_a = {}, _a[styles.open] = isOpen, _a)) },
        React.createElement("div", { className: classnames(styles.mobileFilterWrapperFiltersList, (_b = {}, _b[styles.open] = isOpen, _b)) },
            React.createElement("div", { className: styles.mobileFilterWrapperTop },
                React.createElement(Button, { appearance: ['tertiary', 'large'], className: styles.toggler, onClick: function () { return toggleFacets(); }, disabled: false },
                    React.createElement("span", null, translate('/Shared/Filter')),
                    totalSelectedFiltersCount > 0 && (React.createElement("span", null,
                        "(",
                        totalSelectedFiltersCount,
                        ")")),
                    React.createElement(Icon, { name: "filter" }),
                    React.createElement(Icon, { name: "close" }))),
            React.createElement("div", { className: styles.mobileFilterWrapperMiddle }, props.children),
            React.createElement("div", { className: styles.mobileFilterWrapperBottom },
                React.createElement(Button, { appearance: ['tertiary'], className: styles.closeButton, onClick: function () { return closeFacets(); }, disabled: false },
                    React.createElement("span", null, buttonText(props.totalCount)))))));
};
export default MobileFiltersAndFacets;
