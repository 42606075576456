import { isBrowser } from 'Shared/device-type';
import * as localStorage from 'Shared/local-storage';
var LOCALSTORAGE_KEY = 'search_history';
var HISTORY_LENGTH = 30;
var searchHistory = deserialize();
export function find(searchWords) {
    searchWords = searchWords.filter(function (w) { return w.trim().length > 1; });
    if (!searchWords.length) {
        return searchHistory.slice();
    }
    return searchHistory.slice().filter(function (item) {
        return !!searchWords
            .filter(function (w) { return w.length > 1; })
            .find(function (searchWord) { return item.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1; });
    });
}
export function add(item) {
    item = item.trim();
    if (item === '' || searchHistory.find(function (s) { return s === item; })) {
        return;
    }
    searchHistory.unshift(item);
    if (searchHistory.length > HISTORY_LENGTH) {
        searchHistory.splice(HISTORY_LENGTH);
    }
    serialize();
}
function serialize() {
    if (!isBrowser()) {
        return;
    }
    localStorage.set(LOCALSTORAGE_KEY, searchHistory);
}
function deserialize() {
    if (!isBrowser()) {
        return [];
    }
    return (localStorage.get(LOCALSTORAGE_KEY) || []);
}
