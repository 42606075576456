import React from 'react';
import Button from 'Shared/Button';
import FeedbackButton, { MAXIMUM_FULFILLED } from 'Shared/Button/Feedback';
import { translate } from 'Shared/translate';
import { timeout } from 'Shared/timeout';
import * as styles from './base.scss';
export var DialogType;
(function (DialogType) {
    DialogType[DialogType["YesNo"] = 0] = "YesNo";
    DialogType[DialogType["OkCancel"] = 1] = "OkCancel";
    DialogType[DialogType["ChooseAbort"] = 2] = "ChooseAbort";
    DialogType[DialogType["OkOnly"] = 3] = "OkOnly";
    DialogType[DialogType["SaveAbort"] = 4] = "SaveAbort";
})(DialogType || (DialogType = {}));
export default function Dialog(props) {
    var translations = getTranslations(props);
    var waitMsForOnStatusWasFulfilled = props.waitMsForOnStatusWasFulfilled || MAXIMUM_FULFILLED;
    var onClickWithFeedback = function (activate) {
        var maybePromise = props.onConfirm();
        if (maybePromise instanceof Promise) {
            activate(maybePromise).then(function () {
                if (props.onStatusWasFulfilled) {
                    timeout(waitMsForOnStatusWasFulfilled).then(function () { return props.onStatusWasFulfilled(); });
                }
            });
        }
    };
    return (React.createElement("div", { className: styles.base },
        React.createElement("div", { className: styles.buttonWrap },
            React.createElement(FeedbackButton, { className: styles.button, appearance: "primary", disabled: props.disabled, onClickWithFeedback: function (e, activate) { return onClickWithFeedback(activate); } }, translations.confirm)),
        props.type !== DialogType.OkOnly && (React.createElement("div", { className: styles.buttonWrap },
            React.createElement(Button, { className: styles.button, appearance: "secondary", onClick: props.onCancel }, translations.cancel)))));
}
function getTranslations(props) {
    var _a;
    var dialogs = (_a = {},
        _a[DialogType.YesNo] = {
            confirm: translate('/Shared/Yes'),
            cancel: translate('/Shared/No'),
        },
        _a[DialogType.OkCancel] = {
            confirm: translate('/Shared/Ok'),
            cancel: translate('/Shared/Cancel'),
        },
        _a[DialogType.ChooseAbort] = {
            confirm: translate('/Shared/Choose'),
            cancel: translate('/Shared/Abort'),
        },
        _a[DialogType.OkOnly] = {
            confirm: translate('/Shared/Ok'),
            cancel: translate('/Shared/Cancel'),
        },
        _a[DialogType.SaveAbort] = {
            confirm: translate('/Shared/Save'),
            cancel: translate('/Shared/Cancel'),
        },
        _a);
    var dialog = dialogs[props.type];
    if (props.customAcceptText) {
        dialog.confirm = props.customAcceptText;
    }
    if (props.customDeclineText) {
        dialog.cancel = props.customDeclineText;
    }
    return dialogs[props.type];
}
