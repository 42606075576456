var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback, useMemo } from 'react';
import FeedbackButton from 'Shared/Button/Feedback';
import DropDownMenu, { DropDownItem, DropDownSeparator, DropDownWrapper } from 'Shared/DropDownMenu';
import { translate } from 'Shared/translate';
import CopyDialog from './CopyDialog';
import * as styles from '../base.scss';
import * as listStyles from '../../List/Table/base.scss';
function ActionbarRecommendations(props) {
    var _a = __read(useState(false), 2), isCopyModalOpen = _a[0], setCopyModalOpen = _a[1];
    var handleOpenCopyModal = useCallback(function () { return setCopyModalOpen(true); }, []);
    var handleCloseCopyModal = useCallback(function () { return setCopyModalOpen(false); }, []);
    var lists = useMemo(function () {
        return (props.currentPage.lists || []).filter(function (list) { return list.id !== props.listId; });
    }, [props.currentPage.lists, props.listId]);
    return (React.createElement("div", { className: listStyles.actionbar },
        React.createElement(DropDownMenu, { title: translate('/Account/ProductList/AddTo'), className: styles.actionMenu },
            React.createElement(DropDownItem, { value: "copyToNewList", text: props.isPantry
                    ? translate('/Account/ProductList/CopyToNewPantryList')
                    : translate('/Account/ProductList/CopyToNewFavoriteList'), onClick: handleOpenCopyModal, disabled: props.disabled }),
            lists && [
                React.createElement(DropDownSeparator, { key: "recommended_sep", text: props.isPantry
                        ? translate('/Account/ProductList/PantryLists')
                        : translate('/Account/ProductList/FavoriteLists') }),
                React.createElement(DropDownWrapper, { key: "recommended_wra", className: styles.wrapper }, lists.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return props.addRecommendations(list.id); }, disabled: props.disabled })); })),
            ]),
        React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(props.removeRecommendations()); }, disabled: props.disabled }, translate('/Account/ProductList/Remove')),
        React.createElement(CopyDialog, { isOpen: isCopyModalOpen, isPantry: props.isPantry, closeModal: handleCloseCopyModal, addRecommendationsToNewList: function (displayName) { return props.addRecommendationsToNewList(displayName); } })));
}
export default ActionbarRecommendations;
