import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import { TINY } from 'Shared/breakpoints';
import * as styles from './base.scss';
var ProductSearchHeader = function (_a) {
    var currentBreakpoint = _a.currentBreakpoint;
    return currentBreakpoint > TINY && (React.createElement("div", { className: styles.header },
        React.createElement("div", { className: styles.headerInner },
            React.createElement("div", { className: classnames(styles.cardColumn, styles.imageWrap) }),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.detailsWrap) }, translate('/ProductListing/Product')),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.typeAndUnitWrap) }, translate('/ProductListing/UnitAndType')),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.priceWrap) }, translate('/ProductListing/Price')),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.headerAvailabilityWrap) }, translate('/ProductListing/StockStatus')),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.addToListBtnWrap) }))));
};
export default ProductSearchHeader;
