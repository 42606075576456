var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import ViewObserver from './ViewObserver';
//https://markmurray.co/blog/view-tracking-with-react/
var ViewTracker = /** @class */ (function (_super) {
    __extends(ViewTracker, _super);
    function ViewTracker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Send the tracking event callback
         * @param  {Boolean} visible - the visibility state of the element
         */
        _this.trackEvent = function (visible) {
            _this.props.onChange(visible);
            if (visible) {
                _this.props.onView(visible);
                // Unobserve the element after firing
                if (_this.props.once)
                    _this.unobserve();
            }
            else {
                _this.props.onHide(visible);
            }
        };
        return _this;
    }
    /**
     * Create the event listener on mount
     */
    ViewTracker.prototype.componentDidMount = function () {
        this.observer = ViewObserver.get(this.props.options);
        this.container = findDOMNode(this);
        if (typeof this.container !== 'undefined') {
            // Observe the container and track all child nodes
            this.observer.observe(this.container, this.trackEvent);
        }
    };
    ViewTracker.prototype.unobserve = function () {
        if (typeof this.container !== 'undefined' && this.observer) {
            // Remove the observation listener
            this.observer.unobserve(this.container);
        }
        this.observer = null;
    };
    /**
     * Remove the observe listener on unmount
     */
    ViewTracker.prototype.componentWillUnmount = function () {
        this.unobserve();
    };
    ViewTracker.prototype.render = function () {
        // Use React.Children.only to indicate that their
        // should only be a single child component
        return React.Children.only(this.props.children);
    };
    ViewTracker.defaultProps = {
        once: true,
        children: null,
        options: null,
        onView: function () { },
        onHide: function () { },
        onChange: function () { },
    };
    return ViewTracker;
}(Component));
export default ViewTracker;
