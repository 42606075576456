import React from 'react';
import { searchPageUrl } from 'Shared/known-urls';
import { urlToString } from 'Shared/url';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './autocomplete.scss';
var SearchSuggestions = function (props) {
    var suggestions = props.lists.filter(function (x) { return x.id == 'suggestions'; })[0] &&
        !!props.lists.filter(function (x) { return x.id == 'suggestions'; })[0].items
        && props.lists.filter(function (x) { return x.id === 'suggestions'; })[0].items;
    return (React.createElement(React.Fragment, null, !props.thinSelection && !!suggestions && suggestions.length > 0 && (React.createElement("div", { className: styles.sectionLists },
        React.createElement("div", { className: classnames(styles.section, styles.searchSuggestionList), key: "Suggestions" },
            React.createElement("h4", null, translate('/QuickSearch/Suggestions')),
            React.createElement("ul", null, suggestions.map(function (item, j) {
                return (React.createElement("li", { key: item.text + j },
                    React.createElement("a", { className: styles.button, href: urlToString({ path: searchPageUrl(), query: { q: item.text } }), "data-ticket": item.ticket, onClick: function () { return props.onItemClick(item.text); } }, item.text)));
            })))))));
};
export default SearchSuggestions;
