var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import DropDownMenu, { DropDownItem, DropDownSeparator, DropDownWrapper } from 'Shared/DropDownMenu';
import { translate } from 'Shared/translate';
import MoveDialog from './MoveDialog';
import CopyDialog from './CopyDialog';
import ProductSearch from 'Shared/ProductSearch';
import PantryDialog from './PantryDialog';
import * as styles from '../base.scss';
import { copyToList, copyToOtherList, lockList, shareList, print } from 'Shared/ListItem/action-creators';
import DeleteDialog from './DeleteDialog';
function disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) {
    if (isMaster && !isDeleteDisabled) {
        return false;
    }
    else if (!isDeleteDisabled || showOnlyUnlockedAction) {
        return false;
    }
    else if (disabled || isDeleteDisabled) {
        return true;
    }
}
var Actionbar = /** @class */ (function (_super) {
    __extends(Actionbar, _super);
    function Actionbar(props) {
        var _this = _super.call(this, props) || this;
        _this.showCopyModal = function () {
            _this.setState({ isCopyModalOpen: true });
        };
        _this.hideCopyModal = function () {
            _this.setState({ isCopyModalOpen: false });
        };
        _this.showMoveModal = function () {
            _this.setState({ isMoveModalOpen: true });
        };
        _this.hideMoveModal = function () {
            _this.setState({ isMoveModalOpen: false });
        };
        _this.showDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: true });
        };
        _this.hideDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: false });
        };
        _this.showPantryModal = function () {
            _this.setState({ isPantryModalOpen: true });
        };
        _this.hidePantryModal = function () {
            _this.setState({ isPantryModalOpen: false });
        };
        _this.state = {
            isCopyModalOpen: false,
            isMoveModalOpen: false,
            isPantryModalOpen: false,
            isDeleteModalOpen: false,
        };
        return _this;
    }
    Actionbar.prototype.render = function () {
        var _a = this.props, isShared = _a.isShared, isLocked = _a.isLocked, isMaster = _a.isMaster, disabled = _a.disabled, isOpen = _a.isOpen, isActive = _a.isActive, listId = _a.listId, selectedItems = _a.selectedItems, searchQuery = _a.searchQuery, currentPage = _a.currentPage, sortBy = _a.sortBy, lockFavorites = _a.lockFavorites, shareFavorites = _a.shareFavorites, resetList = _a.resetList, print = _a.print, updateSearchPhrase = _a.updateSearchPhrase, copyToList = _a.copyToList, copyToOtherList = _a.copyToOtherList, save = _a.save;
        var favlists = (currentPage.lists || []).filter(function (list) { return list.id !== listId; });
        var pantryList = (currentPage.pantryLists || []).filter(function (list) { return list.id !== listId; });
        var activeList = this.props.currentPage.lists.find(function (i) { return i.id === listId; });
        var getCheckedItemsInList = function (list) {
            return list.products.filter(function (item) { return selectedItems.includes(item.selectedVariant.code); });
        };
        var checkedItemsInList = getCheckedItemsInList(activeList);
        var hasLockedItems = function (list) { return list.some(function (item) { return item.isLocked; }); };
        var hasUnlockedItems = function (list) { return list.some(function (item) { return !item.isLocked; }); };
        var isDeleteDisabled = checkedItemsInList.length === 0 || (!isMaster && !hasUnlockedItems(checkedItemsInList));
        var showOnlyUnlockedAction = !isMaster && hasLockedItems(checkedItemsInList) && hasUnlockedItems(checkedItemsInList);
        return (React.createElement("div", { className: styles.actionbar },
            React.createElement("div", { className: styles.actionbarInner },
                React.createElement("div", { className: styles.actionMenuWrap },
                    React.createElement(DropDownMenu, { title: translate('/Account/ProductList/AddTo'), className: styles.actionMenu },
                        React.createElement(DropDownItem, { value: "copyToNewList", text: translate('/Account/ProductList/CopyToNewFavoriteList'), onClick: this.showCopyModal, disabled: disabled }),
                        React.createElement(DropDownItem, { value: "copyToNewPantryList", text: translate('/Account/ProductList/CopyToNewPantryList'), onClick: this.showPantryModal, disabled: disabled }),
                        favlists && [
                            React.createElement(DropDownSeparator, { key: "fav_sep", text: translate('/Account/ProductList/FavoriteLists') }),
                            React.createElement(DropDownWrapper, { key: "fav_wra", className: styles.wrapper }, favlists.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return copyToList(listId, list.id, selectedItems); }, disabled: disabled })); })),
                        ],
                        pantryList && [
                            React.createElement(DropDownSeparator, { key: "pan_sep", text: translate('/Account/ProductList/PantryLists') }),
                            React.createElement(DropDownWrapper, { key: "pan_wra", className: styles.wrapper }, pantryList.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return copyToOtherList(listId, list.id, selectedItems); }, disabled: disabled })); })),
                        ]),
                    React.createElement(DropDownMenu, { title: translate('/Account/ProductList/Actions'), className: styles.actionMenu },
                        React.createElement(DropDownItem, { value: "removeProductsFromList", text: translate('/Account/ProductList/DeleteSelected'), onClick: this.showDeleteModal, disabled: disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) }),
                        React.createElement(DropDownItem, { value: "save", text: translate('/Account/ProductList/SaveChanges'), onClick: function () { return save(); } }),
                        React.createElement(DropDownItem, { value: "moveToList", text: translate('/Account/ProductList/MoveSelected'), onClick: this.showMoveModal, disabled: disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) }),
                        !isShared && isMaster && (React.createElement(DropDownItem, { value: "shareFavorites", text: translate('/Account/ProductList/ShareFavorites'), onClick: function () { return shareFavorites(listId); }, disabled: !isMaster })),
                        !isLocked && isMaster && isShared && (React.createElement(DropDownItem, { value: "lockPantry", text: translate('/Account/ProductList/LockFavorites'), onClick: function () { return lockFavorites(listId, true); }, disabled: !isMaster })),
                        isLocked && (React.createElement(DropDownItem, { value: "unlockPantry", text: translate('/Account/ProductList/UnLockFavorites'), onClick: function () { return lockFavorites(listId, false); }, disabled: !isMaster })),
                        isOpen && isActive && (React.createElement(DropDownItem, { value: "resetList", text: translate('/RecipeListing/ResetList'), onClick: function () { return resetList(); } })),
                        React.createElement(DropDownItem, { value: "print", text: translate('/Account/ProductList/Print'), onClick: function () { return print(listId, sortBy); } }))),
                React.createElement("div", { className: styles.searchInListWrap },
                    React.createElement("input", { className: styles.searchInList, id: "searchInList", placeholder: translate('/Account/ProductList/FilterInList'), defaultValue: searchQuery, onChange: function (e) { return updateSearchPhrase(e); } }))),
            React.createElement(MoveDialog, { isOpen: this.state.isMoveModalOpen, closeModal: this.hideMoveModal, listId: listId, codes: selectedItems, showOnlyUnlockedWillBeMoved: showOnlyUnlockedAction }),
            React.createElement(CopyDialog, { isOpen: this.state.isCopyModalOpen, closeModal: this.hideCopyModal, listId: listId, codes: selectedItems }),
            React.createElement(PantryDialog, { isOpen: this.state.isPantryModalOpen, closeModal: this.hidePantryModal, listId: listId, codes: selectedItems }),
            React.createElement(DeleteDialog, { isOpen: this.state.isDeleteModalOpen, closeModal: this.hideDeleteModal, listId: listId, codes: selectedItems, showOnlyUnlockedWillBeDeleted: showOnlyUnlockedAction, isMaster: isMaster }),
            (isMaster || !isLocked) && React.createElement(ProductSearch, { listId: listId, pantry: false })));
    };
    return Actionbar;
}(React.PureComponent));
export default connect(function (state) { return ({
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    copyToList: function (listId, toListId, code) {
        return dispatch(copyToList(listId, toListId, code));
    },
    copyToOtherList: function (listId, toListId, code) {
        return dispatch(copyToOtherList(listId, toListId, code));
    },
    lockFavorites: function (listId, locked) {
        return dispatch(lockList(listId, locked));
    },
    shareFavorites: function (listId) {
        return dispatch(shareList(listId));
    },
    print: function (listId, sortBy) {
        return dispatch(print(listId, sortBy));
    },
}); })(Actionbar);
