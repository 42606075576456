var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import NewListModal from './NewListModal';
function NewListButton(props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var text = props.isPantry
        ? translate('/Account/ProductList/NewPantry')
        : translate('/Account/ProductList/NewList');
    return (React.createElement("div", null,
        React.createElement(Button, { onClick: function () { return setOpen(true); } }, text),
        React.createElement(NewListModal, { isPantry: props.isPantry, isOpen: isOpen, handleClose: handleClose })));
}
export default NewListButton;
