import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import LoginContainer from 'Shared/Login/Container';
import ProductCampaignLabel from 'Shared/ProductCampaignLabel/ProductCampaignLabel';
import { CampaignType } from 'Shared/ProductCampaignLabel/ProductCampaignLabel';
import { getCampaignTypeFromId, getCampaignTypeNameFromId } from './Components/action-creators';
import * as styles from './base.scss';
import moment from 'moment';
var ProductCampaignMarks = function (_a) {
    var isSustainableChoice = _a.isSustainableChoice, productCampaigns = _a.productCampaigns, isOnProductPage = _a.isOnProductPage, isPrimary = _a.isPrimary;
    return (React.createElement(LoginContainer, null, function (_a) {
        var _b;
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn ? (React.createElement("div", { className: classnames(styles.campaignLabelWrapper, (_b = {},
                _b[styles.campaignLabelWrapperOnProductPage] = isOnProductPage,
                _b)) }, productCampaigns &&
            productCampaigns.map(function (data, i) { return (React.createElement(ProductCampaignLabel, { isPrimary: isPrimary, startDateDelivery: moment(data.campaignOrderDateFrom).format('YYYY-MM-DD'), endDateDelivery: moment(data.campaignOrderDateTo).format('YYYY-MM-DD'), startDate: moment(data.beginDate).format('YYYY-MM-DD'), endDate: moment(data.endDate).format('YYYY-MM-DD'), key: i, labelText: getCampaignTypeNameFromId(data.campaignTypeId), campaignType: getCampaignTypeFromId(data.campaignTypeId) })); }))) : (React.createElement("div", { className: styles.campaignLabelWrapper }, !!isSustainableChoice && !isPrimary && (React.createElement(ProductCampaignLabel, { labelText: translate('/Campaign/CampaignTypeNames/SustainableChoice'), campaignType: CampaignType.SustainableChoice }))));
    }));
};
export default ProductCampaignMarks;
