var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import { pushState } from 'Shared/history';
import classnames from 'classnames';
var ContentContainerContentCard = function (props) {
    var _a;
    var onButtonClick = function () {
        var url = !!props.linkUrl && props.linkUrl;
        !!url && pushState(url);
    };
    var itemOpts = { 'data-block-width': !!props.cardSize ? props.cardSize : '', };
    return (React.createElement("div", __assign({}, itemOpts, { className: classnames(styles.contentCard, (_a = {}, _a[styles.noImage] = !props.contentCardImage, _a[styles.onlyImage] = !props.title && !props.text, _a)) }),
        React.createElement("div", { className: classnames(styles.contentCardInner), onClick: function () { return onButtonClick(); } },
            !!props.contentCardImage && React.createElement("div", { className: styles.imageWrapper },
                React.createElement("img", { src: props.contentCardImage, alt: props.title })),
            !(!props.title && !props.text) &&
                React.createElement("div", { className: styles.contentWrapper },
                    !!props.title && React.createElement("h2", { className: styles.inspiration }, props.title),
                    !!props.text && React.createElement("p", { className: styles.contentCardText }, props.text)))));
};
export default ContentContainerContentCard;
