var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { replaceState } from 'Shared/history';
import { currentUrl } from 'Shared/url';
import { addToCart } from './action-creators';
import { toggleMiniCart } from 'SiteLayout/MiniCart/action-creators';
import { toNumber } from './action-creators';
var queryParam = 'ScriptIntoCart';
export default (function (store) {
    var url = currentUrl();
    var queryValue = url.query[queryParam];
    if (queryValue && typeof queryValue === 'string') {
        var add = queryValue.split('+').map(function (value) {
            value = value.trim();
            if (!value.includes('*')) {
                value = value + '*1*'; // default quantity and empty unit
            }
            var _a = __read(value.split('*'), 3), code = _a[0], quantity = _a[1], unitShortName = _a[2];
            return {
                code: code,
                quantity: toNumber(quantity) || 1,
                unitShortName: unitShortName,
            };
        });
        Promise.all(add.map(function (add) { return store.dispatch(addToCart(add.code, add.quantity, add.unitShortName, null, 'AddToCartFromLink')); })).then(function () { return store.dispatch(toggleMiniCart()); });
        // `replaceState` because if the user refreshes the page we don't want to
        // add the item again
        delete url.query[queryParam];
        replaceState(url);
    }
});
