var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './report.scss';
var PantryReportInvent = /** @class */ (function (_super) {
    __extends(PantryReportInvent, _super);
    function PantryReportInvent(props) {
        return _super.call(this, props) || this;
    }
    PantryReportInvent.prototype.componentDidMount = function () {
        window.print();
    };
    PantryReportInvent.prototype.render = function () {
        var report = this.props.report;
        return (React.createElement("div", { className: styles.reportOverview },
            React.createElement("div", null,
                React.createElement("h3", null, translate('/Account/ProductList/PrintOptions/Table/InventSheet'))),
            report.groupedByPantries.map(function (pantry, j) {
                return (React.createElement("div", { key: 'printPantries' + j, className: styles.pageBreak },
                    React.createElement("div", { className: styles.infoHeader },
                        React.createElement("span", null,
                            translate('/Account/ProductList/PrintOptions/Table/Pantry'),
                            ":"),
                        React.createElement("span", null,
                            pantry.displayName,
                            pantry.missingPrices && ' *')),
                    React.createElement("table", null,
                        React.createElement("thead", null,
                            React.createElement("tr", { className: styles.headerRow },
                                React.createElement("th", { style: { width: '300px' } }, translate('/Account/ProductList/PrintOptions/Table/Name')),
                                React.createElement("th", { style: { width: '170px' } }, translate('/Account/ProductList/PrintOptions/Table/Information')),
                                React.createElement("th", { style: { width: '80px' } }, translate('/Account/ProductList/PrintOptions/Table/OrderNo')),
                                React.createElement("th", { style: { width: '100px' } }, translate('/Account/ProductList/PrintOptions/Table/Trademark')),
                                React.createElement("th", { style: { width: '70px' } }, translate('/Account/ProductList/PrintOptions/Table/Count')),
                                React.createElement("th", { style: { width: '70px' } }, translate('/Account/ProductList/PrintOptions/Table/Unit')),
                                React.createElement("th", { style: { width: '80px' }, className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/Price')))),
                        React.createElement("tbody", null,
                            pantry.items.map(function (item, i) { return (React.createElement("tr", { key: 'printBody' + i, className: styles.itemSeparator },
                                React.createElement("td", null,
                                    item.displayName,
                                    item.missingPrice && ' **'),
                                React.createElement("td", null, item.information),
                                React.createElement("td", null, item.productId),
                                React.createElement("td", null, item.trademark),
                                React.createElement("td", null),
                                React.createElement("td", null, item.unit),
                                React.createElement("td", { className: styles.right }, item.currentPrice.toFixed(2)))); }),
                            pantry.missingPrices && (React.createElement("tr", { className: styles.missingPrice },
                                React.createElement("td", { colSpan: 7 },
                                    "* ",
                                    translate('/Account/ProductList/PrintOptions/Table/MissingPricePantry'),
                                    React.createElement("br", null),
                                    "** ",
                                    translate('/Account/ProductList/PrintOptions/Table/MissingPrice'))))))));
            })));
    };
    return PantryReportInvent;
}(React.Component));
export default PantryReportInvent;
