var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Button from 'Shared/Button';
import { MEDIUM } from 'Shared/breakpoints';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import AutoComplete from '../Shared/AutoComplete';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { postJson } from 'Shared/server';
import moment from 'moment';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import Icon from 'Shared/Icon';
import { pathCombine } from 'Shared/url';
import Modal from 'Shared/Modal';
import { DayPickerSingleDateController } from 'react-dates';
import * as styles from './base.scss';
import Main, { Section } from 'Shared/PageLayout';
import { Input } from 'Shared/Fields';
var LogfileOverviewPage = /** @class */ (function (_super) {
    __extends(LogfileOverviewPage, _super);
    function LogfileOverviewPage(props) {
        var _this = _super.call(this, props) || this;
        _this.onFocusChange = function (args) {
            _this.setState({ isDayPickerFocused: !!args.focused });
        };
        _this.hideDateModal = function () {
            _this.setState({ isDateModalOpen: false });
        };
        _this.state = {
            orderFrom: _this.props.orderFrom || '',
            orderTo: _this.props.orderTo || '',
            deliveryFrom: _this.props.deliveryFrom || '',
            deliveryTo: _this.props.deliveryTo || '',
            autoCompleteCustomerList: [],
            customer: '',
            isDayPickerFocused: false,
            isDateModalOpen: false,
            currentDateField: 'orderFrom',
        };
        return _this;
    }
    LogfileOverviewPage.prototype.onGetLog = function () {
        window.open(pathCombine(location.pathname, '/getfile') + "?customer=" + this.state.customer + "\n      &orderFrom=" + this.state.orderFrom + "&orderTo=" + this.state.orderTo + "\n      &deliveryFrom=" + this.state.deliveryFrom + "&deliveryTo=" + this.state.deliveryTo);
    };
    LogfileOverviewPage.prototype.onCustomerSearch = function (query) {
        var _this = this;
        return postJson(pathCombine(location.pathname, '/customersearch'), { query: query })
            .then(function (result) {
            if (result.success) {
                _this.setState({ autoCompleteCustomerList: result.value });
                return Promise.resolve();
            }
            else {
                return Promise.reject(result.message);
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    LogfileOverviewPage.prototype.onCustomerChange = function (e) {
        this.setState({
            customer: e.target.value,
        });
    };
    LogfileOverviewPage.prototype.onDateChangeClick = function (day, datefield) {
        var _a;
        this.setState((_a = {}, _a[datefield] = day.format('YYYY-MM-DD'), _a));
        this.hideDateModal();
    };
    LogfileOverviewPage.prototype.onDateChange = function (e, datefield) {
        var _a;
        this.setState((_a = {},
            _a[datefield] = e.target.value,
            _a));
    };
    LogfileOverviewPage.prototype.showDateModal = function (datefield) {
        this.setState({ isDateModalOpen: true, currentDateField: datefield });
    };
    LogfileOverviewPage.prototype.onChooseCustomer = function (customer) {
        this.setState({
            customer: customer,
        });
    };
    LogfileOverviewPage.prototype.getDateOfCurrentDateField = function () {
        var date = moment(this.state[this.state.currentDateField]);
        return date.isValid() ? date : moment();
    };
    LogfileOverviewPage.prototype.renderHeader = function () {
        return (React.createElement("div", { className: styles.headers },
            React.createElement("div", { className: styles.customerColumn }, translate('/Admin/LogFile/CustomerNo')),
            React.createElement("div", { className: styles.dateColumn }, translate('/Admin/LogFile/OrderFrom')),
            React.createElement("div", { className: styles.dateColumn }, translate('/Admin/LogFile/OrderTo')),
            React.createElement("div", { className: styles.dateColumn }, translate('/Admin/LogFile/DeliveryFrom')),
            React.createElement("div", { className: styles.dateColumn }, translate('/Admin/LogFile/DeliveryTo')),
            React.createElement("div", { className: styles.dateColumn })));
    };
    LogfileOverviewPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", null, translate('/Admin/LogFile/Title'))),
            React.createElement(Section, null,
                React.createElement(Section, null,
                    this.renderHeader(),
                    React.createElement("div", { className: !this.props.isMobile ? styles.formWrapper : styles.formWrapperMobile },
                        React.createElement("div", { className: styles.customerColumn },
                            React.createElement(AutoComplete, { onBlur: function () { return _this.setState({ autoCompleteCustomerList: [] }); }, list: this.state.autoCompleteCustomerList, onAdd: function (customerId) { return _this.onChooseCustomer(customerId); }, onChange: function (query) { return _this.onCustomerSearch(query); } }),
                            React.createElement(Input, { className: styles.customerValue, value: this.state.customer, onChange: function (e) { return _this.onCustomerChange(e); } })),
                        React.createElement("div", { className: styles.dateColumn },
                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'orderFrom'); }, value: this.state.orderFrom }),
                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('orderFrom'); } },
                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                        React.createElement("div", { className: styles.dateColumn },
                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'orderTo'); }, value: this.state.orderTo }),
                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('orderTo'); } },
                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                        React.createElement("div", { className: styles.dateColumn },
                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'deliveryFrom'); }, value: this.state.deliveryFrom }),
                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('deliveryFrom'); } },
                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                        React.createElement("div", { className: styles.dateColumn },
                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'deliveryTo'); }, value: this.state.deliveryTo }),
                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('deliveryTo'); } },
                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                        React.createElement("div", { className: styles.dateColumn },
                            React.createElement(Button, { onClick: function () { return _this.onGetLog(); }, appearance: "primary" }, translate('/Admin/LogFile/GetLog')))),
                    React.createElement(Modal, { isOpen: this.state.isDateModalOpen, onClose: this.hideDateModal, title: translate('/Admin/Exhibition/ChooseDate') },
                        React.createElement(DayPickerSingleDateController, { date: this.getDateOfCurrentDateField(), onDateChange: function (day) { return _this.onDateChangeClick(day, _this.state.currentDateField); }, onFocusChange: this.onFocusChange, focused: this.state.isDayPickerFocused, enableOutsideDays: false }))))));
    };
    return LogfileOverviewPage;
}(React.Component));
export default connect(function (state) { return ({
    isMobile: state.currentBreakpoint < MEDIUM,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(LogfileOverviewPage);
