var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
import ClassificationGroup from '../Classification/ClassificationGroup';
import Button from 'Shared/Button';
import classnames from 'classnames';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { scrollPosition } from 'SiteLayout/Root';
var ClassificationBlock = function (_a) {
    var productViewModel = _a.productViewModel, selectedVariant = _a.selectedVariant, isProductPage = _a.isProductPage;
    var _b = __read(useState(false), 2), isOpen = _b[0], setOpen = _b[1];
    var ButtonClick = function () {
        setOpen(!isOpen);
        if (isOpen) {
            var headerElement = document.getElementById('mainHeader');
            var element = document.getElementById('ProductClassification');
            var offset = !!element ? element.offsetTop : 0;
            scrollPosition(offset - headerElement.offsetHeight);
        }
    };
    var showEmpty = false;
    var list = isProductPage && productViewModel.productInformationList ? productViewModel.productInformationList.map(function (x) { return (__assign({}, x, { items: x.items.filter(function (x) { return !["Artikelnummer", "Märke", "Förpackning"].includes(x.label); }) })); }) : productViewModel.productInformationList;
    var showButton = showEmpty
        ? !!list && list.length > 1
        : !!list && list.filter(function (x) { return x.items.filter(function (x) { return !!x.valueAsString && !!x.isVisible; }).length; }).length > 1;
    var buttonText = isOpen
        ? translate('/Product/ProductClassification/ShowLess')
        : translate('/Product/ProductClassification/ShowMore');
    var classificationList = (React.createElement("div", { id: "ProductClassification", className: classnames(styles.productClassification__Box, !!isOpen && styles.isOpen) },
        React.createElement("ul", { className: styles.productClassification__List }, !!list &&
            list
                .slice(0, isOpen ? list.length : 1)
                .map(function (itemgroup, index) { return (React.createElement(ClassificationGroup, { key: 'group_' + index, showEmpty: showEmpty, shouldRender: showEmpty
                    ? !!itemgroup.items && itemgroup.items.filter(function (x) { return !!x.isVisible; }).length > 0
                    : !!itemgroup.items.filter(function (x) { return !!x.valueAsString && !!x.isVisible; }).length, title: itemgroup.header, items: itemgroup.items })); })),
        showButton && (React.createElement(Button, { className: styles.showMoreLessButton, onClick: ButtonClick, appearance: 'bare' },
            buttonText,
            " ",
            React.createElement(MyMenigoIcon, { name: isOpen ? 'dropdownArrowUp' : 'dropdownArrowDown' })))));
    return React.createElement(React.Fragment, null, classificationList);
};
export default ClassificationBlock;
