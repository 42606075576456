import SearchPage from './SearchPage';
import resolveComponent from 'Shared/resolve-component';
export var isSearchPage = function (currentPage) {
    return resolveComponent(currentPage.componentName) === SearchPage;
};
export default (function (state) {
    return componentIsSearch(state.currentPage.componentName);
});
export function componentIsSearch(componentName) {
    return resolveComponent(componentName) === SearchPage;
}
