var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Main, { Part, Section } from 'Shared/PageLayout';
import { updatePortions, setGrade, removeRecipe, shareRecipe, searchUsers } from './../action-creators';
import RecipeItem from './../RecipeItem';
import Breadcrumbs from 'Shared/Breadcrumbs';
import connect from 'Shared/connect';
import { SMALL } from 'Shared/breakpoints';
import Image from 'Shared/ImageFallback';
import NutritionFacts from 'Shared/NutritionFact/NutritionFacts';
import Grade from './../Grade';
import EnergyChart from './../EnergyChart';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import * as styles from './page.scss';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import classnames from 'classnames';
import CreateMenu from './../CreateMenu';
import Calculation from './../Calculation';
import { pushState } from 'Shared/history';
import { recipeOverviewPageUrl } from 'Shared/known-urls';
import { MasterFor } from 'Shared/MasterFor';
import AddRecipeToCart from '../AddRecipeToCart';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { timeout } from 'Shared/timeout';
import Spinner from 'Shared/Spinner';
import { ENTER } from 'Shared/keyboard';
import { Input } from 'Shared/Fields';
var debounceMs = 500;
var RecipePage = /** @class */ (function (_super) {
    __extends(RecipePage, _super);
    function RecipePage(props) {
        var _this = _super.call(this, props) || this;
        _this.showShareModal = function () {
            _this.setState({ isShareModalOpen: true });
        };
        _this.hideModal = function () {
            _this.setState({ isShareModalOpen: false });
        };
        _this.addCustomerToShareList = function (evnt, e) {
            if (evnt.keyCode == ENTER) {
                if (e.length > 2) {
                    var user = { name: "", customerId: e, id: "", customerQuotationId: "", userId: "" };
                    _this.selectThisUser({
                        name: user.name,
                        id: user.id,
                        customerId: user.customerId,
                        customerQuotationId: user.customerQuotationId,
                        userId: user.userId,
                    });
                }
            }
        };
        _this.searchforUsers = function (e) {
            clearTimeout(_this.currentTimeout);
            if (e.length > 2) {
                _this.currentTimeout = setTimeout(function () {
                    _this.setState({ searchingForUsers: true });
                    _this.props.searchUsers(e).then(function (result) {
                        var slicedArr = result.slice(0, 5);
                        _this.setState({ shareWith: slicedArr, searchingForUsers: false });
                    });
                }, 300);
            }
        };
        _this.onSharedSuccessful = function () {
            _this.setState({ isShareModalOpen: false });
            timeout(300).then(function () {
                _this.setState({ shareWithAdd: null });
            });
        };
        _this.shareRecipe = function () {
            var recipe = {
                recipeName: _this.props.name,
                recipeId: _this.props.id,
                users: _this.state.shareWithAdd.map(function (x) { return x.customerId; }),
            };
            return _this.props
                .shareRecipe(recipe)
                .then(function (r) {
                if (!!r.success && !!r.error) {
                    _this.props.setFeedback({
                        text: translate('/RecipeListing/ShareOK') + r.message + ". " + (translate('/RecipeListing/ShareFailed') + r.error),
                        type: 'warning',
                    });
                }
                else {
                    _this.props.setFeedback({
                        text: r.success ? translate('/RecipeListing/ShareOK') + r.message : translate('/RecipeListing/ShareFailed') + r.error,
                        type: r.success ? 'success' : 'error',
                    });
                }
                return r.success ? Promise.resolve(r) : Promise.reject(null);
            })
                .catch(function (e) { return Promise.reject(); });
        };
        _this.state = {
            numberOfPortions: !!_this.props.originalNumberOfPortions ? _this.props.originalNumberOfPortions.toString() : '',
            portions: !!_this.props.numberOfPortions ? _this.props.numberOfPortions : 10,
            isPdf: false,
            openModal: false,
            shareWith: null,
            shareWithAdd: null,
            searchingForUsers: false,
            isShareModalOpen: false,
        };
        return _this;
    }
    RecipePage.prototype.updatePortions = function (portion) {
        var _this = this;
        this.setState({ numberOfPortions: portion });
        clearTimeout(this.currentTimeout);
        var parsedValue = parseInt(portion, 10);
        if (!!parsedValue && parsedValue > 0) {
            this.setState({ portions: parsedValue });
            this.currentTimeout = setTimeout(function () { return _this.props.updatePortions(parsedValue); }, debounceMs);
        }
        return Promise.resolve();
    };
    RecipePage.prototype.toRaw = function (htmlStr) {
        return { __html: htmlStr };
    };
    RecipePage.prototype.onPdfPreview = function () {
        this.setState({ isPdf: !this.state.isPdf ? true : false });
    };
    RecipePage.prototype.pushState = function () {
        pushState(recipeOverviewPageUrl());
    };
    RecipePage.prototype.redirectLink = function (url) {
        window.location.href = url || '/';
    };
    RecipePage.prototype.printSave = function () {
        window.print();
    };
    RecipePage.prototype.onConfirmRemoveRecipe = function () {
        return this.props.removeRecipe(this.props.id);
    };
    RecipePage.prototype.removeThisUser = function (user) {
        var shareWithAdd = Object.assign([], this.state.shareWithAdd);
        var indexToRemove = 0;
        for (var u in this.state.shareWithAdd) {
            if (this.state.shareWithAdd[u].customerId === user.customerId) {
                indexToRemove = Number(u);
                break;
            }
        }
        shareWithAdd.splice(indexToRemove, 1);
        this.setState({ shareWithAdd: shareWithAdd });
    };
    RecipePage.prototype.selectThisUser = function (user) {
        var shareWithAdd = Object.assign([], this.state.shareWithAdd);
        shareWithAdd.push(user);
        this.setState({ shareWith: null, shareWithAdd: shareWithAdd });
    };
    RecipePage.prototype.render = function () {
        var _this = this;
        var isDesktop = this.props.currentBreakpoint > SMALL;
        var breadcrumbs = React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs || [] });
        var imageNode = (React.createElement(Image, { preferSmallerIfCached: true, minSizeIfCached: "tiny", skipSrcSet: true, src: this.props.imageUrl, alt: this.props.name, size: "medium" }));
        var gradeNode = !this.state.isPdf && (React.createElement(Grade, { grade: this.props.grade, votes: this.props.voteCount, uservoted: this.props.userVoted, onClick: this.props.setGradeProp }));
        var mealDescriptions = [];
        var mealItems = [];
        var allMeals = this.props.allMealRecipeModels;
        if (!!allMeals) {
            this.props.allMealRecipeModels.map(function (mealRecipe, i) {
                mealItems.push(React.createElement("tr", { key: i + mealRecipe.name },
                    React.createElement("th", { colSpan: 2 },
                        React.createElement("h4", null, mealRecipe.name))));
                mealRecipe.recipeItemModels.forEach(function (item) {
                    return mealItems.push(React.createElement(RecipeItem, __assign({ key: i + item.id }, item)));
                });
                mealDescriptions.push(React.createElement("h4", { key: i },
                    React.createElement("a", { href: mealRecipe.url }, mealRecipe.name)));
                mealDescriptions.push(React.createElement("div", { key: 'd' + i, className: styles.text, dangerouslySetInnerHTML: _this.toRaw(mealRecipe.description) }));
            });
        }
        return isDesktop ? (React.createElement(Main, { appearance: "narrow", className: classnames(styles.visibleCalc) },
            React.createElement(Section, { className: styles.hideInPrint },
                breadcrumbs,
                React.createElement("div", { className: styles.buttonSection },
                    React.createElement(DropDownMenu, { title: translate('/Account/ProductList/Actions'), className: styles.actionMenu },
                        !!this.props.isLoggedIn &&
                            !!this.props.links &&
                            this.props.links.map(function (link, i) {
                                return (React.createElement(DropDownItem, { key: "ddi_" + i, value: i.toString(), text: link.text, onClick: function () { return _this.redirectLink(link.url); }, disabled: false }));
                            }),
                        !!this.props.isLoggedIn && this.props.isOwner && (React.createElement(DropDownItem, { value: translate('/Shared/Delete'), text: translate('/Shared/Delete'), onClick: function () { return _this.setState({ openModal: true }); }, disabled: false })),
                        React.createElement(DropDownItem, { value: translate('/Shared/Share'), text: translate('/Shared/Share'), onClick: function () { return _this.showShareModal(); }, disabled: false }),
                        React.createElement(DropDownItem, { value: translate('/Shared/Print'), text: translate('/Shared/Print'), onClick: function () { return _this.printSave(); }, disabled: false }),
                        React.createElement(DropDownItem, { value: !this.state.isPdf ? translate('/Recipe/PdfPreview') : translate('/Recipe/NormalView'), text: !this.state.isPdf ? translate('/Recipe/PdfPreview') : translate('/Recipe/NormalView'), onClick: function () { return _this.onPdfPreview(); }, disabled: false })),
                    React.createElement(Button, { appearance: 'secondary', onClick: function () { return pushState(recipeOverviewPageUrl()); } }, translate('/Shared/Back')),
                    React.createElement(MasterFor, { className: styles.masterList, isMaster: this.props.isMaster, slaves: this.props.slaves }),
                    React.createElement(Modal, { title: translate('/RecipeListing/HeaderConfirmRemoveRecipe'), isOpen: this.state.openModal, onClose: function () { return _this.setState({ openModal: false }); }, type: ModalType.Warning },
                        React.createElement("span", null, translate('/RecipeListing/ConfirmRemoveRecipe', this.props.name, this.props.name)),
                        React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: function () { return _this.onConfirmRemoveRecipe(); }, onCancel: function () { return _this.setState({ openModal: false }); }, onStatusWasFulfilled: function () { return _this.setState({ openModal: false }); } })),
                    React.createElement(Modal, { title: translate('/RecipeListing/ShareWithModalTitle'), isOpen: this.state.isShareModalOpen, onClose: this.hideModal, reserveSpaceForCloseButton: true },
                        React.createElement("div", { className: styles.inputSearchUserWrapper },
                            React.createElement(Input, { label: translate('/RecipeListing/AddUsers') + ":", type: "text", className: styles.inputSearch, pattern: ".{3,}", title: "3 characters minimum", onKeyUp: function (e) { return _this.addCustomerToShareList(e, e.target.value); } }),
                            !!this.state.searchingForUsers && (React.createElement("div", { className: styles.spinnerWrapperSearchUser },
                                React.createElement(Spinner, null))),
                            this.state.shareWithAdd && this.state.shareWithAdd.length > 0 && (React.createElement("div", null,
                                React.createElement("br", null),
                                React.createElement("b", null, translate('/RecipeListing/ShareWith')),
                                React.createElement("ul", { className: styles.shareWithAddedUsers }, this.state.shareWithAdd.map(function (user, i) {
                                    return (React.createElement("li", { key: i },
                                        React.createElement("span", null, user.customerId),
                                        React.createElement(Button, { appearance: "clear", onClick: function () { return _this.removeThisUser(user); } },
                                            React.createElement(Icon, { name: "close" }))));
                                }))))),
                        !!this.state.shareWithAdd && this.state.shareWithAdd.length > 0 && (React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.shareRecipe, onCancel: this.hideModal, onStatusWasFulfilled: this.onSharedSuccessful }))))),
            React.createElement(Section, { layout: "2to5", appearance: "narrow", className: styles.printSection },
                React.createElement(Part, { className: styles.leadParagraph },
                    React.createElement("h1", null,
                        this.props.isLoggedIn && (!this.props.isMenigo && this.props.isPublic) && (React.createElement("span", { className: styles.share },
                            React.createElement(Icon, { name: "md-share" }))),
                        this.props.name),
                    !this.state.isPdf && (!!this.props.group && (React.createElement("span", { className: styles.group },
                        this.props.recipeType === 'meal' ? translate('/Meal/Group') : translate('/Recipe/Group'),
                        ":",
                        ' ',
                        this.props.group.value,
                        ' '))),
                    React.createElement(Part, { className: styles.text }, this.props.leadParagraph),
                    React.createElement(Part, { layout: "1to1" },
                        gradeNode,
                        React.createElement("div", { className: styles.classifications }, !!this.props.classifications &&
                            Object.keys(this.props.classifications).map(function (key) {
                                return _this.props.classifications[key].selected &&
                                    (!!_this.props.classifications[key].imageUrl ? (React.createElement("div", { key: key },
                                        React.createElement(Image, { src: _this.props.classifications[key].imageUrl, size: "tiny" }))) : (React.createElement("span", { key: key, className: styles.markText },
                                        ' ',
                                        _this.props.classifications[key].value,
                                        ' ')));
                            })))),
                React.createElement(Part, { className: styles.leftSection }, imageNode)),
            React.createElement(Section, { layout: "2to5", appearance: "narrow", className: classnames(styles.borderTop, styles.printSection) },
                !this.state.isPdf && (React.createElement(Section, { className: styles.description },
                    React.createElement("h2", null, translate('/Recipe/Instructions')),
                    this.props.recipeType === 'recipe' && !!this.props.description && (React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: this.toRaw(this.props.description) })),
                    mealDescriptions,
                    this.props.recipeType === 'meal' && !!this.props.recipeItemModels && !!this.props.description && (React.createElement(Part, null,
                        React.createElement("h4", null, translate('/Recipe/Trimmings')),
                        React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: this.toRaw(this.props.description) }))))),
                React.createElement(Part, { className: styles.leftSection },
                    React.createElement("h2", null, translate('/Recipe/Ingredients')),
                    React.createElement(Part, null,
                        React.createElement("label", { className: classnames(styles.text) },
                            translate('/Recipe/Portions'),
                            ": "),
                        React.createElement(Input, { type: "number", max: "9999", onChange: function (e) { return _this.updatePortions(e.target.value); }, value: this.state.numberOfPortions, ref: function (e) { return (_this.portionField = e); }, className: styles.portionInput, style: { width: '160px' } }),
                        !!this.props.recipeItemModels && (React.createElement("table", { className: classnames(styles.items, styles.text) },
                            React.createElement("tbody", null,
                                this.props.recipeType === 'recipe' &&
                                    this.props.recipeItemModels.map(function (item) { return (React.createElement(RecipeItem, __assign({ key: item.id }, item))); }),
                                mealItems,
                                this.props.recipeType === 'meal' && !!this.props.recipeItemModels && (React.createElement("tr", null,
                                    React.createElement("th", { colSpan: 2 },
                                        React.createElement("h4", null, translate('/Recipe/Trimmings'))))),
                                this.props.recipeType === 'meal' &&
                                    this.props.recipeItemModels.map(function (item) { return (React.createElement(RecipeItem, __assign({ key: item.id }, item))); }))))),
                    !!this.props.isLoggedIn && (React.createElement("div", { className: styles.actionsWrapper },
                        React.createElement(AddRecipeToCart, { portions: this.state.portions }),
                        React.createElement(CreateMenu, { id: this.props.id, name: this.props.name }))))),
            !!this.props.nutrients && this.props.nutrients.length > 0 && (React.createElement(Section, { layout: "2to5", appearance: "narrow", className: classnames(styles.borderTop, styles.printSection) },
                React.createElement(Part, { className: styles.rightSection },
                    React.createElement("h2", null, translate('/Recipe/Energy')),
                    React.createElement(EnergyChart, { data: this.props.nutrients }),
                    !!this.props.portionCostIncomplete && React.createElement("div", null,
                        "** ",
                        translate('/Recipe/PriceIncomplete')),
                    !!this.props.recipeNutritionIncomplete && React.createElement("div", null,
                        "* ",
                        translate('/Recipe/NutrientsIncomplete'))),
                React.createElement(Part, { className: styles.leftSection },
                    React.createElement("h2", null, translate('/Product/Declaration')),
                    React.createElement(NutritionFacts, { nutritionInformationProvider: null, tableData: this.props.nutrients })))),
            React.createElement(Calculation, __assign({ onUpdateCalculation: null, recipeId: this.props.id, portionCost: this.props.portionCost }, this.props.calculation)))) : (React.createElement(Main, null,
            React.createElement("div", { className: styles.mobileTopNode },
                breadcrumbs,
                React.createElement("div", { className: styles.mobileButtonSection },
                    React.createElement(DropDownMenu, { title: translate('/Account/ProductList/Actions'), className: styles.actionMenu },
                        !!this.props.isLoggedIn &&
                            !!this.props.links &&
                            this.props.links.map(function (link, i) {
                                return (React.createElement(DropDownItem, { value: i.toString(), text: link.text, onClick: function () { return _this.redirectLink(link.url); }, disabled: false }));
                            }),
                        !!this.props.isLoggedIn && this.props.isOwner && (React.createElement(DropDownItem, { value: translate('/Shared/Delete'), text: translate('/Shared/Delete'), onClick: function () { return _this.setState({ openModal: true }); }, disabled: false })),
                        React.createElement(DropDownItem, { value: translate('/Shared/Share'), text: translate('/Shared/Share'), onClick: function () { return _this.showShareModal(); }, disabled: false }),
                        React.createElement(DropDownItem, { value: translate('/Shared/Print'), text: translate('/Shared/Print'), onClick: function () { return _this.printSave(); }, disabled: false }),
                        React.createElement(DropDownItem, { value: !this.state.isPdf ? translate('/Recipe/PdfPreview') : translate('/Recipe/NormalView'), text: !this.state.isPdf ? translate('/Recipe/PdfPreview') : translate('/Recipe/NormalView'), onClick: function () { return _this.onPdfPreview(); }, disabled: false })),
                    React.createElement(Button, { appearance: 'secondary', onClick: function () { return pushState(recipeOverviewPageUrl()); } }, translate('/Shared/Back')),
                    React.createElement(MasterFor, { className: styles.masterList, isMaster: this.props.isMaster, slaves: this.props.slaves }),
                    React.createElement(Modal, { title: translate('/RecipeListing/HeaderConfirmRemoveRecipe'), isOpen: this.state.openModal, onClose: function () { return _this.setState({ openModal: false }); }, type: ModalType.Warning },
                        React.createElement("span", null, translate('/RecipeListing/ConfirmRemoveRecipe', this.props.name, this.props.name)),
                        React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: function () { return _this.onConfirmRemoveRecipe(); }, onCancel: function () { return _this.setState({ openModal: false }); }, onStatusWasFulfilled: function () { return _this.setState({ openModal: false }); } })),
                    React.createElement(Modal, { title: translate('/RecipeListing/SearchUsers'), isOpen: this.state.isShareModalOpen, onClose: this.hideModal, reserveSpaceForCloseButton: true },
                        React.createElement("div", { className: styles.inputSearchUserWrapper },
                            React.createElement(Input, { label: translate('/RecipeListing/SearchUsers') + ":", type: "text", className: styles.inputSearch, pattern: ".{3,}", title: "3 characters minimum", onChange: function (e) { return _this.searchforUsers(e.target.value); } }),
                            this.state.shareWith && (React.createElement("ul", { className: styles.shareWithUsers }, this.state.shareWith.map(function (user, i) {
                                return (React.createElement("li", { key: i, onClick: function () {
                                        return _this.selectThisUser({
                                            name: user.name,
                                            id: user.id,
                                            customerId: user.customerId,
                                            customerQuotationId: user.customerQuotationId,
                                            userId: user.userId,
                                        });
                                    } },
                                    user.name,
                                    ", ",
                                    user.customerId));
                            }))),
                            !!this.state.shareWith && !this.state.searchingForUsers && this.state.shareWith.length === 0 && (React.createElement("p", null, translate('/RecipeListing/NoSearchResults'))),
                            !!this.state.searchingForUsers && (React.createElement("div", { className: styles.spinnerWrapperSearchUser },
                                React.createElement(Spinner, null))),
                            this.state.shareWithAdd && (React.createElement("div", null,
                                React.createElement("br", null),
                                React.createElement("b", null, translate('/RecipeListing/ShareWith')),
                                React.createElement("ul", { className: styles.shareWithAddedUsers }, this.state.shareWithAdd.map(function (user, i) {
                                    return (React.createElement("li", { key: i },
                                        React.createElement("span", null, user.name),
                                        ", ",
                                        React.createElement("span", null, user.customerId),
                                        React.createElement(Button, { appearance: "clear", onClick: function () { return _this.removeThisUser(user); } },
                                            React.createElement(Icon, { name: "close" }))));
                                }))))),
                        !!this.state.shareWithAdd && this.state.shareWithAdd.length > 0 && (React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.shareRecipe, onCancel: this.hideModal, onStatusWasFulfilled: this.onSharedSuccessful })))),
                React.createElement("h1", null, this.props.name),
                !!this.props.group && (React.createElement("span", { className: styles.group },
                    this.props.recipeType === 'meal' ? translate('/Meal/Group') : translate('/Recipe/Group'),
                    ":",
                    ' ',
                    this.props.group.value,
                    ' ')),
                imageNode,
                React.createElement("div", { className: styles.leadParagraph },
                    React.createElement("div", { className: styles.text }, this.props.leadParagraph),
                    React.createElement("div", { className: styles.half },
                        gradeNode,
                        React.createElement("div", { className: classnames(styles.classifications) }, !!this.props.classifications &&
                            Object.keys(this.props.classifications).map(function (key) {
                                return _this.props.classifications[key].selected &&
                                    (!!_this.props.classifications[key].imageUrl ? (React.createElement("div", { key: key },
                                        React.createElement(Image, { src: _this.props.classifications[key].imageUrl, size: "tiny" }))) : (React.createElement("span", { key: key, className: styles.markText },
                                        ' ',
                                        _this.props.classifications[key].value,
                                        ' ')));
                            })))),
                React.createElement("div", { className: styles.borderTop },
                    React.createElement("h2", null, translate('/Recipe/Ingredients')),
                    React.createElement("label", { className: classnames(styles.text) },
                        translate('/Recipe/Portions'),
                        ": "),
                    React.createElement(Input, { type: "number", max: "9999", onChange: function (e) { return _this.updatePortions(e.target.value); }, value: this.state.numberOfPortions, ref: function (e) { return (_this.portionField = e); } }),
                    !!this.props.recipeItemModels && (React.createElement("table", { className: classnames(styles.items, styles.text) },
                        React.createElement("tbody", null,
                            this.props.recipeType === 'recipe' &&
                                this.props.recipeItemModels.map(function (item) { return React.createElement(RecipeItem, __assign({ key: item.id }, item)); }),
                            mealItems,
                            this.props.recipeType === 'meal' && !!this.props.recipeItemModels && (React.createElement("tr", null,
                                React.createElement("th", { colSpan: 2 },
                                    React.createElement("h4", null, translate('/Recipe/Trimmings'))))),
                            this.props.recipeType === 'meal' &&
                                this.props.recipeItemModels.map(function (item) { return React.createElement(RecipeItem, __assign({ key: item.id }, item)); })))),
                    React.createElement(AddRecipeToCart, { portions: this.state.portions }),
                    React.createElement(CreateMenu, { id: this.props.id, name: this.props.name, className: styles.addToMenu })),
                !this.state.isPdf && (React.createElement("div", { className: styles.description },
                    React.createElement("h2", null, translate('/Recipe/Instructions')),
                    this.props.recipeType === 'recipe' && !!this.props.description && (React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: this.toRaw(this.props.description) })),
                    mealDescriptions,
                    this.props.recipeType === 'meal' && !!this.props.recipeItemModels && !!this.props.description && (React.createElement("div", null,
                        React.createElement("h4", null, translate('/Recipe/Trimmings')),
                        React.createElement("div", { className: styles.text, dangerouslySetInnerHTML: this.toRaw(this.props.description) }))))),
                !!this.props.nutrients && this.props.nutrients.length > 0 && (React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement("h2", null, translate('/Product/Declaration')),
                        React.createElement(NutritionFacts, { tableData: this.props.nutrients })),
                    React.createElement("div", null,
                        React.createElement("h2", null, translate('/Recipe/Energy')),
                        React.createElement(EnergyChart, { data: this.props.nutrients }),
                        !!this.props.portionCostIncomplete && React.createElement("div", null,
                            "** ",
                            translate('/Recipe/PriceIncomplete')),
                        !!this.props.recipeNutritionIncomplete && React.createElement("div", null,
                            "* ",
                            translate('/Recipe/NutrientsIncomplete'))))))));
    };
    return RecipePage;
}(React.PureComponent));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoggedIn: state.currentUser.isLoggedIn,
}); }, function (dispatch, _a) {
    var id = _a.id;
    return ({
        updatePortions: function (portion) {
            // Recalculates prices depending on units for product and their prices
            dispatch(updatePortions(portion));
            return Promise.resolve();
        },
        setFeedback: function (data) {
            return dispatch(setFeedback(data));
        },
        setGradeProp: function (grade) {
            return dispatch(setGrade(id, grade));
        },
        removeRecipe: function (id) {
            return dispatch(removeRecipe(id));
        },
        searchUsers: function (query) {
            return dispatch(searchUsers(query));
        },
        shareRecipe: function (model) {
            return dispatch(shareRecipe(model));
        },
    });
})(RecipePage);
