var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import * as server from 'Shared/server';
import MyMenigoIcon from '../../Shared/MyMenigoIcon/MyMenigoIcon';
import Modal from 'Shared/Modal';
import { Input, Checkbox, Textarea } from 'Shared/Fields';
import Button from 'Shared/Button';
import { addToDataLayer } from './../../../TrackingInformation';
var CustomerArrangementContactBlock = /** @class */ (function (_super) {
    __extends(CustomerArrangementContactBlock, _super);
    function CustomerArrangementContactBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = function () {
            _this.setState({ showModal: true });
        };
        _this.hideModal = function () {
            _this.setState({ showModal: false });
        };
        _this.validateEmail = function (email) {
            var model = {
                success: !!!email || _this.isValidEmail(email),
                message: translate('/MyMenigo/CustomerArrangementContactBlock/InvalidEmail'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validatePhone = function (phone) {
            var model = {
                success: _this.isValidPhoneNumber(phone),
                message: translate('/MyMenigo/CustomerArrangementContactBlock/InvalidPhone'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.state = {
            showModal: false,
            id: props.id,
            name: "",
            missingName: false,
            phone: "",
            email: "",
            missingPhone: false,
            changePackage: false,
            changeExtensions: false,
            missingChange: false,
            message: "",
            sentForm: false,
            errorMessage: "",
        };
        return _this;
    }
    CustomerArrangementContactBlock.prototype.changeExtensionsSelected = function () {
        this.setState({
            changeExtensions: !this.state.changeExtensions,
        });
    };
    CustomerArrangementContactBlock.prototype.changePackageSelected = function () {
        this.setState({
            changePackage: !this.state.changePackage,
        });
    };
    CustomerArrangementContactBlock.prototype.isValidPhoneNumber = function (p) {
        if ((p || '').trim() === '') {
            return true;
        }
        var phoneno = /^[\+]?([0-9]|\-|\s)+$/;
        if (p.match(phoneno)) {
            return true;
        }
        return false;
    };
    CustomerArrangementContactBlock.prototype.isValidEmail = function (email) {
        if ((email || '').trim() === '') {
            return true;
        }
        // tslint:disable-next-line:max-line-length
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    CustomerArrangementContactBlock.prototype.validateForm = function () {
        var validForm = true;
        if (this.state.name && this.state.name.trim() !== '') {
            this.setState({ missingName: false });
        }
        else {
            this.setState({ missingName: true });
            validForm = false;
        }
        if (this.state.phone && this.state.phone.trim() !== '' && this.isValidPhoneNumber(this.state.phone)) {
            this.setState({ missingPhone: false });
        }
        else {
            this.setState({ missingPhone: true });
            validForm = false;
        }
        if (this.state.changePackage === true || this.state.changeExtensions === true) {
            this.setState({ missingChange: false });
        }
        else {
            this.setState({ missingChange: true });
            validForm = false;
        }
        if (this.isValidEmail(this.state.email) === false) {
            validForm = false;
        }
        return validForm;
    };
    CustomerArrangementContactBlock.prototype.sendContactForm = function () {
        var _this = this;
        if (this.validateForm() === true) {
            var url = "/customerarrangementcontact/SendContactForm";
            server.postJson(url, this.state).
                then(function (result) {
                if (result.success === true) {
                    _this.setState({ sentForm: true });
                    _this.setState({ errorMessage: "" });
                    addToDataLayer({ event: 'formSubmit' });
                }
                else {
                    _this.setState({ errorMessage: result.errorMessage });
                }
            });
        }
    };
    CustomerArrangementContactBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.contactBox },
            React.createElement("div", { className: styles.contactBox__imageColumn },
                React.createElement(MyMenigoIcon, { name: "contact" })),
            React.createElement("div", null,
                React.createElement("p", { className: styles.contactBox__textHeader }, this.props.header),
                React.createElement("p", { className: styles.contactBox__textBody }, this.props.body),
                this.props.additionalLinks ? this.props.additionalLinks.map(function (item) {
                    return React.createElement("div", null,
                        React.createElement("a", { className: styles.contactButton, href: item.url, title: item.title, target: item.target }, item.text));
                }) : null,
                React.createElement("div", null,
                    React.createElement("a", { className: styles.contactButton, onClick: this.onClick }, this.props.buttonText))),
            React.createElement(Modal, { className: styles.contactForm, isOpen: this.state.showModal, onClose: this.hideModal },
                React.createElement("div", { className: this.state.sentForm === false ? styles.show : styles.hide },
                    React.createElement("h2", null, this.props.formHeader),
                    React.createElement("p", { className: styles.contactForm__preamble }, this.props.formText),
                    React.createElement("form", { className: styles.contactForm__form },
                        React.createElement("div", { className: styles.contactForm__form__element },
                            React.createElement(Input, { label: translate('/MyMenigo/CustomerArrangementContactBlock/Name'), className: this.state.missingName ? styles.inputInvalid : '', type: "text", value: this.state.name, name: "name", spellCheck: false, autoCorrect: 'off', maxLength: 30, required: true, requiredValidationMessage: translate('/MyMenigo/CustomerArrangementContactBlock/RequiredName'), onChange: function (e) { return _this.setState({ name: e.target.value }); } })),
                        React.createElement("div", { className: styles.contactForm__form__element },
                            React.createElement(Input, { label: translate('/MyMenigo/CustomerArrangementContactBlock/Phone'), className: this.state.missingPhone ? styles.inputInvalid : '', type: "text", value: this.state.phone, name: "phone", spellCheck: false, autoCorrect: 'off', maxLength: 30, required: true, requiredValidationMessage: translate('/MyMenigo/CustomerArrangementContactBlock/RequiredPhone'), onValidate: this.validatePhone, onChange: function (e) { return _this.setState({ phone: e.target.value }); } })),
                        React.createElement("div", { className: styles.contactForm__form__element },
                            React.createElement(Input, { label: translate('/MyMenigo/CustomerArrangementContactBlock/Email'), type: "text", value: this.state.email, name: "email", spellCheck: false, autoCorrect: 'off', maxLength: 30, onValidate: this.validateEmail, onChange: function (e) { return _this.setState({ email: e.target.value }); } })),
                        React.createElement("div", { className: styles.contactForm__form__element },
                            React.createElement("div", { className: styles.contactForm__form__changeLabel },
                                React.createElement("label", null, translate('/MyMenigo/CustomerArrangementContactBlock/ChangeLabel'))),
                            React.createElement("div", { className: styles.contactForm__form__changeElement },
                                React.createElement(Checkbox, { checked: this.state.changePackage, onChange: function () { return _this.changePackageSelected(); } }, translate('/MyMenigo/CustomerArrangementContactBlock/ChangePackage'))),
                            React.createElement("div", { className: styles.contactForm__form__changeElement },
                                React.createElement(Checkbox, { checked: this.state.changeExtensions, onChange: function () { return _this.changeExtensionsSelected(); } }, translate('/MyMenigo/CustomerArrangementContactBlock/ChangeExtensions'))),
                            this.state.missingChange === true ? (React.createElement("span", { className: styles.requiredField },
                                React.createElement(MyMenigoIcon, { name: "alert" }),
                                translate('/MyMenigo/CustomerArrangementContactBlock/RequiredChange'))) : null),
                        React.createElement("div", { className: styles.contactForm__form__element },
                            React.createElement(Textarea, { label: translate('/MyMenigo/CustomerArrangementContactBlock/MessageLabel'), name: "message", className: styles.contactForm__messageText, value: this.state.message || '', maxLength: 256, onChange: function (e) { return _this.setState({ message: e.target.value }); } })),
                        React.createElement("div", { className: styles.contactForm__buttons },
                            React.createElement("div", { className: styles.requiredField }, this.state.errorMessage),
                            React.createElement(Button, { className: styles.contactForm__buttons__sendButton, onClick: function () { _this.sendContactForm(); }, appearance: "primary" }, translate('/MyMenigo/CustomerArrangementContactBlock/Send')),
                            React.createElement(Button, { className: styles.contactForm__buttons__cancelButton, onClick: function () { _this.hideModal(); }, appearance: "pale" }, translate('/MyMenigo/CustomerArrangementContactBlock/Cancel'))))),
                React.createElement("div", { className: this.state.sentForm === true ? styles.show : styles.hide },
                    React.createElement("h2", null, this.props.thankYouHeader),
                    React.createElement("p", { className: styles.contactForm__preamble }, this.props.thankYouText),
                    React.createElement("div", { className: styles.contactForm__buttons },
                        React.createElement(Button, { className: styles.contactForm__buttons__closeButton, onClick: function () { _this.hideModal(); }, appearance: "primary" }, translate('/MyMenigo/CustomerArrangementContactBlock/Close')))))));
    };
    return CustomerArrangementContactBlock;
}(React.Component));
export default CustomerArrangementContactBlock;
