var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import Overlay from 'Shared/Overlay';
import Icon from 'Shared/Icon';
import { RootContext } from 'SiteLayout/Root';
import Transition from 'Shared/Transition';
import * as styles from './base.scss';
export var ModalType;
(function (ModalType) {
    ModalType[ModalType["None"] = 0] = "None";
    ModalType[ModalType["Success"] = 1] = "Success";
    ModalType[ModalType["Warning"] = 2] = "Warning";
    ModalType[ModalType["Danger"] = 3] = "Danger";
})(ModalType || (ModalType = {}));
var CheckoutModal = /** @class */ (function (_super) {
    __extends(CheckoutModal, _super);
    function CheckoutModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckoutModal.prototype.componentDidMount = function () {
        this.forceUpdate();
    };
    CheckoutModal.prototype.renderTitleIfProvided = function () {
        return this.props.title ? (React.createElement("h3", { className: styles.checkoutTitleWarning },
            React.createElement("img", { id: "hand", className: styles.image, width: "134", height: "140", src: "./styles/images/hand.svg" }),
            React.createElement("span", null, this.props.title))) : null;
    };
    CheckoutModal.prototype.getModalIconFromType = function (type) {
        if (type === void 0) { type = this.props.type; }
        var wrapElement = function (el) { return React.createElement("div", { className: styles.iconWrapper }, el); };
        if (type === ModalType.Danger) {
            return wrapElement(React.createElement(Icon, { name: "remove-circle" }));
        }
        else if (type === ModalType.Warning) {
            return wrapElement(React.createElement(Icon, { name: "exclamation" }));
        }
        else if (type === ModalType.Success) {
            return wrapElement(React.createElement(Icon, { name: "check" }));
        }
        return null;
    };
    CheckoutModal.prototype.renderComponent = function () {
        var _this = this;
        var _a = this.props, onClose = _a.onClose, isOpen = _a.isOpen, className = _a.className, childContainerClassname = _a.childContainerClassname, children = _a.children;
        return (React.createElement(Overlay, { enabled: isOpen, onClose: onClose },
            React.createElement(Transition, { in: isOpen, timeout: 200, appear: false }, function (active) {
                var _a, _b;
                return (React.createElement("div", { className: classnames(styles.checkoutBase, className, (_a = {}, _a[styles.active] = active, _a)) },
                    React.createElement("div", { className: styles.checkoutInnerContainer },
                        _this.renderTitleIfProvided(),
                        React.createElement("div", { className: classnames(childContainerClassname, (_b = {},
                                _b[styles.reserveSpaceForCloseButton] = !_this.props.title,
                                _b)) }, children))));
            })));
    };
    CheckoutModal.prototype.render = function () {
        return this.context.rootElementRef
            ? ReactDOM.createPortal(this.renderComponent(), this.context.rootElementRef)
            : null;
    };
    return CheckoutModal;
}(React.PureComponent));
CheckoutModal.contextType = RootContext;
export default CheckoutModal;
