var _this = this;
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import Spinner from 'Shared/Spinner';
var Desktop = function (_a) {
    var _b;
    var activePage = _a.activePage, visibleItems = _a.visibleItems, isDisablePrevNav = _a.isDisablePrevNav, isDisableNextNav = _a.isDisableNextNav, pageCount = _a.pageCount, pageCountIndex = _a.pageCountIndex, pageCountMaxIndex = _a.pageCountMaxIndex, isLoading = _a.isLoading, getInvoiceStatus = _a.getInvoiceStatus, pdfSendUrl = _a.pdfSendUrl, onSelectNavPageAction = _a.onSelectNavPageAction, onSelectPage = _a.onSelectPage, noResultElement = _a.noResultElement;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.invoiceContainer },
            React.createElement("div", null,
                React.createElement("div", { className: styles.borderHeader },
                    React.createElement("div", { className: styles.header },
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/InvoiceNumber')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/InvoiceDate')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/DueDate')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/Amount')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/Status')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/CreditInvoice')),
                        React.createElement("div", { className: styles.cell }, translate('/Invoice/PdfLink')))),
                React.createElement(Spinner, { key: "spinner", animate: isLoading, className: classnames(styles.loaderSpinner, (_b = {},
                        _b[styles.visible] = isLoading,
                        _b)) }),
                visibleItems.map(function (data, index) { return (React.createElement("div", { className: styles.invoiceWrapItems, key: index },
                    React.createElement("div", { className: styles.itemHeader },
                        React.createElement("div", { className: styles.cell }, data.id),
                        React.createElement("div", { className: styles.cell }, data.issueDate),
                        React.createElement("div", { className: styles.cell }, data.dueDate),
                        React.createElement("div", { className: styles.cell },
                            data.amount,
                            " kr"),
                        React.createElement("div", { className: styles.cell }, getInvoiceStatus(data.status)),
                        React.createElement("div", { className: styles.cell + " " + styles.creditInvoiceCell }, data.invoiceType === "credit" ? React.createElement(Icon, { name: "check-square", className: styles.creditInvoiceIcon }) : null),
                        React.createElement("div", { className: styles.cell },
                            React.createElement("a", { className: styles.aLink, onClick: function () { return pdfSendUrl(data.url, data.id); } }, translate('/Invoice/PdfText')))))); })),
            visibleItems.length < 1 && noResultElement),
        React.createElement("div", { className: styles.pagingMainContainer },
            React.createElement("div", { className: styles.paginationContent },
                React.createElement("ul", { className: classnames(styles.paginate, styles.page) },
                    React.createElement("li", { className: isDisablePrevNav ? styles.notallowednavpage : styles.navpage, onClick: !isDisablePrevNav
                            ? function () { return onSelectNavPageAction(activePage, 'prev'); }
                            : void 0 },
                        React.createElement("a", { href: void 0, rel: "prev" }, translate('/Invoice/Previous'))),
                    pageCount.slice(pageCountIndex, pageCountMaxIndex).map(function (p, index) {
                        return activePage === p ? (React.createElement("li", { key: index, className: styles.current, onClick: onSelectPage.bind(_this, p) }, p)) : (React.createElement("li", { key: index, onClick: onSelectPage.bind(_this, p) },
                            React.createElement("a", { href: "#" }, p)));
                    }),
                    React.createElement("li", { className: isDisableNextNav ? styles.notallowednavpage : styles.navpage, onClick: !isDisableNextNav
                            ? function () { return onSelectNavPageAction(activePage, 'next'); }
                            : void 0 },
                        React.createElement("a", { href: void 0, rel: "next" }, translate('/Invoice/Next'))))))));
};
export default Desktop;
