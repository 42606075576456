import React from 'react';
import classnames from 'classnames';
import AddRemoveFromList from 'Shared/AddRemoveFromList';
import LoginContainer from 'Shared/Login/Container';
import * as styles from './add-remove-from-list-or-login.scss';
var AddRemoveFromListOrLogin = function (_a) {
    var product = _a.product, variantCode = _a.variantCode, className = _a.className, hideFavoriteButton = _a.hideFavoriteButton, hidePantriesButton = _a.hidePantriesButton, children = _a.children;
    return (React.createElement(LoginContainer, null, function (_a) {
        var isLoggedIn = _a.isLoggedIn, setAccountBoxOpen = _a.setAccountBoxOpen;
        return (React.createElement("div", { className: classnames(styles.base, className), onClickCapture: function (e) {
                if (!isLoggedIn) {
                    e.stopPropagation();
                    setAccountBoxOpen();
                }
            } },
            !hideFavoriteButton && React.createElement(AddRemoveFromList, { type: "favorites", code: variantCode, product: product, className: className }),
            !hidePantriesButton && React.createElement(AddRemoveFromList, { type: "pantries", code: variantCode, product: product, className: className }),
            !!children && children));
    }));
};
export default AddRemoveFromListOrLogin;
