import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './components.scss';
import { searchPageUrl } from 'Shared/known-urls';
import SearchPageInfoBlock from './SearchPageInfoBlock';
import classnames from 'classnames';
import SearchQuickFilters from './SearchQuickFilters';
var SearchPageHeader = function (props) {
    var _a;
    return (React.createElement("div", { className: classnames(styles.searchSuggestionBackgroundBox, (_a = {}, _a[styles.hasSuggestion] = (!(props.products.totalProductCount > 0 || !!props.hasContent) && props.searchSuggestions.length > 0), _a[styles.hasFilters] = (props.products.totalProductCount > 0 || !!props.hasContent), _a)) },
        React.createElement("div", { className: styles.searchSuggestionBox },
            React.createElement("h1", { className: styles.header },
                translate('/Search/ResultsFor', ''),
                " ",
                React.createElement("strong", null,
                    "\u201C",
                    props.searchPhrase,
                    "\u201D")),
            (props.products.totalProductCount > 0 || !!props.hasContent) && React.createElement(SearchQuickFilters, { quickFilterBlocks: props.page.quickFiltersArea, products: props.products }),
            !(props.products.totalProductCount > 0 || !!props.hasContent) && props.searchSuggestions.length > 0 && (React.createElement("div", { className: styles.searchSuggestion },
                "  ",
                React.createElement("ul", null,
                    React.createElement("li", { key: "text" },
                        translate('/Shared/FadeNoHits'), " " + translate('/Search/DidYouMean') + ":"),
                    props.searchSuggestions.map(function (suggestion, i) { return (React.createElement("li", { key: suggestion.text },
                        React.createElement("a", { className: styles.button, "data-ticket": suggestion.ticket, href: searchPageUrl() + '?q=' + suggestion.text }, suggestion.text))); })))),
            (!(props.products.totalProductCount > 0 || !!props.hasContent) && props.searchSuggestions.length < 1 && React.createElement("div", null, translate('/Shared/FadeNoHits')))),
        (!(props.products.totalProductCount > 0 || !!props.hasContent)) && React.createElement(SearchPageInfoBlock, { helpText: props.page.helpText, helpTextChat: props.page.helpTextChat })));
};
export default SearchPageHeader;
