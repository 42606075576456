var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import FreightList from './Freight';
import DiscountList from './Discount';
import * as styles from './base.scss';
var DiscountFreightBlock = /** @class */ (function (_super) {
    __extends(DiscountFreightBlock, _super);
    function DiscountFreightBlock(props) {
        return _super.call(this, props) || this;
    }
    DiscountFreightBlock.prototype.render = function () {
        var discountList = this.props.discountLimits;
        var freightList = this.props.freightLimits;
        return (React.createElement("div", null,
            this.props.isInEditMode && React.createElement("h3", null, "Is in edit mode - Discount Freight Block"),
            (discountList.length > 0 || freightList.length > 0) && (React.createElement("div", { className: styles.discountFreightBlock },
                discountList.length > 0 && React.createElement(DiscountList, { list: discountList }),
                freightList.length > 0 && React.createElement(FreightList, { list: freightList })))));
    };
    return DiscountFreightBlock;
}(React.Component));
export default DiscountFreightBlock;
