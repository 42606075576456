var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { onDeleteRecipeGroup, onAddRecipeGroup, onEditRecipeGroup } from '../action-creators';
import RecipeGroup from './RecipeGroup';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import * as styles from './base.scss';
import Main, { Section } from 'Shared/PageLayout';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { Input } from 'Shared/Fields';
var defaltItem = {
    id: '',
    name: '',
    nameEn: '',
    recipeType: 'recipe',
    integrationId: '',
};
var RecipeGroupPage = /** @class */ (function (_super) {
    __extends(RecipeGroupPage, _super);
    function RecipeGroupPage(props) {
        var _this = _super.call(this, props) || this;
        _this.hideDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: false });
        };
        _this.hideEditModal = function () {
            _this.setState({ isEditModalOpen: false });
        };
        _this.hideAddModal = function () {
            _this.setState({ isAddModalOpen: false });
        };
        _this.state = {
            items: _this.props.recipeGroups,
            filterType: defaltItem.recipeType,
            isDeleteModalOpen: false,
            isEditModalOpen: false,
            isAddModalOpen: false,
            currentItem: defaltItem,
        };
        return _this;
    }
    RecipeGroupPage.prototype.filter = function (type) {
        this.setState({
            filterType: type,
        });
    };
    RecipeGroupPage.prototype.showDeleteModal = function (item) {
        this.setState({
            isDeleteModalOpen: true,
            currentItem: item,
        });
    };
    RecipeGroupPage.prototype.showEditModal = function (item) {
        this.setState({
            isEditModalOpen: true,
            currentItem: item,
        });
    };
    RecipeGroupPage.prototype.editRow = function (editedItem, name) {
        var _this = this;
        return onEditRecipeGroup(editedItem, name)
            .then(function () {
            var newItems = _this.state.items.map(function (i) {
                return i.id === editedItem.id ? __assign({}, i, { name: name }) : i;
            });
            _this.setState({ items: newItems });
        })
            .catch(function (e) { return Promise.reject(null); });
    };
    RecipeGroupPage.prototype.deleteRow = function (item) {
        var _this = this;
        return onDeleteRecipeGroup(item.id)
            .then(function () {
            var newItems = _this.state.items.filter(function (newItem) { return newItem.id !== item.id; });
            _this.setState({ items: newItems });
        })
            .catch(function (e) { return Promise.reject(null); });
    };
    RecipeGroupPage.prototype.showAddModal = function () {
        this.setState({ isAddModalOpen: true });
    };
    RecipeGroupPage.prototype.addRow = function (type, name) {
        var _this = this;
        return onAddRecipeGroup(type, name)
            .then(function (json) {
            var newItems = __spread(_this.state.items, [json.value]).sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.setState({ items: newItems });
        })
            .catch(function (e) { return Promise.reject(null); });
    };
    RecipeGroupPage.prototype.getFilteredItems = function () {
        var _this = this;
        return this.state.items.filter(function (i) { return i.recipeType === _this.state.filterType; });
    };
    RecipeGroupPage.prototype.render = function () {
        var _this = this;
        var items = this.getFilteredItems();
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", null, translate('/Admin/RecipeGroups/RecipeGroup'))),
            React.createElement(Section, null,
                React.createElement(Section, null,
                    React.createElement(Button, { onClick: function () { return _this.filter('recipe'); }, appearance: this.state.filterType === 'recipe' ? 'secondary' : 'pale' }, translate('/Admin/RecipeGroups/RecipeGroup')),
                    React.createElement(Button, { onClick: function () { return _this.filter('meal'); }, appearance: this.state.filterType === 'meal' ? 'secondary' : 'pale' }, translate('/Admin/RecipeGroups/MealGroup')),
                    React.createElement(Button, { onClick: function () { return _this.showAddModal(); }, appearance: 'pale' },
                        translate('/Admin/RecipeGroups/Add'),
                        this.state.filterType === 'meal'
                            ? translate('/Admin/RecipeGroups/meal')
                            : translate('/Admin/RecipeGroups/recipe'))),
                React.createElement(Section, null,
                    React.createElement("h4", { className: styles.heading }, translate('/Admin/RecipeGroups/Name')),
                    React.createElement("div", { className: styles.recipeGroupTable }, items.map(function (group) { return (React.createElement(RecipeGroup, __assign({ key: group.id }, group, { edit: function () { return _this.showEditModal(group); }, delete: function () { return _this.showDeleteModal(group); } }))); }))),
                React.createElement(Modal, { isOpen: this.state.isDeleteModalOpen, onClose: this.hideDeleteModal, type: ModalType.Warning, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", null,
                        React.createElement("p", null, translate('/Admin/RecipeGroups/DeleteModal', this.state.currentItem.name))),
                    React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: function () { return _this.deleteRow(_this.state.currentItem); }, onCancel: this.hideDeleteModal, onStatusWasFulfilled: this.hideDeleteModal })),
                React.createElement(Modal, { isOpen: this.state.isEditModalOpen, onClose: this.hideEditModal, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", null,
                        React.createElement("p", null, translate('/Admin/RecipeGroups/EditName', this.state.currentItem.name)),
                        React.createElement(Input, { className: styles.modalInput, defaultValue: this.state.currentItem.name, ref: function (el) { return (_this.nameInput = el); }, autoFocus: true })),
                    React.createElement(Dialog, { type: DialogType.SaveAbort, onConfirm: function () { return _this.editRow(_this.state.currentItem, _this.nameInput.element.value); }, onCancel: this.hideEditModal, onStatusWasFulfilled: this.hideEditModal })),
                React.createElement(Modal, { isOpen: this.state.isAddModalOpen, onClose: this.hideAddModal, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", null,
                        React.createElement("p", null,
                            translate('/Admin/RecipeGroups/EnterName'),
                            ":"),
                        React.createElement(Input, { className: styles.modalInput, ref: function (el) { return (_this.nameInput = el); }, autoFocus: true })),
                    React.createElement(Dialog, { type: DialogType.SaveAbort, onConfirm: function () { return _this.addRow(_this.state.filterType, _this.nameInput.element.value); }, onCancel: this.hideAddModal, onStatusWasFulfilled: this.hideAddModal })))));
    };
    return RecipeGroupPage;
}(React.Component));
export default RecipeGroupPage;
