var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import React from 'react';
import Main, { Section } from 'Shared/PageLayout';
import shallowEquals from 'Shared/shallow-equals';
import propertyValueFor from 'Shared/property-value-for';
import Price from 'Shared/Price';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { Checkbox, Input, Textarea } from 'Shared/Fields';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import connect from 'Shared/connect';
import { SMALL } from 'Shared/breakpoints';
import Quantity from 'Shared/Quantity';
import IngredientTable from './IngredientTable';
import * as server from 'Shared/server';
import DropDown from 'Shared/DropDown';
import { ESC, ENTER, DOWN, UP, PLUS, PLUS_DESKTOP, MINUS, MINUS_DESKTOP, TAB } from 'Shared/keyboard';
import Icon from 'Shared/Icon';
import ImageFallback from 'Shared/ImageFallback';
import AutocompletionsCustom from './../../Search/QuickSearch/AutocompletionsCustom';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Dropzone from 'react-dropzone';
import { translate } from 'Shared/translate';
import { pathCombine } from 'Shared/url';
import Spinner from 'Shared/Spinner';
import Grade from './../../Recipes/Grade';
import { pushState } from 'Shared/history';
import { isIE } from 'Shared/device-type';
import classnames from 'classnames';
import * as styles from './page.scss';
import { loadProductSearch, loadFilteredQuickSearchRecipes } from '../../Search/action-creators';
import { cancelEdit, notifySelectionChangesToServer } from './../action-creators';
import Calculation from './../Calculation';
import { MasterFor } from 'Shared/MasterFor';
import { ProductCardSelection, ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
var debounceMs = 500;
var inputDebouncer = 400;
var maxLengthOfRecipeSearchResult = 6;
var RecipeEditPage = /** @class */ (function (_super) {
    __extends(RecipeEditPage, _super);
    function RecipeEditPage(props) {
        var _this = _super.call(this, props) || this;
        _this.addIngredientFromQuickSearch = function (index) {
            if (index >= 0) {
                var selectedProduct = _this.state.quickSearchProducts[index];
                if (selectedProduct.isBuyable &&
                    !selectedProduct.isSoldOut &&
                    !selectedProduct.isTender &&
                    !selectedProduct.isDiscontinued) {
                    _this.addProductRecipeItem(selectedProduct);
                    _this.closeQuickSearch();
                }
            }
            else {
                _this.addRecipeItem();
                _this.closeQuickSearch();
            }
        };
        _this.state = {
            // This is SAVED
            id: props.recipeModel.id,
            parentId: props.recipeModel.parentId,
            numberOfPortions: props.recipeModel.numberOfPortions,
            originalNumberOfPortions: props.recipeModel.numberOfPortions,
            isPublic: props.recipeModel.isPublic,
            name: props.recipeModel.name,
            leadParagraph: props.recipeModel.leadParagraph,
            description: props.recipeModel.description,
            recipeType: props.recipeModel.recipeType,
            classifications: props.recipeModel.classifications,
            group: props.recipeModel.group || props.recipeModel.groups[0],
            recipeItemModels: props.recipeModel.recipeItemModels,
            mealRecipeModels: props.recipeModel.mealRecipeModels,
            imageFile: null,
            calculation: props.recipeModel.calculation,
            // End This is SAVED
            // Frontend Helpers (Not present in model) NOT SAVED
            portionCost: props.recipeModel.portionCost,
            listSelectionIndex: -1,
            searchTextIngridients: '',
            isOpen: false,
            isQuickSearchLoading: false,
            quickSearchProducts: null,
            isQuickSearchRecipesLoading: false,
            lastEditedNumeric: null,
            recipeList: null,
            addedImage: null,
            searchTextRecipes: '',
            currentSearchRecipes: null,
            currentIndexOfRecipeSearch: 0,
            appIsMounted: false,
        };
        // Don't load the ReactQuill library on serverside since the package dont support server side rendering
        if (window.document) {
            _this.ReactQuill = require('react-quill');
        }
        return _this;
    }
    RecipeEditPage.prototype.onUpdateIsPublic = function (e) {
        this.setState({
            isPublic: e.target.checked,
        });
    };
    RecipeEditPage.prototype.onUpdateQuantity = function (numberOfPortions) {
        var _this = this;
        this.setState({ numberOfPortions: numberOfPortions }, function () {
            _this.notifySelectionChanges();
        });
        return Promise.resolve(null);
    };
    RecipeEditPage.prototype.componentDidMount = function () {
        // Re-render for isomorphic purposes
        this.setState({ appIsMounted: true });
    };
    RecipeEditPage.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(nextState, this.state, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to RecipeEditPage');
        }
        return shouldUpdate;
    };
    RecipeEditPage.prototype.componentWillReceiveProps = function (nextProps) {
        if ((!!this.props.recipeModel.recipeItemModels && !nextProps.recipeModel.recipeItemModels) ||
            (!!this.props.recipeModel.recipeItemModels &&
                !!nextProps.recipeModel.recipeItemModels &&
                !shallowEquals(nextProps.recipeModel.recipeItemModels, this.props.recipeModel.recipeItemModels, true))) {
            this.setState({ recipeItemModels: nextProps.recipeModel.recipeItemModels });
        }
        if ((!!this.props.recipeModel.mealRecipeModels && !nextProps.recipeModel.mealRecipeModels) ||
            (!!this.props.recipeModel.mealRecipeModels &&
                !!nextProps.recipeModel.mealRecipeModels &&
                !shallowEquals(nextProps.recipeModel.mealRecipeModels, this.props.recipeModel.mealRecipeModels, true))) {
            this.setState({ mealRecipeModels: nextProps.recipeModel.mealRecipeModels });
        }
        if (nextProps.recipeModel.portionCost !== this.props.recipeModel.portionCost) {
            this.setState({ portionCost: nextProps.recipeModel.portionCost });
        }
    };
    RecipeEditPage.prototype.onUpdateCalculation = function (factor, vat) {
        var newCalc = { factor: factor, vat: vat };
        this.setState({ calculation: newCalc });
    };
    RecipeEditPage.prototype.onUpdateClassifications = function (e, item) {
        item.selected = e.target.checked;
        var findIndex = this.state.classifications.findIndex(function (c) { return c.value === item.value; });
        var copyClassificationsArray = this.state.classifications.slice();
        copyClassificationsArray[findIndex] = item;
        this.setState({
            classifications: copyClassificationsArray,
        });
    };
    RecipeEditPage.prototype.onGroupChange = function (groupId) {
        var selectedGroup = this.props.recipeModel.groups.find(function (g) { return g.id === groupId; });
        this.setState({ group: selectedGroup });
    };
    RecipeEditPage.prototype.createRequestModel = function () {
        var recipeItemModels = this.state.recipeItemModels;
        !!recipeItemModels ? recipeItemModels.forEach(function (i) { i.product = null; if (!!!i.id)
            i.id = "00000000-0000-0000-0000-000000000000"; }) : [];
        var recipeMealIds = !!this.state.mealRecipeModels ? this.state.mealRecipeModels.map(function (x) { return Object.assign({}, { id: x.id, portionCost: x.portionCost }); }) : [];
        var requestModel = {
            model: {
                id: this.state.id,
                parentId: !!this.state.parentId ? this.state.parentId : null,
                name: this.state.name,
                description: this.state.description,
                leadParagraph: this.state.leadParagraph,
                isPublic: this.state.isPublic,
                recipeType: this.state.recipeType,
                group: this.state.group,
                classifications: this.state.classifications,
                recipeItemModels: recipeItemModels,
                mealRecipeModels: recipeMealIds,
                numberOfPortions: this.state.numberOfPortions,
                originalNumberOfPortions: this.state.originalNumberOfPortions,
                portionCost: this.state.portionCost,
                calculation: this.state.calculation,
                addedImage: this.state.addedImage
            },
            imageFile: this.state.imageFile
        };
        return requestModel;
    };
    RecipeEditPage.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        this.feedbackButton.activate(new Promise(function (resolve, reject) {
            var requestModel = _this.createRequestModel();
            server.postJson(pathCombine('/recipe', '/save'), requestModel).then(function (result) {
                if (result.success) {
                    resolve();
                    if (!result.redirectUrl) {
                        window.history.back();
                    }
                    else {
                        pushState(result.redirectUrl);
                    }
                }
                else {
                    _this.props.setFeedback({
                        text: result.errorMessages.join(' '),
                        type: 'error',
                    });
                    reject();
                }
            }, function (e) {
                console.warn(e);
                _this.props.setFeedback({ text: e.message, type: 'error' });
                reject();
            });
        }));
    };
    RecipeEditPage.prototype.onDrop = function (acceptedFiles, rejectedFiles) {
        var _this = this;
        acceptedFiles.forEach(function (file) {
            _this.setState({ addedImage: file.name });
            var reader = new FileReader();
            reader.onabort = function () { return console.log('file reading was aborted'); };
            reader.onerror = function () { return console.log('file reading has failed'); };
            reader.onload = function () {
                var fileAsBinaryString = reader.result;
                _this.setState({ imageFile: fileAsBinaryString });
            };
            reader.readAsDataURL(file);
        });
    };
    RecipeEditPage.prototype.focusInCurrentTarget = function (event) {
        var relatedTarget = event.relatedTarget;
        var currentTarget = event.currentTarget;
        if (event.relatedTarget === null) {
            return true;
        }
        var node = relatedTarget.parentNode;
        while (node !== null) {
            if (node === currentTarget) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };
    RecipeEditPage.prototype.onInputFocus = function (resetSelectedIndex) {
        resetSelectedIndex();
        this.setState({ listSelectionIndex: -1 });
    };
    RecipeEditPage.prototype.onBlurRecipes = function (event) {
        if (!this.focusInCurrentTarget(event)) {
            this.closeRecipeSearch();
        }
    };
    RecipeEditPage.prototype.onKeyDownRecipe = function (event) {
        var index = this.state.currentIndexOfRecipeSearch === null ? 0 : this.state.currentIndexOfRecipeSearch;
        switch (event.keyCode) {
            case ENTER:
                event.preventDefault();
                this.addItemToSelectedRecipes(index);
                break;
            case UP:
            case MINUS:
            case MINUS_DESKTOP:
                if (!!this.state.recipeList && this.state.recipeList.length > 0) {
                    event.preventDefault();
                    if (index > 0) {
                        index = index - 1;
                    }
                    this.setState({
                        currentIndexOfRecipeSearch: index,
                    });
                }
                break;
            case DOWN:
            case TAB:
            case PLUS:
            case PLUS_DESKTOP:
                if (!!this.state.recipeList && this.state.recipeList.length > 0) {
                    event.preventDefault();
                    if (index < this.state.recipeList.length - 1) {
                        index = index + 1;
                    }
                    else {
                        index = 0;
                    }
                    this.setState({
                        currentIndexOfRecipeSearch: index,
                    });
                }
                break;
            case ESC:
                event.preventDefault();
                this.closeRecipeSearch();
                break;
            default:
                break;
        }
    };
    RecipeEditPage.prototype.closeRecipeSearch = function () {
        this.setState({ recipeList: null, currentIndexOfRecipeSearch: null });
    };
    RecipeEditPage.prototype.addItemToSelectedRecipes = function (index) {
        var _this = this;
        if (!this.state.recipeList || this.state.recipeList.length < index) {
            return;
        }
        var item = this.state.recipeList[index];
        var newSelectedRecipesList = Object.assign([], this.state.mealRecipeModels);
        newSelectedRecipesList.unshift(item);
        this.setState({
            mealRecipeModels: newSelectedRecipesList,
            recipeList: null,
            searchTextRecipes: '',
            currentIndexOfRecipeSearch: 0,
        }, function () {
            _this.notifySelectionChanges();
        });
        this.searchRecipeInput.focus();
    };
    RecipeEditPage.prototype.clearSelectedRecipes = function () {
        this.setState({
            mealRecipeModels: null,
        });
    };
    RecipeEditPage.prototype.onFocus = function (index) {
        this.setState({ listSelectionIndex: index });
    };
    RecipeEditPage.prototype.removeSelectedRecipeRow = function (index) {
        var _this = this;
        var newSelectedRecipesList = Object.assign([], this.state.mealRecipeModels);
        newSelectedRecipesList.splice(index, 1);
        if (newSelectedRecipesList.length === 0) {
            newSelectedRecipesList = null;
        }
        this.setState({
            mealRecipeModels: newSelectedRecipesList,
        }, function () {
            _this.notifySelectionChanges();
        });
    };
    RecipeEditPage.prototype.setFocusToIngredientInput = function () {
        if (!!this.ingredientInput) {
            this.ingredientInput.focus();
            this.ingredientInput.select();
        }
    };
    RecipeEditPage.prototype.onKeyDown = function (event, setSelectedIndex) {
        var selectedIndex = this.state.listSelectionIndex;
        if (Number(event.key) >= 0 && Number(event.key) <= 9) {
            this.setState({ lastEditedNumeric: Number(event.key) });
        }
        switch (event.keyCode) {
            case ENTER:
                try {
                    event.preventDefault();
                }
                catch (e) {
                    // the event could be simulated by the button - skip error-handling here
                }
                if (!!this.state.isOpen) {
                    this.addIngredientFromQuickSearch(this.state.listSelectionIndex);
                }
                break;
            case TAB:
            case DOWN:
                if (!!this.state.quickSearchProducts && !!this.state.isOpen) {
                    event.preventDefault();
                    if (this.state.listSelectionIndex < this.state.quickSearchProducts.length - 1) {
                        var newSelectedIndex_1 = selectedIndex + 1;
                        this.setState({ listSelectionIndex: newSelectedIndex_1 }, function () { return setSelectedIndex && setSelectedIndex(newSelectedIndex_1); });
                    }
                    else if (this.state.quickSearchProducts.length > 0) {
                        this.setState({ listSelectionIndex: 0 });
                    }
                    if (event.shiftKey) {
                        var newSelectedIndex = selectedIndex === 0 ? this.state.quickSearchProducts.length - 1 : selectedIndex - 1;
                        if (selectedIndex >= 0) {
                            this.setState({ listSelectionIndex: newSelectedIndex });
                        }
                    }
                }
                break;
            case UP:
                if (!!this.state.isOpen) {
                    event.preventDefault();
                    var newSelectedIndex = selectedIndex === 0 ? this.state.quickSearchProducts.length - 1 : selectedIndex - 1;
                    if (selectedIndex >= 0) {
                        this.setState({ listSelectionIndex: newSelectedIndex });
                    }
                }
                break;
            case ESC:
                // Prevent default because Chrome clears the value of a input[type='search']
                // on escape
                event.preventDefault();
                this.ingredientInput.value = '';
                this.setState({ searchTextIngridients: '' });
                this.closeQuickSearch();
                break;
            default:
                !event.shiftKey && !event.ctrlKey && !event.altKey && this.ingredientInput.focus();
                this.setState({ isOpen: true });
                return;
        }
    };
    RecipeEditPage.prototype.closeQuickSearch = function () {
        this.setState({ isOpen: false, listSelectionIndex: -1, isQuickSearchLoading: false, quickSearchProducts: null });
    };
    RecipeEditPage.prototype.handleAddIngredients = function (e) {
        var _this = this;
        this.setState({ searchTextIngridients: e.value });
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(function () {
            _this.setState({ isQuickSearchLoading: true }, function () {
                if (e.value.length) {
                    _this.props.loadProductSearch(e.value).then(function (productResult) {
                        _this.setState({
                            quickSearchProducts: productResult,
                            isQuickSearchLoading: false,
                        });
                    });
                }
                else {
                    _this.closeQuickSearch();
                }
            });
        }, inputDebouncer);
    };
    RecipeEditPage.prototype.handleRecipeSearch = function (e) {
        var _this = this;
        this.setState({ searchTextRecipes: e.value });
        this.closeQuickSearch();
        if (!!e.value) {
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(function () {
                _this.setState({ isQuickSearchRecipesLoading: true }, function () {
                    if (e.value.length > 0) {
                        _this.props.loadFilteredQuickSearchRecipes(e.value, _this.props.recipeModel.id).then(function (r) {
                            _this.setState({
                                isQuickSearchRecipesLoading: false,
                                recipeList: r.items.splice(0, maxLengthOfRecipeSearchResult),
                            });
                        });
                    }
                    else {
                        _this.setState({
                            isQuickSearchRecipesLoading: false,
                            recipeList: null,
                        });
                    }
                });
            }, inputDebouncer);
        }
        else {
            this.setState({
                recipeList: null,
            });
        }
    };
    RecipeEditPage.prototype.notifySelectionChanges = function () {
        var _this = this;
        clearTimeout(this.notifyTimer);
        console.info('Timer start');
        this.notifyTimer = setTimeout(function () {
            var requestModel = _this.createRequestModel();
            _this.props.notifySelectionChangesToServer(requestModel);
        }, debounceMs);
    };
    RecipeEditPage.prototype.updateImage = function (e) {
        try {
            if (e.target.files && e.target.files) {
                this.setState({ imageFile: e.target.files[0] });
            }
        }
        catch (e) {
            console.warn('was unable to handle image: ', e);
        }
    };
    RecipeEditPage.prototype.addRecipeItem = function () {
        var _this = this;
        if (!!this.state.searchTextIngridients) {
            var text_1 = this.state.searchTextIngridients;
            var pattern = /^[0-9]{6}$/g;
            if (pattern.test(text_1)) {
                this.setState({ isQuickSearchLoading: true });
                this.props.loadProductSearch(text_1).then(function (productResult) {
                    var e_1, _a;
                    if (!!productResult) {
                        var selectedProduct = void 0;
                        try {
                            for (var productResult_1 = __values(productResult), productResult_1_1 = productResult_1.next(); !productResult_1_1.done; productResult_1_1 = productResult_1.next()) {
                                var product = productResult_1_1.value;
                                if (propertyValueFor(product.content.code) === text_1) {
                                    selectedProduct = product;
                                    break;
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (productResult_1_1 && !productResult_1_1.done && (_a = productResult_1.return)) _a.call(productResult_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        if (!!selectedProduct) {
                            _this.addProductRecipeItem(selectedProduct);
                        }
                        else {
                            _this.addCustomRecipeItem(text_1);
                        }
                    }
                    _this.setState({
                        quickSearchProducts: productResult,
                        isQuickSearchLoading: false,
                    });
                });
            }
            else {
                this.addCustomRecipeItem(text_1);
            }
        }
    };
    RecipeEditPage.prototype.addCustomRecipeItem = function (text) {
        var newRecipeItem = {
            id: '',
            amount: '',
            amountPerPortion: '',
            name: text,
            nutrientsWastePercent: '',
            price: '',
            originalPrice: 0,
            product: null,
            productId: null,
            selectableUnits: this.props.allSelectableUnits,
            unit: this.resloveDefaultUnit(true, true),
            notSold: true,
            noNutritions: true,
            calculatedSmallWeight: null,
            volumeFactor: null,
            smallUnitWeight: null,
            weight: null,
            numberOfSmallerUnits: null,
        };
        var newRecipeItemModels = Object.assign([], this.state.recipeItemModels);
        newRecipeItemModels.unshift(newRecipeItem);
        this.setState({
            recipeItemModels: newRecipeItemModels,
            searchTextIngridients: '',
            isOpen: false,
        });
    };
    RecipeEditPage.prototype.addProductRecipeItem = function (product) {
        var _this = this;
        var newRecipeItem = {
            id: '',
            amount: '',
            amountPerPortion: '',
            name: propertyValueFor(product.content.displayName),
            nutrientsWastePercent: '',
            price: '',
            originalPrice: 0,
            product: product,
            productId: propertyValueFor(product.content.code),
            selectableUnits: this.resloveSelectableUnits(!!product.recipeSmallUnitWeight, !!product.recipeVolumeFactor),
            unit: this.resloveDefaultUnit(!!product.recipeSmallUnitWeight, !!product.recipeVolumeFactor),
            notSold: false,
            noNutritions: true,
            calculatedSmallWeight: null,
            volumeFactor: null,
            smallUnitWeight: null,
            weight: null,
            numberOfSmallerUnits: null,
        };
        var newRecipeItemModels = Object.assign([], this.state.recipeItemModels);
        newRecipeItemModels.unshift(newRecipeItem);
        this.setState({
            recipeItemModels: newRecipeItemModels,
            searchTextIngridients: '',
            isOpen: false,
        }, function () {
            _this.notifySelectionChanges();
        });
    };
    RecipeEditPage.prototype.resloveSelectableUnits = function (hasSmallUnitWeight, hasVolumeFactor) {
        var weightUnits = Object.assign([], this.props.allSelectableUnits.filter(function (u) { return u.unitType === 'Weight'; }));
        if (hasSmallUnitWeight) {
            return weightUnits.concat(this.props.allSelectableUnits.filter(function (u) { return u.unitType === 'Piece'; }));
        }
        if (hasVolumeFactor) {
            return weightUnits.concat(this.props.allSelectableUnits.filter(function (u) { return u.unitType === 'Volume'; }));
        }
        return weightUnits;
    };
    RecipeEditPage.prototype.resloveDefaultUnit = function (hasSmallUnitWeight, hasVolumeFactor) {
        if (hasSmallUnitWeight) {
            return Object.assign(this.props.defaultUnits.filter(function (u) { return u.unitType === 'Piece'; })[0]);
        }
        if (hasVolumeFactor) {
            return Object.assign(this.props.defaultUnits.filter(function (u) { return u.unitType === 'Volume'; })[0]);
        }
        return Object.assign(this.props.defaultUnits.filter(function (u) { return u.unitType === 'Weight'; })[0]);
    };
    RecipeEditPage.prototype.removeRow = function (index) {
        var _this = this;
        var items = Object.assign([], this.state.recipeItemModels);
        items.splice(index, 1);
        this.setState({
            recipeItemModels: items,
        }, function () {
            _this.notifySelectionChanges();
        });
    };
    RecipeEditPage.prototype.updateIngredient_Price = function (row, value) {
        var items = this.state.recipeItemModels;
        items[row].price = value;
        items[row].originalPrice = parseFloat(value.replace(',', '.'));
        this.setState({
            recipeItemModels: items,
        });
        this.notifySelectionChanges();
    };
    RecipeEditPage.prototype.updateIngredient_Amount = function (row, value) {
        var items = this.state.recipeItemModels;
        items[row].amount = value;
        this.setState({
            recipeItemModels: items,
        });
        this.notifySelectionChanges();
    };
    RecipeEditPage.prototype.updateIngredient_UnitVariant = function (row, value) {
        var items = this.state.recipeItemModels;
        items[row].unit = this.props.allSelectableUnits.find(function (item) { return item.id === value; });
        this.setState({
            recipeItemModels: items,
        });
        this.notifySelectionChanges();
    };
    RecipeEditPage.prototype.updateIngredient_Waste = function (row, value) {
        var items = this.state.recipeItemModels;
        items[row].nutrientsWastePercent = value;
        this.setState({
            recipeItemModels: items,
        });
        this.notifySelectionChanges();
    };
    RecipeEditPage.prototype.resetList = function () {
        this.setState({
            recipeItemModels: [],
        });
        this.notifySelectionChanges();
    };
    RecipeEditPage.prototype.cancelEdit = function () {
        if (this.props.actionType === 'clone') {
            this.props.cancelEdit(this.props.recipeModel.id);
        }
        else {
            window.history.back();
        }
    };
    RecipeEditPage.prototype.handleOnClick = function (index) {
        this.setState({ listSelectionIndex: index });
    };
    RecipeEditPage.prototype.render = function () {
        var _this = this;
        var recipeModel = this.props.recipeModel;
        var ReactQuill = this.ReactQuill;
        var step = 0;
        var optionValues = recipeModel && recipeModel.groups.map(function (option) { return ({ text: option.value, value: option.id }); });
        var selectedGroup = (!!this.state.group && recipeModel.groups.find(function (group) { return group.id === _this.state.group.id; })) ||
            recipeModel.groups[0];
        // Allowed html-tags (must be allowed by BE): strong, b, i, em, strike, s, ul, u, ol, li, hr, br, p
        var quillMmodules = {
            toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
        };
        var recipeTypeTranslated = this.props.recipeModel.recipeType === 'recipe'
            ? translate('/RecipeEdit/TypeRecipe')
            : translate('/RecipeEdit/TypeMeal');
        var selectableProductIndexes = (this.state.quickSearchProducts &&
            this.state.quickSearchProducts.length > 0 &&
            this.state.quickSearchProducts.map(function (_a, i) { return i; })) ||
            [];
        return (React.createElement(Main, { className: classnames(this.props.currentBreakpoint > SMALL && styles.visibleCalc), appearance: "narrow" },
            React.createElement(Section, null,
                React.createElement(Breadcrumbs, { breadcrumbs: this.props.recipeModel.breadcrumbs || [] }),
                React.createElement("div", { className: styles.buttonSection },
                    React.createElement(MasterFor, { className: styles.masterList, isMaster: this.props.recipeModel.isMaster, slaves: this.props.recipeModel.slaves }))),
            React.createElement(Section, { className: styles.mid },
                React.createElement(Section, null,
                    this.props.actionType === 'new' ? (React.createElement("h1", null, translate('/RecipeEdit/CreateTitle', recipeTypeTranslated))) : (React.createElement("h1", null, translate('/RecipeEdit/EditTitle', recipeTypeTranslated))),
                    React.createElement("form", { onSubmit: function (e) { return _this.onSubmit(e); } },
                        ((!!this.props.canPublishPublic && !!this.props.recipeModel.isMenigo) ||
                            !!this.props.recipeModel.isMaster) && (React.createElement("div", { className: styles.inputWrapper },
                            React.createElement(Checkbox, { className: styles.checkbox, checked: this.state.isPublic, onChange: function (e) { return _this.onUpdateIsPublic(e); } }, !!this.props.canPublishPublic && !!this.props.recipeModel.isMenigo
                                ? translate('/RecipeEdit/IsPublic')
                                : translate('/RecipeEdit/Visible')))),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("label", null, translate('/RecipeEdit/NameLabel', recipeTypeTranslated)),
                            React.createElement(Input, { type: "text", placeholderText: translate('/RecipeEdit/NamePlaceholder', recipeTypeTranslated), value: this.state.name || '', autoFocus: true, onChange: function (e) { return _this.setState({ name: e.currentTarget.value }); } })),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement(Textarea, { label: translate('/RecipeEdit/DescriptionLabel'), placeholder: this.props.recipeModel.recipeType === 'recipe'
                                    ? translate('/RecipeEdit/DescriptionRecipePlaceholder')
                                    : translate('/RecipeEdit/DescriptionMealPlaceholder'), value: this.state.leadParagraph || '', className: styles.textarea, name: "Beskrivning", onChange: function (e) {
                                    return _this.setState({ leadParagraph: e.target.value });
                                } })),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("label", null, this.props.recipeModel.recipeType === 'meal' ? translate('/Meal/Group') : translate('/Recipe/Group')),
                            React.createElement(DropDown, { options: optionValues, value: selectedGroup.id, onChange: function (groupId) { return _this.onGroupChange(groupId); } })),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("label", null, translate('/RecipeEdit/AmountLabel')),
                            React.createElement("div", { className: styles.quantityWrapper },
                                React.createElement(Quantity, { update: function (numberOfPortions) { return _this.onUpdateQuantity(numberOfPortions); }, step: step, minQuantity: step, hideButtons: this.props.currentBreakpoint > SMALL ? false : true, value: this.state.numberOfPortions, useNewDesign: true }))),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("label", null, translate('/RecipeEdit/MarkLabel')),
                            React.createElement("div", { className: styles.checkboxWrapper }, recipeModel.classifications.map(function (item, i) { return (React.createElement(Checkbox, { key: item.id, className: styles.checkbox, checked: item.selected, onChange: function (e) { return _this.onUpdateClassifications(e, item); } }, item.value)); }))),
                        React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes, keepIndexOnBlur: true },
                            React.createElement(ProductCardSelection, null, function (_a) {
                                var _b;
                                var selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex, resetSelectedIndex = _a.resetSelectedIndex;
                                return (React.createElement("div", { className: styles.inputWrapper },
                                    React.createElement("h3", null, translate('/RecipeEdit/AddIngredientsLabel')),
                                    React.createElement("div", { className: styles.addIngidientWrapper },
                                        React.createElement("input", { type: "text", autoComplete: "off", placeholder: translate('/RecipeEdit/AddIngredientsPlaceholder'), ref: function (el) { return (_this.ingredientInput = el); }, defaultValue: _this.state.searchTextIngridients || '', onChange: function (e) { return _this.handleAddIngredients(e.target); }, onKeyDown: function (event) { return _this.onKeyDown(event, setSelectedIndex); }, onFocus: function () { return _this.onInputFocus(resetSelectedIndex); } }),
                                        React.createElement(Spinner, { key: "spinner", animate: _this.state.isQuickSearchLoading, className: classnames(styles.loaderSpinner, (_b = {},
                                                _b[styles.visible] = _this.state.isQuickSearchLoading,
                                                _b)) }),
                                        React.createElement(Button, { className: styles.inlineButton, disabled: !_this.state.searchTextIngridients, onClick: function () { return _this.addRecipeItem(); } },
                                            ' ',
                                            translate('/Shared/Add'))),
                                    React.createElement("div", { className: styles.autocompleteWrapper }, _this.state.isOpen && (React.createElement(AutocompletionsCustom, { thinSelection: true, className: styles.autocomplete, isLoading: _this.state.isQuickSearchLoading, products: _this.state.quickSearchProducts, onKeyDown: function (event) { return _this.onKeyDown(event); }, onClick: function (index) { return _this.handleOnClick(index); }, onFocus: function (index) { return _this.onFocus(index); }, addByIndex: function (index) { return _this.addIngredientFromQuickSearch(index); }, recipeSearch: true, selectedIndex: selectedIndex, setSelectedIndex: setSelectedIndex })))));
                            })),
                        React.createElement("div", null,
                            React.createElement(IngredientTable, { recipeItemModels: this.state.recipeItemModels, allSelectableUnits: this.props.allSelectableUnits, portionWeight: this.props.recipeModel.portionWeight, currencySymbol: this.props.currencySymbol, removeRow: function (index) { return _this.removeRow(index); }, updateIngredient_Price: function (row, e) {
                                    _this.updateIngredient_Price(row, e);
                                }, updateIngredient_Amount: function (row, e) {
                                    _this.updateIngredient_Amount(row, e);
                                }, updateIngredient_UnitVariant: function (row, e) {
                                    _this.updateIngredient_UnitVariant(row, e);
                                }, updateIngredient_Waste: function (row, e) {
                                    _this.updateIngredient_Waste(row, e);
                                }, resetList: function () { return _this.resetList(); }, focusSearchInput: function () { return _this.setFocusToIngredientInput(); } })),
                        this.state.recipeItemModels && this.state.recipeItemModels.length > 0 && (React.createElement("div", { className: styles.footNote },
                            React.createElement("sup", null, "*"),
                            translate('/RecipeEdit/Ingredients/FootNote'))),
                        React.createElement("div", { className: styles.inputWrapper, onBlur: function (e) { return _this.onBlurRecipes(e); } },
                            React.createElement("h3", null, translate('/RecipeEdit/AddRecipeLabel')),
                            React.createElement("input", { type: "text", ref: function (e) { return (_this.searchRecipeInput = e); }, placeholder: translate('/RecipeEdit/AddRecipePlaceholder'), value: this.state.searchTextRecipes || '', onChange: function (e) { return _this.handleRecipeSearch(e.target); }, onKeyDown: function (event) {
                                    _this.onKeyDownRecipe(event);
                                } }),
                            React.createElement("div", { className: styles.spinnerWrapper },
                                React.createElement(Spinner, null)),
                            !!this.state.recipeList && this.state.recipeList.length === 0 && (React.createElement("p", null, translate('/RecipeListing/EmptyResult'))),
                            !!this.state.searchTextRecipes && this.state.searchTextRecipes.length > 0 && !!this.state.recipeList && this.state.recipeList.length > 0 && (React.createElement("div", { className: styles.autocompleteWrapper },
                                React.createElement("ul", { className: styles.recipeList_autoComplete_Wrapper }, this.state.recipeList.map(function (recipe, i) {
                                    return (React.createElement("li", { key: recipe.id, onClick: function () { return _this.addItemToSelectedRecipes(i); }, className: classnames(styles.listWrapper, _this.state.currentIndexOfRecipeSearch === i ? styles.selected : null) },
                                        React.createElement("div", { className: styles.img },
                                            React.createElement(ImageFallback, { src: recipe.imageUrl, size: 'small' })),
                                        React.createElement("p", { className: styles.name }, recipe.name),
                                        React.createElement("p", { className: styles.portionCost },
                                            React.createElement(Price, __assign({ className: styles.price }, recipe.portionCost, { size: "normal" }))),
                                        React.createElement("div", { className: styles.grade },
                                            React.createElement(Grade, { grade: recipe.grade, votes: recipe.voteCount })),
                                        React.createElement("div", { className: styles.remove },
                                            recipe.isMenigo && recipe.isPublic && (React.createElement("div", null,
                                                React.createElement(Icon, { name: "lock" }))),
                                            !recipe.isMenigo && recipe.isPublic && (React.createElement("div", null,
                                                React.createElement(Icon, { name: "md-share" }))))));
                                }))))),
                        !!this.state.mealRecipeModels && this.state.mealRecipeModels.length > 0 && (React.createElement("div", null,
                            React.createElement("ul", { className: styles.listWrapper__Recipies },
                                React.createElement("li", { className: classnames(styles.listWrapper, styles.listHeader) },
                                    React.createElement("div", { className: styles.img }),
                                    React.createElement("div", { className: styles.name }, translate('/RecipeListing/Name')),
                                    React.createElement("div", { className: styles.portionCost }, translate('/RecipeListing/PortionCost')),
                                    React.createElement("div", { className: styles.grade }, translate('/RecipeListing/Grade')),
                                    React.createElement("div", { className: styles.remove })),
                                !!this.state.mealRecipeModels &&
                                    this.state.mealRecipeModels.map(function (recipe, i) {
                                        {
                                            return (React.createElement("li", { key: i, className: classnames(styles.listWrapper, !recipe.isPublic && !recipe.isOwner && styles.discontinued) },
                                                React.createElement("div", { className: styles.img },
                                                    React.createElement(ImageFallback, { src: recipe.imageUrl, size: 'small' })),
                                                React.createElement("div", { className: styles.name }, recipe.name),
                                                React.createElement("div", { className: styles.portionCost },
                                                    React.createElement(Price, __assign({ className: styles.price }, recipe.portionCost, { size: "normal" }))),
                                                React.createElement("div", { className: styles.grade },
                                                    React.createElement(Grade, { grade: recipe.grade, votes: recipe.voteCount })),
                                                React.createElement("div", { className: styles.remove },
                                                    recipe.isMenigo && recipe.isPublic && (React.createElement("div", null,
                                                        React.createElement(Icon, { name: "lock" }))),
                                                    ((!recipe.isMenigo && recipe.isPublic) || !recipe.isOwner) && (React.createElement("div", null,
                                                        React.createElement(Icon, { name: "md-share" }))),
                                                    React.createElement("div", { onClick: function () { return _this.removeSelectedRecipeRow(i); } },
                                                        React.createElement(Icon, { name: 'remove' })))));
                                        }
                                    }),
                                React.createElement("li", { className: classnames(styles.listWrapper, styles.footer) },
                                    React.createElement("a", { onClick: function () { return _this.clearSelectedRecipes(); } }, translate('/RecipeListing/Clear')))))),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("h3", null, translate('/RecipeEdit/InstructionsLabel')),
                            !isIE() && this.state.appIsMounted && ReactQuill && (React.createElement(ReactQuill, { theme: 'snow', className: styles.react_quill, value: this.state.description, onChange: function (content, delta, source, editor) {
                                    return _this.setState({ description: editor.getHTML() });
                                }, modules: quillMmodules, placeholder: translate('/RecipeEdit/InstructionsPlaceholder') })),
                            isIE() && (React.createElement(Textarea, { className: styles.textarea_description, value: this.state.description || '', placeholder: translate('/RecipeEdit/InstructionsPlaceholder'), onChange: function (e) {
                                    return _this.setState({ description: e.target.value });
                                } }))),
                        React.createElement("div", { className: styles.inputWrapper },
                            React.createElement("h3", null, translate('/RecipeEdit/AddImage')),
                            React.createElement("span", null,
                                translate('/Shared/CurrentImage'),
                                ": "),
                            React.createElement("br", null),
                            React.createElement("div", { className: styles.previewImage }, this.state.addedImage && this.state.imageFile ? (React.createElement("img", { src: this.state.imageFile.toString() })) : this.props.recipeModel.imageUrl ? (React.createElement(ImageFallback, { src: this.props.recipeModel.imageUrl, size: 'large' })) : (React.createElement("b", null, translate('/Shared/NoImageExists')))),
                            React.createElement("br", null),
                            React.createElement("div", { className: styles.dropzone }, !!this.state.addedImage ? (React.createElement("ul", null,
                                React.createElement("li", { className: styles.addedImageWrapper },
                                    React.createElement("p", null, this.state.addedImage),
                                    React.createElement("div", { className: styles.remove, onClick: function () {
                                            _this.setState({ addedImage: null });
                                        } },
                                        React.createElement(Icon, { name: "close" }))))) : (React.createElement(Dropzone, { accept: "image/jpeg, image/jpg, image/png, image/gif", multiple: false, onDrop: function (e, r) {
                                    _this.onDrop(e, r);
                                } },
                                React.createElement("div", { className: styles.dropzoneContent },
                                    React.createElement(Icon, { name: "plus-circle" }),
                                    React.createElement("p", null, translate('/RecipeEdit/DragnDropText'))))))),
                        React.createElement("div", { className: styles.buttonWrapper },
                            React.createElement(FeedbackButton, { ref: function (el) { return (_this.feedbackButton = el); }, appearance: "large", type: "submit" }, translate('/RecipeEdit/Publish')),
                            React.createElement(Button, { className: styles.rightButtonBig, appearance: "secondary", onClick: function () { return _this.cancelEdit(); } }, translate('/Shared/Abort')))))),
            this.props.currentBreakpoint > SMALL && (React.createElement(Calculation, __assign({ onUpdateCalculation: function (factor, vat) { return _this.onUpdateCalculation(factor, vat); }, recipeId: this.props.recipeModel.id, portionCost: this.state.portionCost }, this.state.calculation)))));
    };
    return RecipeEditPage;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    currencySymbol: state.cart.subTotal.currencySymbol,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    loadProductSearch: function (searchText) {
        return dispatch(loadProductSearch(searchText));
    },
    loadFilteredQuickSearchRecipes: function (searchText, recipeId) {
        return dispatch(loadFilteredQuickSearchRecipes(searchText, recipeId));
    },
    notifySelectionChangesToServer: function (recipeModel) {
        return dispatch(notifySelectionChangesToServer(recipeModel));
    },
    cancelEdit: function (id) {
        cancelEdit(id);
    },
}); })(RecipeEditPage);
