var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { currentUrl, urlToString } from 'Shared/url';
import { nuke as nukeCache } from 'SiteLayout/CurrentPage/cache';
import { triggerEvent } from 'SiteLayout/Root';
var ERROR_PARAM_NAME = 'jserror';
var ErrorBoundaryInner = /** @class */ (function (_super) {
    __extends(ErrorBoundaryInner, _super);
    function ErrorBoundaryInner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorBoundaryInner.prototype.componentDidCatch = function () {
        this.forceUpdate(); // This will cause all children to be recreated and remounted
    };
    ErrorBoundaryInner.prototype.render = function () {
        return this.props.children;
    };
    return ErrorBoundaryInner;
}(React.Component));
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        if (!__DEV__) {
            var url_1 = currentUrl();
            var match = (url_1.query[ERROR_PARAM_NAME] || '').match(/^[0-9]+$/);
            var errorState = match ? +match[0] : 0;
            switch (errorState) {
                case 0:
                    url_1.query[ERROR_PARAM_NAME] = '1';
                    window.location.replace(urlToString(url_1));
                    break;
                case 1:
                    nukeCache().then(function () {
                        url_1.query[ERROR_PARAM_NAME] = '2';
                        window.location.replace(urlToString(url_1));
                    });
                    break;
                default:
                    triggerEvent({ type: 'scope:fataljserror', error: error, info: info });
                    break;
            }
        }
    };
    ErrorBoundary.prototype.render = function () {
        return React.createElement(ErrorBoundaryInner, null, this.props.children);
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
