import React from 'react';
import * as styles from '../base.scss';
import MyMenigoIcon from '../../../Shared/MyMenigoIcon/MyMenigoIcon';
import DeliveryRow from './DeliveryRow';
var DeliveryBox = function (_a) {
    var deliveryList = _a.deliveryList, deliveryPageUrl = _a.deliveryPageUrl, showLinksToDeliveries = _a.showLinksToDeliveries, headerText = _a.headerText, footerText = _a.footerText, linkText = _a.linkText, deliveryHeader = _a.deliveryHeader;
    var footerContent = showLinksToDeliveries && (React.createElement("a", { href: deliveryPageUrl, className: styles.box__Footer__Link },
        React.createElement("span", null, linkText),
        React.createElement(MyMenigoIcon, { name: "arrow" })));
    return (React.createElement("div", { className: styles.box__Outer },
        React.createElement("h2", { className: styles.box__Title }, headerText),
        React.createElement("div", { className: styles.box__Inner },
            deliveryList && deliveryList.length
                ? deliveryList.map(function (item, index) {
                    return (React.createElement(DeliveryRow, { key: item.routeId, isToday: false, deliveryItem: item, deliveryPageUrl: deliveryPageUrl, showLinksToDeliveries: showLinksToDeliveries, showLinksToPackingSlip: false, deliveredTitleText: deliveryHeader }));
                }) : React.createElement(React.Fragment, null),
            React.createElement("div", { className: styles.box__Footer }, deliveryList && deliveryList.length ?
                footerContent : React.createElement("p", null, footerText)))));
};
export default DeliveryBox;
