import React from 'react';
import moment from 'moment';
import { Basic } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
export default (function (props) { return (React.createElement(Basic, null,
    React.createElement("table", { className: styles.base },
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", null, translate('/MyMenigo/OrderOverviewHeaders/OrderNumber')),
                React.createElement("td", null, props.model.orderNumber)),
            React.createElement("tr", null,
                React.createElement("td", null, translate('/MyMenigo/OrderOverviewHeaders/OrderDate')),
                React.createElement("td", null, moment(props.model.orderDate).format('YYYY-MM-DD HH:mm'))),
            !!props.model.orderReferenceId && (React.createElement("tr", null,
                React.createElement("td", null, translate('/MyMenigo/OrderOverviewHeaders/OrderReferenceId')),
                React.createElement("td", null, props.model.orderReferenceId))))))); });
