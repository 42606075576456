import React from 'react';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
import { Part } from 'Shared/PageLayout';
import MyMenigoIcon from '../../../Shared/MyMenigoIcon/MyMenigoIcon';
var DeliveryRow = function (_a) {
    var deliveryItem = _a.deliveryItem, deliveryPageUrl = _a.deliveryPageUrl, showLinksToDeliveries = _a.showLinksToDeliveries, showLinksToPackingSlip = _a.showLinksToPackingSlip, isToday = _a.isToday, deliveredTitleText = _a.deliveredTitleText;
    var ShowDeliveryDetails = function (row) {
        return deliveryPageUrl + (isToday ? "?" : "&") + "show=" + row.routeId + "&ref=" + (Math.random() * Math.random());
    };
    var FormatDisplayDate = function (date) {
        var displayDate = new Date(date);
        if (displayDate.getFullYear() == 1970 && !isToday)
            return new Date(deliveryItem.sortDate).toLocaleString("sv-SE", { month: "short", day: "2-digit" }).replace(/\.$/, '');
        return (displayDate.toLocaleString("sv-SE", { month: "short", day: "2-digit" }).replace(/\.$/, ''));
    };
    var FormatDisplayTime = function (date) {
        var displayDate = new Date(date);
        return (displayDate.toLocaleString("sv-SE", { hour: "2-digit", minute: "2-digit" }));
    };
    var GetDisplayDate = function (row) {
        if (!!!row.inMotion && isToday)
            return "-";
        if (!!row.deliveryDate && isToday)
            return FormatDisplayTime(row.deliveryDate);
        if (!!row.deliveryDate || (!isToday && new Date(row.deliveryDate).getFullYear() == 1970))
            return FormatDisplayDate(row.deliveryDate);
        if (!!row.deliveryWindowFrom && !!row.deliveryWindowTo && isToday)
            return FormatDisplayTime(row.deliveryWindowFrom) + "-" + FormatDisplayTime(row.deliveryWindowTo);
        return "-";
    };
    return (React.createElement(Part, { className: styles.delivery__Row },
        React.createElement(Part, { className: styles.delivery__Row__inner },
            React.createElement(Part, { className: styles.delivery__Row__inner__column },
                isToday ?
                    React.createElement("span", { className: styles.delivery__Row__inner__column__icon },
                        React.createElement(MyMenigoIcon, { name: "goodsCage" })) : null,
                React.createElement("span", { className: styles.delivery__Row__inner__column__header }, translate('/MyMenigo/Overview/NumberOfArticles')),
                React.createElement("span", { className: styles.delivery__Row__inner__column__content },
                    deliveryItem.numberOfArticles,
                    !!deliveryPageUrl && !!showLinksToDeliveries ?
                        React.createElement("a", { className: styles.delivery__Row__inner__column__link, href: ShowDeliveryDetails(deliveryItem) },
                            React.createElement("span", null, translate('/MyMenigo/Overview/ShowProducts')),
                            React.createElement(MyMenigoIcon, { name: "arrow" }))
                        : React.createElement(React.Fragment, null))),
            React.createElement(Part, { className: styles.delivery__Row__inner__column },
                isToday ?
                    React.createElement("span", { className: styles.delivery__Row__inner__column__icon },
                        React.createElement(MyMenigoIcon, { name: "clock" })) : null,
                React.createElement("span", { className: styles.delivery__Row__inner__column__header }, deliveredTitleText),
                React.createElement("span", { className: styles.delivery__Row__inner__column__content }, GetDisplayDate(deliveryItem))),
            React.createElement(Part, { className: styles.delivery__Row__inner__column },
                React.createElement("span", { className: styles.delivery__Row__inner__column__header }, translate('/MyMenigo/Overview/OrderValue')),
                React.createElement("span", { className: styles.delivery__Row__inner__column__content },
                    deliveryItem.totalOrderValue.toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    " ",
                    translate('/Price/CurrencySymbol'),
                    !!showLinksToPackingSlip &&
                        React.createElement("a", { className: styles.delivery__Row__inner__column__link, target: "_blank", href: '' },
                            React.createElement("span", null, translate('/MyMenigo/Overview/Deliveryslip')),
                            React.createElement(MyMenigoIcon, { name: "external" })))))));
};
export default DeliveryRow;
