import { pushState } from "Shared/history";
import propertyValueFor from "Shared/property-value-for";
import { parseUrl, urlToString } from "Shared/url";
export var isInQuery = function (ids) {
    var url = parseUrl(window.location);
    if (!!!url.query)
        return false;
    return ids.every(function (i) { return url.query[i]; });
};
var formatIconName = function (s) {
    if (typeof s !== 'string')
        return '';
    return s.charAt(0).toLowerCase() + s.slice(1);
};
export var isDisabled = function (facet, facetGroup, values) {
    if (!!!facetGroup || !!!facet)
        return false;
    if (!!values && values.length > 1 && !!facetGroup)
        return (!!facet ? facet.count < 1 : facetGroup.facetValues.every(function (f) { return f.count < 1; })) && facetGroup.facetValues.filter(function (x) { return values.indexOf(x.fieldName) > -1; }).every(function (x) { return x.count < 1; });
    return !!facet ? facet.count < 1 : facetGroup.facetValues.every(function (f) { return f.count < 1; });
};
var createQuickFilterBase = function (block, i) {
    var values = propertyValueFor(block.facet).split(";");
    return {
        buttonText: propertyValueFor(block.buttonText),
        id: values[0],
        values: values.length > 1 ? values : null,
        icon: !!propertyValueFor(block.icon) ? formatIconName(propertyValueFor(block.icon)) : "search",
        sortorder: i
    };
};
var createQuickFilter = function (filterBase, facetGroup, facet) {
    return {
        sortorder: filterBase.sortorder,
        selected: (!!filterBase.values && isInQuery(filterBase.values)) || (!!facet ? facet.selected : facetGroup.facetValues.every(function (f) { return f.selected; })),
        facetGroup: facetGroup,
        facet: facet,
        values: filterBase.values,
        buttonText: filterBase.buttonText,
        icon: filterBase.icon,
        disabled: isDisabled(facet, facetGroup, filterBase.values)
    };
};
export var getQuickFilterBlocks = function (quickFilterBlocks) {
    var quickFilters = propertyValueFor(quickFilterBlocks);
    if (!!quickFilters && !!quickFilters.items) {
        var items = quickFilters.items.map(function (block, i) { return (createQuickFilterBase(block, i)); });
        return items;
    }
    return [];
};
export var getValidQuickFilters = function (products, facetsToShow) {
    var buttons = products.facets.filter(function (x) { return x.facetValues.length >= 0 && facetsToShow.some(function (f) { return f.id == x.displayName; }); }).map(function (facetGroup, i) { return (createQuickFilter(facetsToShow.filter(function (f) { return f.id == facetGroup.displayName; })[0], facetGroup, null)); });
    products.facets.filter(function (x) { return x.facetValues.length >= 0; }).forEach(function (facetGroup, i) { return (facetGroup.facetValues.filter(function (y) { return y.count >= 0 && facetsToShow.some(function (f) { return f.id.split(";").indexOf(y.text) > -1; }); }).forEach(function (facet, i) { return (buttons.push(createQuickFilter(facetsToShow.filter(function (f) { return f.id == facet.text; })[0], facetGroup, facet))); })); });
    return buttons.sort(function (a, b) { return a.sortorder < b.sortorder ? -1 : 0; });
};
var handleSpecialCharacters = function (str) {
    str = str.replace(new RegExp("\\)", 'g'), "\\)");
    str = str.replace(new RegExp("\\(", 'g'), "\\(");
    return str;
};
export var updateUrlParameter = function (uri, key, value, multi, removeValue) {
    var i = uri.indexOf('#');
    var hash = i === -1 ? '' : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (value === null || !!removeValue) {
        uri = !!!removeValue ? uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", 'i'), '') : uri.replace(new RegExp("([?&]?)" + key + "=" + handleSpecialCharacters(encodeURIComponent(removeValue)), 'i'), '');
        ;
        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1);
        }
        if (uri.indexOf('?') === -1)
            uri = uri.replace(/&/, '?');
    }
    else if (uri.match(re) && !!!multi) {
        uri = uri.replace(re, "$1" + key + "=" + value + "$2");
    }
    else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
};
export var handleQuickFilterClick = function (item, remove) {
    var url = parseUrl(window.location);
    if (!!!item.facetGroup)
        return;
    if (!!item.values && item.values.length > 1) {
        var multiurl = urlToString(url);
        for (var index = 0; index < item.values.length; index++) {
            var extraFacet = item.values[index];
            multiurl = updateUrlParameter(multiurl, item.facetGroup.fieldName, remove ? null : extraFacet, true, remove ? extraFacet : null);
        }
        pushState(urlToString(multiurl));
    }
    else if (!!!item.facet) {
        var multiurl = urlToString(url);
        for (var index = 0; index < item.facetGroup.facetValues.length; index++) {
            var groupedFacet = item.facetGroup.facetValues[index];
            multiurl = updateUrlParameter(multiurl, groupedFacet.fieldName, remove ? null : groupedFacet.id, true, remove ? groupedFacet.id : null);
        }
        pushState(urlToString(multiurl));
    }
    else {
        var newUrl = updateUrlParameter(urlToString(url), item.facet.fieldName, item.facet.id, (item.facetGroup.fieldName == item.facet.fieldName), remove ? item.facet.id : null);
        pushState(urlToString(newUrl));
    }
};
