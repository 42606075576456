import React from 'react';
import { translate } from 'Shared/translate';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import classnames from 'classnames';
var getDisabledInfo = function (_a) {
    var isDiscontinued = _a.isDiscontinued, isDeletedByMaster = _a.isDeletedByMaster, isBackorderReplaced = _a.isBackorderReplaced, isTender = _a.isTender, isPantry = _a.isPantry, isDisabledForFreCustomer = _a.isDisabledForFreCustomer, useShort = _a.useShort;
    if (isPantry && isDeletedByMaster) {
        return translate('/ProductListing/DeletedByMaster');
    }
    else if (isDiscontinued && useShort) {
        return translate('/Product/DiscontinuedShort');
    }
    else if (isDiscontinued) {
        return translate('/Product/Discontinued');
    }
    else if (isBackorderReplaced) {
        return translate('/Product/BackorderReplaced');
    }
    else if (!isPantry && isTender) {
        return translate('/Tender/ClientInformation');
    }
    else if (isDisabledForFreCustomer) {
        return translate('/Product/DisabledForFreCustomer');
    }
};
var getReplacementInfo = function (_a) {
    var isDiscontinued = _a.isDiscontinued, isBackorderReplaced = _a.isBackorderReplaced, replacementProduct = _a.replacementProduct, recommendedReplacementProduct = _a.recommendedReplacementProduct, isTender = _a.isTender, isPantry = _a.isPantry, isDisabledForFreCustomer = _a.isDisabledForFreCustomer, useShort = _a.useShort;
    if (isDiscontinued || isBackorderReplaced || isDisabledForFreCustomer) {
        if (replacementProduct && useShort) {
            return translate('/Product/RecommendedReplacementProductShort');
        }
        else if (replacementProduct) {
            return translate('/Product/ReplacementProduct');
        }
        else if (recommendedReplacementProduct && useShort) {
            return translate('/Product/RecommendedReplacementProductShort');
        }
        else if (recommendedReplacementProduct) {
            return translate('/Product/RecommendedReplacementProduct');
        }
        else {
            if (isDisabledForFreCustomer) {
                return;
            }
            return translate('/Product/NoRecommendedReplacementProduct');
        }
    }
    else if (!isPantry && isTender) {
        return translate('/Tender/CanOnlyBeSentThroughTender');
    }
    else {
        return translate('/Product/NoRecommendedReplacementProduct');
    }
};
var ProductDisabledInfo = function (props) {
    var _a, _b;
    return (React.createElement("div", { className: styles.wrap },
        !props.hideInfo && (React.createElement("div", { className: classnames(styles.disabledInfo, (_a = {}, _a[styles.useNewDesign] = props.useNewDesign, _a)) }, getDisabledInfo(props))),
        !props.hideButton && (React.createElement("div", { className: classnames(styles.replacementInfo, (_b = {}, _b[styles.useNewDesign] = props.useNewDesign, _b)) },
            !props.isDeletedByMaster && !props.useNewDesign && getReplacementInfo(props),
            props.replacementProduct ? (React.createElement(React.Fragment, null,
                !props.useNewDesign ? ': ' : '',
                React.createElement("a", { href: props.replacementProduct.url.toString(), className: styles.replacementLink, "data-ticket": props.replacementProduct.ticket },
                    props.useNewDesign && (React.createElement(Button, { appearance: ['small', 'secondary', 'wide'] }, !props.isDeletedByMaster && getReplacementInfo(props))),
                    !props.useNewDesign && (translate('/ProductListing/ArticleNumber') + " " + propertyValueFor(props.replacementProduct.content.code))))) : props.recommendedReplacementProduct ? (React.createElement(React.Fragment, null,
                !props.useNewDesign ? ': ' : '',
                React.createElement("a", { href: props.recommendedReplacementProduct.url.toString(), className: styles.replacementLink, "data-ticket": props.recommendedReplacementProduct.ticket },
                    props.useNewDesign && (React.createElement(Button, { appearance: ['small', 'secondary', 'wide'] }, !props.isDeletedByMaster && getReplacementInfo(props))),
                    !props.useNewDesign && (translate('/ProductListing/ArticleNumber') + " " + propertyValueFor(props.recommendedReplacementProduct.content.code))))) : null))));
};
export default ProductDisabledInfo;
