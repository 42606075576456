var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import Checkbox from 'Shared/Fields/Checkbox';
import RemoveList from './RemoveList';
import Body from './Body';
import RecipeSearch from './RecipeSearch';
import * as styles from './base.scss';
import Modal from 'Shared/Modal';
import connect from 'Shared/connect';
import ShoppingList from '../../../../Recipes/ShoppingList';
import { expandList, checkList, getShoppinglist } from '../../../action-creators';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import Icon from 'Shared/Icon';
import { timeout } from 'Shared/timeout';
import Spinner from 'Shared/Spinner';
var RecipeMenuHeader = /** @class */ (function (_super) {
    __extends(RecipeMenuHeader, _super);
    function RecipeMenuHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.showShoppinglistModal = function () {
            _this.setState({ isShoppingListModalOpen: true });
        };
        _this.hideShoppinglistModal = function () {
            _this.setState({ isShoppingListModalOpen: false }, function () {
                timeout(300).then(function () {
                    _this.setState({ currentShoppinglist: null });
                });
            });
        };
        _this.closeList = function () {
            _this.setState({ isOpen: !_this.state.isOpen });
        };
        _this.state = {
            isLoading: false,
            isLoaded: false,
            isOpen: false,
            isChecked: false,
            searchingForUsers: false,
            currentShoppinglist: null,
            isShoppingListModalOpen: false,
        };
        return _this;
    }
    RecipeMenuHeader.prototype.getDetails = function (listId) {
        var _this = this;
        if (!this.state.isOpen && !this.state.isLoaded) {
            this.setState({ isLoading: true });
            return this.props.expandList(listId).then(function () {
                _this.setState({ isOpen: true, isLoaded: true, isLoading: false });
            });
        }
        else {
            this.setState(function (state) { return ({ isOpen: !state.isOpen }); });
        }
    };
    RecipeMenuHeader.prototype.toggleListSelection = function (listId) {
        this.props.checkList(listId, !this.state.isChecked);
        this.setState({
            isChecked: !this.state.isChecked,
        });
    };
    RecipeMenuHeader.prototype.onAddtoShoppinglistClick = function (id) {
        var _this = this;
        return this.props.getShoppinglist(id).then(function (result) {
            _this.setState({ currentShoppinglist: result }, function () {
                _this.showShoppinglistModal();
            });
        });
    };
    RecipeMenuHeader.prototype.render = function () {
        var _this = this;
        var _a = this.props, displayName = _a.displayName, id = _a.id, items = _a.items;
        var removeitemOrCollapseNode = this.state.isOpen ? (React.createElement(Button, { appearance: 'clear', onClick: this.closeList },
            React.createElement(Icon, { name: "arrow-up" }))) : (React.createElement(RemoveList, { listId: this.props.id, name: this.props.displayName, className: styles.remove }));
        var headerInfo = this.props.appearance === 'list' && (React.createElement("div", { className: styles.tableheader },
            React.createElement("div", { className: styles.col0 },
                React.createElement("span", { className: styles.textWrapper }, translate('/RecipeListing/Name'))),
            this.props.isLoggedIn && (React.createElement("div", { className: classnames(styles.col1) }, translate('/RecipeListing/PortionCost'))),
            React.createElement("div", { className: classnames(styles.portionsHeader) }, translate('/RecipeListing/Portions')),
            React.createElement("div", { className: classnames(styles.col2) }, translate('/RecipeListing/Grade')),
            React.createElement("div", { className: classnames(styles.col3) })));
        return (React.createElement("div", { className: styles.Header },
            this.state.currentShoppinglist && (React.createElement(Modal, { title: translate('/Recipe/ShoppingList', this.state.currentShoppinglist.recipeName), isOpen: this.state.isShoppingListModalOpen, onClose: this.hideShoppinglistModal },
                React.createElement(ShoppingList, __assign({}, this.state.currentShoppinglist, { hideModal: this.hideShoppinglistModal })))),
            React.createElement("div", { className: styles.innerHeader },
                React.createElement(Checkbox, { className: styles.checkBox, checked: this.state.isChecked, onChange: function () { return _this.toggleListSelection(id); } }),
                React.createElement(Button, { appearance: "bare", onClick: function () { return _this.getDetails(id); }, className: styles.displayName },
                    React.createElement("h3", { className: styles.title }, displayName),
                    ' ',
                    this.state.isLoading && React.createElement(Spinner, { className: styles.spinner, animate: true })),
                removeitemOrCollapseNode),
            React.createElement("div", { className: styles.table },
                this.state.isOpen && (React.createElement("div", { className: styles.recipeSearch },
                    React.createElement(RecipeSearch, { id: id }))),
                items && this.state.isOpen && headerInfo,
                items && this.state.isOpen && (React.createElement("div", null,
                    items.map(function (item) { return (React.createElement(Body, __assign({ key: "RecipeMenuBody" + item.id }, item, { appearance: _this.props.appearance }))); }),
                    items && this.state.isOpen && (React.createElement("div", { className: styles.AddToCartWrapper },
                        React.createElement(FeedbackButton, { appearance: 'secondary', onClickWithFeedback: function (e, activate) { return activate(_this.onAddtoShoppinglistClick(id)); } }, translate('/AddOrder/AddToCart')))))))));
    };
    return RecipeMenuHeader;
}(React.Component));
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch, ownProps) { return ({
    expandList: function (listId) { return dispatch(expandList(listId)); },
    checkList: function (listId, checked) {
        dispatch(checkList(listId, checked));
    },
    getShoppinglist: function (portion) {
        return dispatch(getShoppinglist(portion));
    },
}); })(RecipeMenuHeader);
