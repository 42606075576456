import { getSizes } from 'Shared/ImageSizes';
var sizes = getSizes();
// The url key here is the url to the raw original without dimension query strings
var loadedImages = {};
export function hasOtherSizeLoaded(url, size, minSize) {
    if (minSize === void 0) { minSize = undefined; }
    if (!loadedImages[url]) {
        return false;
    }
    var otherLoadedSizes = loadedImages[url].filter(function (i) { return i.size !== size; });
    if (minSize) {
        return otherLoadedSizes.some(function (i) { return sizes[i.size] >= sizes[minSize]; });
    }
    else {
        return otherLoadedSizes.length > 0;
    }
}
// Real url is the currentSrc property. We might think that we're displaying a certain size
// but the browser will pick a different one depending on pixel density
export function registerLoaded(url, realUrl, size) {
    if (!loadedImages[url]) {
        loadedImages[url] = [];
    }
    if (!loadedImages[url].some(function (i) { return i.size === size; })) {
        loadedImages[url].push({
            size: size,
            url: realUrl,
        });
    }
}
export function getBestCachedImageUrl(url) {
    if (!loadedImages[url] || !loadedImages[url].length) {
        console.warn('The image for the url ' + url + ' have not been preloaded');
        return '';
    }
    var loadedSizes = loadedImages[url].slice();
    loadedSizes.sort(function (a, b) {
        return sizes[b.size] - sizes[a.size];
    });
    return loadedSizes[0].url;
}
export default (function (url, size, sourceSet) {
    return new Promise(function (resolve, reject) {
        var image = document.createElement('img');
        var removed = false;
        var sizes = getSizes();
        image.src = url;
        if (sourceSet) {
            image.srcset = sourceSet;
        }
        image.style.visibility = 'hidden';
        image.style.position = 'absolute';
        image.style.top = '0';
        image.style.left = '-9999px';
        if (size) {
            image.sizes = sizes[size] + 'px';
        }
        image.onload = function () {
            registerLoaded(url, image.currentSrc, size);
            if (!removed) {
                document.body.removeChild(image);
                removed = true;
            }
            resolve();
        };
        image.onerror = function () {
            if (!removed) {
                document.body.removeChild(image);
                removed = true;
            }
            reject("Could not load image: " + url);
        };
        document.body.appendChild(image);
    });
});
