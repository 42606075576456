export var OPEN_MAINMENU = 'OPEN_MAINMENU';
export var CLOSE_MAINMENU = 'CLOSE_MAINMENU';
export var TOGGLE_MAINMENU = 'TOGGLE_MAINMENU';
export function setMainMenu(open) {
    return {
        type: open ? OPEN_MAINMENU : CLOSE_MAINMENU,
    };
}
export function toggleMainMenu() {
    return {
        type: TOGGLE_MAINMENU,
    };
}
