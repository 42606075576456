import { isBrowser } from 'Shared/device-type';
import * as Root from 'SiteLayout/Root';
var isBound = false;
export function bindInfiniteScroll(element, callback) {
    if (isBrowser() && !isBound) {
        var listener = function (event) { return handleScroll(event, element, callback); };
        var unbindRootScroll_1 = Root.on('scroll', listener);
        isBound = true;
        return function () {
            unbindRootScroll_1();
            isBound = false;
        };
    }
}
function handleScroll(event, element, callback) {
    var clientHeight = document.documentElement.clientHeight;
    var elementPositionY = element.offsetTop - element.scrollTop + element.clientTop;
    var scrollPos;
    if (document.documentElement.scrollTop) {
        scrollPos = document.documentElement.scrollTop;
    }
    else {
        scrollPos = window.pageYOffset;
    }
    if (elementPositionY <= scrollPos + clientHeight) {
        callback();
    }
}
