var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import Modal from 'Shared/Modal';
import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import MyMenigoBasicLayout from '../../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout';
import AccountForm from './../AccountForm';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import { replaceState } from 'Shared/history';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var LoginAccountsPage = /** @class */ (function (_super) {
    __extends(LoginAccountsPage, _super);
    function LoginAccountsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.hideEditAccountModal = function () {
            _this.setState({ isEditAccountOpen: false });
        };
        _this.showNewAccountModal = function () {
            _this.setState({ isNewAccountOpen: true, currentAccount: null });
        };
        _this.hideNewAccountModal = function () {
            _this.setState({ isNewAccountOpen: false });
        };
        _this.onFormSucess = function () {
            replaceState();
            _this.setState({ isEditAccountOpen: false, isNewAccountOpen: false });
        };
        _this.state = {
            isNewAccountOpen: false,
            isEditAccountOpen: false,
            currentAccount: null,
            accountList: props.accountList,
        };
        return _this;
    }
    LoginAccountsPage.prototype.showEditAccountModal = function (accountModel) {
        this.setState({ isEditAccountOpen: true, currentAccount: accountModel });
    };
    LoginAccountsPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (!shallowEquals(this.props.accountList, nextProps.accountList)) {
            this.setState({ accountList: nextProps.accountList });
        }
    };
    LoginAccountsPage.prototype.remove = function (index) {
        var _this = this;
        var account = this.state.accountList[index];
        var accountList = this.state.accountList.filter(function (account, i) { return i !== index; });
        return server
            .postJson(pathCombine(location.pathname, 'Remove'), account)
            .then(function (result) {
            if (!result.success) {
                return Promise.reject(result);
            }
            else {
                _this.setState({ accountList: accountList });
                replaceState();
                return Promise.resolve();
            }
        })
            .catch(function (err) {
            _this.props.setFeedback({ text: err.message || translate('/MyMenigo/Accounts/Unknown'), type: 'error' });
            return Promise.reject(null);
        });
    };
    LoginAccountsPage.prototype.render = function () {
        var _this = this;
        var contactId = this.props.contactId;
        return (React.createElement(MyMenigoBasicLayout, { model: this.props },
            React.createElement("div", { className: styles.addButton },
                React.createElement(Button, { onClick: this.showNewAccountModal, appearance: 'primary' }, translate('/MyMenigo/Accounts/Add'))),
            React.createElement("table", { className: styles.list },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, translate('/MyMenigo/Accounts/AccountName')),
                        React.createElement("th", null, translate('/MyMenigo/Accounts/OrderReferenceId')),
                        React.createElement("th", null, translate('/MyMenigo/Accounts/Email')),
                        React.createElement("th", null, translate('/MyMenigo/Accounts/FirstName')),
                        React.createElement("th", null, translate('/MyMenigo/Accounts/LastName')),
                        React.createElement("th", null))),
                React.createElement("tbody", null, (this.state.accountList || []).map(function (account, i) {
                    var userName = contactId + account.userSuffix;
                    return (React.createElement("tr", { key: userName },
                        React.createElement("td", { "data-label": translate('/MyMenigo/Accounts/AccountName') },
                            React.createElement(Button, { appearance: "link", className: styles.userName, onClick: function () { return _this.showEditAccountModal(account); } }, userName)),
                        React.createElement("td", { "data-label": translate('/MyMenigo/Accounts/OrderReferenceId') }, account.orderReferenceId),
                        React.createElement("td", { "data-label": translate('/MyMenigo/Accounts/Email') }, account.email),
                        React.createElement("td", { "data-label": translate('/MyMenigo/Accounts/FirstName') }, account.firstName),
                        React.createElement("td", { "data-label": translate('/MyMenigo/Accounts/LastName') }, account.lastName),
                        React.createElement("td", { className: styles.right },
                            React.createElement(FeedbackButton, { appearance: "clear", onClickWithFeedback: function (e, activate) { return activate(_this.remove(i)); } },
                                React.createElement(MyMenigoIcon, { name: 'trash' })))));
                }))),
            React.createElement(Modal, { title: translate('/MyMenigo/Accounts/Add'), isOpen: this.state.isNewAccountOpen, onClose: this.hideNewAccountModal },
                React.createElement(AccountForm, { action: "new", account: this.state.currentAccount, contactId: this.props.contactId, cancel: this.hideNewAccountModal, onSuccess: this.onFormSucess })),
            React.createElement(Modal, { title: translate('/MyMenigo/Accounts/Edit'), isOpen: this.state.isEditAccountOpen, onClose: this.hideEditAccountModal },
                React.createElement(AccountForm, { action: "edit", account: this.state.currentAccount, contactId: this.props.contactId, cancel: this.hideEditAccountModal, onSuccess: this.onFormSucess }))));
    };
    return LoginAccountsPage;
}(React.PureComponent));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(LoginAccountsPage);
