import React from 'react';
import FreightList from './Freight';
import DiscountList from './Discount';
var DiscountInfo = function (props) {
    var discountList = props.discountLimits.filter(function (d) { return d.discountType === 'discount'; });
    var freightList = props.discountLimits.filter(function (d) { return d.discountType === 'freight'; });
    return (React.createElement("div", null, (discountList.length > 0 || freightList.length > 0) && (React.createElement("div", null,
        discountList.length > 0 && React.createElement(DiscountList, { list: discountList }),
        freightList.length > 0 && React.createElement(FreightList, { list: freightList })))));
};
export default DiscountInfo;
