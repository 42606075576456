import React from 'react';
import { Desktop } from './desktop';
import { Mobile } from './mobile';
import { translate } from 'Shared/translate';
import AddToButton from '../AddOrder/AddToButton';
export var OrderProduct = function (props) {
    var unitNames = {
        st: translate('/Units/UnitNames/st'),
        frp: translate('/Units/UnitNames/frp'),
        kg: translate('/Units/UnitNames/kg'),
        kgd: translate('/Units/UnitNames/kgd'),
    };
    var productList = props.orderProducts &&
        props.orderProducts.map(function (i, index) {
            var selectedVariant = {
                code: i.articleNumber,
                price: {
                    current: i.price,
                    original: i.price,
                    comparison: i.price,
                    purchasePrice: i.price,
                    smallestUnitPrice: i.price,
                    comparisonPriceUnit: i.unit,
                    currency: 'SEK',
                    priceUnit: null,
                    currencySymbol: 'kr',
                    showAsSmallestUnitPrice: null,
                },
                ticket: null,
                gtin: i.articleNumber,
                packagingWeight: null,
                priceComparisionWeight: null,
                comparisonPriceUnit: null,
                unitShortName: null,
                availability: null,
                storeUnit: {
                    isWeight: null,
                    alcoholTax: null,
                    specification: null,
                    numberOfSmallerUnits: null,
                    weight: null,
                    minimumWeight: null,
                    minimumWeightUnit: null,
                    multiple: null,
                    outerPackage: null,
                    divisibility: null,
                    netNetPrice: null,
                },
                emvPriceDifference: null,
            };
            var OrderProductComponent = props.isMobile ? Mobile : Desktop;
            return (React.createElement(OrderProductComponent, { key: index, currentTab: props.currentTab, product: i, selectedItems: props.selectedItems, currentGrouping: props.currentGrouping, isSelectable: props.isSelectable, selectedVariant: selectedVariant, index: index, addToSelectedItems: function (e) { return props.addToSelectedItems(e, i); }, unit: unitNames[i.unit.toLowerCase()] }));
        });
    return (React.createElement(React.Fragment, null,
        productList,
        props.isSelectable && props.currentTab === 'historyOrders' ? (React.createElement(AddToButton, { selectedItems: props.selectedItems })) : null));
};
