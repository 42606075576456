import MyMenigoIcon from "../../../../Shared/MyMenigoIcon/MyMenigoIcon";
import React from "react";
import Button from "Shared/Button";
import { Part } from "Shared/PageLayout";
import * as styles from '../../base.scss';
import { ListType } from "../DeliveryList/DeliveryList";
import ToolTip from "../Tooltip/ToolTip";
import { translate } from "Shared/translate";
var ToolBar = function (_a) {
    var toolTipText = _a.toolTipText, persistentToolTip = _a.persistentToolTip, listType = _a.listType, setListType = _a.setListType, print = _a.print;
    var isPlanned = function () {
        return (listType === ListType.Planned);
    };
    var doPrint = function () {
        print();
    };
    return (React.createElement(Part, { className: styles.toolbar },
        React.createElement(Part, { className: styles.toolbar__LeftSection },
            React.createElement(Button, { className: (!isPlanned() ? styles.toolbarbutton__Outline : styles.toolbarbutton), appearance: (isPlanned() ? 'primary' : 'clear'), onClick: function () { setListType(ListType.Planned); } }, translate("/MyMenigo/DeliveryBlock/Planned")),
            React.createElement(Button, { className: (isPlanned() ? styles.toolbarbutton__Outline : styles.toolbarbutton), appearance: (!isPlanned() ? 'primary' : 'clear'), onClick: function () { setListType(ListType.Completed); } }, translate("/MyMenigo/DeliveryBlock/Completed"))),
        isPlanned() && React.createElement(Part, { className: styles.toolbar__RightSection },
            React.createElement(ToolTip, { persistent: persistentToolTip, text: toolTipText, direction: "top" },
                React.createElement(Button, { className: styles.Print__Link, appearance: "link", onClick: function () { doPrint(); } },
                    React.createElement(MyMenigoIcon, { name: "print" }),
                    translate("/MyMenigo/DeliveryBlock/Print/Print"))))));
};
export default ToolBar;
