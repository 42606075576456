var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState, useEffect } from 'react';
import connect from 'Shared/connect';
import Tooltip from 'Shared/Tooltip';
import classnames from 'classnames';
import * as styles from './base.scss';
import { MEDIUM } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
var ProductTypeAndUnit = function (_a) {
    var _b;
    var selectedVariant = _a.selectedVariant, variants = _a.variants, productArea = _a.productArea, productIsInCampaign = _a.productIsInCampaign, isWeightProduct = _a.isWeightProduct, discontinuedOrBackordered = _a.discontinuedOrBackordered, isMobile = _a.isMobile, tooltipAlignmentHorizontal = _a.tooltipAlignmentHorizontal, tooltipAlignmentVertical = _a.tooltipAlignmentVertical, isLoggedIn = _a.isLoggedIn, hideType = _a.hideType, isEmvProduct = _a.isEmvProduct, showPrice = _a.showPrice, isRecommendations = _a.isRecommendations, isPantry = _a.isPantry, isRur = _a.isRur;
    if (discontinuedOrBackordered) {
        return null;
    }
    var _c = __read(useState(false), 2), showTooltip = _c[0], toggleShowTooltip = _c[1];
    var tooltipNode = useRef();
    var openTooltip = function () {
        toggleShowTooltip(true);
        document.addEventListener('click', didClickOutside);
    };
    var closeTooltip = function () {
        document.removeEventListener('click', didClickOutside);
        toggleShowTooltip(false);
    };
    var didClickOutside = function (evt) {
        if (!tooltipNode.current) {
            return;
        }
        if (!tooltipNode.current.contains(evt.target)) {
            closeTooltip();
        }
    };
    useEffect(function () {
        return function () { return closeTooltip(); };
    }, []);
    var getPackagingWeightInfo = function (isWeightProduct, packagingWeight, unitShortName, variants) {
        if (isWeightProduct) {
            if (variants.length > 1) {
                return variants.map(function (variant, i) { return (React.createElement("div", { key: i }, translate('/Product/PackagingWeight') + " " + variant.storeUnit.weight + "kg / " + (variant.unitShortName === 'KG' ? 'FRP' : 'ST'))); });
            }
            return variants.map(function (variant, i) { return (React.createElement("div", { key: i }, translate('/Product/PackagingWeight') + " " + variant.storeUnit.weight + "kg / " + (variant.unitShortName === 'KG' ? 'FRP' : 'ST'))); });
        }
        else if (!isWeightProduct) {
            return React.createElement("div", null, translate('/Product/PackagingWeight') + " " + packagingWeight + "kg / " + unitShortName);
        }
    };
    var renderVariants = isWeightProduct ? variants : [selectedVariant];
    var alignUnitWithProductPrice = isWeightProduct && !isEmvProduct && variants.length > 1;
    var rurInformation = (React.createElement("div", { className: styles.rurInformation },
        translate("/Product/Multiple"),
        " ",
        selectedVariant.storeUnit.multiple == 0 ? 1 : selectedVariant.storeUnit.multiple,
        " ",
        translate("/Units/UnitShortNames/st")));
    return !!isRur ? (rurInformation) : (React.createElement("div", { className: classnames(styles.typeAndUnit, (_b = {},
            // If isWeightProduct and 2 variants (KG/KGD) are rendered,
            // make first unit tall to align with ProductPrice component
            _b[styles.tallUnit] = alignUnitWithProductPrice,
            _b[styles.emvCardUnit] = isEmvProduct,
            _b[styles.isLoggedIn] = isLoggedIn,
            _b)), ref: tooltipNode, onClick: isLoggedIn ? openTooltip : undefined },
        !!showPrice && !isRecommendations && (React.createElement("div", { className: classnames(styles.priceWrap) },
            React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: variants, productIsInCampaign: productIsInCampaign, isWeightProduct: isWeightProduct, showOriginal: isPantry, isPantry: isPantry }))),
        renderVariants.map(function (_a) {
            var _b;
            var code = _a.code, storeUnit = _a.storeUnit, unitShortName = _a.unitShortName, price = _a.price, packagingWeight = _a.packagingWeight;
            return (React.createElement("div", { key: code, className: classnames(styles.unit, (_b = {},
                    _b[styles.pantryCardUnit] = showPrice,
                    // If isWeightProduct and 2 variants (KG/KGD) are rendered,
                    // and we have a campaign price set for the product,
                    // make first unit taller to align with ProductPrice component
                    _b[styles.tallerUnit] = alignUnitWithProductPrice && productIsInCampaign && price && price.current < price.original || (!!price && !!price.showAsSmallestUnitPrice),
                    _b)) },
                (productArea != "Utrustning") && unitShortName + " " + (!!storeUnit && storeUnit.specification),
                (productArea == "Utrustning") && (rurInformation)));
        }),
        !hideType && !!productArea && React.createElement("div", { className: styles.type }, productArea),
        showTooltip && (React.createElement(Tooltip, { close: closeTooltip, closeOnClick: true, horizontal: tooltipAlignmentHorizontal ? tooltipAlignmentHorizontal : isMobile ? 'left' : 'right', vertical: tooltipAlignmentVertical ? tooltipAlignmentVertical : isMobile ? 'bottom' : 'top' },
            React.createElement("div", { className: styles.tooltip }, getPackagingWeightInfo(isWeightProduct, selectedVariant.packagingWeight, selectedVariant.unitShortName, variants))))));
};
export default connect(function (state) { return ({
    isMobile: state.currentBreakpoint < MEDIUM,
    isLoggedIn: state.currentUser.isLoggedIn,
}); })(ProductTypeAndUnit);
