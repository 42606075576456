var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './calculation.scss';
import Price from './../../Features/Shared/Price';
import { Input } from 'Shared/Fields';
import shallowEquals from 'Shared/shallow-equals';
import { post } from 'Shared/server';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
var debounceMs = 500;
var Calculation = /** @class */ (function (_super) {
    __extends(Calculation, _super);
    function Calculation(props) {
        var _this = _super.call(this, props) || this;
        var portioncost = props.portionCost.current;
        if (!isNaN(props.factor) && !!props.factor && props.factor !== 0 && !!portioncost && portioncost !== 0) {
            var vatpercent = !!isNaN(props.vat) ? 0 : props.vat;
            var costpercent = 100 / props.factor;
            var calcprice = portioncost * props.factor;
            var vat = (calcprice * vatpercent) / 100;
            var customerprice = calcprice + vat;
            _this.state = {
                factor: props.factor,
                vatpercent: props.vat,
                proxyfactor: !props.factor ? '' : props.factor.toString().replace('.', ','),
                proxyvatpercent: !props.vat ? '' : props.vat.toString().replace('.', ','),
                costpercent: costpercent,
                calcprice: calcprice,
                vat: Math.round(100 * vat) / 100,
                customerprice: customerprice,
                proxycostpercent: costpercent.toFixed().replace('.', ','),
                proxycalcprice: calcprice.toFixed(2).replace('.', ','),
                proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
            };
        }
        else {
            _this.state = {
                factor: props.factor,
                vatpercent: props.vat,
                proxyfactor: !props.factor ? '' : props.factor.toString().replace('.', ','),
                proxyvatpercent: !props.vat ? '' : props.vat.toString().replace('.', ','),
            };
        }
        return _this;
    }
    Calculation.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps) || !shallowEquals(this.state, nextState);
        if (!shouldUpdate) {
            console.info('Ignoring update to Calculation');
        }
        return shouldUpdate;
    };
    Calculation.prototype.componentWillReceiveProps = function (nextProps) {
        var recalc = false;
        var factor;
        var vatpercent;
        if (nextProps.portionCost !== this.props.portionCost) {
            recalc = true;
            factor = this.state.factor;
            vatpercent = this.state.vatpercent;
        }
        if (nextProps.factor !== this.props.factor || nextProps.vat !== this.props.vat) {
            recalc = true;
            factor = nextProps.factor;
            vatpercent = nextProps.vat;
        }
        if (recalc) {
            if (!isNaN(factor) &&
                !!factor &&
                factor !== 0 &&
                !!nextProps.portionCost.current &&
                nextProps.portionCost.current !== 0) {
                var costpercent = 100 / factor;
                var calcprice = nextProps.portionCost.current * factor;
                var vat = !!isNaN(vatpercent) ? 0 : (calcprice * vatpercent) / 100;
                var customerprice = calcprice + vat;
                this.setState({
                    factor: factor,
                    vatpercent: vatpercent,
                    costpercent: costpercent,
                    calcprice: calcprice,
                    vat: Math.round(100 * vat) / 100,
                    proxyfactor: (Math.round(100 * factor) / 100).toString().replace('.', ','),
                    proxyvatpercent: vatpercent.toString().replace('.', ','),
                    customerprice: customerprice,
                    proxycostpercent: costpercent.toFixed().replace('.', ','),
                    proxycalcprice: calcprice.toFixed(2).replace('.', ','),
                    proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
                });
            }
        }
    };
    Calculation.prototype.updateCalculationFields = function (costpercent, factor, calcprice, vat, customerprice) {
        var _this = this;
        this.setState({
            costpercent: costpercent,
            factor: Math.round(100 * factor) / 100,
            calcprice: calcprice,
            vat: Math.round(100 * vat) / 100,
            customerprice: customerprice,
        });
        if (!!this.props.isLoggedIn &&
            !!this.props.recipeId &&
            (!!this.state.factor || (!!this.state.vatpercent && this.state.vatpercent !== 12))) {
            clearTimeout(this.timer);
            this.timer = setTimeout(function () {
                if (!!_this.props.isLoggedIn &&
                    !!_this.props.recipeId &&
                    (!!_this.state.factor || (!!_this.state.vatpercent && _this.state.vatpercent !== 12))) {
                    post(pathCombine('/recipe', '/updateCalculationFactor'), {
                        recipeId: _this.props.recipeId,
                        factor: _this.state.factor,
                        vat: !!_this.state.vatpercent ? _this.state.vatpercent.toString() : '0',
                    });
                    if (!!_this.props.onUpdateCalculation) {
                        _this.props.onUpdateCalculation(_this.state.factor, _this.state.vatpercent);
                    }
                }
            }, debounceMs);
        }
    };
    Calculation.prototype.recalculateCostPercent = function (value) {
        var portioncost = this.props.portionCost.current;
        this.setState({ proxycostpercent: value });
        var costpercent = typeof value === 'string' ? parseInt(value, 10) : value;
        if (!!isNaN(costpercent) || !costpercent || costpercent === 0 || !portioncost || portioncost === 0) {
            return;
        }
        var vatpercent = !!isNaN(this.state.vatpercent) ? 0 : this.state.vatpercent;
        var factor = 100 / costpercent;
        var calcprice = portioncost * factor;
        var vat = (calcprice * vatpercent) / 100;
        var customerprice = calcprice + vat;
        this.updateCalculationFields(costpercent, factor, calcprice, vat, customerprice);
        this.setState({
            proxyfactor: (Math.round(100 * factor) / 100).toString().replace('.', ','),
            proxycalcprice: calcprice.toFixed(2).replace('.', ','),
            proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
        });
    };
    Calculation.prototype.recalculateFactor = function (value) {
        var portioncost = this.props.portionCost.current;
        this.setState({ proxyfactor: value });
        var factor = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        if (!!isNaN(factor) || !factor || factor === 0 || !portioncost || portioncost === 0) {
            return;
        }
        var vatpercent = !!isNaN(this.state.vatpercent) ? 0 : this.state.vatpercent;
        var costpercent = 100 / factor;
        var calcprice = portioncost * factor;
        var vat = (calcprice * vatpercent) / 100;
        var customerprice = calcprice + vat;
        this.updateCalculationFields(costpercent, factor, calcprice, vat, customerprice);
        this.setState({
            proxycostpercent: costpercent.toFixed().replace('.', ','),
            proxycalcprice: calcprice.toFixed(2).replace('.', ','),
            proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
        });
    };
    Calculation.prototype.recalculateCalcPrice = function (value) {
        var portioncost = this.props.portionCost.current;
        this.setState({ proxycalcprice: value });
        var calcprice = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        if (!!isNaN(calcprice) || !calcprice || calcprice === 0 || !portioncost || portioncost === 0) {
            return;
        }
        var vatpercent = !!isNaN(this.state.vatpercent) ? 0 : this.state.vatpercent;
        var factor = calcprice / portioncost;
        var costpercent = 100 / factor;
        var vat = (calcprice * vatpercent) / 100;
        var customerprice = calcprice + vat;
        this.updateCalculationFields(costpercent, factor, calcprice, vat, customerprice);
        this.setState({
            proxycostpercent: costpercent.toFixed().replace('.', ','),
            proxyfactor: (Math.round(100 * factor) / 100).toString().replace('.', ','),
            proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
        });
    };
    Calculation.prototype.recalculateVatPercent = function (value) {
        this.setState({ proxyvatpercent: value });
        var vatpercent = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        if (!!isNaN(vatpercent) || !vatpercent) {
            vatpercent = 0;
        }
        this.setState({ vatpercent: vatpercent });
        var portioncost = this.props.portionCost.current;
        var costpercent = this.state.costpercent;
        if (!costpercent || costpercent === 0 || !portioncost || portioncost === 0) {
            return;
        }
        var factor = 100 / costpercent;
        var calcprice = portioncost * factor;
        var vat = (calcprice * vatpercent) / 100;
        var customerprice = calcprice + vat;
        this.updateCalculationFields(costpercent, factor, calcprice, vat, customerprice);
        this.setState({
            proxycostpercent: costpercent.toFixed().replace('.', ','),
            proxyfactor: (Math.round(100 * factor) / 100).toString().replace('.', ','),
            proxycalcprice: calcprice.toFixed(2).replace('.', ','),
            proxycustomerprice: customerprice.toFixed(2).replace('.', ','),
        });
    };
    Calculation.prototype.recalculateCustomerPrice = function (value) {
        var portioncost = this.props.portionCost.current;
        this.setState({ proxycustomerprice: value });
        var customerprice = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
        if (!!isNaN(customerprice) ||
            (!customerprice && !portioncost && !!isNaN(portioncost)) ||
            customerprice <= portioncost ||
            portioncost === 0) {
            return;
        }
        var vatpercent = !!isNaN(this.state.vatpercent) ? 0 : this.state.vatpercent;
        var calcprice = customerprice / (1 + vatpercent / 100);
        var factor = calcprice / portioncost;
        var costpercent = 100 / factor;
        var vat = (calcprice * vatpercent) / 100;
        this.updateCalculationFields(costpercent, factor, calcprice, vat, customerprice);
        this.setState({
            proxycostpercent: costpercent.toFixed().replace('.', ','),
            proxyfactor: (Math.round(100 * factor) / 100).toString().replace('.', ','),
            proxycalcprice: calcprice.toFixed(2).replace('.', ','),
        });
    };
    Calculation.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("span", null, translate('/Recipe/Calculation/IngredientCost')),
                    React.createElement(Price, __assign({ className: styles.price }, this.props.portionCost, { size: 'large' })))),
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("div", null,
                        React.createElement(Input, { label: translate('/Recipe/Calculation/IngredientPercent'), type: "text", name: "costPercent", value: this.state.proxycostpercent, ref: function (el) { return (_this.costPercent = el); }, maxLength: 5, onChange: function (e) { return _this.recalculateCostPercent(e.target.value); } }),
                        React.createElement("span", { className: styles.symbol }, "%")))),
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("div", null,
                        React.createElement(Input, { label: translate('/Recipe/Calculation/Factor'), type: "text", name: "factor", value: this.state.proxyfactor, ref: function (el) { return (_this.factor = el); }, maxLength: 5, onChange: function (e) { return _this.recalculateFactor(e.target.value); } })))),
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("div", null,
                        React.createElement(Input, { label: translate('/Recipe/Calculation/CalculationPrice'), type: "text", name: "calcPrice", value: this.state.proxycalcprice, ref: function (el) { return (_this.calcPrice = el); }, onChange: function (e) { return _this.recalculateCalcPrice(e.target.value); } }),
                        React.createElement("span", { className: styles.symbol }, translate('/Price/CurrencySymbol'))))),
            React.createElement("div", { className: styles.vatWrapper },
                React.createElement("label", null,
                    React.createElement("div", { className: styles.vat },
                        React.createElement("div", { className: styles.vatInput },
                            React.createElement(Input, { label: translate('/Recipe/Calculation/Vat'), type: "text", name: "vat", value: this.state.proxyvatpercent, ref: function (el) { return (_this.vat = el); }, maxLength: 5, onChange: function (e) { return _this.recalculateVatPercent(e.target.value); } }),
                            React.createElement("span", { className: styles.symbol }, "%")),
                        React.createElement("div", { className: styles.vatCalculated },
                            this.state.vat && this.state.vat.toFixed(2).replace('.', ','),
                            " ",
                            translate('/Price/CurrencySymbol'))))),
            React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement("div", null,
                        React.createElement(Input, { label: translate('/Recipe/Calculation/CustomerPrice'), type: "text", name: "customerPrice", value: this.state.proxycustomerprice, ref: function (el) { return (_this.customerPrice = el); }, onChange: function (e) { return _this.recalculateCustomerPrice(e.target.value); } }),
                        React.createElement("span", { className: styles.symbol }, translate('/Price/CurrencySymbol')))))));
    };
    return Calculation;
}(React.Component));
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
}); })(Calculation);
