var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import Input from 'Shared/Fields/Input';
import Icon from 'Shared/Icon';
var IpsItemRowDetails = function (props) { return (React.createElement("div", { className: classnames(styles.item, styles.compact), onClick: function () { return props.edit(); } },
    React.createElement(Icon, { name: "arrow-right" }),
    React.createElement("div", { className: styles.product }, props.displayName),
    React.createElement("div", { className: styles.price },
        props.price.toString(),
        translate('/App/Ips/PriceSymbol'),
        "/",
        props.unitShortName),
    React.createElement("div", { className: styles.quantity },
        (props.quantity || 0).toString(),
        translate('/App/Ips/QuantitySymbol')))); };
var IpsItemRow = /** @class */ (function (_super) {
    __extends(IpsItemRow, _super);
    function IpsItemRow(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isEditing: props.isEditing || false,
        };
        return _this;
    }
    IpsItemRow.prototype.editing = function (e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ isEditing: !this.state.isEditing });
    };
    IpsItemRow.prototype.render = function () {
        var _this = this;
        switch (this.props.isEditing) {
            case true:
                return React.createElement(IpsItemRowEditing, __assign({}, this.props, { close: function () { return _this.props.toggleEdit(false); } }));
            case false:
            default:
                return React.createElement(IpsItemRowDetails, __assign({}, this.props, { edit: function () { return _this.props.toggleEdit(true); } }));
        }
    };
    return IpsItemRow;
}(React.Component));
export default IpsItemRow;
var IpsItemRowEditing = /** @class */ (function (_super) {
    __extends(IpsItemRowEditing, _super);
    function IpsItemRowEditing(props) {
        var _this = _super.call(this, props) || this;
        _this.editPrice = function (price) { return _this.props.editPrice(parseFloat(price).toFixed(2)); };
        _this.editTax = function (taxRate) { return _this.props.editTax(parseFloat(taxRate).toFixed(2)); };
        _this.lockField = function (field, value) { return _this.props.lockField(field, value); };
        _this.calcMargin = function (value) {
            var cost = _this.props.menigoPrice, margin = parseFloat(value), revenue = cost / ((100 - margin) / 100), tax = (revenue * _this.props.taxRate) / 100, price = revenue + tax, proffit = revenue - cost, markup = (proffit / cost) * 100;
            return {
                margin: value,
                markup: markup.toFixed(2),
                revenue: revenue.toFixed(2),
                tax: tax.toFixed(2),
                price: price.toFixed(2),
            };
        };
        _this.calcMarkup = function (value) {
            var cost = _this.props.menigoPrice, markup = parseFloat(value) / 100, proffit = markup * cost, revenue = proffit + cost, tax = (revenue * _this.props.taxRate) / 100, price = revenue + tax, margin = (proffit / revenue) * 100;
            return {
                revenue: revenue.toFixed(2),
                margin: margin.toFixed(2),
                markup: value,
                tax: tax.toFixed(2),
                price: price.toFixed(2),
            };
        };
        _this.calcRevenue = function (value) {
            var cost = _this.props.menigoPrice, revenue = parseFloat(value) || 0, tax = (revenue * _this.props.taxRate) / 100, price = revenue + tax, proffit = revenue - cost, markup = (proffit / cost) * 100, margin = (proffit / revenue) * 100;
            return {
                revenue: value,
                markup: markup.toFixed(2),
                margin: margin.toFixed(2),
                tax: tax.toFixed(2),
                price: price.toFixed(2),
            };
        };
        _this.calcPrice = function (value) {
            var cost = _this.props.menigoPrice, price = parseFloat(value) || 0, revenue = (price / (100 + _this.props.taxRate)) * 100, tax = (revenue * _this.props.taxRate) / 100, proffit = revenue - cost, markup = (proffit / cost) * 100, margin = (proffit / revenue) * 100;
            return {
                revenue: revenue.toFixed(2),
                markup: markup.toFixed(2),
                margin: margin.toFixed(2),
                tax: tax.toFixed(2),
                price: value,
            };
        };
        _this.changeMargin = function (e) {
            var edit = _this.calcMargin(e.target.value);
            _this.setState(edit);
            if (_this.props.lockedField !== null) {
                _this.lockField(null, edit.margin);
            }
        };
        _this.changeMarkup = function (e) {
            var edit = _this.calcMarkup(e.target.value);
            _this.setState(edit);
            if (_this.props.lockedField !== null) {
                _this.lockField(null, edit.markup);
            }
        };
        _this.changeRevenue = function (e) {
            var edit = _this.calcRevenue(e.target.value);
            _this.setState(edit);
            if (_this.props.lockedField !== null) {
                _this.lockField(null, edit.revenue);
            }
        };
        _this.changePrice = function (e) {
            var edit = _this.calcPrice(e.target.value);
            _this.setState(edit);
            if (_this.props.lockedField !== null) {
                _this.lockField(null, edit.price);
            }
        };
        _this.changeTaxRate = function (e) {
            var taxRate = e.target.value;
            var parsedTaxeRate = parseFloat(taxRate);
            if (isNaN(parsedTaxeRate) || parsedTaxeRate < 0) {
                taxRate = '0';
            }
            _this.editTax(taxRate);
        };
        // https://bugs.chromium.org/p/chromium/issues/detail?id=4505
        _this.selectOnFocus = function (e) {
            var target = e.target;
            setTimeout(function () { return target.select(); }, 0);
        };
        var cost = props.menigoPrice, revenue = (props.price / (100 + props.taxRate)) * 100, tax = (revenue * props.taxRate) / 100, proffit = revenue - cost, markup = (proffit / cost) * 100, margin = (proffit / revenue) * 100;
        _this.state = {
            revenue: revenue.toFixed(2),
            markup: markup.toFixed(2),
            margin: margin.toFixed(2),
            tax: tax.toFixed(2),
            price: props.price.toFixed(2),
            taxRate: props.taxRate.toFixed(0),
        };
        return _this;
    }
    IpsItemRowEditing.prototype.componentDidMount = function () {
        this.setFields(this.props.lockedField, this.props.lockedFieldValue);
        if (this.props.price.toFixed(2) !== this.state.price) {
            this.editPrice(this.state.price);
        }
    };
    IpsItemRowEditing.prototype.componentWillReceiveProps = function (nextProps) {
        if ((!!nextProps.lockedField && nextProps.lockedField !== this.props.lockedField) ||
            nextProps.lockedFieldValue !== this.props.lockedFieldValue) {
            this.setFields(nextProps.lockedField, nextProps.lockedFieldValue);
        }
    };
    IpsItemRowEditing.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props.taxRate !== prevProps.taxRate) {
            this.setState({ taxRate: this.props.taxRate.toFixed(0) });
            if (this.props.lockedField === null) {
                this.setFields('revenue', parseFloat(prevState.revenue).toFixed(2));
            }
            else {
                this.setFields(this.props.lockedField, this.props.lockedFieldValue);
            }
        }
        if (this.state.price !== prevState.price) {
            this.editPrice(this.state.price);
        }
    };
    IpsItemRowEditing.prototype.setFields = function (field, value) {
        switch (field) {
            case 'price':
                this.setState(this.calcPrice(value));
                break;
            case 'revenue':
                this.setState(this.calcRevenue(value));
                break;
            case 'margin':
                this.setState(this.calcMargin(value));
                break;
            case 'markup':
                this.setState(this.calcMarkup(value));
                break;
            default:
                var revenue = (this.props.price / (100 + this.props.taxRate)) * 100;
                this.setState(this.calcRevenue(revenue.toFixed(2)));
                break;
        }
    };
    IpsItemRowEditing.prototype.onClose = function (e) {
        if (e) {
            e.preventDefault();
        }
        this.props.close();
    };
    IpsItemRowEditing.prototype.render = function () {
        var _this = this;
        return (React.createElement("form", { className: classnames(styles.item, styles.editing), onSubmit: function (e) { return _this.onClose(e); }, noValidate: true },
            React.createElement("div", { className: styles.product, onClick: function () { return _this.onClose(); } },
                React.createElement(Icon, { name: "arrow-down" }),
                this.props.displayName),
            React.createElement("div", { className: styles.productInfo },
                React.createElement("ul", { className: styles.row },
                    React.createElement("li", null,
                        React.createElement("label", { htmlFor: "menigoPrice" },
                            translate('/App/Ips/RetailPrice'),
                            " ",
                            translate('/App/Ips/PriceSymbol'),
                            "/",
                            this.props.unitShortName),
                        React.createElement("input", { name: "RetailPrice", type: "number", step: 0.01, value: this.props.menigoPrice.toFixed(2), readOnly: true })),
                    React.createElement("li", null,
                        React.createElement("span", { onClick: function () { return _this.lockField('revenue', _this.state.revenue); } },
                            React.createElement("label", { htmlFor: "revenue" },
                                translate('/App/Ips/Revenue'),
                                " ",
                                translate('/App/Ips/PriceSymbol')),
                            this.props.lockedField === 'revenue' ? (React.createElement(Icon, { name: "lock" })) : (React.createElement(Icon, { name: "lock", className: styles.inactiveLock }))),
                        React.createElement("input", { name: "revenue", type: "number", onFocus: this.selectOnFocus, value: this.state.revenue, onChange: this.changeRevenue, step: 0.01, readOnly: !!this.props.lockedField && this.props.lockedField !== 'revenue' }))),
                React.createElement("ul", { className: styles.row },
                    React.createElement("li", null,
                        React.createElement("span", { onClick: function () { return _this.lockField('margin', _this.state.margin); } },
                            React.createElement("label", { htmlFor: "margin" },
                                translate('/App/Ips/Margin'),
                                " %"),
                            this.props.lockedField === 'margin' ? (React.createElement(Icon, { name: "lock" })) : (React.createElement(Icon, { name: "lock", className: styles.inactiveLock }))),
                        React.createElement("input", { name: "margin", type: "number", onFocus: this.selectOnFocus, value: this.state.margin, onChange: this.changeMargin, step: 0.01, readOnly: !!this.props.lockedField && this.props.lockedField !== 'margin' })),
                    React.createElement("li", null,
                        React.createElement("label", { htmlFor: "tax" },
                            translate('/App/Ips/Tax'),
                            "(",
                            React.createElement("input", { name: "taxRate", type: "number", onFocus: this.selectOnFocus, value: this.state.taxRate, onChange: this.changeTaxRate, step: 0.01, className: styles.taxEdit }),
                            "%) ",
                            translate('/App/Ips/PriceSymbol')),
                        React.createElement("input", { name: "tax", type: "number", onFocus: this.selectOnFocus, value: this.state.tax, step: 0.01, readOnly: true }))),
                React.createElement("ul", { className: styles.row },
                    React.createElement("li", null,
                        React.createElement("span", { onClick: function () { return _this.lockField('markup', _this.state.markup); } },
                            React.createElement("label", { htmlFor: "markup" },
                                translate('/App/Ips/Markup'),
                                " %"),
                            this.props.lockedField === 'markup' ? (React.createElement(Icon, { name: "lock" })) : (React.createElement(Icon, { name: "lock", className: styles.inactiveLock }))),
                        React.createElement("input", { name: "markup", type: "number", onFocus: this.selectOnFocus, value: this.state.markup, onChange: this.changeMarkup, step: 0.01, readOnly: !!this.props.lockedField && this.props.lockedField !== 'markup' })),
                    React.createElement("li", null,
                        React.createElement("span", { onClick: function () { return _this.lockField('price', _this.state.price); } },
                            React.createElement("label", { htmlFor: "price" },
                                translate('/App/Ips/CustomerPrice'),
                                " ",
                                translate('/App/Ips/PriceSymbol')),
                            this.props.lockedField === 'price' ? (React.createElement(Icon, { name: "lock" })) : (React.createElement(Icon, { name: "lock", className: styles.inactiveLock }))),
                        React.createElement("input", { name: "price", type: "number", onFocus: this.selectOnFocus, value: this.state.price, onChange: this.changePrice, step: 0.01, readOnly: !!this.props.lockedField && this.props.lockedField !== 'price' })))),
            React.createElement("div", { className: styles.quantity },
                React.createElement(Input, { type: "number", onFocus: this.selectOnFocus, onChange: function (e) { return _this.props.editQuantity(e.target.value); }, value: (this.props.quantity || 0).toString() }, translate('/App/Ips/Quantity'))),
            React.createElement(Button, { type: "submit" }, translate('/App/Ips/Save')),
            React.createElement(Button, { appearance: "clear", onClick: function () { return _this.props.remove(); } }, translate('/App/Ips/Remove'))));
    };
    return IpsItemRowEditing;
}(React.Component));
