var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Backbutton from '../../../App/Backbutton';
import Main, { Part } from 'Shared/PageLayout';
import { urlToString } from 'Shared/url';
import { pushState } from 'Shared/history';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
var FavoriteListListPage = /** @class */ (function (_super) {
    __extends(FavoriteListListPage, _super);
    function FavoriteListListPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FavoriteListListPage.prototype.render = function () {
        return (React.createElement(Main, null,
            React.createElement(Backbutton, { key: "title", title: translate('/App/Favorites/MyLists') }),
            React.createElement(Part, null,
                React.createElement("table", { className: styles.list },
                    React.createElement("tbody", null, this.props.lists.map(function (e) { return (React.createElement("tr", { key: e.id, onClick: function () { return pushState(urlToString(e.url)); } },
                        React.createElement("td", null, e.displayName))); }))))));
    };
    return FavoriteListListPage;
}(React.Component));
export default FavoriteListListPage;
