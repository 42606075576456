var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var phrases = {};
// Are you here looking for how we know which language keys exists?
// We find that out dynamically using `webpack/translation-finder-loader.js`
// It goes through all code and looks for calls like this: `translate('xxx')`
// and automatically makes that phrase available in the frontend code.
export function translate(key) {
    var values = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        values[_i - 1] = arguments[_i];
    }
    if (!(key in phrases)) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Missing translation for phrase:', key);
            return key;
        }
        return '';
    }
    else {
        if (phrases[key] !== '') {
            return format.apply(void 0, __spread([phrases[key]], values));
        }
        else if (process.env.NODE_ENV !== 'production') {
            console.error('Empty translation for phrase:', key);
        }
        return '[Empty translation: ' + key + ']';
    }
}
export function setPhrases(p) {
    phrases = p;
}
// Format phrase using curly braces, e.g. `format('foo{0} {1}', 'bar', 'baz')` => Outputs: foobar baz
export function format(phrase) {
    var values = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        values[_i - 1] = arguments[_i];
    }
    var regex = /{(\d+)}/g;
    if (process.env.NODE_ENV !== 'production') {
        var placeholders = phrase.match(regex) || [];
        if (placeholders.length !== values.length) {
            var error = "The phrase \"" + phrase + "\" contains " + placeholders.length + " placeholders\n                     but " + values.length + " placeholders was passed to translate(). The placeholders\n                     passed in was: " + JSON.stringify(values);
            throw new Error(error);
        }
    }
    return values.length === 0
        ? phrase
        : phrase.replace(regex, function (match, index) {
            return typeof values[index] !== 'undefined' ? values[index] : match;
        });
}
