var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Main } from 'Shared/PageLayout';
import Description from '../ProductRawMaterialDescription';
import Information from '../ProductRawMaterialInformation';
import AvailabilityTable from '../ProductRawMaterialAvailabilityTable';
import React from 'react';
import * as styles from './base.scss';
var ProductRawMaterialGuide = /** @class */ (function (_super) {
    __extends(ProductRawMaterialGuide, _super);
    function ProductRawMaterialGuide() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductRawMaterialGuide.prototype.render = function () {
        return (React.createElement("div", { className: styles.RawWrapper },
            React.createElement(Main, { className: styles.main },
                React.createElement("div", { className: styles.RawBody },
                    React.createElement(Description, __assign({}, this.props)),
                    React.createElement(Information, __assign({}, this.props)),
                    React.createElement(AvailabilityTable, __assign({}, this.props))))));
    };
    return ProductRawMaterialGuide;
}(React.Component));
export default ProductRawMaterialGuide;
