import { PAGE_UNHANDLED_ERROR, PAGE_LOAD, PAGE_LOAD_SUCCESS, PAGE_LOAD_DONE, PAGE_LOAD_ABORTED, PAGE_UPDATE, PAGE_UPDATE_SUCCESS, PAGE_UPDATE_FAILURE, PAGE_LOAD_FAILURE, SHOW_PAGE_SPINNER, HIDE_PAGE_SPINNER, } from './action-creators';
import { COMPLETE_PURCHASE, COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS, COMPLETE_PURCHASE_FAILURE, TOGGLE_COMPLETE_PURCHASE_MODAL, } from '../../Checkout/action-creators';
import { parseUrl, urlToString } from 'Shared/url';
import * as cache from './cache';
export default function (state, action, appShellData) {
    // `isLoading` is not set when we get the state from the dehydration
    // so we use that to determine if this is the initial state
    if (state.isLoading === undefined) {
        cache.set(state);
        state = Object.assign({}, state, {
            isLoading: false,
            showLoadingSpinner: false,
            isPartial: false,
            loadFailure: null,
            unhandledError: null,
            scrollPosition: undefined,
            url: parseUrl(state.url),
        });
    }
    var pageToCache = null;
    var fastCacheOnly = false;
    switch (action.type) {
        case PAGE_LOAD: {
            var pageLoadAction = action;
            var cachedItem = pageLoadAction.cachedItem;
            var isPartial = cachedItem && cachedItem.$cache === 'partial';
            var isFull = cachedItem && cachedItem.$cache === 'full';
            var shouldShowCached = (isPartial && !pageLoadAction.options.skipPartialCache) || isFull;
            var hideSpinner = pageLoadAction.options.hideSpinner === true;
            // If we want to merge the state we don't want to use the cached version
            // because that has already been merged once. Merging it again would
            // cause undefined behaivor.
            if (shouldShowCached && !pageLoadAction.options.merge) {
                state = Object.assign({}, cachedItem, {
                    componentName: cachedItem.componentName,
                    url: action.url,
                    isPartial: isPartial,
                    isLoading: true,
                    showLoadingSpinner: isPartial && hideSpinner !== true,
                    loadFailure: null,
                    unhandledError: state.unhandledError,
                    scrollPosition: getScrollPosition(state, action),
                });
            }
            else {
                state = Object.assign({}, state, {
                    scrollPosition: undefined,
                    isLoading: true,
                    showLoadingSpinner: hideSpinner !== true,
                    loadFailure: null,
                    unhandledError: state.unhandledError,
                });
            }
            break;
        }
        case PAGE_UPDATE: {
            if (!state.isLoading) {
                state = Object.assign({}, state, {
                    isLoading: true,
                });
            }
            break;
        }
        case SHOW_PAGE_SPINNER: {
            state = Object.assign({}, state, {
                showLoadingSpinner: true,
            });
            break;
        }
        case TOGGLE_COMPLETE_PURCHASE_MODAL: {
            state = Object.assign({}, state, {
                showCompletePurchaseModal: action.showCompletePurchaseModal,
            });
            break;
        }
        case HIDE_PAGE_SPINNER: {
            if (!state.isLoading) {
                state = Object.assign({}, state, {
                    showLoadingSpinner: false,
                });
            }
            break;
        }
        case PAGE_UPDATE_FAILURE: {
            if (state.isLoading) {
                state = Object.assign({}, state, {
                    isLoading: false,
                });
            }
            break;
        }
        case PAGE_UNHANDLED_ERROR: {
            state = Object.assign({}, state, {
                unhandledError: action.error,
            });
            break;
        }
        case PAGE_LOAD_SUCCESS: {
            var loadAction = action;
            var options = loadAction.options;
            var shouldMerge = options && options.merge;
            var shouldCache = true;
            var page = loadAction.page;
            var newPage = void 0;
            // We don't want to merge if it's not the current page because the merger
            // will try to merge the previous page with the new one, and that can be
            // a completely different page type
            if (shouldMerge) {
                if (loadAction.isCurrentPage) {
                    newPage = options.merge(state, page);
                }
                else {
                    // If the page is supposed to be merged but can't because it's not the
                    // current page we can't cache it
                    shouldCache = false;
                    newPage = {};
                }
            }
            else {
                newPage = page;
            }
            var url = loadAction.url;
            // `newPage.url` is what the server thinks is the url, but that can contain
            // the hidden query strings. Instead we overwrite that with what we requested
            // which is what we use as cache key.
            newPage.url = urlToString(url, false);
            if (shouldCache) {
                pageToCache = newPage;
            }
            // We don't want to cache merged pages because that eats up the cache with duplicated
            // content
            if (shouldMerge) {
                fastCacheOnly = true;
                console.debug('Only caching', newPage.url, 'in fast cache because it has been merged');
            }
            // We might get a load success action for a page that is no longer
            // the current page. Like if the user requested a page but got tired of waiting
            // for it and went to another page instead.
            if (loadAction.isCurrentPage) {
                state = Object.assign({}, newPage, {
                    url: url,
                    isPartial: false,
                    loadFailure: null,
                    unhandledError: state.unhandledError,
                    scrollPosition: getScrollPosition(state, action),
                    isLoading: state.isLoading,
                    showLoadingSpinner: state.showLoadingSpinner,
                });
            }
            else {
                if (shouldCache) {
                    console.debug('Ignoring page load response because another request is in-flight. The full response has been cached.');
                }
                else {
                    console.debug('Ignoring page load response because another request is in-flight. The full response has not been cached.');
                }
            }
            break;
        }
        case PAGE_UPDATE_SUCCESS: {
            var pageUpdateAction = action;
            pageToCache = pageUpdateAction.page;
            state = Object.assign({}, state, pageUpdateAction.page, {
                scrollPosition: undefined,
                isLoading: false,
                showLoadingSpinner: false,
            });
            break;
        }
        case PAGE_LOAD_FAILURE: {
            state = Object.assign({}, state, {
                loadFailure: action.loadFailure,
                reload: action.retry,
                unhandledError: state.unhandledError,
            });
            break;
        }
        case PAGE_LOAD_ABORTED:
        case PAGE_LOAD_DONE: {
            if (state.isLoading || state.showLoadingSpinner) {
                state = Object.assign({}, state, { isLoading: false, showLoadingSpinner: false });
            }
            break;
        }
        case COMPLETE_PURCHASE: {
            state = Object.assign({}, state, { isLoading: true, showLoadingSpinner: false, scrollPosition: undefined });
            break;
        }
        case COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS: {
            state = Object.assign({}, state, { isLoading: false, showLoadingSpinner: false, cart: { items: {} } });
            break;
        }
        case COMPLETE_PURCHASE_FAILURE: {
            state = Object.assign({}, state, { isLoading: false, showLoadingSpinner: false });
            break;
        }
        default:
            break;
    }
    if (pageToCache) {
        cache.set(pageToCache, fastCacheOnly);
    }
    return state;
}
function getScrollPosition(previousState, action) {
    if (previousState.url.path !== action.url.path) {
        // We only want to change scroll position if the path has changed, not if
        // only the query string has changed
        return action.scrollPosition;
    }
    return undefined;
}
