var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Price from 'Shared/Price';
import { translate } from 'Shared/translate';
import * as styles from '././base.scss';
var Summary = /** @class */ (function (_super) {
    __extends(Summary, _super);
    function Summary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Summary.prototype.render = function () {
        return (React.createElement("ul", { className: styles.base },
            React.createElement("li", { className: styles.total },
                React.createElement("ul", null,
                    React.createElement("li", null, translate('/Checkout/OrderSummary')),
                    React.createElement("li", null,
                        React.createElement(Price, { current: this.props.totalPrice, size: "normal", currencySymbol: translate('/Price/CurrencySymbol'), isPending: this.props.isLoading })))),
            React.createElement("li", null,
                React.createElement("ul", null,
                    React.createElement("li", null, translate('/Checkout/AlcoholTax')),
                    React.createElement("li", null,
                        React.createElement(Price, { className: classnames(styles.alcoholTax), current: this.props.alcoholTax, size: "normal", currencySymbol: translate('/Price/CurrencySymbol'), isPending: this.props.isLoading })))),
            this.props.savings && this.props.savings > 0 && (React.createElement("li", null,
                React.createElement("ul", null,
                    React.createElement("li", null, translate('/Checkout/Saves')),
                    React.createElement("li", null,
                        React.createElement(Price, { current: this.props.savings, size: "normal", isPending: this.props.isLoading }))))),
            this.props.selectedDelivery && (React.createElement("li", { className: styles.delivery },
                React.createElement("ul", null,
                    React.createElement("li", null, translate('/Checkout/Delivery')),
                    React.createElement("li", null, this.props.selectedDelivery.departureDateAsString + ' | ' + this.props.selectedDelivery.value))))));
    };
    return Summary;
}(React.PureComponent));
export default Summary;
