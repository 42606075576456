import React, { useEffect } from 'react';
import classnames from 'classnames';
import { isBrowser, externalSvgUseSupported } from 'Shared/device-type';
import { get } from 'Shared/server';
import * as styles from './base.scss';
var icons = require('../../../../styles/images/mm-icons.svg');
function href(name) {
    return (!IS_APP && externalSvgUseSupported() ? icons : '') + "#mm-icon-" + name;
}
export function GetIcons(name) {
    name = name.toLowerCase();
    if (name.startsWith('mina fakturor')) {
        return 'accounting';
    }
    else if (name.startsWith('favoritlistor')) {
        return 'favorite';
    }
    else if (name.startsWith('intranät')) {
        return 'intranet';
    }
    else if (name.startsWith('inventering')) {
        return 'inventory';
    }
    else if (name.startsWith('mina kampanjer') || name.startsWith('kampanjer') || name.startsWith('erbjudanden')) {
        return 'campaign';
    }
    else if (name.startsWith('översikt')) {
        return 'overview';
    }
    else if (name.startsWith('pantretur')) {
        return 'pantReturns';
    }
    else if (name.startsWith('reklamationer')) {
        return 'complaints';
    }
    else if (name.startsWith('statistik')) {
        return 'overview';
    }
    else if (name.startsWith('order')) {
        return 'order';
    }
    else if (name.startsWith('leveranser')) {
        return 'deliveries';
    }
    else if (name.startsWith('min profil')) {
        return 'user';
    }
    else if (name.startsWith('användarkonton')) {
        return 'settings';
    }
    else if (name.startsWith('retur')) {
        return 'returnSRS';
    }
    else if (name.startsWith('admin')) {
        return 'admin';
    }
    else if (name.startsWith('impersonering')) {
        return 'impersonate';
    }
    else if (name.startsWith('mitt upplägg')) {
        return 'arrangement';
    }
    else if (name.startsWith('utvalt')) {
        return 'alwaysAffordable';
    }
    else if (name.startsWith('egna varor')) {
        return 'customProducts';
    }
    return null;
}
var MyMenigoIcon = function (_a) {
    var name = _a.name, className = _a.className, onClick = _a.onClick;
    useEffect(function () {
        if (isBrowser() && !externalSvgUseSupported()) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('SVG `<use>` with external reference not supported.');
            }
            if (process.env.NODE_ENV !== 'production') {
                console.log('Initiating SVG external resource embedding.');
            }
            get(icons)
                .then(function (response) { return response.text(); })
                .then(function (response) {
                document.body.insertAdjacentHTML('beforeend', "<div style=\"display:none;\">" + response + "</div>");
                if (process.env.NODE_ENV !== 'production') {
                    console.log('SVG external resource successfully embedded to DOM.');
                }
            });
        }
    }, []);
    return (React.createElement("svg", { className: classnames(styles.mmicon, className), onClick: onClick },
        React.createElement("use", { xlinkHref: href(name) })));
};
export default MyMenigoIcon;
