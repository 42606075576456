import { TOGGLE_DELIVERYDAYS, SHOW_DELIVERYDAYS, HIDE_DELIVERYDAYS } from './action-creators';
export default function (state, action) {
    if (state === void 0) { state = { visible: false }; }
    switch (action.type) {
        case TOGGLE_DELIVERYDAYS: {
            return Object.assign({}, state, {
                visible: !state.visible,
            });
        }
        case SHOW_DELIVERYDAYS: {
            return Object.assign({}, state, {
                visible: true,
            });
        }
        case HIDE_DELIVERYDAYS: {
            return Object.assign({}, state, {
                visible: false,
            });
        }
        default:
            return state;
    }
}
