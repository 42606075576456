var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { SMALL } from 'Shared/breakpoints';
import classnames from 'classnames';
import * as styles from './base.scss';
import MyMenigoButton from './MyMenigoDropdown/MyMenigoButton';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import LogoutBox from './../../Account/Components/LogoutBox';
var TopHeader = /** @class */ (function (_super) {
    __extends(TopHeader, _super);
    function TopHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TopHeader.prototype.renderDesktopHeader = function () {
        return (React.createElement("div", { className: styles.headerWrapperTop },
            React.createElement("div", { className: classnames(styles.layout, styles.rightTopButtonsHolder) },
                React.createElement("div", { className: styles.userInfoWrapper }, this.props.isLoggedIn && (React.createElement("div", null, !this.props.isSupplierSalesman ? (React.createElement("div", null,
                    React.createElement("span", { "data-ta": "logged-in-customer-label" }, this.props.currentUser.punchOutSessionActive
                        ? translate('/SiteLayout/Header/LoggedAsPunchOut')
                        : translate('/SiteLayout/Header/LoggedAsCustomer')),
                    React.createElement("span", { className: styles.userInfoItem },
                        this.props.currentUser.customerId,
                        ", "),
                    React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.fullName))) : (React.createElement("div", { className: styles.suppliersalesmanWrapper },
                    React.createElement("span", null, translate('/SiteLayout/Header/LoggedAsSupplierSalesman')),
                    React.createElement("span", { className: styles.userInfoItemFirst },
                        this.props.currentUser.customerId,
                        ", ",
                        this.props.currentUser.fullName),
                    React.createElement("span", { className: styles.suppliersalesmanInfo },
                        React.createElement("span", null, translate('/SiteLayout/Header/ChosenCustomer')),
                        React.createElement("span", { className: styles.userInfoItem },
                            this.props.suppliersalesmanInfo,
                            " ")),
                    this.props.activeExhibition && (React.createElement("span", { className: styles.suppliersalesmanExhibition },
                        React.createElement("span", null, translate('/SiteLayout/Header/Exhibition')),
                        React.createElement("span", { className: styles.userInfoItem }, this.props.activeExhibition)))))))),
                React.createElement("div", { className: styles.helperLinksHolder },
                    React.createElement("ul", { className: styles.helperLinks }, this.props.mainMenu.topContentLinks.map(function (item, index) { return (React.createElement("li", { key: index },
                        React.createElement("a", { href: item.url }, item.displayName))); })),
                    React.createElement(MyMenigoButton, null),
                    this.props.isLoggedIn && (React.createElement("div", { className: styles.helperLinksHolder },
                        React.createElement(LogoutBox, { close: function () { }, key: "logout" })))))));
    };
    TopHeader.prototype.render = function () {
        if (!IS_APP && this.props.currentBreakpoint > SMALL) {
            return this.renderDesktopHeader();
        }
        else {
            return null;
        }
    };
    return TopHeader;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    suppliersalesmanInfo: state.currentUser.supplierSalesmanInfo,
    activeExhibition: state.currentUser.activeExhibition,
    currentBreakpoint: state.currentBreakpoint,
    mainMenu: state.mainMenu,
    currentUser: state.currentUser,
}); }, {})(TopHeader);
