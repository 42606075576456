import { DISSMISS_FEEDBACK, SET_FEEDBACK } from './action-creators';
import { PAGE_LOAD_FAILURE } from 'SiteLayout/CurrentPage/action-creators';
import { translate } from 'Shared/translate';
export default function (state, action) {
    if (state === void 0) { state = { visible: false, text: '', type: 'default' }; }
    switch (action.type) {
        case DISSMISS_FEEDBACK: {
            return Object.assign({}, state, {
                visible: false,
            });
        }
        case SET_FEEDBACK: {
            return Object.assign({}, state, action.data, { visible: true });
        }
        case PAGE_LOAD_FAILURE:
            if (action.loadFailure.status === 403) {
                return Object.assign({}, state, {
                    visible: true,
                    text: translate("/Account/Login/AccessDeniedForPage"),
                    type: 'error'
                });
            }
            return state;
        default:
            return state;
    }
}
