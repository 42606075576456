import { currentUrl, parseUrl } from 'Shared/url';
export var ACCOUNT_BOX_OPEN = 'ACCOUNT_BOX_OPEN';
export var ACCOUNT_BOX_CLOSE = 'ACCOUNT_BOX_CLOSE';
export var ACTION_REQUIRES_LOGIN = 'ACTION_REQUIRES_LOGIN';
export var ACCOUNT_SWITCH_TAB = 'ACCOUNT_SWITCH_TAB';
export var setAccountBoxOpen = function (tab, message, redirectToAfterLogin, enableAutofocus) {
    if (tab === void 0) { tab = 'login'; }
    if (enableAutofocus === void 0) { enableAutofocus = true; }
    return {
        type: ACCOUNT_BOX_OPEN,
        message: message,
        redirectToAfterLogin: redirectToAfterLogin,
        tab: tab,
        enableAutofocus: enableAutofocus,
    };
};
export var setAccountBoxClose = function () {
    return {
        type: ACCOUNT_BOX_CLOSE,
    };
};
export function openLoginBoxIfUrlSaysSo(store) {
    var queryParam = 'login';
    var queryParamValue = 'required';
    var returnUrlQueryParam = 'ReturnUrl';
    var url = currentUrl();
    var returnUrl = returnUrlQueryParam in url.query ? parseUrl(url.query[returnUrlQueryParam]) : null;
    if (url.query[queryParam] === queryParamValue && !store.getState().currentUser.isLoggedIn) {
        store.dispatch(setAccountBoxOpen('login', '', returnUrl));
    }
}
export function openRegisterBoxIfUrlSaysSo(store) {
    var queryParam = 'register';
    var url = currentUrl();
    if (queryParam in url.query && !store.getState().currentUser.isLoggedIn) {
        store.dispatch(setAccountBoxOpen('register', '', undefined, false));
    }
}
