import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import * as styles from '../base.scss';
import { setAccountBoxOpen } from '../../Header/AccountBox/action-creators';
import Button from 'Shared/Button';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var LoginMenuRow = function (props) { return (React.createElement("div", { className: styles.loginWrapper },
    React.createElement(Button, { appearance: "primary", onClick: function () { return props.setAccountBoxOpen(); } },
        React.createElement(MyMenigoIcon, { name: 'user' }),
        React.createElement("span", null, translate('/Account/Login/Login'))))); };
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
}); }, function (dispatch) { return ({
    setAccountBoxOpen: function () {
        dispatch(setAccountBoxOpen());
    },
}); })(LoginMenuRow);
