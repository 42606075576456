import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import { searchPageUrl } from 'Shared/known-urls';
var lastRequestId = 0;
export function search(searchText, isPantry) {
    var requestId = (lastRequestId = Math.random());
    return function (dispatch) {
        if (searchText.length > 0) {
            return createPromiseWrapper(server
                .get({
                path: pathCombine(searchPageUrl(), 'autocompletecustom'),
                query: { query: searchText, allowCustom: isPantry, recalcPrices: isPantry },
            })
                .then(function (r) { return r.json(); })
                .then(function (json) {
                if (requestId === lastRequestId) {
                    return json.products;
                }
            }));
        }
    };
}
var createPromiseWrapper = function (promise) {
    var _hasCanceled = false;
    var wrappedPromise = new Promise(function (resolve, reject) {
        promise.then(function (val) { return _hasCanceled ? reject({ isCanceled: true }) : resolve(val); }, function (error) { return _hasCanceled ? reject({ isCanceled: true }) : reject(error); });
    });
    return {
        promise: wrappedPromise,
        cancel: function () {
            _hasCanceled = true;
        },
    };
};
export function searchTextToWords(searchText) {
    return searchText.replace(/\s+/, ' ').split(' ');
}
