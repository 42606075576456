import { SWITCH_BREAKPOINT } from './action-creators';
export default function (state, action, appShellData) {
    if (state === void 0) { state = null; }
    if (state === null) {
        state = appShellData.guessedBreakpoint;
    }
    switch (action.type) {
        case SWITCH_BREAKPOINT:
            return action.newBreakpoint;
        default:
            return state;
    }
}
