var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import { Part, Section } from 'Shared/PageLayout';
import OrderOverviewWidget from '../../Widgets/OrderOverviewWidget/OrderOverviewWidget';
import { myOrdersPageUrl } from 'Shared/known-urls';
var OrderOverviewBlock = /** @class */ (function (_super) {
    __extends(OrderOverviewBlock, _super);
    function OrderOverviewBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrderOverviewBlock.prototype.render = function () {
        return (React.createElement(Section, null,
            React.createElement(Part, { className: styles.orderOverviewTitle }, translate('/MyMenigo/Overview/OrderOverview')),
            React.createElement(Part, { className: styles.overviewSection },
                React.createElement(OrderOverviewWidget, { orderList: this.props.currentOrders, widgetType: "pendingOrders", orderOverviewPageUrl: myOrdersPageUrl() }),
                React.createElement(OrderOverviewWidget, { orderList: this.props.orderHistory, widgetType: "deliveredOrders", orderOverviewPageUrl: myOrdersPageUrl() }))));
    };
    return OrderOverviewBlock;
}(React.Component));
export default connect(function (state) { return ({}); })(OrderOverviewBlock);
