var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import { getNumberWithinLimits, floatCalculationSafety, adjustNumberToMatchStep } from './utils';
import LoginContainer from 'Shared/Login/Container';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { ENTER, ESC } from 'Shared/keyboard';
import shallowEquals from 'Shared/shallow-equals';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
var Quantity = /** @class */ (function (_super) {
    __extends(Quantity, _super);
    function Quantity(props) {
        var _this = _super.call(this, props) || this;
        _this.keyDown = function (e) {
            if (e.keyCode === ENTER) {
                _this.quantityInput.blur();
            }
            if (e.keyCode === ESC) {
                _this.resetField();
            }
            if (!!_this.props.keyDown) {
                _this.props.keyDown(e);
            }
        };
        _this.inputRef = function (el) {
            _this.quantityInput = el;
            if (_this.props.inputRef) {
                _this.props.inputRef(el);
            }
        };
        // tslint:disable-next-line:no-empty
        _this.onOkButtonClick = function (e) { };
        _this.state = {
            proxyValue: _this.getProxyValueFromProps(),
            hasChangedValue: false,
        };
        _this.ref = React.createRef();
        return _this;
    }
    Quantity.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(nextProps, this.props, true) || !shallowEquals(nextState, this.state, true);
        return shouldUpdate;
    };
    Quantity.prototype.getProxyValueFromProps = function () {
        var proxyValue = this.props.value || (this.props.allowEmptyQuantity ? '' : '0');
        return this.getValueAsString(proxyValue);
    };
    Quantity.prototype.componentWillUnmount = function () {
        if (this.props.mayCommitUpdateOnUnmountIfFocused && this.isFocused) {
            this.onBlur();
        }
        this.unMounted = true;
    };
    Quantity.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ proxyValue: this.getProxyValueFromProps() });
        }
        if (this.props.focus && !prevProps.focus) {
            var proxyValue = this.state.proxyValue;
            this.quantityInput.focus();
            if (!this.props.neverFocusTheValueItself && !!proxyValue && proxyValue !== '') {
                this.quantityInput.select();
            }
        }
    };
    Quantity.prototype.getStep = function () {
        var step = this.props.step;
        return step > 0 ? step : 1;
    };
    Quantity.prototype.getValueAsNumber = function (value) {
        var numericValue = parseFloat(value);
        return !isNaN(numericValue) ? numericValue : 0;
    };
    Quantity.prototype.getValueAsString = function (value) {
        return value.toString();
    };
    Quantity.prototype.change = function (value, action) {
        var _this = this;
        var proxyValue = this.getValueAsString(value).replace(/\,/g, '.');
        var invalidCharacters = proxyValue.replace(/\d{0,}(\.){0,1}\d{0,}/, '');
        if (proxyValue !== '' && invalidCharacters.length > 0) {
            return;
        }
        this.setState({
            proxyValue: proxyValue,
            hasChangedValue: !action,
        }, function () {
            if (action) {
                var desiredValue = _this.getValueAsNumber(proxyValue);
                var allowedValue = adjustNumberToMatchStep(desiredValue, _this.getStep());
                _this.commitUpdate(allowedValue);
            }
        });
    };
    Quantity.prototype.stepToNextQuantity = function (direction) {
        var _a = this.props, minQuantity = _a.minQuantity, maxQuantity = _a.maxQuantity;
        var _b = floatCalculationSafety(), preFormat = _b.preFormat, postFormat = _b.postFormat;
        var proxyValue = preFormat(this.getValueAsNumber(this.state.proxyValue));
        var step = preFormat(this.getStep());
        var action = direction === 1 ? 'add' : 'reduce';
        var quantity = postFormat(proxyValue + step * direction);
        var quantityWithinLimits = getNumberWithinLimits(quantity, minQuantity, maxQuantity);
        this.change(quantityWithinLimits, action);
        if (action === 'add') {
            this.bounceAnimation();
        }
    };
    Quantity.prototype.commitUpdate = function (quantity) {
        var _this = this;
        if (this.state.hasChangedValue) {
            this.setState({ hasChangedValue: false });
        }
        return this.props.update(quantity).catch(function () {
            if (!_this.unMounted && !_this.isFocused) {
                _this.setState({
                    proxyValue: _this.getValueAsString(_this.props.value),
                });
            }
        });
    };
    Quantity.prototype.bounceAnimation = function () {
        this.ref.current.classList.remove(styles.drop);
        var h = this.ref.current.offsetWidth;
        this.ref.current.classList.add(styles.drop);
    };
    Quantity.prototype.onFocus = function (e) {
        this.isFocused = true;
        e.target.select();
        if (!!this.props.selectIndex) {
            this.props.selectIndex();
        }
        if (!!this.props.onFocus) {
            this.props.onFocus(e);
        }
    };
    Quantity.prototype.onBlur = function (e) {
        this.isFocused = false;
        if (this.state.hasChangedValue) {
            var _a = this.props, minQuantity = _a.minQuantity, maxQuantity = _a.maxQuantity;
            var desiredValue = this.getValueAsNumber(this.state.proxyValue);
            var allowedValue = adjustNumberToMatchStep(getNumberWithinLimits(desiredValue, minQuantity, maxQuantity), this.getStep());
            if (desiredValue > maxQuantity) {
                this.props.setFeedback({
                    text: translate('/Shared/QuantityAboveMaxError', maxQuantity),
                    type: 'warning',
                });
            }
            else if (desiredValue < minQuantity) {
                this.props.setFeedback({
                    text: translate('/Shared/QuantityBelowMinError', minQuantity),
                    type: 'warning',
                });
            }
            else if (desiredValue !== allowedValue) {
                this.props.setFeedback({
                    text: translate('/Shared/QuantityStep'),
                    type: 'warning',
                });
            }
            this.setState({ proxyValue: allowedValue.toString() });
            this.commitUpdate(allowedValue);
        }
        if (!!this.props.onBlur) {
            return this.props.onBlur();
        }
    };
    Quantity.prototype.resetField = function () {
        this.setState({
            proxyValue: this.getValueAsString(this.props.value),
        });
    };
    Quantity.prototype.render = function () {
        var _this = this;
        var _a = this.props, hideButtons = _a.hideButtons, className = _a.className, unitShortName = _a.unitShortName, disabled = _a.disabled, isCartQuantity = _a.isCartQuantity, minQuantity = _a.minQuantity, maxQuantity = _a.maxQuantity, autoFocus = _a.autoFocus, renderVariant = _a.renderVariant, selectedVariant = _a.selectedVariant, useNewDesign = _a.useNewDesign, isCheckout = _a.isCheckout, hasSubDelivery = _a.hasSubDelivery, isPantry = _a.isPantry;
        var _b = this.state, hasChangedValue = _b.hasChangedValue, proxyValue = _b.proxyValue;
        var proxyValueNumber = this.getValueAsNumber(proxyValue);
        var appearance = [{ small: true, bare: true, round: true }];
        var disableIfValueEqualsZero = hasSubDelivery && proxyValueNumber === 0;
        return (React.createElement(LoginContainer, null, function (_a) {
            var _b, _c, _d, _e, _f, _g, _h;
            var isLoggedIn = _a.isLoggedIn;
            return (React.createElement("div", { ref: _this.ref, className: classnames(styles.wrap, (_b = {},
                    _b[styles.useNewDesign] = _this.props.useNewDesign,
                    _b[styles.isCartQuantity] = isCartQuantity,
                    _b[styles.disabled] = !isLoggedIn || disabled || (isCheckout && _this.props.useNewDesign && disableIfValueEqualsZero),
                    _b), className) },
                renderVariant ||
                    (unitShortName && (React.createElement("span", { className: classnames(styles.unitShortName, 'ui-test-unit-short-name') }, unitShortName))),
                React.createElement("div", { className: classnames(styles.wrapInner, (_c = {},
                        _c[styles.inCart] = proxyValueNumber > 0,
                        _c)) },
                    React.createElement("div", { className: classnames(styles.unitHolder, (_d = {}, _d[styles['description--medium']] = useNewDesign, _d)) },
                        React.createElement("div", { className: styles.amount }, proxyValueNumber),
                        React.createElement("div", { className: styles.unit }, !!selectedVariant && selectedVariant.unitShortName)),
                    !hideButtons && (React.createElement(Button, { title: translate('/Product/MinimizeQty'), appearance: appearance, onClick: function () { return _this.stepToNextQuantity(-1); }, className: styles.minusbutton, disabled: !isLoggedIn || disabled || hasChangedValue || proxyValueNumber <= minQuantity || (isCheckout && _this.props.useNewDesign && (proxyValueNumber === 0 && disableIfValueEqualsZero)) }, _this.props.useNewDesign ? React.createElement(Icon, { name: 'minus' }) : '-')),
                    React.createElement("input", { ref: _this.inputRef, type: "text", step: _this.getStep(), name: "quantity", autoFocus: autoFocus, className: classnames(styles.input, className, (_e = {}, _e[styles['description--medium']] = useNewDesign, _e), (_f = {}, _f[styles.useNewDesign] = useNewDesign, _f), (_g = {}, _g[styles.isPantryQuantity] = isPantry, _g)), value: proxyValue, autoComplete: "off", onChange: function (e) { return _this.change(e.target.value); }, onKeyDown: _this.keyDown, onFocus: function (e) { return _this.onFocus(e); }, onBlur: function (e) { return _this.onBlur(e); }, disabled: !isLoggedIn || disabled || (isCheckout && _this.props.useNewDesign && disableIfValueEqualsZero) }),
                    React.createElement("div", { className: classnames(styles.unitOffset, (_h = {}, _h[styles['description--medium']] = useNewDesign, _h)) }, !!selectedVariant && selectedVariant.unitShortName),
                    !hideButtons && (React.createElement("span", { onMouseDown: function (e) { return (_this.ignoreAddClick = hasChangedValue); }, onClickCapture: function (e) {
                            if (_this.ignoreAddClick) {
                                /*  Since we change the hasChangedValue on input blur event which happens onMouseDown and it will change
                              the hasChangedValue state to false before the user release the mouse button and the onClick event is fired.
                              So when the click event is fired the add button is in the DOM and we will call this.add();
                              This onClickCapture event will not bubble up, but instead downwards so we are stopping it from propagating down to the button.
                          */
                                e.stopPropagation();
                            }
                        } }, hasChangedValue ? (React.createElement(Button, { title: "OK", appearance: appearance, onClick: _this.onOkButtonClick, className: styles.okbutton, disabled: !isLoggedIn || disabled || (isCheckout && _this.props.useNewDesign && disableIfValueEqualsZero) },
                        React.createElement("div", { className: styles.okbuttonInner }, "OK"))) : (React.createElement(Button, { title: translate('/Product/MaximizeQty'), appearance: appearance, onClick: function () { return _this.stepToNextQuantity(1); }, className: styles.plusbutton, disabled: !isLoggedIn || disabled || proxyValueNumber >= maxQuantity || (isCheckout && _this.props.useNewDesign && disableIfValueEqualsZero) }, _this.props.useNewDesign ? React.createElement(Icon, { name: 'plus' }) : '+')))))));
        }));
    };
    Quantity.defaultProps = { maxQuantity: 999 };
    return Quantity;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
}); })(Quantity);
