var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ContentCard from 'Shared/ContentCard/';
import * as styles from './boxes.scss';
export default function ProductCardListLargeAd(props) {
    return React.createElement(ContentCard, __assign({}, props.largeAd, { appearance: "large", key: "Ad", className: styles.ad }));
}
