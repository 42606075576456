var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Checkbox } from 'Shared/Fields';
import { translate } from 'Shared/translate';
import { urlToString } from 'Shared/url';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import { pushState } from 'Shared/history';
var PantryListItemRow = /** @class */ (function (_super) {
    __extends(PantryListItemRow, _super);
    function PantryListItemRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PantryListItemRow.prototype.inventoryStatusText = function () {
        switch (this.props.inventoryStatus) {
            case 'ongoing':
                return "" + translate('/App/Pantry/Ongoing');
            case 'completed':
                return translate('/App/Pantry/Completed');
            case 'never':
            default:
                return translate('/App/Pantry/Never');
        }
    };
    PantryListItemRow.prototype.render = function () {
        var _a = this.props, url = _a.url, displayName = _a.displayName;
        return (React.createElement("tr", null,
            React.createElement("td", { onClick: this.props.toggleCheck },
                React.createElement(Checkbox, { checked: this.props.checked }),
                displayName),
            React.createElement("td", { onClick: this.props.toggleCheck }, this.inventoryStatusText()),
            React.createElement("td", { className: styles.end, onClick: function () { return pushState(urlToString(url)); } },
                React.createElement(Icon, { name: "info" }))));
    };
    return PantryListItemRow;
}(React.Component));
export default PantryListItemRow;
