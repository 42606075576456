var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import classnames from 'classnames';
import Overlay from 'Shared/Overlay';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import { translate } from 'Shared/translate';
import { formatPrice } from 'Shared/number-format';
import { toggleMiniCart } from './../MiniCart/action-creators';
import { reloadCart } from './../../Cart/action-creators';
import { SMALL } from 'Shared/breakpoints';
import { isIOS } from 'Shared/device-type';
import shallowEquals from 'Shared/shallow-equals';
import * as miniCartStyles from './mini-cart-button.scss';
// TODO - Include if client wants this later on
function getCount(cart) {
    if (!!cart.items && cart.items.length > 0) {
        var codes = cart.items.map(function (x, i) {
            return x.lineItem.code;
        });
        if (codes.length > 0) {
            var distinctCodes = codes.reduce(function (distinct, code) { return (distinct.indexOf(code) !== -1 ? distinct : __spread(distinct, [code])); }, []);
            if (distinctCodes.length === 1) {
                return distinctCodes.length;
            }
            return distinctCodes.length;
        }
    }
    return 0;
}
var MiniCartButton = /** @class */ (function (_super) {
    __extends(MiniCartButton, _super);
    function MiniCartButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MiniCartButton.prototype.componentDidUpdate = function (nextProps) {
        this.updateIndicators(nextProps.cart);
    };
    MiniCartButton.prototype.updateIndicators = function (cart) {
        var _this = this;
        if (cart === void 0) { cart = this.props.cart; }
        var hasSubtotals = this.props.cart.subTotal && cart.subTotal;
        if (!this.props.isRestrictedCustomer &&
            (!hasSubtotals || !shallowEquals(this.props.cart.subTotal, cart.subTotal))) {
            this.button.setAttribute('data-highlight', 'data-highlight');
            var resetPlayState_1 = function () {
                _this.button.removeAttribute('data-highlight');
                _this.button.removeEventListener('animationend', resetPlayState_1);
            };
            this.button.addEventListener('animationend', resetPlayState_1);
        }
    };
    MiniCartButton.prototype.render = function () {
        var _this = this;
        var _a;
        var isTooltip = this.props.currentBreakpoint > SMALL;
        var count = getCount(this.props.cart);
        var orderRows = count === 1
            ? count + ' ' + translate('/MiniCart/OrderRow')
            : formatPrice(count) + ' ' + translate('/MiniCart/OrderRows');
        var formattedPrice = formatPrice(!!this.props.cart.subTotal ? this.props.cart.subTotal.current : 0) +
            ' ' +
            (!!this.props.cart.subTotal ? this.props.cart.subTotal.currencySymbol : '');
        if (this.props.isRestrictedCustomer) {
            return null;
        }
        return (React.createElement(Overlay, { enabled: isTooltip && this.props.miniCartIsOpen, onClose: function () { return _this.props.toggleMiniCart(); }, className: classnames(miniCartStyles.base, (_a = {}, _a[miniCartStyles.ios] = isIOS(), _a), this.props.cart.items && this.props.cart.items.length > 0
                ? miniCartStyles.cartnotempty
                : miniCartStyles.cartempty, 'ui-test-mini-cart') },
            React.createElement("button", { type: "button", "data-total": !this.props.isSupplierSalesman ? formattedPrice : '', "data-count": !!count ? count : 0, onClick: function () { return _this.props.toggleMiniCart(); }, className: classnames(miniCartStyles.button, this.props.cart.items.length > 0 ? miniCartStyles.cartnotempty : miniCartStyles.cartempty), ref: function (el) { return (_this.button = el); } },
                count > 0 && React.createElement("span", { className: miniCartStyles.orderRows }, orderRows),
                React.createElement(Icon, { name: "cart", className: count > 0 ? miniCartStyles.cartIcon : '' }))));
    };
    return MiniCartButton;
}(React.Component));
export default connect(function (state) { return ({
    miniCartIsOpen: state.miniCart.isOpen,
    currentBreakpoint: state.currentBreakpoint,
    cart: state.cart,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
}); }, function (dispatch) { return ({
    toggleMiniCart: function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(toggleMiniCart());
    },
    reloadCart: function () {
        return dispatch(reloadCart());
    },
}); })(MiniCartButton);
