import React from 'react';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import * as styles from '../OrderHeader/details.scss';
import { translate } from 'Shared/translate';
import ProductMarks from '../OrderProduct/OrderProductMark';
export var Mobile = function (_a) {
    var product = _a.product, selectedVariant = _a.selectedVariant, index = _a.index, unit = _a.unit, currentGrouping = _a.currentGrouping, currentTab = _a.currentTab;
    return (React.createElement("div", { key: index, className: styles.mobileTopRowData },
        React.createElement("div", { className: styles.imageWrap },
            React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.name, size: "tiny" })),
        React.createElement("div", { className: styles.productInformationWrap },
            React.createElement("div", { className: styles.topRowWrap },
                React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/DisplayName')),
                React.createElement(ProductTitle, { linkUrl: '', ticket: '', displayName: product.name }),
                React.createElement(ProductDetails, { code: product.articleNumber, linkUrl: '', ticket: '', trademark: '' }),
                React.createElement(ProductMarks, { IsUtf: product.isUtf })),
            React.createElement("div", { className: styles.leftRightRowContentWrap },
                React.createElement("div", { className: styles.leftRowContent },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/Information')),
                        React.createElement("div", null, product.information)),
                    React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/Quantity')),
                        React.createElement("div", null, product.quantity)),
                    currentTab === 'currentOrders' && currentGrouping !== 'PerOrder' && (React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/OrderNumber')),
                        React.createElement("div", null, product.orderNumber)))),
                React.createElement("div", { className: styles.rightRowContent },
                    React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/Units')),
                        React.createElement("div", null, unit)),
                    React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/UnitPrice')),
                        React.createElement("div", null,
                            React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: [selectedVariant], productIsInCampaign: false, isWeightProduct: false, showOriginal: true }))),
                    currentTab === 'currentOrders' && currentGrouping === 'ViewAll' && (React.createElement("div", null,
                        React.createElement("div", { className: styles.rowLbl }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/DeliveryDate')),
                        React.createElement("div", null, product.deliveryDate))))))));
};
