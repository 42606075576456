var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
import { pushState } from 'Shared/history';
import { addToDataLayer } from './../../../TrackingInformation';
var ShortcutButtonsBlock = function (props) {
    var _a = __read(useState([]), 2), buttons = _a[0], setButtons = _a[1];
    useEffect(function () {
        var tempButtons = [];
        for (var i = 1; i < 6; i++) {
            if (propertyValueFor(props.block["button" + i + "Link"])) {
                tempButtons.push({
                    link: propertyValueFor(props.block["button" + i + "Link"]),
                    image: propertyValueFor(props.block["button" + i + "Image"]),
                    text: propertyValueFor(props.block["button" + i + "Text"]),
                });
            }
        }
        setButtons(tempButtons);
    }, [props]);
    var sendToDataLayerAndRedirect = function (eventType, link) {
        var dlEvent = {
            event: 'Kampanjpuffar',
            eventInfo: {
                eventCategory: 'Kampanjpuff',
                eventAction: 'Click',
                eventLabel: eventType,
            },
        };
        addToDataLayer(dlEvent);
        if (!!link)
            pushState(link);
    };
    return (React.createElement("div", { className: classnames(styles.main, propertyValueFor(props.block.disableTopPadding) ? styles.disableTopPadding : '') },
        propertyValueFor(props.block.header) && React.createElement("h3", null, propertyValueFor(props.block.header)),
        React.createElement("div", { className: classnames(styles.container) }, buttons.map(function (button, index) { return (React.createElement("button", { key: index, className: classnames(styles.button), onClick: function () { return sendToDataLayerAndRedirect(button.text, button.link); } },
            !!button.image && React.createElement("img", { src: button.image }),
            !!button.text && React.createElement("div", { className: classnames(styles.text, styles.bodyMedium) }, button.text))); }))));
};
export default ShortcutButtonsBlock;
