var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PropertyFor from 'Shared/PropertyFor';
import React from 'react';
import FeedbackButton from 'Shared/Button/Feedback';
import * as server from 'Shared/server';
import { translate } from 'Shared/translate';
import { pushState } from 'Shared/history';
import { pathCombine } from 'Shared/url';
import Spinner from 'Shared/Spinner';
import connect from 'Shared/connect';
import { updateAppShellData } from '../../../AppShell/action-creators';
import { searchUsers, setNewImpersonation } from '../../action-creators';
import * as styles from './base.scss';
import { updateUserNameOnImbox } from './../../../TrackingInformation';
import { nuke } from 'SiteLayout/CurrentPage/cache';
import MyMenigoLayout from '../../../MyMenigo/Pages/MyMenigoLayout';
var feedbackDelay = 500;
var ImpersonationPage = /** @class */ (function (_super) {
    __extends(ImpersonationPage, _super);
    function ImpersonationPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { searchInput: '' };
        return _this;
    }
    ImpersonationPage.prototype.searchforUsers = function (e) {
        var _this = this;
        clearTimeout(this.currentTimeout);
        if (e.length > 2) {
            if (!this.state.searchingForUsers) {
                this.setState({ searchingForUsers: true });
            }
            this.currentTimeout = setTimeout(function () {
                _this.props.searchUsers(e).then(function (result) {
                    var slicedArr = result.slice(0, 10);
                    _this.setState({ searchResult: slicedArr, searchingForUsers: false });
                });
            }, 500);
        }
        else if (this.state.searchingForUsers || !!this.state.searchResult) {
            this.setState({ searchResult: null, searchingForUsers: false });
        }
    };
    ImpersonationPage.prototype.onSubmit = function (e, id) {
        e.preventDefault();
        return server
            .postJson(pathCombine(location.pathname, '/ImpersonateUser'), id)
            .then(function (result) {
            if (result.success) {
                return nuke(); // clear cache and return resolved promise
            }
            else {
                return Promise.reject(result.message);
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    ImpersonationPage.prototype.render = function () {
        var _this = this;
        var preamble = (React.createElement(React.Fragment, null,
            React.createElement(PropertyFor, { property: this.props.page.name, tagName: "h1" }),
            React.createElement(PropertyFor, { property: this.props.page.mainBody, tagName: "p" })));
        return (React.createElement(MyMenigoLayout, { topLeft: preamble },
            React.createElement("form", null,
                React.createElement("fieldset", null,
                    React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement("div", { className: styles.searchInputWrapper },
                                React.createElement("input", { value: this.state.searchInput, ref: function (el) { return (_this.customerSearch = el); }, placeholder: translate('/Account/Impersonation/CustomerInput'), onChange: function (e) {
                                        return _this.setState({ searchInput: e.target.value }, function () {
                                            return _this.searchforUsers(_this.state.searchInput);
                                        });
                                    } }),
                                this.state.searchingForUsers && (React.createElement("div", { className: styles.spinnerWrapper },
                                    React.createElement(Spinner, null)))),
                            !!this.state.searchResult && this.state.searchResult.length > 0 && (React.createElement("table", { className: styles.list },
                                React.createElement("tbody", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, translate('/Account/Impersonation/AccountNumber')),
                                        React.createElement("th", null, translate('/Account/Impersonation/CustomerName')),
                                        React.createElement("th", null)),
                                    this.state.searchResult.map(function (contact, i) { return (React.createElement("tr", { key: i },
                                        React.createElement("td", null, contact.customerId),
                                        React.createElement("td", null, contact.name),
                                        React.createElement("td", null,
                                            React.createElement(FeedbackButton, { type: "submit", appearance: "secondary", onClickWithFeedback: function (e, activate) {
                                                    var promiseOrUndefined = _this.onSubmit(e, contact.customerId);
                                                    if (promiseOrUndefined) {
                                                        _this.props.setNewImpersonation();
                                                        activate(promiseOrUndefined, { minimumPending: feedbackDelay })
                                                            .then(function () { return _this.props.reloadAppShell(); })
                                                            .then(function () {
                                                            updateUserNameOnImbox(_this.props.currentUser);
                                                        })
                                                            .then(function () { return pushState('/'); });
                                                    }
                                                } }, translate('/Account/Impersonation/Impersonate'))))); }))))))))));
    };
    return ImpersonationPage;
}(React.Component));
export default connect(function (state) { return ({
    currentUser: state.currentUser,
}); }, function (dispatch) { return ({
    searchUsers: function (query) {
        return dispatch(searchUsers(query));
    },
    setNewImpersonation: function () {
        dispatch(setNewImpersonation());
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
}); })(ImpersonationPage);
