import React from 'react';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { Input } from 'Shared/Fields';
function CopyDialog(props) {
    var textInput = React.createRef();
    return (React.createElement(Modal, { title: props.isPantry
            ? translate('/Account/ProductList/NamePantryList')
            : translate('/Account/ProductList/NameFavoriteList'), isOpen: props.isOpen, onClose: props.closeModal, reserveSpaceForCloseButton: true, fadeDesign: true },
        React.createElement(Input, { ref: textInput, autoFocus: true }),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return props.addRecommendationsToNewList(textInput.current.element.value); }, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal })));
}
export default CopyDialog;
