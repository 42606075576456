var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import moment from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
export var DATE_FORMAT = 'YYYY-MM-DD';
var DeliveryDateSelection = /** @class */ (function (_super) {
    __extends(DeliveryDateSelection, _super);
    function DeliveryDateSelection(props) {
        var _this = _super.call(this, props) || this;
        _this.isOutsideRange = function (day) {
            return !_this.isInRange(day) || _this.isBeforeAvailabilityDate(day);
        };
        _this.isBeforeAvailabilityDate = function (day) {
            var availabilityDate = _this.props.availability;
            return availabilityDate && day.isBefore(moment(availabilityDate.availabilityDate, DATE_FORMAT), 'day');
        };
        _this.isInRange = function (day) {
            return (_this.props.deliveryDays || []).some(function (d) { return day.format(DATE_FORMAT) === d.date; });
        };
        _this.onDateChange = function (day) {
            _this.setState({ selectedDay: day });
            if ((_this.isInRange(day) && !day.isBefore(moment())) ||
                moment(day).format(DATE_FORMAT) ===
                    moment()
                        .add(1, 'days')
                        .format(DATE_FORMAT)) {
                _this.props.onDateSelected(day, true);
            }
        };
        _this.onFocusChange = function (arg) {
            _this.setState({ focused: arg.focused });
        };
        _this.state = {
            focused: true,
            selectedDay: _this.getSelectedDayFromProps(),
        };
        return _this;
    }
    DeliveryDateSelection.prototype.getSelectedDayFromProps = function () {
        if (this.props.selectedDay) {
            return this.props.selectedDay;
        }
        else if (this.props.deliveryDays && this.props.deliveryDays.length > 0) {
            return moment(this.props.deliveryDays[0].date);
        }
        return null;
    };
    DeliveryDateSelection.prototype.render = function () {
        return (React.createElement(DayPickerSingleDateController, { date: this.state.selectedDay, focused: this.state.focused, isOutsideRange: this.isOutsideRange, onDateChange: this.onDateChange, onFocusChange: this.onFocusChange, enableOutsideDays: false }));
    };
    return DeliveryDateSelection;
}(React.Component));
export default DeliveryDateSelection;
