import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import React from 'react';
import * as styles from './base.scss';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
var ProductCarbonFootPrint = function (_a) {
    var carbonFootPrint = _a.carbonFootPrint, carbonCloudLink = _a.carbonCloudLink;
    var round = function (num, precision) { return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision); };
    return !!carbonFootPrint && !!carbonCloudLink && (React.createElement("div", { className: classnames(styles.carbonCloud) },
        React.createElement("div", { className: classnames(styles.first) },
            React.createElement("div", { className: styles.banner },
                React.createElement("div", { className: styles.svg },
                    React.createElement(MyMenigoIcon, { name: "carbonFootprint" })),
                React.createElement("div", { className: styles.text },
                    React.createElement("strong", null, translate('/Product/CarbonCo2UnitTitle')),
                    translate('/Product/CarbonCo2Unit'))),
            React.createElement("div", { className: styles.big_text },
                round(carbonFootPrint, 1),
                translate('/Units/UnitShortNames/kg'))),
        React.createElement("div", { className: classnames(styles.last) },
            React.createElement("div", null,
                translate("/Product/CarbonFootprintText", round(carbonFootPrint, 1)),
                React.createElement("br", null),
                React.createElement("a", { href: carbonCloudLink, target: "_blank" },
                    translate('/Product/CarbonFootLinkText'),
                    React.createElement(MyMenigoIcon, { name: "arrow" }))))));
};
export default ProductCarbonFootPrint;
