import React from 'react';
import * as styles from './base.scss';
var ProductTrademarkLink = function (_a) {
    var link = _a.link, ticket = _a.ticket, children = _a.children, title = _a.title, openNewTab = _a.openNewTab;
    if (!link) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement("a", { href: link, "data-ticket": ticket, className: styles.Link, title: title, target: openNewTab && '_blank' }, children));
};
export default ProductTrademarkLink;
