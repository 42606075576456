var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { isBrowser } from "Shared/device-type";
import styles from "./tooltip.scss";
var ToolTip = function (_a) {
    var text = _a.text, header = _a.header, direction = _a.direction, persistent = _a.persistent, small = _a.small, children = _a.children, carbonCloud = _a.carbonCloud;
    var _b = __read(useState(false), 2), active = _b[0], setActive = _b[1];
    var _c = __read(useState(false), 2), alwaysActive = _c[0], setAlwaysActive = _c[1];
    var _d = __read(useState(direction), 2), toolTipDirection = _d[0], setToolTipDirection = _d[1];
    var ref = useRef(null);
    var tooltipRef = useRef(null);
    var handleClick = function () {
        !!persistent && setAlwaysActive(true);
    };
    var handleClickOutside = function (e) {
        hideToolTip();
        setAlwaysActive(!!persistent);
    };
    var showToolTip = function () {
        setActive(true);
        !!!persistent && adjust();
    };
    var hideToolTip = function () {
        setActive(false);
    };
    var getToolTipDirection = function (direction) {
        if (direction === "top")
            return styles.top;
        if (direction === "left")
            return styles.left;
        if (direction === "bottom")
            return styles.bottom;
        if (direction === "right")
            return styles.right;
    };
    var adjust = function () {
        if (!isBrowser() || !ref.current || !tooltipRef.current) {
            return;
        }
        var elRect = ref.current.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();
        var tooltipRect = tooltipRef.current.getBoundingClientRect();
        if (elRect.right + 150 > bodyRect.right) {
            setToolTipDirection("left");
        }
        else if (elRect.left - 250 < bodyRect.left) {
            setToolTipDirection("right");
        }
        else if (elRect.top - tooltipRect.height - 250 < 0) {
            setToolTipDirection("bottom");
        }
        else {
            setToolTipDirection("top");
        }
    };
    var getWidth = function (text) {
        return (text.length * 3.25) + 'px';
    };
    useEffect(function () {
        setAlwaysActive(persistent);
        !!!persistent && adjust();
        var listener = function (event) {
            var el = ref.current;
            if (!el || el.contains(event.target)) {
                return;
            }
            handleClickOutside(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handleClickOutside]);
    return (React.createElement("div", { ref: ref, onMouseEnter: showToolTip, onMouseLeave: hideToolTip, onClick: handleClick, className: styles.TooltipWrapper },
        React.createElement("div", { className: styles.center }, (active || alwaysActive) && (React.createElement("div", { ref: tooltipRef, "data-type": "tooltip", style: { width: getWidth(text) }, className: classnames(styles.Tooltip, !!small && styles.small, (getToolTipDirection(toolTipDirection) || styles.top), !!carbonCloud && styles.carbonCloud) },
            !!header && (React.createElement("strong", null, header)),
            React.createElement("div", null, text)))),
        children));
};
export default ToolTip;
