var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import Autocompletions from './Autocompletions';
import Icon from 'Shared/Icon';
import * as searchHistory from '../../../../../Search/search-history';
import * as styles from './field.scss';
import * as mobilestyles from './mobile.scss';
import MyMenigoIcon from '../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import Spinner from 'Shared/Spinner';
var QuickSearchField = /** @class */ (function (_super) {
    __extends(QuickSearchField, _super);
    function QuickSearchField(props) {
        var _this = _super.call(this, props) || this;
        _this.onClear = function () {
            if (_this.props.searchText === '') {
                _this.props.onClose();
            }
            else {
                _this.props.onChange('');
                _this.input.focus();
            }
        };
        _this.onFocus = function () {
            if (!_this.hasFocus) {
                _this.props.onChange(_this.props.searchText);
            }
            _this.hasFocus = true;
            clearTimeout(_this.blurTimer);
        };
        _this.onChange = function (e) {
            var searchText = e.target.value;
            _this.setState({ loadingQuickSearch: true });
            _this.props.onChange(searchText).then(function () { _this.setState({ loadingQuickSearch: false }); });
        };
        _this.onSubmit = function (e) {
            e.preventDefault();
            _this.props.onSearch(_this.props.searchText);
            _this.input.blur(); // Needed for iOS10 to close keyboard
        };
        _this.onAutocompleClick = function (text) {
            searchHistory.add(text);
        };
        _this.state = {
            loadingQuickSearch: false,
        };
        return _this;
    }
    QuickSearchField.prototype.componentDidMount = function () {
        if (this.input.setSelectionRange) {
            this.input.setSelectionRange(this.props.searchText.length, this.props.searchText.length);
        }
    };
    QuickSearchField.prototype.render = function () {
        var _this = this;
        var _a = this.props, searchText = _a.searchText, completionsLists = _a.completionsLists;
        var hasCompletions = completionsLists.reduce(function (i, l) {
            return i +
                (l.items && l.items.length ? l.items.length : 0) +
                (l.products && l.products.length ? l.products.length : 0);
        }, 0) > 0;
        var hasProducts = completionsLists.reduce(function (i, l) {
            return i +
                (l.products && l.products.length ? l.products.length : 0);
        }, 0) > 0;
        return (React.createElement("form", { onSubmit: function (e) { return _this.onSubmit(e); }, className: styles.base },
            React.createElement("div", { className: styles.mobileTopWrapper },
                React.createElement("div", { className: styles.searchTop },
                    React.createElement("button", { type: "button", className: styles.close, onClick: this.props.onClose },
                        React.createElement(Icon, { name: "close" })),
                    React.createElement("span", null, translate("/Shared/Search")))),
            React.createElement("div", { className: classnames(styles.searchInputWrapper) },
                React.createElement("div", { className: styles.icon },
                    React.createElement(MyMenigoIcon, { name: 'search' })),
                React.createElement("input", { className: styles.searchInput, placeholder: translate('/QuickSearch/Placeholder'), type: "search", value: this.props.searchText, onFocus: function () { return _this.onFocus(); }, onChange: function (e) { return _this.onChange(e); }, ref: function (el) {
                        _this.input = el;
                    }, autoFocus: true }),
                React.createElement("button", { type: "submit", className: mobilestyles.hiddenSearchButton, style: { opacity: 0 } }),
                !!this.state.loadingQuickSearch && React.createElement(Spinner, { className: styles.smallspinner }),
                !this.state.loadingQuickSearch && this.props.searchText.length > 1 && (React.createElement("button", { type: "button", className: mobilestyles.clear, onClick: function (e) { return _this.onClear(); } },
                    React.createElement(Icon, { name: "close" })))),
            React.createElement(Autocompletions, { lists: completionsLists, thinSelection: !hasProducts, searchText: searchText, onItemClick: this.onAutocompleClick })));
    };
    return QuickSearchField;
}(React.Component));
export default QuickSearchField;
