import { PAGE_LOAD_SUCCESS } from 'SiteLayout/CurrentPage/action-creators';
import { getSearchTextFromUrl } from '../../reducer';
import { componentIsSearch } from './current-page-is-search';
export default function (state, action) {
    if (state === void 0) { state = null; }
    if (componentIsSearch(state.componentName)) {
        var searchPage = state;
        switch (action.type) {
            case PAGE_LOAD_SUCCESS: {
                var pageLoadSuccessAction = action;
                var oldSearch = getSearchTextFromUrl(pageLoadSuccessAction.urlBeforeLoad);
                var newSearch = getSearchTextFromUrl(pageLoadSuccessAction.url);
                if (oldSearch && oldSearch !== newSearch) {
                    return Object.assign({}, searchPage, {
                        scrollPosition: 0,
                    });
                }
                return searchPage;
            }
            default:
                return searchPage;
        }
    }
    return state;
}
