var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import DropDownMenu, { DropDownItem, DropDownSeparator, DropDownWrapper } from 'Shared/DropDownMenu';
import { translate } from 'Shared/translate';
import MoveDialog from './MoveDialog';
import CopyDialog from './CopyDialog';
import FavoriteDialog from './FavoriteDialog';
import CloseInventDialog from './CloseInventDialog';
import DeleteDialog from './DeleteDialog';
import ProductSearch from 'Shared/ProductSearch';
import { removeProductsFromList, copyToList, copyToOtherList, lockList, shareList } from 'Shared/ListItem/action-creators';
import { startInvent, closeInvent } from './../../../action-creators';
import * as styles from '../base.scss';
import ReOpenDialog from './ReOpenDialog';
import StartInventDialog from './StartInventDialog';
import Button from 'Shared/Button';
import classnames from 'classnames';
import CustomProductDialog from './CustomProductDialog';
function disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) {
    if (isMaster && !isDeleteDisabled) {
        return false;
    }
    else if (!isDeleteDisabled || showOnlyUnlockedAction) {
        return false;
    }
    else if (disabled || isDeleteDisabled) {
        return true;
    }
}
var Actionbar = /** @class */ (function (_super) {
    __extends(Actionbar, _super);
    function Actionbar(props) {
        var _this = _super.call(this, props) || this;
        _this.showCopyModal = function () {
            _this.setState({ isCopyModalOpen: true });
        };
        _this.hideCopyModal = function () {
            _this.setState({ isCopyModalOpen: false });
        };
        _this.showMoveModal = function () {
            _this.setState({ isMoveModalOpen: true });
        };
        _this.hideMoveModal = function () {
            _this.setState({ isMoveModalOpen: false });
        };
        _this.showDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: true });
        };
        _this.hideDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: false });
        };
        _this.showFavoriteModal = function () {
            _this.setState({ isFavoriteModalOpen: true });
        };
        _this.hideFavoriteModal = function () {
            _this.setState({ isFavoriteModalOpen: false });
        };
        _this.showCloseInventModal = function () {
            _this.setState({ isCloseInventModalOpen: true });
        };
        _this.hideCloseInventModal = function () {
            _this.setState({ isCloseInventModalOpen: false }, function () {
                _this.props.triggerReloadList();
            });
        };
        _this.showStartModal = function () {
            _this.setState({ isStartModalOpen: true });
        };
        _this.hideStartModal = function () {
            _this.setState({ isStartModalOpen: false }, function () {
                _this.props.triggerReloadList();
            });
        };
        _this.showReOpenModal = function () {
            _this.setState({ isReOpenModalOpen: true });
        };
        _this.hideReOpenModal = function () {
            _this.setState({ isReOpenModalOpen: false }, function () {
                _this.props.triggerReloadList();
            });
        };
        _this.showCustomProductModal = function () {
            _this.setState({ isCustomProductModelOpen: true });
            _this.disableBodyScroll();
        };
        _this.hideCustomProductModal = function () {
            _this.setState({ isCustomProductModelOpen: false });
            _this.enableBodyScroll();
        };
        _this.state = {
            isCopyModalOpen: false,
            isMoveModalOpen: false,
            isDeleteModalOpen: false,
            isFavoriteModalOpen: false,
            isCloseInventModalOpen: false,
            isReOpenModalOpen: false,
            isStartModalOpen: false,
            isCustomProductModelOpen: false,
        };
        return _this;
    }
    Actionbar.prototype.disableBodyScroll = function () {
        document.body.setAttribute("style", "overflow:hidden");
    };
    Actionbar.prototype.enableBodyScroll = function () {
        document.body.setAttribute("style", "overflow:visible");
    };
    Actionbar.prototype.render = function () {
        var _a = this.props, invStatus = _a.invStatus, listId = _a.listId, selectedItems = _a.selectedItems, disabled = _a.disabled, searchQuery = _a.searchQuery, updateSearchPhrase = _a.updateSearchPhrase, copyToList = _a.copyToList, copyToOtherList = _a.copyToOtherList, save = _a.save, startInvent = _a.startInvent, isMaster = _a.isMaster, lockPantry = _a.lockPantry, sharePantry = _a.sharePantry, isLocked = _a.isLocked, isShared = _a.isShared, currentPage = _a.currentPage;
        var favoriteLists = (currentPage.favoriteLists || []).filter(function (list) { return list.id !== listId; });
        var pantryLists = (currentPage.lists || []).filter(function (list) { return list.id !== listId; });
        var activeList = this.props.currentPage.lists.find(function (i) { return i.id === listId; });
        var getCheckedItemsInList = function (list) {
            return list.products.filter(function (item) { return selectedItems.includes(item.selectedVariant.code); });
        };
        var checkedItemsInList = getCheckedItemsInList(activeList);
        var hasLockedItems = function (list) { return list.some(function (item) { return item.isLocked; }); };
        var hasUnlockedItems = function (list) { return list.some(function (item) { return !item.isLocked; }); };
        var isDeleteDisabled = checkedItemsInList.length === 0 || (!isMaster && !hasUnlockedItems(checkedItemsInList));
        var showOnlyUnlockedAction = !isMaster && hasLockedItems(checkedItemsInList) && hasUnlockedItems(checkedItemsInList);
        var showAddCustomProductInPantry = true;
        return (React.createElement("div", { className: styles.actionbar },
            React.createElement("div", { className: styles.actionbarInner },
                React.createElement("div", { className: styles.actionMenuWrap },
                    React.createElement(DropDownMenu, { overrideMobile: true, title: translate('/Account/ProductList/AddTo'), className: styles.actionMenu },
                        React.createElement(DropDownItem, { value: "copyToNewList", text: translate('/Account/ProductList/CopyToNewPantryList'), onClick: this.showCopyModal, disabled: disabled }),
                        React.createElement(DropDownItem, { value: "copyToNewFavoriteList", text: translate('/Account/ProductList/CopyToNewFavoriteList'), onClick: this.showFavoriteModal, disabled: disabled }),
                        favoriteLists && [
                            React.createElement(DropDownSeparator, { key: "fav_sep", text: translate('/Account/ProductList/FavoriteLists') }),
                            React.createElement(DropDownWrapper, { key: "fav_wra", className: styles.wrapper }, favoriteLists.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return copyToOtherList(list.id, selectedItems); }, disabled: disabled })); })),
                        ],
                        pantryLists && [
                            React.createElement(DropDownSeparator, { key: "pan_sep", text: translate('/Account/ProductList/PantryLists') }),
                            React.createElement(DropDownWrapper, { key: "pan_wra", className: styles.wrapper }, pantryLists.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return copyToList(list.id, selectedItems); }, disabled: disabled })); })),
                        ]),
                    React.createElement(DropDownMenu, { overrideMobile: true, title: translate('/Account/ProductList/Actions'), className: styles.actionMenu },
                        React.createElement(DropDownItem, { value: "removeSelectedProductsFromList", text: translate('/Account/ProductList/DeleteSelected'), onClick: this.showDeleteModal, disabled: disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) }),
                        React.createElement(DropDownItem, { value: "save", text: translate('/Account/ProductList/SaveChanges'), onClick: save }),
                        React.createElement(DropDownItem, { value: "moveToList", text: translate('/Account/ProductList/MoveSelected'), onClick: this.showMoveModal, disabled: disableDropDownActions(disabled, isMaster, isDeleteDisabled, showOnlyUnlockedAction) }),
                        React.createElement(DropDownItem, { value: "startInvent", text: translate('/Account/ProductList/startInvent'), onClick: this.showStartModal, disabled: invStatus === 'ongoing' || invStatus === 'reOpened' ? true : false }),
                        React.createElement(DropDownItem, { value: "reOpenInvent", text: translate('/Account/ProductList/ReOpenInventory'), onClick: this.showReOpenModal, disabled: invStatus !== 'completed' ? true : false, className: styles.newOption, customMarking: translate('/Account/ProductList/NewOption') }),
                        React.createElement(DropDownItem, { value: "closeInvent", text: translate('/Account/ProductList/CloseInvent'), onClick: this.showCloseInventModal, disabled: (invStatus !== 'ongoing' && invStatus !== 'reOpened') }),
                        !isShared && isMaster && (React.createElement(DropDownItem, { value: "sharePantry", text: translate('/Account/ProductList/SharePantry'), onClick: function () { return sharePantry(); }, disabled: !isMaster })),
                        !isLocked && isMaster && isShared && (React.createElement(DropDownItem, { value: "lockPantry", text: translate('/Account/ProductList/LockPantry'), onClick: function () { return lockPantry(true); }, disabled: !isMaster })),
                        isLocked && isMaster && (React.createElement(DropDownItem, { value: "unlockPantry", text: translate('/Account/ProductList/UnLockPantry'), onClick: function () { return lockPantry(false); }, disabled: !isMaster })))),
                showAddCustomProductInPantry ? (React.createElement("div", { className: styles.buttonWrap },
                    React.createElement(Button, { appearance: ['toolbarbutton'], onClick: this.showCustomProductModal }, translate('/Account/ProductList/ManageNewArticles')))) : (React.createElement("div", { className: styles.searchInListWrap },
                    React.createElement("input", { className: styles.searchInList, id: "searchInList", placeholder: translate('/Account/ProductList/FilterInList'), defaultValue: searchQuery, onChange: function (e) { return updateSearchPhrase(e); } })))),
            React.createElement(MoveDialog, { isOpen: this.state.isMoveModalOpen, closeModal: this.hideMoveModal, listId: listId, codes: selectedItems, showOnlyUnlockedWillBeMoved: showOnlyUnlockedAction }),
            React.createElement(CopyDialog, { isOpen: this.state.isCopyModalOpen, closeModal: this.hideCopyModal, listId: listId, codes: selectedItems }),
            React.createElement(FavoriteDialog, { isOpen: this.state.isFavoriteModalOpen, closeModal: this.hideFavoriteModal, listId: listId, codes: selectedItems }),
            React.createElement(CloseInventDialog, { isOpen: this.state.isCloseInventModalOpen, closeModal: this.hideCloseInventModal, listId: listId }),
            React.createElement(DeleteDialog, { isOpen: this.state.isDeleteModalOpen, closeModal: this.hideDeleteModal, listId: listId, codes: selectedItems, showOnlyUnlockedWillBeDeleted: showOnlyUnlockedAction, isMaster: isMaster }),
            React.createElement(StartInventDialog, { listId: listId, isOpen: this.state.isStartModalOpen, closeModal: this.hideStartModal }),
            React.createElement(ReOpenDialog, { listId: listId, isOpen: this.state.isReOpenModalOpen, closeModal: this.hideReOpenModal }),
            !!showAddCustomProductInPantry &&
                React.createElement(CustomProductDialog, { activeList: activeList, listId: listId, isOpen: this.state.isCustomProductModelOpen, closeModal: this.hideCustomProductModal, triggerReloadList: this.props.triggerReloadList }),
            !!showAddCustomProductInPantry ?
                (React.createElement("div", { className: styles.actionbarInner },
                    React.createElement("div", { className: classnames(styles.actionMenuWrap, styles.searchBarWrap) }, (isMaster || !isLocked) && React.createElement(ProductSearch, { listId: listId, pantry: true, status: invStatus })),
                    React.createElement("div", { className: styles.searchInListWrap },
                        React.createElement("input", { className: styles.searchInList, id: "searchInList", placeholder: translate('/Account/ProductList/FilterInList'), defaultValue: searchQuery, onChange: function (e) { return updateSearchPhrase(e); } })))) :
                (React.createElement("div", { className: styles.actionbarInner }, (isMaster || !isLocked) && React.createElement(ProductSearch, { listId: listId, pantry: true, status: invStatus })))));
    };
    return Actionbar;
}(React.PureComponent));
export default connect(function (_a) {
    var currentPage = _a.currentPage;
    return ({
        currentPage: currentPage,
    });
}, function (dispatch, _a) {
    var listId = _a.listId;
    return ({
        removeProductsFromList: function (code) {
            return dispatch(removeProductsFromList(listId, code));
        },
        copyToList: function (toListId, code) {
            return dispatch(copyToList(listId, toListId, code));
        },
        copyToOtherList: function (toListId, code) {
            return dispatch(copyToOtherList(listId, toListId, code));
        },
        lockPantry: function (locked) {
            return dispatch(lockList(listId, locked));
        },
        sharePantry: function () {
            return dispatch(shareList(listId));
        },
        startInvent: function () { return dispatch(startInvent(listId)); },
        closeInvent: function () { return dispatch(closeInvent(listId)); },
    });
})(Actionbar);
