var scripts = {};
var scriptElements = {};
export default (function (url) {
    if (!(url in scripts)) {
        var element_1 = document.createElement('script');
        element_1.src = url;
        scripts[url] = new Promise(function (resolve, reject) {
            element_1.onload = function () {
                scriptElements[url] = element_1;
                resolve();
            };
            element_1.onerror = reject;
            document.body.appendChild(element_1);
        });
    }
    return scripts[url];
});
export function unloadScript(url) {
    var element = scriptElements[url];
    if (element) {
        delete scriptElements[url];
        delete scripts[url];
        console.debug('Removing script element for', url);
        document.body.removeChild(element);
    }
}
