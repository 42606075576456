import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import ViewTracker from 'Shared/ViewObserver/ViewTracker';
import { promoImpression, promoClick } from "../../../../../TrackingInformation";
var toPromo = function (marketingAd) {
    return {
        id: 'block-' + propertyValueFor(marketingAd.blockId),
        name: propertyValueFor(marketingAd.header) || propertyValueFor(marketingAd.blockName),
        creative: propertyValueFor(marketingAd.image),
        position: 'QuickSearch',
    };
};
export function onClick(event, marketingAd) {
    promoClick(toPromo(marketingAd));
}
export default function MarketingAdViewTracker(_a) {
    var children = _a.children, item = _a.item;
    var onView = function (visible, marketingAd) {
        if (!visible || !!!marketingAd)
            return;
        promoImpression(toPromo(marketingAd));
    };
    return (React.createElement(ViewTracker, { key: 'autocompleteAdViewTracker', onView: function (visible) { return onView(visible, item); } }, children));
}
