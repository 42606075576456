var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './report.scss';
import moment from 'moment';
var PantryReportItem = /** @class */ (function (_super) {
    __extends(PantryReportItem, _super);
    function PantryReportItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PantryReportItem.prototype.componentDidMount = function () {
        window.print();
    };
    PantryReportItem.prototype.render = function () {
        var report = this.props.report;
        var missingPrices = false;
        return (React.createElement("div", { className: styles.reportOverview },
            React.createElement("div", null,
                React.createElement("h3", null, translate('/Account/ProductList/PrintOptions/Table/Invent')),
                React.createElement("p", null, translate('/Account/ProductList/PrintOptions/Table/StockItem')),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Customer'),
                        ":"),
                    React.createElement("span", null, report.customerName)),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Date'),
                        ":"),
                    React.createElement("span", null, moment(Date.now()).format('YYYY-MM-DD')))),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", { className: styles.headerRow },
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/Name')),
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/OrderNo')),
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/Trademark')),
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/Count')),
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/Unit')),
                        React.createElement("th", null, translate('/Account/ProductList/PrintOptions/Table/Information')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/Price')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockIn')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockOut')))),
                report.groupedByPantries.map(function (pantry, i) {
                    missingPrices = missingPrices || pantry.missingPrices;
                    return (React.createElement("tbody", { key: 'printBody' + i, className: styles.groupSeparator },
                        React.createElement("tr", { className: styles.groupHeaderRow },
                            React.createElement("td", null,
                                pantry.displayName,
                                pantry.missingPrices && ' *')),
                        pantry.items.map(function (item, j) { return (React.createElement("tr", { key: 'printRow' + j, className: styles.itemSeparator },
                            React.createElement("td", null,
                                item.displayName,
                                item.missingPrice && ' **'),
                            React.createElement("td", null, item.productId),
                            React.createElement("td", null, item.trademark),
                            React.createElement("td", null, item.currentQuantity),
                            React.createElement("td", null, item.unit),
                            React.createElement("td", null, item.information),
                            React.createElement("td", { className: styles.right }, item.currentPrice.toFixed(2)),
                            React.createElement("td", { className: styles.right }, item.previousStockValue.toFixed(2)),
                            React.createElement("td", { className: styles.right }, item.currentStockValue.toFixed(2)))); }),
                        React.createElement("tr", { className: styles.totalSubgroup },
                            React.createElement("td", { colSpan: 7 },
                                translate('/Account/ProductList/PrintOptions/Table/Total'),
                                "/",
                                pantry.displayName),
                            React.createElement("td", { className: styles.right }, pantry.previousStockValue.toFixed(2)),
                            React.createElement("td", { className: styles.right }, pantry.currentStockValue.toFixed(2)))));
                }),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: styles.summaryByPantry },
                        React.createElement("td", { colSpan: 7 }, translate('/Account/ProductList/PrintOptions/Table/SummaryByPantry')),
                        React.createElement("td", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockIn')),
                        React.createElement("td", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockOut'))),
                    report.groupedByPantries.map(function (pantry, i) { return (React.createElement("tr", { key: 'printSummary' + i, className: styles.itemSeparator },
                        React.createElement("td", { colSpan: 7 }, pantry.displayName),
                        React.createElement("td", { className: styles.right }, pantry.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, pantry.currentStockValue.toFixed(2)))); })),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: styles.totalOverall },
                        React.createElement("td", { colSpan: 7 },
                            translate('/Account/ProductList/PrintOptions/Table/StockTotal'),
                            "/",
                            report.customerName),
                        React.createElement("td", { className: styles.right }, report.all.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, report.all.currentStockValue.toFixed(2))),
                    missingPrices && (React.createElement("tr", { className: styles.missingPrice },
                        React.createElement("td", { colSpan: 8 },
                            "* ",
                            translate('/Account/ProductList/PrintOptions/Table/MissingPricePantry'),
                            React.createElement("br", null),
                            "** ",
                            translate('/Account/ProductList/PrintOptions/Table/MissingPrice'))))))));
    };
    return PantryReportItem;
}(React.Component));
export default PantryReportItem;
