var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { translate } from 'Shared/translate';
import { removeRecipe } from './action-creators';
var RemoveRecipe = function (props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var onConfirm = useCallback(function () { return props.dispatch(removeRecipe(props.id)); }, [props.dispatch, props.id]);
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, className: props.className },
        React.createElement(Button, { appearance: props.appearance, onClick: function () { return setOpen(true); } }, props.appearance === 'icon' ? React.createElement(Icon, { name: "remove" }) : translate('/Shared/Delete')),
        React.createElement(Modal, { title: translate('/RecipeListing/HeaderConfirmRemoveRecipe'), isOpen: isOpen, onClose: handleClose, type: ModalType.Warning },
            React.createElement("span", null, translate('/RecipeListing/ConfirmRemoveRecipe', props.name, props.name)),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: onConfirm, onCancel: handleClose, onStatusWasFulfilled: handleClose }))));
};
export default connect(function (state) { return ({}); })(RemoveRecipe);
