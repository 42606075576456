var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Fragment } from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import ReactSwipe from 'react-swipe';
import { SMALL, MEDIUM, LARGE } from 'Shared/breakpoints';
import { on } from 'SiteLayout/Root';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import shallowEquals from 'Shared/shallow-equals';
import ProductCardList from 'Shared/ProductCard/List';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import * as styles from './base.scss';
import * as gridStyles from 'Shared/ProductCard/Fade/grid.scss';
var ProductCarousel = /** @class */ (function (_super) {
    __extends(ProductCarousel, _super);
    function ProductCarousel(props) {
        var _this = _super.call(this, props) || this;
        _this.setSameHeightOnCard = function () {
            var productCards = _this.blockWrapper.querySelectorAll('[class*=base---card---]');
            if (productCards.length >= 1) {
                var productCardsArray = __spread(productCards);
                var maxHeight = Math.max.apply(Math, __spread(productCardsArray.map(function (c) { return c.clientHeight; })));
                _this.cardHeight = maxHeight;
            }
        };
        _this.renderPrevAndNextButton = function (slides, hasNext, hasPrev) {
            var _a, _b;
            return (slides.length > 1 && (React.createElement(Fragment, null,
                React.createElement(Button, { onClick: function () { return _this.slidePrev(); }, className: classnames(styles.prev, (_a = {},
                        _a[styles.disabled] = !hasPrev,
                        _a)), appearance: ['secondary', 'round'], disabled: !hasPrev },
                    React.createElement(Icon, { name: "arrow-left" })),
                React.createElement(Button, { onClick: function () { return _this.slideNext(); }, className: classnames(styles.next, (_b = {},
                        _b[styles.disabled] = !hasNext,
                        _b)), appearance: ['secondary', 'round'], disabled: !hasNext },
                    React.createElement(Icon, { name: "arrow-right" })))));
        };
        _this.state = {
            currentIndex: 0,
            isTall: false,
            productsPerSlide: 1,
        };
        return _this;
    }
    ProductCarousel.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = this.props.id !== nextProps.id || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update of ProductCarousel');
        }
        return shouldUpdate;
    };
    ProductCarousel.prototype.componentDidMount = function () {
        var _this = this;
        if (this.props.products.length > 0) {
            this.blockWrapper.classList.remove(styles.loading);
            var productsPerSlide = this.productsPerSlide();
            this.setState({ productsPerSlide: productsPerSlide });
            this.checkIfIsTall();
            this.setSameHeightOnCard();
            this.unbindResize = on('resize', function () {
                _this.checkIfIsTall();
                var productsPerSlide = _this.productsPerSlide();
                if (_this.state.productsPerSlide !== productsPerSlide) {
                    _this.setState({ productsPerSlide: productsPerSlide, currentIndex: 0 });
                }
            });
        }
    };
    ProductCarousel.prototype.componentWillUnmount = function () {
        if (this.props.products.length > 0) {
            this.unbindResize();
        }
    };
    ProductCarousel.prototype.productsPerSlide = function () {
        if (this.props.currentBreakpoint < SMALL) {
            return 1;
        }
        else if (this.props.currentBreakpoint <= SMALL) {
            return 2;
        }
        else if (this.props.currentBreakpoint >= MEDIUM && this.props.currentBreakpoint < LARGE) {
            return 3;
        }
        else if (this.props.currentBreakpoint >= LARGE) {
            return 4;
        }
    };
    ProductCarousel.prototype.checkIfIsTall = function () {
        var isTall = this.blockWrapper && this.blockWrapper.offsetWidth < 350;
        if (this.state.isTall !== isTall) {
            this.setState({ isTall: isTall });
        }
    };
    ProductCarousel.prototype.slidePrev = function () {
        this.swiper.swipe.slide(this.state.currentIndex - 1);
    };
    ProductCarousel.prototype.slideNext = function () {
        this.swiper.swipe.slide(this.state.currentIndex + 1);
    };
    ProductCarousel.prototype.slideTo = function (index) {
        var _this = this;
        this.setState({ currentIndex: index }, function () { return _this.swiper.swipe.slide(index); });
    };
    ProductCarousel.prototype.render = function () {
        var _this = this;
        var isMobile = this.props.currentBreakpoint < SMALL;
        if (this.props.products.length === 0) {
            return null;
        }
        var slides = [[]];
        var count = 0;
        var index = 0;
        this.props.products.forEach(function (product) {
            if (!isMobile || (isMobile && index < 4)) {
                if (count === _this.state.productsPerSlide) {
                    slides.push([]);
                    index++;
                    count = 0;
                }
                slides[index].push(product);
                count++;
            }
        });
        var hasNext = this.state.currentIndex < slides.length - 1;
        var hasPrev = this.state.currentIndex > 0;
        var renderPrevAndNextButton = this.renderPrevAndNextButton(slides, hasNext, hasPrev);
        return (React.createElement("div", { ref: function (el) { return (_this.blockWrapper = el); }, className: classnames(styles.base, styles.loading) },
            React.createElement("div", { className: styles.carouselHeader },
                React.createElement("h2", null, this.props.title),
                React.createElement("div", { className: styles.navigationButtonsWrap }, renderPrevAndNextButton)),
            React.createElement("div", { className: styles.swiperWrap },
                React.createElement(ReactSwipe, { swipeOptions: {
                        continuous: false,
                        callback: function (index) { return _this.slideTo(index); },
                    }, ref: function (swiper) { return (_this.swiper = swiper); }, key: slides.length }, slides.map(function (slide, index) { return (React.createElement("div", { key: index },
                    React.createElement(ProductCardList, { staticCardHeight: _this.cardHeight, products: slide.slice(0, _this.productsPerSlide()), viewLayoutType: viewLayoutTypes.grid.name, className: gridStyles.carouselProductCard }))); }))),
            React.createElement("ul", { className: styles.swiperPagination }, slides.map(function (_a, index) { return (React.createElement("li", { key: index, className: _this.state.currentIndex === index ? styles.active : '', onClick: function () { return _this.slideTo(index); } })); }))));
    };
    return ProductCarousel;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ProductCarousel);
