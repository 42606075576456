var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import * as styles from '../../base.scss';
import { translate } from "Shared/translate";
import Button from "Shared/Button";
import classnames from "classnames";
import { Checkbox } from "Shared/Fields";
import shallowEquals from "Shared/shallow-equals";
import OrderHistoryDetails from "../OrderHistoryProductList/OrderHistoryDetails";
var OrderHistoryRow = /** @class */ (function (_super) {
    __extends(OrderHistoryRow, _super);
    function OrderHistoryRow(props) {
        var _this = _super.call(this, props) || this;
        _this.formatDisplayDate = function (date) {
            var displayDate = new Date(date);
            return (displayDate.toLocaleDateString('sv-SE'));
        };
        _this.formatDisplayTime = function (date) {
            var displayDate = new Date(date);
            return (displayDate.toLocaleString("sv-SE", { hour: "2-digit", minute: "2-digit" }));
        };
        _this.getDisplayTime = function (row) {
            if (!!row.orderDate)
                return " " + translate("/MyMenigo/DeliveryBlock/List/clock") + _this.formatDisplayTime(row.orderDate);
            return "-";
        };
        _this.setExpanded = function (name) {
            _this.props.setExpandedItem(_this.props.expanded ? "" : name);
        };
        _this.setChecked = function (e) {
            _this.props.addToPrint(_this.state.rowItem.orderNumber, e.target.checked);
        };
        _this.state = {
            rowItem: _this.props.rowItem
        };
        return _this;
    }
    OrderHistoryRow.prototype.componentDidUpdate = function (prevProps) {
        if ((!shallowEquals(prevProps, this.props))) {
            this.setState({ rowItem: this.props.rowItem });
        }
    };
    OrderHistoryRow.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { id: "orderHistoryItem_" + this.props.rowItem.orderNumber, className: (!!this.props.expanded ? classnames(styles.OrderHistory__Row__Outer, styles.OrderHistory__Row__Outer_Active) : styles.OrderHistory__Row__Outer) },
            React.createElement("div", { className: !!this.props.expanded ? classnames(styles.OrderHistory__Row, styles.OrderHistory__Row__Open) : styles.OrderHistory__Row },
                React.createElement("div", { className: styles.OrderHistory__Cell },
                    React.createElement(Button, { className: classnames(styles.Button__Toggle, (this.props.expanded ? styles.Button__Toggle__On : styles.Button__Toggle__Off)), appearance: "bare", onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); } },
                        React.createElement("span", null, this.props.expanded ? '-' : '+'))),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: styles.OrderHistory__Cell },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/OrderDate")),
                    !!this.state.rowItem.orderDate && this.formatDisplayDate(this.state.rowItem.orderDate)),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: classnames(styles.OrderHistory__Cell) },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/OrderNumber")),
                    !!this.state.rowItem.orderNumber && this.state.rowItem.orderNumber),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderReferenceId); }, className: styles.OrderHistory__Cell },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/OrderReferenceId")),
                    !!this.state.rowItem.orderReferenceId && this.state.rowItem.orderReferenceId),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: classnames(styles.OrderHistory__Cell) },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/CustomerComment")),
                    !!this.state.rowItem.customerComment && this.state.rowItem.customerComment),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: classnames(styles.OrderHistory__Cell) },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/OrderType")),
                    !!this.state.rowItem.typeAsString && this.state.rowItem.typeAsString),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: styles.OrderHistory__Cell },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/NumberOfArticles")),
                    this.state.rowItem.items.length,
                    " ",
                    translate('/Units/UnitShortNames/st')),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.orderNumber); }, className: styles.OrderHistory__Cell },
                    React.createElement("span", { className: styles.OrderHistory__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/List/TotalSum")),
                    this.state.rowItem.orderSummary.toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    " ",
                    translate('/Price/CurrencySymbol')),
                React.createElement("div", { className: classnames(styles.OrderHistory__Cell, styles.checkbox) },
                    React.createElement(Checkbox, { value: this.state.rowItem.orderNumber, checked: !!this.state.rowItem.markedForPrint, onChange: this.setChecked.bind(this) }))),
            !!this.props.expanded && React.createElement(OrderHistoryDetails, { showAddToCartButton: this.state.rowItem.items.length > 0, sortBy: "categoryName", items: this.state.rowItem.items, recycleItems: this.state.rowItem.recycleItems })));
    };
    return OrderHistoryRow;
}(React.Component));
export default OrderHistoryRow;
