var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var Slice = /** @class */ (function (_super) {
    __extends(Slice, _super);
    function Slice(props) {
        return _super.call(this, props) || this;
    }
    Slice.prototype.getAnglePoint = function (startAngle, endAngle, radius, x, y) {
        var x1 = x + radius * Math.cos((Math.PI * startAngle) / 180);
        var y1 = y + radius * Math.sin((Math.PI * startAngle) / 180);
        var x2 = x + radius * Math.cos((Math.PI * endAngle) / 180);
        var y2 = y + radius * Math.sin((Math.PI * endAngle) / 180);
        return { x1: x1, y1: y1, x2: x2, y2: y2 };
    };
    Slice.prototype.render = function () {
        var p = this.props;
        var path = [];
        var a, b, c;
        // Get angle points
        a = this.getAnglePoint(p.startAngle, p.startAngle + p.angle, p.radius, p.radius, p.radius);
        b = this.getAnglePoint(p.startAngle, p.startAngle + p.angle, 0, p.radius, p.radius);
        path.push('M' + a.x1 + ',' + a.y1);
        path.push('A' + p.radius + ',' + p.radius + ' 0 ' + (p.angle > 180 ? 1 : 0) + ',1 ' + a.x2 + ',' + a.y2);
        path.push('L' + b.x2 + ',' + b.y2);
        path.push('A0,0 0 ' + (p.angle > 180 ? 1 : 0) + ',0 ' + b.x1 + ',' + b.y1);
        // Close
        path.push('Z');
        c = this.getAnglePoint(p.startAngle, p.startAngle + p.angle / 2, p.radius / 2, p.radius, p.radius);
        var x = c.x2;
        var y = c.y2;
        return (React.createElement("g", { overflow: "hidden" },
            React.createElement("path", { d: path.join(' '), fill: p.fill, stroke: p.stroke, strokeWidth: p.strokeWidth }),
            p.percentValue > 0 ? (React.createElement("text", { x: x, y: y, fill: "#fff", textAnchor: "middle" }, p.percentValue.toFixed(1) + '%')) : null));
    };
    return Slice;
}(React.Component));
export default Slice;
