var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var Barcodescanner = /** @class */ (function (_super) {
    __extends(Barcodescanner, _super);
    function Barcodescanner() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.quaggaStarted = false;
        _this.initQuagga = function (hasPermission) {
            if (!hasPermission) {
                _this.element.innerHTML = translate('/App/TakeStock/Barcodescanner/MissingPermissions');
                return;
            }
            _this.element.innerHTML = '';
            require.ensure(['quagga/dist/quagga'], function (require) {
                var Quagga = require('quagga/dist/quagga');
                _this.findDeviceIds().then(function (deviceIds) {
                    Quagga.init({
                        inputStream: {
                            name: 'Live',
                            type: 'LiveStream',
                            target: _this.element,
                            constraints: {
                                audio: false,
                                width: 1280,
                                height: 720,
                                deviceId: deviceIds.length > 0 ? { exact: deviceIds[deviceIds.length - 1].deviceId } : undefined,
                            },
                        },
                        decoder: {
                            readers: ['ean_reader', 'i2of5_reader'],
                        },
                        locate: _this.props.locate,
                    }, function (err) {
                        if (err) {
                            console.error(err.message);
                            if (err.name === 'DevicesNotFoundError') {
                                _this.element.innerHTML = translate('/App/TakeStock/Barcodescanner/NotSupported');
                            }
                            return;
                        }
                        // Move order
                        var video = _this.element.childNodes[0];
                        var canvas = _this.element.childNodes[1];
                        _this.element.insertBefore(canvas, video);
                        Quagga.start();
                        _this.quaggaStarted = true;
                        Quagga.onProcessed(function (result) {
                            var drawingCtx = Quagga.canvas.ctx.overlay, drawingCanvas = Quagga.canvas.dom.overlay;
                            if (result) {
                                if (result.boxes) {
                                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
                                    result.boxes
                                        .filter(function (box) {
                                        return box !== result.box;
                                    })
                                        .forEach(function (box) {
                                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 });
                                    });
                                }
                                if (result.box) {
                                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
                                }
                                if (result.codeResult && result.codeResult.code) {
                                    Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
                                        color: 'red',
                                        lineWidth: 3,
                                    });
                                }
                            }
                        });
                        Quagga.onDetected(function (result) {
                            _this.props.onDetected(result.codeResult.code);
                            Quagga.stop();
                        });
                    });
                });
            });
        };
        return _this;
    }
    Barcodescanner.prototype.findDeviceIds = function () {
        return navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) { return devices.filter(function (device) { return device.kind === 'videoinput'; }); })
            .catch(function (err) {
            console.error(err.name + ": " + err.message);
            return [];
        });
    };
    Barcodescanner.prototype.cameraPermissions = function () {
        return new Promise(function (resolve, reject) {
            if (!(window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.permissions)) {
                // Permissions check not needed if not running in cordova
                return resolve(true);
            }
            var permissions = window['cordova'].plugins.permissions;
            var checkPermissionCallback = function (status) {
                return status.hasPermission
                    ? resolve(true)
                    : permissions.requestPermission(permissions.CAMERA, function (status) { return resolve(status.hasPermission); }, reject);
            };
            return permissions.hasPermission(permissions.CAMERA, checkPermissionCallback, null);
        });
    };
    Barcodescanner.prototype.componentDidMount = function () {
        var _this = this;
        if (!window['navigator']['mediaDevices'] ||
            typeof window['navigator']['mediaDevices'].getUserMedia !== 'function') {
            this.element.innerHTML = translate('/App/TakeStock/Barcodescanner/NotSupported');
            return;
        }
        this.cameraPermissions()
            .then(this.initQuagga)
            .catch(function (err) {
            console.error(err);
            _this.element.innerHTML = translate('/App/TakeStock/Barcodescanner/NotSupported');
        });
    };
    Barcodescanner.prototype.componentWillUnmount = function () {
        var _this = this;
        if (this.quaggaStarted) {
            require.ensure(['quagga/dist/quagga'], function (require) {
                var Quagga = require('quagga/dist/quagga');
                Quagga.stop();
                _this.quaggaStarted = false;
            });
        }
    };
    Barcodescanner.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: classnames(styles.canvas, this.props.className), ref: function (e) { return (_this.element = e); } });
    };
    return Barcodescanner;
}(React.Component));
export default Barcodescanner;
