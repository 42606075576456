var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import Button from 'Shared/Button';
import connect from 'Shared/connect';
import { updateUserSettings } from 'Shared/UserSettings/action-creators';
import * as styles from './base.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { translate } from 'Shared/translate';
export var viewLayoutTypes = {
    grid: { name: 'viewLayout_Grid', icon: 'th' },
    list: { name: 'viewLayout_List', icon: 'th_list' },
    compact: { name: 'viewLayout_Compact', icon: 'nav' },
};
var ViewLayoutSelection = /** @class */ (function (_super) {
    __extends(ViewLayoutSelection, _super);
    function ViewLayoutSelection(props) {
        var _this = _super.call(this, props) || this;
        _this.viewLayouts = Object.keys(viewLayoutTypes).map(function (type) { return viewLayoutTypes[type]; }).filter(function (type) { return _this.props.allowedViewLayouts ? !!Array.from(_this.props.allowedViewLayouts).includes(type.name) : true; });
        _this.inputId = function () {
            return "LayoutSelection";
        };
        _this.handleClickOutside = function (event) {
            if (_this.wrapperRef && !_this.wrapperRef.contains(event.target)) {
                var input = document.getElementById(_this.inputId());
                input.checked = false;
            }
        };
        _this.getLayoutName = function (name) {
            var viewLayoutNames = {
                viewLayout_Grid: { name: translate('/Shared/LayoutOptions/Grid'), },
                viewLayout_List: { name: translate('/Shared/LayoutOptions/List') },
                viewLayout_Compact: { name: translate('/Shared/LayoutOptions/Compact') },
            };
            return !!viewLayoutNames[name] ? viewLayoutNames[name].name : name;
        };
        return _this;
    }
    ViewLayoutSelection.prototype.componentDidMount = function () {
        document.addEventListener('mousedown', this.handleClickOutside);
    };
    ViewLayoutSelection.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };
    ViewLayoutSelection.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { ref: function (e) { return (_this.wrapperRef = e); }, className: classnames(styles.wrap, styles.fadeDropDown, 'ui-test-layout-buttons') },
            React.createElement("input", { style: { display: 'none' }, type: 'checkbox', id: this.inputId() }),
            React.createElement("ul", { className: styles.sortList }, this.viewLayouts.map(function (_a, i) {
                var name = _a.name, icon = _a.icon;
                return (React.createElement("li", { key: i },
                    React.createElement(Button, { className: classnames(styles.sortOptionButton), onClick: function () { return _this.props.setViewLayoutSelection(name); }, appearance: "clear", key: i },
                        React.createElement("label", { htmlFor: _this.inputId() },
                            _this.props.viewLayoutSelection === name && (React.createElement("span", { className: styles.selectedSortOptionCheck },
                                React.createElement(Icon, { name: "check" }))),
                            _this.getLayoutName(name),
                            React.createElement(Icon, { name: icon })))));
            })),
            React.createElement("label", { htmlFor: this.inputId(), className: styles.selectedValue },
                this.getLayoutName(this.props.viewLayoutSelection),
                React.createElement("span", null,
                    React.createElement(MyMenigoIcon, { name: "dropdownArrowDown" })))));
    };
    return ViewLayoutSelection;
}(React.Component));
export { ViewLayoutSelection };
export default connect(function (state) { return ({
    viewLayoutSelection: state.currentUser.userSettings.viewLayoutSelection,
}); }, function (dispatch) { return ({
    setViewLayoutSelection: function (viewLayoutSelection) {
        return dispatch(updateUserSettings({ viewLayoutSelection: viewLayoutSelection }));
    },
}); })(ViewLayoutSelection);
