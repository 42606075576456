var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
var NotificationPage = /** @class */ (function (_super) {
    __extends(NotificationPage, _super);
    function NotificationPage(props) {
        return _super.call(this, props) || this;
    }
    NotificationPage.prototype.render = function () {
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement("h1", null,
                React.createElement(PropertyFor, { property: this.props.page.name })),
            !!this.props.page.hero && (React.createElement("div", null,
                React.createElement(PropertyFor, { property: this.props.page.hero, isImage: true }))),
            React.createElement("h3", null,
                React.createElement(PropertyFor, { property: this.props.page.message })),
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(PropertyFor, { property: this.props.page.mainBody }))),
            React.createElement("div", null)));
    };
    return NotificationPage;
}(React.Component));
export default NotificationPage;
