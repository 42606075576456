var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from "react";
import { Part } from "Shared/PageLayout";
import * as styles from './../../base.scss';
import DeliveryHeaderRow from "./DeliveryHeaderRow";
import DetailedDeliveryRow from "./DetailedDeliveryRow";
import shallowEquals from "Shared/shallow-equals";
import { translate } from "Shared/translate";
export var ListType;
(function (ListType) {
    ListType[ListType["Completed"] = 0] = "Completed";
    ListType[ListType["Planned"] = 1] = "Planned";
})(ListType || (ListType = {}));
var DeliveryList = /** @class */ (function (_super) {
    __extends(DeliveryList, _super);
    function DeliveryList(props) {
        var _this = _super.call(this, props) || this;
        _this.printAllHandler = function (printAll) {
            var updatedCheckedState = __spread(_this.state.deliveries);
            updatedCheckedState.map(function (el) { return Object.assign(el, { markedForPrint: printAll }); });
            _this.setState({ printAll: printAll, deliveries: updatedCheckedState }, function () {
                _this.props.addRemoveToPrint(-1, _this.state.deliveries.some(function (x) { return !!x.markedForPrint === true; }));
            });
        };
        _this.printHandler = function (id, add) {
            _this.props.addRemoveToPrint(id, add);
        };
        _this.state = {
            deliveries: (_this.props.listType == ListType.Planned ? _this.props.plannedDeliveries : _this.props.completedDeliveries),
            printAll: false
        };
        return _this;
    }
    DeliveryList.prototype.componentDidUpdate = function (prevProps) {
        if ((!shallowEquals(prevProps, this.props))) {
            this.setState({ printAll: prevProps.printAll, deliveries: (this.props.listType == ListType.Planned ? this.props.plannedDeliveries : this.props.completedDeliveries) });
        }
    };
    DeliveryList.prototype.render = function () {
        var _this = this;
        return (React.createElement(Part, { className: styles.Delivery__Outer },
            React.createElement(DeliveryHeaderRow, { checked: this.state.printAll, addAllToPrint: this.printAllHandler, listType: this.props.listType }),
            React.createElement(Part, { className: styles.Delivery__List }, this.state.deliveries && this.state.deliveries.length
                ? this.state.deliveries.map(function (item, index) {
                    return (React.createElement(DetailedDeliveryRow, { addToPrint: _this.printHandler, setExpandedItem: _this.props.setExpandedItem, listType: _this.props.listType, key: "row_" + item.routeId, rowItem: item, expanded: item.routeId.toString() === _this.props.expanded }));
                }) : React.createElement(Part, { className: styles.Delivery__Row },
                React.createElement("div", { className: styles.Delivery__Cell }),
                React.createElement("div", { className: styles.Delivery__Cell }, translate("/MyMenigo/DeliveryBlock/List/NoDeliveries")),
                React.createElement("div", { className: styles.Delivery__Cell })))));
    };
    return DeliveryList;
}(React.Component));
;
export default DeliveryList;
