var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import ProductCardList from 'Shared/ProductCard/List';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import Icon from 'Shared/Icon';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import { on } from 'SiteLayout/Root';
import { TINY, SMALL, MEDIUM } from 'Shared/breakpoints';
import * as styles from './base.scss';
var ProductsBlock = /** @class */ (function (_super) {
    __extends(ProductsBlock, _super);
    function ProductsBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.listWidth = '';
        _this.visibleBlocks = function (currentBreakpoint) {
            var rows = propertyValueFor(_this.props.block.numberOfRows);
            if (currentBreakpoint < TINY) {
                return rows;
            }
            if (currentBreakpoint < SMALL) {
                return 2 * rows;
            }
            if (currentBreakpoint < MEDIUM) {
                return 3 * rows;
            }
            return _this.props.products.length;
        };
        _this.state = {
            showLinkText: props.currentBreakpoint >= TINY,
        };
        _this.setClassName();
        return _this;
    }
    ProductsBlock.prototype.componentDidMount = function () {
        var _this = this;
        this.hideLinkText();
        this.unbindResize = on('resize', function () { return _this.hideLinkText(); });
    };
    ProductsBlock.prototype.componentWillUnmount = function () {
        this.unbindResize();
    };
    ProductsBlock.prototype.setClassName = function () {
        switch (this.props.width) {
            case '1:4':
                this.listWidth = 'listBlock25';
                break;
            case '1:3':
                this.listWidth = 'listBlock33';
                break;
            case '1:2':
                this.listWidth = 'listBlock50';
                break;
            case '2:3':
                this.listWidth = 'listBlock66';
                break;
            default:
                this.listWidth = 'listBlock100';
                break;
        }
    };
    ProductsBlock.prototype.hideLinkText = function () {
        var showLinkText = this.listWrapper.offsetWidth > 395;
        if (this.state.showLinkText !== showLinkText) {
            this.setState({ showLinkText: showLinkText });
        }
    };
    ProductsBlock.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement("div", { ref: function (el) { return (_this.listWrapper = el); }, className: classnames(styles.base, styles[this.listWidth]) },
            React.createElement("div", { className: styles.title }, this.props.name),
            propertyValueFor(this.props.block.showMoreUrl) && (React.createElement("a", { href: propertyValueFor(this.props.block.showMoreUrl), className: classnames(styles.link, (_a = {}, _a[styles.mobile] = this.props.currentBreakpoint < SMALL, _a)) },
                React.createElement(Icon, { name: "redo-outline" }),
                this.state.showLinkText && React.createElement(PropertyFor, { tagName: "span", property: this.props.block.showMoreLabel }))),
            React.createElement(ProductCardList, { viewLayoutType: viewLayoutTypes.grid.name, cardClassName: styles.card, products: this.props.products.slice(0, this.visibleBlocks(this.props.currentBreakpoint)) })));
    };
    return ProductsBlock;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ProductsBlock);
