import React from "react";
import { Part } from "Shared/PageLayout";
import * as styles from '../../base.scss';
import OrderHistoryProductRow from "./OrderHistoryProductRow";
;
var OrderHistoryProductList = function (_a) {
    var productItems = _a.productItems;
    return (React.createElement(Part, { className: styles.OrderHistoryDetails__List }, !!productItems && productItems.length
        ? productItems.map(function (item, index) {
            return (React.createElement(OrderHistoryProductRow, { key: index, item: item }));
        }) : React.createElement(React.Fragment, null)));
};
export default OrderHistoryProductList;
