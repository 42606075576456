var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import { isIOS } from 'Shared/device-type';
import { scrollPosition } from 'SiteLayout/Root';
import { ESC } from 'Shared/keyboard';
import { TINY } from 'Shared/breakpoints';
import Transition from 'Shared/Transition';
import * as styles from './base.scss';
export var currentOpenOverlay = null;
var Overlay = /** @class */ (function (_super) {
    __extends(Overlay, _super);
    function Overlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Overlay.prototype.componentWillUnmount = function () {
        this.disable();
    };
    Overlay.prototype.componentWillMount = function () {
        this.props.enabled && this.enable();
    };
    Overlay.prototype.enable = function () {
        // The key down event must be attached to window and not on `<Root />` for the callback to be
        // called even if active element is `<body />`
        window.addEventListener('keydown', this.keyDown);
        this.freezeBodyScroll(true);
        currentOpenOverlay && currentOpenOverlay !== this && currentOpenOverlay.close();
        currentOpenOverlay = this;
    };
    Overlay.prototype.disable = function () {
        window.removeEventListener('keydown', this.keyDown);
        this.freezeBodyScroll(false);
        if (currentOpenOverlay === this) {
            currentOpenOverlay = null;
        }
    };
    Overlay.prototype.keyDown = function (e) {
        if (e.keyCode === ESC && currentOpenOverlay) {
            currentOpenOverlay.close();
            currentOpenOverlay = null;
        }
    };
    Overlay.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.enabled !== nextProps.enabled) {
            this.freezeBodyScroll(nextProps.enabled);
            if (nextProps.enabled) {
                this.enable();
            }
            else {
                this.disable();
            }
        }
    };
    Overlay.prototype.close = function () {
        if (this.props.enabled && !!this.props.onClose) {
            this.props.onClose();
        }
    };
    Overlay.prototype.setCurrentScrollPosition = function (newPosition) {
        if (isIOS()) {
            // In some cases we need to modify the scroll position during the time
            // an overlay is open. And because of iOS we set it to position: fixed
            // which means that it won't react to setting the bodys scroll position,
            // we have to update the fixed positioning.
            this.currentScrollPosition = newPosition;
            document.body.style.top = this.currentScrollPosition * -1 + 'px';
        }
    };
    Overlay.prototype.freezeBodyScroll = function (freeze) {
        // Don't hide overflow if there's a scroll bar. Note
        // that mobile browsers doesn't have scroll bar, so this
        // only applies to desktop browsers.
        if (this.props.currentBreakpoint <= TINY) {
            var bodyClassList = document.body.classList;
            if (freeze) {
                if (isIOS()) {
                    this.currentScrollPosition = scrollPosition();
                    // Setting position fixed is the only way to prevent body scroll
                    // to occur on iOS when you reach the bottom it the scroll position in
                    // a floating layer.
                    document.body.style.position = 'fixed';
                    document.body.style.left = '0';
                    document.body.style.right = '0';
                    document.body.style.top = this.currentScrollPosition * -1 + 'px';
                }
                bodyClassList.add(styles.freezescroll);
            }
            else {
                bodyClassList.remove(styles.freezescroll);
                if (isIOS()) {
                    document.body.style.position = '';
                    document.body.style.left = '';
                    document.body.style.right = '';
                    document.body.style.top = '';
                    scrollPosition(this.currentScrollPosition);
                }
            }
        }
    };
    Overlay.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, enabled = _a.enabled, onClick = _a.onClick, children = _a.children, onClose = _a.onClose, currentBreakpoint = _a.currentBreakpoint, dispatch = _a.dispatch, wrapperClassName = _a.wrapperClassName, overlayClassName = _a.overlayClassName, ignoreAnimation = _a.ignoreAnimation, isTransparent = _a.isTransparent, passProps = __rest(_a, ["className", "enabled", "onClick", "children", "onClose", "currentBreakpoint", "dispatch", "wrapperClassName", "overlayClassName", "ignoreAnimation", "isTransparent"]);
        return (React.createElement("div", { className: classnames(styles.base, this.props.className) },
            React.createElement(Transition, { in: this.props.enabled, timeout: 200 }, function (active) {
                var _a;
                return (React.createElement("div", __assign({}, passProps, { onClick: function () { return _this.close(); }, className: classnames(styles.overlay, (_a = {},
                        _a[styles.active] = active,
                        _a[styles.ignoreAnim] = _this.props.ignoreAnimation,
                        _a[styles.transparent] = _this.props.isTransparent,
                        _a), _this.props.overlayClassName) })));
            }),
            React.createElement("div", { className: classnames(styles.wrapper, this.props.wrapperClassName) }, this.props.children)));
    };
    Overlay.defaultProps = {
        enabled: true,
    };
    return Overlay;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(Overlay);
