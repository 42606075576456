// `fetch` is not in the polyfill bundle because it's the only thing missing on iOS 9
// and we don't want to force a lot of unneeded polyfills just for that
import 'whatwg-fetch';
import 'Shared/console';
import 'Shared/todo';
import 'Shared/ui';
import 'Shared/component-registry';
import * as crossWindowEvents from 'Shared/cross-window-events';
import React from 'react';
import ReactDOM from 'react-dom';
import * as history from 'Shared/history';
import Container from 'SiteLayout/Container';
import { loadPage } from 'SiteLayout/CurrentPage/action-creators';
import { switchBreakpoint } from 'SiteLayout/CurrentBreakpoint/action-creators';
import { current } from 'Shared/breakpoints';
import createStore from 'Shared/create-store';
import { setupResizeListener } from 'Shared/breakpoints';
import addToCartFromUrl from './Cart/add-to-cart-from-url';
import { openLoginBoxIfUrlSaysSo, openRegisterBoxIfUrlSaysSo } from 'SiteLayout/Header/AccountBox/action-creators';
import { setPhrases } from 'Shared/translate';
import { setKnownUrls } from 'Shared/known-urls';
import * as appShell from './AppShell';
import { isIOS } from 'Shared/device-type';
import localForage from 'localforage';
import { initTagManager, pageLoad as gtmPageLoad, initImbox } from './TrackingInformation';
import currentPageIsAppShell from './AppShell/Partials/current-page-is-appshell';
import ErrorBoundary from './ErrorBoundary';
import { pushState } from 'Shared/history';
import { trackPageview, initApplicationInsights } from './Shared/telemetry';
// Use WebSQL for iOS because IndexedDB is broken on most iOS versions
if (isIOS() && window.openDatabase) {
    localForage.setDriver(localForage.WEBSQL);
}
history.captureLinks();
var firstLoadIsAppShell = false;
function render(appShellData, currentPage) {
    setKnownUrls(appShellData.siteSettings);
    var store = createStore({ appShellData: appShellData, currentPage: currentPage });
    initTagManager();
    initImbox();
    initApplicationInsights(appShellData);
    crossWindowEvents.setStore(store);
    setupResizeListener(store);
    addToCartFromUrl(store);
    openLoginBoxIfUrlSaysSo(store);
    openRegisterBoxIfUrlSaysSo(store);
    var currentBreakpoint = current();
    if (currentBreakpoint !== store.getState().currentBreakpoint) {
        console.debug('Setting new breakpoint since server guess was incorrect');
        store.dispatch(switchBreakpoint(currentBreakpoint));
    }
    else {
        console.debug('Server breakpoint guess was correct');
    }
    history.on(['push', 'pop', 'replace'], function (e) {
        var loadPromise = store.dispatch(loadPage(e.url, e.options, e));
        loadPromise.then(function () {
            var state = store.getState();
            if (!state.currentPage.isPartial) {
                setTimeout(function () {
                    gtmPageLoad(state.currentPage, true, state);
                    trackPageview(state.currentPage);
                }, 1000);
                var pageviewEvent = new CustomEvent('pageview');
                window.dispatchEvent(pageviewEvent);
            }
        });
        return loadPromise;
    });
    var container = document.getElementById('container');
    // Make sure phrases isn't `undefined`, otherwise
    // `translate(...)` with cause an exception.
    setPhrases(appShellData.languagePhrases);
    var page = store.getState().currentPage;
    if (!currentPageIsAppShell(page)) {
        gtmPageLoad(page, false, store.getState());
        trackPageview(page);
    }
    else {
        firstLoadIsAppShell = true;
    }
    ReactDOM.render(React.createElement(ErrorBoundary, null,
        React.createElement(Container, { store: store })), container);
    return store;
}
if (!window.APP_SHELL_DATA || !window.CURRENT_PAGE) {
    window.newrelic &&
        window.newrelic.noticeError(new Error('Script error happened on ' + window.location.href + ' that caused important globals not to exist'));
    if (window.location.href !== '/') {
        // We assume that the error doesn't occur on the start page
        // Give Raygun some time to send the request
        setTimeout(function () {
            pushState('/');
        }, 1000);
    }
}
else {
    var store_1 = render(window.APP_SHELL_DATA, window.CURRENT_PAGE);
    if (window.IS_RENDERED_FROM_CACHE) {
        appShell.refreshCachedData(store_1).then(function () {
            var page = store_1.getState().currentPage;
            if (firstLoadIsAppShell) {
                gtmPageLoad(page, false, store_1.getState());
                trackPageview(page);
                firstLoadIsAppShell = false;
            }
        });
    }
    var oldOnError_1 = window.onerror;
    if (process.env.NODE_ENV !== 'production') {
        // Make device debugging a bit simpler
        window.onerror = function (message, url, line, col, e) {
            var error = document.createElement('div');
            error.style.position = 'fixed';
            error.style.padding = '10px';
            error.style.border = '1px solid red';
            error.style.top = '10px';
            error.style.left = '10px';
            error.style.right = '10px';
            error.style.bottom = '10px';
            error.style.background = 'white';
            error.style['z-index'] = 100000;
            var html = '<h1>Oh noes!</h1>' +
                '<p>Message: ' +
                message +
                '</p>' +
                '<p>Line: ' +
                line +
                '</p>' +
                '<p>Error: ' +
                JSON.stringify(e, null, 2) +
                '</p>';
            error.innerHTML = html;
            document.body.appendChild(error);
            error.addEventListener('click', function () { return document.body.removeChild(error); });
            if (oldOnError_1) {
                oldOnError_1.apply(window, arguments);
            }
        };
    }
    else {
        printAvensiaSplashToConsole();
    }
}
function printAvensiaSplashToConsole() {
    if (window['console'] && window['console']['log']) {
        window['console']['log']('     _                      _       \n' +
            '    / \\__   _____ _ __  ___(_) __ _ \n' +
            "   / _ \\ \\ / / _ | '_ \\/ __| |/ _` |\n" +
            '  / ___ \\ V |  __| | | \\__ | | (_| |\n' +
            ' /_/   \\_\\_/ \\___|_| |_|___|_|\\__,_|\n\n' +
            'Do you end up here a lot? Perhaps you should come work for us!\n' +
            'We build sites using React, TypeScript, Webpack, CSS Modules, Episerver, etc.\n' +
            'http://www.avensia.com/careers/');
    }
}
