import { ACCOUNT_BOX_CLOSE, ACCOUNT_BOX_OPEN, ACTION_REQUIRES_LOGIN, } from './action-creators';
import { PAGE_LOAD_FAILURE } from '../../CurrentPage/action-creators';
import { translate } from 'Shared/translate';
export default (function (state, action) {
    state = state || { isOpen: false, currentTab: 'login' };
    switch (action.type) {
        case ACCOUNT_BOX_CLOSE:
            return Object.assign({}, state, { isOpen: false });
        case ACCOUNT_BOX_OPEN:
            return Object.assign({}, state, {
                isOpen: true,
                redirectToAfterLogin: action.redirectToAfterLogin,
                message: action.message,
                currentTab: action.tab,
                enableAutofocus: action.enableAutofocus,
            });
        case PAGE_LOAD_FAILURE:
            if (action.loadFailure.status === 401) {
                return {
                    isOpen: true,
                    redirectToAfterLogin: action.url,
                    message: translate('/Account/Login/LoginRequiredForThisPage'),
                    currentTab: 'login',
                };
            }
            return state;
        case ACTION_REQUIRES_LOGIN:
            return Object.assign({}, state, {
                isOpen: true,
                message: action.message || translate('/Account/Login/LoginRequiredForGeneric'),
                currentTab: 'login',
            });
        default:
            return state;
    }
});
