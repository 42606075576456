var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var logotype = require('!svg-inline-loader?classPrefix=true!../../../styles/images/menigo_redwhitegrey_tagline.svg');
import * as styles from './base.scss';
var Logotype = /** @class */ (function (_super) {
    __extends(Logotype, _super);
    function Logotype() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Logotype.prototype.render = function () {
        return !this.props.disableLink ? (React.createElement("a", { className: styles.logotype + ' ' + (this.props.className || ''), href: this.props.siteUrl },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: logotype } }))) : (React.createElement("div", { className: styles.logotype + ' ' + (this.props.className || ''), dangerouslySetInnerHTML: { __html: logotype } }));
    };
    return Logotype;
}(React.Component));
export default Logotype;
