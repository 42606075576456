var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import Spinner from 'Shared/Spinner';
import { loadQuickSearchRecipes } from '../../../../Search/action-creators';
import { addRecipe } from '../../../action-creators';
import Grade from '../../../../Recipes/Grade';
import Price from '../../../../../Features/Shared/Price';
import ImageFallback from 'Shared/ImageFallback';
import { ESC, ENTER, DOWN, UP, PLUS, PLUS_DESKTOP, MINUS, MINUS_DESKTOP } from 'Shared/keyboard';
import * as styles from './RecipeSearch.scss';
import classnames from 'classnames';
import connect from 'Shared/connect';
var maxLengthOfRecipeSearchResult = 6;
var inputDebouncer = 400;
var RecipeSearch = /** @class */ (function (_super) {
    __extends(RecipeSearch, _super);
    function RecipeSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.onBlur = function (event) {
            _this.setState({ searchText: event.target.value = '' });
        };
        _this.state = { searchText: '' };
        return _this;
    }
    RecipeSearch.prototype.handleRecipeSearch = function (e) {
        var _this = this;
        this.setState({ searchText: e.value });
        this.closeQuickSearch();
        if (!!e.value) {
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(function () {
                _this.setState({ isQuickSearchRecipesLoading: true }, function () {
                    if (e.value.length) {
                        _this.props.loadQuickSearchRecipes(e.value).then(function (r) {
                            _this.setState({
                                isQuickSearchRecipesLoading: false,
                                recipeList: r.items.splice(0, maxLengthOfRecipeSearchResult),
                            });
                        });
                    }
                    else {
                        _this.setState({
                            isQuickSearchRecipesLoading: false,
                            recipeList: null,
                        });
                    }
                });
            }, inputDebouncer);
        }
        else {
            this.setState({
                recipeList: null,
            });
        }
    };
    RecipeSearch.prototype.closeQuickSearch = function () {
        this.setState({ isOpen: false, currentIndexOfRecipeSearch: -1, isQuickSearchRecipesLoading: false });
    };
    RecipeSearch.prototype.onKeyDownRecipe = function (event) {
        var index = null;
        if (this.state.currentIndexOfRecipeSearch === null) {
            index = 0;
        }
        else {
            index = this.state.currentIndexOfRecipeSearch;
        }
        switch (event.keyCode) {
            case ENTER:
                event.preventDefault();
                // TODO add to selection
                this.addItemToMeal(index);
                break;
            case UP:
            case MINUS:
            case MINUS_DESKTOP:
                event.preventDefault();
                // TODO add to selectedIndexRecipe
                if (index > 0) {
                    index = index - 1;
                }
                this.setState({
                    currentIndexOfRecipeSearch: index,
                });
                break;
            case DOWN:
            case PLUS:
            case PLUS_DESKTOP:
                event.preventDefault();
                if (index < maxLengthOfRecipeSearchResult - 1) {
                    index = index + 1;
                }
                this.setState({
                    currentIndexOfRecipeSearch: index,
                });
                break;
            case ESC:
                event.preventDefault();
                this.setState({
                    recipeList: null,
                });
                break;
            default:
                break;
        }
    };
    RecipeSearch.prototype.addItemToMeal = function (index) {
        this.closeQuickSearch();
        var item = this.state.recipeList[index];
        this.props.addRecipe(this.props.id, item);
        var newSelectedRecipesList = Object.assign([], this.state.mealRecipeModels);
        newSelectedRecipesList.unshift(item);
        this.setState({
            mealRecipeModels: newSelectedRecipesList,
            recipeList: null,
            searchText: '',
            currentIndexOfRecipeSearch: 0,
        });
    };
    RecipeSearch.prototype.onChange = function (event) {
        var inputText = event.target.value;
        this.setState({ searchText: inputText });
    };
    RecipeSearch.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("input", { type: "search", value: this.state.searchText || '', onBlur: this.onBlur, ref: function (el) { return (_this.searchInput = el); }, placeholder: translate('/RecipeMenu/SearchRecipe'), onChange: function (e) { return _this.handleRecipeSearch(e.target); }, onKeyDown: function (event) {
                    _this.onKeyDownRecipe(event);
                } }),
            this.state.isQuickSearchRecipesLoading && (React.createElement("div", { className: styles.spinnerWrapper },
                React.createElement(Spinner, null))),
            !!this.state.recipeList && this.state.recipeList.length === 0 && (React.createElement("p", null, translate('/RecipeListing/EmptyResult'))),
            !!this.state.recipeList && this.state.recipeList.length > 0 && (React.createElement("div", { className: styles.autocompleteWrapper },
                React.createElement("ul", { className: styles.recipeList_autoComplete_Wrapper }, this.state.recipeList.map(function (recipe, i) {
                    return (React.createElement("li", { key: i, onClick: function () { return _this.addItemToMeal(i); }, className: classnames(styles.listWrapper, _this.state.currentIndexOfRecipeSearch === i ? styles.selected : null) },
                        React.createElement("div", { className: styles.img },
                            React.createElement(ImageFallback, { src: recipe.imageUrl, size: 'small' })),
                        React.createElement("p", { className: styles.name }, recipe.name),
                        React.createElement("p", { className: styles.portionCost },
                            React.createElement(Price, __assign({ className: styles.price }, recipe.portionCost, { size: "normal" }))),
                        React.createElement("div", { className: styles.grade },
                            React.createElement(Grade, { grade: recipe.grade, votes: recipe.voteCount }))));
                }))))));
    };
    return RecipeSearch;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    quickSearch: state.quickSearch,
    currencySymbol: state.cart.subTotal.currencySymbol,
}); }, function (dispatch) { return ({
    addRecipe: function (id, item) {
        return dispatch(addRecipe(id, item));
    },
    loadQuickSearchRecipes: function (searchText) {
        return dispatch(loadQuickSearchRecipes(searchText));
    },
}); })(RecipeSearch);
