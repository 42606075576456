var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import React from 'react';
import { Input, Checkbox } from 'Shared/Fields';
import FeedbackButton from 'Shared/Button/Feedback';
import * as server from 'Shared/server';
import { translate } from 'Shared/translate';
import { parseUrl } from 'Shared/url';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
import { updateAppShellData } from '../../../AppShell/action-creators';
import * as styles from './base.scss';
var feedbackDelay = 500;
var ForgotPasswordPage = /** @class */ (function (_super) {
    __extends(ForgotPasswordPage, _super);
    function ForgotPasswordPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { showPassword: false, newPassword: '', changeFailedMessage: '', changeSucceeded: false };
        return _this;
    }
    ForgotPasswordPage.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        this.setState({ changeFailedMessage: null });
        var newPassword = this.state.newPassword.trim();
        if (!newPassword) {
            this.newPasswordElement.focus();
            return null;
        }
        return server
            .postJson(pathCombine(location.pathname, '/UpdatePassword'), {
            password: newPassword,
            token: parseUrl(window.location.href).query['token'],
        })
            .then(function (result) {
            if (result.success) {
                return Promise.resolve();
            }
            else {
                _this.setState({ changeFailedMessage: result.message || translate('/Account/ForgotPassword/GenericError') });
                return Promise.reject(null);
            }
        }, function (e) {
            _this.setState({ changeFailedMessage: translate('/Account/ForgotPassword/GenericError') });
            return Promise.reject(null);
        });
    };
    ForgotPasswordPage.prototype.render = function () {
        var _this = this;
        var isValid = window.IS_IN_EDIT_MODE || this.props.isTokenValid;
        return (React.createElement(Main, { appearance: "narrow", className: styles.base },
            React.createElement(PropertyFor, { property: this.props.page.name, tagName: "h1" }),
            this.state.changeSucceeded ? (React.createElement("div", null, translate('/Account/ForgotPassword/ChangeSucceeded'))) : (React.createElement("div", null,
                !isValid && React.createElement("div", null, translate('/Account/ForgotPassword/InvalidToken')),
                isValid && React.createElement(PropertyFor, { property: this.props.page.mainBody, tagName: "span", className: "myClass" }),
                isValid && (React.createElement("form", null,
                    React.createElement("fieldset", null,
                        React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement(Input, { required: true, type: this.state.showPassword ? 'text' : 'password', value: this.state.newPassword, ref: function (el) { return (_this.newPasswordElement = el); }, minLength: 5, placeholder: translate('/Account/ForgotPassword/NewPasswordFieldTitle'), onChange: function (e) { return _this.setState({ newPassword: e.target.value }); }, className: styles.newPassword }),
                                React.createElement(FeedbackButton, { type: "submit", appearance: "secondary", onClickWithFeedback: function (e, activate) {
                                        var promiseOrUndefined = _this.onSubmit(e);
                                        if (promiseOrUndefined) {
                                            activate(promiseOrUndefined, { minimumPending: feedbackDelay })
                                                .then(function () { return _this.props.reloadAppShell(); })
                                                .then(function () { return _this.setState({ changeSucceeded: true }); })
                                                .catch(function () { return console.log('failed'); });
                                        }
                                    } }, translate('/Account/ForgotPassword/ChangePassword'))),
                            React.createElement(Checkbox, { checked: this.state.showPassword, onChange: function (e) { return _this.setState({ showPassword: e.target.checked }); }, className: styles.checkbox }, translate('/Account/ForgotPassword/ShowPassword'))),
                        this.state.changeFailedMessage && (React.createElement("div", { className: styles.changeFailed }, this.state.changeFailedMessage)))))))));
    };
    return ForgotPasswordPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
}); })(ForgotPasswordPage);
