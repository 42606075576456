var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { addToCart } from '../../../../Cart/action-creators';
import FeedbackButton from 'Shared/Button/Feedback';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { timeout } from 'Shared/timeout';
var AddToButton = /** @class */ (function (_super) {
    __extends(AddToButton, _super);
    function AddToButton(props) {
        var _this = _super.call(this, props) || this;
        _this.hideSomeNoneBuyableItemsModal = function () {
            _this.setState({ isSomeNonBuyableItemsModalOpen: false });
            _this.rejectModalConfirmPromse();
            timeout(300).then(function () {
                _this.notBuyableItems = [];
                _this.buyableItems = [];
                _this.waitForModalConfirmPromise = null;
            });
        };
        _this.onSomeNoneBuyableItemsModalConfirm = function () {
            _this.resolveModalConfirmPromse();
            return _this.waitForModalConfirmPromise;
        };
        _this.addItems = function () {
            if (_this.props.selectedItems.size === 0) {
                return Promise.reject(null);
            }
            _this.notBuyableItems = [];
            _this.buyableItems = [];
            _this.props.selectedItems.forEach(function (value, key) {
                var item = {
                    code: key + "_" + value.unit,
                    quantity: value.quantity,
                    unitShortName: value.unit,
                    name: value.name,
                };
                if (value.isBuyable && !value.isSoldOut) {
                    _this.buyableItems.push(item);
                }
                else {
                    _this.notBuyableItems.push(item);
                }
            });
            if (_this.notBuyableItems.length > 0 && _this.notBuyableItems.length === _this.props.selectedItems.size) {
                _this.props.setFeedback({
                    text: translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/AddErrorMessage'),
                    type: 'error',
                });
                return Promise.reject(null);
            }
            else if (_this.notBuyableItems.length > 0) {
                _this.setState({ isSomeNonBuyableItemsModalOpen: true });
                _this.waitForModalConfirmPromise = new Promise(function (resolve, reject) {
                    _this.resolveModalConfirmPromse = resolve;
                    _this.rejectModalConfirmPromse = reject;
                }).then(function () { return _this.addItemsToCart(); });
                return _this.waitForModalConfirmPromise;
            }
            else if (_this.buyableItems.length > 0) {
                return _this.addItemsToCart();
            }
            return Promise.reject(null);
        };
        _this.state = {
            isSomeNonBuyableItemsModalOpen: false,
        };
        _this.buyableItems = [];
        _this.notBuyableItems = [];
        return _this;
    }
    AddToButton.prototype.addItemsToCart = function () {
        var _this = this;
        return Promise.all(this.buyableItems.map(function (add) { return _this.props.addToCart(add.code, add.quantity, add.unitShortName); }))
            .then(function (result) {
            _this.props.setFeedback({
                text: translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/SuccessAddMessage'),
                type: 'success',
            });
            return Promise.resolve();
        })
            .catch(function () {
            _this.props.setFeedback({
                text: translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/AddErrorMessage'),
                type: 'error',
            });
            return Promise.reject(null);
        });
    };
    AddToButton.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.dropdownOptions },
            React.createElement(FeedbackButton, { appearance: 'clear', type: "button", onClickWithFeedback: function (e, activate) { return activate(_this.addItems()); } }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/AddToCart')),
            React.createElement(Modal, { isOpen: this.state.isSomeNonBuyableItemsModalOpen, onClose: this.hideSomeNoneBuyableItemsModal, type: ModalType.Warning, reserveSpaceForCloseButton: true },
                React.createElement("p", null, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/AllItemsNotBuyable')),
                React.createElement("ul", null, this.notBuyableItems.map(function (i) { return (React.createElement("li", null,
                    i.code,
                    " - ",
                    i.name)); })),
                React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.onSomeNoneBuyableItemsModalConfirm, onCancel: this.hideSomeNoneBuyableItemsModal, onStatusWasFulfilled: this.hideSomeNoneBuyableItemsModal }))));
    };
    return AddToButton;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    addToCart: function (code, quantity, unitShortName) {
        return dispatch(addToCart(code, quantity, unitShortName, null, null));
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(AddToButton);
