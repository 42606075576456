var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import Checkbox from 'Shared/Fields/Checkbox';
import * as styles from './base.scss';
var ProductList = /** @class */ (function (_super) {
    __extends(ProductList, _super);
    function ProductList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selected: [],
            selectAll: false,
        };
        return _this;
    }
    ProductList.prototype.setChecked = function (id) {
        var newItems = __spread(this.state.selected);
        if (newItems.some(function (t) { return t === id; })) {
            newItems = newItems.filter(function (t) { return t !== id; });
        }
        else {
            newItems.push(id);
        }
        this.setState({
            selected: newItems,
        });
        this.props.checkbox(id);
    };
    ProductList.prototype.toggleSelectAll = function () {
        var _this = this;
        this.setState({ selectAll: !this.state.selectAll }, function () {
            if (_this.state.selectAll) {
                var selectAll = _this.props.list.map(function (item) { return item.id; });
                _this.props.anySelected(selectAll);
                _this.setState({ selected: selectAll });
            }
            else {
                _this.props.anySelected([]);
                _this.setState({ selected: [] });
            }
        });
    };
    ProductList.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.list }, this.props.list.length > 0 && (React.createElement("div", null,
            React.createElement("h3", null, this.props.heading),
            React.createElement(Checkbox, { className: styles.selectAllCheckbox, checked: this.state.selectAll, onChange: function () { return _this.toggleSelectAll(); } }, translate('/Admin/ProductLists/SelectAll')),
            this.props.list.map(function (item) {
                var _a;
                return (React.createElement("div", { key: item.id },
                    React.createElement("label", { htmlFor: item.id },
                        React.createElement("div", { className: classnames((_a = {},
                                _a[styles.checkboxRow] = true,
                                _a[styles.checkboxActive] = _this.state.selected.some(function (c) { return c === item.id; }),
                                _a)) },
                            React.createElement(Checkbox, { id: item.id, checked: !!_this.state.selected.find(function (id) { return id === item.id; }), onChange: function () { return _this.setChecked(item.id); } }),
                            item.displayName))));
            })))));
    };
    return ProductList;
}(React.Component));
export default ProductList;
