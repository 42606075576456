import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var Discount = function (props) {
    var discounts = props.list;
    var firstDiscount = discounts.length > 0 ? discounts[0] : null;
    var minDiscount;
    var maxDiscount;
    var valDiscount;
    if (firstDiscount != null) {
        minDiscount = firstDiscount.limit;
        maxDiscount = minDiscount;
        valDiscount = firstDiscount.value;
    }
    return (React.createElement("div", { className: styles.halfborder },
        React.createElement("h3", null, translate('/Cart/DiscountModal/DeliveryDiscountHeader')),
        React.createElement("p", null, translate('/Cart/DiscountModal/DeliveryDiscountDescription')),
        React.createElement("h4", null, translate('/Cart/DiscountModal/DeliveryDiscount')),
        discounts.map(function (discount, index) {
            if (index !== 0) {
                maxDiscount = discount.limit - 1;
                var discountRendering = (React.createElement("div", { key: index }, translate('/Cart/DiscountModal/DeliveryDiscountText1', minDiscount.toString(), maxDiscount.toString(), valDiscount.toFixed(2))));
                minDiscount = discount.limit;
                valDiscount = discount.value;
                return discountRendering;
            }
        }),
        React.createElement("div", null, translate('/Cart/DiscountModal/DeliveryDiscountText', minDiscount.toString(), valDiscount.toFixed(2)))));
};
export default Discount;
