import React from 'react';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import Button from 'Shared/Button';
import { back } from 'Shared/history';
export default (function (_a) {
    var title = _a.title;
    return (React.createElement("div", { className: styles.bar },
        React.createElement(Button, { appearance: "clear", onClick: function () { return back(); } },
            React.createElement(Icon, { appearance: "sans", name: "arrow-left" })),
        title));
});
