var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ListItem from 'Shared/ListItem';
import { DOWN, UP, TAB, PLUS, MINUS, PLUS_DESKTOP, MINUS_DESKTOP, ENTER } from 'Shared/keyboard';
import { SMALL } from 'Shared/breakpoints';
import { WindowScroller, AutoSizer, List as VirtualScroll } from 'react-virtualized';
import { freezescroll } from 'Shared/Overlay/base.scss';
import { isIOS } from 'Shared/device-type';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
import { ProductCardSelection } from 'Shared/ProductComponents/ProductCardSelection';
export var ListType;
(function (ListType) {
    ListType[ListType["Pantry"] = 0] = "Pantry";
    ListType[ListType["Favorite"] = 1] = "Favorite";
})(ListType || (ListType = {}));
var filters = [
    function (product, query) { return !!product.trademark && product.trademark.toLowerCase().includes(query); },
    function (product, query) { return !!product.title && product.title.toLowerCase().includes(query); },
    function (product, query) { return product.code.includes(query); },
];
var VIRTUAL_SCROLL_THRESHOLD = 100;
var BaseList = /** @class */ (function (_super) {
    __extends(BaseList, _super);
    function BaseList(props) {
        var _this = _super.call(this, props) || this;
        _this.onKeyDown = function (event) {
            switch (event.keyCode) {
                case PLUS_DESKTOP:
                case TAB:
                case DOWN:
                case PLUS:
                    event.preventDefault();
                    _this.moveSelection('next');
                    if (event.shiftKey) {
                        _this.moveSelection('prev');
                    }
                    break;
                case UP:
                case MINUS:
                case MINUS_DESKTOP:
                    event.preventDefault();
                    _this.moveSelection('prev');
                    break;
                case ENTER:
                    event.preventDefault();
                    _this.onEnter(event);
                    break;
                default:
                    break;
            }
        };
        _this.search = function (value) { return filters.some(function (filter) { return filter(value, _this.state.searchQuery.toLowerCase()); }); };
        _this.updateSearchPhrase = function (e) {
            var searchQuery = e.target.value;
            if (!!searchQuery && searchQuery.length > 0) {
                _this.setState({
                    selectedItems: [],
                    searchQuery: searchQuery,
                    selectedCompletionIndex: -1,
                });
            }
            else {
                _this.setState({
                    searchQuery: searchQuery,
                    selectedCompletionIndex: -1,
                });
            }
        };
        _this.toggleSelected = function (id, add) {
            var selectedItems = Object.assign([], _this.state.selectedItems);
            var productCode = id.split('_')[0];
            var itemIndex = _this.state.selectedItems.findIndex(function (item) { return item.startsWith(productCode); });
            if (itemIndex === -1 && add) {
                selectedItems.push(id);
            }
            else {
                selectedItems.splice(_this.state.selectedItems.indexOf(id), 1);
            }
            _this.setState({ selectedItems: selectedItems });
        };
        _this.selectAll = function (allSelected) {
            var selectedItems = [];
            if (allSelected) {
                _this.getFilteredProducts().map(function (p) {
                    selectedItems.push(p.selectedVariant.code);
                });
            }
            _this.setState({ selectedItems: selectedItems, allSelected: allSelected });
        };
        _this.setActive = function (index) {
            _this.setState({ selectedCompletionIndex: index });
            _this.props.setActiveList(_this.props.id);
        };
        _this.removeListItem = function (rowIndex, code) {
            if (_this.props.isRecommendations) {
                _this.props.removeRecommendations([code]);
            }
            else {
                _this.props.removeProductFromList(_this.props.id, code);
            }
        };
        _this.updateOrdinal = function (index, ordinal) {
            var product = _this.getProductAtIndex(index);
            var selectedVariant = product && product.selectedVariant;
            if (!selectedVariant) {
                console.debug('No selected variant at index');
                return;
            }
            _this.props.updateOrdinal(_this.props.id, selectedVariant.code, ordinal);
        };
        _this.selectIndex = function (index) {
            _this.setState({ selectedCompletionIndex: index });
            !_this.props.isActive && _this.props.setActiveList(_this.props.id);
        };
        _this.selectElement = function (index, focusItem) {
            _this.setState({ selectedCompletionIndex: index, focusItem: focusItem });
            !_this.props.isActive && _this.props.setActiveList(_this.props.id);
        };
        _this.rowRenderer = function (rowProps) {
            var product = _this.getProductAtIndex(rowProps.index);
            return _this.getListItem(product, rowProps);
        };
        _this.getRowHeight = function () {
            // Highest encountered mobile card height: 336
            var mobileHeight = _this.props.isFavoriteList ? 406 : 336;
            // Highest encountered desktop row height: 199
            var desktopHeight = _this.props.isFavoriteList ? 199 : 137;
            var height = _this.isMobile() ? mobileHeight : desktopHeight;
            /* Better performance to have even row heights. This should be enough to fit all content on a row.
                 Check history on file if you really need a caluclated value here */
            return height;
        };
        _this.isMobile = function (currentBreakpoint) {
            if (currentBreakpoint === void 0) { currentBreakpoint = _this.props.currentBreakpoint; }
            return currentBreakpoint <= SMALL;
        };
        _this.setListRef = function (ref) {
            _this.virtualScrollList = ref;
        };
        _this.onHeaderClick = function () {
            if (_this.props.isLoading) {
                return;
            }
            !_this.state.isOpen && _this.props.setActiveList(_this.props.id);
            _this.setState({ isOpen: !_this.state.isOpen });
        };
        _this.getListItem = function (product, rowProps) {
            var productCode = product.isCustom ? product.selectedVariant.code : product.code;
            var rowIsActive = _this.state.selectedCompletionIndex === rowProps.index;
            var isSelected = _this.state.allSelected ||
                _this.state.selectedItems.findIndex(function (item) { return item.slice(0, productCode.length) === productCode; }) !== -1;
            return (React.createElement("div", { style: rowProps.style, key: productCode + "_" + _this.props.id },
                React.createElement(ProductCardSelection, null, function (_a) {
                    var selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex;
                    return (React.createElement(ListItem, __assign({}, product, { product: product, currentBreakpoint: _this.props.currentBreakpoint, isPantry: _this.getListType() === ListType.Pantry, inventoryStatus: _this.props.inventoryStatus, isRecommendations: _this.props.isRecommendations, listId: _this.props.id, rowIndex: rowProps.index, isActive: selectedIndex !== undefined ? selectedIndex === rowProps.index : rowIsActive, selectedCompletionIndex: _this.state.selectedCompletionIndex, isMaster: _this.props.isMaster, checked: isSelected, focusItem: _this.state.focusItem, quantity: product.quantity, disabled: _this.props.inventoryStatus && !_this.props.isRecommendations && _this.props.inventoryStatus !== 'ongoing', onKeyDown: _this.onKeyDown, selectElement: _this.selectElement, updateOrdinal: _this.updateOrdinal, updateQuantity: _this.updateQuantity, selectIndex: _this.selectIndex, toggleSelected: _this.toggleSelected, removeListItem: _this.removeListItem, selectedIndex: selectedIndex, setSelectedIndex: setSelectedIndex })));
                })));
        };
        _this.state = {
            selectedCompletionIndex: -1,
            selectedItems: [],
            allSelected: false,
            searchQuery: '',
            isOpen: props.isActive,
            focusItem: 'quantity',
        };
        _this.allowSelectionOfDiscontinued = _this.getListType() === ListType.Pantry;
        return _this;
    }
    BaseList.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.getFilteredProducts().length > 0) {
            var isAllSelected = this.state.selectedItems.length === this.getFilteredProducts().length;
            if (this.state.allSelected !== isAllSelected) {
                this.setState({ allSelected: isAllSelected });
            }
        }
        if (this.props.sortBy !== prevProps.sortBy || this.state.searchQuery !== prevState.searchQuery) {
            this.virtualScrollList && this.virtualScrollList.recomputeRowHeights();
        }
    };
    BaseList.prototype.componentWillReceiveProps = function (nextProps, nextState) {
        if (nextProps.products && this.props.products && nextProps.products.length !== this.props.products.length) {
            this.setState({
                selectedItems: [],
            });
        }
        if (this.isMobile() !== this.isMobile(nextProps.currentBreakpoint)) {
            this.virtualScrollList && this.virtualScrollList.recomputeRowHeights();
        }
    };
    BaseList.prototype.getListType = function () {
        throw new Error('abstract');
    };
    BaseList.prototype.moveSelection = function (direction) {
        var newSelectedIndex = -1;
        var collection = this.getFilteredProducts();
        var noNavigationToDiscontinued = !this.allowSelectionOfDiscontinued;
        newSelectedIndex = this.getNewSelection(direction, collection, this.state.selectedCompletionIndex);
        if (newSelectedIndex < 0) {
            newSelectedIndex = this.getNewSelection(direction, collection, newSelectedIndex);
        }
        if (newSelectedIndex > -1 &&
            newSelectedIndex < collection.length &&
            collection[newSelectedIndex].isDiscontinued &&
            noNavigationToDiscontinued) {
            do {
                newSelectedIndex = this.getNewSelection(direction, collection, newSelectedIndex);
                if (newSelectedIndex > -1 &&
                    newSelectedIndex < collection.length &&
                    collection[newSelectedIndex].isDiscontinued &&
                    noNavigationToDiscontinued) {
                    newSelectedIndex = this.getNewSelection(direction, collection, newSelectedIndex);
                }
            } while (newSelectedIndex > -1 &&
                newSelectedIndex < collection.length &&
                collection[newSelectedIndex].isDiscontinued &&
                noNavigationToDiscontinued);
        }
        this.setState({
            selectedCompletionIndex: newSelectedIndex,
            focusItem: 'quantity',
        });
    };
    BaseList.prototype.getNewSelection = function (direction, list, selectedIndex) {
        if (direction === 'next') {
            return selectedIndex + 1 > list.length - 1 ? 0 : selectedIndex + 1;
        }
        else {
            return selectedIndex >= 0 ? selectedIndex - 1 : list.length > 0 ? list.length - 1 : -1;
        }
    };
    BaseList.prototype.scrollIsFrozenOnIOS = function () {
        return isIOS() && document.body.classList.contains(freezescroll);
    };
    BaseList.prototype.getFilteredProducts = function () {
        if (this.state.searchQuery) {
            // We only want to return a new array ref if we have a search query to avoid rerenders
            return this.props.products.filter(this.search);
        }
        return this.props.products || [];
    };
    BaseList.prototype.getProductAtIndex = function (index) {
        return this.getFilteredProducts()[index];
    };
    BaseList.prototype.getList = function () {
        var _this = this;
        var selectableProductIndexes = this.getFilteredProducts()
            .map(function (product, index) { return (product.isDiscontinued && _this.getListType() !== ListType.Pantry ? -1 : index); })
            .filter(function (i) { return i !== -1; });
        if (this.props.products.length < VIRTUAL_SCROLL_THRESHOLD) {
            return (React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes }, this.getFilteredProducts().map(function (p, i) { return _this.getListItem(p, { index: i }); })));
        }
        else {
            var rowCount_1 = this.getFilteredProducts().length;
            return (React.createElement(WindowScroller, null, function (windowScrollerProps) {
                var scrollTop = windowScrollerProps.scrollTop;
                // When we freeze scroll on iOS the document is no longer scrollable
                // so we need to trick react-virtualized that the document is still as high
                // as it was
                if (scrollTop === 0 && _this.scrollIsFrozenOnIOS()) {
                    scrollTop = _this.lastScrollTop;
                }
                _this.lastScrollTop = scrollTop;
                return (React.createElement(AutoSizer, { disableHeight: true }, function (autoSizerProps) {
                    var width = autoSizerProps.width === 0 ? window.innerWidth - 20 : autoSizerProps.width;
                    _this.currentWidth = width;
                    var averageRowHeight = rowCount_1 ? _this.getRowHeight() / rowCount_1 : 0;
                    return (React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
                        React.createElement(VirtualScroll, { ref: _this.setListRef, autoHeight: true, height: windowScrollerProps.height, scrollTop: windowScrollerProps.scrollTop, rowHeight: _this.getRowHeight, rowCount: rowCount_1, width: width, overscanRowCount: 1, estimatedRowSize: averageRowHeight, rowRenderer: _this.rowRenderer, 
                            // Following props is not a real prop on VirtualScroll. But VirtualScroll uses `shouldComponentUpdate`
                            // to not re-render unless something has changed. Theese props are used to trigger a rerender.
                            list: _this.props.products, selectedItems: _this.state.selectedItems, allSelected: _this.state.allSelected, selectedCompletionIndex: _this.state.selectedCompletionIndex, searchQuery: _this.state.searchQuery, isMobile: _this.isMobile(), sortBy: _this.props.sortBy })));
                }));
            }));
        }
    };
    return BaseList;
}(React.Component));
export default BaseList;
