var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import ProductRow, { AppearanceNameType as ProductRowApperance } from 'Shared/ProductRow';
import ProductHeaderRow from 'Shared/ProductRow/header';
import { SMALL } from 'Shared/breakpoints';
import propertyValueFor from 'Shared/property-value-for';
import ReplacementProduct from 'Shared/ReplacementProduct';
import { addToCart } from './../Cart/action-creators';
import { updateShoppingList, clearShoppingList } from './action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import { selectVariant } from 'Shared/Product/action-creators';
import { getShoppingList } from './reducer';
import * as styles from './Pages/page.scss';
var ShoppingList = /** @class */ (function (_super) {
    __extends(ShoppingList, _super);
    function ShoppingList(props) {
        var _this = _super.call(this, props) || this;
        _this.updateQuantity = function (item, code, quantity) {
            var unitShortName = item.product.variants.filter(function (v) { return v.code === code; })[0].unitShortName;
            return _this.props.updateListItem(code, quantity, unitShortName);
        };
        _this.selectVariant = function (item, previousCode, newCode) {
            var previousVariant = item.product.variants.find(function (v) { return v.code === previousCode; });
            var newVariant = item.product.variants.find(function (v) { return v.code === newCode; });
            var quantity = _this.props.shoppingList.find(function (listItem) { return listItem.code === previousCode; }).quantity;
            _this.props.updateListItem(previousCode, quantity, previousVariant.unitShortName);
            return _this.props.updateListItem(newCode, item.quantity, newVariant.unitShortName);
        };
        _this.componentDidMount = function () {
            return Promise.all(_this.props.items.map(function (item) { return _this.props.selectVariant(item.selectedVariant.code, item.product.variants); }));
        };
        _this.addProducts = function () {
            return Promise.all(_this.props.shoppingList.map(function (add) { return _this.props.addToCart(add.code, add.quantity, add.unitShortName, add.ticket); })).then(function () { return Promise.resolve(); });
        };
        _this.onAddedToCart = function () {
            clearShoppingList();
            _this.props.hideModal();
        };
        return _this;
    }
    ShoppingList.prototype.render = function () {
        var _this = this;
        var props = this.props;
        return (React.createElement("div", null, !!props.items && !!props.items.length ? (React.createElement("div", { className: styles.shoppingListWrapper },
            React.createElement(ProductHeaderRow, { appearance: "compact", hasAmountNeeded: true, hasDelete: true, isCart: false, isCheckout: false, currentBreakpoint: props.currentBreakpoint }),
            React.createElement("div", { className: classnames(styles.shoppingList) }, props.items.map(function (item, i) {
                var productItem = !!item.product.isDiscontinued || !!item.product.isBackorderReplaced
                    ? item.product.replacementProduct
                    : item.product;
                var listItem = _this.props.shoppingList.find(function (listItem) { return item.selectedVariant.code === listItem.code; });
                return (!!listItem && (React.createElement("div", { key: "wrapper_" + listItem.code + "_" + i },
                    (!!item.product.isDiscontinued || !!item.product.isBackorderReplaced) && (React.createElement(ReplacementProduct, __assign({ key: propertyValueFor(item.product.content.code), appearance: props.currentBreakpoint > SMALL ? 'row' : 'mobile', product: item.product }, props))),
                    !!productItem && (React.createElement(ProductRow, __assign({ appearance: ProductRowApperance.Compact }, productItem, props, { removeCartItem: function () { return props.removeListItem(listItem.code, listItem.unitShortName); }, updateQuantity: function (code, quantity) { return _this.updateQuantity(item, code, quantity); }, quantity: listItem.quantity, selectedVariant: productItem.variants.find(function (v) { return v.unitShortName === listItem.unitShortName; }), onSelectVariant: function (previousCode, newCode) { return _this.selectVariant(item, previousCode, newCode); }, amountNeeded: item.amountNeeded, key: listItem.code, rowIndex: i, location: "ShoppingList" }))))));
            })),
            React.createElement(Dialog, { type: DialogType.OkOnly, onConfirm: this.addProducts, onCancel: this.props.hideModal, onStatusWasFulfilled: this.onAddedToCart, customAcceptText: translate('/AddOrder/AddToCart') }))) : (React.createElement("div", { className: styles.shoppingListWrapper },
            React.createElement("b", null, translate('/Recipe/ShoppingListEmpty'))))));
    };
    return ShoppingList;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    shoppingList: getShoppingList(state),
}); }, function (dispatch) { return ({
    addToCart: function (code, quantity, unitShortName, ticket) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, 'ShoppingList'));
    },
    updateUnit: function (items) {
        var code = items && Object.keys(items)[0];
        return dispatch(updateShoppingList(code, items[code].newQuantity, items[code].newUnitShortName));
    },
    updateListItem: function (code, quantity, unitShortName) {
        return dispatch(updateShoppingList(code, quantity, unitShortName));
    },
    removeListItem: function (code, unitShortName) { return dispatch(updateShoppingList(code, null, unitShortName)); },
    selectVariant: function (code, variants) { return dispatch(selectVariant(code, variants)); },
}); })(ShoppingList);
