import resolveComponent from 'Shared/resolve-component';
import Category from '../Category';
export var isCategoryPageType = function (currentPage) {
    return resolveComponent(currentPage.componentName) === Category;
};
export default (function (state) {
    return componentIsCategory(state.currentPage.componentName);
});
export function componentIsCategory(componentName) {
    return resolveComponent(componentName) === Category;
}
