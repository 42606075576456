import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import ProductVariantSelector from 'Shared/ProductComponents/ProductVariantSelector';
import { ProductCardSelection } from 'Shared/ProductComponents/ProductCardSelection';
import Quantity from 'Shared/Quantity';
import SendTenderRequest from '../../../Tender/send-tender-request';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var VariantAndQuantity = function (_a) {
    var isLoggedIn = _a.isLoggedIn, product = _a.product, index = _a.index, minQuantity = _a.minQuantity, quantityStep = _a.quantityStep, isCartQuantity = _a.isCartQuantity, quantityInCart = _a.quantityInCart, updateCartQuantity = _a.updateCartQuantity, selectedVariant = _a.selectedVariant, variants = _a.variants, isWeightProduct = _a.isWeightProduct, disableBuyWidgets = _a.disableBuyWidgets, isRestrictedCustomer = _a.isRestrictedCustomer, discontinuedOrBackordered = _a.discontinuedOrBackordered, isFavorite = _a.isFavorite, isPantry = _a.isPantry, isVariantVisible = _a.isVariantVisible, isQuantityVisible = _a.isQuantityVisible, selectElement = _a.selectElement, quantity = _a.quantity, maxQuantity = _a.maxQuantity, rowIndex = _a.rowIndex, isDisabled = _a.isDisabled, setSelected = _a.setSelected, selectedIndex = _a.selectedIndex, isMobile = _a.isMobile, mobileFocus = _a.mobileFocus, mobileOnFocus = _a.mobileOnFocus, useNewDesign = _a.useNewDesign, isCheckout = _a.isCheckout, hasSubDelivery = _a.hasSubDelivery;
    return (React.createElement("div", { className: styles.wrapper },
        isLoggedIn && !isFavorite && (React.createElement("div", { className: classnames(styles.quantityAndVariantWrap) }, product.isTender ? (React.createElement(SendTenderRequest, { tenderProductName: product.title, tenderProductArticleNumber: propertyValueFor(product.content.code) })) : (React.createElement(React.Fragment, null,
            !useNewDesign && !discontinuedOrBackordered && (React.createElement(ProductVariantSelector, { variants: variants, selectedVariant: selectedVariant, isWeightProduct: isWeightProduct, disabled: discontinuedOrBackordered })),
            React.createElement(ProductCardSelection, null, function (_a) {
                var selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex;
                return (React.createElement(Quantity, { update: updateCartQuantity, step: quantityStep, value: quantityInCart, focus: selectedIndex === index, disabled: discontinuedOrBackordered || isRestrictedCustomer || disableBuyWidgets, minQuantity: minQuantity, onFocus: function () { return setSelectedIndex(index); }, isCartQuantity: isCartQuantity, useNewDesign: useNewDesign, selectedVariant: selectedVariant, isCheckout: isCheckout, isPantry: isPantry, hasSubDelivery: hasSubDelivery }));
            }))))),
        isFavorite && (React.createElement("div", { className: classnames(styles.quantityAndVariantWrap) },
            isVariantVisible && (React.createElement("div", { className: styles.variantWrapper, onClick: function () { return selectElement(rowIndex, 'unit'); } },
                React.createElement(ProductVariantSelector, { variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, disabled: discontinuedOrBackordered }))),
            isQuantityVisible && (React.createElement("div", { onClick: function () { return selectElement(rowIndex, 'quantity'); } },
                React.createElement(Quantity, { update: updateCartQuantity, step: quantityStep, value: isPantry ? quantity : quantityInCart, allowEmptyQuantity: isPantry && quantity != null ? false : true, focus: isMobile ? mobileFocus : rowIndex === selectedIndex, disabled: (isDisabled || discontinuedOrBackordered || product.isSoldOut || !product.isBuyable) && !isPantry, onFocus: isMobile ? mobileOnFocus : function () { return setSelected(rowIndex); }, minQuantity: 0, maxQuantity: maxQuantity, isCartQuantity: true, hideButtons: isPantry, isCheckout: isCheckout, isPantry: isPantry, mayCommitUpdateOnUnmountIfFocused: true })))))));
};
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); })(VariantAndQuantity);
