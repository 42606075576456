var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import classnames from "classnames";
import React from "react";
import { Checkbox } from "Shared/Fields";
import { translate } from "Shared/translate";
import * as styles from '../../base.scss';
import { ListType } from "./DeliveryList";
var DeliveryHeaderRow = /** @class */ (function (_super) {
    __extends(DeliveryHeaderRow, _super);
    function DeliveryHeaderRow(props) {
        var _this = _super.call(this, props) || this;
        _this.setChecked = function (e) {
            _this.props.addAllToPrint(e.target.checked);
        };
        _this.state = {
            checked: _this.props.checked,
        };
        return _this;
    }
    DeliveryHeaderRow.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: prevProps.checked });
        }
    };
    DeliveryHeaderRow.prototype.render = function () {
        var headerTextCellOne = translate("/MyMenigo/DeliveryBlock/List/DeliveryDate");
        var headerTextCellTwo = (this.props.listType === ListType.Planned) ? translate("/MyMenigo/DeliveryBlock/List/Departure") : translate("/MyMenigo/DeliveryBlock/List/Time");
        var headerTextCellThree = translate("/MyMenigo/DeliveryBlock/List/NumberOfArticles");
        var headerTextCellFour = translate("/MyMenigo/DeliveryBlock/List/TotalSum");
        return (React.createElement("div", { className: classnames(styles.Delivery__Row, styles.Delivery__Row__Header) },
            React.createElement("div", { className: styles.Delivery__Cell }),
            React.createElement("div", { className: styles.Delivery__Cell }, headerTextCellOne),
            React.createElement("div", { className: classnames(styles.Delivery__Cell, (ListType.Completed == this.props.listType && styles.time)) }, headerTextCellTwo),
            React.createElement("div", { className: styles.Delivery__Cell }, headerTextCellThree),
            React.createElement("div", { className: styles.Delivery__Cell }, headerTextCellFour),
            React.createElement("div", { className: styles.Delivery__Cell }, (this.props.listType === ListType.Planned) && React.createElement(Checkbox, { defaultChecked: !!this.state.checked, onChange: this.setChecked.bind(this) }))));
    };
    return DeliveryHeaderRow;
}(React.Component));
export default DeliveryHeaderRow;
