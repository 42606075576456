import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import React from 'react';
import * as styles from './base.scss';
import classnames from 'classnames';
import ToolTip from '../ProductTooltip/ToolTip';
import { getCampaignIcon, getCampaignTextColor, getCampaignLabelToolTipText } from './Components/action-creators';
export var CampaignType;
(function (CampaignType) {
    CampaignType[CampaignType["NewProducts"] = 0] = "NewProducts";
    CampaignType[CampaignType["Utf"] = 1] = "Utf";
    CampaignType[CampaignType["TemporaryOffer"] = 2] = "TemporaryOffer";
    CampaignType[CampaignType["Campaign"] = 3] = "Campaign";
    CampaignType[CampaignType["WebbCampaign"] = 4] = "WebbCampaign";
    CampaignType[CampaignType["EarlyOrder"] = 5] = "EarlyOrder";
    CampaignType[CampaignType["BlackFriday"] = 6] = "BlackFriday";
    CampaignType[CampaignType["NotInStock"] = 7] = "NotInStock";
    CampaignType[CampaignType["Seasonal"] = 8] = "Seasonal";
    CampaignType[CampaignType["Eco"] = 9] = "Eco";
    CampaignType[CampaignType["SpecialOffer"] = 10] = "SpecialOffer";
    CampaignType[CampaignType["Quotation"] = 11] = "Quotation";
    CampaignType[CampaignType["AlwaysAffordable"] = 12] = "AlwaysAffordable";
    CampaignType[CampaignType["SustainableChoice"] = 13] = "SustainableChoice";
    CampaignType[CampaignType["SelectedPriceList"] = 14] = "SelectedPriceList";
    CampaignType[CampaignType["SoldOut"] = 15] = "SoldOut";
    CampaignType[CampaignType["Discontinued"] = 16] = "Discontinued";
})(CampaignType || (CampaignType = {}));
;
var ProductCampaignLabel = function (_a) {
    var _b, _c;
    var labelText = _a.labelText, campaignType = _a.campaignType, startDate = _a.startDate, endDate = _a.endDate, startDateDelivery = _a.startDateDelivery, endDateDelivery = _a.endDateDelivery, tooltipLabel = _a.tooltipLabel, isPrimary = _a.isPrimary;
    var tooltipText = !!getCampaignLabelToolTipText(campaignType) ? getCampaignLabelToolTipText(campaignType).replace(/\[br\]/g, '\n').replace(/\[startDate]/g, startDate).replace(/\[endDate]/g, endDate).replace("[startDateDelivery]", startDateDelivery).replace("[endDateDelivery]", endDateDelivery) : null;
    if (!!!tooltipText)
        return (React.createElement("div", { className: styles.noToolTipWrapper },
            React.createElement("div", { className: classnames(styles.campaignLabel, getCampaignTextColor(campaignType), (_b = {}, _b[styles.primaryTag] = isPrimary, _b)) },
                !!getCampaignIcon(campaignType) && React.createElement(MyMenigoIcon, { name: getCampaignIcon(campaignType) }),
                labelText)));
    else
        return (React.createElement(ToolTip, { header: !!tooltipLabel ? tooltipLabel : labelText, text: tooltipText },
            React.createElement("div", { className: classnames(styles.campaignLabel, getCampaignTextColor(campaignType), (_c = {}, _c[styles.primaryTag] = isPrimary, _c)) },
                !!getCampaignIcon(campaignType) && React.createElement(MyMenigoIcon, { name: getCampaignIcon(campaignType) }),
                labelText)));
};
export default ProductCampaignLabel;
