var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import * as styles from './base.scss';
import ReactDOM from 'react-dom';
import Transition from 'Shared/Transition';
export var ModalType;
(function (ModalType) {
    ModalType[ModalType["None"] = 0] = "None";
    ModalType[ModalType["Success"] = 1] = "Success";
    ModalType[ModalType["Warning"] = 2] = "Warning";
    ModalType[ModalType["Danger"] = 3] = "Danger";
})(ModalType || (ModalType = {}));
var InnerModal = /** @class */ (function (_super) {
    __extends(InnerModal, _super);
    function InnerModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InnerModal.prototype.componentDidMount = function () {
        this.forceUpdate();
    };
    InnerModal.prototype.renderTitleIfProvided = function () {
        var _a;
        return this.props.title ? (React.createElement("h2", { className: classnames(styles.title, (_a = {},
                _a[styles.reserveSpaceForCloseButton] = this.props.reserveSpaceForCloseButton,
                _a)) }, this.props.title)) : null;
    };
    InnerModal.prototype.getModalIconFromType = function (type) {
        if (type === void 0) { type = this.props.type; }
        var wrapElement = function (el) { return React.createElement("div", { className: styles.iconWrapper }, el); };
        if (type === ModalType.Danger) {
            return wrapElement(React.createElement(Icon, { name: "remove-circle" }));
        }
        else if (type === ModalType.Warning) {
            return wrapElement(React.createElement(Icon, { name: "exclamation" }));
        }
        else if (type === ModalType.Success) {
            return wrapElement(React.createElement(Icon, { name: "check" }));
        }
        return null;
    };
    InnerModal.prototype.renderComponent = function () {
        var _this = this;
        var _a = this.props, onClose = _a.onClose, isOpen = _a.isOpen, className = _a.className, childContainerClassname = _a.childContainerClassname, reserveSpaceForCloseButton = _a.reserveSpaceForCloseButton, children = _a.children;
        return (React.createElement(Transition, { in: isOpen, timeout: 200, appear: true }, function (active) {
            var _a, _b, _c;
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classnames((_a = {}, _a[styles.innerModalBackground] = isOpen, _a)), onClick: onClose }),
                React.createElement("div", { className: classnames(styles.base, styles.innerModal, className, (_b = {}, _b[styles.active] = active, _b)) },
                    React.createElement("div", { className: styles.innerContainer },
                        _this.renderTitleIfProvided(),
                        _this.getModalIconFromType(),
                        React.createElement("div", { className: classnames(childContainerClassname, (_c = {},
                                _c[styles.reserveSpaceForCloseButton] = !_this.props.title && reserveSpaceForCloseButton,
                                _c)) }, children),
                        React.createElement(Button, { className: styles.close, appearance: "clear", onClick: onClose },
                            React.createElement(Icon, { name: 'close' }))))));
        }));
    };
    InnerModal.prototype.render = function () {
        return this.props.rootElementRef
            ? ReactDOM.createPortal(this.renderComponent(), this.props.rootElementRef)
            : this.renderComponent();
    };
    return InnerModal;
}(React.PureComponent));
export default InnerModal;
