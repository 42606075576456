export var SELECT_VARIANT = 'SELECT_VARIANT';
export var REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export var SET_ALTERNATIVE_PRODUCTS_LINKS = 'SET_ALTERNATIVE_PRODUCTS_LINKS';
export var setAlternativeProductsLinksToState = function (data) {
    var action = {
        type: SET_ALTERNATIVE_PRODUCTS_LINKS,
        data: data
    };
    return action;
};
export var selectVariant = function (newCode, variants) {
    var oldVariant = variants.find(function (v) { return v.code !== newCode; });
    var oldCode = oldVariant ? oldVariant.code : newCode;
    var action = {
        type: SELECT_VARIANT,
        newCode: newCode,
        oldCode: oldCode,
    };
    return action;
};
export var toggleSelectedVariant = function (currentSelectedVariantCode, variants) {
    var variantCodes = variants.map(function (v) { return v.code; });
    var newVariantCode = variantCodes[0] === currentSelectedVariantCode && variantCodes.length > 1 ? variantCodes[1] : variantCodes[0];
    return selectVariant(newVariantCode, variants);
};
