import { LEFT, RIGHT } from './touch-sequence';
import { transformProperty, transitionProperty, transitionEndEvent } from 'Shared/device-type';
import * as styles from './base.scss';
export default (function (_a) {
    var touchSequence = _a.touchSequence, container = _a.container, overlay = _a.overlay, isOpen = _a.isOpen, position = _a.position, toggleFlyout = _a.toggleFlyout;
    var open = isOpen;
    var containerWidth = container.offsetWidth;
    var startedTouchingCloseToEdge;
    var currentX = 0;
    var followTouchRafId = null;
    var closeOffset = 0;
    var isFollowingTouch = function () {
        return followTouchRafId !== null;
    };
    var followTouch = function () {
        container.style[transitionProperty] = 'none';
        var loop = function () {
            var x = currentX - containerWidth;
            if (x !== 0) {
                container.style[transformProperty] = "translate3d(" + x + "px, 0, 0)";
            }
            followTouchRafId = requestAnimationFrame(loop);
        };
        followTouchRafId = requestAnimationFrame(loop);
    };
    return {
        isOpen: function (bool) {
            open = bool;
        },
        onTouchStart: function (e) {
            var touch = e.touches[0];
            closeOffset = containerWidth - touch.pageX;
            currentX = containerWidth;
            if (position === 'left') {
                startedTouchingCloseToEdge = touchSequence.firstEvent().pageX < 40;
            }
            else {
                startedTouchingCloseToEdge = window.innerWidth - touchSequence.firstEvent().pageX < 40;
            }
        },
        onTouchEnd: function (e) {
            if (isFollowingTouch()) {
                cancelAnimationFrame(followTouchRafId);
                followTouchRafId = null;
                var swipeDirection = touchSequence.swipeDirection();
                if (swipeDirection === LEFT || swipeDirection === RIGHT) {
                    container.style[transitionProperty] = '';
                    container.style[transformProperty] = '';
                    var shouldCloseWhenSwipingDirection = void 0;
                    if (position === 'left') {
                        shouldCloseWhenSwipingDirection = LEFT;
                    }
                    else {
                        shouldCloseWhenSwipingDirection = RIGHT;
                    }
                    var shouldOpen = !open;
                    var shouldClose = open && touchSequence.isSwiping(shouldCloseWhenSwipingDirection);
                    if (shouldOpen || shouldClose) {
                        container.classList.toggle(styles.open);
                        /**
                         * Because there is a delay in the transitionend the overlay
                         * is visible when the mainMenu is hidden, to prevent this we will
                         * change the overlay opacity to 0.
                         */
                        overlay.firstChild.style.opacity = 0;
                        var toggleFlyoutAction_1 = function () {
                            /**
                             * In order to prevent the stuterring we will first fire the
                             * redux toggle function after the css animation is done.
                             */
                            overlay.firstChild.style.opacity = '0.5';
                            toggleFlyout();
                            container.removeEventListener(transitionEndEvent, toggleFlyoutAction_1);
                        };
                        container.addEventListener(transitionEndEvent, toggleFlyoutAction_1);
                    }
                }
            }
        },
        onTouchMove: function (e) {
            var touch = e.touches[0];
            if (!open) {
                // Math.min()/Math.max() because you shouldn't be able to move the flyout further out
                // than the flyout is wide
                var openSwipeDirection = void 0;
                if (position === 'left') {
                    currentX = Math.min(containerWidth, touch.pageX);
                    openSwipeDirection = RIGHT;
                }
                else {
                    // The size of the space between the flyout and the device screen edge
                    var flyoutMargin = window.innerWidth - containerWidth;
                    currentX = Math.max(containerWidth, touch.pageX + containerWidth - flyoutMargin);
                    openSwipeDirection = LEFT;
                }
                if (!isFollowingTouch() && startedTouchingCloseToEdge && touchSequence.isSwiping(openSwipeDirection)) {
                    followTouch();
                }
            }
            else {
                var swipeDirection = touchSequence.swipeDirection();
                if (swipeDirection === LEFT || swipeDirection === RIGHT) {
                    var minOrMax = void 0;
                    if (position === 'left') {
                        minOrMax = Math.min;
                    }
                    else {
                        minOrMax = Math.max;
                    }
                    currentX = minOrMax(containerWidth, touch.pageX + closeOffset);
                    if (!isFollowingTouch()) {
                        followTouch();
                    }
                }
            }
            if (isFollowingTouch()) {
                e.preventDefault();
            }
        },
    };
});
