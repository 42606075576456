var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Slice from './Slice';
var Chart = /** @class */ (function (_super) {
    __extends(Chart, _super);
    function Chart(props) {
        return _super.call(this, props) || this;
    }
    Chart.prototype.render = function () {
        var _this = this;
        var radius = this.props.radius;
        var diameter = radius * 2;
        var sum = this.props.data.reduce(function (carry, current) {
            return carry + current.value;
        }, 0);
        var startAngle = 0;
        return (React.createElement("div", { className: this.props.className },
            React.createElement("svg", { width: diameter, height: diameter, viewBox: '0 0 ' + diameter + ' ' + diameter, xmlns: "http://www.w3.org/2000/svg", version: "1.1" }, this.props.data.map(function (dataItem, sliceIndex) {
                var nextAngle = startAngle;
                var angle = (dataItem.value / sum) * 360;
                var percent = (dataItem.value / sum) * 100;
                startAngle += angle;
                return (React.createElement(Slice, { key: sliceIndex, percentValue: percent, startAngle: nextAngle, angle: angle, radius: radius, fill: dataItem.color, stroke: _this.props.stroke, strokeWidth: _this.props.strokeWidth }));
            })),
            React.createElement("ul", null, this.props.data.map(function (dataItem) { return (React.createElement("li", { key: dataItem.label },
                React.createElement("span", { style: { color: dataItem.color } }, "\u25CF"),
                dataItem.label)); }))));
    };
    return Chart;
}(React.Component));
export default Chart;
