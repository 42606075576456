import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import * as styles from './base.scss';
import MyMenigoIcon from '../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import Price from './ProductListItemPrice';
import { getSelectedVariant } from 'Shared/Product/reducer';
var ProductListItem = function (_a) {
    var isLoggedIn = _a.isLoggedIn, isSupplierSalesman = _a.isSupplierSalesman, selectedVariant = _a.selectedVariant, product = _a.product;
    var url = product.isDiscontinued && product.replacementProduct
        ? product.replacementProduct.url.toString()
        : !product.isDiscontinued && product.url.toString();
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { href: url, className: styles.product_list__link }),
        React.createElement("div", { className: styles.product_list__row },
            React.createElement("div", { className: styles.product_list__image },
                React.createElement(MyMenigoIcon, { name: "alwaysAffordable" })),
            React.createElement("div", { className: styles.product_list__title },
                React.createElement("h5", null, product.title),
                React.createElement("span", null, product.supplier)),
            React.createElement("div", { className: classnames(styles.product_list__price) },
                React.createElement(Price, { isLoggedIn: isLoggedIn, isSupplierSalesman: isSupplierSalesman, price: selectedVariant.price, productIsInCampaign: product.isInCampaign, selectedVariant: product.isWeightProduct ? product.variants ? product.variants[0] : null : selectedVariant })),
            React.createElement("div", { className: styles.product_list__action },
                React.createElement("a", { href: url },
                    React.createElement(MyMenigoIcon, { name: "arrow" }))))));
};
export default connect(function (state, _a) {
    var product = _a.product;
    var selectedVariant = getSelectedVariant(state, product.variants);
    return {
        selectedVariant: selectedVariant,
    };
})(ProductListItem);
