var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { SMALL } from 'Shared/breakpoints';
import classnames from 'classnames';
import * as styles from './TopBar.scss';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import LoginButton from '../Buttons/MyMenigo/LoginButton';
import DeliveryDatesButton from '../Buttons/DeliveryDates/DeliveryDatesButton';
import miniCartIsDisabled from 'SiteLayout/MiniCart/is-disabled-selector';
import LogoutButton from '../Buttons/MyMenigo/LogoutButton';
import MiniCartButton from '../Buttons/MiniCart/MiniCartButton';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
var TopBar = /** @class */ (function (_super) {
    __extends(TopBar, _super);
    function TopBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TopBar.prototype.renderDesktopHeader = function () {
        var _a;
        return (React.createElement("div", { className: classnames(styles.TopBar, this.props.isLoggedIn && styles.isLoggedIn) },
            React.createElement("div", { className: classnames(styles.layout, styles.TopBarSection, (_a = {}, _a[styles.small] = !!this.props.small, _a)) },
                React.createElement("div", { className: styles.spacer }),
                React.createElement("div", { className: classnames(styles.TopBarColumn, styles.TopBarColumn__first) },
                    React.createElement(React.Fragment, null, this.props.isLoggedIn && (React.createElement(LoginButton, null)))),
                this.props.isLoggedIn && (React.createElement(React.Fragment, null, (React.createElement("div", { className: classnames(styles.TopBarColumn, styles.TopBarColumn__info) },
                    React.createElement("div", { className: styles.TopBarRow }, !this.props.isSupplierSalesman ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.userInfoWrapper }, !!this.props.orderReferenceId ? (React.createElement(ToolTip, { direction: "bottom", small: true, header: translate('/MyMenigo/CustomerProfileBlock/OrderReferenceId'), text: this.props.orderReferenceId },
                            React.createElement(React.Fragment, null,
                                React.createElement("span", { className: styles.userInfoItem },
                                    this.props.currentUser.customerId,
                                    ", "),
                                React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.companyName),
                                (this.props.currentUser.fullName != this.props.currentUser.companyName) && React.createElement("span", { className: styles.userInfoItem },
                                    ", ",
                                    this.props.currentUser.fullName)))) : (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: styles.userInfoItem },
                                this.props.currentUser.customerId,
                                ", "),
                            React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.companyName),
                            (this.props.currentUser.fullName != this.props.currentUser.companyName) && React.createElement("span", { className: styles.userInfoItem },
                                ", ",
                                this.props.currentUser.fullName)))),
                        React.createElement(LogoutButton, { close: function () { } }))) : (React.createElement("div", { className: styles.suppliersalesmanWrapper },
                        React.createElement("span", null, translate('/SiteLayout/Header/LoggedAsSupplierSalesman')),
                        React.createElement("span", { className: styles.userInfoItemFirst },
                            this.props.currentUser.customerId,
                            ", ",
                            this.props.currentUser.fullName),
                        React.createElement("br", null),
                        React.createElement("span", { className: styles.suppliersalesmanInfo },
                            React.createElement("span", null, translate('/SiteLayout/Header/ChosenCustomer')),
                            React.createElement("span", { className: styles.userInfoItem },
                                this.props.suppliersalesmanInfo,
                                " ")),
                        this.props.activeExhibition && (React.createElement("span", { className: styles.suppliersalesmanExhibition },
                            React.createElement("span", null, translate('/SiteLayout/Header/Exhibition')),
                            React.createElement("span", { className: styles.userInfoItem }, this.props.activeExhibition)))))))))),
                !!!this.props.isLoggedIn && (React.createElement("div", { className: classnames(styles.TopBarColumn, styles.TopBarColumn__wide) },
                    React.createElement("div", { className: styles.helperLinksHolder },
                        React.createElement("ul", { className: styles.helperLinks }, this.props.mainMenu.topContentLinks.map(function (item, index) { return (React.createElement("li", { key: index },
                            React.createElement("a", { href: item.url }, item.displayName))); })),
                        " "))),
                React.createElement("div", { className: classnames(styles.TopBarColumn, !IS_APP && !!this.props.isLoggedIn && styles.divider, styles.left) }, !IS_APP && !!this.props.isLoggedIn && React.createElement(DeliveryDatesButton, null)),
                React.createElement("div", { className: classnames(styles.TopBarColumn, !!this.props.isLoggedIn && styles.divider, styles.TopBarColumn__last, styles.left, styles.right) },
                    !!!this.props.isLoggedIn && React.createElement(LoginButton, null),
                    !this.props.miniCartIsDisabled && !!this.props.isLoggedIn && React.createElement(MiniCartButton, null)))));
    };
    TopBar.prototype.render = function () {
        if (!IS_APP && this.props.currentBreakpoint > SMALL) {
            return this.renderDesktopHeader();
        }
        else {
            return null;
        }
    };
    return TopBar;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    suppliersalesmanInfo: state.currentUser.supplierSalesmanInfo,
    activeExhibition: state.currentUser.activeExhibition,
    currentBreakpoint: state.currentBreakpoint,
    mainMenu: state.mainMenu,
    currentUser: state.currentUser,
    miniCartIsDisabled: miniCartIsDisabled(state),
    orderReferenceId: state.currentUser.orderReferenceId
}); }, {})(TopBar);
