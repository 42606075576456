var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { onDeleteClassification, onAddClassification, onEditClassification } from './../action-creators';
import Modal, { ModalType } from 'Shared/Modal';
import MyPagesMenu from './../../../MyMenigo/Shared/MyPagesMenu';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Main, { Section } from 'Shared/PageLayout';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import * as styles from './base.scss';
import { Input } from 'Shared/Fields';
var defaultItem = {
    id: '',
    className: '',
    integrationCode: '',
    integrationId: '',
    name: '',
    nameEn: '',
};
var RecipeGroupPage = /** @class */ (function (_super) {
    __extends(RecipeGroupPage, _super);
    function RecipeGroupPage(props) {
        var _this = _super.call(this, props) || this;
        _this.showDeleteModal = function (item) {
            _this.setState({
                showDeleteModal: true,
                deleteItem: item,
            });
        };
        _this.hideDeleteModal = function () {
            _this.setState({ showDeleteModal: false });
        };
        _this.showEditModal = function (item) {
            _this.setState({ showEditModal: true, editItem: item });
        };
        _this.hideEditModal = function () {
            _this.setState({ showEditModal: false });
        };
        _this.deleteRow = function (item) {
            if (item === void 0) { item = _this.state.deleteItem; }
            return onDeleteClassification(item.id).then(function () {
                var newItems = _this.state.items.filter(function (newItem) { return newItem.id !== item.id; });
                _this.setState({ items: newItems });
                _this.props.dismissFeedback();
            }).catch(function (e) { return Promise.reject(null); });
        };
        _this.showAddModal = function () {
            _this.setState({ showAddModal: true });
        };
        _this.hideAddModal = function () {
            _this.setState({ showAddModal: false });
        };
        _this.state = {
            items: props.classifications,
            showDeleteModal: false,
            showEditModal: false,
            showAddModal: false,
            deleteItem: defaultItem,
            editItem: defaultItem,
        };
        return _this;
    }
    RecipeGroupPage.prototype.editRow = function (id, name, nameEn, classificationName, code) {
        var _this = this;
        return onEditClassification(name, nameEn, classificationName, code, id).then(function (result) {
            if (result.success) {
                var newItems = Object.assign([], _this.state.items);
                var i = newItems.find(function (newItem) { return newItem.id === id; });
                i.name = name;
                i.nameEn = nameEn;
                i.className = classificationName;
                i.integrationCode = code;
                _this.setState({ items: newItems });
                _this.props.dismissFeedback();
            }
            else {
                _this.props.setFeedback({ text: result.message, type: 'error' });
                return Promise.reject(null);
            }
        });
    };
    RecipeGroupPage.prototype.addRow = function (name, nameEn, classificationName, code) {
        var _this = this;
        return onAddClassification(name, nameEn, classificationName, code).then(function (result) {
            if (result.success) {
                var newItems = Object.assign([], _this.state.items);
                newItems.push(result.value);
                _this.setState({
                    items: newItems.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    }),
                });
                _this.props.dismissFeedback();
            }
            else {
                _this.props.setFeedback({ text: result.message, type: 'error' });
                return Promise.reject(null);
            }
        });
    };
    RecipeGroupPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, null,
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", null, translate('/Admin/Classifications/Classification'))),
            React.createElement(Section, null,
                React.createElement("table", { className: styles.classificationTable },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, translate('/Admin/Classifications/Name')),
                            React.createElement("th", null, translate('/Admin/Classifications/NameEn')),
                            React.createElement("th", null, translate('/Admin/Classifications/ClassName')),
                            React.createElement("th", null, translate('/Admin/Classifications/Code')),
                            React.createElement("th", null,
                                React.createElement(Button, { onClick: function () { return _this.showAddModal(); }, appearance: 'primary' }, translate('/Admin/Classifications/AddClassification')))),
                        this.state.items.map(function (group) { return (React.createElement("tr", { key: group.id },
                            React.createElement("td", null,
                                React.createElement("a", { onClick: function () { return _this.showEditModal(group); } }, group.name)),
                            React.createElement("td", null, group.nameEn),
                            React.createElement("td", null, group.className),
                            React.createElement("td", null, group.integrationCode),
                            React.createElement("td", null,
                                React.createElement(Button, { onClick: function () { return _this.showDeleteModal(group); }, appearance: 'clear' },
                                    React.createElement(Icon, { name: "remove" }))))); }))),
                React.createElement(Modal, { title: translate('/Admin/Classifications/EditClassification'), isOpen: this.state.showEditModal, onClose: this.hideEditModal, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/Name'), className: styles.modalInput, defaultValue: this.state.editItem.name, ref: function (e) { return (_this.inputName = e); }, required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), autoFocus: true })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/NameEn'), className: styles.modalInput, defaultValue: this.state.editItem.nameEn, ref: function (e) { return (_this.inputNameEn = e); } })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/ClassName'), className: styles.modalInput, defaultValue: this.state.editItem.className, ref: function (e) { return (_this.inputClassName = e); } })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/Code'), className: styles.modalInput, defaultValue: this.state.editItem.integrationCode, ref: function (e) { return (_this.inputClassCode = e); } })),
                    React.createElement(Dialog, { type: DialogType.SaveAbort, onCancel: this.hideEditModal, onConfirm: function () {
                            return _this.editRow(_this.state.editItem.id, _this.inputName.element.value, _this.inputNameEn.element.value, _this.inputClassName.element.value, _this.inputClassCode.element.value);
                        }, onStatusWasFulfilled: this.hideEditModal })),
                React.createElement(Modal, { isOpen: this.state.showDeleteModal, onClose: this.hideDeleteModal, type: ModalType.Warning, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", null,
                        React.createElement("p", null, translate('/Admin/RecipeGroups/DeleteModal', this.state.deleteItem.name))),
                    React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: this.deleteRow, onCancel: this.hideDeleteModal, onStatusWasFulfilled: this.hideDeleteModal })),
                React.createElement(Modal, { title: translate('/Admin/Classifications/AddClassification'), isOpen: this.state.showAddModal, onClose: this.hideAddModal, reserveSpaceForCloseButton: true, fadeDesign: true },
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/Name'), className: styles.modalInput, ref: function (e) { return (_this.inputName = e); }, autoFocus: true })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/NameEn'), className: styles.modalInput, ref: function (e) { return (_this.inputNameEn = e); } })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/ClassName'), className: styles.modalInput, ref: function (e) { return (_this.inputClassName = e); } })),
                    React.createElement("div", { className: styles.modalInput },
                        React.createElement(Input, { label: translate('/Admin/Classifications/Code'), className: styles.modalInput, ref: function (e) { return (_this.inputClassCode = e); } })),
                    React.createElement(Dialog, { type: DialogType.SaveAbort, onConfirm: function () {
                            return _this.addRow(_this.inputName.element.value, _this.inputNameEn.element.value, _this.inputClassName.element.value, _this.inputClassCode.element.value);
                        }, onCancel: this.hideAddModal, onStatusWasFulfilled: this.hideAddModal })))));
    };
    return RecipeGroupPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(RecipeGroupPage);
