var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import AccountBox from '../AccountBox';
import Button from 'Shared/Button';
import Overlay from 'Shared/Overlay';
import { setMyMenigoDropdownOpen, setMyMenigoDropdownClose } from './action-creators';
import * as styles from './base.scss';
import * as button from './../../../../styles/objects/buttons.scss';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import { logout } from '../../../Account/action-creators';
var MyMenigoButton = /** @class */ (function (_super) {
    __extends(MyMenigoButton, _super);
    function MyMenigoButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggle = function () {
            if (!_this.props.isOpen) {
                _this.props.setToOpenMyMenigoDropdown();
            }
            else {
                _this.props.setToCloseMyMenigoDropdown();
            }
        };
        return _this;
    }
    MyMenigoButton.prototype.render = function () {
        var _this = this;
        var _a;
        return this.props.isSupplierSalesman ? (React.createElement(FeedbackButton, { type: "button", appearance: "secondary", onClickWithFeedback: function (e, activate) { return activate(_this.props.logout()); }, className: "ui-test-logout-button" }, translate('/Account/MyPages/Logout'))) : (this.props.isLoggedIn ?
            React.createElement("a", { href: this.props.headerViewModel.myMenigoItems[0].url, className: classnames(button.base, styles.myMenigo) }, translate('/MyMenigo/Header/MyMenigo'))
            :
                React.createElement("div", { className: styles.loginWrapper },
                    React.createElement(Overlay, { enabled: this.props.isOpen, onClose: this.toggle },
                        React.createElement("div", { className: classnames(styles.myMenigoMenuHolder, this.props.className, (_a = {},
                                _a[styles.tertiary] = this.props.appearance === 'tertiary',
                                _a[styles.light] = this.props.appearance === 'light',
                                _a)) },
                            React.createElement(Button, { onClick: this.toggle, className: 'ui-test-user-nav-button', appearance: "clear" }, translate('/Account/Login/Header'))),
                        this.props.isOpen && (React.createElement("div", { className: styles.parentDropdown },
                            React.createElement(AccountBox, { close: this.props.setToCloseMyMenigoDropdown, isCloseButton: false }))))));
    };
    return MyMenigoButton;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    headerViewModel: state.appShellData.header,
    isOpen: state.myMenigoBox.isOpen,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); }, function (dispatch) { return ({
    setToOpenMyMenigoDropdown: function () { return dispatch(setMyMenigoDropdownOpen()); },
    setToCloseMyMenigoDropdown: function () { return dispatch(setMyMenigoDropdownClose()); },
    logout: function () { return dispatch(logout()); },
}); })(MyMenigoButton);
