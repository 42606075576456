var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import { MEDIUM } from 'Shared/breakpoints';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import DropDown from 'Shared/DropDown';
import classnames from 'classnames';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { post, postJson } from 'Shared/server';
import moment from 'moment';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import Icon from 'Shared/Icon';
import { pathCombine } from 'Shared/url';
import { DayPickerSingleDateController } from 'react-dates';
import * as styles from './base.scss';
import Main, { Section } from 'Shared/PageLayout';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { replaceState } from 'Shared/history';
var Exhibitions = /** @class */ (function (_super) {
    __extends(Exhibitions, _super);
    function Exhibitions(props) {
        var _this = _super.call(this, props) || this;
        _this.hideDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: false });
        };
        _this.onFocusChange = function (args) {
            _this.setState({ isDayPickerFocused: !!args.focused });
        };
        _this.hideDateModal = function () {
            _this.setState({ isDateModalOpen: false });
        };
        _this.state = {
            exhibitions: _this.props.exhibitions,
            isDateModalOpen: false,
            isDeleteModalOpen: false,
            currentField: 'startDate',
            currentExhibition: _this.getDefaultExhibition(),
            isDayPickerFocused: false,
        };
        return _this;
    }
    Exhibitions.prototype.getDefaultExhibition = function () {
        return this.props.exhibitions && this.props.exhibitions.length > 0 ? this.props.exhibitions[0] : null;
    };
    Exhibitions.prototype.addExhibition = function () {
        var _this = this;
        postJson(pathCombine(location.pathname, '/add')).then(function (newExhibition) {
            var exhibitions = __spread(_this.state.exhibitions, [newExhibition]);
            _this.setState({ exhibitions: exhibitions });
            replaceState(location.pathname);
        });
    };
    Exhibitions.prototype.saveExhibition = function (exhibition, index) {
        var _this = this;
        return post(pathCombine(location.pathname, '/save'), { exhibition: exhibition })
            .then(function (res) {
            _this.props.setFeedback({
                text: translate('/Admin/Exhibition/SaveSuccess', exhibition.name),
                type: 'success',
            });
            var exhibitions = __spread(_this.state.exhibitions);
            exhibitions[index].changed = false;
            _this.setState({ exhibitions: exhibitions });
            replaceState(location.pathname);
        })
            .catch(function () {
            _this.props.setFeedback({
                text: translate('/Admin/Exhibition/SaveError', exhibition.name),
                type: 'error',
            });
            return Promise.reject(null);
        });
    };
    Exhibitions.prototype.showDeleteModal = function (exhibition) {
        this.setState({
            isDeleteModalOpen: true,
            currentExhibition: exhibition,
        });
    };
    Exhibitions.prototype.deleteExhibition = function (exhibition) {
        var _this = this;
        return post(pathCombine(location.pathname, '/delete'), { id: exhibition.id })
            .then(function (res) {
            var exhibitions = _this.state.exhibitions.filter(function (e) { return e.id !== exhibition.id; });
            _this.setState({ exhibitions: exhibitions });
            replaceState(location.pathname);
        })
            .catch(function (e) { return Promise.reject(null); });
    };
    Exhibitions.prototype.onDateChangeClick = function (day, exhibition) {
        var _this = this;
        var exhibitions = __spread(this.state.exhibitions).map(function (e) {
            if (e.id === exhibition.id) {
                e[_this.state.currentField] = day.format('YYYY-MM-DD');
                e.changed = true;
            }
            return e;
        });
        this.setState({ exhibitions: exhibitions, isDateModalOpen: false });
    };
    Exhibitions.prototype.onDateChange = function (e, datefield, index) {
        var exhibitions = __spread(this.state.exhibitions);
        exhibitions[index][datefield] = e.target.value;
        exhibitions[index].changed = true;
        this.setState({ exhibitions: exhibitions });
    };
    Exhibitions.prototype.showDateModal = function (datefield, exhibition) {
        this.setState({
            isDateModalOpen: true,
            currentField: datefield,
            currentExhibition: exhibition,
        });
    };
    Exhibitions.prototype.changeStoreType = function (field, id, value, index) {
        var exhibitions = __spread(this.state.exhibitions);
        exhibitions[index].storeId = value;
        exhibitions[index].changed = true;
        this.setState({ exhibitions: exhibitions });
    };
    Exhibitions.prototype.changeName = function (e, exhibition, index) {
        var exhibitions = __spread(this.state.exhibitions);
        exhibitions[index].name = e.target.value;
        exhibitions[index].changed = true;
        this.setState({ exhibitions: exhibitions });
    };
    Exhibitions.prototype.renderHeader = function () {
        return (React.createElement("div", { className: styles.headers },
            React.createElement("div", { className: styles.col1 }, translate('/Admin/Exhibition/Header/Name')),
            React.createElement("div", { className: styles.col2 }, translate('/Admin/Exhibition/Header/Warehouse')),
            React.createElement("div", { className: styles.col3 }, translate('/Admin/Exhibition/Header/StartDate')),
            React.createElement("div", { className: styles.col4 }, translate('/Admin/Exhibition/Header/EndDate')),
            React.createElement("div", { className: styles.col5 }, translate('/Admin/Exhibition/Header/FirstDeliveryDate')),
            React.createElement("div", { className: styles.col6 }, translate('/Admin/Exhibition/Header/LastDeliveryDate')),
            React.createElement("div", { className: styles.col7 }),
            React.createElement("div", { className: styles.col8 })));
    };
    Exhibitions.prototype.getDateOfCurrentDateField = function () {
        var date = moment(this.state.currentExhibition[this.state.currentField]);
        return date.isValid() ? date : moment();
    };
    Exhibitions.prototype.render = function () {
        var _this = this;
        var options = this.props.storeIdCodes.map(function (option) {
            return { text: option, value: option };
        });
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", null, translate('/Admin/Exhibition/Exhibitions'))),
            React.createElement(Section, null,
                React.createElement(Section, null,
                    React.createElement("div", { className: !this.props.isMobile ? styles.exhibitionsWrapper : styles.exhibitionsWrapperMobile },
                        React.createElement("div", null,
                            React.createElement(Button, { onClick: function () { return _this.addExhibition(); }, appearance: 'secondary' }, translate('/Admin/Exhibition/AddNew'))),
                        React.createElement("div", null,
                            !this.props.isMobile && this.renderHeader(),
                            this.state.exhibitions.map(function (exhibition, index) {
                                var _a;
                                return (React.createElement("div", { key: "exhibition" + exhibition.id, className: classnames(styles.datarow, (_a = {},
                                        _a[styles.mobileRowWrapper] = _this.props.isMobile,
                                        _a[styles.changed] = exhibition.changed,
                                        _a)) },
                                    _this.props.isMobile && _this.renderHeader(),
                                    React.createElement("div", { className: classnames(styles.datarow, exhibition.changed && styles.changed) },
                                        React.createElement("div", { className: styles.col1 },
                                            React.createElement("input", { value: exhibition.name, onChange: function (e) { return _this.changeName(e, exhibition, index); } })),
                                        React.createElement("div", { className: styles.col2 },
                                            React.createElement(DropDown, { className: '', options: options, value: exhibition.storeId, onChange: function (val) { return _this.changeStoreType('storeId', exhibition.id, val, index); } })),
                                        React.createElement("div", { className: styles.col3 },
                                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'startDate', index); }, value: exhibition.startDate }),
                                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('startDate', exhibition); } },
                                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                                        React.createElement("div", { className: styles.col4 },
                                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'endDate', index); }, value: exhibition.endDate }),
                                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('endDate', exhibition); } },
                                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                                        React.createElement("div", { className: styles.col5 },
                                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'firstDelivery', index); }, value: exhibition.firstDelivery }),
                                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('firstDelivery', exhibition); } },
                                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                                        React.createElement("div", { className: styles.col6 },
                                            React.createElement("input", { onChange: function (e) { return _this.onDateChange(e, 'lastDelivery', index); }, value: exhibition.lastDelivery }),
                                            React.createElement("div", { className: styles.calendar, onClick: function () { return _this.showDateModal('lastDelivery', exhibition); } },
                                                React.createElement(Icon, { appearance: "sans", name: "calendar" }))),
                                        React.createElement("div", { className: styles.col7 },
                                            React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(_this.saveExhibition(exhibition, index)); } }, translate('/Admin/Exhibition/Save'))),
                                        React.createElement("div", { className: styles.col8 },
                                            React.createElement(Button, { onClick: function () { return _this.showDeleteModal(exhibition); }, appearance: "clear" },
                                                React.createElement(Icon, { appearance: "sans", name: "remove" }))))));
                            })),
                        this.state.currentExhibition && (React.createElement(React.Fragment, null,
                            React.createElement(Modal, { isOpen: this.state.isDeleteModalOpen, onClose: this.hideDeleteModal, title: translate('/Admin/Exhibition/DeleteConfirmHeader') },
                                React.createElement("p", null, translate('/Admin/Exhibition/DeleteConfirm', this.state.currentExhibition.name)),
                                React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return _this.deleteExhibition(_this.state.currentExhibition); }, onCancel: this.hideDeleteModal, onStatusWasFulfilled: this.hideDeleteModal })),
                            React.createElement(Modal, { title: translate('/Admin/Exhibition/ChooseDate'), isOpen: this.state.isDateModalOpen, onClose: this.hideDateModal },
                                React.createElement(DayPickerSingleDateController, { date: this.getDateOfCurrentDateField(), onDateChange: function (day) { return _this.onDateChangeClick(day, _this.state.currentExhibition); }, onFocusChange: this.onFocusChange, focused: this.state.isDayPickerFocused, enableOutsideDays: false })))))))));
    };
    return Exhibitions;
}(React.Component));
export default connect(function (state) { return ({
    isMobile: state.currentBreakpoint < MEDIUM,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(Exhibitions);
