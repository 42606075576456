var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import { parseUrl } from 'Shared/url';
import Main from 'Shared/PageLayout';
import ProductListing from 'Shared/ProductListing';
import ContentCardRow from 'Shared/ContentCardRow';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import CategoryList from './CategoryList';
import ContentList from './ContentList';
import CompletionList from './CompletionList';
import SearchPageHeader from './Components/SearchPageHeader';
import { SMALL } from 'Shared/breakpoints';
import { updateODPTrackingSearchPage } from '../../../TrackingInformation';
var SearchPage = /** @class */ (function (_super) {
    __extends(SearchPage, _super);
    function SearchPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchPage.prototype.focusDiv = function () {
        this.wrapper.focus();
    };
    SearchPage.prototype.componentDidUpdate = function () {
        updateODPTrackingSearchPage(this.props.searchPhrase);
    };
    SearchPage.prototype.componentDidMount = function () {
        updateODPTrackingSearchPage(this.props.searchPhrase);
        this.focusDiv();
    };
    SearchPage.prototype.facetClicked = function () {
        this.focusDiv();
    };
    SearchPage.prototype.getQueryParams = function () {
        var currentUrl = parseUrl(window.location);
        if (currentUrl.query && currentUrl.query['q']) {
            return { q: currentUrl.query['q'] };
        }
        else {
            return {};
        }
    };
    SearchPage.prototype.visibleProductCount = function () {
        // TODO - a bad fix - maybe refactor this
        var current = this.props.countPerPage;
        if (this.props.products.products.length / 2 > current) {
            return this.props.products.products.length;
        }
        return current;
    };
    SearchPage.prototype.mayHaveAndFilter = function () {
        /** Since filter is applied optimistically we want to rule out the possibility
         * that it could be set by waiting for page request to be resolved */
        return this.props.currentPageIsLoading || this.props.products.facets.some(function (f) { return f.hasAndFilter; });
    };
    SearchPage.prototype.render = function () {
        var _this = this;
        var _a;
        var searchSuggestions = this.props.searchSuggestions || [];
        var hasContent = this.props.categories.length > 0 || this.props.content.length > 0 || this.props.completions.length > 0;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement("div", { tabIndex: 0, ref: function (el) { return (_this.wrapper = el); } },
                React.createElement("div", { className: this.props.searchPhrase && hasContent && styles.bottomBorder },
                    React.createElement(SearchPageHeader, { products: this.props.products, searchSuggestions: this.props.searchSuggestions, hasContent: hasContent, searchPhrase: this.props.searchPhrase, page: this.props.page })),
                this.props.products.totalProductCount === 0 && !this.mayHaveAndFilter() ? (React.createElement("div", null, !!this.props.searchPhrase && !!hasContent && (React.createElement("div", { className: classnames(styles.lists, styles.noProducts) },
                    this.props.categories.length > 0 && (React.createElement(CategoryList, { categories: this.props.categories, searchPhrase: this.props.searchPhrase })),
                    this.props.searchPhrase.length > 0 && (React.createElement(ContentList, { content: this.props.content, searchPhrase: this.props.searchPhrase })),
                    this.props.completions.length > 0 && (React.createElement(CompletionList, { completions: this.props.completions, searchPhrase: this.props.searchPhrase })))))) : (React.createElement(ProductListing, __assign({ className: classnames((_a = {},
                        _a[styles.hasDidYouMean] = searchSuggestions.length > 0,
                        _a)), facetClicked: function () { return _this.facetClicked(); } }, this.props, { queryParams: this.getQueryParams(), disableHeaderRow: true, showLayoutOptions: this.props.currentBreakpoint > SMALL, searchTerm: this.props.searchPhrase }))),
                React.createElement(ContentCardRow, { cards: this.props.cards, title: translate('/Product/LatestNews') }))));
    };
    return SearchPage;
}(React.Component));
export default connect(function (state) { return ({
    currentPageIsLoading: state.currentPage.isLoading,
    currentUser: state.currentUser,
}); })(SearchPage);
