import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
var MenuItem = function (_a) {
    var _b;
    var displayName = _a.displayName, url = _a.url, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, isActive = _a.isActive;
    return (React.createElement("li", { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, className: classnames((_b = {}, _b[styles.active] = isActive, _b)) },
        React.createElement("a", { href: url }, displayName)));
};
export default MenuItem;
