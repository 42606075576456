var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Price from './index';
import * as styles from './product-price.scss';
function CampaingPrice(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Price, __assign({ className: styles.campaignPrice }, props.variant.price, { microData: true, size: "huge", id: props.variant.code })),
        React.createElement(Price, { className: styles.strikethrough, current: props.variant.price.original, priceUnit: props.variant.price.priceUnit, currencySymbol: props.variant.price.currencySymbol, size: "large", microData: false })));
}
function RegularPrice(props) {
    return React.createElement(Price, __assign({}, props.variant.price, { size: "large", microData: true, id: props.variant.code }));
}
export function RenderPrice(props) {
    var productIsInCampaign = props.productIsInCampaign, variant = props.variant;
    var isCampaign = productIsInCampaign && variant.price && variant.price.current < variant.price.original;
    return isCampaign ? React.createElement(CampaingPrice, { variant: variant }) : React.createElement(RegularPrice, { variant: variant });
}
export default function ProductPrice(props) {
    var selectedVariant = props.selectedVariant, productIsInCampaign = props.productIsInCampaign, isWeightProduct = props.isWeightProduct, allVariants = props.allVariants;
    return props.hide ? null : (React.createElement(React.Fragment, null, isWeightProduct ? (allVariants.map(function (v) { return (React.createElement("div", { key: v.code, className: styles.priceItem },
        React.createElement(RenderPrice, { variant: v, productIsInCampaign: productIsInCampaign }))); })) : (React.createElement(RenderPrice, { variant: selectedVariant, productIsInCampaign: productIsInCampaign }))));
}
