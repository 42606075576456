var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import OrderHeader from '../OrderHeader';
import classnames from 'classnames';
import OrderDetail from '../OrderHeader/details';
import { translate } from 'Shared/translate';
import Price from 'Shared/Price';
import { Checkbox } from 'Shared/Fields';
import { price as loadingPrice } from '../../../loading.scss';
var DeliveryGroupOrder = /** @class */ (function (_super) {
    __extends(DeliveryGroupOrder, _super);
    function DeliveryGroupOrder(props) {
        var _this = _super.call(this, props) || this;
        _this.onPressCollapse = function () { return _this.setState({ isPressed: !_this.state.isPressed }); };
        _this.state = { isPressed: false };
        return _this;
    }
    DeliveryGroupOrder.prototype.calculateBackupSummary = function (items) {
        var backupSummary = 0;
        items.map(function (orderDetail) {
            backupSummary += orderDetail.price * orderDetail.quantity;
            return backupSummary;
        });
        return Math.round(backupSummary * 100) / 100;
    };
    DeliveryGroupOrder.prototype.render = function () {
        var _this = this;
        var totalOrderPrice = this.props.filterView === 'PerDelivery'
            ? this.calculateBackupSummary(this.props.deliveryItem.orderDetailsItems)
            : this.props.deliveryItem.orderHeaderItems
                .map(function (item) {
                return item.orderSummary ? item.orderSummary : _this.calculateBackupSummary(item.orderDetails);
            })
                .reduce(function (a, b) {
                return a + b;
            });
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.deliveryGroupContainer, onClick: this.onPressCollapse },
                React.createElement("div", { className: styles.deliveryGroupItem },
                    React.createElement("div", { className: styles.selectBox },
                        React.createElement(Checkbox, { checked: this.props.checked, onChange: function () { return _this.props.onChange(); } })),
                    React.createElement("div", { className: styles.collapse },
                        React.createElement(Button, { appearance: ['icon'], className: styles.collapseBtn },
                            React.createElement("span", null,
                                React.createElement(Icon, { name: this.state.isPressed ? 'arrow-down' : 'arrow-right' })))),
                    React.createElement("div", { className: styles.wrap },
                        React.createElement("div", null,
                            React.createElement("span", null,
                                translate('/MyMenigo/DeliveryHeader/DeliveryDate'),
                                ":"),
                            React.createElement("span", null, this.props.deliveryItem.deliveryDate)),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                translate('/MyMenigo/DeliveryHeader/Departure'),
                                ":"),
                            React.createElement("span", null, this.props.deliveryItem.departure)),
                        React.createElement("div", null, !!this.props.deliveryItem.stopTime && (React.createElement(React.Fragment, null,
                            React.createElement("span", null,
                                translate('/MyMenigo/DeliveryHeader/Deadline'),
                                ":"),
                            React.createElement("span", null, this.props.deliveryItem.stopTime)))),
                        React.createElement("div", { className: styles.totalShipmentWrap },
                            React.createElement("span", null,
                                translate('/MyMenigo/DeliveryHeader/TotalShipment'),
                                ":"),
                            React.createElement(Price, { className: loadingPrice, current: totalOrderPrice, size: "large", currencySymbol: translate('/Price/CurrencySymbol') }))))),
            this.state.isPressed && (React.createElement("div", { className: classnames(styles.detailsContent, styles.show) }, this.props.filterView === 'PerOrder' ? (React.createElement(OrderHeader, __assign({ orderHeaders: this.props.deliveryItem.orderHeaderItems, isSelectable: false, isOrderItemsSelectable: true }, this.props))) : (React.createElement(OrderDetail, __assign({ isExtensive: true, isItemShown: this.state.isPressed, OrderDetailsList: this.props.deliveryItem.orderDetailsItems.length > 0
                    ? this.props.deliveryItem.orderDetailsItems
                    : null, TotalOrderPrice: totalOrderPrice }, this.props)))))));
    };
    return DeliveryGroupOrder;
}(React.Component));
export default DeliveryGroupOrder;
