var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback, memo } from 'react';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import AddToListDialog from './AddToListDialog';
export function isInList(listThatActuallyIsObject) {
    return listThatActuallyIsObject && Object.keys(listThatActuallyIsObject).length > 0;
}
var AddRemoveList = memo(function (props) {
    var _a;
    var _b = __read(useState(false), 2), isAddToListOpen = _b[0], setAddToListOpen = _b[1];
    var handleOpenAddToList = useCallback(function () { return setAddToListOpen(true); }, []);
    var handleCloseAddToList = useCallback(function () { return setAddToListOpen(false); }, []);
    var properties = {
        favorites: {
            isDisabled: props.disableFavoriteList || !!props.product.isCustom || props.product.isUtf,
            text: translate('/Product/Favorite'),
            uiTestClass: 'ui-test-add-remove-favorites',
            isActive: isInList(props.product.favoritesList),
            iconName: 'heart-outline',
            lists: props.product.favoritesList,
        },
        pantries: {
            isDisabled: props.disablePantry || props.product.isUtf,
            text: translate('/Product/Pantry'),
            uiTestClass: 'ui-test-add-remove-pantries',
            isActive: isInList(props.product.pantriesList),
            iconName: 'list-bulleted',
            lists: props.product.pantriesList,
        },
    };
    var currentProperties = properties[props.type];
    return currentProperties.isDisabled ? null : (React.createElement("div", { className: styles.listAdd, title: currentProperties.text },
        React.createElement("div", { className: classnames(props.className, styles.small, currentProperties.uiTestClass), "data-code": props.code, onClick: handleOpenAddToList },
            React.createElement("div", { className: classnames(styles.circle, (_a = {},
                    _a[styles.activeIcon] = currentProperties.isActive,
                    _a[props.className] = currentProperties.isActive,
                    _a)) },
                React.createElement(Icon, { appearance: "sans", name: currentProperties.iconName }))),
        React.createElement(AddToListDialog, { isOpen: isAddToListOpen, close: handleCloseAddToList, code: props.code, type: props.type, listsContainingProduct: currentProperties.lists })));
});
export default connect(function (state) { return ({
    disablePantry: state.appShellData.disablePantry,
    disableFavoriteList: state.appShellData.disableFavoriteList,
}); })(AddRemoveList);
