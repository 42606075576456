import localForage from 'localforage';
import { loadPage } from 'SiteLayout/CurrentPage/action-creators';
import { updateAppShellData } from './action-creators';
import { parseUrl } from 'Shared/url';
import { getJson } from 'Shared/server';
import * as crossWindowEvents from 'Shared/cross-window-events';
import { replaceState } from 'Shared/history';
var requestTimeout = 100;
var storage = localForage.createInstance({
    storeName: 'appshell',
});
export var appShellDataUrl = '/appshell/data';
export function nuke() {
    // The catch is because it always fails in private browsing
    return storage.clear().catch(function () { return null; });
}
export function refreshCachedData(store) {
    refreshAppShellData(store);
    persistCartAndWishListChanges(store);
    return refreshCurrentPageData(store);
}
// Note! This shouldn't be used by the main application since the app shell data
// is available in the Redux state.
export function getAppShellData() {
    return Promise.race([timeout(), storage.getItem(appShellDataUrl)]).then(function (data) {
        if (data) {
            return data;
        }
        else {
            return getJson(appShellDataUrl).then(function (data) {
                saveAppShellData(data);
                return data;
            });
        }
    });
}
export function saveAppShellData(data) {
    // The catch is because it always fails in private browsing
    return Promise.race([timeout(), storage.setItem(appShellDataUrl, data).catch(function () { return null; })]);
}
function refreshAppShellData(store) {
    console.info('Reloading app shell since rendered from service worker...');
    store.dispatch(updateAppShellData());
}
function refreshCurrentPageData(store) {
    console.info('Reloading current page since rendered from service worker...');
    var currentUrl = parseUrl(window.location);
    return store.dispatch(loadPage(currentUrl));
}
// The cart is one of the things in the app shell data that the user can
// change and we want to persist those changes. Because if the user reloads
// without network connection to refresh the app shell data, the cart should
// still be correct.
function persistCartAndWishListChanges(store) {
    var currentCart = store.getState().cart;
    store.subscribe(function () {
        var newCart = store.getState().cart;
        if (currentCart !== newCart) {
            saveAppShellDataWithNewCartAndWishList(newCart);
            currentCart = newCart;
        }
    });
}
var saveTimer;
function saveAppShellDataWithNewCartAndWishList(cart) {
    clearTimeout(saveTimer);
    saveTimer = setTimeout(function () {
        storage.getItem(appShellDataUrl).then(function (data) {
            console.info('Persisting change to app shell data');
            data.cart = cart;
            saveAppShellData(data);
        });
    }, 200);
}
function timeout() {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve(null);
        }, requestTimeout);
    });
}
crossWindowEvents.on('update-appshelldata', function (store) {
    var isLoggedIn = store.getState().currentUser.isLoggedIn;
    store.dispatch(updateAppShellData(false)).then(function () {
        if (isLoggedIn !== store.getState().currentUser.isLoggedIn) {
            replaceState();
        }
    });
});
export function pageHasAppShell(componentName) {
    var pagesWithoutAppShell = [
        'DeliveryApplication',
        'PantryReportPage',
        'SupplierLoginPage',
        'AccountSettingsPage',
        'PunchOutLoginPage',
        'PunchOutTakeoverPage',
    ];
    return !pagesWithoutAppShell.some(function (pageName) { return componentName.indexOf(pageName) >= 0; });
}
