var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styles from "./../../base.scss";
var ToolTip = function (_a) {
    var text = _a.text, header = _a.header, direction = _a.direction, persistent = _a.persistent, children = _a.children;
    var _b = __read(useState(false), 2), active = _b[0], setActive = _b[1];
    var _c = __read(useState(false), 2), alwaysActive = _c[0], setAlwaysActive = _c[1];
    var ref = useRef(null);
    var handleClick = function () {
        !!persistent && setAlwaysActive(true);
    };
    var handleClickOutside = function (e) {
        hideToolTip();
        setAlwaysActive(!!persistent);
    };
    var showToolTip = function () {
        setActive(true);
    };
    var hideToolTip = function () {
        setActive(false);
    };
    var getToolTipDirection = function (direction) {
        if (direction === "top")
            return styles.top;
        if (direction === "left")
            return styles.left;
        if (direction === "bottom")
            return styles.bottom;
        if (direction === "right")
            return styles.right;
    };
    useEffect(function () {
        setAlwaysActive(persistent);
        var listener = function (event) {
            var el = ref.current;
            if (!el || el.contains(event.target)) {
                return;
            }
            handleClickOutside(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handleClickOutside]);
    return (React.createElement("div", { ref: ref, onMouseEnter: showToolTip, onMouseLeave: hideToolTip, onClick: handleClick, className: styles.TooltipWrapper },
        (active || alwaysActive) && (React.createElement("div", { className: classnames(styles.Tooltip, (getToolTipDirection(direction) || styles.top)) },
            !!header && (React.createElement("strong", null, header)),
            text)),
        children));
};
export default ToolTip;
