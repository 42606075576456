import React from "react";
import { Part } from "Shared/PageLayout";
import * as styles from '../../base.scss';
import DetailedProductRow from "./DetailedProductRow";
var DetailedProductList = function (_a) {
    var productItems = _a.productItems, listType = _a.listType;
    return (React.createElement(Part, { className: styles.Products__List }, !!productItems && productItems.length
        ? productItems.map(function (item, index) {
            return (React.createElement(DetailedProductRow, { key: "row_" + index + item.orderNumber + item.articleNumber, listType: listType, item: item }));
        }) : React.createElement(React.Fragment, null)));
};
export default DetailedProductList;
