var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { translate } from 'Shared/translate';
import { removeList, removeListRequest } from 'Shared/ListItem/action-creators';
import { timeout } from 'Shared/timeout';
import * as styles from './base.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
function RemoveList(props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var onDialogSucessfull = useCallback(function () {
        handleClose();
        // wait for modal to close
        timeout(200).then(function () { return props.removeList(); });
    }, []);
    var handleRemoveRequest = useCallback(function () { return props.removeListRequest(); }, []);
    return (React.createElement("div", { className: props.passedClassName, onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(Button, { appearance: props.buttonAppearance, onClick: function () { return setOpen(true); } },
            React.createElement(MyMenigoIcon, { name: "trash" })),
        React.createElement(Modal, { isOpen: isOpen, onClose: handleClose, title: translate('/Account/ProductList/ConfirmRemoveList'), type: ModalType.Warning, reserveSpaceForCloseButton: true },
            React.createElement("p", { className: styles.modalText }, props.displayName),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleRemoveRequest, onCancel: handleClose, onStatusWasFulfilled: onDialogSucessfull }))));
}
export default connect(null, function (dispatch, _a) {
    var id = _a.id;
    return ({
        removeList: function () { return dispatch(removeList(id)); },
        removeListRequest: function () { return dispatch(removeListRequest(id)); },
    });
})(RemoveList);
