import { getJson } from 'Shared/server';
import { setPhrases } from 'Shared/translate';
import { appShellDataUrl, saveAppShellData } from './index';
import { setKnownUrls } from 'Shared/known-urls';
import * as crossWindowEvents from 'Shared/cross-window-events';
export var UPDATE_APP_SHELL_DATA = 'UPDATE_APP_SHELL_DATA';
export function updateAppShellData(isCurrentBrowserTab) {
    if (isCurrentBrowserTab === void 0) { isCurrentBrowserTab = true; }
    if (isCurrentBrowserTab) {
        crossWindowEvents.emit('update-appshelldata');
    }
    return function (dispatch) {
        return getJson(appShellDataUrl).then(function (appShellData) {
            if (appShellData.languagePhrases) {
                setPhrases(appShellData.languagePhrases);
            }
            if (appShellData.siteSettings) {
                setKnownUrls(appShellData.siteSettings);
            }
            dispatch({
                type: UPDATE_APP_SHELL_DATA,
                appShellData: appShellData,
            });
            return saveAppShellData(appShellData);
        });
    };
}
