var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import * as styles from './base.scss';
var Grade = /** @class */ (function (_super) {
    __extends(Grade, _super);
    function Grade() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Grade.prototype.onClick = function (grade) {
        if (this.canVote) {
            return this.props.onClick(grade);
        }
    };
    Grade.prototype.render = function () {
        var _this = this;
        this.canVote = !!this.props.onClick && (!this.props.uservoted || this.props.uservoted <= 0);
        var arr = new Array(5);
        for (var i = 1; i <= 5; i++) {
            arr[i - 1] = this.props.grade >= i;
        }
        return (React.createElement("div", { className: styles.scorePoints },
            arr.map(function (fill, i) { return (React.createElement("span", { key: i, className: classnames(fill ? styles.fill : styles.empty, _this.canVote && styles.cursor), onClick: function (e) { return _this.onClick(i + 1); } }, "\u25CF")); }),
            React.createElement("br", null),
            React.createElement("span", { className: styles.votes },
                "(",
                this.props.votes === 1
                    ? translate('/Recipe/Vote', this.props.votes)
                    : translate('/Recipe/Votes', this.props.votes),
                !!this.props.uservoted && ", " + translate('/Recipe/UserVote', this.props.uservoted),
                ")")));
    };
    return Grade;
}(React.Component));
export default Grade;
