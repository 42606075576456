import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var EditorialImageBlock = function (props) {
    var imageUrl = !!props.block.image && propertyValueFor(props.block.image);
    return (!!imageUrl &&
        React.createElement("div", { className: styles.base, style: { backgroundImage: 'url(' + imageUrl + ')' } },
            React.createElement("img", { src: imageUrl })));
};
export default EditorialImageBlock;
