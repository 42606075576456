import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import LinkWithAlcoholWarning from 'Shared/LinkWithAlcoholWarning';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import * as categoryStyles from '../../Category/Commerce/base.scss';
import ProductList from './Product/ProductList';
var LargeContentCard = function (_a) {
    var className = _a.className, ticket = _a.ticket, targetLink = _a.targetLink, title = _a.title, text = _a.text, imageUrl = _a.imageUrl, appearance = _a.appearance, containsAlcoholInfo = _a.containsAlcoholInfo, products = _a.products, hasProducts = _a.hasProducts, onClick = _a.onClick;
    return (React.createElement("div", { className: classnames(styles.child, className, styles[appearance], hasProducts ? styles.large_with_products : styles.large_with_image) },
        React.createElement("div", { className: styles.child__inner },
            React.createElement("div", { className: styles.split },
                React.createElement("div", { className: styles.child__text },
                    React.createElement("h3", { className: classnames(categoryStyles.hero__title, styles.title) }, title),
                    React.createElement("div", { className: categoryStyles.hero__text, dangerouslySetInnerHTML: { __html: text } }),
                    !!targetLink.text && (React.createElement(LinkWithAlcoholWarning, { className: categoryStyles.hero__link, "data-ticket": ticket, targetLink: targetLink, hasAlcoholWarning: containsAlcoholInfo, onClick: onClick, wrap: true },
                        React.createElement("span", null, targetLink.text),
                        React.createElement(MyMenigoIcon, { name: "arrow" })))),
                hasProducts && (React.createElement("div", { className: styles.child__products },
                    React.createElement(ProductList, { products: products, isLargeContentCard: true }))),
                !hasProducts && (React.createElement("div", { className: styles.child__image },
                    React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + imageUrl + ")" } })))))));
};
export default LargeContentCard;
