var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Main, { Part } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import connect from 'Shared/connect';
import { beginStockTaking, beginStockTakingAll } from '../../../TakeStock/action-creators';
import PantryListItemRow from './PantryListItemRow';
import * as styles from './base.scss';
import classnames from 'classnames';
var doubleEv = false;
var PantryListPage = /** @class */ (function (_super) {
    __extends(PantryListPage, _super);
    function PantryListPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { values: [] };
        return _this;
    }
    PantryListPage.prototype.toggleCheck = function (checked, id) {
        // Event on both label and checkbox creates double event on when click on input
        setTimeout(function () {
            doubleEv = false;
        }, 0);
        if (doubleEv) {
            return;
        }
        doubleEv = true;
        var values = this.state.values.filter(function (v) { return v !== id; });
        if (!checked) {
            values.push(id);
        }
        this.setState({ values: values });
    };
    PantryListPage.prototype.beginTakeStock = function () {
        if (this.state.values.length > 0) {
            this.props.beginStockTaking(this.state.values);
        }
        else {
            this.props.beginStockTaking(this.props.items.map(function (product) { return product.id; }));
        }
        return new Promise(function () { return false; }); // never-resolving promise ensures the button keeps spinning
    };
    PantryListPage.prototype.beginTakeStockAll = function () {
        this.props.beginStockTakingAll();
        return new Promise(function () { return false; }); // never-resolving promise ensures the button keeps spinning
    };
    PantryListPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, null,
            React.createElement(Part, { appearance: "divider", className: styles.part }, translate('/App/Pantry/MyPantries')),
            React.createElement("div", null,
                React.createElement("table", { className: styles.list },
                    React.createElement("thead", { className: styles.rowHeader },
                        React.createElement("tr", null,
                            React.createElement("th", null, translate('/App/Pantry/Pantry')),
                            React.createElement("th", null, translate('/App/Pantry/Status')))),
                    React.createElement("tbody", null, this.props.items.map(function (e) { return (React.createElement(PantryListItemRow, __assign({ key: e.id, checked: _this.state.values.indexOf(e.id) >= 0, toggleCheck: function () { return _this.toggleCheck(_this.state.values.indexOf(e.id) >= 0, e.id); } }, e))); })))),
            React.createElement(Part, { appearance: "divider", className: classnames(styles.buttons, styles.part) }, this.props.customerType === 'ros' ? (React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(_this.beginTakeStock()); }, disabled: this.state.values.length <= 0 }, translate('/App/Pantry/StartStock'))) : (React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(_this.beginTakeStockAll()); } }, translate('/App/Pantry/WholeStock'))))));
    };
    return PantryListPage;
}(React.Component));
export default connect(function (state) { return ({
    pantries: state.takeStock.pantries,
    customerType: state.currentUser.customerType,
}); }, function (dispatch) { return ({
    beginStockTaking: function (pantryIds) {
        return dispatch(beginStockTaking(pantryIds));
    },
    beginStockTakingAll: function () {
        return dispatch(beginStockTakingAll());
    },
}); })(PantryListPage);
