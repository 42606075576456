var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { parseUrl } from 'Shared/url';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import Html from 'Shared/Partials/PropertyTypes/Html';
import * as styles from './base.scss';
var Description = /** @class */ (function (_super) {
    __extends(Description, _super);
    function Description(props) {
        var _this = _super.call(this, props) || this;
        var showAll = false;
        if (_this.props.expandFromUrl) {
            var currentUrl = parseUrl(window.location);
            if (currentUrl.query['showdescription']) {
                showAll = true;
            }
        }
        _this.state = {
            showAll: !!props.showAll ? props.showAll : showAll,
        };
        return _this;
    }
    Description.prototype.textIsTallerThanClipper = function () {
        return this.clipperElement.offsetHeight < this.textElement.offsetHeight;
    };
    Description.prototype.componentDidMount = function () {
        if (!this.textIsTallerThanClipper()) {
            this.setState({ showAll: true });
        }
    };
    Description.prototype.onTextClick = function () {
        if (!this.props.hasShowAllButton && !this.props.disableClick) {
            this.setState({ showAll: !this.state.showAll });
        }
    };
    Description.prototype.componentDidUpdate = function () {
        if (this.state.showAll && this.textIsTallerThanClipper()) {
            var maxHeight = this.textElement.offsetHeight + 'px';
            this.clipperElement.style.maxHeight = maxHeight;
        }
        else if (!this.state.showAll) {
            this.clipperElement.style.maxHeight = '';
        }
    };
    Description.prototype.render = function () {
        var _this = this;
        var _a;
        var fullText = this.props.lineCount === 'many';
        var html = this.props.text ? this.props.text || this.props.text.html : [];
        var str = '';
        if (fullText && typeof html === 'string') {
            var index = html.indexOf('.');
            if (index !== -1) {
                str = html.slice(index + 1);
                html = html.slice(0, index + 1);
            }
        }
        return (React.createElement("div", null,
            React.createElement("div", { ref: function (el) { return (_this.clipperElement = el); }, onClick: function () { return _this.onTextClick(); }, className: classnames(styles.base, (_a = {},
                    _a[styles.showAll] = this.state.showAll,
                    _a[styles.fewLines] = !this.state.showAll && this.props.lineCount === 'few',
                    _a[styles.hasShowAllButton] = this.props.hasShowAllButton,
                    _a[styles.centeredText] = this.props.centered,
                    _a)) },
                React.createElement("div", { ref: function (el) { return (_this.textElement = el); } }, typeof html === 'string' ? (fullText ? (React.createElement("div", { className: styles.hideH2 },
                    React.createElement("h2", null, html),
                    str)) : (html)) : (React.createElement(Html, { tagName: "div", html: html })))),
            this.props.hasShowAllButton && !this.state.showAll && (React.createElement(Button, { className: styles.button, onClick: !this.state.showAll ? function () { return _this.setState({ showAll: true }); } : null }, translate('/Product/ShowAll')))));
    };
    return Description;
}(React.Component));
export default Description;
