var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _this = this;
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import * as style from './item.scss';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import Image from 'Shared/ImageFallback';
import propertyValueFor from 'Shared/property-value-for';
import { translate } from 'Shared/translate';
import { parseUrl } from 'Shared/url';
import { formatPrice } from 'Shared/number-format';
import ProductMarks from 'Shared/ProductCampaignLabel';
import VariantAndQuantity from '../VariantAndQuantity';
import ProductVariantSelector from 'Shared/ProductComponents/ProductVariantSelector';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
import AddRemoveFromListOrLogin from 'Shared/Product/AddRemoveFromListOrLogin';
import ProductPriceDisplay from '../../ProductComponents/ProductPrice/ProductPriceDisplay';
import ProductLoginToBuy from 'Shared/ProductComponents/ProductLoginToBuy';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import moment from 'moment';
import DeliveryDatePicker, { DATE_FORMAT } from 'Shared/DeliveryDateSelection/DatePicker';
import Modal from 'Shared/Modal';
import DeliveryDateSelection from 'Shared/DeliveryDateSelection';
import { getDepartureDate } from 'Shared/DeliveryDateSelection/utility';
import Dialog, { DialogType } from 'Shared/Dialog';
import { getSelectedVariant } from 'Shared/Product/reducer';
import PriceSupplier from 'Shared/PriceSuppliersalesman';
import Checkbox from 'Shared/Fields/Checkbox';
import SubDeliveryRow from '../../../Checkout/FullCart/SubDeliveryRow';
import Icon from 'Shared/Icon';
import { getCartLineItemCountForVariant } from './../../../Cart/reducer';
import ProductAlternativesLink from 'Shared/ProductAlternativesLink/ProductAlternativesLink';
var ProductCardItem = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var isLoggedIn = _a.isLoggedIn, isSupplierSalesman = _a.isSupplierSalesman, product = _a.product, selectedVariant = _a.selectedVariant, url = _a.url, discontinuedOrBackordered = _a.discontinuedOrBackordered, updateCartQuantity = _a.updateCartQuantity, updateCartQuantityExt = _a.updateCartQuantityExt, quantityStep = _a.quantityStep, quantityInCart = _a.quantityInCart, quantityInCartCheckout = _a.quantityInCartCheckout, index = _a.index, isRestrictedCustomer = _a.isRestrictedCustomer, cardType = _a.cardType, searchTerm = _a.searchTerm, totalPrice = _a.totalPrice, removeCartItem = _a.removeCartItem, removeCheckoutItem = _a.removeCheckoutItem, isCart = _a.isCart, isCheckout = _a.isCheckout, item = _a.item, subDeliveries = _a.subDeliveries, headerViewModel = _a.headerViewModel, updateCartDeliveryDate = _a.updateCartDeliveryDate, connectedSelectedVariant = _a.connectedSelectedVariant, discountType = _a.discountType, discountValue = _a.discountValue, isProductSample = _a.isProductSample, toggleProductSample = _a.toggleProductSample, validDiscounts = _a.validDiscounts, isMobile = _a.isMobile, updateCartItemSubdeliveryQuantity = _a.updateCartItemSubdeliveryQuantity, removeCartItemSubdelivery = _a.removeCartItemSubdelivery, isItemPendingSubDelivery = _a.isItemPendingSubDelivery, hasAddedEmvInCart = _a.hasAddedEmvInCart, openPreviewInModel = _a.openPreviewInModel, isProductPage = _a.isProductPage;
    var productTitle = typeof product.content.displayName === 'string' ? product.content.displayName : propertyValueFor(product.content.displayName);
    var _s = __read(useState(0), 2), height = _s[0], setHeight = _s[1];
    var ref = useRef(null);
    var _t = __read(useState(false), 2), startCollapse = _t[0], setStartCollapse = _t[1];
    var _u = __read(useState(false), 2), isCollapsing = _u[0], setIsCollapsing = _u[1];
    var _v = __read(useState(false), 2), isDatePickerOpen = _v[0], setDatePickerOpen = _v[1];
    var _w = __read(useState(null), 2), selectedDate = _w[0], setSelectedDate = _w[1];
    useEffect(function () {
        setHeight(ref.current.clientHeight);
    });
    var formatTrademark = function (trademark) {
        if (parseUrl(trademark).path.match(/[^"'<>]+.(?:png|jpe?g|gif)\b/gi)) {
            return React.createElement("img", { src: trademark });
        }
        return trademark;
    };
    var removeProduct = function (removeCartItem) {
        setStartCollapse(true);
        setTimeout(function () {
            setIsCollapsing(true);
            setTimeout(function () {
                if (removeCheckoutItem) {
                    removeCheckoutItem(connectedSelectedVariant.code, null, connectedSelectedVariant.unitShortName);
                }
                if (removeCartItem) {
                    removeCartItem();
                }
            }, 500);
        }, 5);
    };
    var removeSubDeliveryRow = function (code, date, unitShortName) {
        var minSubDeliveryLength = 2;
        if (subDeliveries.length < minSubDeliveryLength) {
            setStartCollapse(true);
        }
        setTimeout(function () {
            if (subDeliveries.length < minSubDeliveryLength) {
                setIsCollapsing(true);
            }
            setTimeout(function () {
                if (removeCartItemSubdelivery) {
                    removeCartItemSubdelivery(code, date, unitShortName);
                }
            }, 500);
        }, 5);
    };
    var onDateSelected = function (date, isValid) {
        setSelectedDate(date.format(DATE_FORMAT));
    };
    var showDatePicker = useCallback(function () {
        setDatePickerOpen(true);
    }, []);
    var hideDatePicker = useCallback(function () {
        setDatePickerOpen(false);
    }, []);
    var datePicker = (React.createElement(Modal, { title: translate('/ProductListing/ChooseDividedDelivery'), onClose: hideDatePicker, isOpen: isDatePickerOpen },
        React.createElement(DeliveryDateSelection, { headerViewModel: headerViewModel, articleCode: propertyValueFor(product.content.code), articleName: product.title, datePickerNode: React.createElement(DeliveryDatePicker, { deliveryDays: headerViewModel && headerViewModel.deliveryDates, selectedDay: getDepartureDate(headerViewModel), onDateSelected: onDateSelected, availability: selectedVariant.availability }), dialogNode: React.createElement(Dialog, { type: DialogType.ChooseAbort, onConfirm: function () { return updateCartDeliveryDate(connectedSelectedVariant.code, selectedDate); }, onCancel: hideDatePicker, onStatusWasFulfilled: hideDatePicker }) })));
    var updateCartQuantityCheckout = useCallback(function (quantity) {
        return updateCartQuantityExt(quantity, connectedSelectedVariant);
    }, [updateCartQuantityExt, connectedSelectedVariant]);
    var isNotNaNAndAboveZero = function (n) { return !isNaN(n) && n > 0; };
    var renderVariants = product.isWeightProduct ? product.variants : [selectedVariant];
    var compPrices = isLoggedIn ? renderVariants.filter(function (_a) {
        var price = _a.price;
        return isNotNaNAndAboveZero(price ? price.comparison : 0);
    }).map(function (_a) {
        var price = _a.price;
        return formatPrice(price ? price.comparison : 0);
    }).sort().join('-') : '';
    var currency = isLoggedIn ? renderVariants.map(function (_a) {
        var price = _a.price;
        return price && !!price.currencySymbol ? price.currencySymbol : '';
    })[0] : '';
    var priceUnit = isLoggedIn ? renderVariants.map(function (_a) {
        var price = _a.price;
        return price && !!price.comparisonPriceUnit ? price.comparisonPriceUnit : '';
    })[0] : '';
    var hasStockInfo = selectedVariant.availability ? selectedVariant.availability.availabilityCode !== 'unavailable' : false;
    var round = function (num, precision) { return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision); };
    var hasPrimaryCampaign = product.primaryTags && product.primaryTags.length > 0;
    var hasSubDeliveries = isCheckout && subDeliveries.length > 0;
    var showProduceLogo = !!product.trademark && product.trademark === 'Färskvaruhallen Menigo';
    var fvh = require('!svg-inline-loader?classPrefix=true!../../../../styles/images/FVH-logotype.svg');
    var fvhWide = require('!svg-inline-loader?classPrefix=true!../../../../styles/images/FVH-logotype-wide.svg');
    return (React.createElement("div", { "data-list-type": cardType, ref: ref, style: { '--maxHeight': height + "px" }, className: classnames(style.card, (_b = {}, _b[style.cartItem] = isCart || isCheckout, _b), (_c = {}, _c[style.startCollapse] = startCollapse, _c), (_d = {}, _d[style.collapse] = isCollapsing, _d), (_e = {}, _e[style.isCheckoutPage] = isCheckout, _e), (_f = {}, _f[style.isCart] = isCart, _f), (_g = {}, _g[style.isMobile] = isMobile, _g), (_h = {}, _h[style.isProductPage] = isProductPage, _h)), itemType: "http://schema.org/Product", itemScope: true },
        React.createElement("div", { className: style.cardTop },
            React.createElement("div", { className: style.first },
                React.createElement("div", { className: classnames(style.above, (_j = {}, _j[style.hasPrimaryCampaign] = hasPrimaryCampaign, _j)) },
                    React.createElement("div", { className: style.aboveLeft },
                        React.createElement("div", { className: style.campaign },
                            React.createElement(ProductMarks, { productCampaigns: product.primaryTags, isSustainableChoice: product.isSustainableChoice, isPrimary: true }))),
                    React.createElement("div", { className: style.aboveRight }, !isSupplierSalesman && isLoggedIn && (React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product, className: style.useNewDesign })))),
                React.createElement("div", { className: classnames(style.under, (_k = {}, _k[style.campaignSize] = hasPrimaryCampaign, _k)) },
                    React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, searchTerm: searchTerm, code: selectedVariant && selectedVariant.code, openPreviewInModal: openPreviewInModel, product: product, selectProudctVariant: selectedVariant },
                        React.createElement(Image, { src: product.mainImageUrl, alt: productTitle, size: "tiny", maxSize: "tiny", itemProp: "image", fadeIn: true })),
                    !!product.carbonFootPrint && !!product.carbonCloudLink && (React.createElement("div", { className: style.carbonCloud },
                        React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, searchTerm: searchTerm, code: selectedVariant && selectedVariant.code, openPreviewInModal: openPreviewInModel, product: product, selectProudctVariant: selectedVariant },
                            React.createElement(ToolTip, { persistent: false, text: translate('/ProductListing/CarbonFootprintDescription', round(product.carbonFootPrint, 2)), carbonCloud: true },
                                round(product.carbonFootPrint, 1),
                                " ",
                                translate('/ProductListing/CarbonFootprintUnit'))))))),
            React.createElement("div", { className: style.second },
                React.createElement("div", { className: classnames(style.secondTop, (_l = {}, _l[style.hasSubDelivery] = hasSubDeliveries, _l)) },
                    React.createElement("div", { className: style.informations },
                        React.createElement("div", { className: style.information },
                            React.createElement("h2", { className: classnames(style.primaryInformation, style.title) },
                                React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, searchTerm: searchTerm, title: productTitle, code: selectedVariant && selectedVariant.code, openPreviewInModal: openPreviewInModel, product: product, selectProudctVariant: selectedVariant }, productTitle)),
                            React.createElement("div", { className: style.secondaryInformations },
                                React.createElement("div", { className: classnames(style.secondaryInformation, style.description) },
                                    !!product.trademark && React.createElement("div", null, formatTrademark(product.trademark)),
                                    !!product.area && React.createElement("div", null, product.area),
                                    React.createElement("div", null,
                                        translate('/ProductListing/ArticleNumber'),
                                        " ",
                                        typeof product.content.code === 'string' ? product.content.code : propertyValueFor(product.content.code)),
                                    React.createElement("div", { className: style.separateRow },
                                        !!compPrices && isLoggedIn && !discontinuedOrBackordered && !isSupplierSalesman && React.createElement("div", { className: classnames(style['description--medium'], style.pushDownOnePixel) },
                                            translate('/Price/ComparisonPriceShort'),
                                            compPrices,
                                            React.createElement("span", null,
                                                currency,
                                                !!priceUnit ? '/' + priceUnit : '')),
                                        !!product.isUtf && !!product.bestBeforeDate && (React.createElement("div", { className: classnames(style['description--medium'], style.pushDownOnePixel) },
                                            " ",
                                            translate('/ProductListing/BestBefore'),
                                            " ",
                                            moment(product.bestBeforeDate).locale('sv').format('D MMM YYYY').toLowerCase())))),
                                showProduceLogo && React.createElement("div", { className: style.produceLogoGrid, dangerouslySetInnerHTML: { __html: fvh } })),
                            React.createElement("div", { className: style.tertiaryInformation },
                                !!compPrices && (React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice })),
                                discontinuedOrBackordered && !product.isDiscontinued && (React.createElement("div", { className: classnames(style.label, style.discontinuedLabel) },
                                    React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender, useShort: true, useNewDesign: true, hideButton: true }))))),
                        showProduceLogo && React.createElement("div", { className: style.produceLogoMobile, dangerouslySetInnerHTML: { __html: fvh } })),
                    isLoggedIn && !discontinuedOrBackordered && !!compPrices ? (React.createElement("div", { className: classnames(style.units, (_m = {}, _m[style.isSupplier] = isSupplierSalesman && isCheckout, _m)) }, renderVariants.map(function (_a) {
                        var _b;
                        var code = _a.code, price = _a.price, storeUnit = _a.storeUnit, unitShortName = _a.unitShortName, packagingWeight = _a.packagingWeight;
                        var isInCampaign = price ? product.isInCampaign && price && price.current < price.original : false;
                        return (React.createElement("div", { className: style.row, key: code },
                            React.createElement("div", { className: style.variants },
                                !product.isWeightProduct && !discontinuedOrBackordered && (React.createElement("div", { className: style.toggleVariant },
                                    React.createElement(ProductVariantSelector, { variants: product.variants, selectedVariant: isCheckout ? connectedSelectedVariant : selectedVariant, preferedVariant: isCheckout || isCart ? connectedSelectedVariant : undefined, isWeightProduct: product.isWeightProduct, disabled: discontinuedOrBackordered, className: style.selected, useNewDesign: true }))),
                                product.isWeightProduct && React.createElement("div", { className: classnames(style.singleVariant, style['description--title']) }, unitShortName),
                                React.createElement("div", { className: style.oldPrice }, isInCampaign && price && !isSupplierSalesman && React.createElement("div", null,
                                    React.createElement(ProductPriceDisplay, { showItemAttributes: false, showOriginal: true, price: price, id: selectedVariant.code, hideCompare: true, hideUnit: !product.isWeightProduct, useNewDesign: true, showNewSmallestUnit: false })))),
                            React.createElement("div", { className: style.price },
                                React.createElement("div", { className: classnames(style.priceUnit, style['description--medium']) },
                                    React.createElement(ToolTip, { persistent: false, header: translate('/Product/PackagingWeight'), text: product.isWeightProduct ? storeUnit.weight + 'kg / ' + (unitShortName === 'KG' ? 'frp' : 'st') : packagingWeight + 'kg / ' + unitShortName.toLowerCase(), small: true }, "" + (!!storeUnit && storeUnit.specification))),
                                !isSupplierSalesman && (React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: style.filler }),
                                    React.createElement("div", { className: classnames((_b = {}, _b[style.isCampaign] = isInCampaign, _b)) }, price && React.createElement(ProductPriceDisplay, { showItemAttributes: true, showOriginal: false, price: price, id: selectedVariant.code, hideCompare: true, hideUnit: !product.isWeightProduct, useNewDesign: true, showNewSmallestUnit: false }))))),
                            !isSupplierSalesman && (React.createElement("div", { className: classnames(style.smallestUnit, style.subtext) },
                                React.createElement(ProductPriceDisplay, { showItemAttributes: false, showOriginal: true, price: price, id: selectedVariant.code, hideCompare: true, hideUnit: false, useNewDesign: true, showNewSmallestUnit: true })))));
                    }))) : (React.createElement("div", { className: classnames(style.units, (_o = {}, _o[style.isSupplier] = isSupplierSalesman && isCheckout, _o)) }, renderVariants.map(function (_a) {
                        var code = _a.code, storeUnit = _a.storeUnit, unitShortName = _a.unitShortName, packagingWeight = _a.packagingWeight;
                        return (React.createElement("div", { className: style.row, key: code },
                            React.createElement("div", { className: style.variants },
                                React.createElement("div", { className: classnames(style.singleVariant, style['description--title']) }, unitShortName)),
                            React.createElement("div", { className: style.price },
                                React.createElement("div", { className: classnames(style.priceUnit, style['description--medium']) },
                                    React.createElement(ToolTip, { persistent: false, header: translate('/Product/PackagingWeight'), text: product.isWeightProduct ? storeUnit.weight + 'kg / ' + (unitShortName === 'KG' ? 'frp' : 'st') : packagingWeight + 'kg / ' + unitShortName.toLowerCase(), small: true }, "" + (!!storeUnit && storeUnit.specification))))));
                    }))),
                    !compPrices && isLoggedIn && (React.createElement("div", { className: classnames(style.noPrice, style.subtext) }, translate('/Price/NoPriceAvailable'))),
                    isLoggedIn && isCheckout && isSupplierSalesman && (React.createElement("div", { className: style.supplier },
                        React.createElement("div", { key: 'l-5-1-' + product.content.code, className: style.toggle },
                            React.createElement("div", { className: style.subtext }, isProductSample ? translate('/ProductListing/IsProductSample') : translate('/ProductListing/IsNotProductSample')),
                            React.createElement(Checkbox, { checked: isProductSample, onChange: function () { return toggleProductSample(selectedVariant.code); }, appearance: 'toggle' })),
                        React.createElement(PriceSupplier, { code: selectedVariant.code, discountType: discountType, discountValue: discountValue, unitShortName: selectedVariant.unitShortName, validDiscounts: validDiscounts, toggleProductSample: toggleProductSample, isProductSample: isProductSample, useNewDesign: true }))),
                    React.createElement("div", { className: style.actions },
                        !discontinuedOrBackordered && (React.createElement("div", { className: style.left },
                            React.createElement("div", { className: style.leftTop },
                                !isSupplierSalesman && isLoggedIn && (React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product, className: style.useNewDesign })),
                                !isSupplierSalesman && isLoggedIn && isCheckout && (React.createElement("div", { className: style.subDeliveryButton, onClick: showDatePicker },
                                    React.createElement(MyMenigoIcon, { name: "calendar" })))))),
                        React.createElement("div", { className: classnames(style.right, (_p = {}, _p[style.wide] = discontinuedOrBackordered, _p)) },
                            !discontinuedOrBackordered && (React.createElement("div", { className: style.rightTop },
                                React.createElement("div", { className: classnames(style.quantity, (_q = {}, _q[style.notLoggedIn] = !isLoggedIn, _q)) },
                                    !isLoggedIn && (React.createElement(ProductLoginToBuy, null)),
                                    isLoggedIn && (React.createElement(VariantAndQuantity, { product: product, index: index, updateCartQuantity: isCheckout ? updateCartQuantityCheckout : updateCartQuantity, quantityStep: quantityStep, quantityInCart: isCheckout ? quantityInCartCheckout : quantityInCart, minQuantity: 0, isCartQuantity: true, variants: product.variants, selectedVariant: isCheckout ? connectedSelectedVariant : selectedVariant, isWeightProduct: product.isWeightProduct, isRestrictedCustomer: isRestrictedCustomer, discontinuedOrBackordered: discontinuedOrBackordered, disableBuyWidgets: !product.isBuyable || product.isSoldOut, url: '', useNewDesign: true, isCheckout: isCheckout, hasSubDelivery: hasSubDeliveries }))),
                                (isCart || isCheckout) && (React.createElement("div", { className: style.remove, onClick: function () { return removeProduct(removeCartItem); } },
                                    React.createElement(MyMenigoIcon, { name: 'trash' }))))),
                            isLoggedIn && discontinuedOrBackordered && (React.createElement("div", { className: style.disabled },
                                React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender, useShort: true, useNewDesign: true, hideInfo: true }))))),
                    (isCart || isCheckout) && !!totalPrice && !isSupplierSalesman && (React.createElement("div", { className: classnames(style.priceTotalTop, style.title) }, !hasSubDeliveries ? round(totalPrice.current, 2).toLocaleString('sv-SE') + " " + totalPrice.currencySymbol : ''))),
                React.createElement("div", { className: style.secondBottom },
                    React.createElement("div", { className: style.secondBottomTop },
                        React.createElement("div", { className: style.stock },
                            isLoggedIn && hasStockInfo && !product.isDiscontinued && (React.createElement(ProductAvailabilityLabel, { disableAvailabilityDate: true, availability: selectedVariant.availability, showDescription: true })),
                            !hasStockInfo && isLoggedIn && !product.isDiscontinued && (React.createElement("div", { className: classnames(style.subtext) }, selectedVariant.availability ? selectedVariant.availability.feedbackMessage : translate('/Product/StockInfoMissing'))),
                            isLoggedIn && !isProductPage && !isCheckout && !!!product.replacementProduct &&
                                React.createElement(ProductAlternativesLink, { product: product, selectedVariant: selectedVariant, isList: cardType === 'viewLayout_List' })),
                        showProduceLogo && React.createElement("div", { className: style.produceLogoList, dangerouslySetInnerHTML: { __html: fvhWide } })),
                    hasSubDeliveries && (React.createElement("div", { className: classnames(style.subdeliveries, (_r = {}, _r[style.isVisible] = isCheckout, _r)) },
                        product.isProductIncludedInCloseOutCampaign && (React.createElement("div", { key: item.lineItem.code + '_closeout' },
                            React.createElement(Icon, { name: "exclamation" }),
                            item.lineItem.quantity === 0
                                ? translate('/Checkout/IsCloseOut', item.product.title)
                                : translate('/Checkout/IsCloseOutPartialDelivery'))),
                        !product.isProductIncludedInCloseOutCampaign && (item.lineItem.subDeliveries.map(function (subdel) {
                            return (React.createElement(SubDeliveryRow, { index: index, key: item.lineItem.code + '_' + subdel.date, variants: item.product.variants, selectedVariant: item.lineItem.selectedVariant, date: subdel.date, quantity: subdel.quantity, unitShortName: item.lineItem.selectedVariant.unitShortName, update: function (quantity) {
                                    return updateCartItemSubdeliveryQuantity &&
                                        updateCartItemSubdeliveryQuantity(item.lineItem.code, quantity, subdel.date, item.lineItem.selectedVariant.unitShortName, hasAddedEmvInCart);
                                }, remove: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    return [2 /*return*/, removeSubDeliveryRow(item.lineItem.code, subdel.date, item.lineItem.selectedVariant.unitShortName)];
                                }); }); }, isLineItemPending: isItemPendingSubDelivery(item), isStockable: item.product.isStockable, isMobile: isMobile, originalPrice: totalPrice, product: product, isCheckout: isCheckout }));
                        })))),
                    (isCart || isCheckout) && !!totalPrice && !isSupplierSalesman && !hasSubDeliveries && (React.createElement("div", { className: classnames(style.priceTotal, style.title) }, round(totalPrice.current, 2).toLocaleString('sv-SE') + " " + totalPrice.currencySymbol))))),
        showDatePicker && datePicker));
};
export default memo(connect(function (state, ownProps) {
    var selectedVariant = getSelectedVariant(state, ownProps.product.variants, ownProps.selectedVariant);
    return {
        quantityInCartCheckout: getCartLineItemCountForVariant(state, selectedVariant),
        isLoggedIn: state.currentUser.isLoggedIn,
        isSupplierSalesman: state.currentUser.isSupplierSalesman,
        currentBreakpoint: state.currentBreakpoint,
        headerViewModel: state.appShellData.header,
        connectedSelectedVariant: selectedVariant,
    };
})(ProductCardItem));
