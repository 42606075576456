var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import { refreshContent } from './action-creator';
import PropertyFor from 'Shared/PropertyFor';
import Main, { Section } from 'Shared/PageLayout';
import ContentCardGrid from 'Shared/ContentCardGrid';
import SliderBanner from 'Shared/SliderBanner';
import NotificationBanner from './../../../Notifications/Components/NotificationBanner';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { getVisibilityNames } from 'Shared/visibility';
import { updateAppShellData } from '../../../AppShell/action-creators';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
var initalState = {
    contentCardIndex: 0,
    contentStartTime: new Date(),
};
var StartPage = /** @class */ (function (_super) {
    __extends(StartPage, _super);
    function StartPage(props) {
        var _this = _super.call(this, props) || this;
        _this.contentTimerId = -1;
        _this.interval = 0.5;
        _this.onVisibilityChange = function () {
            if (document[getVisibilityNames().hidden]) {
                _this.stopInterval();
            }
            else {
                _this.startInterval();
            }
        };
        _this.state = initalState;
        return _this;
    }
    StartPage.prototype.componentDidMount = function () {
        this.startInterval();
        document.addEventListener(getVisibilityNames().visibilityChange, this.onVisibilityChange, false);
        if (!!this.props.feedbackBanner) {
            this.props.setFeedback(this.props.feedbackBanner);
            this.props.reloadAppShell();
        }
    };
    StartPage.prototype.componentWillUnmount = function () {
        this.stopInterval();
        document.removeEventListener(getVisibilityNames().visibilityChange, this.onVisibilityChange, false);
    };
    StartPage.prototype.startInterval = function () {
        var _this = this;
        this.contentTimerId = setInterval(function () {
            var newContentIndex = _this.getContentIndex();
            if (newContentIndex !== _this.state.contentCardIndex) {
                _this.setState({
                    contentCardIndex: newContentIndex,
                    contentStartTime: _this.state.contentStartTime,
                }, function () {
                    _this.props.refreshContent(_this.state.contentCardIndex);
                });
            }
        }, this.interval * 1000 * 60);
    };
    StartPage.prototype.stopInterval = function () {
        if (this.contentTimerId !== -1) {
            clearInterval(this.contentTimerId);
            this.contentTimerId = -1;
        }
    };
    StartPage.prototype.getContentIndex = function () {
        var currentTime = new Date();
        var diff = Math.abs(currentTime - this.state.contentStartTime);
        var minutes = diff / 1000 / 60;
        var contentIndex = Math.floor(minutes / this.interval);
        return contentIndex;
    };
    StartPage.prototype.hasHeroBlocks = function () {
        var heroBlocks = !!this.props.page.topContent && propertyValueFor(this.props.page.topContent);
        var hasHeroBlocks = !!heroBlocks && heroBlocks.items.length ? true : false;
        return hasHeroBlocks;
    };
    StartPage.prototype.render = function () {
        var imageUrls = this.props.heroCards !== null ? this.props.heroCards.map(function (c) { return c.imageUrl; }) : null;
        var hasHeroBlocks = this.hasHeroBlocks();
        return (React.createElement(React.Fragment, null,
            !!hasHeroBlocks &&
                React.createElement(Section, { className: styles.topContent },
                    !!this.props.notification && !!this.props.notification.message && React.createElement(NotificationBanner, __assign({}, this.props.notification)),
                    React.createElement(PropertyFor, { property: this.props.page.topContent })),
            !hasHeroBlocks &&
                React.createElement(Main, { appearance: "narrow", className: styles.base },
                    !!this.props.heroCards && (React.createElement(SliderBanner, { heroCards: this.props.heroCards, isStartPage: true, imageUrls: imageUrls, currentBreakpoint: this.props.currentBreakpoint })),
                    !!this.props.notification && !!this.props.notification.message && React.createElement(NotificationBanner, __assign({}, this.props.notification)),
                    !!this.props.contentCards && !!this.props.contentCards[0] && React.createElement(ContentCardGrid, { currentBreakpoint: this.props.currentBreakpoint, cards: this.props.contentCards[0] }),
                    React.createElement(Section, null,
                        React.createElement(PropertyFor, { property: this.props.page.mainContent })),
                    React.createElement(Section, null,
                        React.createElement(PropertyFor, { property: this.props.page.supplierAdContent })))));
    };
    return StartPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    refreshContent: function (contentIndex) {
        return dispatch(refreshContent(contentIndex));
    },
    reloadAppShell: function () { return dispatch(updateAppShellData()); },
}); })(StartPage);
