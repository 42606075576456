var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Section } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import ContentArea from 'Shared/Partials/PropertyTypes/ContentArea';
import * as styles from './base.scss';
var FeaturePage = /** @class */ (function (_super) {
    __extends(FeaturePage, _super);
    function FeaturePage(props) {
        return _super.call(this, props) || this;
    }
    FeaturePage.prototype.render = function () {
        return (React.createElement(Section, { className: styles.base },
            !!this.props.showHeader &&
                React.createElement("h1", null,
                    React.createElement(PropertyFor, { property: this.props.page.name })),
            React.createElement(ContentArea, __assign({}, this.props.content))));
    };
    return FeaturePage;
}(React.Component));
export default FeaturePage;
