export var fetchAlternativeProductsLinkInfo = function (body, token, apiUrl) {
    if (!!!token && !!!apiUrl || !!!body)
        return Promise.resolve();
    return fetch("" + apiUrl, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
        .then(function (response) { return response.json(); })
        .then(function (res) {
        return Promise.resolve(res.products);
    }).catch(function (error) {
        console.error(error);
        return Promise.resolve();
    });
};
