import { useCallback } from 'react';
import connect from 'Shared/connect';
import { addToCart, removeCartItem } from './../../Cart/action-creators';
import { getCartLineItemCountForVariant } from './../../Cart/reducer';
import { getSelectedVariant, getOppositeVariant } from 'Shared/Product/reducer';
import { getStepSize as getQuantityStepSize } from 'Shared/Quantity/utils';
import { selectVariant } from 'Shared/Product/action-creators';
var MiniCartContainer = function (_a) {
    var index = _a.index, cartItem = _a.cartItem, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, addToCart = _a.addToCart, children = _a.children, removeFromCart = _a.removeFromCart, oppositeVariant = _a.oppositeVariant, selectVariant = _a.selectVariant;
    var quantityStep = getQuantityStepSize(selectedVariant);
    var url = cartItem.product.url.toString();
    var updateCartQuantity = useCallback(function (quantity) {
        var code = selectedVariant.code, unitShortName = selectedVariant.unitShortName, ticket = selectedVariant.ticket;
        // If clicked variant quantity set to 0, and clicked variants opposite still has quantity in the minicart
        if (quantity === 0 && oppositeVariant && oppositeVariant.lineItem.quantity > 0) {
            // Select the clicked variants opposite since it has quantity in the minicart
            selectVariant(oppositeVariant.lineItem.selectedVariant.code);
        }
        return addToCart(code, quantity, unitShortName, ticket);
    }, [selectedVariant, addToCart, cartItem, oppositeVariant, selectVariant]);
    var removeCartItem = useCallback(function () {
        var code = selectedVariant.code;
        return removeFromCart(code);
    }, [selectedVariant, removeFromCart]);
    return children({
        index: index,
        cartItem: cartItem,
        removeCartItem: removeCartItem,
        quantityInCart: quantityInCart,
        selectedVariant: selectedVariant,
        updateCartQuantity: updateCartQuantity,
        quantityStep: quantityStep,
        url: url,
    });
};
export default connect(function (state, _a) {
    var cartItem = _a.cartItem;
    var selectedVariant = getSelectedVariant(state, cartItem.product.variants, cartItem.lineItem.selectedVariant);
    var oppositeVariant = getOppositeVariant(state.cart.items, cartItem);
    return {
        selectedVariant: selectedVariant,
        oppositeVariant: oppositeVariant,
        quantityInCart: getCartLineItemCountForVariant(state, selectedVariant),
    };
}, function (dispatch, _a) {
    var cartItem = _a.cartItem;
    return {
        addToCart: function (code, quantity, unitShortName, ticket) {
            return dispatch(addToCart(code, quantity, unitShortName, ticket, 'MiniCart'));
        },
        removeFromCart: function (code) { return dispatch(removeCartItem(code, 'MiniCart')); },
        selectVariant: function (newVariantCode) { return dispatch(selectVariant(newVariantCode, cartItem.product.variants)); },
    };
})(MiniCartContainer);
