var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import classnames from 'classnames';
import isProperty from 'Shared/is-property';
import PropertyFor from 'Shared/PropertyFor';
import * as styles from './input.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
function isFilledString(value) {
    if (typeof (value) == 'number')
        return !!value;
    return !!value.trim();
}
var inputTextTypes = ['email', 'password', 'search', 'tel', 'text', 'url'];
function isInputTextType(type) {
    return typeof type === 'undefined' || inputTextTypes.indexOf(type) !== -1;
}
var Input = /** @class */ (function (_super) {
    __extends(Input, _super);
    function Input(props) {
        var _this = _super.call(this, props) || this;
        _this.setPressedState = function (pressed) {
            _this.setState({ pressed: pressed });
        };
        var value = props.value || '';
        _this.state = {
            isEdited: false,
            isFilled: isFilledString(value),
            isValid: true,
            pressed: false,
            validationmessage: "",
            showRequiredValidationMessage: false
        };
        _this.initialValue = value;
        return _this;
    }
    Input.prototype.componentWillMount = function () {
        var value = this.props.value || '';
        var isFilled = isFilledString(value);
        if (isFilled !== this.state.isFilled) {
            this.setState({ isFilled: isFilled });
        }
    };
    Input.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.value != prevProps.value && !!this.props.value)
            this.setState({
                showRequiredValidationMessage: !!!this.props.value
            });
    };
    Input.prototype.edited = function (e) {
        if (!this.state.isEdited) {
            this.setState({
                isEdited: true,
            });
        }
        var isFilled = !!e.target.value.trim();
        if (this.state.isFilled !== isFilled) {
            this.setState({
                isFilled: isFilled
            });
        }
    };
    Input.prototype.focus = function () {
        this.element.focus();
    };
    Input.prototype.blur = function () {
        this.element.blur();
    };
    Input.prototype.select = function () {
        this.element.select();
    };
    Input.prototype.validate = function (value) {
        var _this = this;
        !!this.props.onValidate && this.props.onValidate(value).then(function (validationResult) {
            _this.setState({
                isValid: validationResult.success,
                validationmessage: validationResult.message
            });
        });
    };
    Input.prototype.getSize = function () {
        return isInputTextType(this.props.type) && !('size' in this.props) ? 1 : this.props.size;
    };
    Input.prototype.onInvalid = function (e) {
        !!this.element && this.setState({
            showRequiredValidationMessage: !!!this.props.value
        });
    };
    Input.prototype.shouldShowRequiredValitationMessage = function () {
        return !!this.props.requiredValidationMessage && !!this.props.required && !!this.state.showRequiredValidationMessage;
    };
    Input.prototype.shouldShowValidationMessage = function () {
        if (this.props.showValidationMessage === false)
            return false;
        return !!this.state.validationmessage && !this.state.isValid;
    };
    Input.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, value = _c.value, defaultValue = _c.defaultValue, size = _c.size, children = _c.children, placeholder = _c.placeholder, appearance = _c.appearance, onValidate = _c.onValidate, requiredValidationMessage = _c.requiredValidationMessage, placeholderText = _c.placeholderText, restProps = __rest(_c, ["value", "defaultValue", "size", "children", "placeholder", "appearance", "onValidate", "requiredValidationMessage", "placeholderText"]);
        var inputProps = Object.assign({}, restProps, {
            className: classnames(styles.input, (_a = {},
                _a[styles.edited] = this.state.isEdited,
                _a[styles.filled] = this.state.isFilled,
                _a[styles.customValidation] = (!!this.props.requiredValidationMessage && !!this.props.required) || !!this.validate,
                _a[styles.invalid] = this.shouldShowValidationMessage() || this.shouldShowRequiredValitationMessage(),
                _a[styles.valid] = !!this.props.value && this.state.isValid,
                _a[styles.disabled] = !!this.props.disabled,
                _a[styles.focused] = !!this.state.pressed,
                _a)),
            onBlur: function (e) {
                _this.edited(e);
                _this.setPressedState(false);
                _this.props.onBlur && _this.props.onBlur(e);
                if (_this.props.onBlurAndChanged && _this.initialValue !== _this.props.value) {
                    _this.props.onBlurAndChanged(_this.props.value);
                }
                _this.setState({
                    showRequiredValidationMessage: !!!_this.element.value
                });
                if (_this.initialValue !== _this.props.value) {
                    _this.validate(_this.props.value);
                }
            },
            onFocus: function (e) {
                _this.initialValue = e.target.value;
                _this.setPressedState(true);
                _this.props.onFocus && _this.props.onFocus(e);
            },
            onChange: function (e) {
                _this.props.onChange && _this.props.onChange(e);
                _this.setState({
                    showRequiredValidationMessage: !!!_this.element.value
                });
            },
        });
        var appearances = typeof this.props.appearance === 'string'
            ? [this.props.appearance]
            : (this.props.appearance || []);
        var elementId = !!this.props.id ? this.props.id : (this.props.name || "input-" + (this.props.label || '').replace(' ', '-'));
        var pattern = !!restProps.type && restProps.type === "number" && !restProps.pattern && { pattern: "^[0-9]*$" };
        var fallbackValue = !!!value && value != '' ? undefined : '';
        var valueProp = !!!value && value != '' ? { defaultValue: defaultValue || fallbackValue } : !isNaN(+value) && value != null ? ({ value: value }) : ({ value: value || fallbackValue });
        return (React.createElement(React.Fragment, null,
            !!this.props.label && React.createElement("label", { className: classnames(styles.inputLabel), htmlFor: elementId },
                this.props.label,
                " ",
                !!this.props.required && React.createElement("span", { className: styles.requiredLabel }, "*")),
            React.createElement("label", { className: classnames(styles.base, this.props.className, (_b = {},
                    _b[styles.drifting] = appearances.indexOf('drifting') !== -1,
                    _b)) },
                React.createElement("input", __assign({ id: elementId, ref: function (el) { return (_this.element = el); }, size: this.getSize() }, valueProp, pattern, inputProps, { placeholder: placeholderText })),
                (this.props.placeholder || this.props.children) && (React.createElement("span", { className: styles.label },
                    isProperty(placeholder) ? React.createElement(PropertyFor, { property: placeholder, tagName: "span" }) : placeholder,
                    this.props.children))),
            this.shouldShowRequiredValitationMessage() && React.createElement("span", { className: styles.requiredField },
                React.createElement(MyMenigoIcon, { name: "alert" }),
                this.props.requiredValidationMessage),
            this.shouldShowValidationMessage() && React.createElement("span", { className: styles.requiredField },
                React.createElement(MyMenigoIcon, { name: "alert" }),
                this.state.validationmessage)));
    };
    return Input;
}(React.Component));
export default Input;
