var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import DeliveryDates from '../../../../../../../Shared/DeliveryDates';
import * as styles from '../DeliveryDatesButton.scss';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
import { hideDeliveryDays } from 'SiteLayout/Header/action-creators';
import Button from 'Shared/Button';
var DeliveryDatesMobile = /** @class */ (function (_super) {
    __extends(DeliveryDatesMobile, _super);
    function DeliveryDatesMobile(props) {
        return _super.call(this, props) || this;
    }
    DeliveryDatesMobile.prototype.render = function () {
        var _this = this;
        if (this.props.currentPage.componentName.indexOf('CheckoutPage') >= 0) {
            return null;
        }
        return (React.createElement("div", { className: styles.mobileTopWrapper },
            React.createElement("div", { className: styles.top },
                React.createElement("div", { className: styles.close },
                    React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.props.hideDeliveryDays(); } },
                        React.createElement(Icon, { name: "close" }))),
                React.createElement("span", null, translate('/DeliveryDate/DeliveryDay'))),
            React.createElement("div", { className: styles.calendarWrapper }, !!this.props.headerViewModel.selectedDelivery ? React.createElement(DeliveryDates, { hasWrapper: true, hideHeader: true, preselectedDeliveryDay: this.props.headerViewModel.selectedDelivery.departureDateAsString, deliveryDays: this.props.headerViewModel.deliveryDates, selectedDelivery: this.props.headerViewModel.selectedDelivery }) : React.createElement("h3", { style: { margin: '1rem' } }, translate('/DeliveryDate/NoDeliveryDatesFound')))));
    };
    return DeliveryDatesMobile;
}(React.Component));
export default connect(function (state) { return ({
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
    headerViewModel: state.appShellData.header,
    currentBreakpoint: state.currentBreakpoint,
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    hideDeliveryDays: function () {
        return dispatch(hideDeliveryDays());
    },
}); })(DeliveryDatesMobile);
