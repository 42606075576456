import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import connect from 'Shared/connect';
import * as breakpoints from 'Shared/breakpoints';
import ImageFallback, { urlWithSize } from 'Shared/ImageFallback';
import { getSizes } from 'Shared/ImageSizes';
import { siteContentMaxWidth } from 'SiteLayout';
import * as styles from './base.scss';
export function getImageWidth(currentBreakPoint, relativeSize, availableSizes) {
    var estimatedWidth = Math.min(siteContentMaxWidth, breakpoints.widthFromBreakpoint(currentBreakPoint));
    switch (relativeSize) {
        case '1:2':
            estimatedWidth = currentBreakPoint <= breakpoints.TINY ? estimatedWidth : estimatedWidth * 0.5;
            break;
        case '1:3':
            estimatedWidth = currentBreakPoint <= breakpoints.TINY ? estimatedWidth * 0.5 : estimatedWidth * 0.33;
            break;
        case '2:3':
            estimatedWidth = currentBreakPoint <= breakpoints.TINY ? estimatedWidth : estimatedWidth * 0.75;
            break;
        case '1:4':
            estimatedWidth = currentBreakPoint <= breakpoints.TINY ? estimatedWidth * 0.5 : estimatedWidth * 0.2;
            break;
        case '3:4':
            estimatedWidth = currentBreakPoint <= breakpoints.TINY ? estimatedWidth : estimatedWidth * 0.8;
            break;
        default:
            break;
    }
    return Object.keys(availableSizes)
        .sort(function (a, b) { return (availableSizes[a] >= availableSizes[b] ? 1 : -1); })
        .filter(function (size) { return availableSizes[size] >= estimatedWidth; })[0];
}
var ImageBlock = function (props) {
    var linkUrl = propertyValueFor(props.block.linkUrl);
    var foundImageWidth = getImageWidth(props.currentBreakpoint, props.width, getSizes());
    var useAsBackgroundImage = propertyValueFor(props.block.displayAsBackgroundImage);
    var imageLink = propertyValueFor(props.block.imageUrl);
    var imageUrl = imageLink ? urlWithSize(imageLink, 'small') : null;
    var imageWithLink = useAsBackgroundImage ? (React.createElement("a", { href: linkUrl, className: styles.backgroundImg, style: imageUrl ? { backgroundImage: 'url(' + imageUrl + ')' } : null })) : (React.createElement("a", { href: linkUrl, className: styles.link },
        React.createElement(ImageFallback, { src: propertyValueFor(props.block.imageUrl), className: styles.image, alt: propertyValueFor(props.block.altText), size: foundImageWidth })));
    var imageWithoutLink = useAsBackgroundImage ? (React.createElement("div", { className: styles.backgroundImg, style: imageUrl ? { backgroundImage: 'url(' + imageUrl + ')' } : null })) : (React.createElement(ImageFallback, { src: propertyValueFor(props.block.imageUrl), className: styles.image, alt: propertyValueFor(props.block.altText), size: foundImageWidth }));
    return linkUrl ? imageWithLink : imageWithoutLink;
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ImageBlock);
