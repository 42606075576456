var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import { Input, Radio, Textarea } from 'Shared/Fields';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import * as styles from './../base.scss';
import * as server from 'Shared/server';
import PropertyFor from 'Shared/PropertyFor';
import MyMenigoBasicLayout from '../../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout';
import { pushState } from 'Shared/history';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { addDeliveryComplaintStartedNew, addDeliveryComplaintReasonSelected, addDeliveryComplaintFinalizingComplaint } from './../../TrackingInformation/CustomGa4EventService';
var Units = [{ value: 'frp' }, { value: 'st' }, { value: 'kg' }, { value: 'kgd' }];
var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
var articleNumberFormat = /^$|^([0-9]{6})$/;
var phoneFormat = /^[\+]?([0-9]|\-|\s)+$/;
var ComplaintBlock = /** @class */ (function (_super) {
    __extends(ComplaintBlock, _super);
    function ComplaintBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.hideModal = function () {
            _this.setState({ showModal: false });
        };
        _this.validatePhone = function (phone) {
            var valid = !!_this.state.complaintAction &&
                _this.state.complaintAction === 'ContactByPhone' &&
                (!!_this.state.phone && !!_this.state.phone.match(phoneFormat));
            var model = {
                success: !(_this.state.complaintAction === 'ContactByPhone') || valid,
                message: translate('/MyMenigo/ComplaintAction/Error/ContactByPhone'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validateEmail = function (phone) {
            var valid = !(!_this.state.email || !_this.state.email.match(mailformat));
            var model = {
                success: valid,
                message: translate('/MyMenigo/ComplaintAction/Error/ContactByEmail'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validateDeliveryDate = function (date) {
            var valid = (_this.state.deliveryDate || '').trim() === '' || !isNaN(Date.parse(_this.state.deliveryDate));
            var model = {
                success: valid,
                message: translate('/MyMenigo/Complaint/deliveryDateRequireMessage'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validateArticleNumber = function (article) {
            var valid = !!_this.state.articleNumber && !!_this.state.articleNumber.match(articleNumberFormat);
            var model = {
                success: valid,
                message: translate('/MyMenigo/Complaint/Error/ArticleNumber'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.state = {
            customerName: _this.props.customerName,
            phone: _this.props.phone,
            email: _this.props.email,
            contact: _this.props.contact,
            deliveryDate: _this.props.deliveryDate,
            orderNumber: _this.props.orderNumber,
            articleNumber: _this.props.articleNumber,
            quantity: _this.props.quantity,
            unit: _this.props.unit,
            message: _this.props.message,
            complaintAction: _this.props.complaintAction,
            complaintReason: _this.props.complaintReason,
            reason1: _this.props.reason1,
            reason2: _this.props.reason2,
            showModal: false,
            moreComplaints: false,
        };
        return _this;
    }
    ComplaintBlock.prototype.onSubmit = function (e, moreComplaints) {
        var _this = this;
        e.preventDefault();
        var hasError = false;
        if ((this.state.contact || '').trim() === '' ||
            (this.state.quantity || 0) === 0 ||
            (this.state.unit || '').trim() === '' ||
            (this.state.complaintReason === 'BadQuality' &&
                ((this.state.reason1 || '').trim() === '' || (this.state.reason2 || '').trim() === ''))) {
            hasError = true;
        }
        if (!this.state.articleNumber || !this.state.articleNumber.match(articleNumberFormat)) {
            hasError = true;
        }
        if (!this.state.email || !this.state.email.match(mailformat)) {
            hasError = true;
        }
        if (!!this.state.complaintAction &&
            this.state.complaintAction === 'ContactByPhone' &&
            (!this.state.phone || !this.state.phone.match(phoneFormat))) {
            hasError = true;
        }
        if (this.state.hasError !== hasError || this.state.moreComplaints !== moreComplaints) {
            this.setState({ hasError: hasError, moreComplaints: moreComplaints });
        }
        if (hasError) {
            this.props.setFeedback({
                text: translate('/MyMenigo/Complaint/Error/FormInvalid'),
                type: 'error',
            });
            return;
        }
        return server.postJson(window.location.href, this.state).then(function (result) {
            if (!result.success) {
                addDeliveryComplaintFinalizingComplaint(false, _this.state.complaintReason);
                _this.props.setFeedback({ text: result.message, type: 'error' });
                return Promise.reject(null);
            }
            addDeliveryComplaintFinalizingComplaint(true, _this.state.complaintReason);
            if (_this.state.moreComplaints) {
                _this.props.setFeedback({
                    text: translate('/MyMenigo/Complaint/SaveAndContinueMessage'),
                    type: 'success',
                });
                _this.setState({
                    complaintReason: '',
                    complaintAction: '',
                    reason1: '',
                    reason2: '',
                    orderNumber: null,
                    articleNumber: null,
                    quantity: 0,
                    unit: null,
                    message: null,
                });
            }
            else {
                _this.setState({
                    showModal: true,
                });
            }
        }, function (e) { return Promise.reject(null); });
    };
    ComplaintBlock.prototype.setComplaintLevel = function (e) {
        this.setState({
            complaintReason: e.target.value,
            reason1: '',
            reason2: '',
        });
        addDeliveryComplaintReasonSelected(e.target.value);
    };
    ComplaintBlock.prototype.componentDidMount = function () {
        addDeliveryComplaintStartedNew('ComplaintScreen/unknownProduct');
    };
    ComplaintBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement(MyMenigoBasicLayout, { model: this.props },
            React.createElement("form", { className: styles.complaintForm, id: "CompaintForm" },
                React.createElement(Modal, { isOpen: this.state.showModal, onClose: this.hideModal },
                    React.createElement(PropertyFor, { property: this.props.page.confirmation }),
                    React.createElement(Dialog, { type: DialogType.OkOnly, onConfirm: function () { return pushState('/'); }, onCancel: this.hideModal })),
                React.createElement("div", { className: styles.customerHeader },
                    React.createElement("div", { className: styles.customerDetails },
                        React.createElement("h3", null, translate('/MyMenigo/Complaint/CustomerDetailHeader')),
                        React.createElement("div", { className: styles.inputContainer },
                            React.createElement("label", { className: styles.inputLabel }, translate('/MyMenigo/Complaint/LabelAccountNumber')),
                            ' ',
                            this.props.customerId),
                        React.createElement("div", { className: styles.inputContainer },
                            React.createElement("label", { className: styles.inputLabel }, translate('/MyMenigo/Complaint/LabelCustomerName')),
                            ' ',
                            this.props.customerName),
                        React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                            React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelCustomerTelephone'), type: "text", value: this.state.phone, name: "phone", spellCheck: false, autoCorrect: 'off', className: styles.inputControl, maxLength: 30, onValidate: this.validatePhone, required: (!!this.state.complaintAction &&
                                    this.state.complaintAction === 'ContactByPhone'), requiredValidationMessage: translate('/MyMenigo/ComplaintAction/Error/ContactByPhone'), onChange: function (e) { return _this.setState({ phone: e.target.value }); } })),
                        React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                            React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelCustomerEmail'), type: "text", value: this.state.email, name: "email", spellCheck: false, autoCorrect: "off", className: classnames(styles.inputControl), maxLength: 256, onValidate: this.validateEmail, onChange: function (e) { return _this.setState({ email: e.target.value }); } }))),
                    React.createElement("div", { className: styles.customerTerms },
                        React.createElement(PropertyFor, { property: this.props.page.description })),
                    React.createElement("div", { className: styles.clearboth })),
                React.createElement("div", null,
                    React.createElement("h3", null, translate('/MyMenigo/Complaint/HeaderDescriptionComplaint')),
                    React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                        React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelContact'), type: "text", value: this.state.contact, name: "contact", spellCheck: false, autoCorrect: "off", className: classnames(styles.inputControl), required: true, requiredValidationMessage: translate('/MyMenigo/Complaint/Error/Contact'), maxLength: 256, onChange: function (e) { return _this.setState({ contact: e.target.value }); } })),
                    React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                        React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelDeliveryDate'), type: "text", value: this.state.deliveryDate, name: "deliveryDate", spellCheck: false, autoCorrect: "off", className: classnames(styles.inputControl), maxLength: 256, required: true, requiredValidationMessage: translate('/MyMenigo/Complaint/deliveryDateRequireMessage'), onValidate: this.validateDeliveryDate, onChange: function (e) { return _this.setState({ deliveryDate: e.target.value }); } }),
                        ' ',
                        translate('/MyMenigo/Complaint/LabelDeliveryNote')),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("br", null),
                    React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                        React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelOrderNumber'), type: "text", value: this.state.orderNumber, name: "orderNumber", spellCheck: false, autoCorrect: "off", className: styles.inputControl, maxLength: 256, onChange: function (e) { return _this.setState({ orderNumber: e.target.value }); } })),
                    React.createElement("div", { className: classnames(styles.inputContainer, styles.divHalf) },
                        React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelArticle'), type: "text", value: this.state.articleNumber, name: "articleNumber", spellCheck: false, autoCorrect: "off", className: classnames(styles.inputControl), onValidate: this.validateArticleNumber, maxLength: 256, onChange: function (e) { return _this.setState({ articleNumber: e.target.value }); } })),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("div", { className: styles.inputContainer },
                        React.createElement("div", { className: styles.clearboth }),
                        React.createElement(Input, { label: translate('/MyMenigo/Complaint/LabelQuantity'), type: "number", value: this.state.quantity, name: "quantity", step: 0.01, spellCheck: false, autoCorrect: "off", className: classnames(styles.inputControl, styles.inputControlQty), maxLength: 256, required: true, requiredValidationMessage: translate('/MyMenigo/Complaint/Error/Quantity'), onChange: function (e) { return _this.setState({ quantity: parseFloat(e.target.value) }); } }),
                        React.createElement("div", { className: styles.clearboth }),
                        React.createElement("br", null),
                        Units.map(function (unit, index) {
                            return (React.createElement(Radio, { key: index, className: styles.radioUnit, name: "unit", required: true, checked: _this.state.unit === unit.value, value: unit.value, onChange: function (e) { return _this.setState({ unit: e.target.value }); } }, unit.value));
                        }),
                        !!!this.state.unit && this.state.hasError && (React.createElement("span", { className: styles.requiredField },
                            React.createElement(MyMenigoIcon, { name: "alert" }),
                            translate('/MyMenigo/Complaint/Error/Unit')))),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("br", null),
                    React.createElement("div", { className: styles.customerTerms },
                        React.createElement("h3", null, translate('/MyMenigo/Complaint/ComplaintReasonTitle'))),
                    this.props.complaintReasons.map(function (reason, index) {
                        return (React.createElement("div", { key: index, className: styles.inputContainer },
                            React.createElement(Radio, { name: "complaintReason", checked: _this.state.complaintReason === reason.value, value: reason.value.toString(), onChange: function (e) { return _this.setComplaintLevel(e); } }, reason.title),
                            ' ',
                            reason.placeholders.length > 0 ? (React.createElement("div", { className: reason.placeholders.length > 1 ? styles.additionalText2TextContainer : '' }, reason.placeholders.map(function (inp, index) {
                                var target = index === 0 ? 'reason1' : 'reason2';
                                return (React.createElement("div", { className: _this.state.complaintReason !== reason.value ? styles.hide : styles.showComplaintReason },
                                    React.createElement(Input, { label: inp.title, key: index, type: "text", name: inp.title, value: index === 0 ? _this.state.reason1 : _this.state.reason2, className: classnames(styles.additionalText), maxLength: 256, required: !(_this.state.complaintReason !== reason.value), requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), disabled: _this.state.complaintReason !== reason.value, onChange: function (e) {
                                            var _a;
                                            _this.setState((_a = {},
                                                _a[target] = e.target.value,
                                                _a));
                                        } })));
                            }))) : (React.createElement("div", null)),
                            React.createElement("div", { className: styles.clearboth }),
                            React.createElement("br", null)));
                    }),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("br", null),
                    React.createElement("div", { className: styles.customerTerms },
                        React.createElement("h3", null, translate('/MyMenigo/Complaint/MeasureTitle'))),
                    this.props.complaintActions.map(function (complaint, index) {
                        return (React.createElement("div", { key: index, className: styles.inputContainer },
                            React.createElement(Radio, { className: styles.complaintForm, checked: _this.state.complaintAction === complaint.value, name: "complaintAction", value: complaint.value.toString(), onChange: function (e) { return _this.setState({ complaintAction: e.target.value }); } }, complaint.title)));
                    }),
                    React.createElement("br", null),
                    React.createElement("div", { className: styles.customerTerms },
                        React.createElement("h3", null, translate('/MyMenigo/Complaint/LabelMessage'))),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement(Textarea, { name: "textarea", value: this.state.message || '', maxLength: 256, onChange: function (e) { return _this.setState({ message: e.target.value }); }, className: styles.anyMessageText }),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("br", null),
                    React.createElement("div", { className: styles.buttonArea },
                        React.createElement(Button, { onClick: function (e) { return _this.onSubmit(e, false); }, type: "button" }, translate('/MyMenigo/Complaint/SaveAndCloseButton'))),
                    React.createElement("div", { className: styles.clearboth }),
                    React.createElement("br", null)))));
    };
    return ComplaintBlock;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(ComplaintBlock);
