import React from 'react';
import classnames from 'classnames';
import propertyValueFor from 'Shared/property-value-for';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import { setCustomProductEditId } from '../../../CustomProducts/action-creators';
import ProductPreview from '../ProductPreview';
var TRUNCATE_LIMIT = 50;
var truncateText = function (text) {
    return text.substr(0, TRUNCATE_LIMIT).trim() + "...";
};
var shouldTextBeTruncated = function (displayName) {
    return displayName.toString().length > TRUNCATE_LIMIT;
};
var ProductTitle = function (_a) {
    var displayName = _a.displayName, linkUrl = _a.linkUrl, ticket = _a.ticket, className = _a.className, trademark = _a.trademark, code = _a.code, setCustomProductEditId = _a.setCustomProductEditId, isCustom = _a.isCustom, searchTerm = _a.searchTerm, openPreviewInModal = _a.openPreviewInModal, product = _a.product, selectedVariant = _a.selectedVariant;
    var title = typeof displayName === 'string'
        ? shouldTextBeTruncated(displayName)
            ? truncateText(displayName)
            : displayName
        : propertyValueFor(displayName);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: classnames(styles.title, className) }, !!isCustom ? (React.createElement("a", { className: styles.link, onClick: function () { setCustomProductEditId(code); } },
            React.createElement(ProductLink, { linkUrl: null, ticket: ticket, title: shouldTextBeTruncated(displayName) ? displayName.toString() : null, code: code }, title))) :
            (!!openPreviewInModal ?
                React.createElement(ProductPreview, { product: product, selectedVariant: selectedVariant },
                    " ",
                    title) :
                React.createElement(ProductLink, { linkUrl: linkUrl, ticket: ticket, title: shouldTextBeTruncated(displayName) ? displayName.toString() : null, searchTerm: searchTerm, code: code }, title))),
        !!trademark && React.createElement("div", { className: classnames(styles.trademark) }, trademark)));
};
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setCustomProductEditId: function (code) {
        return dispatch(setCustomProductEditId(code));
    },
}); })(ProductTitle);
