var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import * as styles from './base.scss';
var ProductOrdinal = function (_a) {
    var passedUpdateOrdinal = _a.passedUpdateOrdinal, passedOrdinal = _a.passedOrdinal, selectElement = _a.selectElement, rowIndex = _a.rowIndex, disabled = _a.disabled;
    var _b = __read(useState(passedOrdinal), 2), currentOrdinal = _b[0], setCurrentOrdinal = _b[1];
    var onBlurOrdinal = function () {
        if (passedOrdinal !== currentOrdinal) {
            passedUpdateOrdinal(rowIndex, currentOrdinal);
        }
    };
    return (React.createElement("div", { className: styles.wrap },
        React.createElement("input", { className: styles.ordinal, name: rowIndex + '_ordinal', type: "text", max: "999", onChange: function (e) { return setCurrentOrdinal(parseInt(e.target.value.replace(/[^0-9]+/g, ''))); }, value: currentOrdinal > 0 ? currentOrdinal.toString() : '', disabled: disabled, onFocus: function () { return selectElement(rowIndex, 'ordinal'); }, onBlur: onBlurOrdinal })));
};
export default ProductOrdinal;
