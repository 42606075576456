import { isBrowser } from 'Shared/device-type';
export function renderMeta(currentPage) {
    if (isBrowser()) {
        var canonicalResource = currentPage;
        var canonical = document.getElementById('link-canonical');
        if (isCanonicalResource(canonicalResource) && (canonicalResource.canonicalUrl || '').trim() !== '') {
            if (!canonical) {
                canonical = document.createElement('link');
                canonical.rel = 'canonical';
                canonical.id = 'link-canonical';
                document.head.appendChild(canonical);
            }
            console.debug('Updating canonical to', canonicalResource.canonicalUrl);
            canonical.href = canonicalResource.canonicalUrl;
        }
        else if (canonical) {
            console.debug('Removing canonical');
            document.head.removeChild(canonical);
        }
        var pagedResource = currentPage;
        var prevLink = document.getElementById('link-prev');
        var nextLink = document.getElementById('link-next');
        if (isPagedResource(pagedResource)) {
            updatePageLink(prevLink, 'prev', pagedResource.prevUrl);
            updatePageLink(nextLink, 'next', pagedResource.nextUrl);
        }
        else {
            if (prevLink) {
                console.debug('Removing link prev');
                document.head.removeChild(prevLink);
            }
            if (nextLink) {
                console.debug('Removing link next');
                document.head.removeChild(nextLink);
            }
        }
        var newTitle = ((currentPage.meta && currentPage.meta.title) || '') + ' | ' + formatHost(location.host);
        if (document.title === newTitle) {
            return;
        }
        document.title = newTitle;
        Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach(function (node) {
            node.parentElement.removeChild(node);
        });
        Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach(function (name) {
            var metaElement = document.createElement('meta');
            metaElement.setAttribute('data-dynamic', '1');
            metaElement.setAttribute(currentPage.meta.elements[name].type, name);
            metaElement.setAttribute('content', currentPage.meta.elements[name].value);
            document.head.appendChild(metaElement);
        });
    }
}
function updatePageLink(pageLink, type, url) {
    if ((url || '').trim() !== '') {
        if (!pageLink) {
            pageLink = document.createElement('link');
            pageLink.rel = type;
            pageLink.id = 'link-' + type;
            document.head.appendChild(pageLink);
        }
        pageLink.href = url;
    }
    else if (pageLink) {
        console.debug('Removing link ', type);
        document.head.removeChild(pageLink);
    }
}
function formatHost(host) {
    var parts = host.split('.');
    if (parts.length !== 3) {
        return host;
    }
    return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
}
function isCanonicalResource(page) {
    return page && 'canonicalUrl' in page;
}
function isPagedResource(page) {
    return page && ('nextUrl' in page || 'prevUrl' in page);
}
