var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { transitionEndEvent } from 'Shared/device-type';
import Icon from 'Shared/Icon';
import compareStrings from './compare-strings';
import ListPickerFacet from './ListPickerFacet';
import { allSelectedValues } from 'Shared/Facet/toggle-facet';
import * as styles from './base.scss';
import { alignmiddle } from '../../../styles/objects/icon.scss';
export var DEFAULT_VISIBLE_FACET_COUNT = 10;
export var POPULAR_FACET_COUNT = 5;
var Facet = /** @class */ (function (_super) {
    __extends(Facet, _super);
    function Facet(props) {
        var _this = _super.call(this, props) || this;
        var open = _this.props.openDefault && !!props.facet.facetValues.filter(function (v) { return v.selected; }).length;
        _this.defaultVisibleFacetCount =
            _this.props.facet.facetValues.length > DEFAULT_VISIBLE_FACET_COUNT + POPULAR_FACET_COUNT ? 5 : 10;
        _this.state = {
            open: open,
            openTransitionDone: open,
            someValuesAreHidden: _this.someValuesAreHidden(props),
        };
        return _this;
    }
    Facet.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.state.someValuesAreHidden && !this.someValuesAreHidden(nextProps)) {
            this.setState({ someValuesAreHidden: false });
        }
    };
    Facet.prototype.componentDidMount = function () {
        this.setMaxHeightOnClipper(this.state.open);
    };
    Facet.prototype.componentDidUpdate = function () {
        this.setMaxHeightOnClipper(this.state.open);
    };
    Facet.prototype.setMaxHeightOnClipper = function (open) {
        if (open) {
            var borderSize = 2;
            var height = this.contentElement.offsetHeight;
            this.clipperElement.style.maxHeight = height + borderSize + 'px';
        }
        else {
            this.clipperElement.style.maxHeight = '';
        }
    };
    Facet.prototype.someValuesAreHidden = function (props) {
        return !this.hiddenValuesAreSelected(props);
    };
    Facet.prototype.hiddenValuesAreSelected = function (props) {
        var hiddenValues = this.filteredFacets(props).alphabeticalValues.slice(DEFAULT_VISIBLE_FACET_COUNT);
        return !!hiddenValues.filter(function (v) { return v.selected; }).length;
    };
    Facet.prototype.toggle = function () {
        var _this = this;
        var newOpen = !this.state.open;
        this.setState({
            open: newOpen,
            openTransitionDone: false,
            someValuesAreHidden: this.someValuesAreHidden(this.props),
        });
        var transitionEnd = function () {
            _this.setState({ openTransitionDone: true });
            _this.clipperElement.removeEventListener(transitionEndEvent, transitionEnd);
        };
        this.clipperElement.addEventListener(transitionEndEvent, transitionEnd);
        this.setMaxHeightOnClipper(newOpen);
    };
    Facet.prototype.showAll = function () {
        this.setState({ someValuesAreHidden: false });
    };
    Facet.prototype.showLess = function () {
        this.setState({ someValuesAreHidden: true });
    };
    Facet.prototype.filteredFacets = function (props) {
        if (props === void 0) { props = this.props; }
        var alphabeticalValues = props.facet.facetValues;
        var showFilterSearch = props.facet.facetValues.length > this.defaultVisibleFacetCount;
        var popularValues = [];
        if (props.facet.sortOrder !== 'alphabetical' && showFilterSearch) {
            popularValues = props.facet.facetValues.slice(0, POPULAR_FACET_COUNT);
            alphabeticalValues = props.facet.facetValues.slice(POPULAR_FACET_COUNT);
            alphabeticalValues.sort(function (a, b) { return compareStrings(a.text, b.text); });
        }
        return { alphabeticalValues: alphabeticalValues, popularValues: popularValues };
    };
    Facet.prototype.render = function () {
        var _this = this;
        var _a;
        var selectedFacetValues = allSelectedValues(this.props.facet.facetValues);
        return (React.createElement("li", { className: classnames(styles.facetGroup, (_a = {}, _a[styles.open] = this.state.open && this.state.openTransitionDone, _a)), ref: function (el) { return (_this.clipperElement = el); } },
            React.createElement("div", { ref: function (el) { return (_this.contentElement = el); } },
                this.props.facet.displayName && (React.createElement("div", { className: styles.toggler, onClick: function () { return _this.toggle(); } },
                    React.createElement("span", { className: alignmiddle },
                        React.createElement("span", { className: styles.title },
                            this.props.facet.displayName,
                            selectedFacetValues.length > 0 && React.createElement("span", { className: styles.hasSelectedValuesMark })),
                        React.createElement(Icon, { name: this.state.open ? 'minus' : 'plus' })))),
                React.createElement(ListPickerFacet, { facet: this.props.facet, setMaxHeight: function (open) { _this.setMaxHeightOnClipper(open); }, someValuesAreHidden: this.state.someValuesAreHidden, onFacetValueChange: this.props.onFacetValueChange, selectedFacetValues: selectedFacetValues, filteredFacets: function () { return _this.filteredFacets(_this.props); }, defaultVisibleFacetCount: this.defaultVisibleFacetCount, onAndOrFilterChange: function (andOrFilter) { return _this.props.setAndOrFilter(_this.props.facet.fieldName, andOrFilter); }, showAll: function () { return _this.showAll(); }, showLess: function () { return _this.showLess(); }, excludeCnt: this.props.excludeCnt }))));
    };
    return Facet;
}(React.Component));
export default Facet;
