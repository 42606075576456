import React from 'react';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import LoginContainer from 'Shared/Login/Container';
import * as styles from './login-to-buy.scss';
var ProductCardLoginToBuy = function () { return (React.createElement(LoginContainer, null, function (_a) {
    var setAccountBoxOpen = _a.setAccountBoxOpen;
    return (React.createElement(Button, { className: styles.base, onClick: function () { return setAccountBoxOpen(); } },
        React.createElement("span", { className: styles.text }, translate('/Account/Login/LoginToBuy')),
        ' ',
        React.createElement(Icon, { name: "add-to-cart", className: styles.icon })));
})); };
export default ProductCardLoginToBuy;
