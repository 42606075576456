var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import FeedbackButton from 'Shared/Button/Feedback';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import * as loading from './../loading.scss';
import { SMALL } from 'Shared/breakpoints';
var CompletePurchase = /** @class */ (function (_super) {
    __extends(CompletePurchase, _super);
    function CompletePurchase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // This method is called on us when the checkout form is submitted
    CompletePurchase.prototype.onCompletePurchase = function (completion) {
        return this.feedbackButton.activate(completion
            .then(function (n) {
            return Promise.resolve(n);
        })
            .catch(function (e) {
            return Promise.reject(e);
        }), {
            minimumPending: 450,
            maximumRejected: 500,
            maximumFulfilled: 500,
        });
    };
    CompletePurchase.prototype.render = function () {
        var _this = this;
        var _a;
        var isMobile = this.props.currentBreakpoint < SMALL;
        return (React.createElement("div", { className: classnames(styles.base, !!isMobile && styles.mobile, (_a = {}, _a[loading.loading] = this.props.isLoading, _a)), ref: function (el) { return (_this.el = el); } },
            React.createElement(FeedbackButton, { type: "submit", appearance: !isMobile ? 'large' : 'full', ref: function (el) { return (_this.feedbackButton = el); }, disabled: this.props.disabled },
                React.createElement("span", null, this.props.isPunchout
                    ? translate('/Checkout/CompletePurchasePunchout')
                    : translate('/Checkout/CompletePurchase')))));
    };
    return CompletePurchase;
}(React.Component));
export default CompletePurchase;
