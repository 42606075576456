window.TODO = function () {
    /* noop */
};
if (process.env.NODE_ENV !== 'production') {
    // Global function `TODO` to output trace messages once
    var traced_1 = [];
    window.TODO = function TraceTodo(message) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (traced_1.indexOf(message) === -1) {
            console.trace.apply(console, ["TODO: " + message].concat(args, ['(remove `TODO(...)` when solved)']));
            traced_1.push(message);
        }
    };
}
