var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Part } from 'Shared/PageLayout';
import InvoiceList from './InvoiceList';
import * as styles from './base.scss';
import PropertyFor from 'Shared/PropertyFor';
import MyMenigoBasicLayout from '../../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout';
var InvoiceOverviewPage = /** @class */ (function (_super) {
    __extends(InvoiceOverviewPage, _super);
    function InvoiceOverviewPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoiceOverviewPage.prototype.render = function () {
        var noResult = (React.createElement("div", { className: styles.notification }, React.createElement(PropertyFor, { tagName: 'strong', property: this.props.hasErrors === true ? this.props.page.errorLoadingInvoiceText :
                this.props.page.noInvoiceText })));
        return (React.createElement(MyMenigoBasicLayout, { model: this.props },
            React.createElement(Part, null,
                React.createElement(InvoiceList, { list: this.props.invoiceList, dropdown: this.props.listYear, pageSize: this.props.pageSize, totalInvoiceCount: this.props.totalInvoice, noResultElement: noResult }))));
    };
    return InvoiceOverviewPage;
}(React.Component));
export default InvoiceOverviewPage;
