var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Basic, Part } from 'Shared/PageLayout';
import OrderSummary from './OrderSummary';
import Price from 'Shared/Price';
import OrderLineItems from '../OrderLineItems';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import moment from 'moment';
var OrderDetails = /** @class */ (function (_super) {
    __extends(OrderDetails, _super);
    function OrderDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            totalRowPrice: 0,
        };
        return _this;
    }
    OrderDetails.prototype.componentDidMount = function () {
        var totalPrice = 0;
        this.props.model.orderLineItems.map(function (lineItem, i) {
            totalPrice += lineItem.totalPrice;
        });
        this.setState({
            totalRowPrice: totalPrice,
        });
    };
    OrderDetails.prototype.render = function () {
        return (React.createElement(Part, null,
            React.createElement(Basic, { className: styles.orderDetails },
                React.createElement(OrderSummary, { model: this.props.model, page: this.props.page, deliveryStatusText: this.props.deliveryStatusText, deliveryTrackingNumberText: this.props.deliveryTrackingNumberText, deliveryTrackingLinkText: this.props.deliveryTrackingLinkText })),
            React.createElement(OrderLineItems, { orderLineItems: this.props.model.orderLineItems }),
            React.createElement("div", { className: styles.details },
                React.createElement("div", { className: styles.divleft },
                    React.createElement("b", null,
                        translate('/MyMenigo/DeliveryHeader/DeliveryDate'),
                        "\u00A0",
                        moment(this.props.model.deliveryDate).format('YYYY-MM-DD'),
                        ", ",
                        translate('/Shared/Departure'),
                        ' ',
                        this.props.model.deliveryDeparture)),
                React.createElement("div", { className: styles.divright },
                    !this.props.model.isSupplierSalesmanOrder && (React.createElement("b", null,
                        translate('/MyMenigo/OrderOverviewHeaders/OrderSummary'),
                        "\u00A0")),
                    !this.props.model.isSupplierSalesmanOrder && this.state.totalRowPrice ? (React.createElement(Price, { current: this.state.totalRowPrice })) : null))));
    };
    return OrderDetails;
}(React.Component));
export default OrderDetails;
