var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main, { Section, Part } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import NewsSideBar from './NewsSideBar';
import Breadcrumbs from 'Shared/Breadcrumbs';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
import { TINY } from 'Shared/breakpoints';
import * as pageLayoutStyles from 'Shared/PageLayout/base.scss';
var NewsOverviewPage = /** @class */ (function (_super) {
    __extends(NewsOverviewPage, _super);
    function NewsOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    NewsOverviewPage.prototype.render = function () {
        var isExtensive = this.props.currentBreakpoint > TINY;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(Section, null,
                React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs }),
                React.createElement("div", { className: styles.pageInfo },
                    React.createElement("span", null,
                        propertyValueFor(this.props.page.startPublishDateString),
                        " | "),
                    React.createElement("span", { className: styles.articleType }, this.props.localizedArticleType))),
            React.createElement(Section, { layout: isExtensive ? '3to1' : null },
                React.createElement(Part, null,
                    React.createElement(PropertyFor, { property: this.props.page.name, tagName: "h1" }),
                    React.createElement(PropertyFor, { property: this.props.page.hero, isImage: true }),
                    React.createElement(PropertyFor, { property: this.props.page.preamble, tagName: "h3" }),
                    React.createElement(Section, { layout: propertyValueFor(this.props.page.isTwoColumnLayout) ? '1to1' : null },
                        React.createElement(Part, null,
                            React.createElement(PropertyFor, { property: this.props.page.mainBody }),
                            React.createElement(PropertyFor, { property: this.props.page.author, className: styles.footer })),
                        React.createElement(Part, { className: pageLayoutStyles.sidebar },
                            React.createElement(PropertyFor, { property: this.props.page.lowerRightBlockArea })))),
                isExtensive && (React.createElement(Part, { className: pageLayoutStyles.sidebar },
                    React.createElement(NewsSideBar, { sideBar: this.props.sideBar })))),
            React.createElement(Part, null)));
    };
    return NewsOverviewPage;
}(React.Component));
export default NewsOverviewPage;
