import * as server from 'Shared/server';
import * as storage from './ips-storage';
import { clearSearchList, setSearchQuery } from '../App/action-creators';
export var EXPORT_LIST_BEGIN = 'EXPORT_LIST_BEGIN';
export var EXPORT_LIST_SUCCESS = 'EXPORT_LIST_SUCCESS';
export var EXPORT_LIST_FAIL = 'EXPORT_LIST_FAIL';
export var LIST_LOADING = 'LIST_LOADING';
export var LIST_LOADED = 'LIST_LOADED';
export var ADD_ITEM = 'ADD_ITEM';
export var CLEAR_ITEMS = 'CLEAR_ITEMS';
export var REMOVE_ITEM = 'REMOVE_ITEM';
export var CHANGE_ITEM_QUANTITY = 'CHANGE_ITEM_QUANTITY';
export var CHANGE_ITEM_PRICE = 'CHANGE_ITEM_PRICE';
export var TOGGLE_EDIT = 'TOGGLE_EDIT';
export var CHANGE_TAX_RATE = 'CHANGE_TAX_RATE';
export function loadList(mode) {
    return function (dispatch, getState) {
        dispatch({
            type: LIST_LOADING,
        });
        storage
            .get(mode)
            .then(function (items) {
            if (items === null) {
                return createList(mode);
            }
            return items;
        })
            .then(function (items) {
            dispatch({
                type: LIST_LOADED,
                items: items,
            });
        });
    };
}
export function createList(mode) {
    return storage.set(mode, []);
}
export function addItem(mode, item) {
    return function (dispatch, getState) {
        var state = getState();
        storage.insert(mode, item).then(function (_) {
            dispatch({
                type: ADD_ITEM,
                mode: mode,
                item: item,
            });
            if (state.app.showCamera) {
                dispatch(clearSearchList());
                dispatch(setSearchQuery(''));
            }
        });
    };
}
export function clearItems(mode) {
    return function (dispatch, getState) {
        storage
            .deleteOrder(mode)
            .then(function (_) { return createList(mode); })
            .then(function (_) {
            dispatch({
                type: CLEAR_ITEMS,
                mode: mode,
            });
        });
    };
}
export function clearState(mode) {
    return {
        type: CLEAR_ITEMS,
        mode: mode,
    };
}
export function toggleEdit(mode, id, editing) {
    return function (dispatch, getState) {
        var state = getState();
        var item = state.ips.items.find(function (item) { return id === item.id; });
        item.isEditing = editing;
        storage.update(mode, item).then(function (_) {
            dispatch({
                type: TOGGLE_EDIT,
                mode: mode,
                id: id,
                editing: editing,
            });
        });
        if (!editing && state.app.showCamera && state.ips.items.find(function (item) { return item.isEditing; })) {
            dispatch(clearSearchList());
        }
    };
}
export function removeItem(mode, id) {
    return function (dispatch, getState) {
        storage.remove(mode, id).then(function (_) {
            dispatch({
                type: REMOVE_ITEM,
                mode: mode,
                id: id,
            });
        });
    };
}
export function changeItemQuantity(mode, id, value) {
    return function (dispatch, getState) {
        var item = getState().ips.items.find(function (item) { return id === item.id; });
        item.quantity = value;
        storage.update(mode, item).then(function (_) {
            dispatch({
                type: CHANGE_ITEM_QUANTITY,
                mode: mode,
                id: id,
                value: value,
            });
        });
    };
}
export function changeItemPrice(mode, id, value) {
    return function (dispatch, getState) {
        var item = getState().ips.items.find(function (item) { return id === item.id; });
        item.price = value;
        storage.update(mode, item).then(function (_) {
            dispatch({
                type: CHANGE_ITEM_PRICE,
                mode: mode,
                id: id,
                value: value,
            });
        });
    };
}
export function changeTaxRate(mode, value) {
    return function (dispatch, getState) {
        dispatch({
            type: CHANGE_TAX_RATE,
            mode: mode,
            value: value,
        });
    };
}
export function exportList(mode) {
    return function (dispatch, getState) {
        dispatch({
            type: EXPORT_LIST_BEGIN,
            mode: mode,
        });
        var state = getState().ips, payload = Object.assign({}, { isIps: mode === 'ips', items: state.items.slice(0) });
        return server
            .postJson('/ips/order', payload)
            .then(function (_) {
            storage.deleteOrder(mode).then(function (_) {
                dispatch({
                    type: EXPORT_LIST_SUCCESS,
                    mode: mode,
                });
            });
        })
            .catch(function (e) {
            dispatch({
                type: EXPORT_LIST_FAIL,
                mode: mode,
            });
        });
    };
}
