import { componentIsSearch } from './Pages/Search/current-page-is-search';
import { HIDE_QUICKSEARCH, SHOW_QUICKSEARCH, UPDATE_QUICKSEARCH_TEXT, UPDATE_QUICKSEARCH_COMPLETIONS, UPDATE_SELECTED_QUICKSEARCH_COMPLETION, UPDATE_QUICKSEARCH_CART, CLEAR_QUICKSEARCH_CART, CLOSE_QUICKSEARCH, SEARCH_HISTORY_LIST_ID, POPULAR_SEARCHES_LIST_ID, SUGGESTIONS_LIST_ID, } from './action-creators';
import { PAGE_LOAD_DONE, PAGE_LOAD } from 'SiteLayout/CurrentPage/action-creators';
import { parseUrl } from 'Shared/url';
export default function (state, action, currentPage, appShellData) {
    if (state === void 0) { state = null; }
    if (state === null) {
        state = {
            isOpen: false,
            isListOpen: false,
            searchText: getSearchTextFromCurrentPage(currentPage) || '',
            suggestion: null,
            completionsLists: [],
            cartItems: [],
            selectedCompletionIndex: -1,
            selectedCompletionType: 'product',
            originalSearchTerm: null,
            searchPage: appShellData.mainSearchPage,
            totalItems: 0
        };
    }
    switch (action.type) {
        case HIDE_QUICKSEARCH: {
            var hideAction = action;
            var suggestion = hideAction.setFirstSuggestionAsSearchText
                ? getQuerySuggestion(state.searchText, state.completionsLists, 0)
                : state.searchText;
            return Object.assign({}, state, {
                isOpen: false,
                searchText: suggestion || state.searchText,
                suggestion: null,
                cartItems: [],
            });
        }
        case CLOSE_QUICKSEARCH: {
            return Object.assign({}, state, {
                isOpen: false,
                searchText: '',
                suggestion: null,
                cartItems: [],
            });
        }
        case SHOW_QUICKSEARCH: {
            return Object.assign({}, state, {
                isOpen: true,
                searchPage: appShellData.mainSearchPage
            });
        }
        case PAGE_LOAD: {
            return Object.assign({}, state, {
                isOpen: false,
                searchText: '',
                suggestion: null,
                cartItems: [],
            });
        }
        case PAGE_LOAD_DONE: {
            var loadDoneAction = action;
            var query = getSearchTextFromCurrentPage(currentPage);
            if (query !== null && !state.isOpen) {
                return Object.assign({}, state, {
                    searchText: query,
                    suggestion: null,
                    cartItems: [],
                });
            }
            else if (!componentIsSearch(currentPage.componentName) && !loadDoneAction.serverRedirected) {
                // We don't want to clear the search text if the current component isn't the search page
                // because the server might redirect us from the search page to a brand page if the user
                // searched for a brand
                return Object.assign({}, state, {
                    searchText: '',
                    suggestion: null,
                    cartItems: [],
                });
            }
            break;
        }
        case UPDATE_SELECTED_QUICKSEARCH_COMPLETION: {
            var index = action.index;
            var type = action.selectionType;
            var text = state.originalSearchTerm || state.searchText;
            var suggestion = type === 'item' ? getQuerySuggestion(text, state.completionsLists, index) : text;
            return Object.assign({}, state, {
                selectedCompletionIndex: index,
                selectedCompletionType: type,
                searchText: text,
                suggestion: suggestion || text,
            });
        }
        case UPDATE_QUICKSEARCH_TEXT: {
            var searchText = action.searchText;
            var suggestion = getQuerySuggestion(searchText, state.completionsLists, 0);
            return Object.assign({}, state, {
                searchText: searchText,
                suggestion: suggestion,
                selectedCompletionIndex: -1,
                originalSearchTerm: searchText,
            });
        }
        case UPDATE_QUICKSEARCH_COMPLETIONS: {
            var completionsAction = action;
            var newState = Object.assign({}, state, {
                completionsLists: completionsAction.lists,
                totalItems: completionsAction.totalItems
            });
            var newListIds_1 = completionsAction.lists.map(function (l) { return l.id; });
            newState.completionsLists = newState.completionsLists
                .concat(state.completionsLists.filter(function (l) { return newListIds_1.indexOf(l.id) === -1; }))
                .sort(sortLists);
            return newState;
        }
        case UPDATE_QUICKSEARCH_CART: {
            var cartAction_1 = action;
            var stateCartItems = state.cartItems.filter(function (i) { return i.code !== cartAction_1.code; });
            if (cartAction_1.quantity && cartAction_1.quantity > 0) {
                stateCartItems.push({
                    code: cartAction_1.code,
                    quantity: cartAction_1.quantity,
                    unitShortName: cartAction_1.unitShortName,
                    ticket: cartAction_1.ticket,
                });
            }
            var newState = Object.assign({}, state, {
                cartItems: stateCartItems,
            });
            return newState;
        }
        case CLEAR_QUICKSEARCH_CART: {
            return Object.assign({}, state, {
                cartItems: [],
            });
        }
        default:
            break;
    }
    return state;
}
export function getQuerySuggestion(currentQuery, lists, index) {
    var firstNonEmptyList = lists.filter(function (l) { return !!l.items && l.items.length > 0; })[0];
    if (!firstNonEmptyList) {
        return null;
    }
    var firstAutocomplete = index === 0 ? firstNonEmptyList.items[index].text : getCompletionAtIndex(index, lists);
    if (!firstAutocomplete || !currentQuery || currentQuery[currentQuery.length - 1] === ' ') {
        return currentQuery;
    }
    var currentWords = currentQuery.split(' ').filter(function (s) { return !!s; });
    var autocompleteWords = firstAutocomplete.split(' ').filter(function (s) { return !!s; });
    if (currentWords.length > autocompleteWords.length) {
        return currentQuery;
    }
    var lastIndex = currentWords.length - 1;
    for (var i = 0; i < lastIndex; i++) {
        if (currentWords[i].toLowerCase() !== autocompleteWords[i].toLowerCase()) {
            return currentQuery;
        }
    }
    if (!autocompleteWords[lastIndex].toLowerCase().startsWith(currentWords[lastIndex].toLowerCase())) {
        return currentQuery;
    }
    return currentQuery + autocompleteWords[lastIndex].substr(currentWords[lastIndex].length);
}
var listOrder = [SUGGESTIONS_LIST_ID, SEARCH_HISTORY_LIST_ID, POPULAR_SEARCHES_LIST_ID];
function sortLists(a, b) {
    return listOrder.indexOf(a.id) - listOrder.indexOf(b.id);
}
function getCompletionAtIndex(index, lists) {
    if (index > 0) {
        return lists.reduce(function (a, b) { return a.concat(b.items); }, [])[index].text;
    }
    return '';
}
function getSearchTextFromCurrentPage(currentPage) {
    if (componentIsSearch(currentPage.componentName)) {
        var currentUrl = parseUrl(window.location);
        return getSearchTextFromUrl(currentUrl);
    }
    else {
        return null;
    }
}
export function getSearchTextFromUrl(url) {
    return url && url.query ? url.query['q'] || '' : '';
}
