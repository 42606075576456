import React from 'react';
import { translate } from 'Shared/translate';
import highlightText from 'Shared/highlight-text';
import { searchTextToWords } from './../../action-creators';
import { searchPageUrl } from 'Shared/known-urls';
import { urlToString } from 'Shared/url';
var CompletionList = function (props) {
    return (props.completions &&
        props.completions.length > 0 && (React.createElement("div", null,
        React.createElement("h2", null, translate('/Search/ResultsForCompletion', props.searchPhrase)),
        React.createElement("ul", null, props.completions.map(function (item, i) {
            return (React.createElement("li", { key: i },
                React.createElement("a", { href: urlToString({ path: searchPageUrl(), query: { q: item.text } }), "data-ticket": item.ticket }, highlightText(item.text, searchTextToWords(props.searchPhrase)))));
        })))));
};
export default CompletionList;
