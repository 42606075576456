var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import { Input } from 'Shared/Fields';
var PopupInputForm = /** @class */ (function (_super) {
    __extends(PopupInputForm, _super);
    function PopupInputForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            email: !!_this.props.hasValidEmail ? _this.props.email : '',
            firstName: _this.props.firstName,
            lastName: _this.props.lastName,
        };
        return _this;
    }
    PopupInputForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement(Main, { appearance: "narrow", className: styles.base },
                React.createElement("div", { className: styles.accountSettings },
                    React.createElement(Icon, { className: styles.accountSettingsIcon, name: 'update-accountsettings' }),
                    React.createElement("div", { className: styles.content },
                        React.createElement("div", { className: styles.information },
                            React.createElement("div", { className: styles.informationHeader },
                                React.createElement(PropertyFor, { property: this.props.preHeader, tagName: "h3" }),
                                React.createElement(PropertyFor, { property: this.props.header, tagName: "h1" })),
                            React.createElement(PropertyFor, { property: this.props.information, tagName: "p" })),
                        React.createElement("div", { className: styles.form },
                            React.createElement("form", null,
                                React.createElement("fieldset", null,
                                    React.createElement("div", { className: styles.accountSettingsFormWrapper },
                                        React.createElement("div", { className: styles.textInputs },
                                            React.createElement("div", { className: styles.nameWrapper },
                                                React.createElement("div", { className: styles.fieldWrapper },
                                                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/FirstName'), disabled: this.props.isFormSubmitted, type: "text", value: this.state.firstName, name: "firstName", spellCheck: false, autoCorrect: "off", maxLength: 50, required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterName'), onValidate: function () { return _this.props.validateNameLength(_this.state.firstName, "firstName"); }, showValidationMessage: false, onChange: function (e) {
                                                            _this.setState({ firstName: e.target.value });
                                                            _this.props.setFirstName(e.target.value);
                                                        } })),
                                                React.createElement("div", { className: styles.fieldWrapper },
                                                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/LastName'), disabled: this.props.isFormSubmitted, type: "text", value: this.state.lastName, name: "lastName", maxLength: 50, spellCheck: false, autoCorrect: "off", required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterName'), onValidate: function () { return _this.props.validateNameLength(_this.state.lastName, "lastName"); }, showValidationMessage: false, onChange: function (e) {
                                                            _this.setState({ lastName: e.target.value });
                                                            _this.props.setLastName(e.target.value);
                                                        } }))),
                                            React.createElement("div", { className: styles.fieldWrapper },
                                                React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/Email'), disabled: this.props.isFormSubmitted, type: "text", name: "email", value: this.state.email, spellCheck: false, autoCorrect: "off", required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterValidEmail'), onValidate: function () { return _this.props.validateEmail(_this.state.email); }, onChange: function (e) {
                                                        _this.props.setEmail(e.target.value);
                                                        _this.setState({ email: e.target.value });
                                                    } }))),
                                        React.createElement("div", { className: styles.buttonsWrapper },
                                            React.createElement("div", { className: styles.saveChanges },
                                                React.createElement(FeedbackButton, { disabled: this.props.isFormSubmitted || this.props.invalid, appearance: ['full', 'primary'], type: "submit", onClickWithFeedback: function (e, activate) {
                                                        var promiseOrUndefined = _this.props.onSubmit(e);
                                                        if (promiseOrUndefined) {
                                                            activate(promiseOrUndefined, { minimumPending: _this.props.feedbackDelay })
                                                                .then(function () {
                                                                return _this.setState({
                                                                    firstName: '',
                                                                    lastName: '',
                                                                    email: '',
                                                                });
                                                            })
                                                                .catch(function () { return console.log('failed'); });
                                                        }
                                                    } }, translate('/Shared/Send'))),
                                            this.props.skipCount < 4 && (React.createElement("div", { className: styles.doThisLater },
                                                React.createElement(Button, { appearance: 'link', onClick: function () { return _this.props.SkipOktaCollectDataForm(); } }, translate('/Shared/SkipForm'))))))))))))));
    };
    return PopupInputForm;
}(React.Component));
export default PopupInputForm;
