var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import connect from 'Shared/connect';
import Quantity from 'Shared/Quantity';
import classnames from 'classnames';
import Button from 'Shared/Button';
import FeedbackButton from 'Shared/Button/Feedback';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import Main from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import EditCustomProduct from './EditCustomProduct';
import { onEditProductAction, onConfirmDeleteAction, onDeleteItemsAction, onEditNutrientAction, onSaveFormAction, SortOrder, } from './action-creators';
import { Checkbox, Input } from 'Shared/Fields';
import Modal from 'Shared/Modal';
import { MEDIUM, TINY } from 'Shared/breakpoints';
import * as styles from './Pages/CustomProductOverview/base.scss';
import shallowEquals from 'Shared/shallow-equals';
import ListItemCustomProduct from './ListItemCustomProduct';
import EditNutrient from './EditNutrient';
import Dialog, { DialogType } from 'Shared/Dialog';
import { timeout } from 'Shared/timeout';
var defaultNutrients = {
    customProductId: '',
    customProductName: '',
    nutrients: [],
    selectableNutrients: [],
};
var defaultRemoveModel = {
    usedBySlavesInPantries: false,
    usedBySlavesInRecipes: false,
};
var inputCalcDebouncer = 400;
var ListCustomProducts = /** @class */ (function (_super) {
    __extends(ListCustomProducts, _super);
    function ListCustomProducts(props) {
        var _this = _super.call(this, props) || this;
        _this.hideAddModal = function () {
            _this.setState({ isProductModalOpen: false });
            _this.enableBodyScroll();
        };
        _this.hideEditModal = function () {
            _this.setState({ isProductModalOpen: false });
            _this.enableBodyScroll();
        };
        _this.setSaved = function (id) {
            var savedIds = [id];
            _this.setState({ savedIds: savedIds, isProductModalOpen: false }, function () {
                timeout(100).then(function () {
                    _this.filterCustomProductList(_this.state.searchValue);
                });
            });
        };
        _this.onAfterSave = function () {
            _this.setState({ isProductModalOpen: false }, function () {
                timeout(100).then(function () {
                    _this.filterCustomProductList(_this.state.searchValue);
                });
            });
        };
        _this.hideNutrientModal = function () {
            _this.setState({ isNutrientModalOpen: false });
        };
        _this.onConfirmDeleteClick = function (selectedIds) {
            return _this.props.onDeleteItemsAction(selectedIds).then(function () {
                timeout(2000).then(function () {
                    _this.hideDeleteModal();
                    _this.filterCustomProductList(_this.state.searchValue);
                });
            });
        };
        _this.hideDeleteModal = function () {
            _this.setState({ isDeleteModalOpen: false });
        };
        _this.state = {
            selectedIds: [],
            savedIds: [],
            searchValue: '',
            calculateValue: 0,
            disabled: false,
            items: props.items,
            suppliers: props.suppliers,
            allSelected: false,
            showOnlyLockedItems: false,
            sortBy: null,
            isProductModalOpen: false,
            isDeleteModalOpen: false,
            isNutrientModalOpen: false,
            currentItem: null,
            secondCategoryLevel: [],
            thirdCategoryLevel: [],
            removeModel: defaultRemoveModel,
            idsToRemove: [],
            nutrients: defaultNutrients,
            pageCount: [],
            isLoading: false,
            filteredItems: props.items,
            visibleItems: [],
            totalNumberOfItems: props.totalNumberOfItems,
            activePage: 1,
        };
        return _this;
    }
    ListCustomProducts.prototype.componentDidMount = function () {
        this.loadVisiblePageItems(1);
    };
    ListCustomProducts.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.items.length !== this.props.items.length) {
            this.setState({ items: this.props.items, selectedIds: [], allSelected: false });
        }
        else if (!shallowEquals(prevProps.items, this.props.items, true)) {
            this.setState({ items: this.props.items });
        }
    };
    ListCustomProducts.prototype.updateSelected = function (id) {
        var selectedIds = Object.assign([], this.state.selectedIds);
        var arrayIndex = selectedIds.indexOf(id);
        var allSelected = false;
        if (arrayIndex >= 0) {
            selectedIds.splice(arrayIndex, 1);
        }
        else {
            selectedIds.push(id);
            allSelected = this.getVisibleIds().length === selectedIds.length;
        }
        this.setState({ selectedIds: selectedIds, allSelected: allSelected });
    };
    ListCustomProducts.prototype.updatePrice = function (id, value) {
        var items = Object.assign([], this.state.items);
        var item = items.find(function (item) { return item.id === id; });
        item.price = value;
        this.setState({
            items: items
        });
    };
    ListCustomProducts.prototype.updateDisplayName = function (id, value) {
        var items = Object.assign([], this.state.items);
        var item = items.find(function (item) { return item.id === id; });
        item.displayName = value;
        this.setState({
            items: items,
        });
    };
    ListCustomProducts.prototype.handleEdit = function (id) {
        var _this = this;
        var item = this.state.items.find(function (item) { return item.id === id; });
        onEditProductAction(item).then(function (customProduct) {
            _this.showEditModal(item, customProduct.secondLevels, customProduct.thirdLevels);
        });
    };
    ListCustomProducts.prototype.showAddModal = function () {
        this.setState({
            isProductModalOpen: true,
            currentItem: null,
            secondCategoryLevel: [],
            thirdCategoryLevel: [],
        });
        this.disableBodyScroll();
    };
    ListCustomProducts.prototype.showEditModal = function (item, secondLevels, thirdLevels) {
        this.setState({
            isProductModalOpen: true,
            currentItem: item,
            secondCategoryLevel: secondLevels,
            thirdCategoryLevel: thirdLevels,
        });
        this.disableBodyScroll();
    };
    ListCustomProducts.prototype.showNutrientModal = function (nutrients) {
        this.setState({
            isNutrientModalOpen: true,
            nutrients: __assign({}, nutrients),
        });
    };
    ListCustomProducts.prototype.editNutrients = function (id) {
        var _this = this;
        onEditNutrientAction(id).then(function (nutrients) {
            _this.showNutrientModal(nutrients);
        });
    };
    ListCustomProducts.prototype.deleteCustomItem = function (id) {
        var _this = this;
        var ids = [id];
        onConfirmDeleteAction(ids).then(function (json) {
            _this.showDeleteModal(json, ids);
        });
    };
    ListCustomProducts.prototype.onDeleteMultipleItems = function (e) {
        var _this = this;
        e.preventDefault();
        if (this.state.selectedIds.length > 0) {
            onConfirmDeleteAction(this.state.selectedIds).then(function (json) {
                _this.showDeleteModal(json, _this.state.selectedIds);
            });
        }
    };
    ListCustomProducts.prototype.showDeleteModal = function (removeModel, selectedIds) {
        this.setState({
            isDeleteModalOpen: true,
            removeModel: removeModel,
            idsToRemove: selectedIds,
        });
        this.props.dismissFeedback();
    };
    ListCustomProducts.prototype.checkCalculatedValueValidity = function (value) {
        return this.state.calculateValue >= 0 && this.state.calculateValue <= 100;
    };
    ListCustomProducts.prototype.onCalculate = function (inputValue) {
        var _this = this;
        clearTimeout(this.inputCalcTimer);
        this.inputCalcTimer = setTimeout(function () {
            if (!_this.checkCalculatedValueValidity(inputValue)) {
                _this.setState({ disabled: true });
            }
            _this.setState({ calculateValue: inputValue, disabled: false });
        }, inputCalcDebouncer);
        return Promise.resolve();
    };
    ListCustomProducts.prototype.formatPrice = function (price, calculateValue) {
        var newPrice = price;
        if (calculateValue > 0 && calculateValue <= 100) {
            newPrice = price * (calculateValue / 100 + 1);
        }
        return newPrice;
    };
    ListCustomProducts.prototype.onSave = function () {
        var _this = this;
        var calculateValue = this.state.calculateValue;
        var items = this.state.items.filter(function (item) {
            return !item.isLocked && (!_this.state.searchValue || (_this.state.filteredItems && _this.state.filteredItems.includes(item)));
        });
        var savedIds = [];
        var customProductChangeItems = !!items && items.map(function (item) {
            savedIds.push(item.id);
            return {
                id: item.id,
                price: _this.formatPrice(item.price, calculateValue),
                displayName: item.displayName,
            };
        });
        var updatedPriceItems;
        if (this.state.calculateValue > 0) {
            updatedPriceItems = this.state.items.map(function (item) {
                if (!item.isLocked && (!_this.state.searchValue || (_this.state.filteredItems && _this.state.filteredItems.includes(item)))) {
                    var newItem = Object.assign({}, item);
                    newItem.price = _this.formatPrice(item.price, calculateValue);
                    return newItem;
                }
                return item;
            });
        }
        this.setState({ disabled: !this.state.disabled });
        return onSaveFormAction(customProductChangeItems)
            .then(function (json) {
            var feedbackMessage = json.success
                ? translate('/CustomProduct/Table/SaveMessage')
                : translate('/CustomProduct/Table/FeedbackMessageError');
            if (json.success) {
                if (updatedPriceItems && updatedPriceItems.length > 0) {
                    _this.setState({
                        calculateValue: 0,
                        selectedIds: [],
                        allSelected: false,
                        items: updatedPriceItems,
                        disabled: false,
                        savedIds: savedIds,
                    });
                }
                else {
                    _this.setState({
                        selectedIds: [],
                        allSelected: false,
                        disabled: false,
                        savedIds: savedIds,
                    });
                }
                _this.props.setFeedback({
                    text: feedbackMessage,
                    type: 'success',
                });
                return Promise.resolve();
            }
            else {
                _this.props.setFeedback({
                    text: feedbackMessage,
                    type: 'error',
                });
                return Promise.reject(null);
            }
        })
            .catch(function (e) {
            _this.setState({ disabled: false });
            return Promise.reject(null);
        });
    };
    ListCustomProducts.prototype.filterCustomProductList = function (value) {
        var _this = this;
        var filterValue = !!value && value.toLowerCase();
        var filteredRecords = !!this.state.items && this.state.items.filter(function (item) {
            return (!value || (!!item.supplier && item.supplier.toLowerCase().indexOf(filterValue) > -1)
                || (!!item.productId && item.productId.indexOf(value) > -1)
                || (!!item.displayName && item.displayName.toLowerCase().indexOf(filterValue) > -1));
        });
        this.setState({
            searchValue: value,
            selectedIds: [],
            calculateValue: 0,
            allSelected: false,
            activePage: 1,
            filteredItems: filteredRecords,
        }, function () { return _this.loadVisiblePageItems(1); });
    };
    ListCustomProducts.prototype.showOnlyLocked = function () {
        var _this = this;
        this.setState({
            showOnlyLockedItems: !this.state.showOnlyLockedItems,
            allSelected: false,
        }, function () { return _this.loadVisiblePageItems(1); });
    };
    ListCustomProducts.prototype.customSort = function (nameA, nameB) {
        if ((nameA || '').toLowerCase() < (nameB || '').toLowerCase()) {
            return -1;
        }
        if ((nameA || '').toLowerCase() > (nameB || '').toLowerCase()) {
            return 1;
        }
        return 0;
    };
    ListCustomProducts.prototype.onSortList = function (sortBy) {
        var _this = this;
        var list = Object.assign([], this.state.filteredItems);
        switch (sortBy) {
            case SortOrder.SORT_TITLE_DESC:
                return list.sort(function (a, b) { return _this.customSort(b.displayName, a.displayName); });
            case SortOrder.SORT_TITLE_ASC:
                return list.sort(function (a, b) { return _this.customSort(a.displayName, b.displayName); });
            case SortOrder.SORT_ARTNR_DESC:
                return list.sort(function (a, b) { return _this.customSort(b.productId, a.productId); });
            case SortOrder.SORT_ARTNR_ASC:
                return list.sort(function (a, b) { return _this.customSort(a.productId, b.productId); });
            case SortOrder.SORT_SUPPLIER_DESC:
                return list.sort(function (a, b) { return _this.customSort(b.supplier, a.supplier); });
            case SortOrder.SORT_SUPPLIER_ASC:
                return list.sort(function (a, b) { return _this.customSort(a.supplier, b.supplier); });
            default:
                return list;
        }
    };
    ListCustomProducts.prototype.sortList = function (e, sortBy) {
        var _this = this;
        e.preventDefault();
        this.setState({
            sortBy: sortBy,
            filteredItems: this.onSortList(sortBy),
        }, function () { return _this.loadVisiblePageItems(1); });
    };
    ListCustomProducts.prototype.getVisibleIds = function () {
        var _this = this;
        return this.state.visibleItems.map(function (item) {
            return !item.isLocked && (!_this.state.searchValue || _this.state.searchValue === item.supplier) && item.id;
        });
    };
    ListCustomProducts.prototype.selectAll = function () {
        var newValue = !this.state.allSelected;
        var selectedIds = !newValue ? [] : this.getVisibleIds();
        this.setState({
            allSelected: newValue,
            selectedIds: selectedIds,
        });
    };
    ListCustomProducts.prototype.onSelectPage = function (value) {
        var _this = this;
        this.setState({
            activePage: value,
            isLoading: true,
        }, function () { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); _this.loadVisiblePageItems(value); });
    };
    ListCustomProducts.prototype.loadVisiblePageItems = function (pageNumber) {
        var indexOfLastRecord = pageNumber * this.state.totalNumberOfItems;
        var indexOfFirstRecord = indexOfLastRecord - this.state.totalNumberOfItems;
        var currentRecords = this.state.showOnlyLockedItems ? this.state.filteredItems.filter(function (x) { return !!x.isLocked; }).slice(indexOfFirstRecord, indexOfLastRecord) : this.state.filteredItems.slice(indexOfFirstRecord, indexOfLastRecord);
        var pages = Array();
        var isDisableNextNav = true;
        if (typeof this.state.filteredItems !== typeof undefined) {
            for (var i = 1; i <= Math.ceil(this.state.filteredItems.length / this.state.totalNumberOfItems); i++) {
                pages.push(i);
            }
            isDisableNextNav =
                Math.ceil(indexOfLastRecord / this.state.totalNumberOfItems) !==
                    Math.ceil(this.state.filteredItems.length / this.state.totalNumberOfItems);
        }
        this.setState({
            isDisableNextNav: indexOfFirstRecord !== 1 && isDisableNextNav,
            isDisablePrevNav: indexOfFirstRecord >= this.state.totalNumberOfItems,
            pageCount: pages,
            isLoading: false,
            visibleItems: currentRecords
        });
    };
    ListCustomProducts.prototype.onSelectNavPageAction = function (currentPage, action) {
        var _this = this;
        var nextPage = action === 'next' ? currentPage + 1 : currentPage - 1;
        this.setState({
            activePage: nextPage,
            isLoading: false,
        }, function () { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); _this.loadVisiblePageItems(nextPage); });
    };
    ListCustomProducts.prototype.getSuppliers = function () {
        var _this = this;
        var suppliers = __spread(this.state.suppliers);
        if (!!this.state.currentItem && !!this.state.currentItem.supplier && !suppliers.some(function (x) { return x == _this.state.currentItem.supplier; }))
            suppliers.push(this.state.currentItem.supplier);
        return suppliers;
    };
    ListCustomProducts.prototype.disableBodyScroll = function () {
        document.body.setAttribute("style", "overflow:hidden");
    };
    ListCustomProducts.prototype.enableBodyScroll = function () {
        document.body.setAttribute("style", "overflow:visible");
    };
    ListCustomProducts.prototype.handleScrollInModal = function (disable) {
        this.setState({
            disableScrollInModal: disable
        });
    };
    ListCustomProducts.prototype.renderModals = function () {
        var _this = this;
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { className: styles.addModalOverflowVisible, isOpen: this.state.isNutrientModalOpen, onClose: this.hideNutrientModal, title: translate('/CustomProduct/Nutrient/Title') + " " + this.state.nutrients.customProductName },
                React.createElement(EditNutrient, { nutrientListViewModel: this.state.nutrients })),
            React.createElement(Modal, { isOpen: this.state.isDeleteModalOpen, onClose: this.hideDeleteModal },
                React.createElement("div", null,
                    React.createElement("p", null,
                        translate('/CustomProduct/Table/DeleteModalTitle'),
                        ":"),
                    React.createElement("ul", { className: styles.deleteArticleList }, !!this.state.items && this.state.items
                        .filter(function (i) { return _this.state.idsToRemove.includes(i.id); })
                        .map(function (product, index) { return (React.createElement("li", { className: styles.listItem, key: index },
                        product.displayName || product.productId,
                        _this.props.pantryList && _this.props.pantryList[product.id] && (React.createElement("div", null, translate('/CustomProduct/Table/WillBeDeletedInPantry', _this.props.pantryList[product.id]))),
                        _this.props.recipeList && _this.props.recipeList[product.id] && (React.createElement("div", null, translate('/CustomProduct/Table/WillBeDeletedInRecipe', _this.props.recipeList[product.id]))))); })),
                    this.state.removeModel.usedBySlavesInPantries && React.createElement("p", null, translate('/CustomProduct/Table/UsedInPantries')),
                    this.state.removeModel.usedBySlavesInRecipes && React.createElement("p", null, translate('/CustomProduct/Table/UsedInRecipes'))),
                React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return _this.onConfirmDeleteClick(_this.state.idsToRemove); }, onCancel: this.hideDeleteModal })),
            React.createElement(Modal, { className: classnames((_a = {},
                    _a[styles.addModalMobile] = this.props.currentBreakpoint < MEDIUM,
                    _a[styles.addModal] = this.props.currentBreakpoint >= MEDIUM,
                    _a[styles.disableScrollInModal] = this.state.disableScrollInModal,
                    _a)), isOpen: this.state.isProductModalOpen, onClose: this.hideEditModal, title: this.state.currentItem ? translate('/Account/CustomProduct/Edit') : translate('/Account/CustomProduct/Add') },
                React.createElement(EditCustomProduct, { customProductItem: this.state.currentItem, secondLevels: this.state.secondCategoryLevel, thirdLevels: this.state.thirdCategoryLevel, firstLevels: this.props.firstLevels, areas: this.props.areas, suppliers: this.getSuppliers(), units: this.props.units, setSaved: this.setSaved, onSaveComplete: this.onAfterSave, isMobile: this.props.currentBreakpoint < MEDIUM, disableScroll: function () { _this.handleScrollInModal(true); }, enableScroll: function () { _this.handleScrollInModal(false); } }))));
    };
    ListCustomProducts.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var itemsExist = !!this.state.items && this.state.items.length > 0;
        var hasLockedItems = itemsExist &&
            this.state.items.some(function (item) {
                return item.isLocked;
            });
        return (React.createElement("div", { className: styles.sectionWrapper },
            React.createElement(Main, { className: styles.main, appearance: "narrow" },
                React.createElement("h2", null, translate('/CustomProduct/Table/CustomArticlesTitle')),
                React.createElement("div", { className: styles.tableArea },
                    React.createElement("div", { className: styles.topArea },
                        itemsExist && (React.createElement("div", { className: styles.suppliers },
                            React.createElement("div", null,
                                React.createElement(Input, { type: "search", placeholderText: 'S\u00F6k p\u00E5 egna varor', onChange: function (e) { return _this.filterCustomProductList(e.currentTarget.value); } })))),
                        React.createElement("div", { className: styles.topButtonArea },
                            React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.showAddModal(); } }, translate('/CustomProduct/Table/AddNewButton')),
                            itemsExist && (React.createElement(Button, { appearance: "secondary", onClick: function (e) { return _this.onDeleteMultipleItems(e); } }, translate('/CustomProduct/Table/RemoveCheckedButton'))))),
                    itemsExist && (React.createElement("div", null,
                        React.createElement("table", { className: styles.table },
                            React.createElement("tbody", null,
                                this.props.currentBreakpoint > TINY ? (React.createElement("tr", { className: styles.tableRow },
                                    React.createElement("th", { className: classnames(styles.tableHeader, styles.code) },
                                        React.createElement(Checkbox, { checked: this.state.allSelected, onChange: function () { return _this.selectAll(); }, disabled: this.state.showOnlyLockedItems }),
                                        React.createElement("a", { href: void 0, className: classnames((_a = {},
                                                _a[styles.arrowUp] = this.state.sortBy === SortOrder.SORT_ARTNR_DESC,
                                                _a[styles.arrowDown] = this.state.sortBy === SortOrder.SORT_ARTNR_ASC,
                                                _a)), onClick: function (e) {
                                                return _this.sortList(e, _this.state.sortBy === SortOrder.SORT_ARTNR_ASC
                                                    ? SortOrder.SORT_ARTNR_DESC
                                                    : SortOrder.SORT_ARTNR_ASC);
                                            } }, translate('/CustomProduct/Table/ProductId'))),
                                    React.createElement("th", { className: classnames(styles.tableHeader, styles.displayName) },
                                        React.createElement("a", { href: void 0, className: classnames((_b = {},
                                                _b[styles.arrowUp] = this.state.sortBy === SortOrder.SORT_TITLE_DESC,
                                                _b[styles.arrowDown] = this.state.sortBy === SortOrder.SORT_TITLE_ASC,
                                                _b)), onClick: function (e) {
                                                return _this.sortList(e, _this.state.sortBy === SortOrder.SORT_TITLE_ASC
                                                    ? SortOrder.SORT_TITLE_DESC
                                                    : SortOrder.SORT_TITLE_ASC);
                                            } }, translate('/CustomProduct/Table/DisplayName'))),
                                    React.createElement("th", { className: styles.tableHeader },
                                        React.createElement("a", { href: void 0, className: classnames((_c = {},
                                                _c[styles.arrowUp] = this.state.sortBy === SortOrder.SORT_SUPPLIER_DESC,
                                                _c[styles.arrowDown] = this.state.sortBy === SortOrder.SORT_SUPPLIER_ASC,
                                                _c)), onClick: function (e) {
                                                return _this.sortList(e, _this.state.sortBy === SortOrder.SORT_SUPPLIER_ASC
                                                    ? SortOrder.SORT_SUPPLIER_DESC
                                                    : SortOrder.SORT_SUPPLIER_ASC);
                                            } }, translate('/CustomProduct/Table/Supplier'))),
                                    React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Table/Specification')),
                                    React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Table/UnitShortName')),
                                    React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Table/Price')),
                                    React.createElement("th", { className: styles.tableHeader, colSpan: 4 }, hasLockedItems && (React.createElement("div", { className: styles.showOnlyLocked },
                                        React.createElement(Checkbox, { checked: this.state.showOnlyLockedItems, onChange: function () { return _this.showOnlyLocked(); } }, translate('/CustomProduct/Table/ShowOnlyLocked'))))))) : (React.createElement("tr", null,
                                    React.createElement("td", { colSpan: 3 },
                                        React.createElement(DropDownMenu, { title: translate('/CustomProduct/Table/Sort') },
                                            React.createElement(DropDownItem, { value: SortOrder.SORT_ARTNR_ASC, text: translate('/CustomProduct/Table/ProductId'), onClick: function (e) { return _this.sortList(e, SortOrder.SORT_ARTNR_ASC); } }),
                                            React.createElement(DropDownItem, { value: SortOrder.SORT_TITLE_ASC, text: translate('/CustomProduct/Table/DisplayName'), onClick: function (e) { return _this.sortList(e, SortOrder.SORT_TITLE_ASC); } }),
                                            React.createElement(DropDownItem, { value: SortOrder.SORT_SUPPLIER_ASC, text: translate('/CustomProduct/Table/Supplier'), onClick: function (e) { return _this.sortList(e, SortOrder.SORT_SUPPLIER_ASC); } }))))),
                                !!this.state.visibleItems && this.state.visibleItems.map(function (item, index) {
                                    return (React.createElement(ListItemCustomProduct, __assign({ key: item.id }, item, { currentBreakpoint: _this.props.currentBreakpoint, isSelected: ((!item.isLocked && _this.state.allSelected) || !!_this.state.selectedIds && _this.state.selectedIds.indexOf(item.id) > -1), calculateValue: _this.state.calculateValue, pantryList: (!!_this.props.pantryList && _this.props.pantryList[item.id]), updateSelected: function (id) { return _this.updateSelected(id); }, handleEdit: function (id) { return _this.handleEdit(id); }, updatePrice: function (id, value) { return _this.updatePrice(id, value); }, updateDisplayName: function (id, value) { return _this.updateDisplayName(id, value); }, editNutrients: function (id) { return _this.editNutrients(id); }, deleteCustomItem: function (id) { return _this.deleteCustomItem(id); }, isSaved: (_this.state.savedIds && !!_this.state.savedIds.find(function (x) { return x == item.id; }) && _this.state.savedIds.indexOf(item.id) > -1) })));
                                }))),
                        React.createElement("div", { className: styles.markupArea },
                            React.createElement("div", null,
                                React.createElement("b", null, translate('/CustomProduct/Table/CalculateElement'))),
                            React.createElement("div", { className: styles.markupButtonArea },
                                React.createElement("div", { className: styles.quantityWrapper },
                                    React.createElement(Quantity, { value: this.state.calculateValue, update: function (quantity) { return _this.onCalculate(quantity); }, minQuantity: 0, step: 1, unitShortName: '%' })),
                                React.createElement("div", null,
                                    React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.onCalculate(0); } }, translate('/CustomProduct/Table/Undo'))),
                                React.createElement("div", null,
                                    React.createElement(FeedbackButton, { disabled: !!this.state.disabled, appearance: "secondary", onClickWithFeedback: function (e, activate) { return activate(_this.onSave()); } }, translate('/CustomProduct/Table/Save'))))),
                        React.createElement("div", null,
                            React.createElement("span", { className: styles.importantMessage }, this.state.calculateValue > 0 &&
                                this.checkCalculatedValueValidity(this.state.calculateValue) &&
                                translate('/CustomProduct/Table/CustomMessage'))),
                        React.createElement("div", { className: styles.pagingMainContainer },
                            React.createElement("div", { className: styles.paginationContent },
                                React.createElement("ul", { className: classnames(styles.paginate, styles.page) },
                                    React.createElement("li", { className: this.state.isDisablePrevNav ? styles.navpage : styles.notallowednavpage, onClick: this.state.isDisablePrevNav
                                            ? function () { return _this.onSelectNavPageAction(_this.state.activePage, 'prev'); }
                                            : void 0 },
                                        React.createElement("a", { href: void 0, rel: "prev" }, translate('/MyMenigo/OrderOverviewHeaders/Pagination/PageNavPrev'))),
                                    this.state.pageCount.map(function (p, index) {
                                        return _this.state.activePage === p ? (React.createElement("li", { key: index, className: styles.current, onClick: _this.onSelectPage.bind(_this, p) }, p)) : (React.createElement("li", { key: index, onClick: _this.onSelectPage.bind(_this, p) },
                                            React.createElement("a", { href: "#" }, p)));
                                    }),
                                    React.createElement("li", { className: this.state.isDisableNextNav ? styles.navpage : styles.notallowednavpage, onClick: this.state.isDisableNextNav
                                            ? function () { return _this.onSelectNavPageAction(_this.state.activePage, 'next'); }
                                            : void 0 },
                                        React.createElement("a", { href: void 0, rel: "next" }, translate('/MyMenigo/OrderOverviewHeaders/Pagination/PageNavNext'))))))))),
                this.renderModals())));
    };
    return ListCustomProducts;
}(React.PureComponent));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
    onDeleteItemsAction: function (data) {
        return dispatch(onDeleteItemsAction(data));
    },
}); })(ListCustomProducts);
