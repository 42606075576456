import React from 'react';
import ClassificationEntry from './ClassificationEntry';
var ClassificationGroup = function (_a) {
    var title = _a.title, shouldRender = _a.shouldRender, items = _a.items, showEmpty = _a.showEmpty;
    return shouldRender ? (React.createElement("li", null,
        React.createElement("ul", null,
            !!title && (React.createElement("li", null,
                React.createElement("h4", null,
                    title,
                    " "))),
            !!items && items.filter(function (x) { return !!x.valueAsString || showEmpty; }).map(function (item, index) { return (React.createElement(ClassificationEntry, { key: "entry_" + index, shouldRender: !!item.isVisible, title: item.label, content: showEmpty && !!!item.valueAsString ? "-" : item.valueAsString })); })))) : null;
};
export default ClassificationGroup;
