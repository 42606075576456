var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Part } from 'Shared/PageLayout';
import { isIOS } from 'Shared/device-type';
import ProductCardsGrid from 'Shared/ProductCard/ProductRow2.0/ProductCardsGrid';
import FacetList from 'Shared/Facet/FacetList';
import { FadeDropDown } from 'Shared/DropDown';
import FeedbackButton from 'Shared/Button/Feedback';
import ViewLayoutSelection from 'Shared/ViewLayoutSelection';
import { replaceState } from 'Shared/history';
import { translate } from 'Shared/translate';
import { scrollPosition } from 'SiteLayout/Root';
import { TINY, MEDIUM } from 'Shared/breakpoints';
import { parseUrl } from 'Shared/url';
import toggleFacet, { allSelectedValues } from 'Shared/Facet/toggle-facet';
import { freezescroll } from 'Shared/Overlay/base.scss';
import connect from 'Shared/connect';
import { isIE } from 'Shared/device-type';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import { componentIsSearch } from './../../Search/Pages/Search/current-page-is-search';
import classnames from 'classnames';
import * as styles from './base.scss';
import { componentIsCategory } from './../../Category/Commerce/Components/current-page-is-category';
import SelectedFilters from './Components/SelectedFilters';
import CustomQuickFilters from './Components/CustomQuickFilters';
import Spinner from 'Shared/Spinner';
import MobileFiltersAndFacets from './Components/MobileFiltersAndFacets';
var infiniteScrollQueryParam = 'infinitescroll';
var inStockQueryParam = "inStock";
/* TODO remove if no count in bottom const productCountLabelThreshold = 15; */
var ProductsListing = /** @class */ (function (_super) {
    __extends(ProductsListing, _super);
    function ProductsListing(props) {
        var _this = _super.call(this, props) || this;
        _this.onClickShowMore = function (e, activateFeedback) {
            var maybePromise = _this.showMore(_this.props.products.products.length + _this.props.countPerPage, false, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null);
            if (maybePromise instanceof Promise) {
                activateFeedback(maybePromise);
            }
            _this.setState({ infiniteScrollActive: true });
            var maybePromiseScrolling;
            _this.unbindInfiniteScroll = bindInfiniteScroll(_this.productListElement, function () {
                if (maybePromiseScrolling) {
                    return;
                }
                maybePromiseScrolling = _this.showMore(_this.props.products.products.length + _this.props.countPerPage, true, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null);
                if (maybePromiseScrolling instanceof Promise) {
                    activateFeedback(maybePromiseScrolling)
                        .then(function () {
                        maybePromiseScrolling = false;
                    })
                        .catch(function (e) {
                        maybePromiseScrolling = false;
                    });
                }
            });
        };
        _this.hasActiveCustomFilters = function () {
            return !!_this.state.filterFavorites || !!_this.state.filterInStock;
        };
        _this.customFilterCount = function () {
            return Math.min(_this.props.countPerPage * 10, _this.props.products.totalProductCount);
        };
        _this.setFilterFavorites = function (active) {
            _this.setState({ filterFavorites: active, customFiltersLoading: true }, function () {
                if (active)
                    _this.showMore(_this.props.products.products.length + _this.props.countPerPage, false, _this.customFilterCount());
            });
        };
        _this.setFilterInStock = function (active) {
            _this.setState({ filterInStock: active, customFiltersLoading: true }, function () {
                if (active)
                    _this.showMore(_this.props.products.products.length + _this.props.countPerPage, false, _this.customFilterCount());
            });
        };
        _this.customFiltering = function (products) {
            var filterFavorites = function (products) {
                return products.filter(function (x) { return !!x.favoritesList; });
            };
            var filterInStock = function (products) {
                var isInStock = function (product) {
                    return !product.isCustom && !!product.variants && product.variants.every(function (v) { return v.availability != null && (v.availability.availabilityCode != "outOfStock" && v.availability.availabilityCode != "unavailable" && v.availability.availabilityCode != "futureDelivery"); });
                };
                return products.filter(function (x) { return isInStock(x); });
            };
            if (!!_this.hasActiveCustomFilters()) {
                if (!!_this.state.filterInStock) {
                    products = filterInStock(products);
                }
                if (!!_this.state.filterFavorites) {
                    products = filterFavorites(products);
                }
            }
            return products;
        };
        var currentUrl = parseUrl(window.location);
        _this.state = {
            facets: props.products.facets,
            categories: props.products.subCategories,
            campaigns: props.products.campaigns,
            sortBy: props.products.sortOptions.value,
            infiniteScrollActive: currentUrl.query[infiniteScrollQueryParam] === '1',
            deliveryId: props.products.deliveryId,
            filterFavorites: false,
            filterInStock: !!currentUrl.query[inStockQueryParam],
            customFiltersLoading: false,
        };
        _this.lastScrollTop = 0;
        _this.showMoreCountToLoad = 0;
        _this.currentReload = Promise.resolve(null);
        return _this;
    }
    ProductsListing.prototype.componentDidMount = function () {
        if (this.hasOutdatedProductsDeliveryId(this.props)) {
            this.reloadReplaceProducts();
        }
    };
    ProductsListing.prototype.componentWillUnmount = function () {
        this.unbindInfiniteScroll && this.unbindInfiniteScroll();
        this.setState({
            currentSearchPhrase: '',
            filterFavorites: false,
            filterInStock: false,
        });
    };
    ProductsListing.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.hasOutdatedProductsDeliveryId(nextProps) && nextProps.selectedDelivery.id !== this.state.deliveryId) {
            this.setState({ deliveryId: nextProps.selectedDelivery.id });
        }
        if (this.props.products.facets !== nextProps.products.facets) {
            this.setState({
                facets: nextProps.products.facets,
            });
        }
        if (this.props.products.subCategories !== nextProps.products.subCategories) {
            this.setState({
                categories: nextProps.products.subCategories,
            });
        }
        if (this.state.sortBy !== nextProps.products.sortOptions.value) {
            this.setState({
                sortBy: nextProps.products.sortOptions.value,
            });
        }
        if (this.props.products.sortOptions.value !== nextProps.products.sortOptions.value) {
            this.setState({
                sortBy: nextProps.products.sortOptions.value,
            });
        }
        // Because IE has a bug where reading window.location too fast after it has
        // changed doesn't give the correct results
        if (!isIE()) {
            var currentUrl = parseUrl(window.location);
            var infiniteScrollActive = currentUrl.query[infiniteScrollQueryParam] === '1';
            if (infiniteScrollActive !== this.state.infiniteScrollActive) {
                this.setState({ infiniteScrollActive: infiniteScrollActive });
            }
        }
    };
    ProductsListing.prototype.setCustomFiltersOncomponentWillReceiveProps = function (props, prevProps) {
        var currentUrl = props.currentPage.url.path.split(/[?#]/)[0];
        var prevUrl = prevProps.currentPage.url.path.split(/[?#]/)[0];
        var queryUrl = parseUrl(window.location);
        var inStockIsActive = !!queryUrl.query[inStockQueryParam];
        if (!!inStockIsActive) {
            this.setState({
                filterFavorites: false,
                filterInStock: true,
            }, function () {
                history.replaceState({}, '', currentUrl);
                scrollPosition(0);
            });
        }
        else if (currentUrl != prevUrl) {
            this.setState({
                filterFavorites: false,
                filterInStock: false,
            });
        }
    };
    ProductsListing.prototype.hasOutdatedProductsDeliveryId = function (props) {
        return !!props.selectedDelivery && props.selectedDelivery.id !== props.products.deliveryId;
    };
    ProductsListing.prototype.didSelectNewDelivery = function (prevProps, prevState) {
        var hasNewDeliveryId = prevState.deliveryId !== this.state.deliveryId;
        var hasNewUser = this.props.currentUser !== prevProps.currentUser;
        return hasNewDeliveryId && !hasNewUser;
    };
    ProductsListing.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _this = this;
        if (prevState.deliveryId !== this.state.deliveryId && this.props.currentUser === prevProps.currentUser) {
            this.reloadPage(this.props.countPerPage, 0, false, {}, this.hasActiveCustomFilters() ? this.customFilterCount() : null).then(function () { return _this.resetAfterFilterOrSort(); });
        }
        if (this.props.breadcrumbs !== prevProps.breadcrumbs) {
            this.unbindInfiniteScroll && this.unbindInfiniteScroll();
        }
        ;
        this.setCustomFiltersOncomponentWillReceiveProps(this.props, prevProps);
    };
    ProductsListing.prototype.totalProductCount = function () {
        return this.state.infiniteScrollActive
            ? this.props.products.totalProductCount
            : Math.min(this.props.products.totalProductCount, this.props.countPerPage);
    };
    ProductsListing.prototype.allProductsAreLoaded = function () {
        return this.props.products.totalProductCount <= this.visibleProductCount();
    };
    ProductsListing.prototype.scrollNearBottom = function (scroll) {
        if (document.body.scrollHeight / window.pageYOffset < 1.5 || !scroll) {
            return true;
        }
        return false;
    };
    ProductsListing.prototype.showMore = function (count, scroll, customPageSize) {
        var _this = this;
        if (this.allProductsAreLoaded() || !this.scrollNearBottom(scroll) || this.showMoreIsLoading) {
            return;
        }
        var currentProductCount = this.props.products.products.length;
        if (count <= currentProductCount) {
            console.debug('Skipping request to show more for', count, 'because we already have', currentProductCount);
            return;
        }
        if (this.showMoreCountToLoad >= count) {
            console.debug('Skipping request to show more for', count, 'because we have already scheduled load for', this.showMoreCountToLoad);
            return;
        }
        var pages = Math.ceil(count / this.props.countPerPage);
        this.showMoreCountToLoad = pages * this.props.countPerPage;
        var maxLoad = this.props.countPerPage * 10;
        if (this.showMoreCountToLoad - this.props.products.products.length > maxLoad) {
            this.showMoreCountToLoad = this.props.products.products.length + maxLoad;
        }
        var reload = function (skip, count) {
            return _this.reloadPage(count, skip, true, {
                merge: function (currentPage, newProductPage) {
                    if (!currentPage || !currentPage.products || !currentPage.products.products || !!!newProductPage.products) {
                        window.newrelic && window.newrelic.noticeError(new Error('Listing was unknowingly changed'));
                        // If currentPage isn't what we think, then newProducts isn't either.
                        // It's the new state for another page.
                        return newProductPage;
                    }
                    var array = currentPage.products.products.concat(newProductPage.products.products);
                    var newPage = Object.assign({}, currentPage, {
                        canonicalUrl: newProductPage.canonicalUrl,
                        nextUrl: newProductPage.nextUrl,
                        prevUrl: newProductPage.prevUrl,
                        products: Object.assign({}, currentPage.products, { products: array }),
                    });
                    return newPage;
                },
                hideSpinner: true,
            }, customPageSize).then(function () {
                var countAfterLoad = _this.props.products.products.length;
                if (count < _this.showMoreCountToLoad && _this.showMoreCountToLoad > countAfterLoad) {
                    var requested = _this.showMoreCountToLoad - countAfterLoad;
                    if (requested < _this.props.countPerPage) {
                        _this.showMoreCountToLoad = countAfterLoad + _this.props.countPerPage * 2;
                    }
                    return reload(countAfterLoad, _this.showMoreCountToLoad);
                }
                else {
                    _this.showMoreCountToLoad = 0;
                }
                _this.setState({ customFiltersLoading: false });
            });
        };
        this.showMoreIsLoading = true;
        return reload(currentProductCount, this.showMoreCountToLoad).then(function () {
            _this.showMoreIsLoading = false;
            _this.setState({ customFiltersLoading: false });
        });
    };
    ProductsListing.prototype.reloadReplaceProducts = function () {
        var _this = this;
        var reload = function () {
            return _this.reloadPage(_this.props.countPerPage, 0, true, {
                merge: function (currentPage, newProductPage) {
                    if (!currentPage || !currentPage.products || !currentPage.products.products) {
                        window.newrelic && window.newrelic.noticeError(new Error('Listing was unknowingly changed'));
                        // If currentPage isn't what we think, then newProducts isn't either.
                        // It's the new state for another page.
                        return newProductPage;
                    }
                    var newPage = Object.assign({}, currentPage, {
                        canonicalUrl: newProductPage.canonicalUrl,
                        nextUrl: newProductPage.nextUrl,
                        prevUrl: newProductPage.prevUrl,
                        products: Object.assign({}, currentPage.products, { products: _this.customFiltering(newProductPage.products.products) }),
                    });
                    return newPage;
                },
                hideSpinner: true,
            }, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null);
        };
        if (!this.showMoreIsLoading) {
            this.showMoreIsLoading = true;
            reload().then(function () {
                _this.showMoreIsLoading = false;
                _this.setState({ customFiltersLoading: false });
            });
        }
    };
    ProductsListing.prototype.allProductsAreVisible = function () {
        return this.props.products.totalProductCount === this.visibleProductCount();
    };
    ProductsListing.prototype.visibleProductCount = function () {
        var productCount = this.state.infiniteScrollActive
            ? this.props.products.products.length
            : this.props.countPerPage;
        return Math.min(productCount, this.props.products.totalProductCount);
    };
    ProductsListing.prototype.reloadPage = function (count, skip, infiniteScroll, loadOptions, customPageSize) {
        if (loadOptions === void 0) { loadOptions = undefined; }
        var queryParams = this.props.queryParams || {};
        var url = {
            path: window.location.pathname,
            query: Object.assign({}, queryParams, this.getFacetsObject(this.state.facets), {
                sortBy: this.state.sortBy,
            }, !!this.state.currentSearchPhrase ? { searchPhrase: this.state.currentSearchPhrase } : {}),
            hiddenQuery: {
                count: count,
                skip: skip,
                customCount: customPageSize
            },
        };
        if (infiniteScroll || this.state.infiniteScrollActive) {
            url.query[infiniteScrollQueryParam] = '1';
        }
        this.currentReload = this.currentReload.then(function () { return replaceState(url, loadOptions); });
        return this.currentReload;
    };
    ProductsListing.prototype.getFacetsObject = function (facets) {
        var result = {};
        for (var i = 0; i < facets.length; i++) {
            allSelectedValues(facets[i].facetValues).forEach(function (value) {
                if (!result[value.fieldName]) {
                    result[value.fieldName] = [];
                }
                result[value.fieldName].push(value.id);
            });
        }
        var facetsWithAndFilter = facets.filter(function (f) { return f.hasAndFilter; }).map(function (f) { return f.fieldName; });
        if (facetsWithAndFilter.length) {
            result['hasandfilter'] = facetsWithAndFilter;
        }
        return result;
    };
    ProductsListing.prototype.resetAfterFilterOrSort = function () {
        this.showMoreCountToLoad = this.props.products.products.length;
        this.scrollToTopIfMobile();
    };
    ProductsListing.prototype.scrollToTopIfMobile = function () {
        if (this.props.currentBreakpoint <= TINY && this.productListElement) {
            scrollPosition(this.productListElement.offsetTop - 90);
        }
    };
    ProductsListing.prototype.setAndOrFilter = function (fieldName, andOrFilter) {
        var _this = this;
        var facetsClone = __spread(this.state.facets);
        var facets = facetsClone.map(function (facet) {
            if (facet.fieldName === fieldName) {
                facet.hasAndFilter = andOrFilter === 'and';
            }
            return facet;
        });
        this.setState({ facets: facets, customFiltersLoading: this.hasActiveCustomFilters() }, function () {
            _this.reloadPage(_this.props.countPerPage, 0, _this.state.infiniteScrollActive, {}, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null).then(function () {
                return _this.resetAfterFilterOrSort();
            }).then(function () {
                _this.setState({ customFiltersLoading: false });
            });
        });
    };
    ProductsListing.prototype.onFacetValueChange = function (facets, ticket, element, callback) {
        var _this = this;
        var newFacets = facets.map(function (facet) {
            var nrOfSelectedFacetValues = facet.facetValues.filter(function (v) { return v.selected; }).length;
            facet.hasAndFilter = nrOfSelectedFacetValues < 2 ? false : facet.hasAndFilter;
            return facet;
        });
        var offset = null;
        if (element !== null) {
            offset = offsetTop(element);
        }
        this.setState({
            facets: newFacets,
            infiniteScrollActive: false,
            customFiltersLoading: this.hasActiveCustomFilters()
        }, function () {
            if (element !== null) {
                var newOffset = offsetTop(element);
                scrollPosition(scrollPosition() + (newOffset - offset));
            }
            _this.reloadPage(_this.props.countPerPage, 0, false, { ticket: ticket }, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null).then(function () { return _this.resetAfterFilterOrSort(); }).then(function () {
                _this.setState({ customFiltersLoading: false });
            });
        });
        this.unbindInfiniteScroll && this.unbindInfiniteScroll();
        if (!!this.props.facetClicked) {
            this.props.facetClicked();
        }
        !!callback && callback();
    };
    ProductsListing.prototype.onFreeTextValueChange = function (phrase) {
        var _this = this;
        if (phrase !== undefined) {
            this.setState({
                currentSearchPhrase: phrase || '',
            }, function () {
                _this.reloadPage(_this.props.countPerPage, 0, _this.state.infiniteScrollActive, {}, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null).then(function () {
                    return _this.resetAfterFilterOrSort();
                }).then(function () {
                    _this.setState({ customFiltersLoading: false });
                });
            });
        }
    };
    ProductsListing.prototype.onSortChange = function (sortBy) {
        var _this = this;
        this.setState({
            sortBy: sortBy,
        }, function () {
            _this.reloadPage(_this.props.countPerPage, 0, _this.state.infiniteScrollActive, {}, _this.hasActiveCustomFilters() ? _this.customFilterCount() : null).then(function () {
                return _this.resetAfterFilterOrSort();
            }).then(function () {
                _this.setState({ customFiltersLoading: false });
            });
        });
    };
    ProductsListing.prototype.clearFacets = function () {
        var _this = this;
        var newFacets = this.state.facets;
        this.state.facets.forEach(function (f) {
            var selectedValues = allSelectedValues(f.facetValues);
            selectedValues.forEach(function (v) {
                newFacets = toggleFacet(newFacets, f.fieldName, v, false);
            });
        });
        this.onFacetValueChange(newFacets, null, null, function () {
            _this.setState({
                currentSearchPhrase: '',
                filterFavorites: false,
                filterInStock: false
            });
        });
    };
    ProductsListing.prototype.scrollIsFrozenOnIOS = function () {
        return isIOS() && document.body.classList.contains(freezescroll);
    };
    ProductsListing.prototype.render = function () {
        var _this = this;
        var _a;
        var totalCount = this.props.products.totalProductCount;
        var activeCustomFilterCount = (!!this.state.filterInStock ? 1 : 0) + (!!this.state.filterFavorites ? 1 : 0);
        var facetsAreEmpty = this.state.facets.length === 0 && this.state.currentSearchPhrase === '';
        var visibleCount = this.visibleProductCount();
        var layout = this.props.currentBreakpoint < MEDIUM ? '3to7' : '1to3';
        var largeAd = this.props.largeCards && this.props.largeCards.length > 0 ? this.props.largeCards[0] : null;
        var products = this.customFiltering(this.props.products.products);
        return (React.createElement(Part, { className: this.props.className, layout: this.props.currentBreakpoint < MEDIUM ? null : layout },
            React.createElement("div", { style: { zIndex: 1 } },
                React.createElement("div", { className: styles.sortAndViewBar },
                    React.createElement("div", { className: classnames(styles.productCount, 'ui-test-product-listing-count') }, !this.hasActiveCustomFilters() ? translate('/ProductListing/FadeShows', totalCount) : translate('/ProductListing/CustomFilterShows')),
                    React.createElement("div", { className: styles.sortAndViewActions },
                        !!this.props.showLayoutOptions && React.createElement("div", { className: styles.sortAndViewActionItem },
                            React.createElement(ViewLayoutSelection, { allowedViewLayouts: ['viewLayout_Grid', 'viewLayout_List'] })),
                        React.createElement("div", { className: styles.sortAndViewActionItem },
                            React.createElement(FadeDropDown, { className: classnames(styles.sortDropDown, 'ui-test-dropdown-sort'), options: this.props.products.sortOptions.options, value: this.state.sortBy, onChange: function (sortBy) { return _this.onSortChange(sortBy); } })))),
                React.createElement("div", { ref: function (el) { return (_this.productListElement = el); }, className: "ui-test-product-list" }, products.length === 0 ? (translate('/ProductListing/NoProducts')) : (React.createElement(ProductCardsGrid, { products: products.slice(0, visibleCount), largeAd: largeAd, fullWidth: facetsAreEmpty, viewLayoutType: !!this.props.showLayoutOptions ? this.props.currentUser.userSettings.viewLayoutSelection : 'viewLayout_Grid', disableHeaderRow: this.props.disableHeaderRow, recommendationsPanel: this.props.recommendationsPanel, searchTerm: this.props.searchTerm }))),
                React.createElement(Part, { className: styles.info }, !this.allProductsAreVisible() && (React.createElement(FeedbackButton, { appearance: "primary", className: classnames(styles.showMoreBtn, (_a = {}, _a[styles.hiddenWhileLoading] = !!this.state.customFiltersLoading, _a)), onClickWithFeedback: function (e, activate) {
                        _this.onClickShowMore(e, activate);
                    } },
                    !!this.state.customFiltersLoading && React.createElement(Spinner, { className: styles.spinner }),
                    React.createElement("span", null, translate('/ProductListing/ShowAll'))))),
                this.props.renderAdditionalInfo),
            this.props.currentBreakpoint >= MEDIUM && (React.createElement(Part, { className: styles.facetListPartWrap },
                React.createElement("div", { className: styles.columnTitle }, translate('/Shared/FilterHeader')),
                this.props.currentUser.isLoggedIn && React.createElement(CustomQuickFilters, { onCustomFavoriteFilterClick: function () { _this.setFilterFavorites(!_this.state.filterFavorites); }, onCustomInStockFilterClick: function () { _this.setFilterInStock(!_this.state.filterInStock); }, activeFavorites: this.state.filterFavorites, activeInStock: this.state.filterInStock }),
                React.createElement(SelectedFilters, { showClearButton: this.hasActiveCustomFilters(), facets: this.state.facets, onClearFacets: function () { return _this.clearFacets(); } }),
                React.createElement(FacetList, { subCategories: this.state.categories || this.state.campaigns, facets: this.state.facets, onFacetValueChange: function (facets, ticket, element, freeText) {
                        return _this.onFacetValueChange(facets, ticket, element);
                    }, setAndOrFilter: function (fieldName, andOrFilter) { return _this.setAndOrFilter(fieldName, andOrFilter); }, onFreeTextValueChange: function (ticket) { return _this.onFreeTextValueChange(ticket); }, currentFreeTextValue: this.state.currentSearchPhrase, excludeCnt: false, hideFreeText: this.props.isSearchPage || this.props.isCategoryPage || !!this.props.isCampaignPage || !!this.props.hideFreeTextSearch, hideCategories: (this.props.isCategoryPage || !!this.props.isCampaignPage), defaultOpen: true }))),
            this.props.currentBreakpoint < MEDIUM && (React.createElement(MobileFiltersAndFacets, { facets: this.state.facets, activeCustomFilterCount: activeCustomFilterCount, totalCount: totalCount, hasActiveCustomFilters: this.hasActiveCustomFilters() },
                this.props.currentUser.isLoggedIn && React.createElement(CustomQuickFilters, { onCustomFavoriteFilterClick: function () { _this.setFilterFavorites(!_this.state.filterFavorites); }, onCustomInStockFilterClick: function () { _this.setFilterInStock(!_this.state.filterInStock); }, activeFavorites: this.state.filterFavorites, activeInStock: this.state.filterInStock }),
                React.createElement(SelectedFilters, { facets: this.state.facets, onClearFacets: function () { return _this.clearFacets(); }, showClearButton: this.hasActiveCustomFilters() }),
                React.createElement(FacetList, { subCategories: this.state.categories || this.state.campaigns, facets: this.state.facets, onFacetValueChange: function (facets, ticket, element, freeText) {
                        return _this.onFacetValueChange(facets, ticket, element);
                    }, setAndOrFilter: function (fieldName, andOrFilter) { return _this.setAndOrFilter(fieldName, andOrFilter); }, currentFreeTextValue: this.state.currentSearchPhrase, excludeCnt: false, hideFreeText: true, hideCategories: false, showCategoriesLast: true, defaultOpen: false })))));
    };
    return ProductsListing;
}(React.PureComponent));
function offsetTop(element) {
    if (!element) {
        return 0;
    }
    return element.offsetTop + offsetTop(element.offsetParent);
}
export default connect(function (state) { return ({
    currentUser: state.currentUser,
    isSearchPage: componentIsSearch(state.currentPage.componentName),
    isCategoryPage: componentIsCategory(state.currentPage.componentName),
    selectedDelivery: state.appShellData.header.selectedDelivery,
    currentPage: state.currentPage
}); })(ProductsListing);
