import { translate } from './translate';
export function formatPrice(price, formatAppearance) {
    if (formatAppearance === void 0) { formatAppearance = 'currency'; }
    var floorOrCeil = price > 0 ? Math.floor(price) : Math.ceil(price);
    var fraction = Math.round((price - floorOrCeil) * 100);
    var whole = floorOrCeil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, translate('/Price/ThousandSeparator'));
    var formatedPrice = whole +
        (fraction !== 0 ? translate('/Price/Decimal') + ((Math.abs(fraction) < 10 ? '0' : '') + Math.abs(fraction)) : '');
    if (formatAppearance === 'currency') {
        return formatedPrice;
    }
    else {
        throw new Error('Unknown formatAppearance: ' + formatAppearance);
    }
}
export function leadingZeros(num, numberLength) {
    if (numberLength === void 0) { numberLength = 2; }
    var s = String(num);
    while (s.length < numberLength) {
        s = '0' + s;
    }
    return s;
}
export function toNumber(value) {
    return (value || '').toString().replace(',', '.');
}
export function isDecimal(input) {
    return input === '.' || input === ',';
}
export function toNumberValue(val) {
    return (val || '').toString().replace('.', ',');
}
