import React from 'react';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import { SMALL } from 'Shared/breakpoints';
import Image from 'Shared/ImageFallback';
import MyMenigoIcon, { GetIcons } from '../MyMenigoIcon/MyMenigoIcon';
import classnames from 'classnames';
var LeftPaneMenu = function (_a) {
    var menuItems = _a.menuItems, currentBreakpoint = _a.currentBreakpoint, currentPage = _a.currentPage;
    var isExtensive = currentBreakpoint > SMALL;
    var ItemCssClass = function (url) {
        return (url === currentPage.url.path) ? classnames(styles.menuItem, styles.isActive) : styles.menuItem;
    };
    return (React.createElement(React.Fragment, null, isExtensive ? (React.createElement("div", { className: styles.menuBlock },
        React.createElement("ul", null, menuItems.map(function (data, index) { return (React.createElement("li", { className: ItemCssClass(data.url), key: index },
            React.createElement("div", null,
                React.createElement("a", { href: data.url },
                    !data.menuIcon
                        ? React.createElement(MyMenigoIcon, { name: GetIcons(data.name) })
                        : React.createElement(Image, { src: data.menuIcon }),
                    React.createElement("span", null, data.name),
                    !!data.textMark &&
                        React.createElement("span", { className: styles.menuTextMark }, data.textMark))))); })))) : (React.createElement(React.Fragment, null))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    currentPage: state.currentPage
}); })(LeftPaneMenu);
