var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ENTER } from 'Shared/keyboard';
import Icon from 'Shared/Icon';
import DropDown from 'Shared/DropDown';
import { translate } from 'Shared/translate';
import * as base from './base.scss';
import shallowEquals from 'Shared/shallow-equals';
import { Input } from 'Shared/Fields';
var IngredientTableRow = /** @class */ (function (_super) {
    __extends(IngredientTableRow, _super);
    function IngredientTableRow(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            hasFocus: props.hasFocus,
            focusDropDown: false,
            amount: props.recipeItem.amount,
            nutrientsWastePercent: props.recipeItem.nutrientsWastePercent,
        };
        return _this;
    }
    IngredientTableRow.prototype.addRef = function (index, element) {
        if (!this.inputRefs[index]) {
            this.inputRefs[index] = element;
        }
    };
    IngredientTableRow.prototype.componentWillReceiveProps = function (nextProps) {
        if (!shallowEquals(this.props, nextProps, true)) {
            this.setState({
                hasFocus: nextProps.hasFocus,
                amount: nextProps.recipeItem.amount,
                nutrientsWastePercent: nextProps.recipeItem.nutrientsWastePercent,
            });
        }
    };
    IngredientTableRow.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to Ingredient');
        }
        return shouldUpdate;
    };
    IngredientTableRow.prototype.componentDidMount = function () {
        if (!!this.inputRefs[0] && !!this.props.hasFocus) {
            this.inputRefs[0].focus();
            this.onFocus(this.inputRefs[0]);
        }
    };
    IngredientTableRow.prototype.updateIngredient_Amount = function (row, value) {
        this.setState({
            amount: value,
        });
        this.props.updateIngredient_Amount(row, value);
    };
    IngredientTableRow.prototype.updateIngredient_Waste = function (row, value) {
        this.setState({
            nutrientsWastePercent: value,
        });
        this.props.updateIngredient_Waste(row, value);
    };
    IngredientTableRow.prototype.onFocus = function (el) {
        this.focusElement = el;
        this.props.onFocus(this.props.rowIndex);
    };
    IngredientTableRow.prototype.onKeyDown = function (event) {
        var _this = this;
        if (event.keyCode === ENTER) {
            event.preventDefault();
            if (!!this.focusElement) {
                var row = this.inputRefs;
                var index = this.state.focusDropDown ? 1 : row.findIndex(function (el) { return el === _this.focusElement; });
                if (index >= 0) {
                    var nextIndex = index + 1;
                    if (nextIndex < row.length && !!row[nextIndex]) {
                        if (!!row[nextIndex].focus) {
                            this.setState({ focusDropDown: false });
                            row[nextIndex].focus();
                            row[nextIndex].select();
                        }
                        if (!!row[nextIndex].props) {
                            this.setState({ focusDropDown: true });
                        }
                        this.focusElement = row[nextIndex];
                    }
                    else {
                        this.setState({ hasFocus: false });
                        this.props.focusSearchInput();
                    }
                }
            }
        }
    };
    IngredientTableRow.prototype.render = function () {
        var _this = this;
        var _a = this.props, recipeItem = _a.recipeItem, rowIndex = _a.rowIndex;
        this.inputRefs = new Array(4);
        var unitOptions = recipeItem.selectableUnits.map(function (item) { return ({ value: item.id, text: item.name }); });
        return (!!recipeItem && (React.createElement("tr", { key: rowIndex, className: base.row, onKeyDown: function (e) { return _this.onKeyDown(e); } },
            React.createElement("td", { className: base.productName },
                !!recipeItem.product ? recipeItem.product.title : recipeItem.name,
                (recipeItem.product || recipeItem.productId) && (React.createElement("span", { className: base.lightGray },
                    translate('/ProductListing/ArticleNumber') + ' ',
                    recipeItem.productId)),
                recipeItem.product && React.createElement("span", { className: base.lightGray }, recipeItem.product.trademark)),
            React.createElement("td", { className: base.tdInputWrapper },
                React.createElement(Input, { ref: function (e) { return _this.addRef(0, e); }, type: "text", maxLength: 5, value: this.state.amount, onChange: function (e) { return _this.updateIngredient_Amount(rowIndex, e.target.value); }, onFocus: function (e) { return _this.onFocus(e.target); } }),
                React.createElement(DropDown, { className: base.larger, options: unitOptions, value: recipeItem.unit.id, onChange: function (selection) { return _this.props.updateIngredient_UnitVariant(rowIndex, selection); }, onFocus: function (e) { return _this.onFocus(e.target); }, hasFocus: !!this.state.focusDropDown, ref: function (e) { return _this.addRef(1, e); } })),
            React.createElement("td", null, !!recipeItem.calculatedSmallWeight && recipeItem.calculatedSmallWeight !== '' && (React.createElement("span", null, recipeItem.calculatedSmallWeight))),
            React.createElement("td", { className: base.tdInputWrapper },
                React.createElement(Input, { type: "text", ref: function (e) { return _this.addRef(2, e); }, value: this.state.nutrientsWastePercent, maxLength: 3, onChange: function (e) { return _this.updateIngredient_Waste(rowIndex, e.target.value); }, onFocus: function (e) { return _this.onFocus(e.target); } }),
                React.createElement("span", null, "%")),
            React.createElement("td", null, recipeItem.amountPerPortion),
            React.createElement("td", { className: base.inline }, !!recipeItem.product ? (React.createElement("p", null, recipeItem.price)) : (React.createElement("div", null,
                React.createElement(Input, { type: "text", defaultValue: recipeItem.price, ref: function (e) { return _this.addRef(3, e); }, onChange: function (e) { return _this.props.updateIngredient_Price(rowIndex, e.target.value); }, onFocus: function (e) { return _this.onFocus(e.target); } }),
                ' ',
                this.props.currencySymbol))),
            React.createElement("td", { className: base.right },
                React.createElement("div", { className: base.removeIcon, onClick: function () { return _this.props.removeRow(rowIndex); } },
                    React.createElement(Icon, { name: "remove" }))))));
    };
    return IngredientTableRow;
}(React.Component));
export default IngredientTableRow;
