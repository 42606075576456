import React from 'react';
import * as styles from '../../../Search/Pages/Search/Components/components.scss';
import QuickFilterButton from '../../../Search/Pages/Search/Components/SearchQuickFilter';
import classnames from 'classnames';
import { AddFavouriteFilterButton, AddInStockFilterButton } from './action-creators';
var CustomQuickFilters = function (props) {
    var buttons = [];
    !!!props.hideInStock && AddInStockFilterButton(buttons, !!props.activeInStock);
    !!!props.hideFavorites && AddFavouriteFilterButton(buttons, !!props.activeFavorites);
    var filterFavoritesClick = function () {
        !!props.onCustomFavoriteFilterClick && props.onCustomFavoriteFilterClick();
    };
    var filterInStockClick = function () {
        !!props.onCustomInStockFilterClick && props.onCustomInStockFilterClick();
    };
    var customfilterClick = function (id) {
        if (id == "favorites")
            filterFavoritesClick();
        else if (id == "inStock")
            filterInStockClick();
    };
    return (React.createElement(React.Fragment, null, !!buttons && buttons.length > 0 && (React.createElement("div", { className: classnames(styles.searchSuggestion, styles.searchFilters, styles.searchFilterCustom) },
        React.createElement("ul", null, !!buttons && buttons.map(function (button, i) { return (React.createElement(QuickFilterButton, { onCustomClick: function () { customfilterClick(button.facet.id); }, key: button.buttonText + i, item: button })); }))))));
};
export default CustomQuickFilters;
