var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Facet from './Facet';
import CategoryFacet from './CategoryFacet';
import toggleFacet from './toggle-facet';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import shallowEquals from 'Shared/shallow-equals';
// const DEFAULT_OPEN_FACET_COUNT = 6;
var freeTexthDebounceMs = 500;
var FacetList = /** @class */ (function (_super) {
    __extends(FacetList, _super);
    function FacetList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentFreeText: '',
            currentFreeTextFilter: null,
        };
        return _this;
    }
    FacetList.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.currentFreeTextValue !== nextProps.currentFreeTextValue) {
            this.setState({
                currentFreeText: nextProps.currentFreeTextValue,
            });
        }
    };
    FacetList.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = nextProps.facets !== this.props.facets ||
            !shallowEquals(nextState, this.state, true) ||
            nextProps.subCategories !== this.props.subCategories;
        if (!shouldUpdate) {
            console.info('Ignoring update to FacetList');
        }
        return shouldUpdate;
    };
    FacetList.prototype.onFacetValueChange = function (fieldName, facetValue, selected, element) {
        var facets = toggleFacet(this.props.facets, fieldName, facetValue, selected);
        if (facets !== this.props.facets) {
            this.props.onFacetValueChange(facets, selected ? facetValue.ticket : null, element, this.state.currentFreeText);
        }
    };
    FacetList.prototype.clearSearchTimeouts = function () {
        clearTimeout(this.currentSearchTimeout);
    };
    FacetList.prototype.FreeTextSearch = function (textSearch) {
        var _this = this;
        this.clearSearchTimeouts();
        this.setState({
            currentFreeText: textSearch,
        });
        this.currentSearchTimeout = setTimeout(function () {
            _this.props.onFreeTextValueChange(textSearch);
        }, freeTexthDebounceMs);
    };
    FacetList.prototype.render = function () {
        var _this = this;
        var freeTextIsEmpty = this.state.currentFreeText === '';
        return (React.createElement("div", null,
            !this.props.hideFreeText && (React.createElement("div", { className: styles.freeTextSearchWrap },
                React.createElement("div", { className: styles.freeTextSearch },
                    React.createElement("div", { className: classnames(styles.searchWrapper, !freeTextIsEmpty ? styles.closeFreeTextSearch : ''), onClick: function () { return !freeTextIsEmpty && _this.FreeTextSearch(''); } }, freeTextIsEmpty ? React.createElement(Icon, { name: 'search' }) : React.createElement(Icon, { name: "close" })),
                    React.createElement("input", { type: "text", placeholder: translate('/Shared/FreeTextSearch'), value: this.state.currentFreeText, onChange: function (e) { return _this.FreeTextSearch(e.target.value); } })))),
            this.props.facets.length > 0 && (React.createElement("ul", { className: styles.list },
                !!!this.props.hideCategories && !!!this.props.showCategoriesLast && React.createElement(CategoryFacet, { subCategories: this.props.subCategories, excludeCnt: this.props.excludeCnt }),
                this.props.facets.map(function (facet) { return (React.createElement(Facet, { facet: facet, openDefault: facet.fieldName === 'department' || _this.props.defaultOpen, onFacetValueChange: function (facetValue, selected, element) {
                        return _this.onFacetValueChange(facet.fieldName, facetValue, selected, element);
                    }, setAndOrFilter: _this.props.setAndOrFilter, key: facet.fieldName, excludeCnt: _this.props.excludeCnt })); }),
                !!!this.props.hideCategories && !!this.props.showCategoriesLast && React.createElement(CategoryFacet, { subCategories: this.props.subCategories, excludeCnt: this.props.excludeCnt })))));
    };
    return FacetList;
}(React.Component));
export default FacetList;
