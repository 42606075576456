var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { createNewList } from '../../../action-creators';
import { Input } from 'Shared/Fields';
var NewListButton = function (props) {
    var textInput = React.createRef();
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleConfirm = useCallback(function () { return props.createNewList(textInput.current.element.value); }, [textInput]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: props.className, onClick: function () { return setOpen(true); }, appearance: 'secondary' }, translate('/RecipeMenu/CreateNewHeader')),
        React.createElement(Modal, { title: translate('/RecipeMenu/CreateNew'), isOpen: isOpen, onClose: handleClose, reserveSpaceForCloseButton: true, fadeDesign: true },
            React.createElement(Input, { ref: textInput, autoFocus: true }),
            React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: handleConfirm, onCancel: handleClose, onStatusWasFulfilled: handleClose }))));
};
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    createNewList: function (displayName) { return dispatch(createNewList(displayName)); },
}); })(NewListButton);
