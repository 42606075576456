export var TOGGLE_DELIVERYDAYS = 'TOGGLE_DELIVERYDAYS';
export var SHOW_DELIVERYDAYS = 'SHOW_DELIVERYDAYS';
export var HIDE_DELIVERYDAYS = 'HIDE_DELIVERYDAYS';
export var SET_NEW_DELIVERYDAYS = 'SET_NEW_DELIVERYDAYS';
export function toggleDeliveryDays() {
    return {
        type: TOGGLE_DELIVERYDAYS,
    };
}
export function showDeliveryDays() {
    return {
        type: SHOW_DELIVERYDAYS,
    };
}
export function hideDeliveryDays() {
    return {
        type: HIDE_DELIVERYDAYS,
    };
}
