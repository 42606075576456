import * as server from 'Shared/server';
export function onDeleteRecipeGroup(id) {
    return server.post("/recipegroupadmin/delete", { id: id });
}
export function onAddRecipeGroup(recipeGroup, name) {
    return server.postJson("/recipegroupadmin/add", { recipeGroup: recipeGroup, name: name });
}
export function onEditRecipeGroup(recipeGroup, name) {
    return server.postJson("/recipegroupadmin/edit", { recipeGroup: recipeGroup, name: name });
}
export function onGetPantriesFavoritesList(customerId) {
    return server.postJson("/favoritespantriesadmin/getfavoritesandpantries", { customerId: customerId });
}
export function onCopyToCustomer(fromCustomerId, customerId, list) {
    return server.postJson("/favoritespantriesadmin/copytocustomer", {
        fromCustomerId: fromCustomerId,
        customerId: customerId,
        list: list,
    });
}
export function searchForCustomer(query) {
    return server.postJson("/favoritespantriesadmin/getcustomers", { query: query });
}
export function onDeleteClassification(id) {
    return server.post("/classificationadmin/delete", { id: id });
}
export function onAddClassification(name, nameEn, classificationName, code) {
    return server.postJson("/classificationadmin/add", {
        name: name,
        nameEn: nameEn,
        classificationName: classificationName,
        code: code,
    });
}
export function onEditClassification(name, nameEn, classificationName, code, id) {
    return server.postJson("/classificationadmin/edit", {
        id: id,
        name: name,
        nameEn: nameEn,
        classificationName: classificationName,
        code: code,
    });
}
