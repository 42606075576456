var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
// This class exists as a memoized version of its props. Since props passed to Layout
// can change without the current page changes, we want to avoid re-rendering the
// current page when it hasn't changed.
var CurrentPage = memo(function (props) {
    var Component = props.component;
    return React.createElement(Component, __assign({ currentBreakpoint: props.currentBreakpoint }, props.page));
});
export default CurrentPage;
