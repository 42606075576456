var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Quantity from 'Shared/Quantity';
import Image from 'Shared/ImageFallback';
import Price from 'Shared/Price';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import * as Root from 'SiteLayout/Root';
import { translate } from 'Shared/translate';
import propertyValueFor from 'Shared/property-value-for';
import Tooltip from 'Shared/Tooltip';
import { SMALL, MEDIUM } from 'Shared/breakpoints';
import { DIVIDE, DIVIDE_DESKTOP } from 'Shared/keyboard';
import * as styles from './base.scss';
import ProductFavoriteMark from 'Shared/ProductFavoriteMark';
import ProductMark from 'Shared/ProductMark';
import shallowEquals from 'Shared/shallow-equals';
import { ProductRowTableLayout } from './TableLayout';
import { getSelectedVariant } from 'Shared/Product/reducer';
import { selectVariant, toggleSelectedVariant } from 'Shared/Product/action-creators';
import { getStepSize } from 'Shared/Quantity/utils';
import DeliveryDateSelection from 'Shared/DeliveryDateSelection';
import { getDepartureDate } from 'Shared/DeliveryDateSelection/utility';
import DeliveryDatePicker, { DATE_FORMAT } from 'Shared/DeliveryDateSelection/DatePicker';
import Dialog, { DialogType } from 'Shared/Dialog';
import Modal from 'Shared/Modal';
import { getCartLineItemCountForVariant } from '../../Cart/reducer';
import VariantSelection from 'Shared/VariantSelection';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
export var AppearanceNameType;
(function (AppearanceNameType) {
    AppearanceNameType[AppearanceNameType["TableLayout"] = 0] = "TableLayout";
    AppearanceNameType[AppearanceNameType["Compact"] = 1] = "Compact";
    AppearanceNameType[AppearanceNameType["CompactMobile"] = 2] = "CompactMobile";
})(AppearanceNameType || (AppearanceNameType = {}));
var ProductRow = /** @class */ (function (_super) {
    __extends(ProductRow, _super);
    function ProductRow(props) {
        var _this = _super.call(this, props) || this;
        _this.onDateSelected = function (date, isValid) {
            _this.selectedDate = date.format(DATE_FORMAT);
        };
        _this.onDateConfirmed = function () {
            return _this.props.updateCartDeliveryDate(_this.props.selectedVariant.code, _this.selectedDate);
        };
        _this.showDatePicker = function (event) {
            _this.setState({ isDatePickerOpen: true });
        };
        _this.hideDatePicker = function (event) {
            _this.setState({ isDatePickerOpen: false });
        };
        _this.updateQuantity = function (quantity, action) {
            if (_this.props.updateCartItemQuantity) {
                return _this.props.updateCartItemQuantity(_this.props.selectedVariant.code, quantity, null, _this.props.selectedVariant.unitShortName, _this.props.selectedVariant.ticket);
            }
            else if (_this.props.updateQuantity) {
                return _this.props.updateQuantity(_this.props.selectedVariant.code, quantity, null, _this.props.selectedVariant.unitShortName, _this.props.selectedVariant.ticket);
            }
            else {
                return Promise.resolve();
            }
        };
        _this.onFocusQuantity = function (e) {
            !!_this.props.setFocus && _this.props.setFocus();
            if (!!_this.props.onFocusQuantity && !!_this.props.rowIndex) {
                return _this.props.onFocusQuantity(_this.props.rowIndex);
            }
        };
        _this.keyDown = function (e) {
            switch (e.keyCode) {
                case DIVIDE:
                case DIVIDE_DESKTOP:
                    if (e.shiftKey || e.keyCode === DIVIDE_DESKTOP) {
                        e.preventDefault();
                        var setVariant = _this.props.selectedVariant === _this.props.variants[0] && _this.props.variants.length > 1
                            ? _this.props.variants[1]
                            : _this.props.variants[0];
                        _this.updateUnit(setVariant);
                    }
                    break;
                default:
                    break;
            }
            if (!!_this.props.onKeyDown) {
                return _this.props.onKeyDown(e);
            }
        };
        _this.state = {
            showMatchingInfo: false,
            productRowModalId: 'productRowModal' + props.selectedVariant.code,
            currentModalId: null,
            isDatePickerOpen: false,
        };
        return _this;
    }
    ProductRow.prototype.componentDidMount = function () {
        var _this = this;
        this.unbindRootClick = Root.on('click', function () {
            if (_this.state.showMatchingInfo) {
                _this.setState({ showMatchingInfo: false });
            }
        });
    };
    ProductRow.prototype.componentWillUnmount = function () {
        this.unbindRootClick && this.unbindRootClick();
    };
    ProductRow.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to ProductRow');
        }
        return shouldUpdate;
    };
    ProductRow.prototype.removeItem = function () {
        !!this.props.removeCartItem &&
            this.props.removeCartItem(this.props.selectedVariant.code, null, this.props.selectedVariant.unitShortName);
    };
    ProductRow.prototype.updateUnit = function (variant) {
        var _a;
        var oldCode = this.props.selectedVariant.code;
        this.props.selectVariant(variant.code);
        if (!!variant) {
            !!this.props.unCheck && this.props.unCheck(variant.code);
            var items_1 = (_a = {},
                _a[oldCode] = {
                    isUpdateAction: true,
                    newQuantity: this.props.quantity,
                    newUnitShortName: variant.unitShortName,
                    deliveryDate: null,
                },
                _a);
            this.props.subDeliveries &&
                this.props.subDeliveries.map(function (subdel) {
                    items_1[oldCode + "|" + subdel.date] = {
                        isUpdateAction: true,
                        newQuantity: subdel.quantity,
                        newUnitShortName: variant.unitShortName,
                        deliveryDate: subdel.date,
                    };
                });
            if (!!this.props.updateCartItemUnit) {
                return this.props.updateCartItemUnit(items_1);
            }
            else if (!!this.props.updateUnit) {
                return this.props.updateUnit(items_1, variant.ticket);
            }
        }
        return Promise.resolve();
    };
    ProductRow.prototype.onClick = function () {
        if (!!this.props.onClick) {
            return this.props.onClick();
        }
    };
    ProductRow.prototype.onBlur = function () {
        if (!!this.props.onBlur) {
            return this.props.onBlur();
        }
    };
    ProductRow.prototype.render = function () {
        var _this = this;
        var _a;
        var isMobile = this.props.currentBreakpoint <= SMALL;
        var isMedium = this.props.currentBreakpoint <= MEDIUM && !isMobile;
        var url = this.props.url;
        var disableBuyWidgets = (!this.props.isCart && (!this.props.isBuyable || this.props.isSoldOut || this.props.isTender)) ||
            this.props.isDisabled;
        var imageNode = (React.createElement("div", { className: styles.imageWrap },
            !!this.props.isLoggedIn && !this.props.isCart && React.createElement(ProductMark, __assign({}, this.props)),
            React.createElement(Image, { skipSrcSet: true, src: this.props.mainImageUrl, alt: this.props.title, size: "tiny" })));
        var textNode = (React.createElement("div", null,
            React.createElement("a", { href: url, "data-ticket": this.props.ticket, onClick: function () { return _this.onClick(); }, className: styles.linkText },
                React.createElement("div", { className: styles.mobile },
                    React.createElement("b", null, this.props.title))),
            this.props.isLoggedIn && this.props.appearance === AppearanceNameType.CompactMobile && (React.createElement("div", null,
                React.createElement(ProductFavoriteMark, { list: this.props.favoritesList, horizontal: "right", reverseFavToolbar: true, onClick: function () { return _this.onClick(); } }))),
            React.createElement("a", { href: url, "data-ticket": this.props.ticket, onClick: function () { return _this.onClick(); }, className: styles.linkText },
                React.createElement("div", null,
                    React.createElement("div", null, this.props.trademark),
                    React.createElement("div", { className: "ui-test-mini-cart-product-code" },
                        translate('/ProductListing/ArticleNumber'),
                        " ",
                        propertyValueFor(this.props.content.code))))));
        var removeitemNode = (!!this.props.isCart || this.props.removeCartItem) && (React.createElement("div", { className: styles.removeButtonWrapper },
            React.createElement(Button, { appearance: ['icon', { small: true }], onClick: function () { return _this.removeItem(); }, className: "ui-test-remove-item" },
                React.createElement(Icon, { className: styles.right, name: "remove-circle" }))));
        var priceNode = !this.props.isLoggedIn ? (React.createElement("b", null, translate('/ProductListing/NotLoggedOn'))) : this.props.isWeightProduct ? (this.props.variants.map(function (variant, index) {
            var _a;
            return (React.createElement("div", { key: index, className: styles.priceItem },
                React.createElement(Price, __assign({ size: "small" }, variant.price, { className: classnames((_a = {}, _a[styles.noMargin] = _this.props.appearance === AppearanceNameType.Compact, _a)) }))));
        })) : (React.createElement(Price, __assign({ size: "small" }, this.props.selectedVariant.price, { className: classnames((_a = {}, _a[styles.noMargin] = this.props.appearance === AppearanceNameType.Compact, _a)) })));
        var unitNode = (this.props.isWeightProduct &&
            this.props.variants.map(function (variant, index) {
                return React.createElement("div", { key: index }, variant.unitShortName + " " + variant.storeUnit.specification);
            })) || (React.createElement("div", null, this.props.selectedVariant.unitShortName + " " + this.props.selectedVariant.storeUnit.specification));
        var step = getStepSize(this.props.selectedVariant);
        var quantityNode = (React.createElement("div", { className: styles.quantityWrapper },
            React.createElement(Quantity, { autoFocus: this.props.autoFocus, focus: this.props.focusQuantity, value: !!this.props.updateCartItemQuantity ? this.props.cartItemQuantity : this.props.quantity, update: this.updateQuantity, keyDown: this.keyDown, onFocus: this.onFocusQuantity, minQuantity: 0, step: step, neverFocusTheValueItself: true, unitShortName: !(!!this.props.isCheckout && isMedium) && this.props.selectedVariant.unitShortName, allowEmptyQuantity: this.props.allowEmptyQuantity, disabled: disableBuyWidgets || this.props.isRestrictedCustomer, onBlur: function () { return _this.onBlur(); }, className: !!this.props.isCheckout && isMedium
                    ? styles.checkoutQuantity
                    : !this.props.isCheckout
                        ? styles.pantryQuantity
                        : '', selectIndex: function () { return !!_this.props.selectIndex && _this.props.selectIndex(); }, isCartQuantity: !!this.props.updateCartItemQuantity, renderVariant: React.createElement(VariantSelection, { product: this.props, unitShortName: this.props.selectedVariant.unitShortName, preferedVariant: this.props.isCart && this.props.selectedVariant, onSelectVariant: this.props.onSelectVariant && this.props.onSelectVariant }) })));
        var calendarNode = !this.props.punchOutSessionActive && this.props.isCheckout && !isMobile && (React.createElement("div", { className: classnames(styles.calendar, !getDepartureDate(this.props.headerViewModel) && styles.disabled), onClick: this.showDatePicker }, isMobile ? translate('/Checkout/NewSubDelivery') : React.createElement(Icon, { name: "calendar" })));
        var datePicker = (React.createElement(Modal, { title: translate('/ProductListing/ChooseDividedDelivery'), onClose: this.hideDatePicker, isOpen: this.state.isDatePickerOpen },
            React.createElement(DeliveryDateSelection, { headerViewModel: this.props.headerViewModel, articleCode: propertyValueFor(this.props.content.code), articleName: this.props.title, datePickerNode: React.createElement(DeliveryDatePicker, { deliveryDays: this.props.headerViewModel.deliveryDates, selectedDay: getDepartureDate(this.props.headerViewModel), onDateSelected: this.onDateSelected }), dialogNode: React.createElement(Dialog, { type: DialogType.ChooseAbort, onConfirm: this.onDateConfirmed, onCancel: this.hideDatePicker, onStatusWasFulfilled: this.hideDatePicker }) })));
        var matchingNode = this.props.hasMatchingItemInCurrentOrders && (React.createElement("div", null,
            React.createElement("span", null,
                React.createElement("div", { className: styles.matchingWrapper },
                    this.state.showMatchingInfo && (React.createElement(Tooltip, { close: function () {
                            return _this.setState(function () {
                                return { showMatchingInfo: false };
                            });
                        }, closeButton: true, closeOnClick: false },
                        React.createElement("div", { className: styles.matching }, translate('/Product/AlreadyExistsInCurrentOrders')))),
                    React.createElement("span", { className: styles.matchingIcon, onClick: function () {
                            return _this.setState(function () {
                                return { showMatchingInfo: true };
                            });
                        } },
                        React.createElement(Icon, { name: "exclamation" }))))));
        switch (this.props.appearance) {
            case AppearanceNameType.Compact:
                return this.props.currentBreakpoint > SMALL ? (React.createElement("div", { className: styles.fullRowWrap },
                    React.createElement("div", { className: classnames(styles.fullRow, this.props.noBorder && styles.noBorder, !!this.props.isCheckout && styles.isCheckout, !!this.props.isCheckout && isMedium && styles.medium, this.props.focusQuantity && styles.highlighted, this.props.focusQuantity && 'ui-test-highlighted') },
                        datePicker,
                        React.createElement("div", { className: styles.col1 }, propertyValueFor(this.props.content.code)),
                        this.props.isCart ? (React.createElement("div", { className: styles.col2 },
                            React.createElement("b", null, this.props.title),
                            React.createElement("p", { className: styles.lightGray }, this.props.trademark))) : (React.createElement("div", { className: styles.col2 },
                            React.createElement("a", { href: url, "data-ticket": this.props.ticket, onClick: function () { return _this.onClick(); } },
                                React.createElement("b", null, this.props.title)),
                            React.createElement("br", null),
                            !!this.props.isLoggedIn && (React.createElement(ProductFavoriteMark, { list: this.props.favoritesList, reverseFavToolbar: true, onClick: function () { return _this.onClick(); } })),
                            React.createElement("a", { href: url, "data-ticket": this.props.ticket, onClick: function () { return _this.onClick(); } },
                                this.props.isLoggedIn && this.props.isInCampaign && (React.createElement("p", { className: styles.campaignEnds }, this.props.isUtf
                                    ? this.props.bestBeforeDate &&
                                        translate('/ProductListing/BestBefore') + " " + this.props.bestBeforeDate
                                    : translate('/ProductListing/CampaignEnds') + ": " + this.props.closestCampaignEndDate)),
                                React.createElement("p", { className: styles.lightGray }, this.props.trademark),
                                !!this.props.isDiscontinued && this.props.isDiscontinued && (React.createElement("p", null, !this.props.isDeletedByMaster
                                    ? translate('/ProductListing/Discontinued')
                                    : translate('/ProductListing/DeletedByMaster')))))),
                        this.props.isCart && !this.props.isCheckout && (React.createElement("div", { className: classnames(styles.col3, styles.red) }, matchingNode)),
                        !!this.props.amountNeeded && React.createElement("div", { className: styles.col4 }, this.props.amountNeeded),
                        React.createElement("div", { className: styles.col5 },
                            unitNode,
                            React.createElement("div", { className: styles.areaText }, this.props.area),
                            this.props.isLoggedIn && this.props.isCart && (React.createElement(ProductMark, __assign({}, this.props, { nonStockableOnly: true, isRow: true, inline: true }))),
                            this.props.isLoggedIn && (React.createElement(ProductMark, { isInQuotation: this.props.isInQuotation, quotationOnly: true, isRow: true, inline: true }))),
                        React.createElement("div", { className: styles.col6 }, priceNode),
                        React.createElement("div", { className: styles.col7 },
                            React.createElement(ProductAvailabilityLabel, { availability: this.props.selectedVariant.availability })),
                        React.createElement("div", { className: styles.col8 }, quantityNode),
                        this.props.isCart && !this.props.isSupplierSalesman && (React.createElement("div", { className: styles.col9 }, this.props.totalPrice && (React.createElement(Price, __assign({ className: styles.price }, this.props.totalPrice, { size: "large", isPending: this.props.isLineItemPending }))))),
                        (this.props.isCart || this.props.removeCartItem) && (React.createElement("div", { className: styles.col10 },
                            calendarNode,
                            removeitemNode))))) : (React.createElement("div", { className: classnames(styles.mobileRow, !this.props.isBuyable && styles.invalidPriceIsInvalidRow) },
                    datePicker,
                    React.createElement("div", { className: styles.mobileImageColumn },
                        imageNode,
                        this.props.isLoggedIn && this.props.isCart && (React.createElement(ProductMark, __assign({}, this.props, { nonStockableOnly: true, isRow: true })))),
                    React.createElement("div", { className: styles.infoWrapper },
                        React.createElement("div", { className: styles.upper },
                            React.createElement("div", { className: styles.mobileLeftColumn },
                                React.createElement("b", null, this.props.title),
                                React.createElement("br", null),
                                this.props.isLoggedIn && React.createElement(ProductFavoriteMark, { list: this.props.favoritesList, reverseFavToolbar: true }),
                                React.createElement("div", { className: styles.lightGray },
                                    translate('/ProductListing/ArticleNumber'),
                                    " ",
                                    propertyValueFor(this.props.content.code)),
                                React.createElement("div", { className: styles.mobileInfo }, !!this.props.amountNeeded && (React.createElement(React.Fragment, null,
                                    React.createElement("span", null, translate('/ProductListing/AmountNeeded')),
                                    React.createElement("span", null, this.props.amountNeeded)))),
                                React.createElement("div", { className: classnames(styles.lightGray, styles.unitNode) }, unitNode)),
                            React.createElement("div", { className: styles.mobileRightColumn },
                                removeitemNode,
                                React.createElement(ProductAvailabilityLabel, { availability: this.props.selectedVariant.availability }),
                                calendarNode)),
                        React.createElement("div", { className: styles.lower },
                            quantityNode,
                            React.createElement("div", null, this.props.totalPrice && (React.createElement(Price, __assign({ className: classnames(styles.price, styles.rightFlex) }, this.props.totalPrice, { size: "large" }))))))));
            case AppearanceNameType.CompactMobile:
                return (React.createElement("div", { className: styles.mobileCompactRow },
                    datePicker,
                    React.createElement("div", null, textNode)));
            case AppearanceNameType.TableLayout:
                return (React.createElement(ProductRowTableLayout, { url: url, ticket: this.props.ticket, orignalCode: this.props.selectedVariant.code, mainImageUrl: this.props.mainImageUrl, currentBreakpoint: this.props.currentBreakpoint, unitNode: React.createElement(React.Fragment, null,
                        unitNode,
                        React.createElement("div", { className: styles.areaText }, this.props.area),
                        this.props.isLoggedIn && this.props.isCart && (React.createElement(ProductMark, __assign({}, this.props, { nonStockableOnly: true, isRow: true, inline: true }))),
                        this.props.isLoggedIn && (React.createElement(ProductMark, { isInQuotation: this.props.isInQuotation, quotationOnly: true, isRow: true, inline: true }))), textNode: textNode, quantityNode: quantityNode, removeitemNode: removeitemNode, selectedVariant: this.props.selectedVariant, price: this.props.price, totalPrice: this.props.totalPrice, datePickerNode: datePicker, isLineItemPending: this.props.isLineItemPending }));
            default:
                return null;
        }
    };
    return ProductRow;
}(React.Component));
var ConnectedProductRow = connect(function (state, ownProps) {
    var selectedVariant = getSelectedVariant(state, ownProps.variants, ownProps.isCart && ownProps.selectedVariant);
    return {
        currentBreakpoint: state.currentBreakpoint,
        headerViewModel: state.appShellData.header,
        isLoggedIn: state.currentUser.isLoggedIn,
        isSupplierSalesman: state.currentUser.isSupplierSalesman,
        punchOutSessionActive: state.currentUser.punchOutSessionActive,
        cartItemQuantity: getCartLineItemCountForVariant(state, selectedVariant),
        selectedVariant: selectedVariant,
        isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
    };
}, function (dispatch, ownProps) { return ({
    selectVariant: function (newVariantCode) { return dispatch(selectVariant(newVariantCode, ownProps.variants)); },
    toggleSelectedVariant: function (currentSelectedVariantCode) {
        return dispatch(toggleSelectedVariant(currentSelectedVariantCode, ownProps.variants));
    },
}); })(ProductRow);
export default ConnectedProductRow;
