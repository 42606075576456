import React from 'react';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import LoginContainer from 'Shared/Login/Container';
import * as styles from './base.scss';
var ProductLoginToBuy = function () { return (React.createElement(LoginContainer, null, function (_a) {
    var setAccountBoxOpen = _a.setAccountBoxOpen;
    return (React.createElement(Button, { className: styles.btn, onClick: function () { return setAccountBoxOpen(); } },
        React.createElement("span", { className: styles.text }, translate('/Account/Login/LoginToBuy')),
        ' '));
})); };
export default ProductLoginToBuy;
