import { STOCKTAKING_STARTED, STOCKTAKING_ENDED, SET_INVENTORY, SET_QUERY_STRING, SEARCH_RESULT, NO_SEARCH_RESULT, SET_ACTIVE_INVENTORY_ITEM, CLEAR_SEARCH_RESULT, CLEAR_ACTIVE_INVENTORY_ITEM, SET_ACTIVE_CONTROL, SET_ASSORTMENT_SYNC_DATE, STOCKTAKING_LOAD_INVENTORY, STOCKTAKING_LOADING, STOCKTAKING_SAVED, STOCKTAKING_SAVEANDCLOSED, TOGGLE_CAMERA_TAKESTOCK, STOCKTAKING_NOT_LOADING, } from './action-creators';
export var ExportingStatus;
(function (ExportingStatus) {
    ExportingStatus[ExportingStatus["None"] = 0] = "None";
    ExportingStatus[ExportingStatus["Loading"] = 1] = "Loading";
    ExportingStatus[ExportingStatus["Saved"] = 2] = "Saved";
    ExportingStatus[ExportingStatus["SaveAndClosed"] = 3] = "SaveAndClosed";
    ExportingStatus[ExportingStatus["Failed"] = 4] = "Failed";
})(ExportingStatus || (ExportingStatus = {}));
export default function (state, action) {
    if (!state) {
        state = {
            exportingStatus: ExportingStatus.None,
            pantries: [],
            activeItem: null,
            searchResults: null,
            noSearchResults: false,
            activeControl: null,
            query: '',
            assortmentSyncDate: null,
            inventories: null,
            showCamera: false,
        };
    }
    switch (action.type) {
        case TOGGLE_CAMERA_TAKESTOCK:
            return Object.assign({}, state, { showCamera: action.showCamera });
        case STOCKTAKING_LOADING:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Loading });
        case STOCKTAKING_NOT_LOADING:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Failed });
        case STOCKTAKING_SAVED:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.Saved });
        case STOCKTAKING_SAVEANDCLOSED:
            return Object.assign({}, state, { exportingStatus: ExportingStatus.SaveAndClosed });
        case STOCKTAKING_STARTED:
            return Object.assign({}, state, {
                pantries: action.pantries.map(function (p) { return Object.assign({}, p, { modifiedEntries: [] }); }),
                newCustomProducts: [],
                exportingStatus: ExportingStatus.None,
            });
        case STOCKTAKING_ENDED:
            return Object.assign({}, state, { pantries: null, newCustomProducts: null });
        case SET_INVENTORY:
            return Object.assign({}, state, {
                inventories: setInventory(state.inventories, action),
            });
        case SET_QUERY_STRING:
            return Object.assign({}, state, { query: action.query });
        case NO_SEARCH_RESULT:
            return Object.assign({}, state, { searchResults: null, noSearchResults: true });
        case CLEAR_SEARCH_RESULT:
            return Object.assign({}, state, { searchResults: null, noSearchResults: false, activeControl: null });
        case SEARCH_RESULT:
            return Object.assign({}, state, {
                searchResults: action.products,
                noSearchResults: false,
            });
        case SET_ACTIVE_INVENTORY_ITEM:
            return Object.assign({}, state, {
                activeItem: action.product,
                activeControl: 'activeItem',
            });
        case CLEAR_ACTIVE_INVENTORY_ITEM:
            return Object.assign({}, state, { activeItem: null, activeControl: null });
        case STOCKTAKING_LOAD_INVENTORY:
            return Object.assign({}, state, { inventories: action.products });
        case SET_ASSORTMENT_SYNC_DATE:
            return Object.assign({}, state, { assortmentSyncDate: action.timestamp });
        case SET_ACTIVE_CONTROL:
            return Object.assign({}, state, {
                activeControl: state.activeControl !== action.control
                    ? action.control
                    : null,
            });
        default:
            return state;
    }
}
function setInventory(items, action) {
    var index = items.findIndex(function (i) { return i.productId === action.product.productId; });
    var result = items.slice(0);
    if (index >= 0) {
        result[index] = action.product;
    }
    else {
        result.unshift(action.product);
    }
    return result;
}
