var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import Modal from 'Shared/Modal';
import InfoModalComponent from './Components/InfoModalComponent';
import propertyValueFor from 'Shared/property-value-for';
import { isBrowser } from 'Shared/device-type';
import Dialog, { DialogType } from 'Shared/Dialog';
var InfoModal = /** @class */ (function (_super) {
    __extends(InfoModal, _super);
    function InfoModal(props) {
        var _this = _super.call(this, props) || this;
        _this.closeInfoModal = function () {
            _this.setState({ isOpen: false });
        };
        _this.confirmInfoModal = function () {
            document.cookie = 'hasShownInfoModal=' + _this.getPageId() + '; path=/;SameSite=None;secure';
            _this.closeInfoModal();
        };
        _this.state = {
            isOpen: false,
        };
        return _this;
    }
    InfoModal.prototype.componentDidMount = function () {
        this.shouldShowInfoModal();
    };
    InfoModal.prototype.getCookie = function (cookiename) {
        if (!isBrowser()) {
            return '';
        }
        var cookiestring = RegExp('' + cookiename + '[^;]+').exec(document.cookie);
        return decodeURI(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '');
    };
    InfoModal.prototype.getPageId = function () {
        return propertyValueFor(this.props.headerViewModel &&
            this.props.headerViewModel.infoModalPage &&
            this.props.headerViewModel.infoModalPage.workId);
    };
    InfoModal.prototype.shouldShowInfoModal = function () {
        var pageId = this.getPageId();
        return (!!this.props.headerViewModel.infoModalPage &&
            this.getCookie('hasShownInfoModal') !== pageId &&
            this.getCookie('hideInfoModal') !== pageId);
    };
    InfoModal.prototype.render = function () {
        return (React.createElement(Modal, { onClose: this.closeInfoModal, isOpen: this.state.isOpen },
            React.createElement(InfoModalComponent, __assign({}, this.props.headerViewModel.infoModalPage)),
            React.createElement(Dialog, { type: DialogType.OkOnly, onConfirm: this.confirmInfoModal, onCancel: this.closeInfoModal })));
    };
    return InfoModal;
}(React.Component));
export default connect(function (state) { return ({
    headerViewModel: state.appShellData.header,
}); })(InfoModal);
