var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import Modal from 'Shared/Modal';
import EmvProductDetail from '../../../Checkout/FullCart/ProductList/EmvProductDetail';
import { getProductDetails } from '../../../Checkout/action-creators';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
import { addEcommerceEventToDataLayer } from './../../../TrackingInformation';
var ProductPreview = function (_a) {
    var product = _a.product, getProductDetails = _a.getProductDetails, selectedVariant = _a.selectedVariant, className = _a.className, children = _a.children, selectItemEventList = _a.selectItemEventList;
    var _b = __read(useState(false), 2), isLoading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), productDetailModal = _c[0], setProductDetailModal = _c[1];
    var _d = __read(useState(), 2), productDetails = _d[0], setProductDetails = _d[1];
    var sendGa4SelectItemEvent = function () {
        if (!!selectedVariant &&
            !!selectItemEventList &&
            selectedVariant.code in selectItemEventList) {
            addEcommerceEventToDataLayer(selectItemEventList[selectedVariant.code]);
            setGa4ItemListReferrel(selectItemEventList[selectedVariant.code].ecommerce.item_list_id);
        }
    };
    var sendGa4ViewItemEvent = function (productViewModel) {
        var pageTypeReferrel = getGa4ItemListReferrel();
        if (!!productViewModel &&
            !!productViewModel.trackingData &&
            !!productViewModel.trackingData.ga4EventObjects &&
            'view_item' in productViewModel.trackingData.ga4EventObjects &&
            !!pageTypeReferrel) {
            var events = productViewModel.trackingData.ga4EventObjects[String('view_item')];
            var event = events[selectedVariant.code];
            event.ecommerce.items[0].item_list_id = pageTypeReferrel;
            event.ecommerce.items[0].item_list_name = pageTypeReferrel;
            addEcommerceEventToDataLayer(event);
        }
    };
    var setGa4ItemListReferrel = function (ga4ItemListReferrel) {
        !!sessionStorage && sessionStorage.setItem('ga4ItemListReferrel', ga4ItemListReferrel);
    };
    var getGa4ItemListReferrel = function () {
        return sessionStorage.getItem("ga4ItemListReferrel");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { className: classnames(styles.preview, className), onClick: function () {
                setLoading(true);
                sendGa4SelectItemEvent();
                getProductDetails(product.url.toString())
                    .then(function (r) {
                    setProductDetails(r);
                    sendGa4ViewItemEvent(r);
                })
                    .then(function () { return setProductDetailModal(true); })
                    .then(function () { return setLoading(false); });
            } },
            children,
            !!isLoading && React.createElement(Spinner, { className: styles.spinner })),
        React.createElement(Modal, { isOpen: productDetailModal, onClose: function () { return setProductDetailModal(false); } },
            React.createElement(EmvProductDetail, { productViewModel: productDetails, selectedVariant: selectedVariant }))));
};
export default connect(function (state) { return ({
    selectItemEventList: state.currentPage && state.currentPage.trackingData && state.currentPage.trackingData.ga4EventObjects && state.currentPage.trackingData.ga4EventObjects['select_item']
}); }, function (dispatch) { return ({
    getProductDetails: function (url) {
        return dispatch(getProductDetails(url));
    },
}); })(ProductPreview);
