var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CHECKOUT_OPTIMISTIC_UPDATE, CHECKOUT_CHANGES_ACCEPTED, CHECKOUT_CLEARCART, CHECKOUT_UPDATE_SUCCESS, } from '../../action-creators';
import { PAGE_UPDATE_SUCCESS, PAGE_LOAD_SUCCESS, } from 'SiteLayout/CurrentPage/action-creators';
import CheckoutPage from './CheckoutPage';
import resolveComponent from 'Shared/resolve-component';
import clone from 'clone';
import { applyPendingChanges as applyCartPendingChanges } from '../../../Cart/reducer';
import { CART_LOAD_FAILURE } from '../../../Cart/action-creators';
export default function (pageState, checkoutState, action) {
    if (pageState === void 0) { pageState = null; }
    var state = checkoutState ||
        (resolveComponent(pageState.componentName) === CheckoutPage ? pageState : null);
    switch (action.type) {
        case CHECKOUT_OPTIMISTIC_UPDATE: {
            var diff = [action.diff];
            var newState = applyPendingChanges(state, diff);
            newState.pendingChanges = (newState.pendingChanges || []).concat(diff);
            newState.scrollPosition = undefined;
            return newState;
        }
        case CART_LOAD_FAILURE: {
            var firstPendingChange_1 = state.pendingChanges[0];
            if (!firstPendingChange_1) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                pendingChanges: [],
                cart: __assign({}, state.cart, { items: state.cart.items.map(function (item) {
                        var updatedItem;
                        Object.keys(firstPendingChange_1.cart.items).forEach(function (code) {
                            if (code === item.lineItem.code) {
                                var pendingItemChange = firstPendingChange_1.cart.items[code];
                                updatedItem = {
                                    product: item.product,
                                    lineItem: __assign({}, item.lineItem, { discountValue: pendingItemChange.previousDiscountValue, discountType: pendingItemChange.previousDiscountType, ignoreAvailability: pendingItemChange.previousIgnoreAvailability, quantity: pendingItemChange.previousQuantity, unitShortName: pendingItemChange.previousUnitShortName, isProductSample: pendingItemChange.previousIsProductSample, selectedVariant: __assign({}, item.lineItem.selectedVariant, { unitShortName: pendingItemChange.previousUnitShortName }) }),
                                };
                            }
                        });
                        return updatedItem || item;
                    }) }),
            });
        }
        case CHECKOUT_CHANGES_ACCEPTED: {
            var acceptedChangeIds_1 = action.changes.map(function (c) { return c.id; });
            var checkoutPage = Object.assign({}, state);
            checkoutPage.pendingChanges = checkoutPage.pendingChanges.filter(function (p) { return acceptedChangeIds_1.indexOf(p.id) === -1; });
            return checkoutPage;
        }
        case CHECKOUT_CLEARCART: {
            return Object.assign({}, state);
        }
        case CHECKOUT_UPDATE_SUCCESS: {
            var newState = action.page;
            newState.scrollPosition = undefined;
            if (state.pendingChanges && state.pendingChanges.length) {
                newState = applyPendingChanges(newState, state.pendingChanges);
                newState.pendingChanges = state.pendingChanges;
            }
            else {
                newState.pendingChanges = [];
            }
            return Object.assign({}, state, newState);
        }
        case PAGE_LOAD_SUCCESS:
        case PAGE_UPDATE_SUCCESS: {
            if (resolveComponent(pageState.componentName) === CheckoutPage) {
                var newState = action.page;
                newState.scrollPosition = undefined;
                if (state.pendingChanges && state.pendingChanges.length) {
                    newState = applyPendingChanges(newState, state.pendingChanges);
                    newState.pendingChanges = state.pendingChanges;
                }
                else {
                    newState.pendingChanges = [];
                }
                return Object.assign({}, state, newState);
            }
            return state;
        }
        default:
            break;
    }
    return state;
}
function applyPendingChanges(state, pendingChanges) {
    var newCartState = applyCartPendingChanges(clone(state.cart, false, 1), pendingChanges.map(function (change) { return change.cart; }));
    var newState = clone(state, false, 1);
    newState.cart = newCartState;
    return newState;
}
