var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import MenuColumn from './MenuColumn';
import TertiaryMenuColumn from './TertiaryMenuColumn';
import { toggleMainMenu } from '../action-creators';
import * as styles from './base.scss';
import classnames from 'classnames';
var MainMenuDesktop = /** @class */ (function (_super) {
    __extends(MainMenuDesktop, _super);
    function MainMenuDesktop(props) {
        var _this = _super.call(this, props) || this;
        _this.onEnter = function (columnIndex) {
            _this.timeout = setTimeout(function () { return _this.setState({ columnIndex: columnIndex }); }, 250);
        };
        _this.onLeave = function () {
            clearTimeout(_this.timeout);
        };
        _this.state = {
            columnIndex: 0,
        };
        return _this;
    }
    MainMenuDesktop.prototype.render = function () {
        return (this.props.isOpen && (React.createElement("nav", { className: classnames(styles.base, styles.menuWrapper, 'ui-test-desktop-menu-nav') },
            React.createElement(MenuColumn, { className: styles.primary, onEnter: this.onEnter, onLeave: this.onLeave, items: this.props.primaryMenuItems, activeColumn: this.state.columnIndex }),
            React.createElement(TertiaryMenuColumn, { className: styles.tertiary, items: this.props.primaryMenuItems[this.state.columnIndex]
                    ? this.props.primaryMenuItems[this.state.columnIndex].children
                    : [] }))));
    };
    return MainMenuDesktop;
}(React.PureComponent));
export default connect(function (state) { return ({
    isOpen: state.mainMenu.isOpen,
    primaryMenuItems: state.mainMenu.primaryMenuItems,
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch) { return ({
    toggleMainMenu: function () { return dispatch(toggleMainMenu()); },
}); })(MainMenuDesktop);
