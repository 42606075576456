import classnames from 'classnames';
import React from 'react';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductCategoryLabel from 'Shared/ProductCategoryLabel';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
import ProductMarks from 'Shared/ProductCampaignLabel';
import ProductCampaignDate from 'Shared/ProductComponents/ProductCampaignDate';
import ProductVariantSelector from 'Shared/ProductComponents/ProductVariantSelector';
import { ProductCardSelection } from 'Shared/ProductComponents/ProductCardSelection';
import Quantity from 'Shared/Quantity';
import AddRemoveFromListOrLogin from 'Shared/Product/AddRemoveFromListOrLogin';
import Price from 'Shared/Price';
import MyMenigoIcon from './../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
export var TOGGLE_MINICART = 'TOGGLE_MINICART';
export function toggleMiniCart() {
    return {
        type: TOGGLE_MINICART,
    };
}
export var buildProductRow = function (product, url, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, index, totalPrice, removeCartItem) {
    var parts = [];
    parts.push(ImageColumn(product, url, styles, selectedVariant.code));
    parts.push(ProductTitleAndDetailsColumn(product, url, styles));
    parts.push(TypeAndUnitColumn(product, styles, index, selectedVariant));
    parts.push(PriceColumn(product, styles, selectedVariant));
    parts.push(AvailabilityAndMarkingsColumn(product, styles, selectedVariant));
    parts.push(ActionButtonsColumn(product, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, index, totalPrice, removeCartItem));
    return parts;
};
export var buildProductRowForGridAndMobile = function (product, url, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, totalPrice, removeCartItem, isSupplierSalesman) {
    var parts = [];
    parts.push(ImageForGridAndMobile(product, url, styles, selectedVariant.code));
    parts.push(ProductTitleAndAvailabilityForGridAndMobile(product, url, styles, selectedVariant));
    parts.push(DetailsAndCategoryForGridAndMobile(product, url, styles));
    parts.push(PriceTypeAndUnitForGridAndMobile(product, styles, selectedVariant, isSupplierSalesman));
    parts.push(ActionButtonsForGridAndMobile(product, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, removeCartItem));
    parts.push(TotalSumForGridAndMobile(product, totalPrice, styles, isSupplierSalesman));
    return parts;
};
var ImageColumn = function (product, url, styles, code) {
    return (React.createElement("div", { key: 'l-1-' + product.content.code, className: classnames(styles.column, styles.imageWrap) },
        React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, code: code },
            React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.content.displayName, size: "tiny" }))));
};
var ProductTitleAndDetailsColumn = function (product, url, styles) {
    return (React.createElement("div", { key: 'l-2-' + product.content.code, className: classnames(styles.column, styles.detailsWrap) },
        React.createElement(ProductTitle, { linkUrl: url, ticket: product.ticket, displayName: product.content.displayName }),
        React.createElement(ProductDetails, { code: product.content.code, linkUrl: url, ticket: product.ticket, trademark: product.trademark, trademarkLink: product.trademarkLink }),
        React.createElement(ProductCategoryLabel, { areaId: product.areaId, area: product.area })));
};
var TypeAndUnitColumn = function (product, styles, index, selectedVariant) {
    return (React.createElement("div", { key: 'l-3-' + product.content.code, className: classnames(styles.column, styles.typeAndUnitWrap) },
        React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: selectedVariant, productArea: '', discontinuedOrBackordered: false, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign, tooltipAlignmentVertical: index === 0 ? 'bottom' : null, isRur: product.isRur })));
};
var PriceColumn = function (product, styles, selectedVariant) {
    return (React.createElement("div", { key: 'l-4-' + product.content.code, className: classnames(styles.column, styles.priceWrap) },
        React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: false })));
};
var AvailabilityAndMarkingsColumn = function (product, styles, selectedVariant) {
    return (React.createElement("div", { key: 'l-5-' + product.content.code, className: classnames(styles.column, styles.availabilityWrap) },
        React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability }),
        React.createElement("div", { className: styles.marksWrap },
            React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice }),
            React.createElement(ProductCampaignDate, { isUtf: product.isUtf, bestBeforeDate: product.bestBeforeDate, isInCampaign: product.isInCampaign, closestCampaignEndDate: product.closestCampaignEndDate, isProductIncludedInNewsCampaign: product.isProductIncludedInNewsCampaign, productCampaignDescriptions: product.campaignDescriptions }))));
};
var ActionButtonsColumn = function (product, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, index, totalPrice, removeCartItem) {
    return (React.createElement("div", { key: 'l-6-' + product.content.code, className: classnames(styles.column) },
        React.createElement("div", { className: classnames(styles.row) },
            React.createElement("div", { className: classnames(styles.column, styles.quantityAndVariantWrap) },
                React.createElement("div", { className: classnames(styles.top) },
                    React.createElement(React.Fragment, null,
                        React.createElement(ProductVariantSelector, { variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, preferedVariant: selectedVariant }),
                        React.createElement(ProductCardSelection, null, function (_a) {
                            var selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex;
                            return (React.createElement(Quantity, { update: updateCartQuantity, step: quantityStep, value: quantityInCart, focus: selectedIndex === index, onFocus: function () { return setSelectedIndex(index); }, minQuantity: 0, isCartQuantity: true }));
                        }))),
                React.createElement("div", { className: classnames(styles.bottom) },
                    React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product }, RemoveButton(styles, removeCartItem)))),
            React.createElement("div", { className: classnames(styles.column, styles.sumWrap) },
                React.createElement(Price, { current: totalPrice.current, currencySymbol: totalPrice.currencySymbol })))));
};
var ImageForGridAndMobile = function (product, url, styles, code) {
    return (React.createElement("div", { key: 'gm-1-' + product.content.code, className: styles.row },
        React.createElement("div", { className: styles.column },
            React.createElement("div", { className: styles.marksWrap },
                React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice }),
                React.createElement(ProductCampaignDate, { isUtf: product.isUtf, bestBeforeDate: product.bestBeforeDate, isInCampaign: product.isInCampaign, closestCampaignEndDate: product.closestCampaignEndDate, isProductIncludedInNewsCampaign: product.isProductIncludedInNewsCampaign, productCampaignDescriptions: product.campaignDescriptions })),
            React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, code: code },
                React.createElement("div", { className: styles.imageWrap },
                    React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.content.displayName, size: "tiny" }))))));
};
var ProductTitleAndAvailabilityForGridAndMobile = function (product, url, styles, selectedVariant) {
    return (React.createElement("div", { key: 'gm-2-' + product.content.code, className: styles.row },
        React.createElement("div", { className: classnames(styles.column, styles.wide) },
            React.createElement("div", { className: styles.titleWrap },
                React.createElement(ProductTitle, { linkUrl: url, ticket: product.ticket, displayName: product.content.displayName }))),
        React.createElement("div", { className: classnames(styles.column, styles.narrow) },
            React.createElement("div", { className: styles.availabilityWrap },
                React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability })))));
};
var DetailsAndCategoryForGridAndMobile = function (product, url, styles) {
    return (React.createElement("div", { key: 'gm-3-' + product.content.code, className: classnames(styles.row, styles.extraBottomMargin) },
        React.createElement("div", { className: styles.column },
            React.createElement("div", { className: classnames(styles.detailsWrap) },
                React.createElement(ProductDetails, { code: product.content.code, linkUrl: url, ticket: product.ticket, trademark: product.trademark }))),
        React.createElement("div", { className: styles.column },
            React.createElement("div", { className: classnames(styles.productAreaWrap) },
                React.createElement(ProductCategoryLabel, { areaId: product.areaId, area: product.area })))));
};
var PriceTypeAndUnitForGridAndMobile = function (product, styles, selectedVariant, isSupplierSalesman) {
    var _a;
    return (React.createElement("div", { key: 'gm-4-' + product.content.code, className: styles.row },
        React.createElement("div", { className: styles.column },
            React.createElement("div", { className: classnames(styles.typeAndUnitAndPriceWrap) },
                React.createElement(ProductTypeAndUnit, { selectedVariant: selectedVariant, variants: product.variants, productArea: '', productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, discontinuedOrBackordered: false, isRur: product.isRur }))),
        React.createElement("div", { className: styles.column },
            React.createElement("div", { className: classnames(styles.priceWrap, (_a = {},
                    _a[styles.supplierSalesmanDiscountWrap] = isSupplierSalesman,
                    _a)) },
                React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: false })))));
};
var ActionButtonsForGridAndMobile = function (product, styles, selectedVariant, updateCartQuantity, quantityStep, quantityInCart, removeCartItem) {
    return (React.createElement("div", { key: 'gm-5-' + product.content.code, className: styles.contentWrap },
        React.createElement("div", { className: classnames(styles.contentRow, styles.contentFooter) },
            React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product }, RemoveButton(styles, removeCartItem)),
            React.createElement("div", { className: classnames(styles.quantityAndVariantWrap) },
                React.createElement(React.Fragment, null,
                    React.createElement(ProductVariantSelector, { variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, preferedVariant: selectedVariant }),
                    React.createElement(Quantity, { update: updateCartQuantity, step: quantityStep, value: quantityInCart, disabled: false, minQuantity: 0, isCartQuantity: true }))))));
};
var TotalSumForGridAndMobile = function (product, totalPrice, styles, isSupplierSalesman) {
    return (React.createElement("div", { key: 'gm-6-' + product.content.code, className: styles.mobileSumRow },
        React.createElement("div", { className: styles.sumWrap }, !isSupplierSalesman && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.sum },
                React.createElement(Price, { current: totalPrice.current, currencySymbol: totalPrice.currencySymbol })))))));
};
var RemoveButton = function (styles, removeCartItem) {
    return (React.createElement("div", { onClick: removeCartItem, className: classnames(styles.listAdd, 'ui-test-remove-item') },
        React.createElement("div", { className: classnames(styles.small) },
            React.createElement("div", { className: classnames(styles.circle) },
                React.createElement(MyMenigoIcon, { name: 'trash' })))));
};
