var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { MEDIUM } from 'Shared/breakpoints';
import { scrollPosition } from 'SiteLayout/Root';
import connect from 'Shared/connect';
import MiniCart from 'SiteLayout/MiniCart';
import Main from 'Shared/PageLayout';
import miniCartIsDisabled from 'SiteLayout/MiniCart/is-disabled-selector';
import { on } from 'SiteLayout/Root';
import { translate } from 'Shared/translate';
import TopHeader from 'SiteLayout/Header/TopHeader';
import MiddleHeader from 'SiteLayout/Header/MiddleHeader';
import DesktopMenu from 'SiteLayout/MainMenu/DesktopMenu/';
import { setMainMenu } from 'SiteLayout/MainMenu/action-creators';
import * as styles from './base.scss';
import Overlay from 'Shared/Overlay';
import { closeQuickSearch } from '../../Search/action-creators';
var HEIGHT_RATIO_MIN = 0.07;
var MainHeader = /** @class */ (function (_super) {
    __extends(MainHeader, _super);
    function MainHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.close = function () {
            !!_this.props.closeMainMenu && _this.props.closeMainMenu();
            _this.props.closeQuickSearch();
        };
        _this.state = {
            isSticky: true,
            isVisible: true,
        };
        return _this;
    }
    MainHeader.prototype.isMobile = function (currentBreakpoint) {
        if (currentBreakpoint === void 0) { currentBreakpoint = this.props.currentBreakpoint; }
        return currentBreakpoint <= MEDIUM;
    };
    MainHeader.prototype.calculateHeight = function () {
        this.rootElementOffsetHeight = this.rootElement.offsetHeight;
    };
    MainHeader.prototype.componentWillUnmount = function () {
        this.unsubscribeAutoHide && this.unsubscribeAutoHide();
        this.unsubscribeResize && this.unsubscribeResize();
    };
    MainHeader.prototype.componentDidMount = function () {
        var _this = this;
        this.calculateHeight();
        this.unsubscribeAutoHide = on('scroll', function () { return _this.stickyAutoHideListener(); });
        this.unsubscribeResize = on('resize', function () { return _this.calculateHeight(); });
    };
    MainHeader.prototype.stickyAutoHideListener = function () {
        var isVisible;
        var rootHeightRatio = this.rootElementOffsetHeight / window.screen.height;
        if (this.isMobile() || rootHeightRatio < HEIGHT_RATIO_MIN) {
            // Always show main menu if the height ratio of window is lower than `HEIGHT_RATIO_MIN` or is on mobile
            isVisible = true;
        }
        else {
            var y = scrollPosition();
            var isBelowPlaceholder = y > this.rootElementOffsetHeight;
            isVisible = !isBelowPlaceholder;
        }
        if (this.state.isVisible !== isVisible) {
            this.setState({ isVisible: isVisible });
        }
        this.currentScrollY = scrollPosition();
    };
    MainHeader.prototype.render = function () {
        var _this = this;
        var _a;
        var isMobile = this.props.currentBreakpoint < MEDIUM;
        return (React.createElement("header", { id: "mainHeader", className: styles.placeholder, ref: function (el) { return (_this.rootElement = el); } },
            React.createElement(Overlay, { enabled: this.props.isOpen && !isMobile, onClose: this.close },
                React.createElement("div", { className: classnames(styles.base, styles.sticky, (_a = {},
                        _a[styles.visible] = this.state.isVisible,
                        _a[styles.smaller] = !this.state.isVisible,
                        _a)) },
                    React.createElement(TopHeader, null),
                    React.createElement(MiddleHeader, { miniCartIsDisabled: this.props.miniCartIsDisabled, currentBreakpoint: this.props.currentBreakpoint, mainMenu: this.props.mainMenu, isLoggedIn: this.props.isLoggedIn }),
                    this.props.miniCartIsOpen && !isMobile && (React.createElement("div", { className: styles.miniCartWrapper },
                        React.createElement(Main, null,
                            React.createElement(MiniCart, null)))),
                    !isMobile && React.createElement(DesktopMenu, null)),
                React.createElement("noscript", { className: styles.noscript }, translate('/Errors/JavaScriptRequired')))));
    };
    return MainHeader;
}(React.PureComponent));
export default connect(function (state) { return ({
    miniCartIsOpen: state.miniCart.isOpen,
    isOpen: state.mainMenu.isOpen || (state.quickSearch.isOpen && state.quickSearch.completionsLists !== null),
    miniCartIsDisabled: miniCartIsDisabled(state),
    currentBreakpoint: state.currentBreakpoint,
    mainMenu: state.mainMenu,
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    currentUser: state.currentUser,
}); }, function (dispatch) { return ({
    closeMainMenu: function () { return dispatch(setMainMenu(false)); },
    closeQuickSearch: function () { return dispatch(closeQuickSearch()); },
}); })(MainHeader);
