import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import { Export } from '../../../action-creators';
var ExportButton = function (_a) {
    var Export = _a.Export, className = _a.className;
    return (React.createElement("div", null,
        React.createElement(Button, { className: className, onClick: function () { return Export(); }, appearance: 'secondary' }, translate('/RecipeMenu/Export'))));
};
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    Export: function () { return dispatch(Export()); },
}); })(ExportButton);
