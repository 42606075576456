var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import Button from 'Shared/Button';
import * as server from 'Shared/server';
import classnames from 'classnames';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import { TINY } from 'Shared/breakpoints';
import { Radio } from 'Shared/Fields';
import DeliveryGroupOrder from './DeliveryGroupOrder';
import OrderDetail from './OrderHeader/details';
import OrderHeader from './OrderHeader';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Spinner from 'Shared/Spinner';
import * as loading from '../../loading.scss';
var OrderOverviewBlock = /** @class */ (function (_super) {
    __extends(OrderOverviewBlock, _super);
    function OrderOverviewBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentSelected: 'currentOrders',
            orderHeadersState: [],
            isLoading: true,
            activePage: 1,
            listPerPage: _this.props.pageSize,
            currentGrouping: 'PerOrder',
            itemsList: [],
            visibleItems: [],
            hasSelectedOrders: false,
            pageCount: [],
            selectedOrderGroupsAndItemsInGroup: [],
        };
        return _this;
    }
    OrderOverviewBlock.prototype.componentDidMount = function () {
        this.sortGroup(this.state.currentGrouping);
    };
    OrderOverviewBlock.prototype.notifySelectedItemsChange = function () {
        var selectedDeliveries = this.state.itemsList;
        var toDelete = selectedDeliveries
            .map(function (d) {
            return d.orderHeaderItems.filter(function (f) {
                return f.isSelected;
            });
        })
            .filter(function (f) {
            return f.length > 0;
        });
        this.setState({
            hasSelectedOrders: toDelete.length > 0,
        });
    };
    OrderOverviewBlock.prototype.onLoadOrderView = function (type) {
        var _this = this;
        this.setState({
            isLoading: true,
            hasSelectedOrders: false,
            currentSelected: type,
            pageCount: [],
        });
        if (type === 'currentOrders') {
            this.sortGroup(this.state.currentGrouping);
        }
        else {
            server.postJson('/orderoverviewblock/getorderlistbytype', { orderType: type }).then(function (result) {
                _this.setState({
                    orderHeadersState: result,
                    activePage: 1,
                    itemsList: [],
                    visibleItems: [],
                    selectedOrderGroupsAndItemsInGroup: [],
                    hasSelectedOrders: false,
                });
                _this.loadVisiblePageItems(_this.state.activePage);
                _this.setState({
                    isLoading: false,
                    hasSelectedOrders: false,
                });
            });
        }
    };
    OrderOverviewBlock.prototype.onSelectOrderView = function (key) {
        this.onLoadOrderView(key);
    };
    OrderOverviewBlock.prototype.loadVisiblePageItems = function (pageNumber) {
        var indexOfLastRecord = pageNumber * this.state.listPerPage;
        var indexOfFirstRecord = indexOfLastRecord - this.state.listPerPage;
        var currentRecords = typeof this.state.itemsList !== typeof undefined
            ? this.state.itemsList.slice(indexOfFirstRecord, indexOfLastRecord)
            : new Array();
        var pages = Array();
        var isDisableNextNav = true;
        if (typeof this.state.itemsList !== typeof undefined) {
            for (var i = 1; i <= Math.ceil(this.state.itemsList.length / this.state.listPerPage); i++) {
                pages.push(i);
            }
            isDisableNextNav =
                Math.ceil(indexOfLastRecord / this.state.listPerPage) !==
                    Math.ceil(this.state.itemsList.length / this.state.listPerPage);
        }
        this.setState({
            isDisableNextNav: indexOfFirstRecord !== 0,
            pageCount: pages,
            isLoading: false,
            visibleItems: currentRecords,
        });
    };
    OrderOverviewBlock.prototype.onSelectPage = function (value) {
        this.setState({
            activePage: value,
            isLoading: true,
            hasSelectedOrders: false,
        });
        this.loadVisiblePageItems(value);
    };
    OrderOverviewBlock.prototype.onSelectNavPageAction = function (currentPage, action) {
        this.setState({
            activePage: action === 'next' ? currentPage + 1 : currentPage - 1,
            isLoading: false,
            hasSelectedOrders: false,
        });
        this.loadVisiblePageItems(currentPage);
    };
    OrderOverviewBlock.prototype.getDeliveryItems = function (orderType, orderGrouping) {
        var _this = this;
        this.setState({ itemsList: [] });
        server
            .postJson('/orderoverviewblock/GetAllOrderItems', { orderType: orderType, orderGrouping: orderGrouping })
            .then(function (result) {
            _this.setState({
                itemsList: result,
                hasSelectedOrders: false,
                selectedOrderGroupsAndItemsInGroup: result,
            });
            _this.loadVisiblePageItems(_this.state.activePage);
        });
    };
    OrderOverviewBlock.prototype.sortGroup = function (sort) {
        var _this = this;
        switch (sort) {
            case 'PerOrder':
                this.setState({
                    isLoading: true,
                    currentGrouping: 'PerOrder',
                    hasSelectedOrders: false,
                }, function () {
                    _this.getDeliveryItems(_this.state.currentSelected, _this.state.currentGrouping);
                });
                break;
            case 'PerDelivery':
                this.setState({
                    isLoading: true,
                    currentGrouping: 'PerDelivery',
                    hasSelectedOrders: false,
                }, function () {
                    _this.getDeliveryItems(_this.state.currentSelected, _this.state.currentGrouping);
                });
                break;
            case 'ViewAll':
                this.setState({
                    isLoading: true,
                    currentGrouping: 'ViewAll',
                    hasSelectedOrders: false,
                }, function () {
                    _this.getDeliveryItems(_this.state.currentSelected, _this.state.currentGrouping);
                });
                break;
            default:
                this.setState({
                    currentGrouping: 'PerOrder',
                    itemsList: [],
                    hasSelectedOrders: false,
                });
                break;
        }
    };
    OrderOverviewBlock.prototype.onPrint = function (orderGrouping, selectedOrderGroupsAndItemsInGroup) {
        server
            .post('/orderoverviewblock/SetPrintData', {
            orderGrouping: orderGrouping,
            selectedOrderGroupsAndItemsInGroup: selectedOrderGroupsAndItemsInGroup,
        })
            .then(function (result) {
            window.open('/orderoverviewblock/print', '_blank');
        });
    };
    OrderOverviewBlock.prototype.toggleSelectedOrderGroupAndAllOrderItems = function (groupId) {
        if (this.state.selectedOrderGroupsAndItemsInGroup.length !== 0) {
            var newGroupSelected_1 = __spread(this.state.selectedOrderGroupsAndItemsInGroup);
            newGroupSelected_1[groupId].isSelected = !newGroupSelected_1[groupId].isSelected;
            var newItemsSelected = newGroupSelected_1[groupId].orderHeaderItems;
            if (newItemsSelected != null && newItemsSelected.length > 0) {
                newItemsSelected.forEach(function (element) {
                    element.isSelected = newGroupSelected_1[groupId].isSelected;
                });
                newGroupSelected_1[groupId].orderHeaderItems = newItemsSelected;
            }
            this.setState({ selectedOrderGroupsAndItemsInGroup: newGroupSelected_1 });
        }
        else {
            console.log('selectedGroupsAndItemsInGroup has no items');
        }
    };
    OrderOverviewBlock.prototype.toggleSelectedOrderItem = function (orderNumber) {
        if (this.state.selectedOrderGroupsAndItemsInGroup.length !== 0) {
            var Group = __spread(this.state.selectedOrderGroupsAndItemsInGroup);
            Group.forEach(function (i, c) {
                i.orderHeaderItems.forEach(function (a) {
                    if (a.orderNumber === orderNumber) {
                        a.isSelected = !a.isSelected;
                    }
                });
            });
            this.setState({ selectedOrderGroupsAndItemsInGroup: Group });
        }
        else {
            console.log('selectedGroupsAndItemsInGroup has no items');
        }
    };
    OrderOverviewBlock.prototype.notifySelectedOrderItemChange = function (orderNumber) {
        this.toggleSelectedOrderItem(orderNumber);
        this.toggleOnlyOrderGroup(this.resolvOrderGroupBoxIdOnOrderItemToggle(orderNumber));
    };
    OrderOverviewBlock.prototype.toggleOnlyOrderGroup = function (groupId) {
        if (this.state.selectedOrderGroupsAndItemsInGroup.length !== 0) {
            var newGroupSelected = __spread(this.state.selectedOrderGroupsAndItemsInGroup);
            var newOrderHeadersSelected = newGroupSelected[groupId].orderHeaderItems;
            if (newGroupSelected[groupId].orderHeaderItems != null) {
                var newItemtrue = countTrue(newOrderHeadersSelected);
                var selectGroupBox = newItemtrue >= newOrderHeadersSelected.length ? true : false;
                newGroupSelected[groupId].isSelected = !newGroupSelected[groupId].isSelected && selectGroupBox;
            }
            else {
                newGroupSelected[groupId].isSelected = !newGroupSelected[groupId].isSelected;
                newGroupSelected[groupId].orderHeaderItems = newOrderHeadersSelected;
            }
            this.setState({ selectedOrderGroupsAndItemsInGroup: newGroupSelected });
        }
        else {
            console.log('selectedGroupsAndItemsInGroup has no items');
        }
        function countTrue(obj) {
            var IsTrue = 0;
            for (var o in obj) {
                if (obj[o].isSelected) {
                    IsTrue++;
                }
            }
            return IsTrue;
        }
    };
    OrderOverviewBlock.prototype.resolvOrderGroupBoxIdOnOrderItemToggle = function (orderNumber) {
        var groupId = null;
        if (this.state.selectedOrderGroupsAndItemsInGroup.length !== 0) {
            var newSelected = __spread(this.state.selectedOrderGroupsAndItemsInGroup);
            newSelected.forEach(function (i, c) {
                var orderHeaderArray = i.orderHeaderItems;
                orderHeaderArray.forEach(function (orderHeader) {
                    if (orderHeader.orderNumber === orderNumber) {
                        return (groupId = c);
                    }
                });
            });
        }
        return groupId;
    };
    OrderOverviewBlock.prototype.render = function () {
        var _this = this;
        var _a;
        var isExtensive = this.props.currentBreakpoint > TINY;
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.topRow },
                React.createElement("div", { className: styles.orderStateLine },
                    React.createElement(FeedbackButton, { type: "submit", appearance: this.state.currentSelected === 'currentOrders' ? 'primary' : 'monochrome', ref: function (el) { return (_this.feedbackButton = el); }, onClick: function (e) { return _this.onSelectOrderView('currentOrders'); } }, translate('/MyMenigo/currentOrders')),
                    React.createElement(FeedbackButton, { type: "submit", appearance: this.state.currentSelected === 'historyOrders' ? 'primary' : 'monochrome', ref: function (el) { return (_this.feedbackButton = el); }, onClick: function (e) { return _this.onSelectOrderView('historyOrders'); } }, translate('/MyMenigo/historyOrders'))),
                React.createElement("div", { className: styles.printContainer }, this.state.currentSelected === 'currentOrders' && (React.createElement(Button, { appearance: 'primary', onClick: function () { return _this.onPrint(_this.state.currentGrouping, _this.state.selectedOrderGroupsAndItemsInGroup); } },
                    React.createElement("span", null, translate('/OrderPrint/Print')))))),
            React.createElement("div", { className: styles.orderOptions }, this.state.currentSelected === 'currentOrders' ? (React.createElement("div", { className: isExtensive ? styles.groupFilters : styles.mobileGroupFilters },
                React.createElement("div", { className: styles.wrap },
                    React.createElement("div", null,
                        React.createElement("span", null, translate('/MyMenigo/OrderGrouping/Grouping'))),
                    React.createElement("div", { className: styles.wrap },
                        React.createElement(Radio, { checked: this.state.currentGrouping === 'PerOrder', name: "currentGrouping", value: "PerOrder", disabled: this.state.itemsList.length === 0 || this.state.isLoading, onChange: function (event) { return _this.sortGroup(event.target.value); } },
                            React.createElement("span", null, translate('/MyMenigo/OrderGrouping/PerOrder'))),
                        React.createElement(Radio, { checked: this.state.currentGrouping === 'PerDelivery', name: "currentGrouping", value: "PerDelivery", disabled: this.state.itemsList.length === 0 || this.state.isLoading, onChange: function (event) { return _this.sortGroup(event.target.value); } },
                            React.createElement("span", null, translate('/MyMenigo/OrderGrouping/PerDelivery'))),
                        React.createElement(Radio, { checked: this.state.currentGrouping === 'ViewAll', name: "currentGrouping", value: "ViewAll", disabled: this.state.itemsList.length === 0 || this.state.isLoading, onChange: function (event) { return _this.sortGroup(event.target.value); } },
                            React.createElement("span", null, translate('/MyMenigo/OrderGrouping/ViewAll'))))))) : null),
            this.state.isLoading ? (React.createElement(Spinner, { className: styles.spinner })) : (React.createElement("div", null, this.state.orderHeadersState.length > 0 && this.state.currentSelected === 'historyOrders' ? (React.createElement("div", { className: classnames(isExtensive ? styles.viewLane : styles.mobileViewLane, (_a = {},
                    _a[loading.loading] = this.state.isLoading,
                    _a)) },
                React.createElement("div", { className: styles.singleOrderContainer },
                    React.createElement(OrderHeader, { currentTab: this.state.currentSelected, orderHeaders: this.state.orderHeadersState, currentGrouping: this.state.currentGrouping, notifySelectedItems: function () { return _this.notifySelectedItemsChange(); }, isExtensive: isExtensive })))) : this.state.visibleItems.length > 0 ? (this.state.currentGrouping === 'ViewAll' ? (React.createElement(OrderDetail, { isExtensive: isExtensive, isItemShown: true, OrderDetailsList: this.state.visibleItems, TotalOrderPrice: this.state.itemsList
                    .map(function (item) {
                    return item.price * item.quantity;
                })
                    .reduce(function (a, b) {
                    return a + b;
                }), currentTab: this.state.currentSelected, currentGrouping: this.state.currentGrouping, isSelectable: true })) : (this.state.selectedOrderGroupsAndItemsInGroup.map(function (item, index) { return (React.createElement("div", { key: index, className: styles.singleOrderContainer },
                React.createElement(DeliveryGroupOrder, { key: index, deliveryItem: item, isExtensive: isExtensive, filterView: _this.state.currentGrouping, currentTab: _this.state.currentSelected, currentGrouping: _this.state.currentGrouping, notifySelectedItems: function () { return _this.notifySelectedItemsChange(); }, notifySelectedOrderItemChange: function (ordernumber) { return _this.notifySelectedOrderItemChange(ordernumber); }, checked: _this.state.selectedOrderGroupsAndItemsInGroup[index].isSelected, onChange: function () {
                        return _this.toggleSelectedOrderGroupAndAllOrderItems(index + (_this.state.activePage - 1) * _this.props.pageSize);
                    } }))); }))) : (React.createElement("h5", null, translate('/MyMenigo/NoOrderFound'))))),
            this.state.pageCount.length > 1 && !this.state.isLoading ? (isExtensive ? (React.createElement("div", { className: styles.pagingMainContainer },
                React.createElement("div", { className: styles.paginationContent },
                    React.createElement("ul", { className: classnames(styles.paginate, styles.page) },
                        React.createElement("li", { className: this.state.isDisablePrevNav ? styles.navpage : styles.notallowednavpage, onClick: this.state.isDisablePrevNav
                                ? function () { return _this.onSelectNavPageAction(_this.state.activePage, 'prev'); }
                                : void 0 },
                            React.createElement("a", { href: void 0, rel: "prev" }, translate('/MyMenigo/OrderOverviewHeaders/Pagination/PageNavPrev'))),
                        this.state.pageCount.map(function (p, index) {
                            return _this.state.activePage === p ? (React.createElement("li", { key: index, className: styles.current, onClick: _this.onSelectPage.bind(_this, p) }, p)) : (React.createElement("li", { key: index, onClick: _this.onSelectPage.bind(_this, p) },
                                React.createElement("a", { href: "#" }, p)));
                        }),
                        React.createElement("li", { className: this.state.isDisableNextNav ? styles.navpage : styles.notallowednavpage, onClick: this.state.isDisableNextNav
                                ? function () { return _this.onSelectNavPageAction(_this.state.activePage, 'next'); }
                                : void 0 },
                            React.createElement("a", { href: void 0, rel: "next" }, translate('/MyMenigo/OrderOverviewHeaders/Pagination/PageNavNext'))))))) : (React.createElement("div", { className: styles.mobilePagingLane },
                React.createElement("div", { className: styles.prevContainer }, this.state.isDisablePrevNav ? (React.createElement("a", { href: void 0, rel: "prev" },
                    React.createElement(Button, { appearance: ['icon', 'small', 'large'], onClick: function () { return _this.onSelectNavPageAction(_this.state.activePage, 'prev'); } },
                        React.createElement("span", null,
                            React.createElement(Icon, { name: "arrow-left" })),
                        React.createElement("span", null, "Prev")))) : null),
                React.createElement("div", { className: styles.paging },
                    React.createElement("span", null, "Page " + this.state.activePage + " of " + this.state.pageCount.length)),
                React.createElement("div", { className: styles.nextContainer }, this.state.isDisableNextNav ? (React.createElement("a", { href: void 0, rel: "next" },
                    React.createElement(Button, { appearance: ['icon', 'small', 'large'], onClick: function () { return _this.onSelectNavPageAction(_this.state.activePage, 'next'); } },
                        React.createElement("span", null, "Next"),
                        React.createElement("span", null,
                            React.createElement(Icon, { name: "arrow-right" }))))) : null)))) : null));
    };
    return OrderOverviewBlock;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(OrderOverviewBlock);
