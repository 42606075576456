var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import classnames from 'classnames';
var getBackgroundColor = function (props) {
    var backgroundColor = !!props.backgroundColor && props.backgroundColor;
    switch (backgroundColor) {
        case 'Green':
            return styles.green;
        case 'Warning':
            return styles.warning;
        case 'Yellow':
            return styles.yellow;
        default:
            return styles.warning;
    }
};
var NotificationBanner = /** @class */ (function (_super) {
    __extends(NotificationBanner, _super);
    function NotificationBanner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotificationBanner.prototype.render = function () {
        return (React.createElement("div", { className: classnames(styles.base, getBackgroundColor(this.props)) },
            React.createElement("div", { className: styles.inner },
                React.createElement("h4", { className: styles.inspiration }, this.props.header),
                React.createElement("p", { className: styles.inspiration }, this.props.message),
                !!this.props.targetUrl && (React.createElement("a", { href: this.props.targetUrl, className: styles.link }, translate('/Notifications/ReadMore'))))));
    };
    return NotificationBanner;
}(React.Component));
export default NotificationBanner;
