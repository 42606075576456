var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { get, set } from 'Shared/local-storage';
var STORAGE_NAME = 'TabsInEditMode';
var TabInEditModeHandler = /** @class */ (function (_super) {
    __extends(TabInEditModeHandler, _super);
    function TabInEditModeHandler(props) {
        var _this = _super.call(this, props) || this;
        _this.uniqueTabIdentifyer = (Math.random() + 1).toString(36).substring(3);
        _this.props.setref(_this);
        return _this;
    }
    TabInEditModeHandler.prototype.componentDidMount = function () {
        var _this = this;
        this.setNewState(this.props.isInEdit);
        window.onbeforeunload = function () {
            _this.removeTab();
        };
    };
    TabInEditModeHandler.prototype.componentWillUnmount = function () {
        this.removeTab();
    };
    TabInEditModeHandler.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.isInEdit !== prevProps.isInEdit) {
            this.setNewState(this.props.isInEdit);
        }
        if (this.props.currentUser.isLoggedIn !== prevProps.currentUser.isLoggedIn) {
            this.clearAllTabs();
        }
    };
    TabInEditModeHandler.prototype.getStateFromStore = function () {
        return get(STORAGE_NAME, {});
    };
    TabInEditModeHandler.prototype.setNewState = function (state) {
        var store = this.getStateFromStore();
        store[this.uniqueTabIdentifyer] = state;
        set(STORAGE_NAME, store);
    };
    TabInEditModeHandler.prototype.removeTab = function () {
        var store = this.getStateFromStore();
        delete store[this.uniqueTabIdentifyer];
        set(STORAGE_NAME, store);
    };
    TabInEditModeHandler.prototype.clearAllTabs = function () {
        set(STORAGE_NAME, {});
    };
    TabInEditModeHandler.prototype.anyTabInEditMode = function () {
        var store = this.getStateFromStore();
        return Object.keys(store).some(function (tab) { return Boolean(store[tab]); });
    };
    TabInEditModeHandler.prototype.render = function () {
        return React.createElement("div", null);
    };
    return TabInEditModeHandler;
}(React.Component));
export default connect(function (state) { return ({
    currentUser: state.currentUser,
    isInEdit: state.appShellData.pageIsInEditMode,
}); }, function (dispatch) { return ({}); })(TabInEditModeHandler);
