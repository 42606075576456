import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import classnames from 'classnames';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
import Button from 'Shared/Button';
import { pushState } from 'Shared/history';
var HeroBlock = function (props) {
    var _a;
    var backgroundImage = !!props.block.heroImage ? propertyValueFor(props.block.heroImage) : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
    var useDarkGradient = !!props.block.darkHeroImage && propertyValueFor(props.block.darkHeroImage);
    var onButtonClick = function () {
        var url = !!props.block.buttonLink && propertyValueFor(props.block.buttonLink);
        !!url && pushState(url);
    };
    return (React.createElement("div", { className: classnames(styles.base), style: { backgroundImage: 'url(' + backgroundImage + ')' } },
        React.createElement("div", { className: classnames(styles.inner, (_a = {}, _a[styles.darkBackground] = !!useDarkGradient, _a)) },
            React.createElement("div", null,
                !!props.block.heroHeaderText && React.createElement(PropertyFor, { tagName: 'h1', className: styles.inspiration, property: props.block.heroHeaderText }),
                !!props.block.heroBodyText && React.createElement(PropertyFor, { tagName: 'p', className: styles.preamble, property: props.block.heroBodyText }),
                !!props.block.buttonText && !!propertyValueFor(props.block.buttonText) &&
                    React.createElement(Button, { onClick: onButtonClick, appearance: "primary" }, propertyValueFor(props.block.buttonText)),
                !!props.block.secondaryText && React.createElement(PropertyFor, { tagName: 'div', className: styles.secondaryText, property: props.block.secondaryText })))));
};
export default HeroBlock;
