var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Main, { Part } from 'Shared/PageLayout';
import Button from 'Shared/Button';
import IpsItemRow from './IpsItemRow';
import { loadList, addItem, clearItems, removeItem, exportList, changeItemQuantity, changeItemPrice, changeTaxRate, clearState, toggleEdit, } from '../../action-creators';
import Searchbar from '../../../App/Search/Searchbar';
import Backbutton from '../../../App/Backbutton';
import SearchResultList from '../../../App/Search/SearchResultsList';
import ProductItem from '../../../App/ProductItem/ProductItem';
import { ExportingStatus } from '../../reducer';
import FeedbackPage from './FeedbackPage';
import * as styles from './base.scss';
import classnames from 'classnames';
var mode = 'ips';
var IpsPage = /** @class */ (function (_super) {
    __extends(IpsPage, _super);
    function IpsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { lockedField: null, lockedFieldValue: null };
        return _this;
    }
    IpsPage.prototype.componentWillMount = function () {
        this.props.loadList();
    };
    IpsPage.prototype.componentWillUnmount = function () {
        this.props.clearState();
    };
    IpsPage.prototype.handleSelectedItem = function (item) {
        var option = item.unitOptions.find(function (i) { return i.unitShortName.toLowerCase() === 'st' || i.unitShortName.toLowerCase() === 'kg'; }) ||
            item.unitOptions[0];
        var priceNotNull = option && option.priceWithoutVat;
        var label = {
            id: item.productId,
            displayName: item.displayName,
            quantity: 1,
            menigoPrice: priceNotNull ? option.priceWithoutVat : 0,
            price: priceNotNull ? Math.round(option.priceWithoutVat * (1 + this.props.taxRate / 100) * 100) / 100 : 0,
            isEditing: true,
            unitShortName: option.unitShortName,
        };
        this.props.addItem(label);
    };
    IpsPage.prototype.handleLockField = function (lockedField, lockedFieldValue) {
        if (lockedField === this.state.lockedField && lockedFieldValue === this.state.lockedFieldValue) {
            lockedField = null;
            lockedFieldValue = null;
        }
        if (lockedField === null && lockedFieldValue !== null) {
            this.setState({ lockedFieldValue: lockedFieldValue });
        }
        else {
            this.setState({ lockedField: lockedField, lockedFieldValue: lockedFieldValue });
        }
    };
    IpsPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { className: styles.main },
            this.props.exportingStatus !== ExportingStatus.None && (React.createElement(Part, { appearance: "divider", className: styles.part },
                React.createElement(FeedbackPage, null))),
            this.props.exportingStatus === ExportingStatus.None && [
                React.createElement(Backbutton, { key: "title", title: translate('/App/IPSButton') }),
                React.createElement(Part, { className: styles.part, appearance: "divider", key: "toolbar" },
                    React.createElement(Searchbar, { endpoint: '/ips' }),
                    React.createElement(SearchResultList, { template: ProductItem, onItemSelected: function (item) { return _this.handleSelectedItem(item); } })),
                React.createElement(Part, { className: styles.part, appearance: "divider", key: "list" },
                    this.props.items.length === 0 && (React.createElement("div", { className: styles.emptyRows },
                        React.createElement("strong", null, translate('/App/Ips/OrderEmptyHeader')),
                        React.createElement("p", null, translate('/App/Ips/OrderEmpty')))),
                    !this.props.searchListIsVisible &&
                        this.props.items &&
                        this.props.items.map(function (item) { return (React.createElement(IpsItemRow, __assign({ key: item.id, remove: function () { return _this.props.removeItem(item.id); }, editQuantity: function (value) { return _this.props.changeItemQuantity(item.id, parseFloat(value)); }, editPrice: function (value) { return _this.props.changeItemPrice(item.id, parseFloat(value)); }, editTax: function (value) { return _this.props.changeTaxRate(parseFloat(value)); }, toggleEdit: function (editing) { return _this.props.toggleEdit(item.id, editing); }, lockField: function (field, value) { return _this.handleLockField(field, value); }, lockedField: _this.state.lockedField, lockedFieldValue: _this.state.lockedFieldValue, taxRate: _this.props.taxRate }, item))); })),
            ],
            React.createElement(Part, { appearance: "divider", className: classnames(styles.buttons, styles.part) },
                this.props.exportingStatus === ExportingStatus.None && (React.createElement(Button, { onClick: function () { return _this.props.exportList(); } }, translate('/App/Ips/OrderIps'))),
                this.props.exportingStatus === ExportingStatus.Success && (React.createElement(Button, { onClick: function () { return _this.props.clearItems(); } }, translate('/App/Ips/NewOrder'))))));
    };
    return IpsPage;
}(React.Component));
export default connect(function (state) { return ({
    searchListIsVisible: state.app.searchListIsVisible,
    items: state.ips.items,
    exportingStatus: state.ips.exportingStatus,
    taxRate: state.ips.taxRate,
}); }, function (dispatch) { return ({
    loadList: function () {
        dispatch(loadList(mode));
    },
    removeItem: function (id) {
        dispatch(removeItem(mode, id));
    },
    clearItems: function () {
        dispatch(clearItems(mode));
    },
    addItem: function (item) {
        dispatch(addItem(mode, item));
    },
    changeItemQuantity: function (id, value) {
        dispatch(changeItemQuantity(mode, id, value));
    },
    changeItemPrice: function (id, value) {
        dispatch(changeItemPrice(mode, id, value));
    },
    changeTaxRate: function (value) {
        dispatch(changeTaxRate(mode, value));
    },
    exportList: function () {
        dispatch(exportList(mode));
    },
    clearState: function () {
        dispatch(clearState(mode));
    },
    toggleEdit: function (id, editing) {
        dispatch(toggleEdit(mode, id, editing));
    },
}); })(IpsPage);
