import { parseUrl, urlToString, pathCombine } from 'Shared/url';
import * as itemCache from 'SiteLayout/CurrentPage/cache';
import * as appShell from '../AppShell';
import { getToken, saveToken } from '../App/app-login';
export var ESALES_TICKET_HEADER = 'X-ESales-Ticket';
export var ESALES_ACTION_HEADER = 'X-ESales-Action';
export var ESALES_ADD_TO_CART_ACTION = 'addtocart';
export var ESALES_CLICK_ACTION = 'click';
export var AJAX_REQUEST_QUERY_STRING = 'react';
export function get(url, headers) {
    if (headers === void 0) { headers = undefined; }
    return load(url, 'get', undefined, headers);
}
export var serverVersionHeaderName = 'X-Server-Version';
export function getItem(url, useCache) {
    if (useCache === void 0) { useCache = false; }
    var cachePromise = null;
    if (useCache) {
        var urlString = typeof url === 'string' ? url : urlToString(url);
        var cacheItem = itemCache.get(urlString);
        if (cacheItem) {
            cachePromise = Promise.resolve(cacheItem);
        }
    }
    // Even if we use the cache we still want to get a fresh version of it
    // and update the cache
    var fetchPromise = getJson(url).then(function (item) {
        var cacheItem = item;
        if (itemCache.isCacheItem(cacheItem)) {
            itemCache.set(cacheItem);
        }
        return item;
    });
    return cachePromise ? cachePromise : fetchPromise;
}
export function getItems(url) {
    return getJson(url).then(function (items) {
        if (items) {
            items.forEach(function (i) {
                if (itemCache.isCacheItem(i)) {
                    itemCache.set(i);
                }
            });
        }
        return items;
    });
}
export function getJson(url, headers) {
    if (headers === void 0) { headers = undefined; }
    return load(url, 'get', headers).then(function (r) { return r.json(); });
}
export function post(url, body, headers) {
    if (body === void 0) { body = undefined; }
    if (headers === void 0) { headers = undefined; }
    return load(url, 'post', body, headers);
}
export function head(url, body, headers) {
    if (body === void 0) { body = undefined; }
    if (headers === void 0) { headers = undefined; }
    return load(url, 'head', body, headers);
}
export function postJson(url, body, headers) {
    if (body === void 0) { body = undefined; }
    if (headers === void 0) { headers = undefined; }
    return load(url, 'post', body, headers).then(function (r) {
        if (!!r.redirected) {
            if (IS_APP) {
                window.location.reload();
            }
            else {
                console.log('User has likely been logged out. Redirecting to /starpage/loggedout');
                window.location.href = '/startpage/loggedout';
            }
        }
        return r.json();
    });
}
export function put(url, body, headers) {
    if (body === void 0) { body = undefined; }
    if (headers === void 0) { headers = undefined; }
    return load(url, 'put', body, headers);
}
function load(url, method, body, headers) {
    if (body === void 0) { body = undefined; }
    if (headers === void 0) { headers = {}; }
    var urlObject = parseUrl(url);
    var token = null;
    if (IS_APP) {
        // tslint:disable-next-line
        var baseUrl = parseUrl(window.APP_BASE_ADDRESS);
        urlObject.scheme = baseUrl.scheme;
        urlObject.host = baseUrl.host;
        urlObject.port = baseUrl.port;
        urlObject.path = pathCombine(baseUrl.path, urlObject.path);
        token = getToken();
    }
    // We append the extra querystring here because we can't request the same url for Ajax as what's in
    // the browser address bar. If we use the same url, the browser will use the JSON response from the browser
    // cache to serve up to the user if the user goes back from the site and then forward to it.
    urlObject.query[AJAX_REQUEST_QUERY_STRING] = null;
    var load = fetch(urlToString(urlObject, true), {
        headers: Object.assign({
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-Client-Version': CURRENT_VERSION,
        }, headers, token && { 'X-AuthToken': token }),
        credentials: 'same-origin',
        method: method,
        body: body !== undefined ? JSON.stringify(body) : undefined,
    })
        .then(function (r) {
        if (IS_APP) {
            var token_1 = r.headers.get('X-AuthToken');
            if (token_1) {
                var validity = r.headers.get('X-AuthTokenValidity');
                if (validity && /^[0-9]+$/.test(validity)) {
                    saveToken(token_1, parseInt(validity, 10));
                }
            }
        }
        if (serverHasDifferentMajorVersion(r)) {
            console.info('Nuking the local caches because the server has a higher major version');
            return Promise.all([itemCache.nuke(), appShell.nuke()]).then(function () {
                if (IS_APP) {
                    return Promise.reject('Server has a different major version');
                }
                else {
                    // Might seem drastic to do a full page reload when we get an Ajax response since it
                    // will probably interrupt the user. But a major version change means that data structures
                    // differ in a way that the client has to update. Major version changes should be few and far
                    // between enough to not make this a usability issue.
                    window.location.reload();
                }
                // Return never resolving promise to pause the chain so the page can reload
                return new Promise(function () { return null; });
            });
        }
        else {
            return r;
        }
    })
        .then(function (r) {
        if (r.status >= 200 && r.status < 400) {
            return r;
        }
        else {
            return Promise.reject({
                status: r.status,
            });
        }
    });
    /* For chaosMonkeyMode in non production */
    var delayNumber = (window.chaosMonkeyMode && window.chaosMonkeyMode.delay) || Math.round(Math.random() * 2 * 100) / 100;
    var failNumber = (window.chaosMonkeyMode && window.chaosMonkeyMode.fail) || 0.5;
    function delay(value) {
        console.debug('Delaying request for', delayNumber, 'seconds', urlToString(urlObject, true));
        return new Promise(function (resolve) { return setTimeout(function () { return resolve(value); }, delayNumber * 1000); });
    }
    function randomlyFail(value) {
        if (Math.random() < failNumber) {
            console.debug('Simulating failed request, probability: ', failNumber, urlToString(urlObject, true));
            return Promise.reject({
                status: 500,
            });
        }
        else {
            return value;
        }
    }
    if (process.env.NODE_ENV !== 'production') {
        if (window.chaosMonkeyMode) {
            load = load.then(delay).then(randomlyFail);
        }
    }
    return load;
}
function serverHasDifferentMajorVersion(response) {
    // If the response is an error we might have failed to add the server version
    if (!response.headers.has(serverVersionHeaderName)) {
        console.warn("Response didn't contain a server version in header");
        return false;
    }
    var serverMajor = response.headers.get(serverVersionHeaderName).split('.')[0];
    var clientMajor = CURRENT_VERSION.split('.')[0];
    return serverMajor !== clientMajor;
}
export function serverHasDifferentVersion(response) {
    // If the response is an error we might have failed to add the server version
    if (!response.headers.has(serverVersionHeaderName)) {
        console.warn("Response didn't contain a server version in header");
        return false;
    }
    return response.headers.get(serverVersionHeaderName) !== CURRENT_VERSION;
}
