var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import MyMenigoLayout from '../MyMenigoLayout';
import styles from '../base.scss';
var MyMenigoBasicLayout = /** @class */ (function (_super) {
    __extends(MyMenigoBasicLayout, _super);
    function MyMenigoBasicLayout(props) {
        return _super.call(this, props) || this;
    }
    MyMenigoBasicLayout.prototype.render = function () {
        var preamble = (React.createElement(React.Fragment, null,
            React.createElement(PropertyFor, { tagName: "h1", property: this.props.model.page.name }),
            React.createElement(PropertyFor, { tagName: "p", property: this.props.model.page.preamble })));
        return (React.createElement(MyMenigoLayout, { topLeft: preamble, contentCssClass: this.props.contentCssClass },
            React.createElement(React.Fragment, null,
                React.createElement(PropertyFor, { className: styles.fullChildBlock__item, property: this.props.model.page.mainContent }),
                this.props.children)));
    };
    return MyMenigoBasicLayout;
}(React.Component));
export default MyMenigoBasicLayout;
