var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Price from 'Shared/Price';
import Image from 'Shared/ImageFallback';
import connect from 'Shared/connect';
import Grade from '../../../../Recipes/Grade';
import RemoveRecipe from './RemoveRecipe';
import { updatePortions } from '../../../action-creators';
import classnames from 'classnames';
import * as styles from './base.scss';
var Body = /** @class */ (function (_super) {
    __extends(Body, _super);
    function Body(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            portions: _this.props.numberOfPortions
                ? _this.props.numberOfPortions.toString()
                : _this.props.originalNumberOfPortions.toString(),
        };
        return _this;
    }
    Body.prototype.updatePortionsState = function (parentId, menuItemId, portions) {
        if (portions !== this.state.portions) {
            this.props.updatePortions(parentId, menuItemId, portions);
            this.setState({
                portions: portions,
            });
        }
    };
    Body.prototype.render = function () {
        var _this = this;
        var _a = this.props, name = _a.name, portionCost = _a.portionCost, portionCostIncomplete = _a.portionCostIncomplete, url = _a.url, numberOfPortions = _a.numberOfPortions, parentId = _a.parentId, menuItemId = _a.menuItemId, originalNumberOfPortions = _a.originalNumberOfPortions;
        var textNode = (React.createElement("div", { className: styles.name },
            React.createElement("a", { href: url, className: styles.linkText },
                React.createElement("div", { className: styles.mobile },
                    React.createElement("b", null, this.props.name)))));
        var removeitemNode = (React.createElement(RemoveRecipe, { id: this.props.id, listId: this.props.parentId, name: name, className: styles.remove }));
        var gradeNode = React.createElement(Grade, { grade: this.props.grade, votes: this.props.voteCount });
        switch (this.props.appearance) {
            case 'box':
            case 'mobile':
            case 'compact':
            case 'list':
                return (React.createElement("div", null,
                    React.createElement("div", { className: classnames(styles.tablerow) },
                        React.createElement("div", { className: styles.col0 },
                            React.createElement("div", { className: classnames(styles.imageWrap) },
                                React.createElement(Image, { skipSrcSet: true, src: this.props.imageUrl, alt: this.props.name, size: "thumb" }))),
                        React.createElement("div", { className: styles.col5 },
                            React.createElement("div", null, textNode)),
                        this.props.isLoggedIn && (React.createElement("div", { className: styles.col1 },
                            React.createElement(Price, __assign({ className: styles.price }, portionCost, { size: "normal" })),
                            portionCostIncomplete && React.createElement("sup", null, "**"))),
                        React.createElement("div", { className: styles.col4 },
                            React.createElement("input", { defaultValue: numberOfPortions ? numberOfPortions.toString() : originalNumberOfPortions.toString(), onBlur: function (e) { return _this.updatePortionsState(parentId, menuItemId, e.target.value); }, type: "number" })),
                        React.createElement("div", { className: styles.col2 }, gradeNode),
                        React.createElement("div", { className: styles.col3 }, removeitemNode))));
            default:
                return null;
        }
    };
    return Body;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
}); }, function (dispatch) { return ({
    updatePortions: function (parentId, menuItemId, portions) {
        dispatch(updatePortions(parentId, menuItemId, portions));
    },
}); })(Body);
