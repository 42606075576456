var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback, useRef } from 'react';
import Modal, { ModalType } from 'Shared/Modal';
import { translate } from 'Shared/translate';
import { get, set } from 'Shared/session-storage';
import Dialog, { DialogType } from 'Shared/Dialog';
import { pushState } from 'Shared/history';
import Checkbox from 'Shared/Fields/Checkbox';
import cookieHandler from './../../../Helpers/cookieHandler';
import * as styles from './base.scss';
import classnames from 'classnames';
var STORAGE_KEY = 'alcohol';
export default function LinkWithAlcoholWarning(_a) {
    var onClick = _a.onClick, href = _a.href, children = _a.children, targetLink = _a.targetLink, hasAlcoholWarning = _a.hasAlcoholWarning, wrap = _a.wrap, text = _a.text, restProps = __rest(_a, ["onClick", "href", "children", "targetLink", "hasAlcoholWarning", "wrap", "text"]);
    var linkRef = useRef();
    var linkProps = { href: targetLink ? targetLink.url : href };
    if (targetLink && targetLink.openInNewWindow) {
        linkProps.target = '_blank';
    }
    var _b = __read(useState(false), 2), isOpen = _b[0], setOpen = _b[1];
    var _c = __read(useState(false), 2), isChoiceRemembered = _c[0], setChoiceRemembered = _c[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleConfirm = useCallback(function () {
        if (isChoiceRemembered) {
            cookieHandler.setCookie(STORAGE_KEY, 'true', 0, true);
            set(STORAGE_KEY, true);
        }
        return pushState(linkProps.href, { hideSpinner: true });
    }, [linkProps.href, isChoiceRemembered]);
    var handleLinkClick = useCallback(function (e) {
        var needsAgeConfirm = !get(STORAGE_KEY);
        if (hasAlcoholWarning && needsAgeConfirm) {
            setOpen(true);
            e.preventDefault();
            return false;
        }
        !!onClick && onClick(e);
    }, [hasAlcoholWarning]);
    var toggleRemember = function () {
        setChoiceRemembered(function (state) { return !state; });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("a", __assign({ ref: linkRef, onClick: handleLinkClick, "data-may-prevent-default": true }, restProps, linkProps, { className: classnames(!wrap && styles.nonWrap, linkProps.className, restProps.className) }),
            wrap && children,
            !wrap && !!text && (React.createElement("span", { className: styles.hidden }, text))),
        !wrap && children,
        React.createElement(Modal, { title: translate('/AlcoholModal/Header'), isOpen: isOpen, onClose: handleClose, type: ModalType.Warning, reserveSpaceForCloseButton: true },
            React.createElement("p", null, translate('/AlcoholModal/Body')),
            React.createElement("label", { className: styles.remember },
                React.createElement(Checkbox, { onChange: toggleRemember, checked: isChoiceRemembered }),
                React.createElement("span", null, translate('/AlcoholModal/RememberThisChoice'))),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleConfirm, onCancel: handleClose, customAcceptText: translate('/AlcoholModal/Accept'), customDeclineText: translate('/AlcoholModal/Decline') }))));
}
