var siteSettings = null;
export function setKnownUrls(settings) {
    siteSettings = settings;
}
export function searchPageUrl() {
    return siteSettings.searchPage;
}
export function myOrdersPageUrl() {
    return siteSettings.myOrdersPage;
}
export function checkoutPageUrl() {
    return siteSettings.checkoutPage;
}
export function myMenigoPageUrl() {
    return siteSettings.myMenigoPage;
}
export function returnOrderPageUrl() {
    return siteSettings.returnOrderPage;
}
export function lawPageUrl() {
    return siteSettings.lawPage;
}
export function pantryPageUrl() {
    return siteSettings.pantryOverviewPage;
}
export function editPageUrl() {
    return siteSettings.deliveryApplicationEditPage;
}
export function favoritePageUrl() {
    return siteSettings.favoriteOverviewPage;
}
export function recipeOverviewPageUrl() {
    return siteSettings.recipeOverviewPage;
}
export function recipeMenuOverviewPageUrl() {
    return siteSettings.recipeMenuOverviewPage;
}
export function customProductsPageUrl() {
    return siteSettings.customProductsPage;
}
export function mendelEditUserPageUrl() {
    return siteSettings.deliveryApplicationEditPage;
}
export function mendelSettingsPageUrl() {
    return siteSettings.deliveryApplicationSettingsPage;
}
export function mendelLoginPageUrl() {
    return siteSettings.deliveryApplicationLoginPage;
}
export function mendelDeliveryPageUrl() {
    return siteSettings.deliveryApplicationDeliveryPage;
}
export function mendelDeliveryHistoryPageUrl() {
    return siteSettings.deliveryApplicationDeliveryHistoryPage;
}
export function mendelDeliveryDetailsPageUrl() {
    return siteSettings.deliveryApplicationDeliveryDetailsPage;
}
export function mendelOrderDetailsPageUrl() {
    return siteSettings.deliveryApplicationOrderDetailsPage;
}
export function invoiceOverviewPageUrl() {
    return siteSettings.invoiceOverviewPage;
}
export function deliveryPageUrl() {
    return siteSettings.deliveryOverviewPage;
}
