var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { getProductImage, makeCancelablePromise } from '../action-creators';
import ProductImage from 'Shared/ProductComponents/ProductImage';
var DeliveryImage = /** @class */ (function (_super) {
    __extends(DeliveryImage, _super);
    function DeliveryImage(props) {
        var _this = _super.call(this, props) || this;
        _this.loadImage = makeCancelablePromise(_this.props.getProductImage(_this.props.articleNumber).then(function (result) {
            if (!!result) {
                return result;
            }
            else {
                return;
            }
        }).catch(function (error) { _this.loadImage.cancel(); }));
        _this.state = {
            imageUrl: sessionStorage.getItem(_this.props.articleNumber),
        };
        return _this;
    }
    DeliveryImage.prototype.componentDidMount = function () {
        var _this = this;
        this.loadImage.promise.then(function (result) {
            _this.setImage(result);
        }).catch(function (error) { _this.loadImage.cancel(); });
    };
    DeliveryImage.prototype.componentWillUnmount = function () {
        this.loadImage.cancel();
    };
    DeliveryImage.prototype.setImage = function (imageUrl) {
        !!imageUrl && this.setState({ imageUrl: imageUrl });
        !!imageUrl && sessionStorage.setItem(this.props.articleNumber, imageUrl);
    };
    DeliveryImage.prototype.render = function () {
        return (React.createElement(ProductImage, { imageUrl: this.state.imageUrl, displayName: this.props.displayName, size: 'tiny' }));
    };
    return DeliveryImage;
}(React.Component));
;
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    getProductImage: function (articleNumber) {
        return dispatch(getProductImage(articleNumber));
    }
}); })(DeliveryImage);
