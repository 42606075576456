var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './../CustomerArrangementBlock/base.scss';
import classnames from 'classnames';
var MyDeliveryTimesBlock = /** @class */ (function (_super) {
    __extends(MyDeliveryTimesBlock, _super);
    function MyDeliveryTimesBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyDeliveryTimesBlock.prototype.render = function () {
        return (React.createElement("div", { className: classnames(styles.overviewBox) },
            React.createElement("div", null,
                React.createElement("div", { className: styles.general },
                    React.createElement("div", { className: styles.columnInformation },
                        React.createElement("h2", { className: styles.header }, this.props.header),
                        React.createElement("div", { className: styles.bodyText }, this.props.body)),
                    React.createElement("div", { className: styles.labelWrapper }, (!!this.props.deliveryTimes && !!this.props.deliveryTimes.some(function (x) { return !!x; })) ? (this.props.deliveryTimes.map(function (data) { return (React.createElement("span", { key: data, className: classnames(styles.label, styles.gray) }, data)); })) : (React.createElement("span", { key: "noTimeSlots", className: classnames(styles.label, styles.lightgray) }, this.props.textForNoDeliverySlots)))))));
    };
    ;
    return MyDeliveryTimesBlock;
}(React.Component));
export default MyDeliveryTimesBlock;
