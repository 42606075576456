import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import classnames from 'classnames';
var TertiaryMenuColumn = function (_a) {
    var items = _a.items, className = _a.className;
    return (React.createElement("div", { className: classnames(styles.menuItemsList, className) },
        React.createElement("ul", null, items.map(function (item) { return (React.createElement("li", { key: item.url, className: styles.menuSection },
            React.createElement("a", { href: item.url }, item.displayName),
            item.children &&
                item.children.slice(0, 2).map(function (child) { return (React.createElement("a", { key: child.url, href: child.url }, child.displayName)); }),
            item.children && item.children.length > 2 && (React.createElement("a", { className: styles.showMore, href: item.url }, translate('/ProductListing/ShowMore'))))); }))));
};
export default TertiaryMenuColumn;
