var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from "react";
import PropertyFor from "Shared/PropertyFor";
import * as styles from './base.scss';
import MyMenigoIcon from "../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon";
import Icon from "Shared/Icon";
import { translate } from "Shared/translate";
var NewFeaturesInfoBlock = function (props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var toggleIsOpen = function () {
        setIsOpen(!isOpen);
    };
    return (!!props.showInfoBlock &&
        React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.infoHeader },
                React.createElement("span", { className: styles.infoIcon },
                    React.createElement(MyMenigoIcon, { name: "info" })),
                React.createElement(PropertyFor, { className: styles.infoHeaderText, tagName: "h4", property: props.currentBlock.header })),
            React.createElement("div", null,
                React.createElement(PropertyFor, { property: props.currentBlock.preamble }),
                !isOpen ? (React.createElement("div", { onClick: toggleIsOpen, className: styles.accordionHeader },
                    React.createElement("h3", null, translate('/Product/ProductClassification/ShowMore')),
                    React.createElement(Icon, { className: styles.plusMinusIcon, name: "plus" }))) : (React.createElement("div", { onClick: toggleIsOpen, className: styles.accordionHeader },
                    React.createElement("h3", null, translate('/Product/ProductClassification/ShowLess')),
                    React.createElement(Icon, { className: styles.plusMinusIcon, name: "minus" }))),
                isOpen &&
                    React.createElement(React.Fragment, null,
                        React.createElement(PropertyFor, { tagName: "div", property: props.currentBlock.mainBody })))));
};
export default NewFeaturesInfoBlock;
