var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import PropertyFor from 'Shared/PropertyFor';
import PropertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var LinkBannerBlock = /** @class */ (function (_super) {
    __extends(LinkBannerBlock, _super);
    function LinkBannerBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LinkBannerBlock.prototype.render = function () {
        var linkButtonStyle = this.props.targetLink.linkButtonStyle;
        var bgImageUrl = PropertyValueFor(this.props.block.backgroundImage) + '&quality=70';
        return (React.createElement("div", { className: classnames(styles.base, styles[PropertyValueFor(this.props.block.backgroundStyle)]), style: PropertyValueFor(this.props.block.backgroundImage) && { backgroundImage: 'url(' + bgImageUrl + ')' } },
            React.createElement("div", { className: styles.align },
                React.createElement(PropertyFor, { property: this.props.block.header, tagName: "h2", className: classnames(styles[PropertyValueFor(this.props.block.textColor)]) }),
                React.createElement(PropertyFor, { property: this.props.block.body, className: classnames(styles.preamble, styles[PropertyValueFor(this.props.block.textColor)]) }),
                React.createElement(Button, { appearance: ['large', linkButtonStyle], href: this.props.targetLink.url, target: this.props.targetLink.openInNewWindow ? '_blank' : '' }, this.props.targetLink.text))));
    };
    return LinkBannerBlock;
}(React.Component));
export default LinkBannerBlock;
