var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import * as styles from './printOptions.scss';
import PrintActions from './PrintActions';
import { DropDownListType } from '.';
var printOptions = {};
var defaultName = 'default';
var PrintOptions = /** @class */ (function (_super) {
    __extends(PrintOptions, _super);
    function PrintOptions(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            printOption: '',
            printOptionTitle: translate('/Account/ProductList/PrintOptions/DropDown/Heading'),
        };
        return _this;
    }
    PrintOptions.prototype.componentWillMount = function () {
        printOptions['item'] = translate('/Account/ProductList/PrintOptions/DropDown/Item');
        printOptions['area'] = translate('/Account/ProductList/PrintOptions/DropDown/Area');
        printOptions['pantry'] = translate('/Account/ProductList/PrintOptions/DropDown/Pantry');
        printOptions[defaultName] = this.state.printOptionTitle;
    };
    PrintOptions.prototype.changeSelect = function (option) {
        this.props.handleDropDownSelect(DropDownListType.Print);
        if (this.state.printOption == option) {
            this.changeSelect(defaultName);
            if (this.props.active)
                return;
        }
        this.setState({
            printOption: option,
            printOptionTitle: printOptions[option],
        });
    };
    PrintOptions.prototype.hasChoice = function () {
        return !!this.state.printOption && this.state.printOption != defaultName && this.props.active;
    };
    PrintOptions.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.printOptions },
            React.createElement("div", { className: styles.printOptionsInner },
                React.createElement("div", { className: styles.selectWrapper },
                    React.createElement("div", { className: styles.dropdownWrap },
                        React.createElement(DropDownMenu, { overrideMobile: true, title: this.props.active ? this.state.printOptionTitle : printOptions[defaultName], className: styles.dropdownPrint, isActive: this.hasChoice() },
                            React.createElement(DropDownItem, { key: "PrintOptionsDropDownItem", value: "item", text: translate('/Account/ProductList/PrintOptions/DropDown/Item'), onClick: function (e) { return _this.changeSelect('item'); } }),
                            React.createElement(DropDownItem, { key: "PrintOptionsDropDownArea", value: "area", text: translate('/Account/ProductList/PrintOptions/DropDown/Area'), onClick: function (e) { return _this.changeSelect('area'); } }),
                            React.createElement(DropDownItem, { key: "PrintOptionsDropDownPantry", value: "pantry", text: translate('/Account/ProductList/PrintOptions/DropDown/Pantry'), onClick: function (e) { return _this.changeSelect('pantry'); } }))),
                    React.createElement(PrintActions, { reportType: this.state.printOption, visible: this.hasChoice() })))));
    };
    return PrintOptions;
}(React.Component));
export default PrintOptions;
