import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
export default (function (props) {
    return (props.targetPageUrl && (React.createElement("div", { className: styles.base },
        React.createElement("div", null,
            React.createElement("div", { className: styles.h3 }, propertyValueFor(props.block.title)),
            React.createElement("p", { className: styles.paragraph }, propertyValueFor(props.block.subTitle))),
        React.createElement(Button, { href: props.targetPageUrl, appearance: "toolbarbutton" }, translate("/Shared/ReadMore")))));
});
