var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import connect from 'Shared/connect';
import Radio from 'Shared/Fields/Radio';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var FacetAndOrFilter = /** @class */ (function (_super) {
    __extends(FacetAndOrFilter, _super);
    function FacetAndOrFilter(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasAndFilter: _this.props.hasAndFilter };
        return _this;
    }
    FacetAndOrFilter.prototype.onAndOrFilterChange = function (andOrFilter) {
        var hasAndFilter = andOrFilter === 'and';
        this.setState({ hasAndFilter: hasAndFilter });
        this.props.onAndOrFilterChange(andOrFilter);
    };
    FacetAndOrFilter.prototype.render = function () {
        var _this = this;
        var _a = this.props, fieldName = _a.fieldName, hasAndFilter = _a.hasAndFilter, pageIsLoading = _a.pageIsLoading;
        var hasAndFilterOptimistic = pageIsLoading ? this.state.hasAndFilter : hasAndFilter;
        return (React.createElement("div", { className: styles.andOrConditionToggles },
            React.createElement("div", { className: "ui-test-facet-value" },
                React.createElement(Radio, { className: styles.facetValue, name: "facet-and-or-filter-" + fieldName, checked: hasAndFilterOptimistic === false, onChange: function () { return _this.onAndOrFilterChange('or'); }, value: "or" },
                    React.createElement("span", { className: styles.andOrFilterText }, translate('/Facets/OrFilter')))),
            React.createElement("div", { className: "ui-test-facet-value" },
                React.createElement(Radio, { className: styles.facetValue, name: "facet-and-or-filter-" + fieldName, checked: hasAndFilterOptimistic === true, onChange: function () { return _this.onAndOrFilterChange('and'); }, value: "and" },
                    React.createElement("span", { className: styles.andOrFilterText }, translate('/Facets/AndFilter'))))));
    };
    return FacetAndOrFilter;
}(Component));
export default connect(function (state) { return ({
    pageIsLoading: state.currentPage.isLoading,
}); })(FacetAndOrFilter);
