var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import * as styles from './base.scss';
import { on } from 'SiteLayout/Root';
import DropDownItem from './DropDownItem';
import DropDownSeparator from './DropDownSeparator';
import DropDownWrapper from './DropDownWrapper';
import ViewTracker from 'Shared/ViewObserver/ViewTracker';
export { DropDownItem, DropDownSeparator, DropDownWrapper };
export var Apperance;
(function (Apperance) {
    Apperance[Apperance["Normal"] = 0] = "Normal";
    Apperance[Apperance["Big"] = 1] = "Big";
})(Apperance || (Apperance = {}));
;
var validChildren = [DropDownItem, DropDownSeparator, DropDownWrapper];
var DropDownMenu = /** @class */ (function (_super) {
    __extends(DropDownMenu, _super);
    function DropDownMenu(props) {
        var _this = _super.call(this, props) || this;
        _this.getListHeight = function (children) {
            var childCount = _this.props.childCount || (!!children ? React.Children.count(children) : 0);
            return !!children ? Math.max(140, Math.min(childCount * 35, 350)) : 0;
        };
        _this.hideOptions = function () {
            !!_this.props.onClose && _this.props.onClose();
            _this.setState({ hideOptions: true });
            _this.timer = setTimeout(function () {
                _this.setState({ isOpen: false, hideOptions: false });
            }, 300);
        };
        _this.onClose = function (onCloseFn) { return function (e) {
            if (onCloseFn) {
                _this.hideOptions();
                return onCloseFn();
            }
            else {
                _this.hideOptions();
            }
        }; };
        _this.toggleOpen = function () {
            if (!_this.state.isOpen) {
                !!_this.props.onOpen && _this.props.onOpen();
                _this.setState({ isOpen: true });
            }
            else {
                _this.hideOptions();
            }
        };
        _this.close = function (childClick) { return function (e) {
            if (e) {
                e.stopPropagation();
            }
            var maybePromise = childClick && childClick(e);
            if (maybePromise instanceof Promise) {
                return maybePromise;
            }
            else {
                _this.setState({ isOpen: false });
            }
        }; };
        _this.state = { isOpen: false, hideOptions: false, listHeight: 350 };
        return _this;
    }
    DropDownMenu.prototype.componentDidMount = function () {
        var _this = this;
        var listHeight = this.getListHeight(this.props.children);
        this.setState({ listHeight: listHeight });
        this.unbindRootClick = on('click', function () {
            if (_this.state.isOpen) {
                _this.hideOptions();
            }
        });
    };
    DropDownMenu.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.children !== nextProps.children) {
            var listHeight = this.getListHeight(nextProps.children);
            this.setState({ listHeight: listHeight });
        }
    };
    DropDownMenu.prototype.componentWillUnmount = function () {
        this.unbindRootClick && this.unbindRootClick();
        clearTimeout(this.timer);
    };
    DropDownMenu.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        var _e = this.props, title = _e.title, children = _e.children, className = _e.className, optionsClassName = _e.optionsClassName, appearance = _e.appearance;
        return (React.createElement(ViewTracker, { onHide: function (visible) { return _this.hideOptions(); }, once: false },
            React.createElement("div", { className: classnames(styles.base, appearance == Apperance.Big ? styles.base__big : styles.base__normal, className) },
                React.createElement("div", { className: classnames(styles.innerWrapper, (_a = {}, _a[styles.open] = this.state.isOpen, _a), (_b = {}, _b[styles.innerWrapper__invalid] = this.props.isInvalid && !this.state.isOpen, _b)) },
                    React.createElement("div", { className: classnames(styles.control, (_c = {}, _c[styles.open] = this.state.isOpen, _c)), onClick: this.toggleOpen },
                        React.createElement("span", null, title),
                        React.createElement("span", { className: styles.arrow },
                            React.createElement(Icon, { appearance: "sans", name: "arrow-down" }))),
                    this.state.isOpen && (React.createElement("div", { className: classnames((_d = {}, _d[styles.hideOptions] = this.state.hideOptions, _d), styles.options, optionsClassName), style: { '--listHeight': this.state.listHeight + "px" } }, React.Children.map(children, function (c) {
                        if (c === null) {
                            return;
                        }
                        if (c.type === DropDownItem) {
                            return React.cloneElement(c, {
                                onClick: _this.close(c.props.onClick),
                                onClose: _this.onClose(c.props.onClose),
                            });
                        }
                        if (validChildren.find(function (v) { return c.type === v; })) {
                            return React.cloneElement(c, { onClose: _this.onClose(c.props.onClose) });
                        }
                    })))))));
    };
    return DropDownMenu;
}(React.Component));
export default DropDownMenu;
