import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
var WidgetRow = function (_a) {
    var deliveryDate = _a.deliveryDate, totalPrice = _a.totalPrice, noOfOrder = _a.noOfOrder, widgetType = _a.widgetType;
    return (React.createElement("div", { className: styles.card },
        React.createElement("div", { className: styles.card__inner },
            React.createElement("div", { className: styles.card__inner__column },
                React.createElement("span", { className: styles.card__inner__column__header }, translate('/MyMenigo/Overview/NumberOfOrders')),
                React.createElement("span", { className: styles.card__inner__column__content }, noOfOrder)),
            React.createElement("div", { className: styles.card__inner__column },
                React.createElement("span", { className: styles.card__inner__column__header }, translate('/MyMenigo/Overview/Delivery')),
                React.createElement("span", { className: styles.card__inner__column__content }, deliveryDate)),
            React.createElement("div", { className: styles.card__inner__column },
                React.createElement("span", { className: styles.card__inner__column__header }, translate('/MyMenigo/Overview/OrderValue')),
                React.createElement("span", { className: styles.card__inner__column__content }, totalPrice)))));
};
export default WidgetRow;
