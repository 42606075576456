var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { postJson } from 'Shared/server';
import * as styles from './base.scss';
import { Input, Textarea } from 'Shared/Fields';
var SendTenderRequest = /** @class */ (function (_super) {
    __extends(SendTenderRequest, _super);
    function SendTenderRequest(props) {
        var _this = _super.call(this, props) || this;
        _this.showModal = function () {
            _this.setState({ isOpen: true });
        };
        _this.hideModal = function () {
            _this.setState({ isOpen: false });
        };
        _this.onConfirm = function () {
            var query = {
                articleId: _this.props.tenderProductArticleNumber,
                productName: _this.props.tenderProductName,
                email: _this.state.inputEmail,
                phoneNumber: _this.state.inputPhone,
                message: _this.state.inputMessage,
                customerName: _this.props.currentUser.fullName,
                customerId: _this.props.currentUser.customerId,
            };
            return postJson('/Tender/Send', query)
                .then(function (r) {
                if (r.result === 'success') {
                    _this.props.setFeedback({ text: translate('/Tender/RequestSent'), type: 'success' });
                    _this.setState({ inputEmail: '', inputPhone: '' });
                    return Promise.resolve();
                }
                return Promise.reject(null);
            })
                .catch(function (e) {
                _this.props.setFeedback({ text: translate('/Tender/RequestError'), type: 'error' });
                return Promise.reject(null);
            });
        };
        _this.validateEmail = function (email) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            _this.setState({ validEmail: mailformat.test(email) });
            var model = {
                success: !!!email || !!mailformat.test(email),
                message: translate('/MyMenigo/CustomerProfileBlock/InvalidEmail'),
                value: email
            };
            return Promise.resolve(model);
        };
        _this.validatePhone = function (phone) {
            var re = phone.length > 3;
            _this.setState({ validPhone: re });
            var model = {
                success: !!!phone || !!re,
                message: translate('/MyMenigo/CustomerProfileBlock/InvalidFormatPhoneNumber'),
                value: phone
            };
            return Promise.resolve(model);
        };
        _this.state = {
            isOpen: false,
            inputEmail: '',
            inputPhone: '',
            inputMessage: '',
            validEmail: false,
            validPhone: false,
        };
        return _this;
    }
    SendTenderRequest.prototype.hasValidInput = function () {
        return !!this.state.inputEmail.length && !!this.state.inputPhone.length && this.state.validEmail;
    };
    SendTenderRequest.prototype.renderModal = function () {
        var _this = this;
        return (React.createElement(Modal, { className: styles.broad, title: translate('/Tender/SendTenderToMe'), isOpen: this.state.isOpen, onClose: this.hideModal, reserveSpaceForCloseButton: true, fadeDesign: true },
            React.createElement("div", { className: styles.tenderProductDescriptionWrapper },
                React.createElement("h3", null, this.props.tenderProductName),
                React.createElement("p", { className: styles.artnummer },
                    translate('/ProductListing/ArticleNumber'),
                    " ",
                    this.props.tenderProductArticleNumber),
                React.createElement("p", null, translate('/Tender/PopupSubHeader'))),
            React.createElement("div", { className: styles.tenderInputWrapper__Top },
                React.createElement("div", null,
                    React.createElement(Input, { label: translate('/Tender/Email'), type: "email", name: "email", id: "email", value: this.state.inputEmail, onValidate: function () { return _this.validateEmail(_this.state.inputEmail); }, onChange: function (e) {
                            _this.setState({ inputEmail: e.currentTarget.value });
                        } })),
                React.createElement("div", null,
                    React.createElement(Input, { label: translate('/Tender/Phone'), type: "tel", name: "phone", id: "phone", value: this.state.inputPhone, onValidate: function () { return _this.validatePhone(_this.state.inputPhone); }, onChange: function (e) {
                            _this.setState({ inputPhone: e.currentTarget.value });
                        } }))),
            React.createElement("div", { className: styles.wrapper },
                React.createElement("label", { htmlFor: "msg" }, translate('/Tender/Message')),
                React.createElement("p", null, translate('/Tender/PopupSubText')),
                React.createElement(Textarea, { id: "msg", name: "msg", rows: 4, onChange: function (e) { return _this.setState({ inputMessage: e.currentTarget.value }); } })),
            React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.onConfirm, onCancel: this.hideModal, onStatusWasFulfilled: this.hideModal, disabled: !this.hasValidInput(), customAcceptText: translate('/Shared/Send') })));
    };
    SendTenderRequest.prototype.render = function () {
        return (React.createElement("div", { className: styles.btnWrap },
            React.createElement(Button, { className: styles.btn, appearance: "small", onClick: this.showModal }, translate('/Tender/SendTender')),
            this.renderModal()));
    };
    return SendTenderRequest;
}(React.PureComponent));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    currentUser: state.currentUser,
}); }, function (dispatch) { return ({
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
}); })(SendTenderRequest);
