var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import Price from 'Shared/Price';
var ProductListItemPrice = function (props) {
    if (!props.isLoggedIn || props.isSupplierSalesman || !props.selectedVariant || !props.selectedVariant.price) {
        return null;
    }
    return (React.createElement(Price, __assign({ size: "small" }, props.price, { comparison: props.price.comparison, comparisonPriceUnit: props.price.comparisonPriceUnit, current: props.price.current, noPriceClassName: styles.noPrice })));
};
export default ProductListItemPrice;
