var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { updateCartItemQuantity, addEmvItemToCart, removeCartItem, updateCartDeliveryDate, toggleIgnoreAvailability, toggleProductSample, getProductDetails, } from './../action-creators';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
import { quantityIncludingSubdeliveries } from '../../Cart/reducer';
import { updateAppShellData } from '../../AppShell/action-creators';
import Header from './ProductList/Header';
import * as styles from './base.scss';
import EmvProductCard from './ProductList/EmvProduct';
import { MEDIUM } from 'Shared/breakpoints';
import { addToDataLayer } from '../../TrackingInformation';
import ProductCardItem from 'Shared/ProductCard/ProductRow2.0/ProductCardItem';
import ProductCardContainer from 'Shared/ProductComponents/ProductCardContainer';
import classnames from 'classnames';
var FullCart = /** @class */ (function (_super) {
    __extends(FullCart, _super);
    function FullCart(props) {
        var _this = _super.call(this, props) || this;
        _this.isItemPending = function (item) {
            if (!_this.props.pendingChanges) {
                return false;
            }
            return _this.props.pendingChanges.some(function (change) { return !!change.cart.items[item.lineItem.code]; });
        };
        _this.isEmvProductVisible = function () {
            if (!_this.props.cart && !_this.props.cart.items && _this.props.cart.items.length < 1) {
                return false;
            }
            return !!_this.props.cart.items.some(function (x) { return !!x.lineItem.emvProduct; });
        };
        return _this;
    }
    FullCart.prototype.componentDidMount = function () {
        this.props.reloadAppShell();
        if (this.isEmvProductVisible()) {
            this.trackingEmvImpression();
        }
    };
    FullCart.prototype.getFilteredAndSortedCartItems = function () {
        return this.props.cart.items.filter(function (i) { return quantityIncludingSubdeliveries(i.lineItem) > 0; });
    };
    FullCart.prototype.trackingEmvImpression = function () {
        var action = {
            event: 'EMV switch',
            eventInfo: {
                category: 'EMV switch',
                action: 'Impression',
                label: "Kassa"
            }
        };
        addToDataLayer(action);
    };
    FullCart.prototype.render = function () {
        var _this = this;
        var cartItems = this.getFilteredAndSortedCartItems();
        if (!this.props.cart && !this.props.cart.items && this.props.cart.items.length < 1) {
            return React.createElement("div", null, translate('/Cart/EmptyCart'));
        }
        var selectableProductIndexes = cartItems
            .map(function (product, index) { return (product.product.isDiscontinued ? -1 : index); })
            .filter(function (i) { return i !== -1; });
        var isMobile = this.props.currentBreakpoint < MEDIUM;
        return (React.createElement("div", null,
            React.createElement(Header, { isSupplierSalesman: this.props.isSupplierSalesman, hasEmv: this.props.cart.hasEmvProducts || this.isEmvProductVisible() }),
            React.createElement("div", { className: classnames(styles.items, styles.main) },
                React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
                    React.createElement("div", { className: classnames(styles.grid, styles.isCheckout), "data-list-type": 'viewLayout_List' }, cartItems.map(function (item, i) { return (React.createElement("div", { key: item.lineItem.code, className: styles.rowWrapper },
                        React.createElement(ProductCardContainer, { product: item.product, index: i }, function (_a) {
                            var quantityInCart = _a.quantityInCart, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                            return (React.createElement(ProductCardItem, { product: item.product, quantityInCart: quantityInCart, selectedVariant: item.lineItem.selectedVariant, updateCartQuantity: updateCartQuantity, updateCartQuantityExt: function (quantity, selectedVariant) {
                                    return _this.props.updateCartItemQuantity(selectedVariant.code, quantity, null, selectedVariant.unitShortName, _this.props.hasAddedEmvInCart);
                                }, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: i, url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, cardType: 'viewLayout_List', removeCheckoutItem: _this.props.removeCartItem, totalPrice: item.lineItem.totalPrice, isCheckout: true, item: item, subDeliveries: item.lineItem.subDeliveries, updateCartDeliveryDate: _this.props.updateCartDeliveryDate, isProductSample: item.lineItem.isProductSample, toggleProductSample: _this.props.toggleProductSample, discountType: item.lineItem.discountType, discountValue: item.lineItem.discountValue, validDiscounts: item.lineItem.validDiscounts, isMobile: !!item.lineItem.emvProduct, updateCartItemSubdeliveryQuantity: _this.props.updateCartItemQuantity, removeCartItemSubdelivery: _this.props.removeCartItem, isItemPendingSubDelivery: _this.isItemPending, hasAddedEmvInCart: _this.props.hasAddedEmvInCart, openPreviewInModel: true }));
                        }),
                        !isMobile && _this.props.cart.hasEmvProducts && (React.createElement("div", { className: styles.emvColumnWrapper }, !!item.lineItem.emvProduct && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.emvProductArrow },
                                React.createElement(Icon, { name: "bold-arrow-right" })),
                            React.createElement("div", { className: styles.emvCardWrapper },
                                React.createElement(EmvProductCard, { product: item.lineItem.emvProduct, addEmvItemToCart: function (code, unitShortName, quantity) {
                                        return _this.props.addEmvItemToCart(code, unitShortName, quantity).then(function () {
                                            _this.props.toggleHasAddedEmvInCart(true);
                                        });
                                    }, equivalentEmvProductQuantity: item.lineItem.equivalentEmvProductQuantity, getProductDetails: _this.props.getProductDetails, emvProductIsAddedToCart: item.emvProductIsAddedToCart })))))))); }))))));
    };
    return FullCart;
}(React.PureComponent));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoading: state.checkout.isLoading,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    pendingChanges: state.checkout.pendingChanges,
    userAllowedToIgnoreAvailability: state.currentUser.userAllowedToIgnoreAvailability,
}); }, function (dispatch) { return ({
    updateCartItemQuantity: function (code, quantity, date, unitShortName, hasAddedEmvInCart) {
        return dispatch(updateCartItemQuantity(code, quantity, date, unitShortName, 'CheckoutPage', null, hasAddedEmvInCart));
    },
    addEmvItemToCart: function (code, unitShortName, quantity) {
        return dispatch(addEmvItemToCart(code, unitShortName, 'EmvCardInCheckout', quantity));
    },
    getProductDetails: function (url) {
        return dispatch(getProductDetails(url));
    },
    toggleProductSample: function (code) { return dispatch(toggleProductSample(code)); },
    toggleIgnoreAvailability: function (code) { return dispatch(toggleIgnoreAvailability(code)); },
    removeCartItem: function (code, date, unitShortName) {
        return dispatch(removeCartItem(code, date, unitShortName, 'CheckoutPage'));
    },
    updateCartDeliveryDate: function (code, date) {
        return dispatch(updateCartDeliveryDate(code, date, 'CheckoutPage'));
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
}); })(FullCart);
