var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import * as styles from './base.scss';
import { Part } from 'Shared/PageLayout';
import Barcodescanner from '../../TakeStock/Barcodescanner';
import Icon from 'Shared/Icon';
import { searchItem, setSearchQuery, displaySearchList, clearSearchList, toggleCamera } from '../action-creators';
import { batchActions } from 'redux-batched-actions';
import { translate } from 'Shared/translate';
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
var Searchbar = /** @class */ (function (_super) {
    __extends(Searchbar, _super);
    function Searchbar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.debounceSearch = debounce(function () {
            _this.props.onSearch(_this.props.searchQuery, _this.props.endpoint);
        }, 1000, false);
        _this.showCamera = function () { return _this.toggleCamera(true); };
        _this.hideCamera = function () { return _this.toggleCamera(false); };
        _this.clearSearch = function () { return _this.props.clearSearchList(); };
        _this.handleDetected = function (code) {
            if (_this.props.searchQuery === code) {
                console.log('Searchbar: same code found');
                return;
            }
            if (code.length === 8 && code.startsWith('00')) {
                code = code.substring(2);
            }
            _this.handleQueryChange(code);
        };
        _this.onSearch = function (e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            _this.searchField.blur();
        };
        _this.displaySearchList = function () {
            _this.props.displaySearchList(_this.props.searchQuery.length > 0);
            _this.hideCamera();
        };
        return _this;
    }
    Searchbar.prototype.componentDidMount = function () {
        this.searchField.focus();
    };
    Searchbar.prototype.componentWillUnmount = function () {
        this.clearSearch();
        this.hideCamera();
    };
    Searchbar.prototype.handleChange = function (query) {
        this.handleQueryChange(query);
        this.hideCamera();
    };
    Searchbar.prototype.handleQueryChange = function (query) {
        this.props.setSearchQuery(query);
        if (query.length === 0) {
            this.props.displaySearchList(false);
            return;
        }
        this.props.displaySearchList(true);
        this.debounceSearch();
    };
    Searchbar.prototype.toggleCamera = function (show) {
        this.props.toggleCamera(show);
    };
    Searchbar.prototype.displayCamera = function () {
        this.toggleCamera(!this.props.showCamera);
        this.clearSearch();
        this.props.displaySearchList(false);
    };
    Searchbar.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.toolbar },
                React.createElement("div", { className: styles.searchbar },
                    React.createElement("form", { onSubmit: this.onSearch },
                        React.createElement("input", { type: "search", placeholder: translate('/App/SearchPlaceholderLong'), value: this.props.searchQuery, onChange: function (e) { return _this.handleChange(e.target.value); }, tabIndex: -1, autoFocus: false, onFocus: this.displaySearchList, onBlur: this.displaySearchList, ref: function (e) { return (_this.searchField = e); } })),
                    this.props.searchQuery.length > 0 && (React.createElement("button", { className: styles.clear, onClick: this.clearSearch },
                        React.createElement(Icon, { appearance: "sans", name: "close" }))),
                    React.createElement("button", { onClick: function () { return _this.displayCamera(); } },
                        React.createElement(Icon, { appearance: "sans", name: "camera" })))),
            React.createElement(Part, { appearance: "divider" }, this.props.showCamera && !this.props.searchResults && React.createElement(Barcodescanner, { onDetected: this.handleDetected }))));
    };
    return Searchbar;
}(React.Component));
export default connect(function (state) { return ({
    searchQuery: state.app.searchQuery,
    showCamera: state.app.showCamera,
    searchResults: state.app.searchResults,
}); }, function (dispatch) { return ({
    setSearchQuery: function (query) {
        dispatch(setSearchQuery(query));
    },
    onSearch: function (query, endpoint) {
        dispatch(searchItem(query, endpoint));
    },
    clearSearchList: function () {
        dispatch(batchActions([displaySearchList(false), clearSearchList(), setSearchQuery('')]));
    },
    displaySearchList: function (show) {
        dispatch(displaySearchList(show));
    },
    toggleCamera: function (showCamera) {
        if (showCamera) {
            dispatch(clearSearchList());
        }
        dispatch(toggleCamera(showCamera));
    },
}); })(Searchbar);
