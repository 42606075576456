export var ENTER = 13;
export var DOWN = 40;
export var UP = 38;
export var ESC = 27;
export var TAB = 9;
export var PLUS = 107;
export var PLUS_DESKTOP = 187;
export var MINUS = 109;
export var MINUS_DESKTOP = 189;
export var DIVIDE = 55;
export var DIVIDE_DESKTOP = 111;
