import React from 'react';
import { Checkbox } from 'Shared/Fields';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import * as styles from '../OrderHeader/details.scss';
import ProductMarks from '../OrderProduct/OrderProductMark';
export var Desktop = function (_a) {
    var currentTab = _a.currentTab, product = _a.product, selectedItems = _a.selectedItems, currentGrouping = _a.currentGrouping, isSelectable = _a.isSelectable, selectedVariant = _a.selectedVariant, index = _a.index, addToSelectedItems = _a.addToSelectedItems, unit = _a.unit;
    return (React.createElement("div", { key: index, className: styles.rowDetails },
        isSelectable && !!(currentTab === 'historyOrders') && (React.createElement("div", { className: styles.checkBox },
            React.createElement(Checkbox, { checked: selectedItems.has(product.articleNumber), onChange: function (e) { return addToSelectedItems(e); } }))),
        React.createElement("div", { className: styles.mediaDetail },
            React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.name, size: "tiny" })),
        !!(currentTab === 'currentOrders' && currentGrouping !== 'PerOrder') && (React.createElement("div", { className: styles.orderNumberDetails }, product.orderNumber)),
        !!(currentTab === 'currentOrders' && currentGrouping === 'ViewAll') && (React.createElement("div", { className: styles.deliveryDateDetails }, product.deliveryDate)),
        React.createElement("div", { className: styles.descriptionDetails },
            React.createElement(ProductTitle, { linkUrl: '', ticket: '', displayName: product.name }),
            React.createElement(ProductDetails, { code: product.articleNumber, linkUrl: '', ticket: '', trademark: '' }),
            product.information,
            React.createElement("br", null),
            React.createElement(ProductMarks, { IsUtf: product.isUtf })),
        React.createElement("div", { className: styles.unitDetails }, unit),
        React.createElement("div", { className: styles.quantityDetails }, product.quantity),
        React.createElement("div", { className: styles.priceDetails },
            React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: [selectedVariant], productIsInCampaign: false, isWeightProduct: false, showOriginal: true }))));
};
