var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var NewsSideBar = /** @class */ (function (_super) {
    __extends(NewsSideBar, _super);
    function NewsSideBar(props) {
        return _super.call(this, props) || this;
    }
    NewsSideBar.prototype.urlToString = function (key, value) {
        return this.props.sideBar.baseUrl + '?&key=' + key + '&value=' + value;
    };
    NewsSideBar.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.sideBar },
            React.createElement("h3", { className: styles.header }, translate('/Content/News/Sidebar/Types')),
            React.createElement("div", { className: styles.box }, this.props.sideBar.articleTypes.map(function (data, i) { return (React.createElement("div", { key: i },
                React.createElement("a", { href: _this.urlToString('type', data.value) }, data.text))); })),
            React.createElement("h3", { className: styles.header }, translate('/Content/News/Sidebar/Archive')),
            React.createElement("div", { className: styles.box }, this.props.sideBar.archive.map(function (data, i) { return (React.createElement("div", { key: i },
                React.createElement("a", { href: _this.urlToString('year', data) }, data))); }))));
    };
    return NewsSideBar;
}(React.Component));
export default NewsSideBar;
