var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import ServicePackageContent from './Components/ServicePackageContent/ServicePackageContent';
var ServicePackageDetailBlock = /** @class */ (function (_super) {
    __extends(ServicePackageDetailBlock, _super);
    function ServicePackageDetailBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ServicePackageDetailBlock.prototype.render = function () {
        var basePackageSelected = this.props.selectedPackage == 'basePackage';
        return (React.createElement("div", { className: styles.overviewBox },
            React.createElement("div", { className: styles.bodyText }, this.props.preamble),
            React.createElement("div", { className: styles.content },
                React.createElement(ServicePackageContent, { header: this.props.basePackage.header, description: this.props.basePackage.description, price: this.props.basePackage.price, priceExplanation: this.props.basePackage.priceExplanation, contentParts: this.props.basePackage.contentParts, selected: basePackageSelected }),
                React.createElement(ServicePackageContent, { header: this.props.plusPackage.header, description: this.props.plusPackage.description, price: this.props.plusPackage.price, priceExplanation: this.props.plusPackage.priceExplanation, contentParts: this.props.plusPackage.contentParts, selected: !basePackageSelected }))));
    };
    return ServicePackageDetailBlock;
}(React.Component));
export default ServicePackageDetailBlock;
