var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main, { Part } from 'Shared/PageLayout';
import Button from 'Shared/Button';
import PropertyFor from 'Shared/PropertyFor';
import { translate } from 'Shared/translate';
import { addToImbox } from './../../../TrackingInformation';
import OrderDetails from './OrderDetails';
import Price from 'Shared/Price';
import * as styles from './base.scss';
import { returnOrderPageUrl } from 'Shared/known-urls';
var OrderConfirmationPage = /** @class */ (function (_super) {
    __extends(OrderConfirmationPage, _super);
    function OrderConfirmationPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrderConfirmationPage.prototype.render = function () {
        var _this = this;
        if (!window.IS_IN_EDIT_MODE) {
            try {
                var orderNumber_1 = this.props.models[0].orderNumber;
                this.props.models[0].orderLineItems.map(function (x, i) {
                    addToImbox([
                        'insertTrans',
                        orderNumber_1,
                        '/order-confirmation-page',
                        x.price,
                        x.productName,
                        x.productName,
                        x.itemCode,
                    ]);
                });
            }
            catch (error) {
                window.newrelic && window.newrelic.noticeError(error);
                console.error(error);
            }
        }
        return (React.createElement(Main, { appearance: "narrow" },
            !IS_APP && (React.createElement("a", { href: returnOrderPageUrl(), className: styles.returnOrderLink }, translate('/OrderConfirmation/ReturnOrderAppeal'))),
            React.createElement("h1", null,
                React.createElement(PropertyFor, { property: this.props.page.name, tagName: "span" })),
            React.createElement(Part, { className: styles.container },
                this.props.models.map(function (model, i) {
                    return (React.createElement("div", { className: styles.orderWrapperRow, key: i },
                        React.createElement("h3", null,
                            translate('/Shared/Delivery'),
                            " ",
                            i + 1),
                        React.createElement(OrderDetails, { model: model, page: _this.props.page })));
                }),
                !this.props.models[0].isSupplierSalesmanOrder && (React.createElement("div", { className: styles.subTotal },
                    React.createElement("p", null,
                        translate('/MiniCart/ToPay'),
                        " "),
                    !window.IS_IN_EDIT_MODE && (React.createElement("h3", null,
                        React.createElement(Price, { current: this.props.models[0].totalPrice }))))),
                React.createElement(Part, { className: styles.buttons },
                    React.createElement(Button, { onClick: function () { return window.print(); }, appearance: 'tertiary' }, translate('/Shared/Print'))))));
    };
    return OrderConfirmationPage;
}(React.Component));
export default OrderConfirmationPage;
