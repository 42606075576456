var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import * as styles from './base.scss';
import Tooltip from 'Shared/Tooltip';
import * as Root from 'SiteLayout/Root';
import { favoritePageUrl } from 'Shared/known-urls';
import { pathCombine } from 'Shared/url';
import { replaceState } from 'Shared/history';
import addRemoveFromListStyles from 'Shared/AddRemoveFromList/base.scss';
var ProductFavoriteMark = /** @class */ (function (_super) {
    __extends(ProductFavoriteMark, _super);
    function ProductFavoriteMark(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleTooltip = function (e) {
            e && e.preventDefault();
            _this.setState({ showTooltip: !_this.state.showTooltip });
        };
        _this.state = {
            showTooltip: false,
        };
        return _this;
    }
    ProductFavoriteMark.prototype.componentDidMount = function () {
        var _this = this;
        this.unbindRootClick = Root.on('click', function () {
            if (_this.state.showTooltip) {
                _this.setState({
                    showTooltip: false,
                });
            }
        });
    };
    ProductFavoriteMark.prototype.componentWillUnmount = function () {
        this.unbindRootClick && this.unbindRootClick();
    };
    ProductFavoriteMark.prototype.goTo = function (id) {
        if (!!this.props.onClick) {
            this.props.onClick();
        }
        replaceState({ path: pathCombine(favoritePageUrl()), query: { open: id } });
    };
    ProductFavoriteMark.prototype.render = function () {
        var _this = this;
        if (!this.props.list) {
            return null;
        }
        return (React.createElement("div", { className: classnames(styles.wrapper, this.props.extraLarge && !this.props.showText && styles.extraLarge, this.props.showBoth && styles.both, this.props.className) },
            this.state.showTooltip && (React.createElement(Tooltip, { close: this.toggleTooltip, closeButton: true, closeOnClick: false, horizontal: !!this.props.horizontal ? this.props.horizontal : 'center', vertical: !!this.props.reverseFavToolbar ? 'bottom' : 'top' },
                React.createElement("div", { className: styles.tooltip },
                    React.createElement("strong", null, translate('/Product/FavoriteListHeading')),
                    React.createElement("ul", null, Object.keys(this.props.list).map(function (key) { return (React.createElement("li", { key: "Mark" + key },
                        React.createElement("a", { href: void 0, onClick: function () { return _this.goTo(key); } }, _this.props.list[key]))); }))))),
            React.createElement("div", { onClick: this.toggleTooltip, className: styles.icon },
                this.props.showBoth && (React.createElement("div", { className: addRemoveFromListStyles.listAdd },
                    React.createElement("div", { className: classnames(addRemoveFromListStyles.circle, addRemoveFromListStyles.activeIcon) },
                        React.createElement(Icon, { appearance: "sans", name: "heart-filled" })))),
                this.props.showText || this.props.showBoth ? (React.createElement("span", null, translate('/Product/FavoriteList'))) : (React.createElement("div", { className: addRemoveFromListStyles.listAdd },
                    React.createElement("div", { className: classnames(addRemoveFromListStyles.circle, addRemoveFromListStyles.activeIcon) },
                        React.createElement(Icon, { appearance: "sans", name: "heart-filled" })))))));
    };
    return ProductFavoriteMark;
}(React.Component));
export default ProductFavoriteMark;
