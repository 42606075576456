var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ListCustomProducts from './../../ListCustomProducts';
import * as styles from './base.scss';
import MyMenigoLayout from '../../../MyMenigo/Pages/MyMenigoLayout';
var CustomProductsPage = /** @class */ (function (_super) {
    __extends(CustomProductsPage, _super);
    function CustomProductsPage(props) {
        return _super.call(this, props) || this;
    }
    CustomProductsPage.prototype.render = function () {
        return (React.createElement(MyMenigoLayout, null,
            React.createElement("div", null,
                React.createElement("div", { className: styles.loadingWrapper },
                    React.createElement(ListCustomProducts, { items: this.props.listModel.items, suppliers: this.props.listModel.suppliers, areas: this.props.listModel.areas, units: this.props.listModel.units, pantryList: this.props.listModel.pantryList, recipeList: this.props.listModel.recipeList, firstLevels: this.props.listModel.firstLevels, isMaster: this.props.listModel.isMaster, totalNumberOfItems: this.props.listModel.totalNumberOfItems })))));
    };
    return CustomProductsPage;
}(React.PureComponent));
export default CustomProductsPage;
