import * as server from 'Shared/server';
export function SetDeliveryDate(date) {
    var url = '/deliverydate/set/';
    var body = {
        date: date
    };
    server.post(url, body, null).then(function () {
        return true;
    }).catch(function (e) {
        return false;
    });
}
