export default function shallowEquals(props1, props2, ignoreFunctions) {
    if (ignoreFunctions === void 0) { ignoreFunctions = false; }
    for (var key in props1) {
        if (props1.hasOwnProperty(key)) {
            if (typeof props1[key] === 'function' && typeof props2[key] === 'function' && ignoreFunctions) {
                continue;
            }
            if (props1[key] !== props2[key]) {
                return false;
            }
        }
    }
    return true;
}
