var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import Header from 'Shared/List/Table/Header';
import Actionbar from './Actionbar';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { removeCartItem } from './../../../Cart/action-creators';
import { removeRecommendationFromList, getRecommendationDetails, addRecommendations, addRecommendationsToNewList, toggleListSelection, } from './../../action-creators';
import { togglePageSpinner } from 'SiteLayout/CurrentPage/action-creators';
import * as styles from './base.scss';
import ActionbarRecommendations from 'Shared/Recommendations/Actionbar/index';
import connect from 'Shared/connect';
import ListBase, { ListType, } from 'Shared/List';
import { removeProductsFromList, updateQuantity, updateOrdinal, resetList, getDetails, } from 'Shared/ListItem/action-creators';
import { favoritePageUrl } from 'Shared/known-urls';
import { put } from 'Shared/server';
import ListItemHeader from 'Shared/ListItem/Header';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import { componentIsFavorites } from 'Shared/ListItem/current-page-is';
import RecommendationHeader from 'Shared/Recommendations/Table/Header';
var FavoriteList = /** @class */ (function (_super) {
    __extends(FavoriteList, _super);
    function FavoriteList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.save = function () {
            _this.props.togglePageSpinner(true);
            put(favoritePageUrl() + "/update", {
                listId: _this.props.id,
                products: _this.props.products.reduce(function (acc, item) {
                    var _a;
                    return Object.assign(acc, (_a = {},
                        _a[item.selectedVariant.code] = {
                            quantity: item.quantity,
                            ordinal: item.ordinal,
                            unitShortName: item.selectedVariant.unitShortName,
                        },
                        _a));
                }, {}),
            }).then(function () {
                _this.props.togglePageSpinner(true);
            }).then(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.props.getDetails(this.props.id, false)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }).then(function () {
                _this.props.togglePageSpinner(false);
                _this.props.setFeedback({ text: translate('/Account/ProductList/ChangesSaved'), type: 'success' });
            });
        };
        _this.onEnter = function (event) { };
        _this.resetList = function () {
            _this.setState({
                selectedItems: [],
                searchQuery: '',
                selectedCompletionIndex: -1,
            });
            _this.props.resetList(_this.props.id);
            return _this.props.products
                .filter(function (p) { return p.variants.some(function (v) { return _this.props.cart.items.some(function (cartItem) { return v.code === cartItem.lineItem.code; }); }); })
                .map(function (p) { return p.variants.find(function (v) { return _this.props.cart.items.some(function (cartItem) { return v.code === cartItem.lineItem.code; }); }); })
                .map(function (v) { return _this.props.removeCartItem(v.code); });
        };
        _this.updateQuantity = function (index, quantity) { };
        return _this;
    }
    FavoriteList.prototype.getListType = function () {
        return ListType.Favorite;
    };
    FavoriteList.prototype.resetSelectedFavoriteItems = function () {
        this.setState({
            selectedItems: [],
        });
    };
    FavoriteList.prototype.render = function () {
        var _this = this;
        var hasLoadedProducts = this.props.products && this.props.products.length > 0;
        var disableActionbar = this.state.selectedItems.length === 0;
        return (React.createElement("div", { className: styles.list },
            !this.props.isRecommendations ? (React.createElement(Header, __assign({}, this.props, { onHeaderClick: this.onHeaderClick, currentBreakpoint: this.props.currentBreakpoint, isOpen: this.state.isOpen, getDetails: function () { return _this.props.getDetails(_this.props.id, false); }, toggleListSelection: function () { return _this.props.toggleListSelection(_this.props.id, false); } }))) : (React.createElement(RecommendationHeader, { id: this.props.id, displayName: this.props.displayName, isLoaded: this.props.isLoaded, isLoading: this.props.isLoading, onHeaderClick: this.onHeaderClick, getRecommendationDetails: function () { return _this.props.getRecommendationDetails(_this.props.id); }, currentBreakpoint: this.props.currentBreakpoint, isOpen: this.state.isOpen })),
            this.state.isOpen && this.props.isLoaded && (React.createElement("div", { className: styles.listBorderWrap },
                this.props.isRecommendations ? (hasLoadedProducts && (React.createElement(ActionbarRecommendations, { currentPage: this.props.currentPage, listId: this.props.id, disabled: disableActionbar, selectedItems: this.state.selectedItems, invStatus: this.props.inventoryStatus, removeRecommendations: function () { return _this.props.removeRecommendations(_this.state.selectedItems); }, addRecommendations: function (toListId) {
                        return _this.props.addRecommendations(_this.props.id, toListId, _this.state.selectedItems);
                    }, addRecommendationsToNewList: function (displayName) {
                        return _this.props.addRecommendationsToNewList(_this.props.id, displayName, _this.state.selectedItems);
                    } }))) : (React.createElement(Actionbar, { listId: this.props.id, disabled: disableActionbar, selectedItems: this.state.selectedItems, resetList: this.resetList, searchQuery: this.state.searchQuery, updateSearchPhrase: this.updateSearchPhrase, isLocked: this.props.isLocked, isShared: this.props.isShared, isMaster: this.props.isMaster, isActive: this.props.isActive, isOpen: this.state.isOpen, sortBy: this.props.sortBy, save: this.save })),
                React.createElement(ListItemHeader, { listId: this.props.id, isRecommendations: this.props.isRecommendations, sortBy: this.props.sortBy, selected: this.state.allSelected, selectAll: this.selectAll, hasProducts: hasLoadedProducts }),
                hasLoadedProducts && this.getList(),
                React.createElement(Button, { className: styles.clearList, disabled: !this.state.selectedItems.length, onClick: function () {
                        _this.resetSelectedFavoriteItems();
                    }, appearance: 'secondary' }, translate('/RecipeListing/ClearSelected'))))));
    };
    return FavoriteList;
}(ListBase));
export default connect(function (state) { return ({
    cart: state.cart,
    currentBreakpoint: state.currentBreakpoint,
    isFavoriteList: componentIsFavorites(state.currentPage.componentName),
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    getRecommendationDetails: function (id) { return dispatch(getRecommendationDetails(id)); },
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
    removeRecommendations: function (items) { return dispatch(removeRecommendationFromList(items)); },
    removeProductFromList: function (listId, code) { return dispatch(removeProductsFromList(listId, [code])); },
    updateQuantity: function (listId, code, quantity) {
        return dispatch(updateQuantity(listId, code, quantity));
    },
    updateOrdinal: function (listId, code, ordinal) { return dispatch(updateOrdinal(listId, code, ordinal)); },
    togglePageSpinner: function (show) { return dispatch(togglePageSpinner(show)); },
    resetList: function (listId) { return dispatch(resetList(listId)); },
    removeCartItem: function (code) { return dispatch(removeCartItem(code, 'FavoriteList')); },
    addRecommendations: function (fromListId, toListId, codes) {
        return dispatch(addRecommendations(fromListId, toListId, codes));
    },
    addRecommendationsToNewList: function (listId, displayName, codes) {
        return dispatch(addRecommendationsToNewList(listId, displayName, codes));
    },
    getDetails: function (id, defaultDisabled) { return dispatch(getDetails(id, defaultDisabled)); },
    toggleListSelection: function (id, selected) { return dispatch(toggleListSelection(id, selected)); },
}); })(FavoriteList);
