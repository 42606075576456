import { postJson } from 'Shared/server';
import { pushState, replaceState } from 'Shared/history';
import { updateAppShellData } from './../AppShell/action-creators';
import { nuke } from 'SiteLayout/CurrentPage/cache';
import { updateUserNameOnImbox, login as gtmLogin, updateODPTrackingLogin } from '../TrackingInformation';
import { urlToString } from 'Shared/url';
export var SET_NEW_IMPERSONATION = 'SET_NEW_IMPERSONATION';
export function setNewImpersonation() {
    return function (dispatch, getState) {
        nuke();
        dispatch({
            type: SET_NEW_IMPERSONATION,
        });
    };
}
export function login(details) {
    return function (dispatch, getState) {
        var redirectUrl = urlToString(getState().accountBox.redirectToAfterLogin);
        return new Promise(function (resolve, reject) {
            postJson('/login', details)
                .then(function (result) {
                if (result.success) {
                    gtmLogin('Username/Password', details.customerId);
                    if (result.shouldBeRedirected) {
                        redirectUrl = result.redirectUrl;
                    }
                    return Promise.resolve(result);
                }
                return Promise.reject(result);
            })
                .then(function () { return nuke(); })
                .then(function () {
                dispatch(updateAppShellData())
                    .then(function () { return resolve(); })
                    .then(function () { return (!!redirectUrl ? pushState(redirectUrl) : replaceState()); });
            })
                .catch(reject);
        }).then(function () {
            var currentUser = getState().currentUser;
            updateUserNameOnImbox(currentUser);
            updateODPTrackingLogin(currentUser.customerId, currentUser.email);
        });
    };
}
export function logout() {
    return function (dispatch, getState) {
        var redirecUrl = '/';
        var logoutFrom = '';
        return postJson('/logout')
            .then(function (r) {
            if (r.logoutFrom === 'impersonationPage') {
                redirecUrl = r.redirectUrl;
                logoutFrom = r.logoutFrom;
            }
            if (r.logoutFrom === 'supplierPage') {
                redirecUrl = r.redirectUrl;
                logoutFrom = r.logoutFrom;
            }
            return Promise.resolve();
        })
            .then(function () { return pushState(redirecUrl); })
            .then(function () {
            if (logoutFrom === '' || logoutFrom === 'impersonationPage') {
                dispatch(setNewImpersonation());
                return dispatch(updateAppShellData());
            }
            else {
                nuke();
                return Promise.resolve();
            }
        })
            .then(function () {
            var currentUser = getState().currentUser;
            updateUserNameOnImbox(currentUser);
        });
    };
}
export function register(email, password) {
    return function (dispatch) {
        return postJson('/register', { email: email, password: password }).then(function (result) {
            return Promise.resolve(result);
        });
    };
}
export function resetPassword(customerId) {
    return function (dispatch) {
        return postJson('/ForgotPasswordPage/ResetPassword', { customerId: customerId }).then(function (result) {
            return Promise.resolve(result);
        });
    };
}
export function searchUsers(query) {
    return function (dispatch) {
        return postJson('/SearchContactApi/Search', query).then(function (resp) {
            return resp;
        });
    };
}
