import React from 'react';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import WidgetRow from './WidgetRow';
import { translate } from 'Shared/translate';
var OrderOverviewWidget = function (_a) {
    var orderList = _a.orderList, widgetType = _a.widgetType, orderOverviewPageUrl = _a.orderOverviewPageUrl;
    return (React.createElement("div", { className: styles.widget },
        React.createElement("p", { className: styles.header }, widgetType == 'pendingOrders'
            ? translate('/MyMenigo/Overview/PendingOrders')
            : translate('/MyMenigo/Overview/DeliveredOrders')),
        orderList
            ? orderList.map(function (order, index) {
                return (React.createElement(WidgetRow, { key: index, deliveryDate: order.deliveryDate, noOfOrder: order.orderHeaderItems.length, totalPrice: order.totalPrice, widgetType: widgetType }));
            })
            : null,
        React.createElement("div", { className: styles.viewAllCard },
            React.createElement("a", { href: orderOverviewPageUrl, className: styles.viewAllCard__viewAll },
                React.createElement("span", null, widgetType == 'pendingOrders'
                    ? translate('/MyMenigo/Overview/ViewPendingOrders')
                    : translate('/MyMenigo/Overview/ViewDeliveredOrders')),
                React.createElement(Icon, { className: styles.viewAllCard__viewAll__icon, name: "arrow-right-new" })))));
};
export default OrderOverviewWidget;
