var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import { Input } from 'Shared/Fields';
import * as styles from './base.scss';
import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import connect from 'Shared/connect';
import Dialog, { DialogType } from 'Shared/Dialog';
var AccountForm = /** @class */ (function (_super) {
    __extends(AccountForm, _super);
    function AccountForm(props) {
        var _this = _super.call(this, props) || this;
        _this.validateUserSuffix = function (userSuffix) {
            var model = {
                success: !((userSuffix || '').trim() === ''),
                message: translate('/MyMenigo/Accounts/RequiredAccountName'),
                value: userSuffix
            };
            return Promise.resolve(model);
        };
        _this.validatePassword = function (password) {
            password = (password || '').trim();
            var minLengthFullfilled = !(password.length <= 5);
            var model = {
                success: minLengthFullfilled,
                message: translate('/MyMenigo/Accounts/RequiredPassword'),
                value: password
            };
            return Promise.resolve(model);
        };
        _this.validateEmail = function (email) {
            var model = {
                success: !!!email || _this.validEmail(email),
                message: translate('/MyMenigo/Accounts/InvalidEmail'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.save = function () {
            if (!!_this.state.invalidUserSuffix || !!_this.state.invalidEmail || !!_this.state.invalidPassword) {
                return Promise.reject();
            }
            return server
                .postJson(pathCombine(location.pathname, 'Save', '?action=' + _this.props.action), _this.state.account)
                .then(function (result) {
                if (!result.success) {
                    return Promise.reject(result);
                }
                return Promise.resolve();
            })
                .catch(function (err) {
                _this.props.setFeedback({ text: err.message || translate('/MyMenigo/Accounts/Unknown'), type: 'error' });
                return Promise.reject(null);
            });
        };
        _this.state = {
            invalidUserSuffix: false,
            invalidPassword: false,
            invalidEmail: false,
            account: {
                email: !!props.account ? props.account.email : null,
                userSuffix: !!props.account ? props.account.userSuffix : null,
                firstName: !!props.account ? props.account.firstName : null,
                lastName: !!props.account ? props.account.lastName : null,
                password: null,
                orderReferenceId: !!props.account ? props.account.orderReferenceId : null
            },
            proxyPassword: null,
            proxyEmail: !!props.account ? props.account.email : null,
            proxyUserSuffix: !!props.account ? props.account.userSuffix : null,
        };
        return _this;
    }
    AccountForm.prototype.onChangeUserSuffix = function (userSuffix) {
        userSuffix = (userSuffix || '').trim();
        this.setState({ proxyUserSuffix: userSuffix });
        if (userSuffix === '') {
            this.setState({ invalidUserSuffix: true });
        }
        else {
            this.setState({ invalidUserSuffix: false });
            this.updateState('userSuffix', userSuffix);
        }
    };
    AccountForm.prototype.onChangePassword = function (password) {
        this.setState({ proxyPassword: password });
        if (this.props.action === 'new' && (password || '').trim() === '') {
            this.setState({ invalidPassword: true });
        }
        else {
            this.setState({ invalidPassword: false });
            this.updateState('password', password);
        }
    };
    AccountForm.prototype.onChangeEmail = function (email) {
        this.setState({ proxyEmail: email });
        if ((email || '').trim() !== '' && !this.validEmail(email)) {
            this.setState({ invalidEmail: true });
        }
        else {
            this.setState({ invalidEmail: false });
            this.updateState('email', email);
        }
    };
    AccountForm.prototype.updateState = function (field, value) {
        var account = Object.assign({}, this.state.account);
        switch (field) {
            case 'email':
                account.email = value;
                break;
            case 'firstName':
                account.firstName = value;
                break;
            case 'lastName':
                account.lastName = value;
                break;
            case 'password':
                account.password = value;
                break;
            case 'userSuffix':
                account.userSuffix = value;
                break;
            case 'orderReferenceId':
                account.orderReferenceId = value;
                break;
            default:
                break;
        }
        this.setState({ account: account });
    };
    AccountForm.prototype.validEmail = function (email) {
        // tslint:disable-next-line:max-line-length
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    AccountForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: styles.column },
                    React.createElement("label", null, translate('/MyMenigo/Accounts/AccountName')),
                    React.createElement("span", { className: this.props.action === 'edit' ? styles.accountNumberEdit : styles.accountNumber },
                        this.props.contactId,
                        this.props.action === 'edit' && !!this.state.account.userSuffix && this.state.account.userSuffix),
                    this.props.action === 'new' && (React.createElement(Input, { type: "text", value: this.state.proxyUserSuffix || ' ', name: "userSuffix", spellCheck: false, autoCorrect: "off", maxLength: 20, autoComplete: "off", required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredAccountName'), className: styles.accountInput, onValidate: function (inpt) { return _this.validateUserSuffix(inpt); }, onChange: function (e) { return _this.onChangeUserSuffix(e.target.value); } })),
                    React.createElement("div", null,
                        React.createElement(Input, { label: this.props.action === 'edit'
                                ? translate('/MyMenigo/Accounts/NewPassword')
                                : translate('/MyMenigo/Accounts/Password'), type: "password", className: styles.inputWrapperLabel, name: "password", value: this.state.proxyPassword, spellCheck: false, autoCorrect: "off", minLength: 5, maxLength: 20, autoComplete: "off", onValidate: function (inpt) { return _this.validatePassword(inpt); }, onChange: function (e) { return _this.onChangePassword(e.target.value); } }))),
                React.createElement("div", { className: styles.column },
                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/OrderReferenceId'), type: "text", className: styles.inputWrapperLabel, value: this.state.account.orderReferenceId, name: "orderReferenceId", id: "orderReferenceId", spellCheck: false, autoCorrect: "off", maxLength: 20, onChange: function (e) {
                            return _this.updateState('orderReferenceId', e.target.value);
                        } }),
                    React.createElement("span", { className: styles.small }, translate('/MyMenigo/CustomerProfileBlock/OrderReferenceIdAccountHelpText')))),
            React.createElement(Input, { label: translate('/MyMenigo/Accounts/Email'), type: "email", className: styles.inputWrapperLabel, value: this.state.proxyEmail, name: "email", spellCheck: false, autoCorrect: "off", maxLength: 50, autoComplete: "off", required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), onValidate: this.validateEmail, onChange: function (e) { return _this.onChangeEmail(e.target.value); } }),
            React.createElement(Input, { label: translate('/MyMenigo/Accounts/FirstName'), type: "text", className: styles.inputWrapperLabel, value: this.state.account.firstName, name: "firstName", spellCheck: false, autoCorrect: "off", maxLength: 50, autoComplete: "off", required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), onChange: function (e) {
                    _this.updateState('firstName', e.target.value);
                } }),
            React.createElement(Input, { label: translate('/MyMenigo/Accounts/lastName'), type: "text", className: styles.inputWrapperLabel, value: this.state.account.lastName, name: "lastName", spellCheck: false, autoCorrect: "off", maxLength: 50, autoComplete: "off", required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), onChange: function (e) {
                    _this.updateState('lastName', e.target.value);
                } }),
            React.createElement(Dialog, { type: DialogType.SaveAbort, onConfirm: this.save, onCancel: this.props.cancel, onStatusWasFulfilled: this.props.onSuccess })));
    };
    return AccountForm;
}(React.PureComponent));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(AccountForm);
