import React, { useEffect } from 'react';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
import classnames from 'classnames';
function VideoBlock(props) {
    var getVideoUrl = function (videoId, videoSource) {
        if (videoSource === 'youTube') {
            return "//www.youtube.com/embed/" + videoId;
        }
        else if (videoSource === 'vimeo') {
            return "//player.vimeo.com/video/" + videoId;
        }
    };
    useEffect(function () {
        var reloadOTBanner = window['reloadOTBanner'];
        if (reloadOTBanner !== undefined) {
            reloadOTBanner();
        }
        else {
            console.log('Warning: reloadOTBanner does not exist in OneTrust-Script please add to startPage settings. Reload the page manually to show the video.');
        }
    }, []);
    return (React.createElement("div", null,
        React.createElement("div", { className: styles.videoContainer },
            React.createElement("iframe", { "data-src": getVideoUrl(propertyValueFor(props.videoId), propertyValueFor(props.videoSource)), className: classnames(styles.iframe, 'optanon-category-C0002-C0003-C0004'), frameBorder: "0", allowFullScreen: propertyValueFor(props.allowFullscreen) }))));
}
export default VideoBlock;
