var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as styles from './base.scss';
import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
var viewMoreText = null;
var blockTitle = null;
export function initializeVariables(type, props) {
    switch (type) {
        case props.blockTypes.empty:
            break;
        case props.blockTypes.order:
            viewMoreText = translate('/MyMenigo/StatisticsNavigationBlock/ViewAllOrders');
            blockTitle = translate('/MyMenigo/StatisticsNavigationBlock/OrderProcessing');
            break;
        case props.blockTypes.favoriteList:
            // TODO: return favoritelist img classname & view more text
            break;
        case props.blockTypes.inventory:
            // TODO: return inventory img classname & view more text
            break;
        default:
            break;
    }
}
var StatisticsNavigationBlock = /** @class */ (function (_super) {
    __extends(StatisticsNavigationBlock, _super);
    function StatisticsNavigationBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StatisticsNavigationBlock.prototype.render = function () {
        var _this = this;
        var blockType = propertyValueFor(this.props.block.type);
        initializeVariables(blockType, this.props);
        return (React.createElement("div", { className: styles.parentContentBlock },
            React.createElement("div", { className: styles.mainContentBlock },
                React.createElement("div", { className: styles.displayImage }, blockType === this.props.blockTypes.order ? React.createElement(Icon, { name: "cart" }) : null // TODO: Update icon to correct one once it is available
                ),
                blockType !== this.props.blockTypes.empty ? React.createElement("div", { className: styles.verticalLineSeparator }) : null,
                React.createElement("div", { className: styles.statisticsDetails },
                    React.createElement("div", null,
                        React.createElement("p", { className: styles.title },
                            React.createElement("span", null,
                                blockType !== this.props.blockTypes.empty ? blockTitle : null,
                                " "))),
                    React.createElement("div", null, this.props.rowItems
                        ? this.props.rowItems.map(function (rowItem, key) { return (React.createElement("p", { className: styles.details, key: key },
                            React.createElement("span", null,
                                " ",
                                rowItem,
                                " "),
                            blockType === _this.props.blockTypes.order ? React.createElement("span", null) : null // TODO: Update icon to correct one once it is available
                        )); })
                        : null))),
            React.createElement("div", { className: styles.viewMoreBase },
                React.createElement("div", { className: styles.textDiv },
                    React.createElement("p", null,
                        React.createElement("span", null,
                            " ",
                            blockType !== this.props.blockTypes.empty ? viewMoreText : null,
                            " "))))));
    };
    return StatisticsNavigationBlock;
}(React.Component));
export default connect(function (state) { return ({}); })(StatisticsNavigationBlock);
