var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import ProductListing from 'Shared/ProductListing';
import Main, { Section } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import Description from 'Shared/Description';
import ContentCardRow from 'Shared/ContentCardRow';
import { MEDIUM, SMALL } from 'Shared/breakpoints';
import DescriptionImage from 'Shared/DescriptionImage/DescriptionImage';
import ProductCarousel from 'Shared/Blocks/ProductCarousel/ProductCarouselBlock';
import * as styles from './base.scss';
import CategoryPageHeader from './Components/CategoryHeader';
import CategoryHero from './Components/CategoryHero';
var Category = /** @class */ (function (_super) {
    __extends(Category, _super);
    function Category(props) {
        var _this = _super.call(this, props) || this;
        _this.ads = props.supplierAds;
        _this.state = {
            subCategories: _this.props.products.subCategories
        };
        return _this;
    }
    Category.prototype.componentWillReceiveProps = function (nextProps) {
        this.ads = nextProps.supplierAds;
        if (!!nextProps.products.subCategories) {
            this.setState({
                subCategories: nextProps.products.subCategories
            });
        }
    };
    Category.prototype.render = function () {
        return (React.createElement(Main, { appearance: "narrow", className: styles.main },
            !!propertyValueFor(this.props.content.topContent) && (React.createElement(Section, null,
                React.createElement(PropertyFor, { property: this.props.content.topContent }))),
            React.createElement(Section, { className: styles.bottomBorder },
                this.props.currentBreakpoint >= MEDIUM && !!this.props.heroAds && this.props.heroAds.length > 0 && (React.createElement(CategoryHero, { hero: this.props.heroAds[0] })),
                !!propertyValueFor(this.props.content.middleContent) && (React.createElement(Section, null,
                    React.createElement(PropertyFor, { property: this.props.content.middleContent }))),
                !!this.props.description && !!this.props.imageUrl && this.props.currentBreakpoint >= MEDIUM && (React.createElement(DescriptionImage, { text: this.props.description, imageUrl: this.props.imageUrl })),
                !!this.props.text && (React.createElement(Description, { text: this.props.text, hasShowAllButton: false, centered: true, lineCount: "few", expandFromUrl: true })),
                React.createElement(CategoryPageHeader, { newsBannerArea: this.props.newsBannerContentArea, headerProperty: this.props.content.displayName, breadcrumbs: this.props.breadcrumbs, categories: this.state.subCategories })),
            React.createElement(ProductListing, __assign({}, this.props, { disableHeaderRow: true, showLayoutOptions: this.props.currentBreakpoint > SMALL, recommendationsPanel: this.props.recommendationsPanel })),
            this.ads.map(function (data) { return (React.createElement(ProductCarousel, __assign({ key: data.title }, data))); }),
            React.createElement(ContentCardRow, { cards: this.props.cards, title: translate('/Product/LatestNews') })));
    };
    return Category;
}(React.Component));
export default Category;
