var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADD_ORDER_TO_CART, INIT_ORDER, INIT_DIALOG_LISTS, UPDATE_ITEM, CLEAR_ITEMS, TOGGLE_LIST_NAME_EDITING, } from './action-creators';
export default function (state, action) {
    if (!state) {
        state = {
            favorites: [],
            pantries: [],
            fromList: null,
            codes: [],
            order: [],
        };
    }
    switch (action.type) {
        case INIT_DIALOG_LISTS:
            return __assign({}, state, action.dialog);
        case INIT_ORDER:
            return Object.assign({}, state, {
                order: action.products.slice(0),
            });
        case ADD_ORDER_TO_CART:
            return Object.assign({}, state);
        case UPDATE_ITEM:
            return Object.assign({}, state, {
                order: state.order.findIndex(function (item) { return item.productId === action.productId; }) === -1
                    ? Object.assign([], state.order, state.order.concat([action.item]))
                    : state.order.map(function (i) {
                        return i.productId === action.productId ? action.item : i;
                    }),
            });
        case CLEAR_ITEMS:
            return Object.assign({}, state, { order: state.order.map(function (item) { return Object.assign({}, item, { quantity: 0 }); }) });
        case TOGGLE_LIST_NAME_EDITING:
        default:
            return state;
    }
}
