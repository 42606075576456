var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var ProductSearchList = /** @class */ (function (_super) {
    __extends(ProductSearchList, _super);
    function ProductSearchList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductSearchList.prototype.render = function () {
        if (this.props.searchResults === null) {
            return React.createElement("div", null);
        }
        if (this.props.searchResults.length === 0) {
            return (React.createElement("div", null,
                React.createElement("div", null, translate('/App/QuickOrder/ProductNotFound')),
                React.createElement("div", null, translate('/App/QuickOrder/TryProductName'))));
        }
        var Template = this.props.template;
        return (React.createElement("div", { className: styles.searchList }, this.props.searchResults.map(function (item) { return (React.createElement(Template, __assign({ key: item.productId }, item))); })));
    };
    return ProductSearchList;
}(React.Component));
export default ProductSearchList;
