export default function deepFreeze(o) {
    if (process.env.NODE_ENV !== 'production') {
        if (Array.isArray(o)) {
            o.forEach(function (i) { return deepFreeze(o[i]); });
        }
        else if (typeof o === 'object' && o !== null) {
            Object.keys(o).forEach(function (key) { return deepFreeze(o[key]); });
        }
        Object.freeze(o);
    }
    return o;
}
