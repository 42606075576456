var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Section } from 'Shared/PageLayout';
import ProductListing from 'Shared/ProductListing';
import * as styles from './../../../Campaign/Pages/CampaignOverview/base.scss';
import { SMALL } from 'Shared/breakpoints';
import CampaignPageHeader from './../../../Campaign/Components/CampaignPageHeader';
var CampaignOverviewBlock = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { className: styles.bottomBorder },
            React.createElement(CampaignPageHeader, { overiderPreamble: props.overidePreamble, overiderHeader: props.overideHeader, headerProperty: props.page.name, preambleProperty: props.page.preamble, breadcrumbs: props.breadcrumbs, campaigns: props.products.campaigns })),
        React.createElement(ProductListing, __assign({}, props, { showLayoutOptions: props.currentBreakpoint > SMALL, disableHeaderRow: true, isCampaignPage: true }))));
};
export default CampaignOverviewBlock;
