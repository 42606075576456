var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { ExportingStatus } from '../../reducer';
import { translate } from 'Shared/translate';
var FeedbackPage = /** @class */ (function (_super) {
    __extends(FeedbackPage, _super);
    function FeedbackPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FeedbackPage.prototype.render = function () {
        switch (this.props.exportingStatus) {
            default:
            case ExportingStatus.None:
                return null;
            case ExportingStatus.Begin:
                return React.createElement("h1", null, translate('/App/Ips/OrderSendingText'));
            case ExportingStatus.Success:
                return React.createElement("h1", null, translate('/App/Ips/OrderSuccessText'));
            case ExportingStatus.Fail:
                return React.createElement("h1", null, translate('/App/Ips/OrderFailedText'));
        }
    };
    return FeedbackPage;
}(React.Component));
export default connect(function (state) { return ({
    exportingStatus: state.ips.exportingStatus,
}); })(FeedbackPage);
