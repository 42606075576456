var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PAGE_LOAD_FAILURE } from 'SiteLayout/CurrentPage/action-creators';
import { UPDATE_APP_SHELL_DATA } from '../AppShell/action-creators';
import { UPDATE_USERSETTINGS } from 'Shared/UserSettings/action-creators';
import { SET_NEW_IMPERSONATION } from './action-creators';
export default function (state, action, appShellData) {
    if (state === void 0) { state = null; }
    state = state || appShellData.currentUser;
    switch (action.type) {
        case PAGE_LOAD_FAILURE:
            if (action.loadFailure.status === 401 && state.isLoggedIn) {
                // The user has either been logged out because of inactivity or the account has been
                // locked out
                return Object.assign({}, state, {
                    isLoggedIn: false,
                });
            }
            return state;
        case UPDATE_APP_SHELL_DATA:
            return action.appShellData.currentUser;
        case UPDATE_USERSETTINGS:
            return __assign({}, state, { userSettings: __assign({}, action.payload) });
        case SET_NEW_IMPERSONATION:
            return Object.assign({}, state, {
                isImpersonating: true,
            });
        default:
            return state;
    }
}
