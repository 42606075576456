var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import Image from 'Shared/ImageFallback';
import Price from 'Shared/Price';
import { price as loadingPrice } from '../../../Checkout/loading.scss';
import * as styles from './base.scss';
import * as baseStyles from '../base.scss';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
export function ProductHeaderRow(props) {
    return (React.createElement("div", { className: styles.tableheader },
        React.createElement("div", { className: styles.imageColumn }),
        React.createElement("div", { className: styles.infoColumn }, translate('/ProductListing/Product')),
        React.createElement("div", { className: styles.unitColumn }, translate('/ProductListing/UnitCompact')),
        React.createElement("div", { className: styles.priceColumn }, translate('/ProductListing/Price')),
        React.createElement("div", { className: styles.stockColumn }, translate('/ProductListing/StockStatusCompact')),
        React.createElement("div", { className: styles.actionsColumn }, translate('/ProductListing/Quantity')),
        React.createElement("div", { className: styles.totalPriceColumn }, translate('/ProductListing/Sum')),
        React.createElement("div", { className: styles.removeColumn })));
}
export function ProductRowTableLayout(props) {
    return (React.createElement("div", { className: styles.tablerow },
        props.datePickerNode,
        React.createElement("div", { className: styles.imageColumn },
            React.createElement("a", { href: props.url, "data-ticket": props.ticket, className: baseStyles.media },
                React.createElement(Image, { skipSrcSet: true, src: props.mainImageUrl, alt: props.orignalCode, size: "thumb" }))),
        React.createElement("div", { className: classnames(styles.infoColumn, baseStyles.info) }, props.textNode),
        React.createElement("div", { className: styles.unitColumn }, props.unitNode),
        React.createElement("div", { className: styles.priceColumn }, React.createElement(Price, __assign({}, props.selectedVariant.price))),
        React.createElement("div", { className: styles.stockColumn },
            React.createElement(ProductAvailabilityLabel, { availability: props.selectedVariant.availability })),
        React.createElement("div", { className: styles.actionsColumn }, props.quantityNode),
        React.createElement("div", { className: styles.totalPriceColumn }, props.totalPrice && (React.createElement(Price, __assign({ className: classnames(loadingPrice, baseStyles.price) }, props.totalPrice, { size: "large", isPending: props.isLineItemPending })))),
        React.createElement("div", { className: styles.removeColumn }, props.removeitemNode)));
}
