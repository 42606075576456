import React from 'react';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import connect from 'Shared/connect';
import { MEDIUM } from 'Shared/breakpoints';
import * as styles from './base.scss';
var crumbs = function (breadcrumbs, currentBreakpoint) {
    return currentBreakpoint < MEDIUM ? breadcrumbs.slice(-1) : breadcrumbs;
};
var Breadcrumbs = function (_a) {
    var currentBreakpoint = _a.currentBreakpoint, breadcrumbs = _a.breadcrumbs, productName = _a.productName;
    return (React.createElement("ul", { className: styles.list },
        crumbs(breadcrumbs, currentBreakpoint).map(function (_a, i) {
            var url = _a.url, text = _a.text;
            return (React.createElement("li", { className: styles.item, key: i }, !productName && i === breadcrumbs.length - 1 ? (React.createElement("div", { className: styles.link }, text)) : (React.createElement("a", { className: styles.link, href: url },
                text,
                React.createElement(MyMenigoIcon, { name: "arrow" })))));
        }),
        productName && (React.createElement("li", { className: styles.item, key: productName },
            React.createElement("div", { className: styles.link }, productName)))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(Breadcrumbs);
