import React from 'react';
var informationText = function (item) { return item.unitShortName + ": " + item.specification; };
function getInformationText(props) {
    return !props.unitShortName
        ? props.unitOptions
            .filter(function (item) { return !!item.specification && !item.unitShortName.toLocaleLowerCase().includes('kg/d'); })
            .map(informationText)
            .join('; ')
        : props.unitOptions
            .filter(function (item) {
            return !!item.specification &&
                (props.unitShortName && props.unitShortName.toLowerCase().includes('kg')
                    ? item.unitShortName.toLowerCase().includes('kg')
                    : props.unitShortName === item.unitShortName);
        })
            .map(informationText)
            .join('; ');
}
export default (function (props) { return (React.createElement("div", null, getInformationText(props).length > 0
    ? getInformationText(props)
    : props.unitOptions.length === 1 && props.unitOptions.map(informationText)[0])); });
