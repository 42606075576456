var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import { UP, DOWN, TAB } from 'Shared/keyboard';
var SelectionContext = React.createContext(null);
export var ProductCardSelectionProvider = function (_a) {
    var children = _a.children, selectableIndexes = _a.selectableIndexes, keepIndexOnBlur = _a.keepIndexOnBlur;
    var _b = __read(useState(-1), 2), index = _b[0], setIndex = _b[1];
    var moveDown = useCallback(function () {
        var next = index === selectableIndexes.length - 1 ? 0 : index + 1;
        setIndex(next);
    }, [selectableIndexes, index]);
    var moveUp = useCallback(function () {
        var next = index === 0 ? selectableIndexes.length - 1 : index - 1;
        setIndex(next);
    }, [selectableIndexes, index]);
    var onKeyDown = useCallback(function (e) {
        if (selectableIndexes.length <= 1 || index === -1) {
            return;
        }
        if (e.keyCode === DOWN) {
            e.preventDefault();
            moveDown();
        }
        if (e.keyCode === UP) {
            e.preventDefault();
            moveUp();
        }
        if (e.keyCode === TAB) {
            e.preventDefault();
            moveDown();
        }
        if (e.shiftKey && e.keyCode === TAB) {
            e.preventDefault();
            moveUp();
        }
    }, [selectableIndexes, index]);
    var setIndexIfAllowed = useCallback(function (index) {
        var indexPosition = selectableIndexes.indexOf(index);
        if (indexPosition > -1) {
            setIndex(indexPosition);
        }
    }, [selectableIndexes]);
    var resetSelectedIndex = useCallback(function () {
        setIndex(-1);
    }, [setIndex]);
    var onBlur = useCallback(function () {
        if (!keepIndexOnBlur) {
            resetSelectedIndex();
        }
    }, [setIndex, keepIndexOnBlur]);
    return (React.createElement(SelectionContext.Provider, { value: {
            selectedIndex: selectableIndexes[index],
            setSelectedIndex: setIndexIfAllowed,
            resetSelectedIndex: resetSelectedIndex,
        } },
        React.createElement("div", { tabIndex: 0, onKeyDown: onKeyDown, onBlur: onBlur }, children)));
};
export var ProductCardSelection = SelectionContext.Consumer;
