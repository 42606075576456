var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import { TINY, MEDIUM, SMALL } from 'Shared/breakpoints';
import { ProductHeaderRow as ProductHeaderRowTableLayout } from './TableLayout';
import * as styles from './base.scss';
var ProductHeaderRow = /** @class */ (function (_super) {
    __extends(ProductHeaderRow, _super);
    function ProductHeaderRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductHeaderRow.prototype.render = function () {
        switch (this.props.appearance) {
            case 'slim':
                return null; // TODO implement slim version, maybe APP here?
            case 'compact':
                return (this.props.currentBreakpoint > TINY && (React.createElement("div", { className: classnames(styles.head, !!this.props.isCart && styles.isCheckout, !!this.props.isCheckout &&
                        this.props.currentBreakpoint <= MEDIUM &&
                        this.props.currentBreakpoint > SMALL &&
                        styles.medium) },
                    React.createElement("div", { className: styles.col1 }, translate('/ProductListing/ArticleNumber')),
                    React.createElement("div", { className: styles.col2 }, translate('/ProductListing/DisplayName')),
                    this.props.isCart && !this.props.isCheckout && React.createElement("div", { className: styles.col3 }),
                    this.props.hasAmountNeeded && (React.createElement("div", { className: styles.col4 }, translate('/ProductListing/AmountNeeded'))),
                    this.props.isSupplierSalesman && (React.createElement("div", { className: styles.col5 }, translate('/ProductListing/IsProductSample'))),
                    React.createElement("div", { className: styles.col5 }, translate('/ProductListing/Area')),
                    React.createElement("div", { className: styles.col6 }, translate('/ProductListing/Price')),
                    React.createElement("div", { className: styles.col7 }, translate('/ProductListing/StockStatusCompact')),
                    React.createElement("div", { className: styles.col8 }, translate('/ProductListing/Quantity')),
                    this.props.isCart && !this.props.isSupplierSalesman && (React.createElement("div", { className: styles.col9 }, translate('/ProductListing/Sum'))),
                    (this.props.isCart || this.props.hasDelete) && React.createElement("div", { className: styles.col10 }))));
            case 'simple':
                return (this.props.currentBreakpoint > TINY && (React.createElement("div", { className: styles.head },
                    React.createElement("div", { className: styles.col1 }, translate('/ProductListing/ArticleNumber')),
                    React.createElement("div", { className: styles.col2 }, translate('/ProductListing/DisplayName')),
                    React.createElement("div", { className: styles.col4 }, translate('/ProductListing/UnitCompact')),
                    React.createElement("div", { className: styles.col5 }, translate('/ProductListing/Area')),
                    this.props.isSupplierSalesman && (React.createElement("div", { className: styles.col5 }, translate('/ProductListing/IsProductSample'))),
                    React.createElement("div", { className: styles.col6 }, translate('/ProductListing/Price')),
                    React.createElement("div", { className: styles.col7 }, translate('/ProductListing/StockStatusCompact')))));
            case 'compact-mobile':
                return React.createElement("h4", null, translate('/ProductListing/Products'));
            case 'subDelivery':
                return null;
            case 'tableversion':
                return React.createElement(ProductHeaderRowTableLayout, null);
            default:
                return null;
        }
    };
    return ProductHeaderRow;
}(React.Component));
export default ProductHeaderRow;
