var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { TINY } from 'Shared/breakpoints';
import connect from 'Shared/connect';
var ReplacementProduct = /** @class */ (function (_super) {
    __extends(ReplacementProduct, _super);
    function ReplacementProduct(props) {
        return _super.call(this, props) || this;
    }
    ReplacementProduct.prototype.onClick = function () {
        if (!!this.props.onClick) {
            return this.props.onClick();
        }
    };
    ReplacementProduct.prototype.render = function () {
        var _this = this;
        var product = this.props.product;
        var replacementText;
        var replacementProduct;
        var replacementProductText;
        if (!!product.isDiscontinued) {
            replacementText = translate('/Product/Discontinued');
            replacementProduct = product.replacementProduct;
            replacementProductText = replacementProduct && translate('/Product/ReplacementProduct');
        }
        else if (!!product.isBackorderReplaced) {
            replacementText = translate('/Product/BackorderReplaced');
            replacementProduct = product.replacementProduct;
            replacementProductText = replacementProduct
                ? translate('/Product/RecommendedReplacementProduct')
                : translate('/Product/NoRecommendedReplacementProduct');
        }
        var titleNode = !!product.isBackorderReplaced ? (React.createElement("a", { href: product.url.toString(), onClick: function () { return _this.onClick(); }, "data-ticket": product.ticket },
            React.createElement("b", null, this.props.product.title),
            React.createElement("p", { className: styles.lightGray }, this.props.product.trademark))) : (React.createElement("div", null,
            React.createElement("b", null, this.props.product.title),
            React.createElement("p", { className: styles.lightGray }, this.props.product.trademark)));
        switch (this.props.appearance) {
            case 'row':
                return this.props.currentBreakpoint > TINY ? (React.createElement("div", { className: classnames(styles.fullRow, this.props.hasFocus && styles.highlighted) },
                    React.createElement("div", { className: styles.col1 }, propertyValueFor(this.props.product.content.code)),
                    React.createElement("div", { className: styles.col2 }, titleNode),
                    React.createElement("div", { className: styles.message },
                        React.createElement("i", null, replacementText),
                        !!replacementProductText && (React.createElement("i", null,
                            React.createElement("br", null),
                            React.createElement("b", null, replacementProductText)))))) : (React.createElement("div", { className: styles.mobileRow },
                    React.createElement("div", { className: styles.mobileLeft }),
                    React.createElement("div", { className: styles.mobileRight },
                        React.createElement("div", { className: styles.mobileProductRow }, titleNode),
                        React.createElement("div", { className: styles.mobileInfo },
                            React.createElement("div", { className: styles.mobileProductRow },
                                React.createElement("div", { className: styles.lightGray },
                                    translate('/ProductListing/ArticleNumber'),
                                    " ",
                                    propertyValueFor(this.props.product.content.code))),
                            React.createElement("div", { className: styles.mobileProductRow },
                                React.createElement("i", null, replacementText),
                                !!replacementProductText && (React.createElement("i", null,
                                    React.createElement("br", null),
                                    React.createElement("b", null, replacementProductText))))))));
            case 'mobile':
                return (React.createElement("div", { className: styles.mobileCompactRow },
                    React.createElement("div", null, !!product.isBackorderReplaced ? (React.createElement("a", { href: product.url.toString(), onClick: function () { return _this.onClick(); }, "data-ticket": product.ticket },
                        React.createElement("b", null, this.props.product.title))) : (React.createElement("b", null, this.props.product.title))),
                    React.createElement("div", null,
                        translate('/ProductListing/ArticleNumber'),
                        " ",
                        propertyValueFor(this.props.product.content.code)),
                    React.createElement("div", null,
                        React.createElement("i", null, replacementText),
                        !!replacementProductText && (React.createElement("i", null,
                            React.createElement("br", null),
                            React.createElement("b", null, replacementProductText))))));
            case 'box':
                return (React.createElement("div", { className: styles.discontinuedSectionBox },
                    React.createElement("i", null,
                        replacementText,
                        !!replacementProductText && (React.createElement("b", null,
                            React.createElement("br", null),
                            replacementProductText))),
                    !!replacementProduct && (React.createElement("div", { className: styles.discontinuedProduct },
                        React.createElement("a", { href: replacementProduct.url.toString(), "data-ticket": replacementProduct.ticket },
                            React.createElement("b", null, propertyValueFor(replacementProduct.content.displayName)),
                            React.createElement("span", { className: styles.lightGray }, translate('/ProductListing/ArticleNumber') + " " + propertyValueFor(replacementProduct.content.code)))))));
            case 'list':
            default:
                return (React.createElement("div", { className: this.props.currentBreakpoint > TINY ? styles.discontinuedSection : styles.discontinuedSectionMobile },
                    React.createElement("div", { className: styles.text },
                        replacementText,
                        !!replacementProductText && (React.createElement("b", null,
                            React.createElement("br", null),
                            replacementProductText))),
                    !!replacementProduct && (React.createElement("div", { className: styles.discontinuedProduct },
                        React.createElement("a", { href: replacementProduct.url.toString(), "data-ticket": replacementProduct.ticket },
                            React.createElement("div", { className: styles.title }, replacementProduct.title),
                            React.createElement("div", null,
                                React.createElement("i", null, translate('/ProductListing/ArticleNumber') + " " + propertyValueFor(replacementProduct.content.code))),
                            this.props.currentBreakpoint > TINY && (React.createElement("div", null,
                                React.createElement("i", null, replacementProduct.trademark))))))));
        }
    };
    return ReplacementProduct;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ReplacementProduct);
