var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Part } from 'Shared/PageLayout';
import FacetList from 'Shared/Facet/FacetList';
import FilterAndSortBar from 'Shared/Facet/FilterAndSortBar';
import { FadeDropDown } from 'Shared/DropDown';
import FeedbackButton from 'Shared/Button/Feedback';
import ViewLayoutSelection from 'Shared/ViewLayoutSelection';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import { replaceState } from 'Shared/history';
import { translate } from 'Shared/translate';
import { scrollPosition } from 'SiteLayout/Root';
import { TINY, SMALL, MEDIUM } from 'Shared/breakpoints';
import RecipeCardList from './RecipeCard/List';
import toggleFacet, { allSelectedValues } from 'Shared/Facet/toggle-facet';
import * as styles from './base.scss';
import { bindInfiniteScroll } from 'Shared/infinite-scroll';
import connect from 'Shared/connect';
import SelectedFilters from 'Shared/ProductListing/Components/SelectedFilters';
import classnames from 'classnames';
var RecipeListing = /** @class */ (function (_super) {
    __extends(RecipeListing, _super);
    function RecipeListing(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            query: _this.props.query,
            facets: _this.props.facets,
            sortBy: _this.props.sortOptions.value,
            infiniteScrollActive: false,
            currentLayout: _this.props.userSettings.viewLayoutSelection,
        };
        _this.lastScrollTop = 0;
        _this.showMorePageToLoad = 0;
        _this.currentReload = Promise.resolve(null);
        return _this;
    }
    RecipeListing.prototype.componentWillMount = function () {
        this.setState({
            infiniteScrollActive: this.allRecipesAreVisible(),
        });
    };
    RecipeListing.prototype.componentWillUnmount = function () {
        this.unbindInfiniteScroll && this.unbindInfiniteScroll();
    };
    RecipeListing.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.query !== nextProps.query) {
            this.setState({
                query: nextProps.query,
            });
        }
        if (this.props.facets !== nextProps.facets) {
            this.setState({
                facets: nextProps.facets,
            });
        }
        if (this.props.sortOptions.value !== nextProps.sortOptions.value) {
            this.setState({
                sortBy: nextProps.sortOptions.value,
            });
        }
        if (this.state.currentLayout !== this.props.userSettings.viewLayoutSelection) {
            this.setState({
                currentLayout: this.props.userSettings.viewLayoutSelection,
            });
        }
    };
    RecipeListing.prototype.componentDidUpdate = function () {
        if (this.props.userSettings && this.props.userSettings.viewLayoutSelection !== this.state.currentLayout) {
            this.setState({ currentLayout: this.props.userSettings.viewLayoutSelection });
        }
    };
    RecipeListing.prototype.allRecipesAreLoaded = function () {
        return !(this.props.recipes.totalItems > this.visibleRecipeCount());
    };
    RecipeListing.prototype.scrollNearBottom = function (scroll) {
        if (document.body.scrollHeight / window.pageYOffset < 1.5 || !scroll) {
            return true;
        }
        return false;
    };
    RecipeListing.prototype.showMore = function (scroll) {
        var _this = this;
        if (this.allRecipesAreLoaded() || !this.scrollNearBottom(scroll)) {
            return;
        }
        var currentCount = this.props.recipes.items.length;
        var currentPage = Math.ceil(currentCount / this.props.recipes.pageSize);
        var totalPages = this.props.recipes.totalPages;
        if (currentPage >= totalPages) {
            console.debug('Skipping request to show more for', currentPage, 'because we already have', totalPages);
            return;
        }
        if (this.showMorePageToLoad >= currentPage) {
            console.debug('Skipping request to show more for', currentPage, 'because we have already scheduled load for', this.showMorePageToLoad);
            return;
        }
        this.showMorePageToLoad = currentPage;
        this.setState({
            infiniteScrollActive: true,
        });
        var reload = function (page) {
            return _this.reloadPage(page, {
                merge: function (currentPage, newRecipePage) {
                    if (!currentPage || !currentPage.list || !currentPage.list.recipes || !currentPage.list.recipes.items) {
                        var err = new Error('Recipe listing was unknowingly changed');
                        window.newrelic && window.newrelic.noticeError(err);
                        return newRecipePage;
                    }
                    var array = currentPage.list.recipes.items.concat(newRecipePage.list.recipes.items);
                    var newPage = Object.assign({}, currentPage, {
                        canonicalUrl: newRecipePage.canonicalUrl,
                        nextUrl: newRecipePage.nextUrl,
                        prevUrl: newRecipePage.prevUrl,
                        list: Object.assign({}, currentPage.list, {
                            recipes: Object.assign({}, currentPage.list.recipes, { items: array }),
                        }),
                    });
                    return newPage;
                },
                hideSpinner: true,
            }).then(function () {
                var pageAfterLoad = Math.ceil(_this.props.recipes.items.length / _this.props.recipes.pageSize);
                if (pageAfterLoad < _this.showMorePageToLoad) {
                    _this.showMorePageToLoad = pageAfterLoad + 1;
                }
                else {
                    _this.showMorePageToLoad = 0;
                }
                return;
            });
        };
        if (!this.showMoreIsLoading) {
            this.showMoreIsLoading = true;
            reload(currentPage).then(function () {
                _this.showMoreIsLoading = false;
            });
        }
    };
    RecipeListing.prototype.allRecipesAreVisible = function () {
        return this.props.recipes.totalItems === this.visibleRecipeCount();
    };
    RecipeListing.prototype.visibleRecipeCount = function () {
        return Math.min(this.props.recipes.items.length, this.props.recipes.totalItems);
    };
    RecipeListing.prototype.reloadPage = function (page, loadOptions) {
        if (loadOptions === void 0) { loadOptions = undefined; }
        var queryParams = this.props.queryParams || {};
        var url = {
            path: window.location.pathname,
            query: Object.assign({}, queryParams, !!this.state.query ? { query: this.state.query } : {}, this.getFacetsObject(this.state.facets), !!this.state.sortBy ? { sortBy: this.state.sortBy } : {}),
            hiddenQuery: {
                page: !!page ? page : 0,
            },
        };
        this.currentReload = this.currentReload.then(function () { return replaceState(url, loadOptions); });
        return this.currentReload;
    };
    RecipeListing.prototype.getFacetsObject = function (facets) {
        var result = {};
        for (var i = 0; i < facets.length; i++) {
            allSelectedValues(facets[i].facetValues).forEach(function (value) {
                if (!result[value.fieldName]) {
                    result[value.fieldName] = [];
                }
                result[value.fieldName].push(value.id);
            });
        }
        return result;
    };
    RecipeListing.prototype.resetAfterFilterOrSort = function () {
        var _this = this;
        this.showMorePageToLoad = 0;
        if (!!this.state.infiniteScrollActive) {
            this.unbindInfiniteScroll = bindInfiniteScroll(this.listElement, function () { return _this.showMore(true); });
        }
        this.scrollToTopIfMobile();
    };
    RecipeListing.prototype.scrollToTopIfMobile = function () {
        if (this.props.currentBreakpoint <= TINY && this.listElement) {
            scrollPosition(this.listElement.offsetTop - 90);
        }
    };
    RecipeListing.prototype.onFacetValueChange = function (facets, ticket, element) {
        var _this = this;
        var offset = null;
        if (element !== null) {
            offset = offsetTop(element);
        }
        this.setState({
            facets: facets,
        }, function () {
            if (element !== null) {
                var newOffset = offsetTop(element);
                scrollPosition(scrollPosition() + (newOffset - offset));
            }
            _this.reloadPage(0).then(function () { return _this.resetAfterFilterOrSort(); });
        });
        this.unbindInfiniteScroll && this.unbindInfiniteScroll();
    };
    RecipeListing.prototype.onSortChange = function (sortBy) {
        var _this = this;
        this.setState({
            sortBy: sortBy,
        }, function () {
            _this.reloadPage(0).then(function () { return _this.resetAfterFilterOrSort(); });
        });
    };
    RecipeListing.prototype.onClickShowMore = function (element) {
        var _this = this;
        this.showMore(false);
        this.setState({ infiniteScrollActive: true });
        this.unbindInfiniteScroll = bindInfiniteScroll(this.listElement, function () { return _this.showMore(true); });
    };
    RecipeListing.prototype.clearFacets = function () {
        var newFacets = this.state.facets;
        this.state.facets.forEach(function (f) {
            var selectedValues = allSelectedValues(f.facetValues);
            selectedValues.forEach(function (v) {
                newFacets = toggleFacet(newFacets, f.fieldName, v, false);
            });
        });
        this.onFacetValueChange(newFacets, null, null);
    };
    RecipeListing.prototype.render = function () {
        var _this = this;
        var totalCount = this.props.recipes.totalItems;
        var selectedFacetValueCount = 0;
        this.state.facets.forEach(function (f) {
            selectedFacetValueCount += allSelectedValues(f.facetValues).length;
        });
        var layout = this.props.currentBreakpoint < MEDIUM ? '3to7' : '1to3';
        return (React.createElement(Part, { className: this.props.className, layout: this.props.currentBreakpoint < MEDIUM ? null : layout },
            React.createElement(Part, null,
                this.props.currentBreakpoint >= MEDIUM && (React.createElement("div", { className: styles.sortAndViewBar },
                    React.createElement("div", { className: classnames(styles.productCount, 'ui-test-product-listing-count') }, translate('/RecipeListing/Shows', totalCount)),
                    React.createElement("div", { className: styles.sortAndViewActions },
                        React.createElement("div", { className: styles.sortAndViewActionItem },
                            React.createElement(ViewLayoutSelection, null)),
                        React.createElement("div", { className: styles.sortAndViewActionItem },
                            React.createElement(FadeDropDown, { className: classnames(styles.sortDropDown, 'ui-test-dropdown-sort'), options: this.props.sortOptions.options, value: this.state.sortBy, onChange: function (sortBy) { return _this.onSortChange(sortBy); } }))))),
                React.createElement("div", { ref: function (el) { return (_this.listElement = el); } },
                    React.createElement(RecipeCardList, { recipes: this.props.recipes.items, appearance: this.props.currentBreakpoint < SMALL
                            ? 'mobile'
                            : viewLayoutTypes.grid.name === this.state.currentLayout
                                ? 'box'
                                : 'list' })),
                React.createElement(Part, { className: styles.info }, !this.allRecipesAreVisible() && !this.state.infiniteScrollActive && (React.createElement(FeedbackButton, { appearance: "secondary", onClick: function (e) { return _this.onClickShowMore(e.currentTarget); }, className: styles.full }, translate('/RecipeListing/ShowAll'))))),
            this.props.currentBreakpoint >= MEDIUM && (React.createElement("div", null,
                React.createElement("div", { className: styles.columnTitle }, translate('/RecipeListing/Recipe')),
                React.createElement(Part, null,
                    React.createElement(SelectedFilters, { facets: this.state.facets, onClearFacets: function () { return _this.clearFacets(); } }),
                    React.createElement(FacetList, { subCategories: null, facets: this.state.facets, onFacetValueChange: function (facets, ticket, element, freeText) {
                            return _this.onFacetValueChange(facets, ticket, element);
                        }, excludeCnt: true, hideFreeText: true })))),
            this.props.currentBreakpoint < MEDIUM && (React.createElement(FilterAndSortBar, { subCategories: null, facets: this.state.facets, onFacetValueChange: function (facets, ticket) { return _this.onFacetValueChange(facets, ticket, null); }, onSortChange: function (sortBy) { return _this.onSortChange(sortBy); }, sortBy: this.state.sortBy, sortOptions: this.props.sortOptions.options }))));
    };
    return RecipeListing;
}(React.Component));
function offsetTop(element) {
    if (!element) {
        return 0;
    }
    return element.offsetTop + offsetTop(element.offsetParent);
}
export default connect(function (state) { return ({
    userSettings: state.currentUser.userSettings,
    currentBreakpoint: state.currentBreakpoint,
}); })(RecipeListing);
