var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import connect from 'Shared/connect';
import Quantity from 'Shared/Quantity';
import classnames from 'classnames';
import FeedbackButton from 'Shared/Button/Feedback';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { Form } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import { onSaveEditAction, onGetSecondLevelAction, onGetThirdLevelAction } from './action-creators';
import { Input } from 'Shared/Fields';
import DropDown from 'Shared/DropDown';
import { timeout } from 'Shared/timeout';
import * as styles from './Pages/CustomProductOverview/base.scss';
import Button from 'Shared/Button';
import InnerModal from 'Shared/Modal/InnerModal';
var EditCustomProduct = /** @class */ (function (_super) {
    __extends(EditCustomProduct, _super);
    function EditCustomProduct(props) {
        var _this = _super.call(this, props) || this;
        var price = props.customProductItem && props.customProductItem.price;
        var numberOfSmallerUnits = props.customProductItem && props.customProductItem.numberOfSmallerUnits;
        var area = props.customProductItem && props.customProductItem.area;
        var unitShortName = props.customProductItem && props.customProductItem.unitShortName;
        _this.state = {
            id: props.customProductItem && props.customProductItem.id,
            price: price || 0,
            proxyPriceValue: price || 0,
            firstCategoryLevel: props.customProductItem && props.customProductItem.firstCategoryLevel,
            secondCategoryLevel: props.customProductItem && props.customProductItem.secondCategoryLevel,
            thirdCategoryLevel: props.customProductItem && props.customProductItem.thirdCategoryLevel,
            area: area || Object.keys(props.areas)[0],
            numberOfSmallerUnits: numberOfSmallerUnits || 1,
            gtin: props.customProductItem && props.customProductItem.gtin,
            displayName: props.customProductItem && props.customProductItem.displayName,
            unitShortName: unitShortName || Object.keys(props.units)[0],
            productId: props.customProductItem && props.customProductItem.productId,
            specification: props.customProductItem && props.customProductItem.specification,
            supplier: props.customProductItem && props.customProductItem.supplier,
            volumeFactor: props.customProductItem && props.customProductItem.volumeFactor,
            weight: props.customProductItem && props.customProductItem.weight,
            recipeUnits: props.customProductItem && props.customProductItem.recipeUnits,
            successMessage: null,
            errorMessage: null,
            showSupplierAddCustomModal: false,
            showRecipeArea: false,
            addsupplier: null,
            suppliers: [{ value: '', text: translate("/shared/choose") }].concat(Object.keys(_this.props.suppliers).map(function (key) { return ({
                value: _this.props.suppliers[key],
                text: _this.props.suppliers[key],
                subText: null,
                disabled: false
            }); })),
        };
        return _this;
    }
    EditCustomProduct.prototype.isDecimal = function (input) {
        return input === '.' || input === ',';
    };
    EditCustomProduct.prototype.updatePrice = function (value) {
        var parsedValue = value;
        var update = true;
        if (typeof value === 'string') {
            var last = value.substr(value.length - 1);
            var first = value.charAt(0);
            if (this.isDecimal(last) || this.isDecimal(first)) {
                update = false;
            }
            else if ((value.indexOf(',') >= 0 || value.indexOf('.') >= 0) && last === '0') {
                update = false;
            }
            parsedValue = parseFloat(value.replace(',', '.'));
        }
        else if (typeof value === 'number') {
            parsedValue = value;
        }
        this.setState({
            proxyPriceValue: update ? parsedValue || 0 : value,
        });
    };
    EditCustomProduct.prototype.blurPrice = function () {
        var price = this.state.proxyPriceValue.toString();
        var priceStr = parseFloat(price.replace(',', '.')).toFixed(2);
        this.setState({
            proxyPriceValue: priceStr,
            price: parseFloat(priceStr),
        });
    };
    EditCustomProduct.prototype.updateWeight = function (weight) {
        this.setState({ weight: weight });
        return Promise.resolve(null);
    };
    EditCustomProduct.prototype.onUnitChange = function (unit) {
        if (unit === 'FRP') {
            this.setState({
                unitShortName: unit,
                numberOfSmallerUnits: 1,
            });
        }
        else if (unit === 'KG') {
            this.setState({
                unitShortName: unit,
                recipeUnits: 1,
                weight: null,
                volumeFactor: null,
            });
        }
        else {
            this.setState({ unitShortName: unit });
        }
    };
    EditCustomProduct.prototype.getSecondLevelDropdown = function (firstLevel) {
        var _this = this;
        onGetSecondLevelAction(firstLevel).then(function (secondLevels) {
            _this.setState({
                firstCategoryLevel: firstLevel,
                secondCategoryLevel: null,
                thirdCategoryLevel: null,
                secondLevels: secondLevels,
                thirdLevels: [],
            });
        });
    };
    EditCustomProduct.prototype.getThirdLevelDropdown = function (secondLevel) {
        var _this = this;
        onGetThirdLevelAction(this.state.firstCategoryLevel, secondLevel).then(function (thirdLevels) {
            _this.setState({
                secondCategoryLevel: secondLevel,
                thirdCategoryLevel: null,
                thirdLevels: thirdLevels,
            });
        });
    };
    EditCustomProduct.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        this.setState({ errorMessage: null, successMessage: null });
        var hasError = false;
        if ((this.state.productId || '').trim() === '' ||
            (this.state.displayName || '').trim() === '' ||
            (this.state.unitShortName === 'FRP' &&
                (!this.state.numberOfSmallerUnits || (this.state.numberOfSmallerUnits.toString() || '').trim() === '')) ||
            (this.state.area || '').trim() === '' ||
            (this.state.supplier || '').trim() === '') {
            hasError = true;
        }
        if (hasError) {
            this.props.setFeedback({ text: translate('/CustomProduct/Table/FeedbackMessageError'), type: 'error' });
            return;
        }
        this.feedbackButton.activate(new Promise(function (resolve, reject) {
            return _this.props
                .onSaveEditAction(_this.state, _this.state.id != null)
                .then(function (result) { return (result.success ? Promise.resolve(result) : Promise.reject(result)); })
                .then(function (result) {
                if (!_this.props.customProductItem) {
                    // Add new custom product
                    _this.setState({
                        price: 0,
                        proxyPriceValue: 0,
                        firstCategoryLevel: null,
                        secondCategoryLevel: null,
                        thirdCategoryLevel: null,
                        numberOfSmallerUnits: 1,
                        gtin: '',
                        unitShortName: Object.keys(_this.props.units)[0],
                        specification: '',
                        volumeFactor: null,
                        weight: null,
                        recipeUnits: null,
                        secondLevels: null,
                        thirdLevels: null,
                        productId: '',
                        displayName: '',
                        supplier: '',
                        successMessage: translate('/CustomProduct/Table/FeedbackMessage', _this.state.productId),
                    });
                    !!_this.props.onSaveComplete && _this.props.onSaveComplete(result.item.id, result.success);
                    _this.props.setFeedback({ text: _this.state.successMessage, type: 'success' });
                    return resolve(result);
                }
                else {
                    // Edit existent custom product
                    timeout(1000).then(function () {
                        return _this.props.setSaved(result.item.id);
                    }).then(function () {
                        _this.props.setFeedback({ text: result.message, type: 'success' });
                        return resolve(result);
                    });
                }
            })
                .catch(function (result) {
                _this.setState({
                    errorMessage: result.message ? result.message : translate('/CustomProduct/Table/ServerErrorSave'),
                });
                _this.props.setFeedback({ text: _this.state.errorMessage, type: 'error' });
                return reject(null);
            });
        }));
    };
    EditCustomProduct.prototype.toNumber = function (value) {
        return parseFloat((value || '').replace(',', '.'));
    };
    EditCustomProduct.prototype.isNullOrEmpty = function (value) {
        return value === null || value === '';
    };
    EditCustomProduct.prototype.isEmptyArray = function (value) {
        return value === null || typeof value === 'undefined' || value.length === 0;
    };
    EditCustomProduct.prototype.getFirstLevelCategories = function () {
        var firstLevels = __spread(this.props.firstLevels);
        if (!this.isNullOrEmpty(this.state.firstCategoryLevel) &&
            !this.props.firstLevels.includes(this.state.firstCategoryLevel)) {
            var firstLevelCategory = this.state.firstCategoryLevel;
            firstLevels = __spread(firstLevels, [firstLevelCategory]);
        }
        return firstLevels.map(function (key) { return ({ value: key, text: key }); });
    };
    EditCustomProduct.prototype.getSecondLevelCategories = function () {
        return !this.isEmptyArray(this.props.secondLevels) &&
            this.isEmptyArray(this.state.secondLevels) &&
            !this.isNullOrEmpty(this.state.firstCategoryLevel)
            ? this.props.secondLevels.map(function (key) { return ({ value: key, text: key }); })
            : !this.isEmptyArray(this.state.secondLevels)
                ? this.state.secondLevels.map(function (key) { return ({ value: key, text: key }); })
                : null;
    };
    EditCustomProduct.prototype.getThirdLevelCategories = function () {
        return !this.isEmptyArray(this.props.thirdLevels) &&
            this.isEmptyArray(this.state.thirdLevels) &&
            !this.isNullOrEmpty(this.state.secondCategoryLevel)
            ? this.props.thirdLevels.map(function (key) { return ({ value: key, text: key }); })
            : !this.isEmptyArray(this.state.thirdLevels)
                ? this.state.thirdLevels.map(function (key) { return ({ value: key, text: key }); })
                : null;
    };
    EditCustomProduct.prototype.addNewSupplier = function () {
        this.setState({
            addsupplier: null,
            supplier: this.state.addsupplier,
            suppliers: __spread(this.state.suppliers, [{ value: this.state.addsupplier, text: this.state.addsupplier, }]),
            showSupplierAddCustomModal: false
        });
    };
    EditCustomProduct.prototype.render = function () {
        var _this = this;
        var areas = [{ value: '', text: translate("/shared/choose") }].concat(Object.keys(this.props.areas).map(function (key) { return ({ value: key, text: _this.props.areas[key] }); }));
        var units = [{ value: '', text: translate("/shared/choose") }].concat(Object.keys(this.props.units).map(function (key) { return ({ value: key, text: _this.props.units[key] }); }));
        var firstLevelCategories = this.getFirstLevelCategories();
        var secondLevelCategories = this.getSecondLevelCategories();
        var thirdLevelCategories = this.getThirdLevelCategories();
        var maxCount = window.innerHeight > 800 ? 8 : 4;
        return (React.createElement(Form, { onSubmit: function (e) { return _this.onSubmit(e); }, appearance: "default" },
            React.createElement("div", { className: styles.row },
                React.createElement("p", null, "H\u00E4r kan du skapa en egen vara f\u00F6r att underl\u00E4tta din inventering eller f\u00F6r att anv\u00E4nda i recept.")),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: classnames(styles.mobile2of3) },
                    React.createElement("label", null,
                        translate('/CustomProduct/Table/Supplier'),
                        " *"),
                    " ",
                    React.createElement("br", null),
                    React.createElement(DropDown, { className: classnames(styles.fullWidth), options: this.state.suppliers, name: "supplier", onChange: function (supplier) { return _this.setState({ supplier: supplier }); }, value: this.state.supplier || '', required: true, requiredvalidationmessage: translate('/CustomProduct/Validation/SupplierRequired'), onClose: function () { !!_this.props.enableScroll && _this.props.enableScroll(); }, onOpen: function () { !!_this.props.disableScroll && _this.props.disableScroll(); }, childCount: Math.min(this.state.suppliers.length, maxCount) })),
                React.createElement("div", { className: classnames(styles.mobile1of3) },
                    React.createElement(Button, { onClick: function () { _this.setState({ showSupplierAddCustomModal: !_this.state.showSupplierAddCustomModal }); }, className: styles.button, appearance: 'toolbarbutton' },
                        this.props.isMobile ? translate('/CustomProduct/Table/SupplierAddCustomButtonMobile') : translate('/CustomProduct/Table/SupplierAddCustomButton'),
                        " +"),
                    React.createElement(InnerModal, { rootElementRef: document.body, childContainerClassname: styles.addSupplierWrapper, title: translate('/CustomProduct/Table/SupplierAddCustom'), isOpen: this.state.showSupplierAddCustomModal, onClose: function () { _this.setState({ showSupplierAddCustomModal: false }); } },
                        React.createElement("div", { className: styles.row },
                            React.createElement("span", null, translate('/CustomProduct/Table/AddSupplierText'))),
                        React.createElement("div", { className: classnames(styles.row, styles.stack) },
                            React.createElement(Input, { label: translate('/CustomProduct/Table/SupplierAddCustomInputLabel'), type: "text", required: true, name: "addSupplier", onChange: function (e) { return _this.setState({ addsupplier: e.target.value }); }, value: this.state.addsupplier || undefined, requiredValidationMessage: translate('/CustomProduct/Validation/SupplierRequired') })),
                        React.createElement(Button, { onClick: function () { !!_this.state.addsupplier && _this.addNewSupplier(); }, appearance: 'primary' }, translate('/Shared/Add'))))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement(Input, { label: translate('/CustomProduct/Table/ProductId'), type: "text", required: true, minLength: 1, maxLength: 255, value: this.state.productId || undefined, name: "productId", onChange: function (e) { return _this.setState({ productId: e.target.value }); }, requiredValidationMessage: translate('/CustomProduct/Validation/ProductIdRequired') }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement(Input, { label: translate('/CustomProduct/Table/DisplayName'), type: "text", required: true, minLength: 1, maxLength: 255, value: this.state.displayName || undefined, name: "displayName", onChange: function (e) { return _this.setState({ displayName: e.target.value }); }, requiredValidationMessage: translate('/CustomProduct/Validation/DisplayNameRequired') }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement("label", null,
                        translate('/CustomProduct/Table/EditUnitShortName'),
                        " *"),
                    " ",
                    React.createElement("br", null),
                    React.createElement(DropDown, { className: styles.fullWidth, name: "unitshortname", options: units, onChange: function (unit) { return _this.onUnitChange(unit); }, value: this.state.unitShortName || undefined, required: true, requiredvalidationmessage: translate('/CustomProduct/Validation/UnitRequired') }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: classnames(this.props.isMobile ? styles.divMobile : styles.divHalf, styles.quantityWrapper) },
                    React.createElement(Input, { label: translate('/CustomProduct/Table/Price'), type: "number", className: styles.spacing, value: this.state.proxyPriceValue.toString(), name: "price", min: 0, step: 0.01, onChange: function (e) { return _this.updatePrice(e.target.value); }, onBlur: function () { return _this.blurPrice(); } }))),
            this.state.unitShortName === 'FRP' && (React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement(Input, { label: translate('/CustomProduct/Table/NumberOfSmallerUnits'), type: "number", className: styles.spacing, value: this.state.numberOfSmallerUnits.toString(), name: "numberOfSmallerUnits", min: 1, max: 99, onChange: function (e) {
                            return _this.setState({ numberOfSmallerUnits: parseInt(e.target.value, 10) });
                        }, requiredValidationMessage: translate('/CustomProduct/Validation/NumberOfSmallerUnitsRequired') })))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement("label", { htmlFor: "specification" }, translate('/CustomProduct/Table/EditSpecification')),
                    React.createElement("small", null, translate('/CustomProduct/Table/ReportSpecificationText')),
                    React.createElement(Input, { type: "text", value: this.state.specification || undefined, name: "specification", onChange: function (e) { return _this.setState({ specification: e.target.value }); } }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement("label", { htmlFor: "gtin" }, translate('/CustomProduct/Table/Gtin')),
                    React.createElement("small", null, translate('/CustomProduct/Table/ReportGtinText')),
                    React.createElement(Input, { type: "text", value: this.state.gtin || undefined, name: "gtin", pattern: '^[0-9]*$', onChange: function (e) { return _this.setState({ gtin: e.target.value }); } }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement("label", null, translate('/CustomProduct/Table/Area')),
                    React.createElement("small", null, translate('/CustomProduct/Table/ReportInfoText')),
                    React.createElement(DropDown, { className: styles.fullWidth, options: areas, onChange: function (area) { return _this.setState({ area: area }); }, value: this.state.area || undefined, required: true, name: "area", requiredvalidationmessage: translate('/CustomProduct/Validation/AreaRequired') }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                    React.createElement("label", null, translate('/CustomProduct/Table/Categories')),
                    React.createElement("small", null, translate('/CustomProduct/Table/ReportInfoText')),
                    React.createElement("div", { className: styles.row },
                        React.createElement(DropDown, { className: styles.fullWidth, options: firstLevelCategories, onChange: function (firstLevel) { return _this.getSecondLevelDropdown(firstLevel); }, value: this.state.firstCategoryLevel || '' })),
                    secondLevelCategories && !this.isNullOrEmpty(this.state.firstCategoryLevel) ? (React.createElement("div", { className: styles.row },
                        React.createElement(DropDown, { className: styles.fullWidth, options: secondLevelCategories, onChange: function (secondLevel) { return _this.getThirdLevelDropdown(secondLevel); }, value: this.state.secondCategoryLevel || '' }))) : null,
                    thirdLevelCategories && !this.isNullOrEmpty(this.state.secondCategoryLevel) ? (React.createElement("div", { className: styles.row },
                        React.createElement(DropDown, { className: styles.fullWidth, options: thirdLevelCategories, onChange: function (thirdLevel) { return _this.setState({ thirdCategoryLevel: thirdLevel }); }, value: this.state.thirdCategoryLevel || '' }))) : null)),
            React.createElement("div", { className: styles.row },
                React.createElement(Button, { appearance: 'bare', onClick: function () { _this.setState({ showRecipeArea: !_this.state.showRecipeArea }); } },
                    React.createElement("strong", null, !!this.state.showRecipeArea ? "- " + translate('/CustomProduct/Table/HideRecipeAreaButton') : "+  " + translate('/CustomProduct/Table/ShowRecipeAreaButton')))),
            !!this.state.showRecipeArea && (React.createElement("div", { className: classnames(styles.row, styles.stack) },
                React.createElement("div", { className: styles.row },
                    React.createElement("span", null, translate('/CustomProduct/Table/RecipeInfoText'))),
                this.state.unitShortName === 'KG' && (React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: classnames(this.props.isMobile ? styles.divMobile : styles.divHalf, styles.quantityWrapper) },
                        React.createElement(Input, { label: translate('/CustomProduct/Table/VolumeFactor'), type: "number", step: 0.01, className: styles.fullWidth, value: this.state.volumeFactor != null ? this.state.volumeFactor.toString() : undefined, name: "volumeFactor", onChange: function (e) { return _this.setState({ volumeFactor: _this.toNumber(e.target.value) }); } })))),
                this.state.unitShortName !== 'KG' && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.row },
                        React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                            React.createElement("label", { htmlFor: "weight" }, translate('/CustomProduct/Table/Weight')),
                            React.createElement(Quantity, { className: styles.fullWidth, value: this.state.weight || 0, update: function (value) { return _this.updateWeight(value); }, minQuantity: 0, step: 0.1, hideButtons: true }))),
                    React.createElement("div", { className: styles.row },
                        React.createElement("div", { className: this.props.isMobile ? styles.divMobile : styles.divHalf },
                            React.createElement(Input, { label: translate('/CustomProduct/Table/RecipeUnits'), type: "number", className: styles.spacing, value: this.state.recipeUnits != null ? this.state.recipeUnits.toString() : undefined, name: "recipeUnits", onChange: function (e) { return _this.setState({ recipeUnits: parseInt(e.target.value, 10) }); } }))))))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", { className: styles.feedbackBottomWrap },
                    React.createElement("div", null,
                        React.createElement(FeedbackButton, { type: "submit", ref: function (c) { return (_this.feedbackButton = c); } }, this.props.customProductItem
                            ? translate('/CustomProduct/Table/EditButton')
                            : translate('/CustomProduct/Table/AddButton')))))));
    };
    return EditCustomProduct;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
    onSaveEditAction: function (data, edit) {
        return dispatch(onSaveEditAction(data, edit));
    },
}); })(EditCustomProduct);
