var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
import { SMALL } from 'Shared/breakpoints';
import * as styles from '../base.scss';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, displayName = _d.displayName, isLoading = _d.isLoading, isLoaded = _d.isLoaded, getRecommendationDetails = _d.getRecommendationDetails, isOpen = _d.isOpen, onHeaderClick = _d.onHeaderClick, currentBreakpoint = _d.currentBreakpoint;
        var isMobile = currentBreakpoint < SMALL;
        var showToggle = (React.createElement("div", { className: styles.showToggle }, isOpen ?
            React.createElement(React.Fragment, null,
                React.createElement("span", null, translate('/Shared/Close')),
                React.createElement(MyMenigoIcon, { name: "minus" }))
            :
                React.createElement(React.Fragment, null,
                    React.createElement("span", null, translate('/Shared/Show')),
                    React.createElement(MyMenigoIcon, { name: "plus" }))));
        return isMobile ? (React.createElement("div", { className: classnames(styles.headerRecommendations, (_a = {},
                _a[styles.isOpen] = isOpen,
                _a)), onClick: function () {
                onHeaderClick();
                if (!isLoaded && !isLoading) {
                    getRecommendationDetails();
                }
            }, ref: function (el) { return (_this.element = el); } },
            React.createElement("div", { className: styles.displayName }, displayName),
            showToggle)) : (React.createElement("div", { className: classnames(styles.headerRecommendations, (_b = {},
                _b[styles.isOpen] = isOpen,
                _b)), onClick: function () {
                onHeaderClick();
                if (!isLoaded && !isLoading) {
                    getRecommendationDetails();
                }
            }, ref: function (el) { return (_this.element = el); } },
            React.createElement("div", { className: classnames(styles.spec, (_c = {}, _c[styles.isLoading] = isLoading, _c)), onClick: function (e) { return e.stopPropagation(); } }, isLoading ? React.createElement(Spinner, { className: styles.spinner }) : React.createElement("div", { className: styles.placeholder })),
            React.createElement("div", { className: styles.info },
                React.createElement("span", { className: styles.displayName }, displayName)),
            showToggle));
    };
    return Header;
}(React.PureComponent));
export default Header;
