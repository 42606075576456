var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { postJson } from 'Shared/server';
import { currentUrl } from 'Shared/url';
import { updateAppShellData } from '../../AppShell/action-creators';
import cookieHandler from '../../../Helpers/cookieHandler';
import { replaceState } from 'Shared/history';
export var UPDATE_USERSETTINGS = 'UPDATE_USERSETTINGS';
export function updateUserSettings(partialSettings, shouldUpdateAppShell) {
    if (shouldUpdateAppShell === void 0) { shouldUpdateAppShell = false; }
    return function (dispatch, getState) {
        var settings = __assign({}, getState().currentUser.userSettings, partialSettings);
        dispatch({
            type: UPDATE_USERSETTINGS,
            payload: settings,
        });
        cookieHandler.setCookie(settings.userSettingsCookieName, JSON.stringify(settings), 365);
        var url = currentUrl().path;
        return postJson('/UserSettings/Update', { settings: settings, url: url })
            .then(function (result) {
            if (partialSettings.selectedLanguageCode) {
                replaceState(result || url);
            }
            if (shouldUpdateAppShell) {
                dispatch(updateAppShellData());
            }
        })
            .catch(function (e) {
            console.error(e);
        });
    };
}
