var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import { Input, Checkbox } from 'Shared/Fields';
import classnames from 'classnames';
import { SMALL } from 'Shared/breakpoints';
import { login as loginAction, resetPassword as resetPasswordAction } from '../../action-creators';
import * as styles from './base.scss';
var LoginBox = /** @class */ (function (_super) {
    __extends(LoginBox, _super);
    function LoginBox(props) {
        var _this = _super.call(this, props) || this;
        var presetcustomerId = '';
        var presetPassword = '';
        var presetCheckbox = false;
        if (props.loginModel) {
            presetcustomerId = props.loginModel.customerId;
            presetPassword = props.loginModel.password;
            presetCheckbox = props.loginModel.rememberMe;
        }
        _this.state = {
            customerId: presetcustomerId,
            password: presetPassword,
            rememberMe: presetCheckbox,
            loginMessage: null,
            resetPassword: false,
            messageType: null,
        };
        return _this;
    }
    LoginBox.prototype.doLogin = function () {
        var _this = this;
        this.setState({ loginMessage: null, messageType: null });
        var customerId = this.state.customerId.trim();
        if (!customerId) {
            this.customerIdElement.focus();
            return;
        }
        if (this.state.resetPassword) {
            this.feedbackButton.activate(new Promise(function (resolve, reject) {
                _this.props
                    .resetPasswordAction(customerId)
                    .then(function (result) {
                    if (!result.success) {
                        reject();
                        _this.setState({ loginMessage: result.message, messageType: 'fail' });
                    }
                    else {
                        resolve();
                        _this.setState({
                            loginMessage: translate('/Account/Login/ResetPasswordSuccess'),
                            messageType: 'success',
                        });
                    }
                })
                    .catch(function (e) {
                    reject();
                    _this.setState({
                        loginMessage: translate('/Account/Login/ForgotPasswordServerError'),
                        messageType: 'fail',
                    });
                });
            }));
        }
        else {
            var password = this.state.password.trim();
            if (!password) {
                this.passwordElement.focus();
                return;
            }
            this.feedbackButton.activate(this.props
                .loginAction({ customerId: customerId, password: password, rememberMe: this.state.rememberMe })
                .then(function () {
                _this.props.close();
            })
                .catch(function (e) {
                _this.setState({ loginMessage: !!e.message ? e.message : translate('/Account/Login/ServerError'), messageType: 'fail' });
                return Promise.reject(null);
            }));
        }
    };
    LoginBox.prototype.openPolicyLink = function (url) {
        if (url) {
            window.open(url, '_system');
        }
    };
    LoginBox.prototype.onSubmit = function (e) {
        e.preventDefault();
        this.doLogin();
    };
    LoginBox.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("form", { onSubmit: function (e) { return _this.onSubmit(e); }, className: styles.loginForm },
                this.state.loginMessage && this.state.messageType && (React.createElement("div", { className: classnames((_a = {},
                        _a[styles.loginMessageFail] = this.state.messageType === 'fail',
                        _a[styles.loginMessageOk] = this.state.messageType === 'success',
                        _a)) }, this.state.loginMessage || this.props.message)),
                this.props.message && React.createElement("div", { className: styles.messageInfo }, this.props.message),
                React.createElement(Input, { type: "text", name: "username", autoFocus: this.props.currentBreakpoint <= SMALL ? false : this.props.isCurrent, required: true, value: this.state.customerId, ref: function (el) { return (_this.customerIdElement = el); }, placeholder: translate('/Account/Login/EmailorIDFieldTitle'), onChange: function (e) {
                        return _this.setState({ customerId: e.target.value, loginMessage: null, messageType: null });
                    } }),
                !this.state.resetPassword && (React.createElement(Input, { type: "password", name: "password", required: true, value: this.state.password, ref: function (el) { return (_this.passwordElement = el); }, placeholder: translate('/Account/Login/PasswordFieldTitle'), onChange: function (e) {
                        return _this.setState({ password: e.target.value, loginMessage: null, messageType: null });
                    } })),
                React.createElement("div", { className: styles.belowInputsArea },
                    !IS_APP && !this.state.resetPassword && (React.createElement(Checkbox, { checked: this.state.rememberMe, onChange: function (e) {
                            return _this.setState({ rememberMe: e.target.checked, loginMessage: null });
                        } }, translate('/Account/Login/RememberMe'))),
                    !IS_APP && (React.createElement("div", null,
                        React.createElement("a", { href: "javascript:void(0)", onClick: function (e) { return _this.setState({ resetPassword: !_this.state.resetPassword, loginMessage: null }); } }, this.state.resetPassword
                            ? translate('/Account/Login/BackToLogin')
                            : translate('/Account/Login/ForgotPasswordQuestion'))))),
                React.createElement("div", { className: styles.belowInputsArea },
                    React.createElement(FeedbackButton, { ref: function (c) { return (_this.feedbackButton = c); }, type: "submit", appearance: "secondary", className: "ui-test-submit-button" }, !this.state.resetPassword
                        ? translate('/Account/Login/Login')
                        : translate('/Account/Login/ForgotPasswordButton')),
                    IS_APP && (React.createElement("a", { href: "javascript:void(0)", onClick: function () { return _this.openPolicyLink('http://www.menigo.se/for-kunder/integritetspolicy/'); } }, translate('/App/PolicyLink'))))),
            React.createElement("div", { className: styles.footer },
                React.createElement("div", { className: styles.footerInner },
                    React.createElement("div", { className: styles.signUpText }, translate('/Account/Registration/NotCustomerYet')),
                    React.createElement("div", null,
                        React.createElement("a", { href: this.props.signUpUrl, className: styles.signUpLink, onClick: function () { return _this.props.close(); } }, translate('/Account/Registration/SignUpForMenigo')))))));
    };
    return LoginBox;
}(React.Component));
export default connect(function (state) { return ({
    message: state.accountBox.message,
    redirectToAfterLogin: state.accountBox.redirectToAfterLogin,
    currentBreakpoint: state.currentBreakpoint,
    loginModel: state.appShellData.header.loginModel,
}); }, function (dispatch) { return ({
    loginAction: function (details) { return dispatch(loginAction(details)); },
    resetPasswordAction: function (customerId) { return dispatch(resetPasswordAction(customerId)); },
}); })(LoginBox);
