var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import debounce from 'lodash.debounce';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import { isBrowser } from 'Shared/device-type';
import * as styles from './base.scss';
var LEFT = 'left';
var CENTER = 'center';
var RIGHT = 'right';
var TOP = 'top';
var BOTTOM = 'bottom';
var DEFAULT_TRANSLATEX = 0;
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip(props) {
        var _this = _super.call(this, props) || this;
        _this.el = null;
        _this.state = {
            translateX: DEFAULT_TRANSLATEX,
            flip: false,
        };
        _this.debouncedReadjust = debounce(function () { return _this.readjust(); }, 200);
        _this.keyboardListener = function (e) { return e.which === 27 && _this.props.close(); };
        return _this;
    }
    Tooltip.prototype.componentDidMount = function () {
        this.adjust();
        window.addEventListener('resize', this.debouncedReadjust);
        window.addEventListener('keydown', this.keyboardListener);
    };
    Tooltip.prototype.componentWillUnmount = function () {
        this.debouncedReadjust.cancel();
        window.removeEventListener('resize', this.debouncedReadjust);
        window.removeEventListener('keydown', this.keyboardListener);
    };
    Tooltip.prototype.adjust = function () {
        if (!isBrowser() || !this.el) {
            return;
        }
        var elRect = this.el.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();
        // Adjust horizontally if element is outside the window sides
        if (elRect.right > bodyRect.right) {
            this.setState({
                translateX: bodyRect.right - elRect.right,
            });
        }
        else if (elRect.left < bodyRect.left) {
            this.setState({
                translateX: -elRect.left + bodyRect.left,
            });
        }
        // Flip vertical alignment from `top` to `bottom` if element is above the window
        if (this.props.vertical === TOP && elRect.top < bodyRect.top) {
            this.setState({
                flip: true,
            });
        }
    };
    Tooltip.prototype.readjust = function () {
        var _this = this;
        if (this.state.translateX !== DEFAULT_TRANSLATEX) {
            this.setState({
                translateX: DEFAULT_TRANSLATEX,
            }, function () { return _this.adjust(); });
        }
        else {
            this.adjust();
        }
    };
    Tooltip.prototype.transform = function () {
        var functions = [];
        if (this.props.horizontal === CENTER) {
            functions.push('translateX(-50%)');
        }
        if (this.state.translateX !== DEFAULT_TRANSLATEX) {
            functions.push("translateX(" + this.state.translateX + "px)");
        }
        return functions.join(' ');
    };
    Tooltip.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var horizontal = this.props.horizontal;
        var vertical = this.props.vertical;
        var verticalClassNames = this.state.flip
            ? (_a = {},
                _a[styles.bottom] = true,
                _a[styles.vLeft] = vertical === LEFT,
                _a) : (_b = {},
            _b[styles.top] = vertical === TOP,
            _b[styles.bottom] = vertical === BOTTOM,
            _b[styles.vLeft] = vertical === LEFT,
            _b);
        return (React.createElement("div", { className: classnames(styles.base, verticalClassNames), onClick: function (e) { return _this.props.closeOnClick && _this.props.close && _this.props.close(e); } },
            React.createElement("div", { className: classnames(styles.content, Object.assign((_c = {},
                    _c[styles.left] = horizontal === LEFT,
                    _c[styles.center] = horizontal === CENTER,
                    _c[styles.right] = horizontal === RIGHT,
                    _c), verticalClassNames)), ref: function (el) { return (_this.el = el); } },
                this.props.children,
                this.props.close && this.props.closeButton && (React.createElement("button", { className: styles.close, onClick: function (e) { return _this.props.close(e); } },
                    React.createElement(Icon, { name: "close" }))))));
    };
    Tooltip.defaultProps = {
        horizontal: CENTER,
        vertical: TOP,
    };
    return Tooltip;
}(React.Component));
export default Tooltip;
