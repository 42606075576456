var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Part } from "Shared/PageLayout";
import * as styles from '../../base.scss';
import DetailedProductList from "./DetailedProductList";
import DetailedProductListHeader from "./DetailedProductListHeader";
import { compareValues } from "./../action-creators";
import RecycleItems from "./RecycleItems";
var DeliveryDetails = /** @class */ (function (_super) {
    __extends(DeliveryDetails, _super);
    function DeliveryDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.sortProducts = function (sortField, secondarySortField) {
            _this.setState({
                sortBy: sortField,
                sortAscending: !_this.state.sortAscending,
                items: _this.props.items.sort(compareValues(sortField, secondarySortField, _this.state.sortAscending))
            });
        };
        _this.state = {
            sortBy: _this.props.sortBy,
            items: _this.props.items.sort(compareValues(_this.props.sortBy, "itemDescription", true)),
            sortAscending: true
        };
        return _this;
    }
    DeliveryDetails.prototype.componentDidMount = function () {
        this.setState({
            sortAscending: !this.state.sortAscending,
        });
    };
    DeliveryDetails.prototype.render = function () {
        return (React.createElement(Part, { className: styles.Products__Outer },
            React.createElement(DetailedProductListHeader, { sortField: this.state.sortBy, sortAscending: this.state.sortAscending, showDeliverdColumn: this.props.items.some(function (x) { return !!x.deliveredQuantity; }), setSortedField: this.sortProducts, listType: this.props.listType }),
            React.createElement(DetailedProductList, { listType: this.props.listType, productItems: this.state.items }),
            !!this.props.recycleItems && this.props.recycleItems.length > 0 && React.createElement(RecycleItems, { recycleItems: this.props.recycleItems })));
    };
    return DeliveryDetails;
}(React.Component));
export default DeliveryDetails;
