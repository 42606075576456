var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ContentCard from 'Shared/ContentCard';
import { onView, onClick } from 'Shared/ContentCard/DataLayer';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import ViewTracker from '../ViewObserver/ViewTracker';
var filterCards = function (card) { return card.appearance !== 'large'; };
export default (function (_a) {
    var cards = _a.cards, title = _a.title;
    return (React.createElement("div", { className: styles.wrapper },
        title && React.createElement("div", { className: styles.h3 }, title),
        React.createElement("div", { className: styles.flexwrapper }, cards && cards.length > 0 ? (cards.filter(filterCards).map(function (data, i) { return React.createElement(ViewTracker, { key: 'contentCardRowViewTracker' + i, onView: function (e) { return onView(e, data, i); } },
            React.createElement(ContentCard, __assign({}, data, { key: i, onClick: function (e) { return onClick(e, data, i); } }))); })) : (React.createElement("div", null, translate('/Errors/NoContent'))))));
});
