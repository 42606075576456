import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import * as styles from './printOptions.scss';
var PrintActions = function (props) {
    return props.visible && (React.createElement("div", { className: styles.buttonWrapper },
        React.createElement(Button, { disabled: !(props.listsSelected), className: styles.printButton, onClick: function (e) { document.getElementById('Print').submit(); } }, translate('/Account/ProductList/PrintOptions/Print')),
        React.createElement("form", { id: 'Print', method: 'post', target: '_blank', action: props.reportPageUrl },
            React.createElement("input", { type: 'hidden', name: 'reportType', value: props.reportType }),
            React.createElement("input", { type: 'hidden', name: 'listids', value: JSON.stringify(props.selectedLists) })),
        React.createElement(Button, { disabled: !(props.listsSelected), className: styles.printButton, onClick: function (e) { document.getElementById('Excel').submit(); } }, translate('/Account/ProductList/PrintOptions/Excel')),
        React.createElement("form", { id: 'Excel', method: 'post', target: '_blank', action: props.reportPageUrl + "/Excel" },
            React.createElement("input", { type: 'hidden', name: 'reportType', value: props.reportType }),
            React.createElement("input", { type: 'hidden', name: 'listids', value: JSON.stringify(props.selectedLists) }))));
};
export default connect(function (_a) {
    var currentPage = _a.currentPage, appShellData = _a.appShellData;
    return {
        listsSelected: currentPage.lists
            ? currentPage.lists.some(function (list) { return list.selected; })
            : false,
        selectedLists: currentPage.lists ? currentPage.lists.filter(function (x) { return x.selected === true; }).map(function (a) {
            return { id: a.id, sortBy: a.sortBy };
        }) : [],
        reportPageUrl: appShellData.siteSettings.pantryReportPage
    };
})(PrintActions);
