import React from 'react';
import * as base from './base.scss';
import Icon from 'Shared/Icon';
var ValidationFeedback = function (props) {
    return (!!props.visible && !!props.text &&
        React.createElement("div", { className: base.validationFeeback },
            React.createElement(Icon, { name: "exclamation-mark" }),
            React.createElement("span", null, props.text)));
};
export default ValidationFeedback;
