var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import Main from 'Shared/PageLayout';
import { LARGE } from 'Shared/breakpoints';
import * as styles from './base.scss';
var ArticlePage = /** @class */ (function (_super) {
    __extends(ArticlePage, _super);
    function ArticlePage(props) {
        return _super.call(this, props) || this;
    }
    ArticlePage.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.hero },
                !!propertyValueFor(this.props.page.hero) && (React.createElement("div", { className: styles.aspectRatio },
                    React.createElement("img", { src: propertyValueFor(this.props.page.hero) }))),
                React.createElement(Main, null,
                    React.createElement("div", { className: styles.header },
                        React.createElement(PropertyFor, { tagName: "h1", property: this.props.page.name, className: styles.inspiration })))),
            React.createElement("div", { className: styles.content },
                React.createElement(Main, null,
                    React.createElement("div", { className: styles.mainWrapper },
                        React.createElement("div", { className: styles.leftColumn },
                            this.props.currentBreakpoint < LARGE && (React.createElement("div", { className: styles.preambleColumn },
                                React.createElement(PropertyFor, { property: this.props.page.preamble, className: styles.preamble }))),
                            React.createElement("div", { className: styles.byLineColumn },
                                React.createElement(PropertyFor, { property: this.props.page.articleType, className: styles.preamble }),
                                React.createElement("div", { className: styles.byLine },
                                    React.createElement(PropertyFor, { property: this.props.page.byLine, className: styles.paragraph }),
                                    React.createElement("div", { className: styles.paragraph }, this.props.date)))),
                        React.createElement("div", { className: styles.centerColumn },
                            this.props.currentBreakpoint >= LARGE && (React.createElement("div", { className: styles.preambleDesktop },
                                React.createElement(PropertyFor, { property: this.props.page.preamble, className: styles.preamble }))),
                            React.createElement(PropertyFor, { tagName: "h2", property: this.props.page.mainBodyHeader, className: styles.inspiration }),
                            React.createElement(PropertyFor, { property: this.props.page.mainBody, className: styles.paragraph }),
                            React.createElement("div", { className: styles.mainContent },
                                React.createElement(PropertyFor, { property: this.props.page.mainContent }))),
                        React.createElement("div", { className: styles.rightColumn },
                            React.createElement("div", null,
                                React.createElement(PropertyFor, { property: this.props.page.sideBarBody })),
                            React.createElement("div", null,
                                React.createElement(PropertyFor, { property: this.props.page.sideBarContent }))))))));
    };
    return ArticlePage;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ArticlePage);
