var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import resolveComponent from 'Shared/resolve-component';
import classnames from 'classnames';
import editorAttributesFor from 'Shared/editor-attributes-for';
import * as styles from './content-area.scss';
export function contentAreaItems(propertyName, items) {
    return (items || [])
        .filter(function (i) { return !!i; })
        .map(function (item, i) {
        var Component = resolveComponent(item.componentName);
        var itemOpts = window.IS_IN_EDIT_MODE && propertyName
            ? {
                'data-epi-block-id': item.$idForOnPageEditing,
                'data-epi-block-info': '{&quot;missingrenderer&quot;:false}',
            }
            : {};
        return (React.createElement("div", __assign({ key: i }, itemOpts),
            React.createElement(Component, __assign({}, item))));
    });
}
var ContentArea = function (props) {
    return React.createElement(props.tagName || 'div', editorAttributesFor(props.propertyName, classnames(props.className, styles.base)), contentAreaItems(props.propertyName, props.items));
};
ContentArea.supportsPropertyForIntegration = true;
export default ContentArea;
