var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { Main } from 'Shared/PageLayout';
import { SMALL } from 'Shared/breakpoints';
import ContentArea from 'Shared/Partials/PropertyTypes/ContentArea';
import classnames from 'classnames';
;
import * as styles from './base.scss';
var SocialMediaLinks = function (props) {
    return (React.createElement("ul", { className: styles.socialMediaLinks },
        React.createElement("li", null, props.footer.facebookIcon && (React.createElement("a", { href: props.footer.facebookUrl, target: "_blank" },
            React.createElement("img", { src: props.footer.facebookIcon })))),
        React.createElement("li", null, props.footer.instagramIcon && (React.createElement("a", { href: props.footer.instagramUrl, target: "_blank" },
            React.createElement("img", { src: props.footer.instagramIcon })))),
        React.createElement("li", null, props.footer.twitterIcon && (React.createElement("a", { href: props.footer.twitterUrl, target: "_blank" },
            React.createElement("img", { src: props.footer.twitterIcon })))),
        React.createElement("li", null, props.footer.youtubeIcon && (React.createElement("a", { href: props.footer.youTubeUrl, target: "_blank" },
            React.createElement("img", { src: props.footer.youtubeIcon }))))));
};
var hasSocialMediaBlocks = function (props) {
    var socialMediaBlocks = !!props.footer.socialMediaArea && props.footer.socialMediaArea;
    var hasSocialMediaBlocks = !!socialMediaBlocks && socialMediaBlocks.items.length ? true : false;
    return hasSocialMediaBlocks;
};
var AboveFooter = memo(function (props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(styles.lightBackground) },
            React.createElement(Main, { className: classnames(styles.lightBackground, styles.fixPadding) },
                React.createElement(ContentArea, __assign({}, props.footer.topFooterLinksArea, { className: styles.aboveFooterLinks })),
                hasSocialMediaBlocks(props) ? React.createElement(ContentArea, __assign({}, props.footer.socialMediaArea, { className: styles.socialMediaLinks })) : React.createElement(SocialMediaLinks, __assign({}, props)),
                React.createElement(ContentArea, __assign({}, props.footer.newsletterFormArea, { className: styles.removeMargin })))),
        React.createElement("div", { className: styles.footerLinksContainer },
            React.createElement(Main, { className: classnames(styles.lightBackground, styles.footerLinks, styles.removeMargin, (_a = {}, _a[styles.fixPadding] = props.currentBreakpoint > SMALL, _a)) },
                React.createElement(ContentArea, __assign({}, props.footer.footerNavigationLinksArea, { className: styles.removeMargin }))))));
});
export default AboveFooter;
