import * as store from '../App/app-storage';
var storeName = 'ips';
export function insert(mode, obj) {
    return get(mode).then(function (items) {
        set(mode, items.findIndex(function (item) { return obj.id === item.id; }) === -1
            ? items.concat([obj])
            : items.map(function (item) { return (obj.id === item.id ? obj : item); }));
    });
}
export function update(mode, obj) {
    return get(mode).then(function (items) { return set(mode, items.map(function (item) { return (obj.id === item.id ? obj : item); })); });
}
export function remove(mode, key) {
    return get(mode).then(function (items) { return set(mode, items.filter(function (item) { return key !== item.id; })); });
}
export function get(mode) {
    return store.get(storeName, mode);
}
export function set(mode, items) {
    return store.set(storeName, mode, items);
}
export function deleteOrder(mode) {
    return store.remove(storeName, mode);
}
