var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import flatten from '../flatten-appearance';
import * as styles from './base.scss';
function clone(entry, element) {
    return React.cloneElement(element, {
        className: classnames(element.props.className, styles.block, styles[entry]),
    });
}
export default function BoxLayout(props) {
    var blockIndex = 0;
    var columnIndex = 0;
    var children = React.Children.toArray(props.children);
    var block = function (entry) {
        if (blockIndex in children) {
            var element = children[blockIndex];
            if (React.isValidElement(element)) {
                blockIndex++;
                return clone(entry, element);
            }
        }
        else if (process.env.NODE_ENV !== 'production') {
            console.warn("The layout entry `" + entry + "` at index " + blockIndex + " didn't have any related child element.");
        }
    };
    var divProps = Object.assign({}, props);
    delete divProps.appearance;
    delete divProps.layout;
    var rowNode = (React.createElement("div", __assign({}, divProps, { className: classnames(props.className, styles.row, flatten(props.appearance).map(function (appearance) { return styles[appearance]; })) }), !!children &&
        props.layout.map(function (row) {
            if (typeof row === 'string') {
                return block(row);
            }
            else if (typeof row === 'object') {
                // Layout cell is divided in columns
                var columnNode = (React.createElement("div", { key: columnIndex, className: classnames(styles.column, styles[row[0]]) }, row[1].map(function (column) { return block(column); })));
                columnIndex++;
                return columnNode;
            }
        })));
    if (process.env.NODE_ENV !== 'production') {
        var childrenLength = (children || []).length;
        if (childrenLength < blockIndex) {
            console.warn("%i layout entries %O didn't have any related child elements %O and was therefore ignored.", blockIndex - childrenLength, props.layout, props.children);
        }
        else if (childrenLength > blockIndex) {
            console.warn("%i of %i child elements %O didn't have any related layout entries %O and was therefore ignored.", childrenLength - blockIndex, childrenLength, props.children, props.layout);
        }
    }
    return rowNode;
}
/**
 * TODO: If it's an issue that the last block in a narrow column wrapper with an
 * odd number of blocks grows to full width and the immediately preceded block to
 * the column wrapper also grows to full width in small windows, we need to handle
 * so the component renders all blocks as row blocks and give column blocks the
 * ratio from the column wrapper. When the breakpoint for SMALL kicks in, we start
 * wrapping column blocks as we do now.
 */
