var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import * as styles from './base.scss';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import Icon from 'Shared/Icon';
import Button from 'Shared/Button';
import Modal from 'Shared/Modal';
import { translate } from 'Shared/translate';
import EmvProductDetail from '../EmvProductDetail/index';
import Spinner from 'Shared/Spinner';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
import MyMenigoIcon from './../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var EmvProductCard = function (_a) {
    var product = _a.product, addEmvItemToCart = _a.addEmvItemToCart, getProductDetails = _a.getProductDetails, equivalentEmvProductQuantity = _a.equivalentEmvProductQuantity, emvProductIsAddedToCart = _a.emvProductIsAddedToCart;
    var _b = __read(useState(false), 2), itemAdded = _b[0], setItemAdded = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setLoading = _c[1];
    var _d = __read(useState(false), 2), productDetailModal = _d[0], setProductDetailModal = _d[1];
    var _e = __read(useState(), 2), productDetails = _e[0], setProductDetails = _e[1];
    var addItem = function () {
        if (itemAdded === false && emvProductIsAddedToCart === false) {
            addEmvItemToCart(product.selectedVariant.code, product.selectedVariant.unitShortName, equivalentEmvProductQuantity)
                .then(function () {
                setItemAdded(true);
            })
                .catch();
        }
    };
    var emvPriceDifferenceExist = product.selectedVariant.emvPriceDifference ? true : false;
    return (React.createElement("div", { className: styles.card },
        React.createElement("div", { className: styles.emvTitleWrapper, "data-ab": "A" },
            React.createElement("h3", { className: styles.emvTitle }, translate('/Checkout/EMVHeaderTitle')),
            React.createElement("div", null,
                React.createElement(ToolTip, { persistent: false, header: translate('/Checkout/EMVHeaderTitle'), text: translate('/Checkout/HelpTextEmv') },
                    React.createElement(MyMenigoIcon, { name: "help" })))),
        React.createElement("div", { className: styles.cardInner },
            React.createElement("div", { className: styles.imageWrap },
                React.createElement(ProductLink, { ticket: product.ticket, linkUrl: '', code: product.selectedVariant && product.selectedVariant.code },
                    React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.content.displayName, size: "tiny" }))),
            React.createElement("div", { className: styles.emvDetailWrapper },
                React.createElement("div", { className: styles.detailsWrap },
                    React.createElement("div", { className: styles.productTitleWrap },
                        React.createElement(ProductTitle, { linkUrl: '', ticket: product.ticket, displayName: product.content.displayName }),
                        product.url && (React.createElement(React.Fragment, null, isLoading ? (React.createElement(Spinner, { className: styles.spinner })) : (React.createElement("div", { className: styles.newTabIcon, onClick: function () {
                                setLoading(true);
                                getProductDetails(product.url.toString())
                                    .then(function (r) { return setProductDetails(r); })
                                    .then(function () { return setProductDetailModal(true); })
                                    .then(function () { return setLoading(false); });
                            } },
                            React.createElement(Icon, { name: "new-tab" })))))),
                    React.createElement("div", null,
                        React.createElement(ProductDetails, { code: product.content.code, linkUrl: '', ticket: product.ticket, trademark: product.trademark }))),
                React.createElement("div", { className: styles.unitAndPriceWrap },
                    React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: product.selectedVariant, productArea: product.area, discontinuedOrBackordered: false, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign, hideType: true, isEmvProduct: true }),
                    React.createElement("div", { className: styles.priceWrap },
                        React.createElement(ProductPrice, { selectedVariant: product.selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: true }))))),
        React.createElement("div", { className: styles.bottomRowWrapper },
            React.createElement("span", { className: emvPriceDifferenceExist ? styles.totalSavingsText : styles.unableToCalculateSavingsText }, emvPriceDifferenceExist
                ? translate('/Checkout/TotalSavings', product.selectedVariant.emvPriceDifference)
                : translate('/Checkout/UnableToCalculateSavings')),
            React.createElement("div", { className: styles.addButtonWrapper },
                React.createElement(Button, { className: itemAdded || emvProductIsAddedToCart ? styles.inactive : styles.base, onClick: function () { return addItem(); } },
                    React.createElement("span", { className: styles.text }, itemAdded || emvProductIsAddedToCart ? translate('/Checkout/Added') : translate('/Checkout/Add'))))),
        React.createElement(Modal, { isOpen: productDetailModal, onClose: function () { return setProductDetailModal(false); } },
            React.createElement(EmvProductDetail, { productViewModel: productDetails, selectedVariant: product.selectedVariant }))));
};
export default EmvProductCard;
