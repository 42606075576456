var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { translate } from 'Shared/translate';
import * as styles from './SlaveList/base.scss';
export default function ConfirmRemoveSlave(_a) {
    var single = _a.single, customerId = _a.customerId, slaves = _a.slaves, remove = _a.remove;
    var _b = __read(useState(false), 2), isOpen = _b[0], setOpen = _b[1];
    return (React.createElement(React.Fragment, null,
        single ? (React.createElement(Button, { appearance: "clear", onClick: function () { return setOpen(true); } },
            React.createElement(Icon, { name: "remove" }))) : (React.createElement(Button, { className: styles.removeAll, disabled: slaves.length < 1, appearance: "primary", onClick: function () { return setOpen(true); } }, translate('/Admin/MasterCustomers/Remove'))),
        React.createElement(Modal, { isOpen: isOpen, onClose: function () { return setOpen(false); }, title: translate('/Shared/Delete'), type: ModalType.Warning },
            React.createElement("p", null, translate('/Admin/MasterCustomers/ConfirmRemoveSlave', single ? customerId : slaves.join(', '))),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: remove, onCancel: function () { return setOpen(false); } }))));
}
