import { isBrowser } from 'Shared/device-type';
import isStartPage from '../Start/Pages/Start/current-page-is-start';
import isCheckoutPage from '../Checkout/Pages/Checkout/current-page-is-checkout';
import { isSearchPage } from '../Search/Pages/Search/current-page-is-search';
import { isProductPage } from '../Product/Commerce/current-page-is-product';
import { isCategoryPageType } from '../Category/Commerce/Components/current-page-is-category';
import isOrderConfirmation from '../Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
export function addToDataLayer(action) {
    window.dataLayer.push(action);
}
export function addGa4EventToDataLayer(action) {
    window.dataLayer.push(action);
}
export function addEcommerceEventToDataLayer(event) {
    addGa4EventToDataLayer({ event: null, ecommerce: null });
    addGa4EventToDataLayer(event);
}
export function addCustomEventToDataLayer(action) {
    window.dataLayer.push(action);
}
function addGa4CommerceToDataLayerOnPageLoad(events, selectedVariantCode) {
    if ('view_item_list' in events) {
        addEcommerceEventToDataLayer(events[String('view_item_list')]);
    }
    if ('view_item' in events) {
        var viewItemEvents = events[String('view_item')];
        var viewItemEvent = viewItemEvents[selectedVariantCode];
        var pageTypeReferrel = getGa4ItemListReferrel();
        if (!!viewItemEvent && !!pageTypeReferrel) {
            viewItemEvent.ecommerce.items[0].item_list_id = pageTypeReferrel;
            viewItemEvent.ecommerce.items[0].item_list_name = pageTypeReferrel;
        }
        addEcommerceEventToDataLayer(viewItemEvent);
    }
    if ('begin_checkout' in events) {
        addEcommerceEventToDataLayer(events[String('begin_checkout')]);
    }
    if ('purchase' in events) {
        addEcommerceEventToDataLayer(events[String('purchase')]);
    }
}
function getGa4ItemListReferrel() {
    return sessionStorage.getItem("ga4ItemListReferrel");
}
export function initTagManager() {
    window.dataLayer = window.dataLayer || [];
}
export function addToImbox(action) {
    if (!IS_APP && isBrowser()) {
        try {
            window._imbox.push(action);
        }
        catch (error) {
            window.newrelic && window.newrelic.noticeError(error);
            console.error(error);
        }
    }
}
export function updateUserNameOnImbox(currentUser) {
    if (currentUser.isLoggedIn) {
        addToImbox(['changeName', currentUser.fullName + " (" + currentUser.customerId + ")"]);
    }
    else {
        addToImbox(['changeName', 'Ej inloggad']);
    }
}
export function initImbox() {
    window._imbox = window._imbox || [];
}
export var ODPTrackingActions = {
    'ADD_TO_CART': 'add_to_cart',
    'REMOVE_FROM_CART': 'remove_from_cart',
    'DETAIL': 'detail'
};
export function updateODPTracking(code, action) {
    !!code && !!action && setTimeout(function () {
        !!window["zaius"] && window["zaius"].event("product", {
            action: action,
            product_id: "" + code
        });
    });
}
export function updateODPTrackingMinicart() {
    setTimeout(function () {
        !!window["zaius"] && window["zaius"].event("checkout", {
            action: 'start'
        });
    });
}
export function updateODPTrackingSearchPage(searchTerm) {
    !!searchTerm && setTimeout(function () {
        !!window["zaius"] && window["zaius"].event("navigation", {
            action: 'search',
            search_term: searchTerm,
        });
    });
}
export function updateODPTrackingSearchResultProductClick(searchTerm, url) {
    !!searchTerm && !!url && setTimeout(function () {
        !!window["zaius"] && window["zaius"].event("search", {
            action: 'click',
            search_term: searchTerm,
            campaign: "Content Result Click - - Search Term: " + searchTerm,
            value: url
        });
    });
}
export function updateODPTrackingLogin(customerId, email) {
    !!customerId && setTimeout(function () {
        !!window["zaius"] && window["zaius"].event("account", {
            action: "login",
            customer_number: customerId,
            email: email
        });
    });
}
export function login(method, customerId) {
    var action = {
        event: 'login',
        eventInfo: {
            category: 'Login Method',
            action: method,
        },
    };
    addToDataLayer(action);
}
export function subscribe(toEmail, toSMS) {
    if (!toEmail && !toSMS) {
        return;
    }
    var subscribeTo = toEmail && toSMS ? 'Email + SMS' : toEmail && !toSMS ? 'Email' : 'SMS';
    var action = {
        event: 'subscription',
        eventInfo: {
            category: 'Newsletter Signups',
            action: subscribeTo,
        },
    };
    addToDataLayer(action);
}
export function pageLoad(page, isDynamicPageLoad, state) {
    try {
        var action = {
            event: 'pageview',
            pageData: {
                path: page.url.path,
                type: isDynamicPageLoad ? 'dynamic' : 'hard',
            },
        };
        setTimeout(function () {
            !!window["zaius"] && window["zaius"].event("pageview");
        }, 100);
        if (state.currentUser.trackingUser) {
            action.userData = {
                id: state.currentUser.trackingUser.id,
                customerType: state.currentUser.trackingUser.customerType,
            };
        }
        addToDataLayer(action);
        var ga4EventObjects = !!state.currentPage.trackingData && state.currentPage.trackingData.ga4EventObjects;
        if (!!ga4EventObjects) {
            var code = String(state.product.selectedUnits[state.product.selectedUnits.length - 1]);
            if ((code == null || code == undefined || code == 'undefined') && (!!state.currentPage && !!state.currentPage.vatiants)) {
                code = state.currentPage.variants[0].code;
            }
            addGa4CommerceToDataLayerOnPageLoad(ga4EventObjects, code);
        }
        peeriusTracking(page, state);
    }
    catch (error) {
        window.newrelic && window.newrelic.noticeError(error);
        console.error(error);
    }
}
export function peeriusTracking(page, state) {
    if (!!state.currentUser.customerId) {
        var isSupplierorEmployee = state.currentUser.customerId.startsWith("PK") || state.currentUser.isSupplierSalesman;
        if (!isSupplierorEmployee) {
            try {
                if (!!isStartPage(page)) {
                    var baseJson = state.appShellData.peeriusTrackingData;
                    var json = {};
                    Object.assign(json, baseJson, { type: 'home' });
                    sendToPeerius(json);
                }
                else if (!!isCheckoutPage(page)) {
                    sendToPeerius(page.checkoutPageTrackingData);
                }
                else if (!!isSearchPage(page)) {
                    sendToPeerius(page.searchResultsPageTrackingData);
                }
                else if (!!isProductPage(page)) {
                    sendToPeerius(page.productPageTrackingData);
                }
                else if (!!isCategoryPageType(page)) {
                    sendToPeerius(page.categoryPageTrackingData);
                }
                else if (!!isOrderConfirmation(page)) {
                    sendToPeerius(page.orderConfirmationTrackingData);
                }
                else {
                    sendToPeerius(state.appShellData.peeriusTrackingData);
                }
            }
            catch (error) {
            }
        }
    }
}
export function sendToPeerius(jsonData) {
    if (!!!jsonData)
        return;
    var json = encodeURIComponent(JSON.stringify(jsonData));
    setTimeout(function () {
        if (!!window["Peerius"]) {
            window["Peerius"].sendAjax("rest.pagex?jd=" + json);
        }
    }, 1000);
}
;
export function register() {
    var action = {
        event: 'newAccount',
    };
    addToDataLayer(action);
}
export function promoClick(promo, index) {
    if (!!!promo)
        return;
    promo = handleIndex(promo, index);
    var action = {
        event: 'promotionClick',
        ecommerce: {
            promoClick: {
                promotions: [promo]
            }
        }
    };
    addToDataLayer(action);
}
export function promoImpression(promo, index) {
    if (!!!promo)
        return;
    promo = handleIndex(promo, index);
    var action = {
        event: 'promotionImpression',
        ecommerce: {
            promoView: {
                promotions: [promo]
            }
        }
    };
    addToDataLayer(action);
}
function handleIndex(promo, index) {
    if (!!!promo.position)
        return promo;
    if (!promo.position.indexOf("{index}"))
        return promo;
    if (!!index)
        promo.position = promo.position.replace(/{index}/g, (index + 1).toString()).trim();
    else
        promo.position = promo.position.replace(/-*{index}/g, '').trim();
    return promo;
}
export function searchAsUserTypeAorB(customerAbTestType) {
    var action = {
        event: 'Experiment',
        eventInfo: {
            eventName: 'Description search',
            eventVersion: customerAbTestType == 'a'
                ? 'A'
                : customerAbTestType == 'b'
                    ? 'B'
                    : 'X',
        },
    };
    addToDataLayer(action);
}
export function altenativeProductLinkClick(product) {
    var action = {
        event: 'Alternativa varor',
        eventInfo: {
            eventCategory: 'Alternative items-See alternative items',
            eventAction: 'Click',
            eventLabel: !!product ? product.title : "Missing title"
        }
    };
    addToDataLayer(action);
}
export function outOfStockShowButtonClick(product) {
    var action = {
        event: 'Alternativa varor',
        eventInfo: {
            eventCategory: 'Alternative items-Order',
            eventAction: 'Click',
            eventLabel: !!product ? product.title : "Missing title"
        }
    };
    addToDataLayer(action);
}
export function altenativeProductLinkImpression(product, outOfStock) {
    var action = {
        event: 'Alternativa varor',
        eventInfo: {
            eventCategory: outOfStock ? 'Out of stock' : 'Ready to order',
            eventAction: 'Impression',
            eventLabel: !!product ? product.title : "Missing title"
        }
    };
    addToDataLayer(action);
}
export function recommendationsPanelImpression(recommendationPanel) {
    var action = {
        event: 'Rec-Panel',
        eventInfo: {
            eventCategory: 'Rec-Panel-Native-Category',
            eventAction: 'Impression',
            eventLabel: !!recommendationPanel ? recommendationPanel.title : "Missing title"
        }
    };
    addToDataLayer(action);
}
export function recommendationsPanelLinkButtonClick(recommendationPanel) {
    var action = {
        event: 'Rec-Panel',
        eventInfo: {
            eventCategory: 'Rec-Panel Link button',
            eventAction: 'Click',
            eventLabel: !!recommendationPanel ? recommendationPanel.title + ' > ' + recommendationPanel.linkText : "Missing title"
        }
    };
    addToDataLayer(action);
}
