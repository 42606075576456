import React from 'react';
import connect from 'Shared/connect';
import * as styles from '../base.scss';
import MyMenigoIcon, { GetIcons } from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import classnames from 'classnames';
import { pushState } from 'Shared/history';
var LoginSlider = function (props) { return (React.createElement("div", { className: styles.slideIn, key: "MyMenigo" }, props.myMenigoItems
    .filter(function (item) { return item.disabledForGuestUsers; })
    .map(function (item) { return (React.createElement("a", { key: item.url, onClick: function () { return (pushState(item.url)); } },
    React.createElement("div", { className: classnames(styles.primary, styles.menuLinks) },
        React.createElement("span", null,
            React.createElement(MyMenigoIcon, { name: GetIcons(item.name) }),
            item.name),
        !!item.textMark &&
            React.createElement("span", { className: styles.menuTextMark }, item.textMark),
        React.createElement(MyMenigoIcon, { name: "arrow" })))); }))); };
export default connect(function (state) { return ({
    myMenigoItems: !!state.appShellData.header && state.appShellData.header.myMenigoItems,
}); })(LoginSlider);
