var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import moment from 'moment';
import Icon from 'Shared/Icon';
import connect from 'Shared/connect';
import { toggleDeliveryDays } from './action-creators';
import { translate } from 'Shared/translate';
import DeliveryDates from './../../../Features/Shared/DeliveryDates';
import { MEDIUM } from 'Shared/breakpoints';
import * as styles from './base.scss';
import classnames from 'classnames';
var DeliveryDateInfo = /** @class */ (function (_super) {
    __extends(DeliveryDateInfo, _super);
    function DeliveryDateInfo(props) {
        return _super.call(this, props) || this;
    }
    DeliveryDateInfo.prototype.setDeliveryDayText = function () {
        var stopTime = this.getStopText();
        if (!stopTime || stopTime === '') {
            return translate('/DeliveryDate/NoDeliveryDatesFound');
        }
        var timeLeft = moment.duration(moment().diff(moment(stopTime)));
        var timeLeftAbs = moment.duration(Math.abs(moment().diff(moment(stopTime))));
        if (timeLeft.days() > -1) {
            if (timeLeft.asHours() < -1) {
                return translate('/DeliveryDate/StopTime') + ': ' + timeLeftAbs.hours() + translate('/Shared/HourShort');
            }
            if (timeLeft.asMinutes() < 0) {
                return translate('/DeliveryDate/StopTime') + ': ' + timeLeftAbs.minutes() + translate('/Shared/MinuteShort');
            }
            return translate('/DeliveryDate/SelectNewStopTime');
        }
        return translate('/DeliveryDate/StopTime') + ': ' + stopTime;
    };
    DeliveryDateInfo.prototype.getStopText = function () {
        return (this.props.headerViewModel.selectedDelivery &&
            this.props.headerViewModel.selectedDelivery.stopDate + " " + this.props.headerViewModel.selectedDelivery.stopTime);
    };
    DeliveryDateInfo.prototype.getDepartureText = function () {
        return translate('/DeliveryDate/DeliveryDay') + ": " + this.props.headerViewModel.selectedDelivery.departureDateAsString;
    };
    DeliveryDateInfo.prototype.render = function () {
        var _this = this;
        var isMobile = this.props.currentBreakpoint <= MEDIUM;
        if (this.props.currentPage.componentName.indexOf('CheckoutPage') >= 0) {
            return null;
        }
        return (React.createElement("div", { className: classnames(styles.deliveryDateWrapper, !this.props.headerViewModel.selectedDelivery && (isMobile ? styles.mobileError : styles.error), 'ui-test-delivery-date-info') },
            this.props.headerViewModel.selectedDelivery ? (React.createElement("a", { className: isMobile ? styles.deliveryActionMobile : styles.deliveryAction, onClick: function () { return _this.props.toggleDeliveryDays(); } }, isMobile ? (React.createElement(Icon, { name: "calendar" })) : ([
                React.createElement("div", { key: "departureText" },
                    React.createElement("strong", null, this.getDepartureText())),
                React.createElement("div", { key: "deliveryText" }, this.setDeliveryDayText()),
            ]))) : (React.createElement("span", { className: classnames(styles.deliveryAction, styles.validationError) }, this.setDeliveryDayText())),
            this.props.deliveryDaysIsVisible && (React.createElement(DeliveryDates, { hasWrapper: false, className: styles.deliveryDatesWrapper, preselectedDeliveryDay: this.props.headerViewModel.selectedDelivery.departureDateAsString, deliveryDays: this.props.headerViewModel.deliveryDates, selectedDelivery: this.props.headerViewModel.selectedDelivery }))));
    };
    return DeliveryDateInfo;
}(React.Component));
export default connect(function (state) { return ({
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
    headerViewModel: state.appShellData.header,
    currentBreakpoint: state.currentBreakpoint,
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    toggleDeliveryDays: function () {
        return dispatch(toggleDeliveryDays());
    },
}); })(DeliveryDateInfo);
