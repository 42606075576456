var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import Image from 'Shared/ImageFallback';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import { translate } from 'Shared/translate';
import Price from 'Shared/Price';
import Grade from './../Grade';
import RemoveRecipe from './../RemoveRecipe';
import * as styles from './list.scss';
import shallowEquals from 'Shared/shallow-equals';
var RecipeCard = /** @class */ (function (_super) {
    __extends(RecipeCard, _super);
    function RecipeCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RecipeCard.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to RecipeCard');
        }
        return shouldUpdate;
    };
    RecipeCard.prototype.render = function () {
        var isMeal = this.props.recipeType === 'meal';
        var url = this.props.url;
        var textNode = (React.createElement("div", { className: styles.name },
            React.createElement("a", { href: url, className: styles.linkText },
                React.createElement("b", null, this.props.name),
                isMeal && React.createElement("em", null, translate('/RecipeListing/Meal')))));
        var gradeNode = React.createElement(Grade, { grade: this.props.grade, votes: this.props.voteCount });
        var removeitemNode = this.props.isLoggedIn && this.props.isOwner && (React.createElement(RemoveRecipe, { id: this.props.id, name: this.props.name, className: styles.remove, appearance: 'icon' }));
        switch (this.props.appearance) {
            case 'box':
                return (React.createElement("div", { className: styles.card },
                    removeitemNode,
                    React.createElement("div", { className: styles.imageWrap },
                        React.createElement("a", { href: url },
                            React.createElement(Image, { skipSrcSet: true, src: this.props.imageUrl, alt: this.props.name, size: "tiny", maxSize: "small", fadeIn: true }))),
                    React.createElement("div", { className: styles.info },
                        React.createElement("div", { className: styles.cardRow },
                            textNode,
                            this.props.isLoggedIn && (this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                                React.createElement(Icon, { name: "lock" }))),
                            this.props.isLoggedIn && (!this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                                React.createElement(Icon, { name: "md-share" })))),
                        React.createElement("div", { className: styles.cardRow },
                            React.createElement("div", null,
                                this.props.isLoggedIn && (React.createElement(Price, __assign({ className: styles.price }, this.props.portionCost, { size: "normal" }))),
                                this.props.isLoggedIn && this.props.portionCostIncomplete && React.createElement("sup", null, "**")),
                            React.createElement("div", { className: classnames(styles.col2) }, gradeNode)))));
            case 'mobile':
                return (React.createElement("div", { className: classnames(styles.mobileCard) },
                    React.createElement("div", { className: classnames(styles.col0) },
                        React.createElement("div", { className: classnames(styles.imageWrap, isMeal && styles.meal) },
                            React.createElement(Image, { skipSrcSet: true, src: this.props.imageUrl, alt: this.props.name, size: "thumb" }))),
                    React.createElement("div", { className: styles.col1 },
                        textNode,
                        this.props.isLoggedIn && (React.createElement("div", null,
                            React.createElement(Price, __assign({ className: styles.price }, this.props.portionCost, { size: "normal" })),
                            this.props.portionCostIncomplete && React.createElement("sup", null, "**"))),
                        React.createElement("div", null, gradeNode)),
                    React.createElement("div", { className: styles.col2 },
                        this.props.isLoggedIn && (this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                            React.createElement(Icon, { name: "lock" }))),
                        this.props.isLoggedIn && (!this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                            React.createElement(Icon, { name: "md-share" }))),
                        removeitemNode)));
            case 'compact':
            case 'list':
                return (React.createElement("div", { className: classnames(styles.tablerow) },
                    React.createElement("div", { className: classnames(styles.col0) },
                        React.createElement("div", { className: classnames(styles.imageWrap, isMeal && styles.meal) },
                            React.createElement(Image, { skipSrcSet: true, src: this.props.imageUrl, alt: this.props.name, size: "thumb" })),
                        textNode),
                    this.props.isLoggedIn && (React.createElement("div", { className: classnames(styles.col1) },
                        React.createElement(Price, __assign({ className: styles.price }, this.props.portionCost, { size: "normal" })),
                        this.props.portionCostIncomplete && React.createElement("sup", null, "**"))),
                    React.createElement("div", { className: classnames(styles.col2) }, gradeNode),
                    React.createElement("div", { className: classnames(styles.col3) },
                        this.props.isLoggedIn && (this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                            React.createElement(Icon, { name: "lock" }))),
                        this.props.isLoggedIn && (!this.props.isMenigo && this.props.isPublic) && (React.createElement("div", { className: styles.lock },
                            React.createElement(Icon, { name: "md-share" }))),
                        removeitemNode)));
            default:
                return null;
        }
    };
    return RecipeCard;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
}); }, function (dispatch) { return ({}); })(RecipeCard);
