var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Flyout from 'Shared/Flyout';
import Icon from 'Shared/Icon';
import * as styles from './base.scss';
import ProductsSlider from './Menus/ProductsSlider';
import LoginMenuRow from './Menus/LoginMenuRow';
import Slider from './Slider';
import { translate } from 'Shared/translate';
import LoginSlider from './Menus/LoginSlider';
import { logout } from '../../Account/action-creators';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import classnames from 'classnames';
import Button from 'Shared/Button';
var InitialState = {
    animation: 'forward',
    headline: null,
    prev: null,
    current: null,
};
var Sliding = /** @class */ (function (_super) {
    __extends(Sliding, _super);
    function Sliding(props) {
        var _this = _super.call(this, props) || this;
        _this.back = function () {
            _this.setState({ animation: 'back', current: null }, function () { document.getElementById("MobileMenu").scrollTo(0, 0); });
        };
        _this.forward = function (headline, element, props) {
            if (props === void 0) { props = {}; }
            return _this.setState({ animation: 'forward', current: { element: element, props: props }, headline: headline });
        };
        _this.reset = function () { return _this.setState(InitialState); };
        _this.getMenuItems = function () {
            var menuItems = _this.props.mainMenu.primaryMenuItems.concat(_this.props.mainMenu.rightContentLinks);
            return menuItems;
        };
        _this.state = InitialState;
        return _this;
    }
    Sliding.prototype.componentWillReceiveProps = function (newProps) {
        if (this.props.mainMenuIsOpen && !newProps.mainMenuIsOpen) {
            this.reset();
        }
        document.getElementById("MobileMenu").scrollTo(0, 0);
    };
    Sliding.prototype.render = function () {
        var _this = this;
        return (React.createElement(Flyout, { position: "right", toggleFlyout: this.props.toggleMainMenu, isOpen: this.props.mainMenuIsOpen, canOpen: this.props.canOpen, fillWidth: true },
            React.createElement("div", { id: "MobileMenu", className: classnames(styles.base, !!this.props.isLoggedIn && styles.loggedIn) },
                React.createElement("div", { className: styles.header },
                    (this.state.current && (React.createElement("a", { onClick: this.back },
                        React.createElement(MyMenigoIcon, { name: "arrow" }),
                        this.state.headline))) || React.createElement("span", null, translate('/SiteLayout/Header/Brand')),
                    React.createElement("a", { className: styles.close, onClick: this.props.toggleMainMenu },
                        React.createElement(Icon, { name: "close" }))),
                React.createElement(Slider, { direction: this.state.animation },
                    React.createElement("div", { key: 'MainMenu' }, this.state.current == null ? (React.createElement(React.Fragment, null,
                        React.createElement(ProductsSlider, { displayName: translate('/Product/Product'), children: this.getMenuItems() }),
                        !!!this.props.isLoggedIn && this.props.mainMenu.topContentLinks.map(function (item) {
                            return (React.createElement("a", { key: item.url, href: item.url },
                                React.createElement("div", { className: classnames(styles.primary, styles.topLinks) },
                                    React.createElement("span", null, item.displayName),
                                    React.createElement(MyMenigoIcon, { name: "arrow" }))));
                        }),
                        this.props.isLoggedIn && (React.createElement("a", { className: classnames(styles.myMenigo), onClick: function () { return _this.forward(translate('/MyMenigo/Header/MyMenigo'), LoginSlider); } },
                            React.createElement("div", { className: styles.primary },
                                React.createElement("span", null,
                                    React.createElement(MyMenigoIcon, { name: "user" }),
                                    translate('/MyMenigo/Header/MyMenigo')),
                                React.createElement(MyMenigoIcon, { name: "arrow" })))))) : (React.createElement(this.state.current.element, this.state.current.props)))),
                !!this.props.isLoggedIn ? (React.createElement("div", { className: classnames(styles.userInfoWrapper, styles.myMenigo) },
                    !this.props.isSupplierSalesman ? (React.createElement("div", null,
                        React.createElement("span", { className: styles.userInfoLabel, "data-ta": "logged-in-customer-label" }, this.props.currentUser.punchOutSessionActive
                            ? translate('/SiteLayout/Header/LoggedAsPunchOut')
                            : translate('/SiteLayout/Header/LoggedAsCustomer')),
                        React.createElement("span", { className: styles.userInfoItem },
                            this.props.currentUser.customerId,
                            ", "),
                        React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.companyName),
                        (this.props.currentUser.fullName != this.props.currentUser.companyName) && React.createElement("span", { className: styles.userInfoItem },
                            ", ",
                            this.props.currentUser.fullName),
                        !!this.props.currentUser.orderReferenceId && (React.createElement(React.Fragment, null,
                            React.createElement("span", { className: styles.userInfoLabel },
                                translate('/MyMenigo/CustomerProfileBlock/OrderReferenceId'),
                                ":"),
                            React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.orderReferenceId))))) : (React.createElement("div", { className: classnames(styles.suppliersalesmanWrapper, styles.myMenigo) },
                        React.createElement("span", { className: styles.userInfoLabel }, translate('/SiteLayout/Header/LoggedAsSupplierSalesman')),
                        React.createElement("span", { className: styles.userInfoItem }, this.props.suppliersalesmanInfo),
                        React.createElement("span", { className: styles.suppliersalesmanInfo },
                            React.createElement("span", { className: styles.userInfoLabel }, translate('/SiteLayout/Header/ChosenCustomer')),
                            React.createElement("span", { className: styles.userInfoItem },
                                this.props.currentUser.customerId,
                                ", "),
                            React.createElement("span", { className: styles.userInfoItem }, this.props.currentUser.companyName),
                            (this.props.currentUser.fullName != this.props.currentUser.companyName) && React.createElement("span", { className: styles.userInfoItem },
                                ", ",
                                this.props.currentUser.fullName),
                            this.props.activeExhibition && (React.createElement("span", { className: styles.suppliersalesmanExhibition },
                                React.createElement("span", { className: styles.userInfoLabel }, translate('/SiteLayout/Header/Exhibition')),
                                React.createElement("span", null, this.props.activeExhibition)))))),
                    React.createElement(Button, { appearance: "bare", className: classnames(styles.myMenigoButton), onClick: function () { return _this.props.logout(); } }, translate('/Account/MyPages/Logout')))) : (React.createElement(LoginMenuRow, null)),
                React.createElement("div", null))));
    };
    return Sliding;
}(React.Component));
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    selectedLanguage: state.appShellData.languageSites.find(function (lng) { return lng.language === state.currentUser.userSettings.selectedLanguageCode; }),
    mainMenu: state.appShellData.mainMenu,
    mainMenuIsOpen: state.mainMenu.isOpen,
    currentUser: state.currentUser,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    suppliersalesmanInfo: state.currentUser.supplierSalesmanInfo,
    activeExhibition: state.currentUser.activeExhibition,
}); }, function (dispatch) { return ({
    logout: function () { return dispatch(logout()); },
}); })(Sliding);
