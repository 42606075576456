var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
// ViewObserver uses IntersectionObserver API which requires polyfill
// see https://caniuse.com/#search=IntersectionObserver
if (typeof window !== 'undefined')
    require('intersection-observer');
var THRESHOLD_STEPS = 0;
var buildThreshold = function (steps) {
    if (steps === void 0) { steps = THRESHOLD_STEPS; }
    var threshold = Array.from({ length: steps }, function (x, i) { return i / steps; });
    // Returns an array like [0, ...n, 1]
    return __spread(threshold, [1]);
};
export var DEFAULT_OPTIONS = {
    // If an element height is similar to the container height,
    // it's very difficult to catch an impression in 250ms.
    // By default the intersectionRatio of an element should be
    // 1 to be considered seen and so for tall elements we reduce the
    // max intersectionRatio by this value
    errorMargin: 0.05,
    // The container height range at which to compensate with
    // an error margin
    percentCompensation: 0.2,
    // Element must be visible for >= 250ms
    minTimeVisible: 250,
    // The ViewObserver config
    config: {
        root: null,
        rootMargin: '0px',
        threshold: buildThreshold(100),
    },
};
var instance = null;
var ViewObserver = /** @class */ (function () {
    function ViewObserver(options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        /**
         * Fire onChange event listeners for all visible elements
         */
        this.reportVisibilityStates = function () {
            _this.elements.forEach(function (_a, element) {
                var visible = _a.visible;
                if (visible) {
                    _this.onVisibilityChange(element, visible);
                }
            });
        };
        /**
         * Loop through all of the observed elements and check if visible
         * @param  {Array} [entries=IntersectionObserverEntry] array of [IntersectionObserverEntry],
         */
        this.watchElements = function (entries) {
            // If rootBounds does not exist, it will default to the height and width
            // of the viewport
            var containerHeight = window.innerHeight || document.documentElement.clientHeight;
            entries
                .filter(function (entry) { return entry.isIntersecting; })
                .forEach(function (entry) {
                var node = entry.target || entry['entry'];
                var element = _this.elements.get(node);
                // We wrap the child in a container, so we need to ensure we're looking at
                // the bounds of the child and not the wrapper
                var bounds = entry.boundingClientRect || node.getBoundingClientRect();
                var elementHeight = bounds.height;
                // If the element height is within x% (this.options.percentCompensation)
                // of the container height, use the errorMargin option (this.options.errorMargin).
                // Otherwise default to 0.025. The max intersectionRatio for a regular element
                // should be 0.975 as being _too_ strict can result in missed events.
                var errorMargin = _this.isElementHeightSimilarToContainer(elementHeight, containerHeight)
                    ? _this.options.errorMargin
                    : 0.025;
                var maxIntersectionRatio = containerHeight / elementHeight > 1
                    ? 1 - errorMargin
                    : containerHeight / elementHeight - errorMargin;
                var isVisible = entry.intersectionRatio >= maxIntersectionRatio;
                // Element is still visible since last check
                if (isVisible && element.timeoutSet)
                    return;
                // If the element is visible
                if (isVisible) {
                    // Set the visibility state to true but wait until the timeout finishes
                    // to fire the event
                    element.timeoutSet = true;
                    // Start timer
                    _this.timeouts.set(node, setTimeout(function () {
                        // Fire the event
                        _this.onVisibilityChange(node, true);
                    }, _this.options.minTimeVisible));
                }
                else {
                    var timeout = _this.timeouts.get(node);
                    // Fire event
                    _this.onVisibilityChange(node, false);
                    // Element is no longer visible, delete timeout
                    clearTimeout(timeout);
                    _this.timeouts.delete(node);
                    element.timeoutSet = false;
                }
            });
        };
        /**
         * Observe an entry
         * @param  {Element} element the element to watch
         * @param  {Function} callback the element callback event
         */
        this.observe = function (element, callback) {
            if (!element || !callback)
                return;
            _this.elements.set(element, {
                callback: callback,
                visible: false,
                entry: element,
            });
            _this.observer.observe(element);
        };
        /**
         * Unobserve an entry
         * @param  {Element} element the element to unobserve
         */
        this.unobserve = function (element) {
            if (!element)
                return;
            if (!_this.elements.get(element))
                return;
            if (_this.observer)
                _this.observer.unobserve(element);
            _this.elements.delete(element);
            // If we're not watching any elements, destroy the ViewObserver.
            // If more trackers are mounted, a new instance of the ViewObserver
            // will be created
            if (_this.elements.size === 0) {
                _this.destroy();
            }
        };
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);
        if (isNaN(this.options.minTimeVisible) || this.options.minTimeVisible < 0) {
            this.options.minTimeVisible = DEFAULT_OPTIONS.minTimeVisible;
        }
        this.elements = new Map();
        this.timeouts = new Map();
        this.observer = new IntersectionObserver(this.watchElements, this.options.config);
        // Handle browser tab changes
        window.addEventListener('focus', this.reportVisibilityStates);
        // Handle device orientation changes
        window.addEventListener('orientationchange', this.reportVisibilityStates);
    }
    /**
     * Return the single instance of the IntersectionObserver if one exists already,
     * otherwise create an instance
     * @param {ViewObserverOptions} options - ViewObserver instantiation options
     * @return {ViewObserver} return instance of the class
     */
    ViewObserver.get = function (options) {
        if (options === void 0) { options = DEFAULT_OPTIONS; }
        // If an instance of the observer already exists, return the singleton
        // otherwise instantiate a new one
        if (instance && instance instanceof ViewObserver)
            return instance;
        instance = new ViewObserver(options);
        return instance;
    };
    /**
     * Remove window event listeners to avoid memory leaks
     */
    ViewObserver.prototype.removeEventListeners = function () {
        window.removeEventListener('focus', this.reportVisibilityStates);
        window.removeEventListener('orientationchange', this.reportVisibilityStates);
    };
    /**
     * Detroy all Maps and event listeners.
     * @returns {void} returns instance
     */
    ViewObserver.prototype.destroy = function () {
        // Clear all elements
        this.elements.clear();
        // Clear all lingering timeouts
        this.timeouts.clear();
        // Disconnect the observer, if available
        if (this.observer && typeof this.observer.disconnect === 'function') {
            this.observer.disconnect();
        }
        // Remove all event listeners
        this.removeEventListeners();
        instance = null;
        return instance;
    };
    ViewObserver.prototype.isElementHeightSimilarToContainer = function (elementHeight, containerHeight) {
        return (elementHeight >=
            containerHeight - containerHeight * this.options.percentCompensation &&
            elementHeight <=
                containerHeight + containerHeight * this.options.percentCompensation);
    };
    /**
     * Handle DOM element visibility state change
     * @param  {Element} element - the DOM node
     * @param  {Boolean} visible - the visibility state of the entry
     */
    ViewObserver.prototype.onVisibilityChange = function (element, visible) {
        if (!element)
            return;
        var entry = this.elements.get(element);
        if (!entry)
            return;
        // Set the new visibility state
        entry['visible'] = visible;
        var callback = entry.callback;
        if (typeof callback === 'function')
            callback(visible);
    };
    return ViewObserver;
}());
export default ViewObserver;
