import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
var styleTypes = new Map([['left', styles.left], ['right', styles.right], ['flex', styles.flex]]);
var getStyleType = function (style) { return styleTypes.get(style); };
export default (function (props) {
    var priceWithoutVat = (props.unitShortName
        ? props.unitOptions.find(function (u) { return props.unitShortName === u.unitShortName; })
        : props.unitOptions[0]).priceWithoutVat;
    return (priceWithoutVat !== null &&
        priceWithoutVat !== undefined && (React.createElement("div", { className: classnames(styles.price, getStyleType(props.apperance), getStyleType(props.layout)) },
        priceWithoutVat,
        translate('/App/PriceSymbol'))));
});
