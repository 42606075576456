var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition';
import AvailibilityCircle from './AvailibilityCircle';
import Icon from 'Shared/Icon';
import * as styles from './base.scss';
var ProductAvailabilityTable = /** @class */ (function (_super) {
    __extends(ProductAvailabilityTable, _super);
    function ProductAvailabilityTable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isOpen: null,
        };
        return _this;
    }
    ProductAvailabilityTable.prototype.toggle = function (e, open) {
        if (this.state.isOpen === open) {
            this.setState({ isOpen: null });
        }
        else {
            this.setState({ isOpen: open });
        }
    };
    ProductAvailabilityTable.prototype.render = function () {
        var _this = this;
        var goodState = 'good';
        var neutralState = 'neutral';
        var badState = 'bad';
        if (this.props.seasonCountries.length === 0) {
            return React.createElement("div", null);
        }
        return (React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.h3 }, translate('/Product/ProductRawInformation/Availability')),
            React.createElement("ul", { className: styles.availabilityWrapper }, this.props.seasonCountries.map(function (month, i) {
                var check = false;
                var value1 = badState;
                check = month.countries.some(function (item) {
                    if (item.value1 === goodState) {
                        return true;
                    }
                });
                if (check) {
                    value1 = goodState;
                }
                else {
                    var check_1 = month.countries.some(function (item) {
                        if (item.value1 === neutralState) {
                            return true;
                        }
                    });
                    if (check_1) {
                        value1 = neutralState;
                    }
                }
                var value2 = badState;
                check = month.countries.some(function (item) {
                    if (item.value2 === goodState) {
                        return true;
                    }
                });
                if (check) {
                    value2 = goodState;
                }
                else {
                    var check_2 = month.countries.some(function (item) {
                        if (item.value2 === neutralState) {
                            return true;
                        }
                    });
                    if (check_2) {
                        value2 = neutralState;
                    }
                }
                return (React.createElement("li", { key: i, className: styles.monthWrapper },
                    React.createElement("div", { className: styles.month, onClick: function (e) { return _this.toggle(e, i); } },
                        React.createElement("b", { className: styles.label }, month.month),
                        React.createElement(AvailibilityCircle, { value: value1 }),
                        React.createElement(AvailibilityCircle, { value: value2 }),
                        React.createElement(Icon, { className: styles.arrow, name: _this.state.isOpen === i ? 'arrow-up' : 'arrow-down' })),
                    React.createElement(ReactCSSTransitionGroup, { in: _this.state.isOpen === i, classNames: {
                            appear: styles.enter,
                            appearActive: styles.enterActive,
                            enter: styles.enter,
                            enterActive: styles.enterActive,
                            exit: styles.leave,
                            exitActive: styles.leaveActive,
                        }, mountOnEnter: true, unmountOnExit: true, appear: true, timeout: { enter: 200, exit: 200 }, transitionLeaveTimeout: 1 },
                        React.createElement("div", null, (month.countries || []).map(function (country, i2) {
                            return (React.createElement("div", { key: i2, className: styles.monthChildWrapper },
                                React.createElement("p", { className: styles.monthChild }, country.name),
                                React.createElement(AvailibilityCircle, { value: country.value1 }),
                                React.createElement(AvailibilityCircle, { value: country.value2 })));
                        })))));
            })),
            React.createElement("ul", { className: styles.availabilityWrapper },
                React.createElement("li", null,
                    React.createElement(AvailibilityCircle, { value: "good" }),
                    React.createElement("span", { className: styles.availabilityInfo }, translate('/Product/ProductRawInformation/GoodAvailability'))),
                React.createElement("li", null,
                    React.createElement(AvailibilityCircle, { value: "neutral" }),
                    React.createElement("span", { className: styles.availabilityInfo }, translate('/Product/ProductRawInformation/NeutralAvailability'))),
                React.createElement("li", null,
                    React.createElement(AvailibilityCircle, { value: "bad" }),
                    React.createElement("span", { className: styles.availabilityInfo }, translate('/Product/ProductRawInformation/BadAvailability')))),
            React.createElement("p", null, translate('/Product/ProductRawInformation/InfoAvailability')),
            React.createElement("p", null, translate('/Product/ProductRawInformation/NoAvailability'))));
    };
    return ProductAvailabilityTable;
}(React.Component));
export default ProductAvailabilityTable;
