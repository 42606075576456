var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import classnames from 'classnames';
import 'moment/locale/sv';
import { DayPickerSingleDateController } from 'react-dates';
import { Section } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import DropDown from 'Shared/DropDown';
import Overlay from 'Shared/Overlay';
import { TINY } from 'Shared/breakpoints';
import Icon from 'Shared/Icon';
import { toggleDeliveryDays, showDeliveryDays, hideDeliveryDays } from 'SiteLayout/Header/action-creators';
import connect from 'Shared/connect';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import { deliveryPageUrl } from 'Shared/known-urls';
import Tooltip from 'Shared/Tooltip';
import { updateAppShellData } from './../../AppShell/action-creators';
import { replaceState } from 'Shared/history';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoIcon from './../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { SetDeliveryDate } from './action-creators';
var DATE_FORMAT = 'YYYY-MM-DD';
var DeliveryDates = /** @class */ (function (_super) {
    __extends(DeliveryDates, _super);
    function DeliveryDates(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleTooltip = function (e) {
            e && e.preventDefault();
            _this.setState({ showTooltip: !_this.state.showTooltip });
        };
        moment.locale(_this.props.culture);
        var deliveryDays = props.deliveryDays;
        var date = props.selectedDelivery;
        var state = {
            focused: true,
            hoverDate: null,
            activated: false,
            deliveryDays: deliveryDays,
            deliveryTimes: [],
            selectableDays: deliveryDays,
            showTooltip: false,
        };
        var deliveryDay = deliveryDays && deliveryDays.find(function (d) { return moment(date.departureDate).format(DATE_FORMAT) === d.date; });
        var index = !deliveryDay
            ? -1
            : deliveryDay.deliveries.findIndex(function (d) { return d.stopTime === date.stopTime && d.stopDate === date.stopDate; });
        if (index > -1) {
            state.stopTime = date.stopTime;
            state.stopDate = moment(date.stopDate);
            state.selectedDayProps = deliveryDay;
            state.selectedDay = { date: moment(deliveryDay.date) };
            state.shippingTimeHeader =
                moment(date.departureDate).format(DATE_FORMAT) + ', ' + deliveryDay.deliveries[index].value;
            state.selectedIndex = index.toString();
        }
        var virtualDropdownItems = [];
        if (deliveryDays && deliveryDays.length > 0) {
            virtualDropdownItems = deliveryDays[0].deliveries.map(function (delivery, index) {
                return { text: delivery.value, value: index.toString() };
            });
        }
        state.deliveryTimes = virtualDropdownItems;
        _this.toggleTooltip = _this.toggleTooltip.bind(_this);
        _this.state = state;
        return _this;
    }
    DeliveryDates.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return nextProps.selectedDelivery !== this.props.selectedDelivery || !shallowEquals(this.state, nextState, true);
    };
    DeliveryDates.prototype.componentDidMount = function () {
        var _this = this;
        // Due to issue with height, we need to rerender the calendar
        // https://github.com/airbnb/react-dates/issues/46
        setTimeout(function () {
            _this.setState({
                activated: true,
            });
        });
    };
    DeliveryDates.prototype.componentWillReceiveProps = function (nextProps, nextState) {
        if (nextProps.selectedDelivery !== this.props.selectedDelivery) {
            this.setSelectedDepartureDate(nextProps.selectedDelivery);
        }
    };
    DeliveryDates.prototype.onDayMouseEnter = function (day) {
        this.setState({
            hoverDate: day,
        });
    };
    DeliveryDates.prototype.onDayMouseLeave = function () {
        this.setState({
            hoverDate: null,
        });
    };
    DeliveryDates.prototype.isOutsideRange = function (day) {
        if (!!this.state.deliveryDays) {
            return !this.state.deliveryDays.find(function (d) {
                return day.format(DATE_FORMAT) === d.date;
            });
        }
        return true;
    };
    DeliveryDates.prototype.isHovered = function (day) {
        return day.isSame(this.state.hoverDate, 'day');
    };
    DeliveryDates.prototype.isSelected = function (day) {
        if (this.state.selectedDay) {
            return day.isSame(this.state.selectedDay.date, 'day');
        }
        return false;
    };
    DeliveryDates.prototype.isDaySelectable = function (day) {
        if (!!this.state.deliveryDays) {
            this.state.deliveryDays.find(function (d) {
                if (d.deliveries.length > 0) {
                    return day.format(DATE_FORMAT) === d.date;
                }
            });
        }
        return false;
    };
    DeliveryDates.prototype.isCheckoutPage = function () {
        var pageType = this.props.componentName.split('.').pop();
        return pageType == 'CheckoutPage';
    };
    DeliveryDates.prototype.onDateChange = function (date) {
        this.setState({ selectedDay: { date: date } });
        var deliveryDay = this.state.deliveryDays &&
            this.state.deliveryDays.find(function (d) { return moment(date.format(DATE_FORMAT)).isSame(moment(d.date)); });
        if (deliveryDay) {
            if (!this.isCheckoutPage()) {
                SetDeliveryDate(deliveryDay.date);
            }
            this.setState({
                stopTime: deliveryDay.deliveries[0].stopTime,
                stopDate: moment(deliveryDay.deliveries[0].stopDate),
                selectedDayProps: deliveryDay,
                selectedDay: { date: moment(deliveryDay.date) },
                shippingTimeHeader: deliveryDay.date + ', ' + deliveryDay.deliveries[0].value,
                selectedIndex: '0',
            });
            this.selectDeliveryTimes(deliveryDay);
            this.persistDeparture(deliveryDay.deliveries[0]);
        }
    };
    DeliveryDates.prototype.onFocusChange = function () {
        // Force the focused states to always be truthy so that date is always selectable
        this.setState({ focused: true });
    };
    DeliveryDates.prototype.selectDeparture = function (val) {
        var day = this.state.selectedDayProps.deliveries[val];
        this.setState({
            stopTime: day.stopTime,
            stopDate: moment(day.stopDate),
            shippingTimeHeader: this.state.selectedDayProps.date + ', ' + day.value,
            selectedIndex: val,
        });
        this.persistDeparture(day);
    };
    DeliveryDates.prototype.persistDeparture = function (day) {
        // TODO - This is how the current date is currently sent to BE.
        // This part needs to be refactored through an endpoint and updated through a reducer
        // document.cookie = 'dep=' + day.stopDate + ' ' + day.stopTime + '; path=/';
        document.cookie = 'dep-id=' + day.id + '; path=/;SameSite=None;secure';
        replaceState(location.href);
        // Reload app shell is currently needed because based on current date, prices and products will vary - such as "vegatables"
        this.props.reloadAppShell();
    };
    DeliveryDates.prototype.setSelectedDepartureDate = function (date) {
        var deliveryDay = this.state.deliveryDays.find(function (d) { return moment(date.departureDate).format(DATE_FORMAT) === d.date; });
        var index = deliveryDay.deliveries.findIndex(function (d) { return d.stopTime === date.stopTime && d.stopDate === date.stopDate; });
        if (index > -1) {
            this.setState({
                stopTime: date.stopTime,
                stopDate: moment(date.stopDate),
                selectedDayProps: deliveryDay,
                selectedDay: { date: moment(deliveryDay.date) },
                shippingTimeHeader: deliveryDay.date + ', ' + deliveryDay.deliveries[index].value,
                selectedIndex: index.toString(),
            });
        }
        this.selectDeliveryTimes(deliveryDay);
    };
    DeliveryDates.prototype.selectDeliveryTimes = function (deliveryDay) {
        var virtualDropdownItems = [];
        if (deliveryDay) {
            virtualDropdownItems = deliveryDay.deliveries.map(function (delivery, index) {
                return { text: delivery.value, value: index.toString() };
            });
        }
        this.setState({
            deliveryTimes: virtualDropdownItems,
        });
    };
    DeliveryDates.prototype.render = function () {
        var _this = this;
        var isTooltip = this.props.currentBreakpoint > TINY;
        var myOrdersUrl = deliveryPageUrl();
        var calendar = (React.createElement("div", { className: this.props.className },
            !!!this.props.hideHeader && React.createElement("div", { className: classnames(styles.deliveryHeader, this.props.hasWrapper && styles.positionRelative) },
                React.createElement("h5", null, translate('/DeliveryDate/ChooseDateHeader'))),
            React.createElement(Section, { className: classnames(styles.shippingDateInfo, this.props.hasWrapper && styles.shippingDateInfo_Layout2) },
                React.createElement("div", null,
                    !this.props.hasWrapper && (React.createElement("div", { className: styles.closeDatePicker },
                        React.createElement("a", { onClick: function () { return _this.props.hideDeliveryDays(); } },
                            React.createElement(Icon, { name: "close" })))),
                    React.createElement("div", { className: classnames(styles.headerWrapper, this.props.hasWrapper && styles.headerLayout2) }, !!this.state.selectableDays ? (React.createElement("div", { className: styles.header },
                        React.createElement("b", null,
                            translate('/DeliveryDate/SelectedDeliveryHeader'),
                            ": "),
                        React.createElement("b", null, this.state.shippingTimeHeader),
                        !!this.state.stopDate && (React.createElement("p", null,
                            translate('/DeliveryDate/StopTime'),
                            ": ",
                            this.state.stopDate.format(DATE_FORMAT),
                            ",",
                            ' ',
                            this.state.stopTime)))) : (React.createElement("div", { className: classnames(styles.header, styles.error) },
                        React.createElement("b", null, translate('/DeliveryDate/NoDeliveryDatesFound')))))),
                React.createElement("div", { className: classnames(styles.helpButtonWrapper, this.props.hasWrapper && styles.pushup) },
                    React.createElement(Button, { className: styles.helpButton, appearance: "secondary", onClick: this.toggleTooltip },
                        React.createElement(Icon, { name: "question" })),
                    this.state.showTooltip && (React.createElement("div", { className: styles.tooltipWrapper },
                        React.createElement(Tooltip, { close: this.toggleTooltip, closeButton: true, closeOnClick: false, horizontal: "right" },
                            React.createElement("div", { className: styles.tooltip },
                                React.createElement("h4", null,
                                    translate('/DeliveryDate/ToolboxHeader'),
                                    ": "),
                                React.createElement("div", { className: styles.helpSectionButtonWrapper },
                                    React.createElement(Button, { className: styles.helpSelected, appearance: "round" },
                                        React.createElement("span", null, "10")),
                                    ' ',
                                    translate('/DeliveryDate/HelpSelected')),
                                React.createElement("b", null,
                                    translate('/DeliveryDate/HelptextAboutStop'),
                                    ": "),
                                React.createElement("p", null, translate('/DeliveryDate/HelpTextStopTime')),
                                React.createElement("b", null,
                                    translate('/DeliveryDate/HelptextAboutCalendar'),
                                    ": "),
                                React.createElement("p", null, translate('/DeliveryDate/HelpTextCalendar')),
                                React.createElement("b", null,
                                    translate('/DeliveryDate/HelptextAboutDeparture'),
                                    ": "),
                                React.createElement("p", null, translate('/DeliveryDate/HelpTextDeparture'))))))),
                this.state.activated && (React.createElement("div", { className: classnames(styles.datePicker, this.props.hasWrapper ? null : styles.ownWrapper) },
                    React.createElement(DayPickerSingleDateController, { date: this.state.selectedDay ? this.state.selectedDay.date : null, focused: this.state.focused, isOutsideRange: function (day) { return _this.isOutsideRange(day); }, onDateChange: function (day) { return _this.onDateChange(day); }, onFocusChange: function () { return _this.onFocusChange(); }, 
                        // isDayHighlighted={(day: any) => this.isDaySelectable(day)} TODO - implement when we have orders
                        enableOutsideDays: false }))),
                this.state.deliveryTimes.length > 1 && (React.createElement("div", { className: styles.dropdownWrapper },
                    React.createElement(DropDown, { options: this.state.deliveryTimes, value: this.state.selectedIndex, onChange: function (selection) { return _this.selectDeparture(selection); } }))),
                React.createElement("a", { href: myOrdersUrl, className: styles.myOrdersLink, onClick: function () { return _this.props.hideDeliveryDays(); } },
                    translate('/DeliveryDate/LinktoMyOrdersText'),
                    " ",
                    React.createElement(MyMenigoIcon, { name: "arrow" })))));
        return !!this.props.hasWrapper ? (calendar) : (React.createElement(Overlay, { enabled: isTooltip && this.props.deliveryDaysIsVisible, onClose: function () { return !_this.state.showTooltip && _this.props.hideDeliveryDays(); } }, calendar));
    };
    return DeliveryDates;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
    culture: state.appShellData.culture,
    componentName: state.currentPage.componentName
}); }, function (dispatch) { return ({
    toggleDeliveryDays: function () {
        dispatch(toggleDeliveryDays());
    },
    hideDeliveryDays: function () {
        dispatch(hideDeliveryDays());
    },
    showDeliveryDays: function () {
        dispatch(showDeliveryDays());
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
}); })(DeliveryDates);
