if (process.env.NODE_ENV !== 'production') {
    // Ensures that commonly used methods of console exists
    var methods = ['log', 'error', 'debug', 'warn', 'trace'];
    var console_1 = (window.console = window.console || {});
    var replacement_1 = console_1.log ||
        function () {
            /* noop */
        };
    methods.forEach(function (method) {
        if (typeof console_1[method] !== 'function') {
            console_1[method] = replacement_1;
        }
    });
}
