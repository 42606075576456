export var MYMENIGO_DROPDOWN_BOX_OPEN = 'MYMENIGO_DROPDOWN_BOX_OPEN';
export var MYMENIGO_DROPDOWN_BOX_CLOSE = 'MYMENIGO_DROPDOWN_BOX_CLOSE';
export var setMyMenigoDropdownOpen = function () {
    return {
        type: MYMENIGO_DROPDOWN_BOX_OPEN,
    };
};
export var setMyMenigoDropdownClose = function () {
    return {
        type: MYMENIGO_DROPDOWN_BOX_CLOSE,
    };
};
