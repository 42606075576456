var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { SMALL, MEDIUM } from 'Shared/breakpoints';
import Logotype from '../LogoType/LogoType';
import MobileQuickSearch from '../QuickSearch/Mobile';
import QuickSearch from '../QuickSearch';
import HamburgerButton from '../../../HamburgerButton';
import classnames from 'classnames';
import * as styles from './BottomBar.scss';
import MainMenu from '../MainMenu/MainMenu';
import DeliveryDatesButton from '../Buttons/DeliveryDates/DeliveryDatesButton';
import MiniCartButton from '../Buttons/MiniCart/MiniCartButton';
var isMobile = function (currentBreakpoint) { return currentBreakpoint <= SMALL; };
var BottomBar = function (props) {
    var _a;
    return (React.createElement("div", { className: classnames(styles.BottomBar, (_a = {}, _a[styles.small] = !!props.small, _a)) },
        React.createElement("div", { className: classnames(styles.layout, styles.space) },
            React.createElement("div", { className: classnames(styles.BottomBar__Column) },
                React.createElement(Logotype, { small: !!props.small, siteUrl: IS_APP ? '/' : props.mainMenu.siteUrl, breakpoints: props.currentBreakpoint }),
                !IS_APP && props.currentBreakpoint >= MEDIUM && (React.createElement("div", { className: styles.MenuWrapper },
                    React.createElement(MainMenu, { small: props.small, mainMenu: props.mainMenu, currentPage: props.currentPage })))),
            !IS_APP && props.isLoggedIn && props.currentBreakpoint < MEDIUM && React.createElement("div", { className: classnames(styles.BottomBar__Column, styles.BottomBar__Column__Mobile) },
                React.createElement(MiniCartButton, null)),
            !IS_APP && props.isLoggedIn && props.currentBreakpoint < MEDIUM && React.createElement("div", { className: classnames(styles.BottomBar__Column, styles.BottomBar__Column__Mobile) },
                React.createElement(DeliveryDatesButton, null)),
            React.createElement("div", { className: classnames(styles.BottomBar__Column, styles.BottomBar__Column__Search) }, !IS_APP &&
                isMobile(props.currentBreakpoint) ? (React.createElement(MobileQuickSearch, null)) : (React.createElement(QuickSearch, null))),
            !IS_APP && props.currentBreakpoint < MEDIUM && React.createElement("div", { className: classnames(styles.BottomBar__Column, styles.BottomBar__Column__Mobile) },
                React.createElement(HamburgerButton, __assign({}, props))))));
};
export default BottomBar;
