import React from 'react';
import * as styles from './autocomplete.scss';
import MyMenigoIcon from '../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import propertyValueFor from 'Shared/property-value-for';
export default (function (props) {
    return (React.createElement("div", { className: styles.newsBanner },
        React.createElement("div", { className: styles.iconColumn }, !!props.block.iconName && !!propertyValueFor(props.block.iconName) && React.createElement(MyMenigoIcon, { name: propertyValueFor(props.block.iconName).toLowerCase() })),
        React.createElement("div", { className: styles.headerColumn },
            React.createElement("strong", null, propertyValueFor(props.block.header))),
        React.createElement("div", { className: styles.textColumn },
            React.createElement("p", null, !!props.block.text && (propertyValueFor(props.block.text))),
            React.createElement("span", { className: styles.linkColumn }, !!props.block.link && (React.createElement("a", { href: propertyValueFor(props.block.link) },
                props.ctaText,
                " ",
                React.createElement(MyMenigoIcon, { name: 'arrow' })))))));
});
