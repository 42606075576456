/**
 * User interface specific imports
 *
 * This file is imported in entry.js, but the style guide uses this file as entry to separate it self from React stuff.
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as deviceType from './device-type';
import '../../styles';
var IS_IE_CLASS_NAME = 'ie';
var IS_FF_CLASS_NAME = 'ff';
var IS_IOS_CLASS_NAME = 'ios';
var IS_SF_CLASS_NAME = 'sf';
var defaultClassNames = [];
deviceType.isIE() && defaultClassNames.push(IS_IE_CLASS_NAME);
deviceType.isFF() && defaultClassNames.push(IS_FF_CLASS_NAME);
deviceType.isIOS() && defaultClassNames.push(IS_IOS_CLASS_NAME);
deviceType.isSF() && defaultClassNames.push(IS_SF_CLASS_NAME);
var rootClassList = document.documentElement.classList;
rootClassList.add.apply(rootClassList, __spread(defaultClassNames));
