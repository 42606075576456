import React from 'react';
import classnames from 'classnames';
import { isBrowser, externalSvgUseSupported } from 'Shared/device-type';
import { get } from 'Shared/server';
import * as styles from './base.scss';
var icons = require('../../../styles/images/icons.svg');
if (isBrowser() && !externalSvgUseSupported()) {
    if (process.env.NODE_ENV !== 'production') {
        console.log('SVG `<use>` with external reference not supported.');
    }
    if (process.env.NODE_ENV !== 'production') {
        console.log('Initiating SVG external resource embedding.');
    }
    get(icons)
        .then(function (response) { return response.text(); })
        .then(function (response) {
        document.body.insertAdjacentHTML('beforeend', "<div style=\"display:none;\">" + response + "</div>");
        if (process.env.NODE_ENV !== 'production') {
            console.log('SVG external resource successfully embedded to DOM.');
        }
    });
}
function href(name) {
    return (!IS_APP && externalSvgUseSupported() ? icons : '') + "#icon-" + name;
}
var translateMap = {
    'arrow-up': {
        name: 'arrow-right',
        appearance: 'rotate270',
    },
    'arrow-down': {
        name: 'arrow-right',
        appearance: 'rotate90',
    },
    'arrow-left': {
        name: 'arrow-right',
        appearance: 'rotate180',
    },
};
var flipIcons = {
    user: {
        appearance: 'flip',
    },
    question: {
        appearance: 'flip',
    },
    exclamation: {
        appearance: 'flip',
    },
    archive: {
        appearance: 'flip',
    },
    calendar: {
        appearance: 'flip',
    },
    krav: {
        appearance: 'large',
    },
    carrot: {
        appearance: 'large',
    },
    milk: {
        appearance: 'large-v',
    },
    glutenfree: {
        appearance: 'large',
    },
    keyhole: {
        appearance: 'large-h',
    },
    eko: {
        appearance: 'large',
    },
    fair: {
        appearance: 'extra-large',
    },
    missing: {
        appearance: 'large',
    },
};
var Icon = function (_a) {
    var name = _a.name, appearance = _a.appearance, className = _a.className, onClick = _a.onClick;
    var appearances = (typeof appearance === 'string' ? [appearance] : appearance || []);
    if (name in translateMap) {
        var translated = translateMap[name];
        name = translated.name;
        appearances.push(translated.appearance);
    }
    if (name in flipIcons) {
        var flipped = flipIcons[name];
        appearances.push(flipped.appearance);
    }
    return (React.createElement("svg", { className: classnames(styles.base, className, appearances.map(function (appearance) { return styles[appearance]; })), onClick: onClick },
        React.createElement("use", { xlinkHref: href(name) })));
};
export default Icon;
