import React from 'react';
import connect from 'Shared/connect';
import { searchPageUrl } from 'Shared/known-urls';
import { urlToString } from 'Shared/url';
import classnames from 'classnames';
import highlightText from 'Shared/highlight-text';
import { translate } from 'Shared/translate';
import { updateSelectedCompletion, updateQuickSearchCart } from '../action-creators';
import { SMALL } from 'Shared/breakpoints';
import { addToCart } from './../../Cart/action-creators';
import { SEARCH_HISTORY_LIST_ID, POPULAR_SEARCHES_LIST_ID, SUGGESTIONS_LIST_ID, searchTextToWords, } from './../action-creators';
import ProductCardList from 'Shared/ProductCard/List';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import * as mobileCardListStyles from 'Shared/ProductCard/Mobile/base.scss';
import * as productCardListStyles from 'Shared/ProductCard/List/base.scss';
import * as styles from './autocomplete.scss';
var Autocompletions = function (props) {
    var isMobile = props.currentBreakpoint < SMALL;
    return (React.createElement("div", { className: classnames(styles.base, props.className, 'ui-test-autocomplete') },
        props.lists.map(function (list, index) {
            var _a;
            return list.products &&
                list.products.length > 0 && (React.createElement("div", { key: index },
                React.createElement(ProductCardList, { className: classnames((_a = {},
                        _a[productCardListStyles.quickSearchList] = !isMobile,
                        _a[mobileCardListStyles.quickSearchList] = isMobile,
                        _a)), products: list.products, viewLayoutType: viewLayoutTypes.list.name, wrapCard: function (card) { return React.createElement("div", { key: card.key }, card); }, 
                    // TODO: Remove "temporary" when search is rebuilt
                    temporary // enable "temporary" when you want to hide AddRemoveFromList in QuickSearch
                    : true })));
        }),
        React.createElement("div", { className: styles.sectionLists },
            !props.thinSelection &&
                props.lists.map(function (list, i) {
                    return !!list.content &&
                        list.content.length > 0 && (React.createElement("div", { className: styles.section, key: list.id + i },
                        React.createElement("h4", null, translate('/Search/Content')),
                        React.createElement("ul", null, list.content.map(function (item, i) {
                            return (React.createElement("li", { key: item.ticket + i },
                                React.createElement("a", { href: urlToString({ path: '/' + item.url }), "data-ticket": item.ticket, onClick: function () { return props.onItemClick(item.url); } }, list.id !== SEARCH_HISTORY_LIST_ID
                                    ? highlightText(item.title, searchTextToWords(props.searchText))
                                    : item.title)));
                        }))));
                }),
            !props.thinSelection &&
                props.lists.map(function (list, i) {
                    return !!list.items &&
                        list.items.length > 0 && (React.createElement("div", { className: styles.section, key: list.id + i },
                        React.createElement("h4", null, listIdToName(list.id)),
                        React.createElement("ul", null, list.items.map(function (item, i2) {
                            return (React.createElement("li", { key: item.text + i2 },
                                React.createElement("a", { href: urlToString({ path: searchPageUrl(), query: { q: item.text } }), "data-ticket": item.ticket, onClick: function () { return props.onItemClick(item.text); } }, list.id !== SEARCH_HISTORY_LIST_ID
                                    ? highlightText(item.text, searchTextToWords(props.searchText))
                                    : item.text)));
                        }))));
                }))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch) { return ({
    onFocusQuantity: function (index) {
        dispatch(updateSelectedCompletion(index, 'product'));
    },
    updateCartItemQuantity: function (code, quantity, date, unitShortName, ticket) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, 'QuickSearch'));
    },
    updateUnit: function (items, ticket) {
        var code = items && Object.keys(items)[0];
        return dispatch(updateQuickSearchCart(code, items[code].newQuantity, items[code].newUnitShortName, ticket));
    },
}); })(Autocompletions);
function listIdToName(id) {
    switch (id) {
        case SEARCH_HISTORY_LIST_ID: {
            return translate('/QuickSearch/PreviousSearches');
        }
        case POPULAR_SEARCHES_LIST_ID: {
            return translate('/QuickSearch/PopularSearches');
        }
        case SUGGESTIONS_LIST_ID: {
            return translate('/QuickSearch/Suggestions');
        }
        default: {
            throw new Error('Unknown list id: ' + id);
        }
    }
}
