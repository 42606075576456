var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Part } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import UserInfoBox from '../../Shared/UserInfoBox/UserInfoBox';
import MyMenigoLayout from '../MyMenigoLayout';
var MyMenigoOverviewPage = /** @class */ (function (_super) {
    __extends(MyMenigoOverviewPage, _super);
    function MyMenigoOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    MyMenigoOverviewPage.prototype.render = function () {
        var preamble = (React.createElement(React.Fragment, null,
            React.createElement("h1", null,
                translate('/MyMenigo/Overview/Greeting'),
                ", ",
                React.createElement("span", null,
                    this.props.loggedInName,
                    "!")),
            React.createElement(PropertyFor, { tagName: "p", property: this.props.page.preamble })));
        var userInfo = (React.createElement(Part, { className: styles.heroBlock__userInfo },
            React.createElement(UserInfoBox, { menuItems: this.props.myMenigoItems })));
        return (React.createElement(MyMenigoLayout, { topLeft: preamble, topRight: userInfo },
            React.createElement(PropertyFor, { className: styles.fullChildBlock__item, property: this.props.page.mainContent })));
    };
    return MyMenigoOverviewPage;
}(React.Component));
export default connect(function (state) { return ({
    loggedInName: state.currentUser.fullName,
    myMenigoItems: state.appShellData.header.myMenigoItems,
}); })(MyMenigoOverviewPage);
