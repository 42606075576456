import componentRegistry from './component-registry';
export default (function (componentName) {
    var Component = componentRegistry[componentName];
    if (!Component) {
        if (process.env.NODE_ENV !== 'production') {
            throw new Error("Component '" +
                componentName +
                "' does not exist. " +
                'Did you forget to build the frontend or run jake.cmd frontend:build-component-registry?');
        }
        else {
            throw new Error("Component '" + componentName + "' does not exist.");
        }
    }
    return Component;
});
