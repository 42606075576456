import React from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import { toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import * as styles from './base.scss';
function HamburgerButton(props) {
    var _a;
    return (React.createElement(Button, { type: "button", className: classnames(styles.menu, (_a = {},
            _a[styles.open] = props.mainMenuIsOpen,
            _a)), onClick: props.toggleMainMenu },
        React.createElement("span", { className: styles.nav },
            React.createElement(Icon, { name: "nav" })),
        React.createElement("span", { className: styles.close },
            React.createElement(Icon, { name: "close" }))));
}
export default connect(function (state) { return ({
    mainMenuIsOpen: state.mainMenu.isOpen,
}); }, function (dispatch) { return ({
    toggleMainMenu: function (e) {
        dispatch(toggleMainMenu());
    },
}); })(HamburgerButton);
