import React from "react";
import * as styles from '../../base.scss';
;
import { translate } from "Shared/translate";
import classnames from "classnames";
import { ListType } from "../DeliveryList/DeliveryList";
import ProductImage from "Shared/ProductComponents/ProductImage";
var DetailedProductRow = function (_a) {
    var item = _a.item, listType = _a.listType;
    var unitNames = {
        st: translate('/Units/UnitNames/st'),
        frp: translate('/Units/UnitNames/frp'),
        kg: translate('/Units/UnitNames/kg'),
        kgd: translate('/Units/UnitNames/kgd'),
    };
    var getProductUnit = function (unit) {
        var translatedUnit = unitNames[unit.replace("/", "")];
        return !!translatedUnit && translatedUnit;
    };
    return (React.createElement("div", { className: classnames(styles.Products__Row, styles.Products__Row__Border, (listType == ListType.Planned ? styles.planned : styles.completed)) },
        React.createElement("div", { className: styles.Products__Cell }, !!item.categoryName && item.categoryName.replace(/\d+$/, "")),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement(ProductImage, { key: "img_" + item.orderNumber + item.articleNumber, imageUrl: item.imageUrl, displayName: item.itemDescription, size: "small" })),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement("span", { className: styles.Title }, item.itemDescription),
            React.createElement("br", null),
            React.createElement("span", { className: styles.Description },
                translate("/MyMenigo/DeliveryBlock/ProductList/ArticleNumber"),
                " ",
                item.articleNumber)),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement("span", { className: styles.Title }, getProductUnit(item.deliveredUnit.toLowerCase())),
            React.createElement("span", { className: styles.Description }, item.packageInformation)),
        (listType === ListType.Completed || !!item.deliveredQuantity) &&
            React.createElement("div", { className: styles.Products__Cell },
                React.createElement("span", { className: styles.Products__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/ProductList/DeliveredQuantity")),
                item.deliveredQuantity),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement("span", { className: styles.Products__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/ProductList/OrderedQuantity")),
            item.orderedQuantity),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement("span", { className: styles.Products__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/ProductList/PurchasePrice")),
            item.purchasePrice.toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            " ",
            translate('/Price/CurrencySymbol')),
        React.createElement("div", { className: styles.Products__Cell },
            React.createElement("span", { className: styles.Products__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/ProductList/OrderNumber")),
            !!item.orderNumber && item.orderNumber.replace(/^0+(\d)/, '$1'))));
};
export default DetailedProductRow;
