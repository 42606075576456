var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './details.scss';
import classnames from 'classnames';
import Price from 'Shared/Price';
import DetailDesktopHeaders from './DetailDesktopHeaders';
import { OrderProduct } from '../OrderProduct';
import { translate } from 'Shared/translate';
import { price as loadingPrice } from '../../../loading.scss';
import Button from 'Shared/Button';
import { reloadCart } from 'Features/Cart/action-creators';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import connect from 'Shared/connect';
import { postJson } from 'Shared/server';
import Spinner from 'Shared/Spinner';
var OrderDetails = /** @class */ (function (_super) {
    __extends(OrderDetails, _super);
    function OrderDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selectedItems: new Map(),
            isLoading: false
        };
        return _this;
    }
    OrderDetails.prototype.addToSelectedItems = function (e, props) {
        this.addOrRemoveSelectedItems(e.target.checked, props);
    };
    OrderDetails.prototype.addOrRemoveSelectedItems = function (isSelected, item) {
        var id = item.articleNumber;
        var itemsMap = this.state.selectedItems;
        if (isSelected && !itemsMap.has(id)) {
            itemsMap.set(id, item);
        }
        else if (itemsMap.has(id)) {
            itemsMap.delete(id);
        }
        this.setState({
            isHeaderSelected: this.state.selectedItems.size === this.props.OrderDetailsList.length,
            selectedItems: itemsMap,
        });
    };
    OrderDetails.prototype.triggerSelectAllFromChildAction = function (isSelect) {
        this.setState({ isHeaderSelected: isSelect });
    };
    OrderDetails.prototype.selectAllOrderItems = function () {
        if (this.state.isHeaderSelected) {
            this.setState({ isHeaderSelected: false, selectedItems: new Map() });
        }
        else {
            var items_1 = new Map();
            this.props.OrderDetailsList.map(function (data) {
                items_1.set(data.articleNumber, data);
            });
            this.setState({ isHeaderSelected: !this.state.isHeaderSelected, selectedItems: items_1 });
        }
    };
    OrderDetails.prototype.copyToCart = function () {
        var _this = this;
        var orderItems = [];
        this.setState({
            isLoading: true,
        });
        this.props.OrderDetailsList.map(function (orderDetail) {
            var articleNumber = orderDetail.articleNumber, unit = orderDetail.unit, quantity = orderDetail.quantity;
            var order = {
                item: articleNumber,
                unitShortName: unit,
                quantity: quantity
            };
            orderItems.push(order);
        });
        var url = "/orderoverviewblock/CopyOrder";
        postJson(url, { orderItems: orderItems })
            .then(function (result) {
            _this.setState({
                isLoading: false,
            });
            if (result.success) {
                _this.props.setFeedback({ text: translate('/MyMenigo/OrderOverviewHeaders/CopyOrderSuccess'), type: 'success' });
            }
            else {
                _this.props.setFeedback({ text: translate('/MyMenigo/OrderOverviewHeaders/CopyOrderError') + ':' + result.message, type: 'error' });
            }
            _this.props.reloadCart();
        });
    };
    OrderDetails.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var webOrder = translate('/MyMenigo/OrderOverviewHeaders/OrderTypeKey/wb');
        var desktopNode = (React.createElement("div", { className: classnames(styles.item, (_a = {},
                _a[styles.onShow] = this.props.isItemShown,
                _a)) },
            React.createElement(DetailDesktopHeaders, { currentTab: this.props.currentTab, currentGrouping: this.props.currentGrouping, selectAllOrderItems: function () { return _this.selectAllOrderItems(); }, isHeaderSelected: this.state.isHeaderSelected, isSelectable: this.props.isSelectable }),
            React.createElement(OrderProduct, { orderProducts: this.props.OrderDetailsList, currentTab: this.props.currentTab, selectedItems: this.state.selectedItems, currentGrouping: this.props.currentGrouping, isSelectable: this.props.isSelectable, addToSelectedItems: this.addToSelectedItems, isMobile: false }),
            React.createElement("div", { className: styles.orderDetailsSummary },
                (this.props.type === webOrder) &&
                    React.createElement(Button, { onClick: function () { return _this.copyToCart(); }, appearance: 'primary' }, translate('/MyMenigo/OrderOverviewHeaders/CopyOrderToCart')),
                React.createElement("div", { className: styles.checkBox }),
                React.createElement("div", { className: styles.orderDetailsSummaryTitle },
                    translate('/MyMenigo/OrderOverviewHeaders/OrderSummary'),
                    ":",
                    ' '),
                React.createElement("div", null,
                    React.createElement(Price, { className: loadingPrice, current: this.props.TotalOrderPrice, size: "huge", currencySymbol: translate('/Price/CurrencySymbol') })))));
        var mobileNode = (React.createElement("div", { className: classnames(styles.mobileRowItem, (_b = {},
                _b[styles.onShow] = this.props.isItemShown,
                _b)) },
            React.createElement(OrderProduct, { orderProducts: this.props.OrderDetailsList, currentTab: this.props.currentTab, selectedItems: this.state.selectedItems, currentGrouping: this.props.currentGrouping, isSelectable: this.props.isSelectable, addToSelectedItems: this.addToSelectedItems, isMobile: true }),
            React.createElement("div", { className: styles.mobileBottomSummary },
                React.createElement("div", { className: styles.mobileOrderDetailsSummary },
                    React.createElement("div", null,
                        translate('/MyMenigo/OrderOverviewHeaders/OrderSummary'),
                        ":"),
                    React.createElement(Price, { className: classnames(loadingPrice, styles.totalOrderPrice), current: this.props.TotalOrderPrice, size: "large", currencySymbol: translate('/Price/CurrencySymbol') })),
                (this.props.type === webOrder) && (React.createElement("div", { className: styles.mobileBottomCopyToCart },
                    React.createElement(Button, { onClick: function () { return _this.copyToCart(); }, appearance: 'primary' }, translate('/MyMenigo/OrderOverviewHeaders/CopyOrderToCart')))))));
        return !!this.props.isItemShown && this.props.isExtensive ?
            (this.state.isLoading ? React.createElement(Spinner, { className: styles.spinner }) : desktopNode)
            : mobileNode;
    };
    return OrderDetails;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    reloadCart: function () {
        dispatch(reloadCart());
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    }
}); })(OrderDetails);
