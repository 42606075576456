import { GetIcons } from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var getMenuIcon = function (item, categoryIcons) {
    var name = item.displayName.toLowerCase();
    var formatIconName = function (s) {
        if (typeof s !== 'string')
            return '';
        return s.charAt(0).toLowerCase() + s.slice(1);
    };
    if (!!!item)
        return null;
    if (!!categoryIcons && !!categoryIcons[item.code])
        return formatIconName(categoryIcons[item.code]);
    else
        return GetIcons(name);
};
export default getMenuIcon;
