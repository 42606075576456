var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var _a;
import { updateCurrentPage } from './../../SiteLayout/CurrentPage/action-creators';
import { getJson, post, postJson } from 'Shared/server';
import { setFeedback } from './../../SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import { isFavoritesPage, isPantriesPage } from './current-page-is';
import propertyValueFor from 'Shared/property-value-for';
import { replaceState } from 'Shared/history';
export var INIT_ORDER = 'INIT_ORDER';
export var INIT_DIALOG_LISTS = 'INIT_DIALOG_LISTS';
export var ADD_ORDER_TO_CART = 'ADD_ORDER_TO_CART';
export var UPDATE_ITEM = 'UPDATE_ITEM';
export var CLEAR_ITEMS = 'CLEAR_ITEMS';
export var TOGGLE_LIST_NAME_EDITING = 'TOGGLE_LIST_NAME_EDITING';
export function addOrderToCart() {
    return function (dispatch, getState) {
        dispatch({
            type: ADD_ORDER_TO_CART,
        });
    };
}
export function initList(dialog) {
    return {
        type: INIT_DIALOG_LISTS,
        dialog: dialog,
    };
}
export function initOrder(products) {
    return {
        type: INIT_ORDER,
        products: products,
    };
}
export function updateItem(productId, item) {
    return {
        type: UPDATE_ITEM,
        productId: productId,
        item: item,
    };
}
export function clearItems() {
    return {
        type: CLEAR_ITEMS,
    };
}
export var sortLists = function (a, b) {
    return a.displayName.toLocaleLowerCase() > b.displayName.toLocaleLowerCase()
        ? 1
        : a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase()
            ? -1
            : 0;
};
var SORT_TITLE_DESC = 'SORT_TITLE_DESC';
var SORT_TITLE_ASC = 'SORT_TITLE_ASC';
var SORT_ARTNR_DESC = 'SORT_ARTNR_DESC';
var SORT_ARTNR_ASC = 'SORT_ARTNR_ASC';
var SORT_CUSTOMORDER_DESC = 'SORT_CUSTOMORDER_DESC';
var SORT_CUSTOMORDER_ASC = 'SORT_CUSTOMORDER_ASC';
var SORT_AREA_DESC = 'SORT_AREA_DESC';
var SORT_AREA_ASC = 'SORT_AREA_ASC';
export var DEFAULT_SORT = SORT_CUSTOMORDER_DESC;
export var SortOrder = {
    SORT_TITLE_DESC: SORT_TITLE_DESC,
    SORT_TITLE_ASC: SORT_TITLE_ASC,
    SORT_ARTNR_DESC: SORT_ARTNR_DESC,
    SORT_ARTNR_ASC: SORT_ARTNR_ASC,
    SORT_CUSTOMORDER_DESC: SORT_CUSTOMORDER_DESC,
    SORT_CUSTOMORDER_ASC: SORT_CUSTOMORDER_ASC,
    SORT_AREA_DESC: SORT_AREA_DESC,
    SORT_AREA_ASC: SORT_AREA_ASC,
};
var customSort = function (sortBy, first, second) {
    var a = sortBy === SORT_CUSTOMORDER_DESC ? first : second;
    var b = sortBy === SORT_CUSTOMORDER_DESC ? second : first;
    var result = (a.ordinal == null || a.ordinal <= 0) && (b.ordinal == null || b.ordinal <= 0)
        ? 0
        : a.ordinal == null || a.ordinal <= 0
            ? 1
            : b.ordinal == null || b.ordinal <= 0
                ? -1
                : a.ordinal > b.ordinal
                    ? 1
                    : a.ordinal < b.ordinal
                        ? -1
                        : 0;
    if (result === 0) {
        result =
            SORT_CUSTOMORDER_DESC
                ? a.title.localeCompare(b.title, 'sv', { sensitivity: 'accent' })
                : b.title.localeCompare(a.title, 'sv', { sensitivity: 'accent' });
    }
    return result;
};
var lower = function (str) {
    return (str == null ? '' : str).toLocaleLowerCase();
};
export var sortFuncs = (_a = {},
    _a[SORT_TITLE_ASC] = function (a, b) { return b.title.localeCompare(a.title, 'sv', { sensitivity: 'accent' }); },
    _a[SORT_TITLE_DESC] = function (a, b) {
        return a.title.localeCompare(b.title, 'sv', { sensitivity: 'accent' });
    },
    _a[SORT_ARTNR_ASC] = function (a, b) { return (a.code > b.code ? -1 : a.code < b.code ? 1 : 0); },
    _a[SORT_ARTNR_DESC] = function (a, b) { return (a.code > b.code ? 1 : a.code < b.code ? -1 : 0); },
    _a[SORT_CUSTOMORDER_DESC] = function (a, b) { return customSort(SORT_CUSTOMORDER_DESC, a, b); },
    _a[SORT_CUSTOMORDER_ASC] = function (a, b) { return customSort(SORT_CUSTOMORDER_ASC, a, b); },
    _a[SORT_AREA_ASC] = function (a, b) {
        return lower(a.area) > lower(b.area) ? -1 : lower(a.area) < lower(b.area) ? 1 : 0;
    },
    _a[SORT_AREA_DESC] = function (a, b) {
        return lower(a.area) > lower(b.area) ? 1 : lower(a.area) < lower(b.area) ? -1 : 0;
    },
    _a);
export var setSpinner = function (listId) {
    return updateCurrentPage(function (current) {
        return Object.assign(current, {
            lists: current.lists.map(function (list) {
                return listId !== list.id
                    ? list
                    : Object.assign({}, list, {
                        isLoading: true,
                    });
            }),
        });
    });
};
export var createNewList = function (displayName) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/create"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/create";
    return postJson(url, validateDisplayName(displayName))
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                return Object.assign(current, { lists: __spread(current.lists, [result.value]).sort(sortLists) });
            }));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
var insertListFromResponse = function (list, res) { return Object.assign({}, list, res, {
    sortBy: DEFAULT_SORT,
    products: res.products.map(function (item) { return Object.assign({}, item); }).sort(sortFuncs[list.sortBy || DEFAULT_SORT]),
    isLoaded: true,
    isLoading: false,
    selected: list.selected,
}); };
function updateProductInList(currentList, products) {
    products.forEach(function (product) {
        var index = currentList.findIndex(function (p) { return p.code === product.code && p.isCustom === product.isCustom; });
        if (index !== -1) {
            var current = currentList[index];
            var mergedProduct = Object.assign({}, product, {
                quantity: current.quantity,
                ordinal: current.ordinal,
            });
            currentList.splice(index, 1, mergedProduct);
        }
        else {
            console.error("Could not find " + product.code);
        }
    });
    return currentList;
}
var updatePrices = function (listId, updatedProducts) { return function (dispatch) {
    return dispatch(updateCurrentPage(function (current) {
        return editList(current, listId, function (list) {
            return Object.assign({}, list, { products: updateProductInList(list.products, updatedProducts) });
        });
    }, true));
}; };
var getPrices = function (url, page) { return function (dispatch) {
    return getJson(url + "&page=" + page)
        .then(function (res) {
        if (res.id != null && res.products && res.products.length > 0) {
            dispatch(updatePrices(res.id, res.products));
            dispatch(getPrices(url, ++page));
        }
        else {
            return Promise.reject("Bad response getting prices for: " + url + "&page=" + page);
        }
    })
        .catch(function (e) { return console.warn(e); });
}; };
export var getDetails = function (listId, defaultDisabled) {
    if (defaultDisabled === void 0) { defaultDisabled = false; }
    return function (dispatch, getState) {
        dispatch(setSpinner(listId));
        var isFavorite = isFavoritesPage(getState());
        var url = isFavorite
            ? getState().appShellData.siteSettings.favoriteOverviewPage + "/details?id=" + listId
            : getState().appShellData.siteSettings.pantryOverviewPage + "/details?pantryId=" + listId;
        return getJson("" + url)
            .then(function (res) {
            return dispatch(updateCurrentPage(function (current) {
                editList(current, res.id, function (list) { return insertListFromResponse(list, res); });
            }, true));
        })
            .catch(failedPromiseHandler);
    };
};
export var sortList = function (listId, order) { return function (dispatch, getState) {
    return dispatch(updateCurrentPage(function (current) {
        return editList(current, listId, function (list) {
            return Object.assign({}, list, {
                products: list.products.sort(sortFuncs[order]),
                sortBy: order,
            });
        });
    }, true));
}; };
export var updateSelectedVariant = function (listId, code, unitShortName) { return function (dispatch, getState) {
    return isFavoritesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                lists: current.lists.map(function (list) {
                    return list.id !== listId
                        ? list
                        : !list.products
                            ? list
                            : Object.assign({}, list, {
                                products: list.products.map(function (item) {
                                    return item.code !== code
                                        ? Object.assign({}, item)
                                        : Object.assign({}, item, {
                                            selectedVariant: item.variants.find(function (v) { return v.unitShortName === unitShortName; }),
                                        });
                                }),
                            });
                }),
            });
        }));
}; };
export var updateUnit = function (listId, code, selectedVariant) { return function (dispatch, getState) {
    return dispatch(updateCurrentPage(function (current) {
        return editProductInList(current, listId, function (product) { return Object.assign({}, product, { selectedVariant: selectedVariant }); }, code);
    }, true));
}; };
export var updateQuantity = function (listId, code, quantity) { return function (dispatch, getState) {
    return dispatch(updateCurrentPage(function (current) {
        return editProductInList(current, listId, function (product) { return Object.assign({}, product, { quantity: quantity }); }, code);
    }, true));
}; };
export var updateOrdinal = function (listId, code, ordinal) { return function (dispatch, getState) {
    return dispatch(updateCurrentPage(function (current) {
        return editProductInList(current, listId, function (product) { return Object.assign({}, product, { ordinal: ordinal }); }, code);
    }, true));
}; };
export var resetList = function (listId) { return function (dispatch, getState) {
    return dispatch(updateCurrentPage(function (current) {
        return editProductInList(current, listId, function (product) {
            return !!product.quantity ? Object.assign({}, product, { quantity: null }) : product;
        });
    }, true));
}; };
export var searchInList = function (listId, input) { return function (dispatch, getState) {
    return isPantriesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            editList(current, listId, function (list) { return Object.assign({}, list, { searchQuery: input }); });
        }, true));
}; };
export var toggleListNameEditing = function (listId) { return ({ type: TOGGLE_LIST_NAME_EDITING, listId: listId }); };
export var initLists = function () { return function (dispatch, getState) {
    return getJson(getState().appShellData.siteSettings.favoriteOverviewPage + "/dialog")
        .then(function (dialog) { return dispatch({ type: INIT_DIALOG_LISTS, dialog: dialog }); })
        .catch(failedPromiseHandler);
}; };
export var renameList = function (listId, displayName) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/rename"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/rename";
    return postJson(url, { listId: listId, displayName: displayName })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                editList(current, listId, function (list) { return Object.assign({}, list, { displayName: displayName }); });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var removeList = function (listId) {
    return updateCurrentPage(function (current) {
        return Object.assign(current, {
            lists: current.lists.filter(function (list) { return list.id !== listId; }),
        });
    }, true);
};
export var removeListRequest = function (listId) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/delete"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/delete";
    return postJson(url, listId)
        .then(function (result) {
        if (result.success) {
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var createListAndAddProduct = function (newName, code, isFavorite) { return function (dispatch, getState) {
    var url = isFavorite
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/CreateListAndAddProduct"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/CreateListAndAddProduct";
    return postJson(url, { newName: validateDisplayName(newName), code: code })
        .then(function (res) {
        if (!res.success) {
            dispatch(setFeedback({
                text: res.message,
                type: 'error',
            }));
            return Promise.reject('Failed to create list and add product');
        }
        dispatch(setFeedback({ text: res.message, type: 'success', }));
        return replaceState().then(function () { return Promise.resolve(); });
    })
        .catch(failedPromiseHandler);
}; };
export var addProductToFavoriteList = function (listId, code) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.favoriteOverviewPage + "/addProduct", { listId: listId, code: code })
        .then(function (result) {
        if (result.success) {
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return replaceState().then(function () { return Promise.resolve(); });
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var addProductToPantryList = function (listId, code) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/addProduct", { listId: listId, code: code })
        .then(function (result) {
        if (result.success) {
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return replaceState().then(function () { return Promise.resolve(); });
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var addCustomProductToPantryList = function (listId, code) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/addCustomProduct", { listId: listId, code: code })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                return editList(current, listId, function (list) { return insertListFromResponse(list, result.value); });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve(result);
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var updateCustomProductsInPantryList = function (listId) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/getPantry", { listId: listId })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                return editList(current, listId, function (list) { return insertListFromResponse(list, result.value); });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve(result);
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    }).catch(failedPromiseHandler);
}; };
export var copyToList = function (listId, toListId, codes) { return function (dispatch, getState) {
    var isFavoritesPageState = isFavoritesPage(getState());
    var url = isFavoritesPageState
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/copy"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/Copy";
    return postJson(url, { listId: listId, codes: codes, toListId: toListId })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                return editList(current, toListId, function (list) { return insertListFromResponse(list, result.value); });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var copyToOtherList = function (listId, toListId, codes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/copyToPantry"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/copyToFavoriteList";
    return postJson(url, { listId: listId, codes: codes, toListId: toListId })
        .then(function (result) {
        if (result.success) {
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var copyToNewList = function (listId, displayName, codes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/copyToNew"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/copyToNew";
    return postJson(url, { listId: listId, codes: codes, displayName: validateDisplayName(displayName) })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                editList(current, listId, function (list) { return Object.assign({}, list, { selected: false }); });
                dispatch(setFeedback({ text: result.message, type: 'success' }));
                return Object.assign(current, { lists: __spread(current.lists, [result.value]).sort(sortLists) });
            }, true));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var copyToNewOtherList = function (listId, displayName, codes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/copyToNewPantry"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/copyToNewFavoriteList";
    return postJson(url, {
        listId: listId,
        codes: codes,
        displayName: validateDisplayName(displayName),
    })
        .then(function (result) {
        if (result.success) {
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var moveToList = function (listId, toListId, itemCodes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/move"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/move";
    var activeList = getState().currentPage.lists.find(function (list) { return list.id === listId; });
    var filterCheckedItemsIfSlaveList = function (list) {
        return list.products.filter(function (item) {
            if (list.isSubCustomerList) {
                return !item.isLocked && itemCodes.includes(item.selectedVariant.code);
            }
            else {
                return itemCodes.includes(item.selectedVariant.code);
            }
        });
    };
    var mapItemToCode = filterCheckedItemsIfSlaveList(activeList).map(function (item) { return item.selectedVariant.code; });
    var codes = mapItemToCode;
    return postJson(url, { listId: listId, codes: codes, toListId: toListId })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                editList(current, toListId, function (list) { return insertListFromResponse(list, result.value.targetList); });
                editList(current, listId, function (list) { return insertListFromResponse(list, result.value.fromList); });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var moveToNewList = function (listId, displayName, itemCodes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/moveToNew"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/moveToNew";
    var activeList = getState().currentPage.lists.find(function (list) { return list.id === listId; });
    var filterCheckedItemsIfSlaveList = function (list) {
        return list.products.filter(function (item) {
            if (list.isSubCustomerList) {
                return !item.isLocked && itemCodes.includes(item.selectedVariant.code);
            }
            else {
                return itemCodes.includes(item.selectedVariant.code);
            }
        });
    };
    var mapItemToCode = filterCheckedItemsIfSlaveList(activeList).map(function (item) { return item.selectedVariant.code; });
    var codes = mapItemToCode;
    return postJson(url, { listId: listId, codes: codes, displayName: validateDisplayName(displayName) })
        .then(function (result) {
        if (result.success) {
            if (Object.keys(result.value).length > 0) {
                dispatch(updateCurrentPage(function (current) {
                    editList(current, listId, function (list) {
                        return Object.assign({}, list, {
                            selected: false,
                            products: list.products.filter(function (product) { return !codes.includes(product.selectedVariant.code); }),
                        });
                    });
                    return Object.assign(current, { lists: __spread(current.lists, [result.value.targetList]).sort(sortLists) });
                }, true));
            }
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var removeProductsFromList = function (listId, codes) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/removeProducts"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/removeProducts";
    return postJson(url, { listId: listId, codes: codes }).then(function (result) {
        if (!!result.success && result.message)
            dispatch(setFeedback({ text: result.message, type: 'success' }));
        else
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
    }).then(function () {
        return dispatch(updateCurrentPage(function (current) {
            var oldList = current.lists.find(function (list) { return list.id === listId; });
            var newList = Object.assign({}, oldList, {
                products: oldList.products.filter(function (product) {
                    return !codes.some(function (code) { return product.selectedVariant.code === code; });
                }),
            });
            newList.isLoaded = newList.products.length > 0;
            newList.count = newList.products.length;
            return editList(current, listId, function (list) { return newList; });
        }, true));
    })
        .catch(failedPromiseHandler);
}; };
export var addProductToList = function (listId, product, pantry, status) { return function (dispatch, getState) {
    var url = !pantry
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/addproducts"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/addproducts";
    return postJson(url, { listId: listId, codes: [product.variants[0].code] }).then(function (result) {
        dispatch(setFeedback({ text: result.message, type: !result.success ? 'error' : 'success' }));
        if (!result.success)
            return Promise.reject(result);
    })
        .then(function () {
        return dispatch(updateCurrentPage(function (current) {
            var oldList = current.lists.find(function (list) { return list.id === listId; });
            var selectedVariant = !!product.selectedVariant ? product.selectedVariant : product.variants[0];
            var item = Object.assign({}, product, {
                code: propertyValueFor(product.content.code),
                variants: pantry ? [selectedVariant] : product.variants,
                selectedVariant: selectedVariant,
                isDisabled: pantry && !!status && status !== 'ongoing',
                previousQuantity: null,
                previousTotalPrice: 0,
                pantriesList: !!product.pantriesList ? Object.assign(product.pantriesList, { listId: oldList.displayName }) : { listId: oldList.displayName }
            });
            var newList = Object.assign({}, oldList);
            newList.products.unshift(item);
            newList.isLoaded = newList.products.length > 0;
            newList.count = newList.products.length;
            return editList(current, listId, function (list) { return newList; });
        }, true));
    }).catch(function (result) {
        dispatch(setFeedback({ text: result.message, type: 'error' }));
    });
}; };
export var lockList = function (listId, locked) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/lockFavorites"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/lockPantry";
    return postJson(url, { listId: listId, locked: locked })
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                editList(current, listId, function (list) {
                    return Object.assign({}, list, {
                        isLocked: locked,
                        isShared: true,
                        products: list.products,
                    });
                });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var shareList = function (listId) { return function (dispatch, getState) {
    var url = isFavoritesPage(getState())
        ? getState().appShellData.siteSettings.favoriteOverviewPage + "/shareFavorites"
        : getState().appShellData.siteSettings.pantryOverviewPage + "/sharePantry";
    return postJson(url, listId)
        .then(function (result) {
        if (result.success) {
            dispatch(updateCurrentPage(function (current) {
                editList(current, listId, function (list) {
                    return Object.assign({}, list, {
                        isLocked: false,
                        isShared: true,
                        products: list.products,
                    });
                });
            }, true));
            dispatch(setFeedback({ text: result.message, type: 'success' }));
            return Promise.resolve();
        }
        else {
            dispatch(setFeedback({ text: result.message ? result.message : translate('/Errors/Unknown'), type: 'error' }));
            return Promise.reject(result.message);
        }
    })
        .catch(failedPromiseHandler);
}; };
export var print = function (listId, sortBy) { return function (dispatch, getState) {
    return post(getState().appShellData.siteSettings.favoriteOverviewPage + "/setprintdata", [{ id: listId, sortBy: sortBy }]).then(function () {
        var url = window.location.href;
        var absolute = url.substr(0, url.indexOf('/'));
        window.open(absolute + getState().appShellData.siteSettings.favoriteOverviewPage + "/print", '_blank');
    });
}; };
function validateDisplayName(displayName) {
    var regex = new RegExp('[ ]{2,}');
    return displayName.replace(regex, ' ').trim();
}
function failedPromiseHandler(e) {
    console.error(e);
    return Promise.reject(null);
}
function editProduct(item, productMerger, codes) {
    if (!codes) {
        return productMerger(item);
    }
    else if (Array.isArray(codes) && codes.includes(item.selectedVariant.code)) {
        return productMerger(item);
    }
    else if (item.selectedVariant.code === codes) {
        return productMerger(item);
    }
    return item;
}
function editProductInList(current, listId, productMerger, codes) {
    return editList(current, listId, function (list) {
        return Object.assign({}, list, { products: list.products.map(function (p) { return editProduct(p, productMerger, codes); }) });
    });
}
function editList(currentPage, listId, listMerger) {
    return Object.assign(currentPage, {
        lists: currentPage.lists.map(function (list) { return (list.id !== listId ? list : listMerger(list)); }),
    });
}
