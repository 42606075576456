import React from 'react';
import classnames from 'classnames';
import * as styles from '../base.scss';
import Main from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import Info from 'Shared/Info';
var InfoBlock = function (props) {
    var _a;
    return (props.info &&
        React.createElement("div", { className: classnames(styles.infoRow, (_a = {}, _a[styles.infoRowCheckout] = props.isCheckout, _a)) },
            React.createElement(Main, null,
                React.createElement("div", null,
                    props.isDesktop && React.createElement("div", { className: styles.h2 }, translate(props.headerText)),
                    React.createElement(Info, { text: props.info })))));
};
export default InfoBlock;
