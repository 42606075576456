var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import * as styles from './textarea.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { translate } from 'Shared/translate';
export default (function (props) {
    var inputProps = Object.assign({}, props, {
        className: classnames(styles.base, props.className),
    });
    return (React.createElement(React.Fragment, null,
        !!props.label && React.createElement("label", { className: classnames(styles.inputLabel), htmlFor: props.id },
            props.label,
            " ",
            !!props.required && React.createElement("span", null, "*")),
        React.createElement("textarea", __assign({}, inputProps)),
        React.createElement("span", { className: styles.requiredField },
            React.createElement(MyMenigoIcon, { name: "alert" }),
            translate('/MyMenigo/Accounts/RequiredField'))));
});
