var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import React from 'react';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Logotype from 'Shared/Logotype';
import * as server from 'Shared/server';
import { translate } from 'Shared/translate';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
import { updateAppShellData } from '../../../AppShell/action-creators';
import { ENTER } from 'Shared/keyboard';
import { logout } from '../../action-creators';
import { searchUsers } from '../../action-creators';
import { Checkbox } from 'Shared/Fields';
import Modal from 'Shared/Modal';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import { pushState } from 'Shared/history';
var SupplierLoginPage = /** @class */ (function (_super) {
    __extends(SupplierLoginPage, _super);
    function SupplierLoginPage(props) {
        var _this = _super.call(this, props) || this;
        _this.hideModal = function () {
            _this.setState({ showModal: false });
        };
        _this.state = {
            searchInput: '',
            continueExhibitionOrder: !!props.activeExhibition,
            showModal: false,
            exhibitionName: '',
        };
        return _this;
    }
    SupplierLoginPage.prototype.keyPressCustomerInput = function (event) {
        switch (event.keyCode) {
            case ENTER:
                event.preventDefault();
                this.showOrderTypeModal(event);
                break;
            default:
                break;
        }
    };
    SupplierLoginPage.prototype.login = function (e, exhibition) {
        var _this = this;
        e.preventDefault();
        var customerId = this.state.searchInput;
        return server
            .postJson(pathCombine(location.pathname, '/login'), {
            customerId: customerId,
            exhibition: exhibition,
            keepExhibition: this.state.continueExhibitionOrder,
        })
            .then(function (result) {
            if (result.success) {
                _this.props.reloadAppShell();
                _this.hideModal();
                pushState('/');
                _this.currentTimeout = setInterval(function () {
                    _this.props.reloadAppShell().then(function (appShellData) {
                        if (!appShellData.currentUser.isSupplierSalesman) {
                            clearInterval(_this.currentTimeout);
                            _this.currentTimeout = null;
                        }
                    });
                }, 60000);
            }
            else {
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    SupplierLoginPage.prototype.showOrderTypeModal = function (ev) {
        var _this = this;
        var customerId = this.state.searchInput;
        return server
            .postJson(pathCombine(location.pathname, '/checkIfCustomerExist'), { customerId: customerId })
            .then(function (result) {
            if (result.success) {
                if (_this.state.continueExhibitionOrder &&
                    (_this.props.activeExhibition && _this.props.activeExhibition.length > 0)) {
                    _this.login(ev, true);
                }
                else if (result.exhibitionName.length > 0) {
                    _this.setState({ showModal: true, exhibitionName: result.exhibitionName });
                }
                else {
                    _this.login(ev, false);
                }
            }
            else {
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    SupplierLoginPage.prototype.doLogout = function () {
        var _this = this;
        return server.post(pathCombine(location.pathname, '/Logout')).then(function () {
            _this.props.logout();
        });
    };
    SupplierLoginPage.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.reset },
            React.createElement("div", { className: styles.supplierWrapper },
                React.createElement("div", { className: styles.logotypeWrapper },
                    React.createElement(Logotype, { className: styles.logotype, siteUrl: "/", disableLink: true }))),
            React.createElement(Main, { appearance: "narrow", className: styles.base },
                React.createElement(PropertyFor, { property: this.props.page.header, tagName: "h1" }),
                React.createElement(PropertyFor, { property: this.props.page.mainBody, tagName: "span", className: "myClass" }),
                React.createElement("form", null,
                    React.createElement("fieldset", null,
                        React.createElement("div", { className: styles.searchInputWrapper },
                            React.createElement("input", { value: this.state.searchInput, ref: function (el) { return (_this.customerSearch = el); }, placeholder: translate('/Account/SupplierLogin/AccountNumber'), onChange: function (e) { return _this.setState({ searchInput: e.target.value }); }, onKeyDown: function (e) { return _this.keyPressCustomerInput(e); } }),
                            this.props.activeExhibition && (React.createElement("div", { className: styles.continueExhibitionOrder },
                                React.createElement(Checkbox, { checked: this.state.continueExhibitionOrder, onChange: function (e) { return _this.setState({ continueExhibitionOrder: e.target.checked }); } }),
                                React.createElement("span", null, translate('/Account/SupplierLogin/ContinueExhibitionOrder', this.props.activeExhibition)))),
                            React.createElement("div", { className: styles.loginOut },
                                React.createElement(Button, { appearance: "full", onClick: function (e) { return _this.showOrderTypeModal(e); } }, translate('/Account/SupplierLogin/Login')),
                                React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.doLogout(); } }, translate('/Account/SupplierLogin/Logout')))))),
                React.createElement(Modal, { isOpen: this.state.showModal, onClose: this.hideModal, title: translate('/Account/SupplierLogin/SupplierModalHeader') },
                    React.createElement("div", null,
                        React.createElement("div", null, translate('/Account/SupplierLogin/OngoingExhibition', this.state.exhibitionName)),
                        React.createElement("div", null, translate('/Account/SupplierLogin/CreateOrderFOrCurrentExhibition'))),
                    React.createElement("div", { className: styles.secondChoice },
                        React.createElement(Button, { appearance: "full", onClick: function (e) { return _this.login(e, true); } }, translate('/Account/SupplierLogin/Exhibition')),
                        React.createElement(Button, { appearance: "full", onClick: function (e) { return _this.login(e, false); } }, translate('/Account/SupplierLogin/SupplierOrder')))))));
    };
    return SupplierLoginPage;
}(React.Component));
export default connect(function (state) { return ({
    activeExhibition: state.appShellData.currentUser.activeExhibition,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
    searchUsers: function (query) {
        return dispatch(searchUsers(query));
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
    logout: function () {
        return dispatch(logout());
    },
}); })(SupplierLoginPage);
