var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import propertyValueFor from 'Shared/property-value-for';
import { translate } from 'Shared/translate';
import ProductSearchHeader from './ProductSearch/Header';
import ProductSearchItem from './ProductSearch';
import * as productSearchStyles from './ProductSearch/base.scss';
import * as styles from './autocomplete.scss';
var AutocompletionsCustom = function (props) {
    var _a;
    var nonCustomProducts = !!props.products
        ? props.products.filter(function (item) { return !item.isCustom; })
        : [];
    var hasProducts = !!nonCustomProducts && nonCustomProducts.length > 0;
    var customProducts = !!props.products ? props.products.filter(function (item) { return !!item.isCustom; }) : [];
    var hasCustomProducts = !!customProducts && customProducts.length > 0;
    var pantriesList = props.isPantry ? props.currentPage.lists : props.currentPage.pantryLists;
    var favoritesList = !props.isPantry ? props.currentPage.lists : props.currentPage.favoritesLists;
    var pantryOrFavoritesList = props.isPantry ? pantriesList : favoritesList;
    var itemCodesInList = !props.recipeSearch &&
        pantryOrFavoritesList
            .map(function (list) {
            return list.products && list.id === props.listId && list.products.map(function (product) { return product.code; });
        })
            .filter(Boolean)[0];
    var handleOnClick = function (index) {
        props.setSelectedIndex(index);
        props.onClick(index);
    };
    return (React.createElement("div", { className: classnames(styles.base, productSearchStyles.productSearchList, props.className), onKeyDown: function (e) { return props.onKeyDown(e); } }, (!!hasProducts || !!customProducts) && (React.createElement("div", { className: classnames((_a = {}, _a[styles.sectionProducts] = !props.thinSelection, _a)) },
        (hasProducts || hasCustomProducts) && !props.recipeSearch && (React.createElement(ProductSearchHeader, { currentBreakpoint: props.currentBreakpoint })),
        !hasProducts && !hasCustomProducts && !props.isLoading && (React.createElement("div", { className: styles.noSearchResults }, translate('/QuickSearch/NoSearchResults'))),
        __spread(nonCustomProducts, customProducts).map(function (item, index) {
            var _a, _b;
            var itemCode = propertyValueFor(item.content.code);
            return index === nonCustomProducts.length ? (React.createElement("div", { key: 'wrapper_' + itemCode, onClick: function () { return handleOnClick(index); } },
                React.createElement("h4", { className: styles.searchHeading }, translate('/ProductListing/CustomProducts')),
                React.createElement("div", { className: classnames((_a = {},
                        _a[styles.active] = props.selectedIndex === index,
                        _a)) },
                    React.createElement(ProductSearchItem, { product: item, selectedVariant: item.variants[0], url: item.url ? item.url.toString() : '', discontinuedOrBackordered: item.isDiscontinued || item.isBackorderReplaced, onClick: props.onClick, onFocus: props.onFocus, rowIndex: index, key: itemCode, currentBreakpoint: props.currentBreakpoint, addToListDisabled: !props.recipeSearch && itemCodesInList.indexOf(itemCode) > -1, recipeSearch: props.recipeSearch, rowHasFocus: props.selectedIndex === index, addByIndex: props.addByIndex })))) : (React.createElement("div", { key: 'wrapper_' + itemCode + '_' + index, onClick: function () { return handleOnClick(index); } },
                React.createElement("div", { className: classnames((_b = {},
                        _b[styles.active] = props.selectedIndex === index,
                        _b)) },
                    React.createElement(ProductSearchItem, { product: item, selectedVariant: item.variants[0], url: item.url ? item.url.toString() : '', discontinuedOrBackordered: item.isDiscontinued || item.isBackorderReplaced, onClick: props.onClick, onFocus: props.onFocus, rowIndex: index, key: itemCode, currentBreakpoint: props.currentBreakpoint, addToListDisabled: !props.recipeSearch && itemCodesInList.indexOf(itemCode) > -1, recipeSearch: props.recipeSearch, rowHasFocus: props.selectedIndex === index, addByIndex: props.addByIndex }))));
        })))));
};
var AutocompletionsCustomConnected = connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    currentPage: state.currentPage,
}); })(AutocompletionsCustom);
export default AutocompletionsCustomConnected;
