import React from "react";
import * as styles from '../../base.scss';
;
import { translate } from "Shared/translate";
import classnames from "classnames";
import ProductImage from "Shared/ProductComponents/ProductImage";
var DetailedProductRow = function (_a) {
    var item = _a.item;
    var unitNames = {
        st: translate('/Units/UnitNames/st'),
        frp: translate('/Units/UnitNames/frp'),
        kg: translate('/Units/UnitNames/kg'),
        kgd: translate('/Units/UnitNames/kgd'),
    };
    var getProductUnit = function (unit) {
        var translatedUnit = unitNames[unit.replace("/", "")];
        return !!translatedUnit && translatedUnit;
    };
    return (React.createElement("div", { className: classnames(styles.OrderHistoryDetails__Row, styles.OrderHistoryDetails__Row__Border) },
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell }, !!item.categoryName && item.categoryName.replace(/\d+$/, "")),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell },
            React.createElement(ProductImage, { imageUrl: item.imageUrl, size: "tiny", displayName: item.itemDescription })),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell },
            React.createElement("span", { className: styles.Title }, item.itemDescription),
            React.createElement("br", null),
            React.createElement("span", { className: styles.Description },
                translate("/MyMenigo/OrderHistory/ProductList/ArticleNumber"),
                " ",
                item.articleNumber)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell },
            React.createElement("span", { className: styles.Title }, getProductUnit(item.unit.toLowerCase())),
            React.createElement("span", { className: styles.Description }, item.packageInformation)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell },
            React.createElement("span", { className: styles.OrderHistoryDetails__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/ProductList/OrderedQuantity")),
            item.orderedQuantity),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell },
            React.createElement("span", { className: styles.OrderHistoryDetails__Cell__Mobile_label }, translate("/MyMenigo/OrderHistory/ProductList/PurchasePrice")),
            !!item.purchasePrice && item.purchasePrice.toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            " ",
            !!item.purchasePrice && translate('/Price/CurrencySymbol'))));
};
export default DetailedProductRow;
