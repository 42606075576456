var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { addToCart } from './../../Cart/action-creators';
import Main, { Basic, Part, Section } from 'Shared/PageLayout';
import ProductHeading from './../ProductHeading';
import Quantity from 'Shared/Quantity';
import AddRemoveFromListOrLogin from 'Shared/Product/AddRemoveFromListOrLogin';
import connect from 'Shared/connect';
import Accordion from 'Shared/Accordion';
import Item from 'Shared/Accordion/Item';
import ContentCardRow from 'Shared/ContentCardRow';
import { SMALL } from 'Shared/breakpoints';
import AddToCartRecommendation from './AddToCartRecommendation';
import ProductRawMaterialGuide from './../ProductRawMaterialGuide';
import { scrollPosition } from 'SiteLayout/Root';
import * as styles from './base.scss';
import * as style from '../../Shared/ProductCard//ProductRow2.0/grid.scss';
import SendTenderRequest from './../../Tender/send-tender-request';
import ProductCarousel from 'Shared/Blocks/ProductCarousel/ProductCarouselBlock';
import { getStepSize } from 'Shared/Quantity/utils';
import { getCartLineItemCountForVariant } from '../../Cart/reducer';
import { getSelectedVariant } from 'Shared/Product/reducer';
import { selectVariant, toggleSelectedVariant } from 'Shared/Product/action-creators';
import LoginContainer from 'Shared/Login/Container';
import LoginToBuy from 'Shared/ProductCard/LoginToBuy/LoginToBuy';
import VariantSelection from 'Shared/VariantSelection';
import NutritionBlock from 'Shared/Product/Blocks/NutritionBlock';
import InfoBlock from 'Shared/Product/Blocks/InfoBlock';
import IconMarkingsBlock from 'Shared/Product/Blocks/IconMarkingsBlock';
import ClassificationBlock from 'Shared/Product/Blocks/ClassificationBlock';
import MarkingBlock from 'Shared/Product/Blocks/MarkingBlock';
import PriceBlock from 'Shared/Product/Blocks/PriceBlock';
import MediaBlock from 'Shared/Product/Blocks/MediaBlock';
import BadgeBlock from 'Shared/Product/Blocks/BadgeBlock';
import MyMenigoIcon from './../../../Features/MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { updateODPTracking, ODPTrackingActions } from './../../TrackingInformation';
import ProductCarbonFootPrint from 'Shared/ProductCarbonFootPrint';
import ProductCardContainer from 'Shared/ProductComponents/ProductCardContainer';
import ProductCardItem from 'Shared/ProductCard/ProductRow2.0/ProductCardItem';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
var nonFood = '4';
// Note that this component is used both on the product page and on the search page
// when there's only one result
var Variant = /** @class */ (function (_super) {
    __extends(Variant, _super);
    function Variant(props) {
        var _this = _super.call(this, props) || this;
        _this.addToCart = function (quantity) {
            if (_this.props.cartItemQuantity !== quantity) {
                var variant = _this.props.selectedVariant;
                return _this.props.addToCart(variant.code, quantity, variant.unitShortName, variant.ticket);
            }
            return Promise.reject(null);
        };
        _this.state = {
            addToCartRecommendations: [],
        };
        return _this;
    }
    Variant.prototype.componentDidMount = function () {
        updateODPTracking(this.props.code, ODPTrackingActions.DETAIL);
    };
    Variant.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.code !== this.props.code) {
            this.setState({
                addToCartRecommendations: [],
            });
        }
    };
    Variant.prototype.scrollTo = function (id) {
        var headerElement = document.getElementById('mainHeader');
        var element = document.getElementById(id);
        var offset = !!element ? element.offsetTop : 0;
        scrollPosition(offset - headerElement.offsetHeight);
    };
    Variant.prototype.setSelectedVariant = function (code) {
        if (this.props.variants.find(function (v) { return v.code === code; })) {
            this.props.selectVariant(code);
        }
    };
    Variant.prototype.hasNutritionValue = function () {
        var hasFactValues = this.props.nutritionFacts && this.props.nutritionFacts.reduce(function (acc, fact) { return acc + fact.value; }, 0) > 0;
        return (this.props.areaId !== nonFood &&
            (this.props.isProductEquipment
                ? !!this.props.itemSpecificationT5038 || !!this.props.countryOfOrigin
                : hasFactValues ||
                    !!this.props.itemSpecificationT2235 ||
                    !!this.props.itemSpecificationT4083 ||
                    !!this.props.itemSpecificationT4088 ||
                    !!this.props.manufacturerCountry ||
                    !!this.props.itemSpecificationT5038 ||
                    !!this.props.countryOfOrigin));
    };
    Variant.prototype.render = function () {
        var _this = this;
        var disableBuyWidgets = !this.props.isBuyable || this.props.isSoldOut;
        var headingNode = React.createElement(ProductHeading, { title: this.props.title });
        var discontinuedOrBackordered = this.props.isDiscontinued || this.props.isDisabledForFreCustomer || this.props.isBackorderReplaced;
        var priceBlock = (React.createElement(PriceBlock, { selectedVariant: this.props.selectedVariant, isInCampaign: this.props.isInCampaign }));
        var step = getStepSize(this.props.selectedVariant);
        var quantityOrTenderRequest = function (isTender) {
            return !disableBuyWidgets && isTender ? (React.createElement(SendTenderRequest, { tenderProductName: _this.props.title, tenderProductArticleNumber: _this.props.code })) : (React.createElement(Quantity, { update: _this.addToCart, step: step, minQuantity: 0, value: _this.props.cartItemQuantity, disabled: disableBuyWidgets || _this.props.isRestrictedCustomer, isCartQuantity: true, renderVariant: React.createElement(VariantSelection, { product: _this.props, unitShortName: _this.props.selectedVariant.unitShortName }) }));
        };
        var quantityNode = (React.createElement(LoginContainer, null, function (_a) {
            var isLoggedIn = _a.isLoggedIn;
            return isLoggedIn ? quantityOrTenderRequest(_this.props.isTender) : React.createElement(LoginToBuy, null);
        }));
        var classificationBlock = (React.createElement(ClassificationBlock, { productViewModel: this.props, selectedVariant: this.props.selectedVariant, isProductPage: true }));
        var mediaBlock = React.createElement(MediaBlock, { productViewModel: this.props });
        var badgeBlock = React.createElement(BadgeBlock, { productViewModel: this.props });
        var markingBlock = React.createElement(MarkingBlock, { productViewModel: this.props });
        var isDesktop = this.props.currentBreakpoint > SMALL;
        var addRemoveNode = !this.props.isSupplierSalesman && (React.createElement(AddRemoveFromListOrLogin, { product: this.props, variantCode: this.props.selectedVariant.code, className: styles.addRemoveList }));
        var flavorDescriptionBlock = !!this.props.flavorDescription ? (React.createElement(InfoBlock, { info: this.props.flavorDescription, isDesktop: isDesktop, headerText: '/Product/FlavorDescription' })) : ('');
        var suitableForBlock = !!this.props.suitableFor ? (React.createElement(InfoBlock, { info: this.props.suitableFor, isDesktop: isDesktop, headerText: '/Product/SuitableFor' })) : ('');
        var descriptionBlock = !!this.props.sellingTextWeb ? (React.createElement(InfoBlock, { info: this.props.sellingTextWeb, isDesktop: isDesktop, headerText: '/Product/Description' })) : ('');
        var practicalInfoBlock = !!this.props.practicalInfo ? (React.createElement(InfoBlock, { info: this.props.practicalInfo, isDesktop: isDesktop, headerText: '/Product/HandyInformation' })) : ('');
        var iconMarkingsBlock = !!this.props.classification && Object.keys(this.props.classification).length > 0 ? (React.createElement(IconMarkingsBlock, { classification: this.props.classification, isDesktop: isDesktop })) : ('');
        var recommendationBlock = (React.createElement("div", { className: styles.recommendationRow },
            React.createElement(Main, null, !!this.props.recommendationZone &&
                this.props.recommendationZone.length > 0 &&
                Object.keys(this.props.recommendationZone).map(function (data, i) {
                    var _a;
                    return (React.createElement("div", { key: i, className: classnames((_a = {}, _a[styles.carousel] = !isDesktop, _a)) },
                        React.createElement(ProductCarousel, { title: _this.props.recommendationZone[i].name, products: _this.props.recommendationZone[i].products, width: "", id: data })));
                }))));
        var relatedContent = (React.createElement("div", { className: styles.relatedContent },
            React.createElement(Main, null,
                React.createElement(ContentCardRow, { cards: this.props.cards, title: translate('/Product/LatestNews') }))));
        var rawInformationNode = !!this.props.rawMaterial ? (React.createElement(ProductRawMaterialGuide, __assign({}, this.props.rawMaterial))) : ('');
        var nutritionBlock = (React.createElement(NutritionBlock, { itemSpecificationProduktBladdabas: this.props.itemSpecificationProduktBladdabas, itemSpecificationT0168: this.props.itemSpecificationT0168, itemSpecificationT4066: this.props.itemSpecificationT4066, itemSpecificationT4083: this.props.itemSpecificationT4083, itemSpecificationT4088: this.props.itemSpecificationT4088, itemSpecificationT4090: this.props.itemSpecificationT4090, itemSpecificationT4124: this.props.itemSpecificationT4124, itemSpecificationT4193: this.props.itemSpecificationT4193, itemSpecificationT5038: this.props.itemSpecificationT5038, itemSpecificationT5039: this.props.itemSpecificationT5039, itemSpecificationT5041: this.props.itemSpecificationT5041, itemSpecificationT2235: this.props.itemSpecificationT2235, nutritionFacts: this.props.nutritionFacts, documentUrls: this.props.documentUrls, informationProvider: this.props.informationProvider, manufacturerCountry: this.props.manufacturerCountry, currentBreakpoint: this.props.currentBreakpoint, countryOfOrigin: this.props.countryOfOrigin, isProductEquipment: this.props.isProductEquipment, lawInfoText: this.props.nutritionLawInfo }));
        var showDesktopAccordion = function () {
            return _this.hasNutritionValue();
        };
        return isDesktop ? (React.createElement("div", { className: styles.base },
            React.createElement(AddToCartRecommendation, { products: this.state.addToCartRecommendations }),
            React.createElement(Main, { className: styles.main, appearance: "narrow" },
                React.createElement("div", { className: styles.mainNarrow },
                    React.createElement(Part, null,
                        React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs || [], productName: this.props.title })),
                    React.createElement(Basic, { layout: "2to5" },
                        React.createElement(Section, null,
                            React.createElement("div", { className: styles.productInformation },
                                headingNode,
                                React.createElement("div", { className: classnames(style.main, styles.mainProduct) },
                                    React.createElement("div", { className: classnames(style.grid, style.isProductPage), "data-list-type": 'viewLayout_List' }, !!this.props.product &&
                                        React.createElement(ProductCardSelectionProvider, { selectableIndexes: [0] },
                                            React.createElement(ProductCardContainer, { product: this.props.product, index: 0 }, function (_a) {
                                                var product = _a.product, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                                                return (React.createElement(ProductCardItem, { product: product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: 0, url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, temporary: false, cardType: 'viewLayout_List', isProductPage: true }));
                                            })))),
                                React.createElement(Part, { layout: "1to1", className: styles.articleWrapper },
                                    React.createElement("div", { className: styles.article },
                                        classificationBlock,
                                        !!this.props.itemSpecificationProduktBladdabas && (React.createElement("p", null,
                                            React.createElement("a", { href: this.props.itemSpecificationProduktBladdabas, target: "_blank", rel: "nofollow", className: styles.productSheetLink },
                                                React.createElement("span", { className: styles.productSheetLinkText }, translate('/Product/ProductSheet')),
                                                ' ',
                                                React.createElement(MyMenigoIcon, { name: "arrow" })))))))),
                        React.createElement(Part, { className: styles.leftSection },
                            markingBlock,
                            mediaBlock,
                            badgeBlock)))),
            React.createElement("div", { className: styles.descriptionWrapper },
                React.createElement(Main, { className: styles.descriptionInner },
                    suitableForBlock,
                    flavorDescriptionBlock),
                React.createElement(Main, { className: styles.descriptionInner },
                    descriptionBlock,
                    practicalInfoBlock),
                iconMarkingsBlock,
                !!this.props.carbonFootPrint && !!this.props.carbonCloudLink &&
                    (React.createElement(Main, { className: styles.descriptionInner },
                        React.createElement(ProductCarbonFootPrint, { carbonCloudLink: this.props.carbonCloudLink, carbonFootPrint: this.props.carbonFootPrint })))),
            (showDesktopAccordion() || !!rawInformationNode) && React.createElement(Main, { className: classnames(styles.base, styles.accordionDesktop) },
                React.createElement(Accordion, null,
                    this.hasNutritionValue() && (React.createElement(Item, { label: translate('/Product/Declaration') }, nutritionBlock)),
                    !!rawInformationNode && (React.createElement(Item, { label: translate('/Product/ProductRawInformation/ProductRawGuide') }, rawInformationNode)))),
            recommendationBlock,
            relatedContent)) : (React.createElement(Main, { className: classnames(styles.base, styles.mobileBase) },
            React.createElement(Section, null,
                React.createElement(Part, null,
                    React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs || [], productName: this.props.title })),
                markingBlock,
                headingNode,
                mediaBlock),
            React.createElement("div", { className: classnames(style.main, styles.mainProduct) },
                React.createElement("div", { className: classnames(style.grid, style.isProductPage), "data-list-type": 'viewLayout_List' }, !!this.props.product &&
                    React.createElement(ProductCardSelectionProvider, { selectableIndexes: [0] },
                        React.createElement(ProductCardContainer, { product: this.props.product, index: 0 }, function (_a) {
                            var product = _a.product, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                            return (React.createElement(ProductCardItem, { product: product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: 0, url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, temporary: false, cardType: 'viewLayout_List', isProductPage: true }));
                        })))),
            React.createElement(Accordion, null,
                React.createElement(Item, { label: translate('/Product/ArticleInformation') },
                    React.createElement("div", { className: styles.accordionPadding }, classificationBlock)),
                !!this.props.flavorDescription && (React.createElement(Item, { label: translate('/Product/FlavorDescription') }, flavorDescriptionBlock)),
                !!this.props.suitableFor && (React.createElement(Item, { label: translate('/Product/SuitableFor') }, suitableForBlock)),
                !!this.props.sellingTextWeb && (React.createElement(Item, { label: translate('/Product/Description') }, descriptionBlock)),
                !!this.props.practicalInfo && (React.createElement(Item, { label: translate('/Product/HandyInformation') }, practicalInfoBlock)),
                !!this.props.classification && Object.keys(this.props.classification).length > 0 && (React.createElement(Item, { label: translate('/Product/Markings') },
                    React.createElement("div", { className: styles.accordionPadding }, iconMarkingsBlock))),
                this.hasNutritionValue() && (React.createElement(Item, { label: translate('/Product/Declaration') }, nutritionBlock)),
                !!this.props.rawMaterial && React.createElement(Item, { label: "R\u00E5varuinformation" }, rawInformationNode)),
            !!this.props.carbonFootPrint && !!this.props.carbonCloudLink &&
                React.createElement(ProductCarbonFootPrint, { carbonCloudLink: this.props.carbonCloudLink, carbonFootPrint: this.props.carbonFootPrint }),
            recommendationBlock));
    };
    return Variant;
}(React.PureComponent));
export default connect(function (state, ownProps) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoggedIn: state.currentUser.isLoggedIn,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    currentUser: state.currentUser,
    cartItemQuantity: getCartLineItemCountForVariant(state, getSelectedVariant(state, ownProps.variants)),
    selectedVariant: getSelectedVariant(state, ownProps.variants),
    isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
    currentPage: state.currentPage,
}); }, function (dispatch, ownProps) { return ({
    addToCart: function (code, quantity, unitShortName, ticket) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, 'ProductPage'));
    },
    selectVariant: function (newVariantCode) { return dispatch(selectVariant(newVariantCode, ownProps.variants)); },
    toggleSelectedVariant: function (currentSelectedVariantCode) {
        return dispatch(toggleSelectedVariant(currentSelectedVariantCode, ownProps.variants));
    },
}); })(Variant);
