import { promoImpression, promoClick } from "../../TrackingInformation";
export function onView(visible, content, index) {
    if (!visible || !!!content)
        return;
    promoImpression(content.trackingInformationPromo, index);
}
export function onClick(event, content, index) {
    if (!!!content)
        return;
    promoClick(content.trackingInformationPromo, index);
}
