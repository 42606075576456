import React from 'react';
var logotype = require('!svg-inline-loader?classPrefix=true!../../../../../../styles/images/menigo_redwhitegrey_tagline.svg');
var logotypemobile = require('!svg-inline-loader?classPrefix=true!../../../../../../styles/images/menigo_redwhite_notagline.svg');
import * as styles from './LogoType.scss';
import { SMALL } from 'Shared/breakpoints';
import classnames from 'classnames';
export default (function (props) {
    var _a;
    var isMobile = function (currentBreakpoint) { return currentBreakpoint <= SMALL; };
    return !props.disableLink ? (React.createElement("div", { className: classnames(styles.logotypeWrapper, (_a = {}, _a[styles.small] = !!props.small, _a)) },
        React.createElement("a", { className: styles.logotype + ' ' + (props.className || ''), href: props.siteUrl },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: isMobile(props.breakpoints) || !!props.small ? logotypemobile : logotype } })))) : (React.createElement("div", { className: styles.logotypeWrapper },
        React.createElement("div", { className: styles.logotype + ' ' + (props.className || ''), dangerouslySetInnerHTML: { __html: isMobile(props.breakpoints) || !!props.small ? logotypemobile : logotype } })));
});
