import React, { Fragment } from 'react';
import * as styles from './base.scss';
import * as style from '../ProductRow2.0/grid.scss';
import ProductCardContainer from 'Shared/ProductComponents/ProductCardContainer';
import classnames from 'classnames';
import { recommendationsPanelImpression, recommendationsPanelLinkButtonClick } from '../../../TrackingInformation';
import ViewTracker from 'Shared/ViewObserver/ViewTracker';
import ProductCardItem from '../ProductRow2.0/ProductCardItem';
var onRecommendationsPanelView = function (recommendationsPanel, visible) {
    !!visible && recommendationsPanelImpression(recommendationsPanel);
};
var onRecommendationsPanelLinkButtonClick = function (recommendationsPanel) {
    recommendationsPanelLinkButtonClick(recommendationsPanel);
};
var RecommendationsPanelComponent = function (props) {
    return (React.createElement("div", { className: styles.recommendationsPanel },
        React.createElement(ViewTracker, { onView: function (visible) { return onRecommendationsPanelView(props.recommendationsPanel, visible); } },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.contentRow },
                    React.createElement("img", { className: styles.imageColumn, src: props.recommendationsPanel.imageUrl }),
                    React.createElement("div", { className: styles.infoColumn },
                        React.createElement("div", { className: styles.textColumn },
                            React.createElement("div", { className: styles.textTitle }, props.recommendationsPanel.title),
                            React.createElement("p", { className: styles.textBody }, props.recommendationsPanel.body)),
                        props.recommendationsPanel.linkUrl && props.recommendationsPanel.linkText &&
                            (React.createElement("div", { className: styles.seeMore },
                                React.createElement("a", { href: props.recommendationsPanel.linkUrl, onClick: function () { return onRecommendationsPanelLinkButtonClick(props.recommendationsPanel); } }, props.recommendationsPanel.linkText))))),
                React.createElement("div", { className: classnames(props.recommendedListClassName, styles.products, style.main) },
                    React.createElement("div", { className: style.grid, "data-list-type": props.viewLayoutType }, props.recommendationsPanel.products.map(function (recommendedProductListItem, rec_index) { return (React.createElement(Fragment, { key: rec_index },
                        React.createElement(ProductCardContainer, { product: recommendedProductListItem, index: rec_index }, function (_a) {
                            var product = _a.product, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                            return (React.createElement(ProductCardItem, { product: product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: rec_index + (!!props.startOnIndex ? props.startOnIndex : 20), url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, temporary: props.temporary, cardType: props.viewLayoutType, searchTerm: props.searchTerm }));
                        }))); })))))));
};
export default (RecommendationsPanelComponent);
