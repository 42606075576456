var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Main, { Section } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import RecipeListing from './../RecipeListing';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import { TINY } from 'Shared/breakpoints';
import { currentUrl, areEqual } from 'Shared/url';
import { pushState } from 'Shared/history';
import { ENTER } from 'Shared/keyboard';
import classnames from 'classnames';
import * as styles from './base.scss';
var RecipeOverviewPage = /** @class */ (function (_super) {
    __extends(RecipeOverviewPage, _super);
    function RecipeOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    RecipeOverviewPage.prototype.onKeyDown = function (event) {
        switch (event.keyCode) {
            case ENTER:
                this.loadResults();
                break;
            default:
                break;
        }
    };
    RecipeOverviewPage.prototype.loadResults = function () {
        var queryParams = this.props.list.query;
        var url = {
            path: window.location.pathname,
            query: Object.assign({}, queryParams, !!this.searchInput.value ? { query: this.searchInput.value } : {}),
        };
        if (!areEqual(currentUrl(), url)) {
            pushState(url);
        }
    };
    RecipeOverviewPage.prototype.render = function () {
        var _this = this;
        var background = {
            backgroundImage: 'url(' + propertyValueFor(this.props.page.inspirationImage) + ')',
        };
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement("div", { className: styles.bottomBorder },
                React.createElement(Section, null,
                    this.props.currentBreakpoint >= TINY && React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs }),
                    React.createElement("div", { style: background, className: styles.header },
                        React.createElement(PropertyFor, { tagName: "h2", property: this.props.page.header }),
                        React.createElement("input", { className: styles.searchInput, type: "search", name: "query", defaultValue: this.props.list.query, placeholder: translate('/RecipeListing/SearchRecipe'), tabIndex: -1, autoFocus: false, onKeyDown: function (event) { return _this.onKeyDown(event); }, ref: function (e) { return (_this.searchInput = e); } }))),
                this.props.currentBreakpoint >= TINY && this.props.isLoggedIn && (React.createElement("div", { className: styles.navigationWrapper },
                    React.createElement("div", { className: classnames(styles.selectionNavigatorWrapper, styles.fullWidth) }, !!this.props.list &&
                        this.props.list.links &&
                        this.props.list.links.length > 1 &&
                        this.props.list.links.map(function (link, i) {
                            return (React.createElement("button", { key: i, onClick: function () { return (window.location.href = link.url); } }, link.text));
                        }))))),
            React.createElement(Section, null,
                React.createElement(RecipeListing, __assign({}, this.props.list)))));
    };
    return RecipeOverviewPage;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    isLoggedIn: state.currentUser && state.currentUser.isLoggedIn,
}); }, null)(RecipeOverviewPage);
