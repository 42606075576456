var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import * as searchHistory from './../search-history';
import { translate } from 'Shared/translate';
import { loadQuickSearch, hideQuickSearch, showQuickSearch, closeQuickSearch, updateSelectedCompletion, clearQuickSearchCart, setQuickSearchText, } from './../action-creators';
import Autocompletions from './Autocompletions';
import { DOWN, UP, ESC, TAB, ENTER, PLUS, MINUS, PLUS_DESKTOP, MINUS_DESKTOP } from 'Shared/keyboard';
import { pushState } from 'Shared/history';
import { searchPageUrl } from 'Shared/known-urls';
import Icon from 'Shared/Icon';
import Button from './Button';
import currentPageIsSearch from './../Pages/Search/current-page-is-search';
import { currentUrl, areEqual } from 'Shared/url';
import { loadPage } from 'SiteLayout/CurrentPage/action-creators';
import * as Root from 'SiteLayout/Root';
import * as styles from './base.scss';
import * as stylesAutocomplete from './autocomplete.scss';
import { setMainMenu } from 'SiteLayout/MainMenu/action-creators';
import propertyValueFor from 'Shared/property-value-for';
var searchDebounceMs = 400;
var QuickSearch = /** @class */ (function (_super) {
    __extends(QuickSearch, _super);
    function QuickSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.previousSearchWasPreview = false;
        _this.onKeyDown = function (event) {
            switch (event.keyCode) {
                case ENTER:
                    event.preventDefault();
                    if (_this.searchInputHasFocus) {
                        _this.loadResults();
                        _this.props.hideQuickSearch();
                        _this.searchInput.blur();
                    }
                    break;
                case PLUS_DESKTOP:
                case TAB:
                case DOWN:
                case PLUS:
                    event.preventDefault();
                    _this.moveSelection('next');
                    break;
                case UP:
                case MINUS:
                case MINUS_DESKTOP:
                    event.preventDefault();
                    _this.moveSelection('prev');
                    break;
                case ESC:
                    // Prevent default because Chrome clears the value of a input[type="search"]
                    // on escape
                    event.preventDefault();
                    _this.searchInput.blur();
                    _this.props.hideQuickSearch();
                    break;
                default:
                    break;
            }
        };
        _this.onAutocompleClick = function (text) {
            searchHistory.add(text);
        };
        return _this;
    }
    QuickSearch.prototype.getAllContent = function () {
        return this.props.quickSearch.completionsLists.reduce(function (a, b) { return a.concat(!!b.content ? b.content : []); }, []);
    };
    QuickSearch.prototype.getAllCompletions = function () {
        return this.props.quickSearch.completionsLists.reduce(function (a, b) { return a.concat(!!b.items ? b.items : []); }, []);
    };
    QuickSearch.prototype.getAllProducts = function () {
        return this.props.quickSearch.completionsLists.reduce(function (a, b) { return a.concat(!!b.products ? b.products : []); }, []);
    };
    QuickSearch.prototype.componentDidMount = function () {
        var _this = this;
        this.setQuerySuggestionVisibility();
        this.unbindRootClick = Root.on('click', function () {
            if (_this.props.quickSearch.isOpen) {
                _this.props.hideQuickSearch();
            }
        });
    };
    QuickSearch.prototype.componentDidUpdate = function () {
        this.setQuerySuggestionVisibility();
    };
    QuickSearch.prototype.componentWillUnmount = function () {
        this.unbindRootClick && this.unbindRootClick();
        this.clearSearchTimeouts();
        if (this.searchInput) {
            this.searchInput.removeEventListener('input', this.searchOnInput);
        }
    };
    QuickSearch.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.searchInput && !this.searchInputHasFocus) {
            this.searchInput.value = nextProps.quickSearch.searchText;
        }
    };
    QuickSearch.prototype.clearSearchTimeouts = function () {
        clearTimeout(this.currentSearchTimeout);
    };
    QuickSearch.prototype.setQuerySuggestionVisibility = function () {
        var sw = this.querySuggestionElement.scrollWidth;
        var cw = this.querySuggestionElement.clientWidth;
        var visible = sw <= cw;
        this.querySuggestionElement.style.visibility = visible ? '' : 'hidden';
    };
    QuickSearch.prototype.moveSelection = function (direction) {
        var selectedCompletionType = this.props.quickSearch.selectedCompletionType;
        if (selectedCompletionType === 'product' && !this.props.isLoggedIn) {
            selectedCompletionType = 'content';
        }
        var newSelectedIndex = -1;
        var collection = selectedCompletionType === 'product'
            ? this.getAllProducts()
            : selectedCompletionType === 'content'
                ? this.getAllContent()
                : this.getAllCompletions();
        newSelectedIndex = this.getNewSelection(direction, collection, this.props.quickSearch.selectedCompletionIndex);
        if (newSelectedIndex < 0) {
            if (direction === 'prev') {
                selectedCompletionType =
                    selectedCompletionType === 'product'
                        ? 'item'
                        : selectedCompletionType === 'item'
                            ? 'content'
                            : !this.props.isLoggedIn
                                ? 'item'
                                : 'product';
            }
            else {
                selectedCompletionType =
                    selectedCompletionType === 'product'
                        ? 'content'
                        : selectedCompletionType === 'content'
                            ? 'item'
                            : !this.props.isLoggedIn
                                ? 'content'
                                : 'product';
            }
            collection =
                selectedCompletionType === 'product'
                    ? this.getAllProducts()
                    : selectedCompletionType === 'item'
                        ? this.getAllCompletions()
                        : this.getAllContent();
            newSelectedIndex = this.getNewSelection(direction, collection, newSelectedIndex);
        }
        if (selectedCompletionType !== 'product' && !this.searchInputHasFocus) {
            this.searchInput.focus();
        }
        this.props.updateSelectedCompletion(newSelectedIndex, selectedCompletionType);
        if (newSelectedIndex >= 0) {
            this.setPreviousSearch();
        }
    };
    QuickSearch.prototype.getNewSelection = function (direction, list, selectedIndex) {
        if (direction === 'next') {
            return selectedIndex + 1 > list.length - 1 ? -1 : selectedIndex + 1;
        }
        else {
            return selectedIndex >= 0 ? selectedIndex - 1 : list.length > 0 ? list.length - 1 : -1;
        }
    };
    QuickSearch.prototype.searchUrl = function (searchText, preview) {
        return {
            path: searchPageUrl(),
            query: { q: searchText },
            hiddenQuery: preview ? { preview: preview } : null,
        };
    };
    QuickSearch.prototype.loadResults = function () {
        this.displayQuickSearch = false;
        var query = this.props.quickSearch.searchText;
        searchHistory.add(query);
        var newUrl = this.searchUrl(query, true);
        this.previousSearch = query;
        // Note that we perform the page load regardless of whether the same result is already loaded.
        // This is because we want to load it without the preview parameter to notify eSales of the actual search.
        if (areEqual(currentUrl(), newUrl)) {
            this.props.loadPage(newUrl, false);
        }
        else {
            this.go(query);
        }
    };
    QuickSearch.prototype.setPreviousSearch = function () {
        var query = (this.props.quickSearch.suggestion || this.props.quickSearch.searchText).trim();
        if (this.previousSearch !== query) {
            this.previousSearch = query;
            this.previousSearchWasPreview = true;
        }
    };
    QuickSearch.prototype.go = function (searchText) {
        if (searchText.length) {
            this.previousSearch = searchText;
            this.previousSearchWasPreview = false;
            var newUrl = this.searchUrl(searchText, false);
            var products = this.getAllProducts();
            if (products.length == 1) {
                var item = products[0];
                var itemCode = propertyValueFor(item.content.code);
                newUrl = this.searchUrl(itemCode, false);
            }
            if (!areEqual(currentUrl(), newUrl)) {
                pushState(newUrl);
            }
        }
    };
    QuickSearch.prototype.onChange = function (event) {
        var _this = this;
        var searchText = event.target.value;
        // IE11 and below fires an `input` on focus even if the user didn't type anything
        if (searchText === this.props.quickSearch.searchText) {
            return;
        }
        if (searchText.length !== 0) {
            this.clearSearchTimeouts();
            this.displayQuickSearch = true;
            this.props.setQuickSearchText(searchText);
            if (searchText.length > 1) {
                this.currentSearchTimeout = setTimeout(function () {
                    var query = (_this.props.quickSearch.suggestion || _this.props.quickSearch.searchText).trim();
                    _this.props.loadQuickSearch(searchText);
                    if (_this.displayQuickSearch) {
                        _this.props.showQuickSearch();
                    }
                    if (_this.previousSearch !== query) {
                        _this.previousSearch = query;
                        _this.previousSearchWasPreview = true;
                    }
                }, searchDebounceMs);
            }
        }
        else {
            this.props.closeQuickSearch();
        }
    };
    QuickSearch.prototype.onSearchClick = function (e) {
        e.preventDefault();
        this.props.hideQuickSearch(false);
        this.loadResults();
        this.searchInput.blur();
    };
    QuickSearch.prototype.onClear = function () {
        this.searchInput.focus();
        this.props.loadQuickSearch('');
        var quick = document.getElementById('quicksearch');
        quick.value = '';
        this.props.hideQuickSearch();
    };
    QuickSearch.prototype.onInputFocus = function () {
        this.previousSearch = this.props.quickSearch.searchText;
        this.searchInputHasFocus = true;
    };
    QuickSearch.prototype.onInputBlur = function () {
        this.searchInputHasFocus = false;
    };
    QuickSearch.prototype.onInputClick = function () {
        this.props.loadQuickSearch('');
        this.props.updateSelectedCompletion(-1, 'product');
        this.searchInputHasFocus = true;
        this.props.showQuickSearch();
    };
    QuickSearch.prototype.onAutocompleProductClick = function () {
        this.props.hideQuickSearch();
    };
    QuickSearch.prototype.render = function () {
        var _this = this;
        var _a = this.props.quickSearch, searchText = _a.searchText, completionsLists = _a.completionsLists, isOpen = _a.isOpen;
        var suggestion = this.props.quickSearch.suggestion || '';
        var hasCompletions = completionsLists.reduce(function (i, l) {
            return i +
                (l.items && l.items.length ? l.items.length : 0) +
                (l.products && l.products.length ? l.products.length : 0);
        }, 0) > 0;
        var showResult = isOpen && hasCompletions && (this.displayQuickSearch || (this.searchInputHasFocus && !this.displayQuickSearch));
        return (React.createElement("form", { className: classnames(styles.quickSearch, this.props.className), onClick: function (e) {
                // We stop propagation because we close the quick search on clicks
                // outside the form
                e.stopPropagation();
            } },
            React.createElement("div", { className: styles.inputContainer },
                React.createElement("div", { className: styles.querySuggestion, ref: function (el) { return (_this.querySuggestionElement = el); } },
                    React.createElement("span", { className: styles.querySuggestionPrefix }, suggestion.substr(0, searchText.length)),
                    React.createElement("span", null, suggestion.substr(searchText.length))),
                React.createElement("input", { type: "search", name: "q", id: "quicksearch", autoComplete: "off", defaultValue: searchText, ref: function (el) { return (_this.searchInput = el); }, placeholder: searchText ? '' : translate('/QuickSearch/Placeholder'), onChange: function (e) { return _this.onChange(e); }, onKeyDown: function (event) { return _this.onKeyDown(event); }, onFocus: function () { return _this.onInputFocus(); }, onBlur: function () { return _this.onInputBlur(); }, onClick: function () { return _this.onInputClick(); } })),
            this.props.quickSearch.searchText.length > 0 && (React.createElement("button", { type: "button", className: styles.clear, onClick: function (e) { return _this.onClear(); } },
                React.createElement(Icon, { name: "close" }))),
            React.createElement(Button, { className: classnames(styles.button, 'ui-test-search-button'), element: function (el) { return (_this.searchButtonElement = el); }, onClick: function (e) { return _this.onSearchClick(e); } }),
            showResult ? (React.createElement(Autocompletions, { onItemClick: this.onAutocompleClick, lists: completionsLists, selectedIndex: this.props.quickSearch.selectedCompletionIndex, selectedCompletionType: this.props.quickSearch.selectedCompletionType, searchText: searchText, onKeyDown: this.onKeyDown })) : (this.searchInputHasFocus &&
                searchText.length >= 3 && (React.createElement("div", { className: classnames(stylesAutocomplete.base) },
                React.createElement("div", { className: stylesAutocomplete.noHitsSection }, translate('/Shared/NoHitsUnfortunately', searchText) + "."))))));
    };
    return QuickSearch;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    currentPageIsSearch: currentPageIsSearch(state),
    quickSearch: state.quickSearch,
}); }, function (dispatch) { return ({
    loadPage: function (url, showSpinner, shouldUseResult) {
        dispatch(loadPage(url, { hideSpinner: !showSpinner }, null, shouldUseResult));
    },
    loadQuickSearch: function (searchText) { return dispatch(loadQuickSearch(searchText)); },
    setQuickSearchText: function (searchText) { return dispatch(setQuickSearchText(searchText)); },
    updateSelectedCompletion: function (index, type) {
        dispatch(updateSelectedCompletion(index, type));
    },
    hideQuickSearch: function (setFirstSuggestionAsSearchText) {
        if (setFirstSuggestionAsSearchText === void 0) { setFirstSuggestionAsSearchText = true; }
        dispatch(hideQuickSearch(setFirstSuggestionAsSearchText));
    },
    showQuickSearch: function () {
        dispatch(setMainMenu(false));
        dispatch(showQuickSearch());
    },
    closeQuickSearch: function () {
        dispatch(closeQuickSearch());
    },
    clearQuickSearchCart: function () { return dispatch(clearQuickSearchCart()); },
}); })(QuickSearch);
