import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import LinkWithAlcoholWarning from 'Shared/LinkWithAlcoholWarning';
var NormalContentCard = function (_a) {
    var className = _a.className, ticket = _a.ticket, targetLink = _a.targetLink, headerText = _a.headerText, title = _a.title, text = _a.text, footerText = _a.footerText, imageUrl = _a.imageUrl, appearance = _a.appearance, containsAlcoholInfo = _a.containsAlcoholInfo, onClick = _a.onClick;
    return (React.createElement("div", { className: classnames(styles.child, className, styles[appearance]) },
        React.createElement(LinkWithAlcoholWarning, { className: styles.href, "data-ticket": ticket, targetLink: targetLink, hasAlcoholWarning: containsAlcoholInfo, onClick: onClick, text: title },
            !!headerText && React.createElement("div", { className: styles.header }, headerText),
            React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + imageUrl + ")" } }),
            !!title && React.createElement("div", { className: styles.name }, title),
            !!text && React.createElement("div", { className: styles.description, dangerouslySetInnerHTML: { __html: text } }),
            !!footerText && React.createElement("div", { className: styles.footer }, footerText))));
};
export default NormalContentCard;
