import { MYMENIGO_DROPDOWN_BOX_OPEN, MYMENIGO_DROPDOWN_BOX_CLOSE } from './action-creators';
export default (function (state, action) {
    state = state || { isOpen: false };
    switch (action.type) {
        case MYMENIGO_DROPDOWN_BOX_CLOSE:
            return Object.assign({}, state, { isOpen: false });
        case MYMENIGO_DROPDOWN_BOX_OPEN:
            return Object.assign({}, state, {
                isOpen: true,
            });
        default:
            return state;
    }
});
