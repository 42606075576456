var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Modal from 'Shared/Modal';
import { translate } from 'Shared/translate';
import DropDown from 'Shared/DropDown';
import { initLists, addProductToFavoriteList, addProductToPantryList, createListAndAddProduct, } from 'Shared/ListItem/action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import { isInList } from './index';
import { favoritePageUrl, pantryPageUrl } from 'Shared/known-urls';
import { pathCombine, urlToString } from 'Shared/url';
import { Input } from 'Shared/Fields';
var AddToListDialog = /** @class */ (function (_super) {
    __extends(AddToListDialog, _super);
    function AddToListDialog(props) {
        var _this = _super.call(this, props) || this;
        _this.onNewConfirm = function () {
            return _this.onConfirm(true);
        };
        _this.onExistingConfirm = function () {
            return _this.onConfirm(false);
        };
        _this.onModalClose = function () {
            _this.props.close();
            _this.resetModalState();
        };
        _this.onSelectChange = function (selectedList) { return _this.setState({ selectedList: selectedList }); };
        _this.state = {
            newName: '',
            selectedList: null,
        };
        return _this;
    }
    AddToListDialog.prototype.componentWillReceiveProps = function (nextProps) {
        if (!this.props.isOpen && nextProps.isOpen) {
            this.props.initLists();
        }
    };
    AddToListDialog.prototype.addToFavorite = function (isNew) {
        if (isNew) {
            if (this.state.newName === '') {
                return Promise.reject(null);
            }
            return this.props.createListAndAddProduct(this.state.newName, true);
        }
        else if (this.props.pantriesFavorites.favorites.length > 0) {
            var selectedList = this.state.selectedList || this.getFilteredOptions()[0].value;
            return this.props.addProductToFavoriteList(selectedList);
        }
        else {
            console.error('No favorite lists available. Can not add product to list.');
            return Promise.reject(null);
        }
    };
    AddToListDialog.prototype.addToPantry = function (isNew) {
        if (isNew) {
            if (this.state.newName === '') {
                return Promise.reject(null);
            }
            return this.props.createListAndAddProduct(this.state.newName, false);
        }
        else if (this.props.pantriesFavorites.pantries.length > 0) {
            var selectedList = this.state.selectedList || this.getFilteredOptions()[0].value;
            return this.props.addProductToPantryList(selectedList);
        }
        else {
            console.error('No pantry lists available. Can not add product to list.');
            return Promise.reject(null);
        }
    };
    AddToListDialog.prototype.onConfirm = function (isNew) {
        if (this.props.type === 'favorites') {
            return this.addToFavorite(isNew);
        }
        else if (this.props.type === 'pantries') {
            return this.addToPantry(isNew);
        }
    };
    AddToListDialog.prototype.resetModalState = function () {
        this.setState({ selectedList: null, newName: '' });
    };
    AddToListDialog.prototype.getFilteredOptions = function () {
        var _this = this;
        var productNotInList = function (list) { return !(_this.props.listsContainingProduct || {})[list.id]; };
        return this.props.pantriesFavorites[this.props.type].filter(productNotInList).map(function (list) { return ({
            value: list.id,
            text: list.displayName,
        }); });
    };
    AddToListDialog.prototype.render = function () {
        var _this = this;
        var isPantry = this.props.type !== 'favorites';
        var options = this.getFilteredOptions();
        var hasOptions = !!options && options.length > 0;
        return (React.createElement(Modal, { isOpen: this.props.isOpen, onClose: this.onModalClose, title: isPantry
                ? hasOptions
                    ? translate('/Product/AddToPantry')
                    : translate('/Account/ProductList/NewPantry')
                : hasOptions
                    ? translate('/Product/AddToFavorite')
                    : translate('/Account/ProductList/NewList'), className: "ui-test-add-to-list-dialog", reserveSpaceForCloseButton: true },
            isInList(this.props.listsContainingProduct) && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, isPantry
                    ? translate('/Account/ProductList/AlreadyInPantry')
                    : translate('/Account/ProductList/AlreadyInFavorite')),
                Object.keys(this.props.listsContainingProduct).map(function (key) {
                    var pageUrl = isPantry ? pantryPageUrl() : favoritePageUrl();
                    var urlWithQuery = urlToString({ path: pathCombine(pageUrl), query: { activeItemKey: key } });
                    return (React.createElement("div", { key: key },
                        React.createElement("a", { href: urlWithQuery }, _this.props.listsContainingProduct[key])));
                }))),
            hasOptions && (React.createElement(React.Fragment, null,
                React.createElement("h3", null, translate('/Product/AddToExistingFavoriteList')),
                React.createElement(DropDown, { onChange: this.onSelectChange, options: options, value: this.state.selectedList || '' }),
                React.createElement(Dialog, { type: DialogType.ChooseAbort, onConfirm: this.onExistingConfirm, onCancel: this.onModalClose, onStatusWasFulfilled: this.onModalClose }))),
            React.createElement("h3", null, isPantry ? translate('/Account/ProductList/NewPantry') : translate('/Account/ProductList/NewList')),
            React.createElement(Input, { label: isPantry
                    ? translate('/Account/ProductList/CreateNewPantry')
                    : translate('/Account/ProductList/CreateNewFavoritelist'), onChange: function (e) { return _this.setState({ newName: e.target.value }); } }),
            React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.onNewConfirm, onCancel: this.onModalClose, onStatusWasFulfilled: this.onModalClose })));
    };
    return AddToListDialog;
}(React.PureComponent));
var AddToListDialogConnected = connect(function (state) { return ({
    favoritesUrl: state.appShellData.siteSettings.favoriteOverviewPage,
    pantryUrl: state.appShellData.siteSettings.pantryOverviewPage,
    pantriesFavorites: state.pantriesFavorites,
}); }, function (dispatch, _a) {
    var code = _a.code;
    return ({
        initLists: function () { return dispatch(initLists()); },
        addProductToFavoriteList: function (listId) { return dispatch(addProductToFavoriteList(listId, code)); },
        addProductToPantryList: function (listId) { return dispatch(addProductToPantryList(listId, code)); },
        createListAndAddProduct: function (newName, isFavorite) {
            return dispatch(createListAndAddProduct(newName, code, isFavorite));
        },
    });
})(AddToListDialog);
export default AddToListDialogConnected;
