var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { Basic, Part } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import NutritionFacts from 'Shared/NutritionFact/NutritionFacts';
import * as styles from './base.scss';
var ProductDescription = /** @class */ (function (_super) {
    __extends(ProductDescription, _super);
    function ProductDescription(props) {
        return _super.call(this, props) || this;
    }
    ProductDescription.prototype.render = function () {
        return this.props.tableData && this.props.tableData.length > 0 ? (React.createElement(Basic, { appearance: "default", className: classnames(styles.base, this.props.className) },
            React.createElement("div", { className: styles.productDescription, id: "productDescription" },
                this.props.text && (React.createElement("div", null,
                    React.createElement("div", { className: styles.title }, translate('/Product/Description')),
                    React.createElement("p", null, this.props.text))),
                React.createElement("div", { className: styles.flexWrapper },
                    this.props.list && (React.createElement(Part, { className: styles.flexWrapper__left },
                        React.createElement("b", null, translate('/Product/ArticleInformation')),
                        this.props.list)),
                    React.createElement(Part, { className: styles.flexWrapper__right })),
                !!this.props.tableData && this.props.tableData.length > 0 && (React.createElement("div", null,
                    React.createElement(NutritionFacts, { nutritionInformationProvider: this.props.nutritionInformationProvider, tableData: this.props.tableData })))))) : null;
    };
    return ProductDescription;
}(React.Component));
export default ProductDescription;
