var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import ReturnItem from './ReturnItems/ReturnItem';
import FeedbackButton from 'Shared/Button/Feedback';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import Modal, { ModalType } from 'Shared/Modal';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import * as server from 'Shared/server';
import Dialog, { DialogType } from 'Shared/Dialog';
import MyMenigoBasicLayout from '../../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout';
var ReturnOrderPage = /** @class */ (function (_super) {
    __extends(ReturnOrderPage, _super);
    function ReturnOrderPage(props) {
        var _this = _super.call(this, props) || this;
        _this.showConfirmationModal = function () {
            _this.setState({ isConfirmModalOpen: true });
        };
        _this.hideConfirmationModal = function () {
            _this.setState({ isConfirmModalOpen: false });
            if (_this.isSaved) {
                _this.resetList();
                _this.isSaved = false;
            }
        };
        _this.showWarningModal = function () {
            _this.setState({ isWarningModalOpen: true });
        };
        _this.hideWarningModal = function () {
            _this.setState({ isWarningModalOpen: false });
        };
        _this.onWarningModalConfirm = function () {
            return _this.onSave(true);
        };
        _this.resetList = function () {
            var listWithZeroQuantity = _this.state.items.map(function (item) { return (__assign({}, item, { quantity: 0 })); });
            _this.setState({ items: listWithZeroQuantity });
        };
        _this.onSave = function (ignore) {
            var data = {
                email: _this.state.email,
                items: _this.state.items,
                ruleOverride: ignore,
                selectedDelivery: _this.props.selectedDelivery,
            };
            return _this.feedbackButton.activate(server
                .postJson("/returnorder/sendreturnorder", data)
                .then(function (result) {
                if (result.success) {
                    if (result.returnOrderMessages != null && result.returnOrderMessages.length > 0) {
                        _this.setState({
                            returnOrderMessages: result.returnOrderMessages,
                        });
                        _this.showWarningModal();
                        return Promise.resolve();
                    }
                    _this.showConfirmationModal();
                    _this.isSaved = true;
                    return Promise.resolve();
                }
                else {
                    return Promise.reject(result);
                }
            })
                .catch(function (err) {
                _this.isSaved = false;
                var returnOrderResponseModel = err;
                if (returnOrderResponseModel && returnOrderResponseModel.returnOrderMessages.length > 0) {
                    _this.props.setFeedback({
                        text: returnOrderResponseModel.returnOrderMessages
                            .map(function (returnOrderMessage) { return returnOrderMessage.message; })
                            .join(' '),
                        type: 'error',
                    });
                }
                else {
                    _this.props.setFeedback({
                        text: err.message || translate('/MyMenigo/ReturnOrder/OrderFailure'),
                        type: 'error',
                    });
                }
                return Promise.reject(null);
            }), { minimumPending: 750, maximumRejected: 2000 });
        };
        _this.state = {
            email: _this.props.email,
            items: _this.props.items,
            isConfirmModalOpen: false,
            isWarningModalOpen: false,
            returnOrderMessages: [],
        };
        return _this;
    }
    ReturnOrderPage.prototype.updateItem = function (id, value) {
        var parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
        var newItems = this.state.items.map(function (item) { return (item.id === id ? __assign({}, item, { quantity: parsedValue }) : item); });
        this.setState({ items: newItems });
    };
    ReturnOrderPage.prototype.renderModal = function () {
        var deliveryDate = this.props.selectedDelivery ? this.props.selectedDelivery.departureDateAsString : '';
        var deliveryDeparture = this.props.selectedDelivery ? this.props.selectedDelivery.departure : '';
        return (React.createElement(React.Fragment, null,
            React.createElement(Modal, { type: ModalType.Success, isOpen: this.state.isConfirmModalOpen, onClose: this.hideConfirmationModal, reserveSpaceForCloseButton: true, fadeDesign: false },
                React.createElement("div", null,
                    React.createElement("p", null, translate('/mymenigo/returnorder/hello')),
                    React.createElement("div", null, translate('/mymenigo/returnorder/thankyou')),
                    React.createElement("div", null, translate('/mymenigo/returnorder/chosendelivery', deliveryDate, deliveryDeparture)),
                    React.createElement("p", null, translate('/mymenigo/returnorder/openinghours')),
                    React.createElement("div", null,
                        React.createElement("strong", null, translate('/mymenigo/returnorder/menigocustomerservice'))),
                    React.createElement("div", null,
                        translate('/mymenigo/returnorder/phone'),
                        ":",
                        React.createElement("a", { href: "tel:0107077390" }, " 010-707 73 90")),
                    React.createElement("p", null,
                        translate('/mymenigo/returnorder/email'),
                        ":",
                        React.createElement("a", { href: translate('/mymenigo/returnorder/customerserviceemailtoaddress') }, translate('/mymenigo/returnorder/customerserviceemailaddress'))),
                    React.createElement("p", null,
                        React.createElement("a", { href: translate('/mymenigo/returnorder/hostadressurl'), target: "_blank" }, translate('/mymenigo/returnorder/hostadresstext')))),
                React.createElement(Dialog, { type: DialogType.OkOnly, onConfirm: this.hideConfirmationModal, onCancel: this.hideConfirmationModal })),
            React.createElement(Modal, { className: styles.modal, type: ModalType.Warning, isOpen: this.state.isWarningModalOpen, onClose: this.hideWarningModal, reserveSpaceForCloseButton: true, fadeDesign: false },
                this.state.returnOrderMessages.map(function (_a, index) {
                    var message = _a.message, description = _a.description;
                    return (React.createElement(React.Fragment, { key: index },
                        React.createElement("p", null,
                            React.createElement("strong", null, message)),
                        React.createElement("p", null, description)));
                }),
                React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: this.onWarningModalConfirm, onCancel: this.hideWarningModal }))));
    };
    ReturnOrderPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, selectedDelivery = _a.selectedDelivery, errorMessage = _a.errorMessage, customerId = _a.customerId, customerName = _a.customerName;
        var _b = this.state, email = _b.email, items = _b.items;
        var deliveryInfo = selectedDelivery
            ? selectedDelivery.departureDateAsString + " | " + selectedDelivery.value
            : translate('/mymenigo/returnorder/deliveryinfomissing');
        return (React.createElement(MyMenigoBasicLayout, { model: this.props },
            React.createElement(CustomerInfo, { email: email, customerId: customerId, customerName: customerName, emailUpdate: function (value) { return _this.setState({ email: value }); } }),
            React.createElement("div", { className: styles.returnItemsContainer },
                React.createElement("h3", null, translate('/mymenigo/returnorder/deposittoreturn')),
                React.createElement("h2", null,
                    translate('/mymenigo/returnorder/referstodelivery'),
                    ": ",
                    deliveryInfo),
                errorMessage !== '' && React.createElement("h2", { className: styles.errorText }, errorMessage),
                React.createElement("div", { className: styles.labelHeadings },
                    React.createElement("div", null, translate('/mymenigo/returnorder/productid')),
                    React.createElement("div", null, translate('/mymenigo/returnorder/productname')),
                    React.createElement("div", null, translate('/mymenigo/returnorder/enterquantity'))),
                items.map(function (item, i) { return (React.createElement(ReturnItem, { key: item.id, returnItem: item, onUpdate: function (value) { return _this.updateItem(item.id, value); } })); })),
            React.createElement("div", { className: styles.feedbackButtonContainer },
                React.createElement(FeedbackButton, { appearance: ['large'], ref: function (el) { return (_this.feedbackButton = el); }, onClick: function () { return _this.onSave(); } }, translate('/mymenigo/returnorder/sendpost'))),
            this.renderModal()));
    };
    return ReturnOrderPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
}); })(ReturnOrderPage);
