import React from 'react';
import { translate } from 'Shared/translate';
import highlightText from 'Shared/highlight-text';
import { searchTextToWords } from './../../action-creators';
var ContentList = function (props) {
    return (props.content &&
        props.content.length > 0 && (React.createElement("div", null,
        React.createElement("h2", null, translate('/Search/ResultsForContent', props.searchPhrase, props.content.length)),
        React.createElement("ul", null, props.content.map(function (item, i) {
            return (React.createElement("li", { key: i },
                React.createElement("a", { href: item.url, "data-ticket": item.ticket }, highlightText(item.title, searchTextToWords(props.searchPhrase)))));
        })))));
};
export default ContentList;
