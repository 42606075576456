import { CampaignType } from '../ProductCampaignLabel';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
export var getCampaignTypeFromId = function (id) {
    switch (id) {
        case '01':
            return CampaignType.Campaign;
        case '02':
            return CampaignType.NewProducts;
        case '03':
            return CampaignType.WebbCampaign;
        case '04':
            return CampaignType.TemporaryOffer;
        case '05':
            return CampaignType.EarlyOrder;
        case '06':
            return CampaignType.BlackFriday;
        case '07':
            return CampaignType.Utf;
        case '08':
            return CampaignType.AlwaysAffordable;
        case '09':
            return null;
        case 'SustainableChoice':
            return CampaignType.SustainableChoice;
        case 'NonStockable':
            return CampaignType.NotInStock;
        case 'Quotation':
            return CampaignType.Quotation;
        case 'Utf':
            return CampaignType.Utf;
        case 'SelectedPriceList':
            return CampaignType.SelectedPriceList;
        case 'SoldOut':
            return CampaignType.SoldOut;
        case 'Discontinued':
            return CampaignType.Discontinued;
        default:
            return CampaignType.Campaign;
    }
};
export var getCampaignTypeNameFromId = function (id) {
    switch (id) {
        case '01':
            return translate('/Campaign/CampaignTypeNames/Campaign');
        case '02':
            return translate('/Campaign/CampaignTypeNames/New');
        case '03':
            return translate('/Campaign/CampaignTypeNames/WebbCampaign');
        case '04':
            return translate('/Campaign/CampaignTypeNames/TemporaryOffer');
        case '05':
            return translate('/Campaign/CampaignTypeNames/EarlyOrder');
        case '06':
            return translate('/Campaign/CampaignTypeNames/BlackFriday');
        case '07':
            return translate('/Product/Utf');
        case '08':
            return translate('/Campaign/CampaignTypeNames/AlwaysAffordable');
        case '09':
            return translate('/Campaign/CampaignTypeNames/FlashSale');
        case 'SustainableChoice':
            return translate('/Campaign/CampaignTypeNames/SustainableChoice');
        case 'NonStockable':
            return translate('/Product/NonStockable');
        case 'Quotation':
            return translate('/Product/Quotation');
        case 'Utf':
            return translate('/Product/Utf');
        case 'SelectedPriceList':
            return translate('/Campaign/CampaignTypeNames/SelectedPriceList');
        case 'SoldOut':
            return translate('/Campaign/CampaignTypeNames/SoldOut');
        case 'Discontinued':
            return translate('/Campaign/CampaignTypeNames/Discontinued');
        default:
            return translate('/Campaign/CampaignTypeNames/Campaign');
    }
};
export var getCampaignIcon = function (campaignType) {
    switch (campaignType) {
        case CampaignType.Utf:
        case CampaignType.TemporaryOffer:
            return 'closeOutCampaign';
        case CampaignType.Campaign:
        case CampaignType.WebbCampaign:
            return 'campaignTag';
        default:
            return null;
    }
};
export var getCampaignTextColor = function (campaignType) {
    switch (campaignType) {
        case CampaignType.NewProducts:
        case CampaignType.EarlyOrder:
            return styles.black;
        case CampaignType.Utf:
        case CampaignType.TemporaryOffer:
        case CampaignType.Campaign:
        case CampaignType.WebbCampaign:
        case CampaignType.BlackFriday:
            return styles.red;
        case CampaignType.NotInStock:
        case CampaignType.SoldOut:
        case CampaignType.Discontinued:
            return styles.hotpink;
        case CampaignType.Seasonal:
        case CampaignType.Eco:
        case CampaignType.SustainableChoice:
            return styles.green;
        case CampaignType.Quotation:
            return styles.gray;
        case CampaignType.AlwaysAffordable:
        case CampaignType.SelectedPriceList:
            return styles.purpletext;
        default:
            return null;
    }
};
export var getCampaignLabelToolTipText = function (campaignType) {
    switch (campaignType) {
        case CampaignType.NewProducts:
            return translate('/Campaign/CampaignToolTips/New');
        case CampaignType.Utf:
            return translate('/Campaign/CampaignToolTips/Utf');
        case CampaignType.TemporaryOffer:
            return translate('/Campaign/CampaignToolTips/TemporaryOffer');
        case CampaignType.Campaign:
            return translate('/Campaign/CampaignToolTips/Campaign');
        case CampaignType.WebbCampaign:
            return translate('/Campaign/CampaignToolTips/WebbCampaign');
        case CampaignType.EarlyOrder:
            return translate('/Campaign/CampaignToolTips/EarlyOrder');
        case CampaignType.NotInStock:
            return translate('/Campaign/CampaignToolTips/OutOfStock');
        case CampaignType.SpecialOffer:
            return translate('/Campaign/CampaignToolTips/Offer');
        case CampaignType.AlwaysAffordable:
            return translate('/Campaign/CampaignToolTips/AlwaysAffordable');
        case CampaignType.SustainableChoice:
            return translate('/Campaign/CampaignToolTips/SustainableChoice');
        case CampaignType.SelectedPriceList:
            return translate('/Campaign/CampaignToolTips/SelectedPriceList');
        case CampaignType.BlackFriday:
        case CampaignType.Seasonal:
        case CampaignType.Eco:
            return null;
        default:
            return null;
    }
};
