var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Image from 'Shared/ImageFallback';
import * as styles from './base.scss';
var SalesmanInformationBlock = /** @class */ (function (_super) {
    __extends(SalesmanInformationBlock, _super);
    function SalesmanInformationBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SalesmanInformationBlock.prototype.render = function () {
        return (React.createElement("div", { className: styles.salesperson_base },
            React.createElement("div", { className: styles.salesperson_imgarea },
                React.createElement(Image, { src: this.props.image, className: styles.salesperson_img })),
            React.createElement("div", { className: styles.salesperson_info },
                this.props.name,
                React.createElement("br", null),
                this.props.phoneNumber,
                React.createElement("br", null),
                this.props.emailAddress)));
    };
    return SalesmanInformationBlock;
}(React.Component));
export default SalesmanInformationBlock;
