import * as server from 'Shared/server';
import { clearCustomAssortment, clearStandardAssortment, addCustomAssortmentProduct, insertProducts, findNewCustomProducts as storageFindNewCustomProducts, searchAssortment as storageSearchAssortment, } from './app-websql-storage';
export function downloadStandard(setStatus, isCanceled) {
    setStatus('downloading', 0);
    return server
        .get('/assortment')
        .then(function (r) {
        if (isCanceled()) {
            throw new Error('Canceled');
        }
        setStatus('clearingDB', 2);
        return r;
    })
        .then(function (r) { return r.text(); })
        .then(function (r) { return clearStandard().then(function () { return r; }); })
        .then(function (r) { return readStandardFile(r, setStatus, isCanceled); });
}
export function downloadCustom(setStatus, isCanceled) {
    return server
        .get('/customProducts')
        .then(function (r) { return r.json(); })
        .then(function (r) {
        setStatus('ClearingDB', 2);
        return r;
    })
        .then(function (r) { return clearCustom().then(function () { return r; }); })
        .then(function (r) { return insertProducts(r); });
}
function clearCustom() {
    return clearCustomAssortment();
}
function clearStandard() {
    return clearStandardAssortment();
}
export function addCustomProduct(product) {
    if (!product.isCustomProduct) {
        throw new Error('Product must be custom');
    }
    return addCustomAssortmentProduct(product);
}
export function findNewCustomProducts() {
    return storageFindNewCustomProducts();
}
export function searchAssortment(query, limit, pantryId) {
    return storageSearchAssortment(query, limit, pantryId);
}
function readStandardFile(content, progress, isCanceled) {
    var lines = content.split('\n');
    var pos = 0;
    return new Promise(function (resolve, reject) {
        var batch = [];
        function runBatch() {
            return batch.length > 0 ? insertProducts(batch) : Promise.resolve({});
        }
        function next() {
            for (;;) {
                if (pos >= lines.length - 1) {
                    // The last line is empty
                    runBatch().then(function () { return resolve('done'); });
                    return;
                }
                var current = parseStandardLine(lines[pos++]);
                batch.push(current);
                if (pos % 300 === 0) {
                    runBatch()
                        .then(function () {
                        progress('inserting', Math.trunc((pos / lines.length) * 97) + 3);
                        batch = [];
                        next();
                    })
                        .catch(reject);
                    return;
                }
            }
        }
        next();
    });
}
function parseStandardLine(line) {
    var parts = line.split('\t');
    if (parts.length !== 8) {
        throw new Error('Invalid line "' + line + '" returned');
    }
    var productId = parts[0];
    var displayName = parts[1];
    var trademark = parts[2];
    var isWeightProduct = parts[3] === '1';
    var supplierArtNo = parts[4];
    var isCampaign = parts[5] === '1';
    var eans = parts[6] ? parts[6].split(',') : [];
    var unitOptions = parts[7] ? parts[7].split(';').map(parseUnitOption) : [];
    return {
        productId: productId,
        displayName: displayName,
        trademark: trademark,
        unitShortName: null,
        unitOptions: unitOptions,
        quantity: null,
        eans: eans,
        isCustomProduct: false,
        displayProductId: productId,
        isWeightProduct: isWeightProduct,
        supplierArtNo: supplierArtNo,
        isCampaign: isCampaign,
    };
}
function parseUnitOption(s) {
    var parts = s.split('|');
    if (parts.length !== 2) {
        throw new Error('Invalid unit option "' + s + '" returned');
    }
    return {
        unitShortName: parts[0],
        specification: parts[1],
        priceWithoutVat: null,
        availabilityCode: null,
    };
}
