var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import * as styles from '../Ips/base.scss';
import Icon from 'Shared/Icon';
var ShelfLabelItemRow = /** @class */ (function (_super) {
    __extends(ShelfLabelItemRow, _super);
    function ShelfLabelItemRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // https://bugs.chromium.org/p/chromium/issues/detail?id=4505
        _this.selectOnFocus = function (e) {
            var target = e.target;
            setTimeout(function () { return target.select(); }, 0);
        };
        return _this;
    }
    ShelfLabelItemRow.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: classnames(styles.item, styles.compact) },
            React.createElement("div", { className: styles.product }, this.props.displayName),
            React.createElement("div", { className: styles.quantity },
                React.createElement("input", { type: "number", onFocus: this.selectOnFocus, onChange: function (e) { return _this.props.edit(e.target.value); }, value: this.props.quantity.toString() })),
            React.createElement("div", { onClick: this.props.remove },
                React.createElement(Icon, { name: "close" }))));
    };
    return ShelfLabelItemRow;
}(React.Component));
export default ShelfLabelItemRow;
