import React from 'react';
import * as styles from './autocomplete.scss';
import MyMenigoIcon from '../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import propertyValueFor from 'Shared/property-value-for';
import Image, { urlWithSizeEx } from 'Shared/ImageFallback';
import { SMALL } from 'Shared/breakpoints';
import ViewTracker, { onClick } from './MarketingAdViewTracker';
var limitTextLength = function (text, length) {
    return text.length > length ? text.substring(0, length) + "..." : text;
};
var imageUrl = function (url) {
    return urlWithSizeEx(url, 'marketingAd', 'crop');
};
export default (function (props) { return (React.createElement(ViewTracker, { item: props.item },
    React.createElement("div", { className: styles.marketingAd },
        !!props.item.image && (React.createElement("div", { className: styles.left, style: { backgroundImage: "url(" + imageUrl(propertyValueFor(props.item.image)) + ")" } },
            React.createElement(Image, { preferSmallerIfCached: true, minSizeIfCached: "tiny", skipSrcSet: true, alt: propertyValueFor(props.item.header), src: urlWithSizeEx(propertyValueFor(props.item.image), 'marketingAd', 'crop') }))),
        React.createElement("div", { className: styles.right },
            !!props.item.header && (React.createElement("h3", null, propertyValueFor(props.item.header))),
            !!props.item.text && (React.createElement("p", null, !!(props.currentBreakpoint <= SMALL) ? limitTextLength(propertyValueFor(props.item.text), 150) : propertyValueFor(props.item.text))),
            !!props.item.link && (React.createElement("a", { href: propertyValueFor(props.item.link), onClick: function (e) { return onClick(e, props.item); }, "data-may-prevent-default": true, className: styles.button },
                propertyValueFor(props.item.marketingAdButtonText),
                " ",
                React.createElement(MyMenigoIcon, { name: 'arrow' }))))))); });
