var hidden;
var visibilityChange;
export function getVisibilityNames() {
    if (hidden !== undefined && visibilityChange !== undefined) {
        return {
            hidden: hidden,
            visibilityChange: visibilityChange,
        };
    }
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    }
    else if (typeof document['msHidden'] !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    }
    else if (typeof document['webkitHidden'] !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
    else {
        console.error('Can not query visibility api');
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    }
    return getVisibilityNames();
}
