import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import { searchPageUrl } from 'Shared/known-urls';
import * as searchHistory from './search-history';
import { recipeOverviewPageUrl } from 'Shared/known-urls';
export var SHOW_LISTSEARCH = 'SHOW_LISTSEARCH';
export var SHOW_QUICKSEARCH = 'SHOW_QUICKSEARCH';
export var HIDE_QUICKSEARCH = 'HIDE_QUICKSEARCH';
export var UPDATE_QUICKSEARCH_TEXT = 'UPDATE_QUICKSEARCH_TEXT';
export var UPDATE_QUICKSEARCH_COMPLETIONS = 'UPDATE_QUICKSEARCH_COMPLETIONS';
export var UPDATE_SELECTED_QUICKSEARCH_COMPLETION = 'UPDATE_SELECTED_QUICKSEARCH_COMPLETION';
export var UPDATE_QUICKSEARCH_CART = 'UPDATE_QUICKSEARCH_CART';
export var CLEAR_QUICKSEARCH_CART = 'CLEAR_QUICKSEARCH_CART';
export var CLOSE_QUICKSEARCH = 'CLOSE_QUICKSEARCH';
export var SEARCH_HISTORY_LIST_ID = 'searchHistory';
export var POPULAR_SEARCHES_LIST_ID = 'popular';
export var SUGGESTIONS_LIST_ID = 'suggestions';
export var PRODUCT_LIST_ID = 'products';
var autocompletionCount = 5;
var searchHistoryCount = 3;
var lastRequestId = 0;
export function hideQuickSearch(setFirstSuggestionAsSearchText) {
    if (setFirstSuggestionAsSearchText === void 0) { setFirstSuggestionAsSearchText = true; }
    return {
        type: HIDE_QUICKSEARCH,
        setFirstSuggestionAsSearchText: setFirstSuggestionAsSearchText,
    };
}
export function closeQuickSearch() {
    return {
        type: CLOSE_QUICKSEARCH,
    };
}
export function showQuickSearch() {
    return {
        type: SHOW_QUICKSEARCH,
    };
}
export function showListSearch() {
    return {
        type: SHOW_LISTSEARCH,
    };
}
export function updateSelectedCompletion(index, selectionType) {
    return {
        type: UPDATE_SELECTED_QUICKSEARCH_COMPLETION,
        index: index,
        selectionType: selectionType,
    };
}
export function updateQuickSearchCart(code, quantity, unitShortName, ticket) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_QUICKSEARCH_CART,
            code: code,
            quantity: quantity,
            unitShortName: unitShortName,
            ticket: ticket,
        });
        return Promise.resolve();
    };
}
export function clearQuickSearchCart() {
    return {
        type: CLEAR_QUICKSEARCH_CART,
    };
}
export function updateQuickSearchText(searchText) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_QUICKSEARCH_TEXT,
            searchText: searchText,
        });
    };
}
export function loadQuickSearchRecipes(searchText) {
    var url = recipeOverviewPageUrl() + '?query=' + searchText;
    return function (dispatch) {
        return server.getJson(url).then(function (result) {
            return result && result.list && result.list.recipes;
        });
    };
}
export function loadFilteredQuickSearchRecipes(searchText, recipeId) {
    var url = pathCombine('/RecipeOverviewPage', '/Search?query=' + searchText + '&recipeId=' + recipeId);
    return function (dispatch) {
        return server.getJson(url).then(function (result) {
            return result && result.recipes;
        });
    };
}
export function setQuickSearchText(searchText) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_QUICKSEARCH_TEXT,
            searchText: searchText,
        });
    };
}
export function loadQuickSearch(searchText) {
    return function (dispatch) {
        var filteredSearchHistory = searchHistory
            .find(searchTextToWords(searchText))
            .filter(function (s) { return s.toLowerCase() !== searchText.toLowerCase(); })
            .map(function (s) { return ({ text: s }); })
            .slice(0, searchHistoryCount);
        dispatch({
            type: UPDATE_QUICKSEARCH_COMPLETIONS,
            lists: [
                {
                    id: SEARCH_HISTORY_LIST_ID,
                    items: filteredSearchHistory,
                },
            ],
        });
        dispatch({
            type: UPDATE_QUICKSEARCH_TEXT,
            searchText: searchText,
        });
        var requestId = (lastRequestId = Math.random());
        if (searchText.length > 0) {
            return server
                .get({
                path: pathCombine(searchPageUrl(), 'autocomplete'),
                query: { q: searchText, count: autocompletionCount },
            })
                .then(function (r) { return r.json(); })
                .then(function (json) {
                if (requestId === lastRequestId) {
                    dispatch({
                        type: UPDATE_QUICKSEARCH_COMPLETIONS,
                        totalItems: json.totalItems,
                        lists: [
                            {
                                id: POPULAR_SEARCHES_LIST_ID,
                                items: [],
                            },
                            {
                                id: SUGGESTIONS_LIST_ID,
                                items: json.completions,
                                products: json.products,
                                content: json.content,
                                popularSearches: json.popularSearches,
                                searchDidYouMean: json.searchDidYouMean,
                                categorySearchHits: json.categorySearchHits,
                            },
                        ],
                    });
                }
            });
        }
        else {
            return server
                .get({
                path: pathCombine(searchPageUrl(), 'searchsuggestions'),
                query: { q: searchText, count: -1 },
            })
                .then(function (r) { return r.json(); })
                .then(function (json) {
                if (requestId === lastRequestId) {
                    dispatch({
                        type: UPDATE_QUICKSEARCH_COMPLETIONS,
                        lists: [
                            {
                                id: SUGGESTIONS_LIST_ID,
                                items: [],
                            },
                            {
                                id: POPULAR_SEARCHES_LIST_ID,
                                items: json,
                            },
                        ],
                    });
                }
            });
        }
    };
}
export function loadProductSearch(searchText) {
    return function (dispatch) {
        var requestId = (lastRequestId = Math.random());
        if (searchText.length > 0) {
            return server
                .get({
                path: pathCombine(searchPageUrl(), 'autocompletecustom'),
                query: { query: searchText, allowCustom: true, recalcPrice: false },
            })
                .then(function (r) { return r.json(); })
                .then(function (json) {
                if (requestId === lastRequestId) {
                    return json.products;
                }
            });
        }
    };
}
export function searchTextToWords(searchText) {
    return searchText.replace(/\s+/, ' ').split(' ');
}
