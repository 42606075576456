import React from 'react';
import { translate } from 'Shared/translate';
import propertyValueFor from 'Shared/property-value-for';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import classnames from 'classnames';
import * as styles from './base.scss';
import { parseUrl } from 'Shared/url';
import ProductTrademarkLink from '../ProductTrademarkLink';
var getReplacementTrademarkImage = function (trademark) {
    if (parseUrl(trademark).path.match(/[^"'<>]+.(?:png|jpe?g|gif)\b/gi)) {
        return React.createElement("img", { src: trademark });
    }
    return trademark;
};
var formatTrademark = function (trademark, trademarkLink) {
    if (!trademarkLink) {
        return getReplacementTrademarkImage(trademark);
    }
    return React.createElement(ProductTrademarkLink, { link: trademarkLink, ticket: '' }, getReplacementTrademarkImage(trademark));
};
var ProductDetails = function (_a) {
    var code = _a.code, linkUrl = _a.linkUrl, ticket = _a.ticket, trademark = _a.trademark, className = _a.className, productArea = _a.productArea, hideType = _a.hideType, trademarkLink = _a.trademarkLink, searchTerm = _a.searchTerm;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.articleNumber },
            React.createElement(ProductLink, { linkUrl: linkUrl, ticket: ticket, searchTerm: searchTerm, code: (typeof code === 'string' ? code : propertyValueFor(code)) },
                translate('/ProductListing/ArticleNumber'),
                " ",
                typeof code === 'string' ? code : propertyValueFor(code))),
        !!trademark && React.createElement("div", { className: classnames(styles.trademark, className) }, formatTrademark(trademark, trademarkLink)),
        !!productArea && React.createElement("div", { className: styles.supplier }, productArea)));
};
export default ProductDetails;
