var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import * as styles from './mobile.scss';
import Icon from 'Shared/Icon';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import ProductMarks from 'Shared/ProductCampaignLabel';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import AddRemoveFromListOrLogin from 'Shared/Product/AddRemoveFromListOrLogin';
import ProductOrdinal from 'Shared/ProductComponents/ProductOrdinal';
import RemoveProduct from '../RemoveProduct';
import { Checkbox } from 'Shared/Fields';
import { translate } from 'Shared/translate';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
import ProductAlternativesLink from 'Shared/ProductAlternativesLink/ProductAlternativesLink';
import VariantAndQuantity from '../../ProductCard/VariantAndQuantity';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
var MobileProduct = function (_a) {
    var _b, _c;
    var cartItemQuantity = _a.cartItemQuantity, focusItem = _a.focusItem, getStep = _a.getStep, isActive = _a.isActive, isPantry = _a.isPantry, inventoryStatus = _a.inventoryStatus, onFocusQuantity = _a.onFocusQuantity, product = _a.product, quantity = _a.quantity, selectedVariant = _a.selectedVariant, updateQuantity = _a.updateQuantity, disabled = _a.disabled, isRecommendations = _a.isRecommendations, rowIndex = _a.rowIndex, passedUpdateOrdinal = _a.passedUpdateOrdinal, passedOrdinal = _a.passedOrdinal, selectElement = _a.selectElement, listId = _a.listId, code = _a.code, passedRemoveListItem = _a.passedRemoveListItem, showDelete = _a.showDelete, checked = _a.checked, toggleSelected = _a.toggleSelected, maxQuantity = _a.maxQuantity, isLocked = _a.isLocked, setActive = _a.setActive;
    var url = product.isDiscontinued ||
        product.isDisabledForFreCustomer ||
        product.isDeletedByMaster ||
        product.isBackorderReplaced
        ? ''
        : product.url;
    var discontinuedOrBackordered = product.isDiscontinued ||
        product.isDisabledForFreCustomer ||
        product.isBackorderReplaced ||
        product.isDeletedByMaster;
    var disabledFadeClassName = discontinuedOrBackordered ? styles.disabledFade : '';
    var showInfoIfDisabledAndPantry = isPantry || (!isPantry && !discontinuedOrBackordered);
    var round = function (num, precision) { return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(styles.card, (_b = {},
                _b[styles.isRecommendation] = isRecommendations,
                _b)), itemType: "http://schema.org/Product", itemScope: true },
            React.createElement("div", { className: styles.cardInner },
                React.createElement("div", { className: styles.listMethodsWrap },
                    React.createElement(Checkbox, { className: styles.toggleSelected, checked: checked, onClick: function () { return selectElement(rowIndex, 'checkbox'); }, onChange: function (e) {
                            return toggleSelected(code, e.target.checked);
                        } }),
                    React.createElement("div", { className: styles.topRow },
                        React.createElement("div", { className: styles.titleWrap },
                            React.createElement(ProductTitle, { linkUrl: url ? url.toString() : '', code: selectedVariant.code, ticket: product.ticket, displayName: product.title, className: styles.title, trademark: product.trademark, isCustom: product.isCustom && !product.isLocked && !product.isDeletedByMaster })),
                        !isPantry && !discontinuedOrBackordered && selectedVariant.availability && (React.createElement("div", { className: styles.availabilityWrap },
                            React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability, disableAvailabilityDate: true })))),
                    React.createElement("div", { className: styles.ordinalWrap }, !isRecommendations && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.ordinalTitle }, translate('/ProductListing/Ordinal')),
                        React.createElement(ProductOrdinal, { passedUpdateOrdinal: passedUpdateOrdinal, passedOrdinal: passedOrdinal, selectElement: selectElement, rowIndex: rowIndex, disabled: false })))),
                    isLocked && (React.createElement("div", { className: styles.lockButtonWrap, onClick: function () { return setActive(); } },
                        React.createElement(Icon, { appearance: "sans", name: "lock" })))),
                React.createElement("div", { className: styles.imageWrap },
                    React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url ? url.toString() : '', code: selectedVariant && selectedVariant.code },
                        React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.title, isCustom: product.isCustom, size: "tiny" }))),
                React.createElement("div", { className: styles.innerFlexDiv },
                    React.createElement("div", { className: styles.outerFlexWrap },
                        React.createElement("div", { className: styles.contentRow },
                            React.createElement("div", { className: styles.detailsWrap },
                                showInfoIfDisabledAndPantry && (React.createElement("div", { className: classnames(styles.typeAndUnitWrap, disabledFadeClassName) },
                                    React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: selectedVariant, discontinuedOrBackordered: isPantry ? false : discontinuedOrBackordered, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign, productArea: product.area }))),
                                React.createElement(ProductDetails, { code: product.code, linkUrl: url ? url.toString() : '', ticket: product.ticket, className: disabledFadeClassName, productArea: product.area }),
                                !!product.carbonFootPrint && !!product.carbonCloudLink && (React.createElement("div", { className: styles.carbonCloud },
                                    React.createElement(ToolTip, { persistent: false, text: translate('/ProductListing/CarbonFootprintDescription', round(product.carbonFootPrint, 2)), carbonCloud: true },
                                        round(product.carbonFootPrint, 1),
                                        " ",
                                        translate('/ProductListing/CarbonFootprintUnit'))))),
                            showInfoIfDisabledAndPantry && (React.createElement("div", { className: classnames(styles.priceWrap, disabledFadeClassName) },
                                !isRecommendations && (React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: isPantry })),
                                React.createElement("div", { className: styles.marksAndDisabledInfoWrap }, !discontinuedOrBackordered && (React.createElement("div", { className: styles.marksWrap },
                                    React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice })))),
                                isPantry && product.previousQuantity ? (React.createElement("div", null,
                                    translate('/Account/ProductList/LastInventory'),
                                    " ",
                                    product.previousQuantity)) : null,
                                (isPantry && inventoryStatus === 'never') && (React.createElement("div", null, translate('/Account/ProductList/InventoryNever')))))),
                        !isPantry && discontinuedOrBackordered && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.disabledIconWrap },
                                React.createElement(Icon, { className: styles.disabledIcon, name: "exclamation-mark" })),
                            React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender, isPantry: isPantry, isDisabledForFreCustomer: product.isDisabledForFreCustomer }))),
                        isPantry && discontinuedOrBackordered && (React.createElement("div", { className: styles.pantryDisabledInfoWrap },
                            React.createElement("div", { className: styles.disabledIconWrap },
                                React.createElement(Icon, { className: styles.disabledIcon, name: "exclamation-mark" })),
                            React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender, isPantry: isPantry, isDisabledForFreCustomer: product.isDisabledForFreCustomer }))))),
                React.createElement("div", { className: styles.bottomRow },
                    React.createElement("div", { className: styles.left },
                        showDelete && (React.createElement("div", { className: classnames(styles.circle, styles.removeButtonWrap) },
                            React.createElement(RemoveProduct, { code: code, listId: listId, removeProductFromList: function () { return passedRemoveListItem(rowIndex, code); } }))),
                        React.createElement("div", { className: styles.listBtnsWrap },
                            React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product }))),
                    React.createElement("div", { className: styles.right },
                        !isPantry && React.createElement(ProductAvailabilityLabel, { disableAvailabilityMarkers: true, availability: selectedVariant.availability }),
                        React.createElement("div", { className: classnames(styles.gropupedItems, (_c = {}, _c[styles.gropupedItemsFavorites] = !isPantry, _c)) },
                            !isRecommendations && (isPantry && inventoryStatus === 'completed' && quantity != undefined) && (React.createElement("div", null,
                                React.createElement("div", { className: styles.quantityNoInput },
                                    React.createElement("strong", null,
                                        quantity,
                                        " ",
                                        selectedVariant.unitShortName)),
                                React.createElement("div", { className: styles.quantityNoInput },
                                    React.createElement("span", null,
                                        (selectedVariant.price.current * quantity).toFixed(2),
                                        " ",
                                        selectedVariant.price.currencySymbol)))),
                            React.createElement(VariantAndQuantity, { product: __assign({}, product), index: rowIndex, isRestrictedCustomer: false, updateCartQuantity: updateQuantity, quantityStep: getStep(), quantityInCart: cartItemQuantity, minQuantity: 0, isCartQuantity: true, variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, discontinuedOrBackordered: discontinuedOrBackordered, url: '', isFavorite: true, isPantry: isPantry, isQuantityVisible: !isPantry || inventoryStatus === 'ongoing' || inventoryStatus === 'reOpened', isVariantVisible: !isPantry || inventoryStatus === 'ongoing' || inventoryStatus === 'reOpened' || (inventoryStatus !== 'never' && quantity != undefined), selectElement: selectElement, quantity: quantity, maxQuantity: maxQuantity, rowIndex: rowIndex, isDisabled: disabled, isMobile: true, mobileFocus: isActive && focusItem === 'quantity', mobileOnFocus: onFocusQuantity })),
                        !isPantry && React.createElement(ProductAlternativesLink, { product: __assign({}, product), selectedVariant: selectedVariant })))))));
};
export default MobileProduct;
