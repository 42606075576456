// This module exists both to handle browsers that doesn't have `localStorage`
// and for private browsing in iOS which throws exceptions when you call `setItem`
var fauxStorage = {};
var requestTimeout = 100;
export function set(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    }
    catch (e) {
        fauxStorage[key] = value;
        console.error('localStorage.setItem() failed:', e);
    }
}
export function get(key, defaultValue) {
    try {
        var value = localStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }
        else {
            return JSON.parse(value);
        }
    }
    catch (e) {
        console.error('localStorage.getItem() failed:', e);
        return fauxStorage[key];
    }
}
function timeout() {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({ timeout: true });
        }, requestTimeout);
    });
}
export function nuke() {
    try {
        Promise.race([timeout(), localStorage.clear()]);
    }
    catch (e) {
        console.error('localStorage.clear() failed:', e);
        fauxStorage = {};
    }
}
