import React from 'react';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import ProductPriceDisplay from './ProductPriceDisplay';
import * as styles from './base.scss';
var ProductPrice = function (_a) {
    var isSupplierSalesman = _a.isSupplierSalesman, showOriginal = _a.showOriginal, isWeightProduct = _a.isWeightProduct, productIsInCampaign = _a.productIsInCampaign, selectedVariant = _a.selectedVariant, variants = _a.variants, isPantry = _a.isPantry;
    if (isSupplierSalesman || !selectedVariant.price) {
        return null;
    }
    var renderVariants = isWeightProduct ? variants : [selectedVariant];
    var hasPrice = !!selectedVariant.price.current || !!selectedVariant.price.original;
    var hasZeroPrice = (selectedVariant.price.current == 0.0 || selectedVariant.price.original == 0.0) && !!isPantry;
    return hasPrice || hasZeroPrice ? (React.createElement("div", { className: styles.prices }, renderVariants.map(function (_a) {
        var code = _a.code, price = _a.price;
        var isInCampaign = !showOriginal && productIsInCampaign && price.current < price.original;
        return (React.createElement("div", { className: styles.price, key: code }, isInCampaign ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.originalPrice },
                React.createElement(ProductPriceDisplay, { showItemAttributes: false, showOriginal: true, price: price, id: selectedVariant.code })),
            React.createElement("div", { className: styles.campaignPrice },
                React.createElement(ProductPriceDisplay, { showItemAttributes: true, showOriginal: false, price: price, id: selectedVariant.code })))) : (React.createElement(ProductPriceDisplay, { showItemAttributes: true, showOriginal: showOriginal, price: price, id: selectedVariant.code }))));
    }))) : (React.createElement("div", { className: styles.noAvailablePrice }, translate('/Price/NoPriceAvailable')));
};
export default connect(function (state) { return ({
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); })(ProductPrice);
