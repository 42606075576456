import { UPDATE_SHOPPINGLIST, CLEAR_SHOPPINGLIST, INIT_SHOPPINGLIST, } from './action-creators';
var shoppingListReducer = function (shoppingListState, action) {
    if (shoppingListState === void 0) { shoppingListState = []; }
    switch (action.type) {
        case UPDATE_SHOPPINGLIST: {
            var shoppingListAction_1 = action;
            var stateCartItems = shoppingListState.filter(function (i) { return i.code !== shoppingListAction_1.code; });
            if (shoppingListAction_1.quantity && shoppingListAction_1.quantity > 0) {
                stateCartItems.push({
                    code: shoppingListAction_1.code,
                    quantity: shoppingListAction_1.quantity,
                    unitShortName: shoppingListAction_1.unitShortName,
                    ticket: shoppingListAction_1.ticket,
                });
            }
            return stateCartItems;
        }
        case INIT_SHOPPINGLIST: {
            var shoppingListAction = action;
            return shoppingListAction.shoppingList;
        }
        case CLEAR_SHOPPINGLIST: {
            return [];
        }
        default:
            break;
    }
    return shoppingListState;
};
export default shoppingListReducer;
export var getShoppingList = function (state) { return state.shoppingList; };
