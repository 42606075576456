var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import connect from 'Shared/connect';
import { Main, Part } from 'Shared/PageLayout';
import expandPlaceholders from 'Shared/expand-placeholders';
import currentPageIsCheckout from '../../Checkout/Pages/Checkout/current-page-is-checkout';
import classnames from 'classnames';
import * as headerstyle from './footer-header.scss';
import * as styles from './base.scss';
import AboveFooter from './AboveFooter';
var copyrightText = function (text) { return expandPlaceholders(text, { year: new Date().getFullYear() }); };
var MainFooter = memo(function (props) {
    if (IS_APP || props.currentPageIsCheckout) {
        return React.createElement("noscript", null);
    }
    return (React.createElement("footer", null,
        React.createElement(AboveFooter, __assign({}, props)),
        React.createElement(Main, { layout: "1to1", className: styles.thirdArea },
            React.createElement(Part, null,
                React.createElement("div", { className: headerstyle.h4 }, props.footer.bottomAreaTitle),
                React.createElement("span", null, props.footer.bottomAreaBody)),
            React.createElement(Part, { className: classnames(styles.thirdArea, styles.copyright) },
                React.createElement("span", null, copyrightText(props.footer.copyrightText))))));
});
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    footer: state.footer,
    currentPageIsCheckout: currentPageIsCheckout(state.currentPage),
}); })(MainFooter);
