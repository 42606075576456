var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition';
import Button from 'Shared/Button';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import connect from 'Shared/connect';
import { dismissFeedback } from './action-creators';
import * as styles from './base.scss';
var GenericFeedbackBar = /** @class */ (function (_super) {
    __extends(GenericFeedbackBar, _super);
    function GenericFeedbackBar(props) {
        var _this = _super.call(this, props) || this;
        _this.onButtonClick = function () {
            _this.props.dismissFeedback();
            if (_this.props.feedbackBanner.callback) {
                _this.props.feedbackBanner.callback();
            }
        };
        _this.state = {
            dismissed: null,
        };
        return _this;
    }
    GenericFeedbackBar.prototype.initTimeout = function () {
        var _this = this;
        if (this.props.feedbackBanner.visible && !!!this.props.feedbackBanner.overrideTimeout) {
            if (this.timeout !== null) {
                clearTimeout(this.timeout);
            }
            var timeout = !!this.props.feedbackBanner.timeout
                ? this.props.feedbackBanner.timeout
                : this.props.feedbackBanner.type === 'default' || this.props.feedbackBanner.type === 'success'
                    ? 5000
                    : this.props.feedbackBanner.type === 'warning'
                        ? 10000
                        : 15000;
            this.timeout = setTimeout(function () {
                _this.props.dismissFeedback();
            }, timeout);
        }
    };
    GenericFeedbackBar.prototype.componentDidUpdate = function () {
        this.initTimeout();
    };
    GenericFeedbackBar.prototype.componentDidMount = function () {
        this.initTimeout();
    };
    GenericFeedbackBar.prototype.render = function () {
        return (React.createElement(ReactCSSTransitionGroup, { in: this.props.feedbackBanner.visible, classNames: {
                appear: styles.enter,
                appearActive: styles.enterActive,
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.leave,
                exitActive: styles.leaveActive,
            }, mountOnEnter: true, unmountOnExit: true, appear: true, timeout: { enter: 300, exit: 300 } },
            React.createElement("div", { className: classnames(styles.base, styles[this.props.feedbackBanner.type]), key: "networkErrorMsg" },
                React.createElement("div", { className: styles.wrapper },
                    this.props.feedbackBanner.text,
                    React.createElement(Button, { appearance: 'clear', "data-ta": "close-feedback-banner-button", onClick: this.onButtonClick },
                        React.createElement(Icon, { name: 'close', className: styles.white }))))));
    };
    return GenericFeedbackBar;
}(React.PureComponent));
export default connect(function (state) { return ({
    feedbackBanner: state.feedbackBanner,
}); }, function (dispatch) { return ({
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(GenericFeedbackBar);
