import React from 'react';
import { translate } from 'Shared/translate';
var Freight = function (props) {
    var freightList = props.list;
    var firstFreight = freightList.length > 0 ? freightList[0] : null;
    var lastLimitNotFree = 999999;
    var minFreight;
    var maxFreight;
    var valFreight;
    if (firstFreight != null) {
        minFreight = firstFreight.limit;
        maxFreight = firstFreight.limit;
        valFreight = firstFreight.value;
    }
    return (React.createElement("div", null,
        React.createElement("h3", null, translate('/Cart/DiscountModal/FreightDiscountHeader')),
        React.createElement("p", null, translate('/Cart/DiscountModal/FreightDiscountDescription')),
        React.createElement("h4", null, translate('/Cart/DiscountModal/FreightDiscount')),
        React.createElement("div", null,
            React.createElement("div", null, translate('/Cart/DiscountModal/FreightDiscountText', maxFreight.toString(), valFreight.toString()))),
        freightList.map(function (freight, index) {
            var rendering;
            valFreight = freight.value;
            if (index !== 0) {
                if (freight.limit > lastLimitNotFree - 1) {
                    rendering = (React.createElement("div", { key: index },
                        React.createElement("div", null, translate('/Cart/DiscountModal/FreightDiscountText3', minFreight.toString(), valFreight.toString()))));
                }
                else {
                    maxFreight = freight.limit - 1;
                    rendering = (React.createElement("div", { key: index },
                        React.createElement("div", null, translate('/Cart/DiscountModal/FreightDiscountText1', minFreight.toString(), maxFreight.toString(), valFreight.toString()))));
                }
            }
            minFreight = freight.limit;
            return rendering;
        }),
        minFreight < lastLimitNotFree && (React.createElement("div", null,
            React.createElement("div", null, translate('/Cart/DiscountModal/FreightDiscountText2', minFreight.toString()))))));
};
export default Freight;
