var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Main, { Part } from 'Shared/PageLayout';
import ShelfLabelItemRow from './ShelfLabelItemRow';
import Backbutton from '../../../App/Backbutton';
import Searchbar from '../../../App/Search/Searchbar';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import SearchResultList from '../../../App/Search/SearchResultsList';
import ProductItem from '../../../App/ProductItem/ProductItem';
import connect from 'Shared/connect';
import { loadList, addItem, clearItems, removeItem, exportList, changeItemQuantity, clearState, } from '../../action-creators';
import FeedbackPage from '../Ips/FeedbackPage';
import { ExportingStatus } from '../../reducer';
import * as styles from '../Ips/base.scss';
import classnames from 'classnames';
var mode = 'shelflabel';
var ShelfLabelPage = /** @class */ (function (_super) {
    __extends(ShelfLabelPage, _super);
    function ShelfLabelPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShelfLabelPage.prototype.componentWillMount = function () {
        this.props.loadList();
    };
    ShelfLabelPage.prototype.componentWillUnmount = function () {
        this.props.clearState();
    };
    ShelfLabelPage.prototype.handleSelectedItem = function (item) {
        var label = {
            id: item.productId,
            displayName: item.displayName,
            quantity: 1,
        };
        this.props.addItem(label);
    };
    ShelfLabelPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { className: styles.main },
            this.props.exportingStatus !== ExportingStatus.None && React.createElement(FeedbackPage, null),
            this.props.exportingStatus === ExportingStatus.None && [
                React.createElement(Backbutton, { key: "title", title: translate('/App/ShelfLabelButton') }),
                React.createElement(Part, { className: styles.part, key: "toolbar" },
                    React.createElement(Searchbar, { endpoint: '/ips' }),
                    React.createElement(SearchResultList, { template: ProductItem, onItemSelected: function (item) { return _this.handleSelectedItem(item); } })),
                React.createElement(Part, { className: styles.part, key: "list" },
                    this.props.items.length === 0 && (React.createElement("div", { className: styles.emptyRows },
                        React.createElement("strong", null, translate('/App/Ips/OrderEmptyHeader')),
                        React.createElement("p", null, translate('/App/Ips/OrderEmpty')))),
                    this.props.items.map(function (item) { return (React.createElement(ShelfLabelItemRow, __assign({ key: item.id, remove: function () { return _this.props.removeItem(item.id); }, edit: function (value) { return _this.props.changeItemQuantity(item.id, parseFloat(value)); } }, item))); })),
            ],
            React.createElement(Part, { className: classnames(styles.part, styles.buttons) },
                this.props.exportingStatus === ExportingStatus.None && (React.createElement(Button, { onClick: function () { return _this.props.exportList(); } }, translate('/App/Ips/OrderShelfLabel'))),
                this.props.exportingStatus === ExportingStatus.Success && (React.createElement(Button, { onClick: function () { return _this.props.clearItems(); } }, translate('/App/Ips/NewOrder'))))));
    };
    return ShelfLabelPage;
}(React.Component));
export default connect(function (state) { return ({
    items: state.ips.items,
    exportingStatus: state.ips.exportingStatus,
}); }, function (dispatch) { return ({
    loadList: function () {
        dispatch(loadList(mode));
    },
    addItem: function (item) {
        dispatch(addItem(mode, item));
    },
    clearItems: function () {
        dispatch(clearItems(mode));
    },
    removeItem: function (id) {
        dispatch(removeItem(mode, id));
    },
    changeItemQuantity: function (id, value) {
        dispatch(changeItemQuantity(mode, id, value));
    },
    exportList: function () {
        dispatch(exportList(mode));
    },
    clearState: function () {
        dispatch(clearState(mode));
    },
}); })(ShelfLabelPage);
