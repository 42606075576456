var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import NewListButton from 'Shared/ListItem/NewListButton';
import { MasterFor } from 'Shared/MasterFor';
import * as styles from '../base.scss';
import * as base from './base.scss';
var Toolbar = function (props) { return (React.createElement("div", { className: styles.toolbar },
    React.createElement("div", { className: base.btn },
        React.createElement(NewListButton, null)),
    React.createElement(MasterFor, __assign({ className: base.toolButton }, props)))); };
export default Toolbar;
