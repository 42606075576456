import React from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import Checkbox from 'Shared/Fields/Checkbox';
import { SMALL } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import * as productStyles from './Product/base.scss';
import { SortOrder, sortList } from './action-creators';
import { sortRecommendations } from './../../Pantries/action-creators';
var ListItemHeader = function (_a) {
    var _b, _c;
    var isMobile = _a.isMobile, isPantry = _a.isPantry, isRecommendations = _a.isRecommendations, sortBy = _a.sortBy, sortByTitle = _a.sortByTitle, sortByCustomOrder = _a.sortByCustomOrder, sortByArea = _a.sortByArea, selected = _a.selected, selectAll = _a.selectAll, hasProducts = _a.hasProducts;
    return !isMobile && (React.createElement("div", { className: styles.rowHeaderWrap },
        React.createElement("div", { className: classnames(styles.rowHeader, productStyles.rowHeader) },
            React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.checkboxWrap) },
                React.createElement(Checkbox, { disabled: !hasProducts, checked: hasProducts && selected, onChange: function (e) { return selectAll(e.target.checked); } })),
            React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.imageWrap) }),
            React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.detailsWrap) },
                React.createElement("div", null,
                    React.createElement("a", { className: classnames(styles.sortable, (_b = {},
                            _b[styles.arrowUp] = sortBy === SortOrder.SORT_TITLE_ASC,
                            _b[styles.arrowDown] = sortBy === SortOrder.SORT_TITLE_DESC,
                            _b)), onClick: sortByTitle }, translate('/ProductListing/Product')))),
            React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.listItemInformationWrap, isPantry ? productStyles.listItemInformationWrap_smaller : productStyles.listItemInformationWrap_small) },
                React.createElement("div", null,
                    isRecommendations && (React.createElement(React.Fragment, null, translate('/ProductListing/UnitAndType'))),
                    !isRecommendations && (React.createElement("div", { className: classnames(productStyles.unitAndPriceWrap) },
                        React.createElement("div", null,
                            translate('/ProductListing/Price'),
                            " / ",
                            translate('/ProductListing/Unit')))))),
            !isRecommendations && !isPantry && (React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.availabilityWrap) }, translate('/ProductListing/StockStatusCompact'))),
            !isRecommendations && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.quantityAndVariantWrap_big, isPantry && productStyles.amountAndSum) }, translate('/ProductListing/Quantity')),
                !isRecommendations && isPantry && (React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.quantityAndVariantWrap_big, productStyles.amountAndSum) }, translate('/ProductListing/Sum'))),
                React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.rowActionWrap) },
                    React.createElement("a", { className: classnames(styles.sortable, (_c = {},
                            _c[styles.arrowUp] = sortBy === SortOrder.SORT_CUSTOMORDER_ASC,
                            _c[styles.arrowDown] = sortBy === SortOrder.SORT_CUSTOMORDER_DESC,
                            _c)), onClick: sortByCustomOrder }, translate('/ProductListing/Ordinal'))))),
            isRecommendations && (React.createElement("div", { className: classnames(productStyles.cardColumn, productStyles.rowActionWrap) })))));
};
export default connect(function (state) { return ({
    isMobile: state.currentBreakpoint <= SMALL,
}); }, function (dispatch, _a) {
    var listId = _a.listId, sortBy = _a.sortBy, isRecommendations = _a.isRecommendations;
    return ({
        sortByTitle: function () {
            return !isRecommendations
                ? dispatch(sortList(listId, sortBy === SortOrder.SORT_TITLE_DESC ? SortOrder.SORT_TITLE_ASC : SortOrder.SORT_TITLE_DESC))
                : dispatch(sortRecommendations(listId, sortBy === SortOrder.SORT_TITLE_DESC ? SortOrder.SORT_TITLE_ASC : SortOrder.SORT_TITLE_DESC));
        },
        sortByCustomOrder: function () {
            return !isRecommendations
                ? dispatch(sortList(listId, sortBy === SortOrder.SORT_CUSTOMORDER_DESC
                    ? SortOrder.SORT_CUSTOMORDER_ASC
                    : SortOrder.SORT_CUSTOMORDER_DESC))
                : dispatch(sortRecommendations(listId, sortBy === SortOrder.SORT_CUSTOMORDER_DESC
                    ? SortOrder.SORT_CUSTOMORDER_ASC
                    : SortOrder.SORT_CUSTOMORDER_DESC));
        },
        sortByArea: function () {
            return !isRecommendations
                ? dispatch(sortList(listId, sortBy === SortOrder.SORT_AREA_DESC ? SortOrder.SORT_AREA_ASC : SortOrder.SORT_AREA_DESC))
                : dispatch(sortRecommendations(listId, sortBy === SortOrder.SORT_AREA_DESC ? SortOrder.SORT_AREA_ASC : SortOrder.SORT_AREA_DESC));
        },
    });
})(ListItemHeader);
