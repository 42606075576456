import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import { getCartLineItemsForVariants } from '../../../Cart/reducer';
import { selectVariant } from 'Shared/Product/action-creators';
import * as styles from './base.scss';
var ProductVariantSelector = function (_a) {
    var variants = _a.variants, isWeightProduct = _a.isWeightProduct, preferedVariant = _a.preferedVariant, cartLineItems = _a.cartLineItems, selectedVariant = _a.selectedVariant, selectVariant = _a.selectVariant, disabled = _a.disabled, className = _a.className, useNewDesign = _a.useNewDesign;
    var selectableVariants = useMemo(function () {
        if (preferedVariant && cartLineItems.length > 1) {
            return [preferedVariant];
        }
        if (isWeightProduct) {
            return variants.filter(function (v) { return v.unitShortName === selectedVariant.unitShortName; });
        }
        return variants;
    }, [preferedVariant, selectedVariant, cartLineItems, isWeightProduct, variants]);
    var setSelectedVariant = useCallback(function (newUnitShortName) {
        var variant = variants.find(function (v) { return v.unitShortName === newUnitShortName; });
        return selectVariant(variant.code, variants);
    }, [variants, selectedVariant]);
    return (React.createElement(React.Fragment, null, selectableVariants.map(function (selectableVariant, index) {
        var _a;
        return (React.createElement("div", { key: index, onClick: function () { return setSelectedVariant(selectableVariant.unitShortName); }, className: classnames(styles.placeholderText, (_a = {},
                _a[styles.selected] = selectedVariant.unitShortName === selectableVariant.unitShortName,
                _a[className] = selectedVariant.unitShortName === selectableVariant.unitShortName,
                _a[styles.disabled] = disabled,
                _a[styles['description--title']] = useNewDesign,
                _a)) }, selectableVariant.unitShortName));
    })));
};
export default connect(function (state, _a) {
    var variants = _a.variants;
    return ({
        cartLineItems: getCartLineItemsForVariants(state, variants),
    });
}, function (dispatch, _a) {
    var variants = _a.variants;
    return ({
        selectVariant: function (code) { return dispatch(selectVariant(code, variants)); },
    });
})(ProductVariantSelector);
