var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import Main from 'Shared/PageLayout';
var DownloadFilesOverviewPage = /** @class */ (function (_super) {
    __extends(DownloadFilesOverviewPage, _super);
    function DownloadFilesOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    DownloadFilesOverviewPage.prototype.render = function () {
        var getUrl = this.props.getUrl;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement("h1", null, translate('/Admin/DownloadFiles/Title')),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("a", { href: getUrl + '/downloadfiles/userfile', target: "_blank" }, translate('/Admin/DownloadFiles/UserData'))),
                React.createElement("li", null,
                    React.createElement("a", { href: getUrl + '/downloadfiles/customarticles', target: "_blank" }, translate('/Admin/DownloadFiles/CustomArticles'))),
                React.createElement("li", null,
                    React.createElement("a", { href: getUrl + '/downloadfiles/equipment', target: "_blank" }, translate('/Admin/DownloadFiles/Equipment'))),
                React.createElement("li", null,
                    React.createElement("a", { href: getUrl + '/downloadfiles/suppliers', target: "_blank" }, translate('/Admin/DownloadFiles/Suppliers'))))));
    };
    return DownloadFilesOverviewPage;
}(React.Component));
export default DownloadFilesOverviewPage;
