import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import ProductMarks from 'Shared/ProductCampaignLabel';
import ProductPrice from 'Shared/ProductComponents/ProductPrice';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import ProductCampaignDate from 'Shared/ProductComponents/ProductCampaignDate';
import Icon from 'Shared/Icon';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import * as styles from './base.scss';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
var ProductSearchDesktop = function (_a) {
    var product = _a.product, selectedVariant = _a.selectedVariant, url = _a.url, discontinuedOrBackordered = _a.discontinuedOrBackordered, onClick = _a.onClick, onFocus = _a.onFocus, rowIndex = _a.rowIndex, addToListDisabled = _a.addToListDisabled, rowHasFocus = _a.rowHasFocus, addByIndex = _a.addByIndex;
    var rowElement = useRef(null);
    var disabledFadeClassName = discontinuedOrBackordered || product.isTender ? styles.disabledFade : '';
    var handleOnFocus = function () {
        if (onFocus) {
            return onFocus(rowIndex);
        }
    };
    var handleClick = function (e) {
        e.preventDefault();
        rowElement.current.focus();
        if (onClick) {
            onClick(rowIndex);
        }
        addByIndex(rowIndex);
    };
    useEffect(function () {
        if (rowHasFocus) {
            rowElement.current.focus();
        }
    }, [rowHasFocus, rowElement]);
    return (React.createElement("div", { ref: rowElement, tabIndex: 0, className: styles.card, itemType: "http://schema.org/Product", itemScope: true },
        React.createElement("div", { className: styles.cardInner },
            React.createElement("div", { className: classnames(styles.cardColumn, styles.imageWrap) },
                React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url, code: product.selectedVariant && product.selectedVariant.code },
                    React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.content.displayName, size: "tiny" }))),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.detailsWrap) },
                React.createElement(ProductTitle, { linkUrl: url, ticket: product.ticket, displayName: product.content.displayName }),
                React.createElement("div", { className: disabledFadeClassName },
                    React.createElement(ProductCampaignDate, { isUtf: product.isUtf, bestBeforeDate: product.bestBeforeDate, isInCampaign: product.isInCampaign, closestCampaignEndDate: product.closestCampaignEndDate, isProductIncludedInNewsCampaign: product.isProductIncludedInNewsCampaign, productCampaignDescriptions: product.campaignDescriptions })),
                React.createElement(ProductDetails, { code: product.content.code, linkUrl: url, ticket: product.ticket, trademark: product.trademark, className: disabledFadeClassName })),
            !discontinuedOrBackordered && !product.isTender && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: classnames(styles.cardColumn, styles.typeAndUnitWrap) },
                    React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: selectedVariant, productArea: product.area, discontinuedOrBackordered: discontinuedOrBackordered, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign }),
                    React.createElement("div", { className: styles.marksWrap },
                        React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice }))),
                React.createElement("div", { className: classnames(styles.cardColumn, styles.priceWrap) },
                    React.createElement(ProductPrice, { selectedVariant: selectedVariant, variants: product.variants, productIsInCampaign: product.isInCampaign, isWeightProduct: product.isWeightProduct, showOriginal: false })))),
            (discontinuedOrBackordered || product.isTender) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.disabledIconWrap },
                    React.createElement(Icon, { className: styles.disabledIcon, name: "exclamation-mark" })),
                React.createElement("div", { className: classnames(styles.cardColumn, styles.disabledWrap) },
                    React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender })))),
            !discontinuedOrBackordered && !product.isTender && (React.createElement("div", { className: classnames(styles.cardColumn, styles.availabilityWrap) },
                React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability }))),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.addToListBtnWrap) },
                React.createElement(Button, { onClick: function (e) { return handleClick(e); }, onFocus: function () { return handleOnFocus(); }, className: styles.btn, disabled: addToListDisabled }, addToListDisabled ? translate('/Product/AlreadyInList') : translate('/Product/AddToList'))))));
};
export default ProductSearchDesktop;
