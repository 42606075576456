var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ProductListing from 'Shared/ProductListing';
import Main, { Section } from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import * as styles from './base.scss';
import { SMALL } from 'Shared/breakpoints';
import CampaignPageHeader from '../../Components/CampaignPageHeader';
import propertyValueFor from 'Shared/property-value-for';
var CampaignPage = /** @class */ (function (_super) {
    __extends(CampaignPage, _super);
    function CampaignPage(props) {
        return _super.call(this, props) || this;
    }
    CampaignPage.prototype.render = function () {
        return (React.createElement(Main, { style: { marginTop: 0 }, appearance: "narrow" },
            !!propertyValueFor(this.props.page.hero) && React.createElement("div", { className: styles.heroImageWrapper },
                React.createElement(PropertyFor, { property: this.props.page.hero, isImage: true })),
            React.createElement(Section, { className: styles.bottomBorder },
                React.createElement(CampaignPageHeader, { headerProperty: this.props.page.name, preambleProperty: this.props.page.preamble, breadcrumbs: this.props.breadcrumbs, campaigns: this.props.products.campaigns })),
            React.createElement(ProductListing, __assign({}, this.props, { showLayoutOptions: this.props.currentBreakpoint > SMALL, disableHeaderRow: true, isCampaignPage: true }))));
    };
    return CampaignPage;
}(React.Component));
export default CampaignPage;
