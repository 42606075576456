var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Main, { Part } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import Backbutton from '../../../App/Backbutton';
import PantryContentItemRow from './PantryContentItemRow';
import * as styles from './base.scss';
var PantryContentPage = /** @class */ (function (_super) {
    __extends(PantryContentPage, _super);
    function PantryContentPage(props) {
        var _this = _super.call(this, props) || this;
        _this.isActiveItem = function (e) { return e.productId === _this.state.activeItemId; };
        _this.state = {
            activeItemId: '-1',
        };
        return _this;
    }
    PantryContentPage.prototype.render = function () {
        return (React.createElement(Main, null,
            React.createElement(Backbutton, { title: translate('/App/Pantry/Pantry') + " " + this.props.displayName }),
            React.createElement(Part, { appearance: "divider", className: styles.indent }),
            React.createElement(Part, { appearance: "divider" },
                React.createElement("div", { className: styles.header },
                    React.createElement("div", { className: styles.displayName }, translate('/App/Pantry/DisplayName')),
                    React.createElement("div", { className: styles.eans }, translate('/App/Pantry/EAN'))),
                React.createElement("div", null, this.props.content.map(function (e) { return (React.createElement(PantryContentItemRow, __assign({ key: e.productId }, e))); })))));
    };
    return PantryContentPage;
}(React.Component));
export default PantryContentPage;
