import { switchBreakpoint } from 'SiteLayout/CurrentBreakpoint/action-creators';
import { on } from 'SiteLayout/Root';
// If changing these constants, change the Breakpoint enum in Avensia.React/Device/Breakpoints.cs the same way
export var INIT = 0;
export var MICRO = 1;
export var TINY = 2;
export var SMALL = 3;
export var MEDIUM = 4;
export var LARGE = 5;
export var HUGE = 6;
// If the breakpoint is MICRO (which is set to 320) the width is at minium 320
// so you get the width for the next breakpoint (TINY) because the screen could be up til
// TINY
export function widthFromBreakpoint(breakpoint) {
    var keys = Object.keys(WEBPACK_LAYOUT_BREAKPOINTS);
    if (typeof breakpoint !== 'number' || breakpoint < 0 || breakpoint > keys.length - 1) {
        throw new Error('Invalid breakpoint: ' + breakpoint);
    }
    var name = keys[breakpoint + 1];
    return name ? WEBPACK_LAYOUT_BREAKPOINTS[name] : WEBPACK_LAYOUT_BREAKPOINTS[keys[keys.length - 1]];
}
export function breakpointFromWidth(width) {
    var breakpoints = WEBPACK_LAYOUT_BREAKPOINTS; // eslint-disable-line no-undef
    var keys = Object.keys(breakpoints);
    for (var i = 0; i < keys.length; i++) {
        var curr = breakpoints[keys[i]];
        var next = breakpoints[keys[i + 1]] || Infinity;
        if (curr <= width && next > width) {
            return i;
        }
    }
    if (process.env.NODE_ENV !== 'production') {
        console.error('Could not match a breakpoint. window.innerWidth:', window.innerWidth, 'keys:', keys, 'breakpoints:', breakpoints);
    }
    else {
        return keys.length - 1;
    }
}
export function current() {
    var currentWidth = window.innerWidth;
    return breakpointFromWidth(currentWidth);
}
export function setupResizeListener(store) {
    var currentBreakpoint = current();
    // We use `Root/on` here to enable the throttled variant of `window.addEventListener('resize', ...`
    on('resize', function () {
        var newBreakpoint = current();
        if (currentBreakpoint !== newBreakpoint) {
            currentBreakpoint = newBreakpoint;
            store.dispatch(switchBreakpoint(currentBreakpoint));
        }
    });
}
