import React from 'react';
import * as styles from './components.scss';
import QuickFilterButton from './SearchQuickFilter';
import classnames from 'classnames';
import { getQuickFilterBlocks, getValidQuickFilters } from './action-creators';
import { translate } from 'Shared/translate';
;
var SearchQuickFilters = function (props) {
    var buttons = getValidQuickFilters(props.products, getQuickFilterBlocks(props.quickFilterBlocks));
    return (React.createElement(React.Fragment, null, !!buttons && buttons.length > 0 && (React.createElement("div", { className: classnames(styles.searchSuggestion, styles.searchFilters) },
        React.createElement("div", null, translate("/Search/QuickFilters")),
        React.createElement("ul", null, !!buttons && buttons.map(function (button, i) { return (React.createElement(QuickFilterButton, { key: button.buttonText + i, item: button })); }))))));
};
export default SearchQuickFilters;
