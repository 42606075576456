var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import PropertyFor from 'Shared/PropertyFor';
import BoxLayout from 'Shared/BoxLayout';
import editorAttributesFor from 'Shared/editor-attributes-for';
import { contentAreaItems } from 'Shared/Partials/PropertyTypes/ContentArea';
import * as styles from './base.scss';
var RowLayoutContentArea = function (props) {
    var _a;
    return (React.createElement(BoxLayout, __assign({}, editorAttributesFor(props.propertyName, props.className), { appearance: { gutter: props.spacingBetweenBlocks }, className: classnames((_a = {}, _a[styles.fillPageWidth] = props.fillPageWidth, _a)), layout: props.layout }), contentAreaItems(props.propertyName, props.items)));
};
RowLayoutContentArea.supportsPropertyForIntegration = true;
export default (function (props) { return (React.createElement(PropertyFor, { component: RowLayoutContentArea, className: classnames(styles['layout_' + props.layout.map(function (r) { return (typeof r === 'string' ? r : r[0]); }).join('_')]), componentProps: {
        spacingBetweenBlocks: props.spacingBetweenBlocks,
        fillPageWidth: props.fillPageWidth,
        layout: props.layout,
    }, property: props.block.items })); });
