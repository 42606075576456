var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Chart from 'Shared/PieChart/Chart';
import * as styles from './base.scss';
var EnergyChart = /** @class */ (function (_super) {
    __extends(EnergyChart, _super);
    function EnergyChart(props) {
        return _super.call(this, props) || this;
    }
    EnergyChart.prototype.render = function () {
        var data = [];
        var protein = this.props.data.find(function (n) { return n.code === 'PRO'; });
        var fat = this.props.data.find(function (n) { return n.code === 'FAT'; });
        var carbs = this.props.data.find(function (n) { return n.code === 'CHOAVL'; });
        if (!!protein) {
            data.push({ value: protein.value, color: '#44a269', label: protein.displayName });
        }
        if (!!fat) {
            data.push({ value: fat.value, color: '#f85846', label: fat.displayName });
        }
        if (!!carbs) {
            data.push({ value: carbs.value, color: '#4198ff', label: carbs.displayName });
        }
        return (!!this.props.data &&
            this.props.data.length > 0 && (React.createElement("div", null,
            React.createElement(Chart, { className: styles.energyChart, data: data, radius: 145, strokeWidth: 2, stroke: '#ffffff' }))));
    };
    return EnergyChart;
}(React.Component));
export default EnergyChart;
