import React from 'react';
import ProductSearchMobile from './Mobile';
import ProductSearchDesktop from './Desktop';
import { MEDIUM } from 'Shared/breakpoints';
var ProductSearchItem = function (_a) {
    var product = _a.product, selectedVariant = _a.selectedVariant, url = _a.url, discontinuedOrBackordered = _a.discontinuedOrBackordered, onClick = _a.onClick, onFocus = _a.onFocus, rowIndex = _a.rowIndex, currentBreakpoint = _a.currentBreakpoint, addToListDisabled = _a.addToListDisabled, recipeSearch = _a.recipeSearch, rowHasFocus = _a.rowHasFocus, addByIndex = _a.addByIndex;
    var isMobile = currentBreakpoint < MEDIUM;
    var ProductSearchComponent = isMobile || recipeSearch ? ProductSearchMobile : ProductSearchDesktop;
    return (React.createElement(ProductSearchComponent, { product: product, selectedVariant: selectedVariant, url: url, discontinuedOrBackordered: discontinuedOrBackordered, onClick: onClick, onFocus: onFocus, rowIndex: rowIndex, addToListDisabled: addToListDisabled, rowHasFocus: rowHasFocus, addByIndex: addByIndex }));
};
export default ProductSearchItem;
