var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import { updateSelectedCompletion, updateQuickSearchCart } from '../../../../../Search/action-creators';
import { addToCart } from '../../../../../Cart/action-creators';
import * as styles from './autocomplete.scss';
import PopularSearches from './PopularSearches';
import SearchSuggestions from './SearchSuggestions';
import ProductSearchList from './ProductSearchList';
import NewsBanner from './NewsBanner';
import propertyValueFor from 'Shared/property-value-for';
import MarketingAd from './MarketingAd';
import { scrollPosition } from 'SiteLayout/Root';
import { MEDIUM } from 'Shared/breakpoints';
import SearchCategories from './SearchCategories';
var Autocompletions = function (props) {
    var _a;
    var newsBannerBlocks = propertyValueFor(props.newsBlocks);
    var newsBannerContent = !!newsBannerBlocks && !!newsBannerBlocks.items && !!newsBannerBlocks.items[0] && newsBannerBlocks.items[0];
    var marketingAdBlocks = propertyValueFor(props.marketingBlocks);
    var marketingAdContent = !!marketingAdBlocks && !!marketingAdBlocks.items && !!marketingAdBlocks.items[0] && marketingAdBlocks.items[0];
    var listenScrollEvent = function () {
        if (props.currentBreakpoint > MEDIUM && scrollPosition() <= 100)
            window.scrollBy(0, 100);
    };
    var showResult = (props.currentBreakpoint > MEDIUM) ? (!!props.open && !!props.visible) : (!!props.open || !!props.visible);
    var searchCategories = !!props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0] &&
        !!props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0].categorySearchHits
        && props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0].categorySearchHits;
    return (!!showResult) && (React.createElement("div", { onScroll: listenScrollEvent, className: classnames(styles.base, props.className, 'ui-test-autocomplete') },
        React.createElement(SearchSuggestions, { thinSelection: props.thinSelection, lists: props.lists, onItemClick: props.onItemClick }),
        React.createElement("div", { className: classnames(styles.twoColumnWrapper, (_a = {}, _a[styles.border] = !props.thinSelection, _a)) },
            !props.thinSelection && React.createElement("div", { className: styles.leftColumn },
                React.createElement(SearchCategories, { categorySearchHits: searchCategories, onItemClick: props.onItemClick })),
            React.createElement("div", { className: styles.rightColumn },
                React.createElement(ProductSearchList, { lists: props.lists, onItemClick: props.onItemClick, currentBreakpoint: props.currentBreakpoint, searchTerm: props.searchText, totalItems: props.quickSearch.totalItems, helpText: props.helpText, helpTextChat: props.helpTextChat }))),
        React.createElement("div", { className: styles.sectionLists },
            React.createElement("div", { className: styles.section, key: "Popular" },
                !(props.thinSelection && props.searchText.length > 1) && (React.createElement("div", { className: styles.searchSuggestionBackgroundBox },
                    React.createElement(PopularSearches, { onItemClick: props.onItemClick }),
                    !!newsBannerContent && (React.createElement(NewsBanner, __assign({}, newsBannerContent))))),
                !!marketingAdContent && (React.createElement(MarketingAd, { currentBreakpoint: props.currentBreakpoint, item: marketingAdContent }))))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    newsBlocks: state.quickSearch.searchPage.quickSearchNewsBannerArea,
    marketingBlocks: state.quickSearch.searchPage.quickSearchMarketingAdArea,
    open: state.quickSearch.isOpen,
    helpText: state.quickSearch.searchPage.helpText,
    helpTextChat: state.quickSearch.searchPage.helpTextChat,
    quickSearch: state.quickSearch
}); }, function (dispatch) { return ({
    onFocusQuantity: function (index) {
        dispatch(updateSelectedCompletion(index, 'product'));
    },
    updateCartItemQuantity: function (code, quantity, date, unitShortName, ticket) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, 'QuickSearch'));
    },
    updateUnit: function (items, ticket) {
        var code = items && Object.keys(items)[0];
        return dispatch(updateQuickSearchCart(code, items[code].newQuantity, items[code].newUnitShortName, ticket));
    },
}); })(Autocompletions);
