var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import { getStepSize } from 'Shared/Quantity/utils';
import { replaceStringWithElement } from 'Shared/replace-strings-with-element';
import * as styles from './base.scss';
import MyMenigoIcon from './../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import VariantAndQuantity from 'Shared/ProductCard/VariantAndQuantity';
var debounceMs = 400;
var SubDeliveryRow = /** @class */ (function (_super) {
    __extends(SubDeliveryRow, _super);
    function SubDeliveryRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.updateDebouncer = function (quantity) {
            clearTimeout(_this.currentTimeout);
            return new Promise(function () {
                _this.currentTimeout = setTimeout(function () {
                    _this.props
                        .update(quantity)
                        .then(function (resolve) { return resolve; })
                        .catch(function (reject) { return reject; });
                }, debounceMs);
            });
        };
        _this.getSelectedVariant = function () { return _this.props.selectedVariant || _this.props.variants[0]; };
        _this.showSubDelivery = function () { return !_this.props.punchOutSessionActive && _this.props.date.substring(0, 10) !== '0001-01-01'; };
        _this.getFormattedDeliveryDate = function () { return moment(_this.props.date).format('YYYY-MM-DD'); };
        return _this;
    }
    SubDeliveryRow.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, quantity = _c.quantity, unitShortName = _c.unitShortName, remove = _c.remove, isLineItemPending = _c.isLineItemPending, date = _c.date, originalPrice = _c.originalPrice, product = _c.product, isCheckout = _c.isCheckout;
        var selectedVariant = this.getSelectedVariant();
        var step = getStepSize(selectedVariant);
        var round = function (num, precision) { return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision); };
        var price = !!selectedVariant.price && !!selectedVariant.price.current && !!quantity ? (selectedVariant.price.current * quantity) + (!!originalPrice && !!originalPrice.current ? originalPrice.current : 0) : -1;
        var priceRounded = round(price, 2);
        return this.showSubDelivery() ? (React.createElement("div", { className: classnames(styles.subDeliveryWrapper, (_a = {}, _a[styles.isMobile] = this.props.isMobile, _a)) },
            React.createElement("div", { className: styles.top },
                React.createElement("div", { className: styles.info },
                    React.createElement("div", { className: classnames(styles.date, styles['description--medium']) },
                        React.createElement("div", null, translate('/Checkout/NewDeliveryDate')),
                        React.createElement("div", null, this.getFormattedDeliveryDate())),
                    React.createElement("div", { className: styles.label }, translate('/Checkout/SubDelivery'))),
                React.createElement("div", { className: styles.actions },
                    React.createElement("div", { className: styles.quantity },
                        React.createElement(VariantAndQuantity, { product: product, index: this.props.index, updateCartQuantity: this.updateDebouncer, quantityStep: step, quantityInCart: quantity, minQuantity: 0, isCartQuantity: true, variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, isRestrictedCustomer: false, discontinuedOrBackordered: false, disableBuyWidgets: !product.isBuyable || product.isSoldOut, url: '', useNewDesign: true, isCheckout: isCheckout })),
                    React.createElement("div", { className: styles.remove, onClick: function () { return remove(); } },
                        React.createElement(MyMenigoIcon, { name: "trash" })))),
            React.createElement("div", { className: classnames(styles.bottom, styles.title) }, price >= 0 ? priceRounded.toLocaleString('sv-SE') + " " + originalPrice.currencySymbol : ''))) : (React.createElement("div", { className: classnames(styles.subDeliveryWrapper, (_b = {},
                _b[styles.loading] = isLineItemPending,
                _b)) },
            React.createElement("div", { className: styles.infoText }, date.substring(0, 10) === '0001-01-01'
                ? translate('/Checkout/NotValidDeliveryDate')
                : replaceStringWithElement(translate('/Checkout/PunchoutSubDeliveryText', '{date}'), '{date}', function () { return (React.createElement("span", { className: styles.punchoutDate }, _this.getFormattedDeliveryDate())); }))));
    };
    return SubDeliveryRow;
}(React.PureComponent));
export default connect(function (state) { return ({
    punchOutSessionActive: state.currentUser.punchOutSessionActive,
}); })(SubDeliveryRow);
