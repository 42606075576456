var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { getInvoiceSequence, pdfSendUrl } from '../action-creators';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import { TINY } from 'Shared/breakpoints';
import Desktop from './desktop';
import Mobile from './mobile';
import * as styles from './base.scss';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
var InvoiceList = /** @class */ (function (_super) {
    __extends(InvoiceList, _super);
    function InvoiceList(props) {
        var _this = _super.call(this, props) || this;
        _this.allInvoice = 'All Invoice';
        _this.debitOnly = 'Debit Invoice Only';
        _this.creditOnly = 'Credit Invoice Only';
        _this.state = {
            isLoading: true,
            activePage: 1,
            listPerPage: _this.props.pageSize,
            itemsList: _this.props.list != null ? _this.props.list.invoiceList : [],
            visibleItems: [],
            hasSelectedInvoice: false,
            pageCount: [],
            pageCountIndex: 0,
            pageCountMaxIndex: 10,
            currentSelected: 'all',
            totalInvoiceCount: _this.props.totalInvoiceCount,
            yearOption: (new Date().getFullYear().toString()),
            yearOptionTitle: translate('/Invoice/YearOptionsLabel'),
            dropdown: _this.props.dropdown,
        };
        _this.onSelectPage = _this.onSelectPage.bind(_this);
        _this.onSelectNavPageAction = _this.onSelectNavPageAction.bind(_this);
        return _this;
    }
    InvoiceList.prototype.componentDidMount = function () {
        this.onLoadOrderView();
    };
    InvoiceList.prototype.onLoadOrderView = function () {
        var _this = this;
        this.setState({
            activePage: 1,
            visibleItems: [],
            isLoading: true,
            hasSelectedInvoice: false,
            pageCount: [],
        }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadVisiblePageItems(this.state.activePage, false, this.state.yearOption)];
                    case 1:
                        _a.sent();
                        this.setState({
                            isLoading: false,
                            hasSelectedInvoice: false,
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    InvoiceList.prototype.loadVisiblePageItems = function (pageNumber, isFirstLoad, year) {
        if (isFirstLoad === void 0) { isFirstLoad = true; }
        return __awaiter(this, void 0, void 0, function () {
            var indexOfLastRecord, indexOfFirstRecord, currentRecords, pages, isDisableNextNav, lessThenPageSize, i;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!isFirstLoad) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.getInvoiceSequence((pageNumber - 1) * this.state.listPerPage, this.state.currentSelected, year).then(function (result) {
                                _this.setState({
                                    itemsList: result.invoiceList != null ? result.invoiceList.invoiceList : [],
                                    totalInvoiceCount: result.totalInvoice,
                                    dropdown: result.listYear != null ? result.listYear : _this.props.dropdown,
                                });
                            }).catch(function (any) { return _this.setState({
                                isLoading: false,
                            }); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        indexOfLastRecord = this.state.listPerPage;
                        indexOfFirstRecord = 0;
                        currentRecords = this.state.itemsList.slice(indexOfFirstRecord, indexOfLastRecord);
                        pages = Array();
                        isDisableNextNav = true;
                        lessThenPageSize = (this.state.activePage == 1 && currentRecords.length < this.state.listPerPage);
                        if (typeof currentRecords !== typeof undefined && currentRecords.length > 0 && !lessThenPageSize) {
                            for (i = 1; i <= Math.ceil(this.state.totalInvoiceCount / this.state.listPerPage); i++) {
                                pages.push(i);
                            }
                            isDisableNextNav = pageNumber >= Math.max.apply(Math, __spread(pages)) ? true : false;
                        }
                        this.setState({
                            isDisableNextNav: isDisableNextNav,
                            pageCount: pages,
                            isLoading: false,
                            visibleItems: currentRecords,
                            isDisablePrevNav: pageNumber == 1 ? true : false,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    InvoiceList.prototype.onSelectPage = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var isFirstLoad;
            var _this = this;
            return __generator(this, function (_a) {
                isFirstLoad = false;
                this.setState({
                    activePage: value,
                    isLoading: true,
                    hasSelectedInvoice: false,
                }, function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.loadVisiblePageItems(value, isFirstLoad, this.state.yearOption)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                }); }); });
                return [2 /*return*/];
            });
        });
    };
    InvoiceList.prototype.onSelectNavPageAction = function (currentPage, action) {
        return __awaiter(this, void 0, void 0, function () {
            var isFirstLoad, counter, pageMinIndex, pageMaxIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isFirstLoad = false;
                        counter = action === 'next' ? ++currentPage : --currentPage;
                        pageMinIndex = action === 'next' ?
                            currentPage > this.state.pageCountMaxIndex ? this.state.pageCountIndex + 10 : this.state.pageCountIndex
                            :
                                currentPage == this.state.pageCountIndex ? this.state.pageCountIndex - 10 : this.state.pageCountIndex;
                        pageMaxIndex = action === 'next' ?
                            currentPage > this.state.pageCountMaxIndex ? this.state.pageCountMaxIndex + 10 : this.state.pageCountMaxIndex
                            :
                                currentPage == this.state.pageCountIndex ? this.state.pageCountMaxIndex - 10 : this.state.pageCountMaxIndex;
                        this.setState({
                            activePage: counter,
                            isLoading: false,
                            hasSelectedInvoice: false,
                            pageCountIndex: pageMinIndex,
                            pageCountMaxIndex: pageMaxIndex,
                        });
                        return [4 /*yield*/, this.loadVisiblePageItems(counter, isFirstLoad, this.state.yearOption)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Will be remove if menigo want this permanently removed
    // onSelectInvoiceFilter(filter: InvoiceFilter){
    //   this.setState({
    //     currentSelected: filter,
    //     activePage: 1,
    //     pageCountMaxIndex: 10,
    //     pageCountIndex: 0,
    //   }, async () => await this.loadVisiblePageItems(1, false));
    // }
    InvoiceList.prototype.getInvoiceStatus = function (status) {
        if (status === 'paid')
            return translate('/Invoice/Paid');
        else if (status === 'notPaid')
            return translate('/Invoice/UnPaid');
        else
            return '-';
    };
    InvoiceList.prototype.changeSelect = function (option) {
        var _this = this;
        this.setState({
            yearOption: option,
            yearOptionTitle: option,
            activePage: 1,
            pageCountMaxIndex: 10,
            pageCountIndex: 0,
        }, function () { return _this.loadVisiblePageItems(_this.state.activePage, false, _this.state.yearOption); });
    };
    InvoiceList.prototype.render = function () {
        var _this = this;
        var isExtensive = this.props.currentBreakpoint > TINY;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("div", { className: styles.yearOptions },
                    React.createElement("div", { className: styles.yearOptionsInner },
                        React.createElement("div", { className: styles.selectWrapper },
                            React.createElement("div", { className: styles.dropdownWrap },
                                React.createElement(DropDownMenu, { title: this.state.yearOptionTitle }, !!this.state.dropdown && this.state.dropdown.map(function (year) {
                                    return (React.createElement(DropDownItem, { key: year, value: year.toString(), text: year.toString(), onClick: function (e) { return _this.changeSelect(year.toString()); } }));
                                })))))),
                isExtensive ?
                    React.createElement(Desktop, { activePage: this.state.activePage, visibleItems: this.state.visibleItems, isDisablePrevNav: this.state.isDisablePrevNav, isDisableNextNav: this.state.isDisableNextNav, pageCount: this.state.pageCount, pageCountIndex: this.state.pageCountIndex, pageCountMaxIndex: this.state.pageCountMaxIndex, getInvoiceStatus: this.getInvoiceStatus, pdfSendUrl: this.props.pdfSendUrl, onSelectNavPageAction: this.onSelectNavPageAction, onSelectPage: this.onSelectPage, isLoading: this.state.isLoading, noResultElement: this.props.noResultElement }) :
                    React.createElement(Mobile, { activePage: this.state.activePage, visibleItems: this.state.visibleItems, isDisablePrevNav: this.state.isDisablePrevNav, isDisableNextNav: this.state.isDisableNextNav, pageCount: this.state.pageCount, getInvoiceStatus: this.getInvoiceStatus, pdfSendUrl: this.props.pdfSendUrl, onSelectNavPageAction: this.onSelectNavPageAction, isLoading: this.state.isLoading, noResultElement: this.props.noResultElement }))));
    };
    return InvoiceList;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); }, function (dispatch) { return ({
    pdfSendUrl: function (url, invoiceNumber) {
        dispatch(pdfSendUrl(url, invoiceNumber));
    },
    getInvoiceSequence: function (offset, filter, year) {
        return dispatch(getInvoiceSequence(offset, filter, year));
    },
}); })(InvoiceList);
