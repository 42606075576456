var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './autocomplete.scss';
import { searchPageUrl } from 'Shared/known-urls';
import { urlToString } from 'Shared/url';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
var DidYouMean = /** @class */ (function (_super) {
    __extends(DidYouMean, _super);
    function DidYouMean() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DidYouMean.prototype.render = function () {
        var _this = this;
        var items = !!this.props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0] &&
            !!this.props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0].searchDidYouMean
            && this.props.quickSearch.completionsLists.filter(function (x) { return x.id === 'suggestions'; })[0].searchDidYouMean;
        return !!items && items.length > 0 && (React.createElement("ul", { key: 'DidYouMean' },
            React.createElement("li", null, translate('/Shared/FadeNoHits') + " " + translate('/Shared/FadeDidYouMean')),
            items.map(function (item, j) {
                return (React.createElement("li", { key: item.text + j },
                    React.createElement("a", { href: urlToString({ path: searchPageUrl(), query: { q: item.text } }), "data-ticket": item.ticket, className: styles.button, onClick: function () { return !!_this.props.onItemClick && _this.props.onItemClick(item.text); } }, item.text)));
            })));
    };
    return DidYouMean;
}(React.PureComponent));
export default connect(function (state) { return ({
    quickSearch: state.quickSearch,
}); })(DidYouMean);
