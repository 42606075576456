var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import Checkbox from 'Shared/Fields/Checkbox';
import Spinner from 'Shared/Spinner';
import RenameList from 'Shared/ListItem/RenameList';
import RemoveList from 'Shared/ListItem/RemoveList';
import Button from 'Shared/Button';
import { scrollPosition } from 'SiteLayout/Root';
import classnames from 'classnames';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
import InventoryStatus from './InventoryStatus';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Header.prototype.componentDidMount = function () {
        var _this = this;
        if (this.props.isOpen) {
            this.props.getDetails();
            setTimeout(function () { return scrollPosition(_this.element.getBoundingClientRect().top - 100); }, 100);
        }
    };
    Header.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.props, id = _c.id, selected = _c.selected, isPantry = _c.isPantry, displayName = _c.displayName, count = _c.count, toggleListSelection = _c.toggleListSelection, isLoading = _c.isLoading, isLoaded = _c.isLoaded, getDetails = _c.getDetails, inventoryStatus = _c.inventoryStatus, inventoryDate = _c.inventoryDate, isLocked = _c.isLocked, isShared = _c.isShared, isOpen = _c.isOpen, onHeaderClick = _c.onHeaderClick, currentBreakpoint = _c.currentBreakpoint, isSubCustomerList = _c.isSubCustomerList, isDeletedByMaster = _c.isDeletedByMaster;
        var isMobile = currentBreakpoint < SMALL;
        var buttonAppearance = 'clear';
        var actionsRow = (React.createElement("div", { className: classnames(styles.list__cell__actions, styles.iconsWrap) },
            isDeletedByMaster && (React.createElement("div", { title: translate('/Account/ProductList/DeletedByMaster') },
                React.createElement(Button, { appearance: buttonAppearance },
                    React.createElement(MyMenigoIcon, { name: "alert" })))),
            (isShared || isSubCustomerList) && (isLocked ? (React.createElement("div", { title: isPantry
                    ? translate('/Account/ProductList/LockedPantry')
                    : translate('/Account/ProductList/LockedFavorite') },
                React.createElement(Button, { appearance: buttonAppearance }, React.createElement(MyMenigoIcon, { name: "sharedLock" })))) : (React.createElement("div", { title: isPantry
                    ? translate('/Account/ProductList/SharedPantry')
                    : translate('/Account/ProductList/SharedFavorite') },
                React.createElement(Button, { appearance: buttonAppearance }, React.createElement(MyMenigoIcon, { name: "shared" }))))),
            !isSubCustomerList && (React.createElement(React.Fragment, null,
                React.createElement(RenameList, { buttonAppearance: buttonAppearance, passedClassName: styles.renameListButtonWrap, id: id, displayName: displayName }),
                React.createElement(RemoveList, { buttonAppearance: buttonAppearance, passedClassName: styles.removeListButtonWrap, id: id, displayName: displayName })))));
        var productCount = function (inventoryStatus) {
            if (inventoryStatus == 'ongoing' && !!_this.props.products)
                return (React.createElement(React.Fragment, null,
                    _this.props.products.filter(function (x) { return x.quantity != null; }).length,
                    " ",
                    translate('/Account/ProductList/ProductsInventoryOf'),
                    " ",
                    count));
            if (inventoryStatus == 'ongoing' && _this.props.inventoryItemsCompleted != null)
                return (React.createElement(React.Fragment, null,
                    _this.props.inventoryItemsCompleted,
                    " ",
                    translate('/Account/ProductList/ProductsInventoryOf'),
                    " ",
                    count));
            return (React.createElement(React.Fragment, null,
                count,
                " ",
                translate('/Account/ProductList/ArticleCount')));
        };
        var inventoryTotalPrice = function (inventoryStatus) {
            if (inventoryStatus == 'never' || (_this.props.currentInventoryTotalPrice == null && _this.props.previousInventoryTotalPrice == null))
                return "";
            if ((inventoryStatus == 'ongoing' || inventoryStatus == 'reOpened') && !!_this.props.products)
                return formatInventoryTotalPrice(_this.props.products.reduce(function (sum, p) { return sum + p.selectedVariant.price.current * p.quantity; }, 0), _this.props.previousInventoryTotalPrice);
            if (_this.props.currentInventoryTotalPrice != null && _this.props.previousInventoryTotalPrice != null)
                return formatInventoryTotalPrice(_this.props.currentInventoryTotalPrice, _this.props.previousInventoryTotalPrice);
            return "";
        };
        var formatInventoryTotalPrice = function (current, prev) {
            if (prev != null)
                return React.createElement("div", null,
                    React.createElement("strong", null,
                        current != null && current.toFixed(2),
                        " ",
                        translate('/Price/CurrencySymbol')),
                    React.createElement("small", null,
                        translate('/Account/ProductList/LastInventory'),
                        " ",
                        _this.props.previousInventoryTotalPrice.toFixed(2),
                        " ",
                        translate('/Price/CurrencySymbol')));
            return React.createElement("div", null,
                React.createElement("strong", null,
                    current != null && current.toFixed(2),
                    " ",
                    translate('/Price/CurrencySymbol')));
        };
        return isMobile ? (React.createElement("div", { className: classnames(styles.header, styles.list__row, isPantry ? 'ui-test-pantry-header' : 'ui-test-favorite-header', (_a = {},
                _a[styles.isOpen] = isOpen,
                _a)), onClick: function () {
                onHeaderClick();
                if (!isLoaded && !isLoading) {
                    getDetails();
                }
            }, ref: function (el) { return (_this.element = el); } },
            React.createElement("div", { className: classnames(styles.list__cell__check), onClick: function (e) { return e.stopPropagation(); } }, isLoading ? (React.createElement(Spinner, { className: styles.spinner })) : (isPantry && React.createElement(Checkbox, { checked: selected, onChange: function () { return toggleListSelection(selected); } }))),
            React.createElement("div", { className: styles.list__row_column },
                React.createElement("div", { className: classnames(styles.list__row, styles.firstRow) },
                    React.createElement("div", { className: styles.list__cell__title }, displayName),
                    actionsRow),
                React.createElement("div", { className: classnames(styles.list__row, styles.secondRow) }, isPantry && React.createElement(InventoryStatus, { isMobile: false, status: inventoryStatus, date: inventoryDate })),
                React.createElement("div", { className: classnames(styles.list__row, styles.thirdRow) },
                    React.createElement("div", null, productCount(inventoryStatus)),
                    React.createElement("div", { className: styles.list__cell__totalprice },
                        React.createElement("div", null, inventoryTotalPrice(inventoryStatus))))))) : (React.createElement("div", { className: classnames(styles.header, styles.list__row, isPantry ? 'ui-test-pantry-header' : 'ui-test-favorite-header', (_b = {},
                _b[styles.isOpen] = isOpen,
                _b)), onClick: function () {
                onHeaderClick();
                if (!isLoaded && !isLoading) {
                    getDetails();
                }
            }, ref: function (el) { return (_this.element = el); } },
            React.createElement("div", { className: classnames(styles.list__cell__check), onClick: function (e) { return e.stopPropagation(); } }, isLoading ? (React.createElement(Spinner, { className: styles.spinner })) : (isPantry && React.createElement(Checkbox, { checked: selected, onChange: function () { return toggleListSelection(selected); } }))),
            React.createElement("div", { className: styles.list__cell__title }, displayName),
            isPantry && React.createElement(InventoryStatus, { isMobile: false, status: inventoryStatus, date: inventoryDate }),
            React.createElement("div", null, productCount(inventoryStatus)),
            React.createElement("div", { className: styles.list__cell__totalprice }, inventoryTotalPrice(inventoryStatus)),
            actionsRow));
    };
    return Header;
}(React.PureComponent));
export default Header;
