var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import * as styles from './toggle.scss';
export default (function (type) { return function (props) {
    var _a;
    var inputProps = Object.assign({}, props, {
        className: styles.input,
    });
    delete inputProps.children;
    delete inputProps.semiChecked;
    delete inputProps.appearance;
    return (React.createElement("label", { className: classnames(styles.base, props.className, (_a = {},
            _a[styles.disabled] = props.disabled,
            _a[styles.semiChecked] = props.semiChecked,
            _a[styles.light] = props.appearance === 'light',
            _a[styles.toggle] = props.appearance === 'toggle',
            _a)) },
        React.createElement("input", __assign({ type: type }, inputProps)),
        React.createElement("span", { className: styles.label }, props.appearance !== 'toggle' && (React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: "check", className: styles.checkmark }),
            React.createElement("span", { className: styles.toggleTextContent }, props.children))))));
}; });
