var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { translate } from 'Shared/translate';
import * as styles from './components.scss';
import PropertyFor from 'Shared/PropertyFor';
import MyMenigoIcon from './../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var SearchPageInfoBlock = function (props) {
    var _a = __read(useState(true), 2), chattIsVisible = _a[0], setChatIsVisible = _a[1];
    var openChatBox = function () {
        if (!!window._imbox) {
            window._imbox.push(['openChat']);
            return false;
        }
    };
    var handleAgentStatus = function (status) {
        if (!!status && !!status.isOnline) {
            setChatIsVisible(status.isOnline);
        }
        else {
            setChatIsVisible(false);
        }
    };
    var getImboxStatus = function () {
        if (!!window._imbox) {
            window._imbox.push(['registerChatStatusListener', handleAgentStatus]);
        }
        else {
            setChatIsVisible(false);
        }
    };
    useEffect(function () {
        getImboxStatus();
    }, []);
    return (React.createElement("div", { className: styles.searchSuggestionInfo },
        React.createElement("div", { className: styles.helpText }, !!props.helpText && React.createElement(PropertyFor, { property: props.helpText })),
        React.createElement("div", null,
            React.createElement("div", { className: styles.helpTextChatIcon },
                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "60", height: "60", viewBox: "0 0 60 60", fill: "none" },
                    React.createElement("path", { d: "M51.9141 20.7422H23.7891L13.2422 31.2891V20.7422H6.21094C5.27854 20.7422 4.38432 20.3718 3.72502 19.7125C3.06571 19.0532 2.69531 18.159 2.69531 17.2266V6.67969C2.69531 5.74729 3.06571 4.85307 3.72502 4.19377C4.38432 3.53446 5.27854 3.16406 6.21094 3.16406H51.9141C52.8465 3.16406 53.7407 3.53446 54.4 4.19377C55.0593 4.85307 55.4297 5.74729 55.4297 6.67969V17.2266C55.4297 18.159 55.0593 19.0532 54.4 19.7125C53.7407 20.3718 52.8465 20.7422 51.9141 20.7422Z", stroke: "#0D0F11", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
                    React.createElement("path", { d: "M6.21094 27.7734C5.27854 27.7734 4.38432 28.1438 3.72502 28.8031C3.06571 29.4624 2.69531 30.3567 2.69531 31.2891V41.8359C2.69531 42.7683 3.06571 43.6626 3.72502 44.3219C4.38432 44.9812 5.27854 45.3516 6.21094 45.3516H34.3359L44.8828 55.8984V45.3516H51.9141C52.8465 45.3516 53.7407 44.9812 54.4 44.3219C55.0593 43.6626 55.4297 42.7683 55.4297 41.8359V31.2891C55.4297 30.3567 55.0593 29.4624 54.4 28.8031C53.7407 28.1438 52.8465 27.7734 51.9141 27.7734H27.3047", stroke: "#0D0F11", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }))),
            React.createElement("div", { className: styles.helpTextChat },
                !!props.helpTextChat && React.createElement(PropertyFor, { property: props.helpTextChat }),
                chattIsVisible && (React.createElement("a", { onClick: openChatBox },
                    translate("/Shared/OpenChat"),
                    " ",
                    React.createElement(MyMenigoIcon, { name: 'arrow' })))))));
};
export default SearchPageInfoBlock;
