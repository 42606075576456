import React from 'react';
import * as styles from './base.scss';
var UserInfoBlock = function (_a) {
    var Header = _a.Header, Content = _a.Content, Link = _a.Link;
    return (React.createElement("div", { className: styles.userInfo },
        React.createElement("span", { className: styles.userInfo__header }, Header),
        React.createElement("span", { className: styles.userInfo__content }, Content),
        React.createElement("a", { href: Link.url, className: styles.userInfo__link }, Link.name)));
};
export default UserInfoBlock;
