var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import { pushState } from 'Shared/history';
import React from 'react';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Logotype from 'Shared/Logotype';
import * as server from 'Shared/server';
import { translate } from 'Shared/translate';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
import { updateAppShellData } from '../../AppShell/action-creators';
import { logout } from '../../Account/action-creators';
import * as styles from './base.scss';
import Button from 'Shared/Button';
var PunchOutTakeoverPage = /** @class */ (function (_super) {
    __extends(PunchOutTakeoverPage, _super);
    function PunchOutTakeoverPage(props) {
        return _super.call(this, props) || this;
    }
    PunchOutTakeoverPage.prototype.doLogin = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                e.preventDefault();
                return [2 /*return*/, server
                        .postJson(pathCombine(location.pathname, '/punchouttakeover'), { takeover: true })
                        .then(function (result) {
                        if (result.success) {
                            _this.props.setFeedback({
                                text: result.message,
                                type: 'success',
                            });
                            _this.props.reloadAppShell();
                            pushState('/');
                        }
                        else {
                            _this.props.setFeedback({
                                text: result.message,
                                type: 'error',
                            });
                        }
                    })
                        .catch(function () { return Promise.reject(null); })];
            });
        });
    };
    PunchOutTakeoverPage.prototype.doLogout = function () {
        this.props.logout();
    };
    PunchOutTakeoverPage.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.reset },
            React.createElement("div", { className: styles.punchOutWrapper },
                React.createElement("div", { className: styles.logotypeWrapper },
                    React.createElement(Logotype, { className: styles.logotype, siteUrl: "/", disableLink: true }))),
            React.createElement(Main, { appearance: "narrow", className: styles.base },
                React.createElement(PropertyFor, { property: this.props.page.header, tagName: "h1" }),
                React.createElement(PropertyFor, { property: this.props.page.mainBody, tagName: "p", className: styles.punchOutTakeoverMessage }),
                React.createElement("div", { className: styles.punchOutCartWrap },
                    React.createElement("div", { className: styles.punchOutCartHeader },
                        React.createElement("div", { className: styles.productWrap }, translate('/ProductListing/Product')),
                        React.createElement("div", { className: styles.quantityWrap }, translate('/ProductListing/Quantity')),
                        React.createElement("div", { className: styles.priceWrap }, translate('/ProductListing/Price'))),
                    this.props.items.map(function (item, index) {
                        return (React.createElement("div", { key: index, className: styles.punchOutCartItem },
                            React.createElement("div", { className: styles.productWrap }, item.displayName),
                            React.createElement("div", { className: styles.quantityWrap },
                                item.quantity,
                                " ",
                                item.unitShortName),
                            React.createElement("div", { className: styles.priceWrap },
                                item.placedPrice,
                                " ",
                                _this.props.currencySymbol)));
                    })),
                React.createElement("div", { className: styles.total }, translate('/MiniCart/ToPay') + " " + this.props.totalPrice + " " + this.props.currencySymbol),
                React.createElement("form", null,
                    React.createElement("fieldset", null,
                        React.createElement("div", { className: styles.searchInputWrapper },
                            React.createElement("div", { className: styles.loginOut },
                                React.createElement(Button, { appearance: "full", onClick: function (e) { return _this.doLogin(e); } }, translate('/Account/PunchOut/Login')),
                                React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.doLogout(); } }, translate('/Account/PunchOut/Logout')))))))));
    };
    return PunchOutTakeoverPage;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
    logout: function () {
        return dispatch(logout());
    },
}); })(PunchOutTakeoverPage);
