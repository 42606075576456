import React from 'react';
import { Checkbox } from 'Shared/Fields';
import { translate } from 'Shared/translate';
import * as styles from './details.scss';
var DetailDesktopHeaders = function (props) {
    return (React.createElement("div", { className: styles.rowHead },
        props.isSelectable && !!(props.currentTab === 'historyOrders') && (React.createElement("div", { className: styles.checkBox },
            React.createElement(Checkbox, { checked: props.isHeaderSelected, onChange: function () { return props.selectAllOrderItems(); } }))),
        React.createElement("div", { className: styles.mediaDetailHead }),
        !!(props.currentTab === 'currentOrders' && props.currentGrouping !== 'PerOrder') && (React.createElement("div", { className: styles.orderNumberDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/OrderNumber'))),
        !!(props.currentTab === 'currentOrders' && props.currentGrouping === 'ViewAll') && (React.createElement("div", { className: styles.deliveryDateDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/DeliveryDate'))),
        React.createElement("div", { className: styles.descriptionDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/DisplayName')),
        React.createElement("div", { className: styles.unitDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/Units')),
        React.createElement("div", { className: styles.quantityDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/Quantity')),
        React.createElement("div", { className: styles.priceDetails }, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/UnitPrice'))));
};
export default DetailDesktopHeaders;
