import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import * as layout from './../../../styles/objects/layout.scss';
var APPEARANCES = {
    default: styles.base,
    narrow: layout.narrow,
    divider: styles.divider,
};
var createComponent = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.baseClassName, baseClassName = _c === void 0 ? undefined : _c, _d = _b.tagName, tagName = _d === void 0 ? 'div' : _d;
    return function (props) {
        var appearanceList = (typeof props.appearance === 'string'
            ? [props.appearance]
            : props.appearance || []);
        var divProps = Object.assign({}, props);
        delete divProps.appearance;
        delete divProps.layout;
        return React.createElement(tagName, Object.assign({}, divProps, {
            className: classnames(baseClassName, appearanceList.map(function (name) { return APPEARANCES[name]; }), props.layout && styles["layout" + props.layout], props.className),
        }), props.children);
    };
};
// The `<Basic>`-component is a basic page layout component which is usually a child component to `<Layout>`.
//  - {string} `className` - Passes through the value on the root element `className`.
//  - {string} `appearance` - Apply diffent appearances. Available appearances are:
//     - `default` - Centers content with default page width. Default to `<Main>`-component.
//     - `narrow` - Centers content with narrow page width.
//     - `divider` - Adds a rule (vertical and horizontal) between part childrens.
//  - {string} `layout` - Layouts the component childrens. Available layout names are:
//     - `1to1` - First child ratio 1:2 and second child ratio 1:2 (| x | x |)
//     - `2to1` - First child ratio 2:3 and second child ratio 1:3 (| x x | x |)
//     - `3to1` - First child ratio 3:4 and second child ratio 1:4 (| x x x | x |)
//     - `1to2` - First child ratio 1:3 and second child ratio 2:3 (| x | x x |)
//     - `1to3` - First child ratio 1:4 and second child ratio 3:4 (| x | x x x |)
//     - `2to5` - First child ratio 2:5 and second child ratio 2:5 (| x x | x x x |)
//     - `3to7` - First child ratio 3:10 and second child ratio 7:10 (| x x x | x x x x x x x |)
export var Basic = createComponent();
// `<Part>`, `<Section>` and `<Article>` is like the `<Basic>`-component but adds spacing between (top and bottom) blocks.
// The difference is that `<Part>` is a `<div>`-tag, `<Section>` is a `<section>`-tag and `<Article>` is a `<article>`-tag.
export var Part = createComponent({
    baseClassName: styles.part,
});
export var Section = createComponent({
    baseClassName: styles.part,
    tagName: 'section',
});
export var Article = createComponent({
    baseClassName: styles.part,
    tagName: 'article',
});
export var Form = createComponent({
    baseClassName: styles.part,
    tagName: 'form',
});
// Default component a.k.a. `<Main>` centers the content with white background.
export var Main = createComponent({
    baseClassName: styles.main,
    tagName: 'main',
});
export default Main;
