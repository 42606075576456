var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
var UnitOptionSelect = /** @class */ (function (_super) {
    __extends(UnitOptionSelect, _super);
    function UnitOptionSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UnitOptionSelect.prototype.render = function () {
        var _this = this;
        var _a = this.props, options = _a.options, value = _a.value;
        if (options.filter(function (item) { return !item.toLowerCase().includes('/d'); }).length < 2) {
            return React.createElement("div", { className: styles.onlyOne }, value);
        }
        return (React.createElement("div", null, options.map(function (option, index) {
            return (React.createElement("button", { type: "button", key: index, className: value === option ? null : styles.inactiveButton, onClick: function () { return _this.props.onChange(option); } }, option));
        })));
    };
    return UnitOptionSelect;
}(React.Component));
export default UnitOptionSelect;
