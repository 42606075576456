var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { SELECT_VARIANT, REMOVE_CART_ITEM, SET_ALTERNATIVE_PRODUCTS_LINKS } from './action-creators';
import { getCartLineItemsForVariants } from '../../Cart/reducer';
var initialState = { selectedUnits: [], alternativeProductLinks: [] };
var productReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SELECT_VARIANT:
            var newUnit = action.newCode;
            var oldUnit_1 = action.oldCode;
            return __assign({}, state, { selectedUnits: __spread(new Set(__spread(state.selectedUnits.filter(function (u) { return u !== oldUnit_1; }), [newUnit]).map(function (unit) { return unit; }))) });
        case REMOVE_CART_ITEM:
            var itemUnit_1 = action.code;
            return __assign({}, state, { selectedUnits: __spread(state.selectedUnits.filter(function (u) { return u !== itemUnit_1; })) });
        case SET_ALTERNATIVE_PRODUCTS_LINKS:
            var alternativeProductsLinks = action.data;
            return __assign({}, state, { alternativeProductLinks: alternativeProductsLinks });
        default:
            return state;
    }
};
export default productReducer;
export var isUnitSelected = function (state, code) { return state.selectedUnits.includes(code); };
export var getOppositeVariant = function (cartItems, cartItem) {
    var oppositeVariantCode = cartItem.product.variants
        .filter(function (item) { return item.code !== cartItem.lineItem.selectedVariant.code; })
        .shift();
    return (oppositeVariantCode && cartItems.filter(function (i) { return i.lineItem.selectedVariant.code === oppositeVariantCode.code; }).shift());
};
export var getSelectedVariant = function (state, variants, preferedVariant) {
    var cartLineItems = getCartLineItemsForVariants(state, variants);
    if (preferedVariant && cartLineItems.length > 1) {
        return preferedVariant;
    }
    var selectedVariant = variants.find(function (v) { return isUnitSelected(state.product, v.code); });
    if (selectedVariant) {
        return selectedVariant;
    }
    if (cartLineItems.length > 0) {
        return variants.find(function (v) { return v.code === cartLineItems[0].code; });
    }
    return variants[0];
};
