var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from "react";
import { Part } from "Shared/PageLayout";
import * as styles from '../../base.scss';
import OrderHistoryHeaderRow from "./OrderHistoryHeaderRow";
import OrderHistoryRow from "./OrderHistoryRow";
import shallowEquals from "Shared/shallow-equals";
import { translate } from "Shared/translate";
var OrderHistoryList = /** @class */ (function (_super) {
    __extends(OrderHistoryList, _super);
    function OrderHistoryList(props) {
        var _this = _super.call(this, props) || this;
        _this.printAllHandler = function (printAll) {
            var updatedCheckedState = __spread(_this.state.orders);
            updatedCheckedState.map(function (el) { return Object.assign(el, { markedForPrint: printAll }); });
            _this.setState({ printAll: printAll, orders: updatedCheckedState }, function () {
                _this.props.addRemoveToPrint("", _this.state.orders.some(function (x) { return !!x.markedForPrint === true; }));
            });
        };
        _this.printHandler = function (id, add) {
            _this.props.addRemoveToPrint(id, add);
        };
        _this.state = {
            orders: _this.props.orders,
            printAll: false
        };
        return _this;
    }
    OrderHistoryList.prototype.componentDidUpdate = function (nextProps) {
        if ((!shallowEquals(nextProps, this.props))) {
            this.setState({ printAll: nextProps.printAll, orders: nextProps.orders });
        }
    };
    OrderHistoryList.prototype.render = function () {
        var _this = this;
        return (React.createElement(Part, { className: styles.OrderHistory__Outer },
            React.createElement(OrderHistoryHeaderRow, { checked: this.state.printAll, addAllToPrint: this.printAllHandler }),
            React.createElement(Part, { className: styles.OrderHistory__List }, this.state.orders && this.state.orders.length
                ? this.state.orders.map(function (item, index) {
                    return (React.createElement(OrderHistoryRow, { addToPrint: _this.printHandler, setExpandedItem: _this.props.setExpandedItem, key: index, rowItem: item, expanded: item.orderNumber === _this.props.expanded }));
                }) : React.createElement(Part, { className: styles.OrderHistory__Row },
                React.createElement("div", { className: styles.OrderHistory__Cell }),
                React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/NoResult")),
                React.createElement("div", { className: styles.OrderHistory__Cell })))));
    };
    return OrderHistoryList;
}(React.Component));
;
export default OrderHistoryList;
