import React from 'react';
import MarkAll from './Toolbar/MarkAll';
import { translate } from 'Shared/translate';
import * as listStyles from '../../../Shared/List/Table/base.scss';
import classnames from 'classnames';
var PantryListHeader = function () {
    return (React.createElement("div", { className: classnames(listStyles.list__header, listStyles.list__row) },
        React.createElement(MarkAll, { isPantry: true }),
        React.createElement("div", { className: listStyles.list__cell__status }, translate('/Account/ProductList/Headers/Status')),
        React.createElement("div", null, translate('/Account/ProductList/Headers/Count')),
        React.createElement("div", { className: listStyles.list__cell__totalprice },
            " ",
            translate('/Account/ProductList/Headers/StockValue')),
        React.createElement("div", { className: listStyles.list__cell__actions })));
};
export default PantryListHeader;
