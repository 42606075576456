var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as server from 'Shared/server';
import { addCustomProduct, findNewCustomProducts, searchAssortment } from '../App/assortment';
import { pushState } from 'Shared/history';
import { batchActions } from 'redux-batched-actions';
import { get as getTakeStockCache, set as setTakeStockCache } from '../App/app-takestock-cache';
import { pantryStatus, productsInPantry, restartInventory, saveInventoryItem, clearInventory, findModifiedInventoryItems, remapCustomProductIds, } from '../App/inventory';
export var STOCKTAKING_LOADING = 'STOCKTAKING_LOADING';
export var STOCKTAKING_NOT_LOADING = 'STOCKTAKING_NOT_LOADING';
export var STOCKTAKING_SAVED = 'STOCKTAKING_SAVED';
export var STOCKTAKING_SAVEANDCLOSED = 'STOCKTAKING_SAVEANDCLOSED';
export var STOCKTAKING_STARTED = 'STOCKTAKING_STARTED';
export var STOCKTAKING_ENDED = 'STOCKTAKING_ENDED';
export var SET_INVENTORY = 'SET_INVENTORY';
export var SET_QUERY_STRING = 'SET_QUERY_STRING';
export var SEARCH_RESULT = 'SEARCH_RESULT';
export var TOGGLE_CAMERA_TAKESTOCK = 'TOGGLE_CAMERA_TAKESTOCK';
export var SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export var CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export var NO_SEARCH_RESULT = 'NO_SEARCH_RESULT';
export var SET_ACTIVE_INVENTORY_ITEM = 'SET_ACTIVE_INVENTORY_ITEM';
export var CLEAR_ACTIVE_INVENTORY_ITEM = 'CLEAR_ACTIVE_INVENTORY_ITEM';
export var SET_ACTIVE_CONTROL = 'SET_ACTIVE_CONTROL';
export var SET_ASSORTMENT_SYNC_DATE = 'SET_ASSORTMENT_SYNC_DATE';
export var GET_ASSORTMENT_SYNC_DATE = 'GET_ASSORTMENT_SYNC_DATE';
export var STOCKTAKING_LOAD_INVENTORY = 'STOCKTAKING_LOAD_INVENTORY';
export function toggleCamera(showCamera) {
    return {
        type: TOGGLE_CAMERA_TAKESTOCK,
        showCamera: showCamera,
    };
}
export function beginStockTaking(pantryIds) {
    return function (dispatch, getState) {
        return server
            .post('/inventera/begininventory', { ids: pantryIds })
            .then(function (r) { return r.json(); })
            .then(function (r) {
            restartInventory(r);
            dispatch({
                type: STOCKTAKING_STARTED,
                pantries: r.map(function (p) { return ({
                    id: p.id,
                    displayName: p.displayName,
                    leftCount: p.items.length,
                    totalCount: p.items.length,
                }); }),
            });
        })
            .then(function () { return pushState('/laddanerprodukter'); });
    };
}
export function beginStockTakingAll() {
    return function (dispatch, getState) {
        return server
            .post('/inventera/beginall')
            .then(function (r) { return r.json(); })
            .then(function (r) {
            var pantry = {
                id: 'all',
                displayName: 'all',
                leftCount: null,
                totalCount: null,
                items: r,
            };
            restartInventory([pantry]);
            dispatch({
                type: STOCKTAKING_STARTED,
                pantries: [pantry],
            });
        })
            .then(function () { return pushState('/laddanerprodukter'); });
    };
}
export function endStockTaking(markAsDone) {
    return function (dispatch, getState) {
        dispatch({
            type: STOCKTAKING_LOADING,
        });
        var pantries = pantryStatus();
        var newCustomProducts = findNewCustomProducts();
        var modifiedInventories = findModifiedInventoryItems();
        return Promise.all([pantries, newCustomProducts, modifiedInventories]).then(function (_a) {
            var _b = __read(_a, 3), pantries = _b[0], newCustomProducts = _b[1], modifiedInventories = _b[2];
            var isAll = pantries.length === 1 && pantries[0].id === 'all';
            var url = isAll ? '/inventera/endall' : '/inventera/endinventory';
            var postModel = isAll
                ? makePostModelAll(modifiedInventories, newCustomProducts, markAsDone)
                : makePostModel(pantries, modifiedInventories, newCustomProducts, markAsDone);
            return server
                .postJson(url, postModel)
                .then(function (result) {
                return clearInventory().then(function () { return applyNewCustomProducts(result.createdCustomProducts); });
            })
                .then(function () {
                dispatch(batchActions([
                    {
                        type: STOCKTAKING_ENDED,
                    },
                    {
                        type: markAsDone ? STOCKTAKING_SAVEANDCLOSED : STOCKTAKING_SAVED,
                    },
                ]));
            })
                .catch(function () {
                dispatch({
                    type: STOCKTAKING_NOT_LOADING,
                });
            });
        });
    };
}
function applyNewCustomProducts(products) {
    var map = {};
    Object.keys(products).forEach(function (k) { return (map[k] = products[k].productId); });
    return remapCustomProductIds(map);
}
function makePostModelAll(modifiedInventories, newCustomProducts, markAsDone) {
    return {
        items: modifiedInventories.map(function (i) {
            var r = Object.assign({}, i);
            delete r.pantryId;
            return r;
        }),
        newCustomProducts: newCustomProducts,
        markAsDone: markAsDone,
    };
}
function makePostModel(pantries, modifiedInventories, newCustomProducts, markAsDone) {
    var obj = {};
    pantries.forEach(function (p) { return (obj[p.id] = []); });
    for (var i = 0; i < modifiedInventories.length; i++) {
        var item = modifiedInventories[i];
        var list = obj[item.pantryId];
        var clone = Object.assign({}, item);
        delete clone.pantryId;
        list.push(clone);
    }
    return {
        pantries: Object.keys(obj).map(function (key) { return ({ pantryId: key, items: obj[key], markAsDone: markAsDone }); }),
        newCustomProducts: newCustomProducts,
    };
}
export function loadPantries() {
    return function (dispatch, getState) {
        return pantryStatus().then(function (r) {
            dispatch({
                type: STOCKTAKING_STARTED,
                pantries: r,
            });
            return r;
        });
    };
}
export function setInventory(pantryId, product) {
    return function (dispatch, getState) {
        saveInventoryItem(pantryId, {
            productId: product.productId,
            quantity: product.quantity,
            unitShortName: product.unitShortName,
        });
        dispatch({
            type: SET_INVENTORY,
            pantryId: pantryId,
            product: product,
        });
    };
}
export function loadInventory(pantryId) {
    return function (dispatch, getState) {
        return productsInPantry(pantryId).then(function (r) {
            dispatch({
                type: STOCKTAKING_LOAD_INVENTORY,
                products: r,
            });
        });
    };
}
export function setQueryString(query) {
    return {
        type: SET_QUERY_STRING,
        query: query,
    };
}
export function searchProducts(query, limit, pantryId) {
    if (pantryId === void 0) { pantryId = null; }
    return function (dispatch, getState) {
        dispatch(batchActions([clearActiveInventoryItem(), clearSearchResults(), setActiveControl(null)]));
        return searchAssortment(query, limit, pantryId).then(function (products) {
            if (products.length === 1) {
                dispatch(setActiveInventoryItem(products[0]));
                return;
            }
            if (products.length > 0) {
                dispatch(batchActions([searchResults(products), setActiveControl('search')]));
                return;
            }
            dispatch(batchActions([noSearchResults(), setActiveControl('search')]));
        });
    };
}
export function searchResults(products) {
    return {
        type: SEARCH_RESULT,
        products: products,
    };
}
export function noSearchResults() {
    return {
        type: NO_SEARCH_RESULT,
    };
}
export function clearSearchResults() {
    return {
        type: CLEAR_SEARCH_RESULT,
    };
}
export function setActiveInventoryItem(product) {
    return {
        type: SET_ACTIVE_INVENTORY_ITEM,
        product: product,
    };
}
export function clearActiveInventoryItem() {
    return {
        type: CLEAR_ACTIVE_INVENTORY_ITEM,
    };
}
export function cancelActiveInventoryItem() {
    return function (dispatch, getState) {
        if (!getState().takeStock.searchResults) {
            dispatch(batchActions([clearActiveInventoryItem(), setQueryString(''), clearSearchResults()]));
            return;
        }
        dispatch(batchActions([clearActiveInventoryItem(), setActiveControl('search')]));
    };
}
export function setActiveControl(control) {
    return {
        type: SET_ACTIVE_CONTROL,
        control: control,
    };
}
export function addNewCustomProduct(pantryId, product) {
    return function (dispatch, getState) {
        addCustomProduct(product).then(function () {
            dispatch(setInventory(pantryId, product));
            dispatch(setActiveControl(null));
        });
    };
}
export function setAssortmentSyncDate(timestamp) {
    setTakeStockCache('assortmentSyncDate', timestamp);
    return {
        type: SET_ASSORTMENT_SYNC_DATE,
        timestamp: timestamp,
    };
}
export function getAssortmentSyncDate() {
    return function (dispatch, getState) {
        return getTakeStockCache('assortmentSyncDate').then(function (timestamp) {
            if (timestamp === null) {
                timestamp = 10;
            }
            dispatch({
                type: SET_ASSORTMENT_SYNC_DATE,
                timestamp: timestamp,
            });
        });
    };
}
