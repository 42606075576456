var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import Main, { Section } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import { Input } from 'Shared/Fields';
import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import FeedbackButton from 'Shared/Button/Feedback';
import connect from 'Shared/connect';
import { searchUsers } from '../../../Account/action-creators';
import Button from 'Shared/Button';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import * as styles from './base.scss';
var feedbackDelay = 500;
var AccountSettingsAdminPage = /** @class */ (function (_super) {
    __extends(AccountSettingsAdminPage, _super);
    function AccountSettingsAdminPage(props) {
        var _this = _super.call(this, props) || this;
        _this.setInvalid = function (invalid, arg) {
            switch (arg) {
                case "firstName":
                    _this.setState({ invalidFirstName: invalid });
                    break;
                case "lastName":
                    _this.setState({ invalidLastName: invalid });
                    break;
                case "email":
                    _this.setState({ invalidEmail: invalid });
                    break;
                case "customerId":
                    _this.setState({ invalidCustomerId: invalid });
            }
            if (!_this.state.invalidEmail && !_this.state.invalidFirstName && !_this.state.invalidLastName && !_this.state.invalidCustomerId)
                _this.setState({ invalid: false });
            else
                _this.setState({ invalid: true });
        };
        _this.validateEmail = function (email) {
            return _this.sendValidationRequest("ValidateEmail", { email: email, prevEmail: "", }, false, "email");
        };
        _this.validateNameLength = function (text, arg) {
            return _this.sendValidationRequest("ValidateTextLength", { text: text, minLength: 1, maxLength: 50 }, true, arg);
        };
        _this.sendValidationRequest = function (method, payload, showFeedback, arg) {
            return server
                .postJson(pathCombine("/FieldAndModelValidation", method), payload)
                .then(function (result) {
                if (!result.success) {
                    _this.setInvalid(true, arg);
                    if (!!showFeedback) {
                        !!result.message && _this.props.setFeedback({ text: result.message, type: 'error' });
                    }
                }
                else {
                    _this.setInvalid(false, arg);
                }
                return Promise.resolve(result);
            })
                .catch(function (err) {
                _this.props.setFeedback({ text: err.message, type: 'error' });
                return Promise.resolve(null);
            });
        };
        _this.getCustomerDetails = function () {
            return server
                .postJson(pathCombine(location.pathname, "GetCustomerInfo"), { customerId: _this.state.searchInput })
                .then(function (result) {
                _this.setState({
                    firstName: result.firstName,
                    lastName: result.lastName,
                    email: result.email
                });
            });
        };
        _this.state = {
            searchInput: '',
            isSubmitted: false,
            invalid: false,
            firstName: '',
            lastName: '',
            email: '',
            invalidCustomerId: false,
            customerId: '',
            isCustomerSelected: false,
            selectedUsername: ''
        };
        return _this;
    }
    AccountSettingsAdminPage.prototype.searchforUsers = function (query) {
        var _this = this;
        clearTimeout(this.currentTimeout);
        if (query.length > 2) {
            if (!this.state.searchingForUsers) {
                this.setState({ searchingForUsers: true });
            }
            this.currentTimeout = setTimeout(function () {
                _this.props.searchUsers(query).then(function (result) {
                    var slicedArr = result.slice(0, 10);
                    _this.setState({ searchResult: slicedArr, searchingForUsers: false });
                });
            }, 500);
        }
        else if (this.state.searchingForUsers || !!this.state.searchResult) {
            this.setState({ searchResult: null, searchingForUsers: false });
        }
    };
    AccountSettingsAdminPage.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        this.setState({ isSubmitted: true });
        if (!!this.state.invalid) {
            return Promise.reject(null);
        }
        return server.postJson(pathCombine(location.pathname, 'UpdateFromAdmin'), this.state).then(function (result) {
            if (!result.isSuccess == undefined || !result.isSuccess) {
                _this.props.setFeedback({ text: result.message, type: 'error' });
                _this.setState({ isSubmitted: false });
                return Promise.reject(null);
            }
            _this.props.setFeedback({
                text: translate('/Admin/AccountSettings/OnEmailSentToCustomer', _this.state.searchInput),
                type: 'success'
            });
        }, function (e) {
            _this.setState({ isSubmitted: false });
            return Promise.reject(null);
        });
    };
    ;
    AccountSettingsAdminPage.prototype.selectedUser = function (user, username) {
        this.setState({
            searchInput: user.customerId,
            selectedUser: user,
            searchResult: null,
            customerId: user.customerId,
            isCustomerSelected: true,
            selectedUsername: username
        });
        this.getCustomerDetails();
    };
    AccountSettingsAdminPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { className: styles.base },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", { className: styles.userInputField }, translate('/Admin/AccountSettings/Title'))),
            React.createElement("div", { className: styles.accountSettingsWrapper },
                React.createElement("div", { className: styles.userInputField },
                    React.createElement(Input, { label: translate('/Admin/AccountSettings/CustomerToChange'), type: "text", value: this.state.selectedUsername, name: "UserName", spellCheck: false, autoCorrect: "off", required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterValidCustomerId'), onChange: function (e) { return _this.setState({ selectedUsername: e.target.value }, function () {
                            return _this.searchforUsers(_this.state.selectedUsername);
                        }); }, onValidate: function () { return _this.validateNameLength(_this.state.selectedUsername, "customerId"); }, showValidationMessage: false }),
                    this.state.isCustomerSelected && this.state.selectedUsername && (React.createElement("div", { className: styles.infoForAdmin }, translate("/Admin/AccountSettings/ChangingSettingsForCustomer", this.state.selectedUsername))),
                    !!this.state.searchResult && this.state.searchResult.length > 0 && (React.createElement("table", { className: styles.list },
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, translate('/Account/Impersonation/AccountNumber')),
                                React.createElement("th", null, translate('/Account/Impersonation/CustomerName')),
                                React.createElement("th", null)),
                            this.state.searchResult.map(function (contact, i) { return (React.createElement("tr", { key: 1 },
                                React.createElement("td", null, contact.customerId),
                                React.createElement("td", null, contact.name),
                                React.createElement("td", null,
                                    React.createElement(Button, { onClick: function () { _this.setState({ searchInput: contact.customerId }, function () { _this.selectedUser(contact, contact.name); }); } }, "V\u00E4lj denna kund")))); }))))),
                React.createElement("div", { className: styles.userInputField },
                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/FirstName'), type: "text", name: "firstName", spellCheck: false, autoCorrect: "off", value: this.state.firstName, required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterName'), onChange: function (e) { return _this.setState({ firstName: e.target.value }); }, onValidate: function () { return _this.validateNameLength(_this.state.firstName, "firstName"); }, showValidationMessage: false })),
                React.createElement("div", { className: styles.userInputField },
                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/LastName'), type: "text", name: "lastName", spellCheck: false, value: this.state.lastName, autoCorrect: "off", required: true, requiredValidationMessage: translate('/Account/AccountSettings/Validation/EnterName'), onChange: function (e) { return _this.setState({ lastName: e.target.value }); }, onValidate: function () { return _this.validateNameLength(_this.state.lastName, "lastName"); }, showValidationMessage: false })),
                React.createElement("div", { className: styles.userInputField },
                    React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/Email'), type: "text", name: "Email", spellCheck: false, value: this.state.email, autoCorrect: "off", required: true, onChange: function (e) { return _this.setState({ email: e.target.value }); }, onValidate: function () { return _this.validateEmail(_this.state.email); } })),
                React.createElement("div", null,
                    React.createElement(FeedbackButton, { disabled: this.state.invalid, appearance: ['full', 'primary'], type: 'submit', onClickWithFeedback: function (e, activate) {
                            var promiseOrUndefined = _this.onSubmit(e);
                            if (promiseOrUndefined) {
                                activate(promiseOrUndefined, { minimumPending: feedbackDelay })
                                    .then(function (result) {
                                    console.log(result.isSuccess);
                                    _this.setState({
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        searchInput: '',
                                    });
                                }).catch(function () { return console.log('Failed'); });
                            }
                        } }, translate('/Shared/Send'))))));
    };
    return AccountSettingsAdminPage;
}(Component));
export default connect(function (state) { return ({
    currentUser: state.currentUser
}); }, function (dispatch) { return ({
    searchUsers: function (query) {
        return dispatch(searchUsers(query));
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(AccountSettingsAdminPage);
