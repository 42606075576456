var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Image from 'Shared/ImageFallback';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import ReactSwipe from 'react-swipe';
import * as styles from './base.scss';
var ProductMedia = /** @class */ (function (_super) {
    __extends(ProductMedia, _super);
    function ProductMedia(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentIndex: 0,
        };
        return _this;
    }
    ProductMedia.prototype.componentWillReceiveProps = function (newProps) {
        if (!arrayEquals(newProps.imageUrls, this.props.imageUrls)) {
            this.lastImageUrls = this.props.imageUrls;
            this.slideTo(0);
        }
    };
    ProductMedia.prototype.componentDidUpdate = function () {
        // Only if the last and new imageUrls has more than one image.
        if (this.lastImageUrls &&
            this.lastImageUrls.length > 1 &&
            !!this.props.imageUrls &&
            this.props.imageUrls.length > 1) {
            this.swiper.swipe.setup();
        }
    };
    ProductMedia.prototype.slideTo = function (index) {
        var _this = this;
        this.setState({ currentIndex: index }, function () { return _this.swiper.swipe.slide(index); });
    };
    ProductMedia.prototype.render = function () {
        var _this = this;
        var shouldBeLoaded = function (index) {
            var indexesToLoad = [_this.state.currentIndex - 1, _this.state.currentIndex, _this.state.currentIndex + 1];
            return indexesToLoad.indexOf(index) !== -1;
        };
        var len = this.props.imageUrls ? this.props.imageUrls.length : 0;
        var hasNext = this.state.currentIndex < len - 1;
        var hasPrev = this.state.currentIndex > 0;
        return (React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.primary },
                React.createElement("div", { id: "large-image" },
                    React.createElement(ReactSwipe, { swipeOptions: {
                            continuous: false,
                            callback: function (index) { return _this.slideTo(index); },
                        }, ref: function (swiper) { return (_this.swiper = swiper); }, key: this.props.id }, this.props.imageUrls &&
                        this.props.imageUrls.map(function (imageUrl, index) { return (React.createElement("div", { key: imageUrl, className: styles.media },
                            React.createElement(Image, { preferSmallerIfCached: true, minSizeIfCached: "tiny", skipSrcSet: true, alt: _this.props.name, size: "medium", src: shouldBeLoaded(index) ? imageUrl : '', "aria-hidden": index !== _this.state.currentIndex }))); })),
                    len === 0 && React.createElement(Image, null))),
            hasPrev && (React.createElement(Button, { appearance: ['round', 'secondary'], onClick: function () { return _this.slideTo(_this.state.currentIndex - 1); }, className: styles.prev },
                React.createElement("span", null,
                    React.createElement(Icon, { name: "arrow-left" })))),
            hasNext && (React.createElement(Button, { appearance: ['round', 'secondary'], onClick: function () { return _this.slideTo(_this.state.currentIndex + 1); }, className: styles.next },
                React.createElement("span", null,
                    React.createElement(Icon, { name: "arrow-right" })))),
            len > 1 && (React.createElement("ul", { className: styles.swiperPagination }, this.props.imageUrls.map(function (slide, index) { return (React.createElement("li", { key: index, className: _this.state.currentIndex === index ? styles.active : '', onClick: function () { return _this.slideTo(index); } })); })))));
    };
    return ProductMedia;
}(React.Component));
export default ProductMedia;
function arrayEquals(array1, array2) {
    if (!array1 && !array2) {
        return true;
    }
    if (!array1 || !array2 || array1.length !== array2.length) {
        return false;
    }
    return (array1
        .slice()
        .sort()
        .join('') ===
        array2
            .slice()
            .sort()
            .join(''));
}
