import { urlToString, areEqual } from 'Shared/url';
import { componentNameIsCheckoutPage } from '../../Checkout/Pages/Checkout/current-page-is-checkout';
import localForage from 'localforage';
import { supportAsyncStorage } from 'Shared/device-type';
import { componentIsFavorites, componentIsPantries } from 'Shared/ListItem/current-page-is';
import { componentNameIsCustomProductsPage } from '../../CustomProducts/current-page-is-custom-products';
var maxCacheSize = 50;
var requestTimeout = 100;
var storage = localForage.createInstance({
    storeName: 'cache',
});
var allCachedUrls = null;
// We keep a small cache in memory of full pages so that we can respond quicker
// for the pages the user just viewed. This is mainly because when you go back
// in the browser history, the browser will restore scroll position and if we
// don't respond fast enough from the cache we haven't rendered the page when
// the browser restores scroll position. Which means that the scroll position
// will first jump, then we render, then the scroll position changes again.
var fastCache = {};
var fastCacheUrls = [];
var MAX_FAST_CACHE_SIZE = 10;
export function isCacheItem(item) {
    return !!(item && item.url && item.$cache && item.componentName && item.meta);
}
export function get(url) {
    if (!supportAsyncStorage) {
        return Promise.resolve(null);
    }
    if (fastCache[url]) {
        console.info('Loading', url, 'from fast cache');
        // If you request something from the fast queue
        // we want to update the priority list so we don't
        // drop something from the fast cache that was recently used
        placeFirstInFastCache(fastCache[url]);
        return Promise.resolve(fastCache[url]);
    }
    if (allCachedUrls && allCachedUrls.indexOf(url) === -1) {
        return Promise.resolve(findInFastCache(url));
    }
    return Promise.race([
        timeout(),
        storage.getItem(url).then(function (item) {
            if (item) {
                addToFastCacheIfFullItem(item);
            }
            return item;
        }, function () {
            // If the storage is full, we just act like we don't have anything
            // in the cache
            return null;
        }),
    ])
        .then(function (cacheItem) {
        if (cacheItem && cacheItem.timeout) {
            console.debug('Timing out cache storage request because it took more than ' + requestTimeout + 'ms');
            return null;
        }
        return cacheItem;
    })
        .then(function (cacheItem) {
        if (!cacheItem) {
            return findInFastCache(url);
        }
        return cacheItem;
    });
}
function findInFastCache(url) {
    for (var i = fastCacheUrls.length - 1; i >= 0; i--) {
        var partial = findIn(fastCache[fastCacheUrls[i]], url);
        if (partial) {
            return partial;
        }
    }
    return null;
}
function findIn(cacheItem, url) {
    if (Array.isArray(cacheItem)) {
        for (var i = 0; i < cacheItem.length; i++) {
            var item = cacheItem[i];
            if (isCacheItem(item) && areEqual(item.url, url)) {
                return item;
            }
            // A partial never contains cache items
            if (!isCacheItem(item) || item.$cache !== 'partial') {
                var deep = findIn(item, url);
                if (deep) {
                    return deep;
                }
            }
        }
    }
    else if (cacheItem instanceof Object) {
        if (isCacheItem(cacheItem) && areEqual(cacheItem.url, url)) {
            return cacheItem;
        }
        // A partial never contains cache items
        if (!isCacheItem(cacheItem) || cacheItem.$cache !== 'partial') {
            var keys = Object.keys(cacheItem);
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                var item = cacheItem[key];
                if (isCacheItem(item) && areEqual(item.url, url)) {
                    return item;
                }
                var deep = findIn(item, url);
                if (deep) {
                    return deep;
                }
            }
        }
    }
    return null;
}
function timeout() {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({ timeout: true });
        }, requestTimeout);
    });
}
function placeFirstInFastCache(item) {
    var url = urlToString(item.url);
    fastCacheUrls = fastCacheUrls.filter(function (cachedUrl) { return cachedUrl !== url; });
    fastCacheUrls.push(url);
}
function addToFastCacheIfFullItem(item) {
    if (item.$cache === 'full') {
        var url = urlToString(item.url);
        if (fastCacheUrls.length > MAX_FAST_CACHE_SIZE) {
            var oldestUrlInFastCache = fastCacheUrls.shift();
            console.info('Dropping', oldestUrlInFastCache, 'from fast cache');
            delete fastCache[oldestUrlInFastCache];
        }
        placeFirstInFastCache(item);
        fastCache[url] = item;
    }
}
function isNonCachablePage(item) {
    var doNotCache = [
        componentNameIsCheckoutPage(item.componentName),
        componentIsFavorites(item.componentName),
        componentIsPantries(item.componentName),
        componentNameIsCustomProductsPage(item.componentName)
    ];
    return doNotCache.some(Boolean);
}
export function set(item, fastCacheOnly) {
    if (fastCacheOnly === void 0) { fastCacheOnly = false; }
    if (!supportAsyncStorage) {
        return Promise.resolve(null);
    }
    if (!item.componentName || isNonCachablePage(item)) {
        console.info('Not caching page');
        return Promise.resolve(null);
    }
    var url = urlToString(item.url);
    addToFastCacheIfFullItem(item);
    if (fastCacheOnly) {
        return Promise.resolve(null);
    }
    storage.getItem(url).then(function (existing) {
        if (!existing || existing.$cache === 'partial' || item.$cache === 'full') {
            onCacheSet(url);
            // We don't want to remove unserializable properties from the object
            // because the sender might use them after calling this method
            var serializable_1 = {};
            Object.keys(item).forEach(function (property) {
                // Because we can't serialize functions
                if (!(item[property] instanceof Function)) {
                    serializable_1[property] = item[property];
                }
            });
            return storage.setItem(url, serializable_1).catch(function (e) { return console.error('Error storing in item cache', url, e); });
        }
        else {
            console.info('Not storing partial cache for ' + url + ' because there is already full content cached');
        }
        return Promise.resolve(null);
    });
    // return Promise.resolve(null);
}
export function clear(url) {
    var index = fastCacheUrls.findIndex(function (u) { return u === url; });
    if (index !== -1) {
        fastCacheUrls.splice(index, 1);
        delete fastCache[url];
    }
    return storage.removeItem(url);
}
export function nuke() {
    Object.keys(fastCache).forEach(function (url) { return delete fastCache[url]; });
    fastCacheUrls = [];
    return Promise.race([timeout(), storage.clear().catch(function () { return null; })]);
}
var urlsByDateKey = 'urls-by-date';
var flushTimer;
var pendingNewUrls = [];
function onCacheSet(url) {
    if (!pendingNewUrls.includes(url)) {
        pendingNewUrls.unshift(url);
        clearTimeout(flushTimer);
        flushTimer = setTimeout(saveCachedUrlsList, 100);
    }
}
storage.getItem(urlsByDateKey).then(function (urls) { return (allCachedUrls = urls || []); });
function saveCachedUrlsList() {
    storage.getItem(urlsByDateKey).then(function (urls) {
        urls = urls || [];
        urls = urls.filter(function (url) { return !pendingNewUrls.includes(url); });
        urls = pendingNewUrls.concat(urls);
        pendingNewUrls = [];
        if (urls.length > maxCacheSize) {
            var urlsToRemove = urls.slice(maxCacheSize - 1);
            urls = urls.slice(0, maxCacheSize - 1);
            urlsToRemove.forEach(function (url) {
                storage.removeItem(url);
                console.info('Removing', url, 'from the item cache');
            });
        }
        allCachedUrls = urls;
        storage.setItem(urlsByDateKey, urls).then(function () {
            if (pendingNewUrls.length > 0) {
                clearTimeout(flushTimer);
                flushTimer = setTimeout(saveCachedUrlsList, 100);
            }
        });
    });
}
