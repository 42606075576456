var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classnames from 'classnames';
import FacetValue from 'Shared/Facet/FacetValue';
import { translate } from 'Shared/translate';
import FacetAndOrFilter from 'Shared/Facet/FacetAndOrFilter';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
export var DEFAULT_VISIBLE_FACET_COUNT = 10;
export var POPULAR_FACET_COUNT = 5;
var ListPickerFacet = function (props) {
    var minItemsForSearch = 7;
    var minSearchQuery = 2;
    var maxHitsForSearch = 10;
    var _a = __read(useState(''), 2), searchText = _a[0], setSearchText = _a[1];
    var _b = __read(useState(true), 2), sliceSearchedVaules = _b[0], setSliceSearchedVaules = _b[1];
    var _c = props.filteredFacets(), popularValues = _c.popularValues, alphabeticalValues = _c.alphabeticalValues;
    var someValuesAreHidden = props.someValuesAreHidden && alphabeticalValues.length > props.defaultVisibleFacetCount;
    var visibleAlphabeticalValues = someValuesAreHidden && (!searchText || searchText.length < minSearchQuery)
        ? alphabeticalValues.slice(0, props.defaultVisibleFacetCount)
        : alphabeticalValues;
    var completeList = popularValues.concat(visibleAlphabeticalValues);
    var searchInList = function (facetValues) {
        if (!searchText || searchText.length < minSearchQuery)
            return facetValues;
        if (sliceSearchedVaules)
            return facetValues.filter(function (x) { return x.text.toUpperCase().indexOf(searchText.toUpperCase()) >= 0; }).map(function (x) { return x; }).slice(0, maxHitsForSearch);
        return facetValues.filter(function (x) { return x.text.toUpperCase().indexOf(searchText.toUpperCase()) >= 0; }).map(function (x) { return x; });
    };
    var searchHits = function (facetValues) {
        return facetValues.filter(function (x) { return x.text.toUpperCase().indexOf(searchText.toUpperCase()) >= 0; }).map(function (x) { return x; }).length;
    };
    var onSearchTextChange = function (e) {
        setSearchText(e.target.value);
        props.setMaxHeight(false);
    };
    var onKeyUp = function (e) {
        if (e.key === "Escape") {
            clear();
        }
    };
    var clear = function () {
        setSearchText('');
        props.setMaxHeight(false);
    };
    var onShowAllSearched = function (showMore) {
        !!showMore ? showAll() : showLess();
    };
    var showAll = function () {
        setSliceSearchedVaules(false);
        props.showAll();
    };
    var showLess = function () {
        setSliceSearchedVaules(true);
        props.showLess();
    };
    var showAllCondition = function () {
        return ((someValuesAreHidden) && (searchText.length < minSearchQuery)) || ((searchText.length >= minSearchQuery) && (searchHits(completeList) > maxHitsForSearch) && (sliceSearchedVaules === true));
    };
    var showLessCondition = function () {
        return (((visibleAlphabeticalValues.length > props.defaultVisibleFacetCount) && !someValuesAreHidden) && (searchText.length < minSearchQuery)) || ((searchText.length >= minSearchQuery) && (searchHits(completeList) > maxHitsForSearch) && (sliceSearchedVaules === false));
    };
    var facetValueList = function (facetValues, listClassName) {
        if (listClassName === void 0) { listClassName = ''; }
        return facetValues.length > 0 ? (React.createElement("ul", { className: classnames(styles.values, listClassName) }, facetValues.map(function (facetValue) { return (React.createElement(FacetValue, __assign({ excludeCnt: props.excludeCnt, key: facetValue.fieldName + '-' + facetValue.id, onChange: function (selected, element) { return props.onFacetValueChange(facetValue, selected, element); } }, facetValue))); }))) : null;
    };
    return (React.createElement("div", null,
        props.selectedFacetValues.length > 1 && props.facet.canToggleOrAnd && (React.createElement(FacetAndOrFilter, { fieldName: props.facet.fieldName, onAndOrFilterChange: props.onAndOrFilterChange, hasAndFilter: props.facet.hasAndFilter })),
        (completeList.length > minItemsForSearch) &&
            React.createElement("div", { className: styles.categorySearch },
                React.createElement(Icon, { className: styles.searchIcon, name: "categoryfilter-search" }),
                React.createElement("input", { type: "search", value: searchText, onChange: onSearchTextChange, onKeyUp: onKeyUp, placeholder: translate('/Shared/Search') + " " + props.facet.displayName.toLowerCase() }),
                !!searchText && searchText.length > 0 &&
                    React.createElement(Button, { className: styles.closeIcon, onClick: function () { return clear(); }, appearance: "clear" },
                        React.createElement(Icon, { name: "close" }))),
        facetValueList(searchInList(completeList)),
        completeList.length <= 0 && React.createElement("div", { className: styles.noHits }, translate('/Shared/NoHits')),
        showAllCondition() && (React.createElement("div", { className: styles.showall },
            React.createElement("span", { onClick: function () { return onShowAllSearched(true); } }, translate('/Shared/ShowAll')))),
        showLessCondition() && (React.createElement("div", { className: styles.showall },
            React.createElement("span", { onClick: function () { return onShowAllSearched(false); } }, translate('/Shared/ShowLess'))))));
};
export default ListPickerFacet;
