var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Checkbox from 'Shared/Fields/Checkbox';
import { markAll } from '../../../action-creators';
import classnames from 'classnames';
import * as styles from './base.scss';
import * as listStyles from '../../../../Shared/List/Table/base.scss';
var MarkAll = /** @class */ (function (_super) {
    __extends(MarkAll, _super);
    function MarkAll(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selected: false,
        };
        return _this;
    }
    MarkAll.prototype.selectAll = function () {
        this.props.selectAll(!this.state.selected);
        this.setState({
            selected: !this.state.selected,
        });
    };
    MarkAll.prototype.getMarkAllTranslation = function (isPantry) {
        if (isPantry) {
            return translate('/Account/ProductList/MarkAllPantries');
        }
        else {
            return translate('/Account/ProductList/MarkAll');
        }
    };
    MarkAll.prototype.render = function () {
        var _this = this;
        return this.props.isPantry ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classnames(listStyles.list__cell__check) },
                React.createElement(Checkbox, { checked: this.state.selected, onChange: function () { return _this.selectAll(); } })),
            React.createElement("div", null, this.getMarkAllTranslation(this.props.isPantry)))) : (React.createElement("div", { className: styles.markAll },
            React.createElement(Checkbox, { checked: this.state.selected, onChange: function () { return _this.selectAll(); } }),
            this.getMarkAllTranslation(this.props.isPantry)));
    };
    return MarkAll;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    selectAll: function (selected) {
        dispatch(markAll(selected));
    },
}); })(MarkAll);
