var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main, { Section, Part } from 'Shared/PageLayout';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import LeftPaneMenu from '../Shared/MyPagesMenu/LeftPaneMenu';
import classnames from 'classnames';
var MyMenigoLayout = /** @class */ (function (_super) {
    __extends(MyMenigoLayout, _super);
    function MyMenigoLayout(props) {
        return _super.call(this, props) || this;
    }
    MyMenigoLayout.prototype.render = function () {
        return (React.createElement(Part, { className: styles.background },
            React.createElement(Main, { className: styles.base },
                React.createElement(Part, { className: styles.leftPaneMenu },
                    React.createElement(LeftPaneMenu, { menuItems: this.props.myMenigoItems })),
                React.createElement(Part, { className: styles.rightBlock },
                    React.createElement(Section, { className: styles.heroBlock },
                        React.createElement(Part, { className: styles.leftChildBlock },
                            React.createElement("div", { className: styles.heroBlock__preamble }, this.props.topLeft)),
                        this.props.topRight),
                    React.createElement(Part, { className: classnames(styles.fullChildBlock, this.props.contentCssClass) }, this.props.children)))));
    };
    return MyMenigoLayout;
}(React.Component));
export default connect(function (state) { return ({
    myMenigoItems: state.appShellData.header.myMenigoItems,
}); })(MyMenigoLayout);
