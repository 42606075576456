import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { altenativeProductLinkClick, altenativeProductLinkImpression } from '../../TrackingInformation';
import Image from 'Shared/ImageFallback';
import ViewTracker from 'Shared/ViewObserver/ViewTracker';
import { pushState } from 'Shared/history';
import { timeout } from 'Shared/timeout';
import classnames from 'classnames';
import connect from 'Shared/connect';
var getLinkUrl = function (url) {
    url = getDeepestCategoryLinkUrl(url);
    return url + "/?inStock=true";
};
export var getDeepestCategoryLinkUrl = function (url) {
    var lastSlash = url.lastIndexOf("/");
    url = url.length >= lastSlash ? url.substring(0, lastSlash) : url;
    return url;
};
export var isLinkVisible = function (selectedVariant, product) {
    return isVisible(selectedVariant, product);
};
var isVisible = function (selectedVariant, product) {
    var visibleFromAvailability = {
        inStock: false,
        lowStock: false,
        outOfStock: true,
        futureDelivery: true,
        selectedDate: false,
        warehouse: false,
        unavailable: false,
    };
    if (!!selectedVariant && !!selectedVariant.availability && selectedVariant.availability.availabilityCode) {
        var isVisible_1 = visibleFromAvailability[selectedVariant.availability.availabilityCode];
        if (!!isVisible_1 && (!!product.replacementProduct || !!product.recommendedReplacementProduct) || !!product.hideAlternativeProductsLink || !!product.isDiscontinued) {
            return false;
        }
        return isVisible_1;
    }
    return false;
};
var onLinkClick = function (product) {
    altenativeProductLinkClick(product);
    timeout(10).then(function () {
        pushState(getLinkUrl(product.url.toString()), { skipPartialCache: true });
    });
};
var onView = function (visible, product, selectedVariant) {
    if (!visible)
        return;
    var outOfStock = !!selectedVariant && !!selectedVariant.availability && selectedVariant.availability.availabilityCode == 'outOfStock';
    altenativeProductLinkImpression(product, outOfStock);
};
var ProductAlternativesLink = function (_a) {
    var _b;
    var product = _a.product, selectedVariant = _a.selectedVariant, isList = _a.isList, alternativeLink = _a.alternativeLink;
    if (!isVisible(selectedVariant, product)) {
        return (React.createElement("br", null));
    }
    var getImageUrl = function () {
        return !!alternativeLink && alternativeLink.imageUrl;
    };
    var getAmount = function () {
        return !!alternativeLink && alternativeLink.amount;
    };
    return !!getAmount() && (React.createElement("div", { className: styles.main },
        React.createElement(ViewTracker, { key: 'alternativeProductsLinkViewTracker', onView: function (visible) { return onView(visible, product, selectedVariant); } },
            React.createElement(React.Fragment, null,
                React.createElement("a", { "data-skip-partial-cache": true, className: styles.link, onClick: function () { return onLinkClick(product); } }, translate("/ProductListing/ProductAlternativesLinkText")),
                !!getImageUrl() && !!getAmount() &&
                    React.createElement("div", { className: classnames(styles.info, (_b = {}, _b[styles.isList] = isList, _b)), onClick: function () { return onLinkClick(product); } },
                        React.createElement(Image, { src: getImageUrl(), size: "tiny", maxSize: "tiny", itemProp: "image", fadeIn: true }),
                        React.createElement("div", { className: styles.counter }, getAmount()))))));
};
export default connect(function (state, ownProps) { return ({
    alternativeLink: !!state.product.alternativeProductLinks && !!state.product.alternativeProductLinks[ownProps.product.code] && state.product.alternativeProductLinks[ownProps.product.code]
}); })(ProductAlternativesLink);
