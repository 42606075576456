import React from 'react';
import connect from 'Shared/connect';
import { SMALL } from 'Shared/breakpoints';
import { Part } from 'Shared/PageLayout';
import Accordion from 'Shared/Accordion';
import Item from 'Shared/Accordion/Item';
import * as styles from './base.scss';
import * as headerstyles from 'SiteLayout/Footer/footer-header.scss';
var FooterNavigationLinksBlock = function (props) {
    return props.targetUrl &&
        (props.currentBreakpoint > SMALL ? (React.createElement(Part, { className: styles.categoryContainer },
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("div", { className: headerstyles.h4 },
                        React.createElement("a", { href: props.targetUrl }, props.title))),
                props.items.map(function (child) { return (React.createElement("li", { key: child.url },
                    React.createElement("a", { href: child.url }, child.name))); })))) : (React.createElement(Part, null,
            React.createElement(Accordion, null,
                React.createElement(Item, { label: props.title },
                    React.createElement(Part, { className: styles.categoryContainer },
                        React.createElement("ul", null, props.items.map(function (child) { return (React.createElement("li", { key: child.url },
                            React.createElement("a", { href: child.url }, child.name))); }))))))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(FooterNavigationLinksBlock);
