var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import clone from 'clone';
export default (function (facets, fieldName, facetValue, selected) {
    var newFacets = clone(facets);
    var facet = newFacets.find(function (f) { return f.fieldName === fieldName; });
    if (!facet) {
        return facets;
    }
    // The path contains an array of values where the last element is the current
    var path = findValuePath(facet.facetValues, facetValue.fieldName, facetValue.id);
    var current = path[path.length - 1];
    if (!current || current.selected === selected) {
        return facets;
    }
    current.selected = selected;
    setChildSelections(current.children, selected);
    if (!selected) {
        path.forEach(function (v) { return (v.selected = false); });
    }
    if (selected) {
        for (var i = path.length - 1; i >= 0; i--) {
            var value = path[i];
            if (value.children.every(function (c) { return c.selected; })) {
                value.selected = true;
            }
        }
    }
    return newFacets;
});
function findValuePath(values, fieldName, id, path) {
    if (path === void 0) { path = []; }
    var value = values.find(function (v) { return v.id === id && v.fieldName === fieldName; });
    if (value) {
        path.push(value);
        return path;
    }
    for (var i = 0; i < values.length; i++) {
        path = findValuePath(values[i].children, fieldName, id);
        if (path) {
            path.unshift(values[i]);
            return path;
        }
    }
}
export function allSelectedValues(values, selectedValues) {
    if (selectedValues === void 0) { selectedValues = []; }
    selectedValues.push.apply(selectedValues, __spread(values.filter(function (v) { return v.selected; })));
    values.forEach(function (v) { return allSelectedValues(v.children, selectedValues); });
    return selectedValues;
}
function setChildSelections(children, selected) {
    children.forEach(function (c) {
        c.selected = selected;
        setChildSelections(c.children, selected);
    });
}
