import { addCustomEventToDataLayer } from './index';
export function addDeliveryComplaintStartedNew(statedFrom) {
    var event = {
        event: 'delivery_complaint_started_new',
        statedFrom: statedFrom
    };
    addCustomEventToDataLayer(event);
}
export function addDeliveryComplaintReasonSelected(selectedComplaintReason) {
    var event = {
        event: 'delivery_complaint_reason_selected',
        selectedComplaintReason: selectedComplaintReason
    };
    addCustomEventToDataLayer(event);
}
export function addDeliveryComplaintFinalizingComplaint(succeeded, selectedComplaintReason) {
    var event = {
        event: 'delivery_complaint_finalizing_complaint',
        succeeded: succeeded,
        selectedComplaintReason: selectedComplaintReason
    };
    addCustomEventToDataLayer(event);
}
export function addInventoryCountStarted(pantryIds) {
    var event = {
        event: 'inventorycount_started',
        pantryIds: pantryIds
    };
    addCustomEventToDataLayer(event);
}
export function addInventoryCountCompleted(pantryIds) {
    var event = {
        event: 'inventorycount_completed',
        pantryIds: pantryIds
    };
    addCustomEventToDataLayer(event);
}
export function addInventoryCountReOpened(pantryIds) {
    var event = {
        event: 'inventorycount_reopened',
        pantryIds: pantryIds
    };
    addCustomEventToDataLayer(event);
}
