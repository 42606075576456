var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Icon from 'Shared/Icon';
import Slider from 'SiteLayout/Sliding/Slider';
import { pushState } from 'Shared/history';
import * as styles from 'SiteLayout/Sliding/base.scss';
import { toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import getMenuIcon from '../../../Category/Commerce/Components/action-creators';
var ProductsSlider = /** @class */ (function (_super) {
    __extends(ProductsSlider, _super);
    function ProductsSlider(props) {
        var _this = _super.call(this, props) || this;
        _this.back = function () {
            return _this.setState({
                animation: 'back',
                current: _this.state.prevs[_this.state.prevs.length - 1],
                prevs: _this.state.prevs.slice(0, _this.state.prevs.length - 1),
            }, function () { document.getElementById("MobileMenu").scrollTo(0, 0); });
        };
        _this.forward = function (current) {
            return _this.setState({
                animation: 'forward',
                current: current,
                prevs: _this.state.prevs.concat([_this.state.current]),
            });
        };
        _this.state = {
            animation: 'forward',
            prevs: [],
            current: {
                displayName: props.displayName,
                ticket: undefined,
                code: undefined,
                productCount: 0,
                url: '/',
                description: '',
                children: props.children,
            },
        };
        return _this;
    }
    ProductsSlider.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            this.state.prevs.length > 0 && (React.createElement("div", { className: styles.header },
                React.createElement("a", { onClick: function () { return _this.back(); } },
                    React.createElement(MyMenigoIcon, { name: "arrow" }),
                    this.state.current.displayName),
                React.createElement("a", { className: styles.close, onClick: this.props.toggleMainMenu },
                    React.createElement(Icon, { name: "close" })))),
            React.createElement(Slider, { direction: this.state.animation },
                React.createElement("div", { className: (this.state.animation == "forward" ? styles.slideIn : styles.slideOut), key: this.state.current.url },
                    this.state.prevs.length > 0 && (React.createElement("a", { onClick: function () { return (pushState(_this.state.current.url)); } },
                        React.createElement("div", { className: classnames(styles.primary, styles.menuLinks) },
                            React.createElement("span", null, translate("/MainMenu/CategoryShowAll", this.state.current.displayName)),
                            React.createElement(MyMenigoIcon, { name: "arrow" })))),
                    this.state.current.children.filter(function (i) { return !!i.displayName; }).map(function (item) {
                        var isParent = item.children && item.children.length > 0;
                        return (React.createElement("a", { key: item.url, onClick: function () { return (isParent ? _this.forward(item) : pushState(item.url)); } },
                            React.createElement("div", { className: classnames(styles.primary, styles.menuLinks) },
                                React.createElement("span", null,
                                    !!getMenuIcon(item, _this.props.categoryIcons) && React.createElement(MyMenigoIcon, { name: getMenuIcon(item, _this.props.categoryIcons) }),
                                    item.displayName),
                                React.createElement(MyMenigoIcon, { name: "arrow" }))));
                    })))));
    };
    return ProductsSlider;
}(React.Component));
export default connect(function (state) { return ({
    mainMenu: state.appShellData.mainMenu,
    categoryIcons: state.appShellData.categoryIcons
}); }, function (dispatch) { return ({
    toggleMainMenu: function () { return dispatch(toggleMainMenu()); },
}); })(ProductsSlider);
