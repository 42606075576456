import currentBreakpointReducer from 'SiteLayout/CurrentBreakpoint/reducer';
import currentPageReducer from 'SiteLayout/CurrentPage/reducer';
import checkoutPageReducer from '../Checkout/Pages/Checkout/reducer';
import miniCartReducer from 'SiteLayout/MiniCart/reducer';
import headerReducer from 'SiteLayout/Header/reducer';
import cartReducer from '../Cart/reducer';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import quickSearchReducer from '../Search/reducer';
import searchPageReducer from '../Search/Pages/Search/reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import feedbackBannerReducer from 'SiteLayout/GenericFeedbackBar/reducer';
import currentUserReducer from '../Account/reducer';
import appShellDataReducer from '../AppShell/reducer';
import deepFreeze from 'Shared/deep-freeze';
import accountBoxReducer from 'SiteLayout/Header/AccountBox/reducer';
import myMenigoBoxReducer from 'SiteLayout/Header/MyMenigoDropdown/reducer';
import takeStockReducer from '../TakeStock/reducer';
import appReducer from '../App/reducer';
import ipsReducer from '../Ips/reducer';
import pantriesFavoritesReducer from 'Shared/ListItem/reducer';
import shoppingListReducer from './../Recipes/reducer';
import startPageReducer from './../Start/Pages/Start/reducer';
import productReducer from 'Shared/Product/reducer';
import modalReducer from "Shared/Modal/reducer";
export default (function (state, action) {
    var appShellData = appShellDataReducer(state.appShellData, action);
    // We deep freeze the state to make sure we don't accidentally mutate it
    // Note that freezing objects is quite expensive, so we only do it in
    // dev builds
    var currentPageState = startPageReducer(searchPageReducer(currentPageReducer(state.currentPage, action, appShellData), action), action);
    return deepFreeze({
        appShellData: appShellData,
        checkout: checkoutPageReducer(currentPageState, state.checkout, action),
        currentBreakpoint: currentBreakpointReducer(state.currentBreakpoint, action, appShellData),
        currentPage: currentPageState,
        deliveryDaysVisibility: headerReducer(state.deliveryDaysVisibility, action),
        miniCart: miniCartReducer(state.miniCart, action),
        cart: cartReducer(state.cart, action, currentPageState, appShellData),
        mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
        quickSearch: quickSearchReducer(state.quickSearch, action, currentPageState, appShellData),
        footer: footerReducer(state.footer, action, appShellData),
        currentUser: currentUserReducer(state.currentUser, action, appShellData),
        accountBox: accountBoxReducer(state.accountBox, action),
        myMenigoBox: myMenigoBoxReducer(state.myMenigoBox, action),
        feedbackBanner: feedbackBannerReducer(state.feedbackBanner, action),
        takeStock: takeStockReducer(state.takeStock, action),
        app: appReducer(state.app, action),
        ips: ipsReducer(state.ips, action),
        pantriesFavorites: pantriesFavoritesReducer(state.pantriesFavorites, action),
        shoppingList: shoppingListReducer(state.shoppingList, action),
        product: productReducer(state.product, action),
        modalInput: modalReducer(state.modalInput, action)
    });
});
