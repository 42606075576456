import { get, set, clear } from './app-storage';
var storeName = 'login';
var valueName = 'token';
function getUtcNow(secondsToAdd) {
    var dt = new Date();
    return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds() + secondsToAdd);
}
function isValid(token) {
    return token && token.expires > getUtcNow(-(60 * 60 * 24 * 7));
}
var cachedToken = {
    token: null,
    expires: null,
};
export function saveToken(token, validity) {
    cachedToken = { token: token, expires: getUtcNow(validity) };
    set(storeName, valueName, cachedToken);
}
export function getToken() {
    return isValid(cachedToken) ? cachedToken.token : null;
}
export function isLoggedIn() {
    return !!getToken();
}
export function loadTokenFromStorage() {
    return get(storeName, valueName).then(function (token) {
        if (isValid(token)) {
            cachedToken = token;
        }
    });
}
export function logOut() {
    return clear(storeName);
}
