import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var Freight = function (props) {
    var freightList = props.list;
    var firstFreight = freightList.length > 0 ? freightList[0] : null;
    var minFreight;
    var maxFreight;
    var valFreight;
    if (firstFreight != null) {
        minFreight = firstFreight.limit;
        maxFreight = minFreight;
        valFreight = firstFreight.value;
    }
    return (React.createElement("div", { className: styles.freightContainer },
        React.createElement("h4", null, translate('/MyMenigo/DiscountFreightBlock/Freight')),
        React.createElement("p", null, translate('/MyMenigo/DiscountFreightBlock/FreightText')),
        React.createElement("div", { className: styles.discountItem },
            React.createElement("div", { className: styles.limit },
                translate('/MyMenigo/DiscountFreightBlock/UpTo'),
                " ",
                minFreight.toString(),
                " kr"),
            React.createElement("div", { className: styles.percent },
                valFreight.toString(),
                " kr")),
        freightList.map(function (freight, index) {
            var rendering;
            valFreight = freight.value;
            if (index !== 0) {
                if (freight.limit > 999998) {
                    rendering = (React.createElement("div", { key: index, className: styles.discountItem },
                        React.createElement("div", { className: styles.limit },
                            translate('/MyMenigo/DiscountFreightBlock/From'),
                            " ",
                            minFreight.toString(),
                            " kr}"),
                        React.createElement("div", { className: styles.percent },
                            valFreight.toString(),
                            " kr")));
                }
                else {
                    maxFreight = freight.limit - 1;
                    rendering = (React.createElement("div", { key: index, className: styles.discountItem },
                        React.createElement("div", { className: styles.limit },
                            minFreight.toString(),
                            " kr - ",
                            maxFreight.toString(),
                            " kr"),
                        React.createElement("div", { className: styles.percent },
                            valFreight.toString(),
                            " kr")));
                }
            }
            minFreight = freight.limit;
            return rendering;
        }),
        minFreight < 999999 && (React.createElement("div", { className: styles.discountItem },
            React.createElement("div", { className: styles.limit },
                translate('/MyMenigo/DiscountFreightBlock/From'),
                " ",
                minFreight.toString(),
                " kr"),
            React.createElement("div", { className: styles.percent }, translate('/MyMenigo/DiscountFreightBlock/FreightFree'))))));
};
export default Freight;
