var _a;
import React, { Fragment } from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import RecommendationsPanelComponent from './RecommendationsPanel/index';
import ProductCardContainer from 'Shared/ProductComponents/ProductCardContainer';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
import * as productCardListStyles from 'Shared/ProductCard/Fade/list.scss';
import * as productCardGridStyles from 'Shared/ProductCard/Fade/grid.scss';
import ProductCardItem from 'Shared/ProductCard/ProductRow2.0/ProductCardItem';
import { SMALL } from 'Shared/breakpoints';
import ListLargeAd from './Compact/LargeAd';
import GridLargeAd from './Boxes/LargeAd';
import CompactLargeAd from './Compact/LargeAd';
var listTypes = (_a = {},
    _a[viewLayoutTypes.list.name] = {
        wrapClassName: classnames(productCardListStyles.list, 'ui-test-layout-list'),
        ProductCardComponent: ProductCardItem,
        ProductHeaderComponent: null,
        ProductAdComponent: ListLargeAd,
        adSlotIndex: 2,
        recommendedListClassName: productCardListStyles.recommendationsPanelProductsList,
    },
    _a[viewLayoutTypes.grid.name] = {
        wrapClassName: classnames(productCardGridStyles.grid, 'ui-test-layout-boxes'),
        ProductCardComponent: ProductCardItem,
        ProductHeaderComponent: null,
        ProductAdComponent: GridLargeAd,
        adSlotIndex: 8,
        recommendedListClassName: productCardGridStyles.recommendationsPanelProductsListGallery,
    },
    _a[viewLayoutTypes.compact.name] = {
        wrapClassName: 'ui-test-layout-compact',
        ProductCardComponent: ProductCardItem,
        ProductHeaderComponent: null,
        ProductAdComponent: CompactLargeAd,
        adSlotIndex: 2,
        recommendedListClassName: productCardListStyles.recommendationsPanelProductsList,
    },
    _a);
var ProductCardList = function (props) {
    var passedClassName = props.className;
    var productList = props.products || [];
    var selectableProductIndexes = productList
        .map(function (product, index) { return (product.isDiscontinued ? -1 : index); })
        .filter(function (i) { return i !== -1; });
    var isMobile = props.currentBreakpoint <= SMALL;
    var _a = listTypes[isMobile ? viewLayoutTypes.grid.name : props.viewLayoutType], wrapClassName = _a.wrapClassName, ProductCardComponent = _a.ProductCardComponent, ProductHeaderComponent = _a.ProductHeaderComponent, ProductAdComponent = _a.ProductAdComponent, adSlotIndex = _a.adSlotIndex, recommendedListClassName = _a.recommendedListClassName;
    return (React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
        React.createElement("div", { className: classnames(wrapClassName, passedClassName) },
            !props.disableHeaderRow && ProductHeaderComponent && React.createElement(ProductHeaderComponent, null),
            productList.map(function (productListItem, index) { return (React.createElement(Fragment, { key: index },
                React.createElement(ProductCardContainer, { product: productListItem, index: index, cardHeight: props.staticCardHeight }, function (_a) {
                    var product = _a.product, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                    return (React.createElement(ProductCardComponent, { product: product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: (index > 2 && !!props.recommendationsPanel) ? (index + props.recommendationsPanel.products.length) : index, url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, temporary: props.temporary, cardType: props.viewLayoutType, searchTerm: props.searchTerm }));
                }),
                index === 2 && props.recommendationsPanel &&
                    React.createElement(RecommendationsPanelComponent, { recommendationsPanel: props.recommendationsPanel, recommendedListClassName: recommendedListClassName, viewLayoutType: props.viewLayoutType, staticCardHeight: props.staticCardHeight, temporary: props.temporary, startOnIndex: 3 }),
                index === 2 && props.largeAd && React.createElement(ProductAdComponent, { largeAd: props.largeAd }))); }))));
};
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
    currentBreakpoint: state.currentBreakpoint,
}); })(ProductCardList);
