var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import NewListModal from 'Shared/ListItem/NewListModal';
import * as styles from './printOptions.scss';
import PrintActions from './PrintActions';
import { DropDownListType } from '.';
import StartDialog from './StartDialog';
import CloseDialog from './CloseDialog';
var options = {};
var defaultName = 'default';
var feedbackMessages = {};
var noListSelectedSuffix = "NoListSelected";
var ActionOptions = /** @class */ (function (_super) {
    __extends(ActionOptions, _super);
    function ActionOptions(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentOption: '',
            optionTitle: translate('/Account/ProductList/ActionOptions/DropDown/Heading'),
            disabled: true,
            isModalOpen: false,
            startDialogVisible: false,
            closeDialogVisible: false
        };
        return _this;
    }
    ActionOptions.prototype.componentWillMount = function () {
        options[defaultName] = this.state.optionTitle;
        options['invent'] = translate('/Account/ProductList/ActionOptions/DropDown/PrintInvent');
        options['add'] = translate('/Account/ProductList/ActionOptions/DropDown/AddInvent');
        options['start'] = translate('/Account/ProductList/ActionOptions/DropDown/StartInvent');
        options['close'] = translate('/Account/ProductList/ActionOptions/DropDown/CloseInvent');
        feedbackMessages['start' + noListSelectedSuffix] = translate('/Account/ProductList/ActionOptions/FeebkackMessages/StartNoListSelected');
        feedbackMessages['start'] = translate('/Account/ProductList/ActionOptions/FeebkackMessages/Start');
        feedbackMessages['close' + noListSelectedSuffix] = translate('/Account/ProductList/ActionOptions/FeebkackMessages/CloseNoListSelected');
        feedbackMessages['close'] = translate('/Account/ProductList/ActionOptions/FeebkackMessages/Close');
    };
    ActionOptions.prototype.resetState = function () {
        this.changeSelect(defaultName);
    };
    ActionOptions.prototype.changeSelect = function (option) {
        this.props.handleDropDownSelect(DropDownListType.Actions);
        if (option !== defaultName && this.state.currentOption == option) {
            this.resetState();
            if (this.props.active)
                return;
        }
        this.setState({
            currentOption: option,
            optionTitle: options[option],
            disabled: false,
        });
    };
    ActionOptions.prototype.handleClose = function () {
        this.setOpen(false);
    };
    ActionOptions.prototype.setOpen = function (isOpen) {
        this.resetState();
        this.setState({
            isModalOpen: isOpen,
        });
    };
    ActionOptions.prototype.setValidationFeedback = function (visible, text) {
        this.props.showValidationFeedback(visible);
        this.props.setValidationFeedbackMessage(text);
    };
    ActionOptions.prototype.startInvent = function () {
        this.changeSelect("start");
        if (!!this.props.selectedLists.length && !!this.props.selectedLists.every(function (x) { return x.inventoryStatus != 'ongoing'; }))
            this.setStartDialogVisible(true);
        else {
            this.resetState();
            this.setValidationFeedback(true, this.getFeebackMessage("start"));
        }
    };
    ActionOptions.prototype.closeInvent = function () {
        this.changeSelect("close");
        if (!!this.props.selectedLists.length && !!this.props.selectedLists.every(function (x) { return x.inventoryStatus == 'ongoing'; }))
            this.setCloseDialogVisible(true);
        else {
            this.resetState();
            this.setValidationFeedback(true, this.getFeebackMessage("close"));
        }
    };
    ActionOptions.prototype.hasChoice = function () {
        return !!this.state.currentOption && this.state.currentOption != defaultName && this.props.active;
    };
    ActionOptions.prototype.setStartDialogVisible = function (isOpen) {
        var _this = this;
        this.setState({
            startDialogVisible: isOpen,
        }, function () { _this.resetState(); });
    };
    ActionOptions.prototype.setCloseDialogVisible = function (isOpen) {
        var _this = this;
        this.setState({
            closeDialogVisible: isOpen,
        }, function () { _this.resetState(); });
    };
    ActionOptions.prototype.getFeebackMessage = function (key) {
        return feedbackMessages[key + (!!this.props.selectedLists.filter(function (x) { return x.selected; }).length ? "" : noListSelectedSuffix)];
    };
    ActionOptions.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.printOptions },
            React.createElement("div", { className: styles.printOptionsInner },
                React.createElement("div", { className: styles.selectWrapper },
                    React.createElement("div", { className: styles.dropdownWrap },
                        React.createElement(DropDownMenu, { overrideMobile: true, title: this.props.active ? this.state.optionTitle : options[defaultName], className: styles.dropdownAction, isActive: this.hasChoice() },
                            React.createElement(DropDownItem, { key: "ActionOptionsDropDownStart", value: "start", text: translate('/Account/ProductList/ActionOptions/DropDown/StartInvent'), onClick: function (e) { return _this.startInvent(); } }),
                            React.createElement(DropDownItem, { key: "ActionOptionsDropDownClose", value: "close", text: translate('/Account/ProductList/ActionOptions/DropDown/CloseInvent'), onClick: function (e) { return _this.closeInvent(); } }),
                            React.createElement(DropDownItem, { key: "ActionOptionsDropDownPrint", value: "invent", text: translate('/Account/ProductList/ActionOptions/DropDown/PrintInvent'), onClick: function (e) { return _this.changeSelect('invent'); } }),
                            React.createElement(DropDownItem, { key: "ActionOptionsDropDownAdd", value: "add", text: translate('/Account/ProductList/ActionOptions/DropDown/AddInvent'), onClick: function (e) { _this.setOpen(true); _this.changeSelect('add'); } }))),
                    this.state.currentOption == 'invent' && React.createElement(PrintActions, { reportType: this.state.currentOption, visible: this.hasChoice() }))),
            React.createElement(NewListModal, { isPantry: true, isOpen: this.state.isModalOpen, handleClose: function () { _this.handleClose(); } }),
            React.createElement(StartDialog, { totalAmountOfPantries: this.props.totalAmountOfPantries, isOpen: this.state.startDialogVisible, closeModal: function () { _this.setStartDialogVisible(false); }, selectedLists: this.props.selectedLists }),
            React.createElement(CloseDialog, { totalAmountOfPantries: this.props.totalAmountOfPantries, isOpen: this.state.closeDialogVisible, closeModal: function () { _this.setCloseDialogVisible(false); }, selectedLists: this.props.selectedLists })));
    };
    return ActionOptions;
}(React.Component));
export default ActionOptions;
