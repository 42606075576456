var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Image from 'Shared/ImageFallback';
import * as styles from './base.scss';
var RawDescription = /** @class */ (function (_super) {
    __extends(RawDescription, _super);
    function RawDescription() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RawDescription.prototype.render = function () {
        return (React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.h3 }, this.props.title),
            !!this.props.imageUrls && this.props.imageUrls.length > 0 && React.createElement(Image, { src: this.props.imageUrls[0], size: "tiny" }),
            React.createElement("div", { className: styles.wrapper, dangerouslySetInnerHTML: { __html: this.props.content } }),
            !!this.props.cuttingDetail && !!this.props.animalType && this.props.animalType === 'N' && (React.createElement("div", { className: styles.cuttingDetail },
                React.createElement("a", { href: "/styles/images/styckningsschema-notkott.jpg" },
                    React.createElement("img", { width: "300", alt: "Styckningsschema n\u00F6tk\u00F6tt", src: "/styles/images/styckningsschema-notkott.jpg" }))))));
    };
    return RawDescription;
}(React.Component));
export default RawDescription;
