export var DISSMISS_FEEDBACK = 'DISSMISS_FEEDBACK';
export var SET_FEEDBACK = 'SET_FEEDBACK';
export function dismissFeedback() {
    return {
        type: DISSMISS_FEEDBACK,
    };
}
export function setFeedback(data) {
    return {
        type: SET_FEEDBACK,
        data: data,
    };
}
