import React from 'react';
import connect from 'Shared/connect';
import { MEDIUM } from 'Shared/breakpoints';
import classnames from 'classnames';
import * as styles from './base.scss';
import LinkWithAlcoholWarning from 'Shared/LinkWithAlcoholWarning';
var ImageContentCard = function (_a) {
    var className = _a.className, ticket = _a.ticket, isDesktop = _a.isDesktop, targetLink = _a.targetLink, imageUrl = _a.imageUrl, appearance = _a.appearance, containsAlcoholInfo = _a.containsAlcoholInfo, onClick = _a.onClick, title = _a.title;
    return (appearance !== 'large' || isDesktop) && (React.createElement("div", { className: classnames(styles.child, className, styles[appearance]) },
        React.createElement(LinkWithAlcoholWarning, { className: styles.href, "data-ticket": ticket, targetLink: targetLink, hasAlcoholWarning: containsAlcoholInfo, onClick: onClick, text: title },
            React.createElement("div", { className: styles.image, style: { backgroundImage: "url(" + imageUrl + ")" } }))));
};
export default connect(function (state) { return ({
    isDesktop: state.currentBreakpoint >= MEDIUM,
}); }, function (dispatch) { return ({}); })(ImageContentCard);
