import { getJson } from 'Shared/server';
export var SEARCH_RESULTS = 'SEARCH_RESULTS';
export var SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export var CLEAR_SEARCH_LIST = 'CLEAR_SEARCH_LIST';
export var DISPLAY_SEARCH_LIST = 'DISPLAY_SEARCH_LIST';
export var TOGGLE_CAMERA_SEARCHBAR = 'TOGGLE_CAMERA_SEARCHBAR';
export function searchItem(searchQuery, endpoint) {
    return function (dispatch, getState) {
        return getJson((endpoint || '') + "/queryproduct?q=" + searchQuery + "&p=0&ps=100")
            .then(function (json) {
            dispatch({
                type: SEARCH_RESULTS,
                results: json,
            });
        })
            .catch(function (e) {
            console.error(e);
            return Promise.reject(e);
        });
    };
}
export function toggleCamera(showCamera) {
    return {
        type: TOGGLE_CAMERA_SEARCHBAR,
        showCamera: showCamera,
    };
}
export function setSearchQuery(query) {
    return {
        type: SET_SEARCH_QUERY,
        query: query,
    };
}
export function clearSearchList() {
    return {
        type: CLEAR_SEARCH_LIST,
    };
}
export function displaySearchList(show) {
    return {
        type: DISPLAY_SEARCH_LIST,
        show: show,
    };
}
