export var getSizes = function () { return ({
    thumb: 60,
    tiny: 320,
    small: 640,
    medium: 960,
    large: 1280,
    huge: 2880,
}); };
export var getSizesEx = function () { return ({
    content: { h: 260, w: 440 },
    double: { h: 710, w: 440 },
    medium: { h: 430, w: 440 },
    large: { h: 260, w: 1015 },
    hero: { h: 540, w: 1360 },
    marketingAd: { h: 260, w: 550 }
}); };
