var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import Button from 'Shared/Button';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
export default function RemoveProduct(props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleConfirm = useCallback(function () {
        props.removeProductFromList();
        handleClose();
    }, []);
    return (React.createElement("div", { className: props.className },
        React.createElement(Button, { className: styles.btn, appearance: "bare", onClick: function () { return setOpen(true); } },
            React.createElement(MyMenigoIcon, { name: "trash" })),
        React.createElement(Modal, { title: translate('/Shared/Delete'), type: ModalType.Warning, isOpen: isOpen, onClose: handleClose, reserveSpaceForCloseButton: true, fadeDesign: false },
            React.createElement("p", { className: styles.modalText }, translate('/Account/ProductList/ConfirmRemoveProduct')),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleConfirm, onCancel: handleClose }))));
}
