import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import * as styles from './LoginLogoutButtons.scss';
import MyMenigoIcon from '../../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { logout } from '../../../../../../Account/action-creators';
import FeedbackButton from 'Shared/Button/Feedback';
var LogoutButton = function (_a) {
    var logout = _a.logout;
    return (React.createElement(FeedbackButton, { type: "link", onClickWithFeedback: function (e, activate) { return activate(logout()); }, className: styles.logout },
        translate('/Account/MyPages/Logout'),
        React.createElement(MyMenigoIcon, { name: "arrow" })));
};
export default connect(function (state) { return ({}); }, function (dispatch, ownProps) { return ({
    logout: function () { return dispatch(logout()).then(ownProps.close); },
}); })(LogoutButton);
