var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { createContext } from 'react';
import { currentOpenOverlay } from 'Shared/Overlay';
import { isBrowser } from 'Shared/device-type';
var listeners = {};
// This component is needed to bind events to the most outer
// component but still use Reacts event system. If we were to
// bind directly to `document` you won't be able to stop the
// propgation to those handlers from inside React, since React
// only binds one handler to `document`.
export var RootContext = createContext({ rootElementRef: null });
var Root = /** @class */ (function (_super) {
    __extends(Root, _super);
    function Root() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Root.prototype.componentDidMount = function () {
        this.forceUpdate();
    };
    Root.prototype.render = function () {
        var _this = this;
        return (React.createElement(RootContext.Provider, { value: { rootElementRef: this.elementRef } },
            React.createElement("div", { ref: function (el) { return (_this.elementRef = el); }, className: this.props.className, onClick: triggerEvent, onKeyDown: triggerEvent }, this.props.children)));
    };
    return Root;
}(React.Component));
export default Root;
export function on(event, callback) {
    if (!isBrowser()) {
        console.warn('Root.on should not be called on the server');
        return;
    }
    var events = event.split(' ');
    events.forEach(function (eventName) {
        if (!listeners[eventName]) {
            listeners[eventName] = [];
        }
        listeners[eventName].push(callback);
        handleWindowSubscription(eventName);
    });
    // We return a function here that you can call to unscribe to the event.
    // This instead of exporting an `off` function where you have to pass
    // the same function you passed to `on` which makes it impossible to
    // pass an inline function to `on`.
    return function () {
        return events.forEach(function (eventName) {
            listeners[eventName] = listeners[eventName].filter(function (listener) { return listener !== callback; });
            handleWindowSubscription(eventName);
        });
    };
}
export function scrollPosition(newPosition) {
    if (newPosition === void 0) { newPosition = undefined; }
    if (newPosition !== undefined) {
        document.body.scrollTop = newPosition;
        document.documentElement.scrollTop = newPosition;
        if (currentOpenOverlay) {
            // Because of iOS, we make the body element position: fixed while an overlay
            // is up so we need to notify the current overlay that we've updated the scroll
            currentOpenOverlay.setCurrentScrollPosition(newPosition);
        }
    }
    return Math.max(document.body.scrollTop, document.documentElement.scrollTop);
}
export function scrollTo(scrollTo, duration) {
    var startTime = Date.now();
    var endTime = startTime + duration;
    var startTop = scrollPosition();
    var distance = scrollTo - startTop;
    var smoothStep = function (start, end, point) {
        if (point <= start) {
            return 0;
        }
        if (point >= end) {
            return 1;
        }
        var x = (point - start) / (end - start);
        return x * x * (3 - 2 * x);
    };
    var onScroll = function (e) { return e.stopPropagation(); };
    return new Promise(function (resolve, reject) {
        var previousTop = scrollPosition();
        var scrollFrame = function () {
            if (scrollPosition() !== previousTop) {
                return resolve();
            }
            var now = Date.now();
            var point = smoothStep(startTime, endTime, now);
            var frameTop = Math.round(startTop + distance * point);
            scrollPosition(frameTop);
            if (now >= endTime) {
                return resolve();
            }
            if (scrollPosition() === previousTop && scrollPosition() !== frameTop) {
                return resolve();
            }
            previousTop = scrollPosition();
            requestAnimationFrame(scrollFrame);
        };
        // We don't let anyone else notice the scroll events because
        // we want a smooth animation
        window.addEventListener('scroll', onScroll, true);
        requestAnimationFrame(scrollFrame);
    }).then(function () {
        window.removeEventListener('scroll', onScroll, true);
    });
}
export function one(event, callback) {
    var unsubscribe = on(event, function (e) {
        callback(e);
        unsubscribe();
    });
}
function hasSubscribers(eventName) {
    return listeners[eventName] && listeners[eventName].length > 0;
}
export function triggerEvent(e) {
    if (!hasSubscribers(e.type)) {
        return;
    }
    listeners[e.type].forEach(function (listener) {
        listener(e);
    });
}
var throttling = [];
// Some events than can fire at a high rate and execute computationally
// expensive operations such as DOM modifications. Those events should
// be throttled, such as `scroll` and `resize`.
function throttleEvent(e) {
    if (!hasSubscribers(e.type) || throttling.indexOf(e.type) !== -1) {
        return;
    }
    throttling.push(e.type);
    window.requestAnimationFrame(function () {
        triggerEvent(e);
        throttling.splice(throttling.indexOf(e.type), 1);
    });
}
var windowEvents = {
    resize: throttleEvent,
    scroll: throttleEvent,
    // We need `touchmove` on the window because you can't swipe out
    // the drawer/mini cart
    touchmove: throttleEvent,
    touchstart: throttleEvent,
    touchend: throttleEvent,
};
var boundWindowEvents = [];
// Pass `window` events defined in `windowEvents` to this module. Should be
// invoked when `listeners` has been manipulated
function handleWindowSubscription(eventName) {
    if (windowEvents[eventName]) {
        if (hasSubscribers(eventName) && !boundWindowEvents.includes(eventName)) {
            window.addEventListener(eventName, windowEvents[eventName]);
            boundWindowEvents.push(eventName);
        }
        else if (!hasSubscribers(eventName) && boundWindowEvents.includes(eventName)) {
            window.removeEventListener(eventName, windowEvents[eventName]);
            boundWindowEvents.splice(boundWindowEvents.indexOf(eventName), 1);
        }
    }
}
