var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Part } from 'Shared/PageLayout';
import Toolbar from './Toolbar/';
import FavoriteList from './FavoriteList';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoBasicLayout from '../../../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout';
var Favorites = /** @class */ (function (_super) {
    __extends(Favorites, _super);
    function Favorites(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            activeItemKey: props.activeItemKey,
        };
        return _this;
    }
    Favorites.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return !shallowEquals(this.props, nextProps) || !shallowEquals(this.state, nextState);
    };
    Favorites.prototype.componentWillReceiveProps = function (nextProps, nextState) {
        if (this.props.activeItemKey !== nextProps.activeItemKey) {
            this.setState({ activeItemKey: nextProps.activeItemKey });
        }
    };
    Favorites.prototype.setActiveList = function (id) {
        this.setState({ activeItemKey: id });
    };
    Favorites.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null, (this.props.lists || []).map(function (listItem) { return (React.createElement(FavoriteList, __assign({ isActive: !!_this.state.activeItemKey ? listItem.id === _this.state.activeItemKey : false, setActiveList: function () { return _this.setActiveList(listItem.id); }, key: listItem.id, currentList: listItem.id, isRecommendations: false }, listItem, { isMaster: _this.props.isMaster }))); })));
    };
    return Favorites;
}(React.Component));
var Recommendations = /** @class */ (function (_super) {
    __extends(Recommendations, _super);
    function Recommendations(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            activeItemKey: null,
        };
        return _this;
    }
    Recommendations.prototype.setActiveList = function (id) {
        this.setState({ activeItemKey: id });
    };
    Recommendations.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null, (this.props.recommendations || []).map(function (listItem, i) { return (React.createElement(FavoriteList, __assign({ isActive: !!_this.state.activeItemKey ? listItem.id === _this.state.activeItemKey : false, setActiveList: function () { return _this.setActiveList(listItem.id); }, key: listItem.id, currentList: listItem.id, isRecommendations: true }, listItem, { isMaster: _this.props.isMaster }))); })));
    };
    return Recommendations;
}(React.Component));
var FavoritesOverviewPage = /** @class */ (function (_super) {
    __extends(FavoritesOverviewPage, _super);
    function FavoritesOverviewPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FavoritesOverviewPage.prototype.render = function () {
        var hasRecommendations = this.props.recommendations && this.props.recommendations.length > 0 && this.props.recommendations[0].count > 0;
        return (React.createElement(MyMenigoBasicLayout, { model: this.props },
            React.createElement(Part, null, hasRecommendations && React.createElement(Recommendations, __assign({}, this.props))),
            React.createElement(Part, null,
                React.createElement(Toolbar, __assign({}, this.props))),
            React.createElement(Part, null,
                React.createElement(Favorites, { lists: this.props.lists, recommendations: this.props.recommendations, isMaster: this.props.isMaster, activeItemKey: this.props.activeItemKey }))));
    };
    return FavoritesOverviewPage;
}(React.Component));
export default FavoritesOverviewPage;
