import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import { logout } from '../../action-creators';
import * as styles from './base.scss';
var LogoutBox = function (_a) {
    var logout = _a.logout;
    return (React.createElement("div", null,
        React.createElement(FeedbackButton, { type: "button", onClickWithFeedback: function (e, activate) { return activate(logout()); }, className: styles.logoutButton }, translate('/Account/MyPages/Logout'))));
};
export default connect(function (state) { return ({}); }, function (dispatch, ownProps) { return ({
    logout: function () { return dispatch(logout()).then(ownProps.close); },
}); })(LogoutBox);
