var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Checkbox from 'Shared/Fields/Checkbox';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import shallowEquals from 'Shared/shallow-equals';
import { list } from '../AutoComplete/base.scss';
import ConfirmRemoveSlave from './../ConfirmRemoveSlave';
var SlaveList = /** @class */ (function (_super) {
    __extends(SlaveList, _super);
    function SlaveList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selected: [],
            selectAll: false,
            nameSortAsc: 1,
            numberSortAsc: 1,
            sortValue: 'slaveCustomerId',
        };
        return _this;
    }
    SlaveList.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps) || !shallowEquals(this.state, nextState);
        if (!shouldUpdate) {
            console.info('Ignoring update to Masters');
        }
        return shouldUpdate;
    };
    SlaveList.prototype.toggleSelect = function (customerId) {
        var newState = Object.assign([], this.state.selected);
        var selectAll = false;
        if (newState.find(function (id) { return id === customerId; })) {
            var index = newState.indexOf(customerId);
            newState.splice(index, 1);
        }
        else {
            newState.push(customerId);
            selectAll = newState.length === list.length;
        }
        this.setState({ selected: newState, selectAll: selectAll });
    };
    SlaveList.prototype.toggleSelectAll = function () {
        var _this = this;
        this.setState({ selectAll: !this.state.selectAll }, function () {
            if (_this.state.selectAll) {
                var selectAll = _this.props.list.map(function (slave) { return slave.slaveCustomerId; });
                _this.setState({ selected: selectAll });
            }
            else {
                _this.setState({ selected: [] });
            }
        });
    };
    SlaveList.prototype.customerNumberSort = function () {
        var preSortValue = this.state.sortValue;
        this.setState({
            numberSortAsc: preSortValue === 'slaveCustomerId' ? -1 * this.state.numberSortAsc : 1,
            sortValue: 'slaveCustomerId',
        });
    };
    SlaveList.prototype.customerNameSort = function () {
        var preSortValue = this.state.sortValue;
        this.setState({
            nameSortAsc: preSortValue === 'name' ? -1 * this.state.nameSortAsc : 1,
            sortValue: 'name',
        });
    };
    SlaveList.prototype.sortList = function (a, b, sortValue, direction) {
        var one = a[sortValue], other = b[sortValue];
        if ((isNaN(one) ? one : parseInt(one, 10)) < (isNaN(other) ? other : parseInt(other, 10))) {
            return -direction;
        }
        else {
            return direction;
        }
    };
    SlaveList.prototype.render = function () {
        var _this = this;
        var list = this.props.list;
        var sortedList = list &&
            list.sort(function (a, b) {
                return _this.sortList(a, b, [_this.state.sortValue], _this.state.sortValue === 'name' ? _this.state.nameSortAsc : _this.state.numberSortAsc);
            });
        return (React.createElement("div", { className: styles.wrapper }, !!list && list.length > 0 && (React.createElement("div", null,
            React.createElement(ConfirmRemoveSlave, { single: false, slaves: this.state.selected, remove: function () { return _this.props.removeSelected(_this.state.selected); } }),
            React.createElement("table", { className: styles.list },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", null,
                            React.createElement(Checkbox, { checked: this.state.selectAll, onChange: function () { return _this.toggleSelectAll(); } })),
                        React.createElement("th", { className: this.state.sortValue === 'slaveCustomerId' &&
                                (this.state.numberSortAsc === 1 ? styles.arrowDown : styles.arrowUp), onClick: function () { return _this.customerNumberSort(); } }, translate('/Admin/MasterCustomers/CustomerNumber')),
                        React.createElement("th", { className: this.state.sortValue === 'name' &&
                                (this.state.nameSortAsc === 1 ? styles.arrowDown : styles.arrowUp), onClick: function () { return _this.customerNameSort(); } }, translate('/Admin/MasterCustomers/Name')),
                        React.createElement("th", null)),
                    !!list &&
                        list.length > 0 &&
                        sortedList.map(function (c) { return (React.createElement("tr", { key: c.slaveCustomerId },
                            React.createElement("td", null,
                                React.createElement(Checkbox, { checked: !!_this.state.selected.find(function (id) { return id === c.slaveCustomerId; }), onChange: function () { return _this.toggleSelect(c.slaveCustomerId); } })),
                            React.createElement("td", null, c.slaveCustomerId),
                            React.createElement("td", null, c.name),
                            React.createElement("td", { className: styles.right },
                                React.createElement(ConfirmRemoveSlave, { single: true, customerId: c.slaveCustomerId, remove: function () { return _this.props.remove(c.slaveCustomerId); } })))); })))))));
    };
    return SlaveList;
}(React.Component));
export default SlaveList;
