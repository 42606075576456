import { FETCH_CONTENT_CARD } from './action-creator';
import componentIsStart from './current-page-is-start';
export default (function (state, action) {
    if (componentIsStart(state)) {
        var startPage = state;
        switch (action.type) {
            case FETCH_CONTENT_CARD:
                return Object.assign({}, startPage, {
                    cards: action.payload.cards,
                });
            default:
                return startPage;
        }
    }
    return state;
});
