export var ClassificationMap = new Map([
    ['AC', 'ac'],
    ['BM', 'bm'],
    ['DE', 'de'],
    ['EK', 'ek'],
    ['EU', 'missing'],
    ['FAA', 'faa'],
    ['FT', 'ft'],
    ['GF', 'missing'],
    ['GM', 'missing'],
    ['GI', 'missing'],
    ['GT', 'file'],
    ['HL', 'missing'],
    ['K', 'k'],
    ['KS', 'ks'],
    ['LF', 'missing'],
    ['LL', 'missing'],
    ['MS', 'ms'],
    ['NH', 'nh'],
    ['RA', 'missing'],
    ['SE', 'se'],
    ['SF', 'missing'],
    ['SG', 'file'],
    ['SS', 'ss'],
    ['SU', 'su'],
    ['SV', 'sv'],
    ['UT', 'ut'],
    ['V', 'missing'],
    ['EB', 'eb'],
    ['SL', 'sl'],
]);
export var FileClassificationMap = new Map([
    ['GT', require('styles/images/icon-gt.svg')],
    ['SG', require('styles/images/icon-sg.svg')],
]);
