var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { SMALL, MEDIUM } from 'Shared/breakpoints';
import Logotype from 'Shared/Logotype';
import DesktopMenuButton from '../MainMenu/DesktopMenu/DesktopMenuButton';
import DeliveryDateInfo from './DeliveryDateInfo';
import MobileQuickSearch from '../../Search/QuickSearch/Mobile';
import QuickSearch from '../../Search/QuickSearch';
import HamburgerButton from './HamburgerButton';
import MiniCartButton from './MiniCartButton';
import classnames from 'classnames';
import * as styles from './base.scss';
var isMobile = function (currentBreakpoint) { return currentBreakpoint <= SMALL; };
var MiddleHeader = memo(function (props) {
    var _a;
    return (React.createElement("div", { className: styles.headerWrapperMiddle },
        React.createElement("div", { className: classnames(styles.layout, styles.space) },
            React.createElement("div", { className: styles.actionsHolder },
                React.createElement("div", { className: styles.logotypeWrapper },
                    React.createElement(Logotype, { className: styles.logotype, siteUrl: IS_APP ? '/' : props.mainMenu.siteUrl, breakpoints: props.currentBreakpoint })),
                !IS_APP && props.currentBreakpoint > SMALL && (React.createElement("div", { className: classnames(styles.mainMenuHolder, props.currentBreakpoint <= MEDIUM && styles.medium) },
                    React.createElement("nav", { className: classnames('ui-test-main-menu-nav', (_a = {},
                            _a[styles.hasSixOrMoreMenuItems] = props.mainMenu.rightContentLinks.length > 4,
                            _a)) },
                        React.createElement(DesktopMenuButton, null),
                        props.mainMenu.rightContentLinks.map(function (item, index) { return (React.createElement("a", { href: item.url, key: index },
                            React.createElement("div", { className: styles.secondaryMenuItem }, item.displayName))); }))))),
            React.createElement("div", { className: classnames(styles.actionsHolder, styles.actionsHolderRight) },
                !IS_APP &&
                    (isMobile(props.currentBreakpoint) ? (React.createElement(MobileQuickSearch, null)) : (React.createElement("div", null,
                        React.createElement(QuickSearch, null)))),
                !IS_APP && props.isLoggedIn && React.createElement(DeliveryDateInfo, null),
                !props.miniCartIsDisabled && React.createElement(MiniCartButton, null),
                !IS_APP && props.currentBreakpoint < MEDIUM && React.createElement(HamburgerButton, __assign({}, props))))));
});
export default MiddleHeader;
