export var SWITCH_BREAKPOINT = 'SWITCH_BREAKPOINT';
var breakpointCookieName = 'breakpoint';
export function switchBreakpoint(newBreakpoint) {
    document.cookie =
        breakpointCookieName + '=' + newBreakpoint + ';path=/;max-age=' + 60 * 60 * 24 * 365 + ';SameSite=None;secure';
    return {
        type: SWITCH_BREAKPOINT,
        newBreakpoint: newBreakpoint,
    };
}
