import React from 'react';
export default (function (text, textToHighlight) {
    var index = 0;
    var bold = function (text) {
        index++;
        return React.createElement("b", { key: text + index }, text);
    };
    var span = function (text) {
        index++;
        return React.createElement("span", { key: text + index }, text);
    };
    textToHighlight = textToHighlight.filter(function (t) { return t.length > 1; });
    if (!textToHighlight.length) {
        return text;
    }
    var regex = new RegExp('(' + textToHighlight.sort(function (a, b) { return b.length - a.length; }).join('|') + ')', 'ig');
    var parts = [];
    var lastIndex = 0;
    var match;
    do {
        match = regex.exec(text);
        if (match) {
            var beforeMatch = text.substr(lastIndex, regex.lastIndex - match[1].length - lastIndex);
            if (beforeMatch.length) {
                parts.push(span(beforeMatch));
            }
            parts.push(bold(match[1]));
            lastIndex = regex.lastIndex;
        }
    } while (match);
    parts.push(span(text.substr(lastIndex)));
    return parts;
});
