var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import * as styles from './base.scss';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoIcon from './../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
var DropDown = /** @class */ (function (_super) {
    __extends(DropDown, _super);
    function DropDown(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentValue: _this.props.value,
            showRequiredValidationMessage: false,
            currentText: _this.getCurrentText(_this.props.options, _this.props.value)
        };
        return _this;
    }
    DropDown.prototype.getCurrentText = function (options, value) {
        return !!options && !!options.find(function (x) { return x.value == value; }) && options.find(function (x) { return x.value == value; }).text || '';
    };
    DropDown.prototype.onFocus = function (e) {
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };
    DropDown.prototype.onInvalid = function (e) {
        this.setState({
            showRequiredValidationMessage: !!!this.state.currentValue
        });
    };
    DropDown.prototype.componentDidMount = function () {
        if (!!this.props.setRef) {
            this.props.setRef(this.input);
        }
    };
    DropDown.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(nextProps, this.props, true) || !shallowEquals(nextState, this.state, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to DropDown');
        }
        return shouldUpdate;
    };
    DropDown.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                currentValue: nextProps.value,
                currentText: this.getCurrentText(nextProps.options, nextProps.value),
                showRequiredValidationMessage: !!!nextProps.value
            });
        }
    };
    DropDown.prototype.componentDidUpdate = function (prevProps) {
        if (!!this.input && this.props.hasFocus) {
            this.input.focus();
        }
    };
    DropDown.prototype.onChange = function (e) {
        var val = e.target.value;
        var text = e.target.textContent;
        this.onChangeValue(val, text);
    };
    DropDown.prototype.onChangeValue = function (val, text) {
        var _this = this;
        this.setState({
            currentValue: val,
            showRequiredValidationMessage: !!!val,
            currentText: text
        }, function () {
            _this.props.onChange(val);
            _this.props.onClose && _this.props.onClose();
        });
    };
    DropDown.prototype.shouldShowValitationMessage = function () {
        return !!this.props.requiredvalidationmessage && !!this.props.required && !!this.state.showRequiredValidationMessage;
    };
    DropDown.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classnames(styles.base, this.props.className, (_a = {},
                    _a[styles.secondary] = this.props.appearance === 'secondary',
                    _a[styles.tertiary] = this.props.appearance === 'tertiary',
                    _a[styles.light] = this.props.appearance === 'light',
                    _a[styles.big] = this.props.appearance === 'big',
                    _a[styles.disabled] = this.props.disabled,
                    _a[styles.invalid] = !!this.shouldShowValitationMessage(),
                    _a)) },
                React.createElement("select", { ref: function (e) { return (_this.input = e); }, name: this.props.name, onChange: function (e) { return _this.onChange(e); }, onFocus: function (e) { return _this.onFocus(e); }, onInvalid: function (e) { return _this.onInvalid(e); }, value: !!this.props.hardValue ? this.props.value : this.state.currentValue, disabled: this.props.disabled, required: !!this.props.required, style: { display: 'none' } }, this.props.options.map(function (o, i) { return (React.createElement("option", { key: o.value + "-" + i, title: o.tooltip, disabled: o.disabled, value: o.value }, o.text)); })),
                React.createElement(DropDownMenu, { title: this.state.currentText, isInvalid: this.shouldShowValitationMessage(), onClose: function () { return _this.props.onClose && _this.props.onClose(); }, onOpen: function () { return _this.props.onOpen && _this.props.onOpen(); }, childCount: this.props.childCount }, this.props.options.filter(function (o) { return !!o.text; }).map(function (o, i) { return (React.createElement(DropDownItem, { key: "ddi-" + o.value + "-" + i, value: o.value, text: o.text, disabled: o.disabled, onClick: function () { _this.onChangeValue(o.value, o.text); } })); }))),
            this.shouldShowValitationMessage() && React.createElement("span", { className: styles.requiredField },
                React.createElement(MyMenigoIcon, { name: "alert" }),
                this.props.requiredvalidationmessage)));
    };
    return DropDown;
}(React.Component));
var FadeDropDown = /** @class */ (function (_super) {
    __extends(FadeDropDown, _super);
    function FadeDropDown(props) {
        var _this = _super.call(this, props) || this;
        _this.inputId = function () {
            return "dropdown-" + _this.props.options.map(function (o) { return o.text.charCodeAt(1); }).join("-");
        };
        _this.handleClickOutside = function (event) {
            if (_this.wrapperRef && !_this.wrapperRef.contains(event.target)) {
                var input = document.getElementById(_this.inputId());
                input.checked = false;
            }
        };
        _this.state = {
            currentValue: _this.props.value,
            showRequiredValidationMessage: false,
            currentText: ''
        };
        return _this;
    }
    FadeDropDown.prototype.componentDidMount = function () {
        document.addEventListener('mousedown', this.handleClickOutside);
    };
    FadeDropDown.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };
    FadeDropDown.prototype.onSortChange = function (val) {
        var _this = this;
        this.setState({
            currentValue: val,
        }, function () {
            _this.props.onChange(val);
        });
    };
    FadeDropDown.prototype.render = function () {
        var _this = this;
        var showNewLabel = ["carbon_footprint"];
        return (React.createElement("div", { ref: function (e) { return (_this.wrapperRef = e); }, className: classnames(styles.base, styles.fadeDropDown, this.props.className) },
            React.createElement("input", { style: { display: 'none' }, type: 'checkbox', id: this.inputId() }),
            React.createElement("ul", { className: styles.sortList }, this.props.options.map(function (o) {
                var isSelected = o.value === _this.state.currentValue;
                return (React.createElement("li", { key: o.value },
                    React.createElement(Button, { appearance: 'pale', className: styles.sortOptionButton, onClick: function () { return _this.onSortChange(o.value); }, disabled: o.disabled },
                        React.createElement("label", { htmlFor: _this.inputId() },
                            isSelected && (React.createElement("span", { className: styles.selectedSortOptionCheck },
                                React.createElement(Icon, { name: "check" }))),
                            o.subText ? (React.createElement(React.Fragment, null,
                                React.createElement("span", null, o.text),
                                React.createElement("span", null, o.subText))) : o.text,
                            showNewLabel.indexOf(o.value) > -1 && (React.createElement("div", { className: styles.label }, translate("/Shared/SortOptions/NewOptionMarking")))))));
            })),
            React.createElement("label", { htmlFor: this.inputId(), className: styles.selectedValue }, !!this.props.options.filter(function (o) { return o.value == _this.state.currentValue; })[0] && this.props.options.filter(function (o) { return o.value == _this.state.currentValue; })[0].text),
            React.createElement("span", null,
                React.createElement(MyMenigoIcon, { name: "sort" }))));
    };
    return FadeDropDown;
}(DropDown));
export { FadeDropDown };
export default DropDown;
