import { pantryStatus as storagePantryStatus, productsInPantry as storageProductsInPantry, restartInventory as storageRestartInventory, saveInventoryItem as storageSaveInventoryItem, findModifiedInventoryItems as storageFindModifiedInventoryItems, remapCustomProductIds as storageRemapCustomProductIds, } from './app-websql-storage';
export function restartInventory(pantries) {
    return storageRestartInventory(pantries);
}
export function clearInventory() {
    return restartInventory([]);
}
export function findModifiedInventoryItems() {
    return storageFindModifiedInventoryItems();
}
export function pantryStatus() {
    var storagePantryStatusPromise = storagePantryStatus();
    storagePantryStatusPromise.catch(function (e) { return console.error('Error searching in app storage', e); });
    return storagePantryStatusPromise;
}
export function productsInPantry(pantryId) {
    return storageProductsInPantry(pantryId);
}
export function saveInventoryItem(pantryId, item) {
    return storageSaveInventoryItem(pantryId, item);
}
export function remapCustomProductIds(ids) {
    // This function is required because the backend (due to its use of NHibernate) will create custom products with ids that differ
    // from what the frontend sends
    return storageRemapCustomProductIds(ids);
}
