var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import classnames from 'classnames';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
var InspirationImageBlock = /** @class */ (function (_super) {
    __extends(InspirationImageBlock, _super);
    function InspirationImageBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InspirationImageBlock.prototype.render = function () {
        var _a;
        return (React.createElement("div", { className: classnames(styles.base) },
            !!propertyValueFor(this.props.block.image) &&
                React.createElement("div", { className: classnames(styles.aspectRatio, (_a = {}, _a[styles.doubleImage] = !!propertyValueFor(this.props.block.secondImage), _a)) },
                    React.createElement("div", { className: styles.inner, style: { backgroundImage: 'url(' + propertyValueFor(this.props.block.image) + ')' } },
                        React.createElement("img", { src: propertyValueFor(this.props.block.image), alt: propertyValueFor(this.props.block.imageText) }))),
            !!propertyValueFor(this.props.block.secondImage) &&
                React.createElement("div", { className: classnames(styles.aspectRatio, styles.doubleImage) },
                    React.createElement("div", { className: styles.inner, style: { backgroundImage: 'url(' + propertyValueFor(this.props.block.secondImage) + ')' } },
                        React.createElement("img", { src: propertyValueFor(this.props.block.secondImage), alt: propertyValueFor(this.props.block.imageText) }))),
            !!this.props.block.imageText && React.createElement(PropertyFor, { tagName: 'p', className: styles.imageText, property: this.props.block.imageText }),
            !!this.props.block.imageCredits && React.createElement(PropertyFor, { tagName: 'p', className: styles.imageCredits, property: this.props.block.imageCredits })));
    };
    return InspirationImageBlock;
}(React.Component));
export default InspirationImageBlock;
