import React from 'react';
import * as styles from './base.scss';
import { pushState } from 'Shared/history';
import { updateODPTrackingSearchResultProductClick, addEcommerceEventToDataLayer } from './../../../TrackingInformation';
import connect from 'Shared/connect';
import { selectVariant } from 'Shared/Product/action-creators';
import propertyValueFor from 'Shared/property-value-for';
import ProductPreview from '../ProductPreview';
var ProductLink = function (_a) {
    var linkUrl = _a.linkUrl, ticket = _a.ticket, children = _a.children, title = _a.title, searchTerm = _a.searchTerm, code = _a.code, selectItemEventList = _a.selectItemEventList, products = _a.products, pageType = _a.pageType, selectVariant = _a.selectVariant, product = _a.product, openPreviewInModal = _a.openPreviewInModal, selectProudctVariant = _a.selectProudctVariant, cartSelectItemEventList = _a.cartSelectItemEventList, cart = _a.cart, minicartIsOpen = _a.minicartIsOpen;
    if (!linkUrl) {
        return React.createElement(React.Fragment, null, children);
    }
    var getSelectedVariants = function (code) {
        var articleNumber = code.split('_')[0];
        var cartProducts = !!cart && !!cart.items && cart.items.map(function (i) {
            return i.product;
        });
        var allProducts = [];
        if (!!cartProducts && !!products) {
            allProducts = products.concat(cartProducts);
        }
        else if (!!cartProducts) {
            allProducts = cartProducts;
        }
        else if (!!products) {
            allProducts = products;
        }
        var product = !!allProducts && allProducts.find(function (x) { return propertyValueFor(x.content.code) === articleNumber; });
        return !!product && product.variants;
    };
    var setGa4ItemListReferrel = function (ga4ItemListReferrel) {
        !!sessionStorage && sessionStorage.setItem('ga4ItemListReferrel', ga4ItemListReferrel);
    };
    var sendGa4Event = function (code) {
        var ga4selectEvent;
        if (!!cartSelectItemEventList && code in cartSelectItemEventList && minicartIsOpen) {
            ga4selectEvent = cartSelectItemEventList[code];
        }
        else {
            ga4selectEvent = !!selectItemEventList && code in selectItemEventList && selectItemEventList[code];
        }
        !!ga4selectEvent && !!ga4selectEvent.ecommerce.item_list_id && setGa4ItemListReferrel(ga4selectEvent.ecommerce.item_list_id);
        !!ga4selectEvent && addEcommerceEventToDataLayer(ga4selectEvent);
    };
    var linkTo = function (url, code) {
        !!searchTerm && updateODPTrackingSearchResultProductClick(searchTerm, window.location.href);
        sendGa4Event(code);
        var variants = getSelectedVariants(code);
        !!code && !!variants && selectVariant(code, variants);
        pushState(url);
    };
    return (!!openPreviewInModal && !!product && !!selectProudctVariant ? (React.createElement(ProductPreview, { product: product, selectedVariant: selectProudctVariant }, children)) :
        React.createElement("a", { onClick: function () { return linkTo(linkUrl, code); }, "data-ticket": ticket, className: styles.link, title: title }, children));
};
export default connect(function (state) { return ({
    selectItemEventList: state.currentPage && state.currentPage.trackingData && state.currentPage.trackingData.ga4EventObjects && state.currentPage.trackingData.ga4EventObjects['select_item'],
    cartSelectItemEventList: state.cart && state.cart.trackingData && state.cart.trackingData.ga4EventObjects && state.cart.trackingData.ga4EventObjects['select_item'],
    cart: state.cart,
    minicartIsOpen: !!state.miniCart && state.miniCart.isOpen,
    products: !!state.currentPage && !!state.currentPage.products && state.currentPage.products.products,
    pageType: !!state.currentPage && state.currentPage.componentName
}); }, function (dispatch) { return ({
    selectVariant: function (code, variants) { return dispatch(selectVariant(code, variants)); },
}); })(ProductLink);
