import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import * as styles from './base.scss';
var PageHeader = function (props) {
    return (React.createElement("div", { className: styles.pageHeader },
        React.createElement("div", { className: styles.inner },
            React.createElement(PropertyFor, { className: styles.pageHeaderName, tagName: 'h1', property: props.headerProperty }),
            React.createElement(PropertyFor, { tagName: 'p', property: props.textProperty }))));
};
export default PageHeader;
