var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import propertyValueFor from 'Shared/property-value-for';
import Main, { Section } from 'Shared/PageLayout';
import Breadcrumbs from 'Shared/Breadcrumbs';
import SliderBanner from 'Shared/SliderBanner';
import MyPagesMenu from './../../../MyMenigo/Shared/MyPagesMenu';
import OldCampaignOverviewBlock from '../../../Cms/Blocks/OldCampaignOverview/OldCampaignOverviewBlock';
import CampaignOverviewBlock from '../../../Cms/Blocks/CampaignOverview/CampaignOverviewBlock';
import * as styles from './base.scss';
var defaultCampaignBlock = 'Cms.Blocks.OldCampaignOverview.OldCampaignOverviewBlock';
var newCampaignBlock = 'Cms.Blocks.CampaignOverview.CampaignOverviewBlock';
var CampaignOverviewPage = function (props) {
    var heroCards = props.heroCards, breadcrumbs = props.breadcrumbs, myMenigoMenu = props.myMenigoMenu, currentBreakpoint = props.currentBreakpoint, page = props.page;
    var imageUrls = !!heroCards ? heroCards.map(function (x) { return x.targetLink.url; }) : [];
    var blockContents = propertyValueFor(page.campaignOverviewBlockContent).items;
    var defaultBlockData = blockContents.find(function (i) { return i.componentName === defaultCampaignBlock; });
    var newBlockData = blockContents.find(function (i) { return i.componentName === newCampaignBlock; });
    return (React.createElement(Main, { style: { marginTop: 0 }, appearance: "narrow" },
        !!defaultBlockData && React.createElement("div", { className: styles.breadcrumbsWrap },
            React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs })),
        !!defaultBlockData && React.createElement(Section, null,
            React.createElement(MyPagesMenu, { menuItems: myMenigoMenu.menuItems, name: myMenigoMenu.name })),
        !!heroCards && (heroCards.length > 0) && (React.createElement(SliderBanner, { className: styles.banner, heroCards: heroCards, imageUrls: imageUrls, currentBreakpoint: currentBreakpoint })),
        defaultBlockData ? (React.createElement(OldCampaignOverviewBlock, __assign({ key: defaultBlockData.componentName }, defaultBlockData))) : newBlockData ? (React.createElement(CampaignOverviewBlock, __assign({ key: newBlockData.componentName }, props))) : (React.createElement("p", null, translate('/Campaign/NoCampaignsFound')))));
};
export default CampaignOverviewPage;
