import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import ContentArea from './ContentArea';
export default (function (props) {
    if (window.IS_IN_EDIT_MODE && props.propertyName) {
        return ContentArea(props);
    }
    else {
        return React.createElement(props.tagName || 'div', { className: props.className }, props.items &&
            props.items.map(function (i, idx) {
                return React.createElement("img", { key: idx, src: propertyValueFor(i.block.imageUrl) });
            }));
    }
});
