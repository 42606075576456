var db;
function toArray(rows) {
    var len = rows.length;
    var items = [];
    for (var i = 0; i < len; i++) {
        items.push(rows.item(i));
    }
    return items;
}
function retry(resolve) {
    if (db) {
        resolve(db);
    }
    else if (window['sqlitePlugin']) {
        db = window['sqlitePlugin'].openDatabase({ name: 'appdb', iosDatabaseLocation: 'Library' });
        init(db).then(function () { return resolve(db); });
    }
    else {
        setTimeout(retry, 1000);
    }
}
function getStorage() {
    return new Promise(function (resolve, reject) {
        if (db) {
            resolve(db);
            return;
        }
        if (window['cordova'].platformId === 'browser') {
            db = window.openDatabase('appdb', '1.0', 'appdb', 20 * 1024 * 1024); // 20MB
            init(db).then(function () { return resolve(db); });
        }
        else {
            retry(resolve);
        }
    });
}
function init(db) {
    return executeSqlWithDb(db, [
        "CREATE TABLE IF NOT EXISTS assortment(\n       productId text primary key,\n       displayName text,\n       trademark text,\n       unitOptions object,\n       eans text,\n       isCustomProduct boolean,\n       displayProductId text,\n       isWeightProduct boolean,\n       supplierArtNo text,\n       isCampaign boolean,\n       isNew boolean)",
        "CREATE TABLE IF NOT EXISTS inventory(\n       productId text,\n       pantryId text,\n       unitShortName text,\n       quantity number,\n       isModified boolean,\n       primary key(productId, pantryId))",
        'CREATE TABLE IF NOT EXISTS pantry(id text primary key, displayName text)',
    ]);
}
function executeSql(sql, val) {
    return getStorage().then(function (db) { return executeSqlWithDb(db, sql, val); });
}
function executeSqlWithDb(db, sql, val) {
    val = val || (sql instanceof Array ? sql.map(function (x) { return []; }) : [[]]);
    return new Promise(function (resolve, reject) {
        var successful = 0;
        var result = [];
        function success(index, data) {
            successful++;
            result[index] = toArray(data);
            if (successful === val.length) {
                resolve(result);
            }
        }
        db.transaction(function (t) {
            var _loop_1 = function (i) {
                var currentSql = sql instanceof Array ? sql[i] : sql;
                var index = i;
                t.executeSql(currentSql, val[index], function (_, rs) { return success(index, rs.rows); }, function (transaction, error) {
                    reject({ message: error.message, sql: sql, val: val });
                    return true;
                });
            };
            for (var i = 0; i < val.length; i++) {
                _loop_1(i);
            }
        });
    });
}
export function clearCustomAssortment() {
    return executeSql("DELETE FROM assortment WHERE isCustomProduct='true'");
}
export function clearStandardAssortment() {
    return executeSql("DELETE FROM assortment WHERE isCustomProduct='false'");
}
export function insertProducts(products) {
    return insertProductsInternal(products, false);
}
export function addCustomAssortmentProduct(product) {
    if (!product.isCustomProduct) {
        throw new Error('Product must be custom');
    }
    return insertProductsInternal([product], true);
}
export function findNewCustomProducts() {
    return executeSql("SELECT * FROM assortment WHERE isNew = 'true' AND isCustomProduct = 'true'").then(function (r) {
        return fixProducts(r[0]);
    });
}
var labelId = function (query) { return (/^\d{8}$/.test(query) ? "%" + query.substr(1, 6) + "%" : ''); };
export function searchAssortment(query, limit, pantryId) {
    var label = labelId(query);
    TODO('Sort based on pantryId');
    return executeSql("SELECT * FROM assortment\n                      WHERE eans LIKE ?1\n                      OR displayProductId LIKE ?1\n                      OR displayProductId LIKE ?2\n                      OR displayName LIKE ?1\n                      OR supplierArtNo LIKE ?1\n                      LIMIT ?3", [["%" + query + "%", label, limit]]).then(function (r) { return fixProducts(r[0]); });
}
function insertProductsInternal(products, isNew) {
    if (products.length === 0) {
        return Promise.resolve({});
    }
    return executeSql("INSERT INTO assortment(\n                       productId,\n                       displayName,\n                       trademark,\n                       unitOptions,\n                       eans,\n                       isCustomProduct,\n                       displayProductId,\n                       isWeightProduct,\n                       supplierArtNo,\n                       isCampaign,\n                       isNew\n                     ) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)", products.map(function (p) { return [
        p.productId,
        p.displayName,
        p.trademark,
        JSON.stringify(p.unitOptions),
        p.eans ? p.eans.join(',') : '',
        p.isCustomProduct,
        p.displayProductId,
        p.isWeightProduct,
        p.supplierArtNo,
        p.isCampaign,
        isNew,
    ]; }));
}
export function pantryStatus() {
    var countLeft = "(SELECT count(*) FROM inventory WHERE pantryId = p.id AND isModified IS NOT 'true') as leftCount, ";
    var countAll = '(SELECT count(*) FROM inventory WHERE pantryId = p.id) as totalCount ';
    return executeSql('SELECT *, ' + countLeft + countAll + ' FROM pantry p').then(function (rows) { return rows[0]; });
}
export function productsInPantry(pantryId) {
    return executeSql("SELECT a.*, i.quantity, i.unitShortName\n                       FROM assortment a JOIN inventory i ON a.productId = i.productId\n                      WHERE i.pantryId = ?", [[pantryId]]).then(function (r) { return fixProducts(r[0]); });
}
export function restartInventory(pantries) {
    var sql = ['DELETE FROM inventory', 'DELETE FROM pantry'];
    var val = [[], []];
    for (var i = 0; i < pantries.length; i++) {
        sql.push('INSERT INTO pantry(id, displayName) VALUES(?, ?)');
        val.push([pantries[i].id, pantries[i].displayName]);
        for (var j = 0; j < pantries[i].items.length; j++) {
            sql.push("INSERT INTO inventory(productId, pantryId, unitShortName, quantity, isModified) VALUES(?, ?, ?, ?, 'false')");
            val.push([
                pantries[i].items[j].productId,
                pantries[i].id,
                pantries[i].items[j].unitShortName,
                pantries[i].items[j].quantity,
            ]);
        }
    }
    return executeSql(sql, val);
}
export function saveInventoryItem(pantryId, item) {
    return executeSql("INSERT OR REPLACE INTO inventory(productId, pantryId, unitShortName, quantity, isModified) VALUES(?, ?, ?, ?, 'true')", [[item.productId, pantryId, item.unitShortName, item.quantity]]);
}
export function findModifiedInventoryItems() {
    return executeSql("SELECT * FROM inventory WHERE isModified IS 'true'").then(function (r) { return fixInventoryRows(r[0]); });
}
export function remapCustomProductIds(ids) {
    var keys = Object.keys(ids);
    if (keys.length) {
        var args_1 = keys.map(function (k) { return [k, ids[k]]; });
        return executeSql('UPDATE assortment SET productId = ?2 WHERE productId = ?1', args_1).then(function () {
            return executeSql('UPDATE inventory SET productId = ?2 WHERE productId = ?1', args_1);
        });
    }
    else {
        return Promise.resolve();
    }
}
function fixInventoryRows(rows) {
    for (var i = 0; i < rows.length; i++) {
        delete rows[i].isModified;
    }
    return rows;
}
function fixProducts(rows) {
    for (var i = 0; i < rows.length; i++) {
        rows[i].eans = rows[i].eans ? rows[i].eans.split(',') : [];
        rows[i].unitOptions = JSON.parse(rows[i].unitOptions);
        rows[i].isCustomProduct = rows[i].isCustomProduct === 'true';
        rows[i].isWeightProduct = rows[i].isWeightProduct === 'true';
        rows[i].isCampaign = rows[i].isCampaign === 'true';
        rows[i].quantity = rows[i].quantity !== undefined ? rows[i].quantity : null;
        rows[i].unitShortName =
            rows[i].unitShortName !== undefined ? rows[i].unitShortName : getUnitShortName(rows[i].unitOptions);
        delete rows[i].isNew;
    }
    return rows;
}
function getUnitShortName(unitOptions) {
    var unitShortName = 'FRP';
    if (unitOptions.filter(function (item) { return item.unitShortName.toLocaleLowerCase().includes('st'); }).length > 0) {
        unitShortName = 'ST';
    }
    else if (unitOptions.filter(function (item) { return item.unitShortName.toLocaleLowerCase().includes('kg'); }).length > 0) {
        unitShortName = 'KG';
    }
    return unitShortName;
}
