var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var ProductInformationRaw = /** @class */ (function (_super) {
    __extends(ProductInformationRaw, _super);
    function ProductInformationRaw(props) {
        return _super.call(this, props) || this;
    }
    ProductInformationRaw.prototype.render = function () {
        return (React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.h3 }, translate('/Product/ProductRawInformation/ProductInfo')),
            !!this.props.type && this.props.type === 'K' && !!this.props.usage && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Usage')),
                React.createElement("p", null, this.props.usage))),
            !!this.props.countries && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Countries')),
                React.createElement("p", null, this.props.countries))),
            !!this.props.diaryType && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/DiaryType')),
                React.createElement("p", null, this.props.diaryType))),
            !!this.props.milkType && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/MilkType')),
                React.createElement("p", null, this.props.milkType))),
            !!this.props.pasteurization && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Pasteurization')),
                React.createElement("p", null, this.props.pasteurization))),
            !!this.props.ripening && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Ripening')),
                React.createElement("p", null, this.props.ripening))),
            !!this.props.processing && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Processing')),
                React.createElement("p", null, this.props.processing))),
            !!this.props.group && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Group')),
                React.createElement("p", null, this.props.group))),
            !!this.props.temperature && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Temperature')),
                React.createElement("p", null, this.props.temperature))),
            !!this.props.fat && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Fat')),
                React.createElement("p", null, this.props.fat))),
            !!this.props.storage && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Storage')),
                React.createElement("p", null, this.props.storage))),
            !!this.props.handlingAdvice && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/HandlingAdvice')),
                React.createElement("p", null, this.props.handlingAdvice))),
            !!this.props.breed && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Breed')),
                React.createElement("p", null, this.props.breed))),
            !!this.props.mottling && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Mottling')),
                React.createElement("p", null, this.props.mottling))),
            !!this.props.english && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/English')),
                React.createElement("p", null, this.props.english))),
            !!this.props.usage && !!this.props.type && this.props.type !== 'K' && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Usage')),
                React.createElement("p", null, this.props.usage))),
            !!this.props.allergens && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/Allergens')),
                React.createElement("p", null, this.props.allergens))),
            !!this.props.nerdInfo && (React.createElement("span", null,
                React.createElement("b", null, translate('/Product/ProductRawInformation/NerdInfo')),
                React.createElement("p", null, this.props.nerdInfo)))));
    };
    return ProductInformationRaw;
}(React.Component));
export default ProductInformationRaw;
