var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { timeout } from 'Shared/timeout';
import { translate } from 'Shared/translate';
import { removeListRequest, removeListInCurrentpage } from '../../../action-creators';
var RemoveList = function (props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleRemoveRequest = useCallback(function () { return props.removeListRequest(); }, []);
    var onDialogSucessfull = useCallback(function () {
        handleClose();
        // wait for modal to close
        timeout(200).then(function () { return props.removeListInCurrentpage(); });
    }, []);
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, className: props.className },
        React.createElement(Button, { appearance: 'clear', onClick: function () { return setOpen(true); } },
            React.createElement(Icon, { name: "remove" })),
        React.createElement(Modal, { title: translate('/RecipeMenu/HeaderConfirmRemoveMenu'), type: ModalType.Warning, isOpen: isOpen, onClose: handleClose, reserveSpaceForCloseButton: true },
            React.createElement("span", null, translate('/RecipeMenu/ConfirmRemoveMenu', props.name)),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleRemoveRequest, onCancel: handleClose, onStatusWasFulfilled: onDialogSucessfull }))));
};
export default connect(function (state) { return ({}); }, function (dispatch, _a) {
    var listId = _a.listId;
    return ({
        removeListRequest: function () { return dispatch(removeListRequest(listId)); },
        removeListInCurrentpage: function () { return dispatch(removeListInCurrentpage(listId)); },
    });
})(RemoveList);
