export default function flatten(entry, list) {
    if (list === void 0) { list = []; }
    if (typeof entry === 'string') {
        list.indexOf(entry) === -1 && list.push(entry);
    }
    else if (Array.isArray(entry)) {
        entry.forEach(function (entry) { return flatten(entry, list); });
    }
    else if (typeof entry === 'object') {
        for (var name in entry) {
            if (entry.hasOwnProperty(name) && entry[name]) {
                list.indexOf(name) === -1 && list.push(name);
            }
        }
    }
    return list;
}
