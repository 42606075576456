var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import { Input, Checkbox, Textarea } from 'Shared/Fields';
import Button from 'Shared/Button';
import * as styles from './base.scss';
import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var CustomerProfileBlock = /** @class */ (function (_super) {
    __extends(CustomerProfileBlock, _super);
    function CustomerProfileBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.validateEmail = function (email) {
            var model = {
                success: !!!email || _this.IsValidEmail(email),
                message: translate('/MyMenigo/CustomerProfileBlock/InvalidEmail'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validatePhone = function (phone) {
            var model = {
                success: _this.IsValidPhoneNumber(phone),
                message: translate('/MyMenigo/CustomerProfileBlock/InvalidFormatPhoneNumber'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.validateMobilePhone = function (phone) {
            var model = {
                success: _this.IsValidPhoneNumber(phone),
                message: translate('/MyMenigo/CustomerProfileBlock/InvalidFormatMobileNumber'),
                value: ''
            };
            return Promise.resolve(model);
        };
        _this.state = {
            email: _this.props.email,
            firstName: _this.props.firstName,
            lastName: _this.props.lastName,
            companyName: _this.props.companyName,
            phoneNumber: _this.props.phoneNumber,
            mobileNumber: _this.props.mobileNumber,
            newPassword: _this.props.newPassword || '',
            confirmPassword: _this.props.confirmPassword || '',
            defaultSortOrder: _this.props.defaultSortOrder,
            orderCopyEmail: _this.props.orderCopyEmail,
            warningWeightLimit: _this.props.warningWeightLimit,
            warningPackageLimit: _this.props.warningPackageLimit,
            warningPieceLimit: _this.props.warningPieceLimit,
            showPriceAsSmallestUnitPrice: _this.props.showPriceAsSmallestUnitPrice,
            hasError: _this.props.hasError,
            orderReferenceId: _this.props.orderReferenceId,
            editOrderReferenceIdEnabled: _this.props.editOrderReferenceIdEnabled
        };
        return _this;
    }
    CustomerProfileBlock.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState(this.props);
        }
    };
    CustomerProfileBlock.prototype.IsValidPhoneNumber = function (p) {
        if ((p || '').trim() === '') {
            return true;
        }
        var phoneno = /^[\+]?([0-9]|\-|\s)+$/;
        if (p.match(phoneno)) {
            return true;
        }
        return false;
    };
    CustomerProfileBlock.prototype.AreAllEmailsValid = function () {
        var _this = this;
        var message = translate('/MyMenigo/CustomerProfileBlock/InvalidEmails');
        var isValid = true;
        this.state.orderCopyEmail.split(/[,;\n]/).forEach(function (email) {
            if (email.length > 0 && !_this.IsValidEmail(email.trim())) {
                message += ' ' + email;
                isValid = false;
            }
        });
        return !isValid && React.createElement("span", { className: styles.requiredField },
            React.createElement(MyMenigoIcon, { name: "alert" }),
            message);
    };
    CustomerProfileBlock.prototype.IsValidEmail = function (email) {
        // tslint:disable-next-line:max-line-length
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    CustomerProfileBlock.prototype.guid = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    CustomerProfileBlock.prototype.onSubmit = function (e) {
        var _this = this;
        e.preventDefault();
        var hasError = false;
        if ((this.state.firstName || '').trim() === '' ||
            (this.state.lastName || '').trim() === '' ||
            (this.state.email || '').trim() === '') {
            hasError = true;
        }
        if ((this.state.confirmPassword || '').trim() !== (this.state.newPassword || '').trim()) {
            this.props.setFeedback({
                text: translate('/MyMenigo/CustomerProfileBlock/PasswordNotMatch'),
                type: 'error',
            });
            return;
        }
        this.setState({ hasError: hasError });
        if (hasError) {
            this.props.setFeedback({
                text: translate('/MyMenigo/CustomerProfileBlock/ValidateInputs'),
                type: 'error',
            });
            return;
        }
        return server.postJson(pathCombine(location.pathname, 'Update?v=' + this.guid()), this.state).then(function (result) {
            if (!result.success) {
                _this.props.setFeedback({ text: result.message, type: 'error' });
                return Promise.reject(null);
            }
            _this.setState({
                confirmPassword: '',
                newPassword: '',
            });
            _this.props.setFeedback({
                text: translate('/MyMenigo/CustomerProfileBlock/OnSuccessSave'),
                type: 'success',
            });
            _this.setState(result.model);
        }, function (e) {
            return Promise.reject(null);
        });
    };
    CustomerProfileBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement("form", { onSubmit: function (e) { return _this.onSubmit(e); } },
            React.createElement("div", { className: styles.sectionOne },
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement("label", null, translate('/MyMenigo/CustomerProfileBlock/CompanyName')),
                        React.createElement("br", null),
                        React.createElement("div", null, this.state.companyName)),
                    !this.state.editOrderReferenceIdEnabled && (React.createElement("div", { className: styles.column },
                        React.createElement("label", null, translate('/MyMenigo/CustomerProfileBlock/OrderReferenceId')),
                        React.createElement("br", null),
                        React.createElement("strong", null, this.state.orderReferenceId)))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/FirstName'), type: "text", value: this.state.firstName, name: "firstName", spellCheck: false, autoCorrect: "off", maxLength: 50, required: true, requiredValidationMessage: translate('/MyMenigo/CustomerProfileBlock/RequiredFirstName'), onChange: function (e) {
                                return _this.setState({ firstName: e.target.value });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/lastName'), type: "text", value: this.state.lastName, name: "lastName", spellCheck: false, autoCorrect: "off", maxLength: 50, required: true, requiredValidationMessage: translate('/MyMenigo/CustomerProfileBlock/RequiredLastName'), onChange: function (e) {
                                return _this.setState({ lastName: e.target.value });
                            } }))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/PhoneNumber'), type: "text", value: this.state.phoneNumber, name: "phoneNumber", spellCheck: false, autoCorrect: "off", maxLength: 20, onValidate: this.validatePhone, onChange: function (e) {
                                return _this.setState({ phoneNumber: e.target.value });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/MobileNumber'), type: "text", value: this.state.mobileNumber, name: "mobileNumber", spellCheck: false, autoCorrect: "off", maxLength: 20, onValidate: this.validateMobilePhone, onChange: function (e) {
                                return _this.setState({ mobileNumber: e.target.value });
                            } }))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/Email'), type: "email", value: this.state.email, name: "email", spellCheck: false, autoCorrect: "off", maxLength: 50, required: true, requiredValidationMessage: translate('/MyMenigo/CustomerProfileBlock/RequiredEmail'), onValidate: this.validateEmail, onChange: function (e) {
                                return _this.setState({ email: e.target.value });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/OrderReferenceId'), type: "text", value: this.state.orderReferenceId, name: "orderReferenceId", id: "orderReferenceId", spellCheck: false, disabled: !!!this.state.editOrderReferenceIdEnabled, autoCorrect: "off", maxLength: 20, onChange: function (e) {
                                return _this.setState({ orderReferenceId: e.target.value });
                            } }),
                        !!this.state.editOrderReferenceIdEnabled && (React.createElement("span", { className: styles.small }, translate('/MyMenigo/CustomerProfileBlock/OrderReferenceIdHelpText'))))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/NewPassword'), type: "password", value: this.state.newPassword, name: "newPassword", spellCheck: false, autoCorrect: "off", maxLength: 20, onChange: function (e) {
                                return _this.setState({ newPassword: e.target.value });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement(Input, { label: translate('/MyMenigo/CustomerProfileBlock/ConfirmPassword'), type: "password", value: this.state.confirmPassword, name: "confirmPassword", spellCheck: false, autoCorrect: "off", maxLength: 20, onChange: function (e) {
                                return _this.setState({ confirmPassword: e.target.value });
                            } })))),
            React.createElement("div", { className: styles.sectionTwo },
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement("label", { className: styles.settingLabel }, translate('/MyMenigo/CustomerProfileBlock/SettingLabel')),
                        React.createElement("br", null),
                        React.createElement(Checkbox, { name: "showPriceAsSmallestUnitPrice", checked: this.state.showPriceAsSmallestUnitPrice, onChange: function (e) {
                                return _this.setState({ showPriceAsSmallestUnitPrice: e.target.checked });
                            } }, translate('/MyMenigo/CustomerProfileBlock/ShowPriceAsSmallestUnitPrice')))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement("div", { className: styles.email },
                            React.createElement(Textarea, { label: translate('/MyMenigo/CustomerProfileBlock/SendOrderCopyToTheFollowEmailNote'), name: "orderCopyEmail", value: this.state.orderCopyEmail, spellCheck: false, onChange: function (e) {
                                    return _this.setState({
                                        orderCopyEmail: e.target.value,
                                    });
                                } }),
                            React.createElement("br", null),
                            this.AreAllEmailsValid())))),
            React.createElement("div", { className: styles.sectionThree },
                React.createElement("div", { className: styles.row },
                    React.createElement("div", null,
                        React.createElement("b", null, translate('/MyMenigo/CustomerProfileBlock/Limits')))),
                React.createElement("div", { className: styles.row },
                    React.createElement("div", { className: styles.column },
                        React.createElement("label", { htmlFor: "warningWeightLimit" }, translate('/MyMenigo/CustomerProfileBlock/WeightLimit')),
                        React.createElement("br", null),
                        React.createElement(Input, { type: "number", value: this.state.warningWeightLimit.toString(), name: "warningWeightLimit", spellCheck: false, autoCorrect: "off", max: 999, onChange: function (e) {
                                return _this.setState({
                                    warningWeightLimit: parseFloat(e.target.value) > 999
                                        ? 999
                                        : parseFloat(e.target.value),
                                });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement("label", { htmlFor: "warningPackageLimit" }, translate('/MyMenigo/CustomerProfileBlock/PackageLimit')),
                        React.createElement("br", null),
                        React.createElement(Input, { type: "number", value: this.state.warningPackageLimit.toString(), name: "warningPackageLimit", spellCheck: false, autoCorrect: "off", max: 999, onChange: function (e) {
                                return _this.setState({
                                    warningPackageLimit: parseFloat(e.target.value) > 999
                                        ? 999
                                        : parseFloat(e.target.value),
                                });
                            } })),
                    React.createElement("div", { className: styles.column },
                        React.createElement("label", { htmlFor: "warningPieceLimit" }, translate('/MyMenigo/CustomerProfileBlock/PieceLimit')),
                        React.createElement("br", null),
                        React.createElement(Input, { type: "number", value: this.state.warningPieceLimit.toString(), name: "warningPieceLimit", spellCheck: false, autoCorrect: "off", max: 999, onChange: function (e) {
                                return _this.setState({
                                    warningPieceLimit: parseFloat(e.target.value) > 999
                                        ? 999
                                        : parseFloat(e.target.value),
                                });
                            } })))),
            React.createElement("div", { className: styles.sectionOne },
                React.createElement("div", { className: styles.row },
                    React.createElement(Button, { type: "submit" }, translate('/MyMenigo/CustomerProfileBlock/SaveButton'))))));
    };
    return CustomerProfileBlock;
}(React.Component));
export default connect(function (state) { return ({
    currentUser: state.currentUser
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(CustomerProfileBlock);
