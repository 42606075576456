import React from 'react';
import classnames from 'classnames';
import { Checkbox } from 'Shared/Fields';
import * as styles from './base.scss';
var FacetValue = function (_a) {
    var _b;
    var text = _a.text, selected = _a.selected, onChange = _a.onChange, excludeCnt = _a.excludeCnt, count = _a.count;
    return (React.createElement("li", { className: "ui-test-facet-value" },
        React.createElement("label", { title: text, className: styles.facetValue },
            React.createElement(Checkbox, { className: classnames(styles.facetCheckbox, (_b = {}, _b[styles.disabled] = !excludeCnt && count === 0, _b)), disabled: !selected && !excludeCnt && count === 0, checked: selected, onChange: function (e) {
                    var target = e.currentTarget;
                    onChange(target.checked, target);
                } }, text),
            !excludeCnt && React.createElement("span", { className: styles.count },
                "(",
                count,
                ")"))));
};
export default FacetValue;
