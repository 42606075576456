import React from 'react';
import connect from 'Shared/connect';
import { TINY } from 'Shared/breakpoints';
import * as styles from './desktop.scss';
var ProductCardListHeader = function (_a) {
    var currentBreakpoint = _a.currentBreakpoint;
    return currentBreakpoint > TINY && (React.createElement("div", { className: styles.header },
        React.createElement("div", { className: styles.headerInner })));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ProductCardListHeader);
