import connect from 'Shared/connect';
import { setAccountBoxOpen } from 'SiteLayout/Header/AccountBox/action-creators';
var LoginContainer = function (_a) {
    var children = _a.children, setAccountBoxOpen = _a.setAccountBoxOpen, isLoggedIn = _a.isLoggedIn, signUpUrl = _a.signUpUrl;
    return children({
        setAccountBoxOpen: setAccountBoxOpen,
        isLoggedIn: isLoggedIn,
        signUpUrl: signUpUrl,
    });
};
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    signUpUrl: state.appShellData.siteSettings.signUpPage,
}); }, function (dispatch) { return ({
    setAccountBoxOpen: function () { return dispatch(setAccountBoxOpen()); },
}); })(LoginContainer);
