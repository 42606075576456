import React from 'react';
import classnames from 'classnames';
import Icon from 'Shared/Icon';
import LoginContainer from 'Shared/Login/Container';
import LoginBox from './../../../Account/Components/LoginBox';
import LogoutBox from './../../../Account/Components/LogoutBox';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var AccountBox = function (_a) {
    var isCloseButton = _a.isCloseButton, close = _a.close;
    return (React.createElement(LoginContainer, null, function (_a) {
        var isLoggedIn = _a.isLoggedIn, signUpUrl = _a.signUpUrl;
        return (React.createElement("div", { className: classnames(styles.base) },
            !IS_APP && isCloseButton && (React.createElement("button", { className: classnames(styles.floatingCloseBtn), onClick: function () { return close(); } },
                React.createElement("span", null, translate('/Shared/Close')),
                React.createElement(Icon, { name: "close" }))),
            isLoggedIn ? (React.createElement(LogoutBox, { close: close, key: "logout" })) : (React.createElement(LoginBox, { close: close, key: "login", isCurrent: true, signUpUrl: signUpUrl }))));
    }));
};
export default AccountBox;
