var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import IngredientTableRow from './row';
import shallowEquals from 'Shared/shallow-equals';
import { translate } from 'Shared/translate';
import * as base from './base.scss';
var IngredientTable = /** @class */ (function (_super) {
    __extends(IngredientTable, _super);
    function IngredientTable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            focusRowIndex: -1,
        };
        return _this;
    }
    IngredientTable.prototype.componentWillReceiveProps = function (nextProps) {
        if (!shallowEquals(this.props, nextProps, true) &&
            !!nextProps.recipeItemModels &&
            nextProps.recipeItemModels.length > 0) {
            this.shouldSetFocus =
                !this.props.recipeItemModels || nextProps.recipeItemModels.length > this.props.recipeItemModels.length;
            if (!!this.shouldSetFocus) {
                this.setState({ focusRowIndex: 0 });
            }
        }
    };
    IngredientTable.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to Ingredients');
        }
        return shouldUpdate;
    };
    IngredientTable.prototype.onFocus = function (index) {
        this.setState({ focusRowIndex: index });
    };
    IngredientTable.prototype.render = function () {
        var _this = this;
        var recipeItemModels = this.props.recipeItemModels;
        return (!!recipeItemModels &&
            !!recipeItemModels.length && (React.createElement("table", { className: base.list },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", null, translate('/RecipeEdit/Ingredients/Product')),
                    React.createElement("th", { colSpan: 2 }, translate('/RecipeEdit/Ingredients/Amount')),
                    React.createElement("th", null, translate('/RecipeEdit/Ingredients/NutrientsWastePercent')),
                    React.createElement("th", null, translate('/RecipeEdit/Ingredients/AmountPerProduct')),
                    React.createElement("th", null, translate('/RecipeEdit/Ingredients/Price')),
                    React.createElement("th", null)),
                recipeItemModels.map(function (recipeItem, i) {
                    return (React.createElement(IngredientTableRow, { key: 'Ing_' + i + '_' + recipeItem.id, recipeItem: recipeItem, rowIndex: i, shouldSetFocus: _this.shouldSetFocus, hasFocus: i === _this.state.focusRowIndex, onFocus: function (index) { return _this.onFocus(index); }, currencySymbol: _this.props.currencySymbol, removeRow: function (index) { return _this.props.removeRow(index); }, updateIngredient_Price: function (row, e) {
                            _this.props.updateIngredient_Price(row, e);
                        }, updateIngredient_Amount: function (row, e) {
                            _this.props.updateIngredient_Amount(row, e);
                        }, updateIngredient_UnitVariant: function (row, e) {
                            _this.props.updateIngredient_UnitVariant(row, e);
                        }, updateIngredient_Waste: function (row, e) {
                            _this.props.updateIngredient_Waste(row, e);
                        }, focusSearchInput: function () { return _this.props.focusSearchInput(); } }));
                }),
                React.createElement("tr", null,
                    React.createElement("td", { colSpan: 4 }),
                    React.createElement("td", { className: base.footer },
                        React.createElement("strong", null,
                            translate('/RecipeEdit/Ingredients/PortionSize'),
                            ": ",
                            this.props.portionWeight,
                            "g")),
                    React.createElement("td", null),
                    React.createElement("td", { className: base.footer },
                        React.createElement("a", { onClick: function () { return _this.props.resetList(); } }, translate('/RecipeListing/Clear'))))))));
    };
    return IngredientTable;
}(React.Component));
export default IngredientTable;
