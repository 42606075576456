var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import connect from 'Shared/connect';
import { displaySearchList } from '../action-creators';
import * as styles from './base.scss';
var SearchResultsList = /** @class */ (function (_super) {
    __extends(SearchResultsList, _super);
    function SearchResultsList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchResultsList.prototype.handleItemClick = function (item) {
        if (!this.props.onItemSelected) {
            return;
        }
        this.props.onItemSelected(item);
        this.props.displaySearchList(false);
    };
    SearchResultsList.prototype.render = function () {
        var _this = this;
        if (!this.props.searchResults || !this.props.searchListIsVisible || this.props.searchQuery.length <= 0) {
            return null;
        }
        if (this.props.searchListIsVisible && this.props.searchResults && this.props.searchResults.length === 0) {
            return (React.createElement("div", { className: styles.messageWrapperBox },
                React.createElement("div", null, translate('/App/QuickOrder/ProductNotFound')),
                React.createElement("div", null, translate('/App/QuickOrder/TryProductName'))));
        }
        var Template = this.props.template;
        return (React.createElement("div", null, this.props.searchResults.map(function (item) { return (React.createElement("div", { className: styles.item, key: item.productId, onClick: function (_) { return _this.handleItemClick(item); } },
            React.createElement(Template, __assign({}, item)))); })));
    };
    return SearchResultsList;
}(React.Component));
export default connect(function (state) { return ({
    searchQuery: state.app.searchQuery,
    searchListIsVisible: state.app.searchListIsVisible,
    searchResults: state.app.searchResults,
}); }, function (dispatch) { return ({
    displaySearchList: function (show) {
        dispatch(displaySearchList(show));
    },
}); })(SearchResultsList);
