import React from 'react';
import classnames from 'classnames';
import ProductDescription from '../ProductDescription';
import Main, { Part } from 'Shared/PageLayout';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
var NutritionBlock = function (props) {
    var _a;
    var hasFactValues = props.nutritionFacts && props.nutritionFacts.reduce(function (acc, fact) { return acc + fact.value; }, 0) > 0;
    var equipmentNode = (React.createElement("div", { className: styles.nutritionInfo },
        props.itemSpecificationT5038 && (React.createElement("div", null,
            React.createElement("div", { className: styles.h4 }, translate('/Product/OriginDeclaration')),
            React.createElement("p", null, props.itemSpecificationT5038))),
        props.countryOfOrigin && (React.createElement("div", null,
            React.createElement("div", { className: styles.h4 }, translate('/Product/CountryOfOrigin')),
            React.createElement("p", null, props.countryOfOrigin)))));
    var foodNode = (React.createElement(React.Fragment, null,
        hasFactValues && (React.createElement(ProductDescription, { className: styles.nutritionTable, nutritionInformationProvider: props.informationProvider, tableData: props.nutritionFacts, currentBreakpoint: props.currentBreakpoint })),
        React.createElement("div", { className: styles.nutritionInfo },
            props.itemSpecificationT2235 && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/Instructions')),
                React.createElement("p", null, props.itemSpecificationT2235))),
            props.itemSpecificationT4083 && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/Cooking')),
                React.createElement("p", null, props.itemSpecificationT4083))),
            props.itemSpecificationT4088 && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/Ingredients')),
                React.createElement("p", null, props.itemSpecificationT4088))),
            props.manufacturerCountry && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/ManufacturerCountry')),
                React.createElement("p", null, props.manufacturerCountry))),
            props.itemSpecificationT5038 && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/OriginDeclaration')),
                React.createElement("p", null, props.itemSpecificationT5038))),
            props.countryOfOrigin && (React.createElement("div", null,
                React.createElement("div", { className: styles.h4 }, translate('/Product/CountryOfOrigin')),
                React.createElement("p", null, props.countryOfOrigin))),
            props.documentUrls &&
                props.documentUrls.map(function (data, i) { return (React.createElement("div", { key: i },
                    React.createElement("a", { target: "_blank", href: data, rel: "nofollow", className: styles.h4 }, translate('/Product/ProductSheet')))); }),
            !!props.lawInfoText && (React.createElement("div", null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: props.lawInfoText } }))))));
    return (React.createElement("div", { id: "NutritionBlock", className: classnames(styles.nutritionRow, (_a = {}, _a[styles.nutritionRowCheckout] = props.isCheckout, _a)) },
        React.createElement(Main, { className: styles.removePadding },
            React.createElement(Part, { layout: "1to1" }, props.isProductEquipment ? equipmentNode : foodNode))));
};
export default NutritionBlock;
