var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Fragment, useEffect, useRef, useState } from 'react';
import connect from 'Shared/connect';
import RecommendationsPanelComponent from '../RecommendationsPanel/index';
import ProductCardContainer from 'Shared/ProductComponents/ProductCardContainer';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
import ProductCardItem from 'Shared/ProductCard/ProductRow2.0/ProductCardItem';
import ListLargeAd from '../Compact/LargeAd';
import * as productCardListStyles from 'Shared/ProductCard/Fade/list.scss';
import * as style from './grid.scss';
import { fetchAlternativeProductsLinkInfo } from 'Shared/ProductAlternativesLink/action-creators';
import { getDeepestCategoryLinkUrl, isLinkVisible } from 'Shared/ProductAlternativesLink/ProductAlternativesLink';
import { setAlternativeProductsLinksToState } from 'Shared/Product/action-creators';
var ProductCardsGrid = function (props) {
    var _a = __read(useState(2), 2), recPanelStartIndex = _a[0], setRecPanelStartIndex = _a[1];
    var gridRef = useRef(null);
    var productList = props.products || [];
    var selectableProductIndexes = productList
        .map(function (product, index) { return (product.isDiscontinued ? -1 : index); })
        .filter(function (i) { return i !== -1; });
    useEffect(function () {
        if (!!props.recommendationsPanel && !!gridRef.current) {
            var reRender_1 = false;
            var handleResize = function () {
                if (reRender_1 !== false) {
                    clearTimeout(reRender_1);
                }
                reRender_1 = setTimeout(function () {
                    setRecPanelStartIndex(props.viewLayoutType === 'viewLayout_Grid' && !!gridRef.current ? Math.floor((gridRef.current.getBoundingClientRect().width + 1) / (232 + 1)) : 2);
                }, 200);
            };
            setRecPanelStartIndex(props.viewLayoutType === 'viewLayout_Grid' && !!gridRef.current ? Math.floor((gridRef.current.getBoundingClientRect().width + 1) / (232 + 1)) : 2);
            window.addEventListener('resize', handleResize);
        }
    }, [props.viewLayoutType]);
    useEffect(function () {
        try {
            var alternativeProductLinksRequests = productList.filter(function (x) { return isLinkVisible(x.selectedVariant || x.variants[0], x); }).map(function (p, i) { return { articleNumber: p.code, categoryPageUrl: getDeepestCategoryLinkUrl(p.url.toString()) }; });
            var reqPanelProducts = !!props.recommendationsPanel && !!props.recommendationsPanel.products ? props.recommendationsPanel.products.filter(function (x) { return isLinkVisible(x.selectedVariant || x.variants[0], x); }).map(function (p, i) { return { articleNumber: p.code, categoryPageUrl: getDeepestCategoryLinkUrl(p.url.toString()) }; }) : [];
            var payload = __spread(alternativeProductLinksRequests, reqPanelProducts);
            !!payload.length && fetchAlternativeProductsLinkInfo(payload, props.jwtToken, props.getAlternativeProductsLinkInfoEndpoint).then(function (result) {
                props.setAlternativeProductsLinks(result);
            });
        }
        catch (error) {
            props.setAlternativeProductsLinks([]);
        }
    }, [props.products]);
    return (React.createElement("div", { className: style.main },
        React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
            React.createElement("div", { className: style.grid, "data-list-type": props.viewLayoutType, ref: gridRef }, productList.map(function (productListItem, index) { return (React.createElement(Fragment, { key: index },
                React.createElement(ProductCardContainer, { product: productListItem, index: index }, function (_a) {
                    var product = _a.product, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, discontinuedOrBackordered = _a.discontinuedOrBackordered, quantityStep = _a.quantityStep, url = _a.url, isRestrictedCustomer = _a.isRestrictedCustomer, cardHeight = _a.cardHeight;
                    return (React.createElement(ProductCardItem, { product: product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: discontinuedOrBackordered, quantityStep: quantityStep, index: (index > recPanelStartIndex - 1 && !!props.recommendationsPanel) ? (index + props.recommendationsPanel.products.length) : index, url: url, isRestrictedCustomer: isRestrictedCustomer, cardHeight: cardHeight, temporary: props.temporary, cardType: props.viewLayoutType, searchTerm: props.searchTerm }));
                }),
                index === recPanelStartIndex - 1 && (!!props.recommendationsPanel || !!props.largeAd) &&
                    React.createElement("div", { className: style.ads },
                        props.recommendationsPanel &&
                            React.createElement(RecommendationsPanelComponent, { recommendationsPanel: props.recommendationsPanel, recommendedListClassName: productCardListStyles.recommendationsPanelProductsList, viewLayoutType: props.viewLayoutType, staticCardHeight: props.staticCardHeight, temporary: props.temporary, startOnIndex: recPanelStartIndex, searchTerm: props.searchTerm }),
                        !!props.largeAd && React.createElement(ListLargeAd, { largeAd: props.largeAd })))); })))));
};
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
    currentBreakpoint: state.currentBreakpoint,
    jwtToken: state.appShellData.currentUser.jwtToken,
    getAlternativeProductsLinkInfoEndpoint: state.appShellData.getAlternativeProductsLinkInfoEndpoint
}); }, function (dispatch) { return ({
    setAlternativeProductsLinks: function (data) {
        return dispatch(setAlternativeProductsLinksToState(data));
    },
}); })(ProductCardsGrid);
