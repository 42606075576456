import connect from 'Shared/connect';
import { getCartLineItemCountForVariant } from '../../Cart/reducer';
import { getSelectedVariant } from 'Shared/Product/reducer';
import { selectVariant, toggleSelectedVariant } from 'Shared/Product/action-creators';
import { addToCart } from './../../Cart/action-creators';
function ProductStateHandling(props) {
    return props.children({
        cartItemQuantity: props.cartItemQuantity,
        selectVariant: props.selectVariant,
        addToCart: props.addToCart,
        selectedVariant: props.selectedVariant,
        toggleSelectedVariant: props.toggleSelectedVariant,
    });
}
var mapStateToProps = function (state, ownProps) {
    var selectedVariant = getSelectedVariant(state, ownProps.variants);
    return {
        cartItemQuantity: getCartLineItemCountForVariant(state, selectedVariant),
        selectedVariant: selectedVariant,
    };
};
var mapStateToDispatch = function (dispatch, ownProps) { return ({
    selectVariant: function (newVariantCode) { return dispatch(selectVariant(newVariantCode, ownProps.variants)); },
    toggleSelectedVariant: function (currentSelectedVariantCode) {
        return dispatch(toggleSelectedVariant(currentSelectedVariantCode, ownProps.variants));
    },
    addToCart: function (code, quantity, unitShortName, ticket, location, experiment) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, location, experiment));
    },
}); };
export default connect(mapStateToProps, mapStateToDispatch)(ProductStateHandling);
