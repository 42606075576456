var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Spinner from 'Shared/Spinner';
import * as styles from './feedback.scss';
import shallowEquals from 'Shared/shallow-equals';
export var MINIMUIM_PENDING = 600;
export var MAXIMUM_FULFILLED = 2000;
export var MAXIMUM_REJECTED = 2000;
var defaultState = {
    status: 'default',
    text: null,
};
var FeedbackButton = /** @class */ (function (_super) {
    __extends(FeedbackButton, _super);
    function FeedbackButton(props) {
        var _this = _super.call(this, props) || this;
        _this.timerResolveVisuals = null;
        _this.timerStatusDefault = null;
        _this.timerDeactivate = null;
        _this.isUnmounting = false;
        _this.state = defaultState;
        return _this;
    }
    FeedbackButton.prototype.componentWillUnmount = function () {
        this.isUnmounting = true;
        this.clearFeedbackTimeouts();
    };
    FeedbackButton.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update to FeedbackButton');
        }
        return shouldUpdate;
    };
    FeedbackButton.prototype.clearFeedbackTimeouts = function () {
        if (this.timerResolveVisuals) {
            clearTimeout(this.timerResolveVisuals);
            this.timerResolveVisuals = null;
        }
        if (this.timerStatusDefault) {
            clearTimeout(this.timerStatusDefault);
            this.timerStatusDefault = null;
        }
        if (this.timerDeactivate) {
            clearTimeout(this.timerDeactivate);
            this.timerDeactivate = null;
        }
    };
    FeedbackButton.prototype.isPending = function () {
        return this.state.status === 'pending';
    };
    FeedbackButton.prototype.isRejected = function () {
        return this.state.status === 'rejected';
    };
    FeedbackButton.prototype.isFulfilled = function () {
        return this.state.status === 'fulfilled';
    };
    FeedbackButton.prototype.isActive = function () {
        return this.state.status !== 'default';
    };
    FeedbackButton.prototype.activate = function (asyncOperation, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (this.isPending()) {
            return Promise.reject(new Error("Not allowed to activate user feedback on buttons when it's pending"));
        }
        var _a = options.minimumPending, minimumPending = _a === void 0 ? MINIMUIM_PENDING : _a, _b = options.maximumFulfilled, maximumFulfilled = _b === void 0 ? MAXIMUM_FULFILLED : _b, _c = options.maximumRejected, maximumRejected = _c === void 0 ? MAXIMUM_REJECTED : _c;
        this.clearFeedbackTimeouts();
        // Extend the button props and wait for the next animation frame
        // to enable visual user feedback. If all props are passed to the
        // button per default, there's some significant performance issues
        // when there's a lot of them in the DOM.
        var activateVisuals = new Promise(function (resolve) {
            _this.setState({
                status: 'pending',
            }, function () {
                return requestAnimationFrame(function () {
                    // Sometimes you want the spinner to be visible so the user has a chance
                    // to notice that something happend. Studies shows that users today expects
                    // that things like this take some time. So if it's to quick they
                    // assume that something went wrong. I know... stupid... right?
                    _this.timerResolveVisuals = setTimeout(resolve, minimumPending);
                });
            });
        });
        return Promise.all([asyncOperation, activateVisuals])
            .then(function (_a) {
            var _b = __read(_a, 1), text = _b[0];
            return _this.textStatus('fulfilled', (text || ''), maximumFulfilled);
        })
            .catch(function (text) {
            _this.textStatus('rejected', (text || ''), maximumRejected);
            return Promise.reject(text);
        });
    };
    FeedbackButton.prototype.textStatus = function (status, text, maximumDisplay) {
        var _this = this;
        if (text === void 0) { text = ''; }
        if (maximumDisplay === void 0) { maximumDisplay = Infinity; }
        this.clearFeedbackTimeouts();
        if (typeof text === 'string' && maximumDisplay > 0) {
            if (!this.isUnmounting) {
                this.setState({
                    text: text,
                }, function () {
                    return requestAnimationFrame(function () {
                        // Set status to "fulfilled" or "rejected" when status text is in place
                        if (!_this.isUnmounting) {
                            _this.setState({
                                status: status,
                            }, maximumDisplay !== Infinity &&
                                (function () { return (_this.timerStatusDefault = setTimeout(function () { return _this.deactivate(); }, maximumDisplay)); }));
                        }
                    });
                });
            }
        }
        else {
            this.deactivate();
        }
    };
    FeedbackButton.prototype.deactivate = function () {
        var _this = this;
        this.setState({
            status: 'default',
        }, function () {
            _this.timerDeactivate = setTimeout(function () {
                // Using `setTimeout(...)` instead of `addEventListener('transitionend', ...)`
                // since you bearly notice the animation and wouldn't kill you if the
                // the animation for some reason stopped in the middle of it (which it shouldn't)
                _this.setState(defaultState);
            }, 1000);
        });
    };
    FeedbackButton.prototype.render = function () {
        var _this = this;
        var _a;
        var buttonProps = Object.assign({}, this.props, {
            className: classnames(this.props.className, styles.base, this.isActive() && [
                styles.active,
                (_a = {},
                    _a[styles.failed] = this.isRejected(),
                    _a[styles.succeeded] = this.isFulfilled(),
                    _a[styles.spinning] = this.isPending(),
                    _a),
            ]),
            disabled: this.props.disabled,
            'data-feedback-text': this.state.text,
        });
        delete buttonProps.onClickWithFeedback;
        if (this.props.onClickWithFeedback) {
            buttonProps.onClick = function (e) {
                return _this.isPending() || _this.isFulfilled()
                    ? false
                    : _this.props.onClickWithFeedback(e, function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        return _this.activate.apply(_this, args);
                    });
            };
        }
        return (React.createElement(Button, __assign({}, buttonProps),
            React.createElement("span", { className: styles.text }, this.props.children),
            this.isPending() && React.createElement(Spinner, { className: styles.spinner })));
    };
    return FeedbackButton;
}(React.Component));
export default FeedbackButton;
