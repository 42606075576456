import { postJson } from 'Shared/server';
export var FETCH_CONTENT_CARD = 'FETCH_CONTENT_CARD';
export function refreshContent(contentIndex) {
    return function (dispatch, getState) {
        var currentPage = getState().currentPage;
        if (!!currentPage.contentCardsMaxIndex && contentIndex > currentPage.contentCardsMaxIndex)
            contentIndex = 0;
        var cards = (currentPage.contentCards[contentIndex]);
        if (!!cards)
            return dispatch(refreshContentSuccess(cards));
        return postJson('/StartPage/GetContentCards', { index: contentIndex })
            .then(function (resp) {
            dispatch(refreshContentSuccess(resp));
            return resp;
        });
    };
}
export function refreshContentSuccess(cards) {
    return {
        type: FETCH_CONTENT_CARD,
        payload: {
            cards: cards
        }
    };
}
