var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PantryReportArea from './Reports/PantryReportArea';
import PantryReportItem from './Reports/PantryReportItem';
import PantryReportPantry from './Reports/PantryReportPantry';
import PantryReportInvent from './Reports/PantryReportInvent';
var PantriesReportPage = /** @class */ (function (_super) {
    __extends(PantriesReportPage, _super);
    function PantriesReportPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PantriesReportPage.prototype.area = function (reportType) {
        switch (reportType) {
            case 'item':
                return React.createElement(PantryReportItem, __assign({}, this.props));
            case 'area':
                return React.createElement(PantryReportArea, __assign({}, this.props));
            case 'pantry':
                return React.createElement(PantryReportPantry, __assign({}, this.props));
            case 'invent':
                return React.createElement(PantryReportInvent, __assign({}, this.props));
            default:
                return React.createElement("div", null);
        }
    };
    PantriesReportPage.prototype.render = function () {
        return React.createElement("div", null, this.area(this.props.report.reportType));
    };
    return PantriesReportPage;
}(React.Component));
export default PantriesReportPage;
