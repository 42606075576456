import * as crossWindowEvents from 'Shared/cross-window-events';
import deepmerge from 'deepmerge';
import * as server from 'Shared/server';
import { batchActions } from 'redux-batched-actions';
import { updateODPTracking, ODPTrackingActions, sendToPeerius, addEcommerceEventToDataLayer } from './../TrackingInformation';
import currentPageIsCheckout from './../../Features/Checkout/Pages/Checkout/current-page-is-checkout';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import { updateCartItemQuantity } from '../Checkout/action-creators';
export var CART_LOAD = 'CART_LOAD';
export var CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export var CART_LOAD_FAILURE = 'CART_LOAD_FAILURE';
export var CART_INVALID_QUANTITY = 'CART_INVALID_QUANTITY';
export var CART_OPTIMISTIC_UPDATE = 'CART_OPTIMISTIC_UPDATE';
export var CART_CHANGES_ACCEPTED = 'CART_CHANGES_ACCEPTED';
export var TOGGLE_IGNORE_AVAILABILITY = 'TOGGLE_IGNORE_AVAILABILITY';
export function getExistingItem(cart, code) {
    if (!code) {
        return;
    }
    var existingCartItem = cart.items.find(function (i) { return i.lineItem.code === code; });
    return existingCartItem && existingCartItem.lineItem;
}
export function rejectInvalidQuantity(code) {
    return function (dispatch) {
        dispatch({
            type: CART_INVALID_QUANTITY,
            code: code,
        });
        return Promise.reject(null);
    };
}
export var isValidQuantity = function (quantity, limit) {
    if (isNaN(quantity)) {
        return false;
    }
    if (!isNaN(limit) && quantity > limit) {
        return false;
    }
    if (quantity < 0) {
        return false;
    }
    return true;
};
export var addToCart = function (code, quantity, unitShortName, ticket, location, experiment) {
    if (ticket === void 0) { ticket = null; }
    if (location === void 0) { location = null; }
    return function (dispatch, getState) {
        if (!isValidQuantity(quantity)) {
            rejectInvalidQuantity(code);
            return Promise.reject(null);
        }
        var cart = getState().cart;
        var existingItem = getExistingItem(cart, code);
        var newItemTicket = existingItem ? null : ticket;
        var isUpdateAction = existingItem ? true : false;
        var previousQuantity = existingItem ? existingItem.quantity : 0;
        updateODPTracking(code, ODPTrackingActions.ADD_TO_CART);
        if (currentPageIsCheckout(getState().currentPage)) {
            dispatch(updateCartItemQuantity(code, quantity, null, unitShortName, 'CheckoutPage'));
        }
        return dispatch(sendCartRequest(function () {
            var _a;
            return {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: isUpdateAction,
                        newQuantity: quantity,
                        newUnitShortName: unitShortName,
                        previousQuantity: previousQuantity,
                        previousUnitShortName: unitShortName,
                    },
                    _a),
            };
        }, location, newItemTicket, experiment)).catch(function (e) {
            dispatch(setFeedback({ type: 'error', text: translate('/Cart/Errors/ChangeQuantity') }));
        });
    };
};
export function updateCartItemUnit(code, unitShortName, location) {
    return function (dispatch) {
        return dispatch(sendCartRequest(function (cart) {
            var _a;
            var existingItem = getExistingItem(cart, code);
            return {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: true,
                        newQuantity: existingItem.quantity,
                        newUnitShortName: unitShortName,
                        previousQuantity: existingItem.quantity,
                        previousUnitShortName: existingItem.selectedVariant.unitShortName,
                        rowNumber: existingItem.rowNumber,
                    },
                    _a),
            };
        }, location)).catch(function (e) {
            dispatch(setFeedback({ type: 'error', text: translate('/Cart/Errors/ChangeUnit') }));
        });
    };
}
export function removeCartItem(code, location) {
    return function (dispatch) {
        return dispatch(sendCartRequest(function (cart) {
            var _a;
            var existingItem = getExistingItem(cart, code);
            if (!existingItem || !existingItem.selectedVariant) {
                return null;
            }
            updateODPTracking(code, ODPTrackingActions.REMOVE_FROM_CART);
            return {
                items: (_a = {},
                    _a[code] = {
                        newQuantity: 0,
                        newUnitShortName: existingItem.selectedVariant.unitShortName,
                        previousQuantity: existingItem.quantity,
                        previousUnitShortName: existingItem.selectedVariant.unitShortName,
                        deleteRowOverride: true,
                    },
                    _a),
            };
        }, location)).catch(function (e) {
            dispatch(setFeedback({ type: 'error', text: translate('/Cart/Errors/Remove') }));
        });
    };
}
var currentCartUpdate = Promise.resolve(null);
crossWindowEvents.on(['cart-modified', 'checkout-modified'], function (store) {
    if (!currentPageIsCheckout(store.getState().currentPage)) {
        doReloadCart(store.dispatch);
    }
    else {
        console.info('Ignoring cart changes on checkout page');
    }
});
export function reloadCart() {
    return doReloadCart;
}
function doReloadCart(dispatch) {
    dispatch({
        type: CART_LOAD,
    });
    var updateCart = function () {
        console.debug('Reloading cart since it was modified in another window/tab');
        var url = { path: '/cart' };
        server
            .get(url)
            .then(function (r) { return r.json(); })
            .then(function (json) {
            if (!!json.cartUpdatesPageTrackingData) {
                sendToPeerius(json.cartUpdatesPageTrackingData);
            }
            var cartTevent = json.trackingData.ga4EventObjects['view_cart'];
            addEcommerceEventToDataLayer(cartTevent);
            var viewItemListEvent = json.trackingData.ga4EventObjects['view_item_list'];
            addEcommerceEventToDataLayer(viewItemListEvent);
            dispatch({
                type: CART_LOAD_SUCCESS,
                cart: json,
            });
        })
            .catch(function (e) {
            console.error(e);
            dispatch({ type: CART_LOAD_FAILURE, error: e, url: url });
            return Promise.reject(e);
        });
    };
    currentCartUpdate = currentCartUpdate.then(updateCart, updateCart);
}
var pendingTickets = [];
function sendCartRequest(diffOrCreator, location, ticket, experiment) {
    if (ticket === void 0) { ticket = null; }
    if (ticket) {
        pendingTickets.push(ticket);
    }
    return function (dispatch, getState) {
        var diff;
        if (typeof diffOrCreator === 'function') {
            diff = diffOrCreator(getState().cart);
        }
        else {
            diff = diffOrCreator;
        }
        if (!!diff) {
            diff.id = Math.random();
        }
        dispatch(batchActions([
            {
                type: CART_OPTIMISTIC_UPDATE,
                diff: diff,
            },
            {
                type: CART_LOAD,
            },
        ]));
        var sendNextRequest = function () {
            var _a;
            var state = getState().cart;
            var currentPendingChanges = state.pendingChanges;
            if (!currentPendingChanges.length) {
                return Promise.resolve();
            }
            var totalDiff = {};
            currentPendingChanges.forEach(function (r) { return (totalDiff = deepmerge(totalDiff, r)); });
            var componentNameSections = getState().currentPage.componentName.split('.');
            var lastSection = componentNameSections[componentNameSections.length - 1];
            totalDiff.location = lastSection;
            var headers;
            if (pendingTickets.length) {
                var tickets = pendingTickets.join(',');
                pendingTickets = [];
                headers = IS_APP
                    ? {}
                    : (_a = {},
                        _a[server.ESALES_TICKET_HEADER] = tickets,
                        _a[server.ESALES_ACTION_HEADER] = server.ESALES_ADD_TO_CART_ACTION,
                        _a);
            }
            var url = {
                path: '/cart/update',
            };
            return server
                .post(url, totalDiff, headers)
                .then(function (r) { return r.json(); })
                .then(function (json) {
                if (!!json.cartUpdatesPageTrackingData) {
                    sendToPeerius(json.cartUpdatesPageTrackingData);
                }
                if (json.trackingData && json.trackingData.ga4EventObjects) {
                    var ga4Events = json.trackingData.ga4EventObjects;
                    if ("add_to_cart" in ga4Events) {
                        addEcommerceEventToDataLayer(ga4Events["add_to_cart"]);
                    }
                    if ("remove_from_cart" in ga4Events) {
                        addEcommerceEventToDataLayer(ga4Events["remove_from_cart"]);
                    }
                }
                dispatch(batchActions([
                    {
                        type: CART_CHANGES_ACCEPTED,
                        changes: currentPendingChanges,
                    },
                    {
                        type: CART_LOAD_SUCCESS,
                        cart: json,
                    },
                ]));
                crossWindowEvents.emit('cart-modified');
            })
                .catch(function (e) {
                console.error(e);
                dispatch({ type: CART_LOAD_FAILURE, error: e, url: url });
                return Promise.reject(e);
            });
        };
        currentCartUpdate = currentCartUpdate.then(sendNextRequest, sendNextRequest);
        return currentCartUpdate;
    };
}
export function toNumber(quantity) {
    return parseFloat((quantity || '').replace(',', '.'));
}
