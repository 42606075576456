var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import shallowEquals from 'Shared/shallow-equals';
import Overlay from 'Shared/Overlay';
import { translate } from 'Shared/translate';
import compareStrings from 'Shared/Facet/compare-strings';
import toggleFacet, { allSelectedValues } from 'Shared/Facet/toggle-facet';
import { isIOS } from 'Shared/device-type';
import { DEFAULT_VISIBLE_FACET_COUNT, POPULAR_FACET_COUNT } from 'Shared/Facet/Facet';
import * as styles from 'Shared/Facet/filter-and-sort-bar.scss';
import FacetAndOrFilter from 'Shared/Facet/FacetAndOrFilter';
var FilterAndSortBar = /** @class */ (function (_super) {
    __extends(FilterAndSortBar, _super);
    function FilterAndSortBar(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            facetsIsOpen: false,
            sortingIsOpen: false,
            openFacetFieldName: null,
            facets: props.facets,
            categories: props.subCategories,
            filterText: '',
        };
        return _this;
    }
    FilterAndSortBar.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState);
        if (!shouldUpdate) {
            console.info('Ignoring update to FilterAndSortBar');
        }
        return shouldUpdate;
    };
    FilterAndSortBar.prototype.componentDidMount = function () {
        document.body.classList.add(styles.viewPortBottomMargin);
    };
    FilterAndSortBar.prototype.componentWillUnmount = function () {
        document.body.classList.remove(styles.viewPortBottomMargin);
    };
    FilterAndSortBar.prototype.componentWillReceiveProps = function (props) {
        this.setState({ facets: props.facets, categories: props.subCategories });
        if (this.state.openFacetFieldName === 'categories' &&
            this.state.facetsIsOpen &&
            (!props.subCategories || props.subCategories.length <= 1)) {
            this.setState({ facetsIsOpen: !this.state.facetsIsOpen, openFacetFieldName: null });
        }
    };
    FilterAndSortBar.prototype.onSortChange = function (sortBy) {
        var _this = this;
        this.setState({ sortingIsOpen: false });
        // A timeout because we don't want to start an HTTP request during the animation
        setTimeout(function () { return _this.props.onSortChange(sortBy); }, 250);
    };
    FilterAndSortBar.prototype.openCategories = function () {
        if (this.state.openFacetFieldName === 'categories') {
            return this.state.categories;
        }
        return [];
    };
    FilterAndSortBar.prototype.openFacet = function () {
        var _this = this;
        if (!this.state.openFacetFieldName || this.state.openFacetFieldName === 'categories') {
            return null;
        }
        return this.state.facets.find(function (f) { return f.fieldName === _this.state.openFacetFieldName; });
    };
    FilterAndSortBar.prototype.openFacetValues = function () {
        var _this = this;
        if (!this.state.openFacetFieldName || this.state.openFacetFieldName === 'categories') {
            return [];
        }
        var openFacet = this.state.facets.find(function (f) { return f.fieldName === _this.state.openFacetFieldName; });
        if (!openFacet) {
            return [];
        }
        return openFacet.facetValues;
    };
    FilterAndSortBar.prototype.isOpen = function () {
        if (!this.state.openFacetFieldName) {
            return false;
        }
        return this.openCategories().length > 1 || this.openFacetValues().length > 0;
    };
    FilterAndSortBar.prototype.onFacetValueChange = function (fieldName, facetValue, selected) {
        if (facetValue.count === 0 && !facetValue.selected) {
            return;
        }
        var facets = toggleFacet(this.state.facets, fieldName, facetValue, selected);
        this.setState({ facets: facets });
        this.props.onFacetValueChange(facets, selected ? facetValue.ticket : null);
    };
    FilterAndSortBar.prototype.onFacetValuesChange = function (fieldName, facetValues) {
        var facets = this.state.facets.map(function (f) {
            return f.fieldName !== fieldName
                ? f
                : Object.assign({}, f, {
                    facetValues: f.facetValues.map(function (fv) {
                        return facetValues[fv.id] !== undefined ? Object.assign({}, fv, { selected: facetValues[fv.id] }) : fv;
                    }),
                });
        });
        if (facets !== this.state.facets) {
            this.setState({ facets: facets });
            this.props.onFacetValueChange(facets, null);
        }
    };
    FilterAndSortBar.prototype.setOpenFacet = function (fieldName) {
        if (this.state.openFacetFieldName === fieldName) {
            fieldName = null;
        }
        this.setState({ filterText: '', openFacetFieldName: fieldName });
    };
    FilterAndSortBar.prototype.toggleFacets = function () {
        if (!this.state.facetsIsOpen &&
            this.state.facets.length === 0 &&
            (!this.state.categories || this.state.categories.length <= 1)) {
            return;
        }
        this.setState({ facetsIsOpen: !this.state.facetsIsOpen, openFacetFieldName: null });
    };
    FilterAndSortBar.prototype.clearFacets = function () {
        var newFacets = this.state.facets;
        this.state.facets.forEach(function (f) {
            var selectedFacets = allSelectedValues(f.facetValues);
            selectedFacets.forEach(function (v) {
                newFacets = toggleFacet(newFacets, f.fieldName, v, false);
            });
        });
        this.setState({ facets: newFacets });
        this.props.onFacetValueChange(newFacets, null);
    };
    FilterAndSortBar.prototype.setFilterText = function (value) {
        this.setState({ filterText: value });
    };
    FilterAndSortBar.prototype.filteredFacets = function () {
        if (this.state.openFacetFieldName === 'categories') {
            return null;
        }
        var filterText = this.state.filterText.toLocaleLowerCase();
        return this.openFacetValues().filter(function (facetValue) { return facetValue.text.toLowerCase().includes(filterText); });
    };
    FilterAndSortBar.prototype.onClearSearch = function () {
        if (this.state.filterText !== '') {
            this.setState({ filterText: '' });
        }
    };
    FilterAndSortBar.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var categoryFieldName = 'categories';
        var facetValues = this.filteredFacets();
        var facet = this.openFacet();
        var unFilteredFacetValues = this.openFacetValues();
        var showFilterSearch = this.state.openFacetFieldName !== categoryFieldName && unFilteredFacetValues.length > DEFAULT_VISIBLE_FACET_COUNT;
        var sortButtonText = translate('/Shared/Sort');
        var selectedFacetValueCount = 0;
        this.state.facets.forEach(function (f) {
            selectedFacetValueCount += allSelectedValues(f.facetValues).length;
        });
        if (!this.state.sortingIsOpen) {
            sortButtonText = this.props.sortOptions.find(function (o) { return o.value === _this.props.sortBy; }).text;
        }
        var alphabeticalValues = facetValues;
        var popularValues = [];
        if (!!facet && facet.sortOrder !== 'alphabetical' && unFilteredFacetValues.length > 0 && showFilterSearch) {
            if (this.state.filterText === '') {
                popularValues = facetValues.slice(0, POPULAR_FACET_COUNT);
                alphabeticalValues = facetValues.slice(POPULAR_FACET_COUNT);
            }
            alphabeticalValues.sort(function (a, b) { return compareStrings(a.text, b.text); });
        }
        var facetValueList = function (facetValues, listClassName) {
            if (listClassName === void 0) { listClassName = ''; }
            return (facetValues.length > 0 && (React.createElement("ul", { className: listClassName }, facetValues.map(function (v) {
                var _a;
                return (React.createElement("li", { key: v.fieldName + '-' + v.id },
                    React.createElement("button", { type: "button", className: classnames(styles.facetValueButton, (_a = {}, _a[styles.disabled] = v.count === 0, _a)), onClick: function () { return _this.onFacetValueChange(_this.state.openFacetFieldName, v, !v.selected); } },
                        v.selected && (React.createElement("span", { className: styles.selectedFacetCheck },
                            React.createElement(Icon, { name: "check" }))),
                        React.createElement("span", { className: styles.facetValue },
                            React.createElement("span", { className: styles.facetValueText }, v.text),
                            React.createElement("span", { className: styles.count },
                                "(",
                                v.count,
                                ")")))));
            }))));
        };
        var categoryList = function () {
            var categories = _this.state.categories;
            return (!!categories &&
                categories.length > 1 && (React.createElement("ul", null, categories.map(function (category) { return (React.createElement("li", { key: category.code },
                React.createElement("a", { href: category.url, "data-ticket": category.ticket, className: styles.facetValueButton },
                    React.createElement("span", { className: styles.facetValue },
                        React.createElement("span", { className: styles.facetValueText }, category.displayName),
                        React.createElement("span", { className: styles.count },
                            "(",
                            category.productCount,
                            ")"))))); }))));
        };
        var isOpen = !!this.isOpen();
        return (React.createElement("div", { className: classnames(styles.base, (_a = {}, _a[styles.isOpen] = this.state.facetsIsOpen || this.state.sortingIsOpen, _a)) },
            !!!this.props.disableSorting &&
                React.createElement(Overlay, { enabled: this.state.sortingIsOpen, onClose: function () { return _this.setState({ sortingIsOpen: false }); } },
                    React.createElement("div", { ref: function (el) { return (_this.sortOptionsElement = el); }, className: classnames(styles.sorting, (_b = {},
                            _b[styles.open] = this.state.sortingIsOpen,
                            _b)) },
                        React.createElement(Button, { appearance: ['tertiary', 'large'], className: styles.toggler, onClick: function () { return _this.setState({ sortingIsOpen: !_this.state.sortingIsOpen }); } },
                            React.createElement("span", null, sortButtonText),
                            this.state.sortingIsOpen ? React.createElement(Icon, { name: "close" }) : React.createElement(Icon, { name: "arrow-down" })),
                        React.createElement("ul", { className: styles.sortList }, this.props.sortOptions.map(function (o) {
                            var isSelected = o.value === _this.props.sortBy;
                            return (React.createElement("li", { key: o.value },
                                React.createElement(Button, { appearance: 'pale', className: styles.sortOptionButton, onClick: function () { return _this.onSortChange(o.value); } },
                                    isSelected && (React.createElement("span", { className: styles.selectedSortOptionCheck },
                                        React.createElement(Icon, { name: "check" }))),
                                    React.createElement("span", null, o.text))));
                        })))),
            !!this.props.disableSorting && React.createElement("div", { ref: function (el) { return (_this.sortOptionsElement = el); }, className: classnames(styles.sorting, (_c = {},
                    _c[styles.open] = this.state.sortingIsOpen,
                    _c)) },
                React.createElement("div", { className: styles.toggler })),
            React.createElement(Overlay, { enabled: this.state.facetsIsOpen, onClose: function () { return _this.toggleFacets(); }, overlayClassName: styles.overlay, className: styles.filteringOverlayContainer },
                React.createElement("div", { className: classnames(styles.filtering, (_d = {},
                        _d[styles.open] = this.state.facetsIsOpen,
                        _d[styles.sortingDisabled] = this.props.disableSorting,
                        _d[styles.openAndSelected] = !!isOpen,
                        _d)) },
                    React.createElement("div", { className: classnames(styles.filters, (_e = {},
                            _e[styles.open] = !!isOpen,
                            _e)) }, !!isOpen && (React.createElement("div", null,
                        React.createElement(Button, { appearance: 'tertiary', className: classnames(styles.closeFacets, styles.closeHeader), onClick: function () { return _this.toggleFacets(); } }),
                        React.createElement("div", { className: styles.facetValueList },
                            React.createElement("div", { className: classnames(styles.values, (_f = {},
                                    _f[styles.filteredList] = this.state.filterText !== '',
                                    _f[styles.searchable] = showFilterSearch,
                                    _f[styles.ios] = isIOS(),
                                    _f)) },
                                !!facet && !!facet.canToggleOrAnd && facet.facetValues.filter(function (v) { return v.selected; }).length > 1 && (React.createElement(FacetAndOrFilter, { fieldName: facet.fieldName, hasAndFilter: facet.hasAndFilter, onAndOrFilterChange: function (andOrFilter) { return _this.props.setAndOrFilter(facet.fieldName, andOrFilter); } })),
                                this.state.filterText !== '' ? (React.createElement("div", { className: styles.valuesHeading }, translate('/Shared/SearchResults'))) : (popularValues.length > 0 && (React.createElement("div", { className: styles.valuesHeading }, translate('/Shared/PopularChoices')))),
                                this.state.openFacetFieldName === categoryFieldName ? (React.createElement("div", null, categoryList())) : (React.createElement("div", null,
                                    facetValueList(popularValues, styles.popular),
                                    facetValueList(alphabeticalValues)))),
                            !!alphabeticalValues && alphabeticalValues.length <= 0 && React.createElement("div", null, translate('/Shared/NoHits'))),
                        React.createElement(Button, { appearance: "secondary", className: styles.setFacets, onClick: function () { return _this.toggleFacets(); } }, translate('/Shared/Ok'))))),
                    React.createElement("div", { className: styles.facets },
                        React.createElement(Button, { appearance: ['tertiary', 'large'], className: styles.toggler, onClick: function () { return _this.toggleFacets(); }, disabled: this.state.facets.length === 0 },
                            React.createElement("span", null, translate('/Shared/Filter')),
                            React.createElement(Icon, { name: "filter" })),
                        React.createElement("div", { className: styles.facetList },
                            React.createElement("ul", null,
                                !!!this.props.disableCategories && !!this.state.categories && this.state.categories.length > 1 && (React.createElement("li", { key: categoryFieldName },
                                    React.createElement("button", { className: classnames(styles.openFacetButton, (_g = {},
                                            _g[styles.isOpen] = categoryFieldName === this.state.openFacetFieldName,
                                            _g)), type: "button", onClick: function () { return _this.setOpenFacet(categoryFieldName); } },
                                        React.createElement("span", { className: styles.facetName }, translate('/facets/department')),
                                        React.createElement(Icon, { name: "arrow-right" })))),
                                this.state.facets.map(function (f) {
                                    var _a;
                                    var selectedCount = allSelectedValues(f.facetValues).length;
                                    return (React.createElement("li", { key: f.fieldName },
                                        React.createElement("button", { className: classnames(styles.openFacetButton, (_a = {},
                                                _a[styles.isOpen] = f.fieldName === _this.state.openFacetFieldName,
                                                _a)), type: "button", onClick: function () { return _this.setOpenFacet(f.fieldName); } },
                                            React.createElement("span", { className: styles.facetName }, f.displayName),
                                            selectedCount > 0 ? (React.createElement("span", { className: styles.facetIndicator }, selectedCount)) : (React.createElement(Icon, { name: "arrow-right" })))));
                                })),
                            React.createElement(Button, { appearance: "monochrome", className: styles.clearFacets, onClick: function () { return _this.clearFacets(); } },
                                React.createElement(Icon, { name: "remove-circle" }),
                                React.createElement("span", null, translate('/Shared/ClearFilter'))))))),
            this.state.facetsIsOpen && !isOpen && (React.createElement(Button, { className: classnames(styles.closeFacets, styles.filterFloatingButton), onClick: function () { return _this.toggleFacets(); } },
                React.createElement("span", null, translate('/Shared/Close')),
                React.createElement(Icon, { name: "close" })))));
    };
    return FilterAndSortBar;
}(React.Component));
export default FilterAndSortBar;
