var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Button from 'Shared/Button';
import Modal, { ModalType } from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { translate } from 'Shared/translate';
import * as styles from './../MasterCustomersPage/base.scss';
export default function ConfirmRemoveMaster(props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: styles.deleteBtn, appearance: "primary", onClick: function () { return setOpen(true); } }, translate('/Admin/MasterCustomers/RemoveMaster')),
        React.createElement(Modal, { isOpen: isOpen, onClose: function () { return setOpen(false); }, title: translate('/Shared/Delete'), type: ModalType.Warning },
            React.createElement("p", null, translate('/Admin/MasterCustomers/ConfirmRemoveMaster', props.masterName)),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: props.onUpdateMaster, onCancel: function () { return setOpen(false); }, onStatusWasFulfilled: function () { return setOpen(false); } }))));
}
