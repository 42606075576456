var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Overlay from 'Shared/Overlay';
import Tooltip from 'Shared/Tooltip';
var ProductCardListHeader = function (_a) {
    var currentBreakpoint = _a.currentBreakpoint, isSupplierSalesman = _a.isSupplierSalesman, hasEmv = _a.hasEmv;
    var _b = __read(useState(false), 2), showTooltip = _b[0], setShowTooltip = _b[1];
    return (currentBreakpoint > SMALL && (React.createElement("div", { className: styles.header },
        React.createElement("div", { className: styles.headerFlexWrapper }),
        !!hasEmv && (React.createElement("div", { className: styles.emvHeaderSpace },
            React.createElement("div", { "data-ab": "B", className: styles.emvTitleWrapper },
                React.createElement("h3", { className: styles.emvTitle }, translate('/Checkout/EMVHeaderTitle')),
                React.createElement("div", null,
                    React.createElement(Button, { className: styles.helpButton, appearance: "secondary", onClick: function () { return setShowTooltip(true); } },
                        React.createElement(Icon, { name: "question" })),
                    showTooltip && (React.createElement(Overlay, { onClose: function () { return !IS_APP && setShowTooltip(false); }, isTransparent: true },
                        React.createElement(Tooltip, { close: function () { return setShowTooltip(false); }, closeButton: true, closeOnClick: false, horizontal: "left" },
                            React.createElement("div", { className: styles.tooltip },
                                React.createElement("p", null, translate('/Checkout/HelpTextEmv')))))))))))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(ProductCardListHeader);
