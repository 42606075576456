import React from 'react';
import * as styles from './base.scss';
import classnames from 'classnames';
var MenuItem = function (_a) {
    var _b, _c;
    var isCurrent = _a.isCurrent, toggle = _a.toggle, isLoggedIn = _a.isLoggedIn, isDisabledForGuestUsers = _a.isDisabledForGuestUsers, url = _a.url, name = _a.name;
    return (React.createElement("div", { className: classnames(styles.myMenigoMenuItems, (_b = {},
            _b[styles.isCurrent] = isCurrent,
            _b)) },
        React.createElement("a", { onClick: toggle, href: isLoggedIn || !isDisabledForGuestUsers ? url : '#', className: classnames(styles.myMenigoMenuItems__item, (_c = {},
                _c[styles.disabled] = !isLoggedIn && isDisabledForGuestUsers,
                _c)) }, name)));
};
export default MenuItem;
