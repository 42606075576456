var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import * as styles from './base.scss';
export var ProductMark = function (props) { return (React.createElement("span", { className: classnames(props.className, styles.productMark, styles[props.layout]) }, props.text)); };
function isNonStockable(props) {
    return (!props.quotationOnly && !props.isCloseOut && !props.isInCampaign && !props.isStockable && !props.isDiscontinued);
}
function haveMarkings(props) {
    return props.markings && props.markings.length > 0;
}
function haveProductMark(props) {
    return haveMarkings(props) || props.isCloseOut || props.isInCampaign || isNonStockable(props) || props.isInQuotation;
}
export default (function (props) {
    var _a;
    return haveProductMark(props) && (React.createElement("div", { className: classnames(styles.base, (_a = {},
            _a[styles.productMarkWrapperList] = props.isRow,
            _a[styles.productMarkWrapper] = !props.isRow,
            _a[styles.inline] = props.inline,
            _a)) },
        haveMarkings(props) && props.markings.map(function (mark) { return React.createElement(ProductMark, __assign({}, mark, { className: props.className })); }),
        (props.isCloseOut && !props.nonStockableOnly && (React.createElement(ProductMark, { text: translate('/Product/CloseOutCampaign'), layout: "campaign", className: props.className }))) ||
            (props.isInCampaign && !props.nonStockableOnly && (React.createElement(ProductMark, { text: translate('/Product/Campaign'), layout: "campaign", className: props.className }))),
        isNonStockable(props) && (React.createElement(ProductMark, { text: translate('/Product/NonStockable'), layout: "primary", className: props.className })),
        props.isInQuotation && !props.nonStockableOnly && (React.createElement(ProductMark, { text: translate('/Product/Quotation'), layout: "secondary", className: props.className }))));
});
