var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { MEDIUM } from 'Shared/breakpoints';
import { scrollPosition } from 'SiteLayout/Root';
import connect from 'Shared/connect';
import MiniCart from 'SiteLayout/MiniCart';
import Main from 'Shared/PageLayout';
import miniCartIsDisabled from 'SiteLayout/MiniCart/is-disabled-selector';
import { on } from 'SiteLayout/Root';
import { translate } from 'Shared/translate';
import { setMainMenu } from 'SiteLayout/MainMenu/action-creators';
import * as styles from './base.scss';
import { closeQuickSearch } from '../../../Search/action-creators';
import TopBar from './Components/TopBar/TopBar';
import BottomBar from './Components/BottomBar/BottomBar';
import Overlay from 'Shared/Overlay';
var FadeHeader = /** @class */ (function (_super) {
    __extends(FadeHeader, _super);
    function FadeHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.close = function () {
            !!_this.props.closeMainMenu && _this.props.closeMainMenu();
        };
        _this.state = {
            isSticky: true,
            isVisible: true,
        };
        return _this;
    }
    FadeHeader.prototype.calculateHeight = function () {
        this.rootElementOffsetHeight = this.rootElement.offsetHeight;
    };
    FadeHeader.prototype.componentWillUnmount = function () {
        this.unsubscribeAutoHide && this.unsubscribeAutoHide();
        this.unsubscribeResize && this.unsubscribeResize();
    };
    FadeHeader.prototype.componentDidMount = function () {
        var _this = this;
        this.calculateHeight();
        this.unsubscribeAutoHide = on('scroll', function () { return _this.stickyAutoHideListener(); });
        this.unsubscribeResize = on('resize', function () { return _this.calculateHeight(); });
    };
    FadeHeader.prototype.stickyAutoHideListener = function () {
        var isVisible;
        if (this.props.currentBreakpoint < MEDIUM) {
            isVisible = true;
        }
        else {
            var y = scrollPosition();
            var isBelowPlaceholder = y > this.rootElementOffsetHeight / 3;
            isVisible = !isBelowPlaceholder;
        }
        if (this.state.isVisible !== isVisible) {
            this.setState({ isVisible: isVisible });
        }
        this.currentScrollY = scrollPosition();
    };
    FadeHeader.prototype.render = function () {
        var _this = this;
        var isMobile = this.props.currentBreakpoint < MEDIUM;
        return (React.createElement("header", { id: "mainHeader", className: classnames(styles.placeholder), ref: function (el) { return (_this.rootElement = el); } },
            React.createElement(Overlay, { enabled: this.props.isOpen && !isMobile, onClose: this.close },
                React.createElement("div", { className: classnames(styles.base, styles.sticky) },
                    React.createElement(TopBar, { small: !this.state.isVisible }),
                    this.props.miniCartIsOpen && (React.createElement("div", { className: styles.miniCartWrapper },
                        React.createElement(Main, null,
                            React.createElement(MiniCart, null)))),
                    React.createElement(BottomBar, { small: !this.state.isVisible, miniCartIsDisabled: this.props.miniCartIsDisabled, currentBreakpoint: this.props.currentBreakpoint, mainMenu: this.props.mainMenu, isLoggedIn: this.props.isLoggedIn, currentPage: this.props.currentPage })),
                React.createElement("noscript", { className: styles.noscript }, translate('/Errors/JavaScriptRequired')))));
    };
    return FadeHeader;
}(React.PureComponent));
export default connect(function (state) { return ({
    miniCartIsOpen: state.miniCart.isOpen,
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
    isOpen: state.mainMenu.isOpen || state.quickSearch.isOpen,
    miniCartIsDisabled: miniCartIsDisabled(state),
    currentBreakpoint: state.currentBreakpoint,
    mainMenu: state.mainMenu,
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    currentUser: state.currentUser,
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    closeMainMenu: function () { return dispatch(setMainMenu(false)); },
    closeQuickSearch: function () { return dispatch(closeQuickSearch()); },
}); })(FadeHeader);
