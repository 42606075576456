// This module exists both to handle browsers that doesn't have `sessionStorage`
// and for private browsing in iOS which throws exceptions when you call `setItem`
var fauxStorage = {};
export function set(key, value) {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
    catch (e) {
        fauxStorage[key] = value;
        console.error('sessionStorage.setItem() failed:', e);
    }
}
export function get(key, defaultValue) {
    try {
        var value = sessionStorage.getItem(key);
        if (value === null) {
            return defaultValue;
        }
        else {
            return JSON.parse(value);
        }
    }
    catch (e) {
        console.error('sessionStorage.getItem() failed:', e);
        return fauxStorage[key];
    }
}
