var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as styles from './base.scss';
import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import Input from 'Shared/Fields/Input';
import { Checkbox } from 'Shared/Fields';
import FeedbackButton from 'Shared/Button/Feedback';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import PropertyFor from 'Shared/PropertyFor';
import * as server from 'Shared/server';
import { Form } from 'Shared/PageLayout';
import connect from 'Shared/connect';
var NewsLetterFormBlock = /** @class */ (function (_super) {
    __extends(NewsLetterFormBlock, _super);
    function NewsLetterFormBlock(props) {
        var _this = _super.call(this, props) || this;
        var tmp = 'dfh';
        if (typeof props.newsletterPostData === 'undefined') {
            tmp = null;
        }
        else {
            tmp = props.newsletterPostData.prenumerationlink;
        }
        _this.state = {
            newsletterPostData: { email: '', prenumerationlink: tmp },
        };
        return _this;
    }
    NewsLetterFormBlock.prototype.onSubmit = function (e) {
        e.preventDefault();
        this.feedbackButton.activate(server.postJson('/NewsletterForm/Index', this.state.newsletterPostData).then(function (resp) {
            if (resp) {
                return Promise.resolve();
            }
            else {
                return Promise.reject(null);
            }
        }));
    };
    NewsLetterFormBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.newsletter },
            React.createElement("h2", null, propertyValueFor(this.props.header)),
            React.createElement(PropertyFor, { property: this.props.mainBody, tagName: "div" }),
            React.createElement(Form, { id: "checkout-form", appearance: "default", onSubmit: function (e) { return _this.onSubmit(e); } },
                React.createElement(Input, { type: "email", name: "email", required: true, value: this.state.newsletterPostData.email, ref: function (el) { return (_this.emailElement = el); }, placeholder: propertyValueFor(this.props.placeholderEmailField), onChange: function (e) {
                        return _this.setState({
                            newsletterPostData: {
                                email: e.target.value,
                                prenumerationlink: _this.state.newsletterPostData.prenumerationlink,
                            },
                        });
                    } }),
                React.createElement("div", { className: styles.checkboxWrapper },
                    React.createElement(Checkbox, { required: true, name: "agree" },
                        React.createElement(PropertyFor, { property: this.props.legalBody, tagName: "strong" })),
                    React.createElement(FeedbackButton, { type: "submit", ref: function (el) { return (_this.feedbackButton = el); } }, propertyValueFor(this.props.labelOnBotton))))));
    };
    return NewsLetterFormBlock;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(NewsLetterFormBlock);
