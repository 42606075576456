// This file has been generated by the Jake build. Do not alter
// it manually as your changes will be overwritten. Run:
// jake.cmd frontend:build-component-registry
// instead.
/* tslint:disable:max-line-length */
var registry = {
    'Account.Pages.AccountSettings.AccountSettingsPage': require('../Account/Pages/AccountSettings/AccountSettingsPage').default,
    'Account.Pages.AccountSettings.PopUpInputForm': require('../Account/Pages/AccountSettings/PopUpInputForm').default,
    'Account.Pages.AccountSettings.PopUpMessage': require('../Account/Pages/AccountSettings/PopUpMessage').default,
    'Account.Pages.ForgotPassword.ForgotPasswordPage': require('../Account/Pages/ForgotPassword/ForgotPasswordPage').default,
    'Account.Pages.Impersonation.ImpersonationPage': require('../Account/Pages/Impersonation/ImpersonationPage').default,
    'Account.Pages.Supplier.SupplierLoginPage': require('../Account/Pages/Supplier/SupplierLoginPage').default,
    'Ad.Pages.SupplierAd.SupplierAdPage': require('../Ad/Pages/SupplierAd/SupplierAdPage').default,
    'Admin.Pages.AccountSettingsAdminPage.AccountSettingsAdminPage': require('../Admin/Pages/AccountSettingsAdminPage/AccountSettingsAdminPage').default,
    'Admin.Pages.ClassificationPage.ClassificationPage': require('../Admin/Pages/ClassificationPage/ClassificationPage').default,
    'Admin.Pages.DownloadFilesPage.DownloadFilesOverviewPage': require('../Admin/Pages/DownloadFilesPage/DownloadFilesOverviewPage').default,
    'Admin.Pages.ExhibitionsPage.ExhibitionItem': require('../Admin/Pages/ExhibitionsPage/ExhibitionItem').default,
    'Admin.Pages.ExhibitionsPage.ExhibitionsOverviewPage': require('../Admin/Pages/ExhibitionsPage/ExhibitionsOverviewPage').default,
    'Admin.Pages.FavoritesPantriesPage.FavoritesPantriesPage': require('../Admin/Pages/FavoritesPantriesPage/FavoritesPantriesPage').default,
    'Admin.Pages.FavoritesPantriesPage.ProductList': require('../Admin/Pages/FavoritesPantriesPage/ProductList').default,
    'Admin.Pages.LogFilePage.LogFileOverviewPage': require('../Admin/Pages/LogFilePage/LogFileOverviewPage').default,
    'Admin.Pages.MasterCustomersPage.MasterCustomersPage': require('../Admin/Pages/MasterCustomersPage/MasterCustomersPage').default,
    'Admin.Pages.PunchoutPage.PunchoutOverviewPage': require('../Admin/Pages/PunchoutPage/PunchoutOverviewPage').default,
    'Admin.Pages.RecipeGroupPage.RecipeGroup': require('../Admin/Pages/RecipeGroupPage/RecipeGroup').default,
    'Admin.Pages.RecipeGroupPage.RecipeGroupPage': require('../Admin/Pages/RecipeGroupPage/RecipeGroupPage').default,
    'Admin.Pages.RecipeMasterPage.RecipeMasterPage': require('../Admin/Pages/RecipeMasterPage/RecipeMasterPage').default,
    'Admin.Pages.Shared.ConfirmRemoveMaster': require('../Admin/Pages/Shared/ConfirmRemoveMaster').default,
    'Admin.Pages.Shared.ConfirmRemoveSlave': require('../Admin/Pages/Shared/ConfirmRemoveSlave').default,
    'AppShell.Partials.AppShell': require('../AppShell/Partials/AppShell').default,
    'Campaign.Pages.Campaign.CampaignPage': require('../Campaign/Pages/Campaign/CampaignPage').default,
    'Campaign.Pages.CampaignOverview.CampaignOverviewPage': require('../Campaign/Pages/CampaignOverview/CampaignOverviewPage').default,
    'Category.Commerce.Category': require('../Category/Commerce/Category').default,
    'Category.Commerce.Components.CategoryHeader': require('../Category/Commerce/Components/CategoryHeader').default,
    'Category.Commerce.Components.CategoryHero': require('../Category/Commerce/Components/CategoryHero').default,
    'Checkout.Pages.Checkout.CheckoutPage': require('../Checkout/Pages/Checkout/CheckoutPage').default,
    'Checkout.Pages.Checkout.CompletePurchaseModal': require('../Checkout/Pages/Checkout/CompletePurchaseModal').default,
    'Checkout.Pages.Checkout.CurrentOrders': require('../Checkout/Pages/Checkout/CurrentOrders').default,
    'Checkout.Pages.Checkout.Discount': require('../Checkout/Pages/Checkout/Discount').default,
    'Checkout.Pages.Checkout.DiscountInfo': require('../Checkout/Pages/Checkout/DiscountInfo').default,
    'Checkout.Pages.Checkout.Freight': require('../Checkout/Pages/Checkout/Freight').default,
    'Checkout.Pages.OrderConfirmation.OrderConfirmationPage': require('../Checkout/Pages/OrderConfirmation/OrderConfirmationPage').default,
    'Checkout.Pages.OrderConfirmation.OrderDetails.OrderStoreInfo': require('../Checkout/Pages/OrderConfirmation/OrderDetails/OrderStoreInfo').default,
    'Checkout.Pages.OrderConfirmation.OrderDetails.OrderSummary': require('../Checkout/Pages/OrderConfirmation/OrderDetails/OrderSummary').default,
    'Cms.Blocks.CampaignOverview.CampaignOverviewBlock': require('../Cms/Blocks/CampaignOverview/CampaignOverviewBlock').default,
    'Cms.Blocks.CollageBlock.CollageBlock': require('../Cms/Blocks/CollageBlock/CollageBlock').default,
    'Cms.Blocks.ContentBlock.ContentBlock': require('../Cms/Blocks/ContentBlock/ContentBlock').default,
    'Cms.Blocks.ContentContainerBlock.ContentContainerBlock': require('../Cms/Blocks/ContentContainerBlock/ContentContainerBlock').default,
    'Cms.Blocks.ContentContainerBlock.ContentContainerContentCard': require('../Cms/Blocks/ContentContainerBlock/ContentContainerContentCard').default,
    'Cms.Blocks.DiscountFreight.Discount': require('../Cms/Blocks/DiscountFreight/Discount').default,
    'Cms.Blocks.DiscountFreight.DiscountFreightBlock': require('../Cms/Blocks/DiscountFreight/DiscountFreightBlock').default,
    'Cms.Blocks.DiscountFreight.Freight': require('../Cms/Blocks/DiscountFreight/Freight').default,
    'Cms.Blocks.EditorialImage.EditorialImageBlock': require('../Cms/Blocks/EditorialImage/EditorialImageBlock').default,
    'Cms.Blocks.HeroBanner.HeroBannerBlock': require('../Cms/Blocks/HeroBanner/HeroBannerBlock').default,
    'Cms.Blocks.HeroBlock.HeroBlock': require('../Cms/Blocks/HeroBlock/HeroBlock').default,
    'Cms.Blocks.InfoBlock.InfoBlock': require('../Cms/Blocks/InfoBlock/InfoBlock').default,
    'Cms.Blocks.Inspiration.InspirationBlock': require('../Cms/Blocks/Inspiration/InspirationBlock').default,
    'Cms.Blocks.InspirationImageBlock.InspirationImageBlock': require('../Cms/Blocks/InspirationImageBlock/InspirationImageBlock').default,
    'Cms.Blocks.LinkBanner.LinkBannerBlock': require('../Cms/Blocks/LinkBanner/LinkBannerBlock').default,
    'Cms.Blocks.OldCampaignOverview.OldCampaignOverviewBlock': require('../Cms/Blocks/OldCampaignOverview/OldCampaignOverviewBlock').default,
    'Cms.Blocks.ShortcutButtonsBlock.ShortcutButtonsBlock': require('../Cms/Blocks/ShortcutButtonsBlock/ShortcutButtonsBlock').default,
    'Cms.Blocks.SocailMediaFooterBlock.SocialMediaFooterBlock': require('../Cms/Blocks/SocailMediaFooterBlock/SocialMediaFooterBlock').default,
    'Cms.Pages.Article.ArticlePage': require('../Cms/Pages/Article/ArticlePage').default,
    'Cms.Pages.Cms.CmsPage': require('../Cms/Pages/Cms/CmsPage').default,
    'Cms.Pages.ContentOverview.ContentOverviewPage': require('../Cms/Pages/ContentOverview/ContentOverviewPage').default,
    'Cms.Pages.FeaturePage.FeaturePage': require('../Cms/Pages/FeaturePage/FeaturePage').default,
    'Cms.Pages.InspirationOverview.InspirationOverviewPage': require('../Cms/Pages/InspirationOverview/InspirationOverviewPage').default,
    'Cms.Pages.News.NewsItemPage': require('../Cms/Pages/News/NewsItemPage').default,
    'Cms.Pages.News.NewsOverviewPage': require('../Cms/Pages/News/NewsOverviewPage').default,
    'Cms.Pages.News.NewsSideBar': require('../Cms/Pages/News/NewsSideBar').default,
    'Complaints.Pages.ComplaintOverviewPage': require('../Complaints/Pages/ComplaintOverviewPage').default,
    'CustomProducts.Pages.CustomProductOverview.CustomProductOverviewPage': require('../CustomProducts/Pages/CustomProductOverview/CustomProductOverviewPage').default,
    'Error.Pages.PageNotFound': require('../Error/Pages/PageNotFound').default,
    'Favorites.Pages.Details.FavoriteListDetailsPage': require('../Favorites/Pages/Details/FavoriteListDetailsPage').default,
    'Favorites.Pages.Details.FavoriteListItem': require('../Favorites/Pages/Details/FavoriteListItem').default,
    'Favorites.Pages.List.Actionbar.CopyDialog': require('../Favorites/Pages/List/Actionbar/CopyDialog').default,
    'Favorites.Pages.List.Actionbar.DeleteDialog': require('../Favorites/Pages/List/Actionbar/DeleteDialog').default,
    'Favorites.Pages.List.Actionbar.MoveDialog': require('../Favorites/Pages/List/Actionbar/MoveDialog').default,
    'Favorites.Pages.List.Actionbar.PantryDialog': require('../Favorites/Pages/List/Actionbar/PantryDialog').default,
    'Favorites.Pages.List.FavoriteList': require('../Favorites/Pages/List/FavoriteList').default,
    'Favorites.Pages.List.FavoriteListListPage': require('../Favorites/Pages/List/FavoriteListListPage').default,
    'Favorites.Pages.List.FavoriteOverviewPage': require('../Favorites/Pages/List/FavoriteOverviewPage').default,
    'InfoModal.Pages.InfoModalPage': require('../InfoModal/Pages/InfoModalPage').default,
    'InvoiceOverview.Pages.InvoiceList': require('../InvoiceOverview/Pages/InvoiceList').default,
    'InvoiceOverview.Pages.InvoiceOverviewPage': require('../InvoiceOverview/Pages/InvoiceOverviewPage').default,
    'InvoiceOverview.Pages.InvoiceOverviewPageModel': require('../InvoiceOverview/Pages/InvoiceOverviewPageModel').default,
    'Ips.Pages.Ips.FeedbackPage': require('../Ips/Pages/Ips/FeedbackPage').default,
    'Ips.Pages.Ips.IpsItemRow': require('../Ips/Pages/Ips/IpsItemRow').default,
    'Ips.Pages.Ips.IpsPage': require('../Ips/Pages/Ips/IpsPage').default,
    'Ips.Pages.ShelfLabel.ShelfLabelItemRow': require('../Ips/Pages/ShelfLabel/ShelfLabelItemRow').default,
    'Ips.Pages.ShelfLabel.ShelfLabelPage': require('../Ips/Pages/ShelfLabel/ShelfLabelPage').default,
    'LoginAccounts.Pages.LoginAccountsPage': require('../LoginAccounts/Pages/LoginAccountsPage').default,
    'MyMenigo.Blocks.AddOnDetailBlock.AddOnDetailBlock': require('../MyMenigo/Blocks/AddOnDetailBlock/AddOnDetailBlock').default,
    'MyMenigo.Blocks.AddOnDetailBlock.Components.AddOnDetailContent': require('../MyMenigo/Blocks/AddOnDetailBlock/Components/AddOnDetailContent').default,
    'MyMenigo.Blocks.CustomerArrangementBlock.CustomerArrangementBlock': require('../MyMenigo/Blocks/CustomerArrangementBlock/CustomerArrangementBlock').default,
    'MyMenigo.Blocks.CustomerArrangementContactBlock.CustomerArrangementContactBlock': require('../MyMenigo/Blocks/CustomerArrangementContactBlock/CustomerArrangementContactBlock').default,
    'MyMenigo.Blocks.CustomerProfile.CustomerProfileBlock': require('../MyMenigo/Blocks/CustomerProfile/CustomerProfileBlock').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DeliveryImage.DeliveryImage': require('../MyMenigo/Blocks/DeliveryBlock/Components/DeliveryImage/DeliveryImage').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DeliveryList.DeliveryHeaderRow': require('../MyMenigo/Blocks/DeliveryBlock/Components/DeliveryList/DeliveryHeaderRow').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DeliveryList.DeliveryList': require('../MyMenigo/Blocks/DeliveryBlock/Components/DeliveryList/DeliveryList').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DeliveryList.DetailedDeliveryRow': require('../MyMenigo/Blocks/DeliveryBlock/Components/DeliveryList/DetailedDeliveryRow').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DetailedProductList.DeliveryDetails': require('../MyMenigo/Blocks/DeliveryBlock/Components/DetailedProductList/DeliveryDetails').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DetailedProductList.DetailedProductList': require('../MyMenigo/Blocks/DeliveryBlock/Components/DetailedProductList/DetailedProductList').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DetailedProductList.DetailedProductListHeader': require('../MyMenigo/Blocks/DeliveryBlock/Components/DetailedProductList/DetailedProductListHeader').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DetailedProductList.DetailedProductRow': require('../MyMenigo/Blocks/DeliveryBlock/Components/DetailedProductList/DetailedProductRow').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.DetailedProductList.RecycleItems': require('../MyMenigo/Blocks/DeliveryBlock/Components/DetailedProductList/RecycleItems').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.Toolbar.Toolbar': require('../MyMenigo/Blocks/DeliveryBlock/Components/Toolbar/Toolbar').default,
    'MyMenigo.Blocks.DeliveryBlock.Components.Tooltip.ToolTip': require('../MyMenigo/Blocks/DeliveryBlock/Components/Tooltip/ToolTip').default,
    'MyMenigo.Blocks.DeliveryBlock.DeliveryBlock': require('../MyMenigo/Blocks/DeliveryBlock/DeliveryBlock').default,
    'MyMenigo.Blocks.DeliveryOverviewBlock.Components.DeliveryBox': require('../MyMenigo/Blocks/DeliveryOverviewBlock/Components/DeliveryBox').default,
    'MyMenigo.Blocks.DeliveryOverviewBlock.Components.DeliveryRow': require('../MyMenigo/Blocks/DeliveryOverviewBlock/Components/DeliveryRow').default,
    'MyMenigo.Blocks.DeliveryOverviewBlock.Components.TodaysDeliveries': require('../MyMenigo/Blocks/DeliveryOverviewBlock/Components/TodaysDeliveries').default,
    'MyMenigo.Blocks.DeliveryOverviewBlock.Components.TruckPictogram': require('../MyMenigo/Blocks/DeliveryOverviewBlock/Components/TruckPictogram').default,
    'MyMenigo.Blocks.DeliveryOverviewBlock.DeliveryOverviewBlock': require('../MyMenigo/Blocks/DeliveryOverviewBlock/DeliveryOverviewBlock').default,
    'MyMenigo.Blocks.MyDeliveryTimesBlock.MyDeliveryTimesBlock': require('../MyMenigo/Blocks/MyDeliveryTimesBlock/MyDeliveryTimesBlock').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryList.OrderHistoryHeaderRow': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryList/OrderHistoryHeaderRow').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryList.OrderHistoryList': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryList/OrderHistoryList').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryList.OrderHistoryRow': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryList/OrderHistoryRow').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryProductList.OrderHistoryDetails': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryProductList/OrderHistoryDetails').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryProductList.OrderHistoryProductList': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryProductList/OrderHistoryProductList').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryProductList.OrderHistoryProductListHeader': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryProductList/OrderHistoryProductListHeader').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryProductList.OrderHistoryProductRow': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryProductList/OrderHistoryProductRow').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.OrderHistoryProductList.RecycleItems': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/OrderHistoryProductList/RecycleItems').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.Components.Tooltip.ToolTip': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/Components/Tooltip/ToolTip').default,
    'MyMenigo.Blocks.MyMenigoOrderHistoryBlock.MyMenigoOrderHistoryBlock': require('../MyMenigo/Blocks/MyMenigoOrderHistoryBlock/MyMenigoOrderHistoryBlock').default,
    'MyMenigo.Blocks.MyPriceListBlock.Components.Product.ProductList': require('../MyMenigo/Blocks/MyPriceListBlock/Components/Product/ProductList').default,
    'MyMenigo.Blocks.MyPriceListBlock.Components.Product.ProductListItem': require('../MyMenigo/Blocks/MyPriceListBlock/Components/Product/ProductListItem').default,
    'MyMenigo.Blocks.MyPriceListBlock.Components.Product.ProductListItemPrice': require('../MyMenigo/Blocks/MyPriceListBlock/Components/Product/ProductListItemPrice').default,
    'MyMenigo.Blocks.MyPriceListBlock.MyPriceListBlock': require('../MyMenigo/Blocks/MyPriceListBlock/MyPriceListBlock').default,
    'MyMenigo.Blocks.NewFeaturesInfoBlock.NewFeaturesInfoBlock': require('../MyMenigo/Blocks/NewFeaturesInfoBlock/NewFeaturesInfoBlock').default,
    'MyMenigo.Blocks.NewFeaturesInfoBlock.NewFeaturesInfoBlock.type': require('../MyMenigo/Blocks/NewFeaturesInfoBlock/NewFeaturesInfoBlock.type').default,
    'MyMenigo.Blocks.NewFeaturesInfoBlock.NewFeaturesInfoBlockViewModel.type': require('../MyMenigo/Blocks/NewFeaturesInfoBlock/NewFeaturesInfoBlockViewModel.type').default,
    'MyMenigo.Blocks.OrderHistoryBlock.OrderHistoryBlock': require('../MyMenigo/Blocks/OrderHistoryBlock/OrderHistoryBlock').default,
    'MyMenigo.Blocks.OrderOverview.AddOrder.AddToButton': require('../MyMenigo/Blocks/OrderOverview/AddOrder/AddToButton').default,
    'MyMenigo.Blocks.OrderOverview.AddOrder.AddToOptions': require('../MyMenigo/Blocks/OrderOverview/AddOrder/AddToOptions').default,
    'MyMenigo.Blocks.OrderOverview.OrderOverviewBlock': require('../MyMenigo/Blocks/OrderOverview/OrderOverviewBlock').default,
    'MyMenigo.Blocks.OrderOverview.OrderProduct.OrderProductMark.ProductMark': require('../MyMenigo/Blocks/OrderOverview/OrderProduct/OrderProductMark/ProductMark').default,
    'MyMenigo.Blocks.SalesmanInformation.SalesmanInformationBlock': require('../MyMenigo/Blocks/SalesmanInformation/SalesmanInformationBlock').default,
    'MyMenigo.Blocks.ServicePackageDetailBlock.Components.ServicePackageContent.ServicePackageContent': require('../MyMenigo/Blocks/ServicePackageDetailBlock/Components/ServicePackageContent/ServicePackageContent').default,
    'MyMenigo.Blocks.ServicePackageDetailBlock.ServicePackageDetailBlock': require('../MyMenigo/Blocks/ServicePackageDetailBlock/ServicePackageDetailBlock').default,
    'MyMenigo.Blocks.StatisticsNavigationBlock.StatisticsNavigationBlock': require('../MyMenigo/Blocks/StatisticsNavigationBlock/StatisticsNavigationBlock').default,
    'MyMenigo.Blocks.UnpaidInvoiceBlock.UnpaidInvoiceBlock': require('../MyMenigo/Blocks/UnpaidInvoiceBlock/UnpaidInvoiceBlock').default,
    'MyMenigo.Blocks.UserInfoBlock.UserInfoBlock': require('../MyMenigo/Blocks/UserInfoBlock/UserInfoBlock').default,
    'MyMenigo.Pages.MyMenigoBasic.MyMenigoBasicLayout': require('../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicLayout').default,
    'MyMenigo.Pages.MyMenigoBasic.MyMenigoBasicPage': require('../MyMenigo/Pages/MyMenigoBasic/MyMenigoBasicPage').default,
    'MyMenigo.Pages.MyMenigoLayout': require('../MyMenigo/Pages/MyMenigoLayout').default,
    'MyMenigo.Pages.MyMenigoOverview.MyMenigoOverviewPage': require('../MyMenigo/Pages/MyMenigoOverview/MyMenigoOverviewPage').default,
    'MyPriceLists.Pages.MyPriceListsPage': require('../MyPriceLists/Pages/MyPriceListsPage').default,
    'Notifications.Pages.NotificationPage': require('../Notifications/Pages/NotificationPage').default,
    'Pantries.Pages.List.Actionbar.CloseInventDialog': require('../Pantries/Pages/List/Actionbar/CloseInventDialog').default,
    'Pantries.Pages.List.Actionbar.CopyDialog': require('../Pantries/Pages/List/Actionbar/CopyDialog').default,
    'Pantries.Pages.List.Actionbar.CustomProductDialog': require('../Pantries/Pages/List/Actionbar/CustomProductDialog').default,
    'Pantries.Pages.List.Actionbar.DeleteDialog': require('../Pantries/Pages/List/Actionbar/DeleteDialog').default,
    'Pantries.Pages.List.Actionbar.FavoriteDialog': require('../Pantries/Pages/List/Actionbar/FavoriteDialog').default,
    'Pantries.Pages.List.Actionbar.MoveDialog': require('../Pantries/Pages/List/Actionbar/MoveDialog').default,
    'Pantries.Pages.List.Actionbar.ReOpenDialog': require('../Pantries/Pages/List/Actionbar/ReOpenDialog').default,
    'Pantries.Pages.List.Actionbar.StartInventDialog': require('../Pantries/Pages/List/Actionbar/StartInventDialog').default,
    'Pantries.Pages.List.PantryList': require('../Pantries/Pages/List/PantryList').default,
    'Pantries.Pages.List.PantryListHeader': require('../Pantries/Pages/List/PantryListHeader').default,
    'Pantries.Pages.List.PantryOverviewPage': require('../Pantries/Pages/List/PantryOverviewPage').default,
    'Pantries.Pages.List.PantryReportPage': require('../Pantries/Pages/List/PantryReportPage').default,
    'Pantries.Pages.List.Reports.PantryReportArea': require('../Pantries/Pages/List/Reports/PantryReportArea').default,
    'Pantries.Pages.List.Reports.PantryReportInvent': require('../Pantries/Pages/List/Reports/PantryReportInvent').default,
    'Pantries.Pages.List.Reports.PantryReportItem': require('../Pantries/Pages/List/Reports/PantryReportItem').default,
    'Pantries.Pages.List.Reports.PantryReportPantry': require('../Pantries/Pages/List/Reports/PantryReportPantry').default,
    'Pantries.Pages.List.Toolbar.ActionOptions': require('../Pantries/Pages/List/Toolbar/ActionOptions').default,
    'Pantries.Pages.List.Toolbar.CloseDialog': require('../Pantries/Pages/List/Toolbar/CloseDialog').default,
    'Pantries.Pages.List.Toolbar.MarkAll': require('../Pantries/Pages/List/Toolbar/MarkAll').default,
    'Pantries.Pages.List.Toolbar.PrintActions': require('../Pantries/Pages/List/Toolbar/PrintActions').default,
    'Pantries.Pages.List.Toolbar.PrintOptions': require('../Pantries/Pages/List/Toolbar/PrintOptions').default,
    'Pantries.Pages.List.Toolbar.StartDialog': require('../Pantries/Pages/List/Toolbar/StartDialog').default,
    'Pantries.Pages.List.Toolbar.ValidationFeedback': require('../Pantries/Pages/List/Toolbar/ValidationFeedback').default,
    'Pantries.Pages.PantryDetails.PantryContentItemRow': require('../Pantries/Pages/PantryDetails/PantryContentItemRow').default,
    'Pantries.Pages.PantryDetails.PantryDetailsPage': require('../Pantries/Pages/PantryDetails/PantryDetailsPage').default,
    'Pantries.Pages.PantryList.PantryListItemRow': require('../Pantries/Pages/PantryList/PantryListItemRow').default,
    'Pantries.Pages.PantryList.PantryListPage': require('../Pantries/Pages/PantryList/PantryListPage').default,
    'Product.Commerce.AddToCartRecommendation': require('../Product/Commerce/AddToCartRecommendation').default,
    'Product.Commerce.Product': require('../Product/Commerce/Product').default,
    'PunchOut.Pages.PunchOutLoginPage': require('../PunchOut/Pages/PunchOutLoginPage').default,
    'PunchOut.Pages.PunchOutTakeoverPage': require('../PunchOut/Pages/PunchOutTakeoverPage').default,
    'RecipeMenus.Pages.List.RecipeMenuOverviewPage': require('../RecipeMenus/Pages/List/RecipeMenuOverviewPage').default,
    'RecipeMenus.Pages.List.Table.Body': require('../RecipeMenus/Pages/List/Table/Body').default,
    'RecipeMenus.Pages.List.Table.Header': require('../RecipeMenus/Pages/List/Table/Header').default,
    'RecipeMenus.Pages.List.Table.RecipeSearch': require('../RecipeMenus/Pages/List/Table/RecipeSearch').default,
    'RecipeMenus.Pages.List.Table.RemoveList': require('../RecipeMenus/Pages/List/Table/RemoveList').default,
    'RecipeMenus.Pages.List.Table.RemoveRecipe': require('../RecipeMenus/Pages/List/Table/RemoveRecipe').default,
    'RecipeMenus.Pages.List.Table.Table': require('../RecipeMenus/Pages/List/Table/Table').default,
    'RecipeMenus.Pages.List.ToolBox.Export': require('../RecipeMenus/Pages/List/ToolBox/Export').default,
    'RecipeMenus.Pages.List.ToolBox.NewList': require('../RecipeMenus/Pages/List/ToolBox/NewList').default,
    'Recipes.Pages.RecipeEditPage': require('../Recipes/Pages/RecipeEditPage').default,
    'Recipes.Pages.RecipeOverviewPage': require('../Recipes/Pages/RecipeOverviewPage').default,
    'Recipes.Pages.RecipePage': require('../Recipes/Pages/RecipePage').default,
    'ReturnOrder.Pages.CustomerInfo.CustomerInfo': require('../ReturnOrder/Pages/CustomerInfo/CustomerInfo').default,
    'ReturnOrder.Pages.ReturnItems.ReturnItem': require('../ReturnOrder/Pages/ReturnItems/ReturnItem').default,
    'ReturnOrder.Pages.ReturnOrderPage': require('../ReturnOrder/Pages/ReturnOrderPage').default,
    'Search.Blocks.QuickSearchNewsBanner': require('../Search/Blocks/QuickSearchNewsBanner').default,
    'Search.Pages.Search.CategoryList': require('../Search/Pages/Search/CategoryList').default,
    'Search.Pages.Search.CompletionList': require('../Search/Pages/Search/CompletionList').default,
    'Search.Pages.Search.Components.SearchPageHeader': require('../Search/Pages/Search/Components/SearchPageHeader').default,
    'Search.Pages.Search.Components.SearchPageInfoBlock': require('../Search/Pages/Search/Components/SearchPageInfoBlock').default,
    'Search.Pages.Search.Components.SearchQuickFilter': require('../Search/Pages/Search/Components/SearchQuickFilter').default,
    'Search.Pages.Search.Components.SearchQuickFilters': require('../Search/Pages/Search/Components/SearchQuickFilters').default,
    'Search.Pages.Search.ContentList': require('../Search/Pages/Search/ContentList').default,
    'Search.Pages.Search.SearchPage': require('../Search/Pages/Search/SearchPage').default,
    'Shared.Blocks.FooterLink.FooterLinkBlock': require('../Shared/Blocks/FooterLink/FooterLinkBlock').default,
    'Shared.Blocks.FooterNavigationLinks.FooterNavigationLinksBlock': require('../Shared/Blocks/FooterNavigationLinks/FooterNavigationLinksBlock').default,
    'Shared.Blocks.Html.HtmlBlock': require('../Shared/Blocks/Html/HtmlBlock').default,
    'Shared.Blocks.Image.ImageBlock': require('../Shared/Blocks/Image/ImageBlock').default,
    'Shared.Blocks.InformationFooter.InformationFooterBlock': require('../Shared/Blocks/InformationFooter/InformationFooterBlock').default,
    'Shared.Blocks.NewsletterForm.NewsletterFormBlock': require('../Shared/Blocks/NewsletterForm/NewsletterFormBlock').default,
    'Shared.Blocks.ProductCarousel.ProductCarouselBlock': require('../Shared/Blocks/ProductCarousel/ProductCarouselBlock').default,
    'Shared.Blocks.Products.ProductsBlock': require('../Shared/Blocks/Products/ProductsBlock').default,
    'Shared.Blocks.RowLayout.RowLayoutBlock': require('../Shared/Blocks/RowLayout/RowLayoutBlock').default,
    'Shared.Blocks.VideoBlock.VideoBlock': require('../Shared/Blocks/VideoBlock/VideoBlock').default,
    'Shared.Partials.ImageMediaData': require('../Shared/Partials/ImageMediaData').default,
    'Shared.Partials.Preview': require('../Shared/Partials/Preview').default,
    'Shared.Partials.PropertyTypes.ContentArea': require('../Shared/Partials/PropertyTypes/ContentArea').default,
    'Shared.Partials.PropertyTypes.Html': require('../Shared/Partials/PropertyTypes/Html').default,
    'Shared.Partials.PropertyTypes.ImageCarousel': require('../Shared/Partials/PropertyTypes/ImageCarousel').default,
    'Shared.Partials.PropertyTypes.RawHtml': require('../Shared/Partials/PropertyTypes/RawHtml').default,
    'Start.Pages.Start.StartPage': require('../Start/Pages/Start/StartPage').default,
    'Test.Blocks.NoControllerTest.NoControllerTestBlock': require('../Test/Blocks/NoControllerTest/NoControllerTestBlock').default,
    'Test.Blocks.Test.TestBlock': require('../Test/Blocks/Test/TestBlock').default,
    'Test.Pages.Test.TestPage': require('../Test/Pages/Test/TestPage').default,
};
export function registerComponent(name, component) {
    registry[name] = component;
}
export default registry;
