import { updateCurrentPage } from 'SiteLayout/CurrentPage/action-creators';
import { getJson, post, postJson } from 'Shared/server';
import { setFeedback } from './../SiteLayout/GenericFeedbackBar/action-creators';
import { isPantriesPage } from 'Shared/ListItem/current-page-is';
import { translate } from 'Shared/translate';
import moment from 'moment';
import { getDetails, setSpinner, DEFAULT_SORT, sortFuncs, sortLists, } from 'Shared/ListItem/action-creators';
import { addInventoryCountStarted, addInventoryCountCompleted, addInventoryCountReOpened } from './../TrackingInformation/CustomGa4EventService';
var insertListFromResponse = function (list, res, stayOpen) {
    return Object.assign({}, list, res, {
        sortBy: DEFAULT_SORT,
        products: res.products.map(function (item) { return Object.assign({}, item); }).sort(sortFuncs[list.sortBy || DEFAULT_SORT]),
        isLoaded: stayOpen || !list.isLoaded,
        isLoading: false,
        selected: list.selected,
    });
};
var removeFromRecommendation = function (recommendation, codes) {
    var newProducts = recommendation.products.filter(function (product) { return !codes.includes(product.selectedVariant.code); });
    Object.assign(recommendation, {
        products: newProducts,
        count: newProducts.length,
        displayName: translate('/Account/ProductList/PantryRecommendation', newProducts.length.toString()),
    });
};
export var toggleListSelection = function (listId, selected) { return function (dispatch, getState) {
    return isPantriesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                lists: current.lists.map(function (list) {
                    return list.id !== listId
                        ? list
                        : !list.products
                            ? Object.assign({}, list, { selected: !selected })
                            : Object.assign({}, list, {
                                products: list.products && list.products.map(function (item) { return Object.assign({}, item, { selected: !selected }); }),
                                selected: !selected,
                            });
                }),
            });
        }));
}; };
export var addRecommendations = function (fromlistId, listId, codes) { return function (dispatch, getState) {
    if (!isPantriesPage(getState())) {
        return Promise.reject('Is not pantries page. Failed to add recommendations');
    }
    return post(getState().appShellData.siteSettings.pantryOverviewPage + "/addProducts", { listId: listId, codes: codes })
        .then(function () {
        dispatch(updateCurrentPage(function (current) {
            removeFromRecommendation(current.recommendations[0], codes);
        }));
        dispatch(getDetails(listId));
        return Promise.resolve();
    })
        .catch(failedPromiseHandler);
}; };
export var sortRecommendations = function (listId, order) { return function (dispatch, getState) {
    return isPantriesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                recommendations: current.recommendations.map(function (list) {
                    return list.id !== listId
                        ? list
                        : !list.products
                            ? list
                            : Object.assign({}, list, {
                                products: list.products.sort(sortFuncs[order]),
                                sortBy: order,
                            });
                }),
            });
        }));
}; };
export var addRecommendationsToNewList = function (listId, displayName, codes) { return function (dispatch, getState) {
    if (!isPantriesPage(getState())) {
        return Promise.reject('Is not pantries page. Failed to add recommendations to new list');
    }
    var regex = new RegExp('[ ]{2,}');
    var trimmedDisplayName = displayName.replace(regex, ' ').trim();
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/AddRecommendations", {
        codes: codes,
        displayName: trimmedDisplayName,
    })
        .then(function (res) {
        if (!res.success) {
            dispatch(setFeedback({
                text: res.message,
                type: 'error',
            }));
            return Promise.reject('Failed to add recommendations to new list');
        }
        else {
            dispatch(updateCurrentPage(function (current) {
                removeFromRecommendation(current.recommendations[0], codes);
                dispatch(setFeedback({ text: res.message, type: 'success' }));
                return Object.assign(current, {
                    lists: current.lists
                        .map(function (list) { return (list.id !== listId ? list : Object.assign({}, list, { selected: false })); })
                        .concat([res.pantryListSummary])
                        .sort(sortLists),
                });
            }, true));
            return Promise.resolve();
        }
    })
        .catch(failedPromiseHandler);
}; };
export var removeRecommendationFromList = function (productIds) { return function (dispatch, getState) {
    return post(getState().appShellData.siteSettings.pantryOverviewPage + "/removeRecommendations", productIds)
        .then(function () {
        if (isPantriesPage(getState())) {
            dispatch(updateCurrentPage(function (current) {
                removeFromRecommendation(current.recommendations[0], productIds);
            }));
            return Promise.resolve();
        }
        else {
            return Promise.reject('Is not pantries page. Failed to remove recommendation from list');
        }
    })
        .catch(failedPromiseHandler);
}; };
export var importPantries = function () { return function (dispatch, getState) {
    return getJson(getState().appShellData.siteSettings.pantryOverviewPage + "/import")
        .then(function (res) {
        return dispatch(updateCurrentPage(function (current) { return Object.assign(current, { lists: res.lists }); }));
    })
        .catch(console.error);
}; };
export var startInvent = function (listId) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/beginInvent", listId)
        .then(function (result) {
        if (!result.success) {
            return Promise.resolve(result);
        }
        addInventoryCountStarted(listId);
        dispatch(updateCurrentPage(function (current) {
            var currentList = current.lists.find(function (x) {
                return x.id === listId;
            });
            currentList.inventoryStatus = 'ongoing';
            currentList.previousInventoryTotalPrice = currentList.currentInventoryTotalPrice;
            currentList.products.forEach(function (p) {
                p.previousQuantity = p.quantity;
                p.quantity = null;
                p.isDisabled = false;
            });
            Object.assign(current.lists.find(function (l) { return l.id === listId; }), { currentList: currentList });
        }));
        return Promise.resolve(result);
    });
}; };
export var closeInvent = function (listId) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/closeInvent", listId)
        .then(function (result) {
        if (!result.success) {
            return Promise.resolve(result);
        }
        addInventoryCountCompleted(listId);
        dispatch(updateCurrentPage(function (current) {
            var currentList = current.lists.find(function (x) {
                return x.id === listId;
            });
            moment.locale("sv-SE");
            currentList.inventoryStatus = 'completed';
            currentList.inventoryDate = moment(Date.now()).format('DD MMM');
            currentList.currentInventoryTotalPrice = currentList.products.reduce(function (sum, p) { return sum + p.selectedVariant.price.current * p.quantity; }, 0);
            currentList.products.forEach(function (p) {
                p.isDisabled = true;
            });
            Object.assign(currentList, { currentList: currentList });
        }));
        return Promise.resolve(result);
    });
}; };
export var reOpenInvent = function (listId) { return function (dispatch, getState) {
    return postJson(getState().appShellData.siteSettings.pantryOverviewPage + "/reopenInvent", listId).then(function (response) {
        if (!response.success) {
            dispatch(setFeedback({
                text: response.message,
                type: 'error',
            }));
            return Promise.reject();
        }
        addInventoryCountReOpened(listId);
        return Promise.resolve();
    }).then(function () {
        return dispatch(updateCurrentPage(function (current) {
            var currentList = current.lists.find(function (x) {
                return x.id === listId;
            });
            currentList.inventoryStatus = 'reOpened';
            currentList.products.forEach(function (p) {
                p.isDisabled = false;
            });
            Object.assign(current.lists.find(function (l) { return l.id === listId; }), { currentList: currentList });
        }));
    })
        .catch(function () { return Promise.reject('Failed to reopen inventory'); });
}; };
export var getRecommendationDetails = function (listId) { return function (dispatch, getState) {
    if (!isPantriesPage(getState())) {
        return;
    }
    dispatch(setSpinner(listId));
    getJson(getState().appShellData.siteSettings.pantryOverviewPage + "/recommendationdetails?id=" + listId).then(function (res) {
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                recommendations: current.recommendations.map(function (list) {
                    return insertListFromResponse(list, res, true);
                }),
            });
        }));
    });
}; };
export var printList = function (reportType) { return function (dispatch, getState) {
    var currentPage = getState().currentPage;
    var selectedLists = currentPage.lists.filter(function (x) { return x.selected === true; });
    if (selectedLists.length > 0) {
        var lists = selectedLists.map(function (a) {
            return { id: a.id, sortBy: a.sortBy };
        });
        post(getState().appShellData.siteSettings.pantryOverviewPage + "/report", { lists: lists, reportType: reportType }).then(function (res) {
            var url = window.location.href;
            var absolute = url.substr(0, url.indexOf('/'));
            window.open("" + (absolute + getState().appShellData.siteSettings.pantryReportPage), '_blank');
        });
    }
}; };
export var exportList = function (reportType) {
    return function (dispatch, getState) {
        var currentPage = getState().currentPage;
        var selectedLists = currentPage.lists.filter(function (x) { return x.selected === true; });
        if (selectedLists.length > 0) {
            var lists = selectedLists.map(function (a) {
                return { id: a.id, sortBy: a.sortBy };
            });
            post(getState().appShellData.siteSettings.pantryOverviewPage + "/report", { lists: lists, reportType: reportType }).then(function (res) {
                var url = window.location.href;
                var absolute = url.substr(0, url.indexOf('/'));
                window.open(absolute + getState().appShellData.siteSettings.pantryReportPage + "/excel", '_blank');
            });
        }
    };
};
export var markAll = function (selected) { return function (dispatch, getState) {
    isPantriesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                lists: current.lists.map(function (list) {
                    return Object.assign({}, list, {
                        products: list.products && list.products.map(function (item) { return Object.assign({}, item, { selected: selected }); }),
                        selected: selected,
                    });
                }),
            });
        }));
}; };
function failedPromiseHandler(e) {
    console.error(e);
    return Promise.reject(null);
}
