var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import classnames from 'classnames';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
import editorAttributesFor from 'Shared/editor-attributes-for';
import ContentContainerContentCard from './ContentContainerContentCard';
var getBackgroundColor = function (props) {
    var backgroundColor = !!props.block.backgroundColor && propertyValueFor(props.block.backgroundColor);
    switch (backgroundColor) {
        case 'White':
            return styles.white;
        case 'Green':
            return styles.green;
        case 'Red':
            return styles.red;
        case 'Yellow':
            return styles.yellow;
        case 'Gray':
            return styles.gray;
        default:
            return styles.white;
    }
};
function contentAreaContentCards(items) {
    return (items || [])
        .filter(function (i) { return !!i; })
        .map(function (item, i) {
        return (React.createElement(ContentContainerContentCard, __assign({ key: i }, item)));
    });
}
var ContentCardsArea = function (props) {
    return React.createElement(props.tagName || 'div', editorAttributesFor(props.propertyName, classnames(props.className, styles.base)), contentAreaContentCards(props.items));
};
var FirstRow = function (props) {
    var _a;
    var showFirstRow = !!propertyValueFor(props.block.header) || !!propertyValueFor(props.block.preamble) || !!propertyValueFor(props.block.heroImage) || !!propertyValueFor(props.block.bodyText);
    var imageUrl = !!props.block.heroImage && propertyValueFor(props.block.heroImage);
    var useReversedOrder = !!props.block.reversedColumnOrder && propertyValueFor(props.block.reversedColumnOrder);
    return !!showFirstRow &&
        React.createElement("div", { className: classnames(styles.inner) },
            React.createElement("div", { className: classnames(styles.row, (_a = {}, _a[styles.reversed] = !!useReversedOrder, _a)) },
                React.createElement("div", { className: styles.contentCell },
                    React.createElement("div", null,
                        !!props.block.header && React.createElement(PropertyFor, { tagName: 'h2', className: styles.inspiration_big, property: props.block.header }),
                        !!props.block.preamble && React.createElement(PropertyFor, { tagName: 'p', className: styles.preamble, property: props.block.preamble }))),
                React.createElement("div", { className: styles.imageCell },
                    !!imageUrl && React.createElement("img", { src: imageUrl, alt: "" }),
                    !imageUrl && !!props.block.bodyText && React.createElement(PropertyFor, { className: styles.paragraph, property: props.block.bodyText }))));
};
var ContentContainerBlock = function (props) {
    return (React.createElement("div", { className: classnames(styles.base, getBackgroundColor(props)) },
        React.createElement(FirstRow, __assign({}, props)),
        !!props.block.contentCardContentArea &&
            React.createElement("div", { className: styles.contentCardsSection },
                React.createElement(ContentCardsArea, __assign({ className: styles.contentCardsRow }, propertyValueFor(props.block.contentCardContentArea), { items: props.contentCards })))));
};
export default ContentContainerBlock;
