import React from 'react';
import Image from 'Shared/ImageFallback';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var customProductImage = require('../../../../styles/images/custom-product.svg');
var ProductImage = function (_a) {
    var imageUrl = _a.imageUrl, displayName = _a.displayName, size = _a.size, isCustom = _a.isCustom;
    return (React.createElement("div", { className: styles.image },
        React.createElement(Image, { src: isCustom === true ? customProductImage : imageUrl, alt: typeof displayName === 'string' ? displayName : propertyValueFor(displayName), size: size, maxSize: size, itemProp: "image", fadeIn: true })));
};
export default ProductImage;
