var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import clone from 'clone';
export var serverVersionHeaderName = 'X-Server-Version';
export function currentUrl() {
    return parseUrl(window.location);
}
export function parseUrl(url) {
    var path = '';
    var query = {};
    var hiddenQuery = {};
    var host = null;
    var port = null;
    var scheme = null;
    if (typeof url === 'string') {
        var parts = url.split('?');
        path = parts[0];
        if (path.indexOf('//') === 0 || path.indexOf('http') === 0) {
            var parseUrlRegex = new RegExp('^((?:https?|file):)?//' + // protocol
                '(([^:/?#]*)(?::([0-9]+))?)' + // host (hostname and port)
                '(/{0,1}[^?#]*)');
            var match = path.match(parseUrlRegex);
            scheme = match[1];
            host = match[3];
            if (match[4]) {
                port = +match[4];
            }
            path = match[5];
        }
        if (!path) {
            path = '/';
        }
        query = searchToObject(parts[1]);
    }
    else if (isLocation(url)) {
        path = url.pathname;
        query = searchToObject(url.search.split('?').pop());
        host = url.hostname;
        port = url.port ? +url.port : null;
        scheme = url.protocol;
    }
    else if (isUrlObject(url)) {
        // Url is already parsed but might not contain `query` and `hiddenQuery`
        path = url.path;
        query = clone(url.query || {});
        hiddenQuery = clone(url.hiddenQuery || {});
        host = url.host;
        port = url.port;
        scheme = url.scheme;
    }
    return {
        path: path,
        query: query,
        hiddenQuery: hiddenQuery,
        host: host,
        port: port,
        scheme: scheme,
    };
}
// We cannot use `url instanceof Location` because location objects in IE
// are not instances of that constructor
function isLocation(url) {
    return !!url && 'hash' in url && 'pathname' in url && 'origin' in url;
}
export function isExternal(url) {
    if (!url.host) {
        return false;
    }
    var current = currentUrl();
    if (url.host.replace(/^www./, '') === current.host.replace(/^www./, '')) {
        return false;
    }
    return true;
}
export function urlToString(url, includeHiddenQuery) {
    if (includeHiddenQuery === void 0) { includeHiddenQuery = false; }
    if (!url) {
        return null;
    }
    else if (typeof url === 'string') {
        return urlToString(parseUrl(url));
    }
    else {
        var q = queryToString(url.query);
        if (includeHiddenQuery && url.hiddenQuery) {
            var hiddenQ = queryToString(url.hiddenQuery);
            q = (q === '' ? hiddenQ : q + '&' + hiddenQ).replace(/&$/, '');
        }
        var pathAndQuery = url.path + (q === '' ? '' : '?' + q);
        var port = url.port ? ':' + url.port : '';
        return (url.host ? url.scheme + '//' + url.host + port : '') + pathAndQuery;
    }
}
export function areEqual(url1, url2, compareHiddenQuery) {
    if (compareHiddenQuery === void 0) { compareHiddenQuery = false; }
    var urlObject1 = null;
    var urlObject2 = null;
    if (typeof url1 === 'string') {
        urlObject1 = parseUrl(url1);
    }
    else {
        urlObject1 = url1;
    }
    if (typeof url2 === 'string') {
        urlObject2 = parseUrl(url2);
    }
    else {
        urlObject2 = url2;
    }
    if (urlObject1.path !== urlObject2.path) {
        return false;
    }
    if (queryToString(urlObject1.query) !== queryToString(urlObject2.query)) {
        return false;
    }
    if (compareHiddenQuery && queryToString(urlObject1.hiddenQuery) !== queryToString(urlObject2.hiddenQuery)) {
        return false;
    }
    return true;
}
var validUrlKeys = ['path', 'query', 'hiddenQuery', 'host', 'scheme', 'port'];
export function isUrlObject(url) {
    if (!url || typeof url !== 'object') {
        return false;
    }
    if ('path' in url && typeof url.path === 'string') {
        var otherKeys = Object.keys(url).filter(function (key) { return validUrlKeys.indexOf(key) === -1; });
        if (otherKeys.length === 0) {
            var validKeyTypes = true;
            if (url.query && typeof url.query !== 'object') {
                validKeyTypes = false;
            }
            if (url.hiddenQuery && typeof url.hiddenQuery !== 'object') {
                validKeyTypes = false;
            }
            return validKeyTypes;
        }
        else {
            return false;
        }
    }
    return false;
}
export function pathCombine(first) {
    var rest = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        rest[_i - 1] = arguments[_i];
    }
    var result = first;
    for (var i = 0; i < rest.length; i++) {
        if (result[result.length - 1] !== '/') {
            result += '/';
        }
        var current = rest[i][0] === '/' ? rest[i].substr(1) : rest[i];
        if (current) {
            result += current;
        }
    }
    return result;
}
function queryToString(query) {
    if (!query) {
        return '';
    }
    var queryString = '';
    var keys = Object.keys(query).sort();
    keys.forEach(function (k) {
        if (Array.isArray(query[k])) {
            var queryParams = query[k].slice();
            queryParams.sort();
            queryParams.forEach(function (v) {
                queryString += createQueryKeyValuePair(k, v);
            });
        }
        else {
            queryString += createQueryKeyValuePair(k, query[k]);
        }
    });
    return queryString.replace(/&$/, '');
}
function createQueryKeyValuePair(key, value) {
    if (value === null || value === undefined) {
        return encodeURIComponent(key) + '&';
    }
    else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
}
function searchToObject(search) {
    // decodeURIComponent doesn't convert + to a space so we need to handle it manually
    var decode = function (value) { return decodeURIComponent(String(value).replace(/\+/g, '%20')); };
    var query = {};
    if (search) {
        search.split('&').forEach(function (q) {
            var _a = __read(q.split('='), 2), key = _a[0], value = _a[1];
            if (key in query) {
                if (!Array.isArray(query[key])) {
                    query[key] = [query[key]];
                }
                query[key].push(decode(value));
            }
            else {
                query[key] = decode(value);
            }
        });
    }
    return query;
}
