var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import { on } from 'SiteLayout/Root';
import { logout } from '../Account/action-creators';
import TabInEditModeHandler from 'Shared/TabInEditModeHandler';
function secToMs(sec) {
    return sec * 1000;
}
function minToMs(min) {
    return secToMs(min * 60);
}
var WARNING_LOGOUT_TIME = secToMs(45);
var timeLeft = WARNING_LOGOUT_TIME;
var AutomaticLogout = /** @class */ (function (_super) {
    __extends(AutomaticLogout, _super);
    function AutomaticLogout(props) {
        var _this = _super.call(this, props) || this;
        _this.resetAutomaticLogout = function () {
            clearTimeout(_this.logoutTimer);
            if (_this.isWithinLogoutWarningPeriod()) {
                _this.logoutWarningAborted();
            }
            if (!_this.props.currentUser.isLoggedIn || !_this.hasAutomaticLogout()) {
                /*
                  Flawed logic?
                  This will still start the automatic logout on another tab were this.hasAutomaticLogout() is true
                  And this tab will be logged out as well, it will just not show the the notification banner.
                */
                return;
            }
            if (!_this.tabInEditModeHandler.anyTabInEditMode()) {
                _this.setLogoutTimer();
            }
        };
        _this.setTabInEditModeHandlerRef = function (c) {
            _this.tabInEditModeHandler = c;
        };
        _this.autoMaticLogoutTime = minToMs(_this.props.automaticLogout || 30);
        return _this;
    }
    AutomaticLogout.prototype.componentDidMount = function () {
        on('click', this.resetAutomaticLogout);
        if (this.props.currentUser.isLoggedIn && !this.tabInEditModeHandler.anyTabInEditMode()) {
            this.setLogoutTimer();
        }
    };
    AutomaticLogout.prototype.componentDidUpdate = function (prevProps) {
        if (!this.props.currentPage.isLoading && prevProps.currentPage.isLoading && this.props.currentUser.isLoggedIn) {
            this.resetAutomaticLogout();
        }
    };
    AutomaticLogout.prototype.hasAutomaticLogout = function () {
        return (!IS_APP &&
            !(this.props.currentPage.componentName.indexOf('DeliveryApplication') >= 0) &&
            !(this.props.currentPage.componentName.indexOf('PantryReportPage') >= 0));
    };
    AutomaticLogout.prototype.logoutWarningAborted = function () {
        this.props.setFeedback({
            text: translate('/Account/LogoutWarningAborted'),
            type: 'success',
        });
        timeLeft = WARNING_LOGOUT_TIME;
        clearInterval(this.warningInterval);
    };
    AutomaticLogout.prototype.isWithinLogoutWarningPeriod = function () {
        return timeLeft !== 0 && timeLeft < WARNING_LOGOUT_TIME;
    };
    AutomaticLogout.prototype.setLogoutTimer = function () {
        var _this = this;
        this.logoutTimer = setTimeout(function () {
            if (!_this.tabInEditModeHandler.anyTabInEditMode()) {
                _this.setWarningInterval();
            }
        }, this.autoMaticLogoutTime - WARNING_LOGOUT_TIME);
    };
    AutomaticLogout.prototype.setWarningInterval = function () {
        var _this = this;
        if (!this.props.currentUser.isLoggedIn) {
            clearInterval(this.warningInterval);
            return;
        }
        timeLeft = WARNING_LOGOUT_TIME;
        var INTERVAL = secToMs(1);
        var unitText = translate('/Account/LogoutWarningUnit');
        this.warningInterval = setInterval(function () {
            timeLeft -= INTERVAL;
            if (_this.tabInEditModeHandler.anyTabInEditMode()) {
                _this.logoutWarningAborted();
            }
            else {
                _this.props.setFeedback({
                    text: translate('/Account/LogoutWarning') + " : " + timeLeft / INTERVAL + " " + unitText,
                    type: 'error',
                });
                if (timeLeft <= 0) {
                    _this.noTimeLeftDoLogout();
                }
            }
        }, INTERVAL);
    };
    AutomaticLogout.prototype.noTimeLeftDoLogout = function () {
        clearTimeout(this.logoutTimer);
        clearInterval(this.warningInterval);
        this.props.setFeedback({
            text: translate('/Account/YouHaveBeenOutloggedDueToInactivity'),
            type: 'error',
            timeout: minToMs(120),
        });
        this.props.logout();
    };
    AutomaticLogout.prototype.render = function () {
        return React.createElement(TabInEditModeHandler, { setref: this.setTabInEditModeHandlerRef });
    };
    return AutomaticLogout;
}(React.Component));
export default connect(function (state) { return ({
    currentPage: state.currentPage,
    automaticLogout: state.appShellData.siteSettings.automaticLogout,
    currentUser: state.currentUser,
}); }, function (dispatch) { return ({
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
    logout: function () { return dispatch(logout()); },
}); })(AutomaticLogout);
