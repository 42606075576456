import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
import * as styles from './base.scss';
import moment from 'moment';
var numberToArray = function (n) {
    var numbers = [];
    for (var i = 1; i <= n; i += 1) {
        numbers.push(i);
    }
    return numbers;
};
var ProductAvailabilityLabel = function (_a) {
    var _b, _c;
    var className = _a.className, availability = _a.availability, disableAvailabilityDate = _a.disableAvailabilityDate, disableAvailabilityMarkers = _a.disableAvailabilityMarkers, showDescription = _a.showDescription;
    if (!availability) {
        return null;
    }
    var availabilityVariants = {
        inStock: { display: 'inStock', dots: 3, header: translate('/Product/StockStatusToolTip/inStock/header'), tooltip: translate('/Product/StockStatusToolTip/inStock/text'), description: translate('/Product/StockStatusToolTip/inStock/description') },
        lowStock: { display: 'lowStock', dots: 2, header: translate('/Product/StockStatusToolTip/lowStock/header'), tooltip: translate('/Product/StockStatusToolTip/lowStock/text'), description: translate('/Product/StockStatusToolTip/lowStock/description') },
        outOfStock: { display: 'outOfStock', dots: 1, header: translate('/Product/StockStatusToolTip/outOfStock/header'), tooltip: !!availability.availabilityDate ? translate('/Product/StockStatusToolTip/outOfStock/text2', moment(availability.availabilityDate).format('YYYY-MM-DD')) : translate('/Product/StockStatusToolTip/outOfStock/text'), description: !!availability.availabilityDate ? translate('/Product/StockStatusToolTip/outOfStock/description2', moment(availability.availabilityDate).locale('sv').format('D MMM').toLowerCase()) : translate('/Product/StockStatusToolTip/outOfStock/description') },
        futureDelivery: { display: 'outOfStock', dots: 2, header: translate('/Product/StockStatusToolTip/futureDelivery/header'), tooltip: translate('/Product/StockStatusToolTip/futureDelivery/text', !!availability.availabilityDate ? moment(availability.availabilityDate).format('YYYY-MM-DD') : "-"), description: !!availability.availabilityDate ? translate('/Product/StockStatusToolTip/futureDelivery/description2', moment(availability.availabilityDate).locale('sv').format('D MMM').toLowerCase()) : translate('/Product/StockStatusToolTip/futureDelivery/description') },
        selectedDate: { display: 'inStock', dots: 1, header: translate('/Product/StockStatusToolTip/selectedDate/header'), tooltip: translate('/Product/StockStatusToolTip/selectedDate/text'), description: translate('/Product/StockStatusToolTip/selectedDate/description') },
        warehouse: { display: 'warehouse', dots: 1, header: translate('/Product/StockStatusToolTip/warehouse/header'), tooltip: translate('/Product/StockStatusToolTip/warehouse/text'), description: translate('/Product/StockStatusToolTip/warehouse/description') },
        unavailable: { display: 'unavailable', dots: 0, header: "", tooltip: "", description: "" },
    };
    var availabilityVariant = availabilityVariants[availability.availabilityCode];
    return (!disableAvailabilityMarkers ?
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: classnames(styles.wrap, (_b = {}, _b[styles.showDescription] = showDescription, _b)) },
                React.createElement(ToolTip, { small: true, header: availabilityVariant.header, text: availabilityVariant.tooltip.replace(/\[br\]/g, '\n') },
                    React.createElement("div", { className: classnames((_c = {}, _c[styles.showDescriptionRow] = showDescription, _c)) },
                        React.createElement("div", { className: classnames(className, styles.dots) }, numberToArray(availabilityVariant.dots).map(function (n) { return (React.createElement("div", { className: classnames(styles.dot, styles[availabilityVariant.display]), key: n })); })),
                        showDescription && (React.createElement("div", { className: styles.description }, availabilityVariant.description)))),
                !disableAvailabilityDate &&
                    availability.availabilityCode === 'futureDelivery' &&
                    availability.availabilityDate && (React.createElement("div", { className: styles.availabilityDate }, moment(availability.availabilityDate).format('YYYY-MM-DD'))))) : React.createElement(React.Fragment, null, (availability.availabilityCode === 'futureDelivery' || availability.availabilityCode === 'outOfStock') &&
        availability.availabilityDate && (React.createElement(ToolTip, { small: true, text: translate("/Product/StockStatus/OutOfStockToolTip") },
        React.createElement("div", { className: classnames(styles.availabilityDateBold) },
            availability.availabilityCode === 'futureDelivery' ?
                translate("/Product/StockStatus/FutureDeliveryDatePrefix") : translate("/Product/StockStatus/OutOfStockDatePrefix"),
            " ",
            moment(availability.availabilityDate).format('YYYY-MM-DD'))))));
};
export default ProductAvailabilityLabel;
