var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ProductList from './Components/Product/ProductList';
import * as styles from './../CustomerArrangementBlock/base.scss';
import * as priceListStyles from './base.scss';
import MyMenigoIcon from '../../Shared/MyMenigoIcon/MyMenigoIcon';
import classnames from 'classnames';
var MyPriceListBlock = /** @class */ (function (_super) {
    __extends(MyPriceListBlock, _super);
    function MyPriceListBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyPriceListBlock.prototype.render = function () {
        var priceSegmentLabels = this.props.priceSegmentLabels;
        var showPriceSegmentLabels = !!priceSegmentLabels && priceSegmentLabels.length > 0;
        return (!!this.props.products.products && this.props.products.products.length > 0) && (React.createElement("div", { className: classnames(styles.overviewBox, styles.circleBackground) },
            React.createElement("div", null,
                React.createElement("div", { className: styles.general },
                    React.createElement("div", { className: styles.columnInformation },
                        React.createElement("h2", { className: styles.header }, this.props.header),
                        React.createElement("div", { className: styles.bodyText }, this.props.body)),
                    React.createElement("div", { className: styles.columnButton }),
                    React.createElement("div", { className: styles.labelWrapper }, showPriceSegmentLabels && (priceSegmentLabels.map(function (data) { return (React.createElement("span", { key: data, className: classnames(styles.label, styles.gray) }, data)); }))))),
            React.createElement("div", { className: priceListStyles.block },
                React.createElement("div", null,
                    React.createElement("h2", null, this.props.priceListHeader),
                    React.createElement(ProductList, { products: this.props.products.products }),
                    React.createElement("div", null,
                        React.createElement("a", { href: this.props.buttonTargetPageUrl, className: priceListStyles.priceListPageLink },
                            this.props.buttonText,
                            " ",
                            React.createElement(MyMenigoIcon, { className: priceListStyles.icon, name: "arrow" })))),
                React.createElement("div", null, this.props.priceListBody))));
    };
    return MyPriceListBlock;
}(React.Component));
export default MyPriceListBlock;
