var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/no-multi-comp */
import React from 'react';
import Image from 'Shared/ImageFallback';
import { isBrowser } from 'Shared/device-type';
import loadScript, { unloadScript } from 'Shared/load-script';
import resolveComponent from 'Shared/resolve-component';
import editorAttributesFor from 'Shared/editor-attributes-for';
function renderHtmlTag(item, i, componentProps) {
    if (item.tag) {
        var attr_1 = Object.assign({ key: i }, item.attr);
        if (item.tag === 'img') {
            var props = Object.assign({}, attr_1);
            var size = 'large';
            if (props['data-size']) {
                size = props['data-size'];
            }
            if (componentProps.keepImageSizes !== true) {
                if (props.width) {
                    delete props.width;
                }
                if (props.height) {
                    delete props.height;
                }
            }
            return React.createElement(Image, __assign({}, props, { size: size }));
        }
        else if (item.tag === 'style') {
            // https://github.com/facebook/react/issues/7093
            return React.createElement(item.tag, attr_1, item.children[0]);
        }
        else if (item.tag === 'script') {
            if (isBrowser()) {
                return (React.createElement("noscript", { key: attr_1.key, ref: function (el) {
                        var anyEl = el;
                        if (el) {
                            if (item.children && !anyEl.loaded) {
                                anyEl.loaded = true;
                                try {
                                    console.debug('Evaluating inline script', item.children[0]);
                                    eval(item.children[0]);
                                }
                                catch (e) {
                                    window['console']['error'] && window['console']['error'](e);
                                }
                            }
                            else if (attr_1.src && !anyEl.loaded) {
                                anyEl.loaded = true;
                                console.debug('Loading script', attr_1.src);
                                loadScript(attr_1.src);
                            }
                        }
                        else if (attr_1.src) {
                            console.debug('Unloading script', attr_1.src);
                            unloadScript(attr_1.src);
                        }
                    } }));
            }
            else {
                // We don't want to include a script tag when running on the server because that will cause the script
                // to load once again when the client dehydates the render tree. This has the slight disadvantage that
                // scripts will load first when everything else is up and ready and not when the browser parses the initial
                // server rendered DOM, but it's much simpler this way.
                return React.createElement("noscript", { key: attr_1.key });
            }
        }
        else {
            return React.createElement(item.tag, attr_1, item.children && item.children.map(function (childItem, j) { return renderHtmlTag(childItem, j, componentProps); }));
        }
    }
    else if (item.componentName) {
        var Component = resolveComponent(item.componentName);
        var model = Object.assign({ key: i }, item);
        return React.createElement(Component, model);
    }
    else {
        return item;
    }
}
var Html = function (props) {
    return React.createElement(props.tagName, editorAttributesFor(props.propertyName, props.className), props.html && props.html.map(function (item, i) { return renderHtmlTag(item, i, props); }));
};
Html.supportsPropertyForIntegration = true;
export default Html;
