var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import * as styles from '../../base.scss';
import { translate } from "Shared/translate";
import Button from "Shared/Button";
import classnames from "classnames";
import { Checkbox } from "Shared/Fields";
import DeliveryDetails from "../DetailedProductList/DeliveryDetails";
import { ListType } from "./DeliveryList";
import MyMenigoIcon from "../../../../Shared/MyMenigoIcon/MyMenigoIcon";
import shallowEquals from "Shared/shallow-equals";
var DetailedDeliveryRow = /** @class */ (function (_super) {
    __extends(DetailedDeliveryRow, _super);
    function DetailedDeliveryRow(props) {
        var _this = _super.call(this, props) || this;
        _this.formatDisplayDate = function (date) {
            var displayDate = new Date(date);
            if (displayDate.getFullYear() == 1)
                return new Date(_this.state.rowItem.sortDate).toLocaleDateString('sv-SE');
            return (displayDate.toLocaleDateString('sv-SE'));
        };
        _this.formatDisplayTime = function (date) {
            var displayDate = new Date(date);
            return (displayDate.toLocaleString("sv-SE", { hour: "2-digit", minute: "2-digit" }));
        };
        _this.getDisplayTime = function (row) {
            if (!!row.deliveryDate)
                return " " + translate("/MyMenigo/DeliveryBlock/List/clock") + _this.formatDisplayTime(row.deliveryDate);
            if (!!row.deliveryWindowFrom && !!row.deliveryWindowTo)
                return " " + translate("/MyMenigo/DeliveryBlock/List/clock") + _this.formatDisplayTime(row.deliveryWindowFrom) + "-" + _this.formatDisplayTime(row.deliveryWindowTo);
            return "-";
        };
        _this.setExpanded = function (name) {
            _this.props.setExpandedItem(_this.props.expanded ? "" : name);
        };
        _this.setChecked = function (e) {
            _this.props.addToPrint(_this.state.rowItem.routeId, e.target.checked);
        };
        _this.state = {
            rowItem: _this.props.rowItem
        };
        return _this;
    }
    DetailedDeliveryRow.prototype.componentDidUpdate = function (prevProps) {
        if ((!shallowEquals(prevProps, this.props))) {
            this.setState({ rowItem: this.props.rowItem });
        }
    };
    DetailedDeliveryRow.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { id: "deliveryItem_" + this.props.rowItem.routeId, className: (!!this.props.expanded ? classnames(styles.Delivery__Row__Outer, styles.Delivery__Row__Outer_Active) : styles.Delivery__Row__Outer) },
            React.createElement("div", { className: !!this.props.expanded ? classnames(styles.Delivery__Row, styles.Delivery__Row__Open) : styles.Delivery__Row },
                React.createElement("div", { className: styles.Delivery__Cell },
                    React.createElement(Button, { className: classnames(styles.Button__Toggle, (this.props.expanded ? styles.Button__Toggle__On : styles.Button__Toggle__Off)), appearance: "bare", onClick: function () { _this.setExpanded(_this.props.rowItem.routeId.toString()); } },
                        React.createElement("span", null, this.props.expanded ? '-' : '+'))),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.routeId.toString()); }, className: styles.Delivery__Cell },
                    React.createElement("span", { className: styles.Delivery__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/List/DeliveryDate")),
                    !!this.state.rowItem.deliveryDate && this.formatDisplayDate(this.state.rowItem.deliveryDate),
                    !!!this.state.rowItem.deliveryDate && this.formatDisplayDate(this.state.rowItem.sortDate),
                    (this.props.listType == ListType.Planned) && (!!this.state.rowItem.deliveryWindowFrom && !!this.state.rowItem.deliveryWindowTo) && this.getDisplayTime(this.state.rowItem),
                    !!(this.props.listType == ListType.Completed) &&
                        (React.createElement("span", { className: styles.show__Mobile_Only },
                            " ,",
                            this.formatDisplayTime(this.state.rowItem.deliveryDate)))),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.routeId.toString()); }, className: classnames(styles.Delivery__Cell, (this.props.listType == ListType.Completed && styles.show__Desktop_Only), (this.props.listType == ListType.Completed && styles.time)) },
                    React.createElement("span", { className: styles.Delivery__Cell__Mobile_label }, (this.props.listType === ListType.Planned) ? translate("/MyMenigo/DeliveryBlock/List/Departure") : translate("/MyMenigo/DeliveryBlock/List/Time")),
                    this.props.listType == ListType.Planned ? (!!this.state.rowItem.departure ? this.state.rowItem.departure : "-") :
                        this.formatDisplayTime(this.state.rowItem.deliveryDate)),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.routeId.toString()); }, className: styles.Delivery__Cell },
                    React.createElement("span", { className: styles.Delivery__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/List/NumberOfArticles")),
                    this.state.rowItem.numberOfArticles,
                    " ",
                    translate('/Units/UnitShortNames/st')),
                React.createElement("div", { onClick: function () { _this.setExpanded(_this.props.rowItem.routeId.toString()); }, className: styles.Delivery__Cell },
                    React.createElement("span", { className: styles.Delivery__Cell__Mobile_label }, translate("/MyMenigo/DeliveryBlock/List/TotalSum")),
                    this.state.rowItem.totalOrderValue.toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    " ",
                    translate('/Price/CurrencySymbol')),
                React.createElement("div", { className: classnames(styles.Delivery__Cell, (this.props.listType == ListType.Completed ? styles.link : styles.checkbox)) }, this.props.listType == ListType.Planned ? React.createElement(Checkbox, { value: this.state.rowItem.routeId, checked: !!this.state.rowItem.markedForPrint, onChange: this.setChecked.bind(this) })
                    : React.createElement(Button, { className: styles.Payslip__Link, appearance: "link", onClick: function () { } },
                        translate('/MyMenigo/Overview/Deliveryslip'),
                        " ",
                        React.createElement(MyMenigoIcon, { name: "external" })))),
            !!this.props.expanded && React.createElement(DeliveryDetails, { sortBy: "categoryName", listType: this.props.listType, items: this.state.rowItem.items, recycleItems: this.state.rowItem.recycleItems })));
    };
    return DetailedDeliveryRow;
}(React.Component));
export default DetailedDeliveryRow;
