var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main from 'Shared/PageLayout';
import ContentCardGrid from 'Shared/ContentCardGrid';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var ContentOverviewPage = /** @class */ (function (_super) {
    __extends(ContentOverviewPage, _super);
    function ContentOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    ContentOverviewPage.prototype.render = function () {
        return (React.createElement(Main, null,
            React.createElement(PropertyFor, { tagName: "h1", property: this.props.page.name }),
            propertyValueFor(this.props.page.hero) && (React.createElement("div", { className: styles.hero },
                React.createElement(PropertyFor, { property: this.props.page.hero, isImage: true }))),
            React.createElement("div", { className: styles.text },
                React.createElement(PropertyFor, { property: this.props.page.preamble })),
            React.createElement("div", { className: styles.text },
                React.createElement(PropertyFor, { property: this.props.page.mainBody })),
            React.createElement(ContentCardGrid, { currentBreakpoint: this.props.currentBreakpoint, cards: this.props.cards, showAll: true })));
    };
    return ContentOverviewPage;
}(React.Component));
export default ContentOverviewPage;
