import { parseUrl, urlToString, pathCombine } from 'Shared/url';
import * as server from 'Shared/server';
import { recipeMenuOverviewPageUrl } from 'Shared/known-urls';
import { updateCurrentPage } from 'SiteLayout/CurrentPage/action-creators';
export var RECIPE_MENU_EXPAND = 'RECIPE_MENU_EXPAND';
export var RECIPE_MENU_LISTS = 'RECIPE_MENU_LISTS';
export var INIT_SHOPPINGLIST = 'INIT_SHOPPINGLIST';
export function expandList(listId) {
    return function (dispatch, getState) {
        return server
            .get({ path: pathCombine(recipeMenuOverviewPageUrl(), 'details'), query: { id: listId } })
            .then(function (r) { return r.json(); })
            .then(function (json) {
            dispatch(updateCurrentPage(function (current) {
                var lists = getState().currentPage.lists;
                Object.assign(current, {
                    lists: lists.map(function (list) {
                        return list.id === listId ? insertListFromResponse(list, json.items) : list;
                    }),
                });
            }));
        })
            .catch(failedPromiseHandler);
    };
}
export function addRecipe(listId, item) {
    return function (dispatch, getState) {
        server
            .post(pathCombine(recipeMenuOverviewPageUrl(), 'connect'), { listId: listId, id: item.id })
            .then(function () {
            dispatch(updateCurrentPage(function (current) {
                var lists = getState().currentPage.lists;
                Object.assign(current, {
                    lists: lists.map(function (list) { return (list.id === listId ? insertItemsFromResponse(list, item) : list); }),
                });
            }));
        })
            .catch(function (e) {
            console.warn(e);
        });
    };
}
export var addRecipeShort = function (listId, id) { return function (dispatch, getState) {
    return server
        .post(pathCombine(recipeMenuOverviewPageUrl(), 'connect'), { listId: listId, id: id })
        .then(function () { return Promise.resolve(); })
        .catch(failedPromiseHandler);
}; };
export var createNewList = function (displayName) { return function (dispatch, getState) {
    return server
        .postJson(pathCombine(recipeMenuOverviewPageUrl(), 'create'), displayName)
        .then(function (res) {
        dispatch(updateCurrentPage(function (current) {
            var lists = getState().currentPage.lists;
            var newLists = Object.assign([], lists);
            newLists.push(res);
            Object.assign(current, {
                lists: newLists,
            });
        }));
        return Promise.resolve();
    })
        .catch(failedPromiseHandler);
}; };
export var createNewListAndAdd = function (displayName, id) { return function (dispatch, getState) {
    return server
        .postJson(pathCombine(recipeMenuOverviewPageUrl(), 'create?recipeId=' + id), displayName)
        .then(function () { return Promise.resolve(); })
        .catch(failedPromiseHandler);
}; };
export var removeRecipeInCurrentPage = function (listId, itemId) { return function (dispatch, getState) {
    dispatch(updateCurrentPage(function (current) {
        var lists = getState().currentPage.lists;
        Object.assign(current, {
            lists: lists.map(function (list) { return (list.id === listId ? removeItemFromResponse(list, itemId) : list); }),
        });
    }));
}; };
export var removeRecipeRequest = function (listId, itemId) { return function (dispatch, getState) {
    return server
        .get({ path: pathCombine(recipeMenuOverviewPageUrl(), 'delete'), query: { id: itemId, listId: listId } }) //fixa?
        .then(function (result) {
        return Promise.resolve();
    })
        .catch(failedPromiseHandler);
}; };
export var removeListInCurrentpage = function (listId) { return function (dispatch, getState) {
    dispatch(updateCurrentPage(function (current) {
        var lists = getState().currentPage.lists;
        Object.assign(current, {
            lists: lists.filter(function (list) { return list.id !== listId; }),
        });
    }));
}; };
export var removeListRequest = function (listId) { return function (dispatch, getState) {
    return server
        .get({ path: pathCombine(recipeMenuOverviewPageUrl(), 'delete'), query: { listId: listId } }) //fixa?
        .then(function (result) { return Promise.resolve(); })
        .catch(failedPromiseHandler);
}; };
export function checkList(listId, checked) {
    return function (dispatch, getState) {
        dispatch(updateCurrentPage(function (current) {
            var lists = getState().currentPage.lists;
            Object.assign(current, {
                lists: lists.map(function (list) {
                    return list.id !== listId
                        ? list
                        : Object.assign({}, list, {
                            selected: checked,
                        });
                }),
            });
        }));
    };
}
export function Export() {
    return function (dispatch, getState) {
        var lists = getState().currentPage.lists.filter(function (x) { return x.selected === true; }).map(function (i) { return i.id; });
        var getUrl = parseUrl({ path: pathCombine(recipeMenuOverviewPageUrl(), 'Export'), query: { menuIds: lists } });
        server.get(getUrl).then(function (result) {
            download(result, getUrl);
        });
    };
}
export function getShoppinglist(id) {
    return function (dispatch, getState) {
        return server
            .postJson(getState().appShellData.siteSettings.recipeMenuOverviewPage + "/Shoppinglist", id)
            .then(function (resp) {
            var shoppingList = [];
            if (resp.items) {
                resp.items.forEach(function (item) {
                    var product = !!item.product.isDiscontinued || !!item.product.isBackorderReplaced
                        ? item.product.replacementProduct
                        : item.product;
                    if (!!product && item.quantity > 0) {
                        shoppingList.push({
                            code: item.selectedVariant.code,
                            quantity: item.quantity,
                            unitShortName: item.selectedVariant.unitShortName,
                            ticket: item.selectedVariant.ticket,
                        });
                    }
                });
                dispatch(initShoppingList(shoppingList));
            }
            return Promise.resolve(resp);
        })
            .catch(failedPromiseHandler);
    };
}
export function getMenus() {
    return function (dispatch, getState) {
        return server
            .getJson(getState().appShellData.siteSettings.recipeMenuOverviewPage + "/menus")
            .then(function (json) { return Promise.resolve(json); })
            .catch(failedPromiseHandler);
    };
}
export function updatePortions(parentId, menuItemId, portions) {
    return function (dispatch, getState) {
        var itemModel = [];
        itemModel.push({
            Id: menuItemId,
            Portions: portions,
        });
        return server
            .post(getState().appShellData.siteSettings.recipeMenuOverviewPage + "/save", { id: parentId, items: itemModel })
            .then(function (result) {
            return result;
        })
            .catch(console.error);
    };
}
export function initShoppingList(shoppingList) {
    return {
        type: INIT_SHOPPINGLIST,
        shoppingList: shoppingList,
    };
}
function download(result, url) {
    if (!!result.url) {
        window.location.href = result.url;
    }
    else {
        window.location.href = urlToString(url);
    }
}
function insertItemsFromResponse(list, item) {
    var newItems = Object.assign([], list.items);
    item.parentId = list.id;
    newItems.push(item);
    return Object.assign({}, list, {
        items: newItems,
        isLoaded: true,
    });
}
function insertListFromResponse(list, items) {
    return Object.assign({}, list, {
        items: items,
        isLoaded: true,
    });
}
function removeItemFromResponse(list, itemId) {
    return Object.assign({}, list, {
        items: list.items.filter(function (x) { return x.id !== itemId; }),
    });
}
export function PopulateLists(lists) {
    return function (dispatch) {
        dispatch({
            type: RECIPE_MENU_LISTS,
            initLists: lists,
        });
    };
}
function failedPromiseHandler(e) {
    console.error(e);
    return Promise.reject(null);
}
