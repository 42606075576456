var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import FeedbackButton from 'Shared/Button/Feedback';
import Modal from 'Shared/Modal';
import { translate } from 'Shared/translate';
import { addRecipeShort, createNewListAndAdd, getMenus } from '../RecipeMenus/action-creators';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import Dialog, { DialogType } from 'Shared/Dialog';
import { Input } from 'Shared/Fields';
var CreateMenu = function (props) {
    var textInput = React.createRef();
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(useState([]), 2), menus = _b[0], setMenus = _b[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleConfirm = useCallback(function () {
        if (textInput.current.element.value.length > 0) {
            return props.createNewListAndAdd(textInput.current.element.value, props.id);
        }
        return Promise.reject();
    }, [textInput, props.id]);
    var setupModal = function () {
        return props.getAllMenus().then(function (result) {
            setMenus(result.menus);
            setOpen(true);
        });
    };
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, className: props.className },
        React.createElement(FeedbackButton, { appearance: "secondary", onClickWithFeedback: function (e, activate) { return activate(setupModal()); } }, translate('/RecipeMenu/AddToMenu')),
        React.createElement(Modal, { title: translate('/RecipeMenu/AddRecipe'), isOpen: isOpen, onClose: handleClose, reserveSpaceForCloseButton: true, fadeDesign: true },
            React.createElement("h4", null, translate('/RecipeMenu/AddToExisistingMenu')),
            React.createElement(DropDownMenu, { title: translate('/Shared/Choose') }, menus.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return props.addRecipeShort(list.id, props.id); }, onClose: handleClose })); })),
            React.createElement("h4", null, translate('/RecipeMenu/CreateNewMenu')),
            React.createElement(Input, { ref: textInput, width: "100%", autoFocus: true }),
            React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: handleConfirm, onCancel: handleClose, onStatusWasFulfilled: handleClose }))));
};
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    addRecipeShort: function (listId, id) { return dispatch(addRecipeShort(listId, id)); },
    createNewListAndAdd: function (displayName, id) { return dispatch(createNewListAndAdd(displayName, id)); },
    getAllMenus: function () { return dispatch(getMenus()); },
}); })(CreateMenu);
