import { getJson } from 'Shared/server';
export var pdfSendUrl = function (url, invoiceNumber) { return function (dispatch, getState) {
    return window.open(getState().appShellData.siteSettings.invoiceOverviewPage + "/RedirectToInvoice?url=" + url + "&invoiceNumber=" + invoiceNumber);
}; };
export function getInvoiceSequence(offset, filter, year) {
    return function (dispatch, getState) {
        return getJson(getState().appShellData.siteSettings.invoiceOverviewPage + "/GetInvoicesPerPage?offset=" + offset + "&year=" + year + "&filter=" + filter)
            .then(function (res) {
            return res;
        });
    };
}
