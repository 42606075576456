var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Logotype from 'Shared/Logotype';
import Main, { Section } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
// This component is rendered when the visitor has a service worker enabled browser
// and she is making a full page load for a url that we don't have in the local cache.
// So the app shell (header and footer) will be rendered and this component gets
// rendered during the time we load the page she wanted.
var AppShell = /** @class */ (function (_super) {
    __extends(AppShell, _super);
    function AppShell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppShell.prototype.render = function () {
        return (React.createElement(Main, { appearance: "narrow", className: styles.base }, this.props.isLoading ? (React.createElement(Logotype, { className: styles.logo, siteUrl: "/" })) : this.props.loadFailure && this.props.loadFailure.status === 404 ? (React.createElement(Section, null,
            React.createElement("h1", null, translate('/Errors/PageNotFoundHeader')),
            React.createElement("p", null, translate('/Errors/PageNotFoundText')))) : !navigator.onLine ? (React.createElement(Section, null,
            React.createElement("h1", null, translate('/Errors/OfflineHeader')),
            React.createElement("p", null, translate('/Errors/OfflineText')))) : (React.createElement(Section, null,
            React.createElement("h1", null, translate('/Errors/UnknownLoadFailureHeader')),
            React.createElement("p", null, translate('/Errors/UnknownLoadFailureText'))))));
    };
    return AppShell;
}(React.Component));
export default AppShell;
