import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import { toggleMainMenu } from '../action-creators';
import * as styles from './base.scss';
import classnames from 'classnames';
var DesktopMenuButton = function (props) {
    var _a, _b;
    return (React.createElement("a", { className: classnames(styles.button, (_a = {}, _a[styles.active] = props.isOpen, _a)), href: "javascript:;", onClick: function () { return props.toggleMainMenu(); } },
        React.createElement("div", { className: classnames(styles.button, (_b = {}, _b[styles.active] = props.isOpen, _b)) }, translate('/Product/Product'))));
};
export default connect(function (state) { return ({
    isOpen: state.mainMenu.isOpen,
}); }, function (dispatch) { return ({
    toggleMainMenu: function () { return dispatch(toggleMainMenu()); },
}); })(DesktopMenuButton);
