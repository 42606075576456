var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Modal, { ModalType } from 'Shared/Modal';
import { translate } from 'Shared/translate';
import { removeRecipeRequest, removeRecipeInCurrentPage } from '../../../action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import { timeout } from 'Shared/timeout';
var RemoveRecipe = function (props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var onDialogSucessfull = useCallback(function () {
        handleClose();
        // wait for modal to close
        timeout(200).then(function () { return props.removeRecipe(); });
    }, []);
    var handleRemoveRequest = useCallback(function () { return props.removeRecipeRequest(); }, []);
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, className: props.className },
        React.createElement(Button, { appearance: 'clear', onClick: function () { return setOpen(true); } },
            React.createElement(Icon, { name: "remove" })),
        React.createElement(Modal, { title: translate('/RecipeMenu/HeaderConfirmRemoveRecipe'), isOpen: isOpen, onClose: handleClose, type: ModalType.Warning },
            React.createElement("span", null, translate('/RecipeMenu/ConfirmRemoveRecipe')),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleRemoveRequest, onCancel: handleClose, onStatusWasFulfilled: onDialogSucessfull }))));
};
export default connect(function (state) { return ({}); }, function (dispatch, _a) {
    var listId = _a.listId, id = _a.id;
    return ({
        removeRecipeRequest: function () { return dispatch(removeRecipeRequest(listId, id)); },
        removeRecipe: function () { return dispatch(removeRecipeInCurrentPage(listId, id)); },
    });
})(RemoveRecipe);
