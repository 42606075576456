import React from 'react';
import * as styles from './base.scss';
var CampaignCategory = function (_a) {
    var subCategories = _a.subCategories, excludeCnt = _a.excludeCnt;
    var list = subCategories.map(function (campaign) { return (React.createElement("li", { key: campaign.displayName },
        React.createElement("a", { href: campaign.url, className: styles.facetValue },
            React.createElement("div", { className: styles.facetEllipsisWrap },
                React.createElement("label", null, campaign.displayName),
                !excludeCnt && React.createElement("span", { className: styles.count },
                    "(",
                    campaign.productCount,
                    ")"))))); });
    return React.createElement(React.Fragment, null, list);
};
export default CampaignCategory;
