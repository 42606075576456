import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import React from 'react';
import { translate } from 'Shared/translate';
import { parseUrl } from 'Shared/url';
var ClassificationEntry = function (_a) {
    var title = _a.title, shouldRender = _a.shouldRender, content = _a.content;
    var formatTrademark = function (trademark) {
        if (parseUrl(trademark).path.match(/[^"'<>]+.(?:png|jpe?g|gif)\b/gi)) {
            return React.createElement("img", { src: trademark });
        }
        return trademark;
    };
    var isLink = function (content) {
        return (!!content && !!(content + "").startsWith("http"));
    };
    var getLinkText = function (title, content) {
        if (title == translate("/Product/OriginalArticleNumber"))
            return content.split("?title=")[1];
        return React.createElement(React.Fragment, null,
            translate("/Product/ProductClassification/Manual"),
            (React.createElement(MyMenigoIcon, { name: "arrow" })));
    };
    return shouldRender ? (React.createElement("li", null,
        React.createElement("span", null,
            title,
            ": "),
        React.createElement("span", null,
            " ",
            isLink(content) ? (React.createElement("a", { href: content, target: "_blank" }, getLinkText(title, content))) : formatTrademark(content)))) : null;
};
export default ClassificationEntry;
