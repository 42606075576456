var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ContentCard from './../ContentCard';
import { onView, onClick } from './../ContentCard/DataLayer';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import classnames from 'classnames';
import { TINY } from 'Shared/breakpoints';
import ViewTracker from '../ViewObserver/ViewTracker';
var allowedCards = function (card) { return card.appearance !== 'large'; };
var ContentCardGrid = function (_a) {
    var cards = _a.cards, skipFirstCard = _a.skipFirstCard, showAll = _a.showAll, currentBreakpoint = _a.currentBreakpoint, isNews = _a.isNews;
    var filteredCards = cards.filter(allowedCards);
    var hasCards = filteredCards && filteredCards.length > 0;
    var numberOfCards = hasCards && (showAll || isNews) ? filteredCards.length : currentBreakpoint > TINY ? 9 : 5;
    return (React.createElement("div", { className: classnames(styles.flexwrapper, (showAll || isNews) && styles.row, 'ui-test-content-card-grid') }, filteredCards && filteredCards.length > 0 ? (filteredCards.map(function (data, i) {
        return (!skipFirstCard || i !== 0) &&
            i < numberOfCards && React.createElement(ViewTracker, { key: 'contentCardGridViewTracker' + i, onView: function (e) { return onView(e, data, i); } },
            React.createElement(ContentCard, __assign({}, data, { isNews: isNews, key: i, showAll: showAll, onClick: function (e) { return onClick(e, data, i); } })));
    })) : (React.createElement("div", null, translate('/Errors/NoContent')))));
};
export default ContentCardGrid;
