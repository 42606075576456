import moment from 'moment';
export function getDepartureDate(headerViewModel) {
    if (headerViewModel.selectedDelivery && headerViewModel.selectedDelivery.departureDate) {
        return moment(headerViewModel.selectedDelivery.departureDate);
    }
    else if (headerViewModel.deliveryDates && headerViewModel.deliveryDates.length > 0) {
        return moment(headerViewModel.deliveryDates[0].date);
    }
    return null;
}
