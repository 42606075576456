var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import classnames from "classnames";
import React from "react";
import { Checkbox } from "Shared/Fields";
import { translate } from "Shared/translate";
import * as styles from '../../base.scss';
var OrderHistoryHeaderRow = /** @class */ (function (_super) {
    __extends(OrderHistoryHeaderRow, _super);
    function OrderHistoryHeaderRow(props) {
        var _this = _super.call(this, props) || this;
        _this.setChecked = function (e) {
            _this.props.addAllToPrint(e.target.checked);
        };
        _this.state = {
            checked: _this.props.checked,
        };
        return _this;
    }
    OrderHistoryHeaderRow.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: prevProps.checked });
        }
    };
    OrderHistoryHeaderRow.prototype.render = function () {
        return (React.createElement("div", { className: classnames(styles.OrderHistory__Row, styles.OrderHistory__Row__Header) },
            React.createElement("div", { className: styles.OrderHistory__Cell }),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/OrderDate")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/OrderNumber")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/OrderReferenceId")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/CustomerComment")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/OrderType")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/NumberOfArticles")),
            React.createElement("div", { className: styles.OrderHistory__Cell }, translate("/MyMenigo/OrderHistory/List/TotalSum")),
            React.createElement("div", { className: styles.OrderHistory__Cell },
                React.createElement(Checkbox, { defaultChecked: !!this.state.checked, onChange: this.setChecked.bind(this) }))));
    };
    return OrderHistoryHeaderRow;
}(React.Component));
export default OrderHistoryHeaderRow;
