var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import { Checkbox } from 'Shared/Fields';
import { translate } from 'Shared/translate';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var InfoModalComponent = /** @class */ (function (_super) {
    __extends(InfoModalComponent, _super);
    function InfoModalComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfoModalComponent.prototype.toggleHideModal = function (checked) {
        var pageId = checked ? propertyValueFor(this.props.workId) : '-1';
        var now = new Date();
        now.setTime(now.getTime() + 1 * 3600 * 1000);
        document.cookie = 'hideInfoModal=' + pageId + '; expires=' + now.toUTCString() + '; path=/;SameSite=None;secure';
    };
    InfoModalComponent.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement("h1", null,
                React.createElement(PropertyFor, { property: this.props.name })),
            React.createElement("div", null,
                React.createElement(PropertyFor, { property: this.props.mainBody })),
            React.createElement("div", { className: styles.right },
                React.createElement("div", null,
                    React.createElement(Checkbox, { onChange: function (e) { return _this.toggleHideModal(e.target.checked); } }, translate('/Shared/DontShowAgain'))))));
    };
    return InfoModalComponent;
}(React.Component));
export default connect(function (state) { return ({
    currentUser: state.currentUser,
}); })(InfoModalComponent);
