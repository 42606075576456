var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './../base.scss';
import Price from '../../Price';
var ProductListItemPrice = function (props) {
    if (!props.isLoggedIn || props.isSupplierSalesman || !props.selectedVariant || !props.selectedVariant.price) {
        return null;
    }
    var isInCampaign = props.productIsInCampaign && props.price.current < props.price.original;
    return (isInCampaign ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.product_price__campaign },
            React.createElement(PriceDefault, __assign({}, props))),
        React.createElement("div", { className: styles.product_price__original },
            React.createElement(PriceDefault, __assign({}, props, { showOriginal: true }))))) : (React.createElement(PriceDefault, __assign({}, props))));
};
var PriceDefault = function (_a) {
    var price = _a.price, showOriginal = _a.showOriginal;
    return (React.createElement(Price, __assign({ size: "small" }, price, { comparison: null, current: showOriginal ? price.original : price.current, noPriceClassName: styles.noPrice })));
};
export default ProductListItemPrice;
