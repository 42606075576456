var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import { MEDIUM } from 'Shared/breakpoints';
import EditCustomProduct from './../../../../CustomProducts/EditCustomProduct';
import { onEditProductAction, onGetAreasAction, onGetCustomProductAction, onGetFirstLevelAction, onGetSuppliersAction, onGetUnitsAction, resetCustomProductEditId } from './../../../../CustomProducts/action-creators';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import * as styles from './../base.scss';
import Spinner from 'Shared/Spinner';
import { addCustomProductToPantryList, updateCustomProductsInPantryList } from 'Shared/ListItem/action-creators';
import classnames from 'classnames';
var CustomProductDialog = /** @class */ (function (_super) {
    __extends(CustomProductDialog, _super);
    function CustomProductDialog(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            suppliers: [],
            currentItem: null,
            firstCategoryLevel: [],
            secondCategoryLevel: [],
            thirdCategoryLevel: [],
            areas: {},
            units: {},
            dropDownItemsLoaded: false,
            disableScrollInModal: false
        };
        return _this;
    }
    CustomProductDialog.prototype.componentDidMount = function () {
        var _this = this;
        !this.props.isOpen && this.getSuppliersDropdown().then(function () {
            _this.getFirstLevelDropdown();
        }).then(function () {
            _this.getUnitsDropdown();
        }).then(function () {
            _this.getAreasDropdown();
        }).then(function () {
            _this.setState({
                dropDownItemsLoaded: true,
            });
        });
    };
    CustomProductDialog.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        if (!!this.props.customProductEditId && this.props.customProductEditId != prevProps.customProductEditId)
            !!this.props.activeList.products.some(function (x) { return x.selectedVariant.code == _this.props.customProductEditId; }) && this.getCustomProductForEdit(this.props.customProductEditId);
    };
    CustomProductDialog.prototype.getCustomProductForEdit = function (code) {
        var _this = this;
        return onGetCustomProductAction(code).then(function (result) {
            var item = result;
            return onEditProductAction(item).then(function (customProduct) {
                if (result != null)
                    _this.setState({ currentItem: result, secondCategoryLevel: customProduct.secondLevels, thirdCategoryLevel: customProduct.thirdLevels });
                else
                    _this.props.setFeedback({ text: translate('/Account/ProductList/LoadError'), type: 'error' });
            });
        });
    };
    CustomProductDialog.prototype.getUnitsDropdown = function () {
        var _this = this;
        return onGetUnitsAction().then(function (units) {
            _this.setState({
                units: units,
            });
        });
    };
    CustomProductDialog.prototype.getAreasDropdown = function () {
        var _this = this;
        return onGetAreasAction().then(function (areas) {
            _this.setState({
                areas: areas,
            });
        });
    };
    CustomProductDialog.prototype.getSuppliersDropdown = function () {
        var _this = this;
        return onGetSuppliersAction().then(function (suppliers) {
            _this.setState({
                suppliers: suppliers,
            });
        });
    };
    CustomProductDialog.prototype.getFirstLevelDropdown = function () {
        var _this = this;
        return onGetFirstLevelAction().then(function (firstLevel) {
            _this.setState({
                firstCategoryLevel: firstLevel,
                secondCategoryLevel: _this.state.secondCategoryLevel,
                thirdCategoryLevel: _this.state.thirdCategoryLevel,
            });
        });
    };
    CustomProductDialog.prototype.onAfterSave = function (id, success) {
        var _this = this;
        var customId = id + '_CUSTOM';
        !!success && this.props.addCustomProductToPantryList(this.props.listId, customId).then(function (result) {
            if (!!result.success)
                _this.closeModal();
        }).catch(function (reason) { console.log(reason); });
    };
    CustomProductDialog.prototype.onSetSave = function (id) {
        var _this = this;
        this.props.updateCustomProductsInPantryList(this.props.listId).then(function (result) {
            if (!!result.success) {
                _this.closeModal();
                _this.props.triggerReloadList();
            }
        }).catch(function (reason) { console.log(reason); });
    };
    CustomProductDialog.prototype.closeModal = function () {
        var _this = this;
        !!this.props.resetCustomProductEditId && this.props.resetCustomProductEditId();
        this.setState({ currentItem: null }, function () {
            _this.props.closeModal();
        });
    };
    CustomProductDialog.prototype.handleScrollInModal = function (disable) {
        this.setState({
            disableScrollInModal: disable
        });
    };
    CustomProductDialog.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement(Modal, { className: classnames((_a = {}, _a[styles.disableScrollInModal] = this.state.disableScrollInModal, _a)), title: this.state.currentItem ? translate('/Account/CustomProduct/Edit') : translate('/Account/CustomProduct/Add'), isOpen: !!this.props.isOpen || !!this.state.currentItem, onClose: function () { _this.closeModal(); } },
            React.createElement("div", { className: styles.customProductFormWrapper }, !!this.state.dropDownItemsLoaded ?
                React.createElement(EditCustomProduct, { customProductItem: this.state.currentItem, secondLevels: this.state.secondCategoryLevel, thirdLevels: this.state.thirdCategoryLevel, firstLevels: this.state.firstCategoryLevel, areas: this.state.areas, suppliers: this.state.suppliers, units: this.state.units, setSaved: function (id) { _this.onSetSave(id); }, onSaveComplete: function (id, success) { _this.onAfterSave(id, success); }, isMobile: this.props.currentBreakpoint < MEDIUM, disableScroll: function () { _this.handleScrollInModal(true); }, enableScroll: function () { _this.handleScrollInModal(false); } })
                : React.createElement(Spinner, { className: styles.spinner }))));
    };
    return CustomProductDialog;
}(React.PureComponent));
;
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    customProductEditId: state.modalInput.id
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    addCustomProductToPantryList: function (listId, code) {
        return dispatch(addCustomProductToPantryList(listId, code));
    },
    updateCustomProductsInPantryList: function (listId) {
        return dispatch(updateCustomProductsInPantryList(listId));
    },
    resetCustomProductEditId: function () {
        return dispatch(resetCustomProductEditId());
    },
}); })(CustomProductDialog);
