import { isBrowser } from './device-type';
import * as localStorage from './local-storage';
var EVENT_PREFIX = 'cross.window.event.';
// Note that this is only set in the browser, it's undefined
// when running on the server so we cannot rely on it always being
// set
var store;
// Because IE dispatches storage events also to the window that generated them,
// we keep this list of events we generate and if we encounter any of them we just ignore it.
var generatedEvents = [];
// If the list of events we generated ourselves contains more than this amount of events,
// assume that we are not getting our own events.
var MAX_EVENTS_TO_WATCH_FOR_SELF = 20;
export function setStore(s) {
    store = s;
}
var listeners = {};
if (isBrowser()) {
    var pendingCallbacks_1 = [];
    var timer_1;
    var runCallbacksIfVisible_1 = function () {
        if (document.visibilityState === 'visible' && pendingCallbacks_1.length) {
            console.debug('Calling cross window event callbacks because the page is visible');
            pendingCallbacks_1.forEach(function (c) { return c(store); });
            pendingCallbacks_1 = [];
        }
        else if (pendingCallbacks_1.length) {
            console.debug('Deferring calling cross window event callbacks because the page is not visible');
        }
    };
    window.addEventListener('storage', function (e) {
        if (e.key && e.key.indexOf(EVENT_PREFIX) === 0) {
            if (!e.newValue) {
                return;
            }
            if (generatedEvents) {
                var index = generatedEvents.indexOf(e.newValue.replace(/"/g, ''));
                if (index >= 0) {
                    generatedEvents.splice(index, 1);
                    return;
                }
            }
            var eventName = e.key.substr(EVENT_PREFIX.length);
            if (eventName in listeners) {
                listeners[eventName].forEach(function (c) {
                    if (pendingCallbacks_1.indexOf(c) === -1) {
                        pendingCallbacks_1.push(c);
                    }
                });
            }
            clearTimeout(timer_1);
            timer_1 = setTimeout(runCallbacksIfVisible_1, 100);
        }
    });
    document.addEventListener('visibilitychange', runCallbacksIfVisible_1);
}
export function on(event, callback) {
    var eventsArray = typeof event === 'string' ? [event] : event;
    eventsArray.forEach(function (eventName) {
        if (!(eventName in listeners)) {
            listeners[eventName] = [];
        }
        listeners[eventName].push(callback);
    });
    return function () {
        eventsArray.forEach(function (eventName) {
            var index = listeners[eventName].indexOf(callback);
            if (index !== -1) {
                listeners[eventName].splice(index, 1);
            }
        });
    };
}
export function emit(event) {
    if (!store) {
        throw new Error('Cannot emit cross window event before setting the Redux store');
    }
    var eventsArray = typeof event === 'string' ? [event] : event;
    eventsArray.forEach(function (eventName) {
        var id = 'event' + Math.random();
        if (generatedEvents) {
            if (generatedEvents.length < MAX_EVENTS_TO_WATCH_FOR_SELF) {
                generatedEvents.push(id);
            }
            else {
                generatedEvents = null;
            }
        }
        localStorage.set(EVENT_PREFIX + eventName, id);
    });
}
