var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import FeedbackButton from 'Shared/Button/Feedback';
import Modal from 'Shared/Modal';
import { translate } from 'Shared/translate';
import LoginContainer from 'Shared/Login/Container';
import { fetchShoppinglist } from './action-creators';
import ShoppingList from './ShoppingList';
import * as styles from './Pages/page.scss';
var AddRecipeToCart = function (props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(useState(null), 2), shoppingList = _b[0], setShoppingList = _b[1];
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleGetShoppingList = useCallback(function () {
        return props.fetchShoppinglist(props.portions).then(function (result) {
            setShoppingList(result);
            setOpen(true);
        });
    }, [shoppingList, props.fetchShoppinglist, props.portions, setShoppingList, setOpen]);
    return (React.createElement(LoginContainer, null, function (_a) {
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn ? (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(FeedbackButton, { appearance: "secondary", onClickWithFeedback: function (e, activate) { return activate(handleGetShoppingList()); }, disabled: props.isRestrictedCustomer }, translate('/AddOrder/AddToCart')),
            shoppingList && (React.createElement(Modal, { title: translate('/Recipe/ShoppingList', shoppingList.recipeName), isOpen: isOpen, onClose: handleClose, className: styles.addRecipeToCartModal },
                React.createElement(ShoppingList, __assign({}, shoppingList, { hideModal: handleClose })))))) : null;
    }));
};
export default connect(function (state) { return ({
    isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
}); }, function (dispatch) { return ({
    fetchShoppinglist: function (portion) { return dispatch(fetchShoppinglist(portion)); },
}); })(AddRecipeToCart);
