var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as crossWindowEvents from 'Shared/cross-window-events';
import deepmerge from 'deepmerge';
import * as server from 'Shared/server';
import { pathCombine } from 'Shared/url';
import { checkoutPageUrl } from 'Shared/known-urls';
import { CART_LOAD, rejectInvalidQuantity } from '../Cart/action-creators';
import { PAGE_UPDATE, PAGE_UPDATE_SUCCESS, PAGE_UPDATE_FAILURE, } from 'SiteLayout/CurrentPage/action-creators';
import { CART_LOAD_SUCCESS, CART_LOAD_FAILURE, CART_OPTIMISTIC_UPDATE, CART_CHANGES_ACCEPTED, isValidQuantity, getExistingItem, } from '../Cart/action-creators';
import { scrollPosition } from 'SiteLayout/Root';
import currentPageIsCheckout from './Pages/Checkout/current-page-is-checkout';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import { replaceState } from 'Shared/history';
import { batchActions } from 'redux-batched-actions';
import { updateAppShellData } from '../AppShell/action-creators';
import { addEcommerceEventToDataLayer } from './../TrackingInformation';
import propertyValueFor from 'Shared/property-value-for';
export var COMPLETE_PURCHASE = 'COMPLETE_PURCHASE';
export var COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS = 'COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS';
export var COMPLETE_PURCHASE_FAILURE = 'COMPLETE_PURCHASE_FAILURE';
export var CHECKOUT_OPTIMISTIC_UPDATE = 'CHECKOUT_OPTIMISTIC_UPDATE';
export var CHECKOUT_CHANGES_ACCEPTED = 'CHECKOUT_CHANGES_ACCEPTED';
export var CHECKOUT_CLEARCART = 'CHECKOUT_CLEARCART';
export var CHECKOUT_UPDATE_SUCCESS = 'CHECKOUT_UPDATE_SUCCESS';
export var TOGGLE_COMPLETE_PURCHASE_MODAL = 'TOGGLE_COMPLETE_PURCHASE_MODAL';
var completePurchaseInProgress = false;
export function toggleCompletePurchaseModal(showCompletePurchaseModal) {
    return {
        type: TOGGLE_COMPLETE_PURCHASE_MODAL,
        showCompletePurchaseModal: showCompletePurchaseModal,
    };
}
export function updateCartItemQuantity(code, quantity, date, unitShortName, location, ticket, hasAddedEmvInCart) {
    if (ticket === void 0) { ticket = null; }
    if (hasAddedEmvInCart === void 0) { hasAddedEmvInCart = false; }
    return function (dispatch, getState) {
        var _a;
        if (!isValidQuantity(quantity)) {
            rejectInvalidQuantity(code);
            return Promise.reject(null);
        }
        var cart = getState().checkout.cart;
        var existingItem = getExistingItem(cart, code);
        var isUpdateAction = existingItem ? true : false;
        var previousQuantity = existingItem ? existingItem.quantity : 0;
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: isUpdateAction,
                        newQuantity: quantity,
                        previousQuantity: previousQuantity,
                        newUnitShortName: unitShortName,
                        previousUnitShortName: unitShortName,
                        deliveryDate: date,
                    },
                    _a),
            },
        }, location, ticket, hasAddedEmvInCart)).catch(function (e) {
            var feedbackText = getActionBarError(translate('/Cart/Errors/ChangeQuantity'), cart, code);
            dispatch(setFeedback({ type: 'error', text: feedbackText }));
        });
    };
}
export function getProductDetails(url) {
    return function (dispatch, getState) {
        return server
            .getJson(url)
            .then(function (r) {
            return Promise.resolve(r);
        })
            .catch(function (e) {
            console.error(e);
            return Promise.reject(null);
        });
    };
}
export function addEmvItemToCart(code, unitShortName, location, quantity, hasAddedEmvInCart, ticket, date) {
    if (hasAddedEmvInCart === void 0) { hasAddedEmvInCart = true; }
    if (ticket === void 0) { ticket = null; }
    if (date === void 0) { date = null; }
    return function (dispatch, getState) {
        return dispatch(updateCartItemQuantity(code, quantity, date, unitShortName, location, ticket, hasAddedEmvInCart))
            .then(function () {
            dispatch(setFeedback({ type: 'success', text: translate('/Cart/Emv/EmvItemAddedSuccess') }));
            Promise.resolve();
        })
            .catch(function () {
            dispatch(setFeedback({ type: 'error', text: translate('/Cart/Emv/EmvItemAddedError') }));
            Promise.reject();
        });
    };
}
export function updateCartItemUnit(items, location, ticket) {
    if (ticket === void 0) { ticket = null; }
    return function (dispatch, getState) {
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: items,
            },
        }, location, ticket)).catch(function (e) {
            dispatch(setFeedback({ type: 'error', text: translate('/Cart/Errors/ChangeUnit') }));
        });
    };
}
export function updateCartDeliveryDate(code, date, location, ticket) {
    var _a;
    if (ticket === void 0) { ticket = null; }
    return updateCheckoutStateAndSend({
        cart: {
            items: (_a = {}, _a[code] = { isUpdateAction: true, deliveryDate: date }, _a),
        },
    }, location, ticket);
}
export function toggleIgnoreAvailability(code) {
    return function (dispatch, getState) {
        var _a;
        var cart = getState().checkout.cart;
        var existingItem = getExistingItem(cart, code);
        var subDeliveryDate = existingItem.subDeliveries.map(function (i) { return i.date; }).shift();
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: true,
                        deliveryDate: subDeliveryDate,
                        newIgnoreAvailability: !existingItem.ignoreAvailability,
                        previousIgnoreAvailability: existingItem.ignoreAvailability,
                    },
                    _a),
            },
        }, 'CheckoutPage')).catch(function (e) {
            var feedbackText = getActionBarError(translate('/Checkout/SetIgnoreAvailabilityError'), cart, code);
            dispatch(setFeedback({ type: 'error', text: feedbackText }));
        });
    };
}
export function toggleProductSample(code) {
    return function (dispatch, getState) {
        var _a;
        var cart = getState().checkout.cart;
        var existingItem = getExistingItem(cart, code);
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: true,
                        newIsProductSample: !existingItem.isProductSample,
                        previousIsProductSample: existingItem.isProductSample,
                        newDiscountType: existingItem.discountType,
                        newDiscountValue: 0,
                    },
                    _a),
            },
        }, 'CheckoutPage')).catch(function (e) {
            var feedbackText = getActionBarError(translate('/Checkout/SetProductSampleError'), cart, code);
            dispatch(setFeedback({ type: 'error', text: feedbackText }));
        });
    };
}
export function setDiscountValue(code, value) {
    return function (dispatch, getState) {
        var _a;
        var cart = getState().checkout.cart;
        var existingItem = getExistingItem(cart, code);
        dispatch(updateCheckoutState({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: true,
                        newDiscountValue: value,
                        previousDiscountValue: existingItem.discountValue,
                        newDiscountType: existingItem.discountType,
                    },
                    _a),
            },
        }, 'CheckoutPage'));
        return function () {
            return flushCheckoutRequests(dispatch, getState).catch(function (e) {
                var feedbackText = getActionBarError(translate('/Checkout/SetDiscountError'), cart, code);
                dispatch(setFeedback({ type: 'error', text: feedbackText }));
            });
        };
    };
}
export function setDiscountType(code, type) {
    return function (dispatch, getState) {
        var _a;
        var cart = getState().checkout.cart;
        var existingItem = getExistingItem(cart, code);
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        isUpdateAction: true,
                        newDiscountType: type,
                        previousDiscountType: existingItem.discountType,
                    },
                    _a),
            },
        }, 'CheckoutPage')).catch(function (e) {
            var feedbackText = getActionBarError(translate('/Checkout/SetDiscountTypeError'), cart, code);
            dispatch(setFeedback({ type: 'error', text: feedbackText }));
        });
    };
}
export function removeCartItem(code, date, unitShortName, location) {
    return function (dispatch, getState) {
        var _a;
        return dispatch(updateCheckoutStateAndSend({
            cart: {
                items: (_a = {},
                    _a[code] = {
                        newQuantity: 0,
                        newUnitShortName: unitShortName,
                        deliveryDate: date,
                        deleteRowOverride: true,
                    },
                    _a),
            },
        }, location)).catch(function (e) {
            var cart = getState().checkout.cart;
            var feedbackText = getActionBarError(translate('/Cart/Errors/Remove'), cart, code);
            dispatch(setFeedback({ type: 'error', text: feedbackText }));
        });
    };
}
export function removeAllCartItems(location) {
    var url = {
        path: pathCombine(checkoutPageUrl(), 'ClearCart'),
    };
    return function (dispatch, getState) {
        return server.post(url, location, null).then(function (json) {
            var result = json;
            dispatch(batchActions([
                { type: CHECKOUT_CLEARCART },
                { type: CART_LOAD_SUCCESS, cart: { items: [] } },
            ]));
            return result;
        });
    };
}
function dispatchCartAndCheckoutUpdate(data, getState, emitModified) {
    if (emitModified === void 0) { emitModified = true; }
    if (emitModified) {
        crossWindowEvents.emit('cart-modified');
    }
    var cartLoadSuccess = function () {
        return ({
            type: CART_LOAD_SUCCESS,
            cart: data.cart,
        });
    };
    var checkoutUpdate = function () { return dispatchCheckoutUpdate(data, getState, emitModified); };
    return [cartLoadSuccess(), checkoutUpdate()];
}
function dispatchCheckoutUpdate(data, getState, emitModified) {
    if (emitModified === void 0) { emitModified = true; }
    if (emitModified) {
        crossWindowEvents.emit('checkout-modified');
    }
    // We only update the page if the user didn't navigate away from the page
    // during the request
    if (currentPageIsCheckout(getState().currentPage)) {
        return {
            page: data,
            type: PAGE_UPDATE_SUCCESS,
        };
    }
    else {
        return {
            type: CHECKOUT_UPDATE_SUCCESS,
            page: data,
        };
    }
}
export function loadCheckout() {
    crossWindowEvents.emit('checkout-modified');
    return {
        type: PAGE_UPDATE,
    };
}
export function setOrderNoteDetails(cart) {
    return updateCheckoutStateAndSend({ cart: cart });
}
var currentCheckoutUpdate = Promise.resolve(null);
crossWindowEvents.on(['checkout-modified', 'cart-modified'], function (store) {
    if (!currentPageIsCheckout(store.getState().currentPage)) {
        return;
    }
    store.dispatch(batchActions([{ type: CART_LOAD }, { type: PAGE_UPDATE }]));
    console.debug('Reloading checkout since it was modified in another window/tab');
    // Note that we don't update the cart here. That is handled in the action creators for the cart.
    var url = { path: checkoutPageUrl() };
    currentCheckoutUpdate = currentCheckoutUpdate.then(function () {
        server
            .get(url)
            .then(function (r) { return r.json(); })
            .then(function (json) {
            store.dispatch(dispatchCheckoutUpdate(json, store.getState, false));
        })
            .catch(function (e) {
            console.error(e);
            store.dispatch({ type: CART_LOAD_FAILURE, error: e, url: url });
            store.dispatch({ type: PAGE_UPDATE_FAILURE, error: e, url: url });
        });
    });
});
var pendingTickets = [];
function handleCheckoutDiff(dispatch, getState, diff, location, ticket) {
    if (location === void 0) { location = null; }
    if (ticket === void 0) { ticket = null; }
    if (ticket) {
        pendingTickets.push(ticket);
    }
    diff.id = Math.random();
    if (diff.cart && diff.cart.items) {
        Object.keys(diff.cart.items).forEach(function (code) {
            var existingCartItem = getState().checkout.cart.items.find(function (i) { return i.lineItem.code === code; });
            var existingItem = existingCartItem && existingCartItem.lineItem;
            var applyIfDefinedOrFallback = function (value, fallback) { return (value !== undefined ? value : fallback); };
            if (existingItem) {
                var itemDiff = diff.cart.items[code];
                itemDiff.newQuantity = applyIfDefinedOrFallback(itemDiff.newQuantity, existingItem.quantity);
                itemDiff.previousQuantity = applyIfDefinedOrFallback(itemDiff.previousQuantity, existingItem.quantity);
                itemDiff.rowNumber = applyIfDefinedOrFallback(itemDiff.rowNumber, existingItem.rowNumber);
                itemDiff.previousDiscountType = applyIfDefinedOrFallback(itemDiff.previousDiscountType, existingItem.discountType);
                itemDiff.previousDiscountValue = applyIfDefinedOrFallback(itemDiff.previousDiscountValue, existingItem.discountValue);
                itemDiff.previousIsProductSample = applyIfDefinedOrFallback(itemDiff.previousIsProductSample, existingItem.isProductSample);
                itemDiff.previousIgnoreAvailability = applyIfDefinedOrFallback(itemDiff.previousIgnoreAvailability, existingItem.ignoreAvailability);
            }
        });
    }
    if (completePurchaseInProgress) {
        return Promise.reject(null);
    }
    var actions = [{ type: PAGE_UPDATE }];
    if (diff.cart) {
        var cartDiff = {
            id: Math.random(),
            items: diff.cart.items,
            orderNoteMarking: diff.cart.orderNoteMarking,
            orderNoteOrderNo: diff.cart.orderNoteOrderNo,
            location: location,
        };
        diff.cart = cartDiff;
        actions.push({ type: CART_LOAD });
        actions.push({
            type: CART_OPTIMISTIC_UPDATE,
            diff: cartDiff,
        });
    }
    actions.push({
        type: CHECKOUT_OPTIMISTIC_UPDATE,
        diff: diff,
    });
    dispatch(batchActions(actions));
}
function updateCheckoutStateAndSend(diff, location, ticket, hasAddedEmvInCart) {
    if (location === void 0) { location = null; }
    if (ticket === void 0) { ticket = null; }
    if (hasAddedEmvInCart === void 0) { hasAddedEmvInCart = false; }
    return function (dispatch, getState) {
        handleCheckoutDiff(dispatch, getState, diff, location, ticket);
        return flushCheckoutRequests(dispatch, getState, location, hasAddedEmvInCart);
    };
}
function updateCheckoutState(diff, location, ticket) {
    if (location === void 0) { location = null; }
    if (ticket === void 0) { ticket = null; }
    return function (dispatch, getState) {
        return handleCheckoutDiff(dispatch, getState, diff, location, ticket);
    };
}
export function sendPendingCheckoutRequests() {
    return function (dispatch, getState) {
        return flushCheckoutRequests(dispatch, getState, null);
    };
}
function flushCheckoutRequests(dispatch, getState, location, hasAddedEmvInCart) {
    if (location === void 0) { location = 'CheckoutPage'; }
    if (hasAddedEmvInCart === void 0) { hasAddedEmvInCart = false; }
    var url = {
        path: pathCombine(checkoutPageUrl(), 'update'),
    };
    var sendNextRequest = function (failedAttempts) {
        var _a;
        if (failedAttempts === void 0) { failedAttempts = 0; }
        var state = getState().checkout;
        var currentPendingChanges = state.pendingChanges || [];
        if (!currentPendingChanges.length) {
            return Promise.resolve(state.updateResult);
        }
        var totalDiff = {};
        currentPendingChanges.forEach(function (r) { return (totalDiff = deepmerge(totalDiff, r)); });
        var headers;
        if (pendingTickets.length) {
            var tickets = pendingTickets.join(',');
            pendingTickets = [];
            headers = (_a = {},
                _a[server.ESALES_TICKET_HEADER] = tickets,
                _a[server.ESALES_ACTION_HEADER] = server.ESALES_ADD_TO_CART_ACTION,
                _a);
        }
        return server
            .post(url, __assign({}, totalDiff, { hasAddedEmvInCart: hasAddedEmvInCart }), headers)
            .then(function (r) { return r.json(); })
            .then(function (json) {
            if (json.trackingData && json.trackingData.ga4EventObjects) {
                var ga4Events = json.trackingData.ga4EventObjects;
                if ("add_to_cart" in ga4Events) {
                    addEcommerceEventToDataLayer(ga4Events["add_to_cart"]);
                }
                if ("remove_from_cart" in ga4Events) {
                    addEcommerceEventToDataLayer(ga4Events["remove_from_cart"]);
                }
            }
            dispatch(batchActions(__spread([
                {
                    type: CART_CHANGES_ACCEPTED,
                    changes: currentPendingChanges.filter(function (c) { return c.cart != null; }).map(function (c) { return c.cart; }),
                },
                {
                    type: CHECKOUT_CHANGES_ACCEPTED,
                    changes: currentPendingChanges,
                }
            ], dispatchCartAndCheckoutUpdate(json, getState)).filter(Boolean)));
            return json.updateResult;
        })
            .catch(function (e) {
            console.error('Failed checkout request number: ', failedAttempts);
            if (failedAttempts === 3) {
                dispatch(batchActions([
                    { type: CART_LOAD_FAILURE, error: e, url: url },
                    { type: PAGE_UPDATE_FAILURE, error: e, url: url },
                ]));
                return Promise.reject(e);
            }
            return sendNextRequest(failedAttempts + 1);
        });
    };
    var sendNextDebounced = function () { return timeoutPromise(sendNextRequest, 0); };
    currentCheckoutUpdate = currentCheckoutUpdate.then(sendNextDebounced, sendNextDebounced);
    return currentCheckoutUpdate;
}
export function completePurchase(model, isStoreDevice, hasAddedEmvInCart) {
    if (hasAddedEmvInCart === void 0) { hasAddedEmvInCart = false; }
    return function (dispatch, getState) {
        if (completePurchaseInProgress) {
            return Promise.reject({ errorMessage: 'Complete purchase already in progress' });
        }
        dispatch({
            type: COMPLETE_PURCHASE,
        });
        completePurchaseInProgress = true;
        return currentCheckoutUpdate.then(function () {
            return server
                .post(pathCombine(checkoutPageUrl(), 'completePurchase'), { model: model, hasAddedEmvInCart: hasAddedEmvInCart })
                .then(function (r) { return r.json(); })
                .then(function (json) {
                dispatch(updateAppShellData());
                if (!!json.newPageData) {
                    var completeCheckoutFailure = function () {
                        return ({
                            type: COMPLETE_PURCHASE_FAILURE,
                        });
                    };
                    var cartAndCheckout = dispatchCartAndCheckoutUpdate(json.newPageData, getState);
                    var checkoutFailure = json.newPageData.verifyOrderErrorResultViewModel === null && completeCheckoutFailure();
                    var batchedArray = __spread([checkoutFailure], cartAndCheckout, [updateAppShellData()]);
                    dispatch(batchActions(batchedArray));
                    completePurchaseInProgress = false;
                    if (json.newDeliveryDate !== null) {
                        return Promise.resolve(json.newDeliveryDate);
                    }
                    else if (json.newPageData.verifyOrderErrorResultViewModel.length === 0) {
                        return Promise.reject(null);
                    }
                    else {
                        // Return data with the resolve so modal with changes is shown
                        var obj = {
                            verifyOrderErrorResultViewModel: json.newPageData.verifyOrderErrorResultViewModel,
                        };
                        return Promise.resolve(obj);
                    }
                }
                else if (json.punchOutRedirectUrl) {
                    window.location.href = json.punchOutRedirectUrl;
                    return new Promise(function () { return null; });
                }
                else if (!!json.paymentRedirectUrl) {
                    dispatch(updateAppShellData());
                    // We don't set `completePurchaseInProgress` to false here because
                    // we don't know how long time it takes for the payment gateway
                    // to load and we don't want to allow you to complete the purchase
                    // again during that time.
                    window.location.href = json.paymentRedirectUrl;
                    return new Promise(function () { return null; });
                }
                else if (!!json.orderConfirmationRedirectUrl) {
                    dispatch(updateAppShellData());
                    scrollPosition(0);
                    return replaceState(json.orderConfirmationRedirectUrl)
                        .then(function () {
                        completePurchaseInProgress = false;
                        dispatch({
                            type: COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS,
                        });
                    })
                        .catch(function (e) {
                        console.error('failed to push confirmation url', e);
                        dispatch({
                            type: COMPLETE_PURCHASE_FAILURE,
                        });
                        completePurchaseInProgress = false;
                        return Promise.reject({ errorMessage: e.message });
                    });
                }
                else {
                    console.error('completePurchase returned unknown result', json);
                    dispatch({
                        type: COMPLETE_PURCHASE_FAILURE,
                    });
                    completePurchaseInProgress = false;
                    return Promise.reject(null);
                }
            })
                .catch(function (e) {
                console.error('completePurchase returned error', e);
                dispatch({
                    type: COMPLETE_PURCHASE_FAILURE,
                });
                completePurchaseInProgress = false;
                return Promise.reject({ errorMessage: e.message });
            });
        });
    };
}
function timeoutPromise(func, timer) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            func().then(resolve, reject);
        }, timer);
    });
}
function getActionBarError(message, cart, code) {
    var existingCartItem = cart.items.find(function (i) { return i.lineItem.code === code; });
    if (existingCartItem) {
        var codeNbr = propertyValueFor(existingCartItem.product.content.code);
        return message + " - " + codeNbr + " " + existingCartItem.lineItem.displayName;
    }
    return message;
}
