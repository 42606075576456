var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classnames from 'classnames';
import Button from 'Shared/Button';
import ActionOptions from './ActionOptions';
import PrintOptions from './PrintOptions';
import { MasterFor } from 'Shared/MasterFor';
import { translate } from 'Shared/translate';
import { customProductsPageUrl } from 'Shared/known-urls';
import * as base from './base.scss';
import ValidationFeedback from './ValidationFeedback';
export var DropDownListType;
(function (DropDownListType) {
    DropDownListType[DropDownListType["Print"] = 0] = "Print";
    DropDownListType[DropDownListType["Actions"] = 1] = "Actions";
    DropDownListType[DropDownListType["MasterFor"] = 2] = "MasterFor";
    DropDownListType[DropDownListType["None"] = 3] = "None";
})(DropDownListType || (DropDownListType = {}));
var Toolbar = function (props) {
    var _a = __read(useState(DropDownListType.None), 2), ActiveDropDown = _a[0], SetActiveDropDown = _a[1];
    var _b = __read(useState(false), 2), ValidationFeedbackIsVisible = _b[0], SetValidationFeedbackIsVisible = _b[1];
    var _c = __read(useState(""), 2), ValidationFeedbackText = _c[0], SetValidationFeedbackText = _c[1];
    var handleSelect = function (type) {
        SetValidationFeedbackIsVisible(false);
        SetActiveDropDown(type);
    };
    var showValidationFeedback = function (visible) {
        SetValidationFeedbackIsVisible(visible);
    };
    var setValidationFeedbackMessage = function (text) {
        SetValidationFeedbackText(text);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: base.toolbarWrap },
            React.createElement(ActionOptions, { active: ActiveDropDown == DropDownListType.Actions, handleDropDownSelect: handleSelect, totalAmountOfPantries: props.lists.length, selectedLists: props.lists.filter(function (x) { return x.selected; }), showValidationFeedback: function (visible) { showValidationFeedback(visible); }, setValidationFeedbackMessage: function (text) { setValidationFeedbackMessage(text); } }),
            React.createElement(PrintOptions, { active: ActiveDropDown == DropDownListType.Print, handleDropDownSelect: handleSelect }),
            React.createElement(MasterFor, __assign({ className: base.toolButton }, props)),
            React.createElement("div", { className: classnames(base.buttonWrapper) },
                React.createElement(Button, { appearance: ['secondary', 'toolbar'], href: customProductsPageUrl() }, translate('/Account/ProductList/ManageArticles')))),
        React.createElement(ValidationFeedback, { visible: ValidationFeedbackIsVisible, text: ValidationFeedbackText })));
};
export default Toolbar;
