import React from "react";
import * as styles from '../../base.scss';
import { translate } from "Shared/translate";
import classnames from "classnames";
var RecycleItems = function (_a) {
    var recycleItems = _a.recycleItems;
    return (React.createElement("div", null,
        React.createElement("div", { className: classnames(styles.Recycle__List_Header, styles.Recycle__Row) },
            React.createElement("div", { className: styles.Recycle__Cell },
                React.createElement("strong", { className: styles.Title }, translate("/MyMenigo/DeliveryBlock/ProductList/RecycleMaterial"))),
            React.createElement("div", { className: styles.Recycle__Cell },
                React.createElement("strong", { className: styles.Title }, translate("/MyMenigo/DeliveryBlock/ProductList/PurchasePrice"))),
            React.createElement("div", { className: styles.Recycle__Cell },
                React.createElement("strong", { className: styles.Title }, translate("/MyMenigo/DeliveryBlock/ProductList/Amount")))),
        !!recycleItems && recycleItems.length
            ? recycleItems.map(function (item, index) {
                return (React.createElement("div", { key: index, className: styles.Recycle__Row },
                    React.createElement("div", { className: styles.Recycle__Cell }, !!item.itemDescription && item.itemDescription),
                    React.createElement("div", { className: styles.Recycle__Cell },
                        ((!!item.deliveredQuantity ? item.deliveredQuantity : item.orderedQuantity) * item.purchasePrice).toLocaleString('default', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                        "  ",
                        translate('/Price/CurrencySymbol')),
                    React.createElement("div", { className: styles.Recycle__Cell },
                        !!item.deliveredQuantity ? item.deliveredQuantity : item.orderedQuantity,
                        " ",
                        translate('/Units/UnitShortNames/st'))));
            }) : React.createElement(React.Fragment, null)));
};
export default RecycleItems;
