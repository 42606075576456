import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import Main, { Basic, Part } from 'Shared/PageLayout';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import ProductHeading from '../../../../Product/ProductHeading';
import NutritionBlock from 'Shared/Product/Blocks/NutritionBlock';
import InfoBlock from 'Shared/Product/Blocks/InfoBlock';
import IconMarkingsBlock from 'Shared/Product/Blocks/IconMarkingsBlock';
import ClassificationBlock from 'Shared/Product/Blocks/ClassificationBlock';
import MarkingBlock from 'Shared/Product/Blocks/MarkingBlock';
import PriceBlock from 'Shared/Product/Blocks/PriceBlock';
import MediaBlock from 'Shared/Product/Blocks/MediaBlock';
import BadgeBlock from 'Shared/Product/Blocks/BadgeBlock';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
var EmvProductDetail = function (_a) {
    var productViewModel = _a.productViewModel, selectedVariant = _a.selectedVariant;
    var discontinuedOrBackordered = productViewModel.isDiscontinued ||
        productViewModel.isDisabledForFreCustomer ||
        productViewModel.isBackorderReplaced;
    return (React.createElement(React.Fragment, null,
        React.createElement(Main, { className: styles.main, appearance: "narrow" },
            React.createElement(ProductHeading, { title: productViewModel.title }),
            React.createElement(Basic, { layout: "2to5" },
                React.createElement(Part, { className: styles.articleWrapper },
                    React.createElement("div", { className: styles.productInformation },
                        React.createElement("div", { className: styles.priceAvailabilityWrapper },
                            React.createElement("div", null,
                                React.createElement("div", { className: styles.expiration }, productViewModel.area && (React.createElement("div", { className: styles.h4 }, productViewModel.area))),
                                selectedVariant.availability && (React.createElement("div", { className: styles.availability },
                                    React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability }),
                                    React.createElement("b", null, selectedVariant.availability.feedbackMessage)))),
                            React.createElement("div", null,
                                React.createElement(PriceBlock, { selectedVariant: selectedVariant, isInCampaign: productViewModel.isInCampaign }))),
                        React.createElement("div", { className: styles.listQuantityWrapper }, discontinuedOrBackordered && (React.createElement("div", { className: styles.productDisabledInfoWrap },
                            React.createElement(ProductDisabledInfo, { isDiscontinued: productViewModel.isDiscontinued, isDeletedByMaster: false, isBackorderReplaced: productViewModel.isBackorderReplaced, replacementProduct: productViewModel.replacementProduct, recommendedReplacementProduct: productViewModel.recommendedReplacementProduct, isTender: productViewModel.isTender, isDisabledForFreCustomer: productViewModel.isDisabledForFreCustomer })))),
                        React.createElement("div", { className: styles.article },
                            React.createElement("div", { className: styles.h4 }, translate('/Product/ArticleInformation')),
                            React.createElement(ClassificationBlock, { productViewModel: productViewModel, selectedVariant: selectedVariant })))),
                React.createElement(Part, { className: styles.leftSection },
                    React.createElement(MarkingBlock, { productViewModel: productViewModel }),
                    React.createElement(MediaBlock, { productViewModel: productViewModel }),
                    React.createElement(BadgeBlock, { productViewModel: productViewModel })))),
        React.createElement("div", { className: styles.descriptionWrapper },
            React.createElement(InfoBlock, { info: productViewModel.suitableFor, isDesktop: true, isCheckout: true, headerText: '/Product/SuitableFor' }),
            React.createElement(InfoBlock, { info: productViewModel.flavorDescription, isDesktop: true, isCheckout: true, headerText: '/Product/FlavorDescription' }),
            React.createElement(InfoBlock, { info: productViewModel.sellingTextWeb, isDesktop: true, isCheckout: true, headerText: '/Product/Description' }),
            React.createElement(InfoBlock, { info: productViewModel.practicalInfo, isDesktop: true, isCheckout: true, headerText: '/Product/HandyInformation' }),
            React.createElement(IconMarkingsBlock, { classification: productViewModel.classification, isDesktop: true, isCheckout: true })),
        React.createElement(NutritionBlock, { itemSpecificationProduktBladdabas: productViewModel.itemSpecificationProduktBladdabas, itemSpecificationT0168: productViewModel.itemSpecificationT0168, itemSpecificationT4066: productViewModel.itemSpecificationT4066, itemSpecificationT4083: productViewModel.itemSpecificationT4083, itemSpecificationT4088: productViewModel.itemSpecificationT4088, itemSpecificationT4090: productViewModel.itemSpecificationT4090, itemSpecificationT4124: productViewModel.itemSpecificationT4124, itemSpecificationT4193: productViewModel.itemSpecificationT4193, itemSpecificationT5038: productViewModel.itemSpecificationT5038, itemSpecificationT5039: productViewModel.itemSpecificationT5039, itemSpecificationT5041: productViewModel.itemSpecificationT5041, itemSpecificationT2235: productViewModel.itemSpecificationT2235, nutritionFacts: productViewModel.nutritionFacts, documentUrls: productViewModel.documentUrls, informationProvider: productViewModel.informationProvider, manufacturerCountry: productViewModel.manufacturerCountry, currentBreakpoint: 5, countryOfOrigin: productViewModel.countryOfOrigin, isProductEquipment: productViewModel.isProductEquipment, isCheckout: true, lawInfoText: productViewModel.nutritionLawInfo })));
};
export default EmvProductDetail;
