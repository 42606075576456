var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
var PantryContentItemDetailRow = function (props) { return (React.createElement("div", { className: styles.expanded },
    React.createElement("div", null,
        translate('/App/Pantry/ArtNr'),
        ": ",
        props.productId),
    props.eans.length > 0 ? (React.createElement("div", null,
        translate('/App/Pantry/EAN'),
        ": ",
        props.eans.join(', '))) : null,
    React.createElement("div", null,
        translate('/App/Pantry/StockedAmount'),
        ": ",
        props.quantity,
        " ",
        props.unitShortName))); };
var PantryContentItemRow = /** @class */ (function (_super) {
    __extends(PantryContentItemRow, _super);
    function PantryContentItemRow(props) {
        var _this = _super.call(this, props) || this;
        _this.onClick = function () { return _this.setState({ expanded: !_this.state.expanded }); };
        _this.state = { expanded: false };
        return _this;
    }
    PantryContentItemRow.prototype.render = function () {
        return (React.createElement("div", { className: styles.rowContainer, onClick: this.onClick },
            React.createElement("div", { className: styles.row },
                React.createElement("div", null, !this.state.expanded ? React.createElement(Icon, { name: "arrow-right" }) : React.createElement(Icon, { name: "arrow-down" })),
                React.createElement("div", { className: styles.displayName }, this.props.displayName),
                React.createElement("div", { className: styles.eans }, this.props.eans.length > 0 ? '' : 'X')),
            this.state.expanded && React.createElement(PantryContentItemDetailRow, __assign({}, this.props))));
    };
    return PantryContentItemRow;
}(React.Component));
export default PantryContentItemRow;
