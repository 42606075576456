import React from 'react';
import { translate } from 'Shared/translate';
import highlightText from 'Shared/highlight-text';
import { searchTextToWords } from './../../action-creators';
var CategoryList = function (props) {
    return (props.categories &&
        props.categories.length > 0 && (React.createElement("div", null,
        React.createElement("h2", null, translate('/Search/ResultsForCategory', props.searchPhrase, props.categories.length)),
        React.createElement("ul", null, props.categories.map(function (item, i) {
            return (React.createElement("li", { key: i },
                React.createElement("a", { href: item.url, "data-ticket": item.ticket },
                    highlightText(item.displayName, searchTextToWords(props.searchPhrase)),
                    ' ',
                    React.createElement("span", null,
                        "(",
                        item.productCount,
                        ") "))));
        })))));
};
export default CategoryList;
