var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './report.scss';
import moment from 'moment';
var PantryReportPantry = /** @class */ (function (_super) {
    __extends(PantryReportPantry, _super);
    function PantryReportPantry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PantryReportPantry.prototype.componentDidMount = function () {
        window.print();
    };
    PantryReportPantry.prototype.render = function () {
        var report = this.props.report;
        var missingPrices = false;
        return (React.createElement("div", { className: styles.reportOverview },
            React.createElement("div", null,
                React.createElement("h3", null, translate('/Account/ProductList/PrintOptions/Table/Invent')),
                React.createElement("p", null, translate('/Account/ProductList/PrintOptions/Table/StockPantry')),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Customer'),
                        ":"),
                    React.createElement("span", null, report.customerName)),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Date'),
                        ":"),
                    React.createElement("span", null, moment(Date.now()).format('YYYY-MM-DD')))),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", { className: styles.headerRow },
                        React.createElement("th", { colSpan: 6 }, translate('/Account/ProductList/PrintOptions/Table/Name')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockIn')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockOut')))),
                report.groupedByPantries.map(function (group, i) {
                    missingPrices = missingPrices || group.missingPrices;
                    return (React.createElement("tbody", { key: 'printBody' + i, className: styles.groupSeparator },
                        React.createElement("tr", { className: styles.itemSeparator },
                            React.createElement("td", { colSpan: 6 },
                                group.displayName,
                                " ",
                                group.missingPrices && ' *'),
                            React.createElement("td", { className: styles.right }, group.previousStockValue.toFixed(2)),
                            React.createElement("td", { className: styles.right }, group.currentStockValue.toFixed(2)))));
                }),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: styles.totalOverall },
                        React.createElement("td", { colSpan: 6 },
                            translate('/Account/ProductList/PrintOptions/Table/StockTotal'),
                            "/",
                            report.customerName),
                        React.createElement("td", { className: styles.right }, report.all.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, report.all.currentStockValue.toFixed(2))),
                    missingPrices && (React.createElement("tr", { className: styles.missingPrice },
                        React.createElement("td", { colSpan: 8 },
                            "* ",
                            translate('/Account/ProductList/PrintOptions/Table/MissingPricePantry'))))))));
    };
    return PantryReportPantry;
}(React.Component));
export default PantryReportPantry;
