import React from 'react';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
import DeliveryRow from './DeliveryRow';
import { Part } from 'Shared/PageLayout';
import classnames from 'classnames';
import MyMenigoIcon from '../../../Shared/MyMenigoIcon/MyMenigoIcon';
import TruckPictogram from './TruckPictogram';
var TodaysDeliveries = function (_a) {
    var todaysDeliveries = _a.todaysDeliveries, linkUrl = _a.linkUrl, text = _a.text, deliveryPageUrl = _a.deliveryPageUrl, showLinksToDeliveries = _a.showLinksToDeliveries, showLinksToPackingSlip = _a.showLinksToPackingSlip;
    var pictogramCssClass = todaysDeliveries && todaysDeliveries.length ?
        (todaysDeliveries.length == 1 ? classnames(styles.pictogram, styles.one_Delivery) : styles.pictogram) : classnames(styles.pictogram, styles.no_Deliveries);
    var pictogramText = todaysDeliveries && todaysDeliveries.length > 0 ? todaysDeliveries.length.toString() + " " + (todaysDeliveries.length == 1 ? translate("/MyMenigo/Overview/OneDeliveryText") : translate("/MyMenigo/Overview/DeliveriesText")) : translate("/MyMenigo/Overview/NoDeliveriesText");
    return (React.createElement(Part, { className: styles.delivery__Block },
        React.createElement(Part, { className: styles.delivery__Body },
            React.createElement(Part, { className: pictogramCssClass },
                React.createElement(Part, null,
                    React.createElement(TruckPictogram, null),
                    React.createElement("p", null, pictogramText))),
            React.createElement(Part, { className: styles.delivery__List },
                todaysDeliveries && todaysDeliveries.length > 0
                    && todaysDeliveries.map(function (item, index) {
                        return (React.createElement(DeliveryRow, { key: index, isToday: true, deliveryItem: item, deliveryPageUrl: deliveryPageUrl, showLinksToDeliveries: showLinksToDeliveries, showLinksToPackingSlip: showLinksToPackingSlip, deliveredTitleText: !!item.inMotion && !!item.deliveryDate ? translate('/DeliveryApp/Shared/Delivered') : translate('/DeliveryApp/Shared/Delivery') }));
                    }),
                React.createElement(Part, { className: styles.delivery__ReadMoreRow },
                    React.createElement("p", null,
                        React.createElement("span", null, text),
                        !!linkUrl &&
                            React.createElement("a", { href: linkUrl },
                                translate("/Notifications/ReadMore"),
                                React.createElement(MyMenigoIcon, { name: "arrow" }))))))));
};
export default TodaysDeliveries;
