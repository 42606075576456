var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var Discount = /** @class */ (function (_super) {
    __extends(Discount, _super);
    function Discount(props) {
        return _super.call(this, props) || this;
    }
    Discount.prototype.render = function () {
        var discounts = this.props.list;
        var firstDiscount = discounts.length > 0 ? discounts[0] : null;
        var minDiscount;
        var maxDiscount;
        var valDiscount;
        if (firstDiscount != null) {
            minDiscount = firstDiscount.limit;
            maxDiscount = minDiscount;
            valDiscount = firstDiscount.value;
        }
        return (React.createElement("div", { className: styles.discountContainer },
            React.createElement("h4", null, translate('/MyMenigo/DiscountFreightBlock/Discounts')),
            React.createElement("p", null, translate('/MyMenigo/DiscountFreightBlock/DiscountText')),
            discounts.map(function (discount, index) {
                if (index !== 0) {
                    maxDiscount = discount.limit - 1;
                    var discountRendering = (React.createElement("div", { key: index, className: styles.discountItem },
                        React.createElement("div", { className: styles.limit },
                            minDiscount.toString(),
                            " kr - ",
                            maxDiscount.toString(),
                            " kr"),
                        React.createElement("div", { className: styles.percent },
                            parseFloat(valDiscount.toString()).toFixed(2),
                            "%")));
                    minDiscount = discount.limit;
                    valDiscount = discount.value;
                    return discountRendering;
                }
            }),
            React.createElement("div", { className: styles.discountItem },
                React.createElement("div", { className: styles.limit },
                    translate('/MyMenigo/DiscountFreightBlock/From'),
                    " ",
                    minDiscount.toString(),
                    " kr"),
                React.createElement("div", { className: styles.percent },
                    parseFloat(valDiscount.toString()).toFixed(2),
                    "%"))));
    };
    return Discount;
}(React.Component));
export default Discount;
