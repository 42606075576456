var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import * as styles from './base.scss';
import { Part, Section } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import FeedbackButton from 'Shared/Button/Feedback';
import * as server from 'Shared/server';
import Spinner from 'Shared/Spinner';
import { translate } from 'Shared/translate';
import MyMenigoIcon from '../../Shared/MyMenigoIcon/MyMenigoIcon';
import Button from 'Shared/Button';
import ToolTip from './Components/Tooltip/ToolTip';
import OrderHistoryList from './Components/OrderHistoryList/OrderHistoryList';
import loadAll from './Components/action-creators';
var MyMenigoOrderHistoryBlock = /** @class */ (function (_super) {
    __extends(MyMenigoOrderHistoryBlock, _super);
    function MyMenigoOrderHistoryBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.showLoadMore = function () {
            var initialPageSize = (!!_this.props.pageSize ? _this.props.pageSize : 20) - 1;
            if (!!!_this.state.showLoadButton)
                return false;
            return _this.state.orders.length > initialPageSize;
        };
        _this.setExpandedItem = function (item) {
            _this.setState({ expandedItem: item }, function () {
                !!item && !!window.document.getElementById("orderHistoryItem_" + item) && window.scroll({
                    top: window.document.getElementById("orderHistoryItem_" + item).offsetTop - 100,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        };
        _this.addRemoveToPrint = function (id, markedForPrint) {
            var updatedCheckedState = __spread(_this.state.orders);
            updatedCheckedState.map(function (el) { return (el.orderNumber === id ? Object.assign(el, { markedForPrint: markedForPrint }) : el); });
            _this.setState({ orders: updatedCheckedState, persistentToolTip: !!!id && !updatedCheckedState.some(function (x) { return !!x.markedForPrint === true; }) });
        };
        _this.doPrint = function () {
            _this.setState({ persistentToolTip: !_this.state.orders.some(function (x) { return !!x.markedForPrint === true; }) }, function () {
                _this.onPrint({
                    customer: "",
                    items: _this.state.orders.filter(function (x) { return !!x.markedForPrint === true; })
                });
            });
        };
        _this.state = {
            orders: props.orders || [],
            loading: false,
            expandedItem: "",
            persistentToolTip: false,
            printData: null,
            printLoading: false,
            showLoadButton: true,
            page: 0,
            showLoadAll: props.showLoadAll
        };
        return _this;
    }
    MyMenigoOrderHistoryBlock.prototype.componentDidMount = function () {
        // this.loadAll(false);
    };
    MyMenigoOrderHistoryBlock.prototype.onClickLoadAll = function () {
        return this.loadAll(true);
    };
    MyMenigoOrderHistoryBlock.prototype.onClickLoadMore = function () {
        return this.loadAll(false);
    };
    MyMenigoOrderHistoryBlock.prototype.loadAll = function (loadAll) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        return [4 /*yield*/, this.props.loadAll(this.state.page + 1, this.props.pageSize, this.props.recycleItemsArticleNumbers, loadAll).then(function (result) {
                                if (!result.success) {
                                    _this.props.setFeedback({
                                        text: result.message,
                                        type: 'error',
                                        overrideTimeout: true,
                                    });
                                    return Promise.reject(result);
                                }
                                else {
                                    _this.setState({ orders: result.orders, loading: false, showLoadButton: !loadAll, page: _this.state.page + 1 }, function () { return _this.setExpandedItem(_this.state.page + ""); });
                                    return Promise.resolve(result);
                                }
                            }).catch(function (any) { return _this.setState({ loading: false }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyMenigoOrderHistoryBlock.prototype.onPrint = function (model) {
        var _this = this;
        if (!model.items.some(function (x) { return x.markedForPrint === true; }))
            return;
        this.setState({ printLoading: true, persistentToolTip: false });
        server.post('/mymenigoorderhistory/setupprint', model).then(function (result) {
            document.getElementById('printFrame').setAttribute("src", '/mymenigoorderhistory/print');
            setTimeout(function () {
                _this.setState({ printData: model, printLoading: false });
            }, 6000);
        }).catch(function (any) {
            _this.setState({ printLoading: false }, function () {
                _this.props.setFeedback({
                    text: translate("/MyMenigo/DeliveryBlock/Print/PrintError"),
                    type: 'error',
                    overrideTimeout: true,
                });
            });
        });
    };
    MyMenigoOrderHistoryBlock.prototype.render = function () {
        var _this = this;
        return (React.createElement(Section, { className: !!this.state.loading && styles.loading },
            !!this.state.printLoading && (React.createElement("div", { className: styles.printLoader },
                React.createElement(Spinner, { className: styles.spinner }))),
            React.createElement(Part, { className: styles.toolbar },
                React.createElement(Part, { className: styles.toolbar__LeftSection }),
                React.createElement(Part, { className: styles.toolbar__RightSection },
                    React.createElement(ToolTip, { persistent: this.state.persistentToolTip, text: this.props.textForPrintToolTip, direction: "top" },
                        React.createElement(Button, { className: styles.Print__Link, appearance: "link", onClick: function () { _this.doPrint(); } },
                            React.createElement(MyMenigoIcon, { name: "print" }),
                            translate("/MyMenigo/DeliveryBlock/Print/Print"))))),
            React.createElement(OrderHistoryList, { printAll: false, addRemoveToPrint: this.addRemoveToPrint, setExpandedItem: this.setExpandedItem, expanded: this.state.expandedItem, orders: this.state.orders }),
            (!!this.showLoadMore()) &&
                React.createElement(Part, { className: styles.Pagination__Container },
                    !!!this.state.showLoadAll && React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(_this.onClickLoadMore()); } }, translate("/MyMenigo/OrderHistory/ShowMore")),
                    !!this.state.showLoadAll && React.createElement(FeedbackButton, { onClickWithFeedback: function (e, activate) { return activate(_this.onClickLoadAll()); } }, translate("/MyMenigo/OrderHistory/ShowAll"))),
            React.createElement("iframe", { id: "printFrame", src: "", style: { display: 'none' }, title: "Print" })));
    };
    return MyMenigoOrderHistoryBlock;
}(React.Component));
;
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    loadAll: function (page, pageSize, recycleItemsArticleNumbers, all) {
        return dispatch(loadAll(page, pageSize, recycleItemsArticleNumbers, all));
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(MyMenigoOrderHistoryBlock);
