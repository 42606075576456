import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import React from 'react';
import * as styles from './base.scss';
var ProductCategoryLabel = function (_a) {
    var area = _a.area, areaId = _a.areaId;
    var getAreaIcon = function (areaId) {
        if (areaId == "1")
            return 'fresh';
        else if (areaId == "2")
            return 'frozen';
        else if (areaId == "3")
            return 'colonial';
        else if (areaId == "4")
            return 'menuConsumtion';
        else if (areaId == "5")
            return 'alcohol';
        else if (areaId == "6")
            return 'menuEquipment';
        else
            return null;
    };
    return (React.createElement("div", { className: styles.areaIcon },
        !!getAreaIcon(areaId) && React.createElement(MyMenigoIcon, { name: getAreaIcon(areaId) }),
        area));
};
export default ProductCategoryLabel;
