var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
var RecipeItem = /** @class */ (function (_super) {
    __extends(RecipeItem, _super);
    function RecipeItem(props) {
        return _super.call(this, props) || this;
    }
    RecipeItem.prototype.render = function () {
        return (React.createElement("tr", null,
            React.createElement("td", null,
                !!this.props.amount && this.props.amount,
                " ",
                !!this.props.amount && !!this.props.unit && this.props.unit.name),
            React.createElement("td", null,
                !!this.props.product ? (React.createElement("a", { href: this.props.product.url, title: propertyValueFor(this.props.product.content.code) }, propertyValueFor(this.props.product.content.displayName))) : (this.props.name),
                !!this.props.noNutritions && '* ',
                !!this.props.notSold && '**')));
    };
    return RecipeItem;
}(React.Component));
export default RecipeItem;
