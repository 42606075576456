import React, { useCallback } from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import Dialog, { DialogType } from 'Shared/Dialog';
import { createNewList } from 'Shared/ListItem/action-creators';
import { Input } from 'Shared/Fields';
function NewListModal(props) {
    var textInput = React.createRef();
    var handleConfirm = useCallback(function () { return props.createNewList(textInput.current.element.value); }, [textInput]);
    var text = props.isPantry
        ? translate('/Account/ProductList/NewPantry')
        : translate('/Account/ProductList/NewList');
    return (React.createElement(Modal, { title: text, isOpen: props.isOpen, onClose: props.handleClose, reserveSpaceForCloseButton: true, fadeDesign: true },
        React.createElement(Input, { ref: textInput, autoFocus: true }),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: handleConfirm, onCancel: props.handleClose, onStatusWasFulfilled: props.handleClose })));
}
export default connect(null, function (dispatch) { return ({
    createNewList: function (displayName) { return dispatch(createNewList(displayName)); },
}); })(NewListModal);
