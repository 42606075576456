var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Item.prototype.render = function () {
        var _this = this;
        var _a;
        var isOpen = this.props.open === this;
        return (React.createElement("div", null,
            React.createElement("div", { className: classnames(styles.accordionItemHeader, (_a = {}, _a[styles.active] = isOpen, _a)), onClick: function () { return _this.props.toggle(_this); } },
                React.createElement("span", null, this.props.label),
                React.createElement(Icon, { name: isOpen ? 'minus' : 'plus' })),
            React.createElement("div", null,
                React.createElement("div", null, isOpen && this.props.children))));
    };
    return Item;
}(React.Component));
export default Item;
