var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
import { Checkbox, Input } from 'Shared/Fields';
import Button from 'Shared/Button';
import * as styles from './Pages/CustomProductOverview/base.scss';
import { TINY } from 'Shared/breakpoints';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoIcon from '../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var ListItemCustomProducts = /** @class */ (function (_super) {
    __extends(ListItemCustomProducts, _super);
    function ListItemCustomProducts(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            displayName: props.displayName,
            price: props.price,
            isSelected: props.isSelected,
            proxyPriceValue: props.price.toFixed(2) || 0,
            originalDisplayName: props.displayName,
            originalPrice: props.price,
        };
        return _this;
    }
    ListItemCustomProducts.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || !shallowEquals(this.state, nextState, true);
        if (!shouldUpdate) {
            console.info('Ignoring update of Custom prod. list item');
        }
        return shouldUpdate;
    };
    ListItemCustomProducts.prototype.componentWillReceiveProps = function (nextProps, nextState) {
        if (nextProps.price !== this.props.price) {
            this.setState({
                price: nextProps.price,
                proxyPriceValue: nextProps.price.toFixed(2),
            });
        }
        if (nextProps.calculateValue !== this.props.calculateValue &&
            !this.props.isLocked &&
            nextProps.calculateValue <= 100 &&
            nextProps.calculateValue >= 0) {
            var price = this.formatPrice(nextProps.price, nextProps.calculateValue);
            this.setState({
                proxyPriceValue: price.toFixed(2),
                price: price,
            });
        }
        if (nextProps.isSelected !== this.props.isSelected) {
            this.setState({ isSelected: nextProps.isSelected });
        }
        if (nextProps.displayName !== this.props.displayName) {
            this.setState({
                displayName: nextProps.displayName,
            });
        }
        if (nextProps.isSaved) {
            this.setState({
                originalDisplayName: nextProps.displayName,
                originalPrice: nextProps.price,
            });
        }
    };
    ListItemCustomProducts.prototype.updateDisplayName = function (value) {
        var _this = this;
        this.setState({
            displayName: value,
        }, function () { return _this.props.updateDisplayName(_this.props.id, value); });
    };
    ListItemCustomProducts.prototype.updateSelected = function () {
        var _this = this;
        var selected = !this.state.isSelected;
        this.setState({
            isSelected: selected,
        }, function () { return _this.props.updateSelected(_this.props.id); });
    };
    ListItemCustomProducts.prototype.isDecimal = function (input) {
        return input === '.' || input === ',';
    };
    ListItemCustomProducts.prototype.updatePrice = function (value) {
        var parsedValue = value;
        var update = true;
        if (typeof value === 'string') {
            var last = value.substr(value.length - 1);
            var first = value.charAt(0);
            if (this.isDecimal(last) || this.isDecimal(first)) {
                update = false;
            }
            else if ((value.indexOf(',') >= 0 || value.indexOf('.') >= 0) && last === '0') {
                update = false;
            }
            parsedValue = parseFloat(value.replace(',', '.'));
        }
        else if (typeof value === 'number') {
            parsedValue = value;
        }
        this.setState({
            proxyPriceValue: update ? parsedValue || 0 : value,
        });
    };
    ListItemCustomProducts.prototype.blurPrice = function () {
        var _this = this;
        var price = this.state.proxyPriceValue.toString();
        var priceStr = parseFloat(price.replace(',', '.')).toFixed(2);
        this.setState({
            proxyPriceValue: priceStr,
            price: parseFloat(priceStr),
        }, function () {
            _this.props.updatePrice(_this.props.id, parseFloat(priceStr));
        });
    };
    ListItemCustomProducts.prototype.formatPrice = function (price, calculateValue) {
        var newPrice = price;
        if (calculateValue > 0 && calculateValue <= 100) {
            newPrice = price * (calculateValue / 100 + 1);
        }
        return newPrice;
    };
    ListItemCustomProducts.prototype.handleEdit = function (e) {
        e.preventDefault();
        this.props.handleEdit(this.props.id);
    };
    ListItemCustomProducts.prototype.editNutrients = function (e) {
        e.preventDefault();
        this.props.editNutrients(this.props.id);
    };
    ListItemCustomProducts.prototype.render = function () {
        var _this = this;
        var canUpdate = !this.props.isLocked;
        return this.props.currentBreakpoint > TINY ? (React.createElement("tr", { className: styles.tableRow },
            React.createElement("td", { className: styles.code },
                React.createElement(Checkbox, { disabled: this.props.isLocked, checked: !!this.state.isSelected, onChange: function () { return _this.updateSelected(); } }),
                canUpdate ? (React.createElement("a", { href: void 0, onClick: function (e) { return _this.handleEdit(e); } }, this.props.productId)) : (React.createElement("span", { className: styles.disabled }, this.props.productId))),
            React.createElement("td", { className: styles.displayName }, this.props.displayName),
            React.createElement("td", { className: styles.suppliers }, this.props.supplier),
            React.createElement("td", null, this.props.specification),
            React.createElement("td", null, this.props.unitShortName),
            React.createElement("td", null,
                React.createElement(Input, { type: "text", step: 0.01, name: "price", className: styles.price, value: this.state.proxyPriceValue.toString(), autoComplete: "off", onChange: function (e) { return _this.updatePrice(e.target.value); }, onBlur: function () { return _this.blurPrice(); }, disabled: !canUpdate })),
            (!!this.props.pantryList && (React.createElement("td", { title: translate('/CustomProduct/Table/AlsoInPantry') + ' ' + this.props.pantryList },
                React.createElement(Icon, { className: styles.icon, name: "md-pantry" })))) || React.createElement("td", null),
            (!!this.props.gtin && (React.createElement("td", { title: this.props.gtin },
                React.createElement(Icon, { className: styles.icon, name: "barcode" })))) || React.createElement("td", null),
            React.createElement("td", null, canUpdate ? (React.createElement(Button, { appearance: "bare", onClick: function (e) { return _this.editNutrients(e); } },
                React.createElement(Icon, { className: styles.icon, name: "pie-chart" }))) : (React.createElement("span", { className: styles.disabled },
                React.createElement(Icon, { className: styles.icon, name: "pie-chart" })))),
            React.createElement("td", null, canUpdate ? (React.createElement(Button, { appearance: "bare", onClick: function (e) { return _this.props.deleteCustomItem(_this.props.id); } },
                React.createElement(MyMenigoIcon, { className: styles.icon, name: "trash" }))) : (React.createElement(Icon, { className: styles.icon, name: "lock" }))))) : (React.createElement("tr", { className: styles.tableRow },
            React.createElement("td", null,
                React.createElement(Checkbox, { disabled: this.props.isLocked, checked: !!this.state.isSelected, onChange: function () { return _this.updateSelected(); } }),
                canUpdate ? (React.createElement("a", { href: void 0, onClick: function (e) { return _this.handleEdit(e); } }, this.props.productId)) : (React.createElement("span", { className: styles.disabled }, this.props.productId))),
            React.createElement("td", { className: styles.displayName },
                React.createElement("div", null, this.props.displayName),
                React.createElement("div", null, this.props.supplier),
                React.createElement("div", null, this.props.specification),
                React.createElement("div", { className: styles.pricewrapper },
                    React.createElement(Input, { type: "text", step: 0.01, name: "price", className: styles.price, value: this.state.proxyPriceValue.toString(), autoComplete: "off", onChange: function (e) { return _this.updatePrice(e.target.value); }, onBlur: function () { return _this.blurPrice(); }, disabled: !canUpdate }),
                    React.createElement("span", null,
                        translate('/Price/CurrencySymbol'),
                        " / ",
                        this.props.unitShortName))),
            React.createElement("td", { className: styles.icons },
                !!this.props.pantryList && (React.createElement("span", { title: translate('/CustomProduct/Table/AlsoInPantry') + ' ' + this.props.pantryList },
                    React.createElement(Icon, { className: styles.icon, name: "md-pantry" }))),
                !!this.props.gtin && (React.createElement("span", { title: this.props.gtin },
                    React.createElement(Icon, { className: styles.icon, name: "barcode" }))),
                canUpdate ? (React.createElement(Button, { appearance: "bare", onClick: function (e) { return _this.editNutrients(e); } },
                    React.createElement(Icon, { className: styles.icon, name: "pie-chart" }))) : (React.createElement("span", { className: styles.disabled },
                    React.createElement(Icon, { className: styles.icon, name: "pie-chart" }))),
                canUpdate ? (React.createElement(Button, { appearance: "bare", onClick: function () { return _this.props.deleteCustomItem(_this.props.id); } },
                    React.createElement(MyMenigoIcon, { className: styles.icon, name: 'trash' }))) : (React.createElement("span", null,
                    React.createElement(Icon, { className: styles.icon, name: "lock" }))))));
    };
    return ListItemCustomProducts;
}(React.Component));
export default ListItemCustomProducts;
