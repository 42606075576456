var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import { addToCart } from '../../../Cart/action-creators';
import connect from 'Shared/connect';
import UnitOptionSelect from '../../../QuickOrder/QuickOrderPage/UnitOptionSelect';
import ProductItem from '../../../App/ProductItem/ProductItem';
import ProductPrice from '../../../App/ProductItem/ProductPrice';
import { translate } from 'Shared/translate';
import { updateItem } from 'Shared/ListItem/action-creators';
import shallowEquals from 'Shared/shallow-equals';
import { toNumberValue, isDecimal } from 'Shared/number-format';
var ProductSearchListItem = /** @class */ (function (_super) {
    __extends(ProductSearchListItem, _super);
    function ProductSearchListItem(props) {
        var _this = _super.call(this, props) || this;
        // https://bugs.chromium.org/p/chromium/issues/detail?id=4505
        _this.selectOnFocus = function (e) {
            var target = e.target;
            setTimeout(function () { return target.select(); }, 0);
        };
        _this.state = {
            proxyQuantity: toNumberValue(props.quantity || ''),
        };
        return _this;
    }
    ProductSearchListItem.prototype.add = function () {
        var _this = this;
        var q = parseFloat((this.props.quantity || '').replace(',', '.'));
        if (isNaN(q)) {
            return;
        }
        var code = this.props.productId + '_' + this.props.unitShortName;
        this.props.addToCart(code, q, this.props.unitShortName).then(function (_) { return _this.changeQuantity(''); });
    };
    ProductSearchListItem.prototype.componentWillReceiveProps = function (newProps) {
        if (newProps.quantity !== this.props.quantity) {
            this.setState({ proxyQuantity: newProps.quantity || '' });
        }
    };
    ProductSearchListItem.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps, true) || nextState.proxyQuantity !== this.state.proxyQuantity;
        if (!shouldUpdate) {
            console.info('Ignoring update favorite item');
        }
        return shouldUpdate;
    };
    ProductSearchListItem.prototype.changeQuantity = function (value) {
        this.props.updateItem(this.props.productId, Object.assign({}, this.props, { quantity: value }));
    };
    ProductSearchListItem.prototype.changeUnitShortName = function (value) {
        this.props.updateItem(this.props.productId, Object.assign({}, this.props, { unitShortName: value }));
    };
    ProductSearchListItem.prototype.changeQuantityValidation = function (value) {
        var _this = this;
        var parsedValue;
        var update = true;
        var last = value.substr(value.length - 1);
        var first = value.charAt(0);
        if (isDecimal(last) || isDecimal(first)) {
            update = false;
        }
        else if ((value.indexOf(',') >= 0 || value.indexOf('.') >= 0) && last === '0') {
            update = false;
        }
        var pattern = /[^0-9\.\,]/;
        var proxyValue = value.replace(',', '.').replace(pattern, '');
        parsedValue = parseFloat(proxyValue);
        this.setState({ proxyQuantity: toNumberValue(proxyValue) }, function () {
            update && _this.changeQuantity(parsedValue.toString());
        });
    };
    ProductSearchListItem.prototype.render = function () {
        var _this = this;
        var _a;
        return (React.createElement("div", { className: classnames(styles.row, (_a = {},
                _a[styles.discontinued] = this.props.isDiscontinued || this.props.isDisabledForFreCustomer,
                _a)) },
            React.createElement(ProductItem, __assign({}, this.props)),
            this.props.isDiscontinued || this.props.isDisabledForFreCustomer ? (React.createElement("div", { className: styles.controls },
                React.createElement("span", { className: styles.discontinuedText }, this.props.isDisabledForFreCustomer
                    ? translate('/Product/DisabledForFreCustomer')
                    : translate('/Product/Discontinued')))) : (React.createElement("div", { className: styles.controls },
                React.createElement(ProductPrice, __assign({ apperance: "flex", layout: "left" }, this.props)),
                React.createElement(UnitOptionSelect, { options: this.props.unitOptions.map(function (o) { return o.unitShortName; }), value: this.props.unitShortName, onChange: function (value) { return _this.changeUnitShortName(value); } }),
                React.createElement("input", { className: styles.xsinput, type: "text", autoComplete: "off", onFocus: this.selectOnFocus, onChange: function (e) { return _this.changeQuantityValidation(e.target.value); }, value: this.state.proxyQuantity }),
                React.createElement("button", { onClick: function () { return _this.add(); } }, translate('/App/BuyButton'))))));
    };
    return ProductSearchListItem;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    addToCart: function (code, quantity, unitShortName) {
        return dispatch(addToCart(code, quantity, unitShortName, null, null));
    },
    updateItem: function (productId, product) {
        dispatch(updateItem(productId, product));
    },
}); })(ProductSearchListItem);
