import classnames from "classnames";
import MyMenigoIcon from "./../../../../Shared/MyMenigoIcon/MyMenigoIcon";
import React from "react";
import * as styles from '../../base.scss';
import { ListType } from "../DeliveryList/DeliveryList";
import { translate } from "Shared/translate";
var DeliveryHeaderRow = function (_a) {
    var listType = _a.listType, showDeliverdColumn = _a.showDeliverdColumn, sortField = _a.sortField, sortAscending = _a.sortAscending, setSortedField = _a.setSortedField;
    var sortOn = function (field, secondaryfield) {
        setSortedField(field, secondaryfield);
    };
    var sortableHeader = function (headerText, fieldName, ascending) {
        return (React.createElement("span", { className: styles.sortableLabel },
            headerText,
            " ",
            (sortField == fieldName ? (!ascending ? React.createElement(MyMenigoIcon, { name: "asc" }) : React.createElement(MyMenigoIcon, { name: "desc" })) : React.createElement(React.Fragment, null))));
    };
    return (React.createElement("div", { className: classnames(styles.Products__Row, (listType === ListType.Completed ? styles.Products__Row__Header : styles.Products__Row__PlannedHeader)) },
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('categoryName', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/CategoryName"), 'categoryName', sortAscending)),
        React.createElement("div", { className: styles.Products__Cell }),
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('itemDescription', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/ItemDescription"), 'itemDescription', sortAscending)),
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('deliveredUnit', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/DeliveredUnit"), 'deliveredUnit', sortAscending)),
        ((listType === ListType.Completed) || showDeliverdColumn) &&
            React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('deliveredQuantity', 'itemDescription'); } },
                sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/DeliveredQuantity"), 'deliveredQuantity', sortAscending),
                " ",
                showDeliverdColumn),
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('orderedQuantity', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/OrderedQuantity"), 'orderedQuantity', sortAscending)),
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('purchasePrice', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/PurchasePrice"), 'purchasePrice', sortAscending)),
        React.createElement("div", { className: styles.Products__Cell, onClick: function () { return sortOn('orderNumber', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/OrderNumber"), 'orderNumber', sortAscending))));
};
export default DeliveryHeaderRow;
