import React from 'react';
import classnames from 'classnames';
import * as styles from './components.scss';
import MyMenigoIcon from '../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import Icon from 'Shared/Icon';
import { handleQuickFilterClick } from './action-creators';
;
var quickFilterClick = function (item, onCustomClick) {
    if (!!item.disabled)
        return false;
    !!!onCustomClick && handleQuickFilterClick(item, item.selected);
    !!onCustomClick && onCustomClick(item);
};
var QuickFilterButton = function (_a) {
    var _b;
    var item = _a.item, onCustomClick = _a.onCustomClick, small = _a.small;
    return (React.createElement("li", { className: styles.searchFilter },
        React.createElement("button", { onClick: function () { quickFilterClick(item, onCustomClick); }, className: classnames(styles.button, (_b = {}, _b[styles.small] = !!small, _b[styles.selected] = item.selected, _b[styles.disabled] = item.disabled, _b)) },
            !!!small && !!item.icon && React.createElement(MyMenigoIcon, { name: item.icon }),
            item.buttonText,
            " ",
            !!item.selected && React.createElement(Icon, { name: "close" }))));
};
export default QuickFilterButton;
