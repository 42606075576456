var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import MobileField from './MobileField';
import Button from './Button';
import { pushState } from 'Shared/history';
import * as searchHistory from '../../../../../Search/search-history';
;
import { searchPageUrl } from 'Shared/known-urls';
import { loadQuickSearch, closeQuickSearch, showQuickSearch, updateSelectedCompletion } from '../../../../../Search/action-creators';
import * as styles from './mobile.scss';
import { scrollPosition } from 'SiteLayout/Root';
import Flyout from 'Shared/Flyout';
var MobileQuickSearch = /** @class */ (function (_super) {
    __extends(MobileQuickSearch, _super);
    function MobileQuickSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.go = function () {
            _this.props.closeQuickSearch();
            scrollPosition(0);
            var searchText = _this.props.quickSearch.searchText;
            if (searchText.length) {
                searchHistory.add(searchText);
                pushState({ path: searchPageUrl(), query: { q: _this.props.quickSearch.searchText } });
            }
        };
        return _this;
    }
    MobileQuickSearch.prototype.open = function () {
        this.props.showQuickSearch();
    };
    MobileQuickSearch.prototype.onButtonClick = function () {
        if (!this.props.quickSearch.isOpen) {
            this.open();
        }
        else {
            this.go();
        }
    };
    MobileQuickSearch.prototype.render = function () {
        var _this = this;
        var _a;
        var isOpen = this.props.quickSearch.isOpen;
        return (React.createElement("div", { className: classnames((_a = {}, _a[styles.open] = isOpen, _a)) },
            React.createElement(Button, { className: styles.base, onClick: function () { return _this.onButtonClick(); } }),
            React.createElement(Flyout, { position: "right", canOpen: true, toggleFlyout: null, isOpen: isOpen, fillWidth: true },
                React.createElement(MobileField, { completionsLists: this.props.quickSearch.completionsLists, searchText: this.props.quickSearch.searchText, onChange: this.props.loadQuickSearch, onClose: this.props.closeQuickSearch, onSearch: this.go }))));
    };
    return MobileQuickSearch;
}(React.Component));
export default connect(function (state) { return ({
    quickSearch: state.quickSearch,
}); }, function (dispatch) { return ({
    loadQuickSearch: function (searchText) { return dispatch(loadQuickSearch(searchText)); },
    updateSelectedCompletion: function (index, type) {
        dispatch(updateSelectedCompletion(index, type));
    },
    closeQuickSearch: function () {
        dispatch(closeQuickSearch());
    },
    showQuickSearch: function () {
        dispatch(showQuickSearch());
    },
}); })(MobileQuickSearch);
