var _a;
import React, { memo } from 'react';
import classnames from 'classnames';
import { urlWithSizeEx } from 'Shared/ImageFallback';
import NormalContentCard from './NormalContentCard';
import ImageContentCard from './ImageContentCard';
import LargeContentCard from './LargeContentCard';
var noImage = require('styles/images/missingImage.svg');
var DEFAULT_APPEARANCE = 'content';
var cardAppearances = (_a = {},
    _a['content'] = NormalContentCard,
    _a['medium'] = ImageContentCard,
    _a['large'] = LargeContentCard,
    _a['largeOnlyImage'] = ImageContentCard,
    _a);
var appearanceTypeImage = function (props) { return ({
    content: function () { return urlWithSizeEx(props.contentCardImage, 'content'); },
    medium: function () { return urlWithSizeEx(props.mediumContentCardImage, 'medium'); },
    large: function () { return urlWithSizeEx(props.largeContentCardImage, 'large'); },
    largeOnlyImage: function () { return urlWithSizeEx(props.largeContentCardImage, 'large'); },
    all: function () { return urlWithSizeEx(props.contentCardImage, 'content'); },
    news: function () { return urlWithSizeEx(props.contentCardImage, 'content'); },
}); };
var getAppearance = function (props) {
    return (props.showAll && props.appearance === 'content'
        ? 'all'
        : props.isNews && props.appearance === 'content'
            ? 'news'
            : props.appearance === 'large' && !!!props.text
                ? 'largeOnlyImage'
                : props.appearance) || DEFAULT_APPEARANCE;
};
var getCardType = function (props) {
    return (props.appearance === 'large' && !!!props.text
        ? 'largeOnlyImage'
        : props.appearance || DEFAULT_APPEARANCE);
};
var getImage = function (props) { return appearanceTypeImage(props)[getAppearance(props)]() || noImage; };
export default memo(function (props) {
    return React.createElement(cardAppearances[getCardType(props)], Object.assign({}, props, {
        imageUrl: getImage(props),
        appearance: getAppearance(props),
        className: classnames('ui-test-content-card', props.className),
    }));
});
