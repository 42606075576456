var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { DOWN, UP, TAB, PLUS, MINUS, PLUS_DESKTOP, MINUS_DESKTOP, ESC, ENTER } from 'Shared/keyboard';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import classnames from 'classnames';
var inputDebouncer = 400;
var AutoComplete = /** @class */ (function (_super) {
    __extends(AutoComplete, _super);
    function AutoComplete(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selectedIndex: -1,
        };
        return _this;
    }
    AutoComplete.prototype.moveSelection = function (direction) {
        var newSelectedIndex = -1;
        var collection = this.props.list;
        newSelectedIndex = this.getNewSelection(direction, collection, this.state.selectedIndex);
        if (newSelectedIndex < 0) {
            newSelectedIndex = this.getNewSelection(direction, collection, newSelectedIndex);
        }
        this.setState({
            selectedIndex: newSelectedIndex,
        });
    };
    AutoComplete.prototype.getNewSelection = function (direction, list, selectedIndex) {
        if (direction === 'next') {
            return selectedIndex + 1 > list.length - 1 ? -1 : selectedIndex + 1;
        }
        else if (direction === 'cancel') {
            return (selectedIndex = -1);
        }
        else {
            return selectedIndex >= 0 ? selectedIndex - 1 : list.length > 0 ? list.length - 1 : -1;
        }
    };
    AutoComplete.prototype.onKeyDown = function (event) {
        switch (event.keyCode) {
            case PLUS_DESKTOP:
            case TAB:
            case DOWN:
            case PLUS:
                event.preventDefault();
                this.moveSelection('next');
                break;
            case UP:
            case MINUS:
            case MINUS_DESKTOP:
                event.preventDefault();
                this.moveSelection('prev');
                break;
            case ESC:
                event.preventDefault();
                this.moveSelection('cancel');
                this.inputRef.blur();
                this.bluring();
                break;
            case ENTER:
                event.preventDefault();
                if (this.state.selectedIndex > -1) {
                    this.adding(this.props.list[this.state.selectedIndex].customerId);
                }
                break;
            default:
                break;
        }
    };
    AutoComplete.prototype.adding = function (customerId) {
        this.props.onAdd(customerId);
        this.bluring();
    };
    AutoComplete.prototype.bluring = function () {
        var _this = this;
        this.inputRef.value = '';
        setTimeout(function () { return _this.props.onBlur(); }, 200);
        this.setState({ selectedIndex: -1 });
    };
    AutoComplete.prototype.onChange = function (e) {
        var _this = this;
        clearTimeout(this.inputTimer);
        if (!!e.target && !!e.target.value) {
            this.inputTimer = setTimeout(function () {
                if (!!_this.props.onChange && (_this.inputRef.value || '').trim() !== '') {
                    _this.props.onChange(_this.inputRef.value);
                }
            }, inputDebouncer);
        }
    };
    AutoComplete.prototype.render = function () {
        var _this = this;
        var list = this.props.list;
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("input", { ref: function (ref) { return (_this.inputRef = ref); }, type: "text", onChange: function (e) { return _this.onChange(e); }, onBlur: function () { return _this.bluring(); }, onKeyDown: function (e) { return _this.onKeyDown(e); } }),
            !!list && list.length > 0 && (React.createElement("table", { className: styles.list },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, translate('/Admin/MasterCustomers/CustomerNumber')),
                        React.createElement("th", null, translate('/Admin/MasterCustomers/Name'))),
                    list.map(function (c, i) { return (React.createElement("tr", { key: c.customerId, className: classnames(_this.state.selectedIndex === i && styles.highlighted, styles.link), onClick: function () { return _this.adding(c.customerId); } },
                        React.createElement("td", null, c.customerId),
                        React.createElement("td", null,
                            React.createElement("a", { className: styles.link }, c.name)))); }))))));
    };
    return AutoComplete;
}(React.Component));
export default AutoComplete;
