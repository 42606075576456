import React from 'react';
import * as styles from '../base.scss';
import ProductMarks from 'Shared/ProductCampaignLabel';
var MarkingBlock = function (_a) {
    var productViewModel = _a.productViewModel;
    var tags = !!productViewModel.primaryTags ? productViewModel.primaryTags : productViewModel.secondaryTags;
    if (!!productViewModel.primaryTags && !!productViewModel.secondaryTags)
        tags.concat(productViewModel.secondaryTags);
    return (React.createElement("div", { className: styles.secondaryProductHeadingSection },
        React.createElement("div", { className: styles.left },
            React.createElement(ProductMarks, { isOnProductPage: true, productCampaigns: tags, isSustainableChoice: productViewModel.isSustainableChoice }))));
};
export default MarkingBlock;
