import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import { copyToNewList } from 'Shared/ListItem/action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import { Input } from 'Shared/Fields';
function CopyDialog(props) {
    var textInput = React.createRef();
    return (React.createElement(Modal, { title: translate('/Account/ProductList/NamePantryList'), isOpen: props.isOpen, onClose: props.closeModal, reserveSpaceForCloseButton: true, fadeDesign: true },
        React.createElement(Input, { ref: textInput, autoFocus: true }),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return props.copyToNewList(textInput.current.element.value); }, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal })));
}
export default connect(null, function (dispatch, _a) {
    var listId = _a.listId, codes = _a.codes;
    return ({
        copyToNewList: function (displayName) { return dispatch(copyToNewList(listId, displayName, codes)); },
    });
})(CopyDialog);
