var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import Autocompletions from './Autocompletions';
import Icon from 'Shared/Icon';
import * as searchHistory from '../search-history';
import * as styles from './field.scss';
import * as stylesAutocomplete from './autocomplete.scss';
var QuickSearchField = /** @class */ (function (_super) {
    __extends(QuickSearchField, _super);
    function QuickSearchField() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClear = function () {
            if (_this.props.searchText === '') {
                _this.props.onClose();
            }
            else {
                _this.props.onChange('');
                _this.input.focus();
            }
        };
        _this.onFocus = function () {
            if (!_this.hasFocus) {
                _this.props.onChange(_this.props.searchText);
            }
            _this.hasFocus = true;
            clearTimeout(_this.blurTimer);
        };
        _this.onChange = function (e) {
            var searchText = e.target.value;
            _this.props.onChange(searchText);
        };
        _this.onSubmit = function (e) {
            e.preventDefault();
            _this.props.onSearch(_this.props.searchText);
            _this.input.blur(); // Needed for iOS10 to close keyboard
        };
        _this.onAutocompleClick = function (text) {
            searchHistory.add(text);
        };
        return _this;
    }
    QuickSearchField.prototype.componentDidMount = function () {
        if (this.input.setSelectionRange) {
            this.input.setSelectionRange(this.props.searchText.length, this.props.searchText.length);
        }
    };
    QuickSearchField.prototype.render = function () {
        var _this = this;
        var _a = this.props, searchText = _a.searchText, completionsLists = _a.completionsLists;
        var hasCompletions = completionsLists.reduce(function (i, l) {
            return i +
                (l.items && l.items.length ? l.items.length : 0) +
                (l.products && l.products.length ? l.products.length : 0);
        }, 0) > 0;
        return (React.createElement("form", { onSubmit: function (e) { return _this.onSubmit(e); }, className: styles.base },
            React.createElement("input", { className: styles.searchInput, placeholder: translate('/QuickSearch/Placeholder'), type: "search", value: this.props.searchText, onFocus: function () { return _this.onFocus(); }, onChange: function (e) { return _this.onChange(e); }, ref: function (el) {
                    _this.input = el;
                }, autoFocus: true }),
            React.createElement("button", { type: "submit", style: { display: 'none' } }),
            React.createElement("button", { type: "button", className: styles.clear, onClick: this.onClear },
                React.createElement(Icon, { name: "close" })),
            hasCompletions ? (React.createElement(Autocompletions, { lists: completionsLists, searchText: searchText, onItemClick: this.onAutocompleClick })) : (React.createElement("div", { className: classnames(stylesAutocomplete.base) },
                React.createElement("div", { className: stylesAutocomplete.noHitsSection }, translate('/Shared/NoHitsUnfortunately', searchText) + ".")))));
    };
    return QuickSearchField;
}(React.Component));
export default QuickSearchField;
