import React from 'react';
import { translate } from 'Shared/translate';
import LoginContainer from 'Shared/Login/Container';
import ProductMark from './ProductMark';
import classnames from 'classnames';
import * as styles from './base.scss';
var ProductMarks = function (_a) {
    var IsUtf = _a.IsUtf;
    return (React.createElement(LoginContainer, null, function (_a) {
        var _b;
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn && (React.createElement("div", { className: classnames(styles.marks, (_b = {},
                _b[styles.large] = false,
                _b)) }, IsUtf && (React.createElement(ProductMark, { className: styles.markIsUtf }, translate('/Product/Utf')))));
    }));
};
export default ProductMarks;
