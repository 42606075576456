import React from 'react';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import * as styles from './completePurchaseModal.scss';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import classnames from 'classnames';
import moment from 'moment';
import Icon from 'Shared/Icon';
var CompletePurchaseModal = function (_a) {
    var onCancel = _a.onCancel, items = _a.items;
    var getFormattedDeliveryDate = function (date) { return moment(date).format('YYYY-MM-DD'); };
    var renderDeliveryDates = function (item) {
        if ((item.oldDeliveryDate && item.oldDeliveryDate.length > 0) ||
            (item.newDeliveryDate && item.newDeliveryDate.length > 0)) {
            return true;
        }
        else {
            return false;
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("p", null, translate('/Checkout/CompletePurchaseModal/OrderUpdated')),
            React.createElement("p", null, translate('/Checkout/CompletePurchaseModal/AffectingRows'))),
        React.createElement("div", { className: styles.changedItemsListing }, items.map(function (item, i) {
            return (React.createElement("div", { key: i, className: styles.card },
                React.createElement("div", { className: styles.cardInner },
                    React.createElement("div", { className: classnames(styles.cardColumn, styles.imageWrap) },
                        React.createElement(ProductImage, { imageUrl: item.mainImageUrl, displayName: item.displayName, size: "tiny" })),
                    React.createElement("div", { className: classnames(styles.cardColumn, styles.detailsWrap) },
                        React.createElement("h2", { className: styles.title }, item.displayName),
                        React.createElement("div", { className: styles.articleNumber },
                            translate('/ProductListing/ArticleNumber'),
                            " ",
                            item.code))),
                React.createElement("div", { className: classnames(styles.cardColumn, styles.itemInfoWrap) },
                    item.message && (React.createElement("div", { className: styles.itemMessage },
                        React.createElement("span", { className: styles.itemInfoTitle }, translate('/Checkout/CompletePurchaseModal/Info')),
                        React.createElement("div", null, item.message))),
                    React.createElement("span", { className: styles.subDeliveryTitle }, translate('/Checkout/CompletePurchaseModal/DeliveryDays')),
                    renderDeliveryDates(item) && (React.createElement(React.Fragment, null,
                        item.oldDeliveryDate.map(function (deliveryItem, i) {
                            return (React.createElement("div", { key: i, className: styles.subDeliveryContentWrap },
                                React.createElement("div", { className: styles.subDeliveryContent },
                                    React.createElement("span", { className: styles.currentDeliveryDate },
                                        React.createElement("span", { className: styles.deliveryItemDate }, getFormattedDeliveryDate(deliveryItem.deliveryDate)),
                                        ' ',
                                        React.createElement("span", null,
                                            deliveryItem.quantity,
                                            " ",
                                            deliveryItem.unitShortName)))));
                        }),
                        item.newDeliveryDate.map(function (deliveryItem, i) {
                            return (React.createElement("div", { key: i, className: styles.subDeliveryContentWrap },
                                React.createElement(Icon, { className: styles.subDeliveryIcon, name: "bold-arrow-right" }),
                                React.createElement("div", { className: styles.subDeliveryContent },
                                    React.createElement("span", { className: styles.newDeliveryDate },
                                        React.createElement("span", { className: styles.deliveryItemDate }, getFormattedDeliveryDate(deliveryItem.deliveryDate)),
                                        ' ',
                                        React.createElement("span", null,
                                            deliveryItem.quantity,
                                            " ",
                                            deliveryItem.unitShortName)))));
                        }))))));
        })),
        React.createElement("div", null,
            React.createElement("p", null, translate('/Checkout/CompletePurchaseModal/OrderOrCancel'))),
        React.createElement("div", { className: styles.base },
            React.createElement("div", { className: styles.buttonWrap },
                React.createElement(Button, { type: "submit", form: 'checkout-form', appearance: "primary", className: styles.button }, translate('/Checkout/CompletePurchase'))),
            React.createElement("div", { className: styles.buttonWrap },
                React.createElement(Button, { onClick: onCancel, appearance: "secondary", className: styles.button }, translate('/Checkout/CompletePurchaseModal/ToCheckout'))))));
};
export default CompletePurchaseModal;
