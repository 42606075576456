/**
 * The big ol' master Sass file
 * The philosophy behind simplicity and structure in this project is inspired by inuitcss (https://github.com/inuitcss).
 *
 * CSS modules specific stylesheets (used with the `composes` property):
 *  - Objects - Objects, abstractions, and design patterns (e.g. .media {}).
 *  - Components - Complete chunks of UI (e.g. .carousel {}).
 *  - Trumps - High-specificity, very explicit selectors. Overrides and helper classes (e.g. .hidden {}).
 *
 * Imported explicitly in each stylesheet:
 *  - Settings - Global variables, site-wide settings, config switches, etc.
 *  - Tools - Site-wide mixins and functions.
 */
/** Generic - Low-specificity, far-reaching rulesets (e.g. resets). */
import './generic/normalize.scss';
import './generic/box-sizing.scss';
import './generic/images.scss';
import './generic/outline.scss';
/** Base - Unclassed HTML elements (e.g. a {}, blockquote {}, address {}). */
import './base/body.scss';
import './base/fields.scss';
import './base/tables.scss';
import './base/typography.scss';
/** React Dates picker styles */
import './components/datepicker.scss';
import './components/reactquill.scss';
/** Episerver Forms styles */
import './components/forms.scss';
/** global styles - non css-modules */
import 'react-dates/lib/css/_datepicker.css';
