import { updateCurrentPage } from 'SiteLayout/CurrentPage/action-creators';
import { postJson, post, getJson } from 'Shared/server';
import { currentUrl, pathCombine } from 'Shared/url';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { pushState, replaceState } from 'Shared/history';
import { recipeOverviewPageUrl } from 'Shared/known-urls';
import { clear as clearCache } from 'SiteLayout/CurrentPage/cache';
export var UPDATE_SHOPPINGLIST = 'UPDATE_SHOPPINGLIST';
export var CLEAR_SHOPPINGLIST = 'CLEAR_SHOPPINGLIST';
export var INIT_SHOPPINGLIST = 'INIT_SHOPPINGLIST';
export function updatePortions(portions) {
    return function (dispatch) {
        return getJson(currentUrl().path + "?numberOfPortions=" + portions).then(function (resp) {
            return dispatch(updateCurrentPage(function (current) {
                return Object.assign(current, {
                    recipeItemModels: resp.recipeItemModels.map(function (item) { return Object.assign({}, item); }),
                    allMealRecipeModels: resp.allMealRecipeModels.map(function (item) { return Object.assign({}, item); }),
                    portionCost: resp.portionCost,
                });
            }));
        });
    };
}
export function shareRecipe(model) {
    return function (dispatch) {
        return postJson('/ShareRecipeApi/Share', model).then(function (resp) {
            return resp;
        });
    };
}
export function searchUsers(query) {
    return function (dispatch) {
        return postJson('/SearchContactApi/Search', { query: query }).then(function (resp) {
            return resp;
        });
    };
}
export var removeRecipe = function (id) { return function (dispatch, getState) {
    return post(getState().appShellData.siteSettings.recipeOverviewPage + "/delete", id)
        .then(function (result) {
        if (result.type === 'error') {
            dispatch(setFeedback(result));
            return Promise.reject(null);
        }
        return clearCache(recipeOverviewPageUrl()).then(function () {
            return replaceState(recipeOverviewPageUrl(), { hideSpinner: true }).then(function () { return Promise.resolve(); });
        });
    })
        .catch(failedPromiseHandler);
}; };
export function fetchShoppinglist(portions) {
    var nameKey = currentUrl().path.replace(recipeOverviewPageUrl(), "");
    return function (dispatch) {
        return postJson(pathCombine('/recipe', '/RecipeToShoppingList?recipename=' + nameKey.replace("/", "")), portions).then(function (resp) {
            var shoppingList = [];
            if (resp.items) {
                resp.items.forEach(function (item) {
                    var product = !!item.product.isDiscontinued || !!item.product.isBackorderReplaced
                        ? item.product.replacementProduct
                        : item.product;
                    if (!!product && item.quantity > 0) {
                        shoppingList.push({
                            code: item.selectedVariant.code,
                            quantity: item.quantity,
                            unitShortName: item.selectedVariant.unitShortName,
                            ticket: item.selectedVariant.ticket,
                        });
                    }
                });
                dispatch(initShoppingList(shoppingList));
            }
            return Promise.resolve(resp);
        });
    };
}
export function updateShoppingList(code, quantity, unitShortName) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_SHOPPINGLIST,
            code: code,
            quantity: quantity,
            unitShortName: unitShortName,
        });
        return Promise.resolve();
    };
}
export var clearShoppingList = function () {
    var clearAction = { type: CLEAR_SHOPPINGLIST };
    return clearAction;
};
export var initShoppingList = function (shoppingList) {
    var initAction = { type: INIT_SHOPPINGLIST, shoppingList: shoppingList };
    return initAction;
};
export var cancelEdit = function (id) {
    post({ path: recipeOverviewPageUrl() + "/CancelEdit", query: { id: id } }).then(function (data) {
        pushState(recipeOverviewPageUrl());
    });
};
var lastRequestId = 0;
export function notifySelectionChangesToServer(recipeModel) {
    var requestId = (lastRequestId = Math.random());
    return function (dispatch) {
        postJson(pathCombine('/recipe', '/update'), recipeModel)
            .then(function (result) {
            if (requestId === lastRequestId) {
                if (!!result.errorMessages) {
                    return dispatch(setFeedback({
                        text: result.errorMessages.join(' '),
                        type: 'error',
                    }));
                }
                return dispatch(updateCurrentPage(function (current) {
                    return Object.assign(current, {
                        recipeModel: Object.assign(current.recipeModel, {
                            recipeItemModels: result.recipeItemModels,
                            mealRecipeModels: result.mealRecipeModels,
                            portionCost: result.portionCost,
                            portionWeight: result.portionWeight,
                            recipeItemsCost: result.recipeItemsCost,
                        }),
                    });
                }));
            }
        })
            .catch(console.error);
    };
}
export function setGrade(id, grade) {
    return function (dispatch) {
        return postJson(pathCombine('/recipe', '/SetGrade?grade=' + grade), id).then(function (resp) {
            dispatch(updateCurrentPage(function (current) {
                return Object.assign(current, {
                    grade: resp.grade,
                    voteCount: resp.voteCount,
                    userVoted: resp.userVoted,
                });
            }));
        });
    };
}
function failedPromiseHandler(e) {
    console.error(e);
    return Promise.reject(null);
}
