var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { translate } from 'Shared/translate';
import { formatPrice } from 'Shared/number-format';
import * as styles from './base.scss';
import classnames from 'classnames';
var ProductPriceDisplay = function (_a) {
    var _b, _c, _d, _e, _f;
    var showItemAttributes = _a.showItemAttributes, showOriginal = _a.showOriginal, _g = _a.price, original = _g.original, current = _g.current, comparison = _g.comparison, smallestUnitPrice = _g.smallestUnitPrice, showAsSmallestUnitPrice = _g.showAsSmallestUnitPrice, currencySymbol = _g.currencySymbol, priceUnit = _g.priceUnit, comparisonPriceUnit = _g.comparisonPriceUnit, id = _a.id, hideCompare = _a.hideCompare, hideUnit = _a.hideUnit, showNewSmallestUnit = _a.showNewSmallestUnit, useNewDesign = _a.useNewDesign;
    var isNotNaNAndAboveZero = function (n) { return !isNaN(n) && n > 0; };
    var ifShowAttributeUse = function (attribute) { return (showItemAttributes ? attribute : null); };
    var showSmallestUnitPrice = showAsSmallestUnitPrice && isNotNaNAndAboveZero(smallestUnitPrice);
    var displayPrice = showOriginal ? original : current;
    var itemPropPrice = ifShowAttributeUse('price');
    var trackingProps = {
        'data-id': id,
        'data-discount': (original - current).toFixed(2)
    };
    return (React.createElement("div", __assign({ itemProp: ifShowAttributeUse('offers'), itemType: ifShowAttributeUse('http://schema.org/Offer'), itemScope: ifShowAttributeUse(true) }, trackingProps),
        showItemAttributes && React.createElement("meta", { itemProp: "priceCurrency", content: translate('/Price/Currency') }),
        !showOriginal && showSmallestUnitPrice && !showNewSmallestUnit && !useNewDesign && (React.createElement("div", { className: classnames(styles.currentPrice, (_b = {}, _b[styles['title']] = useNewDesign, _b), (_c = {}, _c[styles.useNewDesign] = useNewDesign, _c)) },
            React.createElement("span", { itemProp: itemPropPrice }, formatPrice(smallestUnitPrice)),
            React.createElement("span", null,
                currencySymbol,
                !hideUnit && priceUnit && "/" + translate('/Price/SmallestUnitPrice')))),
        !showNewSmallestUnit && (React.createElement("div", { className: classnames(!showOriginal && showSmallestUnitPrice && !useNewDesign ? styles.smallestUnitPrice : styles.currentPrice, (_d = {}, _d[styles['title']] = !showOriginal && useNewDesign, _d), (_e = {}, _e[styles['description']] = showOriginal && useNewDesign, _e), (_f = {}, _f[styles.useNewDesign] = useNewDesign, _f)) },
            React.createElement("span", { itemProp: itemPropPrice }, formatPrice(displayPrice) + " "),
            React.createElement("span", { className: "ui-test-unit-price" },
                currencySymbol,
                !hideUnit && priceUnit && "/" + priceUnit))),
        !hideCompare && !showOriginal && isNotNaNAndAboveZero(comparison) && !showNewSmallestUnit && (React.createElement("div", { className: styles.comparePrice },
            translate('/Price/ComparisonPrice', formatPrice(comparison)),
            !!comparisonPriceUnit && React.createElement("span", null, " " + currencySymbol + "/" + comparisonPriceUnit))),
        showSmallestUnitPrice && showNewSmallestUnit && useNewDesign && (React.createElement("div", null,
            React.createElement("span", { itemProp: itemPropPrice }, formatPrice(smallestUnitPrice)),
            React.createElement("span", null,
                currencySymbol,
                priceUnit && "/" + translate('/Price/SmallestUnitPrice'))))));
};
export default ProductPriceDisplay;
