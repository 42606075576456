import { getJson } from 'Shared/server';
export function compareValues(key, secondkey, sortAscending) {
    if (sortAscending === void 0) { sortAscending = true; }
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }
        var primaryA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        var primaryB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
        var comparison = 0;
        if (!!primaryA && !!!primaryB) {
            comparison = -1;
        }
        else if (!!!primaryA && !!primaryB) {
            comparison = 1;
        }
        else if (primaryA > primaryB) {
            comparison = 1;
        }
        else if (primaryA < primaryB) {
            comparison = -1;
        }
        else if (!!secondkey && (a.hasOwnProperty(key) && b.hasOwnProperty(key))) {
            var secondaryA = (typeof a[secondkey] === 'string')
                ? a[secondkey].toUpperCase() : a[secondkey];
            var secondaryB = (typeof b[secondkey] === 'string')
                ? b[secondkey].toUpperCase() : b[secondkey];
            if (secondaryA > secondaryB) {
                comparison = 1;
            }
            else if (secondaryA < secondaryB) {
                comparison = -1;
            }
            else {
                comparison = 0;
            }
        }
        return ((!sortAscending) ? (comparison * -1) : comparison);
    };
}
;
export default function loadAll(page, pageSize, recycleItemsArticleNumbers, all) {
    return function (dispatch, getState) {
        return getJson('/mymenigoorderhistory/LoadOrderHistory?page=' + page + '+&pageSize=' + pageSize + '&recycleItemsArticleNumbers=' + recycleItemsArticleNumbers + "&loadAll=" + all)
            .then(function (res) {
            return res;
        });
    };
}
