var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import * as styles from '../base.scss';
import { onView, onClick } from 'Shared/ContentCard/DataLayer';
import ProductList from 'Shared/ContentCard/Product/ProductList';
import ViewTracker from 'Shared/ViewObserver/ViewTracker';
import LinkWithAlcoholWarning from 'Shared/LinkWithAlcoholWarning';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var CategoryHero = function (props) {
    var hasContent = !!props.hero.text;
    return (React.createElement(ViewTracker, { key: 'categoryHero' + props.hero.adKey, onView: function (visible) {
            onView(visible, props.hero);
        } }, hasContent
        ? props.hero.hasProducts
            ? React.createElement(NewDesignWithProducts, __assign({}, props))
            : React.createElement(NewDesignWithImage, __assign({}, props))
        : React.createElement(OldDesign, __assign({}, props))));
};
function OldDesign(props) {
    return (React.createElement("div", { className: classnames(styles.hero, styles.hero__no_content) },
        React.createElement(LinkWithAlcoholWarning, { className: styles.hero__link__wrapper, targetLink: props.hero.targetLink, hasAlcoholWarning: props.hero.containsAlcoholInfo, onClick: function (e) { return onClick(e, props.hero); }, text: props.hero.title, wrap: true },
            React.createElement("div", { className: styles.hero__image, style: { backgroundImage: 'url(' + props.hero.imageUrl + ')' } }))));
}
function NewDesignWithImage(props) {
    return (React.createElement("div", { className: classnames(styles.hero, styles.hero__with_content) },
        React.createElement("div", { className: styles.hero__image, style: { backgroundImage: 'url(' + props.hero.imageUrl + ')' } }),
        React.createElement("div", { className: styles.hero__content },
            React.createElement(NewDesignCommon, __assign({}, props)),
            React.createElement("div", { className: styles.hero__content__right }))));
}
function NewDesignWithProducts(props) {
    return (React.createElement("div", { className: classnames(styles.hero, styles.hero__with_products) },
        React.createElement("div", { className: styles.hero__content },
            React.createElement(NewDesignCommon, __assign({}, props)),
            React.createElement("div", { className: styles.hero__content__right },
                React.createElement(ProductList, { products: props.hero.products })))));
}
function NewDesignCommon(props) {
    return (React.createElement("div", { className: styles.hero__content__left },
        !!props.hero.title && (React.createElement("h3", { className: classnames(styles.hero__title) }, props.hero.title)),
        React.createElement("div", { className: styles.hero__text, dangerouslySetInnerHTML: { __html: props.hero.text } }),
        !!props.hero.targetLink.text && (React.createElement(LinkWithAlcoholWarning, { className: styles.hero__link, targetLink: props.hero.targetLink, hasAlcoholWarning: props.hero.containsAlcoholInfo, onClick: function (e) { return onClick(e, props.hero); }, wrap: true },
            props.hero.targetLink.text,
            React.createElement(MyMenigoIcon, { name: "arrow" })))));
}
export default CategoryHero;
