import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import classnames from 'classnames';
import * as styles from './base.scss';
import propertyValueFor from 'Shared/property-value-for';
var CollageBlock = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(styles.base) },
            React.createElement("div", { className: styles.wideWrapper },
                !!propertyValueFor(props.block.image) &&
                    React.createElement("div", { className: classnames(styles.aspectRatio), style: { backgroundImage: 'url(' + propertyValueFor(props.block.image) + ')' } },
                        React.createElement("img", { src: propertyValueFor(props.block.image), alt: propertyValueFor(props.block.imageText) })),
                !!propertyValueFor(props.block.secondImage) &&
                    React.createElement("div", { className: classnames(styles.aspectRatio, styles.secondaryImage) },
                        React.createElement("div", { className: styles.inner, style: { backgroundImage: 'url(' + propertyValueFor(props.block.secondImage) + ')' } },
                            React.createElement("img", { src: propertyValueFor(props.block.secondImage), alt: propertyValueFor(props.block.imageText) }))),
                !!propertyValueFor(props.block.thiredImage) &&
                    React.createElement("div", { className: classnames(styles.aspectRatio, styles.thiredImage) },
                        React.createElement("div", { className: styles.inner, style: { backgroundImage: 'url(' + propertyValueFor(props.block.thiredImage) + ')' } },
                            React.createElement("img", { src: propertyValueFor(props.block.thiredImage), alt: propertyValueFor(props.block.imageText) }))))),
        React.createElement("div", { className: styles.text },
            !!props.block.imageText && React.createElement(PropertyFor, { tagName: 'p', className: styles.imageText, property: props.block.imageText }),
            !!props.block.imageCredits && React.createElement(PropertyFor, { tagName: 'p', className: styles.imageCredits, property: props.block.imageCredits }))));
};
export default CollageBlock;
