var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import connect from 'Shared/connect';
import * as styles from './base.scss';
var licenseSet = false;
export default (IS_APP
    ? connect(function (state) { return ({
        forceHide: !!(state.miniCart && state.miniCart.isOpen),
    }); })(/** @class */ (function (_super) {
        __extends(Barcodescanner, _super);
        function Barcodescanner() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.isRunning = false;
            _this.isComponentMounted = false;
            return _this;
        }
        Barcodescanner.prototype.componentWillReceiveProps = function (nextProps) {
            if (this.isComponentMounted) {
                if (nextProps.forceHide && this.isRunning) {
                    this.stop();
                }
                else if (!nextProps.forceHide && !this.isRunning) {
                    this.start();
                }
            }
        };
        Barcodescanner.prototype.componentDidMount = function () {
            this.isComponentMounted = true;
            if (!this.props.forceHide) {
                this.start();
            }
        };
        Barcodescanner.prototype.componentWillUnmount = function () {
            this.isComponentMounted = false;
            if (this.isRunning) {
                this.stop();
            }
        };
        Barcodescanner.prototype.start = function () {
            var Scandit = window['Scandit'];
            if (!licenseSet) {
                Scandit.License.setAppKey('mrUoJtR5AEYfBIkTKLbHAXv9LjNXYLI0xB29/nOXZlM');
                licenseSet = true;
            }
            var settings = new Scandit.ScanSettings();
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.EAN13, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.EAN8, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPC12, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCA, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.UPCE, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE11, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE128, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE39, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE93, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODE25, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.ITF, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.DATA_MATRIX, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.PDF417, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.MICRO_PDF417, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.MSI_PLESSEY, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.GS1_DATABAR, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.GS1_DATABAR_LIMITED, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.GS1_DATABAR_EXPANDED, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.CODABAR, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.AZTEC, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.MAXICODE, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.FIVE_DIGIT_ADD_ON, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.TWO_DIGIT_ADD_ON, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.KIX, true);
            settings.setSymbologyEnabled(Scandit.Barcode.Symbology.RM4SCC, true);
            this.picker = new Scandit.BarcodePicker(settings);
            this.picker.continuousMode = true;
            var constraints = new Scandit.Constraints();
            var rc = this.el.getBoundingClientRect();
            constraints.leftMargin = rc.left;
            constraints.topMargin = rc.top;
            constraints.width = rc.width;
            constraints.height = rc.height;
            this.picker.setConstraints(constraints);
            this.picker.show(this.success.bind(this), null, null);
            this.picker.startScanning();
            this.isRunning = true;
        };
        Barcodescanner.prototype.stop = function () {
            this.picker.cancel();
            this.picker = null;
            this.isRunning = false;
        };
        Barcodescanner.prototype.success = function (session) {
            session.stopScanning();
            this.props.onDetected(session.newlyRecognizedCodes[0].data);
        };
        Barcodescanner.prototype.render = function () {
            var _this = this;
            return React.createElement("div", { className: classnames(styles.canvas, styles.scandit), ref: function (el) { return (_this.el = el); } });
        };
        return Barcodescanner;
    }(React.Component)))
    : null);
