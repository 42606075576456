var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Header from './Header';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
import connect from 'Shared/connect';
var Table = /** @class */ (function (_super) {
    __extends(Table, _super);
    function Table(props) {
        var _this = _super.call(this, props) || this;
        _this.onToggle = function () { return _this.setState({ isOpen: !_this.state.isOpen }); };
        _this.state = { currentLayout: _this.props.userSettings.viewLayoutSelection };
        return _this;
    }
    Table.prototype.render = function () {
        return (React.createElement("div", { className: styles.RecipeMenu },
            React.createElement(Header, __assign({}, this.props, { appearance: this.props.currentBreakpoint < SMALL
                    ? 'mobile'
                    : viewLayoutTypes.grid.name === this.state.currentLayout
                        ? 'box'
                        : 'list' }))));
    };
    return Table;
}(React.PureComponent));
export default connect(function (state) { return ({
    userSettings: state.currentUser.userSettings,
    currentBreakpoint: state.currentBreakpoint,
}); })(Table);
