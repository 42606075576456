import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
export default (function (props) { return (React.createElement("div", null,
    React.createElement("div", { className: "page" },
        React.createElement("span", null, "page"),
        React.createElement("div", null,
            React.createElement("div", { className: "linkUrl" },
                React.createElement("span", null, "linkUrl"),
                React.createElement(PropertyFor, { property: props.page.pageReferenceLinkUrl })),
            React.createElement("div", { className: "linkText" },
                React.createElement("span", null, "linkText"),
                React.createElement(PropertyFor, { property: props.page.linkText })),
            React.createElement("div", { className: "mainBody" },
                React.createElement("span", null, "mainBody"),
                React.createElement(PropertyFor, { property: props.page.mainBody })),
            React.createElement("div", { className: "someContentArea" },
                React.createElement("span", null, "someContentArea"),
                React.createElement(PropertyFor, { property: props.page.someContentArea })),
            React.createElement("div", { className: "someBlock" },
                React.createElement("span", null, "someBlock"),
                React.createElement(PropertyFor, { property: props.page.someBlock })))),
    React.createElement("div", { className: "theC1" },
        React.createElement("span", null, "theC1"),
        React.createElement("div", null,
            React.createElement("div", { className: "theC2" },
                React.createElement("span", null, "theC2"),
                React.createElement("div", null,
                    React.createElement("div", { className: "s" },
                        React.createElement("span", null, "s"),
                        props.theC1 && props.theC1.theC2 && props.theC1.theC2.s),
                    React.createElement("div", { className: "theList" },
                        React.createElement("span", null, "theList"),
                        props.theC1 &&
                            props.theC1.theC2 &&
                            props.theC1.theC2.theList &&
                            props.theC1.theC2.theList.map(function (item) { return React.createElement("div", null, item); })),
                    React.createElement("div", { className: "theArray" },
                        React.createElement("span", null, "theArray"),
                        props.theC1 &&
                            props.theC1.theC2 &&
                            props.theC1.theC2.theArray &&
                            props.theC1.theC2.theArray.map(function (item) { return React.createElement("div", null, item); })))),
            React.createElement("div", { className: "theC2List" },
                React.createElement("span", null, "theC2List"),
                props.theC1 &&
                    props.theC1.theC2List &&
                    props.theC1.theC2List.map(function (item) { return (React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement("div", { className: "s" },
                                React.createElement("span", null, "s"),
                                item.s),
                            React.createElement("div", { className: "theList" },
                                React.createElement("span", null, "theList"),
                                item.theList && item.theList.map(function (item) { return React.createElement("div", null, item); })),
                            React.createElement("div", { className: "theArray" },
                                React.createElement("span", null, "theArray"),
                                item.theArray && item.theArray.map(function (item) { return React.createElement("div", null, item); }))))); })),
            React.createElement("div", { className: "someDictionary" },
                React.createElement("span", null, "someDictionary"),
                props.theC1 && props.theC1.someDictionary))))); });
