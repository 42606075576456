var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Image from 'Shared/ImageFallback';
import classnames from 'classnames';
import * as styles from '../base.scss';
import { Input } from 'Shared/Fields';
var ReturnItem = /** @class */ (function (_super) {
    __extends(ReturnItem, _super);
    function ReturnItem(props) {
        var _this = _super.call(this, props) || this;
        // https://bugs.chromium.org/p/chromium/issues/detail?id=4505
        _this.selectOnFocus = function (e) {
            var target = e.target;
            setTimeout(function () { return target.select(); }, 0);
        };
        return _this;
    }
    ReturnItem.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, returnItem = _b.returnItem, onUpdate = _b.onUpdate;
        return (React.createElement("div", { className: classnames((_a = {},
                _a[styles.returnItemRow] = true,
                _a[styles.returnItemRowActive] = returnItem.quantity > 0,
                _a)) },
            React.createElement("div", { className: styles.productId }, returnItem.productId),
            React.createElement("div", { className: styles.imgContainer },
                React.createElement(Image, { preferSmallerIfCached: true, minSizeIfCached: "tiny", skipSrcSet: true, alt: returnItem.productName, size: "tiny", src: returnItem.image })),
            React.createElement("div", { className: styles.description }, returnItem.productName),
            React.createElement("div", { className: styles.numberInput },
                React.createElement(Input, { type: "number", ref: function (el) { return (_this.inputRef = el); }, onChange: function () { return onUpdate(_this.inputRef.element.value); }, min: "0", value: returnItem.quantity, placeholderText: "Ange antal", onFocus: this.selectOnFocus }))));
    };
    return ReturnItem;
}(React.Component));
export default ReturnItem;
