var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import React, { useRef } from 'react';
import connect from 'Shared/connect';
import DropDown from 'Shared/DropDown';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { ENTER } from 'Shared/keyboard';
import { setDiscountType, setDiscountValue } from 'Features/Checkout/action-creators';
import classnames from 'classnames';
var DISCOUNT_PERCENT_LIMIT = 90;
var PriceSupplier = function (props) {
    var _a;
    var discountTypeIsValid = (props.validDiscounts || []).some(function (x) { return !x.disabled && x.value === props.discountType; });
    var discountType = discountTypeIsValid ? props.discountType : 'percent';
    var discountInput = useRef(null);
    var sendCurrentValue = useRef();
    var changeDiscountType = function (value) {
        props.setDiscountType(props.code, value);
    };
    var onInputChange = function (value) {
        if (props.discountValue !== Number(value))
            sendCurrentValue.current = props.setDiscountValue(props.code, Number(value));
    };
    var onDiscountBlur = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (props.discountType === 'percent' && props.discountValue > DISCOUNT_PERCENT_LIMIT) {
                        props.toggleProductSample(props.code);
                    }
                    _a = sendCurrentValue.current;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, sendCurrentValue.current()];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    discountInput.current.value = parseFloat(discountInput.current.value).toString();
                    return [2 /*return*/];
            }
        });
    }); };
    var keyPressHandler = function (e) {
        if (e.keyCode === ENTER) {
            discountInput.current.blur();
        }
    };
    if (!props.isSupplierSalesman || props.validDiscounts == null) {
        return null;
    }
    var options = props.validDiscounts.map(function (option) {
        return { text: option.displayName, value: option.value, disabled: option.disabled, tooltip: option.tooltip, subText: null };
    });
    return (React.createElement(React.Fragment, null,
        console.log("WEO", options),
        !props.isProductSample && (React.createElement("div", { className: classnames((_a = {}, _a[styles.useNewDesign] = props.useNewDesign, _a)) },
            React.createElement(DropDown, { options: options, value: discountType, onChange: changeDiscountType }),
            !props.useNewDesign && (React.createElement("div", { className: styles.inputWrapper },
                React.createElement("input", { type: "number", ref: discountInput, onChange: function (e) { return onInputChange(e.target.value); }, value: props.discountValue, onBlur: function () { return onDiscountBlur(); }, onKeyDown: function (e) { return keyPressHandler(e); } }),
                React.createElement("span", { className: styles.selectedValue },
                    options.find(function (x) { return x.value === discountType; }).text,
                    discountType !== 'percent' &&
                        " (" + translate('/SupplierSalesman/Discount/Per') + " " + (!!props.unitShortName &&
                            props.unitShortName.toLocaleLowerCase()) + ")"))),
            props.useNewDesign && (React.createElement("div", { className: styles.inputWrapper },
                React.createElement("input", { type: "number", ref: discountInput, onChange: function (e) { return onInputChange(e.target.value); }, value: props.discountValue, onBlur: function () { return onDiscountBlur(); }, onKeyDown: function (e) { return keyPressHandler(e); } }),
                React.createElement("div", { className: styles.filler }, discountType !== 'percent' ? " " + translate('/SupplierSalesman/Discount/PerShort') + (!!props.unitShortName && props.unitShortName.toLocaleLowerCase()) : '%'),
                React.createElement("div", { className: styles.units },
                    React.createElement("div", { className: styles.center },
                        React.createElement("div", { className: styles.number }, props.discountValue),
                        React.createElement("div", null, discountType !== 'percent' ? " " + translate('/SupplierSalesman/Discount/PerShort') + (!!props.unitShortName && props.unitShortName.toLocaleLowerCase()) : '%')))))))));
};
export default connect(function (state) { return ({
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); }, function (dispatch) { return ({
    setDiscountType: function (code, type) { return dispatch(setDiscountType(code, type)); },
    setDiscountValue: function (code, value) { return dispatch(setDiscountValue(code, value)); },
}); })(PriceSupplier);
