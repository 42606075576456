import * as server from 'Shared/server';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { updateCurrentPage } from 'SiteLayout/CurrentPage/action-creators';
import { translate } from 'Shared/translate';
import { RESET_CUSTOMPRODUCTEDIT_ID, SET_CUSTOMPRODUCTEDIT_ID } from 'Shared/Modal/reducer';
var SORT_TITLE_DESC = 'SORT_TITLE_DESC';
var SORT_TITLE_ASC = 'SORT_TITLE_ASC';
var SORT_ARTNR_DESC = 'SORT_ARTNR_DESC';
var SORT_ARTNR_ASC = 'SORT_ARTNR_ASC';
var SORT_SUPPLIER_DESC = 'SORT_SUPPLIER_DESC';
var SORT_SUPPLIER_ASC = 'SORT_SUPPLIER_ASC';
export var SortOrder = {
    SORT_TITLE_DESC: SORT_TITLE_DESC,
    SORT_TITLE_ASC: SORT_TITLE_ASC,
    SORT_ARTNR_DESC: SORT_ARTNR_DESC,
    SORT_ARTNR_ASC: SORT_ARTNR_ASC,
    SORT_SUPPLIER_DESC: SORT_SUPPLIER_DESC,
    SORT_SUPPLIER_ASC: SORT_SUPPLIER_ASC,
};
export function onSaveEditAction(data, edit) {
    return function (dispatch) {
        return server.postJson('/customproductoverviewpage/save', data).then(function (result) {
            if (!result.success) {
                return Promise.reject(result);
            }
            dispatch(updateCurrentPage(function (current) {
                var items;
                if (!!current.listModel) {
                    current = current;
                    if (!edit) {
                        items = Object.assign([], current.listModel.items);
                        items.unshift(result.item);
                    }
                    else {
                        items = current.listModel.items.map(function (item) {
                            return item.id === result.item.id ? Object.assign({}, result.item, { isLocked: item.isLocked }) : item;
                        });
                    }
                    Object.assign(current, {
                        listModel: Object.assign({}, current.listModel, {
                            items: items,
                            suppliers: result.suppliers,
                        }),
                    });
                }
            }));
            return Promise.resolve(result);
        });
    };
}
export function onEditProductAction(data) {
    return server.postJson('/customproductoverviewpage/editaddcustom', data);
}
export function onConfirmDeleteAction(ids) {
    return server.postJson("/customproductoverviewpage/deleteconfirm", ids);
}
export function onDeleteItemsAction(data) {
    return function (dispatch) {
        return server.postJson('/customproductoverviewpage/delete', data).then(function (result) {
            if (!result.success) {
                return dispatch(setFeedback({
                    text: translate('/CustomProduct/Table/DeleteError'),
                    type: 'error',
                }));
            }
            dispatch(setFeedback({
                text: translate('/CustomProduct/Table/DeleteMessage'),
                type: 'success',
            }));
            return dispatch(updateCurrentPage(function (current) {
                var items = current.listModel.items.filter(function (item) { return data.indexOf(item.id) === -1; });
                Object.assign(current, {
                    listModel: Object.assign(current.listModel, {
                        items: items,
                        suppliers: result.suppliers,
                    }),
                });
            }));
        });
    };
}
export function onGetCustomProductAction(code) {
    return server.getJson("/customproductoverviewpage/getcustomproduct?code=" + code);
}
export function onAddToListAction(customProductId, listId) {
    var data = { 'customProductId': customProductId, 'listId': listId };
    return server.postJson('/customproductoverviewpage/addtolist', data);
}
export function onGetAreasAction() {
    return server.getJson("/customproductoverviewpage/getareas");
}
export function onGetUnitsAction() {
    return server.getJson("/customproductoverviewpage/getunits");
}
export function onGetSuppliersAction() {
    return server.getJson("/customproductoverviewpage/getsuppliers");
}
export function onGetFirstLevelAction() {
    return server.getJson("/customproductoverviewpage/getfirstlevelcategories");
}
export function onGetSecondLevelAction(firstLevelId) {
    return server.getJson("/customproductoverviewpage/getsecondlevelcategories?firstLevelId=" + firstLevelId);
}
export function onGetThirdLevelAction(firstLevelId, secondLevelId) {
    return server.getJson("/customproductoverviewpage/getthirdlevelcategories?firstLevelId=" + firstLevelId + "&secondLevelId=" + secondLevelId);
}
export function onSaveFormAction(customProductChange) {
    return server.postJson("/customproductoverviewpage/updatecustomproducts", {
        record: customProductChange,
    });
}
export function onEditNutrientAction(id) {
    return server.getJson("/nutrient/edit?customProductId=" + id);
}
export function onSaveNutrientsAction(customProductId, nutrients) {
    return server.postJson("/nutrient/save?customProductId=" + customProductId, nutrients);
}
export function setCustomProductEditId(code) {
    return {
        type: SET_CUSTOMPRODUCTEDIT_ID,
        code: code,
    };
}
export function resetCustomProductEditId() {
    return {
        type: RESET_CUSTOMPRODUCTEDIT_ID,
    };
}
