var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import classnames from 'classnames';
var AvailibilityCircle = /** @class */ (function (_super) {
    __extends(AvailibilityCircle, _super);
    function AvailibilityCircle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AvailibilityCircle.prototype.render = function () {
        var _a, _b, _c;
        var value = this.props.value;
        var goodState = 'good';
        var neutralState = 'neutral';
        var badState = 'bad';
        return (React.createElement("span", { className: classnames(styles.availabilitycircle, (_a = {}, _a[styles.good] = value === goodState, _a), (_b = {}, _b[styles.neutral] = value === neutralState, _b), (_c = {}, _c[styles.bad] = value === badState, _c)) }));
    };
    return AvailibilityCircle;
}(React.Component));
export default AvailibilityCircle;
