var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import classnames from 'classnames';
var invStatusStyles = new Map([
    ['never', styles.never],
    ['ongoing', styles.ongoing],
    ['completed', styles.completed],
    ['reOpened', styles.reOpened],
]);
function invStatusText(status, date) {
    switch (status) {
        case 'never':
            return translate('/Account/ProductList/inventoryNever');
        case 'ongoing':
            return translate('/Account/ProductList/inventoryOngoing');
        case 'completed':
            return translate('/Account/ProductList/inventoryCompleted') + " " + date;
        case 'reOpened':
            return translate('/Account/ProductList/inventoryReOpened');
        default:
            break;
    }
}
var InventoryStatus = /** @class */ (function (_super) {
    __extends(InventoryStatus, _super);
    function InventoryStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InventoryStatus.prototype.render = function () {
        var _a = this.props, status = _a.status, date = _a.date, isMobile = _a.isMobile;
        return (React.createElement("div", { className: classnames(styles.list__cell__status, invStatusStyles.get(status)) },
            React.createElement("span", { className: styles.inventoryStatusInner },
                !isMobile && React.createElement("span", { className: styles.inventoryStatusCircle }),
                React.createElement("span", null, invStatusText(status, date)),
                isMobile && React.createElement("span", { className: styles.inventoryStatusCircle }))));
    };
    return InventoryStatus;
}(React.Component));
export default InventoryStatus;
