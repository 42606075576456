export var UP = { name: 'UP', horizontal: false };
export var LEFT = { name: 'LEFT', horizontal: true };
export var RIGHT = { name: 'RIGHT', horizontal: true };
export var DOWN = { name: 'DOWN', horizontal: false };
export var UNKNOWN = { name: 'UNKNOWN', horizontal: null };
var eventCountNeeded = 3;
export default function () {
    var lastTouches = [];
    var initialSwipeDirection = UNKNOWN;
    var swipeDirection = function (_a) {
        var horizontalAllowed = _a.horizontalAllowed, verticalAllowed = _a.verticalAllowed;
        var oldestTouch = lastTouches[Math.max(0, lastTouches.length - eventCountNeeded)];
        var latestTouch = lastTouches[lastTouches.length - 1];
        if (!oldestTouch || !latestTouch) {
            return null;
        }
        var diffX = Math.abs(oldestTouch.pageX - latestTouch.pageX);
        var diffY = Math.abs(oldestTouch.pageY - latestTouch.pageY);
        var upOrDown = oldestTouch.pageY - latestTouch.pageY > 0 ? UP : DOWN;
        var leftOrRight = oldestTouch.pageX - latestTouch.pageX > 0 ? LEFT : RIGHT;
        if (horizontalAllowed && verticalAllowed) {
            return diffY > diffX ? upOrDown : leftOrRight;
        }
        else if (horizontalAllowed) {
            return leftOrRight;
        }
        else {
            return upOrDown;
        }
    };
    var rafId = null;
    var timestamp = Date.now();
    var velocityY = 0;
    var velocityX = 0;
    var frameX = 0;
    var frameY = 0;
    var offsetY = 0;
    var offsetX = 0;
    var track = function () {
        var now = Date.now();
        var elapsed = now - timestamp;
        timestamp = now;
        var deltaY = offsetY - frameY;
        var deltaX = offsetX - frameX;
        frameX = offsetX;
        frameY = offsetY;
        var vX = (1000 * deltaX) / (1 + elapsed);
        var vY = (1000 * deltaY) / (1 + elapsed);
        velocityX = 0.8 * vX + 0.2 * velocityX;
        velocityY = 0.8 * vY + 0.2 * velocityY;
        rafId = requestAnimationFrame(track);
    };
    return {
        push: function (touch) {
            if (!lastTouches.length) {
                frameX = touch.pageX;
                frameY = touch.pageY;
            }
            offsetX = touch.pageX;
            offsetY = touch.pageY;
            var currentSwipeDirection;
            if (initialSwipeDirection === UNKNOWN) {
                currentSwipeDirection = UNKNOWN;
            }
            else if (initialSwipeDirection.horizontal) {
                currentSwipeDirection = swipeDirection({ horizontalAllowed: true, verticalAllowed: false });
            }
            else {
                currentSwipeDirection = swipeDirection({ horizontalAllowed: false, verticalAllowed: true });
            }
            lastTouches.push({
                pageX: touch.pageX,
                pageY: touch.pageY,
                swipeDirection: currentSwipeDirection,
            });
            if (lastTouches.length === eventCountNeeded) {
                initialSwipeDirection = swipeDirection({ horizontalAllowed: true, verticalAllowed: true });
            }
        },
        reset: function () {
            initialSwipeDirection = UNKNOWN;
            lastTouches = [];
        },
        startTrackingVelocity: function () {
            if (rafId === null) {
                rafId = requestAnimationFrame(track);
            }
        },
        stopTrackingVelocity: function () {
            cancelAnimationFrame(rafId);
            rafId = null;
        },
        isSwiping: function () {
            var directions = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                directions[_i] = arguments[_i];
            }
            var swipeDirection = this.swipeDirection();
            for (var i = 0; i < directions.length; i++) {
                if (directions[i] === swipeDirection) {
                    return true;
                }
            }
        },
        firstEvent: function () {
            if (!lastTouches.length) {
                throw new Error('Cannot get first touch event when no events have been registered');
            }
            return lastTouches[0];
        },
        swipeVelocity: function () {
            return {
                horizontal: velocityX,
                vertical: velocityY,
            };
        },
        swipeDirection: function () {
            var lastEvent = lastTouches[lastTouches.length - 1];
            return lastEvent ? lastEvent.swipeDirection : UNKNOWN;
        },
    };
}
