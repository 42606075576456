import React from 'react';
import Price from 'Shared/Price';
import { TINY } from 'Shared/breakpoints';
import { Basic } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
var OrderLineItems = function (props) {
    var unitNames = {
        st: translate('/Units/UnitNames/st'),
        frp: translate('/Units/UnitNames/frp'),
        kg: translate('/Units/UnitNames/kg'),
        kgd: translate('/Units/UnitNames/kgd'),
    };
    var discountTypeNames = {
        fixed: 'Fixed',
        amount: 'Amount',
        percent: 'Percent',
    };
    return (React.createElement(Basic, { className: styles.container },
        props.currentBreakpoint >= TINY && (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, translate('/ProductListing/ArticleNumber')),
                    React.createElement("th", null, translate('/ProductListing/DisplayName')),
                    React.createElement("th", null, translate('/ProductListing/Information')),
                    React.createElement("th", null, translate('/ProductListing/Quantity')),
                    React.createElement("th", null, translate('/ProductListing/UnitCompact')),
                    React.createElement("th", { className: styles.textRight }, props.IsSupplierSalesman
                        ? translate('/ProductListing/IsProductSample')
                        : translate('/ProductListing/Price')),
                    React.createElement("th", { className: styles.textRight }, props.IsSupplierSalesman ? translate('/ProductListing/Discount') : translate('/ProductListing/Sum')))),
            React.createElement("tbody", null, props.orderLineItems.map(function (lineItem, i) {
                var discountAmountText = lineItem.discountType === discountTypeNames.fixed
                    ? lineItem.discountTypeName + " " + lineItem.discountValue + " kr"
                    : lineItem.discountValue + " " + (lineItem.discountType === discountTypeNames.percent ? ' %' : ' kr');
                return (React.createElement("tr", { key: i },
                    React.createElement("td", null, lineItem.itemCode),
                    React.createElement("td", null,
                        React.createElement("a", { href: lineItem.productUrl }, lineItem.productName)),
                    React.createElement("td", null, lineItem.storeUnitSpecification),
                    React.createElement("td", null,
                        lineItem.orderedQuantity,
                        " ",
                        lineItem.unitShortName.toLowerCase()),
                    React.createElement("td", null, unitNames[lineItem.unitShortName.toLowerCase()]),
                    React.createElement("td", { className: styles.textRight }, props.IsSupplierSalesman ? lineItem.isProductSample && 'X' : React.createElement(Price, { current: lineItem.price })),
                    React.createElement("td", { className: styles.textRight }, props.IsSupplierSalesman && lineItem.discountValue !== 0 ? (lineItem.isProductSample ? null : (discountAmountText)) : (React.createElement(Price, { current: lineItem.totalPrice })))));
            })))),
        props.currentBreakpoint < TINY && (React.createElement("ul", null, props.orderLineItems.map(function (lineItem, i) {
            var discountAmountText = lineItem.discountType === discountTypeNames.fixed
                ? lineItem.discountTypeName + " " + lineItem.discountValue + " kr"
                : lineItem.discountValue + " " + (lineItem.discountType === discountTypeNames.percent ? ' %' : ' kr');
            return (React.createElement("li", { key: i },
                React.createElement("dl", { className: styles.base },
                    React.createElement("dt", null, translate('/ProductListing/DisplayName')),
                    React.createElement("dd", null,
                        React.createElement("a", { href: lineItem.productUrl }, lineItem.productName)),
                    React.createElement("dt", null, translate('/ProductListing/ArticleNumber')),
                    React.createElement("dd", null, lineItem.itemCode),
                    React.createElement("dt", null, translate('/ProductListing/Information')),
                    React.createElement("dd", null, lineItem.storeUnitSpecification),
                    React.createElement("dt", null, translate('/ProductListing/Quantity')),
                    React.createElement("dd", null, lineItem.orderedQuantity),
                    React.createElement("dt", null, translate('/ProductListing/UnitCompact')),
                    React.createElement("dd", null, unitNames[lineItem.unitShortName.toLowerCase()]),
                    props.IsSupplierSalesman && React.createElement("dt", null, translate('/ProductListing/IsProductSample')),
                    props.IsSupplierSalesman && React.createElement("dd", null, lineItem.isProductSample ? 'X' : ''),
                    !props.IsSupplierSalesman && React.createElement("dt", null, translate('/ProductListing/Price')),
                    !props.IsSupplierSalesman && React.createElement("dd", null, lineItem.price),
                    props.IsSupplierSalesman && lineItem.discountValue !== 0 && (React.createElement("dt", null, translate('/ProductListing/Discount'))),
                    props.IsSupplierSalesman && lineItem.discountValue !== 0 && !lineItem.isProductSample && (React.createElement("dd", null, lineItem.isProductSample ? null : discountAmountText)),
                    !props.IsSupplierSalesman && React.createElement("dt", null, translate('/ProductListing/Sum')),
                    !props.IsSupplierSalesman && (React.createElement("dd", null,
                        React.createElement(Price, { current: lineItem.totalPrice }))))));
        })))));
};
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
    IsSupplierSalesman: state.currentUser.isSupplierSalesman,
}); })(OrderLineItems);
