var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import PropertyFor from 'Shared/PropertyFor';
import { Main } from 'Shared/PageLayout';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
import * as headerstyles from 'SiteLayout/Footer/footer-header.scss';
var InformationFooterBlock = /** @class */ (function (_super) {
    __extends(InformationFooterBlock, _super);
    function InformationFooterBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InformationFooterBlock.prototype.render = function () {
        var isDesktop = this.props.currentBreakpoint > SMALL;
        return (isDesktop && (React.createElement(Main, { className: styles.base, layout: "1to1" },
            React.createElement("div", null,
                React.createElement(PropertyFor, { property: this.props.title, className: headerstyles.h4, tagName: "div" }),
                React.createElement(PropertyFor, { property: this.props.description, tagName: "p" })),
            React.createElement("div", { className: styles.copyright },
                React.createElement(PropertyFor, { property: this.props.copyright, tagName: "span" })))));
    };
    return InformationFooterBlock;
}(React.Component));
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })(InformationFooterBlock);
