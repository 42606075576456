var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main, { Part } from 'Shared/PageLayout';
import FeedbackButton from 'Shared/Button/Feedback';
import connect from 'Shared/connect';
import { addToCart } from '../../../Cart/action-creators';
import ProductSearchList from '../../../QuickOrder/QuickOrderPage/ProductSearchList';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import Backbutton from '../../../App/Backbutton';
import { addOrderToCart, initOrder, clearItems } from 'Shared/ListItem/action-creators';
import FavoriteListItem from './FavoriteListItem';
var FavoriteListDetailsPage = /** @class */ (function (_super) {
    __extends(FavoriteListDetailsPage, _super);
    function FavoriteListDetailsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.validateQuantity = function (quantity) { return !isNaN(parseFloat((quantity || '').replace(',', '.'))); };
        _this.addItems = function () {
            return _this.props.order
                .filter(function (item) { return _this.validateQuantity(item.quantity); })
                .map(function (item) {
                return _this.props.addToCart(item.productId + '_' + item.unitShortName, parseInt(item.quantity, 10), item.unitShortName);
            });
        };
        return _this;
    }
    FavoriteListDetailsPage.prototype.componentWillMount = function () {
        this.props.initOrder(this.props.items);
    };
    FavoriteListDetailsPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(Backbutton, { title: translate('/App/Favorites/List') + " " + this.props.displayName }),
            this.props.items && [
                React.createElement(Part, { key: "list" },
                    React.createElement(ProductSearchList, { template: FavoriteListItem, searchResults: this.props.order })),
                React.createElement(Part, { className: styles.buttons, key: "buttons" },
                    React.createElement(FeedbackButton, { appearance: "clear", type: "button", onClickWithFeedback: function () { return _this.props.clearItems(); } }, translate('/App/Favorites/Clear')),
                    React.createElement(FeedbackButton, { type: "button", onClickWithFeedback: this.addItems }, translate('/App/Favorites/AddAllButton'))),
            ]));
    };
    return FavoriteListDetailsPage;
}(React.Component));
export default connect(function (state) { return ({
    order: state.pantriesFavorites.order,
}); }, function (dispatch) { return ({
    addToCart: function (code, quantity, unitShortName) {
        return dispatch(addToCart(code, quantity, unitShortName, null, null));
    },
    initOrder: function (products) {
        dispatch(initOrder(products));
    },
    clearItems: function () {
        dispatch(clearItems());
    },
    addOrderToCart: function () {
        dispatch(addOrderToCart());
    },
}); })(FavoriteListDetailsPage);
