import React from 'react';
import classnames from 'classnames';
import { SMALL } from 'Shared/breakpoints';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import { translate } from 'Shared/translate';
import { urlToString } from 'Shared/url';
import { searchPageUrl } from 'Shared/known-urls';
import * as styles from './autocomplete.scss';
import MyMenigoIcon from '../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import DidYouMean from './DidYouMean';
import InfoBlock from './InfoBlock';
import ProductCardsGrid from 'Shared/ProductCard/ProductRow2.0/ProductCardsGrid';
var ProductSearchList = function (props) {
    var isMobile = props.currentBreakpoint <= SMALL;
    return (React.createElement(React.Fragment, null, props.lists.map(function (list, index) {
        var _a, _b, _c, _d, _e, _f;
        return list.products &&
            list.products.length > 0 ? (React.createElement("div", { key: "ProductCardList" + index },
            React.createElement("h4", { className: styles.listHeader },
                translate('/ProductListing/CustomFilterShows'),
                " ",
                !!props.totalItems && React.createElement("span", null,
                    "(",
                    props.totalItems,
                    " ",
                    translate('/Units/UnitShortNames/st'),
                    ")"),
                " "),
            React.createElement(ProductCardsGrid, { products: list.products, viewLayoutType: viewLayoutTypes.list.name, searchTerm: props.searchTerm }),
            React.createElement("a", { className: classnames(styles.button, styles.showAll), href: urlToString({ path: searchPageUrl(), query: { q: props.searchTerm } }) },
                list.products.length == 1 ? translate('/Shared/Show') : translate('/Shared/ShowAll'),
                React.createElement(MyMenigoIcon, { name: "arrow" })))) :
            (list.products && props.searchTerm.length > 1 && (!!list.searchDidYouMean && list.searchDidYouMean.length > 0)) ?
                (React.createElement(React.Fragment, { key: "DidYouMean-HelpText" },
                    React.createElement("div", { className: classnames(styles.noHitsSection, (_a = {}, _a[styles.searchSuggestionBackgroundBox] = isMobile, _a)) },
                        React.createElement("div", { className: classnames((_b = {}, _b[styles.searchSuggestionBox] = isMobile, _b)) },
                            React.createElement(DidYouMean, null))),
                    React.createElement("div", { className: classnames(styles.infoBlock, (_c = {}, _c[styles.searchSuggestionBackgroundBox] = !isMobile, _c)) },
                        React.createElement(InfoBlock, { helpText: props.helpText, helpTextChat: props.helpTextChat })))) : (list.products && props.searchTerm.length > 1 &&
                React.createElement(React.Fragment, { key: "NoResult-HelpText" },
                    React.createElement("div", { className: classnames(styles.noHitsSection, (_d = {}, _d[styles.searchSuggestionBackgroundBox] = isMobile, _d)) },
                        React.createElement("div", { className: classnames((_e = {}, _e[styles.searchSuggestionBox] = isMobile, _e)) }, "" + translate('/Shared/FadeNoHits'))),
                    React.createElement("div", { className: classnames(styles.infoBlock, (_f = {}, _f[styles.searchSuggestionBackgroundBox] = !isMobile, _f)) },
                        React.createElement(InfoBlock, { helpText: props.helpText, helpTextChat: props.helpTextChat }))));
    })));
};
export default ProductSearchList;
