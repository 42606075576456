var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import classnames from 'classnames';
import flatten from 'Shared/flatten-appearance';
import * as styles from '../../../styles/objects/buttons.scss';
function isLink(props) {
    return 'href' in props;
}
function classnamesFromApperance(appearance) {
    var apperances = flatten(appearance);
    if (apperances.includes('link') && !apperances.includes('bare')) {
        apperances.push('bare');
    }
    return apperances.map(function (name) { return styles[name]; });
}
export default function Button(props) {
    var computedProps = Object.assign({}, props, {
        className: classnames(props.className, classnamesFromApperance(props.appearance)),
    });
    var appearance = computedProps.appearance, restProps = __rest(computedProps, ["appearance"]);
    return isLink(restProps)
        ? React.createElement(AnchorVariant, __assign({}, restProps))
        : React.createElement(ButtonVariant, __assign({}, restProps));
}
function ButtonVariant(computedProps) {
    return (React.createElement("button", __assign({}, computedProps, { type: computedProps.type || 'button', disabled: computedProps.disabled }), computedProps.children));
}
function AnchorVariant(props) {
    var _a;
    var disabled = props.disabled, type = props.type, anchorProps = __rest(props, ["disabled", "type"]);
    return (React.createElement("a", __assign({}, anchorProps, { className: classnames(styles.base, anchorProps.className, (_a = {},
            _a[styles.disabled] = disabled,
            _a)) }), anchorProps.children));
}
