var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { getSelectedVariant } from 'Shared/Product/reducer';
import { getCartLineItemsForVariants } from '../../Cart/reducer';
import { selectVariant, toggleSelectedVariant } from 'Shared/Product/action-creators';
import * as styles from './base.scss';
import Button from 'Shared/Button';
import classnames from 'classnames';
var VariantSelection = function (_a) {
    var variants = _a.variants, selectedUnitShortName = _a.selectedUnitShortName, onSelectVariant = _a.onSelectVariant;
    return (React.createElement(React.Fragment, null, variants.length > 1 ? (variants.map(function (v) {
        var _a;
        return (React.createElement(Button, { key: v.code, appearance: "bare", onClick: function () { return onSelectVariant(v.unitShortName); }, className: classnames(styles.button, (_a = {}, _a[styles.selected] = v.unitShortName === selectedUnitShortName, _a)) }, v.unitShortName));
    })) : (React.createElement("div", { className: styles.placeholderText },
        " ",
        variants[0].unitShortName))));
};
export { VariantSelection };
var VariantSelectionContainer = /** @class */ (function (_super) {
    __extends(VariantSelectionContainer, _super);
    function VariantSelectionContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setSelectedVariant = function (unitShortName) {
            var variant = _this.getVariantFromUnitShortName(unitShortName);
            if (variant) {
                _this.props.selectVariant(variant.code, _this.props.product.variants);
                if (_this.props.onSelectVariant)
                    _this.props.onSelectVariant(_this.props.product.selectedVariant.code, variant.code);
            }
        };
        return _this;
    }
    VariantSelectionContainer.prototype.componentWillReceiveProps = function (newProps) {
        if (newProps.cartLineItems.length === 1 && this.props.cartLineItems.length === 2) {
            this.props.selectVariant(newProps.cartLineItems[0].code, this.props.product.variants);
        }
    };
    VariantSelectionContainer.prototype.getVariantFromUnitShortName = function (unitShortName) {
        return this.props.product.variants.find(function (v) { return v.unitShortName === unitShortName; });
    };
    VariantSelectionContainer.prototype.getSelectableVariants = function () {
        var _this = this;
        if (this.props.preferedVariant && this.props.cartLineItems.length > 1) {
            return [this.props.preferedVariant];
        }
        if (this.props.product.isWeightProduct) {
            return this.props.product.variants.filter(function (v) { return v.unitShortName === _this.props.unitShortName; });
        }
        return this.props.product.variants;
    };
    VariantSelectionContainer.prototype.render = function () {
        var _a = this.props, Component = _a.Component, unitShortName = _a.unitShortName;
        return (React.createElement(Component, { onSelectVariant: this.setSelectedVariant, selectedUnitShortName: unitShortName, variants: this.getSelectableVariants() }));
    };
    VariantSelectionContainer.defaultProps = {
        Component: VariantSelection,
    };
    return VariantSelectionContainer;
}(React.Component));
export default connect(function (state, ownProps) { return ({
    selectedVariant: getSelectedVariant(state, ownProps.product.variants),
    cartLineItems: getCartLineItemsForVariants(state, ownProps.product.variants),
}); }, function (dispatch, ownProps) { return ({
    selectVariant: function (variantCode) { return dispatch(selectVariant(variantCode, ownProps.product.variants)); },
    toggleSelectedVariant: function (currentSelectedVariantCode) {
        return dispatch(toggleSelectedVariant(currentSelectedVariantCode, ownProps.product.variants));
    },
}); })(VariantSelectionContainer);
