var placeholderReplaceRegex = /({[^}]+})/g;
export default (function (value, args) {
    return value &&
        value.replace(placeholderReplaceRegex, function (ph) {
            var key = ph.substr(1, ph.length - 2).trim();
            if (key in args) {
                var val = args[key];
                if (val == null) {
                    return '';
                }
                else if (typeof val === 'number') {
                    return val.toString();
                }
                else {
                    return val;
                }
            }
            else {
                console.error('Could not find placeholder', key, 'in', args);
                return ph;
            }
        });
});
