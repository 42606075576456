import React from 'react';
import connect from 'Shared/connect';
import UserInfoBlock from '../../Blocks/UserInfoBlock/UserInfoBlock';
import { translate } from 'Shared/translate';
var UserInfoBox = function (_a) {
    var menuItems = _a.menuItems, companyName = _a.companyName, customerId = _a.customerId;
    var userAccountsLink = menuItems.find(function (x) { return x.name === 'Användarkonton'; });
    var myProfileLink = menuItems.find(function (x) { return x.name === 'Min profil'; });
    return (React.createElement(React.Fragment, null,
        userAccountsLink ? (React.createElement(UserInfoBlock, { Header: translate('/MyMenigo/Overview/CustomerId'), Content: customerId, Link: userAccountsLink })) : null,
        myProfileLink ? (React.createElement(UserInfoBlock, { Header: translate('/MyMenigo/Overview/CompanyName'), Content: companyName, Link: myProfileLink })) : null));
};
export default connect(function (state) { return ({
    companyName: state.currentUser.companyName,
    customerId: state.currentUser.customerId
}); })(UserInfoBox);
