var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Quantity from 'Shared/Quantity';
import Button from 'Shared/Button';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { Form } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import { onSaveNutrientsAction } from './action-creators';
import DropDown from 'Shared/DropDown';
import * as styles from './Pages/CustomProductOverview/base.scss';
import MyMenigoIcon from '../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var EditNutrient = /** @class */ (function (_super) {
    __extends(EditNutrient, _super);
    function EditNutrient(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            activeNutrient: props.nutrientListViewModel.selectableNutrients[0],
            nutrientListViewModel: props.nutrientListViewModel,
        };
        return _this;
    }
    EditNutrient.prototype.onAddNutrient = function (e) {
        e.preventDefault();
        this.props.dismissFeedback();
        var activeNutrient = Object.assign({}, this.state.activeNutrient);
        if (!activeNutrient || (activeNutrient && activeNutrient.value <= 0)) {
            return;
        }
        var nutrientListViewModel = Object.assign({}, this.state.nutrientListViewModel);
        var selectableNutrients = Object.assign([], nutrientListViewModel.selectableNutrients);
        var newNutrients = Object.assign([], nutrientListViewModel.nutrients);
        var indexInArray = newNutrients.findIndex(function (x) { return x.productNutrientsId === activeNutrient.productNutrientsId; });
        if (indexInArray >= 0) {
            return;
        }
        newNutrients.push(activeNutrient);
        nutrientListViewModel.nutrients = newNutrients;
        var indexInSelectableNutrients = selectableNutrients.findIndex(function (x) { return x.productNutrientsId === activeNutrient.productNutrientsId; });
        selectableNutrients.splice(indexInSelectableNutrients, 1);
        nutrientListViewModel.selectableNutrients = selectableNutrients;
        this.setState({
            nutrientListViewModel: nutrientListViewModel,
            activeNutrient: nutrientListViewModel.selectableNutrients[0],
        });
    };
    EditNutrient.prototype.onRemoveNutrient = function (e, nutrientId) {
        e.preventDefault();
        if (!nutrientId) {
            return;
        }
        var nutrientListViewModel = Object.assign({}, this.state.nutrientListViewModel);
        var selectableNutrients = Object.assign([], nutrientListViewModel.selectableNutrients);
        var newNutrients = Object.assign([], nutrientListViewModel.nutrients);
        var nutrient = newNutrients.filter(function (nutrient) {
            return nutrient.productNutrientsId === nutrientId;
        });
        if (nutrient) {
            var index = newNutrients.findIndex(function (x) { return x.productNutrientsId === nutrientId; });
            if (index > -1) {
                newNutrients.splice(index, 1);
            }
            nutrientListViewModel.nutrients = newNutrients;
            selectableNutrients.push(nutrient[0]);
            nutrientListViewModel.selectableNutrients = selectableNutrients;
            this.setState({
                nutrientListViewModel: nutrientListViewModel,
                activeNutrient: nutrientListViewModel.selectableNutrients[0],
            });
        }
    };
    EditNutrient.prototype.onChangeNutrient = function (nutrientsId) {
        var newActiveNutrient = this.state.nutrientListViewModel.selectableNutrients.find(function (x) { return x.productNutrientsId === nutrientsId; });
        this.setState({
            activeNutrient: newActiveNutrient,
        });
    };
    EditNutrient.prototype.onUpdateActiveNutrientValue = function (value) {
        var activeNutrient = Object.assign({}, this.state.activeNutrient);
        activeNutrient.value = value;
        this.setState({
            activeNutrient: activeNutrient,
        });
        return Promise.resolve();
    };
    EditNutrient.prototype.onUpdateCustomNutrient = function (value, nutrientId) {
        if (!nutrientId) {
            return;
        }
        var nutrientsObject = Object.assign({}, this.state.nutrientListViewModel);
        var nutrients = Object.assign([], nutrientsObject.nutrients);
        var nutrient = nutrients.filter(function (nutrient) {
            return nutrient.productNutrientsId === nutrientId;
        });
        if (nutrient) {
            nutrient[0].value = value;
            nutrientsObject.nutrients = nutrients;
            this.setState({
                nutrientListViewModel: nutrientsObject,
            });
        }
        return Promise.resolve();
    };
    EditNutrient.prototype.onSaveNutrients = function (e) {
        var _this = this;
        e.preventDefault();
        onSaveNutrientsAction(this.state.nutrientListViewModel.customProductId, this.state.nutrientListViewModel.nutrients.length > 0 ? this.state.nutrientListViewModel.nutrients : null).then(function (json) {
            var feedbackMessage = json && json.success
                ? translate('/CustomProduct/Nutrient/SavedNutrient')
                : translate('/CustomProduct/Nutrient/NutrientError');
            if (json && json.success) {
                _this.props.dismissFeedback();
            }
            _this.props.setFeedback({
                text: feedbackMessage,
                type: json && json.success ? 'success' : 'error',
            });
        }, function (e) {
            return Promise.reject(null);
        });
    };
    EditNutrient.prototype.render = function () {
        var _this = this;
        var selectableNutrients = Object.keys(this.state.nutrientListViewModel.selectableNutrients).map(function (key) { return ({
            value: _this.state.nutrientListViewModel
                ? _this.state.nutrientListViewModel.selectableNutrients[key].productNutrientsId
                : '',
            text: _this.state.nutrientListViewModel ? _this.state.nutrientListViewModel.selectableNutrients[key].name : ''
        }); });
        return (React.createElement(Form, { appearance: "default" },
            React.createElement("div", { className: styles.divOneThree },
                React.createElement("label", null, translate('/CustomProduct/Nutrient/Nutrients')),
                " ",
                React.createElement("br", null),
                React.createElement(DropDown, { className: styles.fullWidth, options: selectableNutrients, onChange: function (nutrientId) { return _this.onChangeNutrient(nutrientId); }, value: this.state.activeNutrient.productNutrientsId || '' })),
            React.createElement("div", { className: styles.divOneThree },
                React.createElement("label", { htmlFor: "nutrient" }, translate('/CustomProduct/Nutrient/Value')),
                React.createElement("div", { className: styles.quantityWrapper },
                    React.createElement(Quantity, { className: styles.fullWidth, value: this.state.activeNutrient.value, update: function (value) { return _this.onUpdateActiveNutrientValue(value); }, minQuantity: 0, step: 0.1, useNewDesign: true, unitShortName: this.state.activeNutrient.unit }))),
            React.createElement("div", { className: styles.divOneThree },
                React.createElement("label", null),
                React.createElement("br", null),
                React.createElement(Button, { type: "button", onClick: function (e) { return _this.onAddNutrient(e); } }, translate('/CustomProduct/Nutrient/AddButton'))),
            React.createElement("div", { className: styles.clearboth }),
            React.createElement("br", null),
            this.state.nutrientListViewModel.nutrients.length > 0 ? (React.createElement("div", { className: styles.tableArea },
                React.createElement("table", { className: styles.table },
                    React.createElement("tbody", null,
                        React.createElement("tr", { className: styles.tableRow },
                            React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Nutrient/Name')),
                            React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Nutrient/Unit')),
                            React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Nutrient/Value')),
                            React.createElement("th", { className: styles.tableHeader }, translate('/CustomProduct/Nutrient/RemoveNutrient'))),
                        this.state.nutrientListViewModel.nutrients.map(function (nutrient) {
                            return (React.createElement("tr", { className: styles.tableRow, key: nutrient.productNutrientsId },
                                React.createElement("td", null, nutrient.name),
                                React.createElement("td", null, nutrient.unit),
                                React.createElement("td", null,
                                    React.createElement("div", { className: styles.quantityWrapper },
                                        React.createElement(Quantity, { className: styles.fullWidth, value: nutrient.value, update: function (value) { return _this.onUpdateCustomNutrient(value, nutrient.productNutrientsId); }, minQuantity: 0, step: 0.1, useNewDesign: true, unitShortName: nutrient.unit }))),
                                React.createElement("td", null,
                                    React.createElement("a", { href: "javascript:void(0)", onClick: function (e) {
                                            return _this.onRemoveNutrient(e, nutrient.productNutrientsId);
                                        }, className: styles.deleteNutrientButton },
                                        React.createElement(MyMenigoIcon, { name: "trash" })))));
                        }))))) : (React.createElement("p", null, translate('/CustomProduct/Nutrient/NoNutrients'))),
            React.createElement("div", { className: styles.clearboth }),
            React.createElement("br", null),
            React.createElement("div", null,
                React.createElement(Button, { type: "button", onClick: function (e) { return _this.onSaveNutrients(e); } }, translate('/CustomProduct/Nutrient/SaveButton')))));
    };
    return EditNutrient;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(EditNutrient);
