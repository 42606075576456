var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { onView, onClick } from 'Shared/ContentCard/DataLayer';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import ReactSwipe from 'react-swipe';
import { translate } from 'Shared/translate';
import { TINY } from 'Shared/breakpoints';
import * as styles from './base.scss';
import ViewTracker from '../ViewObserver/ViewTracker';
import LinkWithAlcoholWarning from '../LinkWithAlcoholWarning';
var BannerSlider = /** @class */ (function (_super) {
    __extends(BannerSlider, _super);
    function BannerSlider(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentIndex: 0,
            direction: 'next',
        };
        _this.timer = _this.timer.bind(_this);
        _this.slideTo = _this.slideTo.bind(_this);
        return _this;
    }
    BannerSlider.prototype.slideTo = function (index) {
        var _this = this;
        this.setState({ currentIndex: index }, function () { return _this.swiper.swipe.slide(index); });
    };
    BannerSlider.prototype.componentDidMount = function () {
        if (!!this.props.imageUrls && this.props.imageUrls.length > 1) {
            this.intervalId = setInterval(this.timer, 6000);
        }
    };
    BannerSlider.prototype.componentWillUnmount = function () {
        !!this.swiper && this.swiper.swipe.kill();
        !!this.intervalId && clearInterval(this.intervalId);
    };
    BannerSlider.prototype.timer = function () {
        this.slide('next');
    };
    BannerSlider.prototype.componentWillReceiveProps = function (nextProps) {
        if (!arrayEquals(nextProps.imageUrls, this.props.imageUrls)) {
            this.lastImageUrls = this.props.imageUrls;
            !!this.swiper && this.swiper.swipe.kill();
            if (!!nextProps.imageUrls && nextProps.imageUrls.length > 0) {
                !!this.swiper && this.swiper.swipe.setup();
                if (!this.intervalId && nextProps.imageUrls.length > 1) {
                    this.intervalId = setInterval(this.timer, 6000);
                }
            }
        }
    };
    BannerSlider.prototype.slide = function (direction) {
        var currentIndex = this.state.currentIndex;
        var len = !!this.props.imageUrls ? this.props.imageUrls.length : 0;
        if (len === 0) {
            return;
        }
        if (direction === 'next') {
            if (currentIndex + 1 === len) {
                this.slideTo(0);
            }
            else {
                this.slideTo(currentIndex + 1);
            }
        }
        else {
            if (currentIndex === 0) {
                this.slideTo(len - 1);
            }
            else {
                this.slideTo(currentIndex - 1);
            }
        }
    };
    BannerSlider.prototype.prevNextClick = function (direction) {
        this.slide(direction);
        clearInterval(this.intervalId);
    };
    BannerSlider.prototype.componentDidUpdate = function () {
        if (!!this.lastImageUrls &&
            this.lastImageUrls.length > 0 &&
            !!this.props.imageUrls &&
            this.props.imageUrls.length > 0) {
            !!this.swiper && this.swiper.swipe.setup();
        }
    };
    BannerSlider.prototype.render = function () {
        var _this = this;
        var len = this.props.imageUrls ? this.props.imageUrls.length : 0;
        return (React.createElement("div", { className: classnames(styles.base, this.props.isStartPage && styles.negativeTopMargin, !!this.props.className && this.props.className) },
            len > 0 && (React.createElement("div", null,
                React.createElement("div", { id: "large-image" },
                    React.createElement(ReactSwipe, { swipeOptions: {
                            continuous: false,
                            callback: function (index) { return _this.slideTo(index); },
                        }, ref: function (swiper) { return (_this.swiper = swiper); }, key: len }, this.props.heroCards.map(function (c, i) {
                        var color = c.textColor === 'primary-text' ? styles.primaryText : styles.secondaryText;
                        return (React.createElement("div", { key: 'slider' + i },
                            React.createElement(ViewTracker, { key: 'sliderTracker' + i, onView: function (visible) {
                                    onView(visible, c, i);
                                } },
                                React.createElement("div", { className: styles.hero },
                                    React.createElement(LinkWithAlcoholWarning, { targetLink: c.targetLink, hasAlcoholWarning: c.containsAlcoholInfo, text: c.title || 'Slider' + (i + 1), onClick: function (e) { return onClick(e, c, i); } },
                                        React.createElement("div", { className: styles.heroImage, style: { backgroundImage: 'url(' + c.imageUrl + ')' } }),
                                        (!!c.title || !!c.targetLink.text) && (React.createElement("div", { className: styles.bottomSection },
                                            !!c.title && (React.createElement("h2", { className: classnames(styles.heroTitle, color) }, c.title)),
                                            !!c.targetLink.text && (React.createElement(Button, { onClick: function (e) { return onClick(e, c, i); }, href: c.targetLink.url, "data-may-prevent-default": true, appearance: !!c.targetLink.linkButtonStyle
                                                    ? c.targetLink.linkButtonStyle
                                                    : _this.props.currentBreakpoint > TINY
                                                        ? 'primary'
                                                        : 'secondary' }, !!c.targetLink.text ? c.targetLink.text : translate('/Product/ShowAll'))))))))));
                    }))))),
            len > 1 && (React.createElement("div", null,
                React.createElement(Button, { appearance: ['round', 'primary'], onClick: function () { return _this.prevNextClick('prev'); }, className: styles.prev },
                    React.createElement("span", null,
                        React.createElement(Icon, { name: "arrow-left" }))),
                React.createElement(Button, { appearance: ['round', 'primary'], onClick: function () { return _this.prevNextClick('next'); }, className: styles.next },
                    React.createElement("span", null,
                        React.createElement(Icon, { name: "arrow-right" }))),
                React.createElement("ul", { className: styles.swiperPagination }, this.props.imageUrls.map(function (slide, index) { return (React.createElement("li", { key: index, className: _this.state.currentIndex === index ? styles.active : '', onClick: function () { return _this.slideTo(index); } })); }))))));
    };
    return BannerSlider;
}(React.Component));
function arrayEquals(array1, array2) {
    if (!array1 && !array2) {
        return true;
    }
    if (!array1 || !array2 || array1.length !== array2.length) {
        return false;
    }
    return (array1
        .slice()
        .sort()
        .join('') ===
        array2
            .slice()
            .sort()
            .join(''));
}
export default BannerSlider;
