import { isBrowser } from 'Shared/device-type';
import * as sessionStorage from 'Shared/session-storage';
import * as server from 'Shared/server';
import { parseUrl, urlToString, areEqual, isUrlObject, currentUrl, isExternal } from 'Shared/url';
import { scrollPosition } from 'SiteLayout/Root';
var listeners = [];
export var historyAPISupported = (function () {
    if (!isBrowser()) {
        return false;
    }
    var ua = navigator.userAgent;
    if ((ua.indexOf('Android 2.') !== -1 || ua.indexOf('Android 4.0') !== -1) &&
        ua.indexOf('Mobile Safari') !== -1 &&
        ua.indexOf('Chrome') === -1 &&
        ua.indexOf('Windows Phone') === -1) {
        return false;
    }
    // Don't use History API for edit mode, this to make on page editing work
    if (window.IS_IN_EDIT_MODE) {
        return false;
    }
    return window.history && 'pushState' in window.history;
})();
var ignoreNextPopStateEvent = false;
var deserialized = deserialize();
var historyStates = deserialized.historyStates;
var currentHistoryIndex = deserialized.currentHistoryIndex;
serialize();
function makeHashUrlForApp(url) {
    if (IS_APP) {
        if (isExternal(url)) {
            throw new Error('External url navigation in app must be explicitly handled');
        }
        var urlString = urlToString(Object.assign({}, url, { hostname: null }));
        return '#!' + urlString;
    }
    else {
        return urlToString(url);
    }
}
export function pushState(url, options) {
    if (url === void 0) { url = undefined; }
    if (options === void 0) { options = {}; }
    var urlObject = null;
    if (!url) {
        urlObject = currentUrl();
    }
    else if (typeof url === 'string') {
        urlObject = parseUrl(normalizeUrl(url));
    }
    else if (isUrlObject(url)) {
        urlObject = url;
    }
    else {
        throw new Error('Unknown type for `url` passed to pushState: ' + typeof url + ' ' + JSON.stringify(url));
    }
    if (historyAPISupported) {
        var previousState_1 = historyStates[currentHistoryIndex];
        if (previousState_1) {
            previousState_1.scrollPosition = scrollPosition();
        }
        var state_1 = {
            stateId: stateId(),
            // We clear `hiddenQuery` here because when we get a `popstate` event
            // we don't want the `hiddenQuery`. The hidden query string is used
            // to pass information to the server to only load a partial page
            // and if we go back/forward to that state, we want the full page.
            url: Object.assign({}, urlObject, {
                hiddenQuery: {},
            }),
        };
        // If the user pressed Back a couple of times and then click
        // on a link, we splice off the states that she backed away from
        historyStates.splice(currentHistoryIndex + 1);
        historyStates.push(state_1);
        currentHistoryIndex++;
        serialize();
        window.history.pushState(state_1, '', makeHashUrlForApp(urlObject));
        return triggerEvent({
            event: 'push',
            url: urlObject,
            state: state_1,
            previousState: previousState_1,
            options: options,
            revert: function () {
                if (historyStates[currentHistoryIndex] !== state_1) {
                    console.debug('Ignoring revert of history state change because the state has changed');
                    return function () { return false; };
                }
                currentHistoryIndex--;
                serialize();
                back(true);
                return function () {
                    if (previousState_1 !== historyStates[currentHistoryIndex]) {
                        console.debug('Ignoring replay of history state change because the state has changed');
                        return false;
                    }
                    console.debug('Retrying reverted push state change to', urlObject);
                    pushState(url, options);
                    return true;
                };
            },
        });
    }
    else {
        window.location.href = urlToString(urlObject);
    }
}
export function replaceState(url, options, dispatchEvent) {
    if (url === void 0) { url = undefined; }
    if (options === void 0) { options = {}; }
    if (dispatchEvent === void 0) { dispatchEvent = true; }
    var urlObject = null;
    if (!url) {
        urlObject = currentUrl();
    }
    else if (typeof url === 'string') {
        urlObject = parseUrl(normalizeUrl(url));
    }
    else if (isUrlObject(url)) {
        urlObject = url;
    }
    else {
        throw new Error('Unknown type for `url` passed to replaceState: ' + typeof url + ' ' + JSON.stringify(url));
    }
    var previousState = historyStates[currentHistoryIndex];
    var state = {
        stateId: stateId(),
        url: Object.assign({}, urlObject, {
            hiddenQuery: {},
        }),
        scrollPosition: scrollPosition(),
    };
    historyStates[currentHistoryIndex] = state;
    if (historyAPISupported) {
        window.history.replaceState(state, '', makeHashUrlForApp(urlObject));
    }
    serialize();
    // If `replaceState` is called with `dispatchEvent` === false it means that the url bar
    // is not in sync with what the server gave us (the server redirected). So we call replaceState
    // to get back in sync but we don't want to dispatch any events because that would cause us to
    // reload the page which we don't need.
    if (!dispatchEvent) {
        return;
    }
    return triggerEvent({
        event: 'replace',
        url: urlObject,
        state: state,
        previousState: previousState,
        options: options,
        revert: function () {
            if (historyStates[currentHistoryIndex] !== state) {
                console.debug('Ignoring revert of history state change because the state has changed');
                return function () { return false; };
            }
            if (previousState) {
                historyStates[currentHistoryIndex] = previousState;
                if (historyAPISupported) {
                    window.history.replaceState(previousState, '', urlToString(previousState.url));
                }
                serialize();
            }
            return function () {
                if (previousState !== historyStates[currentHistoryIndex]) {
                    console.debug('Ignoring replay of history state change because the state has changed');
                    return false;
                }
                console.debug('Retrying reverted replace state change to', urlObject);
                replaceState(url, options);
                return true;
            };
        },
    });
}
export function on(events, callback) {
    var eventList = null;
    if (typeof events === 'string') {
        eventList = [events];
    }
    else {
        eventList = events;
    }
    eventList.forEach(function (eventName) { return listeners.push({ event: eventName, callback: callback }); });
    // We return a function here that you can call to unscribe to the event.
    // This instead of exporting an `off` function where you have to pass
    // the same function you passed to `on` which makes it impossible to
    // pass an inline function to `on`.
    return function () {
        return eventList.forEach(function (eventName) { return (listeners = listeners.filter(function (l) { return !(l.callback === callback && l.event === eventName); })); });
    };
}
export function one(event, callback) {
    var unsubscribe = on(event, function (e) {
        callback(e);
        unsubscribe();
    });
}
export function back(skipPopEvent) {
    if (skipPopEvent === void 0) { skipPopEvent = false; }
    ignoreNextPopStateEvent = skipPopEvent;
    window.history.back();
}
export function forward(skipPopEvent) {
    if (skipPopEvent === void 0) { skipPopEvent = false; }
    ignoreNextPopStateEvent = skipPopEvent;
    window.history.forward();
}
export function go(index, skipPopEvent) {
    if (skipPopEvent === void 0) { skipPopEvent = false; }
    ignoreNextPopStateEvent = skipPopEvent;
    window.history.go(index);
}
export function captureLinks() {
    // Don't capture any links when in edit mode. We want full page loads in edit mode
    // to make on page editing work
    if (window.IS_IN_EDIT_MODE) {
        return;
    }
    document.addEventListener('click', function (e) {
        var defaultPrevented = false;
        var link = clickedLink(e);
        if (!link) {
            return;
        }
        var ticket = link.getAttribute('data-ticket');
        var useReplaceState = link.getAttribute('data-replace');
        if ((/^http[s]?:/.test(link.href) || /^file:\/\/\//.test(link.href)) &&
            isNormalClick(e) &&
            !isExternalLink(link)) {
            e.preventDefault();
            e.preventDefault = function () { return (defaultPrevented = true); };
        }
        else {
            // This is a special case where the user opens a link in a new tab. We can't set
            // headers for that request, so we need to send it as a special request
            if (isOpenInTabClick(e) && ticket) {
                server.post('/esales/notifyclick', { ticket: ticket });
            }
            return;
        }
        // Not very elegant to schedule a timeout here which is a macro task.
        // If we could schedule a micro task instead, we could call e.preventDefault()
        // in that micro task but it's too late to do that in a macro task.
        // When micro task scheduling API's are available in the browsers we support
        // (like Promises) we can rewrite this.
        var push = function () {
            // If any listener called e.preventDefault() it's because
            // the listener doesn't want to change the url when clicking
            // on a link, and we should respect that here.
            if (defaultPrevented) {
                return;
            }
            var options = {
                ticket: ticket,
                skipPartialCache: !!link.getAttribute('data-skip-partial-cache'),
            };
            if (link && !isExternalLink(link)) {
                if (areEqual(currentUrl(), link.href) || useReplaceState) {
                    replaceState(link.href, options);
                }
                else {
                    pushState(link.href, options);
                }
            }
        };
        // Since the components uses Reacts event system and we use a capturing event listener here
        // we wait for the components click handler to run if it may want to prevent default.
        // The reason for not running setTimeout in all cases is because some older devices give
        // an intermittent noticable delay for clicks.
        if (link.getAttribute('data-may-prevent-default')) {
            setTimeout(push, 0);
        }
        else {
            push();
        }
    }, true);
}
function isNormalClick(e) {
    return !(e.which > 1 || e.metaKey || e.ctrlKey || e.shiftKey || e.altKey);
}
function isOpenInTabClick(e) {
    return (e.which === 1 && e.ctrlKey) || (e.which === 1 && e.metaKey) || (e.which === 2 && !e.ctrlKey && !e.metaKey);
}
function isExternalLink(link) {
    // In IE `link.hostname` equals an empty string if the location is relative
    return !(link.hostname === '' || link.hostname === window.location.hostname) || link.target === '_blank';
}
function clickedLink(e) {
    var target = e.target;
    do {
        if (target.tagName === 'A') {
            return target;
        }
    } while ((target = target.parentElement));
    return null;
}
if (isBrowser()) {
    window.addEventListener('popstate', function (e) {
        // When we revert a state change, we need to call `back()` which triggers
        // a `popstate` which we want to ignore
        if (ignoreNextPopStateEvent) {
            ignoreNextPopStateEvent = false;
            return;
        }
        // Safari triggers a popstate on load which we want to ignore
        if (!e.state || !e.state.stateId) {
            return;
        }
        var previousState = historyStates[currentHistoryIndex];
        if (previousState) {
            previousState.scrollPosition = scrollPosition();
        }
        var stateIndex = historyStates.findIndex(function (s) { return s.stateId === e.state.stateId; });
        // We find the state object in `historyStates` here instead of using `e.state`
        // directly because we might have modified (added scrollPosition) the object
        // after we gave it to pushState/replaceState.
        var eventState = historyStates[stateIndex];
        if (process.env.NODE_ENV !== 'production') {
            if (!eventState) {
                console.error('historyStates', historyStates, 'e', e, 'currentUrl()', currentUrl());
                throw new Error('`eventState` is not defined.');
            }
        }
        // This can happen if the user is in private browsing mode on iOS
        // In that case nothing will get serailized and `historyStates` will
        // always be empty for when the page loads but we can still get
        // state objects from the native history API.
        if (!eventState) {
            eventState = e.state;
            historyStates.push(eventState);
            currentHistoryIndex = historyStates.length - 1;
        }
        var previousHistoryIndex = currentHistoryIndex;
        var isBack = stateIndex < currentHistoryIndex;
        currentHistoryIndex = stateIndex;
        var event;
        if (isBack) {
            event = 'pop';
        }
        else {
            event = 'push';
        }
        triggerEvent({
            url: eventState.url,
            event: event,
            state: eventState,
            restoreState: true,
            previousState: previousState,
            revert: function () {
                if (eventState !== historyStates[currentHistoryIndex]) {
                    console.debug('Ignoring revert of history state change because the state has changed');
                    return function () { return false; };
                }
                var revertToIndex = previousHistoryIndex - stateIndex;
                var revertToState = historyStates[revertToIndex];
                go(revertToIndex, true);
                currentHistoryIndex = revertToIndex;
                serialize();
                return function () {
                    if (revertToState !== historyStates[currentHistoryIndex]) {
                        console.debug('Ignoring revert of history state change because the state has changed');
                        return false;
                    }
                    console.debug('Retrying reverted state change to', eventState.url);
                    go(stateIndex - previousHistoryIndex);
                    return true;
                };
            },
        });
        serialize();
    });
    window.addEventListener('beforeunload', function (e) {
        if (historyStates[currentHistoryIndex]) {
            historyStates[currentHistoryIndex].scrollPosition = scrollPosition();
            serialize();
        }
    });
}
function triggerEvent(data) {
    var promises = [];
    listeners.forEach(function (o) {
        if (o.event === data.event) {
            var maybePromise = o.callback(data);
            if (maybePromise instanceof Promise) {
                promises.push(maybePromise);
            }
        }
    });
    return Promise.all(promises);
}
function stateId() {
    return Math.ceil(Math.random() * 100000000);
}
var urlNormalizer = isBrowser() ? document.createElement('a') : null;
function normalizeUrl(url) {
    urlNormalizer.href = url;
    var normalizedUrl = urlNormalizer.pathname + urlNormalizer.search + urlNormalizer.hash;
    if (normalizedUrl.indexOf('/') !== 0) {
        // Don't we love IE... link.pathname doesn't start with slash in IE
        normalizedUrl = '/' + normalizedUrl;
    }
    return normalizedUrl;
}
function serialize() {
    if (!isBrowser()) {
        return;
    }
    sessionStorage.set('historyStates', historyStates);
    sessionStorage.set('currentHistoryIndex', currentHistoryIndex);
}
function deserialize() {
    if (!isBrowser()) {
        return {
            historyStates: [],
            currentHistoryIndex: 0,
        };
    }
    var historyStates = sessionStorage.get('historyStates', createInitalHistoryState());
    var currentHistoryIndex = sessionStorage.get('currentHistoryIndex') || 0;
    if (history.state) {
        currentHistoryIndex = historyStates.findIndex(function (s) { return s.stateId === history.state.stateId; });
        if (currentHistoryIndex === -1) {
            currentHistoryIndex = 0;
            if (process.env.NODE_ENV !== 'production') {
                console.error('history.state contained a state not present in serialized state list');
            }
        }
    }
    var state = historyStates[currentHistoryIndex];
    if (!state) {
        var error = new Error('currentHistoryIndex and historyStates are not in sync, ' +
            'currentHistoryIndex: ' +
            currentHistoryIndex +
            ', ' +
            'history.state: ' +
            JSON.stringify(history.state) +
            ', ' +
            'historyStates: ' +
            JSON.stringify(historyStates));
        if (process.env.NODE_ENV !== 'production') {
            throw error;
        }
        else {
            window.newrelic && window.newrelic.noticeError(error);
            // If the state is out of sync because an error occured on another page
            // we first try to repair it
            var index = historyStates.findIndex(function (s) { return areEqual(s.url, currentUrl()); });
            if (index !== -1) {
                currentHistoryIndex = index;
                state = historyStates[currentHistoryIndex];
            }
            else {
                historyStates = createInitalHistoryState();
                currentHistoryIndex = 0;
                state = historyStates[currentHistoryIndex];
            }
        }
    }
    if (!areEqual(state.url, currentUrl())) {
        if (process.env.NODE_ENV !== 'production') {
            console.debug('adding new history state after manually navigating to new url');
        }
        currentHistoryIndex++;
        historyStates.splice(currentHistoryIndex, historyStates.length - currentHistoryIndex, {
            url: currentUrl(),
            stateId: stateId(),
        });
    }
    // We call replaceState() here to get a stateId for the initial state as well
    // This is mostly so we can ignore the `popstate` event that Safari fires on
    // page load that doesn't contain any state.
    if (historyAPISupported) {
        window.history.replaceState(historyStates[currentHistoryIndex], '', urlToString(currentUrl()));
    }
    return {
        historyStates: historyStates,
        currentHistoryIndex: currentHistoryIndex,
    };
}
function createInitalHistoryState() {
    return [{ url: currentUrl(), stateId: history.state ? history.state.stateId : stateId() }];
}
