var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import MyMenigoIcon from "../../../../Shared/MyMenigoIcon/MyMenigoIcon";
import React from "react";
import Button from "Shared/Button";
import { Part } from "Shared/PageLayout";
import { translate } from "Shared/translate";
import * as styles from '../../base.scss';
import { compareValues } from "../action-creators";
import OrderHistoryProductList from "./OrderHistoryProductList";
import OrderHistoryProductListHeader from "./OrderHistoryProductListHeader";
import RecycleItems from "./RecycleItems";
import { setFeedback } from "SiteLayout/GenericFeedbackBar/action-creators";
import { reloadCart } from "../../../../../Cart/action-creators";
import connect from "Shared/connect";
import * as server from 'Shared/server';
import Spinner from "Shared/Spinner";
var OrderHistoryDetails = /** @class */ (function (_super) {
    __extends(OrderHistoryDetails, _super);
    function OrderHistoryDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.sortProducts = function (sortField, secondarySortField) {
            _this.setState({
                sortBy: sortField,
                sortAscending: !_this.state.sortAscending,
                items: _this.props.items.sort(compareValues(sortField, secondarySortField, _this.state.sortAscending))
            });
        };
        _this.copyToCart = function (orders) {
            var orderItems = [];
            var url = "/mymenigoorderhistory/CopyOrder";
            _this.setState({
                loading: true,
            });
            orders.map(function (orderDetail) {
                var articleNumber = orderDetail.articleNumber, unit = orderDetail.unit, orderedQuantity = orderDetail.orderedQuantity;
                var order = {
                    item: articleNumber,
                    unitShortName: unit,
                    quantity: orderedQuantity
                };
                orderItems.push(order);
            });
            server.postJson(url, orderItems)
                .then(function (result) {
                setTimeout(function () {
                    _this.setState({
                        loading: false,
                    });
                }, 300);
                if (result.success) {
                    _this.props.setFeedback({ text: translate('/MyMenigo/OrderOverviewHeaders/CopyOrderSuccess'), type: 'success' });
                }
                else {
                    _this.props.setFeedback({ text: translate('/MyMenigo/OrderOverviewHeaders/CopyOrderError') + ':' + result.message, type: 'error' });
                }
                _this.props.reloadCart();
            }).catch(function (any) {
                _this.setState({ loading: false }, function () {
                    _this.props.setFeedback({
                        text: translate("/MyMenigo/OrderHistory/Error"),
                        type: 'error',
                        overrideTimeout: true,
                    });
                });
            });
            ;
        };
        _this.state = {
            sortBy: _this.props.sortBy,
            items: _this.props.items,
            sortAscending: true,
            loading: false,
            showAddToCartButton: _this.props.showAddToCartButton
        };
        return _this;
    }
    OrderHistoryDetails.prototype.componentDidMount = function () {
        this.setState({
            items: this.props.items.sort(compareValues(this.state.sortBy, "itemDescription", this.state.sortAscending)),
            sortAscending: !this.state.sortAscending,
        });
    };
    OrderHistoryDetails.prototype.render = function () {
        var _this = this;
        return (React.createElement(Part, { className: styles.OrderHistoryDetails__Outer },
            React.createElement(OrderHistoryProductListHeader, { sortField: this.state.sortBy, sortAscending: this.state.sortAscending, setSortedField: this.sortProducts }),
            React.createElement(OrderHistoryProductList, { productItems: this.state.items }),
            !!this.props.recycleItems && this.props.recycleItems.length > 0 && React.createElement(RecycleItems, { recycleItems: this.props.recycleItems }),
            (this.props.showAddToCartButton) &&
                React.createElement("div", { className: styles.addToCartWrapper },
                    React.createElement(Button, { onClick: function () { _this.copyToCart(_this.state.items); }, appearance: 'link' },
                        !!!this.state.loading &&
                            React.createElement("span", null,
                                React.createElement(MyMenigoIcon, { name: 'shoppingbag' }),
                                " ",
                                translate('/MyMenigo/OrderHistory/CopyToCart')),
                        !!this.state.loading && React.createElement(Spinner, { className: styles.addToCartSpinner })))));
    };
    return OrderHistoryDetails;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    reloadCart: function () {
        dispatch(reloadCart());
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(OrderHistoryDetails);
