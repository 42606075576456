import React from 'react';
import { translate } from 'Shared/translate';
import LoginContainer from 'Shared/Login/Container';
import * as styles from './base.scss';
import { getCampaignTypeNameFromId } from 'Shared/ProductCampaignLabel/Components/action-creators';
import connect from 'Shared/connect';
var hideDatesForCampaigns = function (productCampaigns, hideCampaignDateFor) {
    if (!!productCampaigns && productCampaigns.length > 0) {
        var hideFor_1 = new Array();
        !!hideCampaignDateFor &&
            hideCampaignDateFor.forEach(function (id) {
                hideFor_1.push(getCampaignTypeNameFromId(id));
            });
        return productCampaigns.some(function (value) { return hideFor_1.includes(value); });
    }
    else
        return false;
};
var ProductCampaignDate = function (_a) {
    var isInCampaign = _a.isInCampaign, isUtf = _a.isUtf, bestBeforeDate = _a.bestBeforeDate, closestCampaignEndDate = _a.closestCampaignEndDate, isProductIncludedInNewsCampaign = _a.isProductIncludedInNewsCampaign, productCampaignDescriptions = _a.productCampaignDescriptions, hideCampaignDateFor = _a.hideCampaignDateFor;
    return (React.createElement(LoginContainer, null, function (_a) {
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn &&
            isInCampaign &&
            !hideDatesForCampaigns(productCampaignDescriptions, hideCampaignDateFor) &&
            !isProductIncludedInNewsCampaign && (React.createElement("div", { className: styles.alert }, isUtf
            ? bestBeforeDate && translate('/ProductListing/BestBefore') + " " + bestBeforeDate
            : translate('/ProductListing/CampaignEnds') + " " + closestCampaignEndDate));
    }));
};
export default connect(function (state) { return ({
    hideCampaignDateFor: state.appShellData.hideCampaignDateFor,
}); })(ProductCampaignDate);
