var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import classnames from 'classnames';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import React, { useEffect, useRef } from 'react';
import PropertyFor from 'Shared/PropertyFor';
import * as styles from '../base.scss';
import connect from 'Shared/connect';
import getMenuIcon from './action-creators';
import NewsBanner from 'SiteLayout/Header/FadeHeader/Components/QuickSearch/NewsBanner';
var CategoryPageHeader = function (props) {
    var _a;
    var ref = useRef(null);
    var subCategories = !!props.categories &&
        !!(props.categories.length > 0) &&
        __spread(props.categories).sort(function (a, b) { return a.displayName.localeCompare(b.displayName, 'sv-SE'); });
    var newsBannerBlocks = props.newsBannerArea;
    var newsBannerContent = !!newsBannerBlocks && !!newsBannerBlocks.items && !!newsBannerBlocks.items[0] && newsBannerBlocks.items[0];
    useEffect(function () {
        var el = ref.current;
        el.scrollLeft = 0;
    }, [props]);
    return (React.createElement("div", { className: classnames(styles.categoryHeader, (_a = {}, _a[styles.withBanner] = !!newsBannerContent, _a)) },
        !!newsBannerContent &&
            React.createElement("div", { className: styles.newsBanner },
                React.createElement(NewsBanner, __assign({}, newsBannerContent))),
        React.createElement("div", { className: styles.inner },
            !!props.breadcrumbs && props.breadcrumbs.length > 2 && (React.createElement("div", { className: styles.categoryBreadcrumbsWrap },
                React.createElement("ul", { className: classnames(styles.categoryList, styles.breadcrumbsList) }, props.breadcrumbs.slice(1, props.breadcrumbs.length - 1).map(function (_a, i) {
                    var url = _a.url, text = _a.text;
                    return (React.createElement("li", { key: text },
                        React.createElement("a", { className: styles.link, href: url }, text),
                        React.createElement(MyMenigoIcon, { name: "arrow" })));
                })))),
            React.createElement(PropertyFor, { className: styles.categoryHeaderName, tagName: "h1", property: props.headerProperty }),
            React.createElement("ul", { ref: ref, className: styles.categoryList }, !!subCategories &&
                subCategories.map(function (category, i) { return (React.createElement("li", { key: category.code },
                    React.createElement("a", { className: styles.button, "data-ticket": category.ticket, href: category.url },
                        !!getMenuIcon(category, props.categoryIcons) && (React.createElement(MyMenigoIcon, { name: getMenuIcon(category, props.categoryIcons) })),
                        React.createElement("span", null, category.displayName)))); })))));
};
export default connect(function (state) { return ({
    categoryIcons: state.appShellData.categoryIcons,
}); })(CategoryPageHeader);
