import React from 'react';
import propertyValueFor from 'Shared/property-value-for';
import * as styles from './base.scss';
var SocialMediaFooterBlock = function (props) {
    return (!!props.block.icon && !!props.block.icon &&
        React.createElement("div", { className: styles.base },
            React.createElement("a", { href: propertyValueFor(props.block.url), target: "_blank" },
                React.createElement("img", { src: propertyValueFor(props.block.icon) }))));
};
export default SocialMediaFooterBlock;
