var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from '../base.scss';
import { Input } from 'Shared/Fields';
var CustomerInfo = /** @class */ (function (_super) {
    __extends(CustomerInfo, _super);
    function CustomerInfo(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            email: _this.props.email,
        };
        return _this;
    }
    CustomerInfo.prototype.render = function () {
        var _this = this;
        var emailUpdate = this.props.emailUpdate;
        return (React.createElement("div", { className: styles.customerInfoContainer },
            React.createElement("h3", null, translate('/mymenigo/returnorder/customerdetails')),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement("p", null,
                        translate('/mymenigo/returnorder/customerid'),
                        ":")),
                React.createElement("div", null,
                    React.createElement("p", null, this.props.customerId))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement("p", null,
                        translate('/mymenigo/returnorder/customerName'),
                        ":")),
                React.createElement("div", null,
                    React.createElement("p", { className: styles.customerInfo }, this.props.customerName))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement("p", { className: styles.labelForProp },
                        translate('/mymenigo/returnorder/email'),
                        ":")),
                React.createElement("div", null,
                    React.createElement(Input, { type: "email", className: styles.customerInput, required: true, requiredValidationMessage: translate('/MyMenigo/Accounts/RequiredField'), defaultValue: this.props.email, ref: function (el) { return (_this.emailInputRef = el); }, onChange: function (e) { return emailUpdate(e.currentTarget.value); } })))));
    };
    return CustomerInfo;
}(React.Component));
export default CustomerInfo;
