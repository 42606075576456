var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DIVIDE, DIVIDE_DESKTOP } from 'Shared/keyboard';
import { getStepSize } from 'Shared/Quantity/utils';
import MobileProduct from './Product/Mobile';
import DesktopProduct from './Product/Desktop';
import { SMALL } from 'Shared/breakpoints';
import * as styles from './base.scss';
import classnames from 'classnames';
import ProductStateHandling from 'Shared/Product/ProductStateHandling';
import { getAddToCartExperiment } from 'Shared/ProductComponents/ProductCardContainer';
function updateForKeys(props, diffProps, keys) {
    return keys.some(function (key) { return props[key] !== diffProps[key]; });
}
var diffKeys = [
    'isActive',
    'checked',
    'disabled',
    'currentBreakpoint',
    'quantity',
    'cartItemQuantity',
    'isLocked',
];
var ListItem = /** @class */ (function (_super) {
    __extends(ListItem, _super);
    function ListItem(props) {
        var _this = _super.call(this, props) || this;
        _this.updateQuantity = function (quantity) {
            if (_this.props.isPantry) {
                _this.props.updateQuantity(_this.props.rowIndex, quantity);
            }
            else {
                return _this.props.addToCart(_this.props.selectedVariant.code, quantity, _this.props.selectedVariant.unitShortName, _this.props.selectedVariant.ticket, 'FavoriteList', getAddToCartExperiment(_this.props.selectedVariant, _this.props.product));
            }
            return Promise.resolve();
        };
        _this.onFocusQuantity = function () {
            _this.props.selectElement(_this.props.rowIndex, 'quantity');
        };
        _this.keyDown = function (e) {
            switch (e.keyCode) {
                case DIVIDE:
                case DIVIDE_DESKTOP:
                    if (e.shiftKey || e.keyCode === DIVIDE_DESKTOP) {
                        e.preventDefault();
                        var setVariant = _this.props.selectedVariant === _this.props.variants[0] && _this.props.variants.length > 1
                            ? _this.props.variants[1]
                            : _this.props.variants[0];
                        _this.updateUnit(setVariant);
                    }
                    break;
                default:
                    break;
            }
            if (!!_this.props.onKeyDown) {
                return _this.props.onKeyDown(e);
            }
        };
        _this.setWrapperRef = function (node) {
            _this.wrapperRef = node;
        };
        _this.handleClickOutside = function (event) {
            if (_this.wrapperRef && !_this.wrapperRef.contains(event.target)) {
                _this.setState({ isActive: false });
            }
            else {
                _this.setState({ isActive: true });
            }
        };
        _this.state = {
            currentOrdinal: props.ordinal,
            isActive: props.isActive,
        };
        _this.updateUnit = _this.updateUnit.bind(_this);
        _this.setActive = _this.setActive.bind(_this);
        _this.setWrapperRef = _this.setWrapperRef.bind(_this);
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        return _this;
    }
    ListItem.prototype.componentWillMount = function () {
        document.addEventListener('mousedown', this.handleClickOutside);
    };
    ListItem.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var nextPropsSelectedVariant = !!nextProps.selectedVariant ? nextProps.selectedVariant : nextProps.variants[0];
        return (this.props.isActive ||
            updateForKeys(this.props, nextProps, diffKeys) ||
            ((nextPropsSelectedVariant &&
                this.props.selectedVariant &&
                (this.props.selectedVariant.price === null && nextPropsSelectedVariant.price !== null)) ||
                (!this.props.isPantry &&
                    this.props.selectedVariant.availability === null &&
                    nextPropsSelectedVariant.availability !== null)));
    };
    ListItem.prototype.componentWillReceiveProps = function (newProps) {
        if (newProps.ordinal !== this.props.ordinal && !isNaN(newProps.ordinal)) {
            this.setState({ currentOrdinal: newProps.ordinal });
        }
    };
    ListItem.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.checked && !this.props.checked) {
            this.setState({ isActive: false });
        }
    };
    ListItem.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClickOutside);
        // Since user may scroll so the items is removed and no blur happens.
        if (this.props.ordinal !== this.state.currentOrdinal) {
            this.props.updateOrdinal(this.props.rowIndex, this.state.currentOrdinal);
        }
    };
    ListItem.prototype.updateUnit = function (variant) {
        this.props.selectElement(this.props.rowIndex, 'quantity');
        if (!!variant) {
            this.props.selectVariant(variant.code);
        }
        return Promise.resolve();
    };
    ListItem.prototype.showLocked = function () {
        if (this.props.isMaster) {
            return false;
        }
        return this.props.isLocked;
    };
    ListItem.prototype.showDelete = function () {
        if (this.props.isMaster) {
            return true;
        }
        return !this.props.isLocked;
    };
    ListItem.prototype.getStep = function () {
        var _a = this.props, isPantry = _a.isPantry, variants = _a.variants, selectedVariant = _a.selectedVariant;
        if (isPantry) {
            return 0.01;
        }
        return getStepSize(selectedVariant);
    };
    ListItem.prototype.setActive = function () {
        // TODO: Verify usage for locked item in the list, remove if not used, pass down to ProductComponent if used
        this.props.selectIndex(this.props.rowIndex);
    };
    ListItem.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, disabled = _b.disabled, isPantry = _b.isPantry, inventoryStatus = _b.inventoryStatus, product = _b.product, selectedVariant = _b.selectedVariant, quantity = _b.quantity, isActive = _b.isActive, focusItem = _b.focusItem, cartItemQuantity = _b.cartItemQuantity;
        var isMobile = this.props.currentBreakpoint <= SMALL;
        var ProductComponent = isMobile ? MobileProduct : DesktopProduct;
        var maxQuantity = isPantry ? 999999 : 999;
        return (React.createElement("div", { className: classnames(styles.row, (_a = {},
                _a[styles.highlighted] = this.props.selectedIndex !== undefined ? this.props.isActive : this.state.isActive,
                _a), "ui-test-list-item-" + this.props.code, isMobile && styles.flexWrap), ref: this.setWrapperRef },
            React.createElement(ProductComponent, { cartItemQuantity: cartItemQuantity, focusItem: focusItem, getStep: function () { return _this.getStep(); }, isActive: isActive, isPantry: isPantry, inventoryStatus: inventoryStatus, onFocusQuantity: this.onFocusQuantity, product: product, quantity: quantity, selectedVariant: selectedVariant, updateQuantity: this.updateQuantity, disabled: disabled, isRecommendations: this.props.isRecommendations, rowIndex: this.props.rowIndex, passedUpdateOrdinal: this.props.updateOrdinal, passedOrdinal: this.props.ordinal, selectElement: this.props.selectElement, passedRemoveListItem: this.props.removeListItem, code: product.selectedVariant && product.selectedVariant.code, listId: this.props.listId, showDelete: this.showDelete(), checked: this.props.checked, toggleSelected: this.props.toggleSelected, selectedIndex: this.props.selectedIndex, setSelectedIndex: this.props.setSelectedIndex, maxQuantity: maxQuantity, isLocked: this.showLocked(), setActive: this.setActive })));
    };
    return ListItem;
}(React.Component));
var ListItemWithProductHandling = function (props) { return (React.createElement(ProductStateHandling, { variants: props.variants }, function (renderProps) { return React.createElement(ListItem, __assign({}, props, renderProps)); })); };
export default ListItemWithProductHandling;
