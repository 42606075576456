var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactDOM from 'react-dom';
import Main, { Part, Form } from 'Shared/PageLayout';
import FullCart from './../../FullCart';
import Summary from './../../Summary';
import CompletePurchase from './../../CompletePurchase';
import { completePurchase, setOrderNoteDetails, removeAllCartItems, toggleCompletePurchaseModal, } from './../../action-creators';
import Price from 'Shared/Price';
import PropertyFor from 'Shared/PropertyFor';
import DeliveryDates from 'Shared/DeliveryDates';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
import connect from 'Shared/connect';
import { updateUserSettings } from 'Shared/UserSettings/action-creators';
import { translate } from 'Shared/translate';
import { replaceState } from 'Shared/history';
import Button from 'Shared/Button';
import DropDown from 'Shared/DropDown';
import { Input } from 'Shared/Fields';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { ENTER } from 'Shared/keyboard';
import * as styles from '././base.scss';
import Modal, { ModalType } from 'Shared/Modal';
import DiscountInfo from './DiscountInfo';
import CurrentOrders from './CurrentOrders';
import { MEDIUM } from 'Shared/breakpoints';
import { postJson } from 'Shared/server';
import Backbutton from '../../../App/Backbutton';
import { checkoutPageUrl } from 'Shared/known-urls';
import Overlay from 'Shared/Overlay';
import Dialog, { DialogType } from 'Shared/Dialog';
import CompletePurchaseModal from './CompletePurchaseModal';
import CheckoutModal from 'Shared/Modal/checkoutModal';
import shallowEquals from 'Shared/shallow-equals';
import MyMenigoIcon from '../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var CheckoutPage = /** @class */ (function (_super) {
    __extends(CheckoutPage, _super);
    function CheckoutPage(props) {
        var _this = _super.call(this, props) || this;
        _this.hasNewWarningsToShow = function (prevProps) {
            if ((_this.props.campaignWarnings || []).length > 0 && !shallowEquals(_this.props.campaignWarnings, prevProps.campaignWarnings)
                || !!_this.props.sharedDeliveryWarnings && _this.props.sharedDeliveryWarnings !== prevProps.sharedDeliveryWarnings
                || (_this.props.exceedLimitWarnings || []).length > 0 && !shallowEquals(_this.props.exceedLimitWarnings, prevProps.exceedLimitWarnings)) {
                return true;
            }
            return false;
        };
        _this.hasWarningsToShow = function () {
            if (!!_this.props.campaignWarnings && _this.props.campaignWarnings.length > 0
                || !!_this.props.sharedDeliveryWarnings
                || !!_this.props.exceedLimitWarnings && _this.props.exceedLimitWarnings.length > 0
                || !_this.props.userIsVerified)
                return true;
            else
                return false;
        };
        _this.onEnter = function (e) {
            if (e.which === ENTER && e.target !== ReactDOM.findDOMNode(_this.completePurchase.feedbackButton)) {
                e.preventDefault();
            }
        };
        _this.onSubmit = function (e) {
            e.preventDefault();
            if (!_this.state.completingPurchase) {
                _this.setState({ completingPurchase: true });
                if (!_this.IsValidOrderNumber()) {
                    _this.setState({ completingPurchase: false });
                    return;
                }
                if (!_this.isCustomerNameAndTelephoneValid()) {
                    _this.setState({ completingPurchase: false });
                    return;
                }
                _this.isUniqueOrderNumber().then(function () {
                    var cartItems = {};
                    _this.props.checkoutcart.items.forEach(function (i) {
                        return (cartItems[i.lineItem.code] = {
                            isUpdateAction: false,
                            newQuantity: i.lineItem.quantity,
                            newUnitShortName: i.lineItem.selectedVariant.unitShortName,
                            previousQuantity: 0,
                            previousUnitShortName: i.lineItem.selectedVariant.unitShortName,
                            deliveryDate: null,
                            rowNumber: i.lineItem.rowNumber,
                            newDiscountType: i.lineItem.discountType,
                            newDiscountValue: i.lineItem.discountValue,
                            previousDiscountType: 'percent',
                            previousDiscountValue: 0,
                            newIsProductSample: i.lineItem.isProductSample,
                            previousIsProductSample: false,
                            deleteRowOverride: false,
                            newIgnoreAvailability: i.lineItem.ignoreAvailability,
                            previousIgnoreAvailability: !i.lineItem.ignoreAvailability,
                        });
                    });
                    var promise = _this.props
                        .completePurchase({
                        form: {
                            cart: {
                                items: cartItems,
                                orderNoteMarking: _this.state.currentMarkingText,
                                orderNoteOrderNo: _this.state.currentOrderNumber,
                                location: ''
                            },
                        },
                    }, null, _this.state.hasAddedEmvInCart)
                        .then(function (n) {
                        if (!!n && n.stopDate !== (null || undefined)) {
                            _this.props.setFeedback({
                                text: translate('/Checkout/StopTimePassed'),
                                type: 'warning',
                            });
                            _this.setState({ completingPurchase: false });
                        }
                        if (!!n && n.verifyOrderErrorResultViewModel.length > 0) {
                            _this.setState({ completingPurchase: false }, function () {
                                _this.props.toggleCompletePurchaseModal(true);
                            });
                        }
                    })
                        .catch(function (e) { return _this.onError(e); });
                    _this.completePurchase.onCompletePurchase(promise).catch(function (e) { return _this.onError(e); });
                });
            }
        };
        _this.setMarkText = function (event) {
            _this.setState({
                currentMarkingText: event.target.value,
            }, function () {
                _this.isCustomerNameAndTelephoneValid();
            });
        };
        _this.setOrderNumber = function (event) {
            var newOrderNumber = event.target.value;
            _this.setState({
                currentOrderNumber: newOrderNumber,
                currentOrderNumberIsUnique: true,
            });
            _this.IsValidOrderNumber();
        };
        _this.onShowDiscounts = function () {
            _this.setState({ isDiscountInfoVisible: true });
        };
        _this.onHideDiscounts = function () {
            _this.setState({ isDiscountInfoVisible: false });
        };
        _this.showCurrentOrders = function () {
            _this.setState({ isCurrentOrdersVisible: true });
        };
        _this.hideCurrentOrders = function () {
            _this.setState({ isCurrentOrdersVisible: false });
        };
        _this.showWarnings = function () {
            _this.setState({ isWarningsVisible: true });
        };
        _this.hideWarnings = function () {
            _this.setState({ isWarningsVisible: false });
        };
        _this.showErrors = function (errors) {
            if (!!errors && errors.length > 0) {
                _this.props.setFeedback({
                    text: errors[0],
                    type: 'error',
                });
            }
        };
        _this.closeCompletePurchaseModal = function () {
            _this.props.toggleCompletePurchaseModal(false);
        };
        _this.handleClearCartClose = function () {
            _this.setState({ isClearCartVisible: false });
        };
        _this.clearCart = function () {
            return _this.props
                .removeAllCartItems()
                .then(function () { return _this.reloadPage({ hideSpinner: true }); })
                .catch(function (e) { return Promise.reject(null); });
        };
        _this.isEmvProductVisible = function () {
            if (!_this.props.checkoutcart && !_this.props.checkoutcart.items && _this.props.checkoutcart.items.length < 1) {
                return false;
            }
            return !!_this.props.checkoutcart.items.some(function (x) { return !!x.lineItem.emvProduct; });
        };
        _this.state = {
            showMobileDeliveryDetails: false,
            sortBy: _this.props.sortOptions ? _this.props.sortOptions.value : '',
            currentOrderNumber: '',
            currentMarkingText: '',
            completingPurchase: false,
            currentOrderNumberIsValid: true,
            currentOrderNumberIsUnique: _this.props.orderNumberIsUnique,
            isDiscountInfoVisible: false,
            isCurrentOrdersVisible: false,
            isWarningsVisible: false,
            isClearCartVisible: false,
            customerNameAndTelephoneIsValid: true,
            hasAddedEmvInCart: false,
            totalCarbonFootprint: '',
            carbonFootprintText: '',
        };
        if (!!_this.props.validationResult && !_this.props.validationResult.success) {
            _this.props.setFeedback({
                text: translate('/Checkout/ValidationError'),
                type: 'error',
                overrideTimeout: true,
            });
        }
        _this.currentReload = Promise.resolve(null);
        return _this;
    }
    CheckoutPage.prototype.componentDidUpdate = function (prevProps, nextState) {
        if (this.hasNewWarningsToShow(prevProps)) {
            this.showWarnings();
        }
        if (prevProps.checkoutcart.items != this.props.checkoutcart.items) {
            this.setTotalCarbonFootprint();
        }
    };
    CheckoutPage.prototype.componentDidMount = function () {
        if (this.hasWarningsToShow()) {
            this.showWarnings();
        }
        this.showErrors(this.props.errors);
        this.setTotalCarbonFootprint();
    };
    CheckoutPage.prototype.componentWillReceiveProps = function (nextProps, nextState) {
        if (this.props.checkoutcart !== nextProps.checkoutcart && !this.state.completingPurchase) {
            this.setState({
                showMobileDeliveryDetails: nextState.showMobileDeliveryDetails,
                sortBy: nextProps.sortOptions ? nextProps.sortOptions.value : '',
            });
        }
        if (this.props.errors !== nextProps.errors) {
            this.showErrors(nextProps.errors);
        }
    };
    CheckoutPage.prototype.componentWillUnmount = function () {
        this.onSortChange('input_order');
        if (this.formElement && this.invalidListener) {
            this.formElement.removeEventListener('invalid', this.invalidListener, true);
            this.formElement = null;
            this.invalidListener = null;
        }
    };
    CheckoutPage.prototype.onError = function (e) {
        console.error('Checkout', e);
        this.props.setFeedback({
            text: translate('/Checkout/ExceptionWhileCheckingOut'),
            type: 'error',
            overrideTimeout: true,
        });
        replaceState('/');
    };
    CheckoutPage.prototype.hasNonStockableProducts = function () {
        return this.props.cart.items.some(function (i) { return !i.product.isStockable; });
    };
    CheckoutPage.prototype.onSortChange = function (sortBy) {
        var _this = this;
        this.props.updateUserSettings({ currentSelectededSorting: sortBy });
        this.setState({
            sortBy: sortBy,
            hasAddedEmvInCart: false
        }, function () {
            _this.reloadPage();
        });
    };
    CheckoutPage.prototype.toggleMobileDelivery = function () {
        this.setState({
            showMobileDeliveryDetails: !this.state.showMobileDeliveryDetails,
        });
    };
    CheckoutPage.prototype.reloadPage = function (loadOptions) {
        if (loadOptions === void 0) { loadOptions = undefined; }
        if (!!this.state.completingPurchase) {
            return;
        }
        var queryParams = this.props.queryParams || {};
        var url = {
            path: window.location.pathname,
            query: Object.assign({}, queryParams, {
                sortBy: this.state.sortBy,
            }),
        };
        this.currentReload = this.currentReload.then(function () {
            replaceState(url, loadOptions);
        });
        return this.currentReload;
    };
    CheckoutPage.prototype.isUniqueOrderNumber = function () {
        var _this = this;
        var orderNumber = this.state.currentOrderNumber;
        if ((orderNumber || '').trim() === '') {
            return Promise.resolve(null);
        }
        return postJson(checkoutPageUrl() + '/IsOrderNumberUnique', { orderNumber: orderNumber }).then(function (result) {
            if (!result) {
                _this.setState({
                    completingPurchase: false,
                    currentOrderNumberIsUnique: result,
                });
                return Promise.reject(null);
            }
            return Promise.resolve(null);
        });
    };
    CheckoutPage.prototype.IsValidOrderNumber = function () {
        var orderno = this.state.currentOrderNumber;
        if ((orderno || '').trim() === '') {
            this.setState({
                currentOrderNumberIsValid: true,
                currentOrderNumberIsUnique: true,
            });
            return true;
        }
        var pattern = /^[A-Za-z0-9åäöÅÄÖ-]+$/g;
        var result = pattern.test(orderno);
        this.setState({ currentOrderNumberIsValid: result });
        return result;
    };
    CheckoutPage.prototype.isCustomerNameAndTelephoneValid = function () {
        var customNameAndTelephone = this.state.currentMarkingText;
        if (this.props.isSupplierSalesman && customNameAndTelephone.trim() === '') {
            this.setState({
                customerNameAndTelephoneIsValid: false,
            });
            return false;
        }
        else {
            this.setState({
                customerNameAndTelephoneIsValid: true,
            });
            return true;
        }
    };
    CheckoutPage.prototype.onClearCartButtonClick = function () {
        this.setState({ isClearCartVisible: true });
    };
    CheckoutPage.prototype.toggleHasAddedEmvInCart = function (hasAddedEmvInCart) {
        this.setState({ hasAddedEmvInCart: hasAddedEmvInCart });
    };
    CheckoutPage.prototype.setTotalCarbonFootprint = function () {
        var total = this.props.checkoutcart.items.reduce(function (accumulator, item) {
            var carbonFootPrint = item.product.carbonFootPrint;
            var quantity = item.lineItem.quantity;
            if (!carbonFootPrint) {
                return accumulator;
            }
            if (item.lineItem.selectedVariant.unitShortName === 'KGD') {
                accumulator += quantity * carbonFootPrint;
            }
            else if (['FRP', 'ST'].includes(item.lineItem.selectedVariant.unitShortName)) {
                accumulator += quantity * carbonFootPrint * item.lineItem.selectedVariant.packagingWeight;
            }
            return accumulator;
        }, 0);
        var roundedTotal = total.toFixed(1).toString();
        this.setState({ totalCarbonFootprint: roundedTotal });
    };
    CheckoutPage.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var clearCartWarningModal = (React.createElement("div", null,
            React.createElement(Modal, { title: translate('/Cart/ClearCartWarning'), isOpen: this.state.isClearCartVisible, onClose: this.handleClearCartClose, reserveSpaceForCloseButton: true },
                React.createElement("p", null, translate('/Cart/WarningMessage')),
                React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: this.clearCart, onCancel: this.handleClearCartClose, onStatusWasFulfilled: this.handleClearCartClose }))));
        var isMobile = this.props.currentBreakpoint < MEDIUM;
        if (!this.props.checkoutcart) {
            return null;
        }
        var orderFormInputs = (React.createElement("div", { className: styles.inputbase },
            React.createElement("label", null,
                React.createElement("span", null, translate('/Checkout/Notes/OrderNumber')),
                React.createElement(Input, { value: this.state.currentOrderNumber, type: "text", onChange: this.setOrderNumber, className: styles.markinglbl, maxLength: 14 }),
                !this.state.currentOrderNumberIsValid && (React.createElement("span", { className: styles.requiredField }, translate('/Checkout/InvalidFormatOrderNumber'))),
                !this.state.currentOrderNumberIsUnique && (React.createElement("span", { className: styles.requiredField }, translate('/Checkout/InvalidUniqnessOrderNumber')))),
            React.createElement("label", null,
                this.props.isSupplierSalesman ? (React.createElement("span", null, translate('/Checkout/Notes/CustomerNameAndTelephone'))) : (React.createElement("span", null, translate('/Checkout/Notes/Marking'))),
                React.createElement(Input, { value: this.state.currentMarkingText, type: "text", onChange: this.setMarkText, className: styles.markinglbl, maxLength: 30 }),
                this.props.isSupplierSalesman && !this.state.customerNameAndTelephoneIsValid && (React.createElement("span", { className: styles.requiredField }, translate('/Checkout/CustomerNameAndTelephoneIsRequired'))))));
        var completePurchase = (React.createElement(Part, null,
            React.createElement(CompletePurchase, { ref: function (el) { return (_this.completePurchase = el); }, isPunchout: this.props.isPunchout, checkoutPage: this.props.page, isLoading: this.props.cart.isLoading, totalPrice: this.props.isPunchout ? this.props.checkoutcart.subTotal.current : this.props.totalPrice, serverValidationErrors: this.props.validationResult, currentBreakpoint: this.props.currentBreakpoint, disabled: this.props.isLoading ||
                    (this.props.cart && this.props.cart.isLoading) ||
                    this.props.headerViewModel.selectedDelivery === null ||
                    !!this.state.completingPurchase ||
                    (this.props.errors && this.props.errors.length > 0) ||
                    !this.state.currentOrderNumberIsValid ||
                    !this.state.currentOrderNumberIsUnique ||
                    !this.props.userIsVerified })));
        var orderSummary = (React.createElement(Summary, { isLoading: this.props.isLoading || !!this.state.completingPurchase, totalPrice: this.props.isPunchout ? this.props.checkoutcart.subTotal.current : this.props.totalPrice, items: this.props.checkoutcart.items, alcoholTax: !!this.props.orderResponse && this.props.orderResponse.alcoholTax, selectedDelivery: this.props.headerViewModel.selectedDelivery }));
        return !this.props.checkoutcart || !this.props.checkoutcart.items || this.props.checkoutcart.items.length === 0 ? (React.createElement(Main, { appearance: "narrow", className: classnames(styles.emptyCartCheckout, (_a = {}, _a[styles.mobileTop] = isMobile, _a)) },
            IS_APP && React.createElement(Backbutton, { title: translate('/App/Checkout/Title') }),
            React.createElement("h1", null, translate('/Checkout/Heading')),
            React.createElement(Part, null,
                React.createElement("p", null, translate('/Cart/EmptyCart'))))) : (React.createElement("div", null,
            React.createElement(Overlay, { overlayClassName: styles.overlayCheckout, enabled: !!this.state.completingPurchase }, !!this.state.completingPurchase && (React.createElement("div", { className: classnames(styles.generalSpinner, styles.overlayCheckoutSpinner) },
                React.createElement(Spinner, null)))),
            React.createElement(Main, { appearance: "narrow", className: classnames((_b = {}, _b[styles.mobileTop] = isMobile, _b)) },
                IS_APP && React.createElement(Backbutton, { title: translate('/App/Checkout/Title') }),
                React.createElement(Form, { id: "checkout-form", appearance: "default", className: styles.topForm, onKeyPress: this.onEnter, onSubmit: this.onSubmit },
                    React.createElement("h1", null, translate('/Checkout/Heading')),
                    !isMobile && React.createElement("span", { className: styles.subHeading }, translate('/Checkout/SubHeading')),
                    this.hasNonStockableProducts() && (React.createElement("span", { className: classnames(styles.subHeading, styles.subHeadingObserve) }, translate('/Checkout/NonStockableProducts'))),
                    React.createElement("div", { className: styles.checkout__contentWrapper },
                        React.createElement("div", null,
                            !isMobile && (React.createElement("div", { className: styles.topWrapper },
                                React.createElement("div", null,
                                    React.createElement(Button, { onClick: function () { return window.print(); }, appearance: 'secondary' }, translate('/Shared/Print')),
                                    React.createElement(Button, { onClick: function () { return _this.onClearCartButtonClick(); }, appearance: 'secondary' }, translate('/Cart/ClearCart')),
                                    clearCartWarningModal,
                                    !!this.props.sortOptions && !!this.props.sortOptions.options.length && (React.createElement("div", null,
                                        React.createElement(DropDown, { className: styles.right, options: this.props.sortOptions.options, value: this.props.sortOptions.value, onChange: function (sortBy) { return _this.onSortChange(sortBy); } })))),
                                !!this.isEmvProductVisible() && (React.createElement("div", null)))),
                            React.createElement("div", { className: styles.fullCartWrapper },
                                !!isMobile && (React.createElement("div", { className: styles.summary },
                                    React.createElement("div", { className: styles.row },
                                        React.createElement("b", { className: styles.left }, translate('/Checkout/OrderSummary')),
                                        React.createElement(Price, { className: styles.right, current: this.props.isPunchout ? this.props.checkoutcart.subTotal.current : this.props.totalPrice, size: "normal", currencySymbol: translate('/Price/CurrencySymbol'), isPending: this.props.isLoading })))),
                                React.createElement("div", null, this.props.checkoutcart &&
                                    React.createElement(FullCart, { cart: this.props.checkoutcart, hasAddedEmvInCart: this.state.hasAddedEmvInCart, toggleHasAddedEmvInCart: function (hasEmvAddedInCart) { return _this.toggleHasAddedEmvInCart(hasEmvAddedInCart); } }))),
                            React.createElement("div", { className: classnames(styles.deliveryAndOrderSummaryWrap, (_c = {},
                                    _c[styles.orderInfoMobile] = isMobile,
                                    _c[styles.closed] = isMobile && !this.state.showMobileDeliveryDetails,
                                    _c)) },
                                !!isMobile && React.createElement("div", { className: styles.orderinfo }, orderSummary),
                                !!isMobile && orderFormInputs,
                                !!isMobile && completePurchase)),
                        !isMobile && (React.createElement("div", { className: styles.content },
                            React.createElement("div", { className: styles.orderinfo },
                                orderFormInputs,
                                orderSummary),
                            React.createElement("div", { className: styles.deliveryAndOrderSummaryWrap }, (!isMobile || this.state.showMobileDeliveryDetails) && (React.createElement("div", { className: styles.deliveryAndOrderSummary },
                                React.createElement("div", { className: !isMobile ? styles.deliveryDatesWrap : styles.content },
                                    React.createElement(DeliveryDates, { hasWrapper: true, preselectedDeliveryDay: this.props.headerViewModel &&
                                            this.props.headerViewModel.selectedDelivery &&
                                            this.props.headerViewModel.selectedDelivery.departureDateAsString, deliveryDays: this.props.headerViewModel.deliveryDates, selectedDelivery: this.props.headerViewModel.selectedDelivery })),
                                React.createElement("div", { className: styles.orderSummaryWrap },
                                    React.createElement("div", { className: classnames(styles.content, styles.halfborder) },
                                        React.createElement("h2", { className: styles.summaryTitle }, translate('/Checkout/Summary')),
                                        !!this.props.currentOrdersViewModel &&
                                            !!this.props.currentOrdersViewModel.orders &&
                                            this.props.currentOrdersViewModel.orders.length > 0 &&
                                            !isMobile && (React.createElement("div", { className: styles.row },
                                            React.createElement(Button, { className: styles.left, appearance: "link", onClick: function () { return _this.showCurrentOrders(); } }, translate('/Cart/CurrentOrders')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "normal", current: this.props.currentOrdersViewModel.totalPurchase, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: styles.left }, translate('/Cart/CurrentCart')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "normal", current: this.props.isPunchout
                                                        ? this.props.checkoutcart.subTotal.current
                                                        : this.props.totalPrice, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading }))),
                                        !!this.props.orderResponse && !!this.props.orderResponse.currentRebatePercent && (React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: classnames(styles.left) },
                                                translate('/Cart/Discount'),
                                                " ",
                                                this.props.orderResponse.currentRebatePercent,
                                                " %"),
                                            React.createElement("div", { className: classnames(styles.right, styles.red) },
                                                React.createElement(Price, { size: "large", current: this.props.orderResponse.currentRebateAmount * -1, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        !!this.props.orderResponse && !!this.props.orderResponse.freightCost && (React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: classnames(styles.left) }, translate('/Cart/Freight')),
                                            React.createElement("div", { className: classnames(styles.right) },
                                                React.createElement(Price, { size: "large", current: this.props.orderResponse.freightCost, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading }))))),
                                    React.createElement("div", { className: classnames(styles.content, styles.halfborder) },
                                        !!this.props.orderResponse && !!this.props.orderResponse.totalCostForDelivery && (React.createElement("div", { className: classnames(styles.row, styles.bigger) },
                                            React.createElement("b", { className: styles.left }, translate('/Cart/ToPayForThisInvoice')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "large", current: this.props.orderResponse.totalCostForDelivery, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        !!this.props.orderResponse && !!this.props.orderResponse.alcoholTax && (React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: styles.left }, translate('/Cart/AlcoholTax')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "normal", current: this.props.orderResponse.alcoholTax, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        React.createElement("p", null, translate('/Cart/InvoiceDisclaimer'))),
                                    this.props.carbonFootprintTitle && this.props.carbonFootprintLinkUrl && this.props.carbonFootprintLinkText &&
                                        this.props.carbonFootprintText && (React.createElement("div", { className: classnames(styles.halfborder, styles.carbonFootprintBanner) },
                                        React.createElement("div", { className: classnames(styles.banner) },
                                            React.createElement("div", null,
                                                React.createElement("div", { className: classnames(styles.svg) },
                                                    React.createElement(MyMenigoIcon, { name: "carbonFootprint" }))),
                                            React.createElement("div", { className: styles.content },
                                                React.createElement("div", { className: classnames(styles.row) },
                                                    React.createElement("p", { className: classnames(styles.left) }, this.props.carbonFootprintTitle),
                                                    this.state.totalCarbonFootprint && (React.createElement("p", { className: classnames(styles.right) },
                                                        this.state.totalCarbonFootprint,
                                                        " kg"))),
                                                this.state.totalCarbonFootprint && (React.createElement("p", null,
                                                    this.props.carbonFootprintText.replace(/\[totalCarbonFootprint\]/, this.state.totalCarbonFootprint),
                                                    "\u00A0",
                                                    React.createElement("a", { target: 'blank', href: this.props.carbonFootprintLinkUrl }, this.props.carbonFootprintLinkText))))))),
                                    React.createElement("div", { className: styles.content },
                                        !!this.props.orderResponse && !!this.props.orderResponse.freightLimit && (React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: styles.left }, translate('/Cart/RemainingToFreeDelivery')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "large", current: this.props.orderResponse.freightLimit, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        !!this.props.orderResponse && !!this.props.orderResponse.amountUntilNextRebate && (React.createElement("div", null,
                                            React.createElement("div", { className: styles.row },
                                                React.createElement("b", { className: styles.left },
                                                    translate('/Cart/RemainingToNextDiscountLevel'),
                                                    ",",
                                                    ' ',
                                                    this.props.orderResponse.nextRebatePercent,
                                                    " %"),
                                                React.createElement("div", { className: styles.right },
                                                    React.createElement(Price, { size: "large", current: this.props.orderResponse.amountUntilNextRebate, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading }))))),
                                        !!this.props.orderResponse && this.props.discountLimits.length > 0 && (React.createElement("div", { className: styles.row },
                                            React.createElement("a", { href: '#', onClick: function () { return _this.onShowDiscounts(); } }, translate('/Cart/ShowDiscounts')))),
                                        !!this.props.orderResponse && !!this.props.orderResponse.totalRebateFounding && (React.createElement("div", { className: styles.row },
                                            React.createElement("b", { className: styles.left }, translate('/Cart/DeliveryTotal')),
                                            React.createElement("div", { className: styles.right },
                                                React.createElement(Price, { size: "large", current: this.props.orderResponse.totalRebateFounding, currencySymbol: this.props.checkoutcart.subTotal.currencySymbol, priceUnit: this.props.checkoutcart.subTotal.priceUnit, isPending: this.props.isLoading })))),
                                        React.createElement("p", null, translate('/Cart/PriceDisclaimer'))))))),
                            completePurchase))),
                    React.createElement(Modal, { isOpen: this.props.showCompletePurchaseModal, onClose: this.closeCompletePurchaseModal, title: translate('/Shared/Observ'), type: ModalType.Warning },
                        React.createElement(CompletePurchaseModal, { items: this.props.itemsWithChanges, onCancel: this.closeCompletePurchaseModal }))),
                React.createElement(PropertyFor, { property: this.props.page.footer }),
                React.createElement(Modal, { isOpen: this.state.isDiscountInfoVisible, onClose: this.onHideDiscounts, title: translate('/Cart/DiscountInfo') },
                    React.createElement(DiscountInfo, { discountLimits: this.props.discountLimits })),
                React.createElement(Modal, { isOpen: this.state.isCurrentOrdersVisible, onClose: this.hideCurrentOrders, title: translate('/Cart/CurrentOrders') },
                    React.createElement(CurrentOrders, { currentOrders: this.props.currentOrdersViewModel.orders, currentBreakpoint: this.props.currentBreakpoint })),
                React.createElement(CheckoutModal, { isOpen: this.state.isWarningsVisible, onClose: this.hideWarnings, title: translate('/Checkout/CheckoutWarningTitle'), type: ModalType.Warning },
                    !!!this.props.userIsVerified && (React.createElement("div", { className: styles.whiteBackground },
                        React.createElement("strong", { className: styles.userNotVerifiedMessage }, translate("/Checkout/ValidationErrorUser")))),
                    !!this.props.sharedDeliveryWarnings && (React.createElement("div", null,
                        React.createElement("b", { className: styles.childTitle }, translate('/Checkout/SharedDeliveryWarningHeader')),
                        React.createElement("div", { className: styles.whiteBackground }, translate('/Checkout/SharedDeliveryWarning')))),
                    !!this.props.errors && this.props.errors.length > 0 && (React.createElement("div", null,
                        React.createElement("b", { className: styles.childTitle }, translate('/Checkout/ExceedUtfWarningHeader')),
                        React.createElement("div", { className: styles.whiteBackground },
                            React.createElement("ul", null, this.props.errors.map(function (w, i) { return (React.createElement("li", { key: "utfwarning_" + i }, w)); }))))),
                    !!this.props.exceedLimitWarnings && this.props.exceedLimitWarnings.length > 0 && (React.createElement("div", { className: styles.limitWarning },
                        React.createElement("b", { className: styles.childTitle }, translate('/Checkout/LimitHeader')),
                        React.createElement("div", { className: styles.whiteBackground },
                            React.createElement("ul", null, this.props.exceedLimitWarnings.map(function (w, i) { return (React.createElement("li", { key: "limitwarning_" + i }, w)); }))))),
                    !!this.props.campaignWarnings && this.props.campaignWarnings.length > 0 && (React.createElement("div", { className: styles.limitWarning },
                        React.createElement("b", { className: styles.childTitle }, translate('/Checkout/CampaignWarningHeader')),
                        React.createElement("div", { className: styles.whiteBackground },
                            React.createElement("ul", null, this.props.campaignWarnings.map(function (w, i) { return (React.createElement("li", { key: "campaignwarning_" + i }, w)); }))))),
                    React.createElement("div", { className: styles.alignButton },
                        React.createElement("button", { onClick: this.hideWarnings }, translate('/Shared/WarningOk')))))));
    };
    return CheckoutPage;
}(React.PureComponent));
export default connect(function (state) { return ({
    cart: state.cart,
    checkoutcart: state.checkout.cart,
    exceedLimitWarnings: state.checkout.exceedLimitWarnings,
    sharedDeliveryWarnings: state.checkout.sharedDeliveryWarnings,
    campaignWarnings: state.checkout.campaignWarnings,
    errors: state.checkout.errors,
    headerViewModel: state.appShellData.header,
    isLoading: state.cart.isLoading,
    currentBreakpoint: state.currentBreakpoint,
    userSettings: state.currentUser.userSettings,
    isPunchout: state.currentUser.punchOutSessionActive,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    showCompletePurchaseModal: state.currentPage.showCompletePurchaseModal
        ? state.currentPage.showCompletePurchaseModal
        : false,
    itemsWithChanges: state.currentPage.verifyOrderErrorResultViewModel,
}); }, function (dispatch) { return ({
    completePurchase: function (model, isStoreDevice, hasAddedEmvInCart) {
        return dispatch(completePurchase(model, isStoreDevice, hasAddedEmvInCart));
    },
    setOrderNoteDetails: function (cart) {
        return dispatch(setOrderNoteDetails(cart));
    },
    removeAllCartItems: function () {
        return dispatch(removeAllCartItems('CheckoutPage'));
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    updateUserSettings: function (settings) {
        return dispatch(updateUserSettings(settings, true));
    },
    toggleCompletePurchaseModal: function (showCompletePurchaseModal) {
        return dispatch(toggleCompletePurchaseModal(showCompletePurchaseModal));
    },
}); })(CheckoutPage);
