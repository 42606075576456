import { getJson } from 'Shared/server';
export default function loadMore(pageSize, skip) {
    return function (dispatch, getState) {
        return getJson('/deliveryblockaction/loadmoredeliveries?pageSize=' + pageSize + "&skip=" + skip)
            .then(function (res) {
            return res;
        });
    };
}
export function getProductImage(articleNumber) {
    return function (dispatch, getState) {
        if (!!sessionStorage.getItem(articleNumber))
            return Promise.resolve(sessionStorage.getItem(articleNumber));
        return getJson('/deliveryimage/?articleNumber=' + articleNumber)
            .then(function (res) {
            return res;
        });
    };
}
export function getQueryStringParameterByName(name) {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results)
        return null;
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export function compareValues(key, secondkey, sortAscending) {
    if (sortAscending === void 0) { sortAscending = true; }
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }
        var primaryA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        var primaryB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
        var comparison = 0;
        if (primaryA > primaryB) {
            comparison = 1;
        }
        else if (primaryA < primaryB) {
            comparison = -1;
        }
        else if (!!secondkey && (a.hasOwnProperty(key) && b.hasOwnProperty(key))) {
            var secondaryA = (typeof a[secondkey] === 'string')
                ? a[secondkey].toUpperCase() : a[secondkey];
            var secondaryB = (typeof b[secondkey] === 'string')
                ? b[secondkey].toUpperCase() : b[secondkey];
            if (secondaryA > secondaryB) {
                comparison = 1;
            }
            else if (secondaryA < secondaryB) {
                comparison = -1;
            }
            else {
                comparison = 0;
            }
        }
        return ((!sortAscending) ? (comparison * -1) : comparison);
    };
}
;
export var makeCancelablePromise = function (promise) {
    var hasCanceled_ = false;
    var wrappedPromise = new Promise(function (resolve, reject) {
        promise.then(function (val) { return hasCanceled_ ? reject({ isCanceled: true }) : resolve(val); }, function (error) { return hasCanceled_ ? reject({ isCanceled: true }) : reject(error); });
    });
    return {
        promise: wrappedPromise,
        cancel: function () {
            hasCanceled_ = true;
        },
    };
};
