var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { startInvent } from './../../../action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
function StartInventDialog(props) {
    var _a = __read(useState(true), 2), isSuccess = _a[0], setIsSuccess = _a[1];
    var onConfirm = function () {
        return props.startInvent(props.listId).then(function (result) {
            if (!result.success) {
                setIsSuccess(false);
                return Promise.reject();
            }
            else {
                return Promise.resolve();
            }
        }).catch(function () {
            setFeedback({
                text: translate('/Account/ProductList/StartInventError'),
                type: 'error',
            });
            return Promise.reject();
        });
    };
    return (React.createElement(Modal, { title: !!isSuccess ? translate('/Account/ProductList/StartInvent') : translate('/Account/ProductList/ActionOptions/Dialogs/StartFailed'), isOpen: props.isOpen, onClose: props.closeModal, reserveSpaceForCloseButton: true, fadeDesign: true },
        React.createElement("p", null, !!isSuccess ? translate('/Account/ProductList/ActionOptions/Dialogs/StartText') : translate('/Account/ProductList/ActionOptions/Dialogs/RetryText')),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: onConfirm, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal, customAcceptText: !!isSuccess ? translate('/Account/ProductList/StartInvent') : translate('/Account/ProductList/ActionOptions/Dialogs/Retry') })));
}
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    startInvent: function (listId) { return dispatch(startInvent(listId)); },
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
}); })(StartInventDialog);
