var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import Price from 'Shared/Price';
import Button from 'Shared/Button';
import { addToCart, reloadCart } from './../../Cart/action-creators';
import { quantityIncludingSubdeliveries } from '../../Cart/reducer';
import { loadCheckout } from './../../Checkout/action-creators';
import { toggleMiniCart } from './../MiniCart/action-creators';
import { translate } from 'Shared/translate';
import Icon from 'Shared/Icon';
import { MEDIUM } from 'Shared/breakpoints';
import connect from 'Shared/connect';
import { checkoutPageUrl } from 'Shared/known-urls';
import FeedbackButton from 'Shared/Button/Feedback';
import { pushState } from 'Shared/history';
import DesktopCartHeader from './Header';
import MiniCartContainer from './MiniCartContainer';
import { ProductCardSelectionProvider } from 'Shared/ProductComponents/ProductCardSelection';
import * as styles from './base.scss';
import { updateODPTrackingMinicart } from './../../TrackingInformation';
import ProductCardItem from 'Shared/ProductCard/ProductRow2.0/ProductCardItem';
var MiniCart = /** @class */ (function (_super) {
    __extends(MiniCart, _super);
    function MiniCart(props) {
        var _this = _super.call(this, props) || this;
        _this.isItemPending = function (item) {
            if (!_this.props.cart.pendingChanges) {
                return false;
            }
            return _this.props.cart.pendingChanges.some(function (change) { return !!change.items[item.lineItem.code]; });
        };
        _this.state = { setCartItemScroll: false };
        return _this;
    }
    MiniCart.prototype.componentDidMount = function () {
        updateODPTrackingMinicart();
    };
    MiniCart.prototype.componentWillUnmount = function () {
        this.unbindResizeListener && this.unbindResizeListener();
    };
    MiniCart.prototype.fetchCheckoutDataAndGoToCheckoutPage = function (resolve) {
        this.props.loadCheckout();
        pushState(checkoutPageUrl());
        resolve();
    };
    MiniCart.prototype.getFilteredAndSortedCartItems = function () {
        return this.props.cart.items.filter(function (i) { return quantityIncludingSubdeliveries(i.lineItem) > 0; });
    };
    MiniCart.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var cartItems = this.getFilteredAndSortedCartItems();
        var isMobile = this.props.currentBreakpoint < MEDIUM;
        var selectableProductIndexes = cartItems.map(function (_a, index) { return index; });
        return (React.createElement("div", { ref: function (ref) { return (_this.rootElement = ref); }, className: classnames(styles.base, (_a = {},
                _a[styles.flyout] = this.props.isFlyout,
                _a)) },
            React.createElement("div", { className: classnames(styles.body, styles.scroll, (_b = {},
                    _b[styles.scroll] = this.props.isFlyout,
                    _b)) }, cartItems.length > 0 ? (!isMobile ? (React.createElement("div", { ref: function (ref) { return (_this.tableList = ref); } },
                React.createElement(DesktopCartHeader, null),
                React.createElement("div", { className: styles.main },
                    React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
                        React.createElement("div", { className: classnames(styles.cartItemSection, styles.grid, styles.isCart), "data-list-type": 'viewLayout_List' }, cartItems.map(function (item, index) { return (React.createElement(MiniCartContainer, { key: item.lineItem.code, cartItem: item, index: index }, function (_a) {
                            var cartItem = _a.cartItem, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, removeCartItem = _a.removeCartItem, quantityStep = _a.quantityStep, url = _a.url;
                            return (React.createElement(React.Fragment, null,
                                React.createElement(ProductCardItem, { product: cartItem.product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: false, quantityStep: quantityStep, index: index, url: url, isRestrictedCustomer: false, cardType: 'viewLayout_List', isCart: true, totalPrice: item.lineItem.totalPrice, removeCartItem: removeCartItem })));
                        })); })))),
                React.createElement("div", { className: classnames(styles.tablefooter, styles.summary) },
                    React.createElement("div", { className: styles.removePlaceholder }),
                    React.createElement(FeedbackButton, { appearance: "large", onClickWithFeedback: function (e, activate) { return activate(new Promise(function (resolve, reject) { return _this.fetchCheckoutDataAndGoToCheckoutPage(resolve); })); }, disabled: !this.props.cart.items.length, "data-may-prevent-default": IS_APP, "data-ta": "to-checkout-button" }, translate('/MiniCart/ToCheckout')),
                    React.createElement("div", { className: styles.priceSummary }, !this.props.isSupplierSalesman && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: styles.toPayText },
                            translate('/MiniCart/ToPay'),
                            ":"),
                        React.createElement(Price, __assign({}, this.props.cart.subTotal, { size: "huge", isPending: this.props.cart.isLoading })))))))) : (React.createElement("div", null,
                React.createElement("div", { className: styles.mobileTopWrapper },
                    React.createElement("div", { className: styles.top },
                        React.createElement("div", { className: styles.close },
                            React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.props.toggleMiniCart(); } },
                                React.createElement(Icon, { name: "close" }))),
                        React.createElement("span", null, translate('/Checkout/Heading'))),
                    React.createElement("div", { className: styles.total },
                        React.createElement("div", { className: styles.sumWrap }, !this.props.isSupplierSalesman && (React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                translate('/MiniCart/ToPay'),
                                ":"),
                            React.createElement(Price, __assign({ className: styles.sum }, this.props.cart.subTotal, { size: "huge", isPending: this.props.cart.isLoading }))))),
                        React.createElement(FeedbackButton, { appearance: "full", onClickWithFeedback: function (e, activate) {
                                return activate(new Promise(function (resolve, reject) {
                                    _this.fetchCheckoutDataAndGoToCheckoutPage(resolve);
                                }));
                            }, disabled: !this.props.cart.items.length, "data-ta": "to-checkout-button" }, translate('/MiniCart/ToCheckout')))),
                React.createElement("div", { className: styles.main },
                    React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes },
                        React.createElement("div", { className: classnames(styles.mobileBottomWrapper, styles.grid, styles.isCart), "data-list-type": 'viewLayout_List' }, cartItems.map(function (item, index) { return (React.createElement(MiniCartContainer, { key: item.lineItem.code, cartItem: item, index: index }, function (_a) {
                            var cartItem = _a.cartItem, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, updateCartQuantity = _a.updateCartQuantity, removeCartItem = _a.removeCartItem, quantityStep = _a.quantityStep, url = _a.url;
                            return (React.createElement(React.Fragment, null,
                                React.createElement(ProductCardItem, { product: cartItem.product, quantityInCart: quantityInCart, selectedVariant: selectedVariant, updateCartQuantity: updateCartQuantity, discontinuedOrBackordered: false, quantityStep: quantityStep, index: index, url: url, isRestrictedCustomer: false, cardType: 'viewLayout_List', isCart: true, totalPrice: item.lineItem.totalPrice, removeCartItem: removeCartItem })));
                        })); }))))))) : (React.createElement("div", null,
                React.createElement("div", { className: styles.close }, isMobile && (React.createElement(Button, { appearance: "clear", onClick: function () { return _this.props.toggleMiniCart(); } },
                    React.createElement(Icon, { name: "close" })))),
                React.createElement("p", { className: styles.empty }, translate('/MiniCart/EmptyCart')))))));
    };
    return MiniCart;
}(React.PureComponent));
export default connect(function (state) { return ({
    cart: state.cart,
    miniCartIsOpen: state.miniCart.isOpen,
    currentBreakpoint: state.currentBreakpoint,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); }, function (dispatch) { return ({
    toggleMiniCart: function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(toggleMiniCart());
    },
    reloadCart: function () {
        dispatch(reloadCart());
    },
    loadCheckout: function () {
        return dispatch(loadCheckout());
    },
    updateCartItemQuantity: function (code, quantity, date, unitShortName, ticket) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, 'MiniCart'));
    },
}); })(MiniCart);
