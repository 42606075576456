var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import { alignmiddle } from '../../../styles/objects/icon.scss';
import { translate } from 'Shared/translate';
import EsalesFacetCategory from './EsalesFacetCategory';
import CampaignCategory from './CampaignCategory';
import Icon from 'Shared/Icon';
function isEsalesItem(item) {
    return 'ticket' in item;
}
function isEsalesType(items) {
    return items.some(isEsalesItem);
}
var CategoryFacet = function (_a) {
    var _b, _c;
    var subCategories = _a.subCategories, restProps = __rest(_a, ["subCategories"]);
    var _d = __read(useState(!!restProps.defaultOpen), 2), isOpen = _d[0], setOpen = _d[1];
    var renderFacetTitle = function () {
        if (!!subCategories && isEsalesType(subCategories)) {
            return translate('/Facets/Department');
        }
        else {
            return translate('/Facets/Campaigns');
        }
    };
    return (!!subCategories &&
        subCategories.length > 0 && (React.createElement("li", { className: classnames(styles.facetGroup, (_b = {}, _b[styles.open] = isOpen, _b)) },
        React.createElement("div", null,
            React.createElement("div", { className: styles.toggler, onClick: function () { return setOpen(!isOpen); } },
                React.createElement("span", { className: alignmiddle },
                    React.createElement("span", { className: styles.title }, renderFacetTitle()),
                    React.createElement(Icon, { name: isOpen ? 'minus' : 'plus' }))),
            React.createElement("div", { className: classnames(styles.categoryList, (_c = {}, _c[styles.categoryListClosed] = !isOpen, _c)) },
                React.createElement("ul", { className: styles.values }, isEsalesType(subCategories) ? (React.createElement(EsalesFacetCategory, __assign({ subCategories: subCategories }, restProps))) : (React.createElement(CampaignCategory, __assign({ subCategories: subCategories }, restProps)))))))));
};
export default CategoryFacet;
