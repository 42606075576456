var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import { pathCombine } from 'Shared/url';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import { postJson } from 'Shared/server';
import FeedbackButton from 'Shared/Button/Feedback';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { translate } from 'Shared/translate';
import Main from 'Shared/PageLayout';
var PunchoutOverviewPage = /** @class */ (function (_super) {
    __extends(PunchoutOverviewPage, _super);
    function PunchoutOverviewPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentCustomerId: _this.props.customerId,
        };
        return _this;
    }
    PunchoutOverviewPage.prototype.getCustomer = function (customerId, urlInput) {
        var _this = this;
        this.getFeedbackButton.activate(postJson(pathCombine(location.pathname, '/getcustomer'), { customerId: customerId }).then(function (result) {
            var urlResponse = result.url.toString();
            if (result.success) {
                _this.setState({
                    currentCustomerId: customerId,
                    url: urlResponse,
                    currentPassword: result.password,
                });
                urlInput.value = urlResponse;
                return Promise.resolve();
            }
            else {
                urlInput.value = urlResponse;
                _this.setState({
                    currentCustomerId: result.customerId,
                    url: '',
                    currentPassword: result.password,
                });
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                    overrideTimeout: true,
                });
                return Promise.reject(null);
            }
        }), { minimumPending: 750, maximumRejected: 2000 });
    };
    PunchoutOverviewPage.prototype.copyToClipboard = function (urlInput) {
        urlInput.select();
        document.execCommand('Copy');
    };
    PunchoutOverviewPage.prototype.createPassword = function (urlValue) {
        var _this = this;
        this.generatePwdFeedbackButton.activate(postJson(pathCombine(location.pathname, '/generatepassword'), { customerId: this.state.currentCustomerId }).then(function (result) {
            var urlResponse = result.url.toString();
            if (result.success) {
                _this.setState({
                    currentCustomerId: result.customerId,
                    url: urlResponse,
                    currentPassword: result.password,
                });
                urlValue.value = urlResponse;
                return Promise.resolve();
            }
            else {
                urlValue.value = urlResponse;
                _this.setState({
                    currentCustomerId: '',
                    url: '',
                    currentPassword: '',
                });
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                    overrideTimeout: true,
                });
                return Promise.reject(null);
            }
        }), { minimumPending: 750, maximumRejected: 2000 });
    };
    PunchoutOverviewPage.prototype.render = function () {
        var _this = this;
        var getValue;
        var urlValue;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement("h3", null, translate('/Admin/Punchout/Title')),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement("input", { id: "getInput", type: "text", placeholder: "Anv\u00E4ndarnamn", ref: function (el) { return (getValue = el); }, autoFocus: true })),
                React.createElement("div", null,
                    React.createElement(FeedbackButton, { appearance: "secondary", onClick: function () { return _this.getCustomer(getValue.value, urlValue); }, ref: function (el) { return (_this.getFeedbackButton = el); } }, translate('/Admin/Punchout/GetCustomer')))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement(Button, { appearance: "secondary", disabled: !!this.state.url ? false : true, onClick: function () { return _this.copyToClipboard(urlValue); } }, translate('/Admin/Punchout/CopyToClipboard'))),
                React.createElement("div", null,
                    React.createElement("input", { className: styles.urlInput, id: "urlInput", placeholder: "Punchout-URL", type: "text", ref: function (el) { return (urlValue = el); } }))),
            React.createElement("div", { className: styles.row },
                React.createElement("div", null,
                    React.createElement(FeedbackButton, { appearance: "secondary", onClick: function () { return _this.createPassword(urlValue); }, disabled: !!this.state.currentCustomerId ? false : true, ref: function (el) { return (_this.generatePwdFeedbackButton = el); } }, !!this.state.url
                        ? translate('/Admin/Punchout/GenerateNewPwd')
                        : translate('/Admin/Punchout/GeneratePwd'))),
                React.createElement("div", null,
                    React.createElement("span", null, this.state.currentPassword)))));
    };
    return PunchoutOverviewPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(PunchoutOverviewPage);
