var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import Overlay from 'Shared/Overlay';
import { default as touchSequence, UNKNOWN } from './touch-sequence';
import { on } from 'SiteLayout/Root';
import onload from 'Shared/onload';
import { isIOS } from 'Shared/device-type';
import swiper from './swiper';
import * as styles from './base.scss';
var Flyout = /** @class */ (function (_super) {
    __extends(Flyout, _super);
    function Flyout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onTouchStart = function (e) {
            _this.touchSequence && _this.touchSequence.push(e.touches[0]);
            _this.swiper && _this.swiper.onTouchStart(e);
        };
        _this.onTouchMove = function (e) {
            if (!_this.props.canOpen || !_this.touchSequence || (isIOS() && !_this.props.isOpen) || e.touches.length > 1) {
                return;
            }
            var touch = e.touches[0];
            _this.touchSequence.push(touch);
            // Have we gotten enough touch events to be able to detect
            // how the user is swiping?
            var swipeDirection = _this.touchSequence.swipeDirection();
            if (swipeDirection === UNKNOWN) {
                return;
            }
            _this.swiper && _this.swiper.onTouchMove(e);
        };
        _this.onTouchEnd = function (e) {
            _this.swiper && _this.swiper.onTouchEnd(e);
            _this.touchSequence && _this.touchSequence.reset();
        };
        return _this;
    }
    Flyout.prototype.componentDidMount = function () {
        if (this.props.isOpen && !!this.props.toggleFlyout) {
            this.enableSwiper();
        }
    };
    Flyout.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.isOpen && !this.props.isOpen && !!this.props.toggleFlyout) {
            this.enableSwiper();
        }
        this.swiper && this.swiper.isOpen(nextProps.isOpen);
        if (!nextProps.isOpen && this.props.isOpen) {
            this.disableSwiper();
        }
    };
    Flyout.prototype.componentWillUnmount = function () {
        this.disableSwiper();
    };
    Flyout.prototype.enableSwiper = function () {
        var _this = this;
        if (this.touchStartUnsubscribe) {
            // We're already enabled
            return;
        }
        // We use `Root/on` here to enable the throttled variant of `window.addEventListener('resize', ...`
        this.touchStartUnsubscribe = on('touchstart', this.onTouchStart);
        this.touchMoveUnsubscribe = on('touchmove', this.onTouchMove);
        this.touchEndUnsubscribe = on('touchend', this.onTouchEnd);
        // We have to wait until the element has been layed out in order to
        // know it's geometry
        onload(function () {
            // This can happen if the element was destroyed before we got a chance
            // to initialize the swipe
            if (!_this.base) {
                return;
            }
            var overlayDom = ReactDOM.findDOMNode(_this.overlay);
            _this.touchSequence = touchSequence();
            _this.swiper = swiper({
                touchSequence: _this.touchSequence,
                container: _this.base,
                overlay: overlayDom,
                isOpen: _this.props.isOpen,
                position: _this.props.position,
                toggleFlyout: _this.props.toggleFlyout,
            });
        });
    };
    Flyout.prototype.disableSwiper = function () {
        if (this.touchStartUnsubscribe) {
            this.touchStartUnsubscribe();
            this.touchStartUnsubscribe = null;
        }
        if (this.touchMoveUnsubscribe) {
            this.touchMoveUnsubscribe();
            this.touchMoveUnsubscribe = null;
        }
        if (this.touchEndUnsubscribe) {
            this.touchEndUnsubscribe();
            this.touchEndUnsubscribe = null;
        }
    };
    Flyout.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, isOpen = _b.isOpen, toggleFlyout = _b.toggleFlyout;
        var position = styles.left;
        if (this.props.position === 'right') {
            position = styles.right;
        }
        return (React.createElement(Overlay, { onClose: toggleFlyout, enabled: isOpen, ref: function (el) { return (_this.overlay = el); } },
            React.createElement("div", { className: classnames(styles.base, position, this.props.fillWidth && styles.fillWidth, (_a = {},
                    _a[styles.open] = isOpen,
                    _a)), onClick: function (e) { return e.stopPropagation(); }, ref: function (el) { return (_this.base = el); } }, this.props.children)));
    };
    return Flyout;
}(React.Component));
export default Flyout;
