var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import { translate } from 'Shared/translate';
var PopupMessage = /** @class */ (function (_super) {
    __extends(PopupMessage, _super);
    function PopupMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PopupMessage.prototype.render = function () {
        return (React.createElement("div", { className: styles.infoToCustomer },
            React.createElement(Main, { appearance: "narrow", className: styles.base_verified },
                React.createElement(Icon, { className: styles.accountSettingsIcon, name: 'sent-accountsettings' }),
                React.createElement("div", { className: styles.informationTextWrapper },
                    React.createElement(PropertyFor, { property: this.props.header, tagName: "h1" }),
                    React.createElement("p", { dangerouslySetInnerHTML: { __html: this.props.information.replace(/\[emailAddress\]/, "<strong>" + this.props.email + "</strong>") }, className: styles.information }),
                    React.createElement("div", { className: styles.changeOrSendAgain },
                        React.createElement("div", { className: styles.row },
                            React.createElement(PropertyFor, { property: this.props.sendVerificationAgainText, tagName: "p" }),
                            React.createElement("div", { className: styles.clickableText, onClick: this.props.sendEmailVerification }, translate('/Account/AccountSettings/SendEmailAgain'))),
                        React.createElement("div", { className: styles.row },
                            React.createElement(PropertyFor, { property: this.props.wrongEmailAddressText, tagName: "p" }),
                            React.createElement("div", { className: styles.clickableText, onClick: this.props.changeEmail }, translate('/Account/AccountSettings/ChangeEmailAddress')))),
                    React.createElement("div", { className: styles.contactCustomerService },
                        React.createElement(PropertyFor, { property: this.props.contactCustomerServiceText, tagName: "p", className: styles.information }))))));
    };
    return PopupMessage;
}(React.Component));
export default PopupMessage;
