import { SEARCH_RESULTS, SET_SEARCH_QUERY, CLEAR_SEARCH_LIST, DISPLAY_SEARCH_LIST, TOGGLE_CAMERA_SEARCHBAR, } from './action-creators';
export default function (state, action) {
    if (!state) {
        state = {
            searchQuery: '',
            searchResults: null,
            searchListIsVisible: false,
            showCamera: false,
        };
    }
    switch (action.type) {
        case TOGGLE_CAMERA_SEARCHBAR:
            return Object.assign({}, state, { showCamera: action.showCamera });
        case SEARCH_RESULTS:
            return Object.assign({}, state, { searchResults: action.results });
        case SET_SEARCH_QUERY:
            return Object.assign({}, state, { searchQuery: action.query });
        case CLEAR_SEARCH_LIST:
            return Object.assign({}, state, { searchResults: null });
        case DISPLAY_SEARCH_LIST:
            return Object.assign({}, state, { searchListIsVisible: action.show });
        default:
            return state;
    }
}
