var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './autocomplete.scss';
import { translate } from 'Shared/translate';
import MyMenigoIcon from './../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var SearchCategories = /** @class */ (function (_super) {
    __extends(SearchCategories, _super);
    function SearchCategories() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SearchCategories.prototype.render = function () {
        var _this = this;
        var items = this.props.categorySearchHits;
        var renderBreadCrumbs = function (breadcrumbItems) {
            return (!!breadcrumbItems && breadcrumbItems.map(function (cat, i) {
                return (React.createElement("span", { className: styles.breadCrumbs, key: cat + i },
                    cat,
                    React.createElement(MyMenigoIcon, { name: "arrow" })));
            }));
        };
        var renderDisplayName = function (category) {
            return React.createElement("span", null,
                " ",
                category.displayName,
                " (",
                category.productCount,
                " ",
                translate("/Units/UnitShortNames/st"),
                ")");
        };
        var renderItems = function (items) {
            return (!!items && items.map(function (item, j) {
                return (React.createElement("li", { key: item.displayName + j },
                    React.createElement("a", { href: item.url, "data-ticket": item.ticket, onClick: function () { return _this.props.onItemClick(item.ticket); } },
                        renderBreadCrumbs(item.breadCrumb),
                        renderDisplayName(item))));
            }));
        };
        return (React.createElement("div", { className: styles.searchCategories },
            React.createElement("h4", { className: styles.listHeader }, translate("/QuickSearch/Categories")),
            React.createElement("ul", { key: 'SearchCategories' }, renderItems(items))));
    };
    return SearchCategories;
}(React.PureComponent));
export default SearchCategories;
