var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import MyMenigoIcon from './../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var InfoBlock = /** @class */ (function (_super) {
    __extends(InfoBlock, _super);
    function InfoBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfoBlock.prototype.getTypeStyle = function () {
        switch (this.props.color) {
            case 'RedLight':
                return styles.infoType;
            case 'GreenLight':
                return styles.tipsType;
            case 'BackgroundDark':
                return styles.reportType;
            default:
                return '';
        }
    };
    InfoBlock.prototype.render = function () {
        return (React.createElement("div", { className: classnames(styles.base, this.getTypeStyle()) },
            React.createElement("div", { className: styles.iconRow }, !!this.props.iconName && (React.createElement(MyMenigoIcon, { name: this.props.iconName }))),
            !!(this.props.image) &&
                React.createElement("div", { className: classnames(styles.aspectRatio) },
                    React.createElement("img", { src: this.props.image })),
            React.createElement("h3", { className: styles.preamble }, this.props.name),
            React.createElement("p", { className: classnames(styles.description, styles.descriptionColor) }, this.props.body),
            !!this.props.ctaUrl && (React.createElement("div", { className: styles.cta },
                React.createElement("div", { className: styles.linkCol },
                    React.createElement("a", { className: styles.preamble, href: this.props.ctaUrl, target: this.props.ctaIsExternal === true || this.props.ctaIsFile === true ? '_blank' : '' }, this.props.ctaText)),
                React.createElement("div", { className: styles.iconCol }, !!this.props.ctaIconName && (React.createElement(MyMenigoIcon, { name: this.props.ctaIconName })))))));
    };
    return InfoBlock;
}(React.Component));
export default InfoBlock;
