import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal from 'Shared/Modal';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import { reOpenInvent } from './../../../action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
function ReOpenInventDialog(props) {
    var onConfirm = function () {
        return props.reOpenInvent(props.listId).catch(function () {
            setFeedback({
                text: translate('/Account/ProductList/ReOpenInventError'),
                type: 'error',
            });
        });
    };
    var text = (React.createElement("p", null,
        translate('/Account/ProductList/ReOpenInventConfirmLineOne'),
        React.createElement("br", null),
        translate('/Account/ProductList/ReOpenInventConfirmLineTwo')));
    return (React.createElement(Modal, { title: translate('/Account/ProductList/ReOpenInventory'), isOpen: props.isOpen, onClose: props.closeModal, reserveSpaceForCloseButton: true, fadeDesign: true },
        text,
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: onConfirm, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal, customAcceptText: translate('/Account/ProductList/ReOpenInventory') })));
}
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    reOpenInvent: function (listId) { return dispatch(reOpenInvent(listId)); },
    setFeedback: function (data) { return dispatch(setFeedback(data)); },
}); })(ReOpenInventDialog);
