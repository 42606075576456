import { isBrowser } from './device-type';
var callbacks = [];
var hasLoaded = false;
export default function (callback) {
    callbacks.push(callback);
    if (hasLoaded) {
        callback();
    }
}
if (isBrowser()) {
    if (process.env.NODE_ENV !== 'production') {
        // If we're in development (hot) mode we have to wait an arbitrary amount
        // until the styles have loaded
        // Or maybe until this is resolved: https://github.com/webpack/style-loader/issues/83
        setTimeout(function () {
            callbacks.forEach(function (c) { return c(); });
            hasLoaded = true;
        }, 1000);
    }
    else {
        (requestAnimationFrame || setTimeout)(function () {
            callbacks.forEach(function (c) { return c(); });
            hasLoaded = true;
        });
    }
}
