var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import * as styles from './base.scss';
import { Part, Section } from 'Shared/PageLayout';
import connect from 'Shared/connect';
import getUpdates from './Components/action-creators';
import TodaysDeliveries from './Components/TodaysDeliveries';
import DeliveryBox from './Components/DeliveryBox';
import { translate } from 'Shared/translate';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
var DeliveryOverviewBlock = /** @class */ (function (_super) {
    __extends(DeliveryOverviewBlock, _super);
    function DeliveryOverviewBlock(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            todaysDeliveries: props.todaysDeliveries,
            plannedDeliveries: props.plannedDeliveries,
            loading: false,
            timer: 0
        };
        return _this;
    }
    DeliveryOverviewBlock.prototype.componentDidMount = function () {
        this.updateDeliveries();
        this.setupRecuringUpdate();
    };
    DeliveryOverviewBlock.prototype.componentWillUnmount = function () {
        this.stopRecuringUpdate();
    };
    DeliveryOverviewBlock.prototype.setupRecuringUpdate = function () {
        var _this = this;
        var t = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.updateDeliveries();
                return [2 /*return*/];
            });
        }); }, this.props.updateInterval || 30000);
        this.setState({ timer: t });
    };
    DeliveryOverviewBlock.prototype.stopRecuringUpdate = function () {
        clearInterval(this.state.timer);
    };
    DeliveryOverviewBlock.prototype.updateDeliveries = function () {
        var _this = this;
        this.setState({
            loading: true,
        }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUpdates()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    DeliveryOverviewBlock.prototype.getUpdates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.getUpdates().then(function (result) {
                            if (!result.success) {
                                _this.props.setFeedback({
                                    text: result.message,
                                    type: 'error',
                                    overrideTimeout: true,
                                });
                            }
                            else {
                                _this.setState({ todaysDeliveries: result.deliveries, plannedDeliveries: result.plannedDeliveries, loading: false });
                            }
                        }).catch(function (any) { return _this.setState({ loading: false }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DeliveryOverviewBlock.prototype.render = function () {
        return (React.createElement(Section, { className: !!this.state.loading && styles.loading },
            !this.props.hideTodaysDeliveries && React.createElement(TodaysDeliveries, { todaysDeliveries: this.state.todaysDeliveries, deliveryPageUrl: this.props.deliveryPageUrl, showLinksToDeliveries: this.props.showLinksToDeliveries, showLinksToPackingSlip: this.props.showLinksToPackingSlip, linkUrl: this.props.linkUrl, text: this.props.text }),
            React.createElement(Part, { className: styles.columns },
                !this.props.hidePlannedDeliveries && React.createElement(Part, { className: styles.column },
                    React.createElement(DeliveryBox, { deliveryList: this.state.plannedDeliveries, deliveryPageUrl: this.props.deliveryPageUrl + "?type=planned", showLinksToDeliveries: this.props.showLinksToDeliveries, showLinksToPackingSlip: this.props.showLinksToPackingSlip, headerText: translate('/MyMenigo/Overview/AllUpcomingDeleveries'), footerText: translate('/MyMenigo/Overview/NoUpcomingDeleveries'), linkText: translate('/MyMenigo/Overview/ShowAllUpcomingDeleveries'), deliveryHeader: translate('/DeliveryApp/Shared/Delivery') })),
                !this.props.hideCompletedDeliveries && React.createElement(Part, { className: styles.column },
                    React.createElement(DeliveryBox, { deliveryList: this.props.completedDeliveries, deliveryPageUrl: this.props.deliveryPageUrl + "?type=completed", showLinksToDeliveries: this.props.showLinksToDeliveries, showLinksToPackingSlip: this.props.showLinksToPackingSlip, headerText: translate('/MyMenigo/Overview/AllPastDeleveries'), footerText: translate('/MyMenigo/Overview/NoPastDeleveries'), linkText: translate('/MyMenigo/Overview/ShowAllPastDeleveries'), deliveryHeader: translate('/DeliveryApp/Shared/Delivered') })))));
    };
    return DeliveryOverviewBlock;
}(React.Component));
;
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    getUpdates: function () {
        return dispatch(getUpdates());
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(DeliveryOverviewBlock);
