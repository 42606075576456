import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
var Mobile = function (_a) {
    var _b;
    var activePage = _a.activePage, visibleItems = _a.visibleItems, isDisablePrevNav = _a.isDisablePrevNav, isDisableNextNav = _a.isDisableNextNav, pageCount = _a.pageCount, isLoading = _a.isLoading, getInvoiceStatus = _a.getInvoiceStatus, pdfSendUrl = _a.pdfSendUrl, onSelectNavPageAction = _a.onSelectNavPageAction, noResultElement = _a.noResultElement;
    return (React.createElement(React.Fragment, null,
        React.createElement(Spinner, { key: "spinner", animate: isLoading, className: classnames(styles.loaderSpinner, (_b = {},
                _b[styles.visible] = isLoading,
                _b)) }),
        React.createElement("div", { className: styles.mobileViewLane },
            visibleItems.map(function (item) { return (React.createElement("div", { className: styles.invoiceWrapItems, key: item.id },
                React.createElement("div", { className: styles.mobileHeader },
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/InvoiceNumber'),
                            ":"),
                        " ",
                        item.id),
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/InvoiceDate'),
                            ":"),
                        " ",
                        item.issueDate),
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/DueDate'),
                            ":"),
                        " ",
                        item.dueDate),
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/Amount'),
                            ":"),
                        " ",
                        item.amount,
                        " kr"),
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/Status'),
                            ":"),
                        " ",
                        getInvoiceStatus(item.status),
                        " "),
                    React.createElement("div", { className: styles.mobileCell },
                        React.createElement("span", null,
                            translate('/Invoice/CreditInvoice'),
                            ":"),
                        item.invoiceType === "credit" ? React.createElement(Icon, { name: "check-square" }) : null),
                    React.createElement("div", { className: styles.mobileCell, onClick: function () { return pdfSendUrl(item.url, item.id); } },
                        React.createElement("span", null,
                            translate('/Invoice/PdfLink'),
                            ":"),
                        " ",
                        React.createElement("a", { className: styles.aLink }, translate('/Invoice/PdfText')))))); }),
            visibleItems.length < 1 && noResultElement),
        React.createElement("div", { className: styles.mobilePagingLane },
            React.createElement("div", { className: styles.prevContainer }, !isDisablePrevNav ? (React.createElement("a", { href: void 0, rel: "prev" },
                React.createElement(Button, { appearance: ['icon', 'small', 'large'], onClick: function () { return onSelectNavPageAction(activePage, 'prev'); } },
                    React.createElement("span", null,
                        React.createElement(Icon, { name: "arrow-left" })),
                    React.createElement("span", null, "Prev")))) : null),
            React.createElement("div", { className: styles.paging },
                React.createElement("span", null, "Page " + activePage + " of " + pageCount.length)),
            React.createElement("div", { className: styles.nextContainer }, !isDisableNextNav ? (React.createElement("a", { href: void 0, rel: "next" },
                React.createElement(Button, { appearance: ['icon', 'small', 'large'], onClick: function () { return onSelectNavPageAction(activePage, 'next'); } },
                    React.createElement("span", null, "Next"),
                    React.createElement("span", null,
                        React.createElement(Icon, { name: "arrow-right" }))))) : null))));
};
export default Mobile;
