var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import connect from 'Shared/connect';
import { onGetPantriesFavoritesList, onCopyToCustomer } from '../action-creators';
import MyPagesMenu from '../../../MyMenigo/Shared/MyPagesMenu';
import ProductList from './ProductList';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import Main, { Basic, Section } from 'Shared/PageLayout';
import { searchUsers } from './action-creator';
import * as styles from './base.scss';
var FavoritesPantriesPage = /** @class */ (function (_super) {
    __extends(FavoritesPantriesPage, _super);
    function FavoritesPantriesPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            searchInput: '',
            favoriteList: null,
            pantryList: null,
            selectedProducts: [],
            currentCustomerId: null,
            anySelected: [],
        };
        _this.copyValue = React.createRef();
        return _this;
    }
    FavoritesPantriesPage.prototype.searchforUsers = function (e) {
        var _this = this;
        clearTimeout(this.currentTimeout);
        if (e.length > 2) {
            if (!this.state.searchingForUsers) {
                this.setState({ searchingForUsers: true });
            }
            this.currentTimeout = setTimeout(function () {
                _this.props.searchUsers(e).then(function (result) {
                    var slicedArr = result.slice(0, 10);
                    _this.setState({ searchResult: slicedArr, searchingForUsers: false });
                });
            }, 500);
        }
        else if (this.state.searchingForUsers || !!this.state.searchResult) {
            this.setState({ searchResult: null, searchingForUsers: false });
        }
    };
    FavoritesPantriesPage.prototype.copyToCustomer = function (customerId) {
        var _this = this;
        this.feedbackButton.activate(onCopyToCustomer(this.state.currentCustomerId, customerId, this.state.selectedProducts).then(function (result) {
            if (result.success) {
                _this.props.setFeedback({
                    text: result.message,
                    type: 'success',
                    overrideTimeout: true,
                });
                return Promise.resolve();
            }
            else {
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                    overrideTimeout: true,
                });
                return Promise.reject(result.message);
            }
        }), { minimumPending: 750, maximumRejected: 2000 });
    };
    FavoritesPantriesPage.prototype.getLists = function (inputValue, input) {
        var _this = this;
        this.setState({ searchInput: '', searchResult: [] });
        this.getFeedbackButton.activate(onGetPantriesFavoritesList(inputValue).then(function (result) {
            if (result.success) {
                _this.setState({
                    favoriteList: result.lists,
                    pantryList: result.pantryLists,
                    selectedProducts: [],
                    currentCustomerId: inputValue,
                });
                if (result.lists.length <= 0 && result.pantryLists.length <= 0) {
                    _this.props.setFeedback({
                        text: translate('/Admin/ProductLists/NoLists'),
                        type: 'error',
                    });
                    return Promise.reject(translate('/Admin/ProductLists/NoLists'));
                }
                return Promise.resolve();
            }
            else {
                _this.props.setFeedback({ text: result.message, type: 'error', overrideTimeout: true });
                return Promise.reject(result.message);
            }
        }), { minimumPending: 750, maximumRejected: 2000 });
    };
    FavoritesPantriesPage.prototype.addRemoveSelectedItem = function (id, type) {
        var newItems = __spread(this.state.selectedProducts);
        if (newItems.some(function (item) { return item.id === id.toString(); })) {
            newItems = newItems
                .filter(function (newItem) { return newItem.id !== id; })
                .map(function (i) { return ({ id: i.id, type: i.type }); });
        }
        else {
            var newItem = { id: id, type: type };
            newItems.push(newItem);
        }
        this.setState({
            selectedProducts: newItems,
        });
    };
    FavoritesPantriesPage.prototype.testing = function (items, type) {
        var newItems = __spread(this.state.selectedProducts);
        items.map(function (v, i) {
            if (newItems.some(function (item) { return item.id === v.toString(); })) {
                newItems = newItems
                    .filter(function (newItem) { return newItem.id !== v; })
                    .map(function (i) { return ({ id: i.id, type: i.type }); });
            }
            else {
                var newItem = { id: v, type: type };
                newItems.push(newItem);
            }
        });
        if (items.length <= 0) {
            newItems = newItems
                .filter(function (newItem) { return newItem.type !== type; })
                .map(function (i) { return ({ id: i.id, type: i.type }); });
        }
        this.setState({
            selectedProducts: newItems,
        });
    };
    FavoritesPantriesPage.prototype.render = function () {
        var _this = this;
        var getValue;
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement("h1", null, translate('/Admin/ProductLists/Title')),
            React.createElement("div", { className: styles.btnRows },
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "getInput" }, translate('/Admin/ProductLists/GetProductLists'))),
                React.createElement("div", null,
                    React.createElement("input", { value: this.state.searchInput, ref: function (el) { return (_this.customerSearch = el); }, placeholder: translate('/Account/Impersonation/CustomerInput'), onChange: function (e) {
                            return _this.setState({ searchInput: e.target.value }, function () {
                                return _this.searchforUsers(_this.state.searchInput);
                            });
                        } }),
                    !!this.state.searchResult && this.state.searchResult.length > 0 && (React.createElement("table", { className: styles.list },
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, translate('/Account/Impersonation/AccountNumber')),
                                React.createElement("th", null, translate('/Account/Impersonation/CustomerName')),
                                React.createElement("th", null)),
                            this.state.searchResult.map(function (contact, i) { return (React.createElement("tr", { key: contact.customerId },
                                React.createElement("td", null, contact.customerId),
                                React.createElement("td", null, contact.name),
                                React.createElement("td", null,
                                    React.createElement(FeedbackButton, { className: styles.buttons, onClick: function () { return _this.getLists(contact.customerId, getValue); }, ref: function (el) { return (_this.getFeedbackButton = el); } }, translate('/Admin/ProductLists/Get'))))); })))))),
            React.createElement("div", { className: styles.btnRows },
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "copyInput" }, translate('/Admin/ProductLists/CopyCustomerId'))),
                React.createElement("div", null,
                    React.createElement("input", { id: "copyInput", type: "text", ref: this.copyValue }),
                    React.createElement(FeedbackButton, { className: styles.buttons, onClick: function () { return _this.copyToCustomer(_this.copyValue.current.value); }, ref: function (el) { return (_this.feedbackButton = el); }, disabled: !!(this.state.selectedProducts.length <= 0) }, translate('/Admin/ProductLists/Copy')))),
            React.createElement("div", { className: styles.btnRows },
                React.createElement("p", null,
                    React.createElement("em", null, translate('/Admin/ProductLists/Tip')),
                    ' ',
                    React.createElement("span", null, translate('/Admin/ProductLists/SelectListsToCopy')))),
            React.createElement("div", null,
                React.createElement("p", null, translate('/Admin/ProductLists/TipText'))),
            React.createElement(Basic, { layout: "1to1", id: "checkboxContainer" },
                React.createElement(Section, null,
                    React.createElement(ProductList, { anySelected: function (a) { return _this.testing(a, 'favorites'); }, checkbox: function (id) { return _this.addRemoveSelectedItem(id, 'favorites'); }, list: this.state.favoriteList || this.props.lists, heading: translate('/Admin/ProductLists/FavoriteList') })),
                React.createElement(Section, null,
                    React.createElement(ProductList, { anySelected: function (a) { return _this.testing(a, 'pantries'); }, checkbox: function (id) { return _this.addRemoveSelectedItem(id, 'pantries'); }, list: this.state.pantryList || this.props.pantryLists, heading: translate('/Admin/ProductLists/PantryList') })))));
    };
    return FavoritesPantriesPage;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    searchUsers: function (query) {
        return dispatch(searchUsers(query));
    },
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(FavoritesPantriesPage);
