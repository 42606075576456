import React from 'react';
import LoginContainer from 'Shared/Login/Container';
import { RenderPrice } from 'Shared/Price/ProductPrice';
import SignUpLink from 'Shared/ProductCard/SignUpLink/SignUpLink';
var PriceBlock = function (_a) {
    var selectedVariant = _a.selectedVariant, isInCampaign = _a.isInCampaign;
    return (React.createElement(LoginContainer, null, function (_a) {
        var isLoggedIn = _a.isLoggedIn;
        return isLoggedIn ? (React.createElement(RenderPrice, { variant: selectedVariant, productIsInCampaign: isInCampaign })) : (React.createElement(SignUpLink, null));
    }));
};
export default PriceBlock;
