import React from 'react';
import * as styles from '../../../Search/Pages/Search/Components/components.scss';
import QuickFilterButton from '../../../Search/Pages/Search/Components/SearchQuickFilter';
import classnames from 'classnames';
import MyMenigoIcon from './../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
var SelectedFilters = function (props) {
    var selectedFacetsButtons = [];
    !!props.facets && props.facets.filter(function (x) { return x.facetValues.some(function (y) { return y.selected; }); }).forEach(function (f) {
        f.facetValues.filter(function (x) { return x.selected; }).forEach(function (facet, i) {
            selectedFacetsButtons.push({
                buttonText: facet.text,
                selected: facet.selected,
                facet: facet,
                facetGroup: f,
                sortorder: i,
                values: [],
                disabled: false,
            });
        });
    });
    var hasSelectedFacetValue = (!!selectedFacetsButtons && selectedFacetsButtons.length > 0) || !!props.showClearButton;
    return (React.createElement(React.Fragment, null, !!hasSelectedFacetValue && (React.createElement("div", { className: classnames(styles.searchSuggestion, styles.selectedFilters) },
        React.createElement("ul", null,
            !!selectedFacetsButtons && selectedFacetsButtons.map(function (button, i) { return (React.createElement(QuickFilterButton, { small: true, key: button.buttonText + i, item: button })); }),
            (hasSelectedFacetValue) && (React.createElement("li", { className: styles.searchFilter },
                React.createElement(Button, { appearance: "pale", className: styles.clearFacets, onClick: function () { return props.onClearFacets(); } },
                    translate('/Shared/ClearFilter'),
                    React.createElement(MyMenigoIcon, { name: "trash" })))))))));
};
export default SelectedFilters;
