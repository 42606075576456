var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import { translate } from 'Shared/translate';
import ProductLink from 'Shared/ProductComponents/ProductLink';
import ProductImage from 'Shared/ProductComponents/ProductImage';
import ProductTitle from 'Shared/ProductComponents/ProductTitle';
import ProductDetails from 'Shared/ProductComponents/ProductDetails';
import ProductTypeAndUnit from 'Shared/ProductComponents/ProductTypeAndUnit';
import ProductMarks from 'Shared/ProductCampaignLabel';
import ProductDisabledInfo from 'Shared/ProductComponents/ProductDisabledInfo';
import AddRemoveFromListOrLogin from 'Shared/Product/AddRemoveFromListOrLogin';
import ProductOrdinal from 'Shared/ProductComponents/ProductOrdinal';
import RemoveProduct from '../RemoveProduct';
import { Checkbox } from 'Shared/Fields';
import ProductAvailabilityLabel from 'Shared/ProductAvailabilityLabel';
import ProductAlternativesLink from 'Shared/ProductAlternativesLink/ProductAlternativesLink';
import VariantAndQuantity from '../../ProductCard/VariantAndQuantity';
import ToolTip from 'Shared/ProductTooltip/ToolTip';
var DesktopProduct = function (_a) {
    var cartItemQuantity = _a.cartItemQuantity, getStep = _a.getStep, isPantry = _a.isPantry, inventoryStatus = _a.inventoryStatus, product = _a.product, quantity = _a.quantity, selectedVariant = _a.selectedVariant, updateQuantity = _a.updateQuantity, disabled = _a.disabled, isRecommendations = _a.isRecommendations, rowIndex = _a.rowIndex, passedUpdateOrdinal = _a.passedUpdateOrdinal, passedOrdinal = _a.passedOrdinal, selectElement = _a.selectElement, listId = _a.listId, code = _a.code, passedRemoveListItem = _a.passedRemoveListItem, showDelete = _a.showDelete, checked = _a.checked, toggleSelected = _a.toggleSelected, selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex, maxQuantity = _a.maxQuantity, isLocked = _a.isLocked, setActive = _a.setActive;
    var url = product.isDiscontinued ||
        product.isDisabledForFreCustomer ||
        product.isDeletedByMaster ||
        product.isBackorderReplaced
        ? ''
        : product.url;
    var discontinuedOrBackordered = product.isDiscontinued ||
        product.isDisabledForFreCustomer ||
        product.isBackorderReplaced ||
        product.isDeletedByMaster;
    var disabledFadeClassName = discontinuedOrBackordered ? styles.disabledFade : '';
    var showInfoIfDisabledAndPantry = isPantry || (!isPantry && !discontinuedOrBackordered);
    var round = function (num, precision) { return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision); };
    return (React.createElement("div", { className: styles.card, itemType: "http://schema.org/Product", itemScope: true },
        React.createElement("div", { className: styles.cardInner },
            React.createElement("div", { className: classnames(styles.cardColumn, styles.checkboxWrap) },
                React.createElement(Checkbox, { className: styles.toggleSelected, checked: checked, onClick: function () { return selectElement(rowIndex, 'checkbox'); }, onChange: function (e) {
                        return toggleSelected(code, e.target.checked);
                    } })),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.imageWrap) },
                React.createElement(ProductLink, { ticket: product.ticket, linkUrl: url ? url.toString() : '', code: selectedVariant && selectedVariant.code },
                    React.createElement(ProductImage, { imageUrl: product.mainImageUrl, displayName: product.title, isCustom: product.isCustom, size: "tiny" }))),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.detailsWrap) },
                React.createElement(ProductTitle, { isCustom: product.isCustom && !product.isLocked && !product.isDeletedByMaster, code: selectedVariant.code, linkUrl: url ? url.toString() : '', ticket: product.ticket, displayName: product.title }),
                React.createElement(ProductDetails, { code: product.code, linkUrl: url ? url.toString() : '', ticket: product.ticket, trademark: product.trademark, className: disabledFadeClassName, productArea: product.area }),
                !!product.carbonFootPrint && !!product.carbonCloudLink && (React.createElement("div", { className: styles.carbonCloud },
                    React.createElement(ToolTip, { persistent: false, text: translate('/ProductListing/CarbonFootprintDescription', round(product.carbonFootPrint, 2)), carbonCloud: true },
                        round(product.carbonFootPrint, 1),
                        " ",
                        translate('/ProductListing/CarbonFootprintUnit'))))),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.listItemInformationWrap, isPantry ? styles.listItemInformationWrap_smaller : styles.listItemInformationWrap_small) },
                showInfoIfDisabledAndPantry && (React.createElement("div", { className: classnames(styles.unitAndPriceWrap, disabledFadeClassName) },
                    React.createElement("div", { className: classnames(styles.typeAndUnitWrap) },
                        React.createElement(ProductTypeAndUnit, { variants: product.variants, selectedVariant: selectedVariant, discontinuedOrBackordered: isPantry ? false : discontinuedOrBackordered, isWeightProduct: product.isWeightProduct, productIsInCampaign: product.isInCampaign, tooltipAlignmentVertical: rowIndex === 0 ? 'bottom' : null, isRecommendations: isRecommendations, isPantry: isPantry, showPrice: true, hideType: true, productArea: product.area }),
                        !discontinuedOrBackordered && (React.createElement("div", { className: styles.marksWrap },
                            React.createElement(ProductMarks, { productCampaigns: product.secondaryTags, isSustainableChoice: product.isSustainableChoice })))))),
                discontinuedOrBackordered && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.disabledIconWrap },
                        React.createElement(Icon, { className: styles.disabledIcon, name: "exclamation-mark" })),
                    React.createElement("div", { className: classnames(styles.disabledWrap) },
                        React.createElement(ProductDisabledInfo, { isDiscontinued: product.isDiscontinued, isDeletedByMaster: product.isDeletedByMaster, isBackorderReplaced: product.isBackorderReplaced, replacementProduct: product.replacementProduct, recommendedReplacementProduct: product.recommendedReplacementProduct, isTender: product.isTender, isPantry: isPantry, isDisabledForFreCustomer: product.isDisabledForFreCustomer }))))),
            !isPantry && !isRecommendations && (React.createElement("div", { className: classnames(styles.cardColumn, styles.availabilityWrap) },
                React.createElement(ProductAvailabilityLabel, { availability: selectedVariant.availability, disableAvailabilityDate: true }))),
            !isRecommendations && (React.createElement("div", { className: classnames(styles.cardColumn, styles.quantityAndVariantWrap_big, !isPantry && disabledFadeClassName, isPantry && styles.amountAndSum) },
                (!isPantry || inventoryStatus === 'ongoing' || inventoryStatus === 'reOpened') && (React.createElement(React.Fragment, null, !isPantry && React.createElement(ProductAvailabilityLabel, { disableAvailabilityMarkers: true, availability: selectedVariant.availability }))),
                (isPantry && inventoryStatus === 'completed') && (React.createElement("div", { className: styles.quantityNoInput },
                    React.createElement("strong", null, quantity))),
                React.createElement(VariantAndQuantity, { product: __assign({}, product), index: rowIndex, isRestrictedCustomer: false, updateCartQuantity: updateQuantity, quantityStep: getStep(), quantityInCart: cartItemQuantity, minQuantity: 0, isCartQuantity: true, variants: product.variants, selectedVariant: selectedVariant, isWeightProduct: product.isWeightProduct, discontinuedOrBackordered: discontinuedOrBackordered, url: '', isFavorite: true, isPantry: isPantry, isQuantityVisible: !isPantry || inventoryStatus === 'ongoing' || inventoryStatus === 'reOpened', isVariantVisible: !isPantry || inventoryStatus === 'ongoing' || inventoryStatus === 'reOpened' || (inventoryStatus !== 'never' && quantity != undefined), selectElement: selectElement, quantity: quantity, maxQuantity: maxQuantity, rowIndex: rowIndex, isDisabled: disabled, setSelected: setSelectedIndex, selectedIndex: selectedIndex }),
                !isPantry && React.createElement(ProductAlternativesLink, { product: __assign({}, product), selectedVariant: selectedVariant }),
                isPantry && product.previousQuantity ? (React.createElement("div", { className: styles.previousAmount },
                    translate('/Account/ProductList/LastInventory'),
                    " ",
                    product.previousQuantity,
                    " ",
                    selectedVariant.unitShortName)) : null,
                (isPantry && inventoryStatus === 'never') && (React.createElement("div", { className: styles.previousAmount },
                    React.createElement("strong", null, translate('/Account/ProductList/InventoryNever')))))),
            !isRecommendations && isPantry && (React.createElement("div", { className: classnames(styles.cardColumn, styles.quantityAndVariantWrap_big, styles.amountAndSum) },
                (quantity != undefined) ? (React.createElement("div", { className: styles.quantityNoInput },
                    React.createElement("strong", null,
                        (selectedVariant.price.current * quantity).toFixed(2),
                        " ",
                        selectedVariant.price.currencySymbol))) : React.createElement("div", { className: styles.quantityNoInput },
                    React.createElement("strong", null,
                        "0 ",
                        selectedVariant.price.currencySymbol)),
                product.previousQuantity ? (React.createElement("div", { className: styles.previousAmount },
                    translate('/Account/ProductList/LastInventory'),
                    " ",
                    (selectedVariant.price.current * product.previousQuantity).toFixed(2),
                    " ",
                    selectedVariant.price.currencySymbol)) : null)),
            React.createElement("div", { className: classnames(styles.cardColumn, styles.rowActionWrap) },
                !isRecommendations && (React.createElement("div", { className: classnames(styles.ordinalWrap) },
                    React.createElement(ProductOrdinal, { passedUpdateOrdinal: passedUpdateOrdinal, passedOrdinal: passedOrdinal, selectElement: selectElement, rowIndex: rowIndex, disabled: false }))),
                isLocked && (React.createElement("div", { className: styles.lockButtonWrap, onClick: function () { return setActive(); } },
                    React.createElement(Icon, { appearance: "sans", name: "lock" }))),
                showDelete && (React.createElement("div", { className: styles.removeButtonWrap },
                    React.createElement(RemoveProduct, { code: code, listId: listId, removeProductFromList: function () { return passedRemoveListItem(rowIndex, code); } }))),
                React.createElement("div", { className: classnames(styles.listBtnsWrap) },
                    React.createElement(AddRemoveFromListOrLogin, { variantCode: selectedVariant.code, product: product }))))));
};
export default DesktopProduct;
