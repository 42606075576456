var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { search } from './action-creators';
import { addProductToList } from 'Shared/ListItem/action-creators';
import AutocompletionsCustom from '../../Search/QuickSearch/AutocompletionsCustom';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import { ENTER, DOWN, UP, TAB, ESC } from 'Shared/keyboard';
import { ProductCardSelectionProvider, ProductCardSelection } from 'Shared/ProductComponents/ProductCardSelection';
import propertyValueFor from 'Shared/property-value-for';
var inputDebouncer = 300;
var ProductSearch = /** @class */ (function (_super) {
    __extends(ProductSearch, _super);
    function ProductSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            searchText: '',
            listSelectionIndex: -1,
            isOpen: false,
            isQuickSearchLoading: false,
            quickSearchProducts: null,
        };
        _this.setWrapperRef = _this.setWrapperRef.bind(_this);
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        return _this;
    }
    ProductSearch.prototype.componentDidMount = function () {
        document.addEventListener('mousedown', this.handleClickOutside);
    };
    ProductSearch.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    };
    ProductSearch.prototype.onFocus = function (index) {
        this.setState({ listSelectionIndex: index });
    };
    ProductSearch.prototype.addByIndex = function (index) {
        var _this = this;
        if (index >= 0 && this.state.quickSearchProducts.length > index) {
            var selectedProduct = this.state.quickSearchProducts[index];
            var pantriesList = this.props.pantry ? this.props.currentPage.lists : this.props.currentPage.pantryLists;
            var favoritesList = !this.props.pantry ? this.props.currentPage.lists : this.props.currentPage.favoritesLists;
            var pantryOrFavoritesList = this.props.pantry ? pantriesList : favoritesList;
            var itemCodesInList = pantryOrFavoritesList
                .map(function (list) {
                return list.products &&
                    list.id === _this.props.listId &&
                    list.products.map(function (product) { return product.code; });
            })
                .filter(Boolean)[0];
            var itemCode = propertyValueFor(selectedProduct.content.code);
            var addToListDisabled = itemCodesInList.indexOf(itemCode) > -1;
            if (!!selectedProduct && !addToListDisabled) {
                this.props.addProduct(this.props.listId, selectedProduct, this.props.pantry, this.props.status);
            }
            else {
                return;
            }
        }
        this.closeQuickSearch(); // TODO: Remove this line when addToList-button inside QuickSearch/ProductSearch is a FeedbackButton
    };
    ProductSearch.prototype.closeQuickSearch = function () {
        this.setState({ isOpen: false, listSelectionIndex: -1, isQuickSearchLoading: false, quickSearchProducts: null });
    };
    ProductSearch.prototype.emptySearchInput = function () {
        this.searchInput.value = '';
        this.setState({ searchText: '' });
        this.closeQuickSearch();
    };
    ProductSearch.prototype.handleSearch = function (e) {
        var _this = this;
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(function () {
            if (e.value.length > 0) {
                _this.setState({ searchText: e.value, isQuickSearchLoading: true }, function () {
                    _this.doSearch(e.value);
                });
            }
            else {
                _this.closeQuickSearch();
            }
        }, inputDebouncer);
    };
    ProductSearch.prototype.doSearch = function (searchText) {
        var _this = this;
        !!this.currentRequest && !!this.currentRequest.cancel && this.currentRequest.cancel();
        this.currentRequest = this.props.loadProductSearch(searchText, this.props.pantry);
        !!this.currentRequest.promise && this.currentRequest.promise.then(function (productResult) {
            _this.setState({
                quickSearchProducts: productResult,
                isQuickSearchLoading: false,
            });
        }).catch(function (error) {
        });
    };
    ProductSearch.prototype.focusInCurrentTarget = function (event) {
        var relatedTarget = event.relatedTarget;
        var currentTarget = event.currentTarget;
        if (event.relatedTarget === null) {
            return true;
        }
        var node = relatedTarget.parentNode;
        while (node !== null) {
            if (node === currentTarget) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };
    // If input has text and input gains focus, search for products and show them
    ProductSearch.prototype.onInputFocus = function (event, resetSelectedIndex) {
        resetSelectedIndex();
        this.setState({ listSelectionIndex: -1 });
        var eventTarget = event.target;
        if (!this.state.isOpen && eventTarget.value.length > 0) {
            this.handleSearch(eventTarget);
            this.setState({ isOpen: true });
        }
    };
    ProductSearch.prototype.onKeyDown = function (event, setSelectedIndex) {
        var selectedIndex = this.state.listSelectionIndex;
        if (Number(event.key) >= 0 && Number(event.key) <= 9) {
            this.setState({ lastEditedNumeric: Number(event.key) });
        }
        switch (event.keyCode) {
            case ENTER:
                try {
                    event.preventDefault();
                }
                catch (e) {
                    // the event could be simulated by the button - skip error-handling here
                }
                if (!!this.state.isOpen) {
                    this.addByIndex(this.state.listSelectionIndex);
                }
                break;
            case TAB:
            case DOWN:
                if (!!this.state.quickSearchProducts && !!this.state.isOpen) {
                    event.preventDefault();
                    if (this.state.listSelectionIndex < this.state.quickSearchProducts.length - 1) {
                        var newSelectedIndex_1 = selectedIndex + 1;
                        this.setState({ listSelectionIndex: newSelectedIndex_1 }, function () { return setSelectedIndex && setSelectedIndex(newSelectedIndex_1); });
                    }
                    else if (this.state.quickSearchProducts.length > 0) {
                        this.setState({ listSelectionIndex: 0 });
                    }
                    if (event.shiftKey) {
                        var newSelectedIndex = selectedIndex === 0 ? this.state.quickSearchProducts.length - 1 : selectedIndex - 1;
                        if (selectedIndex >= 0) {
                            this.setState({ listSelectionIndex: newSelectedIndex });
                        }
                    }
                }
                break;
            case UP:
                if (!!this.state.isOpen) {
                    event.preventDefault();
                    var newSelectedIndex = selectedIndex === 0 ? this.state.quickSearchProducts.length - 1 : selectedIndex - 1;
                    if (selectedIndex >= 0) {
                        this.setState({ listSelectionIndex: newSelectedIndex });
                    }
                }
                break;
            case ESC:
                // Prevent default because Chrome clears the value of a input[type='search']
                // on escape
                event.preventDefault();
                this.searchInput.value = '';
                this.setState({ searchText: '' });
                this.closeQuickSearch();
                break;
            default:
                !event.shiftKey && !event.ctrlKey && !event.altKey && this.searchInput.focus();
                this.setState({ isOpen: true });
                return;
        }
    };
    ProductSearch.prototype.setWrapperRef = function (node) {
        this.wrapperRef = node;
    };
    ProductSearch.prototype.handleClickOutside = function (event) {
        if (this.state.isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.closeQuickSearch();
        }
    };
    ProductSearch.prototype.handleOnClick = function (index) {
        this.setState({ listSelectionIndex: index });
    };
    ProductSearch.prototype.render = function () {
        var _this = this;
        var selectableProductIndexes = (this.state.quickSearchProducts &&
            this.state.quickSearchProducts.length > 0 &&
            this.state.quickSearchProducts.map(function (_a, i) { return i; })) ||
            [];
        return (React.createElement("div", { ref: this.setWrapperRef, className: styles.productSearchWrapper },
            React.createElement(ProductCardSelectionProvider, { selectableIndexes: selectableProductIndexes, keepIndexOnBlur: true },
                React.createElement(ProductCardSelection, null, function (_a) {
                    var _b;
                    var selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex, resetSelectedIndex = _a.resetSelectedIndex;
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.addWrapper },
                            React.createElement("input", { type: "search", placeholder: translate('/QuickSearch/Placeholder'), ref: function (el) { return (_this.searchInput = el); }, defaultValue: _this.state.searchText || '', onChange: function (e) { return _this.handleSearch(e.target); }, onKeyDown: function (event) { return _this.onKeyDown(event, setSelectedIndex); }, onFocus: function (e) { return _this.onInputFocus(e, resetSelectedIndex); } }),
                            _this.state.searchText.length > 0 && (React.createElement(Button, { onClick: function () { return _this.emptySearchInput(); }, appearance: "clear", className: styles.emptySearchBtn },
                                React.createElement(Icon, { name: "close" }))),
                            React.createElement(Spinner, { key: "spinner", animate: _this.state.isQuickSearchLoading, className: classnames(styles.loaderSpinner, (_b = {},
                                    _b[styles.visible] = _this.state.isQuickSearchLoading,
                                    _b)) })),
                        React.createElement("div", { id: "result-" + _this.props.listId, className: styles.resultWrapper }, _this.state.isOpen && !!_this.state.quickSearchProducts && (React.createElement(AutocompletionsCustom, { thinSelection: true, className: styles.autocomplete, isLoading: _this.state.isQuickSearchLoading, products: _this.state.quickSearchProducts, onKeyDown: function (event) { return _this.onKeyDown(event); }, onClick: function (index) { return _this.handleOnClick(index); }, onFocus: function (index) { return _this.onFocus(index); }, addByIndex: function (index) { return _this.addByIndex(index); }, listId: _this.props.listId, isPantry: _this.props.pantry, selectedIndex: selectedIndex, setSelectedIndex: setSelectedIndex })))));
                }))));
    };
    return ProductSearch;
}(React.Component));
export default connect(function (state) { return ({
    currentPage: state.currentPage,
}); }, function (dispatch) { return ({
    loadProductSearch: function (searchText, isPantry) {
        return dispatch(search(searchText, isPantry));
    },
    addProduct: function (listId, product, pantry, status) {
        dispatch(addProductToList(listId, product, pantry, status));
    },
}); })(ProductSearch);
