var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Spinner from 'Shared/Spinner';
import classnames from 'classnames';
import * as styles from './base.scss';
var timeout = 300;
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isVisible: props.isVisible,
        };
        return _this;
    }
    Loader.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (nextProps.isVisible) {
            clearTimeout(this.visibilityTimer);
            this.visibilityTimer = setTimeout(function () {
                _this.setState({ isVisible: true });
            }, timeout);
        }
        else {
            if (this.visibilityTimer) {
                clearTimeout(this.visibilityTimer);
                this.visibilityTimer = null;
            }
            this.setState({ isVisible: false });
        }
    };
    Loader.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = this.state.isVisible !== nextState.isVisible;
        if (!shouldUpdate) {
            console.info('Ignoring update to Loader');
        }
        return shouldUpdate;
    };
    Loader.prototype.render = function () {
        var _a;
        return (React.createElement(Spinner, { key: "spinner", animate: this.state.isVisible, className: classnames(styles.base, (_a = {},
                _a[styles.visible] = this.state.isVisible,
                _a)) }));
    };
    return Loader;
}(React.Component));
export default Loader;
