export var getStepSize = function (variant) {
    var _a = variant.storeUnit, isWeight = _a.isWeight, minimumWeight = _a.minimumWeight, multiple = _a.multiple;
    if (isWeight && minimumWeight)
        return minimumWeight;
    if (multiple > 0)
        return multiple;
    return 1;
};
export var getNumberWithinLimits = function (value, min, max) {
    if (min && value < min)
        return min;
    if (max && value > max)
        return max;
    return value;
};
export var floatCalculationSafety = function (safetyExponential) {
    if (safetyExponential === void 0) { safetyExponential = 3; }
    var pow = Math.pow(10, safetyExponential);
    return {
        preFormat: function (n) { return n * pow; },
        postFormat: function (n) { return n / pow; },
    };
};
export var adjustNumberToMatchStep = function (value, step) {
    var _a = floatCalculationSafety(), preFormat = _a.preFormat, postFormat = _a.postFormat;
    var stepSize = preFormat(step);
    var desiredValue = preFormat(value);
    return postFormat(stepSize * Math.ceil(desiredValue / stepSize));
};
