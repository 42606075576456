import React from 'react';
import connect from 'Shared/connect';
import { translate } from 'Shared/translate';
import Modal, { ModalType } from 'Shared/Modal';
import DropDownMenu, { DropDownItem } from 'Shared/DropDownMenu';
import { moveToNewList, moveToList } from 'Shared/ListItem/action-creators';
import { timeout } from 'Shared/timeout';
import Dialog, { DialogType } from 'Shared/Dialog';
import * as styles from '../base.scss';
import { Input } from 'Shared/Fields';
function MoveDialog(props) {
    var textInput = React.createRef();
    var onMoveToListSelect = function (listId) {
        props.moveToList(listId).then(function () { return timeout(1000).then(function () { return props.closeModal(); }); });
    };
    return (React.createElement(Modal, { title: translate('/Account/ProductList/MoveToExistingFavoriteList'), isOpen: props.isOpen, className: styles.dialog, onClose: props.closeModal, type: props.showOnlyUnlockedWillBeMoved && ModalType.Warning, reserveSpaceForCloseButton: true, fadeDesign: true },
        props.showOnlyUnlockedWillBeMoved && (React.createElement("p", { className: styles.moveDialog }, translate('/Account/ProductList/ConfirmMoveUnlockedItems'))),
        React.createElement(DropDownMenu, { title: translate('/Shared/Choose'), optionsClassName: styles.listoptions }, props.favoriteLists.map(function (list) { return (React.createElement(DropDownItem, { key: list.id, value: list.id, text: list.displayName, onClick: function () { return onMoveToListSelect(list.id); } })); })),
        React.createElement("h4", null, translate('/Account/ProductList/MoveToNewFavoriteList')),
        React.createElement(Input, { ref: textInput, width: "100%", autoFocus: true }),
        React.createElement(Dialog, { type: DialogType.OkCancel, onConfirm: function () { return props.moveToNewList(textInput.current.element.value); }, onCancel: props.closeModal, onStatusWasFulfilled: props.closeModal })));
}
export default connect(function (state, _a) {
    var listId = _a.listId;
    return ({
        favoriteLists: state.currentPage.lists &&
            state.currentPage.lists.filter(function (list) { return list.id !== listId; }),
    });
}, function (dispatch, _a) {
    var listId = _a.listId, codes = _a.codes;
    return ({
        moveToNewList: function (displayName) { return dispatch(moveToNewList(listId, displayName, codes)); },
        moveToList: function (toListId) { return dispatch(moveToList(listId, toListId, codes)); },
    });
})(MoveDialog);
