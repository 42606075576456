var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import * as styles from '../base.scss';
import ProductListItem from './ProductListItem';
var ProductList = function (props) { return (React.createElement("ul", { className: classnames(styles.product_list, props.className, props.isLargeContentCard ? styles.product_list__large : "") }, props.products.map(function (product, i) { return (React.createElement("li", { key: i },
    React.createElement(ProductListItem, __assign({ product: product }, props)))); }))); };
export default connect(function (state) { return ({
    isLoggedIn: state.currentUser.isLoggedIn,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
}); })(ProductList);
