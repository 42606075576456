import React from 'react';
import moment from 'moment';
import { translate } from 'Shared/translate';
import { getDepartureDate } from './utility';
import * as styles from './base.scss';
export default function DeliveryDateSelection(props) {
    return (React.createElement("div", { className: styles.base },
        React.createElement("div", { className: styles.leftColumn }, props.datePickerNode),
        React.createElement("div", { className: styles.rightColumn },
            props.headerViewModel ? (React.createElement("div", null,
                React.createElement("b", null, translate('/DeliveryDate/Article')),
                React.createElement("p", null,
                    props.articleCode,
                    " ",
                    props.articleName),
                React.createElement("b", null, translate('/DeliveryDate/SelectedDate')),
                React.createElement("p", null, moment(getDepartureDate(props.headerViewModel)).format('YYYY-MM-DD HH:MM')),
                React.createElement("b", null, translate('/DeliveryDate/StopTime')),
                React.createElement("p", null, props.headerViewModel.selectedDelivery && props.headerViewModel.selectedDelivery.stopTime))) : (React.createElement("p", null, translate('/DeliveryDate/SelectDateFirst'))),
            props.dialogNode)));
}
