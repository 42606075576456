import classnames from "classnames";
import MyMenigoIcon from "../../../../Shared/MyMenigoIcon/MyMenigoIcon";
import React from "react";
import * as styles from '../../base.scss';
import { translate } from "Shared/translate";
var OrderHistoryProductListHeader = function (_a) {
    var sortField = _a.sortField, sortAscending = _a.sortAscending, setSortedField = _a.setSortedField;
    var sortOn = function (field, secondaryfield) {
        setSortedField(field, secondaryfield);
    };
    var sortableHeader = function (headerText, fieldName, ascending) {
        return (React.createElement("span", { className: styles.sortableLabel },
            headerText,
            " ",
            (sortField == fieldName ? (!ascending ? React.createElement(MyMenigoIcon, { name: "asc" }) : React.createElement(MyMenigoIcon, { name: "desc" })) : React.createElement(React.Fragment, null))));
    };
    return (React.createElement("div", { className: classnames(styles.OrderHistoryDetails__Row, styles.OrderHistoryDetails__Row__Header) },
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell, onClick: function () { return sortOn('categoryName', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/CategoryName"), 'categoryName', sortAscending)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell }),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell, onClick: function () { return sortOn('itemDescription', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/ItemDescription"), 'itemDescription', sortAscending)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell, onClick: function () { return sortOn('unit', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/DeliveredUnit"), 'unit', sortAscending)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell, onClick: function () { return sortOn('orderedQuantity', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/OrderedQuantity"), 'orderedQuantity', sortAscending)),
        React.createElement("div", { className: styles.OrderHistoryDetails__Cell, onClick: function () { return sortOn('purchasePrice', 'itemDescription'); } }, sortableHeader(translate("/MyMenigo/DeliveryBlock/ProductList/PurchasePrice"), 'purchasePrice', sortAscending))));
};
export default OrderHistoryProductListHeader;
