import { TOGGLE_MINICART } from './action-creators';
import { PAGE_LOAD } from './../CurrentPage/action-creators';
export default function (state, action) {
    if (state === void 0) { state = { isOpen: false }; }
    switch (action.type) {
        case TOGGLE_MINICART:
            // Hides Imbox FAQ when minicart is open
            var imboxFAQ = document.getElementById('ImBox__1149_snapifyHidden');
            if (!!imboxFAQ) {
                imboxFAQ.style.display = !state.isOpen ? 'none' : 'block';
            }
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        case PAGE_LOAD: {
            if (state.isOpen) {
                return Object.assign({}, state, {
                    isOpen: false,
                });
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
