var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import classnames from 'classnames';
import AccountBox from '../../../../AccountBox';
import Button from 'Shared/Button';
import Overlay from 'Shared/Overlay';
import { setMyMenigoDropdownOpen, setMyMenigoDropdownClose } from '../../../../MyMenigoDropdown/action-creators';
import * as styles from './LoginLogoutButtons.scss';
import * as button from '../../../../../../../styles/objects/buttons.scss';
import { translate } from 'Shared/translate';
import FeedbackButton from 'Shared/Button/Feedback';
import { logout } from '../../../../../../Account/action-creators';
import MyMenigoIcon from '../../../../../..//MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
var LoginButton = /** @class */ (function (_super) {
    __extends(LoginButton, _super);
    function LoginButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggle = function () {
            if (!_this.props.isOpen) {
                _this.props.setToOpenMyMenigoDropdown();
            }
            else {
                _this.props.setToCloseMyMenigoDropdown();
            }
        };
        _this.menigoButtonClass = function (url) {
            return (url === _this.props.currentPage.url.path || (_this.props.currentPage.url.path + "/").indexOf(url) == 0) ? classnames(button.base, button.bare, styles.myMenigoButton, styles.current) : classnames(button.base, button.bare, styles.myMenigoButton);
        };
        return _this;
    }
    LoginButton.prototype.render = function () {
        var _this = this;
        return this.props.isSupplierSalesman ? (React.createElement(FeedbackButton, { type: "button", appearance: "link", onClickWithFeedback: function (e, activate) { return activate(_this.props.logout()); }, className: "ui-test-logout-button" }, translate('/Account/MyPages/Logout'))) : (this.props.isLoggedIn ?
            React.createElement("a", { href: !!this.props.headerViewModel.myMenigoItems[0] && this.props.headerViewModel.myMenigoItems[0].url, className: this.menigoButtonClass(!!this.props.headerViewModel.myMenigoItems[0] ? this.props.headerViewModel.myMenigoItems[0].url : "/") },
                React.createElement(MyMenigoIcon, { name: "user" }),
                translate('/MyMenigo/Header/MyMenigo'))
            :
                React.createElement("div", { className: styles.loginWrapper },
                    React.createElement(Overlay, { enabled: this.props.isOpen, onClose: this.toggle },
                        React.createElement("div", { className: classnames(this.props.className) },
                            React.createElement(Button, { onClick: this.toggle, className: 'ui-test-user-nav-button', appearance: "primary" },
                                React.createElement(MyMenigoIcon, { name: "user" }),
                                " ",
                                translate('/Account/Login/Header'))),
                        this.props.isOpen && (React.createElement("div", { className: styles.parentDropdown },
                            React.createElement(AccountBox, { close: this.props.setToCloseMyMenigoDropdown, isCloseButton: false }))))));
    };
    return LoginButton;
}(React.PureComponent));
export default connect(function (state) { return ({
    isLoggedIn: !!state.currentUser && state.currentUser.isLoggedIn,
    headerViewModel: state.appShellData.header,
    isOpen: state.myMenigoBox.isOpen,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    currentPage: state.currentPage
}); }, function (dispatch) { return ({
    setToOpenMyMenigoDropdown: function () { return dispatch(setMyMenigoDropdownOpen()); },
    setToCloseMyMenigoDropdown: function () { return dispatch(setMyMenigoDropdownClose()); },
    logout: function () { return dispatch(logout()); },
}); })(LoginButton);
