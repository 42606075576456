import { isInQuery } from "../../../Search/Pages/Search/Components/action-creators";
export var AddFavouriteFilterButton = function (buttons, isActive) {
    var fakeFacet = {
        fieldName: "customFilters",
        displayName: "Custom",
        facetValues: [],
        sortOrder: null,
        canToggleOrAnd: false,
        hasAndFilter: false
    };
    var fakeFacetValue = {
        ticket: "-",
        id: "favorites",
        fieldName: "favorites",
        text: "Favoriter",
        count: 0,
        selected: isInQuery(["favorites"]) || isActive,
        children: []
    };
    buttons.push({
        sortorder: buttons.length + 1,
        selected: isInQuery(["favorites"]) || isActive,
        facetGroup: fakeFacet,
        facet: fakeFacetValue,
        values: [],
        buttonText: "Favoriter",
        icon: "favorite",
        disabled: false
    });
};
export var AddInStockFilterButton = function (buttons, isActive) {
    var fakeFacet = {
        fieldName: "customFilters",
        displayName: "Custom",
        facetValues: [],
        sortOrder: null,
        canToggleOrAnd: false,
        hasAndFilter: false
    };
    var fakeFacetValue = {
        ticket: "-",
        id: "inStock",
        fieldName: "inStock",
        text: "I lager",
        count: 0,
        selected: isInQuery(["inStock"]) || isActive,
        children: []
    };
    buttons.push({
        sortorder: buttons.length + 1,
        selected: isInQuery(["inStock"]) || isActive,
        facetGroup: fakeFacet,
        facet: fakeFacetValue,
        values: [],
        buttonText: "I lager",
        icon: "inStock",
        disabled: false
    });
};
