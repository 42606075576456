import React from 'react';
import classnames from 'classnames';
import FeedbackButton from 'Shared/Button/Feedback';
import { timeout } from 'Shared/timeout';
import * as styles from './base.scss';
export default (function (_a) {
    var _b;
    var text = _a.text, disabled = _a.disabled, onClick = _a.onClick, onClose = _a.onClose, customMarking = _a.customMarking, className = _a.className;
    var onClickHandler = function (e, activate) {
        if (disabled) {
            return;
        }
        var maybePromise = onClick(e);
        if (maybePromise instanceof Promise) {
            e.stopPropagation();
            activate(maybePromise).then(function () { return timeout(1000).then(function () { return onClose && onClose(); }); });
        }
    };
    return (React.createElement("div", { className: className },
        React.createElement(FeedbackButton, { className: classnames(styles.item, styles.button, (_b = {}, _b[styles.disabled] = disabled, _b)), appearance: "bare", onClickWithFeedback: onClickHandler },
            text,
            " ",
            !!customMarking && (React.createElement("span", null, customMarking)))));
});
