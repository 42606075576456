var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import Transition from 'react-transition-group/Transition';
var SharedTransition = /** @class */ (function (_super) {
    __extends(SharedTransition, _super);
    function SharedTransition() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleEnter = function (node, isAppearing) {
            // Force a repaint.
            // https://github.com/reactjs/react-transition-group/blob/5007303e729a74be66a21c3e2205e4916821524b/src/CSSTransition.js#L208-L215
            node && node.scrollTop; // tslint:disable-line:no-unused-expression
            _this.props.onEnter && _this.props.onEnter(node, isAppearing);
        };
        return _this;
    }
    SharedTransition.prototype.render = function () {
        var _a = this.props, _b = _a.mountOnEnter, mountOnEnter = _b === void 0 ? true : _b, _c = _a.unmountOnExit, unmountOnExit = _c === void 0 ? true : _c, _d = _a.appear, appear = _d === void 0 ? true : _d, children = _a.children, restProps = __rest(_a, ["mountOnEnter", "unmountOnExit", "appear", "children"]);
        return (React.createElement(Transition, __assign({ mountOnEnter: mountOnEnter, unmountOnExit: unmountOnExit, appear: appear }, restProps, { onEnter: this.handleEnter }), function (status) { return children(status === 'entering' || status === 'entered'); }));
    };
    return SharedTransition;
}(React.PureComponent));
export default SharedTransition;
