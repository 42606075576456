import React, { memo } from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition';
import ProductCardList from 'Shared/ProductCard/List';
import { viewLayoutTypes } from 'Shared/ViewLayoutSelection';
import { Basic } from 'Shared/PageLayout';
import { translate } from 'Shared/translate';
import * as styles from './add-to-cart-recommendations.scss';
var AddToCartRecommendation = memo(function (props) { return (React.createElement(Basic, { appearance: "narrow", className: styles.base },
    React.createElement("div", { className: styles.title }, translate('/MiniCart/MiniCartRecommendations')),
    React.createElement(ProductCardList, { cardClassName: styles.card, products: props.products, viewLayoutType: viewLayoutTypes.grid.name, wrapCard: function (card, product) { return (React.createElement("div", { key: card.key, className: styles.cardWrapper }, card)); } }))); });
var AddToCartRecommendationWithTransition = function (props) { return (React.createElement(ReactCSSTransitionGroup, { in: props.products.length > 0, classNames: {
        exit: styles.invisible,
        enter: styles.invisible,
        enterActive: styles.visible,
        exitActive: styles.invisible,
    }, mountOnEnter: true, unmountOnExit: true, timeout: { enter: 300, exit: 300 } },
    React.createElement(Basic, { appearance: "default", className: styles.addToCartRecommendations },
        React.createElement(AddToCartRecommendation, { products: props.products })))); };
export default AddToCartRecommendationWithTransition;
