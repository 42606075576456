import localForage from 'localforage';
var requestTimeout = 1000;
var storages = {};
function getStorage(name) {
    if (!storages[name]) {
        storages[name] = localForage.createInstance({ storeName: name });
    }
    return storages[name];
}
export function get(storeName, key) {
    var storage = getStorage(storeName);
    return Promise.race([timeout(), storage.getItem(key)]).then(function (resp) {
        if (resp && resp.timeout) {
            console.debug("Timing out app storage request because it took more than " + requestTimeout + " ms");
            return null;
        }
        return resp;
    });
}
function timeout() {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve({ timeout: true });
        }, requestTimeout);
    });
}
export function clear(storeName) {
    return getStorage(storeName).clear();
}
export function set(storeName, key, item) {
    return getStorage(storeName)
        .setItem(key, item)
        .catch(function (e) { return console.error('Error storing in app storage', item, e); });
}
export function remove(storeName, key) {
    return getStorage(storeName).removeItem(key);
}
