var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Button from 'Shared/Button';
import AddToOptions from './AddToOptions';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
var AddToButton = /** @class */ (function (_super) {
    __extends(AddToButton, _super);
    function AddToButton(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleAddToOption = function () {
            _this.setState({ isOpen: !_this.state.isOpen });
        };
        _this.state = { isOpen: false };
        return _this;
    }
    AddToButton.prototype.render = function () {
        return (React.createElement("div", { className: styles.dropdownParentDiv },
            React.createElement("div", null,
                React.createElement(Button, { appearance: 'clear', onClick: this.toggleAddToOption, disabled: this.props.selectedItems.size === 0 },
                    React.createElement("span", null, translate('/MyMenigo/OrderOverviewHeaders/OrderOverviewHeaderDetails/AddOrder/AddTo')),
                    React.createElement("span", null,
                        React.createElement(Icon, { name: "arrow-down" })))),
            this.state.isOpen && (React.createElement(AddToOptions, { selectedItems: this.props.selectedItems }))));
    };
    return AddToButton;
}(React.Component));
export default AddToButton;
