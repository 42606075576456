import { createStore, applyMiddleware, compose } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import reducers from './reducers';
export default (function (initialState) {
    var createStoreWithMiddleware = compose(applyMiddleware(thunk), 
    // This enables the Redux Dev Tools for Chrome:
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) { return f; })(createStore);
    return createStoreWithMiddleware(enableBatching(reducers), initialState);
});
