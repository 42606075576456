import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransition';
import * as styles from './base.scss';
import classnames from 'classnames';
var transitionClass = function (direction) {
    var _a, _b;
    return ({
        enter: classnames((_a = {},
            _a[styles.forwardEnter] = direction === 'forward',
            _a[styles.backEnter] = direction === 'back',
            _a)),
        enterActive: styles.active,
        exit: classnames((_b = {},
            _b[styles.forwardLeave] = direction === 'forward',
            _b[styles.backLeave] = direction === 'back',
            _b)),
        exitActive: styles.active,
        appear: 'appear',
        appearActive: 'appearActive',
    });
};
export default (function (props) { return (React.createElement(ReactCSSTransitionGroup, { classNames: transitionClass(props.direction), timeout: { enter: 250, exit: 250 } }, props.children)); });
