export var SET_CUSTOMPRODUCTEDIT_ID = "SET_CUSTOMPRODUCTEDIT_ID";
export var RESET_CUSTOMPRODUCTEDIT_ID = "RESET_CUSTOMPRODUCTEDIT_ID";
export default function (state, action) {
    if (state === void 0) { state = {
        input: true,
        id: '',
        keepOpen: false
    }; }
    switch (action.type) {
        case SET_CUSTOMPRODUCTEDIT_ID: {
            return Object.assign({}, state, {
                id: action.code,
            });
        }
        case RESET_CUSTOMPRODUCTEDIT_ID: {
            return Object.assign({}, state, {
                id: '',
            });
        }
        default:
            return state;
    }
}
