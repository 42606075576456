import { updateCurrentPage } from 'SiteLayout/CurrentPage/action-creators';
import { getJson, post, postJson } from 'Shared/server';
import { setFeedback } from './../SiteLayout/GenericFeedbackBar/action-creators';
import { isFavoritesPage } from 'Shared/ListItem/current-page-is';
import { translate } from 'Shared/translate';
import { getDetails, setSpinner, DEFAULT_SORT, sortFuncs, sortLists, } from 'Shared/ListItem/action-creators';
var insertListFromResponse = function (list, res, stayOpen) {
    return Object.assign({}, list, res, {
        sortBy: DEFAULT_SORT,
        products: res.products.map(function (item) { return Object.assign({}, item); }).sort(sortFuncs[list.sortBy || DEFAULT_SORT]),
        isLoaded: stayOpen || !list.isLoaded,
        isLoading: false,
    });
};
var removeFromRecommendation = function (recommendation, codes) {
    var newProducts = recommendation.products.filter(function (product) { return !codes.includes(product.selectedVariant.code); });
    Object.assign(recommendation, {
        products: newProducts,
        count: newProducts.length,
        displayName: translate('/Account/ProductList/FavoriteRecommendation', newProducts.length.toString()),
    });
};
export var toggleListSelection = function (listId, selected) { return function (dispatch, getState) {
    return isFavoritesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                lists: current.lists.map(function (list) {
                    return list.id !== listId
                        ? list
                        : !list.products
                            ? Object.assign({}, list, { selected: !selected })
                            : Object.assign({}, list, {
                                products: list.products && list.products.map(function (item) { return Object.assign({}, item, { selected: !selected }); }),
                                selected: !selected,
                            });
                }),
            });
        }));
}; };
export var addRecommendations = function (fromlistId, listId, codes) { return function (dispatch, getState) {
    if (!isFavoritesPage(getState())) {
        return Promise.reject('Is not favorites page. Failed to add recommendations');
    }
    return post(getState().appShellData.siteSettings.favoriteOverviewPage + "/addProducts", { listId: listId, codes: codes })
        .then(function () {
        dispatch(updateCurrentPage(function (current) {
            removeFromRecommendation(current.recommendations[0], codes);
        }));
        dispatch(getDetails(listId));
        return Promise.resolve();
    })
        .catch(failedPromiseHandler);
}; };
export var sortRecommendations = function (listId, order) { return function (dispatch, getState) {
    return isFavoritesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                recommendations: current.recommendations.map(function (list) {
                    return list.id !== listId
                        ? list
                        : !list.products
                            ? list
                            : Object.assign({}, list, {
                                products: list.products.sort(sortFuncs[order]),
                                sortBy: order,
                            });
                }),
            });
        }));
}; };
export var addRecommendationsToNewList = function (listId, displayName, codes) { return function (dispatch, getState) {
    if (!isFavoritesPage(getState())) {
        return Promise.reject('Is not favorites page. Failed to add recommendations to new list');
    }
    var regex = new RegExp('[ ]{2,}');
    var trimmedDisplayName = displayName.replace(regex, ' ').trim();
    return postJson(getState().appShellData.siteSettings.favoriteOverviewPage + "/AddRecommendations", {
        codes: codes,
        displayName: trimmedDisplayName,
    })
        .then(function (res) {
        if (!res.success) {
            dispatch(setFeedback({
                text: res.message,
                type: 'error',
            }));
            return Promise.reject('Failed to add recommendations to new list');
        }
        else {
            dispatch(updateCurrentPage(function (current) {
                removeFromRecommendation(current.recommendations[0], codes);
                dispatch(setFeedback({ text: res.message, type: 'success' }));
                return Object.assign(current, {
                    lists: current.lists
                        .map(function (list) { return (list.id !== listId ? list : Object.assign({}, list, { selected: false })); })
                        .concat([res.favoriteListSummary])
                        .sort(sortLists),
                });
            }, true));
            return Promise.resolve();
        }
    })
        .catch(failedPromiseHandler);
}; };
export var removeRecommendationFromList = function (productIds) { return function (dispatch, getState) {
    return post(getState().appShellData.siteSettings.favoriteOverviewPage + "/removeRecommendations", productIds)
        .then(function () {
        if (isFavoritesPage(getState())) {
            dispatch(updateCurrentPage(function (current) {
                removeFromRecommendation(current.recommendations[0], productIds);
            }));
            return Promise.resolve();
        }
        else {
            return Promise.reject('Is not favorites page. Failed to remove recommendation from list');
        }
    })
        .catch(failedPromiseHandler);
}; };
export var getRecommendationDetails = function (listId) { return function (dispatch, getState) {
    if (!isFavoritesPage(getState())) {
        return;
    }
    dispatch(setSpinner(listId));
    getJson(getState().appShellData.siteSettings.favoriteOverviewPage + "/recommendationdetails?id=" + listId).then(function (res) {
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                recommendations: current.recommendations.map(function (list) {
                    return insertListFromResponse(list, res, true);
                }),
            });
        }));
    });
}; };
export var markAll = function (selected) { return function (dispatch, getState) {
    isFavoritesPage(getState()) &&
        dispatch(updateCurrentPage(function (current) {
            return Object.assign(current, {
                lists: current.lists.map(function (list) {
                    return Object.assign({}, list, {
                        products: list.products && list.products.map(function (item) { return Object.assign({}, item, { selected: selected }); }),
                        selected: selected,
                    });
                }),
            });
        }));
}; };
function failedPromiseHandler(e) {
    console.error(e);
    return Promise.reject(null);
}
