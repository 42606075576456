var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import classnames from 'classnames';
import Overlay from 'Shared/Overlay';
import connect from 'Shared/connect';
import { formatPrice } from 'Shared/number-format';
import { toggleMiniCart } from '../../../../../MiniCart/action-creators';
import { reloadCart } from '../../../../../../Cart/action-creators';
import { SMALL } from 'Shared/breakpoints';
import { isIOS } from 'Shared/device-type';
import * as miniCartStyles from './mini-cart-button.scss';
import MyMenigoIcon from '../../../../../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { hideDeliveryDays } from '../../../../action-creators';
function getCount(cart) {
    if (!!cart.items && cart.items.length > 0) {
        var codes = cart.items.map(function (x, i) {
            return x.lineItem.code;
        });
        if (codes.length > 0) {
            var distinctCodes = codes.reduce(function (distinct, code) { return (distinct.indexOf(code) !== -1 ? distinct : __spread(distinct, [code])); }, []);
            if (distinctCodes.length === 1) {
                return distinctCodes.length;
            }
            return distinctCodes.length;
        }
    }
    return 0;
}
var MiniCartButton = /** @class */ (function (_super) {
    __extends(MiniCartButton, _super);
    function MiniCartButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggleOverlay = function () {
            _this.props.hideDeliveryDays();
            _this.props.reloadCart();
            if (_this.props.currentBreakpoint > SMALL) {
                !_this.props.miniCartIsOpen && _this.attachOutsideClickOnOverlay();
                !!_this.props.miniCartIsOpen && _this.detatchOutsideClickOnOverlay();
            }
            !!!_this.props.deliveryDaysIsVisible && _this.props.toggleMiniCart();
        };
        _this.attachOutsideClickOnOverlay = function () {
            document.addEventListener('click', _this.clickOutside);
        };
        _this.detatchOutsideClickOnOverlay = function () {
            document.removeEventListener('click', _this.clickOutside);
        };
        _this.clickOutside = function (evt) {
            if (!_this.miniCartElement) {
                return;
            }
            if ((!!_this.button.contains(evt.target)) || (!!_this.miniCartElement.contains(evt.target))) {
                _this.toggleOverlay();
            }
        };
        return _this;
    }
    MiniCartButton.prototype.render = function () {
        var _this = this;
        var _a;
        var isTooltip = this.props.currentBreakpoint > SMALL;
        var count = getCount(this.props.cart);
        var formattedPrice = formatPrice(!!this.props.cart.subTotal ? this.props.cart.subTotal.current : 0) +
            ' ' +
            (!!this.props.cart.subTotal ? this.props.cart.subTotal.currencySymbol : '');
        if (this.props.isRestrictedCustomer) {
            return null;
        }
        return (React.createElement("div", { ref: function (el) { return _this.miniCartElement = el; } },
            React.createElement(Overlay, { enabled: isTooltip && this.props.miniCartIsOpen, className: classnames(miniCartStyles.base, (_a = {}, _a[miniCartStyles.ios] = isIOS(), _a), 'ui-test-mini-cart') },
                React.createElement("button", { type: "button", "data-total": !this.props.isSupplierSalesman ? formattedPrice : '', "data-count": !!count ? count : 0, onClick: this.toggleOverlay, className: classnames(miniCartStyles.button, this.props.deliveryDaysIsVisible && miniCartStyles.inactive, this.props.miniCartIsOpen && miniCartStyles.open, this.props.cart.items.length > 0 ? miniCartStyles.Cart__NotEmpty : miniCartStyles.Cart__Empty), ref: function (el) { return (_this.button = el); } },
                    React.createElement("div", { className: classnames(!!!this.props.miniCartIsOpen && miniCartStyles.inner) },
                        React.createElement("div", { className: miniCartStyles.CartIcon },
                            count > 0 && React.createElement("span", { className: miniCartStyles.orderRows }, count),
                            React.createElement(MyMenigoIcon, { name: "shoppingbag" })),
                        React.createElement("div", null, !this.props.isSupplierSalesman && count > 0 ? formattedPrice : ''))))));
    };
    return MiniCartButton;
}(React.Component));
export default connect(function (state) { return ({
    miniCartIsOpen: state.miniCart.isOpen,
    currentBreakpoint: state.currentBreakpoint,
    cart: state.cart,
    isSupplierSalesman: state.currentUser.isSupplierSalesman,
    isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
}); }, function (dispatch) { return ({
    toggleMiniCart: function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(toggleMiniCart());
    },
    reloadCart: function () {
        return dispatch(reloadCart());
    },
    hideDeliveryDays: function () {
        return dispatch(hideDeliveryDays());
    },
}); })(MiniCartButton);
