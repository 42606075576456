var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import Item from './Item';
import * as styles from './base.scss';
var Accordion = /** @class */ (function (_super) {
    __extends(Accordion, _super);
    function Accordion(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            open: null,
        };
        return _this;
    }
    Accordion.prototype.toggle = function (item) {
        this.setState({ open: item !== this.state.open ? item : null });
    };
    Accordion.prototype.render = function () {
        var _this = this;
        return (this.props.children && (React.createElement("div", { className: classnames(styles.base, this.props.className) }, React.Children.map(this.props.children, function (child) {
            if (React.isValidElement(child) && child.type === Item) {
                return React.cloneElement(child, {
                    open: _this.state.open,
                    toggle: function (item) { return _this.toggle(item); },
                });
            }
        }))));
    };
    return Accordion;
}(React.Component));
export default Accordion;
