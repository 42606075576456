import * as server from 'Shared/server';
import clone from 'clone';
import { replaceState } from 'Shared/history';
import { parseUrl, urlToString, areEqual } from 'Shared/url';
import { batchActions } from 'redux-batched-actions';
import * as cache from './cache';
export var PAGE_LOAD = 'PAGE_LOAD';
export var PAGE_LOAD_SUCCESS = 'PAGE_LOAD_SUCCESS';
export var PAGE_LOAD_FAILURE = 'PAGE_LOAD_FAILURE';
export var PAGE_LOAD_DONE = 'PAGE_LOAD_DONE';
export var PAGE_LOAD_ABORTED = 'PAGE_LOAD_ABORTED';
export var PAGE_UNHANDLED_ERROR = 'PAGE_UNHANDLED_ERROR';
export var PAGE_UPDATE = 'PAGE_UPDATE';
export var PAGE_UPDATE_SUCCESS = 'PAGE_UPDATE_SUCCESS';
export var PAGE_UPDATE_FAILURE = 'PAGE_UPDATE_FAILURE';
export var SHOW_PAGE_SPINNER = 'SHOW_PAGE_SPINNER';
export var HIDE_PAGE_SPINNER = 'HIDE_PAGE_SPINNER';
var lastPageLoadRequest = 0;
export function unhandledError(error) {
    return {
        type: PAGE_UNHANDLED_ERROR,
        error: {
            message: error,
        },
    };
}
var clientRoutes = {};
var hasClientRoutes = false;
function ensureSlashes(url) {
    if (!url.startsWith('/')) {
        url = '/' + url;
    }
    if (!url.endsWith('/')) {
        url = url + '/';
    }
    return url;
}
export function registerClientRoute(url, routeDescendents, data) {
    hasClientRoutes = true;
    clientRoutes[ensureSlashes(url)] = { routeDescendents: routeDescendents, data: data };
}
export var togglePageSpinner = function (show) { return ({ type: show ? SHOW_PAGE_SPINNER : HIDE_PAGE_SPINNER }); };
export function updateCurrentPage(updater, shallowClone) {
    return function (dispatch, getState) {
        var page = shallowClone ? clone(getState().currentPage, false, 1) : clone(getState().currentPage);
        updater(page);
        dispatch({
            type: PAGE_UPDATE_SUCCESS,
            page: page,
        });
    };
}
function makeFullData(data, url) {
    return Object.assign({}, data, {
        componentName: data.componentName,
        url: url,
        isLoading: data.isLoading || false,
        showLoadingSpinner: data.showLoadingSpinner || false,
        loadFailure: data.loadFailure && {
            status: data.loadFailure.status,
            url: url,
            isShowingFullCachedPage: false,
            isShowingPartialCachedPage: false,
        },
        unhandledError: data.unhandledError || null,
        reload: data.reload,
        isPartial: data.isPartial || false,
        scrollPosition: data.scrollPosition || 0,
        meta: { title: (data.meta && data.meta.title) || '', elements: (data.meta && data.meta.elements) || {} },
    });
}
function loadClientRoute(url) {
    if (!hasClientRoutes) {
        return null;
    }
    var path = ensureSlashes(url.path);
    var full = true;
    while (true) {
        var route = clientRoutes[path];
        if (route && (full || route.routeDescendents)) {
            var data = route.data;
            if (data instanceof Function) {
                var result = data(Object.assign({}, url, { path: url.path.substr(path.length - 1) || '/' }));
                if (result) {
                    return result instanceof Promise
                        ? result.then(function (x) { return makeFullData(x, url); })
                        : Promise.resolve(makeFullData(result, url));
                }
            }
            else {
                return Promise.resolve(makeFullData(data, url));
            }
        }
        if (path === '/') {
            return null;
        }
        path = path.substring(0, path.lastIndexOf('/', path.length - 2) + 1);
        full = false;
    }
}
function loadClientRouteResult(dispatch, pagePromise, url, urlBeforeLoad, scrollPosition, options) {
    dispatch({
        type: PAGE_LOAD,
        url: url,
        urlBeforeLoad: urlBeforeLoad,
        cachedItem: null,
        scrollPosition: scrollPosition,
        options: options,
    });
    return pagePromise.then(function (page) {
        dispatch(batchActions([
            {
                type: PAGE_LOAD_SUCCESS,
                page: page,
                scrollPosition: scrollPosition,
                options: options,
                url: url,
                urlBeforeLoad: urlBeforeLoad,
                isCurrentPage: true,
            },
            {
                type: PAGE_LOAD_DONE,
                serverRedirected: false,
            },
        ]));
    });
}
export function loadPage(url, options, stateChangeEvent, shouldUseResponse) {
    if (options === void 0) { options = {}; }
    return function (dispatch, getState) {
        var _a;
        var urlObject = typeof url === 'string' ? parseUrl(url) : url;
        var currentRequest = (lastPageLoadRequest = Math.random());
        var scrollPosition = 0;
        if (stateChangeEvent && stateChangeEvent.state.scrollPosition) {
            scrollPosition = stateChangeEvent.state.scrollPosition;
        }
        var urlBeforeLoad = getState().currentPage.url;
        var clientRouteResult = loadClientRoute(urlObject);
        if (clientRouteResult) {
            return loadClientRouteResult(dispatch, clientRouteResult, urlObject, urlBeforeLoad, scrollPosition, options);
        }
        var userIsGoingToNewPage = !(stateChangeEvent && stateChangeEvent.event === 'pop');
        var headers;
        if (options.ticket) {
            headers = (_a = {},
                _a[server.ESALES_TICKET_HEADER] = options.ticket,
                _a[server.ESALES_ACTION_HEADER] = server.ESALES_CLICK_ACTION,
                _a);
        }
        var loadPromise = null;
        var load = function () {
            if (loadPromise) {
                return loadPromise;
            }
            loadPromise = server.get(urlToString(urlObject, true), headers);
            return loadPromise;
        };
        // If the user is going to a new page, we start the network request early
        // because the cache can take ~50ms to respond and we don't want to wait
        // ~50ms to start the request.
        // If the user isn't going to a new page but going back, we don't want to
        // issue a new request because we should have that page cached. But if we don't
        // we load it after we know that the cache didn't give us anything.
        if (userIsGoingToNewPage) {
            load();
        }
        var serverRedirected = false;
        var urlString = urlToString(urlObject);
        return cache.get(urlString).then(function (cachedItem) {
            // This check is needed because we might want to ignore a cached response
            // like if you searched for something that gave no results we don't want to use
            // that response in a preview search.
            if (cachedItem && shouldUseResponse && !shouldUseResponse(cachedItem)) {
                cachedItem = null;
            }
            var willLoadPage = userIsGoingToNewPage || !cachedItem || cachedItem.$cache === 'partial';
            var actions = [
                {
                    type: PAGE_LOAD,
                    url: urlObject,
                    urlBeforeLoad: urlBeforeLoad,
                    cachedItem: cachedItem,
                    scrollPosition: scrollPosition,
                    options: options,
                },
            ];
            var pageLoadDone = {
                type: PAGE_LOAD_DONE,
                serverRedirected: serverRedirected,
            };
            if (!willLoadPage) {
                actions.push(pageLoadDone);
            }
            try {
                dispatch(batchActions(actions));
            }
            catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                    var div_1 = document.createElement('div');
                    div_1.style.position = 'fixed';
                    div_1.style.zIndex = '999999999';
                    div_1.style.border = '5px solid red';
                    div_1.style.background = 'white';
                    div_1.onclick = function () { return document.body.removeChild(div_1); };
                    div_1.style.padding = '20px';
                    div_1.style.top = '10px';
                    div_1.style.left = '10px';
                    div_1.style.right = '10px';
                    div_1.style.bottom = '10px';
                    div_1.innerHTML =
                        '<h1 style="color:red">The page could not be rendered from a cached response</h1>' +
                            '<p>It seems that the current page can not be rendered with a cached response. This ' +
                            'most likely happened because the backend has sent new data to this page and the component to render it assumes' +
                            ' that it is there. If you absolutely must do this and can not check if the data is there, make sure that the next release ' +
                            'increments the major version as that will clear the users cache.<br>' +
                            'The full error has been logged to the console.' +
                            '<h4>Error:</h4>' +
                            '<pre>' +
                            e.message +
                            '</pre>';
                    document.body.appendChild(div_1);
                    console.error(e);
                }
                else {
                    // If this error occurs in production we can't really recover from it
                    // So we send it to Raygun and reload the page since that will
                    window.newrelic && window.newrelic.noticeError(e);
                    return cache.clear(urlString).then(function () {
                        window.location.reload();
                        return new Promise(function () { return null; });
                    });
                }
            }
            // We only want to load the page from the server in the case that:
            // - The user is not going back to a page shes seen before
            // - The page we're displaying is only partially loaded
            // - We're in a loading state because we're waiting for the new page
            if (willLoadPage) {
                return load()
                    .then(function (r) {
                    // This allows the server to use redirects. When the server redirects us
                    // `fetch` won't tell us that, so the url in the address bar won't reflect
                    // what we loaded. By using a custom header we can get what `fetch` won't give us.
                    // When that happens we call `replaceState` with a flag that we shouldn't give
                    // any events for it since we don't need to load that page.
                    var actualUrlHeader = 'x-actual-url';
                    var isCurrentPage = currentRequest === lastPageLoadRequest;
                    if (r.headers.has(actualUrlHeader) && isCurrentPage) {
                        var actualUrl_1 = parseUrl(r.headers.get(actualUrlHeader));
                        var currentUrl = parseUrl(window.location);
                        if (actualUrl_1.path !== currentUrl.path) {
                            urlObject.hiddenQuery && Object.keys(urlObject.hiddenQuery).forEach(function (key) { return delete actualUrl_1.query[key]; });
                            delete actualUrl_1.query[server.AJAX_REQUEST_QUERY_STRING];
                            replaceState(actualUrl_1, {}, false);
                            serverRedirected = true;
                        }
                    }
                    if (!IS_APP && server.serverHasDifferentVersion(r)) {
                        console.debug('Reloading the page since the server has a new version');
                        // Note that something similar to this is already done in server.tsx
                        // but that only checks for a major version change. Here we check for
                        // any version change and only clear the service worker cache. We don't
                        // unregister the SW or clear other caches, we just want the new scripts/styles
                        cache.nuke().then(function () {
                            window.location.reload();
                        });
                        // Return never resolving promise to wait for the page to reload
                        return new Promise(function () { return null; });
                    }
                    return r.json();
                })
                    .then(function (json) {
                    var shouldUse = shouldUseResponse ? shouldUseResponse(json) : true;
                    if (!shouldUse) {
                        console.debug('Ignoring response because initiator did not want it');
                        return dispatch({
                            type: PAGE_LOAD_ABORTED,
                        });
                    }
                    var isCurrentPage = currentRequest === lastPageLoadRequest;
                    dispatch(batchActions([
                        {
                            type: PAGE_LOAD_SUCCESS,
                            page: json,
                            scrollPosition: scrollPosition,
                            options: options,
                            url: urlObject,
                            urlBeforeLoad: urlBeforeLoad,
                            isCurrentPage: isCurrentPage,
                        },
                        {
                            type: PAGE_LOAD_DONE,
                            serverRedirected: serverRedirected,
                        },
                    ]));
                })
                    .catch(function (e) {
                    if (process.env.NODE_ENV === 'production') {
                        window.newrelic && window.newrelic.noticeError(e);
                    }
                    else {
                        console.error(e);
                    }
                    if (currentRequest !== lastPageLoadRequest) {
                        console.debug('Ignoring page load failure because another request is in-flight');
                        return Promise.resolve(null);
                    }
                    var urlHasNotChangedSinceLoad = areEqual(getState().currentPage.url, urlBeforeLoad);
                    var stillWaitingForPage = urlHasNotChangedSinceLoad && getState().currentPage.isLoading && !getState().currentPage.isPartial;
                    var retry;
                    if (stillWaitingForPage && stateChangeEvent) {
                        // If we're still waiting for the page and haven't been able to show a preview
                        // of the new page, revert the state change and make `retry` be the opposite of
                        // the revert
                        retry = stateChangeEvent.revert();
                    }
                    else {
                        // If we've managed to show something to the user on the new page but the full
                        // page failed to load, we just try to load it again
                        retry = function () { return loadPage(url, options, stateChangeEvent)(dispatch, getState); };
                    }
                    dispatch(batchActions([
                        {
                            type: PAGE_LOAD_FAILURE,
                            error: e,
                            url: urlObject,
                            retry: retry,
                            loadFailure: {
                                url: urlObject,
                                status: e.status,
                                isShowingFullCachedPage: !urlHasNotChangedSinceLoad &&
                                    !getState().currentPage.isLoading &&
                                    !getState().currentPage.isPartial,
                                isShowingPartialCachedPage: !urlHasNotChangedSinceLoad && getState().currentPage.isPartial,
                            },
                        },
                        {
                            type: PAGE_LOAD_DONE,
                            serverRedirected: serverRedirected,
                        },
                    ]));
                    // If we're still on the same page as when we started loading, reject
                    // the load as we haven't reacted at all to it and we need to reset
                    if (stillWaitingForPage) {
                        return Promise.reject(e);
                    }
                    else {
                        return Promise.resolve(null);
                    }
                });
            }
        });
    };
}
