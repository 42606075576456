var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import Overlay from 'Shared/Overlay';
import Button from 'Shared/Button';
import Icon from 'Shared/Icon';
import { RootContext } from 'SiteLayout/Root';
import Transition from 'Shared/Transition';
import * as styles from './base.scss';
export var ModalType;
(function (ModalType) {
    ModalType[ModalType["None"] = 0] = "None";
    ModalType[ModalType["Success"] = 1] = "Success";
    ModalType[ModalType["Warning"] = 2] = "Warning";
    ModalType[ModalType["Danger"] = 3] = "Danger";
})(ModalType || (ModalType = {}));
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Modal.prototype.componentDidMount = function () {
        this.forceUpdate();
    };
    Modal.prototype.renderTitleIfProvided = function () {
        var _a;
        return this.props.title ? (React.createElement("h2", { className: classnames(styles.title, (_a = {},
                _a[styles.reserveSpaceForCloseButton] = this.props.reserveSpaceForCloseButton,
                _a)) }, this.props.title)) : null;
    };
    Modal.prototype.getModalIconFromType = function (type) {
        if (type === void 0) { type = this.props.type; }
        var wrapElement = function (el) { return React.createElement("div", { className: styles.iconWrapper }, el); };
        if (type === ModalType.Danger) {
            return wrapElement(React.createElement(Icon, { name: "remove-circle" }));
        }
        else if (type === ModalType.Warning) {
            return wrapElement(React.createElement(Icon, { name: "exclamation" }));
        }
        else if (type === ModalType.Success) {
            return wrapElement(React.createElement(Icon, { name: "check" }));
        }
        return null;
    };
    Modal.prototype.renderComponent = function () {
        var _this = this;
        var _a = this.props, onClose = _a.onClose, isOpen = _a.isOpen, className = _a.className, childContainerClassname = _a.childContainerClassname, reserveSpaceForCloseButton = _a.reserveSpaceForCloseButton, children = _a.children, fadeDesign = _a.fadeDesign;
        return (React.createElement(Overlay, { enabled: isOpen, onClose: onClose },
            React.createElement(Transition, { in: isOpen, timeout: 200, appear: false }, function (active) {
                var _a, _b;
                return (React.createElement("div", { className: classnames(styles.base, className, (_a = {}, _a[styles.active] = active, _a[styles.fadeModal] = !!fadeDesign, _a)) },
                    React.createElement("div", { className: styles.innerContainer },
                        _this.renderTitleIfProvided(),
                        _this.getModalIconFromType(),
                        React.createElement("div", { className: classnames(childContainerClassname, (_b = {},
                                _b[styles.reserveSpaceForCloseButton] = !_this.props.title && reserveSpaceForCloseButton,
                                _b)) }, children),
                        React.createElement(Button, { className: styles.close, appearance: "clear", onClick: onClose },
                            React.createElement(Icon, { name: 'close' })))));
            })));
    };
    Modal.prototype.render = function () {
        return this.context.rootElementRef
            ? ReactDOM.createPortal(this.renderComponent(), this.context.rootElementRef)
            : null;
    };
    return Modal;
}(React.PureComponent));
Modal.contextType = RootContext;
export default Modal;
