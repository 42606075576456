// Are we rendering on the server or are we in the browser?
export function isBrowser() {
    return typeof document !== 'undefined';
}
export function isServiceWorker() {
    return !isBrowser() && typeof navigator !== 'undefined' && cachesObjectExistsAndIsCacheStorage();
}
function cachesObjectExistsAndIsCacheStorage() {
    // https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
    return typeof caches !== 'undefined' && caches.delete && caches.has && caches.keys && caches.match && caches.open;
}
export function isTouch() {
    // Detection explained: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
    return (isBrowser() && 'ontouchstart' in window) || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}
var isCurrentScrollBar;
export function isScrollBar() {
    return isCurrentScrollBar || (isCurrentScrollBar = isBrowser() && window.innerWidth > document.body.offsetWidth);
}
export function iOSVersion() {
    if (!isIOS()) {
        throw new Error('Can not get iOS version on a none iOS device');
    }
    var versionString = navigator.userAgent.match(/\sOS ([0-9+]_[0-9+])/)[1];
    if (!versionString) {
        return 0; // We don't know
    }
    return parseFloat(versionString.replace('_', '.'));
}
export function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
}
export function isLandscape() {
    return isBrowser() && window.screen.width > window.screen.height;
}
export function isPortrait() {
    return isBrowser() && !isLandscape();
}
export function isIE() {
    return /MSIE|Trident/.test(navigator.userAgent);
}
export function isEdge() {
    return /Edge/.test(navigator.userAgent);
}
export function isFF() {
    return /Firefox/.test(navigator.userAgent);
}
export function isSF() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
export function isAndroid() {
    return /android/i.test(navigator.userAgent);
}
export function externalSvgUseSupported() {
    return !isIE() && !/Edge\/12/.test(navigator.userAgent);
}
function currentStyleProperty(name, element) {
    if (element === void 0) { element = isBrowser() ? document.body : null; }
    if (!isBrowser()) {
        return '';
    }
    var nameCap = name.charAt(0).toUpperCase() + name.slice(1);
    var transform;
    [name, "webkit" + nameCap, "Moz" + nameCap, "O" + nameCap, "ms" + nameCap].every(function (e) {
        if (typeof element.style[e] !== 'undefined') {
            transform = e;
            return false;
        }
        return true;
    });
    return transform;
}
export var supportAsyncStorage = (function () {
    if (!isBrowser() && !isServiceWorker()) {
        return false;
    }
    if (isServiceWorker()) {
        return true;
    }
    var windowAny = window;
    return (!!windowAny.openDatabase ||
        !!windowAny.indexedDB ||
        !!windowAny.webkitIndexedDB ||
        !!windowAny.mozIndexedDB ||
        !!windowAny.msIndexedDB);
})();
export var transformProperty = currentStyleProperty('transform');
export var transitionProperty = currentStyleProperty('transition');
export var transitionEndEvent = (function () {
    if (!isBrowser()) {
        return 'transitionend';
    }
    var el = document.createElement('div');
    var transitions = {
        transition: 'transitionend',
        OTransition: 'oTransitionEnd',
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
    };
    for (var t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
})();
