var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import connect from 'Shared/connect';
import currentPageIsOrderConfirmation from '../Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
var CustomVariables = function (_a) {
    var currentUser = _a.currentUser, currentPage = _a.currentPage;
    var unknownValue = "unknown";
    var _b = __read(useState(unknownValue), 2), orderId = _b[0], setOrderId = _b[1];
    useEffect(function () {
        if (currentPageIsOrderConfirmation(currentPage)) {
            setOrderId(currentPage.models.map(function (x) { return x.orderNumber; }).join(","));
        }
        SetCustomVariables();
    });
    var SetCustomVariables = function () {
        Object.assign(window, { getFeedback: {
                customerId: !!currentUser && !!currentUser.customerId ? currentUser.customerId : unknownValue,
                isLoggedIn: !!currentUser.isLoggedIn ? "yes" : "no",
                customerType: !!currentUser.customerType ? currentUser.customerType : unknownValue,
                marketSegment: !!currentUser.trackingUser && !!currentUser.trackingUser.customerType ? currentUser.trackingUser.customerType : unknownValue,
                storageId: !!currentUser.storeId && !!currentUser.isLoggedIn ? currentUser.storeId : unknownValue,
                orderId: orderId
            } });
    };
    return (React.createElement("div", { style: { display: 'none' }, id: "CustomVariables" },
        React.createElement("input", { type: "hidden", id: "customerId", name: "customerId", value: !!currentUser && !!currentUser.customerId ? currentUser.customerId : unknownValue }),
        React.createElement("input", { type: "hidden", id: "isLoggedIn", name: "isLoggedIn", value: !!currentUser.isLoggedIn ? "yes" : "no" }),
        React.createElement("input", { type: "hidden", id: "customerType", name: "customerType", value: !!currentUser.customerType ? currentUser.customerType : unknownValue }),
        React.createElement("input", { type: "hidden", id: "marketSegment", name: "marketSegment", value: !!currentUser.trackingUser && !!currentUser.trackingUser.customerType ? currentUser.trackingUser.customerType : unknownValue }),
        React.createElement("input", { type: "hidden", id: "storageId", name: "storageId", value: !!currentUser.storeId && !!currentUser.isLoggedIn ? currentUser.storeId : unknownValue }),
        React.createElement("input", { type: "hidden", id: "orderId", name: "orderId", value: orderId })));
};
export default connect(function (state) { return ({
    currentUser: state.currentUser,
    currentPage: state.currentPage,
}); })(CustomVariables);
