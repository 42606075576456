import { OPEN_MAINMENU, CLOSE_MAINMENU, TOGGLE_MAINMENU } from './action-creators';
import { PAGE_LOAD } from '../CurrentPage/action-creators';
import { UPDATE_APP_SHELL_DATA } from '../../AppShell/action-creators';
export default function (state, action, appShellData) {
    if (state === void 0) { state = null; }
    if (state === null) {
        state = Object.assign({ isOpen: false }, appShellData.mainMenu);
    }
    switch (action.type) {
        case OPEN_MAINMENU:
            return Object.assign({}, state, { isOpen: true });
        case CLOSE_MAINMENU:
            return Object.assign({}, state, { isOpen: false });
        case TOGGLE_MAINMENU: {
            return Object.assign({}, state, {
                isOpen: !state.isOpen,
            });
        }
        case UPDATE_APP_SHELL_DATA: {
            var updateAppShellDataAction = action;
            return Object.assign({ isOpen: state.isOpen }, updateAppShellDataAction.appShellData.mainMenu);
        }
        case PAGE_LOAD: {
            if (state.isOpen) {
                return Object.assign({}, state, {
                    isOpen: false,
                });
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
