import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
export default (function (props) {
    var _a;
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: classnames(styles.base, (_a = {},
                _a[styles.animate] = props.animate !== false,
                _a)) },
            React.createElement("svg", { className: styles.graphics, viewBox: "25 25 50 50" },
                React.createElement("circle", { className: styles.path, cx: "50", cy: "50", r: "20", fill: "none", strokeWidth: "5", strokeMiterlimit: "10" })))));
});
