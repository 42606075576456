var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import { formatPrice } from 'Shared/number-format';
import connect from 'Shared/connect';
import * as styles from './base.scss';
var Price = /** @class */ (function (_super) {
    __extends(Price, _super);
    function Price() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Price.prototype.render = function () {
        var _a;
        if (this.props.current === undefined || this.props.IsSupplierSalesman) {
            return null;
        }
        var props = this.props;
        var size = props.size || 'normal';
        var baseProps = {
            className: classnames(styles.base, props.className, (_a = {},
                _a[styles.small] = size === 'small',
                _a[styles.medium] = size === 'medium',
                _a[styles.large] = size === 'large',
                _a[styles.huge] = size === 'huge',
                _a[styles.gigantic] = size === 'gigantic',
                _a[styles.isPending] = props.isPending,
                _a)),
        };
        var priceProps = {};
        var showSamllestUnitPrice = props.showAsSmallestUnitPrice && !isNaN(props.smallestUnitPrice) && props.smallestUnitPrice > 0;
        if (props.microData) {
            baseProps.itemProp = 'offers';
            baseProps.itemType = 'http://schema.org/Offer';
            baseProps.itemScope = true;
            priceProps.itemProp = 'price';
        }
        var trackingProps = {
            'data-id': props.id,
            'data-discount': (props.original - props.current).toFixed(2)
        };
        return props.current !== null ? (React.createElement("span", __assign({}, baseProps, trackingProps),
            props.microData && React.createElement("meta", { itemProp: "priceCurrency", content: translate('/Price/Currency') }),
            showSamllestUnitPrice && (React.createElement("span", { className: classnames(styles.current) },
                React.createElement("span", __assign({}, priceProps), formatPrice(props.smallestUnitPrice, props.formatAppearance)),
                React.createElement("span", { className: styles.unit },
                    props.currencySymbol,
                    props.priceUnit && ' / ' + translate('/Price/SmallestUnitPrice')))),
            React.createElement("span", { className: classnames(showSamllestUnitPrice ? styles.current_sup : styles.current) },
                React.createElement("span", __assign({}, priceProps), formatPrice(props.current, props.formatAppearance)),
                React.createElement("span", { className: classnames(styles.unit, 'ui-test-unit-price') },
                    props.currencySymbol,
                    props.priceUnit && ' / ' + props.priceUnit)),
            !isNaN(props.comparison) && props.comparison > 0 && (React.createElement("span", { className: styles.comparePrice },
                translate('/Price/ComparisonPrice', formatPrice(props.comparison, props.formatAppearance)),
                !!props.comparisonPriceUnit && (React.createElement("span", null,
                    ' ',
                    props.currencySymbol,
                    "/",
                    props.comparisonPriceUnit)))))) : (React.createElement("span", { className: classnames(styles.noPrice, props.noPriceClassName) }, translate('/Price/NoPriceAvailable')));
    };
    return Price;
}(React.PureComponent));
export default connect(function (state) { return ({
    IsSupplierSalesman: state.currentUser.isSupplierSalesman,
}); }, null)(Price);
