import classnames from 'classnames';
import React from 'react';
import PropertyFor from 'Shared/PropertyFor';
import * as categorystyles from '../../Category/Commerce/base.scss';
import * as styles from '../../Campaign/Pages/Campaign/base.scss';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import propertyValueFor from 'Shared/property-value-for';
var CampaignPageHeader = function (props) {
    var campaigns = props.campaigns;
    return (React.createElement("div", { className: classnames(styles.campaignHeader) },
        React.createElement("div", { className: styles.inner },
            !!props.breadcrumbs && props.breadcrumbs.length > 2 && React.createElement("div", { className: categorystyles.categoryBreadcrumbsWrap },
                React.createElement("ul", { className: classnames(styles.campaignList, categorystyles.breadcrumbsList) }, props.breadcrumbs.slice(Math.min(2, props.breadcrumbs.length - 1), props.breadcrumbs.length - 1).map(function (_a, i) {
                    var url = _a.url, text = _a.text;
                    return (React.createElement("li", { key: text },
                        React.createElement("a", { className: styles.link, href: url }, text),
                        React.createElement(MyMenigoIcon, { name: "arrow" })));
                }))),
            !!props.overiderHeader ?
                (React.createElement("h1", { className: categorystyles.categoryHeaderName }, props.overiderHeader)) :
                React.createElement(PropertyFor, { className: categorystyles.categoryHeaderName, tagName: "h1", property: props.headerProperty }),
            !!props.overiderPreamble ?
                (React.createElement("p", null, props.overiderPreamble)) :
                React.createElement(React.Fragment, null, !!propertyValueFor(props.preambleProperty) && React.createElement(PropertyFor, { tagName: "p", property: props.preambleProperty })),
            React.createElement("ul", { className: styles.campaignList }, !!campaigns && campaigns.map(function (campaign, i) { return (React.createElement("li", { key: campaign.url },
                React.createElement("a", { className: styles.button, href: campaign.url },
                    React.createElement("span", null, campaign.displayName)))); })))));
};
export default CampaignPageHeader;
