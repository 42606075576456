import React, { useEffect } from 'react';
import connect from 'Shared/connect';
import PropertyFor from 'Shared/PropertyFor';
import propertyValueFor from 'Shared/property-value-for';
import Main, { Section, Part } from 'Shared/PageLayout';
import * as styles from './base.scss';
import * as pageLayoutStyles from 'Shared/PageLayout/base.scss';
import { translate } from 'Shared/translate';
function CmsPage(props) {
    useEffect(function () {
        var cookieProp = document.querySelector('.optanon-show-settings');
        var hasLoaded = localStorage.getItem('loaded');
        if (cookieProp) {
            if (hasLoaded === null || hasLoaded === 'false') {
                console.log(hasLoaded);
                localStorage.setItem('loaded', 'true');
                return location.reload();
            }
            else {
                localStorage.setItem('loaded', 'false');
            }
        }
    }, []);
    return (React.createElement(Main, { appearance: "narrow" },
        React.createElement(Section, null,
            React.createElement(PropertyFor, { tagName: "h1", property: props.page.name }),
            propertyValueFor(props.page.hero) && (React.createElement("div", { className: styles.hero },
                React.createElement(PropertyFor, { property: props.page.hero, isImage: true }))),
            propertyValueFor(props.page.isCookies) && (React.createElement("span", null,
                React.createElement("div", { className: styles.text },
                    React.createElement("span", null, translate('/Footer/CookieDescription')),
                    React.createElement("a", { className: "optanon-show-settings" }, translate('/Footer/CookieSettings'))),
                React.createElement("div", null,
                    React.createElement("br", null),
                    React.createElement("div", { id: "ot-sdk-cookie-policy" }))))),
        React.createElement(Section, { layout: !!propertyValueFor(props.page.showSideMenu) ? '3to1' : null },
            React.createElement(Part, null,
                React.createElement(PropertyFor, { className: styles.pageName, tagName: "h3", property: props.page.preamble }),
                React.createElement(PropertyFor, { property: props.page.mainBody }),
                React.createElement(PropertyFor, { property: props.page.mainContent })),
            propertyValueFor(props.page.showSideMenu) && (React.createElement(Part, { className: pageLayoutStyles.sidebar },
                React.createElement(PropertyFor, { property: props.page.sideBarBody }),
                React.createElement(PropertyFor, { property: props.page.sideBarContent })))),
        React.createElement(Part, null)));
}
export default connect(function (state) { return ({
    currentBreakpoint: state.currentBreakpoint,
}); })((CmsPage));
