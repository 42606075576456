var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Table from './Table/Table';
import connect from 'Shared/connect';
import Breadcrumbs from 'Shared/Breadcrumbs';
import Main, { Part } from 'Shared/PageLayout';
import NewListButton from './ToolBox/NewList';
import ExportButton from './ToolBox/Export';
import * as styles from './base.scss';
var RecipeMenus = connect(function (state, props) { return ({
    lists: props.lists,
}); })(function (props) { return (React.createElement("div", null, props.lists.map(function (recipeMenu) { return (React.createElement(Table, __assign({}, recipeMenu, { key: "RecipeMenuTable" + recipeMenu.id }))); }))); });
var RecipeMenuOverviewPage = /** @class */ (function (_super) {
    __extends(RecipeMenuOverviewPage, _super);
    function RecipeMenuOverviewPage(props) {
        return _super.call(this, props) || this;
    }
    RecipeMenuOverviewPage.prototype.render = function () {
        return (React.createElement(Main, { className: styles.base },
            React.createElement(Breadcrumbs, { breadcrumbs: this.props.breadcrumbs }),
            React.createElement(Part, null,
                React.createElement("div", { className: styles.toolButtons },
                    React.createElement(NewListButton, { className: styles.toolButton }),
                    React.createElement(ExportButton, { className: styles.toolButton })),
                React.createElement(RecipeMenus, __assign({}, this.props)))));
    };
    return RecipeMenuOverviewPage;
}(React.Component));
export default RecipeMenuOverviewPage;
