import React from 'react';
import { translate } from 'Shared/translate';
import LoginContainer from 'Shared/Login/Container';
import Icon from 'Shared/Icon';
import * as styles from './sign-up-link.scss';
var ProductCardSignUpLink = function () { return (React.createElement(LoginContainer, null, function (_a) {
    var signUpUrl = _a.signUpUrl;
    return (React.createElement("a", { href: signUpUrl, className: styles.base },
        React.createElement(Icon, { name: "account", className: styles.icon }),
        ' ',
        React.createElement("span", { className: styles.text }, translate('/Account/Registration/SignUp'))));
})); };
export default ProductCardSignUpLink;
