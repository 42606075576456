import { useCallback } from 'react';
import connect from 'Shared/connect';
import { addToCart } from './../../../Cart/action-creators';
import { getCartLineItemCountForVariant } from '../../../Cart/reducer';
import { getSelectedVariant } from 'Shared/Product/reducer';
import { getStepSize as getQuantityStepSize } from 'Shared/Quantity/utils';
import { componentIsSupplierAdPage } from './../../../Ad/Pages/SupplierAd/current-page-is-supplieradpage';
import { isLinkVisible } from 'Shared/ProductAlternativesLink/ProductAlternativesLink';
export var getAddToCartExperiment = function (selectedVariant, product) {
    if (!isLinkVisible(selectedVariant, product))
        return null;
    var body = document.querySelector('body');
    var experimentName = !!body.getAttribute("data-experiment-name") ? body.getAttribute("data-experiment-name") : "Experiment";
    var experimentType = body.getAttribute("data-experiment-type") == 'B' ? 'B' : "A";
    return experimentName + "-" + experimentType;
};
var ProductCardContainer = function (_a) {
    var index = _a.index, product = _a.product, cardHeight = _a.cardHeight, quantityInCart = _a.quantityInCart, selectedVariant = _a.selectedVariant, addToCart = _a.addToCart, isRestrictedCustomer = _a.isRestrictedCustomer, cardType = _a.cardType, isSupplierAdPage = _a.isSupplierAdPage, isRecommendationsPanel = _a.isRecommendationsPanel, children = _a.children;
    var discontinuedOrBackordered = product.isDiscontinued || product.isBackorderReplaced;
    var quantityStep = getQuantityStepSize(selectedVariant);
    var url = product.isDiscontinued && product.replacementProduct
        ? product.replacementProduct.url.toString()
        : !product.isDiscontinued && product.url.toString();
    var getAddToCartLocation = function () {
        return !!isSupplierAdPage ? 'Supplier_ad' : !!isRecommendationsPanel ? 'Rec-Panel' : 'ProductList';
    };
    var updateCartQuantity = useCallback(function (quantity) {
        var code = selectedVariant.code, unitShortName = selectedVariant.unitShortName, ticket = selectedVariant.ticket;
        return addToCart(code, quantity, unitShortName, ticket, getAddToCartLocation(), getAddToCartExperiment(selectedVariant, product));
    }, [selectedVariant]);
    return children({
        index: index,
        product: product,
        quantityInCart: quantityInCart,
        selectedVariant: selectedVariant,
        updateCartQuantity: updateCartQuantity,
        discontinuedOrBackordered: discontinuedOrBackordered,
        quantityStep: quantityStep,
        url: url,
        isRestrictedCustomer: isRestrictedCustomer,
        cardHeight: cardHeight,
        cardType: cardType
    });
};
export default connect(function (state, _a) {
    var product = _a.product;
    var selectedVariant = getSelectedVariant(state, product.variants);
    return {
        selectedVariant: selectedVariant,
        quantityInCart: getCartLineItemCountForVariant(state, selectedVariant),
        isRestrictedCustomer: state.appShellData.currentUser.isRestrictedCustomer,
        isSupplierAdPage: componentIsSupplierAdPage(state.currentPage.componentName),
    };
}, function (dispatch) { return ({
    addToCart: function (code, quantity, unitShortName, ticket, addToCartLocation, experiment) {
        return dispatch(addToCart(code, quantity, unitShortName, ticket, addToCartLocation, experiment));
    },
}); })(ProductCardContainer);
