var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import connect from 'Shared/connect';
import Root from './Root';
import MainFooter from './Footer';
import Sliding from './Sliding';
import AccountBox from './Header/AccountBox';
import Overlay from 'Shared/Overlay';
import { setAccountBoxClose } from './Header/AccountBox/action-creators';
import Flyout from 'Shared/Flyout';
import MiniCart from './MiniCart';
import Loader from './Loader';
import CurrentPage from './CurrentPage';
import NetworkError from './NetworkError';
import GenericFeedbackBar from './GenericFeedbackBar';
import { toggleMainMenu } from './MainMenu/action-creators';
import { toggleMiniCart } from './MiniCart/action-creators';
import { closeQuickSearch } from './../Search/action-creators';
import miniCartIsDisabled from './MiniCart/is-disabled-selector';
import { MEDIUM } from 'Shared/breakpoints';
import resolveComponent from 'Shared/resolve-component';
import { base as layout, baseApp as layoutApp } from './base.scss';
import moment from 'moment';
import { updateAppShellData } from './../AppShell/action-creators';
import { pageHasAppShell } from './../AppShell/';
import InfoModal from '../InfoModal';
import AutomaticLogout from 'Shared/AutomaticLogout';
import { renderMeta } from './meta-data-handling';
import CustomVariables from './CustomVariables';
import FadeHeader from './Header/FadeHeader';
import { toggleDeliveryDays } from './Header/action-creators';
import DeliveryDatesMobile from './Header/FadeHeader/Components/Buttons/DeliveryDates/DeliveryDatesMobile/index';
import Header from './Header';
import { componentIsCategory, isCategoryPageType } from './../Category/Commerce/Components/current-page-is-category';
import { isProductPage } from './../Product/Commerce/current-page-is-product';
import { isSearchPage } from './../Search/Pages/Search/current-page-is-search';
import isCheckoutPage from '../Checkout/Pages/Checkout/current-page-is-checkout';
import isStartPage from '../Start/Pages/Start/current-page-is-start';
import isOrderConfirmation from '../Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
export var siteContentMaxWidth = 1200;
var Layout = /** @class */ (function (_super) {
    __extends(Layout, _super);
    function Layout(props) {
        var _this = _super.call(this, props) || this;
        moment.locale('sv');
        moment.locale('en');
        _this.scrollPosition = props.currentPage.scrollPosition;
        return _this;
    }
    Layout.prototype.componentDidMount = function () {
        this.addTrackingScriptsToHead();
    };
    Layout.prototype.componentWillReceiveProps = function (newProps) {
        if (componentIsCategory(newProps.currentPage.componentName)) {
            var currentPath = newProps.currentPage.url.path;
            var newPath = this.props.currentPage.url.path;
            if (!!currentPath && !!newPath) {
                this.scrollPosition = currentPath.startsWith(newPath) || newPath.startsWith(currentPath) ? undefined : newProps.currentPage.scrollPosition;
            }
        }
        else if (this.props.currentPage !== newProps.currentPage) {
            this.scrollPosition = newProps.currentPage.scrollPosition;
        }
        else {
            this.scrollPosition = undefined;
        }
    };
    Layout.prototype.componentDidUpdate = function (prevProps) {
        if (this.scrollPosition !== undefined) {
            document.body.scrollTop = this.scrollPosition;
            document.documentElement.scrollTop = this.scrollPosition;
        }
        if (prevProps.punchOutSessionActive && !this.props.punchOutSessionActive) {
            /* Punchout session will be terminated in backend system */
            this.props.updateAppShellData();
        }
        if (this.props.currentPage.url !== prevProps.currentPage.url) {
            if (!(this.props.currentUser.isSupplierSalesman || (this.props.currentUser && this.props.currentUser.customerId && this.props.currentUser.customerId.startsWith("PK")))) {
                if (document.getElementById("PeeriusScriptTag")) {
                    document.getElementById("PeeriusScriptTag").innerText = 'var PeeriusCallbacks = {track : ' + this.getPeeriusTrackingData(this.props.currentPage) + ', smartRecs: function(jsonData) {}, apiVersion: "v1_4"}';
                }
                else {
                    this.addPeeriusCallback();
                }
            }
        }
    };
    Layout.prototype.addTrackingScriptsToHead = function () {
        var zaiusScript = document.createElement("script");
        zaiusScript.setAttribute("type", "text/javascript");
        zaiusScript.text = "window.zaius = window['zaius']||(window['zaius']=[]);zaius.methods=[\"initialize\",\"onload\",\"customer\",\"entity\",\"event\",\"subscribe\",\"unsubscribe\",\"consent\",\"identify\",\"anonymize\",\"dispatch\"];zaius.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);zaius.push(t);return zaius}};(function(){for(var i=0;i<zaius.methods.length;i++){var method=zaius.methods[i];zaius[method]=zaius.factory(method)}var e=document.createElement(\"script\");e.type=\"text/javascript\";e.async=true;e.src=(\"https:\"===document.location.protocol?\"https://\":\"http://\")+\"d1igp3oop3iho5.cloudfront.net/v2/" + this.props.ODPKey + "\";var t=document.getElementsByTagName(\"script\")[0];t.parentNode.insertBefore(e,t)})();";
        document.head.appendChild(zaiusScript);
        this.addPeeriusCallback();
        var peeriusScript = document.createElement("script");
        peeriusScript.setAttribute("type", "text/javascript");
        peeriusScript.setAttribute("src", this.props.PeeriusURL);
        peeriusScript.setAttribute("charset", "UTF-8");
        document.head.appendChild(peeriusScript);
    };
    Layout.prototype.addPeeriusCallback = function () {
        var PeeriusScriptCallbacks = document.createElement("script");
        PeeriusScriptCallbacks.setAttribute("type", "text/javascript");
        PeeriusScriptCallbacks.setAttribute("id", "PeeriusScriptTag");
        PeeriusScriptCallbacks.text = 'var PeeriusCallbacks = {track : ' + this.getPeeriusTrackingData(this.props.currentPage) + ', smartRecs: function(jsonData) {}, apiVersion: "v1_4"}';
        document.head.appendChild(PeeriusScriptCallbacks);
    };
    Layout.prototype.getPeeriusTrackingData = function (page) {
        if (!!isStartPage(page)) {
            var baseJson = this.props.peeriusTrackingData;
            var json = {};
            Object.assign(json, baseJson, { type: 'home' });
            return JSON.stringify(json);
        }
        else if (!!isSearchPage(page)) {
            return JSON.stringify(page.searchResultsPageTrackingData);
        }
        else if (!!isCheckoutPage(page)) {
            return JSON.stringify(page.checkoutPageTrackingData);
        }
        else if (!!isOrderConfirmation(page)) {
            return JSON.stringify(page.orderConfirmationTrackingData);
        }
        else if (!!isProductPage(page)) {
            return JSON.stringify(page.productPageTrackingData);
        }
        else if (!!isCategoryPageType(page)) {
            return JSON.stringify(page.categoryPageTrackingData);
        }
        else {
            return JSON.stringify(this.props.peeriusTrackingData);
        }
    };
    Layout.prototype.render = function () {
        var _this = this;
        var hasAppShell = pageHasAppShell(this.props.currentPage.componentName);
        renderMeta(this.props.currentPage);
        return (React.createElement(Root, { className: hasAppShell ? layout : layoutApp },
            !IS_APP && hasAppShell && React.createElement(FadeHeader, { toggleMainMenu: this.props.toggleMainMenu }),
            !!IS_APP && hasAppShell && React.createElement(Header, { toggleMainMenu: this.props.toggleMainMenu }),
            React.createElement(CurrentPage, { currentBreakpoint: this.props.currentBreakpoint, page: this.props.currentPage, component: resolveComponent(this.props.currentPage.componentName) }),
            hasAppShell && React.createElement(MainFooter, null),
            this.props.currentBreakpoint < MEDIUM && (React.createElement(React.Fragment, null,
                React.createElement(Sliding, { toggleMainMenu: this.props.toggleMainMenu, canOpen: !this.props.miniCartIsOpen || !this.props.deliveryDaysIsVisible }),
                React.createElement(Flyout, { position: "right", canOpen: !this.props.mainMenu.isOpen && !this.props.miniCartIsDisabled, toggleFlyout: null, isOpen: this.props.miniCartIsOpen, fillWidth: true }, this.props.miniCartIsOpen && React.createElement(MiniCart, { isFlyout: true })),
                React.createElement(Flyout, { position: "right", canOpen: !this.props.mainMenu.isOpen, toggleFlyout: this.props.toggleDeliveryDays, isOpen: this.props.deliveryDaysIsVisible, fillWidth: true },
                    React.createElement(DeliveryDatesMobile, null)))),
            this.props.accountBoxIsOpen && (React.createElement(Overlay, { onClose: function () { return !IS_APP && _this.props.setLoginBoxClose(); } },
                React.createElement(AccountBox, { close: function () { return _this.props.setLoginBoxClose(); }, isCloseButton: true }))),
            React.createElement(Loader, { isVisible: this.props.currentPage.showLoadingSpinner }),
            React.createElement(NetworkError, { reload: this.props.currentPage.reload, loadFailure: this.props.currentPage.loadFailure }),
            React.createElement(GenericFeedbackBar, null),
            React.createElement(InfoModal, null),
            React.createElement(CustomVariables, null),
            React.createElement(AutomaticLogout, null)));
    };
    return Layout;
}(React.Component));
export default connect(function (state) { return ({
    currentPage: state.currentPage,
    currentBreakpoint: state.currentBreakpoint,
    mainMenu: state.mainMenu,
    miniCartIsOpen: state.miniCart.isOpen,
    miniCartIsDisabled: miniCartIsDisabled(state),
    accountBoxIsOpen: state.accountBox.isOpen && !state.currentUser.isLoggedIn,
    punchOutSessionActive: state.currentUser.punchOutSessionActive,
    deliveryDaysIsVisible: state.deliveryDaysVisibility.visible,
    headerViewModel: state.appShellData.header,
    ODPKey: state.appShellData.siteSettings.odpEnvironmentKey,
    PeeriusURL: state.appShellData.siteSettings.optiRecsTrackerURL,
    peeriusTrackingData: state.appShellData.peeriusTrackingData,
    currentUser: state.currentUser
}); }, function (dispatch) { return ({
    toggleMainMenu: function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(closeQuickSearch());
        dispatch(toggleMainMenu());
    },
    toggleMiniCart: function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        dispatch(toggleMiniCart());
    },
    toggleDeliveryDays: function () {
        return dispatch(toggleDeliveryDays());
    },
    setLoginBoxClose: function () { return dispatch(setAccountBoxClose()); },
    updateAppShellData: function () { return dispatch(updateAppShellData()); },
}); })(Layout);
