var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Button from 'Shared/Button';
import { translate } from 'Shared/translate';
import { scrollPosition, on } from 'SiteLayout/Root';
import * as styles from './base.scss';
var NetworkError = /** @class */ (function (_super) {
    __extends(NetworkError, _super);
    function NetworkError(props) {
        var _this = _super.call(this, props) || this;
        _this.onRetry = function () {
            if (!isOnline()) {
                // If you click Retry we don't want to show the "You're offline" message again
                _this.retriedWhileOffline = true;
            }
            _this.props.reload();
        };
        _this.state = {
            visible: _this.isVisible(props),
            dismissed: null,
        };
        _this.hideTimer = null;
        return _this;
    }
    NetworkError.prototype.isVisible = function (props) {
        if (props.loadFailure === null) {
            return false;
        }
        else {
            return (this.state &&
                this.state.dismissed !== props.loadFailure &&
                // AccountButton handles failed requests that requires auth
                props.loadFailure.status !== 401 &&
                // Don't bother the user if we're showing a full version of the page
                (!props.loadFailure.isShowingFullCachedPage || props.loadFailure.status <= 500));
        }
    };
    NetworkError.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return nextProps.loadFailure !== this.props.loadFailure || nextState.visible !== this.state.visible;
    };
    NetworkError.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        var visible = this.isVisible(nextProps);
        if (visible !== this.state.visible) {
            if (!this.retriedWhileOffline &&
                visible &&
                !isOnline() &&
                this.ignoredOfflineWarning &&
                !oneHourHasPassedFrom(this.ignoredOfflineWarning)) {
                // Don't hassle the user by telling them about offline all the time
                return;
            }
            this.setState({
                visible: visible,
                dismissed: null,
            });
            this.clearHideTimer();
            if (visible) {
                this.hideTimer = setTimeout(function () {
                    _this.onDismiss();
                }, 4000);
                this.scrollPositionWhenErrorShown = scrollPosition();
                this.scrollListener = on('scroll', function () {
                    var newPosition = scrollPosition();
                    if (newPosition - _this.scrollPositionWhenErrorShown > 75) {
                        _this.onDismiss();
                    }
                });
            }
        }
    };
    NetworkError.prototype.clearHideTimer = function () {
        if (this.hideTimer) {
            clearTimeout(this.hideTimer);
            this.hideTimer = null;
        }
    };
    NetworkError.prototype.onDismiss = function () {
        if (this.scrollListener) {
            this.scrollListener();
            this.scrollListener = null;
        }
        this.clearHideTimer();
        if (!isOnline()) {
            this.ignoredOfflineWarning = new Date();
            this.retriedWhileOffline = false;
        }
        this.setState({ visible: false, dismissed: this.props.loadFailure });
    };
    NetworkError.prototype.render = function () {
        var showOfflineMessage = !isOnline() && !this.retriedWhileOffline;
        return (React.createElement(CSSTransition, { in: this.state.visible, classNames: {
                appear: styles.enter,
                appearActive: styles.enterActive,
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
            }, mountOnEnter: true, unmountOnExit: true, appear: true, timeout: { enter: 300, exit: 300 } },
            React.createElement("div", { className: styles.base, key: "networkErrorMsg" },
                React.createElement("div", { className: styles.wrapper },
                    React.createElement("p", null, showOfflineMessage ? translate('/Errors/Offline') : translate('/Errors/PageLoad')),
                    !showOfflineMessage && ( // No reason to show a try again message when we know that the user is offline
                    React.createElement("div", { className: styles.buttons },
                        React.createElement(Button, { className: styles.button, onClick: this.onRetry }, translate('/Errors/Retry'))))))));
    };
    return NetworkError;
}(React.Component));
export default NetworkError;
function oneHourHasPassedFrom(date) {
    return (new Date().valueOf() - date.valueOf()) / 1000 > 3600;
}
function isOnline() {
    return navigator.onLine === false ? false : true;
}
