var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import Icon from 'Shared/Icon';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
var AddOnContent = /** @class */ (function (_super) {
    __extends(AddOnContent, _super);
    function AddOnContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddOnContent.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: classnames(styles.box, (!!this.props.selected && styles.selected)) },
            React.createElement("div", { className: styles.boxTop },
                React.createElement("span", { className: classnames(styles.header, (this.props.selected && styles.selected)) },
                    this.props.header,
                    !!this.props.selected && " - ",
                    !!this.props.selected && translate('/MyMenigo/MyArrangement/Selected')),
                React.createElement("span", { className: classnames(styles.description, (!!this.props.selected && styles.selected)) }, this.props.description),
                !!this.props.price && React.createElement("span", { className: classnames(styles.price, (!!this.props.selected && styles.selected)) },
                    React.createElement("span", null, this.props.price))),
            React.createElement("div", { className: styles.card },
                React.createElement("ul", null, (this.props.content.map(function (d, i) { return (React.createElement("li", { key: i, className: classnames(styles.contentList, (!!_this.props.selected && styles.selected)) },
                    React.createElement(Icon, { name: "check", className: classnames(styles.checkMark, (!!_this.props.selected && styles.selected)) }),
                    " ",
                    d)); }))))));
    };
    return AddOnContent;
}(React.Component));
export default AddOnContent;
