import React from 'react';
import { translate } from 'Shared/translate';
import DropDownMenu, { DropDownItem, DropDownWrapper, DropDownSeparator, Apperance } from 'Shared/DropDownMenu';
import { DropDownListType } from '../../Pantries/Pages/List/Toolbar';
export var MasterFor = function (_a) {
    var isMaster = _a.isMaster, slaves = _a.slaves, className = _a.className, isBig = _a.isBig, handleDropDownSelect = _a.handleDropDownSelect, active = _a.active;
    return (React.createElement("div", { className: className }, isMaster && (React.createElement(DropDownMenu, { title: translate('/Account/ProductList/MasterFor'), appearance: !!isBig ? Apperance.Big : Apperance.Normal, isActive: !!active, childCount: slaves.length },
        React.createElement(DropDownSeparator, { key: "fav_sep", text: translate('/Account/ProductList/Customers') }),
        React.createElement(DropDownWrapper, null, slaves.map(function (subCustomer) { return (React.createElement(DropDownItem, { key: "slave-" + subCustomer.slaveCustomerId, value: subCustomer.slaveCustomerId, text: subCustomer.slaveCustomerId + " " + subCustomer.name, onClick: function (e) { return !!handleDropDownSelect && handleDropDownSelect(DropDownListType.MasterFor); }, disabled: false })); }))))));
};
