var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactDOM from 'react-dom';
import resolveComponent from './resolve-component';
import editorAttributesFor from './editor-attributes-for';
import expandPlaceholders from './expand-placeholders';
var PropertyFor = /** @class */ (function (_super) {
    __extends(PropertyFor, _super);
    function PropertyFor(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            property: props.property,
        };
        return _this;
    }
    PropertyFor.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = this.props.property !== nextProps.property ||
            this.props.className !== nextProps.className ||
            this.props.tagName !== nextProps.tagName ||
            this.props.dangerouslySetInnerHTML !== nextProps.dangerouslySetInnerHTML ||
            this.state.property !== nextState.property;
        if (!shouldUpdate) {
            console.info('Ignoring update to PropertyFor');
        }
        return shouldUpdate;
    };
    PropertyFor.prototype.componentDidMount = function () {
        if (window.IS_IN_EDIT_MODE) {
            var node_1 = ReactDOM.findDOMNode(this);
            var component_1 = this;
            var currentProperty = this.state.property;
            var hasComponent_1 = !!(this.props.component ||
                (currentProperty && currentProperty.$c && currentProperty.$c.componentName));
            Object.defineProperty(node_1, 'innerHTML', {
                get: function () {
                    var html = '';
                    for (var i = 0; i < node_1.childNodes.length; i++) {
                        var child = node_1.childNodes[i];
                        if (child instanceof Element) {
                            html += child.innerHTML;
                        }
                    }
                    return html;
                },
                set: function (value) {
                    // Because Epi wants to replace empty content with a space, probably to make it take up some space
                    var isImage = false;
                    if (value === '&nbsp;') {
                        return;
                    }
                    if (RegExp('<img?').test(value)) {
                        value = RegExp('<img.+?src=["\'](.+?)["\'].+?>').exec(value)[1];
                        isImage = true;
                    }
                    // component.innerHTML = value;
                    var content = hasComponent_1 && !isImage ? JSON.parse(value) : value;
                    // If content is null, it means that the content was a complex type and that it has now been
                    // removed. But we still want to use the same component to render it since otherwise React
                    // will throw away the DOM node
                    if (content === null) {
                        content = {
                            componentName: component_1.props.property.$c.componentName,
                        };
                    }
                    var property = Object.assign({}, component_1.state.property, { $c: content });
                    component_1.setState({ property: property });
                },
            });
        }
    };
    PropertyFor.prototype.componentWillReceiveProps = function (newProps) {
        if (this.state.property !== newProps.property) {
            this.setState({ property: newProps.property });
        }
    };
    PropertyFor.prototype.render = function () {
        var _a = this.props, className = _a.className, _b = _a.tagName, tagName = _b === void 0 ? 'div' : _b;
        var property = this.state.property;
        var name = property && property._name;
        var content = property ? property.$c : null;
        if (this.props.isImage) {
            var props = editorAttributesFor(name, className);
            return React.createElement("img", __assign({}, props, { src: content }));
        }
        else if (this.props.component || (content && content.componentName)) {
            // Need to cast props.component to React.ComponentClass to avoid a TS error
            // (both possible types it can have are allowed as JSX elements but TS is not smart enough to understand that)
            var Component = this.props.component || resolveComponent(content.componentName);
            return Component.supportsPropertyForIntegration ? (React.createElement(Component, __assign({ tagName: tagName, className: className, propertyName: name }, this.props.componentProps, content))) : (React.createElement(tagName, editorAttributesFor(name, className), React.createElement(Component, __assign({}, this.props.componentProps, content))));
        }
        else {
            var actualContent = typeof content === 'string' && this.props.args ? expandPlaceholders(content, this.props.args) : content;
            var props = editorAttributesFor(name, className);
            if (this.props.dangerouslySetInnerHTML) {
                // We use dangerouslySetInnerHTML here because placeholders might expand to HTML like links etc
                props.dangerouslySetInnerHTML = { __html: actualContent };
                return React.createElement(tagName, props);
            }
            else {
                return React.createElement(tagName, props, [actualContent || '']);
            }
        }
    };
    return PropertyFor;
}(React.Component));
export default PropertyFor;
