var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Main from 'Shared/PageLayout';
import PropertyFor from 'Shared/PropertyFor';
import { pushState } from 'Shared/history';
import React from 'react';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import FeedbackButton from 'Shared/Button/Feedback';
import Logotype from 'Shared/Logotype';
import * as server from 'Shared/server';
import { translate } from 'Shared/translate';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
import { updateAppShellData } from '../../AppShell/action-creators';
import { logout } from '../../Account/action-creators';
import { searchUsers } from '../action-creators';
import Spinner from 'Shared/Spinner';
import * as styles from './base.scss';
import Button from 'Shared/Button';
var feedbackDelay = 500;
var PunchOutLoginPage = /** @class */ (function (_super) {
    __extends(PunchOutLoginPage, _super);
    function PunchOutLoginPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { searchInput: '', redirectUrl: null };
        return _this;
    }
    PunchOutLoginPage.prototype.doLogout = function () {
        this.props.logout();
    };
    PunchOutLoginPage.prototype.searchforUsers = function (e) {
        var _this = this;
        clearTimeout(this.currentTimeout);
        if (e.length > 2) {
            if (!this.state.searchingForUsers) {
                this.setState({ searchingForUsers: true });
            }
            this.currentTimeout = setTimeout(function () {
                _this.props.searchUsers(e).then(function (result) {
                    var slicedArr = result.slice(0, 10);
                    _this.setState({ searchResult: slicedArr, searchingForUsers: false });
                });
            }, 500);
        }
        else if (this.state.searchingForUsers || !!this.state.searchResult) {
            this.setState({ searchResult: null, searchingForUsers: false });
        }
    };
    PunchOutLoginPage.prototype.onSubmit = function (e, id) {
        var _this = this;
        e.preventDefault();
        return server
            .postJson(pathCombine(location.pathname, '/login'), { customerId: id })
            .then(function (result) {
            if (result.success) {
                if (result.message != null) {
                    _this.props.setFeedback({
                        text: result.message,
                        type: 'success',
                    });
                }
                _this.setState({ redirectUrl: result.redirectUrl == null ? '/' : result.redirectUrl });
                return Promise.resolve();
            }
            else {
                _this.props.setFeedback({
                    text: result.message,
                    type: 'error',
                });
                return Promise.reject(result.message);
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    PunchOutLoginPage.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: styles.reset },
            React.createElement("div", { className: styles.punchOutWrapper },
                React.createElement("div", { className: styles.logotypeWrapper },
                    React.createElement(Logotype, { className: styles.logotype, siteUrl: "/", disableLink: true }))),
            React.createElement(Main, { appearance: "narrow", className: styles.base },
                React.createElement(PropertyFor, { property: this.props.page.header, tagName: "h1" }),
                React.createElement(PropertyFor, { property: this.props.page.mainBody, tagName: "p" }),
                React.createElement("form", null,
                    React.createElement("fieldset", null,
                        React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement("div", { className: styles.searchInputWrapper },
                                    React.createElement("input", { value: this.state.searchInput, ref: function (el) { return (_this.customerSearch = el); }, placeholder: translate('/PunchOut/LoginPage/AccountNumber'), onChange: function (e) {
                                            return _this.setState({ searchInput: e.target.value }, function () {
                                                return _this.searchforUsers(_this.state.searchInput);
                                            });
                                        } }),
                                    this.state.searchingForUsers && (React.createElement("div", { className: styles.spinnerWrapper },
                                        React.createElement(Spinner, null))),
                                    !!this.state.searchResult && this.state.searchResult.length > 0 && (React.createElement("table", { className: styles.list },
                                        React.createElement("tbody", null,
                                            React.createElement("tr", null,
                                                React.createElement("th", null, translate('/PunchOut/LoginPage/AccountNumber')),
                                                React.createElement("th", null, translate('/PunchOut/LoginPage/CustomerName')),
                                                React.createElement("th", null)),
                                            this.state.searchResult.map(function (contact, i) { return (React.createElement("tr", { key: i },
                                                React.createElement("td", null, contact.customerId),
                                                React.createElement("td", null, contact.name),
                                                React.createElement("td", null,
                                                    React.createElement(FeedbackButton, { type: "submit", appearance: "primary", onClickWithFeedback: function (e, activate) {
                                                            var promiseOrUndefined = _this.onSubmit(e, contact.customerId);
                                                            if (promiseOrUndefined) {
                                                                activate(promiseOrUndefined, { minimumPending: feedbackDelay })
                                                                    .then(function () { return _this.props.reloadAppShell(); })
                                                                    .then(function () { return pushState(_this.state.redirectUrl); });
                                                            }
                                                        } }, translate('/PunchOut/LoginPage/Login'))))); })))),
                                    React.createElement("div", { className: styles.loginOut },
                                        React.createElement(Button, { appearance: "secondary", onClick: function () { return _this.doLogout(); } }, translate('/PunchOut/LoginPage/Logout')))))))))));
    };
    return PunchOutLoginPage;
}(React.Component));
export default connect(function (state) { return ({
    userSettings: state.currentUser.userSettings,
}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
    searchUsers: function (query) {
        return dispatch(searchUsers(query));
    },
    reloadAppShell: function () {
        return dispatch(updateAppShellData());
    },
    logout: function () {
        return dispatch(logout());
    },
}); })(PunchOutLoginPage);
