var _this = this;
import classnames from "classnames";
import React, { useEffect, useRef } from "react";
import * as styles from './MainMenu.scss';
export default (function (_a) {
    var _b;
    var mainMenu = _a.mainMenu, currentPage = _a.currentPage, small = _a.small;
    var ref = useRef(null);
    var itemCssClass = function (url) {
        return (url === currentPage.url.path || (currentPage.url.path + "/").indexOf(url) == 0) ? classnames(styles.menu__item, styles.menu__item_current) : styles.menu__item;
    };
    var menuItemClick = function (ev) {
        var item = ev.target.parentElement;
        if (document.querySelectorAll("." + styles.menu__item_current).length == 1) {
            var li = document.querySelector("." + styles.menu__item_current + ">a").parentElement;
            li.className = classnames(styles.menu__item);
        }
        item.className = classnames(styles.menu__item, styles.menu__item, styles.menu__item_current);
    };
    var setCurrent = function (ev) {
        !!ev && ev.preventDefault();
        if (document.querySelectorAll("." + styles.menu__item_current).length == 1) {
            document.getElementById('mdot').style.opacity = '1';
            document.getElementById('mdot').style.transform = 'scale(1)';
            var item = ev.target.parentElement;
            var positon = item.offsetLeft + item.offsetWidth / 2;
            document.getElementById('mdot').style.left = positon + "px";
        }
        else {
            var item = ev.target.parentElement;
            var positon = item.offsetLeft + item.offsetWidth / 2;
            document.getElementById('mdot').style.left = positon + "px";
            document.getElementById('mdot').style.opacity = '1';
            document.getElementById('mdot').style.transform = 'scale(1)';
        }
    };
    var unsetCurrent = function (ev) {
        document.getElementById('mdot').style.transform = 'scale(.5)';
        if (document.querySelectorAll("." + styles.menu__item_current).length == 1) {
            document.getElementById('mdot').style.opacity = '1';
            document.getElementById('mdot').style.transform = 'scale(1)';
            var item = document.querySelector("." + styles.menu__item_current + ">a").parentElement;
            var positon = item.offsetLeft + item.offsetWidth / 2;
            document.getElementById('mdot').style.left = positon + "px";
        }
        else {
            document.getElementById('mdot').style.opacity = '0';
            document.getElementById('mdot').style.transform = 'scale(0)';
            if (document.querySelectorAll("." + styles.menu__item_current).length == 1) {
                var li = document.querySelector("." + styles.menu__item_current + ">a").parentElement;
                li.className = classnames(styles.menu__item);
                document.getElementById('mdot').style.left = 0 + "px";
            }
        }
    };
    useEffect(function () {
        var listener = function (event) {
            var el = ref.current;
            if (!el || el.contains(event.target)) {
                return;
            }
            document.getElementById('mdot').style.opacity = '0';
            document.getElementById('mdot').style.transform = 'scale(0)';
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, unsetCurrent]);
    return (React.createElement("nav", { className: classnames('ui-test-main-menu-nav', styles.menu, styles.menu_menigo, (_b = {}, _b[styles.small] = !!small, _b)) },
        React.createElement("ul", { ref: ref, className: classnames(styles.menu__list) },
            mainMenu.primaryMenuItems.map(function (item, index) { return (React.createElement("li", { key: "left" + index, className: itemCssClass(item.url) },
                React.createElement("a", { onClick: menuItemClick.bind(_this), onMouseEnter: setCurrent.bind(_this), onMouseLeave: unsetCurrent.bind(_this), className: classnames(styles.menu__link), href: item.url }, item.displayName))); }),
            mainMenu.rightContentLinks.map(function (item, index) { return (React.createElement("li", { key: "right" + index, className: itemCssClass(item.url) },
                React.createElement("a", { onClick: menuItemClick.bind(_this), onMouseEnter: setCurrent.bind(_this), onMouseLeave: unsetCurrent.bind(_this), className: classnames(styles.menu__link), href: item.url }, item.displayName))); })),
        React.createElement("div", { id: "mdot", style: { opacity: 0 }, className: classnames(styles.menu__dot, styles.animated) })));
});
