var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CART_LOAD, CART_LOAD_SUCCESS, CART_LOAD_FAILURE, CART_OPTIMISTIC_UPDATE, CART_CHANGES_ACCEPTED, TOGGLE_IGNORE_AVAILABILITY, } from './action-creators';
import { UPDATE_APP_SHELL_DATA } from '../AppShell/action-creators';
import currentPageIsCheckout from 'Features/Checkout/Pages/Checkout/current-page-is-checkout';
export default function (state, action, currentPage, appShellData) {
    if (state === void 0) { state = null; }
    if (state === null) {
        state = Object.assign({}, appShellData.cart, {
            isLoading: false,
            pendingChanges: [],
        });
    }
    switch (action.type) {
        case TOGGLE_IGNORE_AVAILABILITY: {
            return Object.assign({}, state, {
                ignoreAvailability: action.ignoreAvailability,
            });
        }
        case CART_LOAD: {
            return Object.assign({}, state, {
                isLoading: true,
            });
        }
        case CART_LOAD_SUCCESS: {
            var newState = Object.assign({}, action.cart, {
                isLoading: state.pendingChanges.length > 0,
                pendingChanges: state.pendingChanges,
            });
            return applyPendingChanges(newState, newState.pendingChanges);
        }
        case UPDATE_APP_SHELL_DATA: {
            return Object.assign({}, action.appShellData.cart, {
                isLoading: false,
                pendingChanges: [],
            });
        }
        case CART_LOAD_FAILURE: {
            var firstPendingChange_1 = state.pendingChanges[0];
            if (!firstPendingChange_1) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                pendingChanges: [],
                items: state.items.map(function (item) {
                    var updatedItem;
                    Object.keys(firstPendingChange_1.items).forEach(function (code) {
                        if (code === item.lineItem.code) {
                            updatedItem = {
                                product: item.product,
                                lineItem: __assign({}, item.lineItem, { quantity: firstPendingChange_1.items[code].previousQuantity, unitShortName: firstPendingChange_1.items[code].previousUnitShortName, selectedVariant: __assign({}, item.lineItem.selectedVariant, { unitShortName: firstPendingChange_1.items[code].previousUnitShortName }) }),
                            };
                        }
                    });
                    return updatedItem || item;
                }),
            });
        }
        case CART_OPTIMISTIC_UPDATE: {
            var diff = [action.diff];
            var newState = Object.assign({}, state);
            newState.pendingChanges = (newState.pendingChanges || []).concat(diff);
            return applyPendingChanges(newState, diff);
        }
        case CART_CHANGES_ACCEPTED: {
            var acceptedChangeIds_1 = action.changes.map(function (c) { return c.id; });
            var newState = Object.assign({}, state);
            newState.pendingChanges = newState.pendingChanges.filter(function (p) { return acceptedChangeIds_1.indexOf(p.id) === -1; });
            newState.items = newState.items.filter(function (i) { return i.lineItem.quantity > 0; });
            return newState;
        }
        default:
            return state;
    }
}
export function applyPendingChanges(state, pendingChanges) {
    if (!pendingChanges || !pendingChanges.length) {
        return state;
    }
    var itemLengthBeforeChange = state.items.length;
    pendingChanges.forEach(function (pendingChange) {
        Object.keys(pendingChange.items).forEach(function (code) {
            var items = state.items.map(function (cartItem) {
                if (cartItem.lineItem.code === code) {
                    var pendingLineItemChange = pendingChange.items[code];
                    var newChanges = {
                        quantity: pendingLineItemChange.newQuantity,
                        ignoreAvailability: pendingLineItemChange.newIgnoreAvailability,
                        isProductSample: pendingLineItemChange.newIsProductSample,
                        discountType: pendingLineItemChange.newDiscountType,
                        discountValue: pendingLineItemChange.newDiscountValue,
                    };
                    var applyDefinedChanges = function (item) {
                        return Object.keys(item)
                            .filter(function (key) { return item[key] !== undefined; })
                            .reduce(function (prev, curr) {
                            prev[curr] = item[curr];
                            return prev;
                        }, {});
                    };
                    return __assign({}, cartItem, { lineItem: __assign({}, cartItem.lineItem, { unitShortName: pendingLineItemChange.newUnitShortName }, applyDefinedChanges(newChanges), { selectedVariant: __assign({}, cartItem.lineItem.selectedVariant, applyDefinedChanges({ unitShortName: pendingLineItemChange.newUnitShortName })) }) });
                }
                else {
                    return cartItem;
                }
            });
            state.items = items;
        });
    });
    // If we remove an item we need a new cart object to rerender but if we only change an item it's enough to just have new object for that item.
    return state.items.length !== itemLengthBeforeChange ? __assign({}, state) : state;
}
export function getCartLineItemsForVariants(state, variants) {
    if (!variants || variants.length < 1) {
        return [];
    }
    return variants.map(function (v) { return getCartLineItemForVariant(state, v); }).filter(Boolean);
}
export function getCartLineItemForVariant(state, variant) {
    if (!variant) {
        return null;
    }
    var cart = currentPageIsCheckout(state.currentPage) ? state.checkout.cart : state.cart;
    var cartLineItem = cart.items.find(function (i) { return i.lineItem.code === variant.code; });
    return cartLineItem ? cartLineItem.lineItem : null;
}
export function getCartLineItemCountForVariant(state, variant) {
    var cartLineItem = getCartLineItemForVariant(state, variant);
    return cartLineItem ? cartLineItem.quantity : 0;
}
export function quantityIncludingSubdeliveries(lineItem) {
    return lineItem.quantity + lineItem.subDeliveries.reduce(function (sum, subDelivery) { return sum + subDelivery.quantity; }, 0);
}
