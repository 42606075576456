var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import OrderDetail from './details';
import Checkbox from 'Shared/Fields/Checkbox';
import Price from 'Shared/Price';
import { price as loadingPrice } from '../../../loading.scss';
import classnames from 'classnames';
var OrderHeader = /** @class */ (function (_super) {
    __extends(OrderHeader, _super);
    function OrderHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { isPressed: false, selectedItems: new Map() };
        return _this;
    }
    OrderHeader.prototype.onPressCollapse = function (e, ordernum) {
        var selected = ordernum === this.state.selectedOrder ? null : ordernum;
        this.setState({ isPressed: !this.state.isPressed, selectedOrder: selected });
    };
    OrderHeader.prototype.addToSelectedItems = function (e, props) {
        props.isSelected = e.target.checked;
        this.addOrRemoveSelectedItems(e.target.checked, props);
    };
    OrderHeader.prototype.addOrRemoveSelectedItems = function (isSelected, item) {
        var id = item.orderNumber;
        var itemsMap = this.state.selectedItems;
        if (isSelected && !itemsMap.has(id)) {
            itemsMap.set(id, item);
        }
        else if (itemsMap.has(id)) {
            itemsMap.delete(id);
        }
        this.setState({
            isHeaderSelected: this.state.selectedItems.size === this.props.orderHeaders.length,
            selectedItems: itemsMap,
        });
        !!this.props.notifySelectedItems && this.props.notifySelectedItems();
    };
    OrderHeader.prototype.triggerSelectAllFromChildAction = function (isSelect) {
        this.setState({ isHeaderSelected: isSelect });
    };
    OrderHeader.prototype.selectAllOrderItems = function () {
        if (this.state.isHeaderSelected) {
            this.setState({ isHeaderSelected: false, selectedItems: new Map() });
        }
        else {
            var items_1 = new Map();
            this.props.orderHeaders.map(function (data) {
                items_1.set(data.orderNumber, data);
            });
            this.setState({ isHeaderSelected: !this.state.isHeaderSelected, selectedItems: items_1 });
        }
    };
    OrderHeader.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.orderHeaderContainer },
                this.props.isExtensive ? (React.createElement("div", { className: styles.tableHeader },
                    React.createElement("div", { className: styles.checkBox }, this.props.isSelectable &&
                        !!(this.props.currentTab === 'currentOrders' && this.props.notifySelectedItems !== null) && (React.createElement(Checkbox, { checked: this.state.isHeaderSelected, onChange: function (e) { return _this.selectAllOrderItems(); } }))),
                    React.createElement("div", null, translate('/MyMenigo/OrderOverviewHeaders/OrderNumber')),
                    this.props.currentTab === 'historyOrders' && (React.createElement("div", null, translate('/MyMenigo/OrderOverviewHeaders/DeliveryDate'))),
                    React.createElement("div", null, translate('/MyMenigo/OrderOverviewHeaders/OrderType')),
                    !!(this.props.currentTab === 'currentOrders') && (React.createElement("div", null, translate('/MyMenigo/OrderOverviewHeaders/Status'))),
                    React.createElement("div", null, translate('/MyMenigo/OrderOverviewHeaders/OrderSummary')))) : (React.createElement("div", { className: styles.tableHeader }, this.props.isSelectable &&
                    !!(this.props.currentTab === 'currentOrders' && this.props.notifySelectedItems !== null) && (React.createElement("div", { className: styles.checkBox },
                    React.createElement(Checkbox, { checked: this.state.isHeaderSelected, onChange: function (e) { return _this.selectAllOrderItems(); } }))))),
                !!this.props.orderHeaders &&
                    this.props.orderHeaders.map(function (header, index) {
                        var _a;
                        return (React.createElement("div", { className: styles.orderHeaderWrap, key: index },
                            _this.props.isExtensive ? (React.createElement("div", { className: classnames(styles.header, (_a = {},
                                    _a[styles.isOpen] = _this.state.selectedOrder === header.erpOrderNumber,
                                    _a)), onClick: function (e) { return _this.onPressCollapse(e, header.erpOrderNumber); } },
                                _this.props.isOrderItemsSelectable && _this.props.currentTab === 'currentOrders' && (React.createElement("div", { className: styles.checkBox },
                                    React.createElement(Checkbox, { checked: _this.props.orderHeaders[index].isSelected, onChange: function (e) { return _this.addToSelectedItems(e, header); }, onClick: function () { return _this.props.notifySelectedOrderItemChange(header.orderNumber); } }))),
                                React.createElement("div", null, header.orderNumber),
                                !!(_this.props.currentTab === 'historyOrders') && React.createElement("div", null, header.deliveryDate),
                                React.createElement("div", null, header.typeAsString),
                                !!(_this.props.currentTab === 'currentOrders') && React.createElement("div", null, header.status),
                                React.createElement("div", null,
                                    React.createElement(Price, { className: classnames(loadingPrice), current: header.orderSummary, size: "large", currencySymbol: translate('/Price/CurrencySymbol') })))) : (React.createElement("div", { className: styles.header, onClick: function (e) { return _this.onPressCollapse(e, header.erpOrderNumber); } },
                                _this.props.isOrderItemsSelectable && _this.props.currentTab === 'currentOrders' && (React.createElement("div", { className: styles.checkBox },
                                    React.createElement(Checkbox, { checked: _this.props.orderHeaders[index].isSelected, onChange: function (e) { return _this.addToSelectedItems(e, header); }, onClick: function () { return _this.props.notifySelectedOrderItemChange(header.orderNumber); } }))),
                                React.createElement("div", { className: styles.mobileColumn },
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement("b", null,
                                                translate('/MyMenigo/OrderOverviewHeaders/OrderNumber'),
                                                ": ")),
                                        header.orderNumber),
                                    !!(_this.props.currentTab === 'historyOrders') && (React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement("b", null,
                                                translate('/MyMenigo/OrderOverviewHeaders/DeliveryDate'),
                                                ": ")),
                                        header.deliveryDate)),
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement("b", null,
                                                translate('/MyMenigo/OrderOverviewHeaders/OrderType'),
                                                ": ")),
                                        header.typeAsString),
                                    _this.props.currentTab === 'currentOrders' && (React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement("b", null,
                                                translate('/MyMenigo/OrderOverviewHeaders/Status'),
                                                ": ")),
                                        header.status)),
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement("b", null,
                                                translate('/MyMenigo/OrderOverviewHeaders/OrderSummary'),
                                                ": ")),
                                        React.createElement(Price, { className: classnames(loadingPrice), current: header.orderSummary, size: "large", currencySymbol: translate('/Price/CurrencySymbol') }))))),
                            _this.state.selectedOrder === header.erpOrderNumber && (React.createElement("div", null,
                                React.createElement(OrderDetail, { isExtensive: _this.props.isExtensive, isItemShown: _this.state.selectedOrder === header.erpOrderNumber, OrderDetailsList: header.orderDetails, TotalOrderPrice: header.orderSummary, currentTab: _this.props.currentTab, currentGrouping: _this.props.currentGrouping, isSelectable: _this.props.isOrderItemsSelectable, type: header.typeAsString })))));
                    }))));
    };
    return OrderHeader;
}(React.Component));
export default OrderHeader;
