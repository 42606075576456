import React from 'react';
import classnames from 'classnames';
import * as styles from '../base.scss';
import { translate } from 'Shared/translate';
import { ClassificationMap, FileClassificationMap } from '../Classification/mapping';
import Icon from 'Shared/Icon';
import Main from 'Shared/PageLayout';
var IconMarkingsBlock = function (props) {
    var _a;
    return (!!props.classification && Object.keys(props.classification).length > 0 &&
        React.createElement(Main, { id: "IconMarkingsBlock", className: classnames(styles.iconMarkingsRow, (_a = {}, _a[styles.iconMarkingsRowCheckout] = props.isCheckout, _a)) },
            React.createElement("div", null,
                React.createElement("div", null, (props.isDesktop) && React.createElement("div", { className: styles.h2 }, translate('/Product/Markings'))),
                React.createElement("div", { className: styles.iconWrapper }, !!props.classification &&
                    Object.keys(props.classification).map(function (key) {
                        var icon = ClassificationMap.get(key);
                        return (React.createElement("div", { key: key, className: styles.icon }, !!icon && icon !== 'missing' && icon !== 'file' ? (React.createElement(Icon, { name: icon })) : !!icon && icon === 'file' ? (React.createElement("img", { src: FileClassificationMap.get(key), alt: key })) : (React.createElement("span", null, props.classification[key]))));
                    })))));
};
export default IconMarkingsBlock;
