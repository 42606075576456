var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './report.scss';
import moment from 'moment';
var PantryReportArea = /** @class */ (function (_super) {
    __extends(PantryReportArea, _super);
    function PantryReportArea(props) {
        return _super.call(this, props) || this;
    }
    PantryReportArea.prototype.componentDidMount = function () {
        window.print();
    };
    PantryReportArea.prototype.render = function () {
        var report = this.props.report;
        return (React.createElement("div", { className: styles.reportOverview },
            React.createElement("div", null,
                React.createElement("h3", null, translate('/Account/ProductList/PrintOptions/Table/Invent')),
                React.createElement("p", null, translate('/Account/ProductList/PrintOptions/Table/StockArea')),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Customer'),
                        ":"),
                    React.createElement("span", null, report.customerName)),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Pantries'),
                        ":"),
                    React.createElement("span", null, report.groupedByPantries
                        .map(function (pantry) {
                        return pantry.displayName;
                    })
                        .join(', '))),
                React.createElement("div", { className: styles.infoHeader },
                    React.createElement("span", null,
                        translate('/Account/ProductList/PrintOptions/Table/Date'),
                        ":"),
                    React.createElement("span", null, moment(Date.now()).format('YYYY-MM-DD')))),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", { className: styles.headerRow },
                        React.createElement("th", { colSpan: 6 }, translate('/Account/ProductList/PrintOptions/Table/Name')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockIn')),
                        React.createElement("th", { className: styles.right }, translate('/Account/ProductList/PrintOptions/Table/StockOut')))),
                report.all.groups.map(function (group, i) { return (React.createElement("tbody", { key: "printBody" + i, className: styles.groupSeparator },
                    React.createElement("tr", { className: styles.groupHeaderRow },
                        React.createElement("td", null, group.displayName)),
                    group.subGroups.map(function (subgroup, j) { return (React.createElement("tr", { key: "printRow" + j, className: styles.itemSeparator },
                        React.createElement("td", { colSpan: 6 }, subgroup.displayName),
                        React.createElement("td", { className: styles.right }, subgroup.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, subgroup.currentStockValue.toFixed(2)))); }),
                    React.createElement("tr", { className: styles.totalSubgroup },
                        React.createElement("td", { colSpan: 6 },
                            translate('/Account/ProductList/PrintOptions/Table/Total'),
                            "/",
                            group.displayName),
                        React.createElement("td", { className: styles.right }, group.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, group.currentStockValue.toFixed(2))))); }),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: styles.totalOverall },
                        React.createElement("td", { colSpan: 6 },
                            translate('/Account/ProductList/PrintOptions/Table/StockTotal'),
                            "/",
                            report.customerName),
                        React.createElement("td", { className: styles.right }, report.all.previousStockValue.toFixed(2)),
                        React.createElement("td", { className: styles.right }, report.all.currentStockValue.toFixed(2)))))));
    };
    return PantryReportArea;
}(React.Component));
export default PantryReportArea;
