var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Logotype from 'Shared/Logotype';
import { translate } from 'Shared/translate';
import { pathCombine } from 'Shared/url';
import * as server from 'Shared/server';
import connect from 'Shared/connect';
import React from 'react';
import { setFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import * as styles from './base.scss';
import PopUpMessage from './PopUpMessage';
import PopupInputForm from './PopUpInputForm';
var feedbackDelay = 500;
var AccountSettingsPage = /** @class */ (function (_super) {
    __extends(AccountSettingsPage, _super);
    function AccountSettingsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.onSubmit = function (e) {
            e.preventDefault();
            if (!!_this.state.invalid) {
                return Promise.reject();
            }
            return server.postJson(pathCombine(location.pathname, 'Update'), _this.state).then(function (result) {
                if (!result.isSuccess == undefined || !result.isSuccess) {
                    _this.props.setFeedback({ text: result.message, type: 'error' });
                    _this.setState({ isFormSubmitted: false, emailHasBeenSent: false });
                    return Promise.reject(null);
                }
                _this.setState({ isFormSubmitted: true, changeEmail: false, emailHasBeenSent: true });
                _this.props.setFeedback({
                    text: translate('/Account/AccountSettings/OnEmailConfirmationSent'),
                    type: 'success',
                });
            }, function (e) {
                _this.setState({ isFormSubmitted: false });
                return Promise.reject(null);
            });
        };
        _this.validateEmail = function (email) {
            return _this.sendValidationRequest("ValidateEmail", { email: email, prevEmail: "", }, false, "email");
        };
        _this.validateNameLength = function (text, arg) {
            return _this.sendValidationRequest("ValidateTextLength", { text: text, minLength: 1, maxLength: 50 }, true, arg);
        };
        _this.setInvalid = function (invalid, arg) {
            switch (arg) {
                case "firstName":
                    _this.setState({ invalidFirstName: invalid });
                    break;
                case "lastName":
                    _this.setState({ invalidLastName: invalid });
                    break;
                case "email":
                    _this.setState({ invalidEmail: invalid });
                    break;
            }
            if (!_this.state.invalidEmail && !_this.state.invalidFirstName && !_this.state.invalidLastName)
                _this.setState({ invalid: false, email: '' });
            else
                _this.setState({ invalid: true });
        };
        _this.sendValidationRequest = function (method, payload, showFeedback, arg) {
            return server
                .postJson(pathCombine("/FieldAndModelValidation", method), payload)
                .then(function (result) {
                if (!result.success) {
                    _this.setInvalid(true, arg);
                    if (!!showFeedback) {
                        !!result.message && _this.props.setFeedback({ text: result.message, type: 'error' });
                    }
                }
                else {
                    _this.setInvalid(false, arg);
                }
                return Promise.resolve(result);
            })
                .catch(function (err) {
                _this.props.setFeedback({ text: err.message, type: 'error' });
                return Promise.resolve(null);
            });
        };
        _this.sendEmailVerification = function () {
            try {
                return server.postJson(pathCombine(location
                    .pathname, '/SendEmailVerification'), {
                    email: _this.state.email
                })
                    .then(function (result) {
                    if (result.isSuccess) {
                        _this.props.setFeedback({
                            text: translate('/Account/AccountSettings/OnEmailConfirmationSent'),
                            type: 'success',
                        });
                    }
                    else {
                        _this.props.setFeedback({
                            text: result.message,
                            type: 'error',
                        });
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
        };
        _this.changeEmail = function () {
            _this.setState({ changeEmail: true, isFormSubmitted: false });
        };
        _this.setEmail = function (email) {
            _this.setState({ email: email });
        };
        _this.setFirstName = function (firstName) {
            _this.setState({ firstName: firstName });
        };
        _this.setLastName = function (lastName) {
            _this.setState({ lastName: lastName });
        };
        _this.state = {
            email: _this.props.email,
            firstName: _this.props.firstName,
            lastName: _this.props.lastName,
            hasError: false,
            isFormSubmitted: false,
            invalid: false,
            skipCount: _this.props.collectDataAttempts,
            userName: _this.props.userName,
            isAdmin: _this.props.isAdmin,
            emailHasBeenSent: _this.props.hasVerificationEmailBeenSent,
            isVerified: _this.props.isVerified,
            changeEmail: false,
            invalidEmail: false,
            invalidFirstName: false,
            invalidLastName: false,
        };
        return _this;
    }
    AccountSettingsPage.prototype.componentDidMount = function () {
        if (typeof document !== 'undefined') {
            var bodyElement = document.body;
            bodyElement.style.backgroundColor = '#f9f9f9';
        }
    };
    AccountSettingsPage.prototype.SkipOktaCollectDataForm = function () {
        return server.postJson(pathCombine(location.pathname, 'SkipOktaCollectData'));
    };
    AccountSettingsPage.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement("div", { className: styles.reset },
                React.createElement("div", { className: styles.accountSettingsWrapper },
                    React.createElement("div", { className: styles.logotypeWrapper },
                        React.createElement(Logotype, { className: styles.logotype, siteUrl: "/", disableLink: true }))),
                (!this.state.isFormSubmitted && this.state.emailHasBeenSent && !this.state.isVerified && !this.state.changeEmail) ? (React.createElement(PopUpMessage, { header: this.props.page.mustVerifyHeader, information: this.props.sentVerificationInformation, sendVerificationAgainText: this.props.page.sendVerificationAgainText, wrongEmailAddressText: this.props.page.wrongEmailAddressText, contactCustomerServiceText: this.props.page.contactCustomerServiceText, email: this.state.email, changeEmail: this.changeEmail, sendEmailVerification: this.sendEmailVerification })) : (this.state.emailHasBeenSent && this.state.isFormSubmitted && !this.state.changeEmail) ? (React.createElement(PopUpMessage, { header: this.props.page.sentVerificationHeader, information: this.props.sentVerificationInformation, sendVerificationAgainText: this.props.page.sendVerificationAgainText, wrongEmailAddressText: this.props.page.wrongEmailAddressText, contactCustomerServiceText: this.props.page.contactCustomerServiceText, email: this.state.email, changeEmail: this.changeEmail, sendEmailVerification: this.sendEmailVerification })) : (React.createElement(PopupInputForm, { validateNameLength: this.validateNameLength, validateEmail: this.validateEmail, SkipOktaCollectDataForm: this.SkipOktaCollectDataForm, onSubmit: this.onSubmit, setEmail: this.setEmail, setFirstName: this.setFirstName, setLastName: this.setLastName, preHeader: this.props.page.preHeader, header: (this.state.skipCount < 4) ? this.props.page.header : this.props.page.mustUpdateHeader, information: (this.state.skipCount < 4) ? this.props.page.information : this.props.page.mustUpdateInformation, isFormSubmitted: this.state.isFormSubmitted, firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, invalid: this.state.invalid, feedbackDelay: feedbackDelay, skipCount: this.state.skipCount, hasValidEmail: this.props.hasValidEmail })))));
    };
    return AccountSettingsPage;
}(React.Component));
export default connect(function (state) { return ({}); }, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
}); })(AccountSettingsPage);
