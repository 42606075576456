var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import ProductInformation from './ProductInformation';
import classnames from 'classnames';
var ProductItem = /** @class */ (function (_super) {
    __extends(ProductItem, _super);
    function ProductItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductItem.prototype.render = function () {
        switch (this.props.apperance) {
            default:
            case 'online':
                return React.createElement(ProductItemOnline, __assign({}, this.props));
            case 'offline':
                return React.createElement(ProductItemOffline, __assign({}, this.props));
        }
    };
    return ProductItem;
}(React.Component));
export default ProductItem;
var ProductItemOnline = /** @class */ (function (_super) {
    __extends(ProductItemOnline, _super);
    function ProductItemOnline() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.availabilityClass = { 0: styles.zero, 1: styles.one, 2: styles.two, 3: styles.three, 4: styles.four };
        return _this;
    }
    ProductItemOnline.prototype.render = function () {
        var _this = this;
        var option = this.props.unitShortName
            ? this.props.unitOptions.find(function (item) { return _this.props.unitShortName === item.unitShortName; })
            : this.props.unitOptions[0];
        return (React.createElement("div", { className: styles.productItem },
            React.createElement("div", { className: styles.displayName }, this.props.displayName),
            React.createElement("div", { className: styles.supplier },
                this.props.trademark,
                this.props.isCampaign && React.createElement("span", { className: styles.campaign }, translate('/Product/Campaign'))),
            React.createElement("div", { className: styles.information },
                React.createElement("div", null,
                    React.createElement("div", null, "Art: " + this.props.displayProductId,
                        this.props.supplierArtNo && ", Lev.art: " + this.props.supplierArtNo),
                    React.createElement(ProductInformation, __assign({}, this.props))),
                React.createElement("div", { className: styles.area },
                    this.props.area,
                    option.availabilityDate,
                    React.createElement("span", { className: classnames(styles.stock, this.availabilityClass[option.availabilityCode]) })))));
    };
    return ProductItemOnline;
}(React.Component));
var ProductItemOffline = /** @class */ (function (_super) {
    __extends(ProductItemOffline, _super);
    function ProductItemOffline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductItemOffline.prototype.render = function () {
        return (React.createElement("div", { className: styles.productItem },
            React.createElement("div", { className: styles.displayName }, this.props.displayName),
            React.createElement("div", { className: styles.supplier }, this.props.trademark),
            React.createElement("div", { className: styles.small }, "Art: " + this.props.displayProductId,
                this.props.supplierArtNo && ", Lev.art: " + this.props.supplierArtNo),
            React.createElement("div", { className: styles.small },
                React.createElement(ProductInformation, __assign({}, this.props)))));
    };
    return ProductItemOffline;
}(React.Component));
