var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import MenuItem from './MenuItem';
import * as styles from './base.scss';
import classnames from 'classnames';
var MenuColumn = function (_a) {
    var items = _a.items, className = _a.className, onEnter = _a.onEnter, onLeave = _a.onLeave, activeColumn = _a.activeColumn;
    return (React.createElement("ul", { className: classnames(styles.menuItemsList, className) }, items &&
        items.map(function (item, index) { return (React.createElement(MenuItem, __assign({ key: item.url, onMouseEnter: function () { return onEnter(index); }, onMouseLeave: function () { return onLeave(); }, isActive: index === activeColumn }, item))); })));
};
export default MenuColumn;
