var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import classnames from 'classnames';
import { parseUrl, urlToString } from 'Shared/url';
import { getSizes, getSizesEx } from 'Shared/ImageSizes';
import preload, { hasOtherSizeLoaded, getBestCachedImageUrl, registerLoaded } from 'Shared/ImageFallback/preload';
import * as styles from './base.scss';
var noImage = require('../../../styles/images/missingImage.svg');
var sizes = getSizes();
var sizesEx = getSizesEx();
export function srcset(urlString, requestedSize, maxSize) {
    if (requestedSize === void 0) { requestedSize = null; }
    if (maxSize === void 0) { maxSize = null; }
    var srcset = [];
    Object.keys(sizes).forEach(function (size) {
        if ((!requestedSize || sizes[size] >= sizes[requestedSize]) && (!maxSize || sizes[size] <= sizes[maxSize])) {
            srcset.push(urlWithSize(urlString, size) + ' ' + sizes[size] + 'w');
        }
    });
    return srcset.join(', ');
}
export function urlWithSize(urlString, size) {
    var url = parseUrl(urlString);
    url.query['w'] = sizes[size];
    url.query['h'] = sizes[size];
    url.query['mode'] = 'max';
    url.query['quality'] = '75';
    return urlToString(url);
}
export function urlWithSizeEx(urlString, size, mode) {
    var url = parseUrl(urlString);
    url.query['w'] = sizesEx[size].w;
    url.query['h'] = sizesEx[size].h;
    url.query['mode'] = !!mode ? mode : 'max';
    url.query['quality'] = '70';
    return urlToString(url);
}
var ImageFallback = /** @class */ (function (_super) {
    __extends(ImageFallback, _super);
    function ImageFallback(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            useCachedImagePlaceHolder: props.preferSmallerIfCached && hasOtherSizeLoaded(props.src, props.size, props.minSizeIfCached),
            stretchPlaceHolderOnWidth: false,
        };
        return _this;
    }
    ImageFallback.prototype.onLoad = function (e) {
        var image = e.target;
        setCachedIfLoaded(image, this.props.src, this.props.size);
        if (this.props.fadeIn && !this.state.useCachedImagePlaceHolder) {
            image.classList.remove(styles.fadedOut);
        }
    };
    ImageFallback.prototype.sizes = function () {
        if (this.props.size !== 'original') {
            return sizes[this.props.size] + 'px';
        }
        else {
            return null;
        }
    };
    ImageFallback.prototype.srcSet = function () {
        if (this.props.size !== 'original') {
            return srcset(this.props.src, this.props.size, this.props.maxSize);
        }
        else {
            return srcset(this.props.src, 'tiny');
        }
    };
    ImageFallback.prototype.setPlaceholderStretchAxisFromDimensions = function (width, height) {
        this.setState({ stretchPlaceHolderOnWidth: width > height });
    };
    ImageFallback.prototype.componentDidMount = function () {
        var _this = this;
        if (this.state.useCachedImagePlaceHolder) {
            this.setPlaceholderStretchAxisFromDimensions(this.placeHolderImage.naturalWidth, this.placeHolderImage.naturalHeight);
            preload(this.props.src, this.props.size, this.srcSet()).then(function () {
                if (_this.state.useCachedImagePlaceHolder) {
                    _this.setState({ useCachedImagePlaceHolder: false });
                }
            });
        }
    };
    ImageFallback.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, size = _a.size, maxSize = _a.maxSize, skipSrcSet = _a.skipSrcSet, preferSmallerIfCached = _a.preferSmallerIfCached, fadeIn = _a.fadeIn, minSizeIfCached = _a.minSizeIfCached, restProps = __rest(_a, ["className", "size", "maxSize", "skipSrcSet", "preferSmallerIfCached", "fadeIn", "minSizeIfCached"]);
        var newProps = __assign({}, restProps);
        var noImageSrc = this.props.noImageSrc ? this.props.noImageSrc : noImage;
        if (!this.props.src) {
            return (React.createElement("div", { className: styles.noImageHolder },
                React.createElement("img", __assign({}, newProps, { className: classnames(className, styles.missingImage, styles.disableClicks), src: noImageSrc }))));
        }
        if (this.props.size !== 'original') {
            newProps.src = urlWithSize(this.props.src, this.props.size);
        }
        if (this.state.useCachedImagePlaceHolder) {
            return (React.createElement("img", __assign({}, newProps, { ref: function (el) { return (_this.placeHolderImage = el); }, src: getBestCachedImageUrl(this.props.src), className: classnames(className, this.state.stretchPlaceHolderOnWidth ? styles.stretchOnWidth : styles.stretchOnHeigt, styles.disableClicks) })));
        }
        else {
            return (React.createElement("img", __assign({}, newProps, { className: classnames(className, styles.disableClicks), srcSet: this.props.skipSrcSet ? null : this.srcSet(), sizes: this.props.skipSrcSet ? null : this.sizes(), onLoad: function (e) { return _this.onLoad(e); }, ref: function (img) {
                    if (img) {
                        setCachedIfLoaded(img, _this.props.src, _this.props.size);
                        if (imageHasFailedToLoad(img)) {
                            setTimeout(function () {
                                // Firefox has an ugly tendency to report naturalWidth as 0 when a SW is active.
                                // But if we wait just a little bit, the sizes have been calculated. Broken
                                // images should be uncommon, so we can afford the setTimeout here
                                if (imageHasFailedToLoad(img)) {
                                    setErrorImage(img);
                                }
                            });
                        }
                        if (_this.props.fadeIn && !_this.props.preferSmallerIfCached && !imageHasLoaded(img)) {
                            img.classList.add(styles.fadedOut);
                            img.classList.add(styles.base);
                        }
                    }
                }, onError: function (e) { return onError(e); } })));
        }
    };
    ImageFallback.defaultProps = {
        size: 'original',
    };
    return ImageFallback;
}(React.Component));
export default ImageFallback;
function setCachedIfLoaded(img, originalSrc, size) {
    // If it's `noImage`
    if (!originalSrc) {
        return;
    }
    if (imageHasLoaded(img)) {
        registerLoaded(originalSrc, img.currentSrc, size);
    }
}
function onError(e) {
    var img = e.target;
    e.currentTarget.classList.add(styles.missingImage);
    setErrorImage(img);
}
function setErrorImage(img) {
    img.src = img.src.includes('dev.menigo.se') ? img.src.replace('dev.menigo.se', 'int.menigo.se') : noImage;
    img.classList.add(styles.base);
    img.srcset = '';
    img.sizes = '';
}
function imageHasLoaded(img) {
    if (!img.complete) {
        return false;
    }
    if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) {
        return false;
    }
    // No other way of checking: assume it’s ok.
    return true;
}
function imageHasFailedToLoad(img) {
    // Firefox and IE has a bug where svg reports 0 for natural size in some cases
    if (img.src && parseUrl(img.src).path.endsWith('.svg')) {
        return false;
    }
    return img.complete && typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0;
}
