var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { exit } from '../../SiteLayout/NetworkError/base.scss';
var NutritionFacts = /** @class */ (function (_super) {
    __extends(NutritionFacts, _super);
    function NutritionFacts(props) {
        return _super.call(this, props) || this;
    }
    NutritionFacts.prototype.listSubItems = function (parent, id) {
        var _this = this;
        !!parent.length &&
            this.rows.push(React.createElement("tr", { key: 'parent_' + id, className: classnames(styles.tableSubRow, styles.tableRow) },
                React.createElement("td", { colSpan: 2, className: classnames(styles.indent) }, translate('/Product/Nutrient/WhereOf'))));
        parent.map(function (data, i) {
            _this.rows.push(React.createElement("tr", { key: 'child_' + data.code + i, className: classnames(styles.tableSubRow, styles.tableRow) },
                React.createElement("td", null,
                    " - ",
                    data.displayName),
                React.createElement("td", { className: styles.right }, data.value + ' ' + data.unit)));
        });
    };
    NutritionFacts.prototype.render = function () {
        var _this = this;
        this.rows = [];
        this.props.tableData.map(function (data, i) {
            {
                (data.value == 0 && !data.shouldAlwaysDisplay) ? exit :
                    _this.rows.push(React.createElement("tr", { key: 'main_' + data.code + i, className: styles.tableRow },
                        React.createElement("td", null, data.displayName),
                        React.createElement("td", { className: styles.right }, data.value + ' ' + data.unit)));
            }
            _this.listSubItems(data.children, i);
        });
        return (!!this.props.tableData &&
            this.props.tableData.length > 0 && (React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement("tr", { className: styles.tableHeading },
                    React.createElement("th", null, translate('/Product/FoodValue')),
                    React.createElement("th", null,
                        translate('/Product/Value'),
                        !!this.props.nutritionInformationProvider && ' (' + this.props.nutritionInformationProvider + ')')),
                this.rows))));
    };
    return NutritionFacts;
}(React.Component));
export default NutritionFacts;
