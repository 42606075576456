/* Warning, using any of theese functions should only be done in client side.
 The server don't know what "document" is since this app uses serverrendering.
- Solution: be sure to use theese functions in, or after the render method in the react lifecycle.
    i.e. (don't use theese functions in the constructor of your component)
*/
function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
function setCookie(cname, cvalue, exdays, sessionOnly) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    var sameSite = ';SameSite=None;secure';
    if (sessionOnly) {
        document.cookie = cname + '=' + cvalue + sameSite;
    }
    else {
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/' + sameSite;
    }
}
function forceDeleteCookieOnWindowClose(cname) {
    // will also be fired when navigating with an url on the page.
    // unfortunaly not any way around this atm:
    // https://stackoverflow.com/questions/3888902/detect-browser-or-tab-closing
    window.onbeforeunload = function () {
        document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;SameSite=None;secure';
    };
}
export default {
    setCookie: setCookie,
    getCookie: getCookie,
    forceDeleteCookieOnWindowClose: forceDeleteCookieOnWindowClose,
};
