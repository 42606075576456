var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import * as styles from './base.scss';
import AddOnDetailContent from './Components/AddOnDetailContent';
var AddOnDetailBlock = /** @class */ (function (_super) {
    __extends(AddOnDetailBlock, _super);
    function AddOnDetailBlock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddOnDetailBlock.prototype.render = function () {
        var _this = this;
        var showPriceExplanation = this.props.priceExplanation != "";
        return (React.createElement("div", { className: styles.overviewBox },
            React.createElement("div", { className: styles.header }, this.props.header),
            React.createElement("div", { className: styles.preamble }, this.props.preamble),
            React.createElement("div", { className: styles.contentBox }, this.props.content.map(function (data, i) { return (React.createElement(AddOnDetailContent, { key: i, header: data.header, description: data.description, price: data.price, content: data.content, selected: _this.props.selectedAddOns.some(function (v) { return (v == data.addOnType); }) })); })),
            showPriceExplanation &&
                React.createElement("div", { className: styles.priceExplanation },
                    "*",
                    this.props.priceExplanation)));
    };
    return AddOnDetailBlock;
}(React.Component));
export default AddOnDetailBlock;
