import * as styles from './propertyFor.scss';
export default (function (propertyName, className) {
    if (className === void 0) { className = ''; }
    if (window.IS_IN_EDIT_MODE && propertyName) {
        return {
            className: (className ? className + ' ' : '') + +styles.textBase + ' epi-editContainer',
            'data-epi-property-name': propertyName,
            'data-epi-use-mvc': 'True',
        };
    }
    else {
        return { className: (className ? className + ' ' : '') + styles.textBase };
    }
});
