var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import MyMenigoIcon from '../../Shared/MyMenigoIcon/MyMenigoIcon';
import classnames from 'classnames';
import ContentArea from 'Shared/Partials/PropertyTypes/ContentArea';
var CustomerArrangementBlock = /** @class */ (function (_super) {
    __extends(CustomerArrangementBlock, _super);
    function CustomerArrangementBlock() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.seeDetails = translate('/MyMenigo/MyArrangement/SeeDetails');
        _this.close = translate('/MyMenigo/MyArrangement/Close');
        _this.state = {
            buttonText: _this.seeDetails,
            iconName: 'plus',
            expandedPackageDetails: false
        };
        _this.onClick = function () {
            var buttonText = _this.state.buttonText == _this.seeDetails ? _this.close : _this.seeDetails;
            var iconName = _this.state.iconName == 'plus' ? 'minus' : 'plus';
            var expandedPackageDetails = _this.state.expandedPackageDetails == false ? true : false;
            _this.setState({
                buttonText: buttonText,
                iconName: iconName,
                expandedPackageDetails: expandedPackageDetails
            });
        };
        return _this;
    }
    CustomerArrangementBlock.prototype.render = function () {
        var _a;
        var packageLabel = this.props.packageLabel;
        var showPackageLabel = !!packageLabel;
        var addOnLabels = this.props.addOnLabels;
        var showAddOnLabels = !!addOnLabels && addOnLabels.length > 0;
        return (React.createElement(React.Fragment, null, (!!showPackageLabel || !!showAddOnLabels) && (React.createElement("div", { className: classnames(styles.overviewBox) },
            React.createElement("div", { className: classnames((_a = {}, _a[styles.selected] = this.state.expandedPackageDetails, _a)) },
                React.createElement("div", { className: styles.general },
                    React.createElement("div", { className: styles.columnInformation },
                        React.createElement("h2", { className: styles.header }, this.props.header),
                        React.createElement("div", { className: styles.bodyText }, this.props.body)),
                    React.createElement("div", { className: styles.columnButton }, showPackageLabel && (React.createElement("a", { className: styles.buttonDetails, onClick: this.onClick },
                        this.state.buttonText,
                        " ",
                        React.createElement(MyMenigoIcon, { className: styles.icon, name: this.state.iconName })))),
                    React.createElement("div", { className: styles.labelWrapper },
                        showPackageLabel && (React.createElement("span", { className: classnames(styles.label, styles.green) }, packageLabel)),
                        showAddOnLabels && (addOnLabels.map(function (data) { return (React.createElement("span", { key: data, className: classnames(styles.label, styles.yellow) }, data)); }))))),
            this.state.expandedPackageDetails && (React.createElement(React.Fragment, null,
                React.createElement(ContentArea, __assign({}, this.props.servicePackageDetailsArea)),
                React.createElement(ContentArea, __assign({}, this.props.addOnDetailsArea)),
                this.props.documentOfTermsLinkUrl && (React.createElement("div", { className: styles.documentLink },
                    React.createElement(MyMenigoIcon, { name: 'pdf' }),
                    React.createElement("a", { href: this.props.documentOfTermsLinkUrl, target: '_blank' }, this.props.documentOfTermsLinkText)))))))));
    };
    return CustomerArrangementBlock;
}(React.Component));
export default CustomerArrangementBlock;
