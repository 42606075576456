var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback } from 'react';
import connect from 'Shared/connect';
import Button from 'Shared/Button';
import Modal from 'Shared/Modal';
import { translate } from 'Shared/translate';
import { renameList } from 'Shared/ListItem/action-creators';
import Dialog, { DialogType } from 'Shared/Dialog';
import MyMenigoIcon from '../../MyMenigo/Shared/MyMenigoIcon/MyMenigoIcon';
import { Input } from 'Shared/Fields';
function RenameList(props) {
    var _a = __read(useState(false), 2), isOpen = _a[0], setOpen = _a[1];
    var textInput = React.createRef();
    var handleClose = useCallback(function () { return setOpen(false); }, []);
    var handleConfirm = useCallback(function () { return props.renameList(textInput.current.element.value); }, [textInput]);
    return (React.createElement("div", { className: props.passedClassName, onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(Button, { appearance: props.buttonAppearance, onClick: function () { return setOpen(true); } },
            React.createElement(MyMenigoIcon, { name: "edit" })),
        React.createElement(Modal, { title: translate('/Account/ProductList/RenameList'), isOpen: isOpen, onClose: handleClose, reserveSpaceForCloseButton: true, fadeDesign: false },
            React.createElement(Input, { defaultValue: props.displayName, ref: textInput, autoFocus: true }),
            React.createElement(Dialog, { type: DialogType.YesNo, onConfirm: handleConfirm, onCancel: handleClose, onStatusWasFulfilled: handleClose }))));
}
export default connect(null, function (dispatch, _a) {
    var id = _a.id;
    return ({
        renameList: function (newName) { return dispatch(renameList(id, newName)); },
    });
})(RenameList);
