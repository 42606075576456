var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Main, { Section, Basic } from 'Shared/PageLayout';
import MyPagesMenu from './../../../MyMenigo/Shared/MyPagesMenu';
import AutoComplete from './../Shared/AutoComplete';
import SlaveList from './../Shared/SlaveList';
import { translate } from 'Shared/translate';
import * as server from 'Shared/server';
import DropDown from 'Shared/DropDown';
import * as styles from './base.scss';
import { pathCombine } from 'Shared/url';
import connect from 'Shared/connect';
import { setFeedback, dismissFeedback } from 'SiteLayout/GenericFeedbackBar/action-creators';
import shallowEquals from 'Shared/shallow-equals';
import ConfirmRemoveMaster from './../Shared/ConfirmRemoveMaster';
var RecipeMasterPage = /** @class */ (function (_super) {
    __extends(RecipeMasterPage, _super);
    function RecipeMasterPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            autoCompleteMasterList: [],
            autoCompleteSlaveList: [],
            masterOptions: props.masterOptions,
            selectedMasterId: props.masters.length > 0 ? props.masters[0].recipeMasterCustomerId : '',
            selectedMasterName: props.masters.length > 0 ? props.masters[0].name : '',
            masters: props.masters,
        };
        return _this;
    }
    RecipeMasterPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (!shallowEquals(this.props.masters, nextProps.masters)) {
            this.setState({ masters: nextProps.masters });
        }
    };
    RecipeMasterPage.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var shouldUpdate = !shallowEquals(this.props, nextProps) || !shallowEquals(this.state, nextState);
        if (!shouldUpdate) {
            console.info('Ignoring update to Masters');
        }
        return shouldUpdate;
    };
    RecipeMasterPage.prototype.onCustomerSearch = function (query, type) {
        var _this = this;
        return server
            .postJson(pathCombine(location.pathname, '/customersearch'), {
            query: query,
            masterSearch: type !== 'autoCompleteSlaveList',
        })
            .then(function (result) {
            var _a;
            if (result.success) {
                _this.setState((_a = {}, _a[type] = result.value, _a));
                return Promise.resolve();
            }
            else {
                return _this.props.setFeedback({ text: result.message, type: 'error' });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    RecipeMasterPage.prototype.onUpdateMaster = function (type, customerId) {
        var _this = this;
        return server
            .postJson(pathCombine(location.pathname, type), { recipeMasterCustomerId: customerId })
            .then(function (result) {
            if (result.success) {
                var masterObj = result.value.masterOptions.length <= 0
                    ? null
                    : type === 'addmaster'
                        ? result.value.masterOptions.find(function (f) { return f.value === customerId; })
                        : result.value.masterOptions[0];
                var selectedMaster = !!masterObj ? masterObj.value : '';
                var selectedMasterName = !!masterObj ? masterObj.text : '';
                _this.setState({
                    masters: result.value.masters,
                    masterOptions: result.value.masterOptions,
                    selectedMasterId: selectedMaster,
                    selectedMasterName: selectedMasterName,
                });
                return Promise.resolve();
            }
            else {
                return _this.props.setFeedback({ text: result.message, type: 'error' });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    RecipeMasterPage.prototype.onChangeSelectedMaster = function (customerId) {
        var masterObj = this.props.masterOptions.find(function (f) { return f.value === customerId; });
        var name = !!masterObj ? masterObj.text : '';
        this.setState({ selectedMasterId: customerId, selectedMasterName: name });
    };
    RecipeMasterPage.prototype.onUpdateSlave = function (type, recipeMasterCustomerId, customerId) {
        var _this = this;
        return server
            .postJson(pathCombine(location.pathname, type), { recipeMasterCustomerId: recipeMasterCustomerId, slaveCustomerId: customerId })
            .then(function (result) {
            if (result.success) {
                var i = _this.state.masters.findIndex(function (master) { return master.recipeMasterCustomerId === recipeMasterCustomerId; });
                var newMasters = Object.assign([], _this.state.masters);
                newMasters[i].slaves = result.value;
                _this.setState({ masters: newMasters });
                return Promise.resolve();
            }
            else {
                return _this.props.setFeedback({ text: result.message, type: 'error' });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    RecipeMasterPage.prototype.onRemoveSelectedSlaves = function (slaveCustomerIds, recipeMasterCustomerId) {
        var _this = this;
        return server
            .postJson(pathCombine(location.pathname, 'removeselectedslaves'), { recipeMasterCustomerId: recipeMasterCustomerId, slaveCustomerIds: slaveCustomerIds })
            .then(function (result) {
            if (result.success) {
                var i = _this.state.masters.findIndex(function (master) { return master.recipeMasterCustomerId === recipeMasterCustomerId; });
                var newMasters = Object.assign([], _this.state.masters);
                newMasters[i].slaves = result.value;
                _this.setState({ masters: newMasters });
                return Promise.resolve();
            }
            else {
                return _this.props.setFeedback({ text: result.message, type: 'error' });
            }
        })
            .catch(function () { return Promise.reject(null); });
    };
    RecipeMasterPage.prototype.render = function () {
        var _this = this;
        var selectedMaster = this.state.masters.find(function (master) { return master.recipeMasterCustomerId === _this.state.selectedMasterId; });
        return (React.createElement(Main, { appearance: "narrow" },
            React.createElement(MyPagesMenu, { menuItems: this.props.menu.menuItems, name: this.props.menu.name }),
            React.createElement(Section, null,
                React.createElement("h1", null, translate('/Admin/MasterCustomers/Recipe/Title'))),
            React.createElement(Section, { appearance: "divider", layout: "1to1" },
                React.createElement(Basic, null,
                    React.createElement("h3", null, translate('/Admin/MasterCustomers/Recipe/AddMasterCustomer')),
                    React.createElement(AutoComplete, { onBlur: function () { return _this.setState({ autoCompleteMasterList: [] }); }, list: this.state.autoCompleteMasterList, onAdd: function (customerId) { return _this.onUpdateMaster('addmaster', customerId); }, onChange: function (query) { return _this.onCustomerSearch(query, 'autoCompleteMasterList'); } })),
                React.createElement(Basic, null,
                    React.createElement("h3", null, translate('/Admin/MasterCustomers/Recipe/ChooseMasterCustomer')),
                    React.createElement(DropDown, { hardValue: true, className: styles.dropdown, options: this.state.masterOptions, value: this.state.selectedMasterId, onChange: function (customerId) { return _this.onChangeSelectedMaster(customerId); } }),
                    !!this.state.masters && this.state.masters.length > 0 && (React.createElement(ConfirmRemoveMaster, { masterName: this.state.selectedMasterName, onUpdateMaster: function () { return _this.onUpdateMaster('removemaster', _this.state.selectedMasterId); } })),
                    React.createElement("h3", { className: styles.addCustomer }, translate('/Admin/MasterCustomers/AddCustomer')),
                    React.createElement(AutoComplete, { onBlur: function () { return _this.setState({ autoCompleteSlaveList: [] }); }, list: this.state.autoCompleteSlaveList, onAdd: function (customerId) { return _this.onUpdateSlave('addslave', _this.state.selectedMasterId, customerId); }, onChange: function (query) { return _this.onCustomerSearch(query, 'autoCompleteSlaveList'); } }),
                    React.createElement(SlaveList, { list: !!selectedMaster && selectedMaster.slaves, remove: function (customerId) {
                            return _this.onUpdateSlave('removeslave', _this.state.selectedMasterId, customerId);
                        }, removeSelected: function (slaves) { return _this.onRemoveSelectedSlaves(slaves, _this.state.selectedMasterId); } })))));
    };
    return RecipeMasterPage;
}(React.Component));
export default connect(null, function (dispatch) { return ({
    setFeedback: function (data) {
        return dispatch(setFeedback(data));
    },
    dismissFeedback: function () {
        return dispatch(dismissFeedback());
    },
}); })(RecipeMasterPage);
